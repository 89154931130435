import { Box, Grid, Typography } from "@mui/material"
import { StickyBox } from "components"
import { Button, CancelButton, DatePicker, Select } from "components/Form"
import { useShiftCategoryListQuery } from "data/core/shiftCategory/queries"
import { useUnitShiftCategoryCreateMutation, useShiftCategoryUserEditMutation } from "data/finance/unit/mutations"
import { UnitShiftCategory, UnitShiftCategoryRequest } from "data/finance/unit/types"
import { ProfileMode } from "lib/types"
import validators, { composeValidators } from "lib/validators"
import { DateTime } from "luxon"
import React, { memo, useMemo } from "react"
import { Field, Form } from "react-final-form"

interface OwnProps {
  mode: ProfileMode
  shiftCategory?: UnitShiftCategory
  unitGuid: string
}

const REQUIRED_FIELD = "This field is required"

const useSubmitHandler = (mode: ProfileMode, unitGuid: string) => {
  const { mutateAsync: createUnitShiftCategory } = useUnitShiftCategoryCreateMutation({ unitGuid })
  const { mutateAsync: updateUnitShiftCategory } = useShiftCategoryUserEditMutation({ unitGuid })

  if (mode === "create") {
    return (shiftCategory: UnitShiftCategoryRequest) => {
      if (!shiftCategory.start || !shiftCategory.shift_category_guid) {
        return {
          ...(!shiftCategory.start ? { start: REQUIRED_FIELD } : {}),
          ...(!shiftCategory.shift_category_guid ? { shift_category_guid: REQUIRED_FIELD } : {})
        }
      }
      createUnitShiftCategory({ shiftCategory })
    }
  } else {
    return (shiftCategory: UnitShiftCategory) => {
      if (!shiftCategory.start) {
        return { start: REQUIRED_FIELD }
      }
      updateUnitShiftCategory({ shiftCategory })
    }
  }
}

const Item = ({ children }: { children: React.JSX.Element }): React.JSX.Element => (
  <Grid {...{ item: true, md: 6 }}>{children}</Grid>
)

export const UnitShiftCategoryProfile: React.FC<OwnProps> = memo(({ mode, shiftCategory, unitGuid }) => {
  const { data: shiftCategories } = useShiftCategoryListQuery()

  const onSubmit = useSubmitHandler(mode, unitGuid)

  const shiftCategoryAsOptions = useMemo(
    () => (shiftCategories || []).map((category) => ({ title: category.title, value: category.guid })),
    [shiftCategories]
  )

  return (
    <Box>
      <Form {...{ onSubmit, initialValues: shiftCategory }}>
        {({ handleSubmit, pristine, submitting, values }) => (
          <form {...{ onSubmit: handleSubmit }}>
            <Box my={2}>
              <Grid
                container
                spacing={1}
                sx={{
                  mt: 2,
                  mb: 2
                }}
              >
                {shiftCategory ? (
                  <>
                    <Item>
                      <>
                        <Typography variant="body2" color="textSecondary">
                          Shift Category Title
                        </Typography>
                        <Typography variant="h6">{shiftCategory.shift_title}</Typography>
                      </>
                    </Item>
                    <Item>
                      <>
                        <Typography variant="body2" color="textSecondary">
                          Status
                        </Typography>
                        <Typography variant="h6">{shiftCategory.status}</Typography>
                      </>
                    </Item>
                  </>
                ) : (
                  <Item>
                    <Field<string>
                      {...{
                        name: "shift_category_guid",
                        label: "Shifts Category (required)",
                        component: Select,
                        options: shiftCategoryAsOptions,
                        InputLabelProps: { required: true }
                      }}
                    />
                  </Item>
                )}
              </Grid>
              <Grid container spacing={1}>
                <Item>
                  <Field<DateTime>
                    {...{
                      name: "start",
                      label: "Start",
                      component: DatePicker,
                      withTime: false,
                      InputLabelProps: { required: true },
                      maxDate: values.end ? values.end.minus({ days: 1 }) : undefined,
                      validate: composeValidators([validators.required, validators.validDate])
                    }}
                  />
                </Item>
                <Item>
                  <Field<DateTime>
                    {...{
                      name: "end",
                      label: "End",
                      component: DatePicker,
                      withTime: false,
                      minDate: values.start ? values.start.plus({ days: 1 }) : undefined,
                      validate: validators.validDate
                    }}
                  />
                </Item>
              </Grid>
            </Box>

            <StickyBox>
              <CancelButton
                {...{ disabled: submitting, pristine, navigateUrl: `/units/${unitGuid}/shift-categories` }}
              />
              <Button {...{ type: "submit", fullWidth: false, disabled: submitting || pristine }}>Save changes</Button>
            </StickyBox>
          </form>
        )}
      </Form>
    </Box>
  )
})

UnitShiftCategoryProfile.displayName = "UnitShiftCategoryProfile"
