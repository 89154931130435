import { CalendarResponse } from "data/finance/contractModel/types"
import { DateTimeFormat } from "lib/datetime"
import { Row } from "pages/Finance/ContractModels/components/calendars/Table/Body"
import { NavigateFunction } from "react-router-dom"

const crateCalendarData = (calendar: CalendarResponse): string[] => [
  calendar.title,
  calendar.frequency_type_alias,
  calendar.start.toFormat(DateTimeFormat.DATE),
  calendar.end ? calendar.end.toFormat(DateTimeFormat.DATE) : "/ /",
  calendar.status
]

export const mapCalendarsToRows = (navigate: NavigateFunction, calendars?: CalendarResponse[]): Row[] =>
  calendars?.map((calendar: CalendarResponse, index: number) => ({
    data: crateCalendarData(calendar),
    guid: calendar.guid || index.toString(),
    canBeDeleted: calendar.status === "Future",
    navigate: () => {
      navigate(calendar.guid)
    }
  })) || []
