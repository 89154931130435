import React from "react"
import { Header, ContentContainer } from "components"
import ShiftCategoryDetail from "./ShiftCategoryDetail"

const ShiftCategoryCreate: React.FC = () => {
  return (
    <>
      <ContentContainer>
        <Header title={"Create a Shift Category"} />
        <ShiftCategoryDetail mode="create" />
      </ContentContainer>
    </>
  )
}

export default ShiftCategoryCreate
