import React, { useState } from "react"
import { ContentContainer, Header } from "components"
import { Button } from "components/Form"
import { useUnassignedOperatorsListQuery } from "data/core/operator/queries"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import Auth from "lib/Auth"
import { Checkbox, Grid, Typography } from "@mui/material"
import { useAssignOperatorsMutation } from "data/core/operator/mutations"
import ConfirmationDialog from "components/ConfirmationDialog"
import OperatorsTable from "./OperatorsTable"
import usePagination from "./usePagination"
import SearchOperators, { SearchFilter } from "./SearchOperators"
import { useNavigate } from "react-router-dom"
import useBranchName from "lib/hooks/branchInfo/useBranchName"

const UnassignedOperatorsList: React.FC = () => {
  const navigate = useNavigate()
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false)

  const { rowsPerPage, page, onRowsPerPageChange, onPageChange } = usePagination()

  const [filter, setFilter] = useState<SearchFilter>({})
  const userInfo = Auth.getUserInfo()
  const branchGuid = userInfo?.branchGuid || ""
  const { data } = useUnassignedOperatorsListQuery({
    branchGuid,
    page: page + 1,
    pageSize: rowsPerPage,
    search: filter.search
  })

  const { mutate: assignOperators } = useAssignOperatorsMutation()

  const { count, users: operators = [] } = data || {}

  const [checkedOperatorsIds, setCheckedOperators] = useState<string[]>([])

  const toggleOperator = (operatorId: string) => {
    if (checkedOperatorsIds.indexOf(operatorId) > -1) {
      setCheckedOperators(checkedOperatorsIds.filter((checkedOperatorId) => checkedOperatorId !== operatorId))
    } else {
      setCheckedOperators([...checkedOperatorsIds, operatorId])
    }
  }

  useFilterLocationChange({ filter })

  const branchTitle = useBranchName()

  return (
    <ContentContainer>
      <ConfirmationDialog
        title="Assign Operators"
        open={showConfirmationDialog}
        onCancel={() => setShowConfirmationDialog(false)}
        onConfirm={() => {
          assignOperators({ branchGuid, operatorsGuid: checkedOperatorsIds })
          navigate("/admin/operators")
        }}
      >
        <Typography variant="body1">
          {`Are you sure you want to assign ${checkedOperatorsIds.length} operator${
            checkedOperatorsIds.length > 1 ? "s" : ""
          } to branch ${branchTitle}?`}
        </Typography>
      </ConfirmationDialog>
      <Header title="Assign Operators to Branch" />
      <Grid container justifyContent="space-between" alignItems="center">
        <SearchOperators initialValues={filter} onClear={() => setFilter({})} onSubmit={setFilter} />
        <Grid item>
          <Button disabled={checkedOperatorsIds.length === 0} onClick={() => setShowConfirmationDialog(true)}>
            Assign
          </Button>
        </Grid>
      </Grid>
      <OperatorsTable
        operators={operators}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        additionalData={[
          {
            children: ({ operator }) => (
              <Checkbox
                checked={!!checkedOperatorsIds.find((checkedOperatorId) => checkedOperatorId === operator.guid)}
                onChange={() => toggleOperator(operator.guid)}
                inputProps={{ "aria-label": `${operator.first_name} ${operator.last_name}` }}
              />
            )
          }
        ]}
      />
    </ContentContainer>
  )
}

export default UnassignedOperatorsList
