import { TableCell, TableRow } from "@mui/material"
import { Select, TextField } from "components/Form"
import { SelectOption } from "components/Form/Select"
import { displayWithDecimals } from "lib/number"
import React, { memo, useMemo } from "react"
import { getRateTimeFromPeriod } from "../../helpers/getRateTimeFromPeriod"
import { RateUpliftForm, RateUpliftTypeEnum } from "../../types"
import { FormApi } from "final-form"
import { useRateUpliftNewRateUpdateHandler } from "../../handlers/useRateUpliftNewRateUpdateHandler"
import { Field, useFormState } from "react-final-form"
import { isNil } from "ramda"
import validators from "lib/validators"
import { ContractModelRate } from "data/finance/contractModel/types"

interface OwnProps {
  contractGuid: string
  serviceGuid: string
  bandGuid: string
  rate: ContractModelRate
  dayTypeOptions: SelectOption[]
  form: FormApi<RateUpliftForm>
}

export const RateUpliftThreeBandRate: React.FC<OwnProps> = memo(
  ({ serviceGuid, contractGuid, bandGuid, rate, dayTypeOptions, form }) => {
    const { values } = useFormState<RateUpliftForm>()

    useRateUpliftNewRateUpdateHandler({ form, rate, contractGuid, serviceGuid, bandGuid: bandGuid })

    const formRateName = `models[${contractGuid}].services[${serviceGuid}].bands[${bandGuid}].rates[${rate.guid}]`

    const isNewRateFieldDisabled = useMemo(() => {
      const upliftType = values.models[contractGuid].services?.[serviceGuid]?.uplift_type

      return isNil(upliftType) || upliftType !== RateUpliftTypeEnum.MANUAL
    }, [contractGuid, serviceGuid, values.models])

    return (
      <TableRow>
        <TableCell
          sx={{
            padding: "0",
            paddingLeft: "5px",
            paddingRight: "5px"
          }}
        >
          <Field
            name={`${formRateName}.day_type`}
            label="Day Type"
            component={Select}
            options={dayTypeOptions}
            disabled
            defaultValue={rate.holiday_tier_guid ?? rate.day_type_alias}
          />
        </TableCell>
        <TableCell
          sx={{
            padding: "0",
            paddingLeft: "5px",
            paddingRight: "5px"
          }}
        >
          <Field
            name={`${formRateName}.start`}
            component={TextField}
            type="time"
            label=""
            disabled
            defaultValue={getRateTimeFromPeriod(rate.start)}
          />
        </TableCell>
        <TableCell
          sx={{
            padding: "0",
            paddingLeft: "5px",
            paddingRight: "5px"
          }}
        >
          <Field
            name={`${formRateName}.end`}
            component={TextField}
            type="time"
            label=""
            disabled
            defaultValue={getRateTimeFromPeriod(rate.end)}
          />
        </TableCell>
        <TableCell
          sx={{
            padding: "0",
            paddingLeft: "5px",
            paddingRight: "5px"
          }}
        >
          <Field
            name={`${formRateName}.rate`}
            label="Rate (£)"
            component={TextField}
            disabled
            defaultValue={displayWithDecimals(rate.rate, 2)}
          />
        </TableCell>
        <TableCell
          sx={{
            padding: "0",
            paddingLeft: "5px",
            paddingRight: "5px"
          }}
        >
          <Field
            name={`${formRateName}.new_rate`}
            label="New Rate (£) *"
            component={TextField}
            disabled={isNewRateFieldDisabled}
            validate={validators.validateCommonNumber({ min: 0 })}
          />
        </TableCell>
      </TableRow>
    )
  }
)

RateUpliftThreeBandRate.displayName = "RateUpliftThreeBandRate"
