import React, { memo } from "react"
import { ImportForm } from "./ImportForm"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

export const Import: React.FC = memo(() => {
  Import.displayName = "Import"

  return (
    <SentryRoutes>
      <Route path="/" element={<ImportForm />} />
    </SentryRoutes>
  )
})
