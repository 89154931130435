import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import React from "react"
import { optionsToValueTitle } from "../lib/schema"
import { VisitTask } from "../models"
import testingId from "constants/testingId"
import { path } from "ramda"
import { useCoreSchemasStore } from "stores"

interface Props {
  tasks: Array<VisitTask> | null
}

const VisitTasks: React.FC<Props> = ({ tasks }) => {
  const { BusinessVisit } = useCoreSchemasStore((state) => state.schema.models)

  const taskStatusValues = optionsToValueTitle(path(["tasks", "status", "options"], BusinessVisit) ?? [])

  if (tasks === null || tasks.length === 0) return <p>This visit does not have any assigned tasks.</p>

  return (
    <Table data-cy={testingId.visit.detail.tasks.table}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontWeight: "bold" }}>Category</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Description</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
          <TableCell sx={{ fontWeight: "bold" }} align="right">
            Comments
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tasks.map((task: VisitTask) => (
          <TableRow key={task.guid} data-cy={testingId.visit.detail.tasks.tableRow}>
            <TableCell data-cy={testingId.visit.detail.tasks.tableCell}>{task.category}</TableCell>
            <TableCell data-cy={testingId.visit.detail.tasks.tableCell}>{task.description}</TableCell>
            <TableCell data-cy={testingId.visit.detail.tasks.tableCell}>
              {taskStatusValues[task.status] === "Init" && (
                <Typography variant="body2" color="textSecondary">
                  Created
                </Typography>
              )}
              {taskStatusValues[task.status] === "NotCompleted" && (
                <Typography variant="body2" color="textSecondary">
                  Not completed
                </Typography>
              )}
              {taskStatusValues[task.status] === "Completed" && (
                <Typography variant="body2" color="textSecondary">
                  Completed
                </Typography>
              )}
            </TableCell>
            <TableCell align="right" data-cy={testingId.visit.detail.tasks.tableCell}>
              {task.comment || (
                <Typography variant="body2" color="textSecondary">
                  No comments provided.
                </Typography>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default VisitTasks
