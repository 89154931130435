import React, { memo } from "react"
import MuiDivider from "@mui/material/Divider"

interface Props {
  withMargin?: boolean
  color?: string
}

const Divider = ({ withMargin = true, color = "black" }: Props) => (
  <MuiDivider
    sx={{
      height: "1px",
      backgroundColor: color,
      ...(withMargin ? { marginTop: "20px", marginBottom: "20px" } : {})
    }}
  />
)

export default memo(Divider)
