import { FinanceApi } from "api"
import { enabledById } from "data/helpers"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { HolidayTier } from "./types"

export enum QueryKeyParts {
  HOLIDAY_TIER = "holiday_tier",
  DETAIL = "detail",
  LIST = "list"
}

export const queryKeys = {
  getHolidayTier: ({ guid }: { guid?: string }): QueryKey => [
    QueryKeyParts.HOLIDAY_TIER,
    QueryKeyParts.DETAIL,
    { guid }
  ],
  getHolidayTierList: (): QueryKey => [QueryKeyParts.HOLIDAY_TIER, QueryKeyParts.LIST]
}

export const useHolidayTierQuery = ({ guid }: { guid?: string }): UseQueryResult<HolidayTier, Error> => {
  return useQuery({
    queryKey: queryKeys.getHolidayTier({ guid }),
    queryFn: async () => FinanceApi.get(`/finance/holiday-tiers/${guid}`),
    ...enabledById(guid)
  })
}

export const useHolidayTierListQuery = (): UseQueryResult<HolidayTier[], Error> => {
  return useQuery({
    queryKey: queryKeys.getHolidayTierList(),
    queryFn: async () => FinanceApi.get("/finance/holiday-tiers")
  })
}
