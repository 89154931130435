import React from "react"
import { NotFound } from "./index"
import CarerList from "./Carers/CarerList"
import CarerDetail from "./Carers/CarerDetail"
import CarerCreate from "./Carers/CarerCreate"
import Auth from "lib/Auth"
import PermissionDenied from "app/PermissionDenied"
import { Permission } from "constants/permission"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

const Carers: React.FC = () => {
  if (!Auth.hasPermission([Permission.USER_CARER_READ])) {
    return <PermissionDenied />
  }

  return (
    <SentryRoutes>
      <Route path="/" element={<CarerList />} />
      <Route path="new/*" element={<CarerCreate />} />
      <Route path=":id/*" element={<CarerDetail />} />
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  )
}
export default Carers
