import { Link } from "components"
import React, { memo } from "react"
import { ContractModel, ContractModelService, ContractModelServiceBand } from "data/finance/contractModel/types"
import { ContractModelTitle } from "./"
import { ContractModelType } from "constants/modelTypes"

interface OwnProps {
  contractModel: ContractModel
  modelType: ContractModelType
  service: ContractModelService
  band: ContractModelServiceBand
  isInvoicePaymentService?: boolean
}

export const RatesTitle: React.FC<OwnProps> = memo(
  ({ contractModel, service, band, modelType, isInvoicePaymentService }) => {
    const isInvoiceService = modelType === ContractModelType.INVOICE && !isInvoicePaymentService

    return (
      <ContractModelTitle
        modelType={modelType}
        title={
          <>
            <Link to="../settings">{contractModel?.title}</Link> /{" "}
            <Link to={`../${isInvoiceService ? "invoice-services" : "payment-services"}`}>
              {isInvoiceService ? "Invoice" : "Payment"} services
            </Link>{" "}
            /{" "}
            <Link to={`../${isInvoiceService ? "invoice-services" : "payment-services"}/${service?.guid}`}>
              {service?.service_type?.title}
            </Link>{" "}
            /{" "}
            {`${band.minimum_duration_minutes}${
              band.maximum_duration_minutes ? ` - ${band.maximum_duration_minutes}` : "+"
            }`}
          </>
        }
      />
    )
  }
)

RatesTitle.displayName = "RatesTitle"
