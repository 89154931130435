import { FinanceApi } from "api"
import { enabledByMandatoryUrlParams, setDayBorders } from "data/helpers"
import { removeFromSearchString } from "lib/helpers/removeFromSearchString"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { AppointmentSearch } from "./types"

enum QueryKeyParts {
  INVOICE_SEARCH = "invoice-search"
}

const QueryKeys = {
  getInvoiceSearch: ({ search }: { search?: string }): QueryKey => [QueryKeyParts.INVOICE_SEARCH, { search }]
}

export const useInvoiceSearchQuery = ({
  search,
  enabled = true
}: {
  search?: string
  enabled?: boolean
}): UseQueryResult<AppointmentSearch[], Error> => {
  const searchQuery = removeFromSearchString({ search, remove: ["modelType"] })
  const newSearch = setDayBorders({ search: searchQuery, from: "start", to: "end" })

  return useQuery({
    queryKey: QueryKeys.getInvoiceSearch({ search: newSearch }),
    queryFn: async () => FinanceApi.get(`finance/invoice-search${newSearch}`),
    ...enabledByMandatoryUrlParams([newSearch, enabled])
  })
}
