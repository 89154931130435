import React, { memo } from "react"
import { ContentContainer } from "components"
import { ContractModelForm, ContractModelTitle } from "./components"
import testingId from "constants/testingId"
import { ContractModelType, INVOICE_CONTRACT_PUBLIC_ALIASES } from "constants/modelTypes"
import { useInvoiceModelSubmitHandler } from "./handlers/useInvoiceModelSubmitHandler"
import { usePaymentModelSubmitHandler } from "./handlers/usePaymentModelSubmitHandler"
import { omit } from "ramda"
import { ContractModel, ContractModelRequest } from "data/finance/contractModel/types"

interface OwnProps {
  contractModel?: ContractModel
  modelType: ContractModelType
}

const ContractModelDetail: React.FC<OwnProps> = ({ contractModel, modelType }) => {
  const submitInvoiceModel = useInvoiceModelSubmitHandler()
  const submitPaymentModel = usePaymentModelSubmitHandler()

  const onSubmit = async (values: ContractModelRequest & { no_end_date: boolean }) => {
    const isInvoiceModelType = modelType === ContractModelType.INVOICE

    let payload = {
      ...values,
      end: values.no_end_date ? null : values.end,
      modelType
    } as ContractModelRequest

    if (!INVOICE_CONTRACT_PUBLIC_ALIASES.includes(payload.type_alias)) {
      payload = omit(["finance"], payload)
    }
    if (isInvoiceModelType) {
      submitInvoiceModel(contractModel, payload)
    } else {
      submitPaymentModel(contractModel, payload)
    }
  }

  return (
    <ContentContainer data-cy={testingId.contractModelSettings.screen}>
      <ContractModelTitle
        modelType={modelType}
        title={contractModel ? `${contractModel.title} / Settings` : "New model"}
        data-cy={testingId.contractModelSettings.title}
      />
      <ContractModelForm {...{ modelType, contractModel, onSubmit }} />
    </ContentContainer>
  )
}

export default memo(ContractModelDetail)
