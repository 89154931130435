import { FormSectionHeader } from "components/Form"
import { ContractModelType } from "constants/modelTypes"
import React, { memo } from "react"
import { ContractModelTable } from ".."
import { ContractModelUplift } from "../../types"
import { RateUpliftOneModelCheckboxAll } from "./RateUpliftOneModelCheckboxAll"
import { RateUpliftOneModelCheckbox } from "./RateUpliftOneModelCheckbox"
import { RateUpliftHeaderWithTooltip } from "./RateUpliftHeaderWithTooltip"
import { ContractModel } from "data/finance/contractModel/types"

interface OwnProps {
  contracts: ContractModelUplift[]
  modelType: ContractModelType
  onSortCallback: (items: ContractModel[]) => void
}

export const RateUpliftOne: React.FC<OwnProps> = memo(({ contracts, modelType, onSortCallback }) => {
  RateUpliftOne.displayName = "RateUpliftOne"

  const isInvoice = modelType === ContractModelType.INVOICE

  const label = isInvoice ? "Invoice Group" : "Pay Group"

  return (
    <>
      <FormSectionHeader
        title={
          <RateUpliftHeaderWithTooltip
            {...{
              label,
              title: `Rules for selecting ${label}:`,
              titleBody: [
                `${label} must not have an end date (hidden)`,
                `${label} must start before the uplift (disabled)`,
                `${label} must not have pending uplifts (disabled)`
              ]
            }}
          />
        }
      />
      <ContractModelTable
        {...{
          items: contracts,
          modelType,
          customComponents: {
            CustomTableItem: RateUpliftOneModelCheckbox,
            CustomTableHeader: RateUpliftOneModelCheckboxAll
          },
          clickable: false,
          onSortCallback
        }}
      />
    </>
  )
})
