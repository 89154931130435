import React from "react"
import { FieldRenderProps } from "react-final-form"
import {
  InputLabel,
  FormHelperText,
  Checkbox,
  Select,
  MenuItem,
  ListItemText,
  FormControl,
  FormControlProps,
  SxProps,
  Theme
} from "@mui/material"
import testingId from "constants/testingId"

export type SelectOption<T = string> = {
  title: T
  plainTitle?: string // for AutocompleteMui
  description?: string | null
  value: string | number
}

export type SelectOptionNumber = {
  title: string
  description: string | null
  value: number
}

type Props = FieldRenderProps<any, any> & {
  multiselect?: boolean
  displayEmpty?: boolean
  options?: SelectOption[]
  label?: string
  formControlProps?: FormControlProps
  InputLabelProps?: {
    required?: boolean
    sx?: SxProps<Theme>
  }
  SelectProps?: {
    sx?: SxProps<Theme>
  }
}

const SelectWrapper = (props: Props) => {
  const {
    input: { name, value, onChange, ...restInput },
    meta,
    label,
    multiselect,
    options,
    formControlProps,
    InputLabelProps,
    SelectProps,
    ...rest
  } = props

  const selectOptions: SelectOption[] = options ? options : []
  const inputLabel = React.useRef<HTMLLabelElement>(null)

  const optionsValueLabel = selectOptions.reduce((r: any, e: SelectOption) => {
    r[e.value] = e.title
    return r
  }, {})

  const computeValue = (selected: string[]): string => {
    return selected
      .reduce((result: string[], selectedItem: string) => {
        result.push(optionsValueLabel[selectedItem])
        return result
      }, [])
      .join(", ")
  }

  // NOTE(maoc): This is here because Cohen messed up the default_value for type Array :)
  const multiselectOptionIsChecked = (value: string | number, item: SelectOption) => {
    if (Array.isArray(value)) {
      return value.indexOf(item.value) > -1
    }
    if (value) {
      return [value].indexOf(item.value) > -1
    }
    return false
  }

  const renderMultipleOptions = () =>
    selectOptions.map((item, key) => (
      <MenuItem dense={true} key={key} value={item.value} data-cy={testingId.selectOption}>
        <Checkbox checked={multiselectOptionIsChecked(value, item)} />
        <ListItemText primary={item.title} />
      </MenuItem>
    ))

  const renderOptions = () =>
    selectOptions.map((item, key) => (
      <MenuItem key={key} value={item.value} data-cy={testingId.selectOption}>
        {item.title}
      </MenuItem>
    ))

  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched

  // adjust value for multi/single select
  const multiselectNonArrayValue = value ? [value] : []
  const multiselectValue = Array.isArray(value) ? value : multiselectNonArrayValue
  const fieldValue = multiselect ? multiselectValue : value

  return (
    <FormControl
      fullWidth
      margin="normal"
      variant="outlined"
      {...formControlProps}
      error={showError}
      data-cy={testingId.select}
    >
      <InputLabel sx={InputLabelProps?.sx} ref={inputLabel} htmlFor={`${name}`} required={InputLabelProps?.required}>
        {label}
      </InputLabel>

      <Select
        sx={{
          "& > .MuiSelect-select": {
            whiteSpace: "normal !important"
          }
        }}
        {...rest}
        {...SelectProps}
        name={name}
        variant="outlined"
        onChange={onChange}
        inputProps={restInput}
        label={label}
        multiple={multiselect}
        value={fieldValue}
        renderValue={(selected: any) => {
          return multiselect ? computeValue(selected) : <>{optionsValueLabel[selected]}</>
        }}
      >
        {multiselect ? renderMultipleOptions() : renderOptions()}
      </Select>

      {showError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
    </FormControl>
  )
}
export default SelectWrapper
