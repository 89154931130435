import { DateTime } from "luxon"
import { isNil } from "ramda"
import { Row } from "pages/Clients/components/Table/Body"
import dateTimeUtils from "lib/datetime"
import { StatusEnum } from "data/commonTypes"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { UserContractTab } from "pages/Finance/components/types"
import { queryStringToObject } from "lib/queryString"
import { getContractModelLink } from "pages/Finance/components/helpers/getContractModelLink"
import { useSchema } from "data/schema/useSchema"
import { useLocation, useNavigate } from "react-router-dom"
import { BaseApiContract } from "data/finance/client/types"

export const buildUserContractInitialValues = <T extends { end?: DateTime }>({
  contract,
  isFirstContract
}: {
  contract?: T
  isFirstContract: boolean
}): T | { is_default: true } | {} => {
  if (contract) {
    return {
      ...contract,
      no_end_date: isNil(contract.end)
    }
  }

  if (isFirstContract) {
    return { is_default: true }
  }

  return {}
}

export const useMapContractsAsTableRows = (contracts: BaseApiContract[]): Row[] => {
  const { InvoiceContractType } = useSchema(["InvoiceContractType"])
  const navigate = useNavigate()

  return useMemo(
    () =>
      contracts.map((contract: BaseApiContract) => {
        const canBeDeleted = contract.status === StatusEnum.FUTURE && !contract.is_default
        const modelType = InvoiceContractType?.find((model) => model.value === contract.contract_type_alias)
        const isDefault = contract.is_default
        const isInvoiceModel = true

        const data = [
          contract.reference,
          getContractModelLink({
            contract_guid: contract.contract_guid,
            contract_title: contract.contract_model,
            isInvoiceModel
          }),
          modelType?.title || contract.contract_type_alias,
          dateTimeUtils.formatDate(contract.start),
          contract.end ? dateTimeUtils.formatDate(contract.end) : "--/--/--",
          contract.status + `${isDefault ? " (Default)" : ""}`
        ]

        return {
          data,
          guid: contract.guid,
          isDefault,
          canBeDeleted,
          navigate: () => navigate(`${contract.guid}`),
          isInvoiceModel
        } as Row
      }),
    [contracts, InvoiceContractType, navigate]
  )
}

export const getOtherUserContracts = <T extends { contract_guid?: string }>(
  contractModelGuid: string,
  userContracts: T[] = []
): T[] => {
  return userContracts.filter((contract) => contract.contract_guid === contractModelGuid)
}

export const useUserContractTabState = (): [UserContractTab, Dispatch<SetStateAction<UserContractTab>>] => {
  const location = useLocation()

  return useState<UserContractTab>(() => {
    const { tabType } = queryStringToObject(location.search)
    return tabType === UserContractTab.FILES ? UserContractTab.FILES : UserContractTab.SETTINGS
  })
}
