import React, { memo } from "react"
import { Tag } from "components/Tag"
import { StatusEnum } from "data/commonTypes"
import { ApiContractAccrual } from "data/finance/carer/types"

const getStatusText = (status: StatusEnum) => {
  if (status === StatusEnum.CURRENT) {
    return "This accrual is actively running"
  }
  if (status === StatusEnum.FUTURE) {
    return "This accrual policy is due to start"
  }
  return "This accrual policy has ended"
}

interface OwnProps {
  accrual: ApiContractAccrual
}

export const CarerAccrualListTableItemStatus: React.FC<OwnProps> = memo(({ accrual }) => {
  CarerAccrualListTableItemStatus.displayName = "CarerAccrualListTableItemStatus"

  return <Tag status={accrual.status} tooltipText={getStatusText(accrual.status)} />
})
