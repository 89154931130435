import { SchemaFieldOption } from "../../../lib/types"
import { ApiUserCarer, ApiUserFinanceCarer, SourcingType } from "../../../models"
import { toUpper } from "ramda"
import { SelectOption } from "components/Form/Select"
import { DateTime } from "luxon"
import { CarerProfileData } from "data/carer-profile/types"

const inactiveStatuses = [3, 14, 15, 16]

const EXIT_PREFERENCE_BRANCH_MANAGER = 1

export const buildCarerStatus = (status: number, statusOptions: SchemaFieldOption[]): any => {
  const statusValues = statusOptions.find((schemaStatus) => schemaStatus.value === status)

  return {
    id: status,
    title: statusValues?.title,
    type: statusValues?.type
  }
}

export const hasGivenNotice = (status: number, statusOptions: SchemaFieldOption[]): boolean => {
  const givenNotice = statusOptions.find((schemaStatus) => schemaStatus.value === status)
  return givenNotice?.type === "Notice"
}

export const isExEmployee = (status: number): boolean => inactiveStatuses.includes(status)

export const buildCarerExit = ({ carerProfileData, hr }: ApiUserCarer, leavingReasonsOptions: SelectOption[]): any => ({
  contact_about_future_roles: carerProfileData?.contact_about_future_roles,
  exit_interview_preference_id: carerProfileData?.exit_interview_preference_id ?? EXIT_PREFERENCE_BRANCH_MANAGER,
  resignation_date: carerProfileData?.resignation_date,
  leaving_date: hr?.leaving_date,
  leaving_notes: hr?.leaving_notes,
  leaving_reason: {
    guid: leavingReasonsOptions.find((option) => option.title === hr?.leaving_reason)?.description
  }
})

export const constructSourcingType = (enableAgencyIndicator: boolean, sourcing_type?: string): any =>
  enableAgencyIndicator && { sourcing_type: sourcing_type ? SourcingType.AGENCY : SourcingType.NONE }

export const constructCarerExit = (
  apiUserCarer: ApiUserCarer,
  enableCarerExit: boolean,
  enableGivenNotice: boolean,
  statusOptions: SchemaFieldOption[],
  leavingReasonsOptions: SelectOption[]
): any =>
  ((enableCarerExit && isExEmployee(apiUserCarer.status)) ||
    (enableGivenNotice && hasGivenNotice(apiUserCarer.status, statusOptions))) && {
    carer_exit: buildCarerExit(apiUserCarer, leavingReasonsOptions)
  }

export const constructStatus = (status: number, enableGivenNotice: boolean, statusOptions: SchemaFieldOption[]): any =>
  enableGivenNotice && {
    status: buildCarerStatus(status, statusOptions)
  }

export const buildCarerProfileDataForUpdateOrCreate = (
  values: ApiUserCarer,
  enableAgencyIndicator: boolean,
  enableCarerExit: boolean,
  enableGivenNotice: boolean,
  statusOptions: SchemaFieldOption[],
  leavingReasonsOptions: SelectOption[]
) => {
  if (enableAgencyIndicator || enableCarerExit || enableGivenNotice) {
    return {
      id: values.carerProfileData?.id,
      start_date: values.hr?.start_date,
      ...constructSourcingType(enableAgencyIndicator, values.carerProfileData?.sourcing_type),
      ...constructCarerExit(values, enableCarerExit, enableGivenNotice, statusOptions, leavingReasonsOptions),
      ...constructStatus(values.status, enableGivenNotice, statusOptions)
    } as CarerProfileData
  }

  return undefined
}

export const buildFinanceData = (
  finance: ApiUserFinanceCarer,
  enableAgencyIndicator: boolean,
  carerProfileSourcingType?: string
): ApiUserFinanceCarer => {
  let formattedNiNumber = undefined
  const isAgency = enableAgencyIndicator && !!carerProfileSourcingType

  if (!isAgency && finance?.ni_number) {
    formattedNiNumber = toUpper(finance.ni_number.replace(/\s/g, ""))
  }

  return {
    ...finance,
    is_agency: isAgency,
    ni_number: formattedNiNumber
  }
}

export const removeZeroWidthCharactersAndTrimObject = <T extends Partial<Record<keyof T, unknown>>>(obj: T): T => {
  // eslint-disable-next-line no-misleading-character-class
  const clearString = (val: string) => val.replace(/[\u200B-\u200F\uFEFF]/g, "").trim()

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, typeof value === "string" ? clearString(value) : value])
  ) as T
}

export const getPreferenceTitle = (
  exitInterviewPreferences: SelectOption[],
  exit_interview_preference_id?: number
): string | undefined => {
  return exitInterviewPreferences.find((preference) => preference.value === exit_interview_preference_id)?.title
}

export const getCarerInitialValues = () => ({
  finance: {
    job: 3,
    travel_method: "Driving"
  },
  hr: { start_date: DateTime.local().startOf("day") },
  preferences: {
    a_car_owner: 0,
    able_to_drive: 0,
    alzheimers: 0,
    cat_friendly: 0,
    copd: 0,
    dementia: 0,
    dog_friendly: 0,
    domicilliary_package: 0,
    drug_and_alcohol_package: 0,
    end_of_life_package: 0,
    extra_care_package: 0,
    happy_to_service_couples: 0,
    happy_to_service_female_clients: 0,
    happy_to_service_male_clients: 0,
    learning_disability_package: 0,
    mental_health_package: 0,
    multiple_sclerosis: 0,
    nurse_led_package: 0,
    other_mental_health: 0,
    oxygen_machine: 0,
    palliative_care: 0,
    parkinsons_disease: 0,
    peg_feeding: 0,
    permanent_live_in_package: 0,
    reablement_package: 0,
    short_term_live_in_package: 0,
    smoking: 0,
    spinal_injury: 0,
    stroke: 0
  },
  profile: {
    gender: "Unspecified",
    ethnicity: 0,
    marital_status: "Unknown",
    religion: 0,
    salutation: "Mr",
    sexual_orientation: "Unknown"
  },
  regions: [],
  status: 13
})
