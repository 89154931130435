import { CoreApi, HealthApi } from "api"
import { DateTime } from "luxon"
import { ApiVisit } from "models"
import { ApiMobilityAssessment } from "models/mobilityAssessment"
import { QueryKey, useQuery, UseQueryResult } from "react-query"

export enum QueryKeyParts {
  VISIT = "visit",
  LIST = "list",
  DETAIL = "detail",
  RISK_SCORE = "risk_score",
  MOBILITY_ASSESSMENT_REPORT = "mobility_assessment_report"
}

export const queryKeys = {
  getVisitListKey: ({ day, search }: { day: DateTime; search?: string }): QueryKey => [
    QueryKeyParts.VISIT,
    QueryKeyParts.LIST,
    { day, search }
  ],
  getVisitDetailKey: (id?: string): QueryKey => [QueryKeyParts.VISIT, QueryKeyParts.DETAIL, { id }],
  getVisitMobilityAssessmentReportKey: (visitId?: string): QueryKey => [
    QueryKeyParts.MOBILITY_ASSESSMENT_REPORT,
    { visitId }
  ]
}

export const useVisitQuery = ({ id }: { id: string }): UseQueryResult<ApiVisit, Error> => {
  return useQuery({
    queryKey: queryKeys.getVisitDetailKey(id),
    queryFn: async () => CoreApi.get(`/visit/${id}`)
  })
}

export const useVisitMobilityAssessmentReportQuery = ({
  visitId
}: {
  visitId: string
}): UseQueryResult<ApiMobilityAssessment> => {
  return useQuery({
    queryKey: queryKeys.getVisitMobilityAssessmentReportKey(visitId),
    enabled: !!visitId,
    queryFn: async () => HealthApi.get(`/visits/${visitId}/reports/mobility-assessment`),
    retry: false
  })
}
