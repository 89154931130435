import { Typography } from "@mui/material"
import { Header } from "components"
import testingId from "constants/testingId"
import { ApiUserCarer } from "models"
import React, { memo } from "react"

interface OwnProps {
  isReducedPaddingView: boolean
  carer: ApiUserCarer
}

export const CarerHeader: React.FC<OwnProps> = memo(({ isReducedPaddingView, carer }) => {
  CarerHeader.displayName = "CarerHeader"

  return (
    <Header
      sx={
        isReducedPaddingView
          ? (theme) => ({
              padding: theme.spacing(0, 5)
            })
          : undefined
      }
      data-cy={testingId.carer.payAdvice.payAdviceCarerInformationTitle}
      title={
        carer ? (
          <>
            {`Carers - ${carer.profile.name}`}
            {carer.finance.payroll_reference || carer.reference ? (
              <>
                {" "}
                -{" "}
                <Typography
                  component="span"
                  sx={{
                    color: "primary.main"
                  }}
                  variant="h4"
                >
                  {carer.finance.payroll_reference || carer.reference}
                </Typography>
              </>
            ) : null}
          </>
        ) : (
          "Not Found"
        )
      }
    />
  )
})
