import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { EmptyData } from "components"
import testingId from "constants/testingId"
import { DateTimeFormat } from "lib/datetime"
import { formatDuration } from "lib/utils"
import React, { memo } from "react"
import { useNavigate } from "react-router-dom"
import { ApiShiftResponse } from "types/Shifts/ShiftList/types"

interface ShiftListTableProps {
  shifts: ApiShiftResponse[] | null
}

const ShiftListTable = ({ shifts }: ShiftListTableProps) => {
  const navigate = useNavigate()
  if (!shifts) return null

  return (
    <>
      {shifts.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Shift Time</TableCell>
              <TableCell align="left">Duration</TableCell>
              <TableCell align="left">Shift Name</TableCell>
              <TableCell align="left">Carer Name</TableCell>
              <TableCell align="left">Visits</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shifts.map((shift) => {
              return (
                <TableRow
                  key={shift.guid}
                  hover
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/shifts/detail/${shift.guid}`)
                  }}
                  data-cy={testingId.shifts.list.row}
                >
                  <TableCell data-cy={testingId.shifts.list.cell}>
                    {shift.start.toFormat(DateTimeFormat.TIME)} - {shift.end.toFormat(DateTimeFormat.TIME)}
                  </TableCell>
                  <TableCell data-cy={testingId.shifts.list.cell}>{formatDuration(shift.start, shift.end)}</TableCell>
                  <TableCell data-cy={testingId.shifts.list.cell}>{shift.title}</TableCell>
                  <TableCell data-cy={testingId.shifts.list.cell}>{shift.carer_name}</TableCell>
                  <TableCell data-cy={testingId.shifts.list.cell}>{shift.visit_guids.length}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      ) : (
        <EmptyData message="We did not find any matching shifts for selected date and filter values." />
      )}
    </>
  )
}

export default memo(ShiftListTable)
