import React from "react"
import { Field, Form, FormSpy, FormSpyRenderProps } from "react-final-form"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { ApiUserRelative, ApiUserClient } from "models"
import { AddressSearch, UserAccount, ContentContainer, StickyBox } from "components"
import { Button, Checkbox, FormSectionHeader, Select, TextField } from "components/Form"
import { ProfileMode } from "lib/types"
import { useClientRelativeQuery } from "data/core/client/queries"
import { useClientCreateRelativeMutation, useClientUpdateRelativeMutation } from "data/core/client/mutations"
import { ClientHeader } from "./components/ClientHeader"
import validators, { composeValidators } from "lib/validators"
import { getOptionsFromSchema } from "lib/schema"
import { useParams } from "react-router-dom"
import { useCoreSchemasStore } from "stores"

interface OwnProps {
  mode: ProfileMode
  client: ApiUserClient
}

const ClientRelativeProfile: React.FC<OwnProps> = ({ mode, client }) => {
  const { relativeId } = useParams()
  const { ApiUserPatientRelativeCreate } = useCoreSchemasStore((state) => state.schema.models)

  const salutationOptions = getOptionsFromSchema(ApiUserPatientRelativeCreate, "profile.salutation")
  const relationshipOptions = getOptionsFromSchema(ApiUserPatientRelativeCreate, "relationship")

  const clientGuid = client.guid

  const { data: relative } = useClientRelativeQuery({
    enabled: mode === "update" && !!relativeId,
    clientGuid,
    relativeGuid: relativeId
  })

  const { mutateAsync: createRelative } = useClientCreateRelativeMutation({ clientGuid })
  const { mutateAsync: updateRelative } = useClientUpdateRelativeMutation({ clientGuid })

  const onSubmit = (values: ApiUserRelative) => {
    const payload = {
      ...values,
      profile: {
        ...values.profile,
        short_name: values.profile.first_name
      },
      client: clientGuid
    }

    if (mode === "create") {
      createRelative(payload)
    } else {
      updateRelative(payload)
    }
  }

  const initialValues = relative ?? { status: 1 }

  return (
    <ContentContainer>
      <ClientHeader client={client} />
      {!!relative && <UserAccount user={relative} />}
      <FormSectionHeader title="Relative" />

      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={8}>
              <Grid item md={6}>
                <Field
                  name="status"
                  label="Status *"
                  component={Select}
                  options={[
                    { title: "Active", value: 0 },
                    { title: "Inactive", value: 1 }
                  ]}
                  validate={validators.required}
                />
              </Grid>
            </Grid>

            <FormSectionHeader title="Personal details" />
            <Grid container spacing={8}>
              <Grid item md={6}>
                <Field
                  name="profile.salutation"
                  label="Salutation *"
                  component={Select}
                  options={salutationOptions}
                  validate={validators.required}
                />
                <Field
                  name="profile.first_name"
                  label="First name *"
                  component={TextField}
                  validate={validators.required}
                />
                <Field
                  name="profile.last_name"
                  label="Last name *"
                  component={TextField}
                  validate={validators.required}
                />
              </Grid>
              <Grid item md={6}>
                <Field
                  name="relationship"
                  label="Relationship with client"
                  component={Select}
                  options={relationshipOptions}
                />
                <Field name="profile.bio" label="Bio" component={TextField} multiline={true} minRows={5} />
              </Grid>
            </Grid>

            <FormSectionHeader title="Address" />
            <Grid container spacing={8}>
              <Grid item md={6}>
                <Field name="profile.address" label="Address *" component={TextField} validate={validators.required} />
                <FormSpy subscription={{ values: true }}>
                  {(props: FormSpyRenderProps<ApiUserRelative>) => (
                    <AddressSearch address={props.values.profile?.address} form={props.form} />
                  )}
                </FormSpy>
                <Box display="none">
                  <Field name="profile.address_post_code" component={TextField} />
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={8}>
              <Grid item md={6}>
                <Field
                  name="profile.phone_mobile"
                  label="Personal mobile number"
                  component={TextField}
                  validate={validators.phone}
                />
                <Field
                  name="profile.phone_landline"
                  label="Landline phone number"
                  component={TextField}
                  validate={validators.phone}
                />
              </Grid>
              <Grid item md={6}>
                <Field
                  name="profile.email"
                  label="E-mail *"
                  component={TextField}
                  validate={composeValidators([validators.required, validators.email])}
                />
                {mode === "create" && (
                  <Field name="send_welcome_email" label="Send welcome e-mail" component={Checkbox} type="checkbox" />
                )}
              </Grid>
            </Grid>

            <StickyBox>
              {mode === "update" && (
                <Button
                  variant="text"
                  fullWidth={false}
                  disabled={submitting || pristine}
                  onClick={() => {
                    form.reset()
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                type="submit"
                color="secondary"
                fullWidth={false}
                disabled={submitting || pristine}
                sx={(theme) => ({ marginLeft: theme.spacing(2) })}
              >
                Save changes
              </Button>
            </StickyBox>
          </form>
        )}
      </Form>
    </ContentContainer>
  )
}

export default ClientRelativeProfile
