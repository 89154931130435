import React, { memo, useEffect, useState } from "react"
import { TableBody, TableCell, TableRow } from "@mui/material"
import { Field } from "react-final-form"
import { DateTime } from "luxon"
import { Checkbox } from "components/Form"
import { grey } from "@mui/material/colors"
import { DataLoading } from "components"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import { Pagination } from "lib/types"
import { DateField } from "components/Form/DateField"
import { CalendarPeriod } from "data/finance/contractModel/types"

interface OwnProps {
  items?: CalendarPeriod[]
  modelType: ContractModelType
  pagination: Pagination
  allItemsLength: number
}

export const CalendarPeriodsTableBody: React.FC<OwnProps> = memo(
  ({ items = [], modelType, pagination, allItemsLength }) => {
    CalendarPeriodsTableBody.displayName = "CalendarPeriodsTableBody"
    const isInvoiceModel: boolean = modelType === ContractModelType.INVOICE

    // item.guid as a table row key makes component to re-render but with warning Warning: Cannot update a component (`ForwardRef(Field)`) while rendering a different component (`ForwardRef(Field)`). To locate the bad setState() call inside `ForwardRef(Field)`

    // shit code that forces table to rebuild because otherwise after adding new periods their displayed values are same as values of first previous period
    const [show, setShow] = useState(true)
    useEffect(() => {
      setShow(false)
      const timer = setTimeout(() => setShow(true), 500)
      return () => clearTimeout(timer)
    }, [allItemsLength, pagination])

    if (!show)
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={5}>
              <DataLoading />
            </TableCell>
          </TableRow>
        </TableBody>
      )

    return (
      <TableBody>
        {items.map((item) => {
          const isNew = item.guid?.indexOf("new-item-") === 0
          const canBeDeleted = item.start > DateTime.local() || isNew
          const isToBeDeleted = item.delete
          const formItemIndex = item.index

          return (
            <TableRow
              key={formItemIndex}
              style={{
                opacity: isToBeDeleted ? 0.4 : 1,
                backgroundColor: isToBeDeleted ? grey[100] : "transparent"
              }}
              data-cy={testingId.contractModel.calendar.list.tableRow}
            >
              <TableCell>
                {canBeDeleted ? (
                  <Field
                    type="checkbox"
                    label=""
                    component={Checkbox}
                    name={`periods[${formItemIndex}].delete`}
                    noValidate={true}
                    data-cy={testingId.contractModel.calendar.list.deleteCheckbox}
                  />
                ) : null}
              </TableCell>
              <TableCell data-cy={testingId.contractModel.calendar.list.tableCell}>
                <DateField name={`periods[${formItemIndex}].start`} label="Starts on" required />
              </TableCell>
              <TableCell data-cy={testingId.contractModel.calendar.list.tableCell}>
                <DateField name={`periods[${formItemIndex}].end`} label="Ends after" required />
              </TableCell>
              <TableCell data-cy={testingId.contractModel.calendar.list.tableCell}>
                <DateField name={`periods[${formItemIndex}].process_date`} label="Process date" required />
              </TableCell>
              {isInvoiceModel && (
                <TableCell data-cy={testingId.contractModel.calendar.list.tableCell}>
                  <DateField name={`periods[${formItemIndex}].invoice_deadline`} label="Invoice Deadline" />
                </TableCell>
              )}
            </TableRow>
          )
        })}
      </TableBody>
    )
  }
)
