import { Box, Typography } from "@mui/material"
import testingId from "constants/testingId"
import { PaymentTransactionGroup } from "data/finance/paymentProcessing/types"
import { moneyFormat } from "lib/utils"
import { sumBy } from "lodash"
import React, { memo, useMemo } from "react"

interface OwnProps {
  transactionGroups: PaymentTransactionGroup[]
}

export const TransactionGroupMoney: React.FC<OwnProps> = memo(({ transactionGroups }) => {
  const costOfDisplayed = useMemo(() => sumBy(transactionGroups, "total") ?? 0, [transactionGroups])

  return (
    <Box data-cy={testingId.paymentProcessingGroupList.totalCostTitle}>
      <Typography
        component="span"
        sx={{
          fontSize: "20px"
        }}
      >
        Cost of displayed:&nbsp;
      </Typography>
      <Typography
        sx={(theme) => ({
          fontSize: "20px",
          color: theme.palette.primary.main
        })}
      >
        {moneyFormat.format(costOfDisplayed)}
      </Typography>
    </Box>
  )
})

TransactionGroupMoney.displayName = "TransactionGroupMoney"
