import { FormStage } from "constants/form"
import { useCarerContractCreateOrEditHandler } from "data/finance/carer/handlers/useCreateOrEditCarerContractHandler"
import { ApiCarerContractRequest } from "data/finance/carer/types"
import { filterDeletableItems } from "data/helpers"
import { constructTimeSpanUsingPeriod } from "lib/timespan"
import { Dispatch, SetStateAction, useCallback } from "react"
import { CarerContractFormValues } from "../types"

export const useCarerContractSubmitHandler = ({
  formStage,
  carerGuid,
  setFormStage,
  setSelectedContractModelGuid
}: {
  formStage: FormStage
  carerGuid: string
  setFormStage: Dispatch<SetStateAction<FormStage>>
  setSelectedContractModelGuid: Dispatch<SetStateAction<string | undefined>>
}): ((values: CarerContractFormValues) => void) => {
  const createOrUpdate = useCarerContractCreateOrEditHandler({ carerGuid })

  return useCallback(
    (values: CarerContractFormValues) => {
      if (formStage === FormStage.CREATE) {
        setSelectedContractModelGuid(values.contract_guid)
        setFormStage(FormStage.EDIT)
        return
      }

      const { current_role, guaranteed_hours, rates, mileages, travel_times, ...rest } = values

      const travelTimesPayload = filterDeletableItems(travel_times || []).map((travelTime) => ({
        ...travelTime,
        wait_time_threshold:
          constructTimeSpanUsingPeriod(travelTime.wait_time_threshold, "MINUTES")
            ?.shiftTo("days", "hours", "minutes", "seconds", "milliseconds")
            .toFormat("d:h:m:s.S") ?? ""
      }))

      const payload: ApiCarerContractRequest = {
        ...rest,
        owner_guid: carerGuid,
        end: current_role ? undefined : values.end,
        guaranteed_hours: filterDeletableItems(guaranteed_hours),
        rates: filterDeletableItems(rates),
        mileages: filterDeletableItems(mileages),
        travel_times: travelTimesPayload
      }

      createOrUpdate(payload)
    },
    [formStage, createOrUpdate, carerGuid, setFormStage, setSelectedContractModelGuid]
  )
}
