import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { grey } from "@mui/material/colors"
import testingId from "constants/testingId"

const EmptyData: React.FC<{ message: React.ReactNode }> = (props) => (
  <Box
    display="flex"
    justifyContent="space-between"
    p={3}
    m={3}
    mx={"auto"}
    alignItems="center"
    flexDirection="column"
    bgcolor={grey[100]}
    width={"100%"}
    data-cy={testingId.emptyDataBox}
    {...props}
  >
    <Typography variant="body1">{props.message}</Typography>
  </Box>
)
export default EmptyData
