import { Box, Grid } from "@mui/material"
import { FormSpyCustom } from "components/Form"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { queryStringToObject } from "lib/queryString"
import React, { memo, useState } from "react"
import { Form } from "react-final-form"
import { isEmpty } from "ramda"
import { Pagination } from "lib/types"
import { ClearFilterButton } from "components/ClearFilterButton"
import { FilterValues, formatFilterValues } from "lib/helpers/formatFilterValues"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"
import { DateField } from "components/Form/DateField"
import { StatusSelectField } from "components/Form/StatusSelectField"
import { FrequencySelectField } from "components/Form/FrequencySelectField"
import { useLocation } from "react-router-dom"
import { BillingFrequency } from "data/finance/contractModel/types"
import { StatusEnum } from "data/commonTypes"

interface InvoiceModelRateFilter {
  status?: StatusEnum
  start?: string
  end?: string
  frequency_type_alias?: BillingFrequency
}

interface OwnProps {
  pagination: Pagination
  resetPagination: () => void
}

export const InvoiceModelStandingChargeListFilter: React.FC<OwnProps> = memo(({ pagination, resetPagination }) => {
  const location = useLocation()

  const [filter, setFilter] = useState<InvoiceModelRateFilter>({
    ...queryStringToObject<InvoiceModelRateFilter>(location.search)
  })

  const onSubmit = (values: FilterValues<InvoiceModelRateFilter>) => {
    const formattedValues = formatFilterValues<InvoiceModelRateFilter>({
      values,
      startName: "start",
      endName: "end"
    })

    setFilter(formattedValues)
    resetPagination()
  }

  const clearFilter = () => {
    setFilter({})
    resetPagination()
  }

  useFilterLocationChange({ filter, pagination })

  return (
    <Form {...{ onSubmit, initialValues: filter, validate: validateFilterPeriodDates({ start: "start", end: "end" }) }}>
      {({ handleSubmit }) => (
        <form {...{ onSubmit: handleSubmit }}>
          <FormSpyCustom {...{ handleSubmit }} />
          <Grid container alignItems="center" spacing={1}>
            <Grid item md={3}>
              <DateField name="start" label="Start" />
            </Grid>
            <Grid item md={3}>
              <DateField name="end" label="End" />
            </Grid>
            <Grid item md={3}>
              <StatusSelectField withAny />
            </Grid>
            <Grid item md={3}>
              <FrequencySelectField label="Frequency Type" multiselect />
            </Grid>
          </Grid>
          <Box textAlign="right">
            <ClearFilterButton disabled={isEmpty(filter)} onClick={clearFilter} />
          </Box>
        </form>
      )}
    </Form>
  )
})

InvoiceModelStandingChargeListFilter.displayName = "InvoiceModelStandingChargeListFilter"
