import { DateTime } from "luxon"

export const getClientProfileDefaultValues = () => {
  return {
    hr: {
      start_date: DateTime.local().startOf("day")
    },
    preferences: {
      a_car_owner: 0,
      a_driver: 0,
      african: 0,
      arab: 0,
      asian_indian_pakistan_bangladesh: 0,
      asian_other: 0,
      black_african: 0,
      black_caribbean: 0,
      black_other: 0,
      cat_friendly: 0,
      caucasian_british: 0,
      caucasian_other: 0,
      dog_friendly: 0,
      happy_with_female_carers: 0,
      happy_with_male_carers: 0,
      mixed: 0,
      other: 0,
      smoking: 0
    },
    profile: {
      gender: "Unspecified",
      ethnicity: 0,
      marital_status: "Unknown",
      religion: 0,
      salutation: "Mr",
      sexual_orientation: "Unknown"
    },
    status: 19,
    regions: []
  }
}
