import { IconButton } from "@mui/material"
import React, { memo, MouseEvent, useCallback, useState } from "react"
import { useUnlockMutation } from "data/core/mutations"
import { LockOpenOutlined, LockOutlined } from "@mui/icons-material"

interface Props {
  unlocked: boolean
  carerVisitGuid: string
  clientGuid: string
}

export const UnlockButton = memo(({ carerVisitGuid, unlocked, clientGuid }: Props) => {
  UnlockButton.displayName = "UnlockButton"

  const { mutateAsync: unlock } = useUnlockMutation(clientGuid)
  const [animate, setAnimate] = useState(true)

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      if (window.confirm("Are you sure you want to unlock this visit?")) {
        unlock(carerVisitGuid)
      }
    },
    [unlock, carerVisitGuid]
  )

  return (
    <IconButton
      size="medium"
      onClick={onClick}
      onMouseEnter={() => setAnimate(true)}
      onMouseLeave={() => setAnimate(false)}
      sx={{
        backgroundColor: "transparent",
        "&:hover": {
          background: "none"
        }
      }}
    >
      {animate || unlocked ? (
        <LockOpenOutlined
          sx={{
            backgroundColor: "transparent",
            color: "crimson"
          }}
        />
      ) : (
        <LockOutlined
          sx={{
            backgroundColor: "transparent",
            color: "crimson"
          }}
        />
      )}
    </IconButton>
  )
})
