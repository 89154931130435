import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React, { memo, useMemo } from "react"
import testingId from "constants/testingId"

interface OwnProps {
  isInvoicePaymentService?: boolean
}

export const ServiceListHeader: React.FC<OwnProps> = memo(({ isInvoicePaymentService }) => {
  ServiceListHeader.displayName = "ServiceListHeader"

  const headers = useMemo(() => {
    const baseHeaders = ["Care type", "Price type", "Starts on", "Ends before", "Status"]

    if (isInvoicePaymentService) return ["Pay group", ...baseHeaders]
    return [...baseHeaders, "Has Overrides"]
  }, [isInvoicePaymentService])

  return (
    <TableHead>
      <TableRow data-cy={testingId.tableHeaderRow}>
        {headers.map((header) => (
          <TableCell data-cy={testingId.tableHeaderCell} align="left" key={header}>
            {header}
          </TableCell>
        ))}
        <TableCell data-cy={testingId.tableHeaderCell} align="left"></TableCell>
      </TableRow>
    </TableHead>
  )
})
