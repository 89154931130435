import { Box, Grid } from "@mui/material"
import { ModalWrapper } from "components"
import { Button, Select } from "components/Form"
import { SelectOption } from "components/Form/Select"
import { SelectDummy } from "components/Form/SelectDummy"
import { GUID_ZERO } from "constants/guid"
import testingId from "constants/testingId"
import { useAppointmentAssigneePutMutation } from "data/finance/appointment/mutations"
import { AppointmentAssignee, AppointmentAssigneeRequest } from "data/finance/appointment/types"
import { useSchema } from "data/schema/useSchema"
import validators from "lib/validators"
import { isNil } from "ramda"
import React, { memo, useCallback, useMemo } from "react"
import { Field, Form } from "react-final-form"

interface OwnProps {
  assignee: AppointmentAssignee
  destroyModal: () => void
  hasValidModel?: boolean
}

export const VisitDetailVariationModal: React.FC<OwnProps> = memo(({ assignee, destroyModal, hasValidModel }) => {
  const { VariationType, ContractServiceType } = useSchema(["VariationType", "ContractServiceType"])

  const { mutateAsync: putAssignee } = useAppointmentAssigneePutMutation({
    appointmentGuid: assignee.appointment_guid,
    userAppointmentGuid: assignee.carer_guid
  })

  const onSubmit = useCallback(
    (values: AppointmentAssigneeRequest) => {
      putAssignee(values)
      destroyModal()
    },
    [putAssignee, destroyModal]
  )

  const modelOptions: SelectOption[] = useMemo(
    () =>
      assignee.available_contracts.map(({ guid, title }) => ({
        value: guid,
        title
      })),
    [assignee]
  )

  const initialValues = useMemo(
    () => ({
      contract_guid: isNil(hasValidModel) ? GUID_ZERO : hasValidModel ? assignee.contract_guid : null,
      variation_guid: assignee.variation_guid,
      service_guid: assignee.service_guid,
      confirmed: assignee.confirmed
    }),
    [hasValidModel, assignee]
  )

  return (
    <ModalWrapper
      {...{
        title: "Confirmation, Variation & Service",
        destroyModal,
        width: 800,
        "data-cy": testingId.visit.detail.variation.modal
      }}
    >
      <Form {...{ onSubmit, initialValues }}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item md={4}>
                <Field
                  name="contract_guid"
                  label="Contract Model *"
                  component={Select}
                  options={modelOptions}
                  validate={validators.required}
                />
              </Grid>
              <Grid item md={4}>
                {VariationType ? (
                  <Field
                    name="variation_guid"
                    label="Variation *"
                    component={Select}
                    options={VariationType}
                    validate={validators.required}
                  />
                ) : (
                  <SelectDummy value="loading..." />
                )}
              </Grid>
              <Grid item md={4}>
                {ContractServiceType ? (
                  <Field
                    name="service_guid"
                    label="Service *"
                    component={Select}
                    options={ContractServiceType}
                    validate={validators.required}
                  />
                ) : (
                  <SelectDummy value="loading..." />
                )}
              </Grid>
              <Grid item md={4}>
                <Field
                  name="confirmed"
                  label="Status *"
                  component={Select}
                  options={[
                    { title: "Confirmed", value: true },
                    { title: "Unconfirmed", value: false }
                  ]}
                  validate={validators.required}
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" my={3}>
              <Button variant="text" fullWidth={false} onClick={destroyModal}>
                Cancel
              </Button>
              <Button
                type="submit"
                fullWidth={false}
                disabled={submitting}
                data-cy={testingId.visit.detail.variation.modalSaveButton}
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Form>
    </ModalWrapper>
  )
})

VisitDetailVariationModal.displayName = "VisitDetailVariationModal"
