import React from "react"
import { Box, Button, Typography } from "@mui/material"
import WarningIcon from "@mui/icons-material/Warning"
import { Divider } from "components"
import { Visit } from "models/Visit"
import { DateTime } from "luxon"
import { DateTimeFormat } from "lib/datetime"

interface Props {
  setEdit: () => void
  visit: Visit
  checkInTime?: DateTime
  checkOutTime?: DateTime
  submitting: boolean
}

const ConfirmationStep = ({ submitting, setEdit, visit, checkInTime, checkOutTime }: Props) => {
  return (
    <Box>
      <Box
        display="flex"
        sx={({ palette: { warning } }) => ({
          border: `1px solid ${warning.main}`,
          backgroundColor: warning.light,
          padding: "6px 16px"
        })}
        marginBottom={4}
      >
        <WarningIcon
          sx={({ palette: { warning } }) => ({
            icon: {
              color: warning.main,
              marginRight: 12
            }
          })}
        />
        <Typography variant="body2" color="textPrimary">
          The visit duration you&lsquo;re trying to save exceeds the scheduled duration by more than 1 hour.
        </Typography>
      </Box>
      <Typography sx={{ marginBottom: 32 }} variant="body2" color="textPrimary">
        Please double-check the start and end times before proceeding, as incorrect times could affect payroll
        processing.
      </Typography>
      <Typography variant="body1" color="textPrimary">
        Scheduled date & time
      </Typography>
      <Divider color="divider" withMargin={false} />
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" marginBottom={4} marginTop={1}>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            flex: "1 1 50%",
            marginTop: 8
          }}
        >
          Check-in
        </Typography>
        <Typography
          variant="body2"
          sx={{
            flex: "1 1 50%",
            marginTop: 8
          }}
          align="right"
        >
          {visit.start.toFormat(DateTimeFormat.DATE_TIME)}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            flex: "1 1 50%",
            marginTop: 8
          }}
        >
          Check-out
        </Typography>
        <Typography
          variant="body2"
          sx={{
            flex: "1 1 50%",
            marginTop: 8
          }}
          align="right"
        >
          {visit.end.toFormat(DateTimeFormat.DATE_TIME)}
        </Typography>
      </Box>
      <Typography variant="body1" color="textPrimary">
        Updated date & time
      </Typography>
      <Divider color="divider" withMargin={false} />
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" marginBottom={4} marginTop={2}>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            flex: "1 1 50%",
            marginTop: 8
          }}
        >
          Check-in
        </Typography>
        <Typography
          variant="body2"
          sx={{
            flex: "1 1 50%",
            marginTop: 8
          }}
          align="right"
        >
          {checkInTime
            ? checkInTime.toFormat(DateTimeFormat.DATE_TIME)
            : visit.check_in?.time.toFormat(DateTimeFormat.DATE_TIME)}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            flex: "1 1 50%",
            marginTop: 8
          }}
        >
          Check-out
        </Typography>
        <Typography
          variant="body2"
          sx={{
            flex: "1 1 50%",
            marginTop: 8
          }}
          align="right"
        >
          {checkOutTime
            ? checkOutTime.toFormat(DateTimeFormat.DATE_TIME)
            : visit.check_out?.time.toFormat(DateTimeFormat.DATE_TIME)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" m={3} mx={0}>
        <Button variant="outlined" fullWidth={false} onClick={setEdit}>
          Edit
        </Button>
        <Button disabled={submitting} variant="contained" type="submit" fullWidth={false}>
          Confirm & save
        </Button>
      </Box>
    </Box>
  )
}

export default ConfirmationStep
