import Table from "@mui/material/Table"
import TableContainer from "@mui/material/TableContainer"
import { BackButton, ContentContainer, Divider, Paginator } from "components"
import React, { memo, ReactNode, useCallback, useMemo, useState } from "react"
import { Form } from "react-final-form"
import { CreditingModal } from "../components"
import { TransactionListFilter } from "./components/TransactionListFilter"
import { useSelectableIdsDictionary } from "lib/hooks"
import { AddUserTransactionModal } from "../components/AddUserTransactionModal"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import { usePagination } from "components/handlers/usePagination"
import TransactionListHeader from "./components/TransactionListHeader"
import TransactionListMoney from "./components/TransactionListMoney"
import TransactionListAddClientButton from "./components/TransactionListAddClientButton"
import { TransactionListTableHead } from "./components/TransactionListTableHead"
import TransactionListTableBody from "./components/TransactionListTableBody"
import TransactionListSelectButtons from "./components/TransactionListSelectButtons"
import TransactionListActionButtons from "./components/TransactionListActionButtons"
import { useInvoiceProcessingTransactionListQuery } from "data/finance/invoiceProcessing/queries"
import { removeFromSearchString } from "lib/helpers/removeFromSearchString"
import TransactionGroupInvoicingModal from "./components/TransactionGroupInvoicingModal"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { useInvoiceModelQuery, usePeriodQuery } from "data/finance/contractModel/queries"
import { queryStringToObject } from "lib/queryString"
import Auth from "lib/Auth"
import { Permission } from "constants/permission"
import { useLocation, useParams } from "react-router-dom"
import { PATH } from "constants/path"
import { InvoiceTransactionFilter } from "./types"
import { InvoiceTransaction } from "data/finance/invoiceProcessing/types"

const TransactionList: React.FC = () => {
  const location = useLocation()
  const { periodGuid, calendarGuid, contractGuid } = useParams()
  const { data: invoiceModel } = useInvoiceModelQuery(contractGuid)

  const [addClientModal, setAddClientModal] = useState<ReactNode>(null)
  const [modal, setModal] = useState<ReactNode>(null)

  const { pagination, setPagination, resetPagination } = usePagination({ initialPageSize: 10 })
  const [filter, setFilter] = useState<InvoiceTransactionFilter>({
    ...queryStringToObject<InvoiceTransactionFilter>(location?.search)
  })

  const { data: period } = usePeriodQuery({
    modelType: ContractModelType.INVOICE,
    contractGuid,
    calendarGuid,
    periodGuid
  })

  const { data: transactionsRaw, refetch } = useInvoiceProcessingTransactionListQuery({
    periodGuid,
    search: removeFromSearchString({ search: location?.search, remove: ["page", "page_size"] })
  })

  const transactions: InvoiceTransaction[] = useMemo(
    () => transactionsRaw?.sort((a, b) => a.owner_guid.localeCompare(b.owner_guid)) || [],
    [transactionsRaw]
  )

  const { selectableIdsForCrediting, selectableIdsForInvoicing, allSelectableIds } = useMemo(() => {
    const selectableIdsForCrediting: string[] = []
    const selectableIdsForInvoicing: string[] = []
    const allSelectableIds: string[] = []
    transactions.forEach((transaction: InvoiceTransaction) => {
      if (transaction.is_creditable) {
        selectableIdsForCrediting.push(transaction.guid)
        allSelectableIds.push(transaction.guid)
      }
      if (transaction.is_processable) {
        selectableIdsForInvoicing.push(transaction.guid)
        allSelectableIds.push(transaction.guid)
      }
    })

    return {
      selectableIdsForCrediting,
      selectableIdsForInvoicing,
      allSelectableIds
    }
  }, [transactions])

  const { toggleId, toggleSubset, selectableIds, getSelectedAsStringArr } = useSelectableIdsDictionary(allSelectableIds)

  const destroyModal = useCallback(() => setModal(null), [])

  const onProcessed = useCallback(() => {
    refetch()
    destroyModal()
  }, [destroyModal, refetch])

  const submitForInvoicing = useCallback(() => {
    setModal(
      <TransactionGroupInvoicingModal
        periodGuid={periodGuid as string}
        guids={getSelectedAsStringArr()}
        destroyModal={onProcessed}
      />
    )
  }, [getSelectedAsStringArr, onProcessed, periodGuid])

  const submitForCrediting = useCallback(() => {
    setModal(
      <CreditingModal
        guids={getSelectedAsStringArr()}
        onCancel={destroyModal}
        periodGuid={periodGuid as string}
        isTransaction={true}
        modelType={ContractModelType.INVOICE}
        onOk={onProcessed}
      />
    )
  }, [destroyModal, getSelectedAsStringArr, onProcessed, periodGuid])

  const onClientAdded = useCallback(() => {
    refetch()
    setAddClientModal(null)
  }, [refetch])

  if (!invoiceModel || !period) return null

  const handleAddClientButton = () => {
    setAddClientModal(
      <AddUserTransactionModal
        modelType={ContractModelType.INVOICE}
        periodGuid={periodGuid as string}
        onCancel={() => {
          setAddClientModal(null)
        }}
        onOk={onClientAdded}
      />
    )
  }

  const hasDeveloperPermission = Auth.hasPermission([Permission.DEVELOPER_EDIT])
  const hasRewindPermission = Auth.hasPermission([Permission.FINANCE_REWIND_EDIT])

  const hasCreditableTransactions = transactions.some((t) => t.is_creditable)
  const hasFiles = transactions.some((t) => !!t.reference)
  const hasAlerts = transactions.some((t) => t.is_alerting)

  return (
    <ContentContainer data-cy={testingId.invoiceProcessingTransactionList.screen}>
      <BackButton fallbackUrl={PATH.FINANCE.INVOICE_PROCESSING} />
      <TransactionListHeader {...{ period, contract: invoiceModel }} />
      <TransactionListMoney {...{ transactions }} />
      <TransactionListFilter {...{ pagination, resetPagination, filter, setFilter }} />
      <Divider color="divider" />
      <TransactionListAddClientButton onClick={handleAddClientButton} />
      <RequestLoaderWrapper>
        <Form onSubmit={() => ({})}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TableContainer>
                <Table stickyHeader>
                  <TransactionListTableHead
                    {...{
                      setFilter,
                      hasDeveloperPermission,
                      hasRewindPermission,
                      hasCreditableTransactions,
                      hasFiles,
                      hasAlerts
                    }}
                  />
                  <TransactionListTableBody
                    {...{
                      transactions,
                      toggleId,
                      selectableIds,
                      selectedIds: getSelectedAsStringArr(),
                      location,
                      pagination,
                      hasCreditableTransactions,
                      hasFiles,
                      hasAlerts,
                      hasDeveloperPermission,
                      hasRewindPermission,
                      refetch
                    }}
                  />
                </Table>
                <Paginator {...{ setPagination, count: transactions.length, initialPageSize: 10 }} />
              </TableContainer>
              <TransactionListSelectButtons
                {...{ toggleSubset, selectableIdsForCrediting, selectableIdsForInvoicing }}
              />
              <TransactionListActionButtons
                {...{
                  selectableIdsForCrediting,
                  selectableIdsForInvoicing,
                  selectedIds: getSelectedAsStringArr(),
                  submitForCrediting,
                  submitForInvoicing
                }}
              />
            </form>
          )}
        </Form>
      </RequestLoaderWrapper>
      {modal !== null && modal}
      {addClientModal !== null && addClientModal}
    </ContentContainer>
  )
}

export default memo(TransactionList)
