import { FinanceApi } from "api"
import { ApiDownloadLink } from "data/download/types"
import { enabledByMandatoryUrlParams, setDayBorders } from "data/helpers"
import { removeFromSearchString } from "lib/helpers/removeFromSearchString"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { buildUserContractFileListUrl } from "./helpers"
import { ApiUserContractFile, UserContractType } from "./types"

export enum QueryKeyParts {
  USER = "user",
  CONTRACT = "contract",
  LIST = "list",
  FILE = "file",
  DOWNLOAD_LINK = "download_link"
}

export const queryKeys = {
  getUserContractFileListKey: ({
    userGuid,
    contractGuid,
    newSearch,
    userType
  }: {
    userGuid?: string
    contractGuid?: string
    newSearch?: string
    userType: UserContractType
  }): QueryKey => [
    QueryKeyParts.USER,
    QueryKeyParts.CONTRACT,
    QueryKeyParts.FILE,
    QueryKeyParts.LIST,
    { userGuid, contractGuid, newSearch, userType }
  ],
  getUserContractFileDownloadKey: ({
    userGuid,
    contractGuid,
    fileGuid,
    userType
  }: {
    userGuid?: string
    contractGuid?: string
    fileGuid?: string
    userType: UserContractType
  }): QueryKey => [
    QueryKeyParts.USER,
    QueryKeyParts.CONTRACT,
    QueryKeyParts.FILE,
    QueryKeyParts.DOWNLOAD_LINK,
    {
      userGuid,
      contractGuid,
      fileGuid,
      userType
    }
  ]
}

export const useUserContractFileListQuery = ({
  userGuid,
  contractGuid,
  search,
  userType
}: {
  userGuid?: string
  contractGuid?: string
  search?: string
  userType: UserContractType
}): UseQueryResult<ApiUserContractFile[], Error> => {
  const trimmedSearch = removeFromSearchString({ search, remove: ["tabType"] })
  const newSearch = setDayBorders({ search: trimmedSearch, from: "start", to: "end" })

  return useQuery({
    queryKey: queryKeys.getUserContractFileListKey({ userGuid, contractGuid, newSearch, userType }),
    queryFn: async () =>
      FinanceApi.get(`${buildUserContractFileListUrl({ userGuid, contractGuid, userType })}${newSearch}`),
    ...enabledByMandatoryUrlParams([userGuid, contractGuid, newSearch, userType])
  })
}

export const useUserContractFileDownloadQuery = ({
  userGuid,
  contractGuid,
  fileGuid,
  enabled = true,
  userType
}: {
  userGuid?: string
  contractGuid?: string
  fileGuid?: string
  enabled?: boolean
  userType: UserContractType
}): UseQueryResult<ApiDownloadLink, Error> => {
  return useQuery({
    queryKey: queryKeys.getUserContractFileDownloadKey({ userGuid, contractGuid, fileGuid, userType }),
    queryFn: async () =>
      FinanceApi.get(
        `${buildUserContractFileListUrl({
          userGuid,
          contractGuid,
          userType
        })}/${fileGuid}/download`
      ),
    ...enabledByMandatoryUrlParams([userGuid, contractGuid, fileGuid, enabled, userType])
  })
}
