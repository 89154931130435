import React, { memo } from "react"
import { FormSectionHeader, TextField } from "components/Form"
import { Grid } from "@mui/material"
import testingId from "constants/testingId"
import { Field } from "react-final-form"
import { DateField } from "components/Form/DateField"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { BranchFeatures } from "lib/hooks/branchInfo/types"

export const CarerProfileCustomFields = memo(() => {
  const { hasBranchPermission } = useBranchPermissions()

  const showScotlandFields = hasBranchPermission(BranchFeatures.SCOTLAND_FIELDS)
  const showWalesFields = hasBranchPermission(BranchFeatures.WALES_FIELDS)

  return (
    <>
      {(showScotlandFields || showWalesFields) && (
        <FormSectionHeader
          title="Local authorities requirements"
          data-cy={testingId.carer.create.localAuthoritiesRequirements}
        />
      )}
      {showScotlandFields && (
        <Grid container spacing={8} data-cy={testingId.carer.create.scotlandFields.section}>
          <Grid item md={6}>
            <Field
              name="scotland_fields.social_services_council_id"
              label="Scottish Social Services Council (SSSC) ID"
              component={TextField}
            />
            <Field
              name="scotland_fields.disclosure_pvg_issue"
              label="Scottish Disclosure Scotland PVG Issue"
              component={TextField}
            />
            <Field
              name="scotland_fields.disclosure_pvg_membership"
              label="Scottish Disclosure Scotland PVG Membership"
              component={TextField}
            />
            <DateField
              name="scotland_fields.disclosure_expiring"
              label="Scottish Disclosure Scotland expiration date"
            />
          </Grid>
          <Grid item md={6}>
            <Field name="scotland_fields.nmc_id" label="Scottish NMC Id" component={TextField} />
            <DateField name="scotland_fields.nmc_start" label="Scottish NMC Start Date" />
            <DateField name="scotland_fields.nmc_end" label="Scottish NMC End Date" />
          </Grid>
        </Grid>
      )}
      {showWalesFields && (
        <Grid item md={6} data-cy={testingId.carer.create.walesField.section}>
          <Field
            name="wales_fields.social_care_registration_id"
            label="Wales Social Cares Registration ID"
            component={TextField}
          />
        </Grid>
      )}
    </>
  )
})

CarerProfileCustomFields.displayName = "CarerProfileCustomFields"
