import React from "react"
import { SvgIconOwnProps } from "@mui/material"

export const ErrorIcon = (props: SvgIconOwnProps & React.SVGProps<SVGSVGElement>): React.JSX.Element | null => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.145 2H7.85503L2 7.85558V16.1444L7.85503 22H16.145L22 16.1444V7.85558L16.145 2ZM19 10H5V14H19V10Z"
        fill="currentColor"
      />
    </svg>
  )
}
