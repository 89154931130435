import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { Button, FormSpyCustom, Select, TextField } from "components/Form"
import React, { Dispatch, memo, SetStateAction, useCallback, useState } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"
import testingId from "constants/testingId"
import InvoiceExtrasModal from "pages/Finance/InvoiceProcessing/components/InvoiceExtrasModal"
import Auth from "lib/Auth"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { TransactionDetailType } from "pages/Finance/types"
import { FormApi } from "final-form"
import { ClearFilterButton } from "components/ClearFilterButton"
import { Permission } from "constants/permission"
import { BasicPeriod } from "lib/types"
import { useSchema } from "data/schema/useSchema"
import { InvoiceTransaction } from "data/finance/invoiceProcessing/types"
import { InvoiceTransactionChargeFilter } from "../types"
import { CalendarPeriod } from "data/finance/contractModel/types"

export const defaultChargeListFilter: InvoiceTransactionChargeFilter = {
  tab: TransactionDetailType.EXTRA
}

interface Props {
  transaction: InvoiceTransaction
  period: CalendarPeriod
  contract?: BasicPeriod
  refetchTransaction: () => void
  chargeFilter: InvoiceTransactionChargeFilter
  setChargeFilter: Dispatch<SetStateAction<InvoiceTransactionChargeFilter>>
  resetAllCreditable: () => void
  resetAllDeletable: () => void
}

export const TransactionDetailChargesFilter: React.FC<Props> = memo(
  ({
    transaction,
    period,
    resetAllCreditable,
    resetAllDeletable,
    contract,
    refetchTransaction,
    chargeFilter,
    setChargeFilter
  }) => {
    const { TransactionCharge } = useSchema(["TransactionCharge"])

    const onSubmit = useDebouncedCallback((values: InvoiceTransactionChargeFilter) => {
      resetAllCreditable()
      resetAllDeletable()
      setChargeFilter({ ...defaultChargeListFilter, ...values })
    }, 300)

    useFilterLocationChange({ filter: chargeFilter })

    const canAddExtra = !transaction.reference && Auth.hasPermission([Permission.FINANCE_PROCESSING_CHARGES_EDIT])
    const [modal, setModal] = useState<React.ReactNode>(null)

    const onFilterClearClick = useCallback(
      (form: FormApi<InvoiceTransactionChargeFilter, Partial<InvoiceTransactionChargeFilter>>) => () => {
        form.reset()
        setChargeFilter(defaultChargeListFilter)
      },
      [setChargeFilter]
    )

    const onExtraAddClick = useCallback(() => {
      setModal(
        <InvoiceExtrasModal
          {...{
            transaction,
            period,
            destroyModal: () => setModal(null),
            contract,
            refetchTransaction
          }}
        />
      )
    }, [contract, period, refetchTransaction, transaction])

    return (
      <>
        <Form {...{ onSubmit, initialValues: chargeFilter }}>
          {({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <FormSpyCustom {...{ handleSubmit }} />
              <Grid container alignItems="center" spacing={1}>
                <Grid item md={4}>
                  <Field name="reference_owner_name" label="Search by carer name" component={TextField} />
                </Grid>
                <Grid item md={4}>
                  <Field
                    name="charge_type_alias"
                    label="Extra Type"
                    component={Select}
                    options={TransactionCharge}
                    multiselect
                  />
                </Grid>
                <Grid item md={4}>
                  <Box textAlign="right">
                    <ClearFilterButton
                      onClick={onFilterClearClick(form)}
                      disabled={Object.keys(chargeFilter).length === 0}
                    />
                  </Box>
                </Grid>
                {canAddExtra && (
                  <Grid item md={2}>
                    <Button
                      color="primary"
                      fullWidth={true}
                      onClick={onExtraAddClick}
                      data-cy={testingId.paymentProcessingTransactionDetail.addExtraButton}
                    >
                      Add extra
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          )}
        </Form>
        {modal !== null && modal}
      </>
    )
  }
)

TransactionDetailChargesFilter.displayName = "TransactionDetailChargesFilter"
