import React from "react"
import { Grid } from "@mui/material"
import { CancelButton, Button } from "components/Form"
import { StickyBox } from "components"
import BottomErrorMessage from "components/BottomErrorMessage"

export interface StepsFooterProps {
  buttonName: string
  pristine: boolean
  submitting?: boolean
  errorMessage?: string
  cancelNavTo?: string | number
}

export const StepsFooter = ({ buttonName, errorMessage, pristine, submitting, cancelNavTo }: StepsFooterProps) => (
  <StickyBox>
    <Grid container spacing={8}>
      <Grid item md={6}>
        {errorMessage ? <BottomErrorMessage message={errorMessage} /> : null}
      </Grid>
    </Grid>

    <CancelButton disabled={pristine} pristine={pristine} navigateUrl={cancelNavTo} />
    <Button
      sx={(theme) => ({ margin: theme.spacing(0, 0, 0, 3) })}
      type="submit"
      fullWidth={false}
      disabled={submitting || pristine}
    >
      {buttonName}
    </Button>
  </StickyBox>
)
