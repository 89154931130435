import { FinanceApi } from "api"
import { enabledByMandatoryUrlParams, setDayBorders } from "data/helpers"
import { removeFromSearchString } from "lib/helpers/removeFromSearchString"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { Task } from "../task/types"

export enum QueryKeyParts {
  PAYMENT_TASK = "payment_task",
  LIST = "list"
}

export const queryKeys = {
  getPaymentTaskList: ({ search }: { search?: string }): QueryKey => [
    QueryKeyParts.PAYMENT_TASK,
    QueryKeyParts.LIST,
    { search }
  ]
}

export const usePaymentTaskListQuery = ({
  search,
  enabled = true,
  enableRefetchInterval = false
}: {
  search?: string
  enabled?: boolean
  enableRefetchInterval?: boolean
}): UseQueryResult<Task[], Error> => {
  const searchString = removeFromSearchString({ search, remove: ["modelType"] })
  const newSearch = setDayBorders({ search: searchString, from: "from", to: "to" })

  return useQuery({
    queryKey: queryKeys.getPaymentTaskList({ search: newSearch }),
    queryFn: async () => FinanceApi.get(`finance/payment-tasks${newSearch}`),
    ...enabledByMandatoryUrlParams([enabled, newSearch]),
    refetchInterval: enableRefetchInterval ? 20000 : false
  })
}
