import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { Delete } from "@mui/icons-material"
import React, { memo } from "react"
import { Field, FormSpy, FormSpyRenderProps } from "react-final-form"
import { Button, Checkbox, FormSectionHeader, TextField } from "components/Form"
import { grey } from "@mui/material/colors"
import testingId from "constants/testingId"
import { FieldArray } from "react-final-form-arrays"
import { CarerContractFormValues } from "../types"
import { DateField } from "components/Form/DateField"
import { FrequencySelectField } from "components/Form/FrequencySelectField"
import { StatusEnum } from "data/commonTypes"

export const CarerContractDetailStepTwoGuaranteedHours = memo(() => (
  <FormSpy subscription={{ values: true }}>
    {({ values }: FormSpyRenderProps<CarerContractFormValues, {}>) => (
      <FieldArray name="guaranteed_hours">
        {({ fields }) => (
          <>
            <FormSectionHeader title="Guaranteed hours" />
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "20px" }}>
                    <Delete />
                  </TableCell>
                  <TableCell sx={{ width: "25%" }}>Start Date</TableCell>
                  <TableCell sx={{ width: "25%" }}>Ends before</TableCell>
                  <TableCell sx={{ width: "130px" }}>Hours</TableCell>
                  <TableCell>Frequency</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((name, index) => {
                  const field = fields.value[index]
                  const canBeDeleted = field.status === StatusEnum.FUTURE || !field.guid
                  const isToBeDeleted = field.delete

                  return (
                    <TableRow
                      key={index}
                      style={{
                        opacity: isToBeDeleted ? 0.3 : 1,
                        backgroundColor: isToBeDeleted ? grey[100] : "transparent"
                      }}
                    >
                      <TableCell>
                        {canBeDeleted ? (
                          <Field<boolean> type="checkbox" label="" component={Checkbox} name={`${name}.delete`} />
                        ) : null}
                      </TableCell>
                      <TableCell>
                        <DateField
                          name={`${name}.start`}
                          label="Starts on *"
                          minDate={values.start ?? undefined}
                          maxDate={values.current_role || !values.end ? undefined : values.end.minus({ days: 1 })}
                          disabled={isToBeDeleted}
                        />
                      </TableCell>
                      <TableCell>
                        <DateField
                          name={`${name}.end`}
                          label="Ends before"
                          minDate={values.start ? values.start.plus({ days: 1 }) : undefined}
                          maxDate={values.current_role || !values.end ? undefined : values.end}
                          disabled={isToBeDeleted}
                        />
                      </TableCell>
                      <TableCell>
                        <Field name={`${name}.hours`} label="Hours *" component={TextField} disabled={isToBeDeleted} />
                      </TableCell>
                      <TableCell>
                        <FrequencySelectField
                          name={`${name}.frequency_type_alias`}
                          label="Frequency *"
                          disabled={isToBeDeleted}
                        />
                      </TableCell>
                      <TableCell>{field.status}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
              <Button
                fullWidth={false}
                data-cy={testingId.contractModel.services.addRowButton}
                onClick={() => fields.push({})}
              >
                Add new row
              </Button>
            </Box>
          </>
        )}
      </FieldArray>
    )}
  </FormSpy>
))

CarerContractDetailStepTwoGuaranteedHours.displayName = "CarerContractDetailStepTwoGuaranteedHours"
