import React, { memo } from "react"
import { Box } from "@mui/material"
import { grey } from "@mui/material/colors"
import { showFormattedDatePeriod } from "lib/datetime"
import { DateTime } from "luxon"

interface Props {
  period?: { start?: DateTime; end?: DateTime | null }
}

export const DateSpan = memo(({ period }: Props) => {
  DateSpan.displayName = "DateSpan"

  return period ? (
    <Box sx={{ color: grey[400] }} component="span">
      {showFormattedDatePeriod(period)}
    </Box>
  ) : null
})
