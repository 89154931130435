import { Box, Divider, Grid, Typography } from "@mui/material"
import { StickyBox } from "components"
import { Button, CancelButton, TextField } from "components/Form"
import { useUnitCreateMutation, useUnitUpdateMutation } from "data/finance/unit/mutations"
import { Unit, UnitRequest } from "data/finance/unit/types"
import { ProfileMode } from "lib/types"
import validators, { composeValidators } from "lib/validators"
import React, { memo } from "react"
import { Field, Form } from "react-final-form"

interface OwnProps {
  mode: ProfileMode
  unit?: Unit
}

const useSubmitHandler = (mode: ProfileMode) => {
  const { mutate: createUnit } = useUnitCreateMutation()
  const { mutate: updateUnit } = useUnitUpdateMutation()

  if (mode === "create") {
    return (unit: UnitRequest) => {
      createUnit({ unit })
    }
  } else {
    return (unit: Unit) => {
      updateUnit({ unit })
    }
  }
}

const Item = ({ children }: { children: React.JSX.Element }): React.JSX.Element => (
  <Grid {...{ item: true, md: 6 }}>{children}</Grid>
)

export const UnitProfile: React.FC<OwnProps> = memo(({ mode, unit }) => {
  const onSubmit = useSubmitHandler(mode)

  return (
    <Box>
      <Form {...{ onSubmit, initialValues: unit }}>
        {({ handleSubmit, pristine, submitting }) => (
          <form {...{ onSubmit: handleSubmit }}>
            <Box {...{ alignItems: "center", spacing: 1, my: 2 }}>
              <Item>
                <Field
                  name="name"
                  label="Name *"
                  component={TextField}
                  validate={composeValidators([validators.required, validators.maxLength()])}
                />
              </Item>
              <Item>
                <Field
                  name="email"
                  label="Email *"
                  component={TextField}
                  validate={composeValidators([validators.required, validators.email, validators.maxLength()])}
                />
              </Item>
              <Item>
                <Field name="phone" label="Phone Number" component={TextField} validate={validators.phone} />
              </Item>
            </Box>
            <Typography variant="h6">Address</Typography>
            <Divider
              sx={{
                bgcolor: "divider",
                mt: 0.5,
                mb: 2
              }}
            />
            <Box {...{ alignItems: "center", spacing: 1, mb: 2 }}>
              <Item>
                <Field
                  name="address"
                  label="Address Line 1 *"
                  component={TextField}
                  validate={composeValidators([validators.required, validators.maxLength()])}
                />
              </Item>
              <Item>
                <Field
                  name="additional_address"
                  label="Address Line 2"
                  component={TextField}
                  validate={validators.maxLength()}
                />
              </Item>
              <Item>
                <Field
                  name="city"
                  label="City *"
                  component={TextField}
                  validate={composeValidators([validators.required, validators.maxLength()])}
                />
              </Item>
              <Item>
                <Field
                  name="county"
                  label="County *"
                  component={TextField}
                  validate={composeValidators([validators.required, validators.maxLength()])}
                />
              </Item>
              <Item>
                <Field
                  name="postcode"
                  label="Postcode *"
                  component={TextField}
                  validate={composeValidators([validators.required, validators.maxLength()])}
                />
              </Item>
              <Item>
                <Field
                  name="country"
                  label="Country *"
                  component={TextField}
                  validate={composeValidators([validators.required, validators.maxLength()])}
                />
              </Item>
            </Box>
            <StickyBox>
              <CancelButton {...{ disabled: submitting, pristine, navigateUrl: "/units" }} />
              <Button {...{ type: "submit", fullWidth: false, disabled: submitting || pristine }}>Save changes</Button>
            </StickyBox>
          </form>
        )}
      </Form>
    </Box>
  )
})

UnitProfile.displayName = "UnitProfile"
