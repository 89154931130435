import { Box, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import React, { Dispatch, memo, PropsWithChildren, SetStateAction } from "react"
import { ArrowDownward, ArrowUpward } from "@mui/icons-material"

interface OwnProps {
  title: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const RateUpliftTableModelWrapper: React.FC<PropsWithChildren<OwnProps>> = memo(
  ({ title, children, open, setOpen }) => {
    RateUpliftTableModelWrapper.displayName = "RateUpliftTableModelWrapper"

    const handleOpenClick = () => setOpen((val) => !val)

    return (
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 5,
          padding: 2,
          boxShadow: `0 0 20px 0 ${grey[300]}`,
          borderRadius: 1
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              flexGrow: 1
            }}
          >
            <Typography
              variant="h6"
              color="primary"
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontSize: "1.1rem",
                textTransform: "uppercase"
              })}
            >
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              padding: "10px",
              cursor: "pointer",
              alignSelf: "center"
            }}
            onClick={handleOpenClick}
          >
            {open ? <ArrowUpward /> : <ArrowDownward />}
          </Box>
        </Box>
        {children}
      </Box>
    )
  }
)
