import {
  ClickAwayListener,
  Fade,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  PopperProps
} from "@mui/material"
import React, { memo, useCallback, useMemo, useState } from "react"
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined"
import { AppointmentType } from "constants/appointmentType"
import { AppointmentSearch } from "data/finance/invoiceSearch/types"
import { useInvoiceTransactionItemNoChargeMutation } from "data/finance/invoiceProcessing/mutations"
import { useNavigate } from "react-router-dom"

interface OwnProps {
  appointment: AppointmentSearch
  isInvoiceModel: boolean
  refetch: () => void
}

export const AppointmentSearchListTableItemMenu: React.FC<OwnProps> = memo(
  ({ appointment, isInvoiceModel, refetch }) => {
    AppointmentSearchListTableItemMenu.displayName = "AppointmentSearchListTableItemMenu"

    const navigate = useNavigate()
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<PopperProps["anchorEl"]>(null)

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()

        setAnchorEl(event.currentTarget)
        setMenuOpen(true)
      },
      [setAnchorEl, setMenuOpen]
    )

    const handleClickAway = useCallback(() => {
      setMenuOpen(false)
    }, [setMenuOpen])

    const transactionUrl = useMemo(
      () =>
        `/finance/${isInvoiceModel ? "invoice-processing" : "payment-processing"}/${appointment.calendar_guid}/${
          appointment.calendar_period_guid
        }/${appointment.contract_guid}/${appointment.transaction_guid}`,
      [appointment, isInvoiceModel]
    )

    const handleCalculationClick = useCallback(() => {
      setMenuOpen(false)
      if (appointment.appointment_type === AppointmentType.VISIT) {
        navigate(`${transactionUrl}/${appointment.guid}`)
      }
      if (appointment.appointment_type === AppointmentType.SHIFT) {
        navigate(`${transactionUrl}/shift/${appointment.guid}`)
      }
    }, [appointment, transactionUrl, setMenuOpen, navigate])

    const handleSituClick = useCallback(() => {
      setMenuOpen(false)
      navigate(transactionUrl)
    }, [transactionUrl, setMenuOpen, navigate])

    const { mutateAsync: updateChargeable } = useInvoiceTransactionItemNoChargeMutation({ onOk: () => refetch() })

    const handleChargeableClick = useCallback(
      () =>
        updateChargeable({
          transactionGuid: appointment.transaction_guid,
          periodGuid: appointment.calendar_period_guid,
          itemGuid: appointment.guid,
          chargeable: !!appointment.is_chargeable
        }),
      [appointment, updateChargeable]
    )

    return (
      <>
        <IconButton aria-label="edit" onClick={handleClick}>
          <MoreVertOutlinedIcon />
        </IconButton>
        <Popper
          {...{
            open: isMenuOpen,
            anchorEl,
            placement: "bottom",
            transition: true
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} {...{ timeout: 350 }}>
              <Paper>
                <ClickAwayListener {...{ onClickAway: handleClickAway }}>
                  <List {...{ component: "nav" }}>
                    {appointment.is_calculated && (
                      <ListItemButton onClick={handleCalculationClick}>
                        <ListItemText primary="View Calculation" />
                      </ListItemButton>
                    )}
                    <ListItemButton onClick={handleSituClick}>
                      <ListItemText primary="View in Situ" />
                    </ListItemButton>
                    {isInvoiceModel ? (
                      <ListItemButton onClick={handleChargeableClick}>
                        <ListItemText primary={appointment.is_chargeable ? "Make Non-Chargeable" : "Make Chargeable"} />
                      </ListItemButton>
                    ) : null}
                  </List>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </>
    )
  }
)
