import React, { memo } from "react"
import { FormSectionHeader, Checkbox } from "components/Form"
import Grid from "@mui/material/Grid"
import { Field, FormSpy } from "react-final-form"
import DatesMessageHelp from "components/DatesMessageHelp"
import { DateField } from "components/Form/DateField"

export const CarerContractDetailStepTwoDate: React.FC = memo(() => {
  return (
    <>
      <FormSectionHeader title="Payment Details" />
      <DatesMessageHelp messageType="E" />
      <Grid container spacing={8}>
        <Grid item md={6}>
          <DateField name="start" label="Starts on" required />
        </Grid>
        <Grid item md={6}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => {
              return <DateField name="end" label="Ends before" disabled={values.current_role} />
            }}
          </FormSpy>
          <Field<boolean> type="checkbox" label="Current role" component={Checkbox} name="current_role" />
        </Grid>
      </Grid>
    </>
  )
})

CarerContractDetailStepTwoDate.displayName = "CarerContractDetailStepTwoDate"
