/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import MenuItem from "@mui/material/MenuItem"
import { MenuProps, NoticeProps } from "react-select/src/components/Menu"
import { OptionProps } from "react-select/src/components/Option"
import { PlaceholderProps } from "react-select/src/components/Placeholder"
import { SingleValueProps } from "react-select/src/components/SingleValue"
import { OptionType } from "./Autocomplete"

function NoOptionsMessage(props: NoticeProps<OptionType, false>) {
  return (
    <Typography
      sx={(theme) => ({ padding: theme.spacing(1, 2) })}
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function Option(props: OptionProps<OptionType, false>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

type MuiPlaceholderProps = Omit<PlaceholderProps<OptionType, false>, "innerProps"> &
  Partial<Pick<PlaceholderProps<OptionType, false>, "innerProps">>
function Placeholder(props: MuiPlaceholderProps) {
  const { innerProps = {}, children } = props
  return (
    <Typography color="textSecondary" {...innerProps}>
      {children}
    </Typography>
  )
}

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography
      sx={{ fontSize: 16, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function Menu(props: MenuProps<OptionType, false>) {
  return (
    <Paper
      square
      sx={(theme) => ({
        position: "absolute",
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0
      })}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

const useSingleSelect = (props: any, componentProps: any) => {
  const { defaultOptions, input } = props
  let _defaultOptions: Array<OptionType> = []
  if (defaultOptions) {
    _defaultOptions = Object.keys(defaultOptions).map((key: string) => {
      return { value: key, label: defaultOptions[key].name }
    })
  }

  const _defaultValue = _defaultOptions.length
    ? _defaultOptions.filter((item: any) => item.value === input.value)
    : null

  return {
    configProps: {
      onChange: (value: any) => {
        if (value) input.onChange(value.value)
        else input.onChange("")
      },
      menuPlacement: "auto",
      menuPosition: "fixed",
      menuPortalTarget: document.body,
      isMulti: false,
      components: {
        Menu,
        NoOptionsMessage,
        Option,
        Placeholder,
        SingleValue,
        ...componentProps
      },
      value: _defaultValue,
      defaultOptions: _defaultOptions
    }
  }
}

export default useSingleSelect
