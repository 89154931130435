import { GENERAL_ERROR_MESSAGE } from "api/instance"
import { PATH } from "constants/path"
import { useInvoiceGroupUpdateMutation } from "data/core/invoiceGroup/mutations"
import { useInvoiceModelCreateMutation, useInvoiceModelUpdateMutation } from "data/finance/contractModel/mutations"
import { ContractModel, ContractModelRequest, InvoiceModel } from "data/finance/contractModel/types"
import { FORM_ERROR } from "final-form"
import { BranchFeatures } from "lib/hooks/branchInfo/types"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useUIStore } from "stores"

interface InvoiceGroupApiPayloadProps {
  response: InvoiceModel
  payload: ContractModelRequest
}

const createInvoiceGroupApiPayload = ({ response, payload }: InvoiceGroupApiPayloadProps) => ({
  title: response.title,
  guid: response.guid,
  is_Cm2000: !!payload.cm2000_enabled
})

export const useInvoiceModelSubmitHandler = (): ((
  contractModel: ContractModel | undefined,
  payload: ContractModelRequest
) => Promise<
  | {
      "FINAL_FORM/form-error": string
    }
  | undefined
>) => {
  const { hasBranchPermission } = useBranchPermissions()
  const hasBranchPermissionCM2000 = hasBranchPermission(BranchFeatures.CM2000)

  const { mutate: updateInvoiceGroup } = useInvoiceGroupUpdateMutation()
  const { mutateAsync: updateContract } = useInvoiceModelUpdateMutation()
  const { mutateAsync: createContract } = useInvoiceModelCreateMutation()

  const navigate = useNavigate()
  const showErrorMessage = useUIStore((state) => state.showErrorMessage)

  return useCallback(
    async (contractModel, payload) => {
      if (contractModel?.guid) {
        try {
          const response = await updateContract({ payload })
          if (response && hasBranchPermissionCM2000) {
            updateInvoiceGroup(createInvoiceGroupApiPayload({ response, payload }))
          }
        } catch (apiError: any) {
          showErrorMessage(GENERAL_ERROR_MESSAGE, { apiError })

          const errorMessage: string | undefined = apiError?.response?.data?.exception?.message
          if (errorMessage && errorMessage.includes("EXCEEDS_CHILD")) {
            return {
              [FORM_ERROR]:
                "Something (service/rule/calendar) using this invoice model starts sooner or ends later than this model's start/end date. Change this model's start/end date."
            }
          }
        }
      } else {
        try {
          const response = await createContract({ payload })
          if (response && hasBranchPermissionCM2000) {
            updateInvoiceGroup(createInvoiceGroupApiPayload({ response, payload }))
          }
          if (response?.guid) {
            navigate(`${PATH.FINANCE.INVOICE_MODELS}/${response.guid}`)
          }
        } catch (apiError: any) {
          showErrorMessage(GENERAL_ERROR_MESSAGE, { apiError })
        }
      }
    },
    [updateInvoiceGroup, updateContract, createContract, hasBranchPermissionCM2000, navigate, showErrorMessage]
  )
}
