import React, { memo } from "react"
import { FormSectionHeader, Select, TextField } from "components/Form"
import Grid from "@mui/material/Grid"
import { Field, FormSpy } from "react-final-form"
import testingId from "constants/testingId"
import validators, { composeValidators, validateCommonNumber } from "lib/validators"
import { useSchema } from "data/schema/useSchema"

export const ClientContractModelSettings: React.FC = memo(() => {
  const { UserContractFinanceOutput, UserContractFinanceOutputDelivery, UserContractFinancePayment } = useSchema([
    "UserContractFinanceOutput",
    "UserContractFinanceOutputDelivery",
    "UserContractFinancePayment"
  ])

  if (!UserContractFinanceOutput || !UserContractFinanceOutputDelivery || !UserContractFinancePayment) {
    return (
      <FormSectionHeader title="Invoice Settings - loading" data-cy={testingId.contractModelSettings.invoiceSettings} />
    )
  }

  return (
    <>
      <FormSectionHeader title="Invoice Settings" data-cy={testingId.contractModelSettings.invoiceSettings} />
      <Grid container spacing={8}>
        <Grid item md={6}>
          <Field
            name="finance.output_type_alias"
            label="Invoice Format *"
            component={Select}
            options={UserContractFinanceOutput}
            defaultValue="PRV"
            validate={validators.required}
          />
          <Field
            name="finance.output_delivery_type_alias"
            label="Invoice Delivery *"
            component={Select}
            options={UserContractFinanceOutputDelivery}
            validate={validators.required}
          />
          <FormSpy subscription={{ values: true }}>
            {({ values }) => {
              const isEmailRequired = values.finance?.output_delivery_type_alias === "EMAIL"

              // NOTE(maoc): Workaround
              // https://github.com/final-form/react-final-form/issues/751#issuecomment-654130942
              if (!isEmailRequired) {
                return null
              }

              return (
                <Field
                  name="finance.email"
                  label="Email Address *"
                  component={TextField}
                  validate={composeValidators([validators.required, validators.email, validators.maxLength()])}
                />
              )
            }}
          </FormSpy>
        </Grid>
        <Grid item md={6}>
          <Field
            name="finance.payment_type_alias"
            label="Payment Method *"
            component={Select}
            options={UserContractFinancePayment}
            validate={validators.required}
          />
          <Field
            name="finance.term_days"
            label="Billing Term Days *"
            component={TextField}
            validate={validateCommonNumber({ min: 1, max: 365, precisionValue: 0 })}
          />
        </Grid>
      </Grid>
    </>
  )
})

ClientContractModelSettings.displayName = "ClientContractModelSettings"
