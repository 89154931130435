import { Grid } from "@mui/material"
import { AutocompleteMui } from "components/Form"
import { useCarerListQuery } from "data/core/carer/queries"
import { useMappedSearchableOptions } from "lib/hooks/useLoadAutocompleteOptions"
import React, { memo } from "react"
import { Field } from "react-final-form"

const ShiftListFilterCarer: React.FC = () => {
  const { data: carers } = useCarerListQuery()
  const carerOptions = useMappedSearchableOptions(carers?.users)

  return (
    <Grid item md={3}>
      <Field
        name="carer_guid"
        label="Carer"
        component={AutocompleteMui}
        options={carerOptions}
        placeholder="Search for a carer"
      />
    </Grid>
  )
}

export default memo(ShiftListFilterCarer)
