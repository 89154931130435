import { Box, Grid } from "@mui/material"
import { Link } from "components"
import { Button, FormSpyCustom, TextField } from "components/Form"
import { DateField } from "components/Form/DateField"
import { StatusSelectField } from "components/Form/StatusSelectField"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { StatusEnum } from "data/commonTypes"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"
import { FilterValues, formatFilterValues } from "lib/helpers/formatFilterValues"
import { Pagination } from "lib/types"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"

export interface UnitUserFilter {
  user_name?: string
  start_from?: string
  end_to?: string
  status?: StatusEnum
}

interface OwnProps {
  pagination: Pagination
  resetPagination: () => void
  filter: UnitUserFilter
  setFilter: Dispatch<SetStateAction<UnitUserFilter>>
  unitGuid: string
}

export const UnitUserListFilter: React.FC<OwnProps> = memo(
  ({ filter, setFilter, pagination, resetPagination, unitGuid }) => {
    const debouncedOnSubmit = useDebouncedCallback((values: FilterValues<UnitUserFilter>) => {
      const formattedValues = formatFilterValues<UnitUserFilter>({ values, startName: "start", endName: "end" })

      setFilter(formattedValues)
      resetPagination()
    }, 500)

    useFilterLocationChange({ filter, pagination })

    return (
      <Box {...{ pt: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Form
          {...{
            initialValues: filter,
            onSubmit: debouncedOnSubmit,
            validate: validateFilterPeriodDates({ start: "start", end: "end" })
          }}
        >
          {({ handleSubmit }) => (
            <form {...{ onSubmit: handleSubmit }}>
              <FormSpyCustom {...{ handleSubmit }} />
              <Grid container alignItems="center" spacing={1}>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <Field name="user_name" label="Search by user name" component={TextField} />
                  </Grid>
                  <Grid item md={6}>
                    <StatusSelectField withAny />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <DateField name="start" label="Start from" />
                  </Grid>
                  <Grid item md={6}>
                    <DateField name="end" label="End to" />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
        <Box flexShrink={0} ml={4}>
          <Link to={`/units/${unitGuid}/users/new`}>
            <Button>Create a User</Button>
          </Link>
        </Box>
      </Box>
    )
  }
)

UnitUserListFilter.displayName = "UnitUserListFilter"
