import { set } from "lodash"
import { isEmpty, uniq } from "ramda"
import RuleGroupRuleModel, {
  ContractModelRuleGroupRuleFormData
} from "pages/Finance/ContractModels/helpers/RuleGroupRule"
import {
  ContractModel,
  InvoiceModelRuleGroup,
  InvoiceModelRuleGroupForm,
  InvoiceModelRuleGroupRequest
} from "data/finance/contractModel/types"
import { FormValidation } from "lib/types"
import {
  ContractRuleConditionOptionsByType,
  ContractRuleConditionTypeAlias
} from "data/finance/contractRuleCondition/types"
import { validatePeriodDates, validatePeriodInParent, validatePeriodWithSiblings } from "lib/dateTimeValidators"
import { DateTimeFormat } from "lib/datetime"
import { validateCommonNumber } from "lib/validators"

export const validateRuleGroupDates =
  ({ contractModel, items }: { contractModel: ContractModel; items: InvoiceModelRuleGroup[] }) =>
  (values: InvoiceModelRuleGroupRequest & { no_end_date?: boolean }): FormValidation => {
    // start is before end
    const periodErrors = validatePeriodDates(values)
    if (!isEmpty(periodErrors)) return periodErrors

    // compare to parent model
    const parentErrors = validatePeriodInParent({
      values,
      parent: contractModel,
      itemName: "Rule group",
      parentName: "Contract model"
    })
    if (!isEmpty(parentErrors)) return parentErrors

    // compare to other rule groups
    const overlappingPeriodErrors = validatePeriodWithSiblings({ values, items, label: "Rule group" })
    if (!isEmpty(overlappingPeriodErrors)) return overlappingPeriodErrors
  }

export const validateCopiedRuleGroup =
  ({
    contractModel,
    items,
    originalRuleGroup
  }: {
    contractModel: ContractModel
    items: InvoiceModelRuleGroup[]
    originalRuleGroup: InvoiceModelRuleGroup
  }) =>
  (values: InvoiceModelRuleGroupRequest & { no_end_date?: boolean }): FormValidation => {
    // start is before end
    const periodErrors = validatePeriodDates(values)
    if (!isEmpty(periodErrors)) return periodErrors

    // compare to contract model
    const parentErrors = validatePeriodInParent({
      values,
      parent: contractModel,
      itemName: "Rule group",
      parentName: "Contract model"
    })
    if (!isEmpty(parentErrors)) return parentErrors

    // compare to other rule groups
    const canNotOverlapWithOriginalRuleGroup = !!originalRuleGroup.end

    const filteredRuleGroups = items.filter((ruleGroup) => {
      const isOriginalRuleGroup = ruleGroup.guid === originalRuleGroup.guid
      return !isOriginalRuleGroup || canNotOverlapWithOriginalRuleGroup
    })

    if (!filteredRuleGroups.length || !values.start) return undefined

    const overlappingPeriodErrors = validatePeriodWithSiblings({
      values,
      items: filteredRuleGroups,
      label: "Rule group"
    })
    if (!isEmpty(overlappingPeriodErrors)) return overlappingPeriodErrors

    // compare to original rule group
    if (!canNotOverlapWithOriginalRuleGroup && values.start.toMillis() <= originalRuleGroup.start.toMillis()) {
      return {
        start: `Must start after the original Rule group (${originalRuleGroup.start.toFormat(DateTimeFormat.DATE)})`
      }
    }
  }

export type RuleGroupErrors = {
  [ContractRuleConditionTypeAlias.CONF]: string[]
  [ContractRuleConditionTypeAlias.CANCEL]: string[]
  rules: Record<string, Record<string, string>>
}

/**
 * Validates the RuleGroup rules
 * @param InvoiceModelRuleGroupForm values
 *
 * {
 *   rule_condition_map: [["9ad2c461-f5de-4d57-b5d3-c852713e1eaa", "8a69f6e5-3b26-4a4e-bbe7-6244a2819cde"]],
 *   condition_guids: ["8a69f6e5-3b26-4a4e-bbe7-6244a2819cde"],
 *   rule_type_alias: "V/CONF",
 *   charge_type_alias: "NONE"
 * },
 * {
 *   rule_condition_map: [
 *     ["f14dbbf9-0786-4ab3-ad35-81a11101fe4e", "56eb43e2-6e2e-4366-bd8d-5280acf4e169"],
 *     ["e30d3532-556d-488c-a422-5d73f632f433", "6870ecf9-0242-4303-82e8-1577c935ebf6"]
 *   ],
 *   condition_guids: ["56eb43e2-6e2e-4366-bd8d-5280acf4e169", "6870ecf9-0242-4303-82e8-1577c935ebf6"],
 *   rule_type_alias: "V/CANCEL",
 *   charge_type_alias: "NONE",
 *   period_type_alias: "INSIDE",
 *   period_start: "10",
 *   period: "MINUTES"
 * },
 * {
 *   rule_condition_map: [
 *     ["fd812053-adb0-44cb-bce0-bf3aab07a842", "56eb43e2-6e2e-4366-bd8d-5280acf4e169"],
 *     ["17c177ef-47f2-4eb6-905b-04c6d0b4a653", "6870ecf9-0242-4303-82e8-1577c935ebf6"]
 *   ],
 *   condition_guids: ["56eb43e2-6e2e-4366-bd8d-5280acf4e169", "6870ecf9-0242-4303-82e8-1577c935ebf6"],
 *   rule_type_alias: "V/CANCEL",
 *   charge_type_alias: "NONE",
 *   period_type_alias: "OUTSIDE",
 *   period_start: "10",
 *   period: "MINUTES"
 * }
 *
 * @returns RuleGroupErrors - errors found in form data payload
 */
export const validateRuleGroupRules =
  (conditions: ContractRuleConditionOptionsByType) =>
  (values: InvoiceModelRuleGroupForm): FormValidation => {
    // new rule group does not have rules
    if (!values.guid) return

    const errors: RuleGroupErrors = {
      [ContractRuleConditionTypeAlias.CONF]: [],
      [ContractRuleConditionTypeAlias.CANCEL]: [],
      rules: {}
    }

    // remove deleted rules from validation
    const rules = (values.rules || []).filter((rule) => rule.delete !== true)

    rules.forEach((rule, index) => {
      const rm = RuleGroupRuleModel(rule)

      // IF line
      if (!rm.condition_guids || !rm.condition_guids.length) {
        set(errors, ["rules", index, "condition_guids"], "There must be at least one rule defined.")
      }

      // AND line
      if (rm.isPeriodStartRequired()) {
        const startInt = rm.period_start ? parseInt(rm.period_start, 10) : undefined
        const endInt = rm.period_end ? parseInt(rm.period_end, 10) : undefined

        // Must have a period when period_type_alias is not NONE
        if (!rm.period) {
          set(errors, ["rules", index, "period"], "Required")
        }

        // If Period Type is not NONE there must be at least start value defined
        if (startInt === undefined) {
          set(errors, ["rules", index, "period_start"], "Required")
        } else if (startInt <= 0) {
          // Start period cannot be 0
          set(errors, ["rules", index, "period_start"], "Must be higher than 0")
        }

        // If we're looking for an interval period_end
        // - must be defined
        // - must be higher than period_start
        if (rm.isPeriodEndRequired() && (startInt === undefined || endInt === undefined || endInt <= startInt)) {
          set(errors, ["rules", index, "period_end"], "Must be higher than period")
        }
      }

      // Validate "THEN" condition
      if (!rm.charge_type_alias) {
        set(errors, ["rules", index, "charge_type_alias"], "Required")
      }

      if (rm.isCapTypeAliasRequired() && !rm.cap_type_alias) {
        set(errors, ["rules", index, "cap_type_alias"], "Required")
      }

      const figureErr = validateCommonNumber({ min: 0, precisionValue: 0 })(rm.figure)
      if (rm.isFigureRequired() && figureErr) {
        set(errors, ["rules", index, "figure"], figureErr)
      }

      if (errors.rules[index]) {
        set(errors, ["rules", index, "rule_type_alias"], rm.rule_type_alias)
      }
    })

    // Validate all of the conditions are set in rules
    const ruleTypeAliases = Object.keys(conditions) as ContractRuleConditionTypeAlias[]

    ruleTypeAliases.forEach((ruleType) => {
      const rulesByType = rules.filter((rule) => rule.rule_type_alias === ruleType)

      if (rulesByType.length === 0) {
        errors[ruleType].push("You must define at least one rule for this type")
      }

      // confirmed visit duplicates
      const allSetConditionGuids = rulesByType.reduce((result: string[], rule) => {
        return [...result, ...rule.condition_guids]
      }, [])

      if (
        ruleType === ContractRuleConditionTypeAlias.CONF &&
        allSetConditionGuids.length !== uniq(allSetConditionGuids).length
      ) {
        const duplicateGuids = allSetConditionGuids.reduce(
          (result: string[], conditionGuid, index, allSetConditionGuids) =>
            allSetConditionGuids.indexOf(conditionGuid) !== index && result.indexOf(conditionGuid) === -1
              ? result.concat(conditionGuid)
              : result,
          []
        )

        const duplicateNames = duplicateGuids.map((guid) => {
          const condition = conditions[ContractRuleConditionTypeAlias.CONF].filter((c) => guid === c.value)
          return condition[0].title
        })

        errors[ContractRuleConditionTypeAlias.CONF].push(
          `Can not have duplicated conditions: ${duplicateNames.join(", ")}`
        )
      }

      // missed conditions
      const ruleSetConditions = Array.from(
        new Set(
          rules.reduce((res: string[], rule) => {
            if (rule.rule_type_alias === ruleType && rule.condition_guids) {
              return [...res, ...rule.condition_guids]
            }
            return res
          }, [])
        )
      )

      // We miss conditions in payload
      if (ruleSetConditions.length !== conditions[ruleType].length) {
        const missingConditions: string[] = []
        conditions[ruleType].forEach((condition) => {
          if (!ruleSetConditions.includes(condition.value as string)) {
            missingConditions.push(condition.title as string)
          }
        })

        errors[ruleType].push(
          `All conditions must be represented in rule(s), you're missing: ${missingConditions.join(", ")}`
        )
      }
    })

    // Validate there rules as groups
    const rulesByConditionGuid = rules.reduce(
      (res: Record<string, Array<[number, ContractModelRuleGroupRuleFormData]>>, item, index) => {
        item.condition_guids?.forEach((guid) => {
          if (!res[guid]) {
            res[guid] = [[index, item]]
          } else {
            res[guid].push([index, item])
          }
        })
        return res
      },
      {}
    )

    const validateRuleSetConflicts = (ruleSet: Array<[number, ContractModelRuleGroupRuleFormData]>) => {
      let outsidePeriodIdx: number | null = null
      let insidePeriodIdx: number | null = null
      const betweenPeriod: Array<[number | null, number | null, number, number]> = []

      ruleSet.forEach((indexedRule, ruleSetIdx) => {
        const [rKey, rr] = indexedRule
        const idxRm = RuleGroupRuleModel(rr)

        switch (idxRm.period_type_alias) {
          case "OUTSIDE":
            if (outsidePeriodIdx !== null) {
              set(
                errors,
                ["rules", rKey, "period_type_alias"],
                'Only one "Outside of" condition can be defined per rule'
              )
            } else {
              outsidePeriodIdx = rKey
            }
            break
          case "INSIDE":
            if (insidePeriodIdx !== null) {
              set(
                errors,
                ["rules", rKey, "period_type_alias"],
                'Only one "Inside of" condition can be defined per rule'
              )
            } else {
              insidePeriodIdx = rKey
            }
            break
          case "BETWEEN":
            betweenPeriod.push([
              idxRm.period_start ? parseInt(idxRm.period_start.toString(), 10) : null,
              idxRm.period_end ? parseInt(idxRm.period_end.toString(), 10) : null,
              ruleSetIdx,
              rKey
            ])
        }
      })

      if (insidePeriodIdx !== null && outsidePeriodIdx === null) {
        set(errors, ["rules", insidePeriodIdx, "period_type_alias"], 'Must have corresponding "Outside of" rule')
      } else if (outsidePeriodIdx !== null && insidePeriodIdx === null) {
        set(errors, ["rules", outsidePeriodIdx, "period_type_alias"], 'Must have corresponding "Inside of" rule')
      }

      // Validate BETWEEN conditions
      if (betweenPeriod.length) {
        const outsideRule = outsidePeriodIdx !== null ? rules[outsidePeriodIdx] : null
        const insideRule = insidePeriodIdx !== null ? rules[insidePeriodIdx] : null

        const sortedBetweens = betweenPeriod.sort((a, b) => {
          if (a[0] && b[0]) return a[0] - b[0]
          return 0
        })

        sortedBetweens.forEach((timeSetWithKey, bIdx) => {
          const [start, end, ruleSetIdx, ruleIdx] = timeSetWithKey
          const currentRule = ruleSet[ruleSetIdx][1]

          const nextItem = sortedBetweens[bIdx + 1]

          // First rule in set
          if (bIdx === 0) {
            if (!insideRule) {
              errors[currentRule.rule_type_alias].push(
                'Rules with BETWEEN conditions must have a row with "Inside of" condition defined.'
              )
            } else if (insideRule.period_start && start !== parseInt(insideRule.period_start.toString(), 10)) {
              set(errors, ["rules", ruleIdx, "period_start"], 'Must be equal to "Inside of" value')
            }
          }

          // Start/end match on previous rule
          if (nextItem) {
            const nextRule = ruleSet[nextItem[2]][1]
            if (
              currentRule.period_end !== undefined &&
              currentRule.period_end !== null &&
              nextRule.period_start !== undefined &&
              nextRule.period_start !== null &&
              parseInt(currentRule.period_end.toString(), 10) !== parseInt(nextRule.period_start.toString(), 10)
            ) {
              set(errors, ["rules", ruleIdx, "period_end"], "Start end gap detected")
              set(errors, ["rules", nextItem[3], "period_start"], "Must follow previous end")
            }
          }

          // Last rule in set
          if (!nextItem) {
            if (!outsideRule) {
              errors[currentRule.rule_type_alias].push(
                'Rules with BETWEEN conditions must have a row with "Outside of" condition defined.'
              )
            } else if (outsideRule.period_start && end !== parseInt(outsideRule.period_start.toString(), 10)) {
              set(errors, ["rules", ruleIdx, "period_end"], 'Must be equal to "Outside of" value')
            }
          }
        })
      }
    }

    Object.values(rulesByConditionGuid).forEach((ruleSet) => validateRuleSetConflicts(ruleSet))

    // Add type aliases to rules for easier error display
    Object.keys(errors.rules).forEach((key: string) => {
      errors.rules[key].rule_type_alias = rules[parseInt(key, 10)].rule_type_alias
    })

    if (
      !errors[ContractRuleConditionTypeAlias.CONF].length &&
      !errors[ContractRuleConditionTypeAlias.CANCEL].length &&
      Object.keys(errors.rules).length === 0
    ) {
      return undefined
    }

    return errors
  }
