import Grid from "@mui/material/Grid"
import { StickyBox } from "components"
import { AutocompleteMui, Button, CancelButton, Select } from "components/Form"
import { SelectDummy } from "components/Form/SelectDummy"
import { StateSchema } from "data/schema/types"
import { useSchema } from "data/schema/useSchema"
import validators from "lib/validators"
import React, { memo } from "react"
import { Field, FormSpy } from "react-final-form"

interface Props {
  paymentModelOptions?: StateSchema
  isInvoicePaymentService?: boolean
}

const ServiceCreatePartialForm: React.FC<Props> = ({ paymentModelOptions, isInvoicePaymentService }) => {
  const { PaymentType, ContractServiceType } = useSchema(["PaymentType", "ContractServiceType"])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6}>
          {isInvoicePaymentService ? (
            <Field
              name="payment_contract_guid"
              label="Payment Group *"
              component={AutocompleteMui}
              options={paymentModelOptions}
              validate={validators.required}
            />
          ) : null}
          {PaymentType ? (
            <Field
              name="payment_type_alias"
              label="Price Type *"
              component={Select}
              options={PaymentType}
              validate={validators.required}
            />
          ) : (
            <SelectDummy value="loading..." />
          )}
        </Grid>
        <Grid item md={6}>
          {ContractServiceType ? (
            <Field
              name="type_guid"
              label="Care Type *"
              component={Select}
              options={ContractServiceType}
              validate={validators.required}
            />
          ) : (
            <SelectDummy value="loading..." />
          )}
        </Grid>
      </Grid>
      <StickyBox>
        <FormSpy subscription={{ values: true, pristine: true, submitting: true }}>
          {({ values, pristine, submitting }) => (
            <>
              <CancelButton disabled={submitting} pristine={pristine} />
              <Button
                type="submit"
                fullWidth={false}
                disabled={
                  isInvoicePaymentService
                    ? !values.payment_contract_guid || !values.payment_type_alias || !values.type_guid
                    : !values.payment_type_alias || !values.type_guid
                }
                sx={(theme) => ({
                  margin: theme.spacing(0, 0, 0, 3)
                })}
              >
                Next
              </Button>
            </>
          )}
        </FormSpy>
      </StickyBox>
    </>
  )
}

export default memo(ServiceCreatePartialForm)
