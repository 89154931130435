import React, { useState } from "react"
import { Box, Divider, FormControl, Typography } from "@mui/material"
import { DateField } from "components/Form/DateField"
import CheckInCheckOutReasonField from "./CheckInCheckOutReasonField"
import validators from "lib/validators"
import testingId from "constants/testingId"
import { FormSpy, Field } from "react-final-form"
import { VisitReportTemplateField, VisitReportTemplateFieldOption } from "models/VisitReport"
import { red } from "@mui/material/colors"
import { Visit } from "models/Visit"
import { Button, TextField, Select } from "components/Form"

interface Props {
  destroyModal: () => void
  invalid: boolean
  submitting: boolean
  visit: Visit
}

const CheckOutEdit = ({ destroyModal, invalid, submitting, visit }: Props) => {
  const checkoutFields = visit.visitReport.getQuickCheckOutFields()

  const quickCheckoutBlockers = checkoutFields.reduce(
    (result: Record<string, string>, item: VisitReportTemplateField) => {
      if (item.options) {
        item.options.forEach((option: VisitReportTemplateFieldOption) => {
          if (option.blocks_quick_check_out) {
            result[item.guid] = option.value
          }
        })
      }
      return result
    },
    {}
  )

  const [quickCheckOutBlocked, setQuickCheckoutBlocked] = useState(false)

  return (
    <>
      <Typography variant="body1" paragraph>
        Please finish a quick check out for this visit.
      </Typography>
      <FormSpy
        subscription={{ values: true }}
        onChange={(props: any) => {
          try {
            Object.keys(props.values).forEach((key: any) => {
              if (quickCheckoutBlockers[key] && quickCheckoutBlockers[key] === props.values[key]) {
                throw new Error()
              }
            })
            setQuickCheckoutBlocked(false)
          } catch {
            setQuickCheckoutBlocked(true)
          }
        }}
      />
      <DateField name="time" label="Check out time" required withTime />
      <CheckInCheckOutReasonField testingId={testingId.visit.detail.checkoutModalReason} />
      {checkoutFields.map((field: any, key: number) => {
        const { widget } = field
        let component = TextField
        let extraProps = {}

        if (widget && (widget.type === "radio" || widget.type === "mood")) {
          component = Select
          extraProps = Object.assign({}, extraProps, {
            options: field.options
          })
        }
        return (
          <Box key={key}>
            <Divider sx={{ mt: 4, mb: 4 }} />
            <FormControl margin="normal" fullWidth sx={{ m: 0 }}>
              <Typography variant="body2" data-cy={testingId.visit.detail.fieldTitle}>
                {field.title}
                {widget && (widget.type === "radio" || widget.type === "mood") && "*"}
              </Typography>
              <Field
                name={field.guid}
                label={""}
                component={component}
                disabled={visit.isCheckedOut() || !!visit.visitReport.getValues()[field.guid]}
                {...extraProps}
                validate={
                  visit.isCheckedOut()
                    ? undefined
                    : Array.isArray(field.validators) && field.validators.length > 0
                      ? validators.required
                      : undefined
                }
                required={
                  visit.isCheckedOut() ? undefined : Array.isArray(field.validators) && field.validators.length > 0
                }
              />
            </FormControl>
          </Box>
        )
      })}
      {quickCheckOutBlocked && (
        <Box bgcolor={red[100]} p={0.5} px={2} m={1} mx={0}>
          <p>
            You are unable to complete quick check out because one or more values in the form above are marked as quick
            check out blockers.
          </p>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" m={4} mx={0}>
        <Button
          id="visit-details-check-out-modal-cancel-btn"
          variant="text"
          fullWidth={false}
          onClick={() => destroyModal()}
          data-cy={testingId.visit.detail.cancelCheckOut}
        >
          Cancel
        </Button>
        <Button
          id="visit-details-check-out-modal-submit-btn"
          type="submit"
          fullWidth={false}
          disabled={invalid || submitting || quickCheckOutBlocked}
        >
          Check out
        </Button>
      </Box>
    </>
  )
}

export default CheckOutEdit
