import { Box } from "@mui/material"
import { WithPermission } from "components/Auth"
import { Button } from "components/Form"
import testingId from "constants/testingId"
import React, { memo } from "react"

interface Props {
  toggleSubset: (idArr: string[]) => void
  selectableIdsForCrediting: string[]
  selectableIdsForInvoicing: string[]
}

const TransactionListSelectButtons: React.FC<Props> = ({
  toggleSubset,
  selectableIdsForCrediting,
  selectableIdsForInvoicing
}) => {
  const selectAllForCreditDisabled = !selectableIdsForCrediting.length
  const selectAllForInvoiceDisabled = !selectableIdsForInvoicing.length

  return (
    <Box p={1} px={0} display="flex" justifyContent="flex-end">
      <WithPermission permissions={["Finance.Processing.Credit:Edit"]}>
        <Button
          fullWidth={false}
          variant="text"
          onClick={() => toggleSubset(selectableIdsForCrediting)}
          data-cy={testingId.invoiceProcessingTransactionList.toggleAllForCrediting}
          disabled={selectAllForCreditDisabled}
        >
          (Un)Select all for crediting
        </Button>
      </WithPermission>
      <WithPermission permissions={["Finance.Processing:Edit"]}>
        <Button
          fullWidth={false}
          variant="text"
          onClick={() => toggleSubset(selectableIdsForInvoicing)}
          data-cy={testingId.invoiceProcessingTransactionList.toggleAllForInvoicing}
          disabled={selectAllForInvoiceDisabled}
        >
          (Un)Select all for invoicing
        </Button>
      </WithPermission>
    </Box>
  )
}

export default memo(TransactionListSelectButtons)
