import { Box, Table } from "@mui/material"
import { Divider, EmptyData, Paginator } from "components"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { Permission } from "constants/permission"
import { useAppointmentListQuery } from "data/finance/appointment/queries"
import { AppointmentSearchFilterValues } from "data/finance/invoiceSearch/types"
import Auth from "lib/Auth"
import { useSelectableIdsDictionary } from "lib/hooks"
import { SetPagination } from "lib/types"
import { isEmpty } from "ramda"
import React, { Dispatch, memo, SetStateAction, useMemo } from "react"
import { Form } from "react-final-form"
import { AppointmentSearchScheduledHeader } from "./AppointmentSearchScheduledHeader"
import { AppointmentSearchScheduledTable } from "./AppointmentSearchScheduledTable"
import { mapScheduledAppointments } from "./helpers/mapScheduledAppointments"
import { AppointmentSearchScheduledBulkRecalcButton } from "./AppointmentSearchScheduledBulkRecalcButton"
import { AppointmentSearchScheduledSelectAllButton } from "./AppointmentSearchScheduledSelectAllButton"
import { AppointmentSearchScheduledBulkEditButton } from "./AppointmentSearchScheduledBulkEditButton"

interface OwnProps {
  setPagination: SetPagination
  filter: AppointmentSearchFilterValues
  setFilter: Dispatch<SetStateAction<AppointmentSearchFilterValues>>
}

export const AppointmentSearchScheduled: React.FC<OwnProps> = memo(({ setPagination, setFilter, filter }) => {
  const hasAppointmentEditPermission = Auth.hasPermission([Permission.FINANCE_APPOINTMENT_EDIT])
  const hasRecalcPermission = Auth.hasPermission([Permission.FINANCE_RECALCULATE_EDIT])

  const { data: appointments, refetch } = useAppointmentListQuery()

  const items = useMemo(() => mapScheduledAppointments(appointments || []), [appointments])

  const selectableAssigneeIds = useMemo(() => {
    return items.map((item) => item.assigneeGuid).filter((item) => item)
  }, [items])

  const { selectableIds, toggleId, toggleAll, getSelectedAsStringArr, resetAll } =
    useSelectableIdsDictionary(selectableAssigneeIds)
  const selectedIds = getSelectedAsStringArr()

  const selectedAppointmentsForRecalc = useMemo(() => {
    return items.filter((item) => selectedIds.includes(item.assigneeGuid))
  }, [items, selectedIds])

  return (
    <RequestLoaderWrapper>
      {appointments && appointments.length ? (
        <>
          <Form onSubmit={() => ({})}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Table>
                  <AppointmentSearchScheduledHeader
                    hasAppointmentEditPermission={hasAppointmentEditPermission}
                    hasRecalcPermission={hasRecalcPermission}
                  />
                  <AppointmentSearchScheduledTable
                    items={items}
                    selectableIds={selectableIds}
                    toggleId={toggleId}
                    filter={filter}
                    hasAppointmentEditPermission={hasAppointmentEditPermission}
                    hasRecalcPermission={hasRecalcPermission}
                    selectedIds={selectedIds}
                  />
                </Table>
              </form>
            )}
          </Form>
          <AppointmentSearchScheduledSelectAllButton
            hasAppointmentEditPermission={hasAppointmentEditPermission}
            toggleAll={toggleAll}
            disabled={isEmpty(selectableAssigneeIds)}
          />
          <Divider color="divider" />
          <Paginator
            setPagination={setPagination}
            itemsLength={appointments.length}
            rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          />
          <Box display="flex" justifyContent="right" alignItems="right" m={1}>
            <AppointmentSearchScheduledBulkEditButton
              hasAppointmentEditPermission={hasAppointmentEditPermission}
              setFilter={setFilter}
              resetAll={resetAll}
              selectedIds={selectedIds}
              refetch={refetch}
            />
            <AppointmentSearchScheduledBulkRecalcButton
              items={selectedAppointmentsForRecalc}
              resetAll={resetAll}
              hasRecalcPermission={hasRecalcPermission}
              disabled={isEmpty(selectedIds)}
            />
          </Box>
        </>
      ) : (
        <EmptyData {...{ message: "No appointments found." }} />
      )}
    </RequestLoaderWrapper>
  )
})

AppointmentSearchScheduled.displayName = "AppointmentSearchScheduled"
