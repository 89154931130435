import { useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"
import { useCarerPostMutation, useCarerProfilePutMutation } from "../mutations"
import { ApiError } from "stores/useUIStore/types"
import { useApiMessage } from "stores"

export const useCarerCreateHandler = () => {
  const navigate = useNavigate()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const { mutate: createCarerProfile } = useCarerProfilePutMutation({
    onSuccess: (_, { carerGuid }) => {
      showSuccessMessage("New carer successfully created.")
      navigate(`/carers/${carerGuid}/profile/personal-details`)
    },
    onError: (apiError: ApiError) => {
      showErrorMessage(`Something went wrong when creating new carer in Carer Profile DB.`, { apiError })
    }
  })

  const { mutate: createCarer } = useCarerPostMutation({
    onError: (apiError: ApiError) => {
      showErrorMessage("Something went wrong when creating new carer in Core.", { apiError })
    },
    onSuccess: (response, { carerProfilePayload }) => {
      if (carerProfilePayload && !isEmpty(carerProfilePayload)) {
        createCarerProfile({ carerGuid: response.guid, payload: carerProfilePayload })
      } else {
        showSuccessMessage("New carer successfully created.")
        navigate(`/carers/${response.guid}/profile/personal-details`)
      }
    }
  })

  return createCarer
}
