import React from "react"
import Grid from "@mui/material/Grid"
import { FieldRenderProps } from "react-final-form"
import Select, { SelectOptionNumber } from "./Select"

type Props = FieldRenderProps<number, any> & {
  label: string
  options?: SelectOptionNumber[]
}

const Preference: React.FC<Props> = (props) => {
  return (
    <Grid container alignItems="center">
      <Grid item md={6}>
        {props.label}
      </Grid>
      <Grid item md={6}>
        <Select
          {...props}
          label={undefined}
          options={[
            { title: "Any", value: 0 },
            { title: "Yes", value: 1 },
            { title: "No", value: -1 }
          ]}
        />
      </Grid>
    </Grid>
  )
}
export default Preference
