import React from "react"
import { NotFound } from "./index"
import { MyDownloadList } from "./MyDownload/MyDownloadList"
import Auth from "lib/Auth"
import PermissionDenied from "app/PermissionDenied"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

const MyDownload: React.FC = () => {
  if (!Auth.hasPermission(["File.Download:Read"])) {
    return <PermissionDenied />
  }

  return (
    <SentryRoutes>
      <Route path="/" element={<MyDownloadList />} />
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  )
}
export default MyDownload
