import Grid from "@mui/material/Grid"
import { AutocompleteMui, Select, TextField } from "components/Form"
import React, { memo } from "react"
import { Field, FormSpy } from "react-final-form"
import validators, { composeUnrequiredValidators, DEFAULT_MAX_NUMBER } from "lib/validators"
import numberUtils from "lib/number"
import DatesMessageHelp from "components/DatesMessageHelp"
import { NoEndDateCheckbox } from "components"
import { DateField } from "components/Form/DateField"
import { useSchema } from "data/schema/useSchema"
import { SelectDummy } from "components/Form/SelectDummy"
import { StateSchema } from "data/schema/types"

interface Props {
  paymentModelOptions?: StateSchema
  noEndDate?: boolean
  isInvoicePaymentService?: boolean
}

const ServiceDetailForm: React.FC<Props> = ({ noEndDate, paymentModelOptions, isInvoicePaymentService }) => {
  const { ContractServiceType, PaymentType } = useSchema(["ContractServiceType", "PaymentType"])

  return (
    <>
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          if (values.mileage_rate) values.mileage_rate = numberUtils.displayWithDecimals(values.mileage_rate, 2)
        }}
      />
      <DatesMessageHelp messageType="E" />
      <Grid container spacing={8}>
        <Grid item md={6}>
          {isInvoicePaymentService && (
            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <Field
                  name="payment_contract_guid"
                  label="Payment Group *"
                  component={AutocompleteMui}
                  options={paymentModelOptions}
                  validate={validators.required}
                  disabled={!!values.guid}
                />
              )}
            </FormSpy>
          )}

          {ContractServiceType ? (
            <Field
              name="type_guid"
              label="Care Type *"
              component={Select}
              options={ContractServiceType}
              validate={validators.required}
            />
          ) : (
            <SelectDummy value="loading..." />
          )}
          {PaymentType ? (
            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <Field
                  name="payment_type_alias"
                  label="Price Type *"
                  component={Select}
                  options={PaymentType}
                  validate={validators.required}
                  disabled={!!values.guid}
                />
              )}
            </FormSpy>
          ) : (
            <SelectDummy value="loading..." />
          )}
          <Field
            name="description"
            label="Description"
            component={TextField}
            validate={validators.maxLength(1000)}
            multiline
            minRows={5}
          />
        </Grid>
        <Grid item md={6}>
          <DateField name="start" label="Starts on" required />
          <DateField name="end" label="Ends before" disabled={noEndDate} />
          <NoEndDateCheckbox />

          {isInvoicePaymentService && (
            <>
              <Field
                name="mileage_rate"
                label="Mileage Rate"
                component={TextField}
                validate={composeUnrequiredValidators([validators.availableValueMustBeANumber(0, 1)])}
              />
              <Field
                name="travel_rate"
                label="Travel Rate"
                component={TextField}
                validate={composeUnrequiredValidators([validators.availableValueMustBeANumber(0, DEFAULT_MAX_NUMBER)])}
              />
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default memo(ServiceDetailForm)
