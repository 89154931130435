import { ContractModelType } from "constants/modelTypes"
import { useContractModelCalendarDeleteMutation } from "data/finance/contractModel/mutations"
import { useCallback } from "react"
import { useApiMessage } from "stores"

interface DeleteContractCalendarsProps {
  modelType: ContractModelType
  contractGuid: string
  selectedCalendars: string[]
  refetch: Function
}

export const useContractCalendarDeleteHandler = ({
  modelType,
  contractGuid,
  selectedCalendars,
  refetch
}: DeleteContractCalendarsProps): (() => Promise<void>) => {
  const { mutateAsync: deleteCalendar } = useContractModelCalendarDeleteMutation()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useCallback(async () => {
    if (!selectedCalendars || !selectedCalendars.length) return

    try {
      await Promise.all(
        selectedCalendars.map((calendarGuid: string) => {
          return deleteCalendar({ modelType, contractGuid, calendarGuid })
        })
      )
      refetch()
      showSuccessMessage("Calendars successfully deleted.")
    } catch (apiError: any) {
      showErrorMessage("Something went wrong, please try again.", { apiError })
    }
  }, [modelType, contractGuid, selectedCalendars, deleteCalendar, showSuccessMessage, refetch, showErrorMessage])
}
