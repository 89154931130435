import { Box, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { UnitUser } from "data/finance/unit/types"
import { DateTimeFormat } from "lib/datetime"
import React, { memo, useCallback } from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import { Button, Checkbox } from "components/Form"
import { Field, Form } from "react-final-form"
import { Paginator, StickyBox } from "components"
import { useUnitUserDeleteMutation } from "data/finance/unit/mutations"
import { QueryRefetch, SetPagination } from "lib/types"
import { useDebouncedCallback } from "use-debounce"
import DatesMessageHelp from "components/DatesMessageHelp"
import { useNavigate } from "react-router-dom"

const headers: string[] = ["Name", "Starts on", "Ends before", "Status"]

interface OwnProps {
  users: UnitUser[]
  unitGuid: string
  refetch: QueryRefetch<UnitUser[]>
  setPagination: SetPagination
}

export const UnitUserListTable: React.FC<OwnProps> = memo(({ users, unitGuid, refetch, setPagination }) => {
  const navigate = useNavigate()

  const onRowClick = useCallback(
    (userGuid: string) => () => {
      navigate(`/units/${unitGuid}/users/${userGuid}`)
    },
    [unitGuid, navigate]
  )

  const { mutateAsync: deleteUser } = useUnitUserDeleteMutation({ unitGuid })

  const debouncedRefetch = useDebouncedCallback(() => {
    refetch()
  }, 500)

  const onSubmit = (values: Record<string, boolean>) => {
    Object.keys(values).forEach(async (userGuid) => {
      if (values[userGuid]) {
        await deleteUser({ userGuid })
        debouncedRefetch()
      }
    })
  }

  return (
    <Box {...{ m: 3, mx: 0 }}>
      <Typography variant="h6">Users</Typography>
      <Divider
        sx={{
          backgroundColor: "divider",
          marginTop: 1,
          marginBottom: 1
        }}
      />
      <DatesMessageHelp messageType="E" />
      <Form {...{ onSubmit, destroyOnUnregister: true }}>
        {({ handleSubmit, values }) => {
          return (
            <form {...{ onSubmit: handleSubmit }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <DeleteIcon sx={{ marginRight: 1, marginTop: 1 }} />
                    </TableCell>
                    {headers.map((title) => (
                      <TableCell key={title}>{title}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow sx={{ cursor: "pointer" }} key={user.guid} hover onClick={onRowClick(user.guid)}>
                      <TableCell>
                        {user.status === "Future" ? (
                          <Field<boolean>
                            {...{
                              type: "checkbox",
                              component: Checkbox,
                              name: user.guid,
                              onClick: (e: MouseEvent) => {
                                e.stopPropagation()
                              }
                            }}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell>{user.user_name}</TableCell>
                      <TableCell>{user.start.toFormat(DateTimeFormat.DATE)}</TableCell>
                      <TableCell>{user.end ? user.end.toFormat(DateTimeFormat.DATE) : "--/--/--"}</TableCell>
                      <TableCell>{user.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Paginator {...{ setPagination, itemsLength: users.length }} />
              <StickyBox>
                <Button
                  {...{
                    type: "submit",
                    fullWidth: false,
                    disabled: !Object.values(values).includes(true)
                  }}
                >
                  Delete
                </Button>
              </StickyBox>
            </form>
          )
        }}
      </Form>
    </Box>
  )
})

UnitUserListTable.displayName = "UnitUserListTable"
