import { GENERAL_ERROR_MESSAGE } from "api/instance"
import { useInvoiceModelRateDeleteMutation } from "data/finance/contractModel/mutations"
import { InvoiceModelRate } from "data/finance/contractModel/types"
import { RefetchQuery } from "data/types"
import { useCallback } from "react"
import { useApiMessage } from "stores"

export const useStandingChargeDeleteHandler = ({
  selectedIds,
  contractModelGuid,
  refetch
}: {
  selectedIds: string[]
  contractModelGuid: string
  refetch: RefetchQuery<InvoiceModelRate[]>
}): (() => Promise<void>) => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const { mutateAsync: deleteCharge } = useInvoiceModelRateDeleteMutation()

  return useCallback(async () => {
    if (!selectedIds.length) return

    try {
      await Promise.all(
        selectedIds.map((rateGuid) => {
          return deleteCharge({ modelGuid: contractModelGuid, rateGuid })
        })
      )
      refetch()
      showSuccessMessage("Standing Charges successfully deleted.")
    } catch (apiError: any) {
      showErrorMessage(GENERAL_ERROR_MESSAGE, { apiError })
    }
  }, [showSuccessMessage, refetch, contractModelGuid, deleteCharge, selectedIds, showErrorMessage])
}
