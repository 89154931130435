import React from "react"
import { Chip, Theme } from "@mui/material"
import testingId from "constants/testingId"

export enum SquareChipColorVariant {
  GREY = "GREY",
  RED = "RED",
  GREEN = "GREEN"
}

interface Props {
  label: string
  color: SquareChipColorVariant
  pointerCursor?: boolean
}

const getSquareChipColor = (color: SquareChipColorVariant, theme: Theme): { color: string; borderColor: string } => {
  switch (color) {
    case SquareChipColorVariant.RED:
      return { color: theme.palette.error.main, borderColor: theme.palette.error.main }
    case SquareChipColorVariant.GREY:
      return { color: theme.palette.grey[500], borderColor: theme.palette.grey[500] }
    default:
      return { color: theme.palette.success.main, borderColor: theme.palette.success.main }
  }
}

const OnboardingStatusChip: React.FC<Props> = ({ label, color, pointerCursor = false }) => (
  <Chip
    sx={(theme) => ({
      ...getSquareChipColor(color, theme),
      borderRadius: 0,
      width: 100,
      cursor: pointerCursor ? "pointer" : "initial"
    })}
    label={label}
    variant="outlined"
    data-cy={testingId.onboardingStatusChip}
  />
)

export default OnboardingStatusChip
