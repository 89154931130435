import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import React, { memo } from "react"
import { EmptyData } from "components"
import { DateTimeFormat } from "lib/datetime"
import testingId from "constants/testingId"
import { ContractModelSalary } from "data/finance/contractModel/types"
import { SalaryBandListHeader } from "./SalaryBandListHeader"
import { useNavigate } from "react-router-dom"

interface OwnProps {
  salaries?: ContractModelSalary[]
}

export const SalaryBandList: React.FC<OwnProps> = memo(({ salaries }) => {
  SalaryBandList.displayName = "SalaryBandList"
  const navigate = useNavigate()
  if (!salaries?.length) return <EmptyData message="No salary bands found, start by adding one." />

  return (
    <Table>
      <SalaryBandListHeader />
      <TableBody>
        {salaries.map((salary: ContractModelSalary) => (
          <TableRow
            key={salary.guid}
            data-cy={testingId.tableRow}
            hover
            sx={{
              cursor: "pointer"
            }}
            onClick={() => {
              navigate(salary.guid)
            }}
          >
            <TableCell data-cy={testingId.tableCell}>{salary.start.toFormat(DateTimeFormat.DATE)}</TableCell>
            <TableCell data-cy={testingId.tableCell}>{salary?.end?.toFormat(DateTimeFormat.DATE) ?? "-"}</TableCell>
            <TableCell data-cy={testingId.tableCell}>{salary.status}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})
