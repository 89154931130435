import React, { useMemo, MouseEvent, PropsWithChildren } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import MaterialLink from "@mui/material/Link"
import { SxProps, Theme } from "@mui/material"

const ReactRouterLinkRef = React.forwardRef((props: any, ref: any) => {
  ReactRouterLinkRef.displayName = "ReactRouterLinkRef"
  return <ReactRouterLink ref={ref} {...props} />
})

export interface LinkProps extends PropsWithChildren {
  id?: string
  to: string
  external?: boolean
  target?: string
  onClick?: (e: MouseEvent) => void
  propagate?: boolean
  color?: string
  sx?: SxProps<Theme>
}

const Link = ({ external, propagate, ...props }: LinkProps) => {
  const linkProps = useMemo(
    () => ({
      ...props,
      ...(external
        ? {
            target: props.target ? props.target : "_blank",
            rel: "noopener noreferrer",
            href: props.to
          }
        : {})
    }),
    [props, external]
  )

  return (
    <MaterialLink
      {...linkProps}
      onClick={(e: MouseEvent) => {
        !propagate && e.stopPropagation()
        props.onClick && props.onClick(e)
      }}
      component={external ? "a" : ReactRouterLinkRef}
    />
  )
}
export default Link
