import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { ContentContainer, EmptyData, Header, Link, Divider } from "components"
import { Button } from "components/Form"
import { useHolidayTierListQuery } from "data/finance/holidayTier/queries"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"

const HolidayTierList: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { data: holidayTiers } = useHolidayTierListQuery()

  return (
    <ContentContainer>
      <Header title="Holiday Tiers" />
      <Box display="flex" justifyContent="flex-end">
        <Link to="new">
          <Button>Create a new holiday tier</Button>
        </Link>
      </Box>
      <Divider color="divider" />
      <Box m={3} mx={0}>
        {holidayTiers && !!holidayTiers.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {holidayTiers.map((holidayTier) => (
                <TableRow
                  sx={{
                    cursor: "pointer"
                  }}
                  hover
                  key={holidayTier.guid}
                  onClick={() => {
                    navigate(`${location.pathname}/${holidayTier.guid}`)
                  }}
                >
                  <TableCell>{holidayTier.title}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyData message="No holiday tiers found, start by adding one." />
        )}
      </Box>
    </ContentContainer>
  )
}

export default React.memo(HolidayTierList)
