import React from "react"
import { Box, FormHelperText } from "@mui/material"
import { red } from "@mui/material/colors"
import { FieldRenderProps } from "react-final-form"

type Props = FieldRenderProps<any, any> & {
  setFile: Function
  accept?: string
}

const UploadInput: React.FC<Props> = ({ accept, setFile, meta, input }: Props) => {
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched

  return (
    <Box
      sx={(theme) => ({
        margin: theme.spacing(1.9, 0, 0, 0),
        padding: theme.spacing(0, 1),
        borderRadius: "4px",
        cursor: "pointer",
        height: "54px",
        maxHeight: "54px",

        "& input": {
          fontSize: "16px",
          width: "100%",
          padding: theme.spacing(0, 1),
          outline: "none"
        }
      })}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border={1}
      borderColor={showError ? "#f44336" : "grey.400"}
    >
      <input
        {...input}
        accept={accept ? accept : ""}
        type="file"
        onChange={(e: any) => {
          setFile(e.target.files[0])
          input.onChange(e)
        }}
      />
      {showError && (
        <FormHelperText sx={{ color: red[500], marginTop: 0 }}>{meta.error || meta.submitError}</FormHelperText>
      )}
    </Box>
  )
}
export default UploadInput
