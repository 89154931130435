import { TransactionFailed, TransactionInProgress, TransactionQueued } from "pages/Finance/components"
import { isNil } from "ramda"
import React, { ReactNode, useMemo } from "react"
import { Checkbox } from "components/Form"
import testingId from "constants/testingId"
import { Field } from "react-final-form"
import { AppointmentType } from "constants/appointmentType"
import { Link } from "components"
import dateTimeUtils from "lib/datetime"
import MoneyWrapper from "components/MoneyWrapper"
import { timeSpanToStringDuration } from "lib/timespan"
import { SelectableIdsDictionaryModel } from "lib/hooks"
import { Tooltip } from "@mui/material"
import { useSchema } from "data/schema/useSchema"
import { optionsToValueTitle } from "lib/schema"
import { PaymentTransactionItem } from "data/finance/paymentProcessing/types"

const getServiceTypeLabel = (serviceTypesByGuid?: Record<string, string>, serviceTypeGuid?: string) => {
  if (!serviceTypesByGuid) return "Loading"
  if (!serviceTypeGuid) return "Unknown"
  return serviceTypesByGuid[serviceTypeGuid]
}

const getPeriod = (item: PaymentTransactionItem) => {
  if (item.is_projected) return "Future"
  if (item.is_historic) return "Previous"
  return "Current"
}

const getStatus = ({
  item,
  hasTransactionReference
}: {
  item: PaymentTransactionItem
  hasTransactionReference: boolean
}) => {
  if (hasTransactionReference && item.confirmed && item.processed_at) return "Proc'd"
  return item.confirmed ? "Conf." : "Unconf."
}

const getCreditActions = ({
  visit,
  canCreditTransaction,
  selectableIds,
  toggleId
}: {
  visit: PaymentTransactionItem
  canCreditTransaction: boolean
  toggleId: (id: string) => void
  selectableIds: SelectableIdsDictionaryModel
}) => {
  if (visit.credit_guid) {
    if (visit.is_historic && !visit.is_projected && visit.processed_prefix === "CR") return ""
    return (
      <Tooltip title={visit.credit_reason ?? ""}>
        <>Credited</>
      </Tooltip>
    )
  }

  if (visit.is_failed) return <TransactionFailed />
  if (visit.is_queued) return <TransactionQueued />
  if (visit.is_processing) return <TransactionInProgress />

  if (!isNil(selectableIds[visit.guid]) && canCreditTransaction && visit.is_creditable) {
    return (
      <Field<boolean>
        type="checkbox"
        key={`tItem[${visit.guid}]`}
        name={`tItem[${visit.guid}]`}
        component={Checkbox}
        checked={selectableIds[visit.guid]}
        onClick={(e: MouseEvent) => {
          e.stopPropagation()
          toggleId(visit.guid)
        }}
        data-cy={testingId.paymentProcessingTransactionDetail.listItemCheckbox}
      />
    )
  }

  return null
}

interface ItemToRowProps {
  item: PaymentTransactionItem
  serviceTypesByGuid?: Record<string, string>
  hasTransactionReference: boolean
}

const mapItemToRow = ({ item, serviceTypesByGuid, hasTransactionReference }: ItemToRowProps) => {
  return [
    <Link
      key={`link=${item.appointment_guid}`}
      to={`/${item.appointment_type === AppointmentType.VISIT ? "visits" : "shifts"}/detail/${item.appointment_guid}`}
    >
      {dateTimeUtils.formatDate(item.visit_date)}
    </Link>,
    dateTimeUtils.formatTime(item.visit_date),
    getPeriod(item),
    item.reference_owner_type !== "Unassigned" ? (
      <Link
        key={`link-${item.reference_owner_guid}`}
        to={
          item.reference_owner_type === "User"
            ? `/clients/${item.reference_owner_guid}/profile/personal-details`
            : item.reference_owner_type === "Shift"
            ? `/shift-categories/${item.reference_owner_guid}/detail`
            : `/units/${item.reference_owner_guid}/edit`
        }
      >
        {item.reference_owner_name}
      </Link>
    ) : (
      item.reference_owner_name
    ),
    getServiceTypeLabel(serviceTypesByGuid, item.service_type_guid),
    item.reference_contract_name,
    timeSpanToStringDuration(item.payable_duration),
    getStatus({ item, hasTransactionReference }),
    item.processed_at ? `${item.processed_prefix ?? "P"} - ${dateTimeUtils.formatDate(item.processed_at)}` : "-",
    <MoneyWrapper key={item.guid} amount={item.total ?? 0} />
  ]
}

interface OwnProps {
  items: PaymentTransactionItem[]
  hasTransactionReference: boolean
  canCreditTransaction: boolean
  toggleId: (id: string) => void
  selectableIds: SelectableIdsDictionaryModel
}

export const useMapPaymentTransactionItems = ({
  items,
  hasTransactionReference,
  canCreditTransaction,
  toggleId,
  selectableIds
}: OwnProps): Record<string, ReactNode[]> => {
  const { ContractServiceType } = useSchema(["ContractServiceType"])

  return useMemo(() => {
    const serviceTypesByGuid = optionsToValueTitle(ContractServiceType)

    return items.reduce((result: Record<string, ReactNode[]>, item) => {
      const mappedItem = mapItemToRow({ item, serviceTypesByGuid, hasTransactionReference })

      result[item.guid] = hasTransactionReference
        ? [...mappedItem, getCreditActions({ visit: item, canCreditTransaction, toggleId, selectableIds })]
        : mappedItem

      return result
    }, {})
  }, [canCreditTransaction, items, hasTransactionReference, ContractServiceType, toggleId, selectableIds])
}
