import React, { memo, SetStateAction, Dispatch } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import Box from "@mui/material/Box"
import { Button } from "components/Form"
import { WithPermission } from "components/Auth"
import { SelectableIdsDictionaryModel } from "lib/hooks"
import { ContractModelType } from "constants/modelTypes"
import testingId from "constants/testingId"
import { InvoiceFileItem } from "data/finance/invoiceFile/types"
import { FileManagementTableHeader } from "./FileManagementTableHeader"
import { FileManagementTableItem } from "./FileManagementTableItem"
import Auth from "lib/Auth"
import { Permission } from "constants/permission"
import { InvoiceFilesFilterValues } from "../types"

interface OwnProps {
  items?: InvoiceFileItem[]
  modelType: ContractModelType
  onSelectRow: (id: string) => void
  toggleAll: () => void
  selectableRows: SelectableIdsDictionaryModel
  idsArrayForInterface: string[]
  idsArrayForITrent: string[]
  interfaceDisabled: boolean
  setFilter: Dispatch<SetStateAction<InvoiceFilesFilterValues>>
  showITrent: boolean
  selectableIdsForITrent: SelectableIdsDictionaryModel
  toggleIdForITrent: (id: string) => void
  toggleAllForITrent: () => void
  selectAllForITrentDisabled: boolean
}

export const FileManagementTable: React.FC<OwnProps> = memo(
  ({
    items,
    modelType,
    onSelectRow,
    toggleAll,
    selectableRows,
    idsArrayForInterface,
    idsArrayForITrent,
    interfaceDisabled,
    setFilter,
    showITrent,
    selectableIdsForITrent,
    toggleIdForITrent,
    toggleAllForITrent,
    selectAllForITrentDisabled
  }) => {
    const isDeveloper = Auth.hasPermission([Permission.DEVELOPER_EDIT])

    return (
      <>
        <Table>
          <FileManagementTableHeader {...{ modelType, setFilter, showITrent, isDeveloper }} />
          <TableBody>
            {items?.map((item: InvoiceFileItem) => {
              const isSelected =
                modelType === ContractModelType.INVOICE
                  ? idsArrayForInterface.includes(item.guid)
                  : idsArrayForITrent.includes(item.guid)

              return (
                <FileManagementTableItem
                  key={item.guid}
                  {...{
                    file: item,
                    modelType,
                    onSelectRow,
                    selectableRows,
                    showITrent,
                    selectableIdsForITrent,
                    toggleIdForITrent,
                    isDeveloper,
                    isSelected
                  }}
                />
              )
            })}
          </TableBody>
        </Table>
        <Box p={1} px={0} display="flex" justifyContent="flex-end">
          {showITrent ? (
            <WithPermission permissions={[Permission.DEVELOPER_EDIT]}>
              <Button
                fullWidth={false}
                variant="text"
                onClick={toggleAllForITrent}
                disabled={selectAllForITrentDisabled}
                sx={{ color: "crimson" }}
              >
                (Un)Select all for resubmit to iTrent
              </Button>
            </WithPermission>
          ) : (
            <WithPermission permissions={[Permission.FINANCE_FILE_READ]}>
              <Button
                data-cy={testingId.fileManagement.selectAllButton}
                fullWidth={false}
                variant="text"
                onClick={toggleAll}
                disabled={interfaceDisabled}
              >
                (Un)Select all for interface
              </Button>
            </WithPermission>
          )}
        </Box>
      </>
    )
  }
)

FileManagementTable.displayName = "FileManagementTable"
