import { TableCell, TableRow } from "@mui/material"
import datetime, { DateTimeFormat } from "lib/datetime"
import { moneyFormat } from "lib/utils"
import React, { memo } from "react"
import testingId from "constants/testingId"
import { AppointmentSearch } from "data/finance/invoiceSearch/types"
import { appointmentOwnerLink } from "./helpers/appointmentOwnerLink"
import { appointmentTypeLink } from "./helpers/appointmentTypeLink"
import { AppointmentSearchListTableItemMenu } from "./AppointmentSearchListTableItemMenu"
import { RecalculateButton } from "components/RecalculateButton"
import { getContractModelLink } from "../components/helpers/getContractModelLink"

interface Props {
  appointment: AppointmentSearch
  isInvoiceModel: boolean
  refetch: () => void
  hasRecalcPermission: boolean
}

export const AppointmentSearchListTableItem: React.FC<Props> = memo(
  ({ appointment, isInvoiceModel, refetch, hasRecalcPermission }) => {
    return (
      <TableRow data-cy={testingId.tableRow} key={appointment.guid} hover>
        <TableCell data-cy={testingId.tableCell}>
          {datetime.formatDate(appointment.start, DateTimeFormat.DATE_TIME)}
        </TableCell>
        <TableCell data-cy={testingId.tableCell}>
          {datetime.formatDate(appointment.end, DateTimeFormat.DATE_TIME)}
        </TableCell>
        <TableCell data-cy={testingId.tableCell}>
          {appointmentOwnerLink({
            owner_type: appointment.owner_type,
            owner_guid: appointment.owner_guid,
            owner_name: appointment.owner_name,
            isInvoiceModel
          })}
        </TableCell>
        <TableCell data-cy={testingId.tableCell}>
          {getContractModelLink({
            contract_guid: appointment.contract_guid,
            contract_title: appointment.contract_title,
            isInvoiceModel
          })}
        </TableCell>
        <TableCell data-cy={testingId.tableCell}>
          {appointmentTypeLink({
            appointment_type: appointment.appointment_type,
            appointment_guid: appointment.appointment_guid
          })}
        </TableCell>
        <TableCell data-cy={testingId.tableCell}>{appointment.transaction_reference}</TableCell>
        <TableCell
          sx={{
            whiteSpace: "nowrap"
          }}
          data-cy={testingId.tableCell}
        >
          {moneyFormat.format((appointment.extra_approximation || 0) + (appointment.labour_approximation || 0))}
        </TableCell>
        <TableCell data-cy={testingId.tableCell}>
          <AppointmentSearchListTableItemMenu {...{ appointment, isInvoiceModel, refetch }} />
        </TableCell>
        {hasRecalcPermission && (
          <TableCell>
            <RecalculateButton
              {...{ type: appointment.appointment_type, relation_guids: [appointment.appointment_guid] }}
            />
          </TableCell>
        )}
      </TableRow>
    )
  }
)

AppointmentSearchListTableItem.displayName = "AppointmentSearchListTableItem"
