import {
  TimeSpanPeriod,
  constructTimeSpanUsingPeriod,
  timeSpanToValuePeriod,
  splitTimespanStringToTimeParts
} from "lib/timespan"
import { deepObjectEqual } from "lib/utils"
import { omit } from "ramda"
import { ContractRuleConditionTypeAlias } from "data/finance/contractRuleCondition/types"
import {
  InvoiceModelRuleCapTypeAlias,
  InvoiceModelRuleChargeTypeAlias,
  InvoiceModelRuleGroupRule,
  InvoiceModelRuleGroupRuleRequest,
  InvoiceModelRulePeriodTypeAlias
} from "data/finance/contractModel/types"

type RuleGroupRuleModelType = ContractModelRuleGroupRuleFormData & {
  isFigureRequired: () => boolean
  isMarkedForDeletion: () => boolean
  isPeriodStartRequired: () => boolean
  isPeriodEndRequired: () => boolean
  isCapTypeAliasRequired: () => boolean
  isConfirmedVisitRule: () => boolean
  isExistingRule: () => boolean
}

const RuleGroupRuleModel = (rule: ContractModelRuleGroupRuleFormData): RuleGroupRuleModelType => {
  const isMarkedForDeletion = () => rule.delete === true

  const isConfirmedVisitRule = () => rule.rule_type_alias === ContractRuleConditionTypeAlias.CONF

  // Figure must be entered if
  // Charge type alias is C/ACTUAL or C/ROSTER and Cap type alias is not TIMEONLY
  const isFigureRequired = () =>
    ["C/ACTUAL", "C/ROSTER"].includes(rule.charge_type_alias) && rule.cap_type_alias !== "TIMEONLY"

  // Cap type alias is required for C/ACTUAL and C/ROSTER charge type aliases
  const isCapTypeAliasRequired = () => ["C/ACTUAL", "C/ROSTER"].includes(rule.charge_type_alias)

  // Period start is required when period_type_alias is anything but NONE
  const isPeriodStartRequired = () => ![undefined, "NONE"].includes(rule.period_type_alias)

  // Period end is required for BETWEEN period_type_alias
  const isPeriodEndRequired = () => rule.period_type_alias === "BETWEEN"

  const isExistingRule = () => rule.rule_condition_map && rule.rule_condition_map.length > 0

  return {
    isFigureRequired,
    isMarkedForDeletion,
    isPeriodStartRequired,
    isPeriodEndRequired,
    isCapTypeAliasRequired,
    isConfirmedVisitRule,
    isExistingRule,
    ...rule
  }
}

export default RuleGroupRuleModel

type ContractModelRuleGroupRuleFormPeriods = {
  period_start?: string
  period_end?: string
  period?: TimeSpanPeriod
}

export type ContractModelRuleGroupRuleFormData = {
  condition_guids: string[]
  rule_condition_map: string[][]
  rule_type_alias: ContractRuleConditionTypeAlias
  charge_type_alias: InvoiceModelRuleChargeTypeAlias
  period_type_alias?: InvoiceModelRulePeriodTypeAlias
  cap_type_alias?: InvoiceModelRuleCapTypeAlias
  figure?: number | string
  delete?: boolean
} & ContractModelRuleGroupRuleFormPeriods

/**
 * Takes list/patch endpoint payload and transforms it to RuleGroupForm values
 * @param ContractModelRuleGroupRule[] payload
 * @returns ContractModelRuleGroupRuleFormData[] rules
 */

const mapTimeSpansToPeriods = (rule: Partial<InvoiceModelRuleGroupRuleRequest>, forcedPeriod?: TimeSpanPeriod) => {
  const result = {} as ContractModelRuleGroupRuleFormPeriods
  if (rule.period_start) {
    const [value, period] = timeSpanToValuePeriod(rule.period_start, forcedPeriod, rule.period_end)
    if (value) {
      result.period_start = value.toString()
    } else {
      result.period_start = undefined
    }

    if (period) {
      result.period = period
    }

    if (rule.period_end) {
      const [value] = timeSpanToValuePeriod(rule.period_end, period)
      if (value) {
        result.period_end = value.toString()
      }
    }
  }

  return result
}

const hasMinutes = (rules: InvoiceModelRuleGroupRuleRequest[]): boolean =>
  !!rules.find((rule) => {
    if (rule.period_start) {
      const parts = splitTimespanStringToTimeParts(rule.period_start)
      return Number(parts[2])
    }
    return false
  })

const hasHours = (rules: InvoiceModelRuleGroupRuleRequest[]): boolean =>
  !!rules.find((rule) => {
    if (rule.period_start) {
      const parts = splitTimespanStringToTimeParts(rule.period_start)
      return Number(parts[1])
    }
    return false
  })

const getForcedPeriod = (rules: InvoiceModelRuleGroupRuleRequest[]): TimeSpanPeriod | undefined => {
  if (hasMinutes(rules)) return "MINUTES"
  if (hasHours(rules)) return "HOURS"
}

const mutateRules = (
  rules: InvoiceModelRuleGroupRule[],
  forcedPeriod: TimeSpanPeriod | undefined
): ContractModelRuleGroupRuleFormData[] => {
  const result: ContractModelRuleGroupRuleFormData[] = []

  rules.forEach((rule) => {
    const { guid, condition_guid, ...newConditions } = rule
    const rulePeriods = mapTimeSpansToPeriods(rule, forcedPeriod)
    const ruleMeta = {
      ...newConditions,
      ...rulePeriods
    }
    const newItem = {
      condition_guids: [condition_guid],
      rule_condition_map: [[guid, condition_guid]],
      ...ruleMeta
    }

    const ruleIdx = result.findIndex((item) => {
      const existingConditions = omit(["condition_guids", "rule_condition_map"], item)
      return deepObjectEqual(ruleMeta, existingConditions)
    })

    if (ruleIdx !== -1) {
      const resultItemConditions = omit(["condition_guids", "rule_condition_map"], result[ruleIdx])

      if (JSON.stringify(ruleMeta) === JSON.stringify(resultItemConditions)) {
        result[ruleIdx] = {
          ...result[ruleIdx],
          condition_guids: [...result[ruleIdx].condition_guids, condition_guid],
          rule_condition_map: [...result[ruleIdx].rule_condition_map, [guid, condition_guid]]
        }
      } else {
        result.push(newItem)
      }
    } else {
      result.push(newItem)
    }
  })
  return result
}

export const mapRulesPayloadToFormData = (
  payload: InvoiceModelRuleGroupRule[]
): ContractModelRuleGroupRuleFormData[] => {
  const confirmationRules = payload.filter((rule) => rule.rule_type_alias === ContractRuleConditionTypeAlias.CONF)
  const cancellationRules = payload.filter((rule) => rule.rule_type_alias === ContractRuleConditionTypeAlias.CANCEL)

  const forcedConfirmationPeriod = getForcedPeriod(confirmationRules)
  const forcedCancellationPeriod = getForcedPeriod(cancellationRules)

  const mutatedConfirmationRules = mutateRules(confirmationRules, forcedConfirmationPeriod)
  const mutatedCancellationRules = mutateRules(cancellationRules, forcedCancellationPeriod)

  return [...mutatedConfirmationRules, ...mutatedCancellationRules]
}

/**
 * Takes form data payload and maps it to payload expected by endpoint
 * @param ContractModelRuleGroupRuleFormData[] rules
 * @returns ContractModelRuleGroupRule[] payload
 */
export const mapRulesFormDataToPayload = (
  rules: ContractModelRuleGroupRuleFormData[]
): InvoiceModelRuleGroupRuleRequest[] => {
  let result: InvoiceModelRuleGroupRuleRequest[] = []

  rules
    .filter((rule) => rule.delete !== true)
    .forEach((rule) => {
      const { condition_guids, rule_condition_map, period, ...ruleData } = rule
      const rm = RuleGroupRuleModel(rule)

      if (rm.isPeriodStartRequired()) {
        if (ruleData.period_start) {
          ruleData.period_start = constructTimeSpanUsingPeriod(ruleData.period_start, period as TimeSpanPeriod)
            ?.shiftTo("days", "hours", "minutes", "seconds", "milliseconds")
            .toFormat("d:h:m:s.S")
        }
        if (rm.isPeriodEndRequired() && ruleData.period_end) {
          ruleData.period_end = constructTimeSpanUsingPeriod(ruleData.period_end, period as TimeSpanPeriod)
            ?.shiftTo("days", "hours", "minutes", "seconds", "milliseconds")
            .toFormat("d:h:m:s.S")
        }
      } else {
        delete ruleData.period_start
        delete ruleData.period_end
      }

      if (!rm.isFigureRequired()) {
        delete ruleData.figure
      } else if (ruleData.figure && typeof ruleData.figure === "string") {
        ruleData.figure = parseInt(ruleData.figure, 10)
      }

      if (!rm.isCapTypeAliasRequired()) {
        delete ruleData.cap_type_alias
      }

      delete ruleData["delete"]

      const newItem = {
        ...ruleData,
        figure: ruleData.figure ? (ruleData.figure as number) : undefined
      }

      // New rule, add to result set
      if (!rule_condition_map || rule_condition_map.length === 0) {
        condition_guids.forEach((conditionGuid) => {
          result.push({
            ...newItem,
            condition_guid: conditionGuid
          })
        })
      } else {
        const rulesToAppend: InvoiceModelRuleGroupRuleRequest[] = []
        rule_condition_map.forEach(([ruleGuid, conditionGuid]) => {
          if (condition_guids.includes(conditionGuid)) {
            rulesToAppend.push({
              guid: ruleGuid,
              condition_guid: conditionGuid,
              ...newItem
            })
          }
        })

        const newRulesToAppend: InvoiceModelRuleGroupRuleRequest[] = []
        condition_guids.forEach((conditionGuid) => {
          if (!rulesToAppend.filter((rule) => rule.condition_guid === conditionGuid).length) {
            newRulesToAppend.push({
              condition_guid: conditionGuid,
              ...newItem
            })
          }
        })

        result = result.concat(rulesToAppend)
        result = result.concat(newRulesToAppend)
      }
    })

  return result
}
