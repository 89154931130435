import { Box, Grid } from "@mui/material"
import { ContentContainer, Link, StickyBox } from "components"
import BottomErrorMessage from "components/BottomErrorMessage"
import { Button, CancelButton, Select, TextField } from "components/Form"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import {
  useContractModelAccrualPolicyCreateMutation,
  useContractModelAccrualPolicyUpdateMutation
} from "data/finance/contractModel/mutations"
import {
  useContractAccrualPolicyQuery,
  useContractServiceAccrualPoliciesListQuery
} from "data/finance/contractModel/queries"
import { ContractModel, ContractModelAccrualPolicy } from "data/finance/contractModel/types"
import validators, { composeUnrequiredValidators } from "lib/validators"
import React, { memo, useCallback } from "react"
import { Field, Form } from "react-final-form"
import { ContractModelTitle } from "./components"
import { splitTimespanStringToTimeParts } from "lib/timespan"
import { validatePaymentAccrualPolicy } from "./validators/validatePaymentAccrualPolicy"
import { ContractModelType } from "constants/modelTypes"
import { DateField } from "components/Form/DateField"
import { useSchema } from "data/schema/useSchema"
import { SelectDummy } from "components/Form/SelectDummy"
import { useParams } from "react-router-dom"

type Props = { contractModel: ContractModel }

export const AccrualPolicy: React.FC<Props> = memo(({ contractModel }) => {
  const { policyId } = useParams()
  const { Accrual } = useSchema(["Accrual"])

  const { data: allPolicies } = useContractServiceAccrualPoliciesListQuery({
    contractGuid: contractModel.guid
  })
  const { data: accrualPolicy } = useContractAccrualPolicyQuery({
    contractGuid: contractModel.guid,
    policyGuid: policyId
  })
  const { mutateAsync: createAccrualPolicy } = useContractModelAccrualPolicyCreateMutation()
  const { mutateAsync: updateAccrualPolicy } = useContractModelAccrualPolicyUpdateMutation()

  const onSubmit = useCallback(
    (values: ContractModelAccrualPolicy) => {
      if (policyId) {
        updateAccrualPolicy({ payload: values, contractModelGuid: contractModel.guid })
      } else {
        createAccrualPolicy({ contractModelGuid: contractModel.guid, payload: values })
      }
    },
    [contractModel.guid, createAccrualPolicy, updateAccrualPolicy, policyId]
  )

  const initialValues = accrualPolicy
    ? {
        ...accrualPolicy,
        rollover_expiry: accrualPolicy.rollover_expiry
          ? splitTimespanStringToTimeParts(accrualPolicy.rollover_expiry)[0]
          : undefined
      }
    : {}

  return (
    <ContentContainer>
      <ContractModelTitle
        modelType={ContractModelType.PAYMENT}
        title={
          <>
            <Link to="../settings">{contractModel.title}</Link> / <Link to="../accrual-policies">Accrual Policies</Link>{" "}
            / {policyId ? "Detail" : "New"}
          </>
        }
      />
      <Box m={3} mx={0}>
        <RequestLoaderWrapper my={3}>
          <Form
            {...{
              onSubmit,
              validate: validatePaymentAccrualPolicy(allPolicies),
              initialValues
            }}
          >
            {({ handleSubmit, pristine, submitting, error }) => (
              <form {...{ onSubmit: handleSubmit }}>
                <Grid container spacing={3}>
                  <Grid item md={4}>
                    {Accrual ? (
                      <Field
                        name="type_alias"
                        label="Accrual Type *"
                        component={Select}
                        options={Accrual}
                        validate={validators.required}
                      />
                    ) : (
                      <SelectDummy value="loading..." />
                    )}
                  </Grid>
                  <Grid item md={4}>
                    <DateField name="start" label="Starts on" required minDate={contractModel.start} />
                  </Grid>
                  <Grid item md={4}>
                    <DateField
                      name="end"
                      label="Ends before"
                      required
                      maxDate={contractModel.end ? contractModel.end : undefined}
                      helperText="Period ends one day before the “Ends before” date."
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={4}>
                    <Field
                      name="accrual_cap"
                      label="Accrual Cap *"
                      component={TextField}
                      validate={validators.validateCommonNumber({
                        min: 0,
                        precisionValue: 2
                      })}
                      endAdornment="hours"
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Field
                      name="rollover_cap"
                      label="Rollover Cap *"
                      component={TextField}
                      validate={validators.validateCommonNumber({
                        min: 0,
                        precisionValue: 2
                      })}
                      helperText="Unused Carer accrual hours from the last set weeks of the holiday period can be carried over to the next."
                      endAdornment="weeks"
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Field
                      name="rollover_expiry"
                      label="Rollover Expiry"
                      component={TextField}
                      validate={composeUnrequiredValidators([
                        validators.validateCommonNumber({
                          min: 1,
                          precisionValue: 0
                        })
                      ])}
                      helperText="Number of days at the start of the next holiday period to use the rollover hours."
                      endAdornment="days"
                    />
                  </Grid>
                </Grid>

                <StickyBox>
                  {error ? (
                    <Grid container>
                      <Grid item md={8}>
                        <BottomErrorMessage message={error} />
                      </Grid>
                    </Grid>
                  ) : null}
                  <CancelButton disabled={submitting} pristine={pristine} navigateUrl="../accrual-policies" />
                  <Button
                    type="submit"
                    fullWidth={false}
                    disabled={pristine || submitting}
                    sx={(theme) => ({
                      margin: theme.spacing(0, 0, 0, 3)
                    })}
                  >
                    SAVE
                  </Button>
                </StickyBox>
              </form>
            )}
          </Form>
        </RequestLoaderWrapper>
      </Box>
    </ContentContainer>
  )
})

AccrualPolicy.displayName = "AccrualPolicy"
