import { SortableTableHeader } from "components/SortableTableHeader"
import React, { Dispatch, memo, SetStateAction, useMemo } from "react"
import { PaymentTransactionGroupFilter } from "../types"

interface OwnProps {
  setFilter: Dispatch<SetStateAction<PaymentTransactionGroupFilter>>
  hasCompletedTransactionGroup: boolean
  hasAlertingTransactionGroup: boolean
  hasRewindPermission: boolean
}

export const TransactionGroupTableHeader: React.FC<OwnProps> = memo(
  ({ setFilter, hasCompletedTransactionGroup, hasAlertingTransactionGroup, hasRewindPermission }) => {
    TransactionGroupTableHeader.displayName = "TransactionGroupTableHeader"

    const items = useMemo(
      () => [
        { label: "Pay model", resource: "calendar.contract.title" },
        { label: "Periods", resource: "calendar.frequency_type.title" },
        { label: "Start date", resource: "start" },
        { label: "End date", resource: "end" },
        { label: "Unconf visits" },
        { label: "Paid", resource: "completed_at" },
        { label: "Cost" },
        { label: "Select" },
        ...(hasRewindPermission && hasCompletedTransactionGroup ? [{ label: "", key: "rewind" }] : []),
        ...(hasAlertingTransactionGroup ? [{ label: "", key: "alert" }] : [])
      ],
      [hasRewindPermission, hasCompletedTransactionGroup, hasAlertingTransactionGroup]
    )

    return <SortableTableHeader {...{ items, setFilter }} />
  }
)
