import { useCallback } from "react"
import { useCarerContractCreateMutation, useCarerContractEditMutation } from "../mutations"
import { ApiCarerContract, ApiCarerContractRequest } from "../types"

export const useCarerContractCreateOrEditHandler = ({
  carerGuid
}: {
  carerGuid: string
}): ((payload: ApiCarerContract | ApiCarerContractRequest) => void) => {
  const { mutateAsync: createContract } = useCarerContractCreateMutation({ carerGuid })
  const { mutateAsync: editContract } = useCarerContractEditMutation({ carerGuid })

  return useCallback(
    (payload: ApiCarerContract | ApiCarerContractRequest) => {
      if (payload.guid) {
        editContract(payload as ApiCarerContract)
        return
      }
      createContract(payload as ApiCarerContractRequest)
    },
    [editContract, createContract]
  )
}
