import { Box } from "@mui/material"
import { AppointmentType } from "constants/appointmentType"
import React, { memo, useMemo } from "react"
import { useRecalculateMutation } from "data/finance/development/mutations"
import { groupBy } from "ramda"
import { AppointmentSearch } from "data/finance/invoiceSearch/types"
import { DevButton } from "components/DevButton"

interface Props {
  appointments: AppointmentSearch[]
}

export const AppointmentSearchBulkRecalcButton = memo(({ appointments }: Props) => {
  const selectableRecalcs = useMemo(() => {
    const groupFunction = (item: AppointmentSearch) => item.appointment_type

    const groupedAppointments = groupBy(groupFunction)(appointments ?? [])

    return Object.keys(groupedAppointments).map((source: string) => {
      const appointment = groupedAppointments[source as AppointmentType]
      const relation_guids = (appointment ?? []).map((item) => item.appointment_guid)
      return { type: source, relation_guids: relation_guids }
    })
  }, [appointments])

  const { mutateAsync: bulkRecalc } = useRecalculateMutation()
  const handleBulkRecalc = () => {
    bulkRecalc(selectableRecalcs)
  }

  return (
    <Box margin={1}>
      <DevButton fullWidth={false} onClick={handleBulkRecalc}>
        Recalc All
      </DevButton>
    </Box>
  )
})

AppointmentSearchBulkRecalcButton.displayName = "AppointmentSearchBulkRecalcButton"
