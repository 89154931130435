import { useQuery, UseQueryResult } from "react-query"
import { PayrollApi } from "api"
import { ApiSubmission } from "./types"

const queryKeys = {
  getPayrollEmployeeTransactionDetailKey: (props: { employeeGuid: string; transactionGuid: string }) => [
    "payroll-employee-transaction-detail",
    props
  ]
}

export const usePayrollEmployeeTransactionDetailQuery = ({
  employeeGuid,
  transactionGuid
}: {
  employeeGuid: string
  transactionGuid: string
}): UseQueryResult<ApiSubmission, Error> => {
  return useQuery({
    queryKey: queryKeys.getPayrollEmployeeTransactionDetailKey({ employeeGuid, transactionGuid }),
    queryFn: async () => PayrollApi.get(`/v1/payroll/employees/${employeeGuid}/transactions/${transactionGuid}`)
  })
}
