import React, { memo } from "react"
import { Tooltip, TableHead, TableCell, Table, TableBody, TableRow } from "@mui/material"
import dateTimeUtils from "lib/datetime"
import { moneyFormat } from "lib/utils"
import testingId from "constants/testingId"
import { DateTime } from "luxon"

interface Props {
  date: DateTime
  processed_at?: DateTime
  quantity: number
  rate: number
  total: number
}

export const HolidayEstimationOverviewTable: React.FC<Props> = memo(({ date, processed_at, quantity, rate, total }) => {
  HolidayEstimationOverviewTable.displayName = "HolidayEstimationOverviewTable"

  return (
    <Table data-cy={testingId.holidayEstimation.overviewTable}>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          {!!processed_at && <TableCell>Date Processed</TableCell>}
          <TableCell>Number of Hours</TableCell>
          <TableCell>Avg. Hourly Rate</TableCell>
          <TableCell>
            <Tooltip title="This is an estimate." placement="right">
              <span>Amount *</span>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{dateTimeUtils.formatDate(date)}</TableCell>
          {!!processed_at && <TableCell>{dateTimeUtils.formatDate(processed_at)}</TableCell>}
          <TableCell>{quantity}</TableCell>
          <TableCell>{moneyFormat.format(rate)}</TableCell>
          <TableCell>{moneyFormat.format(total)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
})
