import { Step, StepLabel, Stepper } from "@mui/material"
import React, { memo } from "react"
import { RateUpliftFormPage } from "../../types"
import lodash from "lodash"
import { ContractModelType } from "constants/modelTypes"

const sections = (modelType: ContractModelType) => [
  modelType === ContractModelType.INVOICE ? "Invoice Group" : "Pay Group",
  "Service",
  "Rates",
  "Summary"
]

interface OwnProps {
  page: RateUpliftFormPage
  modelType: ContractModelType
}

export const RateUpliftStepper: React.FC<OwnProps> = memo(({ page, modelType }) => {
  RateUpliftStepper.displayName = "RateUpliftStepper"

  return (
    <Stepper
      activeStep={page}
      alternativeLabel
      sx={(theme) => ({
        margin: theme.spacing(3, 0, 3, 0),
        backgroundColor: "inherit"
      })}
    >
      {sections(modelType).map((label) => (
        <Step key={label}>
          <StepLabel>{lodash.startCase(label)}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
})
