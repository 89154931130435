import { useMutation } from "react-query"
import { PasswordResetRequest } from "api/core"
import coreApi from "api"

const useMutationPasswordReset = () => {
  return useMutation({
    mutationKey: ["password-reset"],
    mutationFn: (data: PasswordResetRequest) => coreApi.passwordReset(data)
  })
}

export default useMutationPasswordReset
