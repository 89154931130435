import { ContentContainer, EmptyData, Header, Paginator } from "components"
import { usePagination } from "components/handlers/usePagination"
import { useUnitListQuery } from "data/finance/unit/queries"
import { queryStringToObject } from "lib/queryString"
import React, { memo, useState } from "react"
import { UnitFilter, UnitListFilter } from "./components/UnitListFilter"
import { UnitListTable } from "./components/UnitListTable"
import { useLocation } from "react-router-dom"

export const UnitList: React.FC = memo(() => {
  const location = useLocation()
  const [filter, setFilter] = useState<UnitFilter>(queryStringToObject(location?.search))

  const { data: units } = useUnitListQuery({ search: location?.search })

  const { pagination, setPagination, resetPagination } = usePagination()

  return (
    <ContentContainer>
      <Header title="Units" />
      <UnitListFilter {...{ pagination, resetPagination, filter, setFilter }} />
      {units?.length ? (
        <>
          <UnitListTable {...{ units }} />
          <Paginator {...{ setPagination, itemsLength: units.length }} />
        </>
      ) : (
        <EmptyData {...{ message: "No units found, start by adding one." }} />
      )}
    </ContentContainer>
  )
})

UnitList.displayName = "UnitList"
