import { CoreApi } from "api"
import { ApiUserOperator, ApiUserOperatorFormRequest } from "models"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { queryKeys } from "./queries"
import { useNavigate } from "react-router-dom"
import { useUIStore } from "stores"

interface OperatorUpdateMutationParams {
  operator: ApiUserOperatorFormRequest
}

export const useOperatorCreateMutation = (): UseMutationResult<
  ApiUserOperator,
  Error,
  OperatorUpdateMutationParams
> => {
  const navigate = useNavigate()
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)

  return useMutation(
    async ({ operator }: OperatorUpdateMutationParams): Promise<ApiUserOperator> => {
      return CoreApi.post("/operator", operator)
    },
    {
      onSuccess: (data) => {
        const { guid: id } = data
        showSuccessMessage("New operator successfully created")
        navigate(`/admin/operators/${id}/profile/personal-details`)
      }
    }
  )
}

export const useOperatorUpdateMutation = (): UseMutationResult<
  ApiUserOperator,
  Error,
  OperatorUpdateMutationParams
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ operator }: OperatorUpdateMutationParams): Promise<ApiUserOperator> => {
      const { guid: id } = operator
      return CoreApi.put(`/operator/${id}`, operator)
    },
    {
      onSuccess: (data) => {
        const { guid: id } = data
        queryCache.invalidateQueries(queryKeys.getOperatorDetailKey(id))
        showSuccessMessage("Operator details successfully updated.")
      }
    }
  )
}

interface AssignOperatorsMutationParams {
  branchGuid: string
  operatorsGuid: string[]
}

export const useAssignOperatorsMutation = (): UseMutationResult<void, Error, AssignOperatorsMutationParams> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ branchGuid, operatorsGuid }: AssignOperatorsMutationParams): Promise<void> => {
      return CoreApi.post(`branch/${branchGuid}/assign-operators`, operatorsGuid)
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.getUnassignedOperators())
        queryCache.invalidateQueries(queryKeys.getAssignedOperators())
        showSuccessMessage("Operators successfully assigned.")
      }
    }
  )
}

export const useUnassignOperatorsMutation = (): UseMutationResult<void, Error, AssignOperatorsMutationParams> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ branchGuid, operatorsGuid }: AssignOperatorsMutationParams): Promise<void> => {
      return CoreApi.post(`branch/${branchGuid}/unassign-operators`, operatorsGuid)
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.getUnassignedOperators())
        queryCache.invalidateQueries(queryKeys.getAssignedOperators())
        queryCache.invalidateQueries(queryKeys.getOperatorListKey())
        showSuccessMessage("Operators successfully unassigned.")
      }
    }
  )
}
