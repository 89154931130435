import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { MobileApi } from "../../api"
import { CarerOnboardingStatus } from "./types"

export enum QueryKeyParts {
  CARERS_ONBOARDING_STATUSES = "carers_onboarding_statuses",
  CARER_ONBOARDING_STATUS = "carer_onboarding_status"
}

/**
 * Fetch the onboarding status for careers
 */
const fetchOnboardingStatus = async (carerIds: string[], onboardingType: string) => {
  const searchQuery = new URLSearchParams(carerIds?.map((id) => ["carerIds", id]))
  searchQuery.append("onboardingType", onboardingType)

  return await MobileApi.get(`/onboarding/statuses?${searchQuery.toString()}`)
}

export const queryKeys = {
  getCarersOnboardingStatusKey: ({
    onboardingType = "basic_onboarding",
    carerGuid
  }: {
    onboardingType?: string
    carerGuid?: string
  }): QueryKey => [QueryKeyParts.CARER_ONBOARDING_STATUS, { onboardingType, carerGuid }],
  getCarersOnboardingStatusListKey: ({
    onboardingType,
    carerIds
  }: {
    onboardingType: string
    carerIds?: string
  }): QueryKey => [QueryKeyParts.CARERS_ONBOARDING_STATUSES, { onboardingType, carerIds }]
}

export const useCarersOnboardingStatusesQuery = ({
  onboardingType = "basic_onboarding",
  carerIds = [],
  enabled = true
}: {
  onboardingType?: string
  carerIds?: string[]
  enabled?: boolean
}): UseQueryResult<Record<string, number>, Error> => {
  return useQuery({
    queryKey: queryKeys.getCarersOnboardingStatusListKey({ onboardingType, carerIds: carerIds.join(",") }),
    queryFn: () => fetchOnboardingStatus(carerIds, onboardingType),
    enabled
  })
}

/**
 * Custom react query to retrieve carer onboarding status
 * @onboardingType currently defaults to "basic_onboarding"
 */
export const useCarerOnboardingStatus = ({
  carerGuid,
  onboardingType = "basic_onboarding"
}: {
  carerGuid?: string
  onboardingType?: string
}): UseQueryResult<CarerOnboardingStatus, Error> => {
  return useQuery({
    queryKey: queryKeys.getCarersOnboardingStatusKey({ carerGuid, onboardingType }),
    queryFn: async () => MobileApi.get(`/onboarding/${carerGuid}?onboardingType=${onboardingType}`),
    enabled: !!carerGuid
  })
}
