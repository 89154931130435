import React, { Dispatch, SetStateAction, useState } from "react"
import { Backdrop, Box, Fade, Modal, IconButton, Button, Slider, styled, SxProps } from "@mui/material"
import { RotateRight, Remove, Add, SaveAlt, Close } from "@mui/icons-material/"
import { TransformComponent, TransformWrapper, useControls } from "react-zoom-pan-pinch"
import { unstable_styleFunctionSx, StyleFunction } from "@mui/system"
import { useModalsStore } from "./useModalsStore"

interface Props {
  setRotation: Dispatch<SetStateAction<number>>
  file: Blob
}

const Controls = ({ setRotation, file }: Props) => {
  const { zoomIn, zoomOut, resetTransform, centerView } = useControls()
  const [scale, setScale] = useState(0)

  const handleDownload = async () => {
    const fileHandle = await window.showSaveFilePicker()
    const writable = await fileHandle.createWritable()
    await writable.write(file)
    await writable.close()
  }

  return (
    <Box
      sx={{
        alignItems: "center",
        flex: 0,
        p: 2,
        gap: 2,
        bgcolor: "white",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16
      }}
    >
      <IconButton onClick={() => setRotation((prev) => prev + 90)}>
        <RotateRight titleAccess="rotate" />
      </IconButton>
      <IconButton
        onClick={() => {
          if (scale > 0) {
            setScale((prev) => prev - 50)
          }
          zoomOut(0.5)
        }}
      >
        <Remove titleAccess="zoom out" />
      </IconButton>
      <Slider
        sx={{ width: 160 }}
        value={scale}
        onChange={(_, value) => {
          const current = Number(value) / 100 + 1
          centerView(current)
          setScale(Number(value))
        }}
      />
      <IconButton
        onClick={() => {
          if (scale < 100) {
            setScale((prev) => prev + 50)
          }
          zoomIn(0.5)
        }}
      >
        <Add titleAccess="zoom in" />
      </IconButton>
      <Button
        onClick={() => {
          setRotation(0)
          setScale(0)
          resetTransform()
        }}
      >
        Reset
      </Button>
      <IconButton onClick={() => handleDownload()}>
        <SaveAlt titleAccess="download" />
      </IconButton>
    </Box>
  )
}

const Img = styled("img")<{ sx?: SxProps }>({
  maxHeight: "calc(100vh - 136px)",
  maxWidth: "100%",
  ...(unstable_styleFunctionSx as StyleFunction<{ sx?: SxProps }>)
})

const ChatModalImage = () => {
  const {
    toggleImageModal,
    imageModal: { open, src }
  } = useModalsStore((state) => state)
  const [rotation, setRotation] = useState(0)

  if (!src) return null

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => toggleImageModal()}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            borderRadius: 4,
            position: "absolute",
            bottom: 32,
            top: 32,
            left: 32,
            right: 32,
            boxShadow: 4,
            bgcolor: "grey.800"
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 24, right: 24, bgcolor: "white", zIndex: 10 }}
            onClick={() => toggleImageModal()}
          >
            <Close titleAccess="close" />
          </IconButton>
          <TransformWrapper initialScale={1} maxScale={2}>
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", height: "100%" }}>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: "100%",
                  maxHeight: "calc(100% - 72px)"
                }}
              >
                <TransformComponent>
                  <Img
                    sx={{ transform: `rotate(${rotation}deg)`, transition: "transform 0.3s ease" }}
                    alt="image zoom"
                    src={URL.createObjectURL(src)}
                  />
                </TransformComponent>
              </Box>
              <Controls setRotation={setRotation} file={src} />
            </Box>
          </TransformWrapper>
        </Box>
      </Fade>
    </Modal>
  )
}

export default ChatModalImage
