import { Typography } from "@mui/material"
import { ContentContainer, Paginator, EmptyData } from "components"
import { DateTimeFormat, formatDate } from "lib/datetime"
import React, { memo } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import HrCarerSearchFilter from "./HrCarerSearchFilter"
import { useHrCarerSearchQuery } from "data/core/carerSearch/queries"
import { usePagination } from "components/handlers/usePagination"
import { useLocation } from "react-router-dom"

const HrCarerSearchList: React.FC = () => {
  const location = useLocation()
  const { data } = useHrCarerSearchQuery({
    query: location?.search,
    withSearch: true
  })
  const { pagination, setPagination, resetPagination } = usePagination({ initialPageSize: 10 })

  return (
    <ContentContainer>
      <Typography variant="h4">Carer search</Typography>
      <HrCarerSearchFilter {...{ location, pagination, resetPagination }} />
      {data && data.carers.length > 0 ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Carer&apos;s name</TableCell>
                <TableCell align="left">Nickname</TableCell>
                <TableCell align="left">Branch</TableCell>
                <TableCell align="left">Start date</TableCell>
                <TableCell align="left">End date</TableCell>
                <TableCell align="left">NI number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.carers.map((carer) => {
                return (
                  <TableRow key={carer.guid} hover>
                    <TableCell>{carer.full_name}</TableCell>
                    <TableCell>{carer.nick_name}</TableCell>
                    <TableCell>{carer.branch}</TableCell>
                    <TableCell>
                      {carer.start_date ? formatDate(carer.start_date, DateTimeFormat.DATE_TERTIARY) : null}
                    </TableCell>
                    <TableCell>
                      {carer.end_date ? formatDate(carer.end_date, DateTimeFormat.DATE_TERTIARY) : null}
                    </TableCell>
                    <TableCell>{carer.ni_number}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <Paginator {...{ setPagination, count: data.count, initialPageSize: 10 }} />
        </>
      ) : (
        <EmptyData message="We did not find any carers" />
      )}
    </ContentContainer>
  )
}

export default memo(HrCarerSearchList)
