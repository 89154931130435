import { EmptyData, Paginator } from "components"
import { usePagination } from "components/handlers/usePagination"
import { useCarerPayslipListQuery } from "data/finance/carer/queries"
import React, { memo } from "react"
import { CarerPayslipListFilter } from "./CarerPayslipListFilter"
import { CarerPayslipListTable } from "./CarerPayslipListTable"
import { useParams } from "react-router-dom"

interface OwnProps {
  id: string
}

const Component: React.FC<OwnProps> = memo(({ id }) => {
  const { data: payslips } = useCarerPayslipListQuery({ carerGuid: id })
  const { pagination, setPagination, resetPagination } = usePagination()

  return (
    <>
      <CarerPayslipListFilter {...{ pagination, resetPagination }} />
      {payslips?.length ? (
        <>
          <CarerPayslipListTable {...{ payslips, carerGuid: id }} />
          <Paginator {...{ setPagination, itemsLength: payslips?.length }} />
        </>
      ) : (
        <EmptyData message="No Care Pay Breakdowns found." />
      )}
    </>
  )
})

export const CarerPayslipList: React.FC = memo(() => {
  const { id } = useParams()

  if (!id) return null
  return <Component {...{ id }} />
})

CarerPayslipList.displayName = "CarerPayslipList"
Component.displayName = "CarerPayslipListComponent"
