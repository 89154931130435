import { Box, MenuItem, Select, styled } from "@mui/material"
import React, { memo } from "react"

const StyledWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1)
}))

interface OwnProps {
  value?: string
}

export const SelectDummy: React.FC<OwnProps> = memo(({ value }) => {
  SelectDummy.displayName = "SelectDummy"

  return (
    <StyledWrapper>
      <Select disabled variant="outlined" fullWidth value={value}>
        <MenuItem value={value}>{value}</MenuItem>
      </Select>
    </StyledWrapper>
  )
})
