import { ApiCarerContract } from "data/finance/carer/types"
import { timeSpanToValuePeriod } from "lib/timespan"
import { isNil } from "ramda"
import { useMemo } from "react"

export const useCarerContractGetInitialValues = (contract?: ApiCarerContract): {} =>
  useMemo(() => {
    if (!contract) return {}

    const { travel_times } = contract
    const formTravelTimes = travel_times.map((travelTime) => ({
      ...travelTime,
      wait_time_threshold: timeSpanToValuePeriod(travelTime.wait_time_threshold, "MINUTES")[0]
    }))

    return {
      ...contract,
      current_role: isNil(contract.end),
      travel_times: formTravelTimes
    }
  }, [contract])
