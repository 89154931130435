import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { Button, FormSpyCustom, Select, TextField } from "components/Form"
import React, { Dispatch, memo, ReactNode, SetStateAction, useState } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"
import { PaymentExtrasModal } from "../../components"
import { SelectableIdsDictionary } from "lib/hooks"
import testingId from "constants/testingId"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { TransactionDetailType } from "pages/Finance/types"
import { ClearFilterButton } from "components/ClearFilterButton"
import { ApiCarerContract } from "data/finance/carer/types"
import { useSchema } from "data/schema/useSchema"
import { PaymentTransaction } from "data/finance/paymentProcessing/types"
import { CalendarPeriod } from "data/finance/contractModel/types"

export type PaymentTransactionChargesFilter = {
  reference_owner_name?: string
  charge_dates?: boolean
  charge_type_alias?: string[]
}

interface OwnProps extends Pick<SelectableIdsDictionary, "resetAll"> {
  transaction: PaymentTransaction
  period: CalendarPeriod
  contract?: ApiCarerContract
  chargeFilter: PaymentTransactionChargesFilter
  setChargeFilter: Dispatch<SetStateAction<PaymentTransactionChargesFilter>>
  tab: TransactionDetailType
  resetDeletableAll: () => void // same resetAll
  canEditExtra: boolean
}

const chargesDatesOptions = [
  { title: "Any", value: "" },
  { title: "In period", value: false },
  { title: "Outside period", value: true }
]

export const TransactionDetailChargesFilter: React.FC<OwnProps> = memo(
  ({
    transaction,
    period,
    resetAll,
    resetDeletableAll,
    contract,
    chargeFilter,
    setChargeFilter,
    tab,
    canEditExtra
  }) => {
    const { TransactionCharge } = useSchema(["TransactionCharge"])

    const onFilterSubmit = useDebouncedCallback((values: PaymentTransactionChargesFilter) => {
      resetAll()
      resetDeletableAll()
      setChargeFilter(values)
    }, 300)

    useFilterLocationChange({ filter: { tab, ...chargeFilter } })

    const [modal, setModal] = useState<ReactNode>(null)
    const destroyModal = () => setModal(null)

    return (
      <>
        <Form onSubmit={onFilterSubmit} initialValues={chargeFilter}>
          {({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <FormSpyCustom {...{ handleSubmit }} />
              <Grid container alignItems="center" spacing={1}>
                <Grid item md={4}>
                  <Field name="reference_owner_name" label="Search by client name" component={TextField} />
                </Grid>
                <Grid item md={2}>
                  <Field name="charge_dates" component={Select} options={chargesDatesOptions} label="Extra dates" />
                </Grid>
                <Grid item md={2}>
                  <Field
                    name="charge_type_alias"
                    label="Extra Type"
                    component={Select}
                    options={TransactionCharge}
                    multiselect
                  />
                </Grid>
                <Grid item md={canEditExtra ? 4 : 2}>
                  <Box textAlign={canEditExtra ? "right" : "center"}>
                    <ClearFilterButton
                      onClick={() => {
                        form.reset()
                        setChargeFilter({})
                      }}
                      disabled={Object.keys(chargeFilter).length === 0}
                    />
                  </Box>
                </Grid>
                {canEditExtra && (
                  <Grid item md={2}>
                    <Button
                      color="primary"
                      fullWidth={true}
                      onClick={() =>
                        setModal(
                          <PaymentExtrasModal
                            transaction={transaction}
                            period={period}
                            destroyModal={destroyModal}
                            contract={contract}
                          />
                        )
                      }
                      data-cy={testingId.paymentProcessingTransactionDetail.addExtraButton}
                    >
                      Add extra
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          )}
        </Form>
        {modal !== null && modal}
      </>
    )
  }
)

TransactionDetailChargesFilter.displayName = "TransactionDetailChargesFilter"
