import { FormSectionHeader } from "components/Form"
import React, { memo, useMemo } from "react"
import { useFormState } from "react-final-form"
import { RateUpliftForm } from "../../types"
import { RateUpliftFourModel } from "./RateUpliftFourModel"
import { getPickedUpliftModelGuids, getPickedUpliftModels, isModelWithRates } from "../../helpers/upliftFormGetters"
import { sortUpliftItemsByIndex } from "../../helpers/sortUpliftItemsByIndex"
import { IndexedContractModel } from "data/finance/contractModel/types"

interface OwnProps {
  contracts: IndexedContractModel[]
}

export const RateUpliftFour: React.FC<OwnProps> = memo(({ contracts }) => {
  RateUpliftFour.displayName = "RateUpliftFour"

  const { values } = useFormState<RateUpliftForm>()

  const pickModelsByGuid = getPickedUpliftModelGuids(values)
  const sortedContractModels = sortUpliftItemsByIndex({ items: contracts, guids: pickModelsByGuid })

  const modelsWithRatesCount = useMemo(() => {
    const pickedModels = getPickedUpliftModels(values)
    return pickedModels.filter(isModelWithRates).length
  }, [values])

  return (
    <>
      <FormSectionHeader title="Summary" />
      {sortedContractModels.map((model) => (
        <RateUpliftFourModel
          {...{
            modelTitle: model.title,
            formModel: values.models[model.guid],
            defaultOpen: modelsWithRatesCount < 5
          }}
          key={model.guid}
        />
      ))}
    </>
  )
})
