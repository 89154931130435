import { ContractModelType } from "constants/modelTypes"
import { UseMutationResult } from "react-query"
import { useInvoiceTaskPostMutation, useInvoiceTaskPutMutation } from "../invoiceTask/mutations"
import { usePaymentTaskPostMutation, usePaymentTaskPutMutation } from "../paymentTask/mutations"
import { TaskPreparation, TaskPreparationPostPayload, TaskPreparationPutPayload } from "./types"

export const TaskMutationHandlers = {
  useTaskPrepare({
    modelType
  }: {
    modelType: ContractModelType
  }): UseMutationResult<TaskPreparation, Error, TaskPreparationPostPayload, unknown> {
    const isInvoiceModel = modelType === ContractModelType.INVOICE

    const invoiceResponse = useInvoiceTaskPostMutation()
    const paymentResponse = usePaymentTaskPostMutation()

    return isInvoiceModel ? invoiceResponse : paymentResponse
  },
  useTaskProcess({
    modelType
  }: {
    modelType: ContractModelType
  }): UseMutationResult<void, Error, TaskPreparationPutPayload> {
    const isInvoiceModel = modelType === ContractModelType.INVOICE

    const invoiceResponse = useInvoiceTaskPutMutation()
    const paymentResponse = usePaymentTaskPutMutation()

    return isInvoiceModel ? invoiceResponse : paymentResponse
  }
}
