import React, { memo } from "react"
import { Form, Field } from "react-final-form"

import Box from "@mui/material/Box"
import { Button, TextField } from "components/Form"
import { Visit } from "models/Visit"
import testingId from "constants/testingId"
import { useUpdateCommentMutation } from "data/visits"

interface OwnProps {
  visit: Visit
}

const VisitDetailCommentForm: React.FC<OwnProps> = ({ visit }) => {
  const { mutateAsync } = useUpdateCommentMutation({ visitId: visit.guid })

  const onCommentSubmit = async (values: { comment: string }) => {
    const payload = { comment: values.comment, visit: visit.guid }
    await mutateAsync(payload)
  }

  return (
    <Box m={3} mx={0}>
      <Form onSubmit={onCommentSubmit} initialValues={{ comment: visit?.comment as string }}>
        {({ handleSubmit, submitting, invalid, pristine, form }) => (
          <form onSubmit={handleSubmit}>
            <Field<string>
              id="visit-details-comments-text-area"
              label="Comments"
              component={TextField}
              name="comment"
              multiline
              minRows={5}
              data-cy={testingId.visit.detail.operator.comments}
            />
            <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
              <Button
                id="visit-details-comments-reset-btn"
                variant="text"
                fullWidth={false}
                onClick={() => {
                  form.reset()
                }}
              >
                Reset
              </Button>
              <Button
                id="visit-details-comments-save-btn"
                type="submit"
                fullWidth={false}
                sx={(theme) => ({
                  marginLeft: theme.spacing(2)
                })}
                disabled={invalid || submitting || pristine}
              >
                Save comments
              </Button>
            </Box>
          </form>
        )}
      </Form>
    </Box>
  )
}

export default memo(VisitDetailCommentForm)
