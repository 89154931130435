import { FormStage } from "constants/form"
import { ContractModelType } from "constants/modelTypes"
import {
  ApiCarerContract,
  ApiUserContractGuaranteedHoursRequest,
  ApiPaymentUserContractRateRequest,
  ApiUserContractMileageRequest,
  ApiUserContractTravelTimeRequest
} from "data/finance/carer/types"
import { ValidationErrors } from "final-form"
import { ValidationErrorsDefined } from "lib/types"
import validators, { composeValidators } from "lib/validators"
import { CarerContractFormValues } from "pages/Carers/types"
import { filter, isEmpty, isNil } from "ramda"
import { mergeValidationErrorsLeft, validateContract, validateOverlappingPeriods } from "./contractValidations"
import { ContractModel } from "data/finance/contractModel/types"

const validateGuaranteedHoursFields = (items?: ApiUserContractGuaranteedHoursRequest[]): ValidationErrorsDefined[] => {
  const errors: ValidationErrorsDefined[] = []

  items?.forEach((item, key) => {
    errors[key] = {}
    if (item.delete) return

    errors[key].start = composeValidators([validators.required, validators.validDate])(item.start)
    errors[key].end = validators.validDate(item.end)
    errors[key].hours = validators.validateCommonNumber()(item.hours)
    errors[key].frequency_type_alias = validators.required(item.frequency_type_alias)

    errors[key] = filter((errorKey) => !isNil(errorKey), errors[key])
  })

  return errors
}

const validateRateFields = (items?: ApiPaymentUserContractRateRequest[]): ValidationErrorsDefined[] => {
  const errors: ValidationErrorsDefined[] = []

  items?.forEach((item, key) => {
    errors[key] = {}
    if (item.delete) return

    errors[key].start = composeValidators([validators.required, validators.validDate])(item.start)
    errors[key].end = validators.validDate(item.end)
    errors[key].rate = validators.validateCommonNumber({ min: 0 })(item.rate)

    errors[key] = filter((errorKey) => !isNil(errorKey), errors[key])
  })

  return errors
}

const validateMileagesFields = (items?: ApiUserContractMileageRequest[]): ValidationErrorsDefined[] => {
  const errors: ValidationErrorsDefined[] = []

  items?.forEach((item, key) => {
    errors[key] = {}
    if (item.delete) return

    errors[key].start = composeValidators([validators.required, validators.validDate])(item.start)
    errors[key].end = validators.validDate(item.end)
    errors[key].pay_to_and_from_home = validators.required(item.pay_to_and_from_home)
    errors[key].rate = validators.validateCommonNumber()(item.rate)

    errors[key] = filter((errorKey) => !isNil(errorKey), errors[key])
  })

  return errors
}

const validateTravelTimesFields = (items?: ApiUserContractTravelTimeRequest[]): ValidationErrorsDefined[] => {
  const errors: ValidationErrorsDefined[] = []

  items?.forEach((item, key) => {
    errors[key] = {}
    if (item.delete) return

    errors[key].start = composeValidators([validators.required, validators.validDate])(item.start)
    errors[key].end = validators.validDate(item.end)
    errors[key].wait_time_threshold = validators.validateCommonNumber()(item.wait_time_threshold)
    errors[key].rate = validators.validateCommonNumber()(item.rate)

    errors[key] = filter((errorKey) => !isNil(errorKey), errors[key])
  })

  return errors
}

export const validateCarerContract =
  ({
    paymentModel,
    carerContracts = [],
    formStage
  }: {
    paymentModel?: ContractModel
    carerContracts?: ApiCarerContract[]
    formStage: FormStage
  }) =>
  (values: CarerContractFormValues): ValidationErrors | undefined => {
    let errors: ValidationErrors = {}

    // do not validate step one
    if (formStage === FormStage.CREATE) return errors

    if (paymentModel && values.start) {
      const contractErrors = validateContract({
        modelType: ContractModelType.PAYMENT,
        values: { ...values, no_end_date: values.current_role },
        contractModel: paymentModel,
        existingContracts: carerContracts
      })

      if (!isEmpty(contractErrors)) {
        errors = contractErrors
      }
    }

    errors.guaranteed_hours = mergeValidationErrorsLeft(
      validateGuaranteedHoursFields(values.guaranteed_hours),
      validateOverlappingPeriods<ApiUserContractGuaranteedHoursRequest, CarerContractFormValues>({
        items: values.guaranteed_hours,
        values,
        label: "Guaranteed hour"
      })
    )

    errors.rates = mergeValidationErrorsLeft(
      validateRateFields(values.rates),
      validateOverlappingPeriods<ApiPaymentUserContractRateRequest, CarerContractFormValues>({
        items: values.rates,
        values,
        label: "Rate"
      })
    )

    errors.mileages = mergeValidationErrorsLeft(
      validateMileagesFields(values.mileages),
      validateOverlappingPeriods<ApiUserContractMileageRequest, CarerContractFormValues>({
        items: values.mileages,
        values,
        label: "Rate"
      })
    )

    errors.travel_times = mergeValidationErrorsLeft(
      validateTravelTimesFields(values.travel_times),
      validateOverlappingPeriods<ApiUserContractTravelTimeRequest, CarerContractFormValues>({
        items: values.travel_times,
        values,
        label: "Travel Times"
      })
    )

    return errors
  }
