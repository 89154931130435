import React, { memo, useState } from "react"
import { AppBar, Tabs, Tab, Box } from "@mui/material"
import { ContentContainer, Divider, Header } from "components"
import { queryStringToObject } from "lib/queryString"
import { ContractModelType } from "constants/modelTypes"
import { DateTime } from "luxon"
import { InterfaceFileTab } from "./InterfaceFileTab"
import { InterfaceFileFilter, InterfaceFileFilterValues } from "./InterfaceFileFilter"
import { usePagination } from "components/handlers/usePagination"
import testingId from "constants/testingId"
import { useLocation } from "react-router-dom"

const defaultFilterValues = {
  period_start_from: DateTime.local().minus({ weeks: 1 }).startOf("week").toISODate() as string,
  period_start_to: DateTime.local().minus({ weeks: 1 }).endOf("week").toISODate() as string,
  modelType: ContractModelType.INVOICE
}

export const InterfaceFileTabs: React.FC = memo(() => {
  const location = useLocation()
  const [filter, setFilter] = useState<InterfaceFileFilterValues>({
    ...defaultFilterValues,
    ...queryStringToObject<InterfaceFileFilterValues>(location?.search)
  })

  const { pagination, setPagination, resetPagination } = usePagination()

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: ContractModelType) => {
    setFilter((values) => ({
      period_start_from: values.period_start_from,
      period_start_to: values.period_start_to,
      modelType: newValue
    }))
    resetPagination()
  }

  return (
    <ContentContainer>
      <Header {...{ title: "Interface File Viewer" }} />
      <Box {...{ m: 3, mx: 0 }}>
        <AppBar {...{ position: "static", color: "transparent" }}>
          <Tabs {...{ value: filter.modelType, onChange: handleTabChange, "aria-label": "Interface Files" }}>
            <Tab
              {...{
                "data-cy": testingId.interfaceFileViewer.invoicingTab,
                label: "Invoicing",
                value: ContractModelType.INVOICE
              }}
            />
            <Tab
              {...{
                "data-cy": testingId.interfaceFileViewer.paymentsTab,
                label: "Payments",
                value: ContractModelType.PAYMENT
              }}
            />
          </Tabs>
        </AppBar>
        <InterfaceFileFilter {...{ filter, setFilter, pagination, resetPagination, defaultFilterValues }} />
        <Divider color="divider" />
        <InterfaceFileTab {...{ pagination, setPagination }} />
      </Box>
    </ContentContainer>
  )
})

InterfaceFileTabs.displayName = "InterfaceFileTabs"
