import React from "react"
import { useShallow } from "zustand/react/shallow"
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import * as UIConfig from "../constants/ui"
import { useUIStore } from "stores"

const getStyles = (isDetailSidebarPresent: boolean) => ({
  sidebarOpen: css({
    marginLeft: isDetailSidebarPresent
      ? UIConfig.MAIN_NAV_WIDTH + UIConfig.SECONDARY_SIDEBAR_OPEN_WIDTH
      : UIConfig.MAIN_NAV_WIDTH,
    transition: "margin-left 0.05s linear",
    width: `calc(100% - ${
      isDetailSidebarPresent ? UIConfig.MAIN_NAV_WIDTH + UIConfig.SECONDARY_SIDEBAR_OPEN_WIDTH : UIConfig.MAIN_NAV_WIDTH
    }px)`
  }),
  sidebarClosed: css({
    marginLeft: isDetailSidebarPresent
      ? UIConfig.MAIN_NAV_COLLAPSED_WIDTH + UIConfig.SECONDARY_SIDEBAR_CLOSED_WIDTH
      : UIConfig.MAIN_NAV_COLLAPSED_WIDTH,
    transition: "margin-left 0.05s linear",
    width: `calc(100% - ${
      isDetailSidebarPresent
        ? UIConfig.MAIN_NAV_COLLAPSED_WIDTH + UIConfig.SECONDARY_SIDEBAR_CLOSED_WIDTH
        : UIConfig.MAIN_NAV_COLLAPSED_WIDTH
    }px)`
  })
})

const MainContent: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { isSidebarOpen, isDetailSidebarPresent } = useUIStore(
    useShallow((state) => ({
      isSidebarOpen: state.sidebar.isOpen,
      isDetailSidebarPresent: state.sidebar.isDetailSidebarPresent
    }))
  )

  const { sidebarClosed, sidebarOpen } = getStyles(isDetailSidebarPresent)
  const className = isSidebarOpen ? sidebarOpen : sidebarClosed

  return <main css={className}>{props.children}</main>
}
export default MainContent
