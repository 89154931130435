import React, { PropsWithChildren } from "react"
import { WizardPage } from "components/Wizard"
import { ApiUserCarePackagesClient, ApiUserClient } from "models"
import { FormSpy, FormSpyRenderProps } from "react-final-form"
import { ProfileForm, ContentContainer, CarePackagesFormGroup } from "components"
import { ProfileMode } from "lib/types"
import { ClientHeader } from "./ClientHeader"
import ClientProfileSectionCm from "./ClientProfileSectionCm"
import ClientProfileSectionOtherDetails from "./ClientProfileSectionOtherDetails"
import ClientProfileSectionStatus from "./ClientProfileSectionStatus"
import ClientProfileSectionAddress from "./ClientProfileSectionAddress"
import ProfileSectionPersonalDetails from "./ProfileSectionPersonalDetails"
import ClientProfileSectionContactDetails from "./ClientProfileSectionContactDetails"
import ClientProfileSectionFinance from "./ClientProfileSectionFinance"
import ClientProfileSectionPreference from "./ClientProfileSectionPreference"
import { getClientProfileValidators } from "../helpers/getClientProfileValidators"
import { getClientProfileDefaultValues } from "../helpers/getClientProfileDefaultValues"
import { useNavigate, useParams } from "react-router-dom"
import { useCreateClientMutation, useUpdateClientMutation } from "data/carer-profile/mutations"
import { useApiMessage } from "stores"

interface OwnProps {
  client?: ApiUserClient
  mode: ProfileMode
  isClientPrefv2Enabled: boolean
}

const ClientProfile = ({ mode, client, isClientPrefv2Enabled }: OwnProps) => {
  const navigate = useNavigate()
  const { mutateAsync: mutateCreateClient } = useCreateClientMutation()
  const { mutate: mutateUpdateClient } = useUpdateClientMutation(client?.guid)
  const { showSuccessMessage } = useApiMessage()

  const { section = "" } = useParams()
  const isCreateMode = mode === "create"

  const onSubmit = (values: ApiUserClient) => {
    if (isCreateMode) {
      mutateCreateClient(values).then((response) => {
        showSuccessMessage("New client successfully created.")
        const client = response as ApiUserClient
        navigate(`/clients/${client.guid}/profile/personal-details`)
      })
    } else {
      mutateUpdateClient(values)
    }
  }

  const customValidation = ({ values }: { values: ApiUserClient }) =>
    Object.keys(values.care_packages ?? []).some(
      (value) => values.care_packages[value as keyof ApiUserCarePackagesClient]
    )
      ? undefined
      : {
          "care_packages.nurse_led": {
            error: "At least one field is required"
          }
        }

  return (
    <Wrapper {...{ isCreateMode, client }}>
      <ProfileForm
        onSubmit={onSubmit}
        sections={["personal-details", "financial-details", "care-packages"]}
        mode={mode}
        section={section}
        user={client}
        createUrl="/clients/new"
        initialValues={getClientProfileDefaultValues()}
        schemaValidators={getClientProfileValidators()}
        customValidation={customValidation}
      >
        <WizardPage
          validate={[
            "status",
            "hr.start_date",
            // personal details
            "profile.salutation",
            "profile.first_name",
            "profile.last_name",
            "profile.short_name",
            // address
            "profile.address",
            "regions",
            // contact details
            "profile.phone_mobile",
            "profile.phone_landline",
            "profile.email",
            // other details
            "profile.date_of_birth",
            "profile.gender"
          ]}
        >
          <FormSpy subscription={{ values: true }}>
            {({ values }: FormSpyRenderProps<ApiUserClient>) => {
              return <ClientProfileSectionStatus formStatusValue={values.status} />
            }}
          </FormSpy>
          <ProfileSectionPersonalDetails />
          <ClientProfileSectionAddress />
          <ClientProfileSectionContactDetails />
          <ClientProfileSectionOtherDetails />
          <ClientProfileSectionCm />
        </WizardPage>
        <WizardPage>
          <ClientProfileSectionFinance />
        </WizardPage>
        <WizardPage>
          <CarePackagesFormGroup
            title="Care packages"
            fieldNames={[
              "care_packages.reablement",
              "care_packages.end_of_life",
              "care_packages.domiciliary",
              "care_packages.extra_care",
              "care_packages.short_term_live_in",
              "care_packages.permanent_live_in",
              "care_packages.nurse_led",
              "care_packages.learning_disability",
              "care_packages.mental_health",
              "care_packages.drug_and_alcohol"
            ]}
          />
          {!isClientPrefv2Enabled && (
            <>
              <ClientProfileSectionPreference
                title="Care preferences"
                fields={{
                  left: [
                    { name: "preferences.a_driver", label: "A driver" },
                    { name: "preferences.cat_friendly", label: "Cat friendly" },
                    { name: "preferences.happy_with_male_carers", label: "Happy with male carers" },
                    { name: "preferences.smoking", label: "Smoking" }
                  ],
                  right: [
                    { name: "preferences.a_car_owner", label: "A car owner" },
                    { name: "preferences.dog_friendly", label: "Dog friendly" },
                    { name: "preferences.happy_with_female_carers", label: "Happy with female carers" }
                  ]
                }}
              />
              <ClientProfileSectionPreference
                title="Carer culture"
                fields={{
                  left: [
                    { name: "preferences.caucasian_british", label: "Caucasian - British" },
                    {
                      name: "preferences.asian_indian_pakistan_bangladesh",
                      label: "Asian - Indian, Pakistan, Bangladesh"
                    },
                    { name: "preferences.african", label: "African" },
                    { name: "preferences.black_african", label: "Black African" },
                    { name: "preferences.arab", label: "Arab" },
                    { name: "preferences.other", label: "Other" }
                  ],
                  right: [
                    { name: "preferences.caucasian_other", label: "Caucasian - Other" },
                    { name: "preferences.asian_other", label: "Asian - Other" },
                    { name: "preferences.black_caribbean", label: "Black Caribbean" },
                    { name: "preferences.black_other", label: "Black - Other" },
                    { name: "preferences.mixed", label: "Mixed" }
                  ]
                }}
              />
            </>
          )}
        </WizardPage>
      </ProfileForm>
    </Wrapper>
  )
}

export default ClientProfile

const Wrapper: React.FC<PropsWithChildren<{ isCreateMode: boolean; client?: ApiUserClient }>> = ({
  children,
  isCreateMode,
  client
}) => {
  return isCreateMode ? (
    <>{children}</>
  ) : (
    <ContentContainer>
      <ClientHeader {...{ client }} />
      {children}
    </ContentContainer>
  )
}
