import { TableCell, TableRow } from "@mui/material"
import { SelectOption } from "components/Form/Select"
import React, { memo } from "react"
import { RateUpliftForm } from "../../types"
import { useFormState } from "react-final-form"
import { SelectDummy } from "components/Form/SelectDummy"
import { get } from "lodash"
import { TextFieldDummy } from "components/Form/TextFieldDummy"
import { grey } from "@mui/material/colors"
import {
  getUpliftEnhancedRateMultiplier,
  getUpliftEnhancedRatePrice,
  getUpliftNewEnhancedRate,
  useGetUpliftDayTypeLabel
} from "../../helpers/upliftFormGetters"
import { ContractModelEnhancedRate, ContractModelRate } from "data/finance/contractModel/types"

interface OwnProps {
  contractGuid: string
  serviceGuid: string
  bandGuid: string
  rate?: ContractModelRate
  enhancedRate: ContractModelEnhancedRate
  dayTypeOptions: SelectOption[]
}

export const RateUpliftThreeBandEnhancedRate: React.FC<OwnProps> = memo(
  ({ serviceGuid, contractGuid, bandGuid, rate, enhancedRate, dayTypeOptions }) => {
    RateUpliftThreeBandEnhancedRate.displayName = "RateUpliftThreeBandEnhancedRate"

    const { values } = useFormState<RateUpliftForm>()

    const basicFormRate: number = get(
      values,
      `models[${contractGuid}].services[${serviceGuid}].bands[${bandGuid}].rates[${rate?.guid}].new_rate`,
      0
    )

    const newEnhancedRate = getUpliftNewEnhancedRate({ formRateNewValue: basicFormRate, enhancedRate })

    const getDayTypeLabel = useGetUpliftDayTypeLabel(dayTypeOptions)

    if (!rate) return null

    return (
      <TableRow>
        <TableCell
          sx={{
            padding: "0px 5px"
          }}
        >
          <SelectDummy value={getDayTypeLabel(enhancedRate.holiday_tier_guid || enhancedRate.day_type_alias)} />
        </TableCell>
        <TableCell
          sx={{
            padding: "0px 5px"
          }}
        >
          <SelectDummy
            value={`${getDayTypeLabel(rate.holiday_tier_guid || rate.day_type_alias)} (${rate.start} - ${rate.end})`}
          />
        </TableCell>
        <TableCell
          sx={{
            padding: "0px 5px",
            color: grey[500]
          }}
        >
          {getUpliftEnhancedRateMultiplier(enhancedRate)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0px 5px"
          }}
        >
          <TextFieldDummy value={getUpliftEnhancedRatePrice({ enhancedRate, standardRate: rate })} />
        </TableCell>
        <TableCell
          sx={{
            padding: "0px 5px"
          }}
        >
          <TextFieldDummy value={newEnhancedRate} />
        </TableCell>
      </TableRow>
    )
  }
)
