import { useMemo } from "react"
import { countBy, groupBy, map, sumBy } from "lodash"
import { InvoiceFileItem } from "data/finance/invoiceFile/types"
import { SummaryItem } from "../types"

const useDownloadSummaryList = (invoices: InvoiceFileItem[]): SummaryItem[] => {
  return useMemo(() => {
    const groupedResult = groupBy(invoices, (item) => item.contract_model_name)
    return map(groupedResult, (contract, key) => {
      const totalEntity = sumBy(map(countBy(groupBy(contract, "user_guid"), (val) => ({ total: val }))))
      const totalAmount = sumBy(contract, "rate")

      return {
        contract_model_title: key,
        users: totalEntity,
        total: totalAmount
      }
    })
  }, [invoices])
}

export default useDownloadSummaryList
