import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { Divider } from "components"
import React, { memo } from "react"
import { RateUpliftForm } from "../../types"
import { FormApi } from "final-form"
import { SelectOption } from "components/Form/Select"
import { RateUpliftThreeBandEnhancedRate } from "./RateUpliftThreeBandEnhancedRate"
import { getUpliftBasicRate } from "../../helpers/upliftFormGetters"
import { ContractModelEnhancedRate, ContractModelRate } from "data/finance/contractModel/types"

const headers = ["Day Type", "Standard Rate", "Multiplier", "Current Fixed Price", "New Fixed Price"]

interface OwnProps {
  contractGuid: string
  serviceGuid: string
  bandGuid: string
  form: FormApi<RateUpliftForm>
  rates: ContractModelRate[]
  enhancedRates: ContractModelEnhancedRate[]
  dayTypeOptions: SelectOption[]
}

export const RateUpliftThreeBandEnhancedRates: React.FC<OwnProps> = memo(
  ({ serviceGuid, contractGuid, bandGuid, form, rates, enhancedRates, dayTypeOptions }) => {
    RateUpliftThreeBandEnhancedRates.displayName = "RateUpliftThreeBandEnhancedRates"

    return rates.length ? (
      <Box
        sx={{
          marginTop: "15px",
          padding: "20px 15px",
          backgroundColor: "#fff"
        }}
      >
        <Typography
          sx={{
            textTransform: "uppercase",
            color: grey[500],
            fontSize: "1em",
            fontWeight: "bold"
          }}
        >
          Enhanced Rate
        </Typography>
        <Divider color="divider" />
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((label) => (
                <TableCell
                  sx={{
                    width: "20%",
                    padding: "5px",
                    border: "none"
                  }}
                  key={label}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {enhancedRates.map((enhancedRate) => {
              return (
                <RateUpliftThreeBandEnhancedRate
                  {...{
                    serviceGuid,
                    contractGuid: contractGuid,
                    bandGuid: bandGuid,
                    enhancedRate,
                    rate: getUpliftBasicRate({ rates, enhancedRate }),
                    dayTypeOptions,
                    form
                  }}
                  key={enhancedRate.guid}
                />
              )
            })}
          </TableBody>
        </Table>
      </Box>
    ) : (
      <Box
        sx={{
          marginTop: "15px",
          padding: "20px 15px",
          backgroundColor: "#fff"
        }}
      >
        No Enhanced Rates in this Band.
      </Box>
    )
  }
)
