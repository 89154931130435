import React from "react"
import VisitDetail from "../Visits/VisitDetail"
import ClientCarePlan from "./ClientCarePlan"
import ClientDocuments from "./ClientDocuments"
import ClientProfile from "./components/ClientProfile"
import ClientRelativeProfile from "./ClientRelativeProfile"
import ClientContractList from "./ClientContractList"
import { ClientContractDetail } from "./ClientContractDetail"
import { MicroFrontend } from "pages"
import Config from "lib/config"
import CompanyInfo, { CompanyBranchFeatures } from "lib/CompanyInfo"
import { ClientDetailSidebar } from "./components/ClientDetailSidebar"
import { DataLoading } from "components"
import { useClientDetailQuery } from "data/core/client/queries"
import ClientRelativeList from "./ClientRelativeList"
import { Navigate, Route, useParams } from "react-router-dom"
import { useAuthStore } from "stores"
import useBranchSettings from "lib/hooks/branchInfo/useBranchSettings"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { BranchFeatures } from "lib/hooks/branchInfo/types"
import SentryRoutes from "components/Routes"

const ClientDetail: React.FC = () => {
  const { id } = useParams()
  const { settings: branchSettings } = useBranchSettings()
  const { data: client, isLoading } = useClientDetailQuery({ id })
  const user = useAuthStore((state) => state.user)
  const { data: hasDigitalCareplanV2, isLoading: isLoadingDigitalCareplanV2 } = CompanyInfo.useBranchFeatures(
    user?.branch_guid,
    CompanyBranchFeatures.DIGITAL_CAREPLAN_V2
  )

  const { data: isClientProfileV2Enabled } = CompanyInfo.useBranchFeatures(
    user?.branch_guid,
    CompanyBranchFeatures.CLIENT_PROFILE_V2
  )
  const isCreateClientProfileUIV2Enabled =
    branchSettings["FeatureCCHTeamCreateClientProfileUIV2"]?.toLowerCase() === "true"
  const isClientProfileV2 = isCreateClientProfileUIV2Enabled && isClientProfileV2Enabled

  const clientMfeHost = Config.getEnvVariable("APP_CLIENTS_MFE_URL")
  const isClientPrefv2Enabled = branchSettings["FeatureCCHTeamClientPrefv2"]?.toLowerCase() === "true"

  const isOutcomesEnabled = branchSettings["FeatureCCHTeamOutcomes"]?.toLowerCase() === "true"

  const { hasBranchPermission } = useBranchPermissions()

  const eMarsHost = Config.getEnvVariable("APP_EMARS_URL")
  const hasEmarsFlag = hasBranchPermission(BranchFeatures.EMARS)

  const schedulerHost = Config.getEnvVariable("APP_SCHEDULER_URL")
  const hasSchedulerFlag = hasBranchPermission(BranchFeatures.SCHEDULER)

  const hasClientAbsenceFlag = Config.getEnvVariable("APP_FEATURE_CLIENT_ABSENCE").toLowerCase() === "true"

  const newCarePlanHost = Config.getEnvVariable("APP_CERA_CARE_PLAN_URL")

  const visitsHost = Config.getEnvVariable("APP_VISITS_URL")

  if (isLoading) {
    return <DataLoading />
  }

  if (!client) {
    return null
  }

  return (
    <>
      <ClientDetailSidebar
        client={client}
        hasDigitalCareplanV2={hasDigitalCareplanV2}
        hasEmarsFlag={hasEmarsFlag}
        hasSchedulerFlag={hasSchedulerFlag}
        hasClientAbsenceFlag={hasClientAbsenceFlag}
        isClientPrefv2Enabled={isClientPrefv2Enabled}
        isOutcomesEnabled={isOutcomesEnabled}
        isClientProfileV2={isClientProfileV2}
      />
      <SentryRoutes>
        {isClientProfileV2 ? (
          <>
            <Route path="profile" element={<MicroFrontend name="Clients" host={clientMfeHost} />} />
            <Route path="profile/:section" element={<Navigate replace to={`/clients/${client.guid}/profile`} />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate replace to={`/clients/${client.guid}/profile/personal-details`} />} />
            <Route
              path="profile/:section"
              element={<ClientProfile mode="update" client={client} isClientPrefv2Enabled={isClientPrefv2Enabled} />}
            />
          </>
        )}
        <Route path="contracts" element={<ClientContractList client={client} />} />
        <Route path="/contracts/new" element={<ClientContractDetail client={client} />} />
        <Route path="/contracts/:contractId" element={<ClientContractDetail client={client} />} />
        <Route path="documents" element={<ClientDocuments client={client} />} />
        <Route path="careplan" element={<ClientCarePlan client={client} />} />
        <Route path="relative" element={<ClientRelativeList client={client} />} />
        <Route path="relative/new/:section" element={<ClientRelativeProfile mode="create" client={client} />} />
        <Route
          path="relative/:relativeId/profile/:section"
          element={<ClientRelativeProfile mode="update" client={client} />}
        />

        {hasDigitalCareplanV2 && !isLoadingDigitalCareplanV2 && (
          <Route
            path="/new-careplan/*"
            element={<MicroFrontend client={client} name="NewCarePlan" host={newCarePlanHost} />}
          />
        )}
        {hasEmarsFlag && (
          <Route path="/emars" element={<MicroFrontend client={client} name="EMars" host={eMarsHost} />} />
        )}
        {hasSchedulerFlag && (
          <Route
            path="/client-schedule"
            element={<MicroFrontend client={client} name="Scheduler" loggedUser={user} host={schedulerHost} />}
          />
        )}
        {hasSchedulerFlag && (
          <Route
            path="/scheduler-client-weekly"
            element={<MicroFrontend client={client} name="Scheduler" loggedUser={user} host={schedulerHost} />}
          />
        )}
        {hasClientAbsenceFlag && (
          <>
            <Route path="/absences" element={<MicroFrontend client={client} name="Clients" host={clientMfeHost} />} />
            <Route path="/absences/*" element={<MicroFrontend client={client} name="Clients" host={clientMfeHost} />} />
          </>
        )}
        <Route path="visits/detail/:id" element={<VisitDetail user={client} />} />

        <Route
          path="/visits"
          element={<MicroFrontend name="Visits" host={visitsHost} loggedUser={user} client={client} />}
        />

        {isClientPrefv2Enabled && (
          <Route
            path="/care-preferences"
            element={<MicroFrontend client={client} name="Clients" loggedUser={user} host={clientMfeHost} />}
          />
        )}

        {isOutcomesEnabled && (
          <Route
            path="/outcomes/*"
            element={<MicroFrontend client={client} name="Clients" loggedUser={user} host={clientMfeHost} />}
          />
        )}
      </SentryRoutes>
    </>
  )
}

export default ClientDetail
