import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { EmptyData, Paginator } from "components"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import testingId from "constants/testingId"
import { useInvoiceFileInterfaceListQuery } from "data/finance/invoiceFile/queries"
import { usePaymentFileInterfaceListQuery } from "data/finance/paymentFile/queries"
import { useIsInvoiceModelSearch } from "lib/hooks/useIsInvoiceModelSearch"
import { Pagination, SetPagination } from "lib/types"
import React, { memo } from "react"
import { InterfaceFileTabItem } from "./InterfaceFileTabItem"
import { useLocation } from "react-router-dom"

interface OwnProps {
  pagination: Pagination
  setPagination: SetPagination
}

export const InterfaceFileTab: React.FC<OwnProps> = memo(({ pagination, setPagination }) => {
  const location = useLocation()
  const isInvoice = useIsInvoiceModelSearch()

  const { data: invoiceInterfaceFiles } = useInvoiceFileInterfaceListQuery({
    enabled: isInvoice,
    search: location.search
  })
  const { data: paymentInterfaceFiles } = usePaymentFileInterfaceListQuery({
    enabled: !isInvoice,
    search: location.search
  })

  const interfaceFiles = isInvoice ? invoiceInterfaceFiles : paymentInterfaceFiles

  const header = ["Reference", "Total", "Contracts count", "Total users count", "Download"]

  return (
    <RequestLoaderWrapper>
      <Box {...{ p: 2, px: 0, display: "flex", justifyContent: "flex-end" }}>
        {interfaceFiles?.length ? (
          <Table>
            <TableHead>
              <TableRow>
                {header.map((title) => (
                  <TableCell data-cy={testingId.tableHeaderCell} key={title}>
                    {title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {interfaceFiles.map((file) => {
                return <InterfaceFileTabItem {...{ file, isInvoice }} key={file.guid} />
              })}
            </TableBody>
          </Table>
        ) : (
          <EmptyData {...{ message: "No files found." }} />
        )}
      </Box>
      {pagination.page !== "1" || interfaceFiles?.length !== 0 ? (
        <Paginator {...{ setPagination, itemsLength: interfaceFiles?.length }} />
      ) : null}
    </RequestLoaderWrapper>
  )
})

InterfaceFileTab.displayName = "InterfaceFileTab"
