import { Box } from "@mui/material"
import { ContentContainer, EmptyData, Header, Paginator } from "components"
import { usePagination } from "components/handlers/usePagination"
import testingId from "constants/testingId"
import { useDownloadListQuery } from "data/download/queries"
import { ApiDownloadFilter, ApiDownloadStatus } from "data/download/types"
import { queryStringToObject } from "lib/queryString"
import { DateTime } from "luxon"
import React, { memo, useEffect, useMemo, useState } from "react"
import { MyDownloadFilter } from "./components/MyDownloadFilter"
import { MyDownloadListTable } from "./components/MyDownloadListTable"
import { useLocation } from "react-router-dom"

const defaultFilterValues = {
  start: DateTime.local().minus({ weeks: 1 }).startOf("week").toISODate() as string
}

export const MyDownloadList: React.FC = memo(() => {
  const location = useLocation()
  const search = useMemo(() => queryStringToObject<ApiDownloadFilter>(location?.search), [location])

  const [enableRefetchInterval, setEnableRefetchInterval] = useState<boolean>(false)

  const { data: downloadList } = useDownloadListQuery({
    query: location?.search,
    withSearch: true,
    enableRefetchInterval
  })

  useEffect(() => {
    if (downloadList?.some((item) => item.status === ApiDownloadStatus.PENDING)) {
      setEnableRefetchInterval(true)
    } else {
      setEnableRefetchInterval(false)
    }
  }, [downloadList])

  const [filter, setFilter] = useState<ApiDownloadFilter>({
    ...defaultFilterValues,
    ...search
  })
  const { pagination, setPagination, resetPagination } = usePagination()

  return (
    <ContentContainer>
      <Header {...{ "data-cy": testingId.pageTitle, title: "Downloads" }} />
      <MyDownloadFilter {...{ filter, setFilter, resetPagination, pagination, defaultFilterValues }} />
      {downloadList?.length ? (
        <>
          <Box {...{ m: 3, mx: 0 }}>
            <MyDownloadListTable {...{ downloadList, searchJobGuid: search.job_guid }} />
          </Box>
          <Paginator {...{ setPagination, itemsLength: downloadList.length }} />
        </>
      ) : (
        <EmptyData {...{ message: "No files found." }} />
      )}
    </ContentContainer>
  )
})

MyDownloadList.displayName = "MyDownloadList"
