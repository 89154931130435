import { DateTime } from "luxon"
import { getDate, getNewestPeriod } from "../components/calendars/dateUtils"
import { AddPaymentCalendarRequest, CalendarPeriod } from "data/finance/contractModel/types"

export const getPaymentCalendarPeriodDates = (values: AddPaymentCalendarRequest): CalendarPeriod[] => {
  const { start, frequency_type_alias = "1/WEEK", periodCount = 0, processDays, periods = [] } = values

  const newPeriods: CalendarPeriod[] = []

  let startDate = start || DateTime.local()

  for (let i = 0; i < periodCount; i++) {
    if (periods.length > 0 && i === 0) {
      const last = getNewestPeriod(periods)
      startDate = last.end.plus({ days: 1 })
    } else if (newPeriods.length > 0) {
      startDate = newPeriods[i - 1].end.plus({ days: 1 })
    }

    const getValueDate = getDate(frequency_type_alias, startDate, processDays)
    const period = {
      guid: `new-item-${periods.length + i}`,
      start: getValueDate("start"),
      end: getValueDate("end"),
      process_date: getValueDate("processDate")
    } as CalendarPeriod

    newPeriods.push(period)
  }

  return newPeriods
}
