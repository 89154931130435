import { Box, Grid } from "@mui/material"
import { ModalWrapper, NoEndDateCheckbox } from "components"
import { AutocompleteMui, Button, Select, TextField } from "components/Form"
import { ContractModelType } from "constants/modelTypes"
import testingId from "constants/testingId"
import { useContractModelServiceCreateMutation } from "data/finance/contractModel/mutations"
import { ContractModelService, ContractModelServiceFormProps, ContractModel } from "data/finance/contractModel/types"
import { DateTime } from "luxon"
import React, { memo, useCallback } from "react"
import { Field, Form } from "react-final-form"
import { validateCopiedService } from "../validators/validateCopiedService"
import validators, { DEFAULT_MAX_NUMBER, composeUnrequiredValidators } from "lib/validators"
import { FORM_ERROR } from "final-form"
import BottomErrorMessage from "components/BottomErrorMessage"
import { useSchema } from "data/schema/useSchema"
import { SelectDummy } from "components/Form/SelectDummy"
import { DateField } from "components/Form/DateField"
import { removeUnassigned } from "data/schema/helpers"

interface OwnProps {
  destroyModal: () => void
  services?: ContractModelService[]
  service: ContractModelService
  modelType: ContractModelType
  isInvoicePaymentService?: boolean
  contractModel: ContractModel
}

export const ServiceCopyModal: React.FC<OwnProps> = memo(
  ({ destroyModal, services, service, modelType, isInvoicePaymentService, contractModel }) => {
    const { PaymentType, ContractServiceType, PaymentContract } = useSchema([
      "PaymentType",
      "ContractServiceType",
      isInvoicePaymentService ? "PaymentContract" : ""
    ])
    const paymentModelOptions = removeUnassigned(PaymentContract)

    const { mutateAsync: createService } = useContractModelServiceCreateMutation({
      modelType,
      isInvoicePaymentService,
      contractModelGuid: contractModel.guid
    })

    const onSubmit = useCallback(
      (values: ContractModelServiceFormProps) => {
        const { no_end_date, description, ...rest } = values
        const payload = {
          ...rest,
          description: description ? description : undefined,
          end: no_end_date ? undefined : values.end
        }

        createService({ payload, serviceGuid: service.guid })
        destroyModal()
      },
      [service, createService, destroyModal]
    )

    const validate = useCallback(
      (values: ContractModelServiceFormProps) => {
        return validateCopiedService({ values, services, contractModel, originalService: service })
      },
      [services, service, contractModel]
    )

    const initialValues = {
      payment_type_alias: service.payment_type_alias,
      type_guid: service.service_type.guid,
      start: service.end || DateTime.local(),
      description: service.description,
      ...(isInvoicePaymentService
        ? {
            payment_contract_guid: service.payment_contract_guid,
            mileage_rate: service.mileage_rate,
            travel_rate: service.travel_rate
          }
        : {})
    }

    return (
      <ModalWrapper
        {...{
          title: "Copy Contract Service",
          width: 700,
          destroyModal,
          "data-cy": testingId.contractModel.services.copyModal
        }}
      >
        <Form {...{ initialValues, onSubmit, validate }}>
          {({ handleSubmit, values, errors }) => {
            const formError = errors ? errors[FORM_ERROR] : null

            return (
              <form {...{ onSubmit: handleSubmit }}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    {isInvoicePaymentService ? (
                      <Field
                        name="payment_contract_guid"
                        label="Payment Group *"
                        component={AutocompleteMui}
                        options={paymentModelOptions}
                        validate={validators.required}
                      />
                    ) : null}
                    {PaymentType ? (
                      <Field
                        name="payment_type_alias"
                        label="Price Type *"
                        component={Select}
                        options={PaymentType}
                        validate={validators.required}
                      />
                    ) : (
                      <SelectDummy value="loading..." />
                    )}
                  </Grid>
                  <Grid item md={6}>
                    <Field
                      name="type_guid"
                      label="Care Type *"
                      component={AutocompleteMui}
                      options={ContractServiceType}
                      validate={validators.required}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DateField name="start" label="Starts on" required minDate={contractModel.start} />
                  </Grid>
                  <Grid item md={6}>
                    <DateField
                      name="end"
                      label="Ends before"
                      disabled={values.no_end_date}
                      maxDate={contractModel.end || undefined}
                    />

                    <NoEndDateCheckbox />
                  </Grid>

                  {isInvoicePaymentService && (
                    <>
                      <Grid item md={6}>
                        <Field
                          name="mileage_rate"
                          label="Mileage Rate"
                          component={TextField}
                          validate={composeUnrequiredValidators([validators.availableValueMustBeANumber(0, 1)])}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Field
                          name="travel_rate"
                          label="Travel Rate"
                          component={TextField}
                          validate={composeUnrequiredValidators([
                            validators.availableValueMustBeANumber(0, DEFAULT_MAX_NUMBER)
                          ])}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item md={6}>
                    <Field
                      name="description"
                      label="Description"
                      component={TextField}
                      validate={validators.maxLength(1000)}
                      multiline
                      minRows={5}
                    />
                  </Grid>
                </Grid>

                {!!formError && (
                  <Grid container>
                    <Grid item md={12}>
                      <BottomErrorMessage message={formError} />
                    </Grid>
                  </Grid>
                )}

                <Box m={3} mx={0} display="flex" justifyContent="flex-end">
                  <Button fullWidth={false} variant="text" onClick={destroyModal}>
                    Cancel
                  </Button>
                  <Button
                    fullWidth={false}
                    color="primary"
                    type="submit"
                    sx={(theme) => ({
                      marginLeft: theme.spacing(3)
                    })}
                  >
                    Save
                  </Button>
                </Box>
              </form>
            )
          }}
        </Form>
      </ModalWrapper>
    )
  }
)

ServiceCopyModal.displayName = "ServiceCopyModal"
