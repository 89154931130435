import { Box, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { getByGuid } from "helpers/getByGuid"
import React, { memo, useMemo } from "react"
import { isServicesWithRates } from "../../helpers/upliftFormGetters"
import { ContractModelServiceUplift, RateUpliftFormService, RateUpliftTypeEnum } from "../../types"
import { RateUpliftFourServiceBand } from "./RateUpliftFourServiceBand"

interface OwnProps {
  formService: RateUpliftFormService
  service: ContractModelServiceUplift
}

interface UpliftTypeProps {
  formService: RateUpliftFormService
}

const UpliftTypeTitle: React.FC<UpliftTypeProps> = memo(({ formService }) => {
  UpliftTypeTitle.displayName = "UpliftTypeTitle"

  const upliftTypeLabel = useMemo(() => {
    const options = {
      [RateUpliftTypeEnum.FIXED_AMOUNT]: "Fixed Price (£):",
      [RateUpliftTypeEnum.MANUAL]: "Manual",
      [RateUpliftTypeEnum.PERCENTAGE]: "Percentage (%):"
    }
    if (!formService.uplift_type) return ""
    return options[formService.uplift_type]
  }, [formService.uplift_type])

  const hasRates = isServicesWithRates(formService)
  if (!hasRates) return null

  const isManual = formService.uplift_type === RateUpliftTypeEnum.MANUAL

  return (
    <Typography
      sx={{
        padding: "10px 15px",
        border: "none"
      }}
    >
      Uplift type: {upliftTypeLabel}
      {!isManual && (
        <Typography
          component="span"
          sx={{
            paddingLeft: 20
          }}
        >
          {formService.uplift_value ?? ""}
        </Typography>
      )}
    </Typography>
  )
})

export const RateUpliftFourService: React.FC<OwnProps> = memo(({ formService, service }) => {
  RateUpliftFourService.displayName = "RateUpliftFourService"

  return (
    <Box>
      <Typography
        sx={{
          fontSize: "16px",
          marginTop: "15px",
          padding: "10px 15px",
          border: `solid ${grey[400]}`,
          borderWidth: "1px 0",
          backgroundColor: grey[200]
        }}
      >
        {service.isPayment ? "Payment Service" : "Service"}: {service.service_type.title}
      </Typography>
      <UpliftTypeTitle {...{ formService }} />
      {formService.bands ? (
        Object.keys(formService.bands).map((bandGuid) => {
          const formBand = (formService.bands || {})[bandGuid]
          const band = getByGuid({ items: formService.bandItems, guid: bandGuid })
          if (!formBand || !band) return null
          return <RateUpliftFourServiceBand key={bandGuid} {...{ formBand, band }} />
        })
      ) : (
        <Typography
          component="span"
          sx={{
            display: "block",
            padding: "10px 0",
            border: "none"
          }}
        >
          This Service does not have any Rates
        </Typography>
      )}
    </Box>
  )
})
