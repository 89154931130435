import React, { memo, useMemo } from "react"
import { Box, Typography } from "@mui/material"
import testingId from "constants/testingId"
import { HolidayCalculationItemDetails } from "data/finance/paymentProcessing/types"
import { moneyFormat } from "lib/utils"
import { sum } from "ramda"

interface OwnProps {
  items: HolidayCalculationItemDetails[]
}

const Item = ({ label, result }: { label: string; result: number | string }) => {
  return (
    <>
      <Typography
        component="span"
        sx={{
          fontSize: "20px",
          marginLeft: "30px"
        }}
      >
        {label}:&nbsp;
      </Typography>
      <Typography component="span" sx={(theme) => ({ fontSize: "20px", color: theme.palette.primary.main })}>
        {result}
      </Typography>
    </>
  )
}

export const HolidayEstimationTotals: React.FC<OwnProps> = memo(({ items }) => {
  HolidayEstimationTotals.displayName = "HolidayEstimationTotals"

  const totals = useMemo(() => {
    const includedItems = items.filter((i) => !i.excluded)
    const sumBy = (name: keyof Pick<HolidayCalculationItemDetails, "hours" | "days" | "pay">): number =>
      sum(includedItems.map((i) => i[name]))

    return {
      hours: sumBy("hours"),
      days: sumBy("days"),
      amount: sumBy("pay")
    }
  }, [items])

  return (
    <Box
      sx={{
        textAlign: "right",
        marginTop: "40px"
      }}
      data-cy={testingId.holidayEstimation.totals}
    >
      <Item label="Total Hours" result={totals.hours} />
      <Item label="Total Days" result={totals.days} />
      <Item label="Total Amount" result={moneyFormat.format(totals.amount)} />
    </Box>
  )
})
