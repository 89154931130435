export interface ApiMobilityAssessment {
  service_user_id: string
  perceived_fall_risk: string
  mobility_balance_change: string
  service_user_perceived_mobility: string
  home_environment_fall_risk: string
  assessment_confidence: string
  local_authority_initiative: string
}

export enum RiskScoreValues {
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High"
}

export type RiskScoreValue = RiskScoreValues.LOW | RiskScoreValues.MEDIUM | RiskScoreValues.HIGH

export interface RiskScoreRecord {
  date: string
  type: string
  value: RiskScoreValue | null
}
