import { downloadFileSimple } from "lib/utils"
import { useCallback, useEffect, useState } from "react"
import { useDownloadMutation } from "data/download/mutations"
import { ApiDownloadMergeTypeEnum } from "data/download/types"
import { ApiUserContractFile, UserContractType } from "./types"
import { useUserContractFileDownloadQuery } from "./queries"
import { DateTime } from "luxon"
import { DateTimeFormat } from "lib/datetime"

export const useContractFileDownloadHandler = ({
  userGuid,
  contractGuid,
  files,
  userType
}: {
  userGuid?: string
  contractGuid?: string
  files: ApiUserContractFile[]
  userType: UserContractType
}): ((values: Record<string, boolean>) => void) => {
  const [filesToDownload, setFilesToDownload] = useState<ApiUserContractFile[]>([])
  const resetFileGuids = () => setFilesToDownload([])

  // one file
  const isOneFile = filesToDownload?.length === 1

  const { data: contractFile } = useUserContractFileDownloadQuery({
    enabled: isOneFile,
    userGuid,
    contractGuid,
    fileGuid: filesToDownload[0]?.guid,
    userType
  })

  useEffect(() => {
    if (isOneFile && contractFile) {
      downloadFileSimple({ href: contractFile.link, fileName: "contract_file", mode: "save&open" })
      resetFileGuids()
    }
  }, [isOneFile, contractFile])

  // more files
  const isMoreFiles = filesToDownload?.length > 1
  const { mutateAsync: postDownloadFiles } = useDownloadMutation()

  const firstFileType = filesToDownload[0]?.file_type_alias || ""
  const isExport = firstFileType.substring(0, 2) === "EX"
  const areAllFilesSameType = filesToDownload.every((file) => {
    return file.file_type_alias === firstFileType
  })
  const date = DateTime.local().toFormat(DateTimeFormat.DATE_FILE)

  if (isMoreFiles && areAllFilesSameType && isExport) {
    const fileName = `${userType}_${firstFileType}_${date}`
    const fileGuids = filesToDownload.map((file) => file.guid)
    const request = {
      input: [{ file_name: fileName, file_guids: fileGuids, merge_type: ApiDownloadMergeTypeEnum.CSV }],
      zip_name: fileName
    }
    postDownloadFiles(request)
    resetFileGuids()
  } else if (isMoreFiles) {
    const fileName = `${userType}_Files_${date}`
    const requestInputs = filesToDownload.map((file) => ({
      file_name: file.file_name,
      file_guids: [file.file_guid],
      merge_type: ApiDownloadMergeTypeEnum.NONE
    }))
    const request = {
      input: requestInputs,
      zip_name: fileName
    }
    postDownloadFiles(request)
    resetFileGuids()
  }

  return useCallback(
    (values: Record<string, boolean>) => {
      const res = Object.keys(values).filter((key: string) => values[key])
      setFilesToDownload(files.filter((file) => res.includes(file.guid)))
    },
    [files]
  )
}
