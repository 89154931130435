import PermissionDenied from "app/PermissionDenied"
import Auth from "lib/Auth"
import React, { PropsWithChildren } from "react"

interface Props {
  permissions: string[]
  showDenied?: boolean
}

const WithPermission: React.FC<PropsWithChildren<Props>> = ({ permissions, showDenied = false, children }) => {
  if (Auth.hasPermission(permissions)) {
    return <>{children}</>
  }

  if (showDenied) return <PermissionDenied />

  return null
}

export default WithPermission
