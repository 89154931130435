import React, { memo } from "react"
import { ListItemButton, MenuItem } from "@mui/material"
import { Link, LinkMenu } from "components"
import Auth from "lib/Auth"
import { Permission } from "constants/permission"
import { PATH } from "constants/path"

const items = [
  {
    label: "Appointment search",
    conditions: [Permission.FINANCE_SEARCH_READ, Permission.FINANCE_APPOINTMENT_READ],
    to: PATH.FINANCE.APPOINTMENT_SEARCH,
    id: "mainnav-finance-appointment-search"
  },
  {
    label: "Additionals management",
    conditions: [Permission.FINANCE_PROCESSING_CHARGES_APPROVE_READ, Permission.HR_APPROVER_READ],
    to: PATH.FINANCE.ADDITIONALS_MANAGEMENT,
    id: "mainnav-finance-additionals-management"
  },
  {
    label: "Invoice models",
    conditions: [Permission.FINANCE_MODEL_EDIT],
    to: PATH.FINANCE.INVOICE_MODELS,
    id: "mainnav-finance-invoice-models"
  },
  {
    label: "Payment models",
    conditions: [Permission.FINANCE_MODEL_EDIT],
    to: PATH.FINANCE.PAYMENT_MODELS,
    id: "mainnav-finance-payment-models"
  },
  {
    label: "File management",
    conditions: [Permission.FINANCE_FILE_READ],
    to: PATH.FINANCE.FILE_MANAGEMENT,
    id: "mainnav-finance-file-management"
  },
  {
    label: "Interface File Viewer",
    conditions: [Permission.FINANCE_FILE_READ],
    to: PATH.FINANCE.INTERFACE_FILES,
    id: "mainnav-finance-interface-files"
  },
  {
    label: "Invoice processing",
    conditions: [Permission.FINANCE_PROCESSING_READ],
    to: PATH.FINANCE.INVOICE_PROCESSING,
    id: "mainnav-invoice-processing"
  },
  {
    label: "Payment processing",
    conditions: [Permission.FINANCE_PROCESSING_READ],
    to: PATH.FINANCE.PAYMENT_PROCESSING,
    id: "mainnav-finance-payment-processing"
  },
  {
    label: "Processing status",
    conditions: [Permission.FINANCE_PROCESSING_READ],
    to: PATH.FINANCE.PROCESSING_STATUS,
    id: "mainnav-finance-processing-status"
  },
  {
    label: "Alerts",
    conditions: [Permission.FINANCE_ALERT_READ],
    to: PATH.FINANCE.ALERTS,
    id: "mainnav-alerts"
  },
  {
    label: "Import",
    conditions: [Permission.FINANCE_EDIT],
    to: PATH.FINANCE.IMPORT,
    id: "mainnav-import"
  }
]
interface OwnProps {
  hasFinanceFlag: boolean
}

export const MainNavFinance: React.FC<OwnProps> = memo(({ hasFinanceFlag }) => {
  const isFinanceMenuDisplayed =
    hasFinanceFlag &&
    Auth.hasPermission([
      Permission.FINANCE_MODEL_EDIT,
      Permission.FINANCE_FILE_READ,
      Permission.FINANCE_PROCESSING_READ,
      Permission.FINANCE_ALERT_READ
    ])

  if (!isFinanceMenuDisplayed) {
    return null
  }

  return (
    <ListItemButton sx={{ p: 0 }}>
      <LinkMenu title="Finance">
        {items
          .filter((item) => Auth.hasPermission(item.conditions))
          .map(({ to, label, id }) => (
            <MenuItem sx={{ padding: 0 }} key={to}>
              <Link
                propagate
                sx={(theme) => ({ width: "100%", display: "block", px: theme.spacing(2), py: theme.spacing(1) })}
                to={to}
                id={id}
              >
                {label}
              </Link>
            </MenuItem>
          ))}
      </LinkMenu>
    </ListItemButton>
  )
})

MainNavFinance.displayName = "MainNavFinance"
