import React from "react"
import { NotFound } from "./index"
// import ReportLooker from "./Reports/ReportLooker"
import Auth from "lib/Auth"
import SickReports from "./Reports/SickReports"
import PermissionDenied from "app/PermissionDenied"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

const Reports: React.FC = () => {
  if (!Auth.hasPermission(["Branch.Report:Read", "Hr.Carer:Read"])) {
    return <PermissionDenied />
  }

  return (
    <SentryRoutes>
      <Route path="/sick-reports" element={<SickReports />} />
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  )
}
export default Reports
