import React, { memo } from "react"
import { getNameFirstLetters } from "./utils"
import { Avatar, Box, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { Actions, StandardMessage } from "./types"
import { SYSTEM_CHAT_IMAGE } from "./constants"
import ChatRoomImage from "./ChatRoomImage"

interface Props {
  message: StandardMessage
  timetoken: string | number
  actions?: Actions
}

const ChatRoomMessage = ({ message, timetoken, actions }: Props) => {
  const isOperator = !message.sender?.isUser
  const userInitials = getNameFirstLetters(message.sender.name)
  const timeToken = DateTime.fromMillis(Number(timetoken) / 10000).toFormat("HH:mm")
  const isImage = message.type === SYSTEM_CHAT_IMAGE

  return (
    <Box sx={{ display: "flex", justifyContent: isOperator ? "flex-end" : "flex-start", mb: 3 }}>
      <Box sx={{ display: "flex", position: "relative" }}>
        <Avatar
          sx={{
            bgcolor: "primary.main",
            position: "absolute",
            bottom: 24,
            ...(isOperator ? { right: 0 } : { left: 0 })
          }}
        >
          {userInitials}
        </Avatar>
        <Box sx={{ mx: 6 }}>
          <Typography variant="body2" color="textSecondary" textAlign={isOperator ? "right" : "left"} sx={{ px: 3 }}>
            {message.sender.name}
          </Typography>
          {isImage ? (
            <ChatRoomImage isOperator={isOperator} id={message.file_id ?? ""} />
          ) : (
            <Typography
              sx={{
                p: 3,
                border: "1px solid",
                borderRadius: 3,
                borderColor: "divider",
                minWidth: 310,
                wordBreak: "break-word",
                whiteSpace: "pre-wrap",
                my: 0.25,
                ...(isOperator
                  ? {
                      bgcolor: "primary.tint",
                      borderBottomRightRadius: 0
                    }
                  : { bgcolor: "grey.100", borderBottomLeftRadius: 0 })
              }}
            >
              {message.text}
            </Typography>
          )}
          <Typography
            variant="caption"
            color="textSecondary"
            textAlign={isOperator ? "right" : "left"}
            sx={{ display: "block", px: 3 }}
          >
            {!actions?.delivered && `Unsent ${timeToken}`}
            {actions?.delivered.read &&
              `Sent ${timeToken} - Read (${DateTime.fromMillis(Number(actions?.delivered.read[0].actionTimetoken ?? 0) / 10000).toFormat("HH:mm dd/MM/yyyy")})`}
            {actions?.delivered && !actions?.delivered.read && `Sent ${timeToken} : Unread`}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default memo(ChatRoomMessage)
