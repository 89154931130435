import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { EmptyData, Paginator } from "components"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import testingId from "constants/testingId"
import { useInvoiceTaskListQuery } from "data/finance/invoiceTask/queries"
import { usePaymentTaskListQuery } from "data/finance/paymentTask/queries"
import { Task, TaskStatus } from "data/finance/task/types"
import datetime, { DateTimeFormat } from "lib/datetime"
import { useIsInvoiceModelSearch } from "lib/hooks/useIsInvoiceModelSearch"
import { timeSpanToStringDuration } from "lib/timespan"
import { Pagination, SetPagination } from "lib/types"
import React, { memo, useEffect, useState } from "react"
import { StatusBar } from "./StatusBar"
import { useLocation } from "react-router-dom"

interface Props {
  pagination: Pagination
  setPagination: SetPagination
}

const ProcessingStatusListTab: React.FC<Props> = ({ setPagination, pagination }) => {
  const location = useLocation()
  const isInvoice = useIsInvoiceModelSearch()
  const [enableRefetchInterval, setEnableRefetchInterval] = useState<boolean>(false)

  const { data: invoiceTasks } = useInvoiceTaskListQuery({
    enabled: isInvoice,
    search: location.search,
    enableRefetchInterval
  })
  const { data: paymentTasks } = usePaymentTaskListQuery({
    enabled: !isInvoice,
    search: location.search,
    enableRefetchInterval
  })

  const tasks: Task[] | undefined = isInvoice ? invoiceTasks : paymentTasks

  useEffect(() => {
    if (tasks?.some((item) => item.status === TaskStatus.PROCESSING)) {
      setEnableRefetchInterval(true)
    } else {
      setEnableRefetchInterval(false)
    }
  }, [tasks])

  const header = ["Inserted by", "Inserted at", "Started at", "Estimated run time", "Status", "Type", "Item count"]

  return (
    <RequestLoaderWrapper>
      <Box p={2} px={0} display="flex" justifyContent="flex-end">
        {tasks?.length ? (
          <Table>
            <TableHead>
              <TableRow>
                {header.map((title) => (
                  <TableCell data-cy={testingId.tableHeaderCell} key={title}>
                    {title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task) => (
                <TableRow data-cy={testingId.tableRow} key={task.guid} hover>
                  <TableCell data-cy={testingId.tableCell}>{task.inserter}</TableCell>
                  <TableCell data-cy={testingId.tableCell}>
                    {datetime.formatDate(task.inserted_at, DateTimeFormat.DATE_TIME)}
                  </TableCell>
                  <TableCell data-cy={testingId.tableCell}>
                    {task.started_at && datetime.formatDate(task.started_at, DateTimeFormat.DATE_TIME)}
                  </TableCell>
                  <TableCell data-cy={testingId.tableCell}>
                    {task.estimated_run_time ? timeSpanToStringDuration(task.estimated_run_time) : null}
                  </TableCell>
                  <TableCell data-cy={testingId.tableCell}>
                    {task.status === TaskStatus.PROCESSING && task.started_at && task.estimated_completion_at ? (
                      <StatusBar {...{ task }} />
                    ) : (
                      task.status
                    )}
                  </TableCell>
                  <TableCell data-cy={testingId.tableCell}>{task.type}</TableCell>
                  <TableCell data-cy={testingId.tableCell}>{task.item_count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyData {...{ message: "No processing items found.", flexGrow: 1 }} />
        )}
      </Box>
      {pagination.page !== "1" || tasks?.length !== 0 ? (
        <Paginator {...{ setPagination, itemsLength: tasks?.length }} />
      ) : null}
    </RequestLoaderWrapper>
  )
}

export default memo(ProcessingStatusListTab)
