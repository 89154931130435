import React, { memo } from "react"
import { Box, Typography } from "@mui/material"
import { moneyFormat } from "lib/utils"
import testingId from "constants/testingId"

interface Props {
  total: number
}

export const HolidayEstimationTotal = memo(({ total }: Props) => {
  HolidayEstimationTotal.displayName = "HolidayEstimationTotal"

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "50px"
      }}
    >
      <Box
        sx={{
          marginLeft: "20px"
        }}
      >
        <Typography variant="h6" component="span">
          Total payment:&nbsp;
        </Typography>
        <Typography variant="h6" color="secondary" component="span" data-cy={testingId.holidayEstimation.totalMain}>
          {moneyFormat.format(total)}
        </Typography>
      </Box>
    </Box>
  )
})
