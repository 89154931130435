import React, { memo, useState, ReactNode } from "react"
import {
  Typography,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  PopperProps
} from "@mui/material"
import { DateTime } from "luxon"
import { Button } from "./Form"
import { ApiUserFile } from "../models"
import { SchemaFieldOption } from "../lib/types"
import { optionsToValueTitle } from "../lib/schema"
import { FileListItem } from "components"
import FileListExpiryModal from "./FileListExpiryModal"

interface OwnProps {
  files?: ApiUserFile[]
  categoryFilter: boolean
  categoryOptions: SchemaFieldOption[]
  deleteFile: Function
  updateExpiry: ({ expires_at, file }: { expires_at: DateTime; file: ApiUserFile }) => void
  replaceFile: Function
  downloadFile: Function
}

const FileList: React.FC<OwnProps> = ({
  files,
  deleteFile,
  updateExpiry,
  replaceFile,
  downloadFile,
  categoryOptions
}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<PopperProps["anchorEl"]>(null)
  const [selectedFile, setSelectedFile] = useState<ApiUserFile>()

  const handleModalClose = () => {
    setModalOpen(false)
    setSelectedFile(undefined)
  }

  const confirmDelete = (item: ApiUserFile) => {
    setSelectedFile(item)
    setModalOpen(true)
  }

  const handleReplaceFile = (item: ApiUserFile) => {
    replaceFile(item)
  }

  const handleDeleteFile = () => {
    if (!selectedFile) return false

    deleteFile(selectedFile)
    setModalOpen(false)
    setSelectedFile(undefined)
  }

  const [modal, setModal] = useState<ReactNode>(null)
  const destroyModal = () => {
    setModal(null)
  }

  const openExpiryModal = (file: ApiUserFile) => {
    setModal(<FileListExpiryModal {...{ destroyModal, file, updateExpiry }} />)
  }

  const categories = optionsToValueTitle(categoryOptions)

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left">Date uploaded</TableCell>
            <TableCell align="left">Document name</TableCell>
            <TableCell align="left">Category</TableCell>
            <TableCell align="left">Uploaded by</TableCell>
            <TableCell align="left">Expires at</TableCell>
            <TableCell align="left">Warnings</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {files?.length === 0 && (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <Typography variant="h6" sx={(theme) => ({ margin: theme.spacing(3) })}>
                  There are currently no uploaded documents. Please select the ‘Upload document’ button above to get
                  started.
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {files?.map((item: ApiUserFile) => (
            <FileListItem
              file={item}
              key={item.file_guid}
              downloadFile={downloadFile}
              confirmDelete={confirmDelete}
              openExpiryModal={openExpiryModal}
              handleReplaceFile={handleReplaceFile}
              anchorEl={anchorEl}
              categories={categories}
              setAnchorEl={setAnchorEl}
            />
          ))}
        </TableBody>
      </Table>
      <Modal
        aria-labelledby="delete-modal"
        aria-describedby="delete-modal-action"
        open={isModalOpen}
        onClose={handleModalClose}
      >
        <Paper
          style={{
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`
          }}
          sx={(theme) => ({
            position: "absolute",
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[1],
            padding: theme.spacing(2, 4, 3)
          })}
        >
          {selectedFile && (
            <>
              <h2 id="delete-modal">Are you sure?</h2>
              <p id="delete-modal-action">
                You are about to delete file{" "}
                <strong>
                  {selectedFile.title} ({selectedFile.file_name})
                </strong>
                . This action is irreversible, do you wish to continue?
              </p>
              <Box display="flex" justifyContent="space-between">
                <Button variant="text" fullWidth={false} onClick={handleModalClose}>
                  Cancel
                </Button>
                <Button fullWidth={false} onClick={handleDeleteFile}>
                  Delete
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </Modal>
      {modal !== null && modal}
    </>
  )
}
export default memo(FileList)
