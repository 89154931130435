import { QueryKey, useQuery, UseQueryResult } from "react-query"
import API from "api"
import { UserType } from "models"

export const queryKeys = {
  getProfilePictureKey: (type: UserType, guid: string): QueryKey => ["get-profile-picture", { type, guid }]
}

export const useGetProfilePicture = ({
  type,
  guid,
  enabled = true
}: {
  type: UserType
  guid: string
  enabled?: boolean
}): UseQueryResult<any, Error> => {
  return useQuery({
    queryKey: queryKeys.getProfilePictureKey(type, guid),
    enabled,
    queryFn: ({ signal }) => API.getProfilePicture({ type, guid }, signal)
  })
}
