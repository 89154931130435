import { Box, Typography } from "@mui/material"
import { ContractModelType } from "constants/modelTypes"
import { useContractServiceListQuery } from "data/finance/contractModel/queries"
import { ContractModelService } from "data/finance/contractModel/types"
import { FormApi } from "final-form"
import { deepObjectEqual } from "lib/utils"
import React, { memo, useEffect, useMemo } from "react"
import { ContractModelServiceUplift, ContractModelUplift, RateUpliftForm } from "../../types"
import { RateUpliftTwoTableService } from "./RateUpliftTwoTableService"

const mapServicesForForm = (
  servicesToMap: ContractModelService[],
  isPayment: boolean,
  values: RateUpliftForm
): ContractModelServiceUplift[] => {
  const isDisabled = (service: ContractModelService) => {
    if (service.end) return true
    return service.start.toMillis() >= values.start_date.toMillis()
  }

  return servicesToMap.map((service) => ({ ...service, isPayment, disabled: isDisabled(service) }))
}
interface OwnProps {
  contract: ContractModelUplift
  values: RateUpliftForm
  form: FormApi<RateUpliftForm, Partial<RateUpliftForm>>
  modelType: ContractModelType
}

export const RateUpliftTwoModel: React.FC<OwnProps> = memo(({ values, form, contract, modelType }) => {
  RateUpliftTwoModel.displayName = "RateUpliftTwoModel"

  const isInvoiceModel = modelType === ContractModelType.INVOICE

  const { data: services = [] } = useContractServiceListQuery({
    modelType,
    contractGuid: contract.guid
  })

  const { data: paymentServices = [] } = useContractServiceListQuery({
    modelType,
    contractGuid: contract.guid,
    isInvoicePaymentService: true,
    enabled: isInvoiceModel
  })

  const mappedServices = useMemo(() => mapServicesForForm(services, false, values), [services, values])

  const mappedPaymentServices = useMemo(
    () => mapServicesForForm(paymentServices, true, values),
    [paymentServices, values]
  )

  const allIndexedServices: ContractModelServiceUplift[] = useMemo(() => {
    return [...mappedServices, ...mappedPaymentServices].map((service, index) => ({ ...service, index }))
  }, [mappedServices, mappedPaymentServices])

  useEffect(() => {
    if (allIndexedServices && !deepObjectEqual(allIndexedServices, values.models[contract.guid].serviceItems)) {
      // @ts-ignore
      form.change(`models[${contract.guid}].serviceItems`, allIndexedServices)
    }
  }, [form, allIndexedServices, contract, values.models])

  return (
    <Box
      sx={{
        marginTop: 3,
        marginBottom: 5
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "primary.main",
          fontSize: "1.1rem",
          mb: 1,
          textTransform: "uppercase"
        }}
      >
        {contract.title}
      </Typography>
      <RateUpliftTwoTableService {...{ form, contract, services: mappedServices }} />
      {isInvoiceModel && !!paymentServices.length && (
        <RateUpliftTwoTableService
          {...{ form, contract, services: mappedPaymentServices, isInvoicePaymentService: true }}
        />
      )}
    </Box>
  )
})
