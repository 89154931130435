import React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { DateTimeFormat } from "lib/datetime"
import testingId from "constants/testingId"
import { ListShiftCategoriesResponse } from "types/Shifts/ShiftCategories/types"
import { useNavigate } from "react-router-dom"

interface Props {
  items: ListShiftCategoriesResponse[]
}

const ShiftCategoriesTable: React.FC<Props> = ({ items }) => {
  const navigate = useNavigate()
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">Shift Category Title</TableCell>
          <TableCell align="left">Starts on</TableCell>
          <TableCell align="left">Ends before</TableCell>
          <TableCell align="left">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody data-cy={testingId.shifts.category.list}>
        {items.map((item) => (
          <TableRow
            key={item.guid}
            data-cy={testingId.shifts.category.row}
            hover
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/shift-categories/${item.guid}/detail`)
            }}
          >
            <TableCell data-cy={testingId.shifts.category.cell}>{item.title}</TableCell>
            <TableCell data-cy={testingId.shifts.category.cell}>{item.start.toFormat(DateTimeFormat.DATE)}</TableCell>
            <TableCell data-cy={testingId.shifts.category.cell}>
              {item.end ? item.end.toFormat(DateTimeFormat.DATE) : "/ /"}
            </TableCell>
            <TableCell data-cy={testingId.shifts.category.cell}>{item.status}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default React.memo(ShiftCategoriesTable)
