import React, { memo } from "react"
import { ModalWrapper } from "components"
import Typography from "@mui/material/Typography"
import Table from "@mui/material/Table"
import Box from "@mui/material/Box"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { Button } from "components/Form"
import { optionsToValueTitle } from "lib/schema"
import { moneyFormat } from "lib/utils"
import testingId from "constants/testingId"
import { useDeleteInvoiceTransactionCharges } from "../InvoiceProcessing/handlers/useInvoiceExtrasDeleteHandler"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { useSchema } from "data/schema/useSchema"
import { InvoiceTransaction, InvoiceTransactionCharge } from "data/finance/invoiceProcessing/types"
import { CalendarPeriod } from "data/finance/contractModel/types"

type Props = {
  charges: InvoiceTransactionCharge[]
  transaction: InvoiceTransaction
  period: CalendarPeriod
  destroyModal: () => void
  onOk: () => void
}

const DeleteInvoiceChargesModal: React.FC<Props> = ({ charges, transaction, period, destroyModal, onOk }) => {
  const { TransactionCharge } = useSchema(["TransactionCharge"])
  const chargeTypes = optionsToValueTitle(TransactionCharge)

  const onDelete = useDeleteInvoiceTransactionCharges({
    transactionGuid: transaction.guid,
    periodGuid: period.guid,
    charges,
    onOk
  })

  return (
    <ModalWrapper
      title="Delete Extras"
      destroyModal={destroyModal}
      width={700}
      data-cy={testingId.invoiceProcessingTransactionDetail.extrasDeleteModal}
    >
      <RequestLoaderWrapper>
        <Typography data-cy={testingId.invoiceProcessingTransactionDetail.extrasDeleteModalCount}>
          You are about to delete <strong>{charges.length} extras</strong> listed below:
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              {["Extra type", "Description", "Cost"].map((label, index) => (
                <TableCell key={index}>{label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {charges.map((charge, index) => (
              <TableRow key={index} data-cy={testingId.invoiceProcessingTransactionDetail.extrasDeleteModalTableRow}>
                {[chargeTypes[charge.charge_type_alias], charge.description, moneyFormat.format(charge.total || 0)].map(
                  (value, cellIdx) => (
                    <TableCell
                      key={cellIdx}
                      data-cy={testingId.invoiceProcessingTransactionDetail.extrasDeleteModalTableCell}
                    >
                      {value}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box m={3} mx={0}>
          <Typography>
            <strong>This process is irreversible, are you sure you want to proceed?</strong>
          </Typography>
        </Box>
        <Box m={3} mx={0} display="flex" justifyContent="flex-end">
          <Button fullWidth={false} variant="text" onClick={destroyModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            fullWidth={false}
            onClick={onDelete}
            sx={(theme) => ({
              marginLeft: theme.spacing(3)
            })}
            data-cy={testingId.deleteButton}
          >
            Delete extras
          </Button>
        </Box>
      </RequestLoaderWrapper>
    </ModalWrapper>
  )
}

export default memo(DeleteInvoiceChargesModal)
