import { PermissionGroup } from "constants/permission"
import jwt_decode from "jwt-decode"

export interface UserInfo {
  id: string
  branchGuid: string
  branchGuids: string[]
  username: string
  env: string
  userType: string
  permissionGroup: string[]
  permissions: string[]
  build: string
  expiryDate: number
}

export interface DecodedAccessToken {
  aud: string
  branch_guid: string
  branch_guids: string[]
  build: string
  env: string
  exp: number
  group: string[]
  iat: number
  iss: string
  nbf: number
  role: string[]
  sub: string
  unique_name: string
  user_type: string
}

export interface UserPermission {
  resource: string
  scope: string
}
export const AUTH_TYPE_GOOGLE = "google"
export const AUTH_TYPE_LEGACY = "legacy"
const ACCESS_TOKEN_STORE_KEY = "access_token"
const REFRESH_TOKEN_STORE_KEY = "refresh_token"
const IDENTITY_TOKEN_STORE_KEY = "identity_token"
const AUTH_TYPE = "auth_type"
const SESSION_BRANCH_KEY = "selectedBranchId"

const SUPERADMIN_RESOURCE = "*"
const SUPERADMIN_SCOPE = "Edit"

const getPermissionsFromAccessToken = (token: string | null) => {
  if (token === null) return []

  const decodedToken = jwt_decode<DecodedAccessToken>(token)

  if (!decodedToken) return []

  const { role } = decodedToken
  return role.map((item) => {
    const [resource, scope] = item.split(":")

    return {
      resource,
      scope
    }
  })
}

export const getDecodedToken = (token: string | null) => {
  if (token === null) return null

  return jwt_decode<DecodedAccessToken>(token)
}

const Auth = {
  permissions: null as UserPermission[] | null,

  getAuthType: () => window.localStorage.getItem(AUTH_TYPE) ?? AUTH_TYPE_LEGACY,
  removeAuthType: () => window.localStorage.removeItem(AUTH_TYPE),
  setAuthType: (type: string) => window.localStorage.setItem(AUTH_TYPE, type),

  getAccessToken: (): string | null => window.sessionStorage.getItem(ACCESS_TOKEN_STORE_KEY),
  setAccessToken: function (token: string): void {
    window.sessionStorage.setItem(ACCESS_TOKEN_STORE_KEY, token)
    this.permissions = getPermissionsFromAccessToken(token)
  },
  removeAccessToken: (): void => window.sessionStorage.removeItem(ACCESS_TOKEN_STORE_KEY),

  getIdentityToken: () => window.sessionStorage.getItem(IDENTITY_TOKEN_STORE_KEY),
  setIdentityToken: (token: string) => {
    window.sessionStorage.setItem(IDENTITY_TOKEN_STORE_KEY, token)
  },
  removeIdentityToken: () => {
    window.sessionStorage.removeItem(IDENTITY_TOKEN_STORE_KEY)
  },
  getUserInfo: function () {
    const decodedToken = getDecodedToken(this.getAccessToken())

    if (!decodedToken) return null

    return {
      id: decodedToken.sub,
      branchGuid: decodedToken.branch_guid,
      branchGuids: decodedToken.branch_guids,
      username: decodedToken.unique_name,
      env: decodedToken.env,
      userType: decodedToken.user_type,
      permissionGroup: decodedToken.group,
      permissions: decodedToken.role,
      build: decodedToken.build,
      expiryDate: decodedToken.exp
    } as UserInfo
  },

  getRefreshToken: (): string | null => window.localStorage.getItem(REFRESH_TOKEN_STORE_KEY),
  setRefreshToken: (token: string): void => window.localStorage.setItem(REFRESH_TOKEN_STORE_KEY, token),
  removeRefreshToken: (): void => window.localStorage.removeItem(REFRESH_TOKEN_STORE_KEY),

  saveSessionBranch: (selectedBranch: string): void => {
    window.sessionStorage.setItem(SESSION_BRANCH_KEY, selectedBranch)
  },
  getSessionBranch: (): string | undefined => window.sessionStorage.getItem(SESSION_BRANCH_KEY) || undefined,
  removeSessionBranch: (): void => window.sessionStorage.removeItem(SESSION_BRANCH_KEY),

  hasPermission: function (requestedPermissions: string[]): boolean {
    if (!this.permissions) {
      this.permissions = getPermissionsFromAccessToken(this.getAccessToken())
    }

    if (!this.permissions) {
      return false
    }

    let result = false

    requestedPermissions.forEach((perm: string) => {
      const [resource, scope] = perm.split(":")
      if (
        this.permissions &&
        (this.permissions.find((o: UserPermission) => o.resource === resource && o.scope === scope) ||
          this.permissions.find(
            (o: UserPermission) => o.resource === SUPERADMIN_RESOURCE && o.scope === SUPERADMIN_SCOPE
          ))
      ) {
        result = true
      }
    })

    return result
  },
  hasPermissionGroup: function (permissionGroup: PermissionGroup): boolean {
    const userPermissionGroups = this.getUserInfo()?.permissionGroup

    const developerPermission = this.permissions?.find(
      (o: UserPermission) => o.resource === SUPERADMIN_RESOURCE && o.scope === SUPERADMIN_SCOPE
    )

    if (developerPermission) {
      return true
    }

    if (!userPermissionGroups) return false
    return userPermissionGroups.includes(permissionGroup)
  }
}

export default Auth
