import { useCallback } from "react"
import { useCarerContractDeleteMutation } from "../mutations"
import { useApiMessage } from "stores"

interface OwnProps {
  carerGuid?: string
  contractGuids: string[]
  onOk: () => void
}

export const useCarerContractListDeleteHandler = ({
  carerGuid,
  contractGuids,
  onOk
}: OwnProps): (() => Promise<void>) => {
  const { mutateAsync: deleteCharge } = useCarerContractDeleteMutation({ carerGuid })
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useCallback(async () => {
    if (!carerGuid || !contractGuids) return

    try {
      await Promise.all(
        contractGuids.map((contractGuid) => {
          return deleteCharge({ contractGuid })
        })
      )
      showSuccessMessage("Payment groups successfully deleted.")
      onOk()
    } catch (apiError: any) {
      showErrorMessage("Something went wrong, please try again.", { apiError })
    }
  }, [carerGuid, contractGuids, deleteCharge, onOk, showErrorMessage, showSuccessMessage])
}
