import { FinanceApi } from "api"
import { SelectOption } from "components/Form/Select"
import { ContractModelType } from "constants/modelTypes"
import { enabledById } from "data/helpers"
import { UseQueryMutatedResult } from "data/types"
import { map, pipe, sortBy, toLower } from "ramda"
import { QueryKey, useQuery } from "react-query"
import { ProcessingAvailableUser } from "./types"

export const getProcessingUrl = (modelType: ContractModelType): string =>
  modelType === ContractModelType.INVOICE ? "invoice-processing" : "payment-processing"

enum QueryKeyParts {
  PROCESSING = "processing",
  AVAILABLE_USERS = "available_users"
}

export const queryKeys = {
  getProcessingAvailableUsersKey: (props: { modelType: ContractModelType; periodGuid: string }): QueryKey => [
    QueryKeyParts.PROCESSING,
    QueryKeyParts.AVAILABLE_USERS,
    props
  ]
}

export const useGetProcessingAvailableUsers = (props: {
  modelType: ContractModelType
  periodGuid: string
}): UseQueryMutatedResult<ProcessingAvailableUser[], SelectOption[]> => {
  const mapAsOptions = (transactionUsers?: ProcessingAvailableUser[]): SelectOption[] => {
    return pipe(
      map<ProcessingAvailableUser, SelectOption>((transactionUser) => ({
        title: transactionUser.full_name,
        description: transactionUser.full_name,
        value: transactionUser.guid
      })),
      sortBy(pipe((option) => option.title as string, toLower))
    )(transactionUsers || [])
  }

  const response = useQuery<ProcessingAvailableUser[], Error>({
    queryKey: queryKeys.getProcessingAvailableUsersKey(props),
    queryFn: async () =>
      FinanceApi.get(`finance/${getProcessingUrl(props.modelType)}/${props.periodGuid}/available-users`),
    ...enabledById(props.periodGuid)
  })

  return { ...response, mutatedData: mapAsOptions(response.data) }
}
