export enum AppointmentType {
  VISIT = "Visit",
  SHIFT = "Shift",
  NONE = "None"
}

// extends ContractModelType
export enum AppointmentTabType {
  INVOICE = "IM",
  PAYMENT = "PM",
  SCHEDULE = "SCH"
}
