import React, { memo, useState } from "react"
import { ApiUserCarer } from "models"
import { FormStage } from "constants/form"
import { useCarerContractQuery } from "data/finance/carer/queries"
import { ContractBackButton } from "pages/Finance/components/ContractBackButton"
import { CarerContractDetailSettings } from "./components/CarerContractDetailSettings"
import { UserContractTab } from "pages/Finance/components/types"
import { ContractTabBar } from "pages/Finance/components/ContractTabBar"
import { Box } from "@mui/material"
import { TabPanel } from "components"
import { ContractFileList } from "pages/Finance/components/ContractFileList"
import { ContractDetailHeader } from "pages/Finance/components/ContractDetailHeader"
import { useUserContractTabState } from "helpers/contractHelpers"
import { CarerBankInfo } from "./components/CarerBankInfo"
import Auth from "lib/Auth"
import { useParams } from "react-router-dom"

interface OwnProps {
  carer: ApiUserCarer
}

export const CarerContractDetail: React.FC<OwnProps> = memo(({ carer }) => {
  CarerContractDetail.displayName = "CarerContractDetail"

  const { contractId } = useParams()
  const isEdit = !!contractId
  const [formStage, setFormStage] = useState<FormStage>(isEdit ? FormStage.EDIT : FormStage.CREATE)

  const [tab, setTab] = useUserContractTabState()

  const { data: contract } = useCarerContractQuery({ carerGuid: carer.guid, contractGuid: contractId })

  const canSeeBankDetails = Auth.hasPermission(["Finance.UserContract.Bank:Read", "Finance.UserContract.Bank:Edit"])

  return (
    <>
      <ContractBackButton {...{ isEdit, setFormStage, formStage }} />
      {!isEdit && <CarerContractDetailSettings {...{ isEdit, formStage, setFormStage, carer, contract }} />}
      {isEdit && (
        <>
          <ContractDetailHeader {...{ userProfile: carer.profile, contract }} />
          <ContractTabBar {...{ tab, setTab, isCarer: true, canSeeBankDetails }} />
          <Box my={2}>
            <TabPanel value={tab} index={UserContractTab.SETTINGS}>
              <CarerContractDetailSettings {...{ isEdit, formStage, setFormStage, carer, contract }} />
            </TabPanel>
            {canSeeBankDetails && (
              <TabPanel value={tab} index={UserContractTab.BANK}>
                <CarerBankInfo {...{ carer }} />
              </TabPanel>
            )}
            <TabPanel value={tab} index={UserContractTab.FILES}>
              {contract && (
                <ContractFileList {...{ userGuid: carer.guid, contractGuid: contract.guid, userType: "Carer" }} />
              )}
            </TabPanel>
          </Box>
        </>
      )}
    </>
  )
})
