import { CheckCircle, Info, Warning } from "@mui/icons-material"
import { Alert, AlertTitle, SxProps } from "@mui/material"
import React, { memo, ReactNode } from "react"
import { Typography } from "@ceracare/dcp-ui"
import { ErrorIcon } from "./icons/ErrorIcon"

interface Props {
  condition: boolean
  title: string
  text: string | ReactNode
  variant: "info" | "warning" | "error" | "success"
  sx?: SxProps
  action?: ReactNode
}

export const InfoBox = memo(({ condition, title, text, variant, sx = { my: 1 }, action }: Props) => {
  if (!condition) return null

  return (
    <Alert
      severity={variant}
      iconMapping={{
        success: <CheckCircle />,
        warning: <Warning />,
        error: <ErrorIcon />,
        info: <Info />
      }}
      sx={sx}
      action={action}
    >
      <AlertTitle sx={{ fontWeight: "bold" }}>{title}</AlertTitle>
      {typeof text === "string" ? <Typography variant="body2">{text}</Typography> : text}
    </Alert>
  )
})

InfoBox.displayName = "InfoBox"
