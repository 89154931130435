import { Table, TableBody, TableCell, TableRow } from "@mui/material"
import { EmptyData } from "components"
import { RunType } from "constants/runType"
import React, { memo, useState } from "react"
import { RunEditModal } from "./RunEditModal"
import { Run } from "data/finance/run/types"

interface TableProps {
  runType: RunType
  runs?: Run[]
}

const RunListTable: React.FC<TableProps> = ({ runType, runs }) => {
  const [editRun, setEditRun] = useState<false | Run>(false)
  const destroyModal = () => {
    setEditRun(false)
  }
  const openModal = (run: Run) => () => {
    setEditRun(run)
  }

  if (!runs) return null

  return (
    <>
      {runs.length ? (
        <Table>
          <TableBody>
            {runs.map((run) => (
              <TableRow sx={{ cursor: "pointer" }} key={run.guid} hover onClick={openModal(run)}>
                <TableCell>{run.title}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <EmptyData {...{ message: "No Runs exist. Start by adding one." }} />
      )}
      {editRun && <RunEditModal {...{ destroyModal, runType, run: editRun, runs }} />}
    </>
  )
}

export default memo(RunListTable)
