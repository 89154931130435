import React, { useState, useEffect, memo } from "react"
import Grid from "@mui/material/Grid"
import { TextField } from "components/Form"
import { getOldestPeriod } from "./dateUtils"
import { isEqual } from "lodash"
import { DateTime } from "luxon"
import { ContractModelType } from "constants/modelTypes"
import { Field, useForm, useFormState } from "react-final-form"
import validators, { composeValidators } from "lib/validators"
import { DateField } from "components/Form/DateField"
import { FrequencySelectField } from "components/Form/FrequencySelectField"
import { InfoBox } from "components"
import { CalendarResponse } from "data/finance/contractModel/types"
import { NewCalendarWithPeriodsRequest } from "../../types"

const getLastCalendar = (items: CalendarResponse[]): undefined | CalendarResponse => {
  const itemsSorted = items.length
    ? items?.slice().sort((a: CalendarResponse, b: CalendarResponse) => a.start.toMillis() - b.start.toMillis())
    : []

  return items.length ? itemsSorted[items.length - 1] : undefined
}

interface Props {
  modelType: ContractModelType
  calendars: CalendarResponse[]
}

const CreateCalendarStepOne: React.FC<Props> = ({ modelType, calendars }) => {
  const form = useForm<NewCalendarWithPeriodsRequest>()
  const { values } = useFormState<NewCalendarWithPeriodsRequest>()

  const { periods } = values
  const [startDate, setStartDate] = useState<DateTime>(values.start)

  // reset periods if user creates new calendar, adds periods and then goes back to step 1 and changes start date
  useEffect(() => {
    const startDateTemp = values.start
    const oldestPeriod = getOldestPeriod(periods)
    const startDateFromFirstPeriod = oldestPeriod.start || startDateTemp

    if (!isEqual(startDateFromFirstPeriod, startDateTemp)) {
      setStartDate(startDateTemp)
      form.change("periods", [])
    }
  }, [values, periods, form])

  // for new calendar add last calendar end date as initial start date
  useEffect(() => {
    const lastCalendar = getLastCalendar(calendars)

    setStartDate(lastCalendar?.end || DateTime.local())
  }, [calendars, setStartDate])

  const frequencyText = modelType === ContractModelType.INVOICE ? "Billing" : "Payment"

  return (
    <>
      <InfoBox
        variant="info"
        condition={!!values.isReplace}
        title={`Changing the ${frequencyText} Frequency?`}
        text={`Changing the ${frequencyText} Frequency requires selecting a "Starts on" date. This date determines when the current ${frequencyText} Calendar ends and a new one begins with the new Frequency. The old calendar will be marked as expired but will remain accessible for your reference on the ${frequencyText} Calendars page.`}
      />
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Field
            name="title"
            label="Name *"
            component={TextField}
            validate={composeValidators([validators.required, validators.maxLength(64)])}
          />
          <DateField name="start" label="Starts on" required defaultValue={startDate} />
        </Grid>
        <Grid item md={4}>
          <FrequencySelectField label={`${frequencyText} Frequency`} required withoutDaily />
        </Grid>
      </Grid>
    </>
  )
}

export default memo(CreateCalendarStepOne)
