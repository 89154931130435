import { ApiCarerContract } from "data/finance/carer/types"
import { CarerContractsFilter } from "../types"

export const filterCarerContractList = ({
  contracts,
  filter
}: {
  contracts?: ApiCarerContract[]
  filter?: CarerContractsFilter
}): ApiCarerContract[] | undefined => {
  if (!filter || !contracts) return contracts

  const { status } = filter

  if (status?.length) {
    return contracts.filter((i) => status.includes(i.status))
  }

  return contracts
}
