import React, { useEffect, useMemo, useState, memo, Dispatch, SetStateAction } from "react"
import { UnitContractCreateStepOne } from "./UnitContractCreateStepOne"
import UnitContractCreateStepTwo from "./UnitContractCreateStepTwo"
import { Form, FormRenderProps } from "react-final-form"
import arrayMutators from "final-form-arrays"
import { useSelectableIdsDictionary } from "lib/hooks"
import { Unit, UnitContract } from "data/finance/unit/types"
import { useUnitContractListQuery } from "data/finance/unit/queries"
import { validateClientContract } from "lib/helpers/validateClientContract"
import { FormStage } from "constants/form"
import { useInvoiceModelQuery } from "data/finance/contractModel/queries"
import { StatusEnum } from "data/commonTypes"
import { buildUserContractInitialValues } from "helpers/contractHelpers"
import { useUnitContractSubmit } from "../handlers/useUnitContractSubmit"
import { ContainerSteps } from "components/Steps"
import { ContractDetailHeader } from "pages/Finance/components/ContractDetailHeader"
import { ContractDetailFooter } from "pages/Finance/components/ContractDetailFooter"
import { ApiClientContractPayload } from "data/finance/client/types"

interface OwnProps {
  unit: Unit
  contract?: UnitContract
  formStage: FormStage
  setFormStage: Dispatch<SetStateAction<FormStage>>
  isEdit: boolean
}

export const UnitContractDetailSettings: React.FC<OwnProps> = memo(
  ({ unit, contract, formStage, setFormStage, isEdit }) => {
    const { data: unitContracts } = useUnitContractListQuery({ unitGuid: unit.guid })

    const [selectedContractModelGuid, setSelectedContractModelGuid] = useState<string | undefined>(
      contract?.contract_guid
    )
    useEffect(() => {
      setSelectedContractModelGuid(contract?.contract_guid)
    }, [contract])

    const { data: selectedContractModel } = useInvoiceModelQuery(selectedContractModelGuid)

    const selectableContributionIds = useMemo(() => {
      if (contract?.is_contributable) {
        return (contract.contributions || [])
          .filter((contribution) => contribution.status === StatusEnum.FUTURE)
          .map((contribution) => contribution.guid)
      }
      return []
    }, [contract])
    const selectableContributions = useSelectableIdsDictionary(selectableContributionIds)

    const onSubmit = useUnitContractSubmit({
      selectableContributions,
      unit,
      formStage,
      setSelectedContractModelGuid,
      setFormStage
    })

    const isFirstContract = !unitContracts?.length

    const initialValues = buildUserContractInitialValues({ contract, isFirstContract })

    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        validate={validateClientContract({
          formStage,
          clientContracts: unitContracts,
          invoiceModel: selectedContractModel
        })}
      >
        {(formProps: FormRenderProps<ApiClientContractPayload>) => (
          <form onSubmit={formProps.handleSubmit}>
            <ContainerSteps>
              {!isEdit && (
                <ContractDetailHeader
                  {...{ userProfile: unit, contract, selectedContractModel, formStage, navigateTo: "../edit" }}
                />
              )}
              {formStage === FormStage.CREATE && <UnitContractCreateStepOne />}
              {formStage === FormStage.EDIT && (
                <UnitContractCreateStepTwo
                  {...{
                    contractModel: selectedContractModel,
                    contractReference: contract?.reference,
                    selectableContributions,
                    contract,
                    form: formProps.form,
                    isFirstContract
                  }}
                />
              )}
              <ContractDetailFooter {...{ formStage, formProps }} />
            </ContainerSteps>
          </form>
        )}
      </Form>
    )
  }
)

UnitContractDetailSettings.displayName = "UnitContractDetailSettings"
