import { Box, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { ContractModelServiceBandWithRates } from "data/finance/contractModel/types"
import React, { memo } from "react"
import { reduceStandardRates } from "../../handlers/reduceStandardRates"
import { reduceEnhancedRates } from "../../handlers/reduceEnhancedRates"
import { useGetRateDayTypeOptions } from "../../helpers/useGetRateDayTypeOptions"
import { ContractModelUplift, RateUpliftForm } from "../../types"
import { FormApi } from "final-form"
import { RateUpliftThreeBandRates } from "./RateUpliftThreeBandRates"
import { RateUpliftThreeBandEnhancedRates } from "./RateUpliftThreeBandEnhancedRates"

interface OwnProps {
  contract: ContractModelUplift
  serviceGuid: string
  band: ContractModelServiceBandWithRates
  form: FormApi<RateUpliftForm>
}

export const RateUpliftThreeBand: React.FC<OwnProps> = memo(({ serviceGuid, contract, band, form }) => {
  RateUpliftThreeBand.displayName = "RateUpliftThreeBand"

  const basicRates = reduceStandardRates(band.rates)
  const enhancedRates = reduceEnhancedRates(band.rates)

  const { dayTypeOptions, isLoading } = useGetRateDayTypeOptions()

  if (!band.rates || isLoading) return null

  return (
    <Box
      sx={{
        marginTop: "20px",
        padding: "10px",
        backgroundColor: grey[100],
        boxShadow: `0 0 10px 0 ${grey[300]}`
      }}
    >
      <Typography color="primary">
        Band ({band.minimum_duration_minutes} - {band.maximum_duration_minutes || "---"})
      </Typography>
      <RateUpliftThreeBandRates
        {...{ serviceGuid, contractGuid: contract.guid, bandGuid: band.guid, form, rates: basicRates, dayTypeOptions }}
      />
      {enhancedRates.length ? (
        <RateUpliftThreeBandEnhancedRates
          {...{
            serviceGuid,
            contractGuid: contract.guid,
            bandGuid: band.guid,
            form,
            rates: basicRates,
            enhancedRates,
            dayTypeOptions
          }}
        />
      ) : null}
    </Box>
  )
})
