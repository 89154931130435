import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import testingId from "constants/testingId"
import { TransactionDetailType } from "pages/Finance/types"
import React, { Dispatch, memo, SetStateAction } from "react"
import { TransactionDetailMoney } from "./TransactionDetailMoney"
import {
  InvoiceTransaction,
  InvoiceTransactionCharge,
  InvoiceTransactionItem
} from "data/finance/invoiceProcessing/types"

interface OwnProps {
  transaction?: InvoiceTransaction
  setTab: Dispatch<SetStateAction<TransactionDetailType>>
  tab: TransactionDetailType
  visits?: InvoiceTransactionItem[]
  charges?: InvoiceTransactionCharge[]
}

export const TransactionDetailTabBar: React.FC<OwnProps> = memo(({ setTab, tab, ...rest }) => {
  const handleChange = (_: React.ChangeEvent<{}>, newValue: TransactionDetailType) => {
    setTab(newValue)
  }

  return (
    <Box
      display="flex"
      m={2}
      mx={0}
      justifyContent="space-between"
      alignItems="center"
      sx={(theme) => ({
        borderBottom: "1px solid",
        borderColor: theme.palette.primary.main
      })}
    >
      <AppBar
        position="relative"
        color="transparent"
        sx={{
          boxShadow: "none",

          width: "auto"
        }}
      >
        <Tabs value={tab} onChange={handleChange}>
          <Tab
            label="Visits"
            value={TransactionDetailType.VISIT}
            data-cy={testingId.invoiceProcessingTransactionDetail.tabVisits}
          />
          <Tab
            label="Extras"
            value={TransactionDetailType.EXTRA}
            data-cy={testingId.invoiceProcessingTransactionDetail.tabExtras}
          />
        </Tabs>
      </AppBar>
      <TransactionDetailMoney {...rest} />
    </Box>
  )
})

TransactionDetailTabBar.displayName = "TransactionDetailTabBar"
