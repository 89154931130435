import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { DataLoading, EmptyData, Paginator } from "components"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { DateTimeFormat } from "lib/datetime"
import { optionsToValueTitle } from "lib/schema"
import { SetPagination } from "lib/types"
import { ApiUserCarer } from "models"
import { isEmpty, omit } from "ramda"
import React, { memo } from "react"
import { CarerAccrualListFilterValues } from "./CarerAccrualListFilter"
import { getContractModelLink } from "pages/Finance/components/helpers/getContractModelLink"
import { CarerAccrualListTableItemBranch } from "./CarerAccrualListTableItemBranch"
import { CarerAccrualListTableItemStatus } from "./CarerAccrualListTableItemStatus"
import { ApiContractAccrual } from "data/finance/carer/types"
import { useSchema } from "data/schema/useSchema"
import { useNavigate } from "react-router-dom"

interface OwnProps {
  carer: ApiUserCarer
  setPagination: SetPagination
  filter: CarerAccrualListFilterValues
  accruals?: ApiContractAccrual[]
  isLoading: boolean
}

export const CarerAccrualListTable: React.FC<OwnProps> = memo(
  ({ carer, setPagination, filter, accruals, isLoading }) => {
    const navigate = useNavigate()
    const { Accrual } = useSchema(["Accrual"])
    const accrualTypes = optionsToValueTitle(Accrual)

    if (isLoading || !accruals) return <DataLoading />

    if (isEmpty(accruals)) {
      if (isEmpty(omit(["page", "page_size"], filter))) {
        return <EmptyData message="This Carer has no accruals." />
      }
      return <EmptyData message="This Carer has no accruals with current filter." />
    }

    const handleRowClick = (guid: string) => () => {
      navigate(`/carers/${carer.guid}/accruals/${guid}`)
    }

    return (
      <RequestLoaderWrapper>
        <Table>
          <TableHead>
            <TableRow>
              {["Type", "Payment Model", "Start", "End", "Accrual Cap", "Branch", "Policy status"].map((item) => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {accruals?.map((accrual) => (
              <TableRow
                key={accrual.guid}
                hover
                sx={{
                  cursor: "pointer"
                }}
                onClick={handleRowClick(accrual.guid)}
              >
                <TableCell
                  sx={(theme) => ({
                    ...(accrual.status === "Future" ? { color: theme.palette.text.secondary } : {}),
                    ...(accrual.status === "Expired" ? { color: theme.palette.text.disabled } : {})
                  })}
                >
                  {accrualTypes[accrual.type_alias]}
                </TableCell>
                <TableCell>
                  {getContractModelLink({
                    contract_guid: accrual.contract.guid,
                    contract_title: accrual.contract.title,
                    isInvoiceModel: false
                  })}
                </TableCell>
                <TableCell
                  sx={(theme) => ({
                    ...(accrual.status === "Future" ? { color: theme.palette.text.secondary } : {}),
                    ...(accrual.status === "Expired" ? { color: theme.palette.text.disabled } : {})
                  })}
                >
                  {accrual.start.toFormat(DateTimeFormat.DATE)}
                </TableCell>
                <TableCell
                  sx={(theme) => ({
                    ...(accrual.status === "Future" ? { color: theme.palette.text.secondary } : {}),
                    ...(accrual.status === "Expired" ? { color: theme.palette.text.disabled } : {})
                  })}
                >
                  {accrual.end.toFormat(DateTimeFormat.DATE)}
                </TableCell>
                <TableCell
                  sx={(theme) => ({
                    ...(accrual.status === "Future" ? { color: theme.palette.text.secondary } : {}),
                    ...(accrual.status === "Expired" ? { color: theme.palette.text.disabled } : {})
                  })}
                >
                  {accrual.accrual_cap} hours
                </TableCell>
                <TableCell
                  sx={(theme) => ({
                    ...(accrual.status === "Future" ? { color: theme.palette.text.secondary } : {}),
                    ...(accrual.status === "Expired" ? { color: theme.palette.text.disabled } : {})
                  })}
                >
                  <CarerAccrualListTableItemBranch accrual={accrual} />
                </TableCell>
                <TableCell>
                  <CarerAccrualListTableItemStatus accrual={accrual} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Paginator {...{ setPagination, itemsLength: accruals?.length, initialPageSize: 100 }} />
      </RequestLoaderWrapper>
    )
  }
)

CarerAccrualListTable.displayName = "CarerAccrualListTable"
