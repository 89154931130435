import React, { useCallback } from "react"
import { get, isEmpty } from "lodash"
import {
  VisitReportTypes,
  VisitReportFullValues,
  VisitReportTemplateField,
  VisitReportTemplateSection
} from "../models/VisitReport"
import { Box, Grid, Typography } from "@mui/material"
import testingId from "constants/testingId"
import MobilityAssessmentReport from "./MobilityAssessmentReport"
import { ApiMobilityAssessment } from "models/mobilityAssessment"
import useBranchSettings from "lib/hooks/branchInfo/useBranchSettings"

interface Props {
  /**
   * The visitor report types
   */
  report: VisitReportTypes

  /**
   * The mobility assessment data returned by the Mobile API
   */
  mobilityAssessment?: ApiMobilityAssessment
}

const VisitReport: React.FC<Props> = ({ report, mobilityAssessment }) => {
  const values = report.getValues(true) as VisitReportFullValues
  const { settings: branchSettings } = useBranchSettings()
  const hasRiskScoreWidgetFlag = branchSettings["FeatureMobileTeamClientFallRiskScore"]?.toLowerCase() === "true"

  /**
   * Renders the field value of the report
   */
  const renderValue = useCallback(
    (field: VisitReportTemplateField) => {
      const fieldName = field.guid
      const fieldValue = values[fieldName]

      if (!fieldValue) {
        return <EmptyOrMissingValue />
      }

      const rawValue = fieldValue.value as string | number
      const optionValue = field.options
        ? get(field.options.filter((option) => option.value.toString() === rawValue.toString())[0], "title")
        : null
      const value = optionValue ? optionValue : rawValue
      const supportingText = get(fieldValue, "supporting_text") as string

      return (
        <>
          <Typography
            variant="body1"
            sx={(theme) => ({
              margin: theme.spacing(1, 0)
            })}
          >
            {!isEmpty(value) ? value : <EmptyOrMissingValue />}
          </Typography>
          {supportingText && (
            <>
              <Typography component="span" variant="body2" color="textSecondary">
                Additional information:&nbsp;
              </Typography>
              <Typography component="span" variant="body2">
                {supportingText}
              </Typography>
            </>
          )}
        </>
      )
    },
    [values]
  )

  /**
   * Renders the field of the report
   */
  const renderField = useCallback(
    (section: VisitReportTemplateSection, key: number) => {
      return (
        <Box key={`${section.guid}-${key}`} m={5} mx={0}>
          <Typography variant="h6" data-cy={testingId.visit.detail.report.title}>
            {section.title}
          </Typography>
          <Box m={3} mx={0}>
            <Grid container spacing={3}>
              {section.fields.map((field: VisitReportTemplateField, fieldKey: number) => (
                <Grid item md={6} key={`${field.guid}-${fieldKey}`}>
                  <Typography color="textSecondary" variant="body2" data-cy={testingId.visit.detail.report.fieldTitle}>
                    {field.title}
                  </Typography>
                  {renderValue(field)}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )
    },
    [renderValue]
  )

  /**
   * Renders the mobility assessment report
   */
  const renderMobilityReport = useCallback(() => {
    if (hasRiskScoreWidgetFlag && mobilityAssessment) {
      return <MobilityAssessmentReport mobilityReport={mobilityAssessment} />
    }
  }, [mobilityAssessment, hasRiskScoreWidgetFlag])

  /**
   * Renders mobility assessment data report if there is no sections on the visitor report
   * but has data for mobility assessment
   */
  if (report.template.sections.length === 0) {
    return renderMobilityReport()
  }

  return (
    <>
      {report.template.sections.map((section, key: number) => {
        /**
         * Renders Mobility assessment report before the last field and if has mobility assessment data
         */
        if (key === report.template.sections.length - 1) {
          return (
            <>
              {renderMobilityReport()}
              {renderField(section, key)}
            </>
          )
        }
        return renderField(section, key)
      })}
    </>
  )
}
export default VisitReport

const EmptyOrMissingValue = () => {
  return (
    <Typography
      sx={(theme) => ({
        margin: theme.spacing(1, 0)
      })}
      variant="body2"
      color="textSecondary"
    >
      No value provided.
    </Typography>
  )
}
