import Box from "@mui/material/Box"
import { ContentContainer, Header, Divider, Paginator } from "components"
import React, { memo, ReactNode, useCallback, useMemo, useState } from "react"
import { Form } from "react-final-form"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import { useIsQA, useSelectableIdsDictionary } from "lib/hooks"
import { TransactionGroupFilter, defaultFilterValues } from "./components/TransactionGroupFilter"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import { usePagination } from "components/handlers/usePagination"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { TransactionGroupTableHeader } from "./components/TransactionGroupTableHeader"
import { TransactionGroupTableBody } from "./components/TransactionGroupTableBody"
import { CreditingModal } from "../components"
import { TransactionGroupActionButtons } from "./components/TransactionGroupActionButtons"
import { TransactionGroupSelectButtons } from "./components/TransactionGroupSelectButtons"
import { TransactionGroupMoney } from "./components/TransactionGroupMoney"
import { PayrollModal } from "./components/PayrollModal"
import { queryStringToObject } from "lib/queryString"
import Auth from "lib/Auth"
import { Permission } from "constants/permission"
import { usePaymentProcessingTransactionGroupListQuery } from "data/finance/paymentProcessing/queries"
import { useLocation } from "react-router-dom"
import { PaymentTransactionGroupFilter } from "./types"
import { PaymentTransactionGroup } from "data/finance/paymentProcessing/types"

const TransactionGroupList: React.FC = () => {
  const location = useLocation()

  const [filter, setFilter] = useState<PaymentTransactionGroupFilter>({
    ...defaultFilterValues,
    ...queryStringToObject<PaymentTransactionGroupFilter>(location.search)
  })

  const { pagination, setPagination, resetPagination } = usePagination()

  const [modal, setModal] = useState<ReactNode>(null)

  const { data: transactionGroups = [], refetch: refetchGroups } = usePaymentProcessingTransactionGroupListQuery()

  const { selectableIdsForCrediting, selectableIdsForPayroll, allSelectableIds } = useMemo(() => {
    const selectableIdsForCrediting: string[] = []
    const selectableIdsForPayroll: string[] = []
    const allSelectableIds: string[] = []

    transactionGroups.forEach((tg: PaymentTransactionGroup) => {
      if (tg.is_creditable || tg.is_processable) {
        allSelectableIds.push(tg.guid)
        if (tg.is_creditable) {
          selectableIdsForCrediting.push(tg.guid)
        }
        if (tg.is_processable) {
          selectableIdsForPayroll.push(tg.guid)
        }
      }
    })

    return {
      selectableIdsForCrediting,
      selectableIdsForPayroll,
      allSelectableIds
    }
  }, [transactionGroups])

  const { toggleId, toggleSubset, getSelectedAsStringArr, selectableIds, resetAll } =
    useSelectableIdsDictionary(allSelectableIds)
  const selectedIds = useMemo(() => getSelectedAsStringArr(), [getSelectedAsStringArr])

  const refetch = useCallback(() => {
    refetchGroups()
  }, [refetchGroups])

  const onOk = useCallback(() => {
    resetAll()
    refetch()
    setModal(null)
  }, [refetch, resetAll])

  const onCancel = useCallback(() => {
    setModal(null)
  }, [])

  const handlePayrollClick = useCallback(() => {
    setModal(<PayrollModal {...{ guids: selectedIds, onCancel, onOk }} />)
  }, [onCancel, onOk, selectedIds])

  const handleCreditClick = useCallback(() => {
    setModal(
      <CreditingModal
        {...{
          guids: selectedIds,
          onCancel,
          isTransaction: true,
          modelType: ContractModelType.PAYMENT,
          onOk,
          isPeriod: true
        }}
      />
    )
  }, [onCancel, selectedIds, onOk])

  const isQA = useIsQA()

  const hasRewindPermission =
    isQA && Auth.hasPermission([Permission.FINANCE_REWIND_EDIT]) && Auth.hasPermission([Permission.DEVELOPER_EDIT])

  const hasCompletedTransactionGroup = transactionGroups.some((tg) => !!tg.completed_at)
  const hasAlertingTransactionGroup = transactionGroups.some((tg) => tg.is_alerting)

  return (
    <ContentContainer>
      <Header title="Payment processing" />
      <Box m={3} mx={0}>
        <TransactionGroupFilter {...{ pagination, resetPagination, filter, setFilter }} />
      </Box>
      <TransactionGroupMoney {...{ transactionGroups }} />
      <Divider color="divider" />
      <RequestLoaderWrapper>
        <Form onSubmit={() => ({})}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TableContainer>
                <Table stickyHeader data-cy={testingId.paymentProcessingGroupList.table}>
                  <TransactionGroupTableHeader
                    {...{ setFilter, hasCompletedTransactionGroup, hasAlertingTransactionGroup, hasRewindPermission }}
                  />
                  <TransactionGroupTableBody
                    {...{
                      transactionGroups,
                      toggleId,
                      selectableIds,
                      hasCompletedTransactionGroup,
                      hasAlertingTransactionGroup,
                      hasRewindPermission,
                      refetch,
                      selectedIds
                    }}
                  />
                </Table>
                <Paginator {...{ setPagination, itemsLength: transactionGroups.length }} />
              </TableContainer>
              <>
                <TransactionGroupSelectButtons
                  {...{ selectableIdsForPayroll, selectableIdsForCrediting, toggleSubset }}
                />
                <TransactionGroupActionButtons
                  {...{
                    handleCreditClick,
                    handlePayrollClick,
                    selectableIdsForCrediting,
                    selectableIdsForPayroll,
                    selectedIds
                  }}
                />
              </>
            </form>
          )}
        </Form>
      </RequestLoaderWrapper>
      {modal !== null && modal}
    </ContentContainer>
  )
}

export default memo(TransactionGroupList)
