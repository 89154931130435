import { useRecalculateMutation } from "data/finance/development/mutations"
import React, { memo, MouseEvent, useCallback, useEffect, useState } from "react"
import { DevButton } from "./DevButton"

interface OwnProps {
  type: string
  relation_guids: string[]
}

export const RecalculateButton: React.FC<OwnProps> = memo(({ type, relation_guids }) => {
  RecalculateButton.displayName = "RecalculateButton"

  const { mutateAsync: recalculate } = useRecalculateMutation()

  const [disabled, setDisabled] = useState(false)

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      recalculate([{ type, relation_guids }])
      setDisabled(true)
    },
    [recalculate, relation_guids, type]
  )

  useEffect(() => {
    if (disabled) {
      setTimeout(() => setDisabled(false), 300000)
    }
  }, [disabled])

  return (
    <DevButton onClick={onClick} size="medium" disabled={disabled}>
      Recalc
    </DevButton>
  )
})
