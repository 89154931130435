import { SortableTableHeader } from "components/SortableTableHeader"
import React, { Dispatch, memo, SetStateAction } from "react"
import { InvoiceTransactionFilter } from "../types"

interface OwnProps {
  hasDeveloperPermission: boolean
  hasRewindPermission: boolean
  hasCreditableTransactions: boolean
  hasFiles: boolean
  hasAlerts: boolean
  setFilter: Dispatch<SetStateAction<InvoiceTransactionFilter>>
}

export const TransactionListTableHead: React.FC<OwnProps> = memo(
  ({ setFilter, hasDeveloperPermission, hasRewindPermission, hasCreditableTransactions, hasFiles, hasAlerts }) => {
    TransactionListTableHead.displayName = "TransactionListTableHead"

    const items = [
      {
        label: "Client",
        resource: ["user_contract.owner.user_branch.user.last_name", "user_contract.owner.user_branch.user.first_name"]
      },
      { label: "Contract", resource: "user_contract.reference" },
      { label: "% Conf" },
      { label: "Visits" },
      { label: "Invoice status" },
      { label: "Invoice num", resource: "reference" },
      { label: "Invoice date", resource: "completed_at" },
      { label: "£ Visits" },
      { label: "£ Extras" },
      { label: "£ Total" },
      { label: "Select" },
      ...(hasDeveloperPermission && hasFiles ? [{ label: "", key: "dev" }] : []),
      ...(hasRewindPermission && hasCreditableTransactions ? [{ label: "", key: "rewind" }] : []),
      ...(hasAlerts ? [{ label: "", key: "alert" }] : [])
    ]

    return <SortableTableHeader {...{ setFilter, items }} />
  }
)
