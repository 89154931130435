import API from "api"
import { useMutation, useQueryClient } from "react-query"
import { useUIStore } from "stores"
import { queryKeys } from "data/core/visit/queries"
import {
  VisitCheckInRequest,
  VisitCheckOutRequest,
  VisitReopenRequest,
  VisitSignOffRequest,
  VisitUpdateCommentRequest
} from "./types"
import { MutationCallbacks } from "data/types"
import { ApiVisit } from "models"

export const useUpdateCommentMutation = ({ visitId }: { visitId: string }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: VisitUpdateCommentRequest) => API.updateComment(payload),
    onSuccess: () => {
      useUIStore.getState().showSuccessMessage("Comments successfully saved.")
      queryClient.invalidateQueries(queryKeys.getVisitDetailKey(visitId))
    }
  })
}

export const useCheckInVisitMutation = ({ onSuccess, onError }: MutationCallbacks<ApiVisit, VisitCheckInRequest>) => {
  return useMutation({
    mutationFn: (payload: VisitCheckInRequest) => API.checkInVisit(payload),
    onSuccess,
    onError
  })
}

export const useCheckOutVisitMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: VisitCheckOutRequest) => API.checkOutVisit(payload),
    onSuccess: (data) => {
      useUIStore.getState().showSuccessMessage("Successfully checked out from visit.")
      queryClient.invalidateQueries(queryKeys.getVisitDetailKey(data.guid))
    }
  })
}

export const useSignOffVisitMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: VisitSignOffRequest) => API.signOffVisit(payload),
    onSuccess: (data) => {
      useUIStore.getState().showSuccessMessage("Visit report successfully signed off.")
      queryClient.invalidateQueries(queryKeys.getVisitDetailKey(data.guid))
    }
  })
}

export const useSignOffVisitWithConcernMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: VisitSignOffRequest) => API.signOffVisitWithConcern(payload),
    onSuccess: (data) => {
      useUIStore.getState().showSuccessMessage("Visit report successfully signed off with concern.")
      queryClient.invalidateQueries(queryKeys.getVisitDetailKey(data.guid))
    }
  })
}

export const useReopenVisitMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: VisitReopenRequest) => API.reopenVisit(payload),
    onSuccess: (data) => {
      useUIStore.getState().showSuccessMessage("Visit report successfully reopened.")
      queryClient.invalidateQueries(queryKeys.getVisitDetailKey(data.guid))
    }
  })
}
