import React, { FC, useMemo, useState } from "react"
import EditIcon from "@mui/icons-material/Edit"
import { CheckInCheckOutWithVariantProps } from "./types"
import { Alert, AlertTitle, Typography, Grid } from "@mui/material"
import { ApiUserClient } from "models"
import { calculateDistance, isWithinRange } from "./helpers"
import { isNil } from "ramda"
import { CorrectPinLocationModal, MapPreview, MapPreviewMarker } from "components/GoogleMaps"
import { Button } from "components/Form"
import pinClientAmber from "assets/images/maps/pin_client_amber.svg"
import pinClientGreen from "assets/images/maps/pin_client_green.svg"
import pinCarer from "assets/images/maps/pin_carer.svg"
import { isInteger } from "lodash"
import NoMarkersMessage from "components/GoogleMaps/NoMarkersMessage"
import { useUpdateClientMutation } from "data/carer-profile/mutations"
import useGoogleMapsStore from "stores/useGoogleMapsStore"
import useBranchSettings from "lib/hooks/branchInfo/useBranchSettings"

const DEFAULT_RADIUS = 100

type Props = CheckInCheckOutWithVariantProps & {
  patient: undefined | ApiUserClient
  mapId: string
}

const CheckInCheckOutDetail: FC<Props> = ({ visit, patient, variant = "in", mapId }) => {
  const isMapLoaded = useGoogleMapsStore((state) => state.isLoaded)
  const [isModalOpened, setIsModalOpened] = useState(false)
  const toggleModal = () => setIsModalOpened((isOpen) => !isOpen)
  const { settings: branchSettings } = useBranchSettings()
  const { FeatureMobileGeoCheckInRadius } = branchSettings
  const geoCheckInRadiusConfig = FeatureMobileGeoCheckInRadius && parseInt(FeatureMobileGeoCheckInRadius, 10)
  const isVisitorOnRange = useMemo(() => isWithinRange({ visit, variant }), [visit, variant])
  const { mutateAsync: mutateUpdateClient } = useUpdateClientMutation(patient?.guid)

  const statusIcon = isVisitorOnRange ? pinClientGreen : pinClientAmber

  const visitMarker = useMemo((): null | MapPreviewMarker => {
    const visitLatitude = visit[`check_${variant}`]?.location.latitude
    const visitLongitude = visit[`check_${variant}`]?.location.longitude

    return !visit || !visitLatitude || !visitLongitude
      ? null
      : {
          icon: {
            url: pinCarer
          },
          coordinates: {
            lat: visitLatitude,
            lng: visitLongitude
          }
        }
  }, [variant, visit])

  const patientMarker = useMemo(() => {
    if (!patient) {
      return null
    }

    return {
      buffer: {
        strokeColor: isVisitorOnRange ? "#4CAF50" : "#FF9800",
        strokeWeight: 2,
        strokeOpacity: 0.5,
        fillColor: isVisitorOnRange ? "#EDF7ED" : "#FDF0E6",
        fillOpacity: 0.45,
        radius: isInteger(geoCheckInRadiusConfig) ? geoCheckInRadiusConfig : DEFAULT_RADIUS
      },
      coordinates: {
        lat: patient.profile.address_latitude,
        lng: patient.profile.address_longitude
      },
      icon: {
        url: statusIcon,
        scaledSize: [250, 250],
        origin: [0, 0],
        anchor: [125, 140]
      }
    }
  }, [statusIcon, patient, isVisitorOnRange, geoCheckInRadiusConfig])

  const distance = useMemo(() => {
    if (!patientMarker || !visitMarker) {
      return 0
    }

    const startCoordinates = {
      lat1: visitMarker.coordinates.lat,
      lon1: visitMarker.coordinates.lng
    }
    const endCoordinates = {
      lat2: patientMarker.coordinates.lat,
      lon2: patientMarker.coordinates.lng
    }

    const distance = calculateDistance(startCoordinates, endCoordinates)

    if (distance < 1) {
      return `${(distance * 1000).toFixed(1)} meters`
    }

    return `${distance.toFixed(1)} kilometers`
  }, [patientMarker, visitMarker])

  const onChangePatientAddress = async (coordinates: google.maps.LatLngLiteral) => {
    if (!patient) {
      return
    }

    await mutateUpdateClient({
      ...patient,
      profile: {
        ...patient.profile,
        address_latitude: coordinates.lat,
        address_longitude: coordinates.lng
      }
    })
    toggleModal()
  }

  const markers = [patientMarker, visitMarker].filter(Boolean) as MapPreviewMarker[]
  const shouldShowNoMarkersMessage = !markers || markers.length === 0

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          Check {variant} distance
        </Typography>
        <Typography variant="h6">{distance}</Typography>
      </Grid>
      {!isNil(visit[`check_${variant}`]?.location.accuracy) && (
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            Approximate accuracy
          </Typography>
          <Typography variant="h6">{visit[`check_${variant}`]?.location.accuracy.toFixed(1)} meters</Typography>
        </Grid>
      )}
      {visit[`check_${variant}`]?.location.outside_range_reason && (
        <Grid item xs={12}>
          <Alert severity="warning" sx={(theme) => ({ marginTop: theme.spacing(3) })}>
            <AlertTitle>Manual check-{variant}</AlertTitle>
            {visit[`check_${variant}`]?.location.outside_range_reason}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        {isMapLoaded && (
          <MapPreview
            markers={markers}
            center={markers[0]?.coordinates}
            noMarkersMessageComponent={shouldShowNoMarkersMessage ? <NoMarkersMessage /> : undefined}
            mapId={mapId}
          />
        )}
        <Button
          startIcon={<EditIcon />}
          variant="text"
          fullWidth={false}
          disabled={markers.length === 0}
          onClick={toggleModal}
        >
          Edit pin location
        </Button>
        {isModalOpened && (
          <CorrectPinLocationModal
            fullAddress={patient?.profile.address}
            center={markers[0]?.coordinates}
            onCancel={toggleModal}
            onSave={onChangePatientAddress}
            mapId={`${mapId}-edit`}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default CheckInCheckOutDetail
