import { useEffect, useState } from "react"

const useInViewPort = (ref: React.RefObject<HTMLDivElement>, options?: IntersectionObserverInit) => {
  const [inViewport, setInViewport] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setInViewport(entry.isIntersecting)
    }, options)

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [ref, options])

  return inViewport
}

export default useInViewPort
