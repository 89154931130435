import React, { memo, useState } from "react"
import {
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Fade,
  TableCell,
  TableRow,
  IconButton,
  Paper,
  Popper,
  PopperProps,
  Tooltip
} from "@mui/material"
import {
  OpenInNew,
  Warning,
  Update,
  Delete,
  DescriptionOutlined,
  MoreVertOutlined,
  GetApp,
  SyncAlt
} from "@mui/icons-material"
import { ApiUserFile } from "../models"
import dateTimeUtils from "lib/datetime"
import testingId from "constants/testingId"

interface OwnProps {
  file: ApiUserFile
  categories: { [key: string]: string }
  downloadFile: Function
  confirmDelete: (item: ApiUserFile) => void
  openExpiryModal: (item: ApiUserFile) => void
  handleReplaceFile: (item: ApiUserFile) => void
  anchorEl: PopperProps["anchorEl"]
  setAnchorEl: Function
}

const FileListItem: React.FC<OwnProps> = ({
  file,
  categories,
  anchorEl,
  setAnchorEl,
  confirmDelete,
  openExpiryModal,
  handleReplaceFile,
  downloadFile
}) => {
  const [isEditOpen, setEditOpen] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    setAnchorEl(event.currentTarget)
    setEditOpen(true)
  }

  const handleClickAway = () => {
    setEditOpen(false)
  }

  return (
    <TableRow data-cy={testingId.tableRow}>
      <TableCell>
        <DescriptionOutlined />
      </TableCell>
      <TableCell>{dateTimeUtils.formatDate(file.uploaded_at)}</TableCell>
      <TableCell>{file.title}</TableCell>
      <TableCell>{categories[file.category_guid]}</TableCell>
      <TableCell>{file.uploaded_by_name}</TableCell>
      <TableCell>{file.expires_at ? dateTimeUtils.formatDate(file.expires_at) : null}</TableCell>
      <TableCell>
        {file.warnings.length > 0 ? (
          <Tooltip title={file.warnings.toString()} placement="right">
            <Warning color="primary" />
          </Tooltip>
        ) : null}
      </TableCell>
      <TableCell>
        <Popper open={isEditOpen} anchorEl={anchorEl} placement="bottom" transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={(theme) => ({
                  padding: theme.spacing(0, 1)
                })}
              >
                <ClickAwayListener onClickAway={handleClickAway}>
                  <List component="nav">
                    <ListItemButton
                      onClick={() => {
                        setEditOpen(false)
                        downloadFile(file, "open")
                      }}
                    >
                      <ListItemIcon>
                        <OpenInNew />
                      </ListItemIcon>
                      <ListItemText primary="Open in new tab" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => {
                        setEditOpen(false)
                        handleReplaceFile(file)
                      }}
                    >
                      <ListItemIcon>
                        <SyncAlt />
                      </ListItemIcon>
                      <ListItemText primary="Replace" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => {
                        setEditOpen(false)
                        downloadFile(file, "save")
                      }}
                    >
                      <ListItemIcon>
                        <GetApp />
                      </ListItemIcon>
                      <ListItemText primary="Download" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => {
                        setEditOpen(false)
                        openExpiryModal(file)
                      }}
                    >
                      <ListItemIcon>
                        <Update />
                      </ListItemIcon>
                      <ListItemText primary="Update expiration date" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => {
                        setEditOpen(false)
                        confirmDelete(file)
                      }}
                    >
                      <ListItemIcon>
                        <Delete />
                      </ListItemIcon>
                      <ListItemText primary="Delete" />
                    </ListItemButton>
                  </List>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
        <IconButton aria-label="edit" onClick={handleClick}>
          <MoreVertOutlined />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default memo(FileListItem)
