import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { FormSpyCustom, Select, TextField } from "components/Form"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"
import testingId from "constants/testingId"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { Pagination } from "lib/types"
import { ClearFilterButton } from "components/ClearFilterButton"
import { PaymentTransactionFilter } from "../types"

export const defaultPaymentTransactionFilter = {
  percent_confirmed: "ShowAny"
} as PaymentTransactionFilter

interface Props {
  resetPagination: () => void
  pagination: Pagination
  filter: PaymentTransactionFilter
  setFilter: Dispatch<SetStateAction<PaymentTransactionFilter>>
}

const TransactionListFilter: React.FC<Props> = ({ resetPagination, pagination, filter, setFilter }) => {
  const onFilterSubmit = useDebouncedCallback((values: PaymentTransactionFilter) => {
    setFilter(values)
    resetPagination()
  }, 500)

  const clearFilter = () => {
    setFilter(defaultPaymentTransactionFilter)
    resetPagination()
  }

  useFilterLocationChange({ filter, pagination })

  return (
    <Form onSubmit={onFilterSubmit} initialValues={filter}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpyCustom {...{ handleSubmit }} />
          <Grid container spacing={1} alignItems="center">
            <Grid item md={6}>
              <Field name="user_name" label="Carer name" component={TextField} />
            </Grid>
            <Grid item md={2}>
              <Field
                name="percent_confirmed"
                label="% Confirmed Hours"
                component={Select}
                options={[
                  { title: "Show Any", value: "ShowAny" },
                  { title: "Show Under 100% Confirmed", value: "ShowUnder100Percent" },
                  { title: "Show Only 100% Confirmed", value: "ShowOnly100Percent" }
                ]}
                data-cy={testingId.select}
              />
            </Grid>
            <Grid item md={2} />
            <Grid item md={2}>
              <Box textAlign="right">
                <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  )
}

export default memo(TransactionListFilter)
