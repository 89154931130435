import Box from "@mui/material/Box"
import { ContentContainer, Link, Divider } from "components"
import React, { memo, useMemo, useState } from "react"
import { ContractModelTitle } from "./components"
import testingId from "constants/testingId"
import { useContractServiceAccrualPoliciesListQuery } from "data/finance/contractModel/queries"
import { queryStringToObject } from "lib/queryString"
import { AccrualPolicyFilter, AccrualPolicyListFilter } from "./components/AccrualPolicyListFilter"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { filterContractAccrualPolicies } from "./helpers/filterContractAccrualPolicies"
import AccrualPolicyList from "./components/AccrualPolicyList"
import { ContractModelType } from "constants/modelTypes"
import { useLocation } from "react-router-dom"
import { ContractModel } from "data/finance/contractModel/types"

interface OwnProps {
  contractModel: ContractModel
}

const AccrualPolicies: React.FC<OwnProps> = ({ contractModel }) => {
  const location = useLocation()
  const [filter, setFilter] = useState<AccrualPolicyFilter>(queryStringToObject(location?.search))

  const { data: allPolicies } = useContractServiceAccrualPoliciesListQuery({
    contractGuid: contractModel.guid
  })

  const currentPolicies = useMemo(() => filterContractAccrualPolicies(allPolicies ?? [], filter), [allPolicies, filter])

  return (
    <ContentContainer>
      <ContractModelTitle
        data-cy={testingId.contractModel.services.title}
        modelType={ContractModelType.PAYMENT}
        title={
          <>
            <Link to={`../settings`}>{contractModel.title}</Link> / Accrual Policies
          </>
        }
      />
      <Box m={3} mx={0}>
        <AccrualPolicyListFilter {...{ filter, setFilter }} />
        <Divider color="divider" />
        <RequestLoaderWrapper>
          <AccrualPolicyList policies={currentPolicies} />
        </RequestLoaderWrapper>
      </Box>
    </ContentContainer>
  )
}

export default memo(AccrualPolicies)
