import React, { memo, useCallback } from "react"
import { ContractModelType } from "constants/modelTypes"
import { InvoiceFileItem } from "data/finance/invoiceFile/types"
import { useInvoiceFileDownloadPostMutation } from "data/finance/invoiceFile/mutations"
import { usePaymentFileDownloadPostMutation } from "data/finance/paymentFile/mutations"
import { FileManagementModal } from "./FileManagementModal"
import { BusinessDownloadMode } from "../types"

interface DownloadFormValues {
  download_mode: BusinessDownloadMode
}

interface OwnProps {
  invoices: InvoiceFileItem[]
  modelType: ContractModelType
  onCancel: () => void
  onOk: () => void
}

const downloadOptions: Array<{ title: string; value: BusinessDownloadMode }> = [
  { title: "Download individual files per client", value: "Individual" },
  { title: "Combine files per Contract model", value: "CombinePerContractModel" },
  { title: "Combine all files", value: "CombineAll" }
]

export const FileManagementModalDownload: React.FC<OwnProps> = memo(({ invoices, modelType, onCancel, onOk }) => {
  FileManagementModalDownload.displayName = "FileManagementModalDownload"
  const isInvoiceModel = modelType === ContractModelType.INVOICE

  const { mutateAsync: postInvoiceDownload } = useInvoiceFileDownloadPostMutation()
  const { mutateAsync: postPaymentDownload } = usePaymentFileDownloadPostMutation()

  const onSubmit = useCallback(
    ({ download_mode }: DownloadFormValues) => {
      const transaction_guids = invoices.map((i) => i.guid)
      if (isInvoiceModel) {
        postInvoiceDownload({ download_mode, transaction_guids })
      } else {
        postPaymentDownload({ download_mode, transaction_guids })
      }
      onOk()
    },
    [invoices, isInvoiceModel, onOk, postInvoiceDownload, postPaymentDownload]
  )

  return (
    <FileManagementModal
      {...{
        isInvoiceModel,
        onCancel,
        onSubmit,
        downloadOptions,
        files: invoices,
        submitButtonLabel: "DOWNLOAD FILES",
        modalTitle: `Download ${isInvoiceModel ? "Invoices" : "Payments"}`
      }}
    />
  )
})
