import React, { memo, useMemo } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import MoneyWrapper from "components/MoneyWrapper"
import testingId from "constants/testingId"
import { formatPaymentTransactionDetailMoney } from "../helpers/formatPaymentTransactionDetailMoney"
import { PaymentTransactionCharge, PaymentTransactionItem } from "data/finance/paymentProcessing/types"

const amountWrapper = (amount: number, prefix?: string) => <MoneyWrapper prefix={prefix} amount={amount} />
const hourWrapper = (total: string) => <span style={{ whiteSpace: "nowrap" }}>{total}</span>

interface OwnProps {
  isVisits?: boolean
  filteredVisits: PaymentTransactionItem[]
  filteredCharges: PaymentTransactionCharge[]
}

export const TransactionDetailMoney: React.FC<OwnProps> = memo(({ isVisits, filteredVisits, filteredCharges }) => {
  const totals = useMemo(() => {
    const money = formatPaymentTransactionDetailMoney({ visits: filteredVisits, charges: filteredCharges })

    return [
      isVisits ? ["Hours:", hourWrapper(money.totalHours)] : ["Mileage:", amountWrapper(money.mileage)],
      ["Visits:", amountWrapper(money.visits)],
      ["Extras:", amountWrapper(money.extras)],
      ["Credited:", money.credited > 0 ? amountWrapper(money.credited, "-") : amountWrapper(Math.abs(money.credited))],
      ["Total value:", amountWrapper(money.total)]
    ]
  }, [filteredVisits, filteredCharges, isVisits])

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      flexGrow={1}
      sx={(theme) => ({
        "& div": {
          marginLeft: theme.spacing(4)
        }
      })}
    >
      {totals.map(([label, value], index) => (
        <div key={index} data-cy={testingId.statsItem}>
          <Typography variant="h6" component="span">
            {label}
          </Typography>{" "}
          <Typography variant="h6" color="primary" component="span">
            {value}
          </Typography>
        </div>
      ))}
    </Box>
  )
})

TransactionDetailMoney.displayName = "TransactionDetailMoney"
