import React from "react"
import { ApiMobilityAssessment } from "models/mobilityAssessment"
import { Box, Grid, Typography } from "@mui/material"
import { MobilityAssessmentTemplate, MobilityAssessmentTemplateKeys } from "./mobilityAssessmentTemplate"

interface Props {
  mobilityReport: ApiMobilityAssessment
}

const MobilityAssessmentReport = ({ mobilityReport }: Props) => {
  const reportSections = Object.keys(mobilityReport) as (keyof MobilityAssessmentTemplateKeys)[]

  return (
    <Box m={5} mx={0}>
      <Typography
        variant="h6"
        sx={{
          marginBottom: 24
        }}
      >
        Mobility Assessment
      </Typography>
      <Grid container spacing={3}>
        {reportSections.map((section) => {
          const sectionTemplate = MobilityAssessmentTemplate[section]

          if (!sectionTemplate) {
            return null
          }

          const selectedOption = sectionTemplate.options.find((option) => option.value === mobilityReport[section])

          return (
            <Grid item md={6} key={section}>
              <Typography variant="body2" color="textSecondary">
                {sectionTemplate.title}
              </Typography>
              <Typography variant="body2">{selectedOption?.title}</Typography>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default MobilityAssessmentReport
