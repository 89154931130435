import { snakeCase } from "lodash"

const getEnvVariable = (name: string): string => {
  const windowEnvVars = (window as any)["environmentVariables"]
  let v: string | undefined
  const processEnv = import.meta.env

  if (windowEnvVars?.[name]) {
    v = windowEnvVars[name]
    // if (!v) console.warn(`Undefined environment variable ${name} on window.`)
  } else {
    v = processEnv[snakeCase(name).toUpperCase()]
    // if (!v) console.warn(`Undefined environment variable ${name} on process.env.`)
  }

  if (v) {
    return v.trim()
  }
  return ""
}

const Config = {
  getEnvVariable
}

export default Config
