import { GENERAL_ERROR_MESSAGE } from "api/instance"
import { useCallback } from "react"
import { useQueryClient } from "react-query"
import { usePaymentTransactionChargeDeleteMutation } from "./mutations"
import { queryKeys } from "./queries"
import { useApiMessage } from "stores"
import { PaymentTransactionCharge } from "./types"

interface Props {
  transactionGuid: string
  periodGuid: string
  charges: PaymentTransactionCharge[]
  onSuccess: () => void
}

export const usePaymentTransactionChargesDeleteHandler = ({
  periodGuid,
  transactionGuid,
  charges,
  onSuccess
}: Props): (() => Promise<void>) => {
  const queryCache = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const { mutateAsync: deleteCharge } = usePaymentTransactionChargeDeleteMutation({ transactionGuid, periodGuid })

  return useCallback(async () => {
    if (!charges?.length) return

    try {
      await Promise.all(
        charges.map((charge) => {
          return deleteCharge({ chargeGuid: charge.guid })
        })
      )

      showSuccessMessage(`Extra${charges.length > 1 ? "s" : ""} successfully deleted.`)
      onSuccess()
    } catch (apiError: any) {
      showErrorMessage(GENERAL_ERROR_MESSAGE, { apiError })
    } finally {
      queryCache.invalidateQueries(
        queryKeys.getPaymentProcessingTransactionChargeListKey({
          transactionGuid,
          periodGuid
        })
      )
    }
  }, [periodGuid, transactionGuid, charges, deleteCharge, showSuccessMessage, onSuccess, queryCache, showErrorMessage])
}
