import { Box, Typography } from "@mui/material"
import MoneyWrapper from "components/MoneyWrapper"
import { TooltipInfo } from "components/TooltipInfo"
import { AdditionalSearchFilterValues, ApiPaymentAdditionalSearch, ApprovalStatus } from "data/finance/additional/type"
import React, { memo, useMemo } from "react"

interface OwnProps {
  additionals: ApiPaymentAdditionalSearch[]
  filter: AdditionalSearchFilterValues
}

export const AdditionalSearchSummary: React.FC<OwnProps> = memo(({ additionals, filter }) => {
  AdditionalSearchSummary.displayName = "AdditionalSearchSummary"

  const stats = useMemo(() => {
    const getSubtotal = (status: ApprovalStatus) =>
      additionals.filter((a) => a.status === status).reduce((res, a) => res + a.total, 0)

    return [
      { title: "Payments", value: additionals.length },
      {
        title: "Approved",
        value: getSubtotal("Approved"),
        disabled: filter.approved && filter.approved !== "Approved",
        isMoney: true
      },
      {
        title: "Awaiting approval",
        value: getSubtotal("Pending"),
        disabled: filter.approved && filter.approved !== "Pending",
        isMoney: true
      },
      {
        title: "Rejected",
        value: getSubtotal("Rejected"),
        disabled: filter.approved && filter.approved !== "Rejected",
        isMoney: true
      },
      {
        title: "Total",
        value: additionals.reduce((res, a) => res + a.total, 0),
        isMoney: true,
        isTotal: true
      }
    ]
  }, [additionals, filter])

  return (
    <Box
      sx={{
        padding: 2,
        boxShadow: 2,
        marginBottom: 2
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          marginBottom: 2,
          display: "flex",
          alignItems: "center"
        }}
      >
        Summary
        <TooltipInfo ml={5} title="Overview of all additional payments and can be filtered together with the table." />
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "stretch"
        }}
      >
        {stats.map((stat) => (
          <Box
            key={stat.title}
            sx={{
              flexGrow: 1,
              ...(stat.isTotal
                ? {
                    paddingLeft: 2,
                    borderLeft: "3px solid",
                    borderColor: "grey.300"
                  }
                : {}),
              ...(stat.disabled
                ? {
                    color: "text.disabled"
                  }
                : {})
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold"
              }}
            >
              {stat.title}
            </Typography>
            <Typography>{stat.isMoney ? <MoneyWrapper amount={stat.value} /> : stat.value}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  )
})
