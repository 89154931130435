import Box from "@mui/material/Box"
import { Button } from "components/Form"
import React, { memo, ReactNode, useState } from "react"
import { WithPermission } from "components/Auth"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import { AddUserTransactionModal } from "pages/Finance/components/AddUserTransactionModal"
import { QueryRefetch } from "lib/types"
import { usePaymentProcessingTransactionGroupQuery } from "data/finance/paymentProcessing/queries"
import { Tooltip } from "@mui/material"
import { PaymentTransaction } from "data/finance/paymentProcessing/types"

interface Props {
  periodGuid: string
  refetch: QueryRefetch<PaymentTransaction[]>
}

const AddButton: React.FC<{ disabled?: boolean; onClick: () => void }> = ({ disabled = false, onClick }) => (
  <Button
    {...{
      fullWidth: false,
      color: "primary",
      "data-cy": testingId.paymentProcessingTransactionList.addCarerButton,
      onClick,
      disabled
    }}
  >
    Add Carer
  </Button>
)

const TransactionListAddButton: React.FC<Props> = ({ periodGuid, refetch }) => {
  const [addCarerModal, setAddCarerModal] = useState<ReactNode>(null)

  const { data: transactionPeriod } = usePaymentProcessingTransactionGroupQuery({ periodGuid })

  const onCarerAdded = () => {
    refetch()
    setAddCarerModal(null)
  }

  const onCancel = () => {
    setAddCarerModal(null)
  }

  const onClick = () => {
    setAddCarerModal(
      <AddUserTransactionModal
        {...{
          modelType: ContractModelType.PAYMENT,
          periodGuid,
          onCancel,
          onOk: onCarerAdded
        }}
      />
    )
  }

  const isPeriodClosed = !!transactionPeriod?.paid_at

  return (
    <WithPermission permissions={["Finance.Processing.User:Edit"]}>
      <Box textAlign="right">
        {isPeriodClosed ? (
          <Tooltip title="Can not add Carer because this transaction group is already paid.">
            <div>
              <AddButton {...{ disabled: true, onClick }} />
            </div>
          </Tooltip>
        ) : (
          <AddButton {...{ onClick }} />
        )}
      </Box>
      {addCarerModal !== null && addCarerModal}
    </WithPermission>
  )
}

export default memo(TransactionListAddButton)
