import { FormApi } from "final-form"
import { useEffect, useMemo } from "react"
import { useFormState } from "react-final-form"
import { RateUpliftForm } from "../types"

interface OwnProps {
  contractGuid: string
  serviceGuid: string
  form: FormApi<RateUpliftForm>
}

export const useRateUpliftServiceTypeUpdateHandler = ({ contractGuid, serviceGuid, form }: OwnProps): void => {
  const { values } = useFormState<RateUpliftForm>()

  const [upliftValue, upliftType] = useMemo(() => {
    const formService = values.models[contractGuid]
    return [formService?.uplift_value, formService?.uplift_type]
  }, [contractGuid, values.models])

  const formServiceUpliftTypePath = `models[${contractGuid}].services[${serviceGuid}].uplift_type`
  const formServiceUpliftValuePath = `models[${contractGuid}].services[${serviceGuid}].uplift_value`

  // update rate if service uplift value or type changes
  useEffect(() => {
    if (upliftType && upliftValue) {
      // @ts-ignore
      form.change(formServiceUpliftTypePath, upliftType)
      // @ts-ignore
      form.change(formServiceUpliftValuePath, upliftValue)
    }
    // we do not want form as a dependency
  }, [upliftValue, upliftType, formServiceUpliftTypePath, formServiceUpliftValuePath]) // eslint-disable-line
}
