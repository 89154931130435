import React, { Dispatch, memo, SetStateAction } from "react"
import { Form } from "react-final-form"
import Grid from "@mui/material/Grid"
import { FormSpyCustom } from "components/Form"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { StatusSelectField } from "components/Form/StatusSelectField"
import { ClearFilterButton } from "components/ClearFilterButton"
import { StatusEnum } from "data/commonTypes"

export interface InvoiceModelRuleGroupFilter {
  status?: StatusEnum
}

interface OwnProps {
  filter: InvoiceModelRuleGroupFilter
  setFilter: Dispatch<SetStateAction<InvoiceModelRuleGroupFilter>>
}

export const RuleGroupListFilter: React.FC<OwnProps> = memo(({ setFilter, filter }) => {
  const onSubmit = (values: InvoiceModelRuleGroupFilter) => {
    setFilter(values)
  }

  useFilterLocationChange({ filter })

  const clearFilter = () => {
    setFilter(() => ({}))
  }

  return (
    <Form onSubmit={onSubmit} initialValues={filter}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpyCustom {...{ handleSubmit }} />
          <Grid container alignItems="center" spacing={1}>
            <Grid item md={3}>
              <StatusSelectField multiselect />
            </Grid>
            <Grid item md={6}>
              <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  )
})

RuleGroupListFilter.displayName = "RuleGroupListFilter"
