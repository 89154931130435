import { PaymentTransactionCharge } from "data/finance/paymentProcessing/types"
import { PaymentTransactionChargesFilter } from "../components/TransactionDetailChargesFilter"

export const filterPaymentTransactionCharges = ({
  charges,
  filter
}: {
  charges: PaymentTransactionCharge[]
  filter: PaymentTransactionChargesFilter
}): PaymentTransactionCharge[] => {
  if (!filter) return charges

  const { reference_owner_name, charge_dates, charge_type_alias } = filter
  let result = charges
  if (reference_owner_name) {
    result = result.filter(
      (charge) =>
        charge.reference_owner_name &&
        charge.reference_owner_name.toLowerCase().indexOf(reference_owner_name.toLowerCase()) !== -1
    )
  }

  if (charge_type_alias?.length) {
    result = result.filter((charge) => charge_type_alias.includes(charge.charge_type_alias))
  }

  if (typeof charge_dates === "boolean") {
    result = result.filter((charge) => charge.is_historic === charge_dates)
  }

  return result
}
