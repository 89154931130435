import { objectToQueryString, queryStringToObject } from "lib/queryString"
import { omit, pick as pickFn } from "ramda"

const prepareSearch = (result?: string): string => (result ? `?${result}` : "")

export const removeFromSearchString = ({ search, remove }: { search?: string; remove: string[] }): string => {
  const obj = queryStringToObject(search)
  const removed = omit(remove, obj)
  const result = objectToQueryString(removed)
  return prepareSearch(result)
}

export const pickFromSearchString = ({ search, pick }: { search?: string; pick: string[] }): string => {
  const obj = queryStringToObject(search)
  // @ts-ignore - pick has broken types in @types/ramda,
  const picked = pickFn(pick, obj) as Record<string, any>
  const result = objectToQueryString(picked)
  return prepareSearch(result)
}

export const addToSearchString = ({
  search,
  add
}: {
  search?: string
  add: Record<string, string | string[]>
}): string => {
  const obj = queryStringToObject(search)
  const result = objectToQueryString({ ...obj, ...add })
  return prepareSearch(result)
}
