import { Box } from "@mui/material"
import { FormSectionHeader } from "components/Form"
import { usePagination } from "components/handlers/usePagination"
import { useCarerAccrualListQuery } from "data/finance/carer/queries"
import { queryStringToObject } from "lib/queryString"
import { ApiUserCarer } from "models"
import React, { memo, useState } from "react"
import { CarerAccrualListFilter, CarerAccrualListFilterValues } from "./CarerAccrualListFilter"
import { CarerAccrualListTable } from "./CarerAccrualListTable"
import { CarerAccrualListTablePrimaryBranchTag } from "./CarerAccrualListTablePrimaryBranchTag"
import { useLocation } from "react-router-dom"
import { CarerAccrualBranchWarning } from "./CarerAccrualBranchWarning"

interface OwnProps {
  carer: ApiUserCarer
}

export const CarerAccrualList: React.FC<OwnProps> = memo(({ carer }) => {
  CarerAccrualList.displayName = "CarerAccrualList"

  const location = useLocation()

  const [filter, setFilter] = useState<CarerAccrualListFilterValues>(
    queryStringToObject<CarerAccrualListFilterValues>(location.search)
  )
  const { pagination, setPagination, resetPagination } = usePagination({ initialPageSize: 100 })

  const { data: accruals, isLoading } = useCarerAccrualListQuery({ carerGuid: carer.guid })

  return (
    <Box my={3}>
      <FormSectionHeader title="Annual Leave Accrual" />
      <CarerAccrualBranchWarning carerGuid={carer.guid} />
      <CarerAccrualListTablePrimaryBranchTag accruals={accruals} />
      <CarerAccrualListFilter {...{ filter, setFilter, pagination, resetPagination }} />
      <CarerAccrualListTable {...{ carer, setPagination, filter, accruals, isLoading }} />
    </Box>
  )
})
