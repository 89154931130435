import React, { memo } from "react"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import dateTimeUtils from "lib/datetime"
import { moneyFormat } from "lib/utils"
import testingId from "constants/testingId"
import { Check, Clear } from "@mui/icons-material"
import { green } from "@mui/material/colors"
import { HolidayCalculationItemDetails } from "data/finance/paymentProcessing/types"

interface Props {
  calculations: HolidayCalculationItemDetails[]
}

export const HolidayEstimationCalculations: React.FC<Props> = memo(({ calculations }) => {
  HolidayEstimationCalculations.displayName = "HolidayEstimationCalculations"

  if (!calculations) return null

  return (
    <Table data-cy={testingId.holidayEstimation.table}>
      <TableHead>
        <TableRow>
          <TableCell>Week no.</TableCell>
          <TableCell>Week beginning</TableCell>
          <TableCell>Pay</TableCell>
          <TableCell>Hours</TableCell>
          <TableCell>Days</TableCell>
          <TableCell>Excluded</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {calculations.map((calculation, index) => (
          <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{dateTimeUtils.formatDate(calculation.start)}</TableCell>
            <TableCell>{moneyFormat.format(calculation.pay)}</TableCell>
            <TableCell>{calculation.hours}</TableCell>
            <TableCell>{calculation.days}</TableCell>
            <TableCell>
              {calculation.excluded ? (
                <Clear color="error" />
              ) : (
                <Check
                  sx={{
                    color: green[700]
                  }}
                />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})
