export const getItemsTableHeaderLabels = (hasTransactionReference: boolean): string[] => {
  const labels = [
    "",
    "Visit Date",
    "Start (R)",
    "Period",
    "Client",
    "Service Type",
    "Contract Model",
    "Chargeable Hours",
    "Status",
    "Processed",
    "£ Cost"
  ]

  return hasTransactionReference ? [...labels, "Credit"] : labels
}
