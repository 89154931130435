import React from "react"
import { MenuItem, Chip } from "@mui/material"
import { OptionProps } from "react-select/src/components/Option"
import { OptionType } from "./Autocomplete"
import Initials from "./Initials"

function Option(props: OptionProps<OptionType, false>) {
  return (
    <MenuItem ref={props.innerRef} selected={props.isFocused} component="div" {...props.innerProps}>
      <Initials withName name={props.data.label} guid={props.data.value} />
    </MenuItem>
  )
}

function MultiValue(props: any) {
  return (
    <Chip
      sx={(theme) => ({ marginRight: theme.spacing(0.7), marginBottom: theme.spacing(0.7) })}
      onDelete={props.removeProps.onClick}
      avatar={<Initials size="small" withName name={props.data.label} guid={props.data.value} />}
      {...props.selectProps.ChipProps}
    />
  )
}

const useMultiSelect = (props: any, componentProps: any) => {
  const shouldDisable = props.multiLimit && props.multiLimit === props.input.value.length

  return {
    configProps: {
      onChange: (value: Array<Object>) => props.input.onChange(value),
      menuPlacement: "auto",
      menuPosition: "fixed",
      menuPortalTarget: document.body,
      isMulti: true,
      value: props.input.value,
      isSearchable: !shouldDisable,
      components: {
        Option,
        MultiValue,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        ...componentProps
      }
    }
  }
}

export default useMultiSelect
