import React, { memo, ReactNode } from "react"

interface OwnProps {
  children: ReactNode
  index: number | string
  value: number | string
}

const TabPanel: React.FC<OwnProps> = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

export default memo(TabPanel)
