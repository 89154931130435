import { Header } from "components"
import testingId from "constants/testingId"
import { usePaymentModelQuery } from "data/finance/contractModel/queries"
import { CalendarPeriod } from "data/finance/contractModel/types"
import dateTimeUtils from "lib/datetime"
import React, { memo } from "react"

interface OwnProps {
  contractGuid: string
  period: CalendarPeriod
}

const TransactionListHeader: React.FC<OwnProps> = ({ contractGuid, period }) => {
  const { data: contract } = usePaymentModelQuery(contractGuid)

  return (
    <Header
      title={`${contract?.title} (${dateTimeUtils.formatDate(period.start)} - ${dateTimeUtils.formatDate(period.end)})`}
      data-cy={testingId.tableTitle}
    />
  )
}

export default memo(TransactionListHeader)
