import React, { memo, useMemo } from "react"
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material"
import { Button, Checkbox, FormSectionHeader, TextField } from "components/Form"
import { isUndefined } from "lodash"
import { useSelectableIdsDictionary } from "lib/hooks"
import { Field, FormSpy } from "react-final-form"
import DeleteIcon from "@mui/icons-material/Delete"
import Box from "@mui/material/Box"
import { FieldArray } from "react-final-form-arrays"
import { grey } from "@mui/material/colors"
import { UnitContract } from "data/finance/unit/types"
import Auth from "lib/Auth"
import { Permission } from "constants/permission"
import { DateField } from "components/Form/DateField"
import validators from "lib/validators"
import { FrequencySelectField } from "components/Form/FrequencySelectField"
import { ContractRate } from "data/finance/client/types"

const unitRatesHeaderData = ["Start date", "Ends before", "Rate", "Hours", "Frequency", "Status"]

interface OwnProps {
  contract?: UnitContract
}

interface WrappedFields {
  value: ContractRate[]
  remove: (idx: number) => void
}

export const UnitContractStandingCharges: React.FC<OwnProps> = memo(({ contract }) => {
  UnitContractStandingCharges.displayName = "UnitContractStandingCharges"

  const deletableIds = useMemo(
    () => contract?.rates?.filter((rate) => rate.status === "Future").map((rate) => rate.guid),
    [contract]
  )

  const { toggleId, getSelectedAsStringArr, selectableIds } = useSelectableIdsDictionary(deletableIds)

  const deleteSelected = (fields: WrappedFields) => () => {
    const idsToDelete = getSelectedAsStringArr()
    idsToDelete.forEach((id: string) => {
      const index = fields.value.findIndex((rate: ContractRate) => id === rate.guid)
      fields.remove(index)
    })
  }

  const canEdit = Auth.hasPermission([Permission.FINANCE_USER_CONTRACT_RATE_EDIT])

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <FieldArray name="rates">
          {({ fields }) => {
            if (!fields.length && !canEdit) return null
            return (
              <>
                <FormSectionHeader title="Standing Charges" />
                <Table>
                  <TableHead>
                    <TableRow>
                      {canEdit && (
                        <TableCell style={{ textAlign: "left" }}>
                          <DeleteIcon onClick={deleteSelected(fields)} />
                        </TableCell>
                      )}
                      {unitRatesHeaderData.map((label) => (
                        <TableCell key={label}>{label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((name, index) => {
                      const itemGuid = fields.value[index].guid
                      const isToBeDeleted = getSelectedAsStringArr().includes(itemGuid)

                      const isDisabled = isToBeDeleted || !canEdit

                      return (
                        <TableRow
                          key={itemGuid}
                          style={{
                            opacity: isToBeDeleted ? 0.4 : 1,
                            backgroundColor: isToBeDeleted ? grey[100] : "transparent"
                          }}
                        >
                          {canEdit && (
                            <TableCell>
                              {!isUndefined(selectableIds[itemGuid]) ? (
                                <Field<boolean>
                                  type="checkbox"
                                  label=""
                                  component={Checkbox}
                                  name={`${name}[delete]`}
                                  onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation()
                                    toggleId(itemGuid)
                                  }}
                                />
                              ) : null}
                            </TableCell>
                          )}
                          <TableCell>
                            <DateField
                              name={`${name}.start`}
                              label="Start Date"
                              required
                              disabled={isDisabled}
                              minDate={values.start ?? undefined}
                              maxDate={values.no_end_date || !values.end ? undefined : values.end.minus({ days: 1 })}
                            />
                          </TableCell>
                          <TableCell>
                            <DateField
                              name={`${name}.end`}
                              label="End Date"
                              disabled={isDisabled}
                              minDate={values.start ? values.start.plus({ days: 1 }) : undefined}
                              maxDate={values.no_end_date || !values.end ? undefined : values.end}
                            />
                          </TableCell>
                          <TableCell>
                            <Field
                              name={`${name}.rate`}
                              label="Rate *"
                              component={TextField}
                              disabled={isDisabled}
                              validate={validators.validateCommonNumber({ min: 0 })}
                            />
                          </TableCell>
                          <TableCell>
                            <Field
                              name={`${name}.hours`}
                              label="Chargeable Hours *"
                              component={TextField}
                              validate={validators.validateCommonNumber({ precisionValue: 2, min: 0 })}
                              disabled={isDisabled}
                            />
                          </TableCell>
                          <TableCell>
                            <FrequencySelectField
                              name={`${name}.frequency_type_alias`}
                              required
                              disabled={isDisabled}
                            />
                          </TableCell>
                          <TableCell>{fields.value[index].status}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                {canEdit && (
                  <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                    <Button fullWidth={false} onClick={() => fields.push({})}>
                      Add new row
                    </Button>
                  </Box>
                )}
              </>
            )
          }}
        </FieldArray>
      )}
    </FormSpy>
  )
})
