import { IANAZone, Settings, Zone } from "luxon"

let value: Zone = IANAZone.create("Europe/London")

const timezone = {
  get: (): Zone => value,
  set: (newValue: string): void => {
    value = IANAZone.create(newValue)
    Settings.defaultZone = value
  }
}

export default timezone
