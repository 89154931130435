import React from "react"
import Form from "./Form"
import List from "./List"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

const ContractServiceTypes: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/" element={<List />} />
      <Route path="/new" element={<Form />} />
      <Route path="/:id" element={<Form />} />
    </SentryRoutes>
  )
}

export default React.memo(ContractServiceTypes)
