import React, { useState } from "react"
import { Form } from "react-final-form"
import { Button } from "components/Form"
import { ApiUserCarer, ApiUserCarerWorkPreferences } from "models"
import { getOptionsFromSchema } from "lib/schema"
import { UserAccount, StickyBox, CarePackagesFormGroup, DataLoading } from "components"
import { Typography } from "@mui/material"
import testingId from "constants/testingId"
import {
  buildCarerProfileDataForUpdateOrCreate,
  buildFinanceData,
  getCarerInitialValues,
  getPreferenceTitle,
  hasGivenNotice,
  isExEmployee,
  removeZeroWidthCharactersAndTrimObject
} from "./CarerProfile/utils"
import {
  useCarerExitInterviewPreferenceQuery,
  useCarerLeavingReasonQuery,
  useCarerProfileDataQuery
} from "data/core/carer/queries"
import { useCarerOnboardingStatusMutation } from "data/carer-profile/mutations"
import CarerTerminationConfirmationDialog from "./CarerTerminationConfirmationDialog"
import { deepObjectEqual } from "lib/utils"
import { validate } from "./helpers/validation"
import WorkPreferences from "./components/WorkPreferences"
import ProfileSectionPersonalDetails from "pages/Clients/components/ProfileSectionPersonalDetails"
import { CarerProfileCustomFields } from "./CarerProfile/CarerProfileCustomFields"
import { CarerProfileSectionCm } from "./CarerProfile/CarerProfileSectionCm"
import { CarerProfileSectionOtherPersonalDetails } from "./CarerProfile/CarerProfileSectionOtherPersonalDetails"
import { CarerProfileSectionFinancialDetails } from "./CarerProfile/CarerProfileSectionFinancialDetails"
import { CarerProfileSectionContactDetails } from "./CarerProfile/CarerProfileSectionContactDetails"
import { CarerProfileSectionAddress } from "./CarerProfile/CarerProfileSectionAddress"
import { useCarerOnboardingStatus } from "data/mobile/queries"
import { useCarerEditHandler } from "data/core/carer/handlers/useCarerEditHandler"
import { CarerProfileSectionStatus } from "./CarerProfile/CarerProfileSectionStatus"
import { useCarerCreateHandler } from "data/core/carer/handlers/useCarerCreateHandler"
import { useCoreSchemasStore, useUIStore } from "stores"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { BranchFeatures } from "lib/hooks/branchInfo/types"

interface CreateProps {
  mode: "create"
  carer?: never
}

type CarerWithWorkPreferences = ApiUserCarer & { work_preferences: ApiUserCarerWorkPreferences }

interface UpdateProps {
  mode: "update"
  carer: ApiUserCarer | CarerWithWorkPreferences
}

type OwnProps = CreateProps | UpdateProps

const CarerProfile: React.FC<OwnProps> = ({ mode, carer }) => {
  const showWarningMessage = useUIStore((state) => state.showWarningMessage)
  const isCreate = mode === "create"

  const { hasBranchPermission } = useBranchPermissions()
  const enableAgencyIndicator = hasBranchPermission(BranchFeatures.CARER_PROFILE_AGENCY_INDICATOR)
  const enableGivenNotice = hasBranchPermission(BranchFeatures.CARER_PROFILE_STATUS)
  const enableCarerExit = hasBranchPermission(BranchFeatures.CARER_PROFILE_CARER_EXIT)
  const { ApiUserCarerCreate } = useCoreSchemasStore((state) => state.schema.models)
  const statusOptions = getOptionsFromSchema(ApiUserCarerCreate, "status")

  const [showTerminationConfirmationDialog, setShowTerminationConfirmationDialog] = useState<boolean>(false)
  const [carerProfileFormModelToSave, setCarerProfileFormModelToSave] = useState<ApiUserCarer>()

  // carer profile data
  const { data: carerOnboardingStatus, isLoading: isLoadingCarerOnboardingStatus } = useCarerOnboardingStatus({
    carerGuid: carer?.guid
  })
  const { data: carerProfileData = {} } = useCarerProfileDataQuery({
    guid: carer?.guid,
    enabled: enableAgencyIndicator || enableCarerExit
  })

  const { data: leavingReasons, isLoading: isLoadingLeavingReasons } = useCarerLeavingReasonQuery()
  const { data: exitInterviewPreferences, isLoading: isLoadingExitInterviewPreferences } =
    useCarerExitInterviewPreferenceQuery()

  const { mutateAsync: resetOnboardingStatus } = useCarerOnboardingStatusMutation({ guid: carer?.guid ?? "" })

  const initialValues = carer ? { ...carer, carerProfileData } : getCarerInitialValues()

  const terminationConfirmationOnSave = () => {
    if (!carerProfileFormModelToSave) {
      throw new Error("Something is wrong. Carer profile data to be saved are undefined.")
    }
    setShowTerminationConfirmationDialog(false)
    submit(carerProfileFormModelToSave)
  }

  const createCarer = useCarerCreateHandler()
  const editCarer = useCarerEditHandler()

  const onSubmit = (values: ApiUserCarer) => {
    if (
      mode === "update" &&
      !isExEmployee(carer.status) &&
      !hasGivenNotice(carer.status, statusOptions) &&
      (isExEmployee(values.status) || hasGivenNotice(values.status, statusOptions))
    ) {
      setCarerProfileFormModelToSave(values)
      setShowTerminationConfirmationDialog(true)
    } else {
      submit(values)
    }
  }

  const submit = (values: ApiUserCarer) => {
    if (!values.cm2000?.enabled && !!values.cm2000?.pin) {
      delete values.cm2000.pin
    }

    // corePayload goes to core DB
    const corePayload = {
      ...values,
      profile: removeZeroWidthCharactersAndTrimObject(values.profile),
      finance: buildFinanceData(values.finance, enableAgencyIndicator, values.carerProfileData?.sourcing_type),
      hr: {
        ...values.hr,
        ...(enableCarerExit
          ? {
              exit_interview_preference_title: getPreferenceTitle(
                exitInterviewPreferences ?? [],
                values.carerProfileData?.exit_interview_preference_id
              ),
              resignation_date: values.carerProfileData?.resignation_date
            }
          : {})
      }
    }

    // carerProfileData goes to carerProfile DB
    const carerProfilePayload = buildCarerProfileDataForUpdateOrCreate(
      corePayload,
      enableAgencyIndicator,
      enableCarerExit,
      enableGivenNotice,
      statusOptions,
      leavingReasons ?? []
    )

    if (carerProfilePayload && corePayload.guid !== carerProfilePayload.id) {
      throw new Error("DcpCore and DcpCarerProfile update payload with different GUIDs!")
    }

    if (mode === "create") {
      createCarer({ corePayload, carerProfilePayload })
    } else {
      editCarer({ corePayload, carerProfilePayload })
    }

    if (!isCreate && corePayload.reset_onboarding_status) {
      resetOnboardingStatus()
    }
  }

  const scrollToError = (errors: any) => {
    Object.keys(errors).forEach((key: string) => {
      const el: HTMLInputElement | null = document.querySelector(`input[name='${key}']`)
      if (el && el.type !== "hidden") {
        el.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
        return false
      }
    })
  }

  if ((carer && isLoadingCarerOnboardingStatus) || isLoadingLeavingReasons || isLoadingExitInterviewPreferences) {
    return <DataLoading />
  }

  if (carer && !carerOnboardingStatus) {
    return "Missing data"
  } else if (
    (enableAgencyIndicator || enableCarerExit || enableGivenNotice) &&
    (!exitInterviewPreferences || !leavingReasons || carerProfileData?.id !== carer?.guid)
  ) {
    return "Missing data"
  }

  return (
    <>
      {mode === "update" && <UserAccount user={carer} />}
      {mode === "create" && <Typography style={{ margin: "8px 0 40px" }}>All required fields marked with *</Typography>}

      <Form onSubmit={onSubmit} validate={validate} initialValuesEqual={deepObjectEqual} initialValues={initialValues}>
        {({ handleSubmit, invalid, submitting, pristine, errors, form, values }) => {
          const isEx = isExEmployee(values.status) || hasGivenNotice(values.status, statusOptions)

          return (
            <form
              onSubmit={(event) => {
                const result = handleSubmit(event)
                if (result === undefined && invalid) {
                  showWarningMessage("Please fix the errors in the form.")
                  scrollToError(errors)
                } else {
                  form.getRegisteredFields().forEach((field) => form.resetFieldState(field as keyof ApiUserCarer))
                }
                return result
              }}
              data-cy={testingId.wizardForm}
            >
              <CarerProfileSectionStatus
                mode={mode}
                isEx={isEx}
                enableCarerExit={enableCarerExit}
                enableAgencyIndicator={enableAgencyIndicator}
                enableGivenNotice={enableGivenNotice}
                carerOnboardingStatus={carerOnboardingStatus}
                leavingReasons={leavingReasons}
                exitInterviewPreferences={exitInterviewPreferences}
                statusOptions={statusOptions}
              />

              <ProfileSectionPersonalDetails withBio />

              <CarerProfileSectionAddress />

              <CarerProfileSectionContactDetails />

              <CarerProfileSectionFinancialDetails />

              <CarerProfileSectionOtherPersonalDetails />

              {mode === "update" && (
                <WorkPreferences preferences={(carer as CarerWithWorkPreferences)?.work_preferences} />
              )}
              <CarePackagesFormGroup
                title="Care package preferences"
                fieldNames={[
                  "preferences.reablement_package",
                  "preferences.end_of_life_package",
                  "preferences.domicilliary_package",
                  "preferences.extra_care_package",
                  "preferences.short_term_live_in_package",
                  "preferences.permanent_live_in_package",
                  "preferences.nurse_led_package",
                  "preferences.learning_disability_package",
                  "preferences.mental_health_package",
                  "preferences.drug_and_alcohol_package"
                ]}
              />

              <CarerProfileSectionCm />

              <CarerProfileCustomFields />

              <StickyBox>
                <Button
                  variant="text"
                  fullWidth={false}
                  disabled={submitting || pristine}
                  onClick={() => {
                    form.reset()
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" fullWidth={false} disabled={submitting || pristine}>
                  {mode === "update" ? "Save changes" : "Add new carer"}
                </Button>
              </StickyBox>
            </form>
          )
        }}
      </Form>
      {mode === "update" && (
        <CarerTerminationConfirmationDialog
          onSave={terminationConfirmationOnSave}
          onCancel={() => setShowTerminationConfirmationDialog(false)}
          open={showTerminationConfirmationDialog}
          userName={`${carer.profile.first_name.trim()} ${carer.profile.last_name.trim()}`}
        />
      )}
    </>
  )
}

export default CarerProfile
