export enum PermissionGroup {
  OPERATOR = "4ca901bb-b1f2-406a-b51a-d4369b11a6ce",
  BRANCH_ADMIN = "2865db69-965c-4633-be9b-2447ae265136",
  FINANCE_ADMIN = "2b72889d-4693-44c8-ad40-463378a72987",
  HR = "c9f5ab88-82ec-4ad5-9089-b49bc3cd7a94",
  CHAT = "4f3359a9-5118-41fe-94d8-678e8ee3f8c2"
}

export enum Permission {
  DEVELOPER_EDIT = "Developer:Edit",
  FINANCE_EDIT = "Finance:Edit",
  FINANCE_ALERT_EDIT = "Finance.Alert:Edit",
  FINANCE_ALERT_READ = "Finance.Alert:Read",
  FINANCE_APPOINTMENT_EDIT = "Finance.Appointment:Edit",
  FINANCE_APPOINTMENT_READ = "Finance.Appointment:Read",
  FINANCE_FILE_EDIT = "Finance.File:Edit",
  FINANCE_FILE_READ = "Finance.File:Read",
  FINANCE_MODEL_EDIT = "Finance.Model:Edit",
  FINANCE_MODEL_READ = "Finance.Model:Read",
  FINANCE_MODEL_RATE_EDIT = "Finance.Model.Rate:Edit",
  FINANCE_MODEL_RATE_READ = "Finance.Model.Rate:Read",
  FINANCE_PROCESSING_EDIT = "Finance.Processing:Edit",
  FINANCE_PROCESSING_READ = "Finance.Processing:Read",
  FINANCE_PROCESSING_CREDIT_EDIT = "Finance.Processing.Credit:Edit",
  FINANCE_PROCESSING_CREDIT_READ = "Finance.Processing.Credit:Read",
  FINANCE_PROCESSING_CHARGES_EDIT = "Finance.Processing.Charges:Edit",
  FINANCE_PROCESSING_CHARGES_READ = "Finance.Processing.Charges:Read",
  FINANCE_PROCESSING_CHARGES_APPROVE_EDIT = "Finance.Processing.Charges.Approve:Edit",
  FINANCE_PROCESSING_CHARGES_APPROVE_READ = "Finance.Processing.Charges.Approve:Read",
  FINANCE_RECALCULATE_EDIT = "Finance.Recalculate:Edit",
  FINANCE_RECALCULATE_READ = "Finance.Recalculate:Read",
  FINANCE_REWIND_EDIT = "Finance.Rewind:Edit",
  FINANCE_REWIND_READ = "Finance.Rewind:Read",
  FINANCE_SEARCH_READ = "Finance.Search:Read",
  FINANCE_UNIT_READ = "Finance.Unit:Read",
  FINANCE_USER_CONTRACT_RATE_EDIT = "Finance.UserContract.Rate:Edit",
  FINANCE_USER_CONTRACT_RATE_READ = "Finance.UserContract.Rate:Read",
  HR_APPROVER_EDIT = "Hr.Approver:Edit",
  HR_APPROVER_READ = "Hr.Approver:Read",
  SHIFT_READ = "Shift:Read",
  SHIFT_SETTINGS_CATEGORY_READ = "Shift.Settings.Category:Read",
  USER_CARER_READ = "User.Carer:Read",
  USER_CLIENT_READ = "User.Client:Read"
}
