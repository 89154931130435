import React from "react"
import { Box, Grid } from "@mui/material"
import { Field, Form } from "react-final-form"
import { ContentContainer, Header, Link, StickyBox, EmptyData } from "components"
import { Button, FormSectionHeader, Select, TextField } from "components/Form"
import { DateTime } from "luxon"
import { useHolidayQuery } from "data/finance/holiday/queries"
import { ApiHoliday, ApiHolidayRequest } from "data/finance/holiday/types"
import { useHolidayCreateMutation, useHolidayUpdateMutation } from "data/finance/holiday/mutations"
import validators, { composeValidators } from "lib/validators"
import { DateField } from "components/Form/DateField"
import { useSchema } from "data/schema/useSchema"
import { SelectDummy } from "components/Form/SelectDummy"
import { useParams } from "react-router-dom"

const HolidayDetail: React.FC = () => {
  const { id } = useParams()
  const { NationAlias, HolidayTier } = useSchema(["NationAlias", "HolidayTier"])

  const { data: holiday } = useHolidayQuery({ guid: id })

  const { mutateAsync: createHoliday } = useHolidayCreateMutation()
  const { mutateAsync: updateHoliday } = useHolidayUpdateMutation()

  const isEditable = holiday ? !holiday.read_only && holiday.date.toMillis() > DateTime.local().toMillis() : true

  if (id && !holiday) return null

  if (!HolidayTier?.length) {
    return (
      <ContentContainer>
        <Header
          title={
            <>
              <Link to="/admin/holidays">Holidays</Link> / {holiday ? holiday.title : "Add new holiday"}
            </>
          }
        />
        <EmptyData message="Please make sure to add some holiday tiers first." />
      </ContentContainer>
    )
  }

  const tierOptions = [{ value: null, title: "None" }, ...HolidayTier]

  return (
    <ContentContainer>
      <Header
        title={
          <>
            <Link to="/admin/holidays">Holidays</Link> / {holiday ? holiday.title : "Add new holiday"}
          </>
        }
      />
      <Box m={3} mx={0}>
        <Form
          onSubmit={(values: ApiHolidayRequest | ApiHoliday) => {
            if (id) {
              updateHoliday({ values } as { values: ApiHoliday })
            } else {
              createHoliday({ values })
            }
          }}
          initialValues={holiday}
        >
          {({ handleSubmit, submitting, pristine, form }) => (
            <form onSubmit={handleSubmit}>
              <FormSectionHeader title="Holiday details" />
              <Grid container spacing={8}>
                <Grid item md={6}>
                  <Field
                    name="title"
                    label="Title *"
                    component={TextField}
                    validate={composeValidators([validators.required, validators.maxLength(64)])}
                    disabled={!isEditable}
                  />
                  <DateField name="date" label="Holiday date" required disabled={!isEditable} />

                  {NationAlias ? (
                    <Field
                      name="nation_alias"
                      label="Nation *"
                      component={Select}
                      options={NationAlias}
                      validate={validators.required}
                      disabled={!isEditable}
                    />
                  ) : (
                    <SelectDummy value="loading..." />
                  )}
                </Grid>
                <Grid item md={6}>
                  <Field
                    name="invoice_tier_guid"
                    label="Invoice Holiday Tier"
                    component={Select}
                    options={tierOptions}
                  />
                  <Field
                    name="payment_tier_guid"
                    label="Payment Holiday Tier"
                    component={Select}
                    options={tierOptions}
                  />
                </Grid>
              </Grid>
              <StickyBox>
                <Button
                  variant="text"
                  fullWidth={false}
                  disabled={submitting || pristine}
                  onClick={() => {
                    form.reset()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  sx={(theme) => ({
                    margin: theme.spacing(0, 0, 0, 3)
                  })}
                  type="submit"
                  fullWidth={false}
                  disabled={submitting || pristine}
                >
                  Save changes
                </Button>
              </StickyBox>
            </form>
          )}
        </Form>
      </Box>
    </ContentContainer>
  )
}

export default React.memo(HolidayDetail)
