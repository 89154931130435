import { ContentContainer, EmptyData, Header } from "components"
import { usePagination } from "components/handlers/usePagination"
import { useUnitContractListQuery } from "data/finance/unit/queries"
import { Unit } from "data/finance/unit/types"
import { queryStringToObject } from "lib/queryString"
import React, { memo, useState } from "react"
import { UnitContractFilter, UnitContractListFilter } from "./components/UnitContractListFilter"
import { UnitContractListTable } from "./components/UnitContractListTable"
import { useLocation, useParams } from "react-router-dom"

// TODO: unite contract lists
export const UnitContractList: React.FC<{ unit: Unit }> = memo(({ unit }) => {
  const { unitGuid } = useParams()
  const location = useLocation()
  const { data: contracts, refetch } = useUnitContractListQuery({
    unitGuid,
    search: location?.search,
    searchRequired: true
  })

  const [filter, setFilter] = useState<UnitContractFilter>(queryStringToObject(location?.search))
  const { pagination, setPagination, resetPagination } = usePagination()

  if (!unitGuid) return null

  return (
    <ContentContainer>
      <Header title={`Unit - ${unit.name} - Contracts`} />
      <UnitContractListFilter {...{ filter, setFilter, pagination, resetPagination, unitGuid }} />
      {contracts?.length ? (
        <UnitContractListTable {...{ contracts, unitGuid, refetch, setPagination }} />
      ) : (
        <EmptyData {...{ message: "No contracts found, start by adding one." }} />
      )}
    </ContentContainer>
  )
})

UnitContractList.displayName = "UnitContractList"
