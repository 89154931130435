import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material"
import { Button } from "components/Form"
import { useDownloadJobLinkQuery } from "data/download/queries"
import { ApiDownload, ApiDownloadStatus } from "data/download/types"
import { titleCase } from "lib/titleCase"
import { DateTimeFormat } from "lib/datetime"
import { downloadFileSimple } from "lib/utils"
import React, { memo, useEffect, useState } from "react"
import { grey } from "@mui/material/colors"
import { HourglassEmpty } from "@mui/icons-material"
import { default as DisabledButton } from "@mui/material/Button"
import testingId from "constants/testingId"
import { DateTime } from "luxon"

const headers = ["File name", "Created at", "Expired at", "Status", "Download"]

interface OwnProps {
  downloadList: ApiDownload[]
  searchJobGuid?: string
}

export const MyDownloadListTable: React.FC<OwnProps> = memo(({ downloadList, searchJobGuid }) => {
  const [clickedJobGuid, setClickedJobGuid] = useState<string | undefined>(undefined)

  const { data: linkData } = useDownloadJobLinkQuery({ jobGuid: clickedJobGuid })

  const onClick = (jobGuid: string) => (e: React.MouseEvent) => {
    e.preventDefault()
    setClickedJobGuid(jobGuid)
  }

  useEffect(() => {
    if (clickedJobGuid && linkData?.link) {
      downloadFileSimple({ href: linkData.link, fileName: linkData.file_name })
      setClickedJobGuid(undefined)
    }
  }, [clickedJobGuid, linkData])

  const isExpired = (expiresAt: DateTime): boolean => expiresAt.toMillis() < DateTime.local().toMillis()

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((title) => (
              <TableCell data-cy={testingId.tableHeaderCell} key={title}>
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {downloadList.map((item, index) => (
            <TableRow
              data-cy={testingId.tableRow}
              key={index}
              sx={
                searchJobGuid && searchJobGuid === item.job_guid
                  ? {
                      backgroundColor: grey[200]
                    }
                  : undefined
              }
            >
              <TableCell data-cy={testingId.tableCell}>{item.file_name}</TableCell>
              <TableCell data-cy={testingId.tableCell}>{item.created_at.toFormat(DateTimeFormat.DATE_TIME)}</TableCell>
              <TableCell data-cy={testingId.tableCell}>{item.expires_at.toFormat(DateTimeFormat.DATE_TIME)}</TableCell>
              <TableCell data-cy={testingId.tableCell}>{titleCase(item.status)}</TableCell>
              <TableCell data-cy={testingId.tableCell}>
                {item.status === ApiDownloadStatus.PENDING ? (
                  <HourglassEmpty />
                ) : item.status === ApiDownloadStatus.FAILED || isExpired(item.expires_at) ? (
                  <Tooltip
                    {...{
                      title: isExpired(item.expires_at)
                        ? "File has expired."
                        : `${item.error_code} - ${item.error_message}`
                    }}
                  >
                    <span>
                      <DisabledButton
                        {...{
                          disabled: true,
                          size: "large",
                          variant: "contained",
                          fullWidth: true
                        }}
                      >
                        Download
                      </DisabledButton>
                    </span>
                  </Tooltip>
                ) : item.status === ApiDownloadStatus.COMPLETED && item.job_guid ? (
                  <Button
                    {...{
                      onClick: onClick(item.job_guid)
                    }}
                  >
                    Download
                  </Button>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
})

MyDownloadListTable.displayName = "MyDownloadListTable"
