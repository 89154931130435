import React from "react"
import Typography from "@mui/material/Typography"

const CustomLegend = () => {
  return (
    <>
      <Typography variant="h6">
        <strong>Trends</strong>
      </Typography>
      <Typography
        variant="body1"
        sx={(theme) => ({
          marginBottom: theme.spacing(4)
        })}
      >
        Fall risk score for the last 30 days
      </Typography>
    </>
  )
}

export default CustomLegend
