import React from "react"
import { Box, Typography } from "@mui/material"

interface Props {
  title: string | React.ReactNode
  action?: React.ReactNode
  "data-cy"?: string
}

const FormSectionHeader = ({ title, action, "data-cy": testingId }: Props) => (
  <Box
    sx={{
      mt: 4,
      mb: 1,
      paddingBottom: 1,
      borderBottom: "1px solid",
      borderColor: "divider",
      display: "flex",
      justifyContent: "space-between"
    }}
    data-cy={testingId}
  >
    <Typography variant="h6" sx={{ display: "flex", alignItems: "flex-end" }}>
      {title}
    </Typography>
    {action ? action : null}
  </Box>
)

export default FormSectionHeader
