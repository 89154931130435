import { Box } from "@mui/material"
import { WithPermission } from "components/Auth"
import { Button } from "components/Form"
import React, { memo } from "react"

interface OwnProps {
  handleCreditClick: () => void
  selectedIds: string[]
  selectableIdsForCrediting: string[]
}

export const TransactionListActionButtons: React.FC<OwnProps> = memo(
  ({ handleCreditClick, selectableIdsForCrediting, selectedIds }) => {
    const isCreditingEnabled = selectedIds.some((id) => selectableIdsForCrediting.includes(id))

    return (
      <Box
        p={2}
        px={0}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        sx={(theme) => ({
          borderColor: theme.palette.text.primary,
          borderTop: "1px solid"
        })}
      >
        <WithPermission permissions={["Finance.Processing.Credit:Edit"]}>
          <Button fullWidth={false} disabled={!isCreditingEnabled} onClick={handleCreditClick}>
            Credit selected
          </Button>
        </WithPermission>
      </Box>
    )
  }
)

TransactionListActionButtons.displayName = "TransactionListActionButtons"
