import React, { useState } from "react"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { Form, Field } from "react-final-form"
import {
  ApiUserContractPurchaseOrderRequest,
  useUserContractPurchaseOrderMutation
} from "data/finance/client/mutations"
import { UploadInput, Button, TextField } from "components/Form"
import { ModalWrapper } from "components"
import validators, { composeValidators } from "lib/validators"
import { ApiClientContract } from "data/finance/client/types"

interface OwnProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  contract: ApiClientContract
}

export const ClientPurchaseOrderModal: React.FC<OwnProps> = ({ setShowModal, contract }) => {
  const destroyModal = () => {
    setShowModal(false)
  }
  const [file, setFile] = useState<File | null>(null)

  const { mutate: saveUserContractPurchaseOrder } = useUserContractPurchaseOrderMutation({ destroyModal })

  const onSubmit = async (payload: ApiUserContractPurchaseOrderRequest): Promise<void> => {
    saveUserContractPurchaseOrder({
      clientGuid: contract.owner_guid,
      clientContractGuid: contract.guid,
      payload
    })
  }

  return (
    <ModalWrapper width={700} destroyModal={destroyModal} title="Upload a Purchase Order">
      <Form
        validate={(values: ApiUserContractPurchaseOrderRequest) =>
          values.file || file ? undefined : { File: "Required" }
        }
        onSubmit={(values) => {
          if (file) {
            onSubmit({ ...values, file })
          }
        }}
      >
        {({ handleSubmit, invalid, submitting }) => {
          return (
            <form encType="multipart/form-data" onSubmit={handleSubmit}>
              <Grid container spacing={3} alignItems="flex-start">
                <Grid item md={4}>
                  <Field
                    name="reference"
                    label="Reference *"
                    component={TextField}
                    validate={composeValidators([validators.required, validators.maxLength(12)])}
                  />
                </Grid>
                <Grid item md={8}>
                  <Field name="file" render={(props) => <UploadInput {...props} setFile={setFile} />} />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                <Button type="submit" fullWidth={false} disabled={invalid || submitting} style={{ marginRight: 10 }}>
                  Upload
                </Button>
                <Button
                  variant="text"
                  fullWidth={false}
                  type="button"
                  onClick={() => {
                    destroyModal()
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          )
        }}
      </Form>
    </ModalWrapper>
  )
}
