import React, { ReactNode } from "react"
import Button from "@mui/material/Button"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Dialog from "@mui/material/Dialog"

export interface Props {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  children: ReactNode
  title: string
}

const ConfirmationDialog: React.FC<Props> = (props) => {
  const { children, open, onConfirm, onCancel, title } = props

  return (
    <Dialog maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={open}>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
