import React from "react"
import { NotFound } from "./index"
import ShiftCategoryList from "./Shifts/ShiftCategories/ShiftCategoryList"
import ShiftCategoryMain from "./Shifts/ShiftCategories/ShiftCategoryMain"
import Auth from "lib/Auth"
import PermissionDenied from "app/PermissionDenied"
import ShiftCategoryCreate from "./Shifts/ShiftCategories/ShiftCategoryCreate"
import { Route } from "react-router-dom"
import AppLoading from "app/AppLoading"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { BranchFeatures } from "lib/hooks/branchInfo/types"
import { Permission } from "constants/permission"
import SentryRoutes from "components/Routes"

const ShiftCategories: React.FC = () => {
  const { hasBranchPermission, isFetching } = useBranchPermissions()
  const hasShiftsFlag = hasBranchPermission(BranchFeatures.SHIFTS)

  if (isFetching) {
    return <AppLoading />
  }

  if (!Auth.hasPermission([Permission.SHIFT_SETTINGS_CATEGORY_READ]) || !hasShiftsFlag) {
    return <PermissionDenied />
  }

  return (
    <SentryRoutes>
      <Route path="/" element={<ShiftCategoryList />} />
      <Route path="/new" element={<ShiftCategoryCreate />} />
      <Route path="/:id/*" element={<ShiftCategoryMain />} />
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  )
}
export default ShiftCategories
