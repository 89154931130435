import React, { Dispatch, memo, SetStateAction, useMemo } from "react"
import { Field, Form } from "react-final-form"
import { FormSpyCustom, Select, TextField } from "components/Form"
import Grid from "@mui/material/Grid"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { ClearFilterButton } from "components/ClearFilterButton"
import { useSchema } from "data/schema/useSchema"
import { ContractModelType } from "constants/modelTypes"
import { StatusSelectField } from "components/Form/StatusSelectField"
import { Box } from "@mui/material"
import { useDebouncedCallback } from "use-debounce"
import { ContractModelFilterValues } from "../types"

interface Props {
  filter: ContractModelFilterValues
  setFilter: Dispatch<SetStateAction<ContractModelFilterValues>>
  modelType: ContractModelType
}

export const ContractModelFilter: React.FC<Props> = memo(({ filter, setFilter, modelType }) => {
  const onSubmit = useDebouncedCallback((values: ContractModelFilterValues) => {
    setFilter(values)
  }, 200)
  const onClearFilter = () => setFilter({})

  useFilterLocationChange({ filter })

  const isInvoice = modelType === ContractModelType.INVOICE

  const { InvoiceContractType = [], PaymentContractType } = useSchema(["InvoiceContractType", "PaymentContractType"])

  const typeAliasOptions = useMemo(() => {
    if (isInvoice) {
      return [...InvoiceContractType.filter((item) => item.value !== "OTHER"), { title: "Other", value: "OTHER" }]
    }
    return PaymentContractType
  }, [isInvoice, InvoiceContractType, PaymentContractType])

  return (
    <Form onSubmit={onSubmit} initialValues={filter}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpyCustom {...{ handleSubmit }} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid mr={1} container alignItems="center" spacing={1}>
              <Grid item md={5}>
                <Field
                  name="search"
                  component={TextField}
                  placeholder={isInvoice ? "Template name or Salesforce funder" : "Pay group"}
                  label="Search"
                />
              </Grid>
              <Grid item md={3}>
                <Field
                  name="type_alias"
                  label={isInvoice ? "Contract Type" : "Pay Type"}
                  component={Select}
                  multiselect
                  options={typeAliasOptions}
                />
              </Grid>
              <Grid item md={2}>
                <StatusSelectField multiselect />
              </Grid>
              {isInvoice ? (
                <Grid item md={2}>
                  <Field name="salesforce_id" label="Salesforce ID" component={TextField} />
                </Grid>
              ) : null}
            </Grid>
            <ClearFilterButton
              sx={{ whiteSpace: "nowrap" }}
              onClick={onClearFilter}
              disabled={Object.keys(filter).length === 0}
            />
          </Box>
        </form>
      )}
    </Form>
  )
})

ContractModelFilter.displayName = "ContractModelFilter"
