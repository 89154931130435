import { ApiPaymentAdditionalSearch, ApprovalStatus } from "data/finance/additional/type"

const noActionMessage = ", and no further action can be taken at this time."

interface OwnProps {
  pickedStatus?: ApprovalStatus
  additional: ApiPaymentAdditionalSearch
}

export const getCheckboxDisabledText = ({ pickedStatus, additional }: OwnProps): string | undefined => {
  if (pickedStatus && additional.status !== pickedStatus) {
    return `Can not pick Additional with a different status${noActionMessage}`
  }

  if (additional.extra_type !== "Charge" && additional.extra_type !== "ShiftExtra") {
    return `Additional has not been added manually${noActionMessage}`
  }

  if (additional.transaction_reference) {
    return `Additional has been paid${noActionMessage}`
  }

  if (!additional.is_approvable) {
    return `Additional is not approvable${noActionMessage}`
  }
}
