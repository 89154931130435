import React, { useCallback, useMemo } from "react"
import testingId from "constants/testingId"
import { BackButton, ContentContainer, Divider, Header } from "components"
import { Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import TransactionDetailItemSection from "pages/Finance/Processing/TransactionDetailItemSection"
import dateTimeUtils from "lib/datetime"
import TransactionDetailItemSectionRow from "pages/Finance/Processing/TransactionDetailItemSectionRow"
import { DateTime } from "luxon"
import { ContractModelType } from "constants/modelTypes"
import shiftPaymentTransactionParser, {
  ParsedShiftTransactionCalculation
} from "lib/helpers/shiftPaymentTransactionParser"
import { isNil } from "ramda"
import TransactionShiftVisitDetailHeader from "../PaymentProcessing/components/TransactionShiftVisitDetailHeader"
import { usePaymentProcessingShiftCalculationQuery } from "data/finance/paymentProcessing/queries"
import { usePeriodQuery } from "data/finance/contractModel/queries"
import { useInvoiceProcessingShiftCalculationQuery } from "data/finance/invoiceProcessing/queries"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { PATH } from "constants/path"

const chargeRulesHeaders = ["Variations", "Pay rule", "Cap rule", "Figure"]
const paymentHeaders = ["Day rate", "Rate period", "Rate", "Duration", "Cost"]
const additionalChargesHeaders = ["Description", "Rate", "Quantity", "Value"]
const visitHeaders = [
  "Date",
  "Visit status",
  "Calculation time",
  "Service",
  "Rost time",
  "Rost dur",
  "Actual time",
  "Actual dur"
]
const contractModelHeaders = [
  "Contract model",
  "Service type",
  "Charge type",
  "Band",
  "Rounding rule",
  "Round min",
  "Pay time",
  "Pay dur"
]

type Props = { modelType: ContractModelType }

const TransactionShiftTransactionDetail: React.FC<Props> = ({ modelType }) => {
  const { periodGuid, transactionGuid, transactionShiftGuid, calendarGuid, contractGuid, visitGuid } = useParams()
  const isVisitDetail = !isNil(visitGuid)
  const isInvoiceModel = modelType === ContractModelType.INVOICE
  const navigate = useNavigate()
  const location = useLocation()

  const { data: period } = usePeriodQuery({
    modelType,
    contractGuid,
    calendarGuid,
    periodGuid
  })
  const { data: paymentShiftCalculations } = usePaymentProcessingShiftCalculationQuery({
    periodGuid,
    transactionGuid,
    transactionShiftGuid,
    visitGuid,
    enabled: !isInvoiceModel
  })
  const { data: invoiceShiftCalculations } = useInvoiceProcessingShiftCalculationQuery({
    periodGuid,
    transactionGuid,
    transactionShiftGuid,
    visitGuid,
    enabled: isInvoiceModel
  })

  const shiftCalculations = isInvoiceModel ? invoiceShiftCalculations : paymentShiftCalculations

  const parsedShiftCalculation: ParsedShiftTransactionCalculation | null = useMemo(() => {
    if (isNil(shiftCalculations)) {
      return null
    }
    return shiftPaymentTransactionParser(shiftCalculations)
  }, [shiftCalculations])

  const visitClickHandler = useCallback(
    (idx: number) => {
      const guid = shiftCalculations?.visits?.[idx]?.visit_guid
      if (guid) {
        navigate(`${location.pathname}/${guid}`)
      }
    },
    [shiftCalculations, location, navigate]
  )

  if (!parsedShiftCalculation) {
    return null
  }

  return (
    <ContentContainer data-cy={testingId.paymentProcessingShiftTransactionDetail.screen}>
      <BackButton fallbackUrl={`${PATH.FINANCE.PAYMENT_PROCESSING}/${calendarGuid}/${periodGuid}/${contractGuid}`} />
      <Header
        data-cy={testingId.tableTitle}
        title={`${parsedShiftCalculation.contract_name} (${dateTimeUtils.formatDate(
          period ? period.start : DateTime.local()
        )} - ${dateTimeUtils.formatDate(period ? period.end : DateTime.local())}): ${
          parsedShiftCalculation.reference_owner_name
        } / ${isVisitDetail ? `Visit` : `Shift`}`}
      />
      {isVisitDetail && <TransactionShiftVisitDetailHeader visit={parsedShiftCalculation.visitDetailHeader} />}
      <Grid
        container
        spacing={10}
        sx={
          isVisitDetail
            ? undefined
            : (theme) => ({
                marginTop: theme.spacing(4)
              })
        }
      >
        <Grid item md={9}>
          <Typography variant="h6" data-cy={testingId.paymentProcessingShiftTransactionDetail.totalChargeableHours}>
            Total shift hours: {parsedShiftCalculation.duration_charge}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography variant="h6" data-cy={testingId.paymentProcessingShiftTransactionDetail.totalValue}>
            Total shift value: {parsedShiftCalculation.totalCharges}
          </Typography>
        </Grid>
      </Grid>
      <Divider color="divider" />

      {isVisitDetail ? (
        <>
          <TransactionDetailItemSectionRow
            title={`Visit overview`}
            headers={visitHeaders}
            data={parsedShiftCalculation.visitsOverview}
            section="extras-breakdown"
            data-cy={testingId.paymentProcessingShiftTransactionDetail.sectionVisitOverview}
          />
          <TransactionDetailItemSectionRow
            title={`Additional ${isInvoiceModel ? "invoice" : "payment"} (excluded from Total Value)`}
            headers={additionalChargesHeaders}
            data={parsedShiftCalculation.extraCharges}
            section="extras-breakdown"
            data-cy={testingId.paymentProcessingShiftTransactionDetail.sectionAdditionalCharges}
          />
        </>
      ) : (
        <>
          <TransactionDetailItemSection
            title={isInvoiceModel ? "Invoice rules" : "Payment rules"}
            headers={chargeRulesHeaders}
            data={Object.values(parsedShiftCalculation.chargeRules)}
            section="charge-rules"
            data-cy={testingId.paymentProcessingShiftTransactionDetail.sectionChargeRules}
          />
          <TransactionDetailItemSection
            title={isInvoiceModel ? "Invoice model" : "Payment model"}
            headers={contractModelHeaders}
            data={Object.values(parsedShiftCalculation.paymentModels)}
            section={isInvoiceModel ? "invoice-models" : "payment-models"}
            data-cy={testingId.paymentProcessingShiftTransactionDetail.sectionPaymentModel}
          />
          <TransactionDetailItemSectionRow
            title={isInvoiceModel ? "Invoice breakdown" : "Payment breakdown"}
            headers={paymentHeaders}
            data={parsedShiftCalculation.chargeBreakdowns}
            section={isInvoiceModel ? "invoice-breakdown" : "payment-breakdown"}
            data-cy={testingId.paymentProcessingShiftTransactionDetail.sectionChargeBreakdown}
          />
          <TransactionDetailItemSectionRow
            title={`Visit overview`}
            headers={visitHeaders}
            data={parsedShiftCalculation.visitsOverview}
            section="extras-breakdown"
            data-cy={testingId.paymentProcessingShiftTransactionDetail.sectionVisitsOverview}
            onRowClickAction={visitClickHandler}
          />
        </>
      )}
    </ContentContainer>
  )
}
export default TransactionShiftTransactionDetail
