import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import { ApiSubmissionUploadIssue } from "data/payroll/types"
import { prop, sortBy } from "ramda"
import React, { memo, useMemo } from "react"

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}))

const StyledAccordionDetail = styled(AccordionDetails)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[300]}`
}))

const SummaryBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  width: "100%"
})

const SummaryBoxName = styled(Typography)({
  width: "50%",
  fontWeight: "bold"
})

const SummaryBoxCount = styled(Typography)({
  width: "50%"
})

const TableBodyCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1)
}))

interface OwnProps {
  issues: ApiSubmissionUploadIssue[]
}

export const FileManagementTablePayrollErrorModalAccordion: React.FC<OwnProps> = memo(({ issues }) => {
  FileManagementTablePayrollErrorModalAccordion.displayName = "FileManagementTablePayrollErrorModalAccordion"

  const sortedIssues = useMemo(() => sortBy(prop("index"))(issues), [issues])

  return (
    <StyledAccordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <SummaryBox>
          <SummaryBoxName>{issues[0].issue}</SummaryBoxName>
          <SummaryBoxCount>{issues.length} rows</SummaryBoxCount>
        </SummaryBox>
      </AccordionSummary>
      <StyledAccordionDetail>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="20%">Row number</TableCell>
              <TableCell width="80%">Failed submission data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedIssues.map((issue, index) => {
              return (
                <TableRow key={index}>
                  <TableBodyCell>{issue.index}</TableBodyCell>
                  <TableBodyCell>{issue.data}</TableBodyCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </StyledAccordionDetail>
    </StyledAccordion>
  )
})
