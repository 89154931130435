import Box from "@mui/material/Box"
import { red } from "@mui/material/colors"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import DeleteIcon from "@mui/icons-material/Delete"
import { EmptyData, Link, TabPanel } from "components"
import { Button } from "components/Form"
import { SelectOption } from "components/Form/Select"
import testingId from "constants/testingId"
import { ContractRuleConditionTypeAlias } from "data/finance/contractRuleCondition/types"
import { ContractModelRuleGroupRuleFormData } from "pages/Finance/ContractModels/helpers/RuleGroupRule"
import React, { memo } from "react"
import { FormSpy } from "react-final-form"
import Rule from "./Rule"

interface TabPanelProps {
  value: number
  index: number
  rules: ContractModelRuleGroupRuleFormData[]
  conditions: SelectOption[]
  ruleType: ContractRuleConditionTypeAlias
  addRule: () => void
  errors?: string[]
}

const RuleGroupFormTabPanel: React.FC<TabPanelProps> = ({
  value,
  index,
  rules,
  ruleType,
  addRule,
  conditions,
  errors
}) => (
  <TabPanel {...{ value, index }}>
    <Box my={2}>
      {conditions.length === 0 ? (
        <EmptyData
          message={
            <>
              No available rule conditions found, please{" "}
              <Link to="/admin/finance/contract-rule-conditions">add some first</Link> and come back to this page.
            </>
          }
        />
      ) : (
        <>
          {errors && errors.length > 0 && (
            <Box m={2} mx={0} p={2} bgcolor={red[50]} data-cy={testingId.invoiceModel.rules.errorBox}>
              <Typography>
                <strong>Please fix the errors below and try submitting again:</strong>
              </Typography>
              <ul>
                {errors.map((item: string, key: number) => (
                  <li key={key}>
                    <Typography>{item}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
          )}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    width: "50px"
                  }}
                >
                  <DeleteIcon />
                </TableCell>
                <TableCell
                  sx={{
                    width: "100px"
                  }}
                />
                <TableCell>Rule details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rules.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <EmptyData message="No rules found, start by adding one" />
                  </TableCell>
                </TableRow>
              )}
              {rules.map((item, key) =>
                item.rule_type_alias === ruleType ? (
                  <FormSpy key={key} subscription={{ values: true }}>
                    {({ values }) => <Rule rule={values.rules[key] || item} conditions={conditions} ruleKey={key} />}
                  </FormSpy>
                ) : null
              )}
              <TableRow>
                <TableCell colSpan={3} align="right" style={{ borderBottom: "none" }}>
                  <Button color="primary" fullWidth={false} onClick={addRule}>
                    Add rule
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  </TabPanel>
)

export default memo(RuleGroupFormTabPanel)
