import React, { useEffect, useMemo } from "react"
import { NavItem } from "components"
import { Typography } from "@mui/material"
import ChatProvider, { useChat } from "pages/Chat/ChatProvider"
import { useGetChannels, useGetUnreadCount } from "pages/Chat/queries"
import { useChatStore } from "pages/Chat/useChatStore"
import { useShallow } from "zustand/react/shallow"
import { StandardMessage } from "pages/Chat/types"
import { Subscription } from "pubnub"
import { useQueryClient } from "react-query"
import { useLocation } from "react-router-dom"

const Counter = () => {
  const location = useLocation()
  const chat = useChat()
  const queryCache = useQueryClient()
  const { channelNames, currentChannel, setCurrentChannel } = useChatStore(
    useShallow(({ channelNames, currentChannel, setCurrentChannel }) => ({
      channelNames,
      currentChannel,
      setCurrentChannel
    }))
  )

  const { data: channels = [] } = useGetChannels(channelNames.chats, [])
  const { data: unreadCount } = useGetUnreadCount(channels, currentChannel?.id)
  const count = useMemo(
    () => Object.keys(unreadCount?.channels ?? []).reduce((acc, value) => acc + (unreadCount?.channels[value] ?? 0), 0),
    [unreadCount]
  )

  useEffect(() => {
    const onMessage = {
      message: ({ message }: Subscription.Message) => {
        if ((message as StandardMessage).sender.isUser) {
          queryCache.invalidateQueries("channels-unread-count")
        }
      }
    }

    chat.subscribe({
      channels: [channelNames.chats]
    })
    chat.addListener(onMessage)

    return () => {
      chat.unsubscribeAll()
      chat.removeListener(onMessage)
    }
  }, [chat, channelNames, queryCache, currentChannel])

  useEffect(() => {
    if (!location.pathname.includes("/messages")) {
      setCurrentChannel(undefined)
    }
  }, [location, setCurrentChannel])

  return (
    <Typography
      component="span"
      sx={{
        bgcolor: "error.main",
        borderRadius: "100px",
        color: "error.contrast",
        display: "inline-block",
        fontSize: 12,
        fontWeight: 500,
        height: 20,
        lineHeight: "20px",
        padding: "0px 6.5px",
        textAlign: "center",
        mr: 2
      }}
    >
      {count >= 100 ? "99+" : count}
    </Typography>
  )
}

export const MainNavMessages = () => (
  <NavItem to="/messages" label="Messages" id="mainnav-messages">
    <ChatProvider isMainNav>
      <Counter />
    </ChatProvider>
  </NavItem>
)
