import { Box, Divider } from "@mui/material"
import React, { memo, useCallback, useMemo, useState } from "react"
import { Form } from "react-final-form"
import { EmptyData, StickyBox, ContentContainer } from "components"
import { Button } from "components/Form"
import testingId from "constants/testingId"
import { ApiUserClient } from "models"
import { ModelTable } from "pages/Clients/components/Table"
import DatesMessageHelp from "components/DatesMessageHelp"
import { filterClientContracts } from "./helpers/filterClientContracts"
import { queryStringToObject } from "lib/queryString"
import { ClientHeader } from "./components/ClientHeader"
import { StatusEnum } from "data/commonTypes"
import { useMapContractsAsTableRows } from "helpers/contractHelpers"
import { useClientContractListQuery } from "data/finance/client/queries"
import { ClientContractListFilter } from "./components/ClientContractListFilter"
import { addOrRemoveItemInList } from "helpers/helpers"
import { useClientContractListDeleteHandler } from "data/finance/client/handlers"
import { useLocation } from "react-router-dom"

export interface ClientContractsFilter {
  status?: StatusEnum[]
}

interface Props {
  client: ApiUserClient
}

const headClientContractsData = ["Contract ID", "Model", "Type", "Starts on", "Ends before", "Status"]

const ClientContractList: React.FC<Props> = ({ client }) => {
  const location = useLocation()

  const { data: clientContracts } = useClientContractListQuery(client.guid)

  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const enableDelete = selectedRows.length > 0

  const onSelectRow = (rowId: string) => {
    setSelectedRows((current) => addOrRemoveItemInList(current, rowId))
  }

  const deleteContracts = useClientContractListDeleteHandler({
    clientGuid: client.guid,
    contractGuids: selectedRows,
    onOk: () => {
      setSelectedRows([])
    }
  })

  const onDelete = useCallback(() => {
    if (enableDelete) {
      deleteContracts()
    }
  }, [enableDelete, deleteContracts])

  const filter = queryStringToObject(location.search)

  const filteredClientContracts = useMemo(() => {
    return filterClientContracts(clientContracts || [], filter)
  }, [clientContracts, filter])
  const rows = useMapContractsAsTableRows(filteredClientContracts)

  return (
    <ContentContainer>
      <ClientHeader client={client} />
      <Box m={3} mx={0} data-cy={testingId.client.contract.list.screen}>
        <ClientContractListFilter />
        <Divider
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            height: "2px",
            backgroundColor: "divider"
          }}
        />
        <DatesMessageHelp messageType="E" />
        <Form {...{ onSubmit: onDelete }}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {rows?.length > 0 ? (
                <>
                  {
                    <ModelTable>
                      <ModelTable.Head addDeleteIcon data={headClientContractsData} />
                      <ModelTable.Body
                        rows={rows}
                        onRowChecked={onSelectRow}
                        sx={{
                          cursor: "pointer"
                        }}
                      />
                    </ModelTable>
                  }
                </>
              ) : (
                <EmptyData message="No contracts found, start by adding one." />
              )}
              <StickyBox>
                <Button type="submit" fullWidth={false} disabled={!enableDelete}>
                  Delete
                </Button>
              </StickyBox>
            </form>
          )}
        </Form>
      </Box>
    </ContentContainer>
  )
}

export default memo(ClientContractList)
