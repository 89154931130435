import { SelectableIdsDictionaryModel } from "lib/hooks"
import React, { Dispatch, ReactNode, SetStateAction } from "react"
import dateTimeUtils from "lib/datetime"
import { IconButton } from "@mui/material"
import testingId from "constants/testingId"
import { Link } from "components"
import MoneyWrapper from "components/MoneyWrapper"
import { isNil } from "ramda"
import { TransactionFailed, TransactionInProgress, TransactionQueued } from "pages/Finance/components"
import InvoiceExtrasModal from "../components/InvoiceExtrasModal"
import EditIcon from "@mui/icons-material/Edit"
import RowSelectionCheckbox from "components/RowSelectionCheckbox"
import { AppointmentType } from "constants/appointmentType"
import { RelationType } from "constants/relationType"
import { BasicPeriod } from "lib/types"
import {
  InvoiceTransaction,
  InvoiceTransactionCharge,
  InvoiceTransactionChargeType
} from "data/finance/invoiceProcessing/types"
import { CalendarPeriod } from "data/finance/contractModel/types"

type InvoiceTransactionChargeRow = {
  row: React.ReactNode[]
  isClickable: boolean
}

interface BasicMapperProps {
  transaction: InvoiceTransaction
  chargeTypes: Record<string, string>
  selectableIds: SelectableIdsDictionaryModel
  selectableDeleteIds: SelectableIdsDictionaryModel
  canCreditExtras: boolean
  canEditExtras: boolean
  toggleId: (id: string) => void
  toggleDeleteId: (id: string) => void
  setModal: Dispatch<SetStateAction<ReactNode>>
  period: CalendarPeriod
  destroyModal: () => void
  contract?: BasicPeriod
  refetchTransaction: () => void
}

interface TransactionChargeRowMapper extends BasicMapperProps {
  charge: InvoiceTransactionCharge
}

interface TransactionChargesMapper extends BasicMapperProps {
  charges: InvoiceTransactionCharge[]
  canEditContributions: boolean
}

interface TransactionChargeActionMapper {
  charge: InvoiceTransactionCharge
  selectableIds: SelectableIdsDictionaryModel
  canCreditExtras: boolean
  toggleId: (id: string) => void
}

const getCreditActions = ({ charge, selectableIds, canCreditExtras, toggleId }: TransactionChargeActionMapper) => {
  if (charge.credit_guid) return "Credited"
  if (charge.is_failed) return <TransactionFailed />
  if (charge.is_queued) return <TransactionQueued />
  if (charge.is_processing) return <TransactionInProgress />

  if (!isNil(selectableIds[charge.guid]) && canCreditExtras && charge.is_creditable) {
    return <RowSelectionCheckbox {...{ selectableIds, name: `credit[${charge.guid}]`, toggleId, id: charge.guid }} />
  }

  return null
}

const mapInvoiceTransactionChargeRow = ({
  charge,
  transaction,
  chargeTypes,
  selectableIds,
  canCreditExtras,
  canEditExtras,
  toggleId,
  selectableDeleteIds,
  toggleDeleteId,
  setModal,
  period,
  destroyModal,
  contract,
  refetchTransaction
}: TransactionChargeRowMapper): React.ReactNode[] => {
  // NOTE(maoc): Only charges with user guid are bound to visits -> display start date
  const visitStart = charge.reference_owner_guid ? dateTimeUtils.formatTime(charge.date) : "-"
  const extraType = chargeTypes[charge.charge_type_alias]
  const taxable = charge.taxable ? "Y" : "-"
  const total = <MoneyWrapper key={charge.guid} amount={charge.total} />

  const editIcon = (
    <IconButton
      onClick={(e) => {
        e.stopPropagation()
        setModal(
          <InvoiceExtrasModal {...{ transaction, period, destroyModal, charge, contract, refetchTransaction }} />
        )
      }}
      data-cy={testingId.invoiceProcessingTransactionDetail.listItemCheckbox}
    >
      <EditIcon color="primary" />
    </IconButton>
  )

  const deleteCheckbox = (
    <RowSelectionCheckbox
      {...{
        selectableIds: selectableDeleteIds,
        name: `itemDelete[${charge.guid}]`,
        toggleId: toggleDeleteId,
        id: charge.guid
      }}
    />
  )

  const chargeDate = dateTimeUtils.formatDate(charge.date)
  const relationUrl = `/${charge.relation_type === RelationType.VISIT ? "visits" : "shifts"}/detail/${
    charge.relation_guid
  }`
  const chargeDateItem =
    charge.relation_guid &&
    charge.relation_type &&
    (charge.relation_type === RelationType.VISIT || charge.relation_type === RelationType.SHIFT) ? (
      <Link to={relationUrl}>{chargeDate}</Link>
    ) : (
      chargeDate
    )

  const referenceOwnerItem =
    charge.reference_owner_type !== "Unassigned" ? (
      <Link to={`/carers/${charge.reference_owner_guid}/profile/personal-details`}>{charge.reference_owner_name}</Link>
    ) : (
      charge.reference_owner_name
    )

  switch (transaction.status_type_alias) {
    case "READY":
    case "PENDING":
    case "NOCHARGE": {
      const items = [chargeDateItem, visitStart, referenceOwnerItem, extraType, charge.description, taxable, total]
      return canEditExtras && !charge.readonly ? [deleteCheckbox, ...items, editIcon] : ["", ...items, ""]
    }

    case "COMPLETE":
      return [
        chargeDateItem,
        visitStart,
        referenceOwnerItem,
        extraType,
        charge.description,
        taxable,
        charge.processed_at
          ? `${charge.processed_prefix ?? "INV"} - ${dateTimeUtils.formatDate(charge.processed_at)}`
          : "-",
        total,
        getCreditActions({
          charge,
          selectableIds,
          canCreditExtras,
          toggleId
        }) ?? ""
      ]
    case "CREDITED":
      return [
        chargeDateItem,
        referenceOwnerItem,
        extraType,
        charge.description,
        taxable,
        charge.credit_reason,
        charge.processed_at
          ? `${charge.processed_prefix ?? "CR"} - ${dateTimeUtils.formatDate(charge.processed_at)}`
          : "-",
        total,
        "Credited"
      ]
  }
}

export const mapInvoiceTransactionCharges = ({
  charges,
  transaction,
  chargeTypes,
  selectableIds,
  canCreditExtras,
  canEditExtras,
  toggleId,
  selectableDeleteIds,
  toggleDeleteId,
  setModal,
  period,
  destroyModal,
  contract,
  refetchTransaction,
  canEditContributions
}: TransactionChargesMapper): Record<string, InvoiceTransactionChargeRow> => {
  return charges.reduce((result: Record<string, InvoiceTransactionChargeRow>, charge) => {
    return {
      ...result,
      [charge.guid]: {
        row: mapInvoiceTransactionChargeRow({
          charge,
          transaction,
          chargeTypes,
          selectableIds,
          canCreditExtras,
          canEditExtras,
          toggleId,
          selectableDeleteIds,
          toggleDeleteId,
          setModal,
          period,
          destroyModal,
          contract,
          refetchTransaction
        }),
        isClickable:
          (!!charge.transaction_item_guid &&
            charge.appointment_type &&
            [AppointmentType.SHIFT, AppointmentType.VISIT].includes(charge.appointment_type)) ||
          (canEditContributions &&
            !charge.processed_at &&
            charge.charge_type_alias === InvoiceTransactionChargeType.CONTRIB)
      }
    }
  }, {})
}
