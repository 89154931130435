import { FinanceApi } from "api"
import { enabledById, enabledByMandatoryUrlParams } from "data/helpers"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { ShiftCategoryContractResponse } from "types/Shifts/ShiftCategories/types"

export enum QueryKeyParts {
  LIST = "list",
  CONTRACT = "contract",
  DETAIL = "detail",
  SHIFT_CATEGORY = "shift_category"
}

export const queryKeys = {
  getShiftCategoryContractsKey: (shiftCategoryGuid?: string): QueryKey => [
    QueryKeyParts.SHIFT_CATEGORY,
    QueryKeyParts.CONTRACT,
    QueryKeyParts.LIST,
    { shiftCategoryGuid }
  ],
  getShiftCategoryContractKey: (shiftCategoryGuid: string, id?: string): QueryKey => [
    QueryKeyParts.SHIFT_CATEGORY,
    QueryKeyParts.CONTRACT,
    QueryKeyParts.DETAIL,
    { shiftCategoryGuid, id }
  ]
}

export const useShiftCategoryContractsQuery = (
  shiftCategoryGuid?: string
): UseQueryResult<ShiftCategoryContractResponse[], Error> => {
  return useQuery({
    queryKey: queryKeys.getShiftCategoryContractsKey(shiftCategoryGuid),
    queryFn: async () => FinanceApi.get(`/finance/shift-categories/${shiftCategoryGuid}/contracts`),
    ...enabledById(shiftCategoryGuid)
  })
}

export const useShiftCategoryContractQuery = ({
  shiftCategoryGuid,
  userContractGuid,
  enabled = true
}: {
  shiftCategoryGuid: string
  userContractGuid?: string
  enabled?: boolean
}): UseQueryResult<ShiftCategoryContractResponse, Error> => {
  return useQuery({
    queryKey: queryKeys.getShiftCategoryContractKey(shiftCategoryGuid, userContractGuid),
    queryFn: async () => FinanceApi.get(`/finance/shift-categories/${shiftCategoryGuid}/contracts/${userContractGuid}`),
    ...enabledByMandatoryUrlParams([userContractGuid, enabled])
  })
}
