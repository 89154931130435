import { FormStage } from "constants/form"
import { INVOICE_CONTRACT_PRIVATE_ALIASES } from "constants/modelTypes"
import { cloneDeep } from "lodash"
import { ApiUserClient } from "models"
import { assocPath } from "ramda"
import { Dispatch, SetStateAction, useCallback } from "react"
import { useClientContractCreateMutation, useClientContractEditMutation } from "data/finance/client/mutations"
import {
  ApiClientContractPayloadContribution,
  ApiClientContractPayload,
  ContractRate,
  FunderTypes
} from "data/finance/client/types"

type OwnProps = {
  formStage: FormStage
  setFormStage: Dispatch<SetStateAction<FormStage>>
  client: ApiUserClient
  setSelectedContractModelGuid: Dispatch<SetStateAction<string | undefined>>
}

export const useClientContractSubmit = ({
  formStage,
  setFormStage,
  client,
  setSelectedContractModelGuid
}: OwnProps): ((values: ApiClientContractPayload) => void) => {
  const { mutate: createContract } = useClientContractCreateMutation({ clientGuid: client.guid })
  const { mutate: editContract } = useClientContractEditMutation({ clientGuid: client.guid })

  return useCallback(
    (values: ApiClientContractPayload) => {
      // step one
      if (formStage === FormStage.CREATE) {
        setSelectedContractModelGuid(values.contract_guid)
        setFormStage(FormStage.EDIT)
        return
      }

      // step two
      const mutatedValues =
        values.finance?.output_delivery_type_alias === "POST"
          ? assocPath(["finance", "email"], null, values)
          : cloneDeep(values)

      const contractFunderType: FunderTypes | undefined = mutatedValues.finance?.funder_type

      if (mutatedValues.finance && contractFunderType === FunderTypes.INDIVIDUAL) {
        delete mutatedValues.finance.for_attention_of
        delete mutatedValues.finance.funding_name
      }

      if (mutatedValues.finance && contractFunderType === FunderTypes.ORGANISATION) {
        delete mutatedValues.finance.funder_salutation
        delete mutatedValues.finance.funder_first_name
        delete mutatedValues.finance.funder_last_name
      }

      const isPrivate = INVOICE_CONTRACT_PRIVATE_ALIASES.includes(mutatedValues.contract_type_alias)

      if (isPrivate) {
        mutatedValues.contributions = null
      }

      const clonedContributions = cloneDeep(mutatedValues.contributions) || []

      let contributions = null
      if (clonedContributions.length > 0) {
        contributions = clonedContributions
          .filter((contribution) => !contribution.delete)
          .map((contribution: any): ApiClientContractPayloadContribution => {
            delete contribution.status
            delete contribution.user_contract_title
            return contribution as ApiClientContractPayloadContribution
          })
      }

      const clonedRates = cloneDeep(mutatedValues.rates) || []
      let rates: ContractRate[] | undefined = undefined
      if (clonedRates.length > 0) {
        rates = clonedRates
          .filter((rate) => !rate.delete)
          .map((rate) => {
            return { ...rate, hours: rate.hours ?? 0 }
          })
      }

      const { no_end_date, ...rest } = mutatedValues
      const payload = {
        ...rest,
        end: no_end_date ? undefined : values.end,
        contributions,
        rates
      }

      if (payload.guid) {
        editContract(payload)
      } else {
        createContract(payload)
      }
    },
    [formStage, setFormStage, setSelectedContractModelGuid, editContract, createContract]
  )
}
