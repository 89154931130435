import { useContext, useEffect, useMemo } from "react"
import { keys, pick, uniq } from "ramda"
import { SchemaProviderContext } from "./SchemaProvider"
import { StateSchemas } from "./types"

export const useSchema = (wantedList: Array<keyof StateSchemas>): StateSchemas => {
  const { schemas, schemasToLoad, setSchemasToLoad } = useContext(SchemaProviderContext)

  const newSchemasToFetch = useMemo(() => {
    const existingSchemaKeys = keys(schemas)
    const existingOrLoadingSchemas = [...existingSchemaKeys, ...schemasToLoad]

    return wantedList.filter((i) => i).filter((item) => !existingOrLoadingSchemas.includes(item))
  }, [schemas, schemasToLoad, wantedList])

  useEffect(() => {
    if (newSchemasToFetch.length) {
      setSchemasToLoad((current) => uniq([...current, ...newSchemasToFetch]))
    }
  }, [setSchemasToLoad, newSchemasToFetch])

  // @ts-ignore: pick has a bug in type
  return { ...pick(wantedList, schemas) }
}

// TODO: RuleCharge has option { "title": "Zero (Missed)", "value": "MISSED" } that is missing in schema
// TODO: Region returns mode items them Region from core schema, see ShiftListFilter
