import React from "react"
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty"
import Tooltip from "@mui/material/Tooltip"

const TransactionQueued: React.FC = () => {
  return (
    <Tooltip title="This transaction is queued for processing">
      <HourglassEmptyIcon />
    </Tooltip>
  )
}

export default TransactionQueued
