import React, { memo, ReactNode, useCallback, useMemo, useState } from "react"
import { Box } from "@mui/material"
import { WithPermission } from "components/Auth"
import { Button } from "components/Form"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import { FileManagementModalDownload } from "./FileManagementModalDownload"
import { InvoiceFileItem } from "data/finance/invoiceFile/types"
import { FileManagementModalInterface } from "./FileManagementModalInterface"
import { usePaymentFilePatchMutation } from "data/finance/paymentFile/mutations"
import { ReplaceFileButton } from "components/ReplaceFileButton"

interface OwnProps {
  modelType: ContractModelType
  files: InvoiceFileItem[]
  refetchInvoiceFileList: Function
  refetchPaymentFileList: Function
  idsArrayForInterface: string[]
  showITrent: boolean
  idsArrayForITrent: string[]
  isDeveloper: boolean
}

export const FileManagementActionButtons: React.FC<OwnProps> = memo(
  ({
    modelType,
    files,
    refetchInvoiceFileList,
    refetchPaymentFileList,
    idsArrayForInterface,
    showITrent,
    idsArrayForITrent,
    isDeveloper
  }) => {
    FileManagementActionButtons.displayName = "FileManagementActionButtons"

    const isInvoice = modelType === ContractModelType.INVOICE

    const [summaryModal, setSummaryModal] = useState<ReactNode>()

    const downloadable = useMemo(() => files.length && files.some((item) => item.file_guid), [files])

    const allFileGuidsForReplace = useMemo(() => files.map((file) => file.guid), [files])

    const onOk = useCallback(() => {
      if (isInvoice) {
        refetchInvoiceFileList()
      } else {
        refetchPaymentFileList()
      }
      setSummaryModal(null)
    }, [isInvoice, refetchInvoiceFileList, refetchPaymentFileList])

    const onCancel = () => {
      setSummaryModal(null)
    }

    const { mutateAsync: resubmitITrent } = usePaymentFilePatchMutation()

    const onITrentResubmitClick = () => {
      resubmitITrent({ guids: idsArrayForITrent })
    }

    const interfaceButtonLabel = `INTERFACE ${isInvoice ? "CREATION" : "FILES"}`

    return (
      <>
        <Box p={2} px={0} display="flex" justifyContent="flex-end">
          <WithPermission permissions={["Finance.File:Read"]}>
            <div>
              <Button
                data-cy={testingId.fileManagement.downloadButton}
                fullWidth={false}
                disabled={!downloadable}
                sx={{
                  marginRight: "10px"
                }}
                onClick={() =>
                  setSummaryModal(<FileManagementModalDownload {...{ onCancel, onOk, invoices: files, modelType }} />)
                }
              >
                DOWNLOAD {isInvoice ? "INVOICES" : "PAYMENTS"}
              </Button>
              {showITrent ? (
                <Button fullWidth={false} disabled={idsArrayForITrent.length === 0} onClick={onITrentResubmitClick}>
                  Resubmit to ITrent
                </Button>
              ) : (
                <Button
                  data-cy={testingId.fileManagement.interfaceButton}
                  fullWidth={false}
                  disabled={idsArrayForInterface.length === 0}
                  onClick={() =>
                    setSummaryModal(
                      <FileManagementModalInterface
                        {...{ files, selectedIds: idsArrayForInterface, modelType, onCancel, onOk }}
                      />
                    )
                  }
                >
                  {interfaceButtonLabel}
                </Button>
              )}
            </div>
          </WithPermission>
          {isInvoice && isDeveloper && (
            <Box display="flex" justifyContent="flex-end" marginLeft="10px">
              <ReplaceFileButton
                {...{
                  transaction_guids: allFileGuidsForReplace,
                  isInvoice,
                  label: "all invoices",
                  isDisabled: files.length === 0
                }}
              />
            </Box>
          )}
        </Box>
        {summaryModal}
      </>
    )
  }
)
