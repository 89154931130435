import React from "react"
import { Button, TextField, styled, SxProps, Typography } from "@mui/material"
import { unstable_styleFunctionSx, StyleFunction } from "@mui/system"
import { useForm } from "react-hook-form"
import { Link } from "components"
import { emailValidationRegex } from "lib/constants"
import type { RequestPasswordResetRequest } from "api/core"
import Container from "./Container"
import useMutationPasswordResetRequest from "./useMutationPasswordResetRequest"
import { useNavigate } from "react-router-dom"

const Form = styled("form")<{ sx?: SxProps }>({
  display: "block",
  textAlign: "right",
  ...(unstable_styleFunctionSx as StyleFunction<{ sx?: SxProps }>)
})

const PasswordResetRequest = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isSubmitting }
  } = useForm<RequestPasswordResetRequest>({ mode: "onChange" })
  const { mutate, isLoading } = useMutationPasswordResetRequest()
  const navigate = useNavigate()

  const onSubmit = (values: RequestPasswordResetRequest) =>
    mutate(values, {
      onSuccess: () => navigate("/password-reset-request-success")
    })

  return (
    <Container title="Password reset">
      <Form onSubmit={handleSubmit(onSubmit)} sx={{ flex: 1 }}>
        <Typography textAlign="left" color="textSecondary" mb={4}>
          Don&apos;t worry if you forgotten your password, this can easily be fixed with a password reset
        </Typography>
        <TextField
          {...register("email", {
            required: "The field is required",
            validate: {
              pattern: (value) => (emailValidationRegex.test(value) ? true : "Value must be a valid email address")
            }
          })}
          helperText={errors.email?.message ?? errors.email?.types?.pattern}
          variant="outlined"
          label="Email address"
          fullWidth
          error={!!errors.email}
        />
        <Link sx={{ display: "inline-block", mt: 0.5, mb: 4, color: "primary.main", fontWeight: 500 }} to="/login">
          I want to sign in
        </Link>
        <Button
          variant="contained"
          type="submit"
          disabled={!isValid || isSubmitting || isLoading}
          fullWidth
          size="large"
        >
          Request password reset
        </Button>
      </Form>
    </Container>
  )
}
export default PasswordResetRequest
