import useToast from "lib/hooks/useToast"
import { ApiUser } from "models"
import React, { useCallback, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { createAnalyticsMFEclient } from "lib/analytics"
import { appendScripts, appendStyles, ENTRY_POINT } from "./helpers"
import { MfeRender, Router } from "./types"
import ErrorPage from "pages/ErrorPage"
import { Button } from "@mui/material"
import { type AuthState } from "stores"
import useBranchSettings from "lib/hooks/branchInfo/useBranchSettings"
import useMicroFrontend from "./hooks/useMicroFrontend"

interface Props {
  name: string
  host: string
  client?: ApiUser
  carer?: ApiUser
  loggedUser?: AuthState
  path?: string
}

const MicroFrontend: React.FC<Props> = ({ name, host, client, carer, loggedUser, path }) => {
  const messages = useToast()
  const { settings: branchSettings } = useBranchSettings()
  const navigate = useNavigate()

  const router = useMemo<Router>(
    () => ({
      navigate: (to, options) => navigate(`/${to}`, { replace: options?.replace })
    }),
    [navigate]
  )

  const {
    errorLoadManifest,
    setErrorLoadManifest,
    setErrorRenderMfe,
    errorRenderMfe,
    isLoadingScript,
    setIsLoadingScript
  } = useMicroFrontend()

  const scriptId = useMemo(() => `micro-frontend-script-${name}`, [name])
  const linkId = useMemo(() => `micro-frontend-css-${name}`, [name])
  const containerId = useMemo(() => `${name}-container`, [name])
  const unmountFunctionName = useMemo(() => `unmount${name}`, [name])

  const renderMicroFrontend = useCallback(() => {
    const functionName = `render${name}`
    const render = (window as any)[functionName] as MfeRender
    console.log("attempting to call microfrontend render function " + functionName)

    if (!window || typeof render !== "function") {
      console.error(`Could not find render function ${functionName} for microfrontend ${name}`)
      setErrorRenderMfe(true)
      return
    }

    if (!document.getElementById(containerId)) {
      console.error(`Could not find dom node for microfrontend ${name}`)
      setErrorRenderMfe(true)
      return
    }

    render({
      containerId,
      host,
      client,
      carer,
      messages,
      loggedUser,
      analyticsClient: createAnalyticsMFEclient(name),
      branchSettings,
      router,
      path
    })
  }, [carer, client, host, loggedUser, messages, name, branchSettings, router, path, setErrorRenderMfe, containerId])

  const removeScript = useCallback(() => {
    const scriptElements = document.querySelectorAll(`[id="${scriptId}"]`)
    const linkElements = document.querySelectorAll(`[data-microfrontend-css="${linkId}"]`)

    scriptElements.forEach((script) => {
      console.log("removing scripts from dom...", scriptId)
      if (script) {
        script.remove()
      }
    })

    linkElements.forEach((el) => {
      console.log("removing styles from dom...", linkId)
      if (el) {
        el.remove()
      }
    })
  }, [scriptId, linkId])

  const fetchHost = useCallback(async () => {
    setIsLoadingScript(true)
    setErrorLoadManifest(false)
    try {
      const response = await fetch(`${host}/asset-manifest.json`)
      const manifest = await response.json()
      if (manifest[ENTRY_POINT].css?.length > 0) {
        appendStyles(linkId, host, manifest, () => {
          appendScripts(scriptId, host, manifest, () => setIsLoadingScript(false))
        })
      } else {
        appendScripts(scriptId, host, manifest, () => setIsLoadingScript(false))
      }
    } catch (error) {
      console.error(`Could not fetch ${host}/asset-manifest.json`)
      setIsLoadingScript(false)
      setErrorLoadManifest(true)
    }
  }, [scriptId, host, linkId, setErrorLoadManifest, setIsLoadingScript])

  useEffect(() => {
    if (!host || isLoadingScript || errorLoadManifest) {
      return
    }

    const isScriptLoaded = !!document.getElementById(scriptId)

    if (isScriptLoaded) {
      renderMicroFrontend()
    } else {
      fetchHost()
    }
  }, [renderMicroFrontend, fetchHost, host, isLoadingScript, scriptId, errorLoadManifest])

  useEffect(() => {
    return () => {
      const unmount = (window as any)[unmountFunctionName]
      if (typeof unmount === "function") {
        unmount(containerId)
      }
      removeScript()
    }
  }, [unmountFunctionName, removeScript, containerId])

  const reloadPage = useCallback(() => {
    window.location.href = "/"
  }, [])

  if (!host) {
    return <ErrorPage title="Failed to load host." />
  }

  if (errorLoadManifest) {
    return <ErrorPage title="Failed to load manifest." />
  }

  if (errorRenderMfe) {
    return (
      <ErrorPage
        title={`Error on rendering ${name} app.`}
        description="Please reload the page or contact user support."
      >
        <Button variant="contained" size="large" color="primary" fullWidth={false} onClick={reloadPage}>
          Reload
        </Button>
      </ErrorPage>
    )
  }

  return <main id={containerId} />
}

export default MicroFrontend
