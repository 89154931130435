import { FormApi } from "final-form"
import { displayWithDecimals } from "lib/number"
import { isNil } from "ramda"
import { useEffect, useMemo } from "react"
import { useFormState } from "react-final-form"
import { RateUpliftForm, RateUpliftTypeEnum } from "../types"
import { ContractModelRate } from "data/finance/contractModel/types"

interface OwnProps {
  contractGuid: string
  serviceGuid: string
  bandGuid: string
  rate: ContractModelRate
  form: FormApi<RateUpliftForm>
}

export const useRateUpliftNewRateUpdateHandler = ({
  contractGuid,
  serviceGuid,
  bandGuid,
  rate,
  form
}: OwnProps): void => {
  const { values } = useFormState<RateUpliftForm>()

  const { upliftValue, upliftType } = useMemo(() => {
    const formService = values.models[contractGuid].services?.[serviceGuid]
    return {
      upliftValue: formService?.uplift_value,
      upliftType: formService?.uplift_type
    }
  }, [contractGuid, serviceGuid, values.models])

  const formNewRatePath = `models[${contractGuid}].services[${serviceGuid}].bands[${bandGuid}].rates[${rate.guid}].new_rate`

  // update rate if service uplift value or type changes
  useEffect(() => {
    if (!isNil(upliftValue) && upliftType && upliftType !== RateUpliftTypeEnum.MANUAL) {
      const currentRate = Number(rate.rate)
      const addition = Number(upliftValue)
      const result =
        upliftType === RateUpliftTypeEnum.PERCENTAGE ? (currentRate * (100 + addition)) / 100 : currentRate + addition
      // @ts-ignore
      form.change(formNewRatePath, displayWithDecimals(result, 2))
    }
  }, [upliftValue, upliftType, rate.rate, formNewRatePath, form])
}
