import React from "react"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Link from "./Link"
import { Button } from "./Form"
import mobileAppStore from "../assets/images/mobile-app-store.png"
import mobileGooglePlay from "../assets/images/mobile-google-play.png"
import { useAuthStore } from "stores"

interface OwnProps {
  login?: boolean
  logout?: boolean
}

const UserPathBox: React.FC<OwnProps> = (props) => {
  const logOut = useAuthStore((state) => state.logOut)
  const { login, logout } = props

  return (
    <>
      <Box m={5}>
        <Typography variant="h6">
          If you are a family member or care worker, please download the Cera app to login.
        </Typography>
        <br />
        <br />
        <Link to="https://apps.apple.com/gb/app/cera-care/id1471638257" external>
          <img src={mobileAppStore} height="60" alt="Download on the App store" />
        </Link>
        <Link to="https://play.google.com/store/apps/details?id=uk.co.ceracare.careapp&amp;hl=en_GB" external>
          <img src={mobileGooglePlay} height="60" alt="Get it on Google Play" />
        </Link>
      </Box>
      {login && (
        <>
          <Divider color="divider" />
          <Box m={5}>
            <Typography variant="h6">If you are a member of the branch team, please proceed to login.</Typography>
            <br />
            <br />
            <Link to="/login">
              <Button color="primary">Branch team login</Button>
            </Link>
          </Box>
        </>
      )}
      {logout && (
        <>
          <Divider color="divider" />
          <br />
          <br />
          <Button color="primary" onClick={logOut}>
            Log out
          </Button>
        </>
      )}
    </>
  )
}

export default UserPathBox
