import { FinanceApi } from "api"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { ApiHoliday, ApiHolidayRequest } from "./types"
import { queryKeys } from "./queries"
import { ApiError } from "stores/useUIStore/types"
import { useNavigate } from "react-router-dom"
import { useApiMessage } from "stores"

export const useHolidayUpdateMutation = (): UseMutationResult<ApiHoliday, ApiError, { values: ApiHoliday }> => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async ({ values }: { values: ApiHoliday }): Promise<ApiHoliday> => {
      return FinanceApi.put(`/finance/holidays/${values.guid}`, values)
    },
    {
      onSuccess: (data) => {
        showSuccessMessage("Holiday successfully updated.")
        queryClient.setQueryData(queryKeys.getHolidayKey({ guid: data.guid }), data)
        navigate(`/admin/holidays`)
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not update Holiday.", { apiError })
      }
    }
  )
}

export const useHolidayCreateMutation = (): UseMutationResult<ApiHoliday, ApiError, { values: ApiHolidayRequest }> => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async ({ values }: { values: ApiHolidayRequest }): Promise<ApiHoliday> => {
      return FinanceApi.post(`/finance/holidays`, values)
    },
    {
      onSuccess: (data) => {
        showSuccessMessage("Holiday successfully created.")
        queryClient.setQueryData(queryKeys.getHolidayKey({ guid: data.guid }), data)
        navigate(`/admin/holidays`)
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not create Holiday.", { apiError })
      }
    }
  )
}

export const useHolidayDeleteMutation = (): UseMutationResult<void, ApiError, { guid: string }> => {
  const queryClient = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async ({ guid }: { guid: string }): Promise<void> => {
      return FinanceApi.delete(`/finance/holidays/${guid}`)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Holiday successfully deleted.")
        queryClient.invalidateQueries(queryKeys.getHolidayListKey())
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not delete Holiday.", { apiError })
      }
    }
  )
}
