import axios, { AxiosError, AxiosInstance } from "axios"
import Auth from "../lib/Auth"
import Config from "../lib/config"
import { mapValues } from "lodash/fp"
import { objectToQueryString, queryStringToObject } from "lib/queryString"
import {
  convertDatesFromBranchTimezoneToUtc,
  convertDatesFromUtcToBranchTimezone,
  getErrorMessage
} from "./instanceHelpers"
import { useUIStore, useAuthStore } from "stores"
import { DateTime } from "luxon"
import jwt_decode from "jwt-decode"
import { IdentityApi } from "./"
import coreApi from "./core"
import type { RefreshTokenResponse } from "api/core"

const REFRESH_THRESHOLD = 60000

export interface RefreshIdentityResponse {
  identityToken: string
  accessToken: string
  refreshToken: string | null
}

export interface DcpAuthResponse {
  accessToken: string
  idToken: string
  legacyAccessToken: string
}

let cachedRefreshRequest: Promise<DcpAuthResponse> | null
let cachedRefreshRequestLegacy: Promise<RefreshTokenResponse> | null
let cachedRefreshRequestIdentity: Promise<RefreshIdentityResponse> | null

export const API_VERSION_KEY = "api_version"
export const API_CORE_BUILD_NUMBER_KEY = "api_core_version"
export const API_FINANCE_BUILD_NUMBER_KEY = "api_finance_version"

export const GENERAL_ERROR_MESSAGE =
  "We apologize, something bad has happened on our end. Our engineers have been notified about this error. Please try again later or contact our support."

const checkApiVersion = (version: string | null) => {
  if (version === null) {
    return
  }

  const storedVersion = window.sessionStorage.getItem(API_VERSION_KEY)
  if (!storedVersion) {
    window.sessionStorage.setItem(API_VERSION_KEY, version)
  }
}

const checkApiBuildNumber = (url: string | undefined, buildNumberKey: string | null) => {
  if (buildNumberKey === null || !url) {
    return
  }
  let storageBuildKey = ""

  if (url.includes("dcp-finance")) {
    storageBuildKey = API_FINANCE_BUILD_NUMBER_KEY
  } else if (url.includes("dcp-core")) {
    storageBuildKey = API_CORE_BUILD_NUMBER_KEY
  } else return

  if (!window.sessionStorage.getItem(storageBuildKey)) {
    window.sessionStorage.setItem(storageBuildKey, buildNumberKey)
  }
}

const refreshLegayAccessToken = async () => {
  const branchId = Auth.getSessionBranch()
  const refreshToken = Auth.getRefreshToken()

  if (!refreshToken) {
    return null
  }

  if (!cachedRefreshRequestLegacy) {
    cachedRefreshRequestLegacy = coreApi.refresh({ refresh_token: refreshToken, branchId })
  }

  const { access_token } = await cachedRefreshRequestLegacy
  Auth.setAccessToken(access_token)
  cachedRefreshRequestLegacy = null
  return access_token
}

const isValid = (expiryDate: number) =>
  DateTime.fromMillis(expiryDate * 1000).diff(DateTime.now()).milliseconds > REFRESH_THRESHOLD

const getAccessTokenIWithIdentity = async (identityToken: string) => {
  if (!cachedRefreshRequest) {
    cachedRefreshRequest = IdentityApi.get<void, DcpAuthResponse>("/v1/auth/dcp", {
      headers: { Authorization: `Bearer ${identityToken}` }
    })
  }

  const { accessToken } = await cachedRefreshRequest
  Auth.setAccessToken(accessToken)
  cachedRefreshRequest = null
  return accessToken
}

const refreshAccessToken = async (identityToken: string) => {
  const { exp } = jwt_decode<{ exp: number }>(identityToken)

  if (isValid(exp)) {
    return getAccessTokenIWithIdentity(identityToken)
  } else {
    if (!cachedRefreshRequestIdentity) {
      const refreshToken = Auth.getRefreshToken()
      cachedRefreshRequestIdentity = IdentityApi.post<void, RefreshIdentityResponse>("/v1/auth/google/refresh", {
        refreshToken,
        clientId: Config.getEnvVariable("APP_GOOGLE_CLIENT_ID")
      })
    }

    const { identityToken: newIdentityToken, refreshToken } = await cachedRefreshRequestIdentity
    Auth.setIdentityToken(newIdentityToken)
    if (refreshToken) {
      Auth.setRefreshToken(refreshToken)
    }
    cachedRefreshRequestIdentity = null
    return getAccessTokenIWithIdentity(newIdentityToken)
  }
}

const getAccessToken = async () => {
  const { user } = useAuthStore.getState()
  if (!user) {
    return null
  }

  if (isValid(user.exp)) {
    return Auth.getAccessToken()
  }

  const identityToken = Auth.getIdentityToken()
  if (identityToken) return refreshAccessToken(identityToken)

  return refreshLegayAccessToken()
}

const isPlainDataObject = (obj: object) => {
  return typeof obj === "object" && !(obj instanceof FormData) && !(obj instanceof Blob)
}

// We shouldn't use interceptors for managing errors on UI level
// The ignore errors flag is used for cases when we want to handle errors on the component level or ignore it
// It's the possible way without breaking the current logic
const createAxiosInstance = (apiBaseUrl: string, ignoreErrors?: boolean): AxiosInstance => {
  const instance = axios.create({
    baseURL: apiBaseUrl,
    timeout: 0,
    transformResponse: [
      ...(axios.defaults.transformResponse
        ? Array.isArray(axios.defaults.transformResponse)
          ? axios.defaults.transformResponse
          : [axios.defaults.transformResponse]
        : []),
      (responseData) => {
        if (!isPlainDataObject(responseData)) {
          return responseData
        }

        return convertDatesFromUtcToBranchTimezone(responseData)
      }
    ],
    transformRequest: [
      (requestData) => {
        if (!isPlainDataObject(requestData)) {
          return requestData
        }

        return convertDatesFromBranchTimezoneToUtc(requestData)
      },
      ...(axios.defaults.transformRequest
        ? Array.isArray(axios.defaults.transformRequest)
          ? axios.defaults.transformRequest
          : [axios.defaults.transformRequest]
        : [])
    ]
  })

  instance.interceptors.request.use(
    async (config) => {
      if (config.params) {
        config.params = mapValues(convertDatesFromBranchTimezoneToUtc, config.params)
      }

      if (config.url) {
        const [urlWithoutQuery, queryParams] = config.url.split("?")

        const convertedUrlWithoutQuery = urlWithoutQuery.split("/").map(convertDatesFromBranchTimezoneToUtc).join("/")
        const convertedQueryParams = queryParams
          ? //@ts-ignore
            objectToQueryString(mapValues(convertDatesFromBranchTimezoneToUtc, queryStringToObject(queryParams)))
          : null

        config.url = convertedQueryParams
          ? [convertedUrlWithoutQuery, convertedQueryParams].join("?")
          : convertedUrlWithoutQuery
      }

      if (["/auth/refresh", "/auth/dcp", "auth/google/refresh"].some((el) => config?.url?.includes(el))) {
        return config
      }

      const accessToken = await getAccessToken()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      Config.getEnvVariable("NODE_ENV") === "development" &&
        console.log(`Axios Request ${config.method && config.method.toUpperCase()} ${config.baseURL}${config.url}`)

      return config
    },
    (error: AxiosError) => {
      Config.getEnvVariable("NODE_ENV") === "development" && console.dir(error)
      return Promise.reject(error.response)
    }
  )

  instance.interceptors.response.use(
    (response) => {
      const contentType = response.headers["content-type"]
      checkApiVersion(response.headers["x-api-version"] || null)
      checkApiBuildNumber(response.config?.url, response.headers["x-service-build"] || null)
      if (contentType && contentType.indexOf("application/json") !== -1 && response.data) {
        return response.data
      }
      return response
    },
    (error) => {
      if (axios.isCancel(error)) {
        return Promise.reject(error)
      }

      Config.getEnvVariable("NODE_ENV") === "development" && console.dir(error)

      if (error.response === undefined) {
        const message = "Network connection error. This might be a CORS issue or a dropped internet connection."
        console.warn(message)
        return Promise.reject(error)
      }

      const status = error.response.status as string | number

      if (status === 401) {
        useAuthStore.getState().logOut()
      }
      if (status === 403) {
        useUIStore
          .getState()
          .showErrorMessage(
            "You don't have permission to access the requested resource. Please contact your manager or our support."
          )
      } else {
        try {
          const data = error.response?.data

          if (!data) throw new Error()

          const formattedError = getErrorMessage(error)

          if (formattedError && !ignoreErrors) {
            useUIStore.getState().showErrorMessage(formattedError, { action: data.instance })
          }
        } catch {
          useUIStore.getState().showErrorMessage(GENERAL_ERROR_MESSAGE)
        }
      }

      Config.getEnvVariable("NODE_ENV") === "development" && console.dir(error.response)

      const apiError = {
        status,
        statusText: error.response?.statusText,
        response: {
          data: error.response?.data
        }
      }

      return Promise.reject(apiError)
    }
  )

  return instance
}

export default createAxiosInstance
