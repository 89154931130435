import React, { useState } from "react"
import { Button, FormSpyCustom, Select, TextField } from "components/Form"
import { Field, Form } from "react-final-form"
import { Box, Grid } from "@mui/material"
import { ReportListFilterProps } from "types/Reports/types"
import { queryStringToObject } from "lib/queryString"
import { useDebouncedCallback } from "use-debounce"
import { Pagination } from "lib/types"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { ClearFilterButton } from "components/ClearFilterButton"
import { FilterValues, formatFilterValues } from "lib/helpers/formatFilterValues"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"
import { getOptionsFromSchema } from "lib/schema"
import { DateField } from "components/Form/DateField"
import { Location } from "react-router-dom"
import { useCoreSchemasStore } from "stores"

const SickReportFilters: React.FC<{
  location?: Location
  pagination: Pagination
  resetPagination: () => void
  downloadCsvFile: () => void
}> = ({ resetPagination, location, pagination, downloadCsvFile }) => {
  const { ApiReportSickLeaveFilter } = useCoreSchemasStore((state) => state.schema.models)

  const branchOptions = getOptionsFromSchema(ApiReportSickLeaveFilter, "branch_guids[]")

  const [filter, setFilter] = useState<ReportListFilterProps>({
    ...queryStringToObject<ReportListFilterProps>(location?.search)
  })

  const onFilterSubmit = useDebouncedCallback((values: FilterValues<ReportListFilterProps>) => {
    const formattedValues = formatFilterValues<ReportListFilterProps>({
      values,
      startName: "start_date",
      endName: "end_date"
    })

    setFilter(formattedValues)
    resetPagination()
  }, 500)

  const clearFilter = () => {
    setFilter({})
    resetPagination()
  }

  useFilterLocationChange({ filter, pagination })

  return (
    <Box m={3} mx={0}>
      <Form
        onSubmit={onFilterSubmit}
        initialValues={filter}
        validate={validateFilterPeriodDates({ start: "start_date", end: "end_date" })}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={3}>
                <Field name="search" label="Filter by Carer" component={TextField} />
              </Grid>
              <Grid item md={3}>
                <Field
                  name="branch_guids"
                  label="Filter by Branches"
                  component={Select}
                  options={branchOptions}
                  multiselect
                />
              </Grid>
              <Grid item md={3}>
                <DateField name="start_date" label="Uploaded after" />
              </Grid>
              <Grid item md={3}>
                <DateField name="end_date" label="Uploaded before" />
              </Grid>
              <Grid item md={12}>
                <Box textAlign="left">
                  <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
                </Box>
                <Box textAlign="right">
                  <Button type="submit" fullWidth={false} onClick={downloadCsvFile}>
                    Download CSV
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </Box>
  )
}

export default SickReportFilters
