import { Box, styled, TextField } from "@mui/material"
import React, { memo } from "react"

const StyledWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1)
}))

interface OwnProps {
  value?: string | number
}

export const TextFieldDummy: React.FC<OwnProps> = memo(({ value }) => {
  TextFieldDummy.displayName = "TextFieldDummy"

  return (
    <StyledWrapper>
      <TextField {...{ variant: "outlined", value, disabled: true, fullWidth: true }} />
    </StyledWrapper>
  )
})
