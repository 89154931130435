import React, { memo } from "react"
import testingId from "constants/testingId"
import { Button } from "./Form"
import { SxProps, Theme } from "@mui/material"

interface Props {
  id?: string
  onClick: () => void
  disabled?: boolean
  sx?: SxProps<Theme>
}

export const ClearFilterButton = memo(({ disabled = false, ...props }: Props) => {
  ClearFilterButton.displayName = "ClearFilterButton"

  return (
    <Button variant="outlined" fullWidth={false} disabled={disabled} data-cy={testingId.clearFilterButton} {...props}>
      Clear filter
    </Button>
  )
})
