import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { FormSpyCustom, Select, TextField } from "components/Form"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"
import { Pagination } from "lib/types"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { ClearFilterButton } from "components/ClearFilterButton"
import { InvoiceTransactionFilter } from "../types"

interface Props {
  resetPagination: () => void
  pagination: Pagination
  filter: InvoiceTransactionFilter
  setFilter: Dispatch<SetStateAction<InvoiceTransactionFilter>>
}

export const TransactionListFilter: React.FC<Props> = memo(({ resetPagination, pagination, filter, setFilter }) => {
  const onFilterSubmit = useDebouncedCallback((values: InvoiceTransactionFilter) => {
    setFilter(values)
    resetPagination()
  }, 500)

  const clearFilter = () => {
    setFilter({})
    resetPagination()
  }

  useFilterLocationChange({ filter, pagination })

  return (
    <Form onSubmit={onFilterSubmit} initialValues={filter}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpyCustom {...{ handleSubmit }} />
          <Grid container alignItems="center" spacing={1}>
            <Grid item md={4}>
              <Field name="user_name" label="Search by name" component={TextField} />
            </Grid>
            <Grid item md={4}>
              <Field
                name="status"
                label="Invoice Status"
                component={Select}
                options={[
                  { title: "Any", value: "" },
                  { title: "Pending", value: "Pending" },
                  { title: "Ready", value: "Ready" },
                  { title: "Processed", value: "Processed" },
                  { title: "Credited", value: "Credited" },
                  { title: "NoCharge", value: "NoCharge" }
                ]}
              />
            </Grid>
            <Grid item md={4}>
              <Field
                name="percent_confirmed"
                label="% of appointments confirmed"
                component={Select}
                options={[
                  { title: "None", value: "" },
                  { title: "Show Any", value: "ShowAny" },
                  { title: "Show Under 100%", value: "ShowUnder100Percent" },
                  { title: "Show Only 100%", value: "ShowOnly100Percent" }
                ]}
              />
            </Grid>

            <Grid item md={12}>
              <Box textAlign="right">
                <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  )
})

TransactionListFilter.displayName = "TransactionListFilter"
