import React from "react"
import { Button, Typography, styled, SxProps, Alert } from "@mui/material"
import { unstable_styleFunctionSx, StyleFunction } from "@mui/system"
import { useGoogleLogin } from "@react-oauth/google"
import { Link } from "components"
import GoogleIcon from "../../assets/svg/google_icon.svg?react"
import Container from "./Container"
import useMutationLoginSSO from "./useMutationLoginSSO"
import { useAuthStore } from "stores"
import { useNavigate } from "react-router-dom"
import useResetBranchData from "lib/hooks/branchInfo/useResetBranchData"
import { AUTH_TYPE_GOOGLE } from "lib/Auth"

const Form = styled("form")<{ sx?: SxProps }>({
  ...(unstable_styleFunctionSx as StyleFunction<{ sx?: SxProps }>)
})

const LoginSSO = () => {
  const navigate = useNavigate()
  const { resetBranchQueryData } = useResetBranchData()
  const { mutate, isLoading, isError } = useMutationLoginSSO()
  const setLogin = useAuthStore((state) => state.logIn)
  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (credentialResponse) => {
      mutate(credentialResponse.code, {
        onSuccess: ({ accessToken }) => {
          resetBranchQueryData()
          setLogin({ accessToken, authType: AUTH_TYPE_GOOGLE })
          navigate("/")
        }
      })
    },
    scope: "openid profile email"
  })

  return (
    <Container title="Welcome to DCP" hasLoginLink={false}>
      <Form sx={{ flex: 1 }}>
        <Button
          fullWidth
          variant="outlined"
          sx={{ display: "flex", gap: 2, p: 2 }}
          onClick={() => login()}
          disabled={isLoading}
        >
          <GoogleIcon />
          Sign in with google
        </Button>
        {isError && (
          <Alert sx={{ textAlign: "left", mt: 4 }} severity="error">
            Looks like your Google account hasn’t been set up yet, please contact your branch administrator.
          </Alert>
        )}
      </Form>

      <Typography color="textSecondary" mb={2}>
        Alternatively, you can sign in using your old DCP email address and password -&nbsp;
        <Link to="/login" sx={{ fontWeight: 500 }}>
          here
        </Link>
      </Typography>
    </Container>
  )
}

export default LoginSSO
