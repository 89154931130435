import React from "react"
import { getTextScore } from "../../utils"

const CustomAxisYTick = (props: any) => {
  const {
    x,
    y,
    payload: { value }
  } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text dx={-1} dy={5} textAnchor="start">
        {getTextScore(value)}
      </text>
    </g>
  )
}

export default CustomAxisYTick
