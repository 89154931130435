import { trimStart, set, get, isObject } from "lodash"
import { isNil, pickBy } from "ramda"
import { hasDuplicates } from "./utils"

/**
 * Returns typed object with values from querystring
 * NOTE(maoc): This is still suboptimal, because using get/set lodash helpers is effectively avoiding typing
 * @param qs - string - a query string
 */
export function queryStringToObject<T extends Record<string, any>>(qs?: string): Record<string, any> {
  const obj: Record<string, any> = {}
  if (!qs) return obj

  const queryValues = trimStart(qs, "?").split("&")

  const queryKeys = queryValues.map((keyValue) => {
    const [key] = keyValue.split("=")
    return key
  })

  /**
   * Check if there are duplicate query strings and they don't support the array formatter on
   * queryString default behavior.
   */
  if (hasDuplicates(queryKeys) && !queryKeys.some((key) => key.endsWith("[]"))) {
    queryValues.forEach((keyValue: string) => {
      const [key, value] = keyValue.split("=")
      const decodedValue = decodeURIComponent(value)
      set(obj, key, [...get(obj, key, []), { value: decodedValue }])
    })
    return obj as T
  }

  queryValues.forEach((keyValue: string) => {
    const [name, value] = keyValue.split("=")
    const decodedValue = decodeURIComponent(value)
    if (name.endsWith("[]")) {
      const [propName] = name.split("[]")
      set(obj, propName, [...get(obj, propName, []), decodedValue])
      return
    } else if (decodedValue === "true" || decodedValue === "false") {
      set(obj, name, decodedValue === "true")
      return
    } else if (!isNaN(Number(decodedValue))) {
      set(obj, name, Number(decodedValue))
      return
    }
    set(obj, name, decodedValue)
  })
  return obj as T
}

export type QSProperty = string | number | string[] | number[] | boolean | undefined | null
export type QSObject = Record<string, QSProperty | Record<string, QSProperty>>
type QSDictionary = {
  value: string | number | boolean
}

/**
 * Returns a query string built from an object
 * @param objQuery Object with strings as keys and values matching QSProperty or QSObject
 * @param prefix string to prefix fields with
 * @param noNil if true removes keys that are undefined or null
 * @returns string queryString
 */
export function objectToQueryString(objQuery: QSObject, prefix?: string, noNil?: boolean): string {
  const obj: QSObject = noNil ? pickBy((val) => !isNil(val), objQuery) : objQuery

  const qs: string[] = Object.keys(obj).map((key: string) => {
    if (Array.isArray(obj[key])) {
      return (obj[key] as string[] | Array<QSDictionary>)
        .map((value: string | QSDictionary) => {
          if (isObject(value)) {
            return `${key}=${encodeURIComponent(value.value)}`
          }
          return `${key}[]=${encodeURIComponent(value)}`
        })
        .join("&")
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      return objectToQueryString(obj[key] as QSObject, key, noNil)
    }

    const qsKey = prefix ? `${prefix}.${key}` : key
    const qsValue = encodeURIComponent(`${obj[key]}`)
    return `${qsKey}=${qsValue}`
  })
  return qs.join(qs.length > 1 ? "&" : "")
}
