import React, { memo, useMemo } from "react"
import { useShallow } from "zustand/react/shallow"
import { Avatar, Box, ListItem, ListItemButton, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { getNameFirstLetters } from "./utils"
import { AppContext } from "pubnub"
import { CustomChannelMetadata } from "./types"
import { useChatStore } from "./useChatStore"
import { IMAGE_MESSAGE, NO_MESSAGES, SYSTEM_CHAT_IMAGE } from "./constants"

interface Props {
  channel: AppContext.ChannelMetadataObject<CustomChannelMetadata>
  numberOfUnreadMessages: number
}

const ChatChannelsListItem = ({ channel, numberOfUnreadMessages }: Props) => {
  const { currentChannel, setCurrentChannel } = useChatStore(
    useShallow(({ currentChannel, setCurrentChannel }) => ({
      currentChannel,
      setCurrentChannel
    }))
  )
  const unreadMessagesCounter = useMemo(
    () => (numberOfUnreadMessages >= 100 ? "99+" : numberOfUnreadMessages),
    [numberOfUnreadMessages]
  )
  const channelInitials = useMemo(() => getNameFirstLetters(channel.name ?? ""), [channel.name])
  const date = DateTime.fromISO(channel.updated)
  const isToday = date.hasSame(DateTime.now(), "day")
  const isActive = currentChannel?.id === channel.id

  return (
    <ListItem sx={{ p: 0, border: "1px solid", borderColor: "divider", borderLeft: 0, borderRight: 0 }}>
      <ListItemButton
        sx={{
          p: 2,
          gap: 1,
          position: "relative",
          ...(isActive
            ? {
                bgcolor: "action.selected",
                "::before": {
                  content: "''",
                  bgcolor: "primary.main",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: 4,
                  bottom: 0
                }
              }
            : {})
        }}
        title={`Conversation with ${channel.name}`}
        onClick={() => setCurrentChannel(channel)}
      >
        <Avatar
          sx={{
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            width: 32,
            height: 32,
            fontSize: 16
          }}
        >
          {channelInitials}
        </Avatar>
        <Box flex="1" maxWidth={159}>
          <Typography sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontWeight: 500 }}>
            {channel.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
            sx={{ display: "block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {channel.custom?.lastMessageType === SYSTEM_CHAT_IMAGE
              ? IMAGE_MESSAGE
              : (channel.custom?.lastMessageText ?? NO_MESSAGES)}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "right", alignSelf: "flex-start", flex: 1 }}>
          <Typography variant="body2" color="textSecondary">
            {isToday ? date.toFormat("HH:mm") : date.toFormat("dd/MM/yyyy")}
          </Typography>
          {numberOfUnreadMessages > 0 && (
            <Typography
              title={`${numberOfUnreadMessages} unread messages in ${channel.name} conversation`}
              sx={{
                color: "white",
                display: "inline-block",
                fontSize: 12,
                height: 20,
                width: 20,
                lineHeight: "20px",
                bgcolor: "error.main",
                borderRadius: "100%",
                textAlign: "center",
                fontWeight: 500
              }}
            >
              {unreadMessagesCounter}
            </Typography>
          )}
        </Box>
      </ListItemButton>
    </ListItem>
  )
}

export default memo(ChatChannelsListItem)
