import React, { useEffect, useMemo } from "react"
import { Route, Navigate } from "react-router-dom"
import { GlobalMessageBar, RequestLoader } from "components"
import Config from "lib/config"
import * as Pages from "pages"
import * as PATH from "constants/path"
import { initializeSentryTracing } from "helpers/initializeSentryTracing"
import AuthenticatedWrapper from "./AuthenticatedWrapper"
import { useAuthStore } from "stores"
import Auth from "lib/Auth"
import AppLoading from "./AppLoading"
import { useRefreshAuth } from "lib/hooks/useRefreshAuth"
import SentryRoutes from "components/Routes"

const Anonymous = () => {
  Config.getEnvVariable("NODE_ENV") !== "production" && console.log("%c Anonymous user", "font-weight: bold")
  const isSSOLoginEnabled = Config.getEnvVariable("APP_SSO_LOGIN_ENABLED").toLowerCase() === "true"

  return (
    <SentryRoutes>
      <Route path={PATH.PATH_LOGIN} element={<Pages.Login />} />
      <Route path={PATH.PATH_LOGIN_SSO} element={<Pages.LoginSSO />} />
      <Route path={PATH.PATH_PASSWORD_RESET} element={<Pages.PasswordResetRequest />} />
      <Route path={PATH.PATH_PASSWORD_RESET_REQUEST_SUCCESS} element={<Pages.PasswordResetRequestSuccess />} />
      <Route path={PATH.PATH_PASSWORD_RESET_SUCCESS} element={<Pages.PasswordResetSuccess />} />
      <Route path={PATH.PATH_PASSWORD_RESET_TOKEN} element={<Pages.PasswordReset />} />
      <Route path={PATH.PATH_INVITATION} element={<Pages.Invitation />} />
      <Route path={PATH.PATH_INVITATION_SUCCESS} element={<Pages.InvitationSuccess />} />
      <Route path="*" element={<Navigate replace to={isSSOLoginEnabled ? PATH.PATH_LOGIN_SSO : PATH.PATH_LOGIN} />} />
    </SentryRoutes>
  )
}

const App = () => {
  const user = useAuthStore((state) => state.user)
  const isRefreshing = useRefreshAuth()
  const isLoggedIn = useMemo(() => user || Auth.getAccessToken() || Auth.getRefreshToken(), [user])
  const isSentryEnabled = Config.getEnvVariable("APP_SENTRY_ENABLED").toLowerCase() === "true"
  const mainContent = useMemo(() => {
    if (isLoggedIn) {
      return isRefreshing ? <AppLoading /> : <AuthenticatedWrapper />
    }
    return <Anonymous />
  }, [isLoggedIn, isRefreshing])

  useEffect(() => {
    if (isSentryEnabled) {
      initializeSentryTracing()
    } else {
      console.info("Sentry tracing disabled...")
    }
  }, [isSentryEnabled])

  return (
    <>
      <RequestLoader />
      <GlobalMessageBar />
      {mainContent}
    </>
  )
}

export default App
