import { useBranchFeaturesQuery } from "data/company/queries"
import Auth from "./Auth"
import { ApiBranchFeature } from "data/company/types"

export enum CompanyBranchFeatures {
  DIGITAL_CAREPLAN_V2 = "Digital Care Plan V2",
  INTERCOM_CHAT = "Intercom chat",
  CLIENT_PROFILE_V2 = "ClientHealth - Client Profile"
}

const CompanyInfo = {
  useBranchFeatures(
    branchGuid: string | undefined,
    feature: typeof CompanyBranchFeatures[keyof typeof CompanyBranchFeatures]
  ): { data: boolean; isLoading: boolean } {
    const hasPermission = Auth.hasPermission(["Company.Branch.Feature:Read"])

    const { data, isLoading } = useBranchFeaturesQuery({ branchGuid: branchGuid, enabled: hasPermission, retries: 1 })
    if (!data) {
      return { data: false, isLoading }
    }
    const result = data.find((branchFeature: ApiBranchFeature) => branchFeature.title === feature)
    return { data: !!result?.enabled, isLoading }
  }
}

export default CompanyInfo
