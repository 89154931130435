import React, { memo } from "react"
import { List } from "@mui/material"

import { WithPermission } from "../Auth"
import { NavItem } from "components"
import Auth from "lib/Auth"
import { MainNavMessages } from "./MainNavMessages"
import { MainNavIntercom } from "./MainNavIntercom"
import { MainNavFinance } from "./MainNavFinance"
import { MainNavReports } from "./MainNavReports"
import * as PATH from "constants/path"
import { PermissionGroup } from "constants/permission"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { BranchFeatures } from "lib/hooks/branchInfo/types"

interface OwnProps {
  hasIntercomFlag: boolean
}

export const MainNavListTop: React.FC<OwnProps> = memo(({ hasIntercomFlag }) => {
  MainNavListTop.displayName = "MainNavListTop"

  const userInfo = Auth.getUserInfo()
  const branchGuid = userInfo?.branchGuid

  const { hasBranchPermission } = useBranchPermissions()
  const hasFinanceFlag = hasBranchPermission(BranchFeatures.FINANCE)
  const hasShiftsFlag = hasBranchPermission(BranchFeatures.SHIFTS)
  const isFinanceAdmin = Auth.hasPermissionGroup(PermissionGroup.FINANCE_ADMIN)

  const hasSchedulerFlag = hasBranchPermission(BranchFeatures.SCHEDULER)
  const hasDashboardFlag = hasBranchPermission(BranchFeatures.DASHBOARD)
  const hasChatFlagAndPermissions =
    hasBranchPermission(BranchFeatures.CHAT) && Auth.hasPermission(["Chat:Edit", "Chat:Read"])

  const carersFilter = `/carers${isFinanceAdmin ? "" : "?status=13"}`

  return (
    <List component="nav">
      <MainNavIntercom branchGuid={branchGuid} hasIntercomFlag={hasIntercomFlag} />
      {hasDashboardFlag && <NavItem to={PATH.PATH_MFE_DASHBOARD} label="Dashboard" fullMatch id="mainnav-dashboard" />}
      {hasChatFlagAndPermissions && !hasIntercomFlag && <MainNavMessages />}
      <WithPermission permissions={["Visit:Read", "Visit:Edit"]}>
        <NavItem to={PATH.PATH_MFE_VISITS} label="Visits" id="mainnav-visits" />
      </WithPermission>
      {hasSchedulerFlag && (
        <WithPermission permissions={["Scheduling.Appointment:Read"]}>
          <NavItem to={PATH.PATH_MFE_SCHEDULER_CARER_DAILY} label="Schedule" id="mainnav-schedule" />
        </WithPermission>
      )}
      {hasShiftsFlag && (
        <>
          <WithPermission permissions={["Shift:Read", "Shift:Edit"]}>
            <NavItem to="/shifts" label="Shifts" id="mainnav-shifts" />
          </WithPermission>
          <WithPermission permissions={["Shift.Settings.Category:Read"]}>
            <NavItem to="/shift-categories" label="Shift Categories" id="mainnav-shift-categories" />
          </WithPermission>
        </>
      )}
      {hasFinanceFlag && hasShiftsFlag ? (
        <WithPermission permissions={["Finance.Unit:Read", "Finance.Unit:Edit"]}>
          <NavItem to="/units" label="Units" id="mainnav-units" />
        </WithPermission>
      ) : null}
      <WithPermission permissions={["User.Carer:Read", "User.Carer:Edit"]}>
        <NavItem to={carersFilter} label="Carers" id="mainnav-carers" />
      </WithPermission>
      <WithPermission permissions={["User.Client:Read", "User.Client:Edit"]}>
        <NavItem to="/clients" label="Clients" id="mainnav-clients" />
      </WithPermission>
      <WithPermission permissions={["Hr.Carer:Read", "Hr.Carer:Edit"]}>
        <NavItem to={`/carers-search`} label="HR" id="mainnav-hr" />
      </WithPermission>

      <MainNavFinance hasFinanceFlag={hasFinanceFlag} />
      <MainNavReports />
    </List>
  )
})
