import Box from "@mui/material/Box"
import List from "@mui/material/List"
import FolderIcon from "@mui/icons-material/Folder"
import { ContentContainer, DetailSidebar, NavItem } from "components"
import Services from "../Services"
import ContractModelDetail from "../ContractModelDetail"
import Service from "../Service"
import RuleGroupList from "../RuleGroupList"
import RuleGroup from "../RuleGroup"
import { Typography } from "@mui/material"
import React, { memo } from "react"
import RatesForm from "../RatesForm"
import Tooltip from "@mui/material/Tooltip"
import CalendarDetail from "../CalendarDetail"
import CalendarList from "../CalendarList"
import { useInvoiceModelQuery } from "data/finance/contractModel/queries"
import { ContractModelType } from "constants/modelTypes"
import { InvoiceModelStandingChargeList } from "./InvoiceModelStandingChargeList"
import { InvoiceModelStandingChargeDetail } from "./InvoiceModelStandingChargeDetail"
import { WithPermission } from "components/Auth"
import { Permission } from "constants/permission"
import { Navigate, Route, useParams } from "react-router-dom"
import { useUIStore } from "stores"
import SentryRoutes from "components/Routes"

const modelType: ContractModelType = ContractModelType.INVOICE

const ModelDetail: React.FC = () => {
  const { id } = useParams()
  const isSidebarOpen = useUIStore((state) => state.sidebar.isOpen)
  const { data: contractModel } = useInvoiceModelQuery(id)

  if (!contractModel) return null

  return (
    <>
      <DetailSidebar>
        <Box m={10} mx={0}></Box>
        <List component="nav">
          <NavItem to="settings" label="Settings" icon={<FolderIcon color="primary" />} />
        </List>
        <Tooltip title="Invoices" placement="right">
          <Typography
            variant="h6"
            sx={(theme) => ({
              margin: theme.spacing(2, 2, 0, 2)
            })}
          >
            {isSidebarOpen ? "Invoices" : "Im"}
          </Typography>
        </Tooltip>
        <List component="nav">
          <NavItem to="invoice-services" label="Services" icon={<FolderIcon color="primary" />} />
          <WithPermission permissions={[Permission.FINANCE_MODEL_RATE_EDIT, Permission.FINANCE_MODEL_RATE_READ]}>
            <NavItem to="standing-charges" label="Standing Charges" icon={<FolderIcon color="primary" />} />
          </WithPermission>
          <NavItem to="invoice-rule-groups" label="Rules" icon={<FolderIcon color="primary" />} />
          <NavItem to="calendars" label="Billing calendars" icon={<FolderIcon color="primary" />} />
        </List>
        <Tooltip title="Payments" placement="right">
          <Typography
            variant="h6"
            sx={(theme) => ({
              margin: theme.spacing(2, 2, 0, 2)
            })}
          >
            {isSidebarOpen ? "Payments" : "Pm"}
          </Typography>
        </Tooltip>
        <List component="nav">
          <NavItem to="payment-services" label="Services" icon={<FolderIcon color="primary" />} />
          <NavItem to="payment-rule-groups" label="Rules" icon={<FolderIcon color="primary" />} />
        </List>
      </DetailSidebar>
      <ContentContainer
        sx={(theme) => ({
          padding: theme.spacing(0, 0)
        })}
      >
        <SentryRoutes>
          <Route path="/" element={<Navigate to="settings" replace />} />
          <Route path="/settings" element={<ContractModelDetail {...{ modelType, contractModel }} />} />
          <Route
            path="/standing-charges"
            element={<InvoiceModelStandingChargeList {...{ modelType, contractModel }} />}
          />
          <Route
            path="/standing-charges/new"
            element={<InvoiceModelStandingChargeDetail {...{ modelType, contractModel }} />}
          />
          <Route
            path="/standing-charges/:rateId"
            element={<InvoiceModelStandingChargeDetail {...{ modelType, contractModel }} />}
          />
          <Route
            path="/invoice-services"
            element={<Services {...{ modelType, contractModel, isInvoicePaymentService: false }} />}
          />
          <Route
            path="/invoice-services/new"
            element={<Service {...{ modelType, contractModel, isInvoicePaymentService: false }} />}
          />
          <Route
            path="/invoice-services/:serviceId"
            element={<Service {...{ modelType, contractModel, isInvoicePaymentService: false }} />}
          />
          <Route
            path="/invoice-services/:serviceId/rates/:bandId"
            element={<RatesForm {...{ modelType, contractModel }} />}
          />
          <Route
            path="/payment-services"
            element={<Services {...{ modelType, contractModel, isInvoicePaymentService: true }} />}
          />
          <Route
            path="/payment-services/new"
            element={<Service {...{ modelType, contractModel, isInvoicePaymentService: true }} />}
          />
          <Route
            path="/payment-services/:serviceId"
            element={<Service {...{ modelType, contractModel, isInvoicePaymentService: true }} />}
          />
          <Route
            path="/payment-services/:serviceId/rates/:bandId"
            element={<RatesForm {...{ modelType, contractModel, isInvoicePaymentService: true }} />}
          />
          <Route path="/invoice-rule-groups" element={<RuleGroupList {...{ modelType, contractModel }} />} />
          <Route path="/invoice-rule-groups/new" element={<RuleGroup {...{ modelType, contractModel }} />} />
          <Route path="/invoice-rule-groups/:ruleGroupId" element={<RuleGroup {...{ modelType, contractModel }} />} />
          <Route
            path="/payment-rule-groups"
            element={<RuleGroupList {...{ modelType, contractModel, isPaymentRule: true }} />}
          />
          <Route
            path="/payment-rule-groups/new"
            element={<RuleGroup {...{ modelType, contractModel, isPaymentRule: true }} />}
          />
          <Route
            path="/payment-rule-groups/:ruleGroupId"
            element={<RuleGroup {...{ modelType, contractModel, isPaymentRule: true }} />}
          />
          <Route path="/calendars" element={<CalendarList {...{ modelType, contractModel }} />} />
          <Route path="/calendars/new" element={<CalendarDetail {...{ modelType, contractModel }} />} />
          <Route path="/calendars/:calendarId" element={<CalendarDetail {...{ modelType, contractModel }} />} />
        </SentryRoutes>
      </ContentContainer>
    </>
  )
}

export default memo(ModelDetail)
