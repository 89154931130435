import React, { memo } from "react"
import { useInvoiceBulkPostMutation, useInvoiceBulkPutMutation } from "data/finance/invoiceBulkCredit/mutations"
import { usePaymentBulkPostMutation, usePaymentBulkPutMutation } from "data/finance/paymentBulkCredit/mutations"
import { AppointmentSearchBulkModal } from "./AppointmentSearchBulkModal"

interface OwnProps {
  destroyModal: () => void
  isInvoiceModel: boolean
}

export const AppointmentSearchCreditModal: React.FC<OwnProps> = memo(({ destroyModal, isInvoiceModel }) => {
  const { mutateAsync: postInvoiceBulk, data: preparationInvoiceData } = useInvoiceBulkPostMutation() // prepare invoices
  const {
    mutateAsync: putInvoiceBulk,
    isLoading: isInvoiceLoading,
    isError: isInvoiceError
  } = useInvoiceBulkPutMutation() // complete invoices

  const { mutateAsync: postPaymentBulk, data: preparationPaymentData } = usePaymentBulkPostMutation() // prepare payments
  const {
    mutateAsync: putPaymentBulk,
    isLoading: isPaymentLoading,
    isError: isPaymentError
  } = usePaymentBulkPutMutation() // complete payments

  return (
    <AppointmentSearchBulkModal
      {...{
        isInvoiceModel,
        destroyModal,
        postBulk: isInvoiceModel ? postInvoiceBulk : postPaymentBulk,
        putBulk: isInvoiceModel ? putInvoiceBulk : putPaymentBulk,
        preparationData: isInvoiceModel ? preparationInvoiceData : preparationPaymentData,
        isLoading: isInvoiceModel ? isInvoiceLoading : isPaymentLoading,
        isError: isInvoiceModel ? isInvoiceError : isPaymentError
      }}
    />
  )
})

AppointmentSearchCreditModal.displayName = "AppointmentSearchCreditModal"
