import React, { memo, useEffect, useState } from "react"
import useInvoiceExtrasSubmitHandler from "../handlers/useInvoiceExtrasSubmitHandler"
import { useAdditionalTypeListQuery } from "data/finance/additional/queries"
import { AdditionalType } from "data/finance/additional/type"
import { findAdditionalByType } from "../helpers/findAdditionalByType"
import { ExtrasModal } from "pages/Finance/components/ExtrasModal"
import { BasicPeriod } from "lib/types"
import { InvoiceTransaction, InvoiceTransactionCharge } from "data/finance/invoiceProcessing/types"
import { CalendarPeriod } from "data/finance/contractModel/types"

interface Props {
  transaction: InvoiceTransaction
  period: CalendarPeriod
  destroyModal: () => void
  charge?: InvoiceTransactionCharge
  contract?: BasicPeriod
  refetchTransaction: () => void
}

const InvoiceExtrasModal: React.FC<Props> = ({
  transaction,
  period,
  destroyModal,
  charge,
  contract,
  refetchTransaction
}) => {
  const { data: additionalChargeTypes } = useAdditionalTypeListQuery({ isInvoice: true })

  const [extraType, setExtraType] = useState<AdditionalType | undefined>(undefined)

  useEffect(() => {
    if (charge && additionalChargeTypes) {
      setExtraType(findAdditionalByType({ additionalChargeTypes, charge_type_alias: charge.charge_type_alias }))
    }
  }, [charge, additionalChargeTypes])

  const onSubmit = useInvoiceExtrasSubmitHandler({
    extraType,
    setExtraType,
    charge,
    transaction,
    period,
    destroyModal,
    refetchTransaction,
    additionalChargeTypes
  })

  return (
    <ExtrasModal
      {...{
        period,
        charge,
        contract,
        extraType,
        additionalChargeTypes,
        destroyModal,
        onSubmit
      }}
    />
  )
}

export default memo(InvoiceExtrasModal)
