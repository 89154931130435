import { FinanceApi } from "api"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { ContractServiceType } from "./types"
import { ApiError } from "stores/useUIStore/types"
import { queryKeys } from "./queries"
import { useNavigate } from "react-router-dom"
import { useApiMessage } from "stores"

export const useContractServiceTypeCreateMutation = (): UseMutationResult<
  ContractServiceType,
  ApiError,
  { values: ContractServiceType }
> => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async ({ values }: { values: ContractServiceType }): Promise<ContractServiceType> => {
      return FinanceApi.post(`/finance/contract-service-types`, values)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Contract care type successfully created.")
        queryClient.invalidateQueries(queryKeys.getContractServiceTypeListKey())
        navigate(`/admin/finance/contract-service-types`)
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not create Contract care type.", { apiError })
      }
    }
  )
}

export const useContractServiceTypeUpdateMutation = (): UseMutationResult<
  ContractServiceType,
  ApiError,
  { values: ContractServiceType }
> => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async ({ values }: { values: ContractServiceType }): Promise<ContractServiceType> => {
      return FinanceApi.put(`/finance/contract-service-types/${values.guid}`, values)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Contract care type successfully updated.")
        queryClient.invalidateQueries(queryKeys.getContractServiceTypeListKey())
        navigate(`/admin/finance/contract-service-types`)
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not update Contract care type.", { apiError })
      }
    }
  )
}

// clean after cypress
export const useContractServiceTypeDeleteMutation = (): UseMutationResult<void, ApiError, { guid: string }> => {
  const queryClient = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async ({ guid }: { guid: string }): Promise<void> => {
      return FinanceApi.delete(`/finance/contract-service-types/${guid}`)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Contract care type successfully deleted.")
        queryClient.invalidateQueries(queryKeys.getContractServiceTypeListKey())
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not delete Contract care type.", { apiError })
      }
    }
  )
}
