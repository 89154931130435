import React, { memo } from "react"
import { ModalWrapper } from "components"
import { Grid, Box } from "@mui/material"
import { Field, Form } from "react-final-form"
import { AutocompleteMui, Button, FormSectionHeader } from "components/Form"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { useGetProcessingAvailableUsers } from "data/finance/invoicePaymentProcessing/queries"
import { useProcessingTransactionPostMutation } from "data/finance/invoicePaymentProcessing/mutations"
import validators from "lib/validators"

interface Props {
  modelType: ContractModelType
  periodGuid: string
  onCancel: () => void
  onOk: Function
}

export const AddUserTransactionModal = memo(({ modelType, periodGuid, onCancel, onOk }: Props) => {
  const isInvoiceModel = modelType === ContractModelType.INVOICE
  const entityType = isInvoiceModel ? "Client" : "Carer"

  const { mutatedData: transactionUsersOptions } = useGetProcessingAvailableUsers({ modelType, periodGuid })

  const { mutateAsync: postTransaction } = useProcessingTransactionPostMutation({ modelType, periodGuid, onOk })

  const onSubmit = (values: { user_guid: string }) => {
    postTransaction(values)
  }

  return (
    <ModalWrapper title={`Add ${entityType}`} destroyModal={onCancel} width={600}>
      <RequestLoaderWrapper>
        <FormSectionHeader title={`Select ${entityType}`} />
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Field
                    name="user_guid"
                    label={`${entityType} *`}
                    component={AutocompleteMui}
                    options={transactionUsersOptions}
                    validate={validators.required}
                  />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                <Button sx={{ marginRight: "20px" }} variant="text" fullWidth={false} onClick={onCancel}>
                  CANCEL
                </Button>
                <Button type="submit" fullWidth={false} data-cy={testingId.addUserTransactionModal.saveButton}>
                  SAVE
                </Button>
              </Box>
            </form>
          )}
        </Form>
      </RequestLoaderWrapper>
    </ModalWrapper>
  )
})

AddUserTransactionModal.displayName = "AddUserTransactionModal"
