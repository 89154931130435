import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { FormSpyCustom, Select, TextField } from "components/Form"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"
import { SelectableIdsDictionary } from "lib/hooks"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { TransactionDetailType } from "pages/Finance/types"
import { ClearFilterButton } from "components/ClearFilterButton"
import { useSchema } from "data/schema/useSchema"

const visitStatusOptions = [
  { title: "Any", value: "" },
  { title: "Confirmed", value: true },
  { title: "Unconfirmed", value: false }
]

const visitDatesOptions = [
  { title: "Any", value: "" },
  { title: "In period", value: false },
  { title: "Outside period", value: true }
]

export type PaymentTransactionItemsFilter = {
  reference_owner_name?: string
  service_type_guid?: string[]
  visit_dates?: boolean
  visit_status?: boolean
}

interface OwnProps extends Pick<SelectableIdsDictionary, "resetAll"> {
  itemFilter: PaymentTransactionItemsFilter
  setItemFilter: Dispatch<SetStateAction<PaymentTransactionItemsFilter>>
  tab: TransactionDetailType
}

export const TransactionDetailItemsFilter: React.FC<OwnProps> = memo(({ resetAll, setItemFilter, itemFilter, tab }) => {
  const { ContractServiceType } = useSchema(["ContractServiceType"])

  const onFilterSubmit = useDebouncedCallback((values: PaymentTransactionItemsFilter) => {
    resetAll()
    setItemFilter(values)
  }, 300)

  useFilterLocationChange({ filter: { tab, ...itemFilter } })

  return (
    <Form onSubmit={onFilterSubmit} initialValues={itemFilter}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <FormSpyCustom {...{ handleSubmit }} />
          <Grid container alignItems="center" spacing={1}>
            <Grid item md={3}>
              <Field name="reference_owner_name" label="Search by client name" component={TextField} />
            </Grid>
            <Grid item md={2}>
              <Field name="visit_dates" component={Select} options={visitDatesOptions} label="Visit dates" />
            </Grid>
            <Grid item md={2}>
              <Field name="visit_status" component={Select} options={visitStatusOptions} label="Visit status" />
            </Grid>
            <Grid item md={3}>
              <Field
                name="service_type_guid"
                label="Care types"
                component={Select}
                options={ContractServiceType}
                multiselect
              />
            </Grid>
            <Grid item md={2}>
              <Box textAlign="right">
                <ClearFilterButton
                  onClick={() => {
                    form.reset()
                    setItemFilter({})
                  }}
                  disabled={Object.keys(itemFilter).length === 0}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  )
})

TransactionDetailItemsFilter.displayName = "TransactionDetailItemsFilter"
