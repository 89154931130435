import React, { memo } from "react"
import { Checkbox, FormSectionHeader } from "components/Form"
import testingId from "constants/testingId"
import { Field } from "react-final-form"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { BranchFeatures } from "lib/hooks/branchInfo/types"

export const CarerProfileSectionCm = memo(() => {
  const { hasBranchPermission } = useBranchPermissions()
  const hasCM200Permission = hasBranchPermission(BranchFeatures.CM2000)

  return (
    <>
      {hasCM200Permission ? (
        <>
          <FormSectionHeader title="CM Mobile" data-cy={testingId.carer.create.cm2000Header} />
          <Field
            type="checkbox"
            label="Enable CM Mobile"
            component={Checkbox}
            name="cm2000.enabled"
            data-cy={testingId.carer.create.enableCm2000Checkbox}
          />
        </>
      ) : null}
    </>
  )
})

CarerProfileSectionCm.displayName = "CarerProfileSectionCm"
