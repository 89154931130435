import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Box, Typography } from "@mui/material"
import Container from "./Container"

const PasswordResetSuccess = () => {
  const navigate = useNavigate()
  return (
    <Container title="Reset password">
      <Box sx={{ flex: 1 }}>
        <Typography mb={4} color="textSecondary">
          Your password has successfully been changed.
        </Typography>
        <Button variant="contained" size="large" fullWidth onClick={() => navigate("/login")}>
          Continue to sign in with email
        </Button>
      </Box>
    </Container>
  )
}

export default PasswordResetSuccess
