import { AuthLink } from "components"
import { Permission } from "constants/permission"
import React from "react"

export const appointmentOwnerLink = ({
  owner_guid,
  owner_name,
  owner_type,
  isInvoiceModel = true
}: {
  owner_type: string
  owner_guid: string
  owner_name?: string
  isInvoiceModel?: boolean
}): React.JSX.Element | string => {
  switch (owner_type) {
    case "User":
      return (
        <AuthLink
          to={`/${isInvoiceModel ? "clients" : "carers"}/${owner_guid}/profile/personal-details`}
          permissions={[Permission.USER_CLIENT_READ]}
        >
          {owner_name}
        </AuthLink>
      )
    case "Shift":
      return (
        <AuthLink to={`/shift-categories/${owner_guid}/detail`} permissions={[Permission.SHIFT_SETTINGS_CATEGORY_READ]}>
          {owner_name}
        </AuthLink>
      )
    case "Unit":
      return (
        <AuthLink to={`/units/${owner_guid}/edit`} permissions={[Permission.FINANCE_UNIT_READ]}>
          {owner_name}
        </AuthLink>
      )
    default:
      return owner_name || ""
  }
}
