import { ApiMobilityAssessment } from "models/mobilityAssessment"

export type MobilityAssessmentTemplateKeys = Omit<ApiMobilityAssessment, "service_user_id">

export const MobilityAssessmentTemplate: Record<
  keyof MobilityAssessmentTemplateKeys,
  {
    title: string
    options: { title: string; value: string }[]
  }
> = {
  perceived_fall_risk: {
    title: "On a scale from 1 to 5, how likely do you think they are to have a fall in the near future?",
    options: [
      {
        title: "Very unlikely to fall (1)",
        value: "VeryUnlikely"
      },
      {
        title: "Unlikely to fall (2)",
        value: "Unlikely"
      },
      {
        title: "Moderate Risk of Falling (3)",
        value: "Moderate"
      },
      {
        title: "Likely to fall (4)",
        value: "Likely"
      },
      {
        title: "Very likely to fall (5)",
        value: "VeryLikely"
      }
    ]
  },
  mobility_balance_change: {
    title:
      "On a scale from 1 to 5, has there been any change in their mobility or balance since the last time you saw them?",
    options: [
      {
        title: "Significantly better (1)",
        value: "SignificantlyBetter"
      },
      {
        title: "Better (2)",
        value: "Better"
      },
      {
        title: "No change (3)",
        value: "NoChange"
      },
      {
        title: "Worse (4)",
        value: "Worse"
      },
      {
        title: "Significantly worse (5)",
        value: "SignificantlyWorse"
      },
      {
        title: "Not Sure",
        value: "NotSure"
      }
    ]
  },
  service_user_perceived_mobility: {
    title:
      "How would you rate your current mobility, from 1 to 5, with 1 being no problems and 5 being very significant problems?",
    options: [
      {
        title: "No problems (1)",
        value: "NoProblems"
      },
      {
        title: "Minor issues (2)",
        value: "MinorIssues"
      },
      {
        title: "Some difficulty (3)",
        value: "SomeDifficulty"
      },
      {
        title: "Significant problems (4)",
        value: "SignificantProblems"
      },
      {
        title: "Very significant problems (5)",
        value: "VerySignificantProblems"
      },
      {
        title: "Client cannot answer",
        value: "ClientCannotAnswer"
      }
    ]
  },
  home_environment_fall_risk: {
    title: "On a scale from 1 to 5, how likely do you think their home environment puts them at risk of falling?",
    options: [
      {
        title: "Very unlikely to cause a fall (1)",
        value: "VeryUnlikely"
      },
      {
        title: "Unlikely to cause a fall (2)",
        value: "Unlikely"
      },
      {
        title: "Some risk of causing a fall (3)",
        value: "SomeRisk"
      },
      {
        title: "Likely to cause a fall (4)",
        value: "Likely"
      },
      {
        title: "Very likely to cause a fall (5)",
        value: "VeryLikely"
      }
    ]
  },
  assessment_confidence: {
    title:
      "On a scale from 1 to 5, how confident do you feel about your answers and your ability to assess the risk of falls for the client?",
    options: [
      {
        title: "Not confident at all (1)",
        value: "NotConfident"
      },
      {
        title: "Not very confident (2)",
        value: "NotVeryConfident"
      },
      {
        title: "Somewhat confident (3)",
        value: "SomewhatConfident"
      },
      {
        title: "Confident (4)",
        value: "Confident"
      },
      {
        title: "Very confident (5)",
        value: "VeryConfident"
      }
    ]
  },
  local_authority_initiative: {
    title:
      "The Service User was offered the opportunity to opt into a Local Authority initiative to reduce the risk of falling",
    options: [
      {
        title: "Strength-focused Exercises",
        value: "StrengthFocusedExercises"
      },
      {
        title: "Balance-focused Exercises",
        value: "BalanceFocusedExercises"
      },
      {
        title: "Home Assessment Service",
        value: "HomeAssessmentService"
      },
      {
        title: "Fall Friends Talks / isolation",
        value: "FallFriendsTalks"
      },
      {
        title: "Strength and Balance Classes",
        value: "StrengthBalanceClasses"
      },
      {
        title: "Falls Information Brochure",
        value: "FallsInformationBrochure"
      },
      {
        title: "Exercise Brochure",
        value: "ExerciseBrochure"
      },
      {
        title: "Client is not interested",
        value: "ClientNotInterested"
      }
    ]
  }
}
