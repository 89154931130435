import { Table, TableBody, TableCell, TableRow, Tooltip } from "@mui/material"
import React, { memo, MouseEvent, ReactNode, useState } from "react"
import { EmptyData } from "components"
import { ContractModel, ContractModelService } from "data/finance/contractModel/types"
import { optionsToValueTitle } from "lib/schema"
import { DateTimeFormat } from "lib/datetime"
import { ContractModelType } from "constants/modelTypes"
import testingId from "constants/testingId"
import { ServiceCopyModal } from "./ServiceCopyModal"
import { Button } from "components/Form"
import { ServiceListHeader } from "./ServiceListHeader"
import { useSchema } from "data/schema/useSchema"
import { useNavigate } from "react-router-dom"

interface OwnProps {
  services?: ContractModelService[]
  modelType: ContractModelType
  isInvoicePaymentService?: boolean
  contractModel: ContractModel
}

const ServiceList: React.FC<OwnProps> = ({ services, modelType, isInvoicePaymentService, contractModel }) => {
  const navigate = useNavigate()
  const { PaymentType } = useSchema(["PaymentType"])

  const paymentTypeAliasMap = optionsToValueTitle(PaymentType)

  const [modal, setModal] = useState<ReactNode>(null)
  const destroyModal = () => setModal(null)
  const handleSetModal = (service: ContractModelService) => (e: MouseEvent) => {
    e.stopPropagation()
    setModal(
      <ServiceCopyModal
        {...{
          services,
          service,
          destroyModal,
          modelType,
          isInvoicePaymentService,
          contractModel
        }}
      />
    )
  }

  if (!services?.length) return <EmptyData message="No services found, start by adding one." />

  return (
    <>
      <Table>
        <ServiceListHeader {...{ isInvoicePaymentService }} />
        <TableBody>
          {services.map((service: ContractModelService, key: number) => (
            <TableRow
              key={key}
              data-cy={testingId.tableRow}
              hover
              sx={{
                cursor: "pointer"
              }}
              onClick={() => {
                navigate(service.guid)
              }}
            >
              {isInvoicePaymentService && (
                <TableCell data-cy={testingId.tableCell}>{service.payment_contract_title}</TableCell>
              )}
              <TableCell data-cy={testingId.tableCell}>{service.service_type?.title}</TableCell>
              <TableCell data-cy={testingId.tableCell}>{paymentTypeAliasMap[service.payment_type_alias]}</TableCell>
              <TableCell data-cy={testingId.tableCell}>{service.start.toFormat(DateTimeFormat.DATE)}</TableCell>
              <TableCell data-cy={testingId.tableCell}>
                {service.end ? service.end.toFormat(DateTimeFormat.DATE) : "/ /"}
              </TableCell>
              <TableCell data-cy={testingId.tableCell}>{service.status}</TableCell>
              {!isInvoicePaymentService && (
                <TableCell data-cy={testingId.tableCell}>
                  {service.is_overidden && (
                    <Tooltip
                      title={
                        <span style={{ whiteSpace: "pre-line" }}>
                          {service.overrides.map((override) => override.title).join("\n")}
                        </span>
                      }
                      sx={{
                        fontSize: "16px",
                        color: "black",
                        background: "#b7b7b7"
                      }}
                    >
                      <span>Y</span>
                    </Tooltip>
                  )}
                  {!service.is_overidden && <span>N</span>}
                </TableCell>
              )}
              <TableCell data-cy={testingId.tableCell}>
                <Button {...{ variant: "text", color: "primary", fullWidth: false, onClick: handleSetModal(service) }}>
                  Copy
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {modal ? modal : null}
    </>
  )
}

export default memo(ServiceList)
