import React from "react"
import { FormControl, SxProps, Autocomplete, TextField, AutocompleteValue } from "@mui/material"

interface Props {
  onChange: (
    value: {
      id: string
      label: string
    }[]
  ) => void
  options: { id: string; label: string }[]
  value: AutocompleteValue<{ id: string; label: string }, true, false, false>
  label: string
  placeholder?: string
  sx?: SxProps
}

const ChatPanelAutocomplete = ({ onChange, value, options, label, sx, placeholder }: Props) => {
  return (
    <FormControl fullWidth sx={sx}>
      <Autocomplete
        disablePortal
        options={options}
        multiple
        limitTags={3}
        getOptionKey={({ label, id }) => `${id}-${label}`}
        renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} />}
        value={value}
        onChange={(_, value) => onChange(value)}
      />
    </FormControl>
  )
}

export default ChatPanelAutocomplete
