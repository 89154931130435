import React, { StrictMode } from "react"
import "./assets/styles/index.css"
import { CssBaseline } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { Settings } from "luxon"
import { createRoot } from "react-dom/client"
import { ceraUiTheme, LuxonLocalProvider } from "@ceracare/dcp-ui"
import { BrowserRouter } from "react-router-dom"
import ReactQueryProvider from "lib/ReactQueryProvider"
import { SchemaProvider } from "data/schema/SchemaProvider"
import timezone from "lib/timezone"
import App from "app/App"
import { GoogleOAuthProvider } from "@react-oauth/google"
import Config from "lib/config"

Settings.defaultZone = timezone.get()
Settings.defaultLocale = "en-GB"

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <GoogleOAuthProvider clientId={Config.getEnvVariable("APP_GOOGLE_CLIENT_ID")}>
      <ReactQueryProvider>
        <LuxonLocalProvider adapterLocale="en-GB">
          <SchemaProvider>
            <ThemeProvider theme={ceraUiTheme}>
              <BrowserRouter>
                <CssBaseline />
                <App />
              </BrowserRouter>
            </ThemeProvider>
          </SchemaProvider>
        </LuxonLocalProvider>
      </ReactQueryProvider>
    </GoogleOAuthProvider>
  </StrictMode>
)
