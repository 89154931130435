import React, { memo } from "react"
import { ModalWrapper } from "components"
import { AdditionalSearchFormValues, ApiPaymentAdditionalSearch } from "data/finance/additional/type"
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { Field, FormRenderProps } from "react-final-form"
import { Button, TextField } from "components/Form"
import validators from "lib/validators"
import { getSelectedAdditionalGuids } from "./helpers/getSelectedAdditionalGuids"
import MoneyWrapper from "components/MoneyWrapper"

interface OwnProps {
  destroyModal: () => void
  formProps: FormRenderProps<AdditionalSearchFormValues>
  additionals: ApiPaymentAdditionalSearch[]
  mappedChargeTypes: Record<string, string>
}

export const AdditionalSearchApproveModal: React.FC<OwnProps> = memo(
  ({ destroyModal, formProps, additionals, mappedChargeTypes }) => {
    const selectedItems = getSelectedAdditionalGuids(formProps.values.items)

    const items = additionals.filter((additional) => selectedItems.includes(additional.guid))

    const itemsTotal = items.reduce((res, item) => res + item.total, 0)

    const isApprove = formProps.values.approved

    const handleSubmit = () => {
      formProps.form.submit()
    }

    return (
      <ModalWrapper
        title={`${isApprove ? "Approve" : "Reject"} the following additional requests`}
        destroyModal={destroyModal}
        width={800}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              {["Carer name", "Additional type", "Note", "Charge type", "Value"].map((item) => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => {
              return (
                <TableRow key={item.guid}>
                  <TableCell>{item.owner_name}</TableCell>
                  <TableCell>{mappedChargeTypes[item.charge_type_alias]}</TableCell>
                  <TableCell sx={(theme) => ({ width: "30%", color: theme.palette.text.secondary })}>
                    {item.description}
                  </TableCell>
                  <TableCell>{item.extra_type}</TableCell>
                  <TableCell>
                    <MoneyWrapper {...{ amount: item.total }} />
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <TableCell
                sx={(theme) => ({
                  backgroundColor: theme.palette.grey[100],
                  color: theme.palette.grey[800],
                  fontWeight: "bold"
                })}
                colSpan={4}
              >
                Total
              </TableCell>
              <TableCell
                sx={(theme) => ({
                  backgroundColor: theme.palette.grey[100],
                  color: theme.palette.grey[800],
                  fontWeight: "bold"
                })}
              >
                <MoneyWrapper amount={itemsTotal} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Field
          component={TextField}
          name="comment"
          label="Enter feedback"
          required={!isApprove}
          validate={!isApprove ? validators.required : undefined}
          multiline={true}
        />

        <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
          <Button variant="text" fullWidth={false} onClick={destroyModal}>
            Cancel
          </Button>
          <Button type="submit" fullWidth={false} onClick={handleSubmit} disabled={formProps.invalid}>
            Save
          </Button>
        </Box>
      </ModalWrapper>
    )
  }
)

AdditionalSearchApproveModal.displayName = "AdditionalSearchApproveModal"
