import React from "react"
import { Avatar, Box, Typography } from "@mui/material"
import uniqolor from "uniqolor"

interface Props {
  name: string
  guid: string
  size?: "small"
  withName?: boolean
}

function generateInitials(name: string) {
  const initials = (name?.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g) || []).join("").toUpperCase()
  return initials?.substring(0, 2)
}

const Initials = ({ name, guid, size, withName = false }: Props) => {
  const avatarColor = uniqolor(guid, { format: "rgb" }).color //TODO: refactor and remove uniqolor
  return (
    <Box display="flex" alignItems="center">
      <Avatar
        style={{ backgroundColor: avatarColor }}
        sx={(theme) => ({
          ...(size === "small"
            ? { width: theme.spacing(3.8), height: theme.spacing(3.8), fontSize: theme.spacing(1.8) }
            : {})
        })}
      >
        {generateInitials(name)}
      </Avatar>
      {withName && (
        <Typography component="span" sx={{ paddingLeft: 2 }}>
          {name}
        </Typography>
      )}
    </Box>
  )
}

export default Initials
