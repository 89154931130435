import { Box, Tooltip, Typography } from "@mui/material"
import React, { memo } from "react"
import { ApiContractAccrualSummary } from "data/finance/carer/types"
import { DateTime } from "luxon"

interface OwnProps {
  accrualSummary?: ApiContractAccrualSummary
}

export const CarerAccrualDetailStatsInfo: React.FC<OwnProps> = memo(({ accrualSummary }) => {
  CarerAccrualDetailStatsInfo.displayName = "CarerAccrualDetailStatsInfo"

  const items = [
    {
      label: "Accrued:",
      value: accrualSummary?.allowance?.toFixed(2) ?? 0,
      projectedValue: accrualSummary?.projected_allowance?.toFixed(2) ?? 0
    },
    { label: "Used:", value: accrualSummary?.used?.toFixed(2) ?? 0, projectedValue: null },
    {
      label: "Remaining:",
      value: accrualSummary?.remaining?.toFixed(2) ?? 0,
      projectedValue: accrualSummary?.projected_remaining?.toFixed(2) ?? 0
    }
  ]

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "nowrap"
      }}
    >
      {items.map(({ label, value, projectedValue }) => (
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap"
          }}
          key={label}
          mx="20px"
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "flex-end",
              paddingRight: 1
            }}
            variant="h6"
            component="span"
          >
            {label}
          </Typography>{" "}
          <Typography
            sx={{
              display: "flex",
              alignItems: "flex-end",
              paddingRight: 1
            }}
            variant="h6"
            color="primary"
            component="span"
          >
            {value}
          </Typography>
          {!!projectedValue && !!accrualSummary?.policy?.end && accrualSummary.policy.end >= DateTime.now().toUTC() && (
            <Tooltip
              title="This projected value is an estimate based on the average days that have already been accrued for this carer."
              placement="right"
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  flexWrap: "wrap",
                  paddingRight: 1,
                  color: "primary.main"
                }}
                variant="h6"
                color="primary"
                component="span"
              >
                {`/\u00A0${projectedValue}*`}
              </Typography>
            </Tooltip>
          )}
        </Box>
      ))}
    </Box>
  )
})
