import React, { useState, useCallback } from "react"
import Box from "@mui/material/Box"
import { ContentContainer, Header, Link } from "components"
import { Button } from "components/Form"
import { ApiAssignedOperator, ApiUnassignedOperator } from "models"
import { useAssignedOperatorsListQuery } from "data/core/operator/queries"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { Typography } from "@mui/material"
import UnassignOperatorButton from "./UnassignOperatorButton"
import { useUnassignOperatorsMutation } from "data/core/operator/mutations"
import Auth from "lib/Auth"
import ConfirmationDialog from "components/ConfirmationDialog"
import usePagination from "./usePagination"
import OperatorsTable from "./OperatorsTable"
import SearchOperators, { SearchFilter } from "./SearchOperators"
import { useNavigate } from "react-router-dom"
import useBranchName from "lib/hooks/branchInfo/useBranchName"

const AssignedOperatorsList: React.FC = () => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState<SearchFilter>({})

  useFilterLocationChange({ filter })

  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false)
  const [operatorToUnassign, setOperatorToUnassign] = useState<ApiUnassignedOperator | undefined>(undefined)

  const userInfo = Auth.getUserInfo()
  const branchGuid = userInfo?.branchGuid || ""

  const { mutate: unassignOperators } = useUnassignOperatorsMutation()

  const unassignOperator = useCallback(
    (operatorGuid: string) => {
      unassignOperators({ operatorsGuid: [operatorGuid], branchGuid })
    },
    [branchGuid, unassignOperators]
  )

  const closeConfirmationDialog = useCallback(() => setShowConfirmationDialog(false), [])

  const branchTitle = useBranchName()

  const { rowsPerPage, page, onRowsPerPageChange, onPageChange } = usePagination()

  const { data } = useAssignedOperatorsListQuery({
    branchGuid,
    page: page + 1,
    pageSize: rowsPerPage,
    search: filter.search
  })

  const { count, users: operators = [] } = data || {}

  return (
    <ContentContainer>
      <ConfirmationDialog
        title="Unassign Operator"
        open={showConfirmationDialog}
        onCancel={closeConfirmationDialog}
        onConfirm={() => {
          operatorToUnassign && unassignOperator(operatorToUnassign.guid)
          closeConfirmationDialog()
        }}
      >
        <Typography variant="body1">
          {`Are you sure you want to unassign ${operatorToUnassign?.first_name} ${operatorToUnassign?.last_name} from branch ${branchTitle}?`}
        </Typography>
      </ConfirmationDialog>
      <Header title="Assigned Operators" />
      <Box display="flex" justifyContent="flex-end">
        <Link to="/admin/operators/new">
          <Button>Create a new operator</Button>
        </Link>
        <Link to="/admin/operators/assign">
          <Button sx={{ ml: 1 }}>Unassigned Operators</Button>
        </Link>
      </Box>
      <SearchOperators initialValues={filter} onClear={() => setFilter({})} onSubmit={setFilter} />
      <OperatorsTable
        operators={operators}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        additionalData={[
          {
            children: ({ operator }) => (
              <UnassignOperatorButton
                onClick={() => {
                  setShowConfirmationDialog(true)
                  setOperatorToUnassign(operator)
                }}
              />
            )
          }
        ]}
        onClick={(operator: ApiAssignedOperator) => () => {
          navigate(`/admin/operators/${operator.guid}/profile/personal-details`)
        }}
      />
    </ContentContainer>
  )
}

export default AssignedOperatorsList
