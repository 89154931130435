import { Box, Tooltip, TableRow, TableCell, TableBody, Table } from "@mui/material"
import { EmptyData } from "components"
import { Button, Checkbox, Select, TextField } from "components/Form"
import React, { memo, useCallback, useMemo } from "react"
import { Field } from "react-final-form"
import { grey } from "@mui/material/colors"
import testingId from "constants/testingId"
import { ContractModelRate, ContractModelService, RateEditorPayload } from "data/finance/contractModel/types"
import { RateEditorTableHead } from "./RateEditorTableHead"
import { FieldArray } from "react-final-form-arrays"
import { SelectOption } from "components/Form/Select"

const CheckboxField: React.FC<{ name: string; disabled?: boolean }> = ({ name, disabled = false }) => (
  <Field<boolean> type="checkbox" label="" component={Checkbox} name={`${name}.delete`} disabled={disabled} />
)

interface OwnProps {
  service: ContractModelService
  values: RateEditorPayload
  dayTypeOptions: SelectOption[]
}

export const RateEditorStandard: React.FC<OwnProps> = memo(({ service, values, dayTypeOptions }) => {
  RateEditorStandard.displayName = "RateEditorStandard"

  // types not used in enhanced rates
  const dayTypeStandardOptions = useMemo(() => {
    const usedOptions = values.enhancedRates?.map((enhancedRate) => enhancedRate.day_type_alias)
    return dayTypeOptions.filter((option) => !usedOptions.includes(String(option.value)))
  }, [values.enhancedRates, dayTypeOptions])

  const canBeDeleted = useCallback(
    (rate: ContractModelRate) => {
      if (!values.enhancedRates) return true
      return !values.enhancedRates
        .filter((er) => !er.delete)
        .map((er) => er.standard_rate_guid)
        .includes(rate.guid)
    },
    [values.enhancedRates]
  )

  return (
    <FieldArray name="basicRates">
      {({ fields }) => {
        return (
          <>
            <Table size="small">
              <RateEditorTableHead {...{ service }} />
              <TableBody>
                {fields.value?.length ? (
                  fields.map((name, index) => {
                    const rate = fields.value[index] as ContractModelRate
                    const isDeletable = canBeDeleted(rate)
                    const isToBeDeleted = rate?.delete

                    return (
                      <TableRow
                        key={index}
                        sx={{ position: "relative" }}
                        style={{
                          opacity: isToBeDeleted ? 0.3 : 1,
                          backgroundColor: isToBeDeleted ? grey[100] : "transparent"
                        }}
                      >
                        <TableCell>
                          {isDeletable ? (
                            <CheckboxField {...{ name }} />
                          ) : (
                            <Tooltip title="Can not be deleted when there are linked Enhanced rates">
                              <div>
                                <CheckboxField {...{ name, disabled: true }} />
                              </div>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell>
                          <Field
                            name={`${name}.day_type_alias`}
                            label="Day Type *"
                            component={Select}
                            options={dayTypeStandardOptions}
                          />
                        </TableCell>
                        <TableCell>
                          <Field name={`${name}.start`} component={TextField} type="time" />
                        </TableCell>
                        <TableCell>
                          <Field name={`${name}.end`} component={TextField} type="time" />
                        </TableCell>
                        <TableCell>
                          <Field name={`${name}.rate`} label="Rate (£) *" component={TextField} />
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <EmptyData message="No rates found, start by adding one." />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
              <Button
                fullWidth={false}
                data-cy={testingId.contractModel.rates.addButton}
                onClick={() => {
                  fields.push({ start: "00:00", end: "00:00", guid: `new-item-${fields.length}` })
                }}
              >
                Add new row
              </Button>
            </Box>
          </>
        )
      }}
    </FieldArray>
  )
})
