export const contractRuleConfirmedOptions = [
  { title: "Unset", value: "" },
  { title: "None", value: "None" },
  { title: "Authorised Overstay", value: "AuthorisedOverstay" },
  { title: "Authorised Understay", value: "AuthorisedUnderstay" },
  { title: "Doorstep Refusal", value: "DoorstepRefusal" }
]

export const contractRuleCancellationOptions = [
  { title: "Unset", value: "" },
  { title: "Covid Or Other Infectious Disease", value: "CovidOrOtherInfectiousDisease" },
  { title: "Entry Error", value: "EntryError" },
  { title: "Family Cover", value: "FamilyCover" },
  { title: "Holiday", value: "Holiday" },
  { title: "Hospital Admission/Stay", value: "Hospital" },
  { title: "Medical Appointment", value: "MedicalAppointment" },
  { title: "Missed Visit", value: "MissedVisit" },
  { title: "Refusal Of Care", value: "RefusalOfCare" },
  { title: "Respite", value: "Respite" }
]
