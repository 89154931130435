import { Tooltip } from "@mui/material"
import React, { memo } from "react"
import { Field } from "react-final-form"
import { Checkbox } from "."

interface OwnProps {
  isFirstContract: boolean
  contract?: { is_default: boolean }
  labelType: string
}

const Component = ({ disabled, labelType }: { disabled: boolean; labelType: string }) => {
  return (
    <Field<boolean>
      type="checkbox"
      label={`Make this contract the default for this ${labelType}`}
      component={Checkbox}
      name="is_default"
      disabled={disabled}
    />
  )
}

export const CheckboxIsDefault: React.FC<OwnProps> = memo(({ isFirstContract, contract, labelType }) => {
  CheckboxIsDefault.displayName = "CheckboxIsDefault"

  const disabled = isFirstContract || contract?.is_default

  return disabled ? (
    <Tooltip
      title={
        isFirstContract
          ? "The first contract must be always set as a default"
          : "There must be a default contract selected. Please choose another contract as a default in order to uncheck the current one"
      }
    >
      <span>
        <Component {...{ labelType, disabled: true }} />
      </span>
    </Tooltip>
  ) : (
    <Component {...{ labelType, disabled: false }} />
  )
})
