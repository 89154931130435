import { splitTimespanStringToTimeParts } from "lib/timespan"

/**
 * Return hours and minutes from period
 * @param period - 0.11:32:00.000 (d/hh/mm/ss/ms)
 * @returns  11:32 (hh:mm)
 */
export const getRateTimeFromPeriod = (period: string): string => {
  const [, hours, minutes] = splitTimespanStringToTimeParts(period)
  return `${hours}:${minutes}`
}
