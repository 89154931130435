import { DateSpan } from "components/DateSpan"
import { GUID_ZERO } from "constants/guid"
import { StateSchema } from "./types"
import React from "react"
import { Typography } from "@mui/material"
import { SelectOption } from "components/Form/Select"
import { showFormattedDatePeriod } from "lib/datetime"

export const removeUnassigned = (list?: StateSchema): StateSchema | undefined => {
  return list?.filter((item) => item.value !== GUID_ZERO)
}

// add plainTitle for AutocompleteMui
export const addDatesToSelectOptions = (list: StateSchema = []): SelectOption<React.JSX.Element>[] =>
  list.map((item) => {
    const titleWithDate = (
      <Typography>
        {item.title}
        {"  "}
        <DateSpan period={item} />
      </Typography>
    )

    return { value: item.value, title: titleWithDate, plainTitle: `${item.title} ${showFormattedDatePeriod(item)}` }
  })
