import React, { memo } from "react"
import { Field, FormSpy } from "react-final-form"
import { Select, TextField } from "components/Form"
import validators, { composeValidators } from "lib/validators"
import { INVOICE_CONTRACT_PRIVATE_ALIASES } from "constants/modelTypes"
import { FunderTypes } from "data/finance/client/types"

type Props = {
  typeAlias?: string
}

const IndividualBillingSection = () => (
  <>
    <Field
      name="finance.funder_salutation"
      label="Salutation *"
      component={Select}
      options={[
        { title: "None", value: "" },
        { title: "Mr", value: "Mr" },
        { title: "Mrs", value: "Mrs" },
        { title: "Miss", value: "Miss" },
        { title: "Ms", value: "Ms" },
        { title: "Mx", value: "Mx" },
        { title: "Dr", value: "Dr" },
        { title: "Lady", value: "Lady" },
        { title: "Master", value: "Master" },
        { title: "Lord", value: "Lord" },
        { title: "Sir", value: "Sir" },
        { title: "Dame", value: "Dame" },
        { title: "Reverend", value: "Reverend" },
        { title: "Sister", value: "Sister" }
      ]}
      validate={validators.required}
    />
    <Field
      name="finance.funder_first_name"
      label="First Name *"
      component={TextField}
      validate={composeValidators([validators.required, validators.maxLength()])}
    />
    <Field
      name="finance.funder_last_name"
      label="Last Name *"
      component={TextField}
      validate={composeValidators([validators.required, validators.maxLength()])}
    />
  </>
)

const OrganisationBillingSection = () => (
  <>
    <Field
      name="finance.funding_name"
      label="Funding Name *"
      component={TextField}
      validate={composeValidators([validators.required, validators.maxLength()])}
    />
    <Field
      name="finance.for_attention_of"
      label="For Attention Of"
      component={TextField}
      validate={validators.maxLength()}
    />
  </>
)

export const BillingInformationClientSection: React.FC<Props> = memo(({ typeAlias }) => {
  if (typeAlias && INVOICE_CONTRACT_PRIVATE_ALIASES.includes(typeAlias)) {
    return (
      <>
        <Field
          name="finance.funder_type"
          label="Funder Type *"
          component={Select}
          options={[
            { title: "Individual", value: "Individual" },
            { title: "Organisation", value: "Organisation" }
          ]}
          defaultValue={FunderTypes.INDIVIDUAL}
          validate={validators.required}
        />
        <FormSpy subscription={{ values: true }}>
          {({ values }) => {
            return values.finance?.funder_type === FunderTypes.ORGANISATION ? (
              <OrganisationBillingSection />
            ) : (
              <IndividualBillingSection />
            )
          }}
        </FormSpy>
      </>
    )
  }

  return <IndividualBillingSection />
})

BillingInformationClientSection.displayName = "BillingInformationClientSection"
