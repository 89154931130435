import { getErrorMessage } from "api/instanceHelpers"
import { create } from "zustand"
import { MessageType, UIState, UIStore } from "./types"

const storedSidebarState = window.localStorage.getItem("sidebar_open")
const sidebarOpen = storedSidebarState === null ? true : storedSidebarState === "true"

const INITIAL_STATE: UIState = {
  sidebar: {
    isDetailSidebarPresent: false,
    isOpen: sidebarOpen
  }
}

const useUIStore = create<UIStore>((set, get) => ({
  ...INITIAL_STATE,
  toggleSidebar: () => {
    set((state) => ({
      sidebar: {
        ...state.sidebar,
        isOpen: !state.sidebar.isOpen
      }
    }))
    window.localStorage.setItem("sidebar_open", get().sidebar.isOpen.toString())
  },
  setDetailSidebar: (value) =>
    set((state) => ({
      sidebar: {
        ...state.sidebar,
        isDetailSidebarPresent: value
      }
    })),
  showWarningMessage: (message, action) => {
    const warningMsg = {
      type: MessageType.Warning,
      message,
      action,
      hideDuration: 5000
    }

    set(() => ({
      message: warningMsg
    }))
  },
  showErrorMessage: (message, { action, apiError } = {}) => {
    const formattedError = getErrorMessage(apiError)
    const errorMsg = {
      type: MessageType.Error,
      message: formattedError ?? message,
      action,
      hideDuration: 5000
    }

    set(() => ({
      message: errorMsg
    }))
  },
  showSuccessMessage: (message, action) => {
    const successMsg = {
      type: MessageType.Success,
      message,
      action,
      hideDuration: 5000
    }

    set(() => ({
      message: successMsg
    }))
  }
}))

export default useUIStore
