import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { Button } from "components/Form"
import React, { Dispatch, memo, ReactNode, SetStateAction, useCallback } from "react"
import { CreditingModal } from "../../components"
import testingId from "constants/testingId"
import { SelectableIdsDictionary } from "lib/hooks"
import { ContractModelType } from "constants/modelTypes"
import DeleteInvoiceChargesModal from "pages/Finance/components/DeleteInvoiceChargesModal"
import { DateTime } from "luxon"
import { TaskPreparationCreditMode } from "data/finance/task/types"
import { InvoiceTransaction, InvoiceTransactionCharge } from "data/finance/invoiceProcessing/types"
import { CalendarPeriod } from "data/finance/contractModel/types"

interface OwnProps {
  charges: InvoiceTransactionCharge[]
  filteredCharges: InvoiceTransactionCharge[]
  transaction: InvoiceTransaction
  periodGuid: string
  onExtrasCredited: Function
  creditableChargesDictionary: SelectableIdsDictionary
  deletableChargesDictionary: SelectableIdsDictionary
  period: CalendarPeriod
  contractStart?: DateTime
  contractEnd?: DateTime | null
  refetchTransaction: () => void
  setModal: Dispatch<SetStateAction<ReactNode>>
  destroyModal: () => void
  canCreditExtras: boolean
  canEditExtras: boolean
}

export const TransactionDetailChargeActionButtons: React.FC<OwnProps> = memo(
  ({
    charges,
    filteredCharges,
    transaction,
    periodGuid,
    onExtrasCredited,
    creditableChargesDictionary,
    deletableChargesDictionary,
    period,
    refetchTransaction,
    setModal,
    destroyModal,
    canCreditExtras,
    canEditExtras
  }) => {
    // credit
    const { getSelectedAsStringArr, resetAll } = creditableChargesDictionary
    const selectedCreditIds = getSelectedAsStringArr()

    // delete
    const { getSelectedAsStringArr: getDeleteSelectedAsStringArr, resetAll: resetDeleteAll } =
      deletableChargesDictionary
    const selectedDeleteIds = getDeleteSelectedAsStringArr()

    const creditCharges = useCallback(() => {
      setModal(
        <CreditingModal
          {...{
            guids: selectedCreditIds,
            onCancel: destroyModal,
            periodGuid: periodGuid,
            transactionGuid: transaction.guid,
            credit_mode: TaskPreparationCreditMode.CHARGES,
            modelType: ContractModelType.INVOICE,
            onOk: () => {
              resetAll()
              destroyModal()
              onExtrasCredited()
              refetchTransaction()
            }
          }}
        />
      )
    }, [
      destroyModal,
      onExtrasCredited,
      periodGuid,
      refetchTransaction,
      resetAll,
      selectedCreditIds,
      transaction.guid,
      setModal
    ])

    const deleteCharges = useCallback(() => {
      const selectedChargesToDelete = filteredCharges.filter((item) => selectedDeleteIds.includes(item.guid))
      setModal(
        <DeleteInvoiceChargesModal
          {...{
            charges: selectedChargesToDelete,
            transaction,
            period,
            destroyModal,
            refetchTransaction,
            onOk: () => {
              resetDeleteAll()
              refetchTransaction()
              destroyModal()
            }
          }}
        />
      )
    }, [
      period,
      refetchTransaction,
      resetDeleteAll,
      transaction,
      filteredCharges,
      selectedDeleteIds,
      destroyModal,
      setModal
    ])

    const creditButtonDisabled = selectedCreditIds.length === 0
    const deleteButtonDisabled = selectedDeleteIds.length === 0

    return (
      <Box
        p={2}
        px={0}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={(theme) => ({
          borderColor: theme.palette.text.primary,
          borderTop: "1px solid"
        })}
      >
        <Typography
          variant="h6"
          color="primary"
          data-cy={testingId.invoiceProcessingTransactionDetail.extrasListNumOfRecords}
        >
          Showing {filteredCharges.length} out of {charges.length} records
        </Typography>
        <div>
          {canCreditExtras ? (
            <Button
              sx={(theme) => ({
                marginLeft: theme.spacing(2)
              })}
              {...{
                fullWidth: false,
                color: "primary",
                disabled: creditButtonDisabled,
                onClick: creditCharges,
                "data-cy": testingId.invoiceProcessingTransactionDetail.creditButton
              }}
            >
              Credit selected
            </Button>
          ) : null}

          {canEditExtras ? (
            <Button
              sx={(theme) => ({
                marginLeft: theme.spacing(2)
              })}
              {...{
                fullWidth: false,
                color: "primary",
                disabled: deleteButtonDisabled,

                onClick: deleteCharges,
                "data-cy": testingId.invoiceProcessingTransactionDetail.extrasListDeleteButton
              }}
            >
              Delete selected extras
            </Button>
          ) : null}
        </div>
      </Box>
    )
  }
)

TransactionDetailChargeActionButtons.displayName = "TransactionDetailChargeActionButtons"
