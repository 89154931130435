import React from "react"
import { Button, DialogTitle, DialogContent, DialogActions, Dialog, Alert, Typography, IconButton } from "@mui/material"
import { Close } from "@mui/icons-material"
import { TextField } from "components/Form"
import { Field, Form } from "react-final-form"
import { ValidationErrors } from "final-form"

export interface Props {
  open: boolean
  onSave: () => void
  onCancel: () => void
  userName: string
}

interface FormModel {
  userName: string
}

const CarerTerminationConfirmationDialog: React.FC<Props> = (props) => {
  const { open, onSave, onCancel, userName } = props

  const validate = (model: FormModel): ValidationErrors => {
    if (model.userName !== userName) {
      return { userName: "Carer's name does not match" }
    }
  }

  return (
    <Dialog maxWidth="sm" aria-labelledby="carer-termination-confirmation-dialog-title" open={open} onClose={onCancel}>
      <Form onSubmit={onSave} validate={validate}>
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle
              sx={(theme) => ({
                padding: theme.spacing(2)
              })}
            >
              <Typography variant="h6">Carer Account Termination</Typography>
              <IconButton
                aria-label="close"
                sx={(theme) => ({
                  position: "absolute",
                  right: theme.spacing(1),
                  top: theme.spacing(1),
                  color: theme.palette.grey[500]
                })}
                onClick={onCancel}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(1)
              })}
            >
              <Alert severity="error">
                Before you save the changes, take a moment to double-check the account details. Once an account is
                terminated, this action cannot be undone. In the event that the Carer intends to return to work, a new
                account will need to be created.
              </Alert>
              <Typography>To confirm this action, please type the name of the Carer:</Typography>
              <b>{userName}</b>
              <Field<string> name="userName" component={TextField} />
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={submitting || !values.userName} variant="contained">
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  )
}

export default CarerTerminationConfirmationDialog
