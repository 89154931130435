import { ApiUserCarer } from "models"
import { defaultTo, path, pipe, trim } from "ramda"

const NI_NUMBER_REGEX = new RegExp("^(?!BG|GB|KN|NK|NT|TN|ZZ)(?![DFIUQV])[A-CE-Z][A-NP-Z][0-9]{6}[A-D]$", "i")

const removeWhiteSpaces = (niNumber: string) => niNumber.replace(/\s/g, "")

export const validateNiNumber = (values: Pick<ApiUserCarer, "finance" | "carerProfileData">) => {
  const agencyProp = path(["carerProfileData", "sourcing_type"])(values)
  const isAgency = agencyProp === "agency" || agencyProp === true

  const niNumber = pipe(path<string | undefined>(["finance", "ni_number"]), defaultTo(""), trim)(values)

  if (!isAgency && !niNumber) {
    return { finance: { ni_number: "NI Number is required because the Carer is not an Agency Worker" } }
  }

  if (isAgency && niNumber) {
    return { finance: { ni_number: "NI Number must be empty for Carers assigned as Agency Worker" } }
  }

  if (!isAgency && !NI_NUMBER_REGEX.test(removeWhiteSpaces(niNumber))) {
    return { finance: { ni_number: "NI Number is incorrect. Please verify it with the Carer or HR and try again" } }
  }

  return undefined
}

export const validate = (values: ApiUserCarer) => {
  const errors = { ...(validateNiNumber(values) ?? {}) }
  return Object.keys(errors).length > 0 ? errors : undefined
}
