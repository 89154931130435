import { SelectOption } from "components/Form/Select"
import { displayWithDecimals } from "lib/number"
import React, { memo, useMemo } from "react"
import { getRateTimeFromPeriod } from "../../helpers/getRateTimeFromPeriod"
import {
  getUpliftNewEnhancedRate,
  getUpliftBasicRate,
  getUpliftEnhancedRatePrice,
  getUpliftEnhancedRateMultiplier,
  useGetUpliftDayTypeLabel
} from "../../helpers/upliftFormGetters"
import { RateUpliftFormServiceBandRate } from "../../types"
import { RateUpliftFourServiceBandRatesTable } from "./RateUpliftFourServiceBandRatesTable"
import { ContractModelEnhancedRate } from "data/finance/contractModel/types"

interface OwnProps {
  formRates: { [key: string]: RateUpliftFormServiceBandRate }
  dayTypeOptions: SelectOption[]
  enhancedRates: ContractModelEnhancedRate[]
}

export const RateUpliftFourServiceBandRates: React.FC<OwnProps> = memo(
  ({ formRates, enhancedRates, dayTypeOptions }) => {
    RateUpliftFourServiceBandRates.displayName = "RateUpliftFourServiceBandRates"

    const getDayTypeLabel = useGetUpliftDayTypeLabel(dayTypeOptions)

    const standardRateSummaryItems = useMemo(() => {
      return Object.keys(formRates).map((rateGuid) => {
        const rate = formRates[rateGuid]
        return [
          getDayTypeLabel(rate.day_type),
          getRateTimeFromPeriod(rate.start),
          getRateTimeFromPeriod(rate.end),
          displayWithDecimals(rate.rate, 2),
          displayWithDecimals(rate.new_rate, 2)
        ]
      })
    }, [formRates, getDayTypeLabel])

    const enhancedRateSummaryItems = useMemo(() => {
      const formRatesWithGuid = Object.keys(formRates).reduce(
        (result: (RateUpliftFormServiceBandRate & { guid: string })[], formRateGuid) => {
          const item = { ...formRates[formRateGuid], guid: formRateGuid }
          return [...result, item]
        },
        []
      )

      return enhancedRates.map((enhancedRate) => {
        const formRate = getUpliftBasicRate({ rates: formRatesWithGuid, enhancedRate })

        return [
          getDayTypeLabel(enhancedRate.day_type_alias),
          `${getDayTypeLabel(formRate.day_type)} (${formRate.start} - ${formRate.end})`,
          getUpliftEnhancedRateMultiplier(enhancedRate),
          getUpliftEnhancedRatePrice({ enhancedRate, standardRate: formRate }),
          getUpliftNewEnhancedRate({ formRateNewValue: formRate.new_rate, enhancedRate })
        ]
      })
    }, [enhancedRates, formRates, getDayTypeLabel])

    return (
      <>
        <RateUpliftFourServiceBandRatesTable
          {...{
            label: "Standard Rate",
            headers: ["Day Type", "Start", "Finish", "Current Fixed Price", "New Fixed Price"],
            rows: standardRateSummaryItems
          }}
        />

        {enhancedRateSummaryItems.length ? (
          <RateUpliftFourServiceBandRatesTable
            {...{
              label: "Enhanced Rate",
              headers: ["Day Type", "Standard Rate", "Modifier", "Current Fixed Price", "New Fixed Price"],
              rows: enhancedRateSummaryItems
            }}
          />
        ) : null}
      </>
    )
  }
)
