import { ShiftCategoryContractResponse } from "types/Shifts/ShiftCategories/types"
import { ListShiftCategoryContractsFilter } from "../components/ShiftCategoryContractsListFilter"

const filterShiftCategoryContracts = ({
  contracts = [],
  filter
}: {
  contracts?: ShiftCategoryContractResponse[]
  filter: ListShiftCategoryContractsFilter
}): ShiftCategoryContractResponse[] => {
  let result = [...contracts]

  if (filter.status && filter.status.length > 0) {
    result = result.filter((sc) => filter.status?.includes(sc.status))
  }

  return result
}

export default filterShiftCategoryContracts
