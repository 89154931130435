import { Box } from "@mui/material"
import React, { memo, useMemo } from "react"
import { Apartment } from "@mui/icons-material"
import { Tag } from "components/Tag"
import { ApiContractAccrual } from "data/finance/carer/types"

interface OwnProps {
  accruals?: ApiContractAccrual[]
}

export const CarerAccrualListTablePrimaryBranchTag: React.FC<OwnProps> = memo(({ accruals }) => {
  CarerAccrualListTablePrimaryBranchTag.displayName = "CarerAccrualListTablePrimaryBranchTag"

  const primaryBranch = useMemo(() => accruals?.find((accrual) => accrual.branch.is_primary)?.branch.title, [accruals])

  if (!primaryBranch) {
    return null
  }

  return (
    <Tag status="Info" tooltipText="Branch that administers all annual leave absences">
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <Apartment
          sx={(theme) => ({
            fill: theme.palette.info.main,
            marginRight: "5px"
          })}
        />
        Primary Branch: {primaryBranch}
      </Box>
    </Tag>
  )
})
