import { ContractModelType } from "constants/modelTypes"
import { StatusEnum } from "data/commonTypes"
import {
  CalendarPeriod,
  CalendarRequest,
  ContractModel,
  ContractModelService,
  ContractModelServiceBandWithRates
} from "data/finance/contractModel/types"
import { DateTime } from "luxon"

export interface ContractModelPageProps {
  contractModel: ContractModel
  modelType: ContractModelType
}

export enum RateUpliftFormPage {
  PAY_GROUP,
  CARE_TYPE,
  RATES,
  SUMMARY
}

export enum RateUpliftLevel {
  CONTRACT = "Contract",
  SERVICE = "Service",
  RATE = "Rate"
}

export enum RateUpliftTypeEnum {
  FIXED_AMOUNT = "Add",
  MANUAL = "Manual",
  PERCENTAGE = "Multiply"
}

export type RateUpliftFormServiceBandRate = {
  day_type: string
  start: string
  end: string
  rate: string
  new_rate: number
}

export type RateUpliftFormServiceBand = {
  rates?: { [key: string]: RateUpliftFormServiceBandRate }
}

export type RateUpliftFormService = {
  picked: boolean
  uplift_type?: RateUpliftTypeEnum
  uplift_value?: number
  bands?: { [key: string]: RateUpliftFormServiceBand } // exists only if rates exist
  bandItems: ContractModelServiceBandWithRates[]
}

export type RateUpliftFormModel = {
  picked: boolean
  services?: { [key: string]: RateUpliftFormService }
  serviceItems?: ContractModelServiceUplift[]
  all_services_picked?: boolean
  uplift_type?: RateUpliftTypeEnum
  uplift_value?: number
}

export type RateUpliftFormInitialValues = {
  start_date: DateTime
  modelType: ContractModelType
  models: {}
}

export type RateUpliftForm = RateUpliftFormInitialValues & {
  models: { [key: string]: RateUpliftFormModel }
  uplift_type?: RateUpliftTypeEnum
  uplift_value?: number
  all_models?: boolean
}

export type ContractModelUplift = ContractModel & { disabled?: boolean; errorMessage?: string }

export type ContractModelServiceUplift = ContractModelService & {
  isPayment?: boolean
  index?: number
  disabled?: boolean
}

export interface ContractModelUpliftHeaderProps {
  items: ContractModelUplift[]
}

export interface ContractModelUpliftContractCheckboxProps {
  contractModel: ContractModelUplift
}

export interface ContractModelUpliftRequestItem {
  guids: string[]
  level: RateUpliftLevel
  modifier: number
  modifier_type: RateUpliftTypeEnum
}

export interface ContractModelUpliftRequest {
  contract_guid: string
  effective_from: DateTime
  guid?: string
  uplifts: ContractModelUpliftRequestItem[]
}

type ContractModelUpliftItemStatus = "Pending" | "Complete" | "Failed"

export interface ContractModelUpliftItem {
  effective_from: DateTime
  errors?: string
  guid: string
  status: ContractModelUpliftItemStatus
}

export interface ContractModelFilterValues {
  status?: StatusEnum[]
  type_alias?: string[]
  search?: string
  salesforce_id?: string
}

// calendars

export interface CalendarFilter {
  status?: StatusEnum[]
}

export interface NewCalendarWithPeriodsRequest extends CalendarRequest {
  guid?: string // edit existing calendar
  modelType: ContractModelType
  periods?: CalendarPeriod[]
  periodCount: number
  isReplace?: boolean
}
