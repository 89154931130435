import React, { memo, useMemo } from "react"
import { ApiPaymentAdditionalSearch, ApprovalStatus } from "data/finance/additional/type"
import { Field } from "react-final-form"
import { Checkbox } from "components/Form"
import { Tooltip } from "@mui/material"
import { StyledTooltipText, TooltipInfo } from "components/TooltipInfo"
import { getCheckboxDisabledText } from "./helpers/getCheckboxDisabledText"
import Auth from "lib/Auth"
import { Permission } from "constants/permission"

interface OwnProps {
  additional: ApiPaymentAdditionalSearch
  pickedStatus?: ApprovalStatus
}

export const AdditionalSearchTableItemCheckbox: React.FC<OwnProps> = memo(({ additional, pickedStatus }) => {
  AdditionalSearchTableItemCheckbox.displayName = "AdditionalSearchTableItemCheckbox"

  const disabledText = useMemo(() => getCheckboxDisabledText({ additional, pickedStatus }), [additional, pickedStatus])

  const hasApproveEditPermission = Auth.hasPermission([Permission.FINANCE_PROCESSING_CHARGES_APPROVE_EDIT])
  const hasHrApproveEditPermission = Auth.hasPermission([Permission.HR_APPROVER_EDIT])

  if (!hasApproveEditPermission && additional.charge_type_alias !== "SUSP/PAY") {
    return <TooltipInfo title="Additional requires Branch approval, and no further action can be taken at this time." />
  }

  if (!hasHrApproveEditPermission && additional.charge_type_alias === "SUSP/PAY") {
    return <TooltipInfo title="Additional requires HR approval, and no further action can be taken at this time." />
  }

  return disabledText ? (
    <Tooltip title={<StyledTooltipText>{disabledText}</StyledTooltipText>}>
      <div
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
        }}
      >
        <Field<boolean>
          {...{
            type: "checkbox",
            name: `items.${additional.guid}`,
            component: Checkbox,
            disabled: true
          }}
        />
      </div>
    </Tooltip>
  ) : (
    <Field<boolean>
      {...{
        type: "checkbox",
        name: `items.${additional.guid}`,
        component: Checkbox,
        onClick: (e: React.MouseEvent) => {
          e.stopPropagation()
        }
      }}
    />
  )
})
