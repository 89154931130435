import { sortByDate } from "lib/sortByDate"
import { sort } from "ramda"
import { ListShiftCategoriesResponse } from "types/Shifts/ShiftCategories/types"
import { ListShiftCategoriesFilter } from "../components/ShiftCategoryListFilter"

const filterShiftCategories = ({
  shiftCategories,
  filter
}: {
  shiftCategories?: ListShiftCategoriesResponse[]
  filter: ListShiftCategoriesFilter
}): ListShiftCategoriesResponse[] | undefined => {
  if (!shiftCategories || !filter) return shiftCategories

  let result = shiftCategories

  if (filter.status) {
    result = result.filter((sc) => sc.status === filter.status)
  }

  if (filter.region) {
    result = result.filter((sc) => sc.regions.includes(filter.region || 0))
  }

  const orderedResult = sort(
    sortByDate(true)((sc: ListShiftCategoriesResponse) => sc.start),
    result
  )

  return orderedResult
}

export default filterShiftCategories
