import React from "react"
import { Box, Typography } from "@mui/material"
import { Room } from "@mui/icons-material"

const NoMarkersMessage = () => {
  return (
    <Box
      sx={{
        position: "relative",
        height: 0,
        width: "100%"
      }}
    >
      <Typography
        variant="body1"
        color="textSecondary"
        sx={{
          noPositionDescription: {
            display: "flex",
            position: "absolute",
            top: -150,
            justifyContent: "center",
            textWrap: "nowrap",
            width: "100%"
          }
        }}
      >
        <Room />
        &nbsp;Looks like there&apos;s no map available, please fill out the address field to display it.
      </Typography>
    </Box>
  )
}

export default NoMarkersMessage
