import React from "react"
import { UserDocuments, ContentContainer } from "components"
import { ApiUserClient, UserType } from "models"
import { ClientHeader } from "./components/ClientHeader"
import { getOptionsFromSchema } from "lib/schema"
import { useCoreSchemasStore } from "stores"

interface OwnProps {
  client: ApiUserClient
}

const ClientDocuments: React.FC<OwnProps> = ({ client }) => {
  const { BusinessPatientFile } = useCoreSchemasStore((state) => state.schema.models)

  const categoryOptions = getOptionsFromSchema(BusinessPatientFile, "category_guid")

  return (
    <ContentContainer>
      <ClientHeader client={client} />
      <UserDocuments userType={UserType.Patient} user={client.guid} categoryOptions={categoryOptions} />
    </ContentContainer>
  )
}

export default ClientDocuments
