import { Box, Grid } from "@mui/material"
import { AutocompleteMui, FormSpyCustom, Select, TextField } from "components/Form"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { ContractModelType } from "constants/modelTypes"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"
import { ClearFilterButton } from "components/ClearFilterButton"
import { FilterValues, formatFilterValues } from "lib/helpers/formatFilterValues"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"
import { Pagination } from "lib/types"
import { useSchema } from "data/schema/useSchema"
import { DateField } from "components/Form/DateField"

export interface AlertsFilterValues {
  modelType: ContractModelType
  from: string
  to?: string
  confirmed?: string
  alert_type_alias?: string[]
  user_name?: string
  models?: string[]
  regions?: string[]
  source_type?: string
}

interface OwnProps {
  filter: AlertsFilterValues
  setFilter: Dispatch<SetStateAction<AlertsFilterValues>>
  pagination: Pagination
  resetPagination: () => void
  defaultFilterValues: { from: string; to: string; modelType: ContractModelType }
  isInvoiceModel: boolean
}

export const AlertFilter: React.FC<OwnProps> = memo(
  ({ filter, setFilter, pagination, resetPagination, defaultFilterValues, isInvoiceModel }) => {
    AlertFilter.displayName = "AlertFilter"

    const { Region, InvoiceContract, PaymentContract, TransactionAlert } = useSchema([
      "Region",
      "InvoiceContract",
      "PaymentContract",
      "TransactionAlert"
    ])

    const debouncedOnSubmit = useDebouncedCallback((values: FilterValues<AlertsFilterValues>) => {
      const formattedValues = formatFilterValues<AlertsFilterValues>({ values, startName: "from", endName: "to" })

      setFilter(formattedValues)
      resetPagination()
    }, 500)

    const clearFilter = () => {
      setFilter({
        ...defaultFilterValues,
        modelType: isInvoiceModel ? ContractModelType.INVOICE : ContractModelType.PAYMENT
      })
      resetPagination()
    }

    useFilterLocationChange({ filter, pagination })

    return (
      <Form
        onSubmit={debouncedOnSubmit}
        initialValues={filter}
        validate={validateFilterPeriodDates({ start: "from", end: "to" })}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={2}>
                <DateField name="from" label="Show event dates from" required />
              </Grid>
              <Grid item md={2}>
                <DateField name="to" label="To" />
              </Grid>
              <Grid item md={2}>
                <Field
                  name="confirmed"
                  label="Status"
                  component={Select}
                  options={[
                    { title: "All", value: "" },
                    { title: "Conf only", value: "true" },
                    { title: "Unconf only", value: "false" }
                  ]}
                />
              </Grid>
              <Grid item md={2}>
                <Field
                  name="source_type"
                  label="Event Type"
                  component={Select}
                  options={[
                    { title: "None", value: "None" },
                    { title: "Visit", value: "Visit" },
                    { title: "Training", value: "Training" },
                    { title: "TimeOff", value: "TimeOff" },
                    { title: "Output", value: "Output" },
                    { title: "Contribution", value: "Contribution" },
                    { title: "Shift", value: "Shift" },
                    { title: "StandingCharge", value: "StandingCharge" },
                    { title: "MinimumWage", value: "MinimumWage" },
                    { title: "Accrual", value: "Accrual" },
                    { title: "Absence", value: "Absence" }
                  ]}
                />
              </Grid>
              <Grid item md={4}>
                <Field
                  name="alert_type_alias"
                  label="Alert Type"
                  component={AutocompleteMui}
                  multiselect
                  options={TransactionAlert}
                />
              </Grid>
              <Grid item md={4}>
                <Field
                  name="models"
                  label={isInvoiceModel ? "Contract models" : "Pay models"}
                  component={AutocompleteMui}
                  multiselect
                  options={isInvoiceModel ? InvoiceContract : PaymentContract}
                />
              </Grid>
              <Grid item md={4}>
                <Field name="user_name" label="Client/Carer name" component={TextField} />
              </Grid>
              <Grid item md={4}>
                <Field name="regions" label="Regions" component={AutocompleteMui} multiselect options={Region} />
              </Grid>
              <Grid item md={12}>
                <Box justifyContent="flex-end" display="flex">
                  <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    )
  }
)
