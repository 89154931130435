import React, { memo } from "react"
import { FormSectionHeader, Select, TextField } from "components/Form"
import Grid from "@mui/material/Grid"
import { Field } from "react-final-form"
import testingId from "constants/testingId"
import validators, { composeUnrequiredValidators, composeValidators } from "lib/validators"
import { useSchema } from "data/schema/useSchema"
import { SelectDummy } from "components/Form/SelectDummy"

export const ContractModelInvoiceSettings: React.FC = memo(() => {
  ContractModelInvoiceSettings.displayName = "ContractModelInvoiceSettings"

  const { ContractFinanceAddress, ContractFinanceName, ContractFinanceOutput } = useSchema([
    "ContractFinanceAddress",
    "ContractFinanceName",
    "ContractFinanceOutput"
  ])

  return (
    <>
      <FormSectionHeader title="Invoice settings" data-cy={testingId.contractModelSettings.invoiceSettings} />
      <Grid container spacing={8}>
        <Grid item md={6}>
          {ContractFinanceOutput ? (
            <Field
              name="finance.output_type_alias"
              label="Invoice Format *"
              component={Select}
              defaultValue="LA"
              options={ContractFinanceOutput}
            />
          ) : (
            <SelectDummy value="loading..." />
          )}
          {ContractFinanceAddress ? (
            <Field
              name="finance.address_type_alias"
              label="Client Address *"
              component={Select}
              defaultValue="FULL"
              options={ContractFinanceAddress}
            />
          ) : (
            <SelectDummy value="loading..." />
          )}

          <Field
            name="finance.term_days"
            label="Billing Term Days *"
            component={TextField}
            validate={composeValidators([validators.required, validators.minValue(1), validators.maxValue(356)])}
          />
        </Grid>
        <Grid item md={6}>
          {ContractFinanceName ? (
            <Field
              name="finance.name_type_alias"
              label="Client Name *"
              component={Select}
              defaultValue="FULLNAME"
              options={ContractFinanceName}
              validate={validators.required}
            />
          ) : (
            <SelectDummy value="loading..." />
          )}
          <Field
            name="finance.email"
            label="Email Address"
            component={TextField}
            validate={composeUnrequiredValidators([validators.email, validators.maxLength()])}
          />
        </Grid>
      </Grid>
    </>
  )
})
