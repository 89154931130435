import React, { memo, useEffect } from "react"
import { Box } from "@mui/material"
import { ModalWrapper } from "components"
import { Button, FormSectionHeader } from "components/Form"
import { Form } from "react-final-form"
import testingId from "constants/testingId"
import { SummaryList } from "components/SummaryList"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { usePaymentTaskPostMutation, usePaymentTaskPutMutation } from "data/finance/paymentTask/mutations"
import { TaskPreparationMode } from "data/finance/task/types"

type Props = {
  guids: string[]
  onCancel: Function
  onOk: Function
}

export const PayrollModal: React.FC<Props> = memo(({ guids, onCancel, onOk }) => {
  const { mutateAsync: prepareTask, data: preparationData } = usePaymentTaskPostMutation()
  const { mutateAsync: processTask, isSuccess } = usePaymentTaskPutMutation()

  useEffect(() => {
    prepareTask({ guids, mode: TaskPreparationMode.COMPLETION })
  }, [prepareTask, guids])

  useEffect(() => {
    if (isSuccess) onOk()
  }, [isSuccess, onOk])

  const onSubmit = async () => {
    if (preparationData?.task_guid) {
      processTask({ task_guid: preparationData.task_guid, is_draft: false, mode: TaskPreparationMode.COMPLETION })
    }
  }

  return (
    <ModalWrapper title="Payroll settings" destroyModal={onCancel} width={700}>
      <RequestLoaderWrapper>
        {preparationData?.contracts?.length ? (
          <>
            <FormSectionHeader title="Selection summary" />
            <SummaryList
              items={preparationData.contracts}
              headers={["Pay Model", "Employees", "Appointments", "£ Total value"]}
              totalLabel="Total Employees:"
            />
          </>
        ) : null}
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                <Button variant="text" fullWidth={false} onClick={() => onCancel()} sx={{ mr: 2 }}>
                  CANCEL
                </Button>
                <Button
                  type="submit"
                  fullWidth={false}
                  disabled={submitting || !preparationData?.task_guid}
                  data-cy={testingId.button}
                >
                  PROCESS PAYROLL
                </Button>
              </Box>
            </form>
          )}
        </Form>
      </RequestLoaderWrapper>
    </ModalWrapper>
  )
})

PayrollModal.displayName = "PayrollModal"
