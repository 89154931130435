import React, { memo, ReactNode, useCallback, useMemo, useState } from "react"
import Box from "@mui/material/Box"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import { ContentContainer, Header, Divider, Paginator } from "components"
import { TransactionGroupFilter, defaultFilterValues } from "./components/TransactionGroupFilter"
import { usePagination } from "components/handlers/usePagination"
import { TransactionGroupTableHeader } from "./components/TransactionGroupTableHeader"
import TransactionGroupTableBody from "./components/TransactionGroupTableBody"
import { useSelectableIdsDictionary } from "lib/hooks"
import { Form } from "react-final-form"
import TransactionListActionButtons from "./components/TransactionListActionButtons"
import TransactionListSelectButtons from "./components/TransactionListSelectButtons"
import TransactionGroupInvoicingModal from "./components/TransactionGroupInvoicingModal"
import TransactionGroupCreditingModal from "./components/TransactionGroupCreditingModal"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { queryStringToObject } from "lib/queryString"
import Auth from "lib/Auth"
import { Permission } from "constants/permission"
import { useInvoiceProcessingTransactionGroupListQuery } from "data/finance/invoiceProcessing/queries"
import { useLocation } from "react-router-dom"
import { InvoiceTransactionGroup } from "data/finance/invoiceProcessing/types"
import { InvoiceTransactionGroupFilter } from "./types"

const TransactionGroupList: React.FC = () => {
  const location = useLocation()

  const { pagination, setPagination, resetPagination } = usePagination()

  const [filter, setFilter] = useState<InvoiceTransactionGroupFilter>({
    ...defaultFilterValues,
    ...queryStringToObject<InvoiceTransactionGroupFilter>(location.search)
  })

  const { data: transactionGroups = [], refetch: refetchTransactionGroups } =
    useInvoiceProcessingTransactionGroupListQuery()

  const { selectableIdsForCrediting, selectableIdsForInvoicing, allSelectableIds } = useMemo(() => {
    const result = {
      selectableIdsForCrediting: [] as string[],
      selectableIdsForInvoicing: [] as string[],
      allSelectableIds: [] as string[]
    }

    transactionGroups.forEach((tg: InvoiceTransactionGroup) => {
      if (tg.is_creditable || tg.is_processable) {
        result.allSelectableIds.push(tg.guid)
        if (tg.is_creditable) {
          result.selectableIdsForCrediting.push(tg.guid)
        }
        if (tg.is_processable) {
          result.selectableIdsForInvoicing.push(tg.guid)
        }
      }
    })

    return result
  }, [transactionGroups])

  const { toggleId, toggleSubset, selectableIds, getSelectedAsStringArr } = useSelectableIdsDictionary(allSelectableIds)

  const [modal, setModal] = useState<ReactNode>(null)

  const refetchForRewind = () => {
    refetchTransactionGroups()
  }

  const destroyModal = useCallback(() => {
    refetchTransactionGroups()
    setModal(null)
  }, [setModal, refetchTransactionGroups])

  const submitForInvoicing = useCallback(() => {
    setModal(<TransactionGroupInvoicingModal {...{ guids: getSelectedAsStringArr(), destroyModal }} />)
  }, [destroyModal, getSelectedAsStringArr])

  const submitForCrediting = useCallback(() => {
    setModal(
      <TransactionGroupCreditingModal
        {...{
          guids: getSelectedAsStringArr(),
          destroyModal
        }}
      />
    )
  }, [destroyModal, getSelectedAsStringArr])

  const hasRewindPermission = Auth.hasPermission([Permission.FINANCE_REWIND_EDIT])

  const hasCompletedTransactionGroups = transactionGroups.some((t) => !!t.completed_at)
  const hasAlertingTransactionGroup = transactionGroups.some((t) => t.is_alerting)

  return (
    <>
      <ContentContainer>
        <Header title="Invoice processing" />
        <Box my={3}>
          <TransactionGroupFilter {...{ pagination, resetPagination, setFilter, filter }} />
        </Box>
        <Divider color="divider" />
        <RequestLoaderWrapper>
          <Form onSubmit={() => ({})}>
            {({ handleSubmit }) => {
              return (
                <form {...{ onSubmit: handleSubmit }}>
                  <TableContainer>
                    <Table stickyHeader>
                      <TransactionGroupTableHeader
                        {...{
                          setFilter,
                          hasCompletedTransactionGroups,
                          hasAlertingTransactionGroup,
                          hasRewindPermission
                        }}
                      />
                      <TransactionGroupTableBody
                        {...{
                          transactionGroups,
                          toggleId,
                          selectableIds,
                          hasCompletedTransactionGroups,
                          hasAlertingTransactionGroup,
                          hasRewindPermission,
                          refetch: refetchForRewind,
                          selectedRows: getSelectedAsStringArr()
                        }}
                      />
                    </Table>
                    {!transactionGroups.length && +pagination.page === 1 ? null : (
                      <Paginator {...{ setPagination, itemsLength: transactionGroups.length }} />
                    )}
                  </TableContainer>
                  {transactionGroups.length ? (
                    <>
                      <TransactionListSelectButtons
                        {...{ toggleSubset, selectableIdsForCrediting, selectableIdsForInvoicing }}
                      />
                      <TransactionListActionButtons
                        {...{
                          selectableIdsForCrediting,
                          selectableIdsForInvoicing,
                          selectedIds: getSelectedAsStringArr(),
                          submitForCrediting,
                          submitForInvoicing
                        }}
                      />
                    </>
                  ) : null}
                </form>
              )
            }}
          </Form>
        </RequestLoaderWrapper>
      </ContentContainer>
      {modal}
    </>
  )
}

export default memo(TransactionGroupList)
