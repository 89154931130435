import { FinanceApi } from "api"
import { useMutation, UseMutationResult } from "react-query"
import { InvoiceFileDownloadPostResponse } from "./types"
import { useNavigate } from "react-router-dom"
import { useUIStore } from "stores"
import { BusinessDownloadMode, InterfaceFileMode } from "pages/Finance/FileManagement/types"

interface InvoiceFileDownloadPostMutationParams {
  download_mode: BusinessDownloadMode
  transaction_guids: string[]
}

interface InvoiceFileInterfacePostMutationParams {
  download_mode: InterfaceFileMode
  transaction_guids: string[]
}

export const useInvoiceFileDownloadPostMutation = (): UseMutationResult<
  InvoiceFileDownloadPostResponse,
  Error,
  InvoiceFileDownloadPostMutationParams
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  return useMutation(
    async ({
      download_mode,
      transaction_guids
    }: InvoiceFileDownloadPostMutationParams): Promise<InvoiceFileDownloadPostResponse> => {
      return FinanceApi.post("/finance/invoice-files/download", { download_mode, transaction_guids })
    },
    {
      onSuccess: async (data) => {
        navigate(`/download?job_guid=${data.job_guid}`)
        showSuccessMessage("Download request sent.")
      }
    }
  )
}

export const useInvoiceFileInterfacePostMutation = (): UseMutationResult<
  InvoiceFileDownloadPostResponse,
  Error,
  InvoiceFileInterfacePostMutationParams
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  return useMutation(
    async ({
      download_mode,
      transaction_guids
    }: InvoiceFileInterfacePostMutationParams): Promise<InvoiceFileDownloadPostResponse> => {
      return FinanceApi.post(`/finance/invoice-files/interfaces?type=${download_mode}`, transaction_guids)
    },
    {
      onSuccess: async (data) => {
        navigate(`/download?job_guid=${data.job_guid}`)
        showSuccessMessage("Download request sent.")
      }
    }
  )
}
