import { Header } from "components"
import testingId from "constants/testingId"
import React, { memo } from "react"
import { ContractModel, CalendarPeriod } from "data/finance/contractModel/types"
import dateTimeUtils from "lib/datetime"

interface Props {
  contract: ContractModel
  period: CalendarPeriod
}

const TransactionListHeader: React.FC<Props> = ({ contract, period }) => {
  return (
    <Header
      title={`${contract.title} (${dateTimeUtils.formatDate(period.start)} - ${dateTimeUtils.formatDate(period.end)})`}
      data-cy={testingId.invoiceProcessingTransactionList.header}
    />
  )
}

export default memo(TransactionListHeader)
