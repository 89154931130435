import Auth from "lib/Auth"
import { useQuery } from "react-query"

/**
 * Set configurations on global window environmentVariables by legacy reasons
 */
const setConfigsOnGlobalWindow = (configs: Record<string, string>) => {
  ;(window as any)["environmentVariables"] = {
    ...(window as any)["environmentVariables"],
    ...configs
  }
}

/**
 * Fetch configurations
 */
const fetchConfig = async () => {
  try {
    const response = await fetch("/config.json", {
      headers: {
        Authorization: `Bearer ${Auth.getAccessToken()}`
      }
    })
    const configs = await response.json()
    setConfigsOnGlobalWindow(configs)
    return configs
  } catch (error) {
    console.error("Error fetching config:", error)
  }
}

/**
 * Gets configs using react-query
 */
export const useConfigurationsQuery = () => {
  return useQuery({
    queryKey: "configs",
    queryFn: fetchConfig
  })
}
