import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { FormSpyCustom, Select, TextField } from "components/Form"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"
import { SelectableIdsDictionary } from "lib/hooks"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { TransactionDetailType } from "pages/Finance/types"
import { ClearFilterButton } from "components/ClearFilterButton"
import { useSchema } from "data/schema/useSchema"
import { InvoiceTransactionItemsFilter } from "../types"

export const defaultItemListFilter: InvoiceTransactionItemsFilter = {
  tab: TransactionDetailType.VISIT
}

interface Props extends Pick<SelectableIdsDictionary, "resetAll"> {
  showStatusFilter: boolean
  itemFilter: InvoiceTransactionItemsFilter
  setItemFilter: Dispatch<SetStateAction<InvoiceTransactionItemsFilter>>
}

export const TransactionDetailItemsFilter: React.FC<Props> = memo(
  ({ showStatusFilter, resetAll, itemFilter, setItemFilter }) => {
    const { ContractServiceType } = useSchema(["ContractServiceType"])

    const onSubmit = useDebouncedCallback((values: InvoiceTransactionItemsFilter) => {
      resetAll()
      setItemFilter({ ...defaultItemListFilter, ...values })
    }, 300)

    useFilterLocationChange({ filter: itemFilter })

    return (
      <Form {...{ onSubmit, initialValues: itemFilter }}>
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={4}>
                <Field name="reference_owner_name" label="Search by carer name" component={TextField} />
              </Grid>
              <Grid item md={4}>
                <Field
                  name="service_type_guid"
                  label="Care types"
                  component={Select}
                  options={ContractServiceType}
                  multiselect
                />
              </Grid>
              {showStatusFilter && (
                <Grid item md={4}>
                  <Field
                    name="visit_status"
                    component={Select}
                    options={[
                      { title: "Any", value: "" },
                      { title: "Confirmed", value: true },
                      { title: "Unconfirmed", value: false }
                    ]}
                    label="Visit status"
                  />
                </Grid>
              )}
              <Grid item md={showStatusFilter ? 12 : 4}>
                <Box textAlign="right">
                  <ClearFilterButton
                    onClick={() => {
                      form.reset()
                      setItemFilter(defaultItemListFilter)
                    }}
                    disabled={Object.keys(itemFilter).length === 0}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    )
  }
)

TransactionDetailItemsFilter.displayName = "TransactionDetailItemsFilter"
