import { Box, IconButton, Typography } from "@mui/material"
import React, { Dispatch, memo, SetStateAction } from "react"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { DateTime } from "luxon"
import testingId from "constants/testingId"

interface DayFilterProps {
  setDay: Dispatch<SetStateAction<DateTime>>
  day: DateTime
}

const DayFilter = ({ setDay, day }: DayFilterProps) => {
  return (
    <Box display="flex" m={2} mx={0}>
      <IconButton
        aria-label="previous-day"
        size="small"
        onClick={() => setDay(day.minus({ days: 1 }))}
        data-cy={testingId.dayFilterLeftArrow}
      >
        <ChevronLeftIcon />
      </IconButton>
      <Typography variant="h5" display="inline">
        {day.toFormat("EEE dd LLLL yyyy")}
      </Typography>
      <IconButton
        aria-label="next-day"
        size="small"
        onClick={() => setDay(day.plus({ days: 1 }))}
        data-cy={testingId.dayFilterRightArrow}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  )
}

export default memo(DayFilter)
