import React from "react"
import { Box, List, Typography, Tooltip } from "@mui/material"
import { Folder } from "@mui/icons-material"
import { NotFound } from "./index"
import AdminOperators from "pages/Admin/Operators"
import AdminContractServiceTypes from "pages/Admin/ContractServiceTypes"
import AdminHolidays from "pages/Admin/Holidays"
import AdminHolidayTiers from "pages/Admin/HolidayTiers"
import AdminContractRuleConditions from "pages/Admin/ContractRuleConditions"
import Auth from "lib/Auth"
import { ContentContainer, DetailSidebar, NavItem } from "components"
import { WithPermission } from "components/Auth"
import PermissionDenied from "app/PermissionDenied"
import MicroFrontend from "components/MicroFrontend"
import Config from "lib/config"
import { Navigate, Route } from "react-router-dom"
import AppLoading from "app/AppLoading"
import RunList from "./Admin/Runs/RunList"
import { useAuthStore, useUIStore } from "stores"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { BranchFeatures } from "lib/hooks/branchInfo/types"
import SentryRoutes from "components/Routes"

const Admin = () => {
  const isSidebarOpen = useUIStore((state) => state.sidebar.isOpen)
  const { hasBranchPermission, isFetching } = useBranchPermissions()
  const isFinanceEnabled = hasBranchPermission(BranchFeatures.FINANCE)
  const userHasFinanceAdminPermission =
    isFinanceEnabled &&
    Auth.hasPermission([
      "Finance.Settings.Holiday:Edit",
      "Finance.Settings.ServiceType:Edit",
      "Finance.Settings.Condition:Edit",
      "Finance.Settings.Run:Edit"
    ])

  const loggedUser = useAuthStore((state) => state.user)

  const companyHostUrl = Config.getEnvVariable("APP_WEB_COMPANY_URL")
  const financeHostUrl = Config.getEnvVariable("APP_WEB_FINANCE_URL")

  const userHasUserAdminPermission = Auth.hasPermission(["User.Operator:Edit"])
  const userHasCompanyPermission = Auth.hasPermission(["Company:Read", "Company.Branch:Read"])

  if (isFetching) {
    return <AppLoading />
  }

  if (!userHasUserAdminPermission && !userHasFinanceAdminPermission && !userHasCompanyPermission) {
    return <PermissionDenied />
  }

  return (
    <>
      <DetailSidebar>
        <Box m={10} mx={0}></Box>
        {userHasUserAdminPermission && (
          <>
            <Tooltip title="Users" placement="right">
              <Typography
                sx={(theme) => ({
                  margin: theme.spacing(2, 2, 0, 2)
                })}
                variant="h6"
              >
                {isSidebarOpen ? "Users" : "Us"}
              </Typography>
            </Tooltip>
            <List component="nav">
              <NavItem fullMatch to={"operators"} label="Operators" icon={<Folder color="primary" />} />
            </List>
            <List component="nav">
              <NavItem
                fullMatch
                to={"operators/assign"}
                label="Unassigned Operators"
                icon={<Folder color="primary" />}
              />
            </List>
          </>
        )}
        {userHasFinanceAdminPermission && (
          <>
            <Tooltip title="Finance" placement="right">
              <Typography
                variant="h6"
                sx={(theme) => ({
                  margin: theme.spacing(2, 2, 0, 2)
                })}
              >
                {isSidebarOpen ? "Finance" : "Fi"}
              </Typography>
            </Tooltip>
            <List component="nav">
              <WithPermission permissions={["Finance.Settings.Holiday:Edit"]}>
                <>
                  <NavItem to={"holidays"} label="Holidays" icon={<Folder color="primary" />} />
                  <NavItem to={"finance/holiday-tiers"} label="Holiday tiers" icon={<Folder color="primary" />} />
                </>
              </WithPermission>
              <WithPermission permissions={["Finance.Settings.ServiceType:Edit"]}>
                <NavItem to={"finance/contract-service-types"} label="Care types" icon={<Folder color="primary" />} />
              </WithPermission>
              <WithPermission permissions={["Finance.Settings.Condition:Edit"]}>
                <NavItem
                  to={"finance/contract-rule-conditions"}
                  label="Rule conditions"
                  icon={<Folder color="primary" />}
                />
              </WithPermission>
              <WithPermission permissions={["Finance.Settings.Run:Edit"]}>
                <NavItem to={"finance/runs"} label="Runs" icon={<Folder color="primary" />} />
              </WithPermission>
            </List>
          </>
        )}
        {userHasCompanyPermission && (
          <>
            <Tooltip title="Organisation Settings" placement="right">
              <Typography
                sx={(theme) => ({
                  margin: theme.spacing(2, 2, 0, 2)
                })}
                variant="h6"
              >
                {isSidebarOpen ? "Organisation Settings" : "OS"}
              </Typography>
            </Tooltip>
            <List component="nav">
              <NavItem
                to={Auth.hasPermission(["Company:Read"]) ? "company/companies" : "company/branch"}
                label={Auth.hasPermission(["Company:Read"]) ? "Company Settings" : "Branch Settings"}
                icon={<Folder color="primary" />}
              />
            </List>
          </>
        )}
      </DetailSidebar>
      <ContentContainer
        sx={(theme) => ({
          padding: theme.spacing(0, 0)
        })}
      >
        <SentryRoutes>
          {userHasUserAdminPermission && (
            <>
              <Route path="/operators/*" element={<AdminOperators />} />
              <Route path="/" element={<Navigate to="/admin/operators" replace />} />
            </>
          )}
          {userHasFinanceAdminPermission && (
            <>
              <Route path="/holidays/*" element={<AdminHolidays />} />
              <Route
                path="/finance/holidays/*"
                element={<MicroFrontend name="Finance" host={financeHostUrl} loggedUser={loggedUser} />}
              />
              <Route path="/finance/holiday-tiers/*" element={<AdminHolidayTiers />} />
              <Route path="/finance/contract-service-types/*" element={<AdminContractServiceTypes />} />
              <Route path="/finance/contract-rule-conditions/*" element={<AdminContractRuleConditions />} />
              <Route path="/finance/runs" element={<RunList />} />
              <Route path="/" element={<Navigate to="/admin/holidays" replace />} />
            </>
          )}
          {userHasCompanyPermission && (
            <>
              <Route path="/company/*" element={<MicroFrontend name="Company" host={companyHostUrl} />} />
              <Route path="/" element={<Navigate to="/admin/company" replace />} />
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </SentryRoutes>
      </ContentContainer>
    </>
  )
}
export default React.memo(Admin)
