import { DateTime } from "luxon"
import { isNil } from "ramda"

/**
 * Generic function to order a list of objects using date properties
 * @param asc true if its ascending order
 * @param (object: T) => Date Function which specify the object (T) and the type of property (Date) to be used
 */
export const sortByDate =
  (asc: boolean) =>
  <T>(getDateProperty: (object: T) => DateTime) =>
  (objectA: T, objectB: T): number => {
    const dateA = getDateProperty(objectA)
    const dateB = getDateProperty(objectB)

    if (isNil(dateA) || isNil(dateB) || !dateA.isValid || !dateB.isValid) return -1

    const timeA = dateA.toMillis()
    const timeB = dateB.toMillis()

    if (timeA === timeB) {
      return 0
    }
    if (asc) {
      return timeB < timeA ? 1 : -1
    } else {
      return timeB > timeA ? 1 : -1
    }
  }
