import React, { memo } from "react"
import { FormRenderProps } from "react-final-form"
import { BandEditor } from "./BandEditor"
import ServiceCreatePartialForm from "./ServiceCreatePartialForm"
import ServiceDetailForm from "./ServiceDetailForm"
import { EmptyData } from "components"
import { FORM_ERROR } from "final-form"
import { red } from "@mui/material/colors"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import {
  ContractModelService,
  ContractModelServiceBand,
  ContractModelServiceRequestWithBands
} from "data/finance/contractModel/types"
import { FormStage } from "constants/form"
import { useSchema } from "data/schema/useSchema"
import { removeUnassigned } from "data/schema/helpers"

interface OwnProps {
  service?: ContractModelService
  formStage: FormStage
  formRenderProps: FormRenderProps<ContractModelServiceRequestWithBands>
  bands?: ContractModelServiceBand[]
  isInvoicePaymentService?: boolean
}

const ServiceForm: React.FC<OwnProps> = ({ service, bands, formStage, formRenderProps, isInvoicePaymentService }) => {
  const { PaymentContract } = useSchema([isInvoicePaymentService ? "PaymentContract" : ""])
  const paymentModelOptions = removeUnassigned(PaymentContract)

  if (isInvoicePaymentService && !paymentModelOptions?.length) {
    return <EmptyData message="There are no Payment models currently present in the system. Start by adding one." />
  }

  return (
    <>
      {formStage === FormStage.CREATE && (
        <ServiceCreatePartialForm {...{ paymentModelOptions, isInvoicePaymentService }} />
      )}
      {formStage === FormStage.EDIT && (
        <>
          <ServiceDetailForm
            {...{
              paymentModelOptions,
              noEndDate: formRenderProps.values.no_end_date,
              isInvoicePaymentService
            }}
          />

          {!formRenderProps.pristine && formRenderProps.errors !== undefined && formRenderProps.errors[FORM_ERROR] && (
            <Box m={2} mx={0} p={2} bgcolor={red[50]}>
              <Typography>{formRenderProps.errors[FORM_ERROR]}</Typography>
            </Box>
          )}
          {service && bands?.length ? <BandEditor {...{ service }} /> : null}
        </>
      )}
    </>
  )
}

export default memo(ServiceForm)
