import React, { memo } from "react"
import { ListItemButton, MenuItem } from "@mui/material"
import { Link, LinkMenu } from "components"
import Auth from "lib/Auth"

const items = [
  {
    label: "Sick Reports",
    id: "mainnav-sick-reports",
    to: "/reports/sick-reports",
    external: false,
    conditions: ["Hr.Carer:Read", "Hr.Carer:Edit"]
  },
  {
    label: "Reporting Launch Pad",
    id: "mainnav-reporting-launch-pad",
    to: "https://ceranet.ceracare.co.uk/home/cera-tech-reporting-launch-pad",
    external: true
  }
]

export const MainNavReports: React.FC = memo(() => {
  return (
    <ListItemButton sx={{ p: 0 }}>
      <LinkMenu title="Reports">
        {items
          .filter((item) => !item.conditions || Auth.hasPermission(item.conditions))
          .map(({ to, id, label, external }) => (
            <MenuItem sx={{ padding: 0 }} key={to}>
              <Link
                propagate
                sx={(theme) => ({ width: "100%", display: "block", px: theme.spacing(2), py: theme.spacing(1) })}
                to={to}
                id={id}
                external={external}
              >
                {label}
              </Link>
            </MenuItem>
          ))}
      </LinkMenu>
    </ListItemButton>
  )
})

MainNavReports.displayName = "MainNavReports"
