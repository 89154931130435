import { PaymentModelRate, PaymentModelRateRequest, ContractModel } from "data/finance/contractModel/types"
import { FORM_ERROR, ValidationErrors } from "final-form"
import dateTimeUtils, { DateTimeFormat } from "lib/datetime"
import { isEmpty } from "ramda"

export const validatePaymentBaseRate =
  ({ contractModel, rates }: { contractModel: ContractModel; rates?: PaymentModelRate[] }) =>
  (values: PaymentModelRateRequest): ValidationErrors | undefined => {
    let errors: ValidationErrors = {}

    // check with contract model
    if (values.start && values.start < contractModel.start) {
      errors.start = `Can not start before contract model starts (${dateTimeUtils.formatDate(contractModel.start)})`
    }
    if (contractModel.end && (!values.end || values.end > contractModel.end)) {
      errors.end = `Can not end after contract model ends (${dateTimeUtils.formatDate(contractModel.end)})`
    }

    // compare start & end
    if (values.start && values.end && values.start.toMillis() >= values.end.toMillis()) {
      errors = {
        start: "Must start before end",
        end: "Must start before end"
      }
    }
    if (!isEmpty(errors)) return errors

    // check with other rates
    // REPLACE "with existing"
    const filteredRates = rates?.filter((rate) => rate.guid !== values.guid)

    const overlappedRate = filteredRates?.find((rate) => {
      const rateStart = rate.start.toMillis()
      const valueStart = values.start?.toMillis()

      const sameStart = rateStart === valueStart
      const overlapsLeft = rateStart < valueStart && (!rate.end || rate.end.toMillis() > valueStart)
      const overlapsRight = rateStart >= valueStart && (!values.end || values.end.toMillis() > rateStart)

      return overlapsLeft || sameStart || overlapsRight
    })

    if (overlappedRate) {
      return {
        [FORM_ERROR]: `Can not overlap with existing Base Rate (${overlappedRate.start.toFormat(
          DateTimeFormat.DATE
        )} - ${overlappedRate.end ? overlappedRate.end.toFormat(DateTimeFormat.DATE) : "--/--/--"})`,
        start: " ",
        end: " "
      }
    }
    return undefined
  }
