import React from "react"
import { Field } from "react-final-form"
import { Select } from "."
import { StatusOptions } from "constants/selectOptions"

interface OwnProps {
  name?: string
  label?: string
  multiselect?: boolean
  withAny?: boolean
}

export const StatusSelectField = ({ name = "status", label = "Status", withAny, ...rest }: OwnProps) => {
  const options = withAny ? [{ title: "Any", value: "" }, ...StatusOptions] : StatusOptions

  return <Field {...rest} name={name} label={label} component={Select} options={options} />
}
