import { Box, styled, Tooltip } from "@mui/material"
import React, { memo, useState } from "react"
import { ApiPaymentAdditionalSearch } from "data/finance/additional/type"
import { usePaymentAdditionalApprovalQuery } from "data/finance/additional/queries"
import { AdditionalSearchTableItemStatusIcon } from "./AdditionalSearchTableItemStatusIcon"
import { AdditionalSearchTableItemStatusTooltipContent } from "./AdditionalSearchTableItemStatusTooltipContent"

const StyledIconBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  fontSize: 12,
  lineHeight: "15px"
})

export const AdditionalSearchTableItemStatus: React.FC<{ additional: ApiPaymentAdditionalSearch }> = memo(
  ({ additional }) => {
    AdditionalSearchTableItemStatus.displayName = "AdditionalSearchTableItemStatus"

    const [isEnabled, setIsEnabled] = useState(false)
    const handleEnabledChange = () => {
      setIsEnabled(true)
    }

    const { data: approvals } = usePaymentAdditionalApprovalQuery({
      additionalGuid: additional.guid,
      status: additional.status,
      enabled: isEnabled
    })

    return (
      <Tooltip title={!approvals?.length ? "" : <AdditionalSearchTableItemStatusTooltipContent {...{ approvals }} />}>
        <StyledIconBox {...{ onMouseEnter: handleEnabledChange }}>
          <AdditionalSearchTableItemStatusIcon {...{ additional }} />
        </StyledIconBox>
      </Tooltip>
    )
  }
)
