import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React, { PropsWithChildren, memo } from "react"
import testingId from "constants/testingId"
import { TooltipInfo } from "components/TooltipInfo"
import { Box, styled } from "@mui/material"

const Styledbox = styled(Box)({
  display: "flex"
})

const Cell = ({ children }: PropsWithChildren) => (
  <TableCell data-cy={testingId.tableHeaderCell} align="left">
    {children}
  </TableCell>
)

export const AccrualPolicyListHeader: React.FC = memo(() => {
  return (
    <TableHead>
      <TableRow data-cy={testingId.tableHeaderRow}>
        <Cell>Type</Cell>
        <Cell>Starts on</Cell>
        <Cell>
          <Styledbox>
            Ends before{" "}
            <TooltipInfo
              ml={5}
              title="The period ends one day before the “Ends before” date. For example, Ends before 01 Apr means the period ends on 31 Mar."
            />
          </Styledbox>
        </Cell>
        <Cell>Accrual Cap</Cell>
        <Cell>
          <Styledbox>
            Rollover Cap{" "}
            <TooltipInfo
              ml={5}
              title="Unused Carer accrual hours from the last set weeks of the holiday period can be carried over to the next."
            />
          </Styledbox>
        </Cell>
        <Cell>
          <Styledbox>
            Rollover Expiry{" "}
            <TooltipInfo
              ml={5}
              title="Number of days at the start of the next holiday period to use the rollover hours. If not used within these days the hours will be lost."
            />
          </Styledbox>
        </Cell>
        <Cell>Status</Cell>
      </TableRow>
    </TableHead>
  )
})

AccrualPolicyListHeader.displayName = "AccrualPolicyListHeader"
