import React, { memo } from "react"
import { red, grey, green } from "@mui/material/colors"
import testingId from "constants/testingId"
import { Box } from "@mui/material"

export enum DotSizeVariant {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large"
}
export enum DotColorVariant {
  RED = "red",
  GREEN = "green",
  WHITE = "white"
}

const dotRingSizeOffset = 10
const dotContainerSizeOffset = 5

const getDotSize = (sizeVariant: DotSizeVariant): { height: number; width: number } => {
  switch (sizeVariant) {
    case DotSizeVariant.SMALL:
      return {
        height: 10,
        width: 10
      }
    case DotSizeVariant.LARGE:
      return {
        height: 20,
        width: 20
      }
    default:
      return {
        height: 15,
        width: 15
      }
  }
}

const getDotColor = (colorVariant: DotColorVariant): string => {
  switch (colorVariant) {
    case DotColorVariant.RED:
      return red[500]
    case DotColorVariant.WHITE:
      return "white"
    default:
      return green[400]
  }
}
const getDotRingColor = (colorVariant: DotColorVariant): string => {
  switch (colorVariant) {
    case DotColorVariant.RED:
      return red[500]
    case DotColorVariant.WHITE:
      return grey[500]
    default:
      return green[400]
  }
}

interface StatusDotProps {
  sizeVariant: DotSizeVariant
  colorVariant: DotColorVariant
  pulsating: boolean
}

const StatusDot: React.FC<StatusDotProps> = ({ sizeVariant, colorVariant, pulsating }) => {
  return (
    <Box
      sx={() => {
        const { height: dotHeight, width: dotWidth } = getDotSize(sizeVariant)
        return {
          "@keyframes pulsate": {
            "0%": { transform: "scale(0.1, 0.1)", opacity: 0 },
            "50%": { opacity: 1.0 },
            "100%": { transform: " scale(1.2, 1.2)", opacity: 0 }
          },
          position: "relative",
          height: dotHeight + dotContainerSizeOffset,
          width: dotWidth + dotContainerSizeOffset,
          marginRight: "5px"
        }
      }}
    >
      <Box
        sx={() => {
          const { height: dotHeight, width: dotWidth } = getDotSize(sizeVariant)
          return {
            border: `3px solid ${getDotRingColor(colorVariant)}`,
            borderRadius: 30,
            width: dotWidth + dotRingSizeOffset,
            height: dotHeight + dotRingSizeOffset,
            position: "absolute",
            left: 0,
            top: 0,
            opacity: 0,
            ...(pulsating
              ? {
                  animation: `$pulsate 1s ease-out`,
                  "animation-iteration-count": "infinite"
                }
              : {})
          }
        }}
      />
      <Box
        sx={() => {
          const { height, width } = getDotSize(sizeVariant)
          return {
            width,
            height,
            backgroundColor: getDotColor(colorVariant),
            borderRadius: "50%",
            border: colorVariant === DotColorVariant.WHITE ? `1px solid ${grey[500]}` : "none",
            position: "absolute",
            top: 5,
            left: 5
          }
        }}
        data-cy={testingId.syncStatus.statusDot}
      />
    </Box>
  )
}

export default memo(StatusDot)
