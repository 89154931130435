import React, { memo } from "react"
import { Drawer, Box } from "@mui/material"
import * as UIConfig from "constants/ui"
import { MainNavLogo } from "./MainNavLogo"
import { MainNavToggleButton } from "./MainNavToggleButton"
import { MainNavListBottom } from "./MainNavListBottom"
import { MainNavListTop } from "./MainNavListTop"
import { MainNavBranch } from "./MainNavBranch"
import type { BranchesListResponse } from "data/core/operator/queries"
import { useUIStore } from "stores"

interface Props {
  branches?: BranchesListResponse[]
  hasIntercomFlag: boolean
}

export const MainNav: React.FC<Props> = memo(({ branches, hasIntercomFlag }) => {
  MainNav.displayName = "MainNav"

  const isSidebarOpen = useUIStore((state) => state.sidebar.isOpen)

  return (
    <Drawer
      open
      variant="persistent"
      PaperProps={{
        sx: (theme) => ({
          width: isSidebarOpen ? UIConfig.MAIN_NAV_WIDTH : UIConfig.MAIN_NAV_COLLAPSED_WIDTH,
          backgroundColor: theme.palette.primary.main
        })
      }}
    >
      <MainNavToggleButton isSidebarOpen={isSidebarOpen} />

      {isSidebarOpen && (
        <Box>
          <MainNavLogo />
          <MainNavBranch branches={branches} />
          <Box
            sx={(theme) => ({
              justifyContent: "space-between",
              margin: theme.spacing(3, 2),
              color: theme.palette.primary.contrastText
            })}
          >
            <MainNavListTop hasIntercomFlag={hasIntercomFlag} />
            <MainNavListBottom />
          </Box>
        </Box>
      )}
    </Drawer>
  )
})
