import React, { useCallback } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import DeleteIcon from "@mui/icons-material/Delete"
import testingId from "constants/testingId"
import { ShiftCategoryContractResponse } from "types/Shifts/ShiftCategories/types"
import { SelectableIdsDictionary } from "lib/hooks"
import { isUndefined } from "lodash"
import { Form } from "react-final-form"
import RowSelectionCheckbox from "components/RowSelectionCheckbox"
import { StickyBox } from "components"
import { Button } from "components/Form"
import { useDeleteShiftCategoryContractHandler } from "../helpers/useDeleteShiftCategoryContractHandler"
import { useMapContractsAsTableRows } from "helpers/contractHelpers"

const tableHeaders = [
  <DeleteIcon key={`delete-icon`} />,
  "Contract ID",
  "Model",
  "Type",
  "Starts on",
  "Ends before",
  "Status"
]

interface Props {
  shiftCategoryGuid: string
  items: ShiftCategoryContractResponse[]
  selectableContracts: SelectableIdsDictionary
}

// TODO: add pagination, unite contract lists
const ShiftCategoryContractsTable: React.FC<Props> = ({ shiftCategoryGuid, items, selectableContracts }) => {
  const { selectableIds, getSelectedAsStringArr, toggleId } = selectableContracts

  const selectedContracts = getSelectedAsStringArr()

  const createCheckbox = useCallback(
    ({ name, id }: { name: string; id: string }) => {
      return <RowSelectionCheckbox {...{ selectableIds, name, toggleId, id }} />
    },
    [selectableIds, toggleId]
  )

  const renderDeleteCheckbox = (id: string) =>
    isUndefined(selectableIds[id]) ? null : createCheckbox({ name: `itemDelete[${id}]`, id: id })

  const deleteContracts = useDeleteShiftCategoryContractHandler()

  const onDelete = async () => {
    await deleteContracts({ shiftCategoryGuid, selectedContracts })
  }

  const rows = useMapContractsAsTableRows(items)

  return (
    <Form onSubmit={() => undefined}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Table>
            <TableHead>
              <TableRow data-cy={testingId.tableHeaderRow}>
                {tableHeaders.map((label, index) => (
                  <TableCell
                    key={index}
                    align="left"
                    data-cy={testingId.tableHeaderCell}
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600
                    }}
                  >
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody data-cy={testingId.shifts.category.list}>
              {rows.map((contract) => {
                return (
                  <TableRow
                    key={contract.guid}
                    data-cy={testingId.shifts.category.row}
                    hover
                    sx={{ cursor: "pointer" }}
                    onClick={() => contract.navigate()}
                  >
                    <TableCell data-cy={testingId.shifts.category.cell}>
                      {renderDeleteCheckbox(contract.guid)}
                    </TableCell>

                    {contract.data.map((item, index) => (
                      <TableCell key={`${contract.guid}-${index}`} data-cy={testingId.shifts.category.cell}>
                        {item}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <StickyBox>
            <Button
              type="submit"
              fullWidth={false}
              onClick={() => onDelete()}
              disabled={selectedContracts.length === 0}
            >
              Delete
            </Button>
          </StickyBox>
        </form>
      )}
    </Form>
  )
}

export default React.memo(ShiftCategoryContractsTable)
