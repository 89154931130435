import React, { memo, useCallback } from "react"
import { Box, Typography } from "@mui/material"
import { isNil, last } from "ramda"
import { ContentContainer, EmptyData } from "components"
import Grid from "@mui/material/Grid"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableContainer from "@mui/material/TableContainer"
import { ApiBusinessSyncStatus, ApiSync } from "data/core/branch/types"
import { DateTime, Duration } from "luxon"
import { getTimeDurationString } from "lib/datetime"
import { red } from "@mui/material/colors"
import StatusDotContainer from "pages/SyncStatus/components/StatusDotContainer"
import testingId from "constants/testingId"
import { sortByDate } from "lib/sortByDate"
import SyncRefreshStatusTimer from "pages/SyncStatus/components/StatusRefreshTimer"
import { useBranchStatusQuery } from "data/core/branch/queries"

const tableHeaders = ["Branch", "Last Sync", "Frequency", ""]

interface LastSync {
  isAlerting: boolean
  timeDiffString: string
}

interface ParsedBranch {
  title: string | null
  syncEnabled: boolean
  lastSync: LastSync
  frequency: string
  syncs: ApiSync[] | null
  provider: string
}

interface ParsedBranchesDictionary {
  [key: string]: ParsedBranch
}

const sortSyncsByStartDateAsc = (syncs: ApiSync[]): ApiSync[] => {
  const sortFunction = sortByDate(true)((sync: ApiSync) => sync.started_at)
  return syncs.slice().sort(sortFunction)
}

const getLastSync = (syncs: ApiSync[] | null): LastSync => {
  if (isNil(syncs) || syncs.length === 0) {
    return { isAlerting: false, timeDiffString: "N/A" }
  }
  const lastSyncEndTimestamp = last(syncs)?.ended_at
  const lastSyncStartTimestamp = last(syncs)?.started_at
  const now = DateTime.local()
  if (!isNil(lastSyncEndTimestamp)) {
    const duration = now.diff(lastSyncEndTimestamp, ["days", "hours", "minutes"])
    return {
      isAlerting: duration.as("minutes") >= 60,
      timeDiffString: getTimeDurationString({ duration })
    }
  }
  if (!isNil(lastSyncStartTimestamp)) {
    const duration = now.diff(lastSyncStartTimestamp, ["days", "hours", "minutes"])
    if (duration.as("minutes") >= 60) {
      return { isAlerting: true, timeDiffString: `Started ${getTimeDurationString({ duration })}` }
    }
  }
  return { isAlerting: false, timeDiffString: "In progress" }
}

const getFrequency = (syncs: ApiSync[] | null) => {
  if (isNil(syncs) || syncs.length <= 1) {
    return "N/A"
  }
  const count = syncs.length
  const firstStartDateTime = syncs[0].started_at.toMillis()
  const lastStartDateTime = syncs[syncs.length - 1].started_at.toMillis()
  const frequencyInMilis = (lastStartDateTime - firstStartDateTime) / count
  const duration = Duration.fromObject({ milliseconds: frequencyInMilis }).shiftTo("days", "hours", "minutes")
  return getTimeDurationString({ duration, fromNow: false })
}

const parseBranchSyncs = (data: ApiBusinessSyncStatus) => {
  return data.branches.reduce((result: Record<string, ParsedBranch>, branch) => {
    const sortedSyncsByDateTimeAsc = branch.syncs ? sortSyncsByStartDateAsc(branch.syncs) : []
    result[branch.guid] = {
      title: branch.title,
      lastSync: getLastSync(sortedSyncsByDateTimeAsc),
      frequency: getFrequency(sortedSyncsByDateTimeAsc),
      syncs: sortedSyncsByDateTimeAsc,
      syncEnabled: branch.sync,
      provider: branch.provider
    }
    return result
  }, {})
}

const getLastSyncValue = (branch: ParsedBranch): string => {
  if (!branch.syncEnabled) {
    return "Sync is disabled for this branch."
  }
  return branch.lastSync.timeDiffString
}

const SyncStatus: React.FC = () => {
  const { data, refetch } = useBranchStatusQuery()

  const getParsedBranchSyncs = useCallback(() => {
    if (!isNil(data)) {
      return parseBranchSyncs(data)
    }
    return null
  }, [data])
  const parsedBranchSyncs: ParsedBranchesDictionary | null = getParsedBranchSyncs()
  return (
    <ContentContainer data-cy={testingId.syncStatus.screen}>
      <Typography variant={"h4"} data-cy={testingId.syncStatus.title}>
        Sync Status - Care Planner
      </Typography>
      <Box m={3} mx={0}>
        <SyncRefreshStatusTimer callback={refetch} />
      </Box>
      {!isNil(parsedBranchSyncs) ? (
        <Box m={3} mx={0}>
          <Grid container spacing={3}>
            <Grid item md={10}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow data-cy={testingId.tableHeaderRow}>
                      {tableHeaders.map((th) => (
                        <TableCell
                          key={th}
                          sx={{
                            fontSize: "16px",
                            fontWeight: 600
                          }}
                          data-cy={testingId.tableHeaderCell}
                        >
                          {th}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(parsedBranchSyncs).map(([guid, branch]) => (
                      <TableRow key={guid} data-cy={testingId.tableRow}>
                        <TableCell data-cy={testingId.tableCell}>{branch.title}</TableCell>
                        <TableCell
                          data-cy={testingId.tableCell}
                          sx={
                            branch.lastSync.isAlerting
                              ? {
                                  color: red[500]
                                }
                              : undefined
                          }
                        >
                          {getLastSyncValue(branch)}
                        </TableCell>
                        <TableCell data-cy={testingId.tableCell}>{branch.frequency}</TableCell>
                        <TableCell data-cy={testingId.tableCell}>
                          <StatusDotContainer syncs={branch.syncs} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <EmptyData message="No syncs to be displayed." />
      )}
    </ContentContainer>
  )
}
export default memo(SyncStatus)
