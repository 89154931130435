import React from "react"
import { Grid } from "@mui/material"
import { Field } from "react-final-form"
import { FormSectionHeader, Select, TextField } from "components/Form"
import validators from "lib/validators"

interface Props {
  withBio?: boolean
}

const ProfileSectionPersonalDetails = ({ withBio = false }: Props) => {
  return (
    <>
      <FormSectionHeader title="Personal details" />
      <Grid container spacing={8}>
        <Grid item md={6}>
          <Field
            name="profile.salutation"
            label="Salutation"
            component={Select}
            options={[
              { title: "Mr", value: "Mr" },
              { title: "Mrs", value: "Mrs" },
              { title: "Miss", value: "Miss" },
              { title: "Ms", value: "Ms" },
              { title: "Mx", value: "Mx" },
              { title: "Dr", value: "Dr" },
              { title: "Lady", value: "Lady" },
              { title: "Master", value: "Master" },
              { title: "Lord", value: "Lord" },
              { title: "Sir", value: "Sir" },
              { title: "Dame", value: "Dame" },
              { title: "Reverend", value: "Reverend" },
              { title: "Sister", value: "Sister" }
            ]}
          />
          <Field
            name="profile.first_name"
            label="First name *"
            component={TextField}
            validate={validators.required}
            // validate={composeValidators([validators.required, validators.name])}
          />
          <Field
            name="profile.last_name"
            label="Last name *"
            component={TextField}
            validate={validators.required}
            // validate={composeValidators([validators.required, validators.name])}
          />
          {withBio ? (
            <Field
              name="profile.bio"
              label="Bio"
              component={TextField}
              multiline
              minRows={5}
              validate={validators.maxLength(1000)}
            />
          ) : null}
        </Grid>
        <Grid item md={6}>
          <Field
            name="profile.middle_name"
            label="Middle name"
            component={TextField}
            // validate={validators.name}
          />
          <Field
            name="profile.short_name"
            label="Short name *"
            component={TextField}
            validate={validators.required}
            // validate={composeValidators([validators.required, validators.name])}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ProfileSectionPersonalDetails
