import { ContractModelService } from "data/finance/contractModel/types"
import { ServiceFilter } from "../components/ServiceListFilter"

export const filterContractServices = (
  items: ContractModelService[],
  filter?: ServiceFilter
): ContractModelService[] => {
  if (!filter) return items

  const { status, service_type, payment_contract_guid } = filter
  let result = items

  if (status?.length) {
    result = result.filter((i) => status.includes(i.status))
  }

  if (service_type && service_type.length) {
    result = result.filter((i) => service_type.includes(i.service_type.guid))
  }

  if (payment_contract_guid && payment_contract_guid.length) {
    result = result.filter((i) => payment_contract_guid.includes(i.payment_contract_guid as string))
  }

  return result
}
