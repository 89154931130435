import { usePaymentModelRateDeleteMutation } from "data/finance/contractModel/mutations"
import { PaymentModelRate } from "data/finance/contractModel/types"
import { RefetchQuery } from "data/types"
import { useCallback } from "react"
import { useApiMessage } from "stores"

export const useBaseRateDeleteHandler = ({
  selectedIds,
  contractModelGuid,
  refetch
}: {
  selectedIds: string[]
  contractModelGuid: string
  refetch: RefetchQuery<PaymentModelRate[]>
}): (() => Promise<void>) => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()
  const { mutateAsync: deleteRate } = usePaymentModelRateDeleteMutation()

  return useCallback(async () => {
    if (!selectedIds.length) return

    try {
      await Promise.all(
        selectedIds.map((rateGuid) => {
          return deleteRate({ modelGuid: contractModelGuid, rateGuid })
        })
      )
      refetch()
      showSuccessMessage("Base Rates successfully deleted.")
    } catch (apiError: any) {
      showErrorMessage("Something went wrong, please try again.", { apiError })
    }
  }, [showSuccessMessage, refetch, contractModelGuid, deleteRate, selectedIds, showErrorMessage])
}
