import React, { ReactNode, useEffect } from "react"
import { Drawer } from "@mui/material"
import * as UIConfig from "../constants/ui"
import { useUIStore } from "stores"
import { useShallow } from "zustand/react/shallow"

const DetailSidebar = ({ children }: { children?: ReactNode }) => {
  const { isSidebarOpen, setDetailSidebar } = useUIStore(
    useShallow((state) => ({
      setDetailSidebar: state.setDetailSidebar,
      isSidebarOpen: state.sidebar.isOpen
    }))
  )

  useEffect(() => {
    setDetailSidebar(true)
    return function cleanup() {
      setDetailSidebar(false)
    }
  }, [setDetailSidebar])

  return (
    <Drawer
      open={isSidebarOpen}
      variant="permanent"
      sx={(theme) => ({
        flexShrink: 0,
        whiteSpace: "nowrap",
        left: isSidebarOpen ? UIConfig.MAIN_NAV_WIDTH : UIConfig.MAIN_NAV_COLLAPSED_WIDTH,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: isSidebarOpen ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen
        }),
        width: isSidebarOpen ? UIConfig.SECONDARY_SIDEBAR_OPEN_WIDTH : UIConfig.SECONDARY_SIDEBAR_CLOSED_WIDTH,
        overflowX: "hidden"
      })}
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: "primary.tint",
          "& a": {
            color: theme.palette.text.primary,
            textDecoration: "none"
          },
          left: isSidebarOpen ? UIConfig.MAIN_NAV_WIDTH : UIConfig.MAIN_NAV_COLLAPSED_WIDTH,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: isSidebarOpen
              ? theme.transitions.duration.enteringScreen
              : theme.transitions.duration.leavingScreen
          }),
          width: isSidebarOpen ? UIConfig.SECONDARY_SIDEBAR_OPEN_WIDTH : UIConfig.SECONDARY_SIDEBAR_CLOSED_WIDTH,
          overflowX: "hidden"
        })
      }}
    >
      {children}
    </Drawer>
  )
}

export default DetailSidebar
