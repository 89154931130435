import React, { memo } from "react"
import { Field } from "react-final-form"
import { Select, Checkbox, TextField } from "components/Form"
import Grid from "@mui/material/Grid"
import { Box } from "@mui/material"
import { useSchema } from "data/schema/useSchema"
import validators, { composeUnrequiredValidators, composeValidators } from "lib/validators"
import { SelectDummy } from "components/Form/SelectDummy"

export const ContractModelFormPaymentFields: React.FC = memo(() => {
  const { PaymentRun } = useSchema(["PaymentRun"])

  const runField = () => {
    return PaymentRun ? (
      <Field
        name="run_guid"
        label="Payment Run"
        component={Select}
        options={[{ title: "Unset", value: "" }, ...PaymentRun]}
      />
    ) : (
      <SelectDummy value="loading..." />
    )
  }

  const nfcField = () => <Field name="require_nfc" label="Require NFC" component={Checkbox} type="checkbox" />

  const costCentreField = () => (
    <Field
      name="info.cost_centre"
      label="Cost centre *"
      component={TextField}
      validate={composeValidators([validators.required, validators.maxLength(16)])}
    />
  )

  const shiftsEnabled = (disabled: boolean) => (
    <Field name="shifts_enabled" label="Shifts Enabled" component={Checkbox} type="checkbox" disabled={disabled} />
  )

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        {nfcField()}
        {shiftsEnabled(false)}
      </Box>
      <Grid container spacing={8}>
        <Grid item md={6}>
          <Field
            name="info.contact_name"
            label="Contact name"
            component={TextField}
            validate={validators.maxLength()}
          />
          <Field
            name="info.contact_email"
            label="Contact email"
            component={TextField}
            validate={composeUnrequiredValidators([validators.email, validators.maxLength()])}
          />
        </Grid>
        <Grid item md={6}>
          {costCentreField()}
          {runField()}
        </Grid>
      </Grid>
    </Box>
  )
})

ContractModelFormPaymentFields.displayName = "ContractModelFormPaymentFields"
