import React from "react"
import { Box, Typography } from "@mui/material"
import { Link } from "components"
import Container from "./Container"

const PasswordResetRequestSuccess = () => {
  return (
    <Container title="Email sent">
      <Box sx={{ flex: 1 }}>
        <Typography textAlign="left" color="textSecondary" mb={4}>
          Please check your inbox and follow the instructions provided.
        </Typography>
        <Link to="/password-reset">No email? You can re-enter your email and try again.</Link>
      </Box>
    </Container>
  )
}
export default PasswordResetRequestSuccess
