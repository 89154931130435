import React from "react"
import { Header, Link } from "components"
import { Box } from "@mui/material"

export interface StepsTitleProps {
  navigateTo: string
  title: string
  section: string
  action: string
  "data-cy"?: string
}

export const StepsTitle: React.FC<StepsTitleProps> = ({ navigateTo, title, section, action, "data-cy": dataCy }) => (
  <Box
    sx={(theme) => ({
      margin: theme.spacing(3, 0, 4, 0)
    })}
  >
    <Header
      title={
        <>
          <Link to={navigateTo}>{title}</Link>: {`${section} / ${action}`}
        </>
      }
      data-cy={dataCy}
    />
  </Box>
)
