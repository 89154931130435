import { ContractModelService, ContractModelServiceFormProps, ContractModel } from "data/finance/contractModel/types"
import { DateTimeFormat } from "lib/datetime"
import { validatePeriodDates, validatePeriodInParent, validatePeriodWithSiblings } from "lib/dateTimeValidators"
import { FormValidation } from "lib/types"
import { isEmpty } from "ramda"

export const validateCopiedService = ({
  values,
  services,
  contractModel,
  originalService
}: {
  values: ContractModelServiceFormProps
  services?: ContractModelService[]
  contractModel: ContractModel
  originalService: ContractModelService
}): FormValidation => {
  if (!services?.length) return undefined

  const periodDateErrors = validatePeriodDates(values)
  if (!isEmpty(periodDateErrors)) return periodDateErrors

  // compare dates to contract model
  const contractDateErrors = validatePeriodInParent({
    values,
    parent: contractModel,
    itemName: "Service",
    parentName: "Contract model"
  })
  if (!isEmpty(contractDateErrors)) return contractDateErrors

  // compare dates to other services
  const canNotOverlapWithOriginalService = !!originalService.end

  const filteredServices = services.filter((service) => {
    const isSameServiceType = service.service_type.guid === values.type_guid
    const samePaymentContract = values.payment_contract_guid
      ? values.payment_contract_guid === service.payment_contract_guid
      : true
    const isOriginalService = service.guid === originalService.guid
    return isSameServiceType && samePaymentContract && (!isOriginalService || canNotOverlapWithOriginalService)
  })

  if (!filteredServices.length || !values.start) return undefined

  const siblingErrors = validatePeriodWithSiblings({ values, items: filteredServices, label: "Service" })
  if (!isEmpty(siblingErrors)) return siblingErrors

  // compare to original service
  if (!canNotOverlapWithOriginalService && values.start.toMillis() <= originalService.start.toMillis()) {
    return { start: `Must start after the original Service (${originalService.start.toFormat(DateTimeFormat.DATE)})` }
  }
}
