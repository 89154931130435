import { Box, Tooltip } from "@mui/material"
import { green, orange, red } from "@mui/material/colors"
import { CheckCircle, HourglassEmpty, Warning } from "@mui/icons-material"
import { ContractModelType } from "constants/modelTypes"
import React, { Dispatch, memo, ReactNode, SetStateAction } from "react"
import { ContractModel } from "data/finance/contractModel/types"
import { ContractModelTableItemUpliftStatusModal } from "./ContractModelTableItemUpliftStatusModal"
import { ContractModelUpliftItem } from "../types"

interface Props {
  contractModel: ContractModel
  setModal: Dispatch<SetStateAction<ReactNode>>
  modelType: ContractModelType
}

export const ContractModelTableItemUpliftStatus: React.FC<Props> = memo(({ contractModel, setModal, modelType }) => {
  ContractModelTableItemUpliftStatus.displayName = "ContractModelTableItemUpliftStatus"

  const destroyModal = () => setModal(null)

  if (!contractModel.uplifts?.length) return null

  const sortedUplifts = [...contractModel.uplifts].sort(
    (a: ContractModelUpliftItem, b: ContractModelUpliftItem) =>
      b.effective_from.toMillis() - a.effective_from.toMillis()
  )

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    setModal(
      <ContractModelTableItemUpliftStatusModal
        {...{ uplifts: sortedUplifts, destroyModal, contractModel, modelType }}
      />
    )
  }

  const lastUplift = sortedUplifts[0]

  const getStatus = () => {
    if (lastUplift.status === "Pending")
      return {
        icon: (
          <HourglassEmpty
            sx={{
              color: orange[400]
            }}
          />
        ),
        status: "pending"
      }
    if (lastUplift.status === "Failed")
      return {
        icon: (
          <Warning
            sx={{
              color: red[400]
            }}
          />
        ),
        status: "failed"
      }
    return {
      icon: (
        <CheckCircle
          sx={{
            color: green[400]
          }}
        />
      ),
      status: "completed"
    }
  }

  return (
    <Box
      sx={{
        display: "inline-block",
        paddingLeft: 5,
        paddingRight: 5,
        cursor: "pointer"
      }}
      onClick={onClick}
    >
      <Tooltip title={`Uplift ${getStatus().status}`}>{getStatus().icon}</Tooltip>
    </Box>
  )
})
