import { ContainerSteps } from "components/Steps"
import { FormStage } from "constants/form"
import testingId from "constants/testingId"
import React, { memo } from "react"
import { ContractModel } from "data/finance/contractModel/types"

interface OwnProps {
  userProfile: { name?: string; first_name?: string; last_name?: string }
  contract?: { contract_model: string }
  selectedContractModel?: ContractModel
  formStage?: FormStage
  navigateTo?: string
}

export const ContractDetailHeader: React.FC<OwnProps> = memo(
  ({ userProfile, contract, selectedContractModel, formStage, navigateTo = "../profile/personal-details" }) => {
    ContractDetailHeader.displayName = "ContractDetailHeader"

    const title = userProfile.name || `${userProfile.first_name} ${userProfile.last_name}`

    const action = contract
      ? contract.contract_model
      : (formStage === 2 && selectedContractModel?.title) || "new contract"

    return (
      <ContainerSteps.StepsTitle
        {...{ navigateTo, title, action, section: "Contracts", "data-cy": testingId.contract.title }}
      />
    )
  }
)
