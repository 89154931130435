import { useMutation } from "react-query"
import type { RequestPasswordResetRequest } from "api/core"
import coreApi from "api"

const useMutationPasswordResetRequest = () => {
  return useMutation({
    mutationKey: ["passwor-reset-request"],
    mutationFn: (data: RequestPasswordResetRequest) => coreApi.passwordResetRequest(data)
  })
}

export default useMutationPasswordResetRequest
