import { useCallback } from "react"
import { useClientContractDeleteMutation } from "./mutations"
import { useQueryClient } from "react-query"
import { queryKeys } from "./queries"
import { useApiMessage } from "stores"

interface OwnProps {
  clientGuid?: string
  contractGuids: string[]
  onOk: () => void
}

export const useClientContractListDeleteHandler = ({
  clientGuid,
  contractGuids,
  onOk
}: OwnProps): (() => Promise<void>) => {
  const { mutateAsync: deleteCharge } = useClientContractDeleteMutation({ clientGuid })
  const queryCache = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useCallback(async () => {
    if (!clientGuid || !contractGuids) return

    try {
      await Promise.all(
        contractGuids.map((contractGuid) => {
          return deleteCharge({ contractGuid })
        })
      )
      showSuccessMessage("Contracts successfully deleted.")
      onOk()
      queryCache.invalidateQueries(queryKeys.getClientContractsKey(clientGuid))
    } catch (apiError: any) {
      showErrorMessage("Something went wrong, please try again.", { apiError })
    }
  }, [clientGuid, contractGuids, deleteCharge, showSuccessMessage, onOk, queryCache, showErrorMessage])
}
