import { ContractModelType } from "constants/modelTypes"
import { useModelRateUpliftMutation } from "data/finance/contractModel/mutations"
import { filter } from "ramda"
import { useCallback } from "react"
import { isModelWithRates, isServicesWithRates } from "../helpers/upliftFormGetters"
import {
  ContractModelUpliftRequestItem,
  RateUpliftForm,
  RateUpliftFormPage,
  RateUpliftFormService,
  RateUpliftLevel,
  RateUpliftTypeEnum
} from "../types"
import { useApiMessage } from "stores"
import { useNavigate } from "react-router-dom"
import { getContractModelUrl } from "data/finance/contractModel/helpers"
import { ApiError } from "stores/useUIStore/types"

const upliftServiceAsManualRateArray = (service: RateUpliftFormService) =>
  Object.values(service.bands || {}).reduce((bandResult: ContractModelUpliftRequestItem[], band) => {
    if (!band.rates) return bandResult

    const upliftBandRates = Object.keys(band.rates).reduce((rateResult: ContractModelUpliftRequestItem[], rateGuid) => {
      if (!band.rates) return rateResult

      const upliftRate = {
        level: RateUpliftLevel.RATE,
        modifier_type: RateUpliftTypeEnum.MANUAL,
        modifier: Number(band.rates[rateGuid].new_rate),
        guids: [rateGuid]
      } as ContractModelUpliftRequestItem

      return [...rateResult, upliftRate]
    }, [])

    return [...bandResult, ...upliftBandRates]
  }, [])

const reduceUpliftModelServicesForPayload = (
  services: Record<string, RateUpliftFormService>
): ContractModelUpliftRequestItem[] =>
  Object.keys(services).reduce((result: ContractModelUpliftRequestItem[], serviceGuid) => {
    const service = services[serviceGuid]

    // map through all bands and all rates
    if (service.uplift_type === RateUpliftTypeEnum.MANUAL) {
      const upliftRates = upliftServiceAsManualRateArray(service)
      return [...result, ...upliftRates]
    }

    const serviceModifier =
      service.uplift_type === RateUpliftTypeEnum.PERCENTAGE
        ? 1 + Number(service.uplift_value) / 100
        : Number(service.uplift_value)

    // uplift for service
    const upliftService = {
      level: RateUpliftLevel.SERVICE,
      modifier_type: service.uplift_type,
      modifier: serviceModifier,
      guids: [serviceGuid]
    } as ContractModelUpliftRequestItem

    return [...result, upliftService]
  }, [])

interface OwnProps {
  page: RateUpliftFormPage
  setPage: React.Dispatch<React.SetStateAction<RateUpliftFormPage>>
  modelType: ContractModelType
}

export const useRateUpliftSubmitHandler = ({
  page,
  setPage,
  modelType
}: OwnProps): { handleUpliftSubmit: (values: RateUpliftForm) => void; isSubmitting: boolean } => {
  const { showErrorMessage, showSuccessMessage } = useApiMessage()
  const navigate = useNavigate()

  const onSuccess = useCallback(() => {
    showSuccessMessage("Uplift successfully queued for processing.")
    navigate(`/finance/contract-models/${getContractModelUrl(modelType)}`)
  }, [modelType, navigate, showSuccessMessage])

  const onError = useCallback(
    (apiError: ApiError) => {
      showErrorMessage("Something went wrong when queueing this uplift.", { apiError })
    },
    [showErrorMessage]
  )

  const { mutate: postUplift, isLoading } = useModelRateUpliftMutation({ modelType, onSuccess, onError })

  const handleUpliftSubmit = useCallback(
    (values: RateUpliftForm) => {
      if (page !== RateUpliftFormPage.SUMMARY) {
        setPage((p) => p + 1)
        return
      }

      // picked models that have services with rates
      const pickedModelsWithServices = filter(isModelWithRates)(values.models)

      const payload = Object.keys(pickedModelsWithServices).map((modelGuid) => {
        const model = pickedModelsWithServices[modelGuid]

        // picked services
        const pickedServicesWithRates = filter(
          (service) => service.picked && isServicesWithRates(service),
          model.services || {}
        )

        return {
          contract_guid: modelGuid,
          effective_from: values.start_date,
          uplifts: reduceUpliftModelServicesForPayload(pickedServicesWithRates)
        }
      })

      postUplift(payload)
    },
    [page, postUplift, setPage]
  )

  return { handleUpliftSubmit, isSubmitting: isLoading }
}
