import { SelectOption } from "components/Form/Select"

export enum ContractRuleConditionTypeAlias {
  CONF = "V/CONF",
  CANCEL = "V/CANCEL"
}

type ContractRuleConditionType = "None" | "AuthorisedOverstay" | "AuthorisedUnderstay" | "CovidOrOtherInfectiousDisease" | "DoorstepRefusal" | "EntryError" | "FamilyCover" | "Holiday" | "Hospital" | "MedicalAppointment" | "MissedVisit" | "RefusalOfCare" | "Respite"

export interface ContractRuleConditionRequest {
  guid?: string
  rule_type_alias: ContractRuleConditionTypeAlias
  title: string
  type?: ContractRuleConditionType
}

export interface ContractRuleCondition extends ContractRuleConditionRequest {
  guid: string
  third_party_id: number
}

export type ContractRuleConditionOptionsByType = Record<ContractRuleConditionTypeAlias, SelectOption[]>
