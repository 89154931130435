import { ContractModelSummaryRequest } from "data/finance/contractModel/types"

export const mapSummariesIntoModelOptions = (
  summaries?: ContractModelSummaryRequest[]
): {
  value: string
  title: string
}[] => {
  const allUniqContractModels: Record<string, string> = (summaries || []).reduce(
    (result, summary) => ({ ...result, ...(summary?.contracts || {}) }),
    {}
  )
  return Object.keys(allUniqContractModels).map((guid) => ({
    value: guid,
    title: allUniqContractModels[guid]
  }))
}
