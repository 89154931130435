import { CoreApi } from "api"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { ApiShiftCategoryRequest } from "types/Shifts/ShiftCategories/types"
import { queryKeys } from "./queries"
import { useNavigate } from "react-router-dom"
import { useUIStore } from "stores"

interface ShiftCategoryUpdateMutationParams {
  shiftCategory: ApiShiftCategoryRequest
}

export const useShiftCategoryUpdateMutation = (): UseMutationResult<
  ApiShiftCategoryRequest,
  Error,
  ShiftCategoryUpdateMutationParams
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ shiftCategory }: ShiftCategoryUpdateMutationParams): Promise<ApiShiftCategoryRequest> => {
      return CoreApi.put(`/shift-categories/${shiftCategory.guid}`, shiftCategory)
    },
    {
      onSuccess: async (data) => {
        const { guid: id } = data
        queryCache.setQueryData(queryKeys.getShiftCategoryDetailKey(id), data)
        showSuccessMessage("Shift category details successfully updated.")
      }
    }
  )
}

export const useShiftCategoryCreateMutation = (): UseMutationResult<
  ApiShiftCategoryRequest,
  Error,
  ShiftCategoryUpdateMutationParams
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  return useMutation(
    async ({ shiftCategory }: ShiftCategoryUpdateMutationParams): Promise<ApiShiftCategoryRequest> => {
      return CoreApi.post("/shift-categories", shiftCategory)
    },
    {
      onSuccess: (data) => {
        const { guid: id } = data
        showSuccessMessage("Shift category successfully created")
        navigate(`/shift-categories/${id}/detail`)
      }
    }
  )
}

// clean after cypress
export const useShiftCategoryDeleteMutation = () => {
  return useMutation(async (guid: string): Promise<void> => {
    return CoreApi.delete(`/shift-categories/${guid}`)
  })
}
