import React, { useState } from "react"
import { Form } from "react-final-form"
import { Visit } from "models/Visit"
import { ModalWrapper } from "components"
import { Button } from "components/Form"
import { validateVisitCheckout } from "lib/helpers/visitValidations"
import testingId from "constants/testingId"
import { VisitReportTemplateField } from "models/VisitReport"
import ConfirmationStep from "./ConfirmationStep"
import CheckOutEdit from "./CheckOutEdit"
import { useCheckOutVisitMutation } from "data/visits"

interface OwnProps {
  destroyModal: Function
  visit: Visit
  canCheckOut: boolean
}

const CheckOutModal: React.FC<OwnProps> = ({ visit, canCheckOut, destroyModal }) => {
  const { visitReport } = visit
  const [step, setStep] = useState<"edit" | "confirm">("edit")
  const { mutateAsync } = useCheckOutVisitMutation()

  const checkoutFields = visitReport.getQuickCheckOutFields()
  const checkoutFieldNames = checkoutFields.map((field: VisitReportTemplateField) => field.guid)

  const initialValues = visitReport.hasValues()
    ? {
        time: visit.isCheckedOut() ? visit.getDate("checkout") : visit.getDate("end"),
        ...visitReport.getValues()
      }
    : { time: visit.getDate("end") }

  const onSubmit = (values: any) => {
    const isDuration1HourMoreThanScheduled =
      visit.check_in &&
      visit.check_out &&
      values.time.diff(visit.check_in.time, "hours").hours - visit.end.diff(visit.start, "hours").hours > 1

    if (isDuration1HourMoreThanScheduled && step === "edit") {
      setStep("confirm")
      return
    }

    return submit(values)
  }

  const submit = async (values: any) => {
    const fields = Object.keys(values).reduce((result: any, key: string) => {
      if (checkoutFieldNames.indexOf(key) === -1) return result
      result[key] = {
        value: values[key],
        supporting_text: ""
      }
      return result
    }, {})

    await mutateAsync({
      visit: visit.guid,
      time: values.time,
      template_guid: visit.visit_report.template.guid,
      reason: values.reason,
      fields
    })
    destroyModal()
  }

  if (!canCheckOut)
    return (
      <ModalWrapper title="Unable to check-out" destroyModal={destroyModal}>
        <p>The check-out functionality is unavailable for this visit. This might be a result of following reasons:</p>
        <ul>
          <li>Visit has not been checked in</li>
          <li>Visit is marked as Paper visit</li>
          <li>Some values in visit report are blocking the quick checkout functionality, eg. concern.</li>
        </ul>
        <p>If you are convinced this is an error, please contact our support.</p>
        <Button
          id="visit-details-check-out-modal-unable-to-check-out-cancel-btn"
          variant="text"
          onClick={() => destroyModal()}
        >
          Close
        </Button>
      </ModalWrapper>
    )

  const checkinDateTime = visit.getDate("checkin")

  return (
    <ModalWrapper title="Visit check-out" destroyModal={destroyModal} data-cy={testingId.visit.detail.checkoutModal}>
      <Form onSubmit={onSubmit} initialValues={initialValues} validate={validateVisitCheckout(checkinDateTime)}>
        {({ handleSubmit, invalid, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            {step === "edit" && (
              <CheckOutEdit
                visit={visit}
                submitting={submitting}
                invalid={invalid}
                destroyModal={() => destroyModal()}
              />
            )}
            {step === "confirm" && (
              <ConfirmationStep
                submitting={submitting}
                checkOutTime={values.time}
                visit={visit}
                setEdit={() => setStep("edit")}
              />
            )}
          </form>
        )}
      </Form>
    </ModalWrapper>
  )
}

export default CheckOutModal
