import React, { memo, useEffect } from "react"
import { ModalWrapper } from "components"
import { Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { Form, Field } from "react-final-form"
import { Button, FormSectionHeader, Select, TextField } from "components/Form"
import validators from "lib/validators"
import testingId from "constants/testingId"
import { useInvoiceTaskPostMutation, useInvoiceTaskPutMutation } from "data/finance/invoiceTask/mutations"
import { DateTime } from "luxon"
import { SummaryList } from "components/SummaryList"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { TaskChargeReasonTypeAlias, TaskCreditTypeAlias, TaskPreparationMode } from "data/finance/task/types"
import { useSchema } from "data/schema/useSchema"
import { DateField } from "components/Form/DateField"
import { SelectDummy } from "components/Form/SelectDummy"
import { useUIStore } from "stores"

interface CreditFormValues {
  credit_type_alias: TaskCreditTypeAlias
  charge_reason_type_alias: TaskChargeReasonTypeAlias
  note: string
  tax_point_date: DateTime
}

interface Props {
  guids: string[]
  destroyModal: () => void
}

const TransactionGroupCreditingModal: React.FC<Props> = ({ guids, destroyModal }) => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)

  const { TransactionCreditReason, TransactionCredit } = useSchema(["TransactionCreditReason", "TransactionCredit"])

  const { mutateAsync: postInvoiceTask, data: preparationData } = useInvoiceTaskPostMutation() // prepare
  const { mutateAsync: putInvoiceTask, isSuccess, isError } = useInvoiceTaskPutMutation() // complete

  const onSubmit = (values: CreditFormValues) => {
    if (!preparationData?.task_guid) return

    if (
      values.credit_type_alias === "CHG/ONLY" &&
      !window.confirm("Do you really want to credit without recalculation?")
    ) {
      return
    }

    putInvoiceTask({
      ...values,
      mode: TaskPreparationMode.CREDIT,
      task_guid: preparationData.task_guid,
      is_draft: false
    })
  }

  useEffect(() => {
    postInvoiceTask({ guids, mode: TaskPreparationMode.CREDIT })
  }, [guids, postInvoiceTask])

  useEffect(() => {
    if (isSuccess) {
      showSuccessMessage("Credit generated!")
      destroyModal()
    }
  }, [isSuccess, showSuccessMessage, destroyModal])

  const initialValues = {
    credit_type_alias: "CHG+RPAY"
  }

  return (
    <ModalWrapper title="Crediting" destroyModal={destroyModal} width={700} data-cy={testingId.creditingModal.modal}>
      <RequestLoaderWrapper>
        <FormSectionHeader title="Selection summary" />
        {preparationData && (
          <SummaryList
            {...{
              headers: ["Invoice Model", "Clients", "£ Visits", "£ Extras", "£ Total value"],
              items: preparationData.contracts,
              totalLabel: "Total clients:"
            }}
          />
        )}
        <FormSectionHeader title="Credit options" />
        <Form {...{ onSubmit, initialValues }}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Field
                    name="charge_reason_type_alias"
                    label="Credit note reason *"
                    component={Select}
                    options={TransactionCreditReason}
                    validate={validators.required}
                  />
                </Grid>
                <Grid item md={6}>
                  {TransactionCredit ? (
                    <Field
                      name="credit_type_alias"
                      label="Credit action *"
                      component={Select}
                      options={TransactionCredit}
                      validate={validators.required}
                    />
                  ) : (
                    <SelectDummy value="loading..." />
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Field<string>
                    name="note"
                    component={TextField}
                    multiline
                    minRows={5}
                    label="Notes *"
                    validate={validators.required}
                  />
                </Grid>
                <Grid item md={6}>
                  <DateField name="tax_point_date" label="Tax point date" />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                {!isSuccess && (
                  <>
                    <Button
                      variant="text"
                      fullWidth={false}
                      onClick={() => destroyModal()}
                      sx={{
                        marginRight: "20px"
                      }}
                      data-cy={testingId.creditingModal.cancelButton}
                    >
                      CANCEL
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      fullWidth={false}
                      data-cy={testingId.creditingModal.confirmButton}
                    >
                      PROCESS CREDIT
                    </Button>
                  </>
                )}
                {isError && (
                  <>
                    <Box m={3} mx={0} display="flex" justifyContent="center" flexDirection="column">
                      <Typography variant="h5">Something went wrong!</Typography>
                      <br />
                      <Typography paragraph>
                        We apologize, we were not able to complete your request. Please try again later. If the problem
                        persists, please contact the user support.
                      </Typography>
                    </Box>
                    <br />
                    <Box m={1} mx={0} display="flex" justifyContent="flex-end">
                      <Button
                        fullWidth={false}
                        variant="text"
                        onClick={() => destroyModal()}
                        data-cy={testingId.creditingModal.closeButton}
                      >
                        Close
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </form>
          )}
        </Form>
      </RequestLoaderWrapper>
    </ModalWrapper>
  )
}

export default memo(TransactionGroupCreditingModal)
