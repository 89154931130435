import React, { ChangeEvent, useCallback, useRef, useState } from "react"
import { Box, TextField, Typography } from "@mui/material"
import { FieldRenderProps } from "react-final-form"
import { isNil } from "ramda"
import testingId from "constants/testingId"
import WarningMessage from "components/WarningMessage"

const TextFieldCountDown = ({
  input,
  inputProps,
  helperText,
  meta,
  instantValidation,
  warningValidators,
  ...restProps
}: FieldRenderProps<string, any>) => {
  const [warningMessage, setWarningMessage] = useState(null)
  const usedCharacters = useRef(0)

  const elemRef = useRef<HTMLInputElement>(null)
  const usedCharactersElemRef = useRef<HTMLTextAreaElement>(null)
  const usedCharactersWrapperRef = useRef<HTMLTextAreaElement>(null)

  const { onChange } = input
  const { minLength = 0, maxLength = 0 } = inputProps

  const isError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && (instantValidation || meta.touched)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!isNil(warningValidators)) {
        // @ts-ignore
        setWarningMessage(warningValidators(e.target.value))
      }

      if (elemRef?.current && usedCharactersElemRef?.current) {
        usedCharacters.current = e.target.value.length
      }

      onChange(e)
    },
    [onChange, warningValidators]
  )

  return (
    <>
      <TextField
        {...input}
        fullWidth
        multiline
        ref={elemRef}
        minRows={5}
        size="medium"
        margin="normal"
        variant="outlined"
        autoComplete="off"
        data-cy={testingId.textField}
        {...(helperText && {
          helperText: (
            <Box component="span" sx={{ display: "flex", justifyContent: "space-between" }}>
              {helperText}
              {maxLength && (
                <Typography
                  sx={(theme) => ({
                    color: usedCharacters.current >= minLength ? theme.palette.success.main : theme.palette.error.main
                  })}
                  component="span"
                  ref={usedCharactersWrapperRef}
                >
                  <span ref={usedCharactersElemRef}>{usedCharacters.current}</span>/{maxLength}
                </Typography>
              )}
            </Box>
          )
        })}
        error={isError}
        onChange={handleChange}
        inputProps={inputProps}
        {...restProps}
      />
      {!isNil(warningMessage) && <WarningMessage>{warningMessage}</WarningMessage>}
    </>
  )
}

export default TextFieldCountDown
