import { ContractModel } from "data/finance/contractModel/types"
import { ContractModelFilterValues } from "../types"
import { StatusEnum } from "data/commonTypes"

/**
 * Based on `expired` prop filters models into current and expired
 */
export const filterContractModels = ({
  filter,
  contracts = [],
  expired
}: {
  filter: ContractModelFilterValues
  contracts?: ContractModel[]
  expired: boolean
}): ContractModel[] => {
  let result = contracts.filter((item) =>
    expired ? item.status === StatusEnum.EXPIRED : item.status !== StatusEnum.EXPIRED
  )

  const { status, type_alias, search, salesforce_id } = filter

  if (status?.length) {
    result = result.filter((item) => status.includes(item.status))
  }

  if (type_alias?.length) {
    result = result.filter((item) => type_alias.includes(item.type_alias))
  }

  if (search?.length) {
    result = result.filter((item) => {
      const funder = item.info?.crm_details?.salesforce?.funder

      return (
        item.title.toLowerCase().includes(search.toLowerCase()) ||
        (funder && funder.toLowerCase().includes(search.toLowerCase()))
      )
    })
  }

  if (salesforce_id?.length) {
    result = result.filter((item) => {
      const salesforceId = item.info?.crm_details?.salesforce?.id

      return salesforceId && salesforceId.toLowerCase().includes(salesforce_id.toLowerCase())
    })
  }

  return result
}
