import React, { Component, ErrorInfo, PropsWithChildren, ReactNode } from "react"
import { EmptyStateIcon } from "assets/images/EmptyStateIcon"
import { Button } from "./Form"
import { Container, Link } from "@mui/material"
import { Typography } from "@ceracare/dcp-ui"
import { Location } from "react-router-dom"

const CERA_NET_LINK = "https://ceranet.ceracare.co.uk/home/"

const DefaultErrorPage = () => {
  return (
    <Container
      maxWidth={false}
      sx={(theme) => ({
        padding: theme.spacing(5, 5),
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        color: theme.palette.text.secondary
      })}
    >
      <EmptyStateIcon />
      <Typography
        variant="h6"
        sx={(theme) => ({
          paddingTop: theme.spacing(2)
        })}
      >
        Something went wrong
      </Typography>
      <Typography variant="body1" sx={(theme) => ({ paddingBottom: theme.spacing(2) })}>
        Our team have been notified. If you would like to help, you can describe what happened by raising a ticket.
      </Typography>
      <Link href={CERA_NET_LINK} target="_blank">
        <Button variant="contained" color="primary" fullWidth={false} size="small">
          Raise an issue
        </Button>
      </Link>
    </Container>
  )
}

interface Props {
  fallback?: ReactNode | string
  location?: Location
}

class ErrorBoundary extends Component<PropsWithChildren<Props>> {
  state = { hasError: false }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error, errorInfo)
  }

  componentDidUpdate(prevProps: Readonly<PropsWithChildren<Props>>): void {
    if (this.state.hasError && prevProps.location?.pathname !== this.props.location?.pathname) {
      this.setState({ hasError: false })
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback ?? <DefaultErrorPage />
    }
    return this.props.children
  }
}

export default ErrorBoundary
