export enum BranchFeatures {
  FINANCE = "finance",
  CM2000 = "cm2000",
  SHIFTS = "shifts",
  SCOTLAND_FIELDS = "scotland_fields",
  WALES_FIELDS = "wales_fields",
  EMARS = "emars",
  SCHEDULER = "scheduling",
  CHAT = "chat",
  DASHBOARD = "dashboard",
  I_TRENT = "i_trent",
  CARER_PROFILE_AVAILABILITY = "carer_profile_availability",
  CARER_PROFILE_ABSENCES = "carer_profile_absences",
  CARER_PROFILE_SKILLS = "carer_profile_skills",
  CARER_PROFILE_LEAVING_REASONS = "carer_profile_leaving_reasons",
  CARER_PROFILE_STATUS = "carer_profile_status",
  CARER_PROFILE_AGENCY_INDICATOR = "carer_profile_agency_indicator",
  CARER_PROFILE_CARER_EXIT = "carer_profile_carer_exit"
}

export interface BranchPermissionDictionary {
  [key: string]: boolean
}
export interface BranchSettings {
  [key: string]: string | undefined
}

export interface BranchInfoResponse {
  guid: string
  title: string
  abbr: string
  phone: string
  care_planner_url: string
  features: BranchPermissionDictionary
  settings: BranchSettings
  ccl_url: string
  reference?: string
  status: string
}
