import { Box } from "@mui/material"
import { StickyBox } from "components"
import { Button } from "components/Form"
import { AdditionalSearchFormValues, ApiPaymentAdditionalSearch } from "data/finance/additional/type"
import { isNil } from "ramda"
import React, { memo, useCallback } from "react"
import { FormRenderProps } from "react-final-form"
import { AdditionalSearchApproveModal } from "./AdditionalSearchApproveModal"

interface OwnProps {
  formProps: FormRenderProps<AdditionalSearchFormValues, Partial<AdditionalSearchFormValues>>
  additionals: ApiPaymentAdditionalSearch[]
  mappedChargeTypes: Record<string, string>
}

export const AdditionalSearchBulkButton: React.FC<OwnProps> = memo(({ formProps, additionals, mappedChargeTypes }) => {
  const setApproved = useCallback((approved?: boolean) => formProps.form.change("approved", approved), [formProps.form])

  const isDisabled = (condition: string) =>
    !formProps.values?.pickedStatus || formProps.values.pickedStatus === condition

  const destroyModal = useCallback(() => {
    formProps.form.change("comment")
    setApproved()
  }, [setApproved, formProps.form])

  return (
    <StickyBox>
      <Box textAlign="right" my={2}>
        <Button fullWidth={false} onClick={() => setApproved(true)} disabled={isDisabled("Approved")}>
          Approve
        </Button>
        <Button sx={{ ml: 2 }} fullWidth={false} onClick={() => setApproved(false)} disabled={isDisabled("Rejected")}>
          Reject
        </Button>
        {!isNil(formProps.values.approved) && (
          <AdditionalSearchApproveModal
            destroyModal={destroyModal}
            formProps={formProps}
            additionals={additionals}
            mappedChargeTypes={mappedChargeTypes}
          />
        )}
      </Box>
    </StickyBox>
  )
})

AdditionalSearchBulkButton.displayName = "AdditionalSearchBulkButton"
