import React, { Dispatch, memo, SetStateAction, useMemo } from "react"
import { ContractModelType } from "constants/modelTypes"
import { SortableTableHeader } from "components/SortableTableHeader"
import { InvoiceFilesFilterValues } from "../types"

const menuItems = ({
  isPaymentModel,
  showITrent,
  isDeveloper
}: {
  isPaymentModel: boolean
  showITrent: boolean
  isDeveloper: boolean
}) => [
  ...(showITrent ? [{ label: "", key: "i_trent" }] : []),
  {
    label: `${isPaymentModel ? "Pay" : "Contract"} model`,
    resource: "user_contract.contract.title"
  },
  { label: "Process date", resource: "calendar_period.process_date" },
  {
    label: isPaymentModel ? "Carer name" : "Client",
    resource: ["user_contract.owner.user_branch.user.last_name", "user_contract.owner.user_branch.user.first_name"]
  },
  {
    label: isPaymentModel ? "Payroll" : "Type",
    resource: isPaymentModel ? "user_contract.reference" : "status_type.title"
  },
  { label: `${isPaymentModel ? "Payment" : "Invoice"}`, resource: "reference" },
  ...(isPaymentModel
    ? [
        {
          label: "Payment period",
          resource: ["calendar_period.end", "calendar_period.start"]
        }
      ]
    : []),
  {
    label: `${isPaymentModel ? "Pay date" : "Billing cycle"}`,
    resource: isPaymentModel ? "completed_at" : "calendar_period.start"
  },
  { label: "£ Value" },
  { label: showITrent ? "Payroll status" : "Interface" },
  ...(showITrent ? [{ label: "", key: "payroll" }] : []),
  ...(isDeveloper && !isPaymentModel ? [{ label: "", key: "replace" }] : [])
]

interface OwnProps {
  modelType: ContractModelType
  setFilter: Dispatch<SetStateAction<InvoiceFilesFilterValues>>
  showITrent: boolean
  isDeveloper: boolean
}

export const FileManagementTableHeader: React.FC<OwnProps> = memo(
  ({ modelType, setFilter, showITrent, isDeveloper }) => {
    const isPaymentModel = modelType === ContractModelType.PAYMENT
    const items = useMemo(
      () => menuItems({ isPaymentModel, showITrent, isDeveloper }),
      [isPaymentModel, showITrent, isDeveloper]
    )

    return <SortableTableHeader {...{ setFilter, items }} />
  }
)

FileManagementTableHeader.displayName = "FileManagementTableHeader"
