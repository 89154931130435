import React from "react"
import { Box, Typography } from "@mui/material"
import { grey, teal, indigo, amber, orange, red } from "@mui/material/colors"
import { isNil } from "ramda"
import testingId from "constants/testingId"
import { Variant } from "@mui/material/styles/createTypography"

const level = [teal[200], indigo[200], amber[200], orange[400], red[400]]

interface Props {
  concern: number | null | undefined
  variant?: Variant
}

const VisitConcern = ({ concern, variant }: Props) => {
  if (isNil(concern))
    return (
      <Typography variant={variant ? variant : "body2"} color="textSecondary">
        Undetermined
      </Typography>
    )
  return (
    <Box
      sx={{
        flexGrow: 0
      }}
      data-cy={testingId.visit.detail.concern}
    >
      {[1, 2, 3, 4, 5].map((concernNumber: number, key: number) => {
        let className
        return (
          <Box
            sx={{
              display: "inline-block",
              width: 15,
              height: 7,
              marginRight: "2px",
              backgroundColor: key < concern ? level[concern - 1] : grey[300],
              borderRadius: "10px"
            }}
            component="span"
            key={concernNumber}
            className={className}
          ></Box>
        )
      })}
    </Box>
  )
}
export default VisitConcern
