import { queryKeys } from "data/core/branch/queries"
import { useQueryClient } from "react-query"

/**
 * useResetBranchData
 *
 * @returns an fn to reset the queries for the current branch data
 */
const useResetBranchData = () => {
  const queryClient = useQueryClient()

  const resetBranchQueryData = () => {
    queryClient.invalidateQueries(queryKeys.getBranchInfoKey())
  }

  return { resetBranchQueryData }
}

export default useResetBranchData
