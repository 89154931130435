import DeleteIcon from "@mui/icons-material/Delete"
import { InvoiceTransaction } from "data/finance/invoiceProcessing/types"
import React from "react"

const pendingReadyLabels = (canEditExtras: boolean) => [
  "",
  canEditExtras ? <DeleteIcon key={`delete-icon`} /> : "",
  "Date",
  "Start (R)",
  "Carer",
  "Extra type",
  "Description",
  "Tax",
  "£ Value",
  ""
]

const invoicedLabels = [
  "",
  "Date",
  "Start (R)",
  "Carer",
  "Extra type",
  "Description",
  "Tax",
  "Processed",
  "£ Value",
  "Credit"
]
const creditedLabels = ["", "Date", "Carer", "Extra type", "Description", "Tax", "Reason", "Processed", "£ Value", ""]

export const getChargeTableHeaderLabels = (
  transaction: InvoiceTransaction,
  canEditExtras: boolean
): (string | React.JSX.Element)[] => {
  switch (transaction.status_type_alias) {
    case "PENDING":
    case "READY":
    case "NOCHARGE":
      return pendingReadyLabels(canEditExtras)
    case "COMPLETE":
      return invoicedLabels
    case "CREDITED":
      return creditedLabels
  }
}
