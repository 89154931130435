import React, { useMemo } from "react"
import { ApiAssignedOperator } from "models"
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, Tooltip } from "@mui/material"
import DefaultAvatar from "components/DefaultAvatar"
import { EmptyData } from "components"
import dateTimeUtils, { DateTimeFormat } from "lib/datetime"
import testingId from "constants/testingId"

interface Props {
  operators: ApiAssignedOperator[]
  additionalData?: {
    title?: string
    children: React.FC<{ operator: ApiAssignedOperator }>
  }[]
  onPageChange: (newPage: number) => void
  onRowsPerPageChange: (newRowsPerPage: number) => void
  count?: number
  rowsPerPage: number
  page: number
  onClick?: (operator: ApiAssignedOperator) => () => void
}

const MIN_ROWS_PER_PAGE = 10

const OperatorsTable: React.FC<Props> = (props) => {
  const { operators, additionalData, onPageChange, onRowsPerPageChange, count, rowsPerPage, page, onClick } = props

  const isPaginationRequired = useMemo(() => count && count > MIN_ROWS_PER_PAGE, [count])
  const isTableEmpty = useMemo(() => !operators || operators.length === 0, [operators])

  const hasOnClick = !!onClick

  if (isTableEmpty) {
    return <EmptyData message="No operators found, start by adding one." />
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>User details</TableCell>
            <TableCell align="center">Login</TableCell>
            <TableCell align="right">Status</TableCell>
            {additionalData?.map(({ title }, idx) => (
              <TableCell key={`${title}_${idx}`} align="right">
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {operators.map((operator: ApiAssignedOperator) => {
            const isInvitationAvailable = !!operator.invitation
            const invitationDate =
              operator.invitation?.label_date_time &&
              isInvitationAvailable &&
              dateTimeUtils.formatDate(operator.invitation?.label_date_time, DateTimeFormat.DATE_TIME)
            return (
              <TableRow
                key={operator.guid}
                hover={hasOnClick}
                sx={
                  hasOnClick
                    ? {
                        cursor: "pointer"
                      }
                    : null
                }
                onClick={onClick && onClick(operator)}
                data-cy={testingId.tableRow}
              >
                <TableCell align="center">
                  <DefaultAvatar alt={`${operator.first_name} ${operator.last_name}`} />
                </TableCell>
                <TableCell>
                  <Typography variant="h6" color="primary">
                    {`${operator.first_name} ${operator.last_name}`}
                  </Typography>
                  <Typography component="span" variant="body2" color="textPrimary">
                    <Typography component="span" variant="body2" color="textSecondary">
                      Email
                    </Typography>
                    &nbsp;{operator.email}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title={invitationDate || "Unknown date"}>
                    <Typography variant="body2" color="textSecondary">
                      {operator.invitation?.label}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">{operator.status === 0 ? "Active" : "Inactive"}</TableCell>
                {additionalData?.map(({ children: Children }, index) => (
                  <TableCell key={index} align="right">
                    <Children operator={operator} />
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {isPaginationRequired && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={count || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          slotProps={{
            actions: {
              previousButton: { "aria-label": "previous page" },
              nextButton: { "aria-label": "next page" }
            }
          }}
          onPageChange={(_e, newPage) => onPageChange(newPage)}
          onRowsPerPageChange={(ev) => onRowsPerPageChange(+ev.target.value)}
        />
      )}
    </>
  )
}

export default OperatorsTable
