import {
  InvoiceTransaction,
  InvoiceTransactionCharge,
  InvoiceTransactionItem
} from "data/finance/invoiceProcessing/types"
import { sumBy } from "lodash"

type InvoiceTransactionDetailMoney = {
  visits: number
  current: number
  extras: number
  total: number
}

export const getInvoiceTransactionMoney = ({
  transaction,
  visits = [],
  charges = []
}: {
  transaction?: InvoiceTransaction
  visits?: InvoiceTransactionItem[]
  charges?: InvoiceTransactionCharge[]
}) => {
  const result: InvoiceTransactionDetailMoney = {
    visits: 0,
    extras: 0,
    current: 0,
    total: 0
  }

  if (!transaction) {
    return result
  }

  const creditedVisits = visits.filter((visit) => visit.credit_guid)
  const creditedExtras = charges.filter((extra) => extra.credit_guid)
  const total = isNaN(transaction.total) ? 0 : transaction.total

  return {
    ...result,
    extras: isNaN(transaction.subtotal_extra) ? 0 : transaction.subtotal_extra,
    visits: isNaN(transaction.subtotal_item) ? 0 : transaction.subtotal_item,
    total,
    current: total - sumBy(creditedVisits, "total") - sumBy(creditedExtras, "total")
  }
}
