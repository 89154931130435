import { FormStage } from "constants/form"
import {
  useShiftCategoryContractCreateMutation,
  useShiftCategoryContractUpdateMutation
} from "data/finance/shiftCategory/mutations"
import { Dispatch, SetStateAction, useCallback } from "react"
import { ContractModel } from "data/finance/contractModel/types"
import { ApiShiftCategoryContractRequest } from "types/Shifts/ShiftCategories/types"

export const useSubmitShiftCategoryContractHandler = ({
  isEdit,
  shiftCategoryGuid,
  setSelectedContractModelGuid,
  formStage,
  setFormStage
}: {
  isEdit: boolean
  shiftCategoryGuid: string
  selectedContractModel?: ContractModel
  setSelectedContractModelGuid: Dispatch<SetStateAction<string | undefined>>
  formStage: FormStage
  setFormStage: Dispatch<SetStateAction<FormStage>>
}): ((values: ApiShiftCategoryContractRequest) => void) => {
  const { mutate: createContract } = useShiftCategoryContractCreateMutation()
  const { mutate: updateContract } = useShiftCategoryContractUpdateMutation()

  return useCallback(
    (values: ApiShiftCategoryContractRequest) => {
      // step one
      if (formStage === FormStage.CREATE) {
        setSelectedContractModelGuid(values.contract_guid)
        setFormStage(FormStage.EDIT)
        return
      }

      // step two
      if (values.no_end_date === true) delete values.end
      delete values.no_end_date
      if (!isEdit) {
        createContract({ shiftCategoryGuid, contract: values })
      } else {
        updateContract({ shiftCategoryGuid, contract: values })
      }
    },
    [createContract, formStage, isEdit, setSelectedContractModelGuid, setFormStage, shiftCategoryGuid, updateContract]
  )
}
