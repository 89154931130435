import React, { memo } from "react"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { Visit } from "models/Visit"
import testingId from "constants/testingId"
import { getNfcCheckInStatusText, getNfcCheckOutStatusText } from "./helpers"

interface OwnProps {
  visit: Visit
  hasClientNfc?: boolean
}

const VisitDetailNfc: React.FC<OwnProps> = ({ visit, hasClientNfc = false }) => {
  return (
    <>
      {(hasClientNfc || visit.isGeoLocationCheckIn()) && (
        <Grid item xs={1.5}>
          <Typography variant="body2" color="textSecondary" component="h6">
            NFC Check-in
          </Typography>
          <Typography variant="body1" data-cy={testingId.visit.detail.nfcCheckinTime}>
            {getNfcCheckInStatusText(visit, hasClientNfc)}
          </Typography>
        </Grid>
      )}
      {(hasClientNfc || visit.isGeoLocationCheckOut()) && (
        <Grid item xs={1.5} sx={{ textAlign: "right" }}>
          <Typography variant="body2" color="textSecondary" component="h6">
            NFC Check-out
          </Typography>
          <Typography variant="body1" data-cy={testingId.visit.detail.nfcCheckoutTime}>
            {getNfcCheckOutStatusText(visit, hasClientNfc)}
          </Typography>
        </Grid>
      )}
    </>
  )
}
export default memo(VisitDetailNfc)
