import { useBranchInfoQuery } from "data/core/branch/queries"

/**
 * useBranchReference
 *
 * @returns the current branch reference
 */
const useBranchReference = () => {
  const { data: { reference = undefined } = {} } = useBranchInfoQuery()
  return reference
}

export default useBranchReference
