import React from "react"
import { Field } from "react-final-form"
import Checkbox from "./Checkbox"

type Props = {
  name: string
  stopPropagation?: boolean
}

const SelectionCheckboxField: React.FC<Props> = ({ name, stopPropagation }) => (
  <Field<boolean>
    type="checkbox"
    name={name}
    component={Checkbox}
    onClick={(e: React.MouseEvent) => {
      stopPropagation && e.stopPropagation()
    }}
  />
)

export default React.memo(SelectionCheckboxField)
