import React from "react"
import ceraLogoLight from "../assets/images/cera_logo_light.png"
import ceraLogoDark from "../assets/images/cera_logo_dark.png"
import { SxProps, Theme, styled } from "@mui/material"
import { unstable_styleFunctionSx, StyleFunction } from "@mui/system"

interface LogoProps {
  sx?: SxProps<Theme>
  invert?: boolean
}

const Img = styled("img")<{ sx?: SxProps }>({
  width: 140,
  ...(unstable_styleFunctionSx as StyleFunction<{ sx?: SxProps }>)
})

const Logo = ({ sx, invert }: LogoProps) => {
  return <Img sx={sx} src={invert ? ceraLogoLight : ceraLogoDark} alt="Cera Logo" />
}

export default Logo
