import { ApiUserCarer } from "models"
import { Dispatch, SetStateAction } from "react"
import { includes, uniq } from "ramda"
import { ContractModelSummaryRequest } from "data/finance/contractModel/types"

export interface CarerListFilterSearchValues {
  search?: string
  region?: number[]
  status?: number
  contract_model?: { id: string; label: string }[]
  reference?: string
}

const filterCarersByModel = ({
  filter,
  summaries,
  carers = []
}: {
  filter: CarerListFilterSearchValues
  summaries?: ContractModelSummaryRequest[]
  carers?: ApiUserCarer[]
}): ApiUserCarer[] | undefined => {
  const filteredSummaries = (summaries || []).filter((summary) => {
    const hasContractModel = filter.contract_model?.length
      ? Object.keys(summary.contracts).find((contractGuid) =>
          includes(contractGuid, filter.contract_model?.map(({ id }) => id) ?? [])
        )
      : true
    return !!hasContractModel
  })
  const uniqCarerGuids = uniq(filteredSummaries.map((summary) => summary.owner_guid))

  return filter.contract_model?.length ? carers?.filter((carer) => includes(carer.guid, uniqCarerGuids)) : carers
}

const filterCarersByReference = ({
  filter,
  carers = []
}: {
  filter: CarerListFilterSearchValues
  carers?: ApiUserCarer[]
}) => {
  const filterReference = filter.reference
  if (!filterReference) return carers

  return carers.filter((carer) => {
    const reference = carer.reference
    if (!!reference && reference.includes(filterReference)) {
      return true
    }

    const niNumber = carer.finance.ni_number
    if (!!niNumber && String(niNumber).includes(filterReference)) {
      return true
    }

    const payrollReference = carer.finance.payroll_reference
    if (!!payrollReference && payrollReference.includes(filterReference)) {
      return true
    }

    return false
  })
}

export const filterCarerList = ({
  filter,
  summaries,
  carers,
  setIsFiltering
}: {
  filter: CarerListFilterSearchValues
  summaries?: ContractModelSummaryRequest[]
  carers?: ApiUserCarer[]
  setIsFiltering: Dispatch<SetStateAction<boolean>>
}): ApiUserCarer[] | undefined => {
  const carersFilteredByModel = filterCarersByModel({ filter, summaries, carers })

  const carersFilteredByReference = filterCarersByReference({ filter, carers: carersFilteredByModel })

  setIsFiltering(false) // remove loader after filtering

  return carersFilteredByReference
}
