import { FinanceApi } from "api"
import { enabledById } from "data/helpers"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { ContractRuleCondition } from "./types"

export enum QueryKeyParts {
  CONTRACT_RULE_CONDITION = "contract-rule-condition",
  LIST = "list",
  ITEM = "item"
}

export const queryKeys = {
  getContractRuleConditionList: (): QueryKey => [QueryKeyParts.CONTRACT_RULE_CONDITION, QueryKeyParts.LIST],
  getContractRuleConditionItem: (guid?: string): QueryKey => [
    QueryKeyParts.CONTRACT_RULE_CONDITION,
    QueryKeyParts.ITEM,
    { guid }
  ]
}

export const useContractRuleConditionListQuery = (): UseQueryResult<ContractRuleCondition[], Error> => {
  return useQuery({
    queryKey: queryKeys.getContractRuleConditionList(),
    queryFn: async () => FinanceApi.get("/finance/contract-rule-conditions")
  })
}

export const useContractRuleConditionItemQuery = ({
  guid
}: {
  guid?: string
}): UseQueryResult<ContractRuleCondition, Error> => {
  return useQuery({
    queryKey: queryKeys.getContractRuleConditionItem(guid),
    queryFn: async () => FinanceApi.get(`/finance/contract-rule-conditions/${guid}`),
    ...enabledById(guid)
  })
}
