import React from "react"
import OperatorCreate from "./OperatorCreate"
import OperatorDetail from "./OperatorDetail"
import AssignedOperatorsList from "./AssignedOperatorsList"
import UnassignedOperatorsList from "./UnassignedOperatorsList"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

const Operators: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/" element={<AssignedOperatorsList />} />
      <Route path="/new/*" element={<OperatorCreate />} />
      <Route path="/:id/*" element={<OperatorDetail />} />
      <Route path="/assign/" element={<UnassignedOperatorsList />} />
    </SentryRoutes>
  )
}

export default React.memo(Operators)
