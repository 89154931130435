import React, { useMemo, memo } from "react"
import { FormSectionHeader } from "components/Form"
import Grid from "@mui/material/Grid"
import { FormSpy } from "react-final-form"
import Typography from "@mui/material/Typography"
import { ContractModel } from "data/finance/contractModel/types"
import BillingInformation from "pages/Finance/ContractModels/components/modelForm/BillingInformation"
import DatesMessageHelp from "components/DatesMessageHelp"
import { SelectableIdsDictionary } from "lib/hooks"
import { DateTime } from "luxon"
import { NoEndDateCheckbox } from "components"
import { INVOICE_CONTRACT_PRIVATE_ALIASES } from "constants/modelTypes"
import UnitContractContributions from "./UnitContractContributions"
import { UnitContract } from "data/finance/unit/types"
import { isNil } from "ramda"
import { UnitContractStandingCharges } from "./UnitContractStandingCharges"
import { filterNil, getPeriodMaxEnd, getPeriodMinStart } from "../helpers/getPeriodDateValues"
import { FormApi } from "final-form"
import { CheckboxIsDefault } from "components/Form/CheckboxIsDefault"
import { DateSpan } from "components/DateSpan"
import { WithPermission } from "components/Auth"
import { Permission } from "constants/permission"
import { ClientContractModelSettings } from "pages/Clients/components/ClientContractInvoiceSettings"
import { DateField } from "components/Form/DateField"
import { ApiClientContractContribution, ContractRate } from "data/finance/client/types"

interface OwnProps {
  contractModel?: ContractModel
  contractReference?: string
  selectableContributions: SelectableIdsDictionary
  contract?: UnitContract
  form: FormApi<any>
  isFirstContract: boolean
}

const UnitContractCreateStepTwo: React.FC<OwnProps> = ({
  contractModel,
  contractReference,
  selectableContributions,
  contract,
  form,
  isFirstContract
}) => {
  const addBillingInformation = INVOICE_CONTRACT_PRIVATE_ALIASES.includes(contractModel?.type_alias ?? "")
  const isContributable = useMemo(() => contract?.is_contributable, [contract])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Typography variant="body2" color="textSecondary">
            Model Name
          </Typography>
          <Typography variant="h6">
            {contractModel?.title} <DateSpan period={contractModel} />
          </Typography>
        </Grid>
        <Grid item md={6}>
          {contractReference && (
            <>
              <Typography variant="body2" color="textSecondary">
                Contract ID
              </Typography>
              <Typography variant="h6">{contractReference}</Typography>
            </>
          )}
        </Grid>
      </Grid>

      <FormSectionHeader title="Contract duration" />
      <DatesMessageHelp messageType="E" />
      <FormSpy subscription={{ values: true }}>
        {({ values }) => (
          <Grid container spacing={8}>
            <Grid item md={6}>
              <DateField
                name="start"
                label="Starts on"
                required
                maxDate={DateTime.min(
                  ...filterNil([
                    getPeriodMinStart<ApiClientContractContribution>(values.contributions, values.end),
                    getPeriodMinStart<ContractRate>(values.rates, values.end)
                  ])
                )}
              />
              <CheckboxIsDefault {...{ isFirstContract, contract, labelType: "Unit" }} />
            </Grid>
            <Grid item md={6}>
              <DateField
                name="end"
                label="Ends before"
                disabled={values.no_end_date}
                minDate={DateTime.max(
                  ...filterNil([
                    getPeriodMaxEnd<ApiClientContractContribution>(values.contributions, values.start),
                    getPeriodMaxEnd<ContractRate>(values.rates, values.start)
                  ])
                )}
              />
              <NoEndDateCheckbox />
            </Grid>
          </Grid>
        )}
      </FormSpy>
      <WithPermission
        permissions={[Permission.FINANCE_USER_CONTRACT_RATE_EDIT, Permission.FINANCE_USER_CONTRACT_RATE_READ]}
      >
        <UnitContractStandingCharges {...{ contract }} />
      </WithPermission>
      {addBillingInformation && (
        <>
          <BillingInformation
            isEditing={!isNil(contractReference)}
            isClientContractModel={true}
            typeAlias={contractModel?.type_alias}
            form={form}
          />
          <ClientContractModelSettings />
        </>
      )}
      {!!contract && isContributable && (
        <>
          <FormSectionHeader title="Client contributions" />
          <UnitContractContributions {...{ selectableContributions, contract }} />
        </>
      )}
    </>
  )
}

export default memo(UnitContractCreateStepTwo)
