import React from "react"
import { Stepper, Step, StepLabel } from "@mui/material"
import { ApiUser } from "../models"
import { ProfileMode, SchemaOnlyValidator } from "../lib/types"
import Wizard from "./Wizard"
import NotFound from "../pages/NotFound"
import lodash from "lodash"
import { DateTime } from "luxon"

interface Props {
  section: string
  createUrl: string
  user?: ApiUser
  mode: ProfileMode
  sections: string[]
  onSubmit: any
  customValidation?: Function
  schemaValidators: SchemaOnlyValidator
  initialValues: Record<string, string | number | Array<string> | Record<string, string | number | boolean | DateTime>>
}

const ProfileForm: React.FC<React.PropsWithChildren<Props>> = ({
  section,
  createUrl,
  user = {},
  mode,
  sections,
  onSubmit,
  children,
  customValidation,
  schemaValidators,
  initialValues
}) => {
  const activeSection = sections.indexOf(section)

  if (activeSection === -1) {
    return <NotFound />
  }

  return (
    <>
      {mode === "create" && (
        <Stepper
          activeStep={activeSection}
          alternativeLabel
          sx={(theme) => ({
            margin: theme.spacing(5, 0, 0, 0),
            backgroundColor: "inherit"
          })}
        >
          {sections.map((label) => (
            <Step key={label} id={`${label}-step`}>
              <StepLabel>{lodash.startCase(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      <Wizard
        onSubmit={onSubmit}
        schema={schemaValidators}
        initialValues={mode === "create" ? initialValues : user}
        page={activeSection}
        sections={sections}
        createUrl={createUrl}
        mode={mode}
        customValidation={mode === "update" || sections.length - 1 === activeSection ? customValidation : undefined}
      >
        {children}
      </Wizard>
    </>
  )
}

export default ProfileForm
