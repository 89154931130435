import React from "react"
import { moneyFormat } from "lib/utils"

interface Props {
  amount?: number
  prefix?: string
}

const MoneyWrapper: React.FC<Props> = ({ amount, prefix }) => (
  <span style={{ whiteSpace: "nowrap" }}>{`${prefix ?? ""}${moneyFormat.format(amount || 0)}`}</span>
)

export default React.memo(MoneyWrapper)
