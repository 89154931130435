import React from "react"
import WarningIcon from "@mui/icons-material/Warning"
import Tooltip from "@mui/material/Tooltip"

const TransactionFailed: React.FC = () => {
  return (
    <Tooltip title="This transaction has failed, contact support">
      <WarningIcon />
    </Tooltip>
  )
}

export default TransactionFailed
