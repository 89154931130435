import { DateTime } from "luxon"
import { filter, isNil, not, pipe } from "ramda"
import { filterNils } from "lib/datetime"

export const filterNil = (items: (DateTime | null | undefined)[]): DateTime[] =>
  filter(pipe(isNil, not), items) as DateTime[]

export const getPeriodMinStart = <T extends { start?: DateTime }>(
  periods: T[] | null,
  end: DateTime | null
): DateTime | undefined => {
  const mappedStarts: DateTime[] = (periods || []).map((cont) => cont.start).filter(filterNils)

  return DateTime.min(...(end ? [...mappedStarts, end.minus({ days: 1 })] : mappedStarts))
}

export const getPeriodMaxEnd = <T extends { end?: DateTime | null }>(
  periods: T[] | null,
  start: DateTime
): DateTime | undefined => {
  const filteredPeriods: DateTime[] = (periods || []).map((contrib) => contrib.end).filter(filterNils)

  return DateTime.max(...(start ? [...filteredPeriods, start.plus({ days: 1 })] : filteredPeriods))
}
