import React from "react"
import { Header } from "components"
import { ApiUserClient, ApiUserCarer } from "models"
import { Typography } from "@mui/material"

interface OwnProps {
  client?: ApiUserClient | ApiUserCarer
}

export const ClientHeader: React.FC<OwnProps> = ({ client }) => (
  <Header
    title={
      client ? (
        <>
          {`Clients / ${client.profile.name}`}
          {client.reference && (
            <>
              {" "}
              /{" "}
              <Typography component="span" sx={{ color: "primary.main" }} variant="h4">
                {client.reference}
              </Typography>
            </>
          )}
        </>
      ) : (
        "Not Found"
      )
    }
  />
)
