import React from "react"
import { BackButton, ContentContainer, Header } from "components"
import dateTimeUtils from "lib/datetime"
import { DateTime } from "luxon"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import { HolidayEstimation } from "components/HolidayEstimation/HolidayEstimation"
import { usePaymentTransactionChargeHolidayCalculationQuery } from "data/finance/paymentProcessing/queries"
import { usePeriodQuery } from "data/finance/contractModel/queries"
import { useParams } from "react-router-dom"

const TransactionHolidayDetails: React.FC = () => {
  const { periodGuid, calendarGuid, contractGuid, transactionGuid, transactionItemGuid } = useParams()
  const { data: period } = usePeriodQuery({
    modelType: ContractModelType.PAYMENT,
    contractGuid,
    calendarGuid,
    periodGuid
  })

  const { data: holidayCalculationDetails } = usePaymentTransactionChargeHolidayCalculationQuery({
    periodGuid,
    transactionGuid,
    transactionChargeGuid: transactionItemGuid
  })

  if (!holidayCalculationDetails) return null

  return (
    <ContentContainer data-cy={testingId.holidayEstimation.screen}>
      <BackButton fallbackUrl=".." />
      <Header
        title={
          <>
            {`${holidayCalculationDetails.contract_model_title} (${dateTimeUtils.formatDate(
              period ? period.start : DateTime.local()
            )} - ${dateTimeUtils.formatDate(period ? period.end : DateTime.local())}): ${
              holidayCalculationDetails.carer_name
            } / Holiday`}
          </>
        }
      />
      <HolidayEstimation {...holidayCalculationDetails} />
    </ContentContainer>
  )
}

export default React.memo(TransactionHolidayDetails)
