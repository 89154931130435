import React from "react"
import { AnyObject, FormSpy } from "react-final-form"

interface OwnProps {
  // handleSubmit: <T>(
  handleSubmit: (
    event?: Partial<Pick<React.SyntheticEvent<Element, Event>, "preventDefault" | "stopPropagation">> | undefined
  ) => Promise<AnyObject | undefined> | undefined
}

// TODO: improve type, add FormState
// onChange={({ values, dirty }: FormState<ClientContractsFilter>) => {

const FormSpyCustom: React.FC<OwnProps> = ({ handleSubmit }) => (
  <FormSpy
    {...{
      subscription: { values: true, modified: true },
      onChange: ({ values, modified }) => {
        const modded = Object.values(modified || {}).some((val) => val)
        if (modded) handleSubmit(values)
      }
    }}
  />
)

export default FormSpyCustom
