import { FinanceApi } from "api"
import { useMutation, UseMutationResult } from "react-query"
import { TaskPreparation, TaskPreparationPostPayload, TaskPreparationPutPayload } from "../task/types"

export const usePaymentTaskPostMutation = (): UseMutationResult<TaskPreparation, Error, TaskPreparationPostPayload> => {
  return useMutation(async (props: TaskPreparationPostPayload): Promise<TaskPreparation> => {
    return FinanceApi.post("/finance/payment-tasks", props)
  })
}

export const usePaymentTaskPutMutation = (): UseMutationResult<void, Error, TaskPreparationPutPayload> => {
  return useMutation(async (props: TaskPreparationPutPayload): Promise<void> => {
    const { task_guid, ...rest } = props
    return FinanceApi.put(`/finance/payment-tasks/${task_guid}`, rest)
  })
}
