import { FinanceApi } from "api"
import { GENERAL_ERROR_MESSAGE } from "api/instance"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { ApiError } from "stores/useUIStore/types"
import { queryKeys } from "./queries"
import { ExtrasFormValues } from "pages/Finance/components/ExtrasModal"
import { useApiMessage } from "stores"
import { PaymentTransactionCharge } from "./types"

interface PaymentTransactionChargeCreateUpdateProps {
  periodGuid: string
  transactionGuid: string
  onSuccess?: () => void
}

export const usePaymentTransactionChargeCreateMutation = ({
  transactionGuid,
  periodGuid,
  onSuccess
}: PaymentTransactionChargeCreateUpdateProps): UseMutationResult<
  PaymentTransactionCharge,
  ApiError,
  { charge: ExtrasFormValues }
> => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const queryCache = useQueryClient()

  return useMutation({
    mutationFn: async ({ charge }) => {
      return FinanceApi.post(
        `/finance/payment-processing/${periodGuid}/transactions/${transactionGuid}/charges`,
        charge
      )
    },
    onSuccess: () => {
      onSuccess && onSuccess()
      showSuccessMessage("Payment transaction charge successfully created.")
      queryCache.invalidateQueries(
        queryKeys.getPaymentProcessingTransactionChargeListKey({ periodGuid, transactionGuid })
      )
    },
    onError: (apiError: ApiError) => {
      showErrorMessage(GENERAL_ERROR_MESSAGE, { apiError })
    }
  })
}

export const usePaymentTransactionChargeUpdateMutation = ({
  periodGuid,
  transactionGuid,
  onSuccess
}: PaymentTransactionChargeCreateUpdateProps): UseMutationResult<
  PaymentTransactionCharge,
  ApiError,
  { charge: ExtrasFormValues; chargeGuid: string }
> => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const queryCache = useQueryClient()

  return useMutation({
    mutationFn: async ({ charge, chargeGuid }) => {
      return FinanceApi.put(
        `/finance/payment-processing/${periodGuid}/transactions/${transactionGuid}/charges/${chargeGuid}`,
        charge
      )
    },
    onSuccess: () => {
      onSuccess && onSuccess()
      showSuccessMessage("Payment transaction charge successfully updated.")
      queryCache.invalidateQueries(
        queryKeys.getPaymentProcessingTransactionChargeListKey({ periodGuid, transactionGuid })
      )
    },
    onError: (apiError: ApiError) => {
      showErrorMessage(GENERAL_ERROR_MESSAGE, { apiError })
    }
  })
}

export const usePaymentTransactionChargeDeleteMutation = ({
  transactionGuid,
  periodGuid
}: PaymentTransactionChargeCreateUpdateProps): UseMutationResult<void, ApiError, { chargeGuid: string }> => {
  return useMutation({
    mutationFn: async ({ chargeGuid }) => {
      return FinanceApi.delete(
        `/finance/payment-processing/${periodGuid}/transactions/${transactionGuid}/charges/${chargeGuid}`
      )
    }
  })
}
