import React from "react"
import { VisitAlert } from "../models"
import Tooltip from "@mui/material/Tooltip"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import MoodBadIcon from "@mui/icons-material/MoodBad"
import WarningIcon from "@mui/icons-material/Warning"
import { Box } from "@mui/material"
import { amber } from "@mui/material/colors"
import { path } from "ramda"
import { SelectOption } from "./Form/Select"
import { useCoreSchemasStore } from "stores"

interface Props {
  alerts: Array<VisitAlert> | null
  display: "full" | "small"
}

const VisitAlerts: React.FC<Props> = (props) => {
  const { alerts, display } = props
  const { BusinessVisit } = useCoreSchemasStore((state) => state.schema.models)

  if (alerts === null || !alerts.length) return null

  const descriptions = (path<SelectOption[]>(["alerts", "id", "options"], BusinessVisit) ?? []).reduce(
    (result: Record<string, string>, item: SelectOption) => {
      if (!item.description) return result
      return { ...result, [item.value]: item.description }
    },
    {}
  )

  return (
    <Box
      m={display === "full" ? 3 : 0}
      mx={0}
      p={display === "full" ? 2 : 0}
      bgcolor={display === "full" ? amber[50] : "transparent"}
      display="flex"
      flexDirection={display === "full" ? "column" : "row"}
      alignItems={display === "full" ? "flex-start" : "center"}
      justifyContent={display === "full" ? "flex-start" : "flex-end"}
    >
      {alerts.map((alert: VisitAlert) => {
        if (display === "full") {
          return (
            <Box key={alert.id} display="flex" alignItems="center">
              <AlertIcon name={alert.name} />
              <p
                style={{
                  marginLeft: "8px"
                }}
              >
                {descriptions[alert.id]}
              </p>
            </Box>
          )
        } else {
          return (
            <Tooltip title={descriptions[alert.id]} placement="bottom" key={alert.id}>
              <div>
                <AlertIcon name={alert.name} />
              </div>
            </Tooltip>
          )
        }
      })}
    </Box>
  )
}
export default VisitAlerts

const AlertIcon: React.FC<{ name: string }> = (props) => {
  if (
    ["ReportHealth", "ReportSleep", "ReportEatingDrinking", "ReportUrine", "ReportMobility"].indexOf(props.name) !== -1
  )
    return <MoodBadIcon color={"secondary"} />
  else if (
    ["VisitCheckInTime", "VisitCheckInTime30", "VisitCheckOutTime", "VisitCheckOutTime30"].indexOf(props.name) !== -1
  )
    return <AccessTimeIcon color={"secondary"} />
  else if (["ReportIncident", "ReportSignedOffConcerned"].indexOf(props.name) !== -1)
    return <WarningIcon color={"secondary"} />
  else return <WarningIcon color={"secondary"} />
}
