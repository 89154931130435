import React, { Dispatch, memo, SetStateAction, useEffect, useState } from "react"
import { CarerContractDetailStepOne } from "./CarerContractDetailStepOne"
import { CarerContractDetailStepTwo } from "./CarerContractDetailStepTwo"
import { Form, FormRenderProps, FormSpy } from "react-final-form"
import { ApiUserCarer } from "models"
import arrayMutators from "final-form-arrays"
import { validateCarerContract } from "lib/helpers/validateCarerContract"
import { FormStage } from "constants/form"
import { useCarerContractListQuery } from "data/finance/carer/queries"
import { CarerContractFormValues } from "../types"
import { usePaymentModelQuery } from "data/finance/contractModel/queries"
import { useCarerContractGetInitialValues } from "../helpers/useCarerContractGetInitialValues"
import { useCarerContractSubmitHandler } from "../helpers/useCarerContractSubmitHandler"
import { ApiCarerContract } from "data/finance/carer/types"
import { ContainerSteps } from "components/Steps"
import { ContractDetailHeader } from "pages/Finance/components/ContractDetailHeader"
import { ContractDetailFooter } from "pages/Finance/components/ContractDetailFooter"

interface OwnProps {
  carer: ApiUserCarer
  contract?: ApiCarerContract
  isEdit: boolean
  formStage: FormStage
  setFormStage: Dispatch<SetStateAction<FormStage>>
}

export const CarerContractDetailSettings: React.FC<OwnProps> = memo(
  ({ contract, carer, isEdit, formStage, setFormStage }) => {
    CarerContractDetailSettings.displayName = "CarerContractDetailSettings"

    const { data: carerContracts } = useCarerContractListQuery({ carerGuid: carer.guid })

    const [selectedContractModelGuid, setSelectedContractModelGuid] = useState<string | undefined>(
      contract?.contract_guid
    )
    useEffect(() => {
      setSelectedContractModelGuid(contract?.contract_guid)
    }, [contract])

    const { data: selectedContractModel } = usePaymentModelQuery(selectedContractModelGuid)

    const onSubmit = useCarerContractSubmitHandler({
      formStage,
      carerGuid: carer.guid,
      setFormStage,
      setSelectedContractModelGuid
    })

    const initialValues = useCarerContractGetInitialValues(contract)

    return (
      <Form
        {...{
          onSubmit,
          initialValues,
          mutators: { ...arrayMutators },
          validate: validateCarerContract({ paymentModel: selectedContractModel, carerContracts, formStage })
        }}
      >
        {(formProps: FormRenderProps<CarerContractFormValues>) => (
          <form onSubmit={formProps.handleSubmit}>
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => {
                if (values.current_role && values.end) {
                  formProps.form.change("end", undefined)
                }
              }}
            />

            <ContainerSteps>
              {!isEdit && (
                <ContractDetailHeader {...{ userProfile: carer.profile, contract, selectedContractModel, formStage }} />
              )}
              {formStage === FormStage.CREATE && <CarerContractDetailStepOne />}
              {formStage === FormStage.EDIT && (
                <CarerContractDetailStepTwo paymentModel={selectedContractModel} contract={contract} />
              )}
              <ContractDetailFooter {...{ formStage, formProps }} />
            </ContainerSteps>
          </form>
        )}
      </Form>
    )
  }
)
