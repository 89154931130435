import { TableCell } from "@mui/material"
import { Checkbox } from "components/Form"
import { equals } from "ramda"
import React, { memo, useCallback, useMemo } from "react"
import { Field, FormSpy, FormSpyRenderProps } from "react-final-form"
import { ContractModelUpliftHeaderProps, RateUpliftForm, RateUpliftFormModel } from "../../types"

export const RateUpliftOneModelCheckboxAll: React.FC<ContractModelUpliftHeaderProps> = memo(
  ({ items: contractModels }) => {
    RateUpliftOneModelCheckboxAll.displayName = "RateUpliftOneModelCheckboxAll"

    const allItemsAsForm = useMemo(() => {
      return contractModels
        .filter((contractModel) => !contractModel.disabled)
        .reduce((result: Record<string, RateUpliftFormModel>, contractModel) => {
          return { ...result, [contractModel.guid]: { picked: true } }
        }, {})
    }, [contractModels])

    // TODO: check equals if some service is picked
    const areAllItemsSelected = useCallback(
      ({ models }: RateUpliftForm) => {
        return equals(models, allItemsAsForm)
      },
      [allItemsAsForm]
    )

    const isDisabled = useMemo(() => {
      return contractModels.every((contractModel) => contractModel.disabled)
    }, [contractModels])

    return (
      <TableCell>
        <FormSpy subscription={{ values: true }}>
          {({ values, form }: FormSpyRenderProps<RateUpliftForm>) => {
            const areAllSelected = areAllItemsSelected(values)

            return (
              <Field<boolean>
                {...{
                  type: "checkbox",
                  name: `all_models`,
                  checked: areAllSelected,
                  disabled: isDisabled,
                  component: Checkbox,
                  onClick: (e: React.MouseEvent) => {
                    e.stopPropagation()
                    if (areAllSelected) {
                      form.change("models", {})
                    } else {
                      form.change("models", allItemsAsForm)
                    }
                  }
                }}
              />
            )
          }}
        </FormSpy>
        Select All
      </TableCell>
    )
  }
)
