import { ContentContainer, EmptyData, Header } from "components"
import { useLocation } from "react-router-dom"
import { usePagination } from "components/handlers/usePagination"
import { useUnitUserListQuery } from "data/finance/unit/queries"
import { Unit } from "data/finance/unit/types"
import { queryStringToObject } from "lib/queryString"
import React, { memo, useState } from "react"
import { UnitUserFilter, UnitUserListFilter } from "./components/UnitUserListFilter"
import { UnitUserListTable } from "./components/UnitUserListTable"

export const UnitUserList: React.FC<{ unit: Unit }> = memo(({ unit }) => {
  const location = useLocation()
  const [filter, setFilter] = useState<UnitUserFilter>(queryStringToObject(location?.search))
  const { data: users, refetch } = useUnitUserListQuery({ search: location?.search, unitGuid: unit.guid })

  const { pagination, setPagination, resetPagination } = usePagination()

  return (
    <ContentContainer>
      <Header title={`Units - ${unit.name} - Users`} />
      <UnitUserListFilter {...{ pagination, resetPagination, filter, setFilter, unitGuid: unit.guid }} />
      {users?.length ? (
        <>
          <UnitUserListTable {...{ users, unitGuid: unit.guid, refetch, setPagination }} />
        </>
      ) : (
        <EmptyData {...{ message: "No users found, start by adding one." }} />
      )}
    </ContentContainer>
  )
})

UnitUserList.displayName = "UnitUserList"
