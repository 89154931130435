import React, { memo, useCallback } from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { Field, Form } from "react-final-form"
import { Button, FormSectionHeader, CancelButton, TextField, Select } from "components/Form"
import { ProfileMode } from "lib/types"
import { StickyBox } from "components"
import { ApiShiftCategoryResponse, ApiShiftCategoryRequest } from "types/Shifts/ShiftCategories/types"
import testingId from "constants/testingId"
import ShiftCategoryDetailsDates from "./components/ShiftCategoryDetailsDates"
import DatesMessageHelp from "components/DatesMessageHelp"
import { useShiftCategoryCreateMutation, useShiftCategoryUpdateMutation } from "data/core/shiftCategory/mutations"
import { validatePeriodDates } from "lib/dateTimeValidators"
import validators, { composeValidators } from "lib/validators"
import { useSchema } from "data/schema/useSchema"

interface OwnProps {
  mode: ProfileMode
  shiftCategory?: ApiShiftCategoryResponse
}

const useSubmitHandler = (mode: ProfileMode) => {
  const { mutate: createShiftCategory } = useShiftCategoryCreateMutation()
  const { mutate: updateShiftCategory } = useShiftCategoryUpdateMutation()

  return useCallback(
    (shiftCategory: ApiShiftCategoryRequest) => {
      if (shiftCategory.no_end_date === true) delete shiftCategory.end
      delete shiftCategory.no_end_date

      if (mode === "create") {
        createShiftCategory({ shiftCategory })
      } else {
        updateShiftCategory({ shiftCategory })
      }
    },
    [mode, createShiftCategory, updateShiftCategory]
  )
}

const ShiftCategoryDetail: React.FC<OwnProps> = ({ shiftCategory, mode }) => {
  const { Region } = useSchema(["Region"])

  const initialValues = shiftCategory ? { ...shiftCategory, no_end_date: !shiftCategory.end } : {}

  const onSubmit = useSubmitHandler(mode)

  return (
    <Box m={3} mx={0}>
      <Form onSubmit={onSubmit} initialValues={initialValues} validate={validatePeriodDates}>
        {({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit} data-cy={testingId.shifts.category.form}>
            <DatesMessageHelp messageType="E" />
            <Grid container spacing={4}>
              <ShiftCategoryDetailsDates />
            </Grid>
            <FormSectionHeader title="Shift Category Details" />
            <Grid container spacing={4}>
              <Grid item md={6}>
                <Field
                  name="title"
                  label="Title"
                  component={TextField}
                  validate={composeValidators([validators.required, validators.maxLength(100)])}
                />
              </Grid>
              <Grid item md={6}>
                <Field
                  name="type"
                  label="Type *"
                  component={Select}
                  options={[
                    { title: "CareDelivery", value: 0 },
                    { title: "OnCall", value: 1 },
                    { title: "Office", value: 2 }
                  ]}
                  validate={validators.required}
                />
              </Grid>
            </Grid>
            <FormSectionHeader title="Address" />
            <Grid container>
              <Grid item md={6}>
                <Field
                  name="regions"
                  label="Regions *"
                  component={Select}
                  options={Region}
                  multiselect
                  validate={validators.required}
                />
              </Grid>
            </Grid>
            <StickyBox>
              <CancelButton disabled={submitting} pristine={pristine} navigateUrl="/shift-categories" />
              <Button
                type="submit"
                fullWidth={false}
                disabled={submitting}
                data-cy={testingId.submitButton}
                sx={(theme) => ({
                  margin: theme.spacing(0, 0, 0, 3)
                })}
              >
                Save changes
              </Button>
            </StickyBox>
          </form>
        )}
      </Form>
    </Box>
  )
}

export default memo(ShiftCategoryDetail)
