import { useMutation } from "react-query"
import coreApi from "api"
import type { CompleteInvitationRequest } from "api/core"

const useMutationInvitation = () => {
  return useMutation({
    mutationKey: ["invitation"],
    mutationFn: (guid: CompleteInvitationRequest) => coreApi.completeInvitation(guid)
  })
}

export default useMutationInvitation
