import { Box, Grid } from "@mui/material"
import React from "react"
import { Field, Form, FormSpy } from "react-final-form"
import { ContentContainer, Header, Link, StickyBox } from "components"
import { Button, FormSectionHeader, CancelButton, TextField, Select } from "components/Form"
import {
  useContractRuleConditionItemQuery,
  useContractRuleConditionListQuery
} from "data/finance/contractRuleCondition/queries"
import {
  ContractRuleCondition,
  ContractRuleConditionRequest,
  ContractRuleConditionTypeAlias
} from "data/finance/contractRuleCondition/types"
import {
  useContractRuleConditionUpdateMutation,
  useContractRuleConditionCreateMutation
} from "data/finance/contractRuleCondition/mutations"
import { contractRuleCancellationOptions, contractRuleConfirmedOptions } from "./data"
import validators, { composeValidators } from "lib/validators"
import { useSchema } from "data/schema/useSchema"
import { useParams } from "react-router-dom"

const validate = (contractRuleConditions?: ContractRuleCondition[]) => (values: ContractRuleConditionRequest) => {
  if (!contractRuleConditions?.length || !values.type || !values.rule_type_alias) {
    return
  }

  const isCancellation = values.rule_type_alias === ContractRuleConditionTypeAlias.CANCEL

  const sameItem = contractRuleConditions
    .filter((item) => {
      if (values.guid === item.guid) {
        return false
      }
      if (isCancellation) {
        return item.rule_type_alias === ContractRuleConditionTypeAlias.CANCEL
      }
      return item.rule_type_alias === ContractRuleConditionTypeAlias.CONF
    })
    .find((item) => item.type === values.type)

  if (sameItem) {
    const sameItemTypeTitle = (isCancellation ? contractRuleCancellationOptions : contractRuleConfirmedOptions).find(
      (item) => item.value === sameItem.type
    )?.title

    return {
      type: `There can only be one rule condition for ${
        isCancellation ? "cancellations" : "confirmed visits"
      } with the same type. "${
        sameItem.title
      }" rule condition is using "${sameItemTypeTitle}" type. Please use a different type for this rule condition.`
    }
  }
}

const ContractServiceTypeForm: React.FC = () => {
  const { id } = useParams()
  const { Rule } = useSchema(["Rule"])

  const { data: contractRuleConditions } = useContractRuleConditionListQuery()
  const { data: contractRuleCondition } = useContractRuleConditionItemQuery({ guid: id })

  const { mutateAsync: updateRuleCondition } = useContractRuleConditionUpdateMutation({ guid: id })
  const { mutateAsync: createRuleCondition } = useContractRuleConditionCreateMutation()

  const onSubmit = (values: ContractRuleConditionRequest) => {
    if (id) {
      updateRuleCondition(values)
    } else {
      createRuleCondition(values)
    }
  }

  if (id && !contractRuleCondition) return null

  return (
    <ContentContainer>
      <Header
        title={
          <>
            <Link to="/admin/finance/contract-rule-conditions">Contract rule conditions</Link> -{" "}
            {contractRuleCondition ? contractRuleCondition.title : "New contract rule condition"}
          </>
        }
      />
      <Box m={3} mx={0}>
        <Form onSubmit={onSubmit} initialValues={contractRuleCondition} validate={validate(contractRuleConditions)}>
          {({ handleSubmit, submitting, pristine, values, form }) => (
            <form onSubmit={handleSubmit}>
              <FormSectionHeader title="Contract rule condition" />
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <Field
                    name="title"
                    label="Reason *"
                    component={TextField}
                    validate={composeValidators([validators.required, validators.maxLength(64)])}
                  />
                </Grid>

                <FormSpy
                  {...{
                    subscription: { values: true },
                    onChange: ({ values: newValues }) => {
                      if (newValues.rule_type_alias !== values.rule_type_alias) {
                        form.change("type", undefined)
                      }
                    }
                  }}
                />

                <Grid item md={6}>
                  <Field
                    name="type"
                    label="Type"
                    component={Select}
                    options={
                      values.rule_type_alias === ContractRuleConditionTypeAlias.CONF
                        ? contractRuleConfirmedOptions
                        : contractRuleCancellationOptions
                    }
                    disabled={!values.rule_type_alias}
                  />
                </Grid>
                <Grid item md={6}>
                  <Field
                    name="rule_type_alias"
                    label="Rule Category *"
                    component={Select}
                    options={Rule}
                    validate={validators.required}
                    disabled={!!contractRuleCondition?.guid}
                  />
                </Grid>
              </Grid>
              <StickyBox>
                <CancelButton disabled={submitting} pristine={pristine} />
                <Button
                  type="submit"
                  fullWidth={false}
                  disabled={submitting || pristine}
                  sx={(theme) => ({
                    margin: theme.spacing(0, 0, 0, 3)
                  })}
                >
                  Save changes
                </Button>
              </StickyBox>
            </form>
          )}
        </Form>
      </Box>
    </ContentContainer>
  )
}

export default React.memo(ContractServiceTypeForm)
