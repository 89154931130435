import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { Paginator } from "components"
import { Permission } from "constants/permission"
import {
  AdditionalSearchFilterValues,
  AdditionalSearchFormValues,
  ApiAdditionalSearch,
  ApiPaymentAdditionalSearch
} from "data/finance/additional/type"
import { useSchema } from "data/schema/useSchema"
import Auth from "lib/Auth"
import { optionsToValueTitle } from "lib/schema"
import { SetPagination } from "lib/types"
import React, { memo, useEffect } from "react"
import { FormRenderProps } from "react-final-form"
import { AdditionalSearchBulkButton } from "./AdditionalSearchBulkButton"
import { AdditionalSearchTableItem } from "./AdditionalSearchTableItem"
import { AdditionalSearchTableSelectAll } from "./AdditionalSearchTableSelectAll"
import { useLocation } from "react-router-dom"

const headers = (isInvoiceModel: boolean): string[] => [
  "Date",
  isInvoiceModel ? "Client name" : "Carer name",
  "Contract model",
  "Additional type",
  "Charge type",
  isInvoiceModel ? "Invoice number" : "Payment number",
  "Value",
  ...(isInvoiceModel ? [] : ["Approval"])
]

interface OwnProps {
  formProps: FormRenderProps<AdditionalSearchFormValues, Partial<AdditionalSearchFormValues>>
  additionals: (ApiAdditionalSearch | ApiPaymentAdditionalSearch)[]
  isInvoiceModel: boolean
  filter: AdditionalSearchFilterValues
  isApproveSuccess: boolean
  setPagination: SetPagination
}

export const AdditionalSearchTable: React.FC<OwnProps> = memo(
  ({ formProps, additionals, isInvoiceModel, filter, isApproveSuccess, setPagination }) => {
    const location = useLocation()

    const hasApproveEditPermission = Auth.hasPermission([Permission.FINANCE_PROCESSING_CHARGES_APPROVE_EDIT])
    const hasHrApproveEditPermission = Auth.hasPermission([Permission.HR_APPROVER_EDIT])

    const canApprove = hasApproveEditPermission || hasHrApproveEditPermission

    const { TransactionCharge } = useSchema(["TransactionCharge"])

    const mappedChargeTypes = optionsToValueTitle(TransactionCharge ?? [])

    // reset form on filter/tab change
    useEffect(() => {
      formProps.form.reset()
      // eslint-disable-next-line
    }, [location.search])

    // reset form on submit success
    useEffect(() => {
      if (isApproveSuccess) {
        formProps.form.reset()
      }
      // eslint-disable-next-line
    }, [isApproveSuccess])

    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              {!isInvoiceModel && canApprove && (
                <AdditionalSearchTableSelectAll
                  formProps={formProps}
                  additionals={additionals as ApiPaymentAdditionalSearch[]}
                  filter={filter}
                />
              )}
              {headers(isInvoiceModel).map((name) => (
                <TableCell key={name}>{name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {additionals.map((additional) => (
              <AdditionalSearchTableItem
                additional={additional}
                isInvoiceModel={isInvoiceModel}
                values={formProps.values}
                mappedChargeTypes={mappedChargeTypes}
                canApprove={canApprove}
                key={additional.guid}
              />
            ))}
          </TableBody>
        </Table>
        <Paginator setPagination={setPagination} itemsLength={additionals.length} />
        {!isInvoiceModel && canApprove && (
          <AdditionalSearchBulkButton
            formProps={formProps}
            mappedChargeTypes={mappedChargeTypes}
            additionals={additionals as ApiPaymentAdditionalSearch[]}
          />
        )}
      </>
    )
  }
)

AdditionalSearchTable.displayName = "AdditionalSearchTable"
