import { PaymentTransactionCharge, PaymentTransactionItem } from "data/finance/paymentProcessing/types"
import { timeSpanSum } from "lib/timespan"
import { sumBy } from "lodash"

export type PaymentTransactionDetailMoney = {
  visits: number
  credited: number
  extras: number
  total: number
  totalHours: string
  mileage: number
}

export const formatPaymentTransactionDetailMoney = ({
  visits,
  charges
}: {
  visits: PaymentTransactionItem[]
  charges: PaymentTransactionCharge[]
}): PaymentTransactionDetailMoney => {
  const result = {
    visits: 0,
    extras: 0,
    credited: 0,
    total: 0,
    mileage: 0,
    totalHours: ""
  } as PaymentTransactionDetailMoney

  // Sum credited visits from previous period
  const creditedVisits = sumBy(
    visits.filter(
      (visit) => visit.credit_guid && visit.is_historic && !visit.is_projected && visit.processed_prefix === "CR"
    ),
    "total"
  )

  // Sum on uncredited mileage
  const mileageExtras = sumBy(
    charges.filter(
      (extra) =>
        (extra.charge_type_alias === "MILEAGE" ||
          extra.charge_type_alias === "MILE/TOP" ||
          extra.charge_type_alias === "COMMUTE") &&
        !extra.credit_guid &&
        !extra.is_historic
    ),
    "total"
  )

  // Sum credited extras from previous period
  const creditedExtras = sumBy(
    charges.filter(
      (extra) => extra.credit_guid && extra.is_historic && !extra.is_projected && extra.processed_prefix === "CR"
    ),
    "total"
  )

  visits.forEach((visit) => {
    result.visits +=
      !visit.total || visit.is_projected ? 0 : visit.credit_guid && visit.processed_prefix === "CR" ? 0 : visit.total
  })

  charges.forEach((charge) => {
    result.extras +=
      !charge.total || charge.is_projected
        ? 0
        : charge.credit_guid && charge.processed_prefix === "CR"
        ? 0
        : charge.total
  })

  const payableHours = visits
    .filter((visit) => !!visit.payable_duration && (visit.total || 0) > 0 && !visit.is_historic && !visit.credit_guid)
    .map((visit) => visit.payable_duration) as string[]
  result.totalHours = timeSpanSum(payableHours)

  result.credited = creditedVisits + creditedExtras
  result.total = result.visits + result.extras - Math.abs(result.credited)
  result.mileage = mileageExtras

  return result
}
