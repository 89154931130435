import { Box } from "@mui/material"
import { Link } from "components"
import { Button } from "components/Form"
import { ContractModelType } from "constants/modelTypes"
import React, { memo } from "react"
import { ContractModel } from "data/finance/contractModel/types"
import { ContractModelTitle } from "../../components"

interface OwnProps {
  modelType: ContractModelType
  contractModel: ContractModel
}

export const PaymentModelBaseRateListTitle: React.FC<OwnProps> = memo(({ contractModel, modelType }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <ContractModelTitle
        {...{
          modelType,
          title: (
            <>
              <Link to={`../settings`}>{contractModel.title}</Link> / Base Rates
            </>
          )
        }}
      />
      <Link to="new">
        <Button fullWidth={false}>Add Base Rate</Button>
      </Link>
    </Box>
  )
})

PaymentModelBaseRateListTitle.displayName = "PaymentModelBaseRateListTitle"
