import { Box, Grid } from "@mui/material"
import { ModalWrapper } from "components"
import { AutocompleteMui, Button, Select } from "components/Form"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { useAppointmentPatchMutation } from "data/finance/appointment/mutations"
import { useSchema } from "data/schema/useSchema"
import React, { memo, useCallback } from "react"
import { Field, Form } from "react-final-form"

interface OwnProps {
  destroyModal: () => void
  selectedIds: string[]
  onOk: () => void
  onError: (errors: string[]) => void
}

interface FormProps {
  confirmed?: boolean
  contract_guid?: string
  variation_guid?: string
  service_guid?: string
}

export const AppointmentSearchScheduledEditModal: React.FC<OwnProps> = memo(
  ({ destroyModal, selectedIds, onOk, onError }) => {
    const { InvoiceContract, VariationType, ContractServiceType } = useSchema([
      "InvoiceContract",
      "VariationType",
      "ContractServiceType"
    ])

    const { mutateAsync: patchAppointments } = useAppointmentPatchMutation({ onOk, onError })

    const onSubmit = useCallback(
      (vals: FormProps) => {
        patchAppointments({ ...vals, user_appointment_guids: selectedIds })
      },
      [patchAppointments, selectedIds]
    )

    return (
      <ModalWrapper {...{ title: "Bulk Edit", destroyModal, width: 700 }}>
        <RequestLoaderWrapper>
          <Form {...{ onSubmit }}>
            {({ handleSubmit, dirty }) => (
              <form {...{ onSubmit: handleSubmit }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item md={4}>
                    <Field
                      name="confirmed"
                      label="Confirmed"
                      component={Select}
                      options={[
                        { title: "Confirmed", value: true },
                        { title: "Unconfirmed", value: false }
                      ]}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Field
                      name="contract_guid"
                      label="Invoice Model"
                      component={AutocompleteMui}
                      options={InvoiceContract}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Field name="variation_guid" label="Variation" component={Select} options={VariationType} />
                  </Grid>
                  <Grid item md={4}>
                    <Field name="service_guid" label="Service" component={Select} options={ContractServiceType} />
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end" my={3}>
                  <Button variant="text" fullWidth={false} onClick={destroyModal}>
                    Cancel
                  </Button>
                  <Button type="submit" fullWidth={false} disabled={!dirty}>
                    Save
                  </Button>
                </Box>
              </form>
            )}
          </Form>
        </RequestLoaderWrapper>
      </ModalWrapper>
    )
  }
)

AppointmentSearchScheduledEditModal.displayName = "AppointmentSearchScheduledEditModal"
