import React from "react"
import { InterfaceFileTabs } from "./InterfaceFileTabs"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

export const InterfaceFileScreen: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/" element={<InterfaceFileTabs />} />
    </SentryRoutes>
  )
}
