import { Grid } from "@mui/material"
import { DateTime } from "luxon"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { ApiContractAccrualItemTypeAlias } from "data/finance/carer/types"
import { ClearFilterButton } from "components/ClearFilterButton"
import { Checkbox, FormSpyCustom, Select } from "components/Form"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { Pagination } from "lib/types"
import { clearFilterValues } from "lib/helpers/clearFilterValues"
import { DateField } from "components/Form/DateField"

export type CarerAccrualItemFilterValues = {
  type?: ApiContractAccrualItemTypeAlias
  date?: DateTime
  hide_zero?: boolean
  page?: number
  page_size?: number
}

interface OwnProps {
  filter: CarerAccrualItemFilterValues
  setFilter: Dispatch<SetStateAction<CarerAccrualItemFilterValues>>
  pagination: Pagination
  resetPagination: () => void
}

export const CarerAccrualDetailListFilter: React.FC<OwnProps> = memo(
  ({ filter, setFilter, pagination, resetPagination }) => {
    const onSubmit = (values: CarerAccrualItemFilterValues) => {
      const formattedValues = clearFilterValues({
        ...values,
        date: DateTime.isDateTime(values.date) ? values.date.toISODate() : values.date
      } as CarerAccrualItemFilterValues)
      setFilter(formattedValues)
      resetPagination()
    }

    const onClearClick = () => {
      setFilter({})
      resetPagination()
    }

    useFilterLocationChange({ filter, pagination })

    return (
      <Form {...{ onSubmit, initialValues: filter }}>
        {({ handleSubmit }) => {
          return (
            <form {...{ onSubmit: handleSubmit }}>
              <FormSpyCustom {...{ handleSubmit }} />
              <Grid
                container
                spacing={2}
                sx={{
                  alignItems: "center"
                }}
              >
                <Grid item md={3}>
                  <Field
                    name="type"
                    label="Type"
                    component={Select}
                    options={[
                      { title: "Any", value: "" },
                      { title: "Accrual", value: "Accrual" },
                      { title: "Rollover", value: "Rollover" },
                      { title: "Holiday", value: "Holiday" },
                      { title: "Manual", value: "Manual" },
                      { title: "Expiry", value: "Expiry" }
                    ]}
                  />
                </Grid>
                <Grid item md={3}>
                  <DateField name="date" label="Date" />
                </Grid>
                <Grid item md={2}>
                  <Field type="checkbox" label="Hide zeros" component={Checkbox} name="hide_zero" />
                </Grid>
                <Grid item md={2}>
                  <ClearFilterButton {...{ onClick: onClearClick }} disabled={Object.keys(filter).length === 0} />
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Form>
    )
  }
)

CarerAccrualDetailListFilter.displayName = "CarerAccrualDetailListFilter"
