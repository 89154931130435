const regexPattern = "[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}"
const hasGuidRegex = new RegExp(regexPattern)
const isGuidRegex = new RegExp(`^${regexPattern}$`)

export const hasGuid = (stringToCheck: string | undefined | null): boolean => {
  return hasGuidRegex.test(stringToCheck ?? "")
}

export const isGuid = (stringToCheck: string | undefined | null): boolean => {
  return isGuidRegex.test(stringToCheck ?? "")
}