import { Box } from "@mui/material"
import { AddressSearch, ModalWrapper } from "components"
import { GeocodeAddress } from "components/AddressSearch"
import { Button, TextField } from "components/Form"
import { FormApi } from "final-form"
import React, { memo, useState, ReactNode, useCallback } from "react"
import { Field, Form, FormSpy, FormSpyRenderProps } from "react-final-form"

interface ModalProps {
  destroyModal: () => void
  form: FormApi<any>
}

interface OwnProps {
  form: FormApi<any>
}

const AddressModal: React.FC<ModalProps> = memo(({ destroyModal, form }) => {
  const changeForm = useCallback(
    (addressName: string, fieldName: string, address: GeocodeAddress) => {
      const item = address.address_components.find(({ types }) => types.includes(addressName))
      if (!item) return

      // @ts-ignore TS works only for first layer form values (title OK, detail.title NO)
      form.change(fieldName, item.long_name)
    },
    [form]
  )

  const handleAddressChange = useCallback(
    (address?: GeocodeAddress) => {
      if (!address) return

      changeForm("postal_code", "finance.postcode", address)
      changeForm("route", "finance.address_line_1", address)
      changeForm("postal_town", "finance.city", address)
      changeForm("administrative_area_level_2", "finance.county", address)
      changeForm("country", "finance.country", address)

      destroyModal()
    },
    [changeForm, destroyModal]
  )

  return (
    <ModalWrapper {...{ title: "Search for Address", width: 600, destroyModal }}>
      <Form {...{ onSubmit: () => ({}) }}>
        {({ handleSubmit }) => (
          <form {...{ onSubmit: handleSubmit }}>
            <Box display="flex" alignItems="baseline" my={3}>
              <Field
                {...{
                  component: TextField,
                  label: "Address / Postcode",
                  name: "address"
                }}
              />
              <FormSpy subscription={{ values: true }}>
                {({ values }: FormSpyRenderProps) => (
                  <AddressSearch
                    buttonProps={{
                      sx: (theme) => ({
                        flexShrink: 0,
                        marginLeft: theme.spacing(2)
                      })
                    }}
                    address={values.address}
                    form={form}
                    handleCustomFormChange={handleAddressChange}
                  />
                )}
              </FormSpy>
            </Box>
          </form>
        )}
      </Form>
      <Box display="flex" justifyContent="flex-end">
        <Button {...{ variant: "text", fullWidth: false, onClick: destroyModal }}>Cancel</Button>
      </Box>
    </ModalWrapper>
  )
})

export const BillingInformationAddressSearch: React.FC<OwnProps> = memo(({ form }) => {
  const [modal, setModal] = useState<ReactNode>(null)
  const destroyModal = () => setModal(null)

  const onClick = () => {
    setModal(<AddressModal {...{ destroyModal, form }} />)
  }

  return (
    <Box justifyContent="center">
      <Button {...{ onClick }}>SEARCH ADDRESS</Button>
      {modal}
    </Box>
  )
})

AddressModal.displayName = "AddressModal"
BillingInformationAddressSearch.displayName = "BillingInformationAddressSearch"
