import { Box } from "@mui/material"
import { EmptyData } from "components"
import { usePagination } from "components/handlers/usePagination"
import { useUserContractFileListQuery } from "data/finance/contractFile/queries"
import { UserContractType } from "data/finance/contractFile/types"
import { queryStringToObject } from "lib/queryString"
import React, { memo, useState } from "react"
import { ContractFileListFilter, defaultFilterValues, UserContractFileFilter } from "./ContractFileListFilter"
import { ContractFileListTable } from "./ContractFileListTable"
import { useLocation } from "react-router-dom"

interface OwnProps {
  userGuid: string
  contractGuid: string
  userType: UserContractType
}

export const ContractFileList = memo(({ userGuid, contractGuid, userType }: OwnProps) => {
  const location = useLocation()

  const [filter, setFilter] = useState<UserContractFileFilter>({
    ...queryStringToObject(location.search),
    ...defaultFilterValues // just for tabType
  })
  const { pagination, setPagination, resetPagination } = usePagination()

  const { data: files } = useUserContractFileListQuery({
    userGuid,
    contractGuid,
    search: location.search,
    userType
  })

  return (
    <Box>
      <ContractFileListFilter
        filter={filter}
        setFilter={setFilter}
        pagination={pagination}
        resetPagination={resetPagination}
      />
      {files?.length ? (
        <ContractFileListTable
          files={files}
          userGuid={userGuid}
          contractGuid={contractGuid}
          userType={userType}
          setPagination={setPagination}
        />
      ) : (
        <EmptyData message="No contract files found." />
      )}
    </Box>
  )
})

ContractFileList.displayName = "ContractFileList"
