import type { AppContext } from "pubnub"
import React, { SyntheticEvent, useEffect, useRef, useState } from "react"
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Typography,
  MenuList,
  Popper,
  Grow,
  ClickAwayListener,
  MenuItem
} from "@mui/material"
import { getNameFirstLetters } from "./utils"
import { MoreVert } from "@mui/icons-material"
import { Link } from "react-router-dom"
import { CustomChannelMetadata } from "./types"

interface Props {
  currentChannel: AppContext.ChannelMetadataObject<CustomChannelMetadata>
}

const ChatRoomHeader = ({ currentChannel }: Props) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const prevOpen = useRef(open)

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | SyntheticEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === "Escape") {
      setOpen(false)
    }
  }

  return (
    <Box sx={{ display: "flex", boxShadow: 4, p: 2, zIndex: 1, alignItems: "center" }}>
      <Avatar sx={{ bgcolor: "primary.main", mr: 2 }}>{getNameFirstLetters(currentChannel?.name ?? "")}</Avatar>
      <Box flex="1">
        <Typography variant="h5">{currentChannel?.name}</Typography>
      </Box>
      <IconButton
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{ ...(open && { bgcolor: "grey.100" }) }}
      >
        <MoreVert titleAccess="more options" />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role="menu" placement="bottom-start" transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom"
            }}
          >
            <Paper elevation={8}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem component={Link} to={`/carers/${currentChannel?.custom?.carerId}/profile/personal-details`}>
                    Go to profile
                  </MenuItem>
                  <MenuItem component={Link} to={`/carers/${currentChannel?.custom?.carerId}/visits`}>
                    Go to visits
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

export default ChatRoomHeader
