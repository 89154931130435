import React from "react"
import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
import { useIntercomQuery } from "data/dashboard/queries"
import { NavItem } from ".."
import Launch from "@mui/icons-material/Launch"
import { isNil } from "ramda"
import Config from "lib/config"

const StyledLaunch = styled(Launch)(() => ({
  marginRight: 10
}))

interface OwnProps {
  branchGuid?: string
  hasIntercomFlag: boolean
}

export const MainNavIntercom: React.FC<OwnProps> = ({ branchGuid, hasIntercomFlag }) => {
  const INTERCOM_ID = Config.getEnvVariable("APP_INTERCOM_INBOX_ID")

  const intercomEnabled = !!INTERCOM_ID && hasIntercomFlag

  const { data: messages } = useIntercomQuery({ enabled: intercomEnabled, branchGuid })

  if (!intercomEnabled) {
    return null
  }

  return (
    <NavItem
      to={`https://app.eu.intercom.com/a/inbox/${INTERCOM_ID}/inbox/`}
      label="Inbox"
      external
      target="IntercomTab"
      id="mainnav-intercom"
    >
      {!isNil(messages?.count) && (
        <>
          <Typography
            sx={{
              bgcolor: "error.main",
              borderRadius: "100px",
              color: "error.contrast",
              display: "inline-block",
              fontSize: 12,
              fontWeight: 500,
              height: 20,
              lineHeight: "20px",
              padding: "0px 6.5px",
              textAlign: "center",
              mr: 2
            }}
            component="span"
          >
            {messages?.count}
          </Typography>
          <StyledLaunch />
        </>
      )}
    </NavItem>
  )
}
