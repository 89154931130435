import React, { memo, useEffect } from "react"
import { ModalWrapper } from "components"
import { Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { Form, Field } from "react-final-form"
import { Button, FormSectionHeader, Select, TextField } from "components/Form"
import Spinner from "components/Spinner"
import CreditingSummary from "./CreditingSummary"
import validators from "lib/validators"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import { SummaryList } from "components/SummaryList"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { TaskMutationHandlers } from "data/finance/task/mutationHandlers"
import {
  TaskChargeReasonTypeAlias,
  TaskCreditTypeAlias,
  TaskPreparationCreditMode,
  TaskPreparationMode
} from "data/finance/task/types"
import { DateTime } from "luxon"
import { useSchema } from "data/schema/useSchema"
import { DateField } from "components/Form/DateField"
import { useApiMessage } from "stores"

interface CreditFormValues {
  credit_type_alias: TaskCreditTypeAlias
  charge_reason_type_alias: TaskChargeReasonTypeAlias
  note: string
  tax_point_date: DateTime
}

interface OwnProps {
  credit_mode?: TaskPreparationCreditMode
  modelType: ContractModelType
  periodGuid?: string
  transactionGuid?: string
  guids: string[]
  onCancel: Function
  onOk: Function
  isPeriod?: boolean
  isTransaction?: boolean
}

const getModalTitle = (mode?: TaskPreparationCreditMode) => {
  switch (mode) {
    case TaskPreparationCreditMode.ITEMS:
      return "Visit crediting"
    case TaskPreparationCreditMode.CHARGES:
      return "Extra crediting"
    default:
      return "Crediting"
  }
}

const CreditingModal: React.FC<OwnProps> = ({
  credit_mode,
  periodGuid,
  transactionGuid,
  guids,
  modelType,
  onCancel,
  onOk,
  isPeriod = false,
  isTransaction = false
}) => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const isInvoiceModel = modelType === ContractModelType.INVOICE

  const { TransactionCreditReason, TransactionCredit } = useSchema(["TransactionCreditReason", "TransactionCredit"])

  const { mutateAsync: prepareNewTask, data: preparationData } = TaskMutationHandlers.useTaskPrepare({ modelType })

  const {
    mutateAsync: processNewTask,
    isSuccess: isProcessSuccess,
    isLoading: isProcessLoading,
    isError: isProcessError
  } = TaskMutationHandlers.useTaskProcess({ modelType })

  const onSubmit = (values: CreditFormValues) => {
    if (!preparationData?.task_guid) return

    if (
      values.credit_type_alias === "CHG/ONLY" &&
      !window.confirm("Do you really want to credit without recalculation?")
    ) {
      return
    }

    processNewTask({
      ...values,
      mode: TaskPreparationMode.CREDIT,
      task_guid: preparationData.task_guid,
      is_draft: false
    })
  }

  useEffect(() => {
    if (!isInvoiceModel && !isTransaction && !transactionGuid) {
      showErrorMessage("Transaction ID not found.")
      return
    }
    if (!isPeriod && !periodGuid) {
      showErrorMessage("Period ID not found.")
      return
    }

    prepareNewTask({
      guids,
      mode: TaskPreparationMode.CREDIT,
      ...(!isPeriod ? { period_guid: periodGuid } : {}),
      ...(!isTransaction
        ? {
            transaction_guid: transactionGuid,
            credit_mode
          }
        : {})
    })
  }, [
    periodGuid,
    transactionGuid,
    guids,
    credit_mode,
    isInvoiceModel,
    isTransaction,
    isPeriod,
    prepareNewTask,
    showErrorMessage
  ])

  useEffect(() => {
    if (isProcessSuccess) {
      showSuccessMessage("Credit generated!")
      onOk()
    }
  }, [isProcessSuccess, onOk, showSuccessMessage])

  const initialValues = {
    credit_type_alias: "CHG+RPAY"
  }

  return (
    <ModalWrapper
      title={getModalTitle(credit_mode)}
      destroyModal={onCancel}
      width={800}
      data-cy={testingId.creditingModal.modal}
    >
      <RequestLoaderWrapper>
        <FormSectionHeader title="Selection summary" />
        {preparationData && (
          <>
            {isTransaction ? (
              <SummaryList
                {...{
                  items: preparationData.contracts,
                  headers: ["Invoice Model", "Clients", "£ Appointments", "£ Extras", "£ Total value"],
                  totalLabel: "Total clients:"
                }}
              />
            ) : (
              <CreditingSummary {...{ summary: preparationData, credit_mode, modelType }} />
            )}
          </>
        )}

        <FormSectionHeader title="Credit options" />
        <Form {...{ onSubmit, initialValues }}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Field
                    name="charge_reason_type_alias"
                    label="Credit note reason *"
                    component={Select}
                    options={TransactionCreditReason}
                    validate={validators.required}
                  />
                </Grid>
                <Grid item md={6}>
                  <Field
                    name="credit_type_alias"
                    label="Credit action *"
                    component={Select}
                    options={TransactionCredit}
                    validate={validators.required}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Field<string>
                    name="note"
                    component={TextField}
                    multiline
                    minRows={5}
                    label="Notes *"
                    validate={validators.required}
                  />
                </Grid>
                <Grid item md={6}>
                  <DateField name="tax_point_date" label="Tax point date" />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                {isProcessLoading && <Spinner />}
                {!isProcessLoading && !isProcessError && (
                  <>
                    <Button
                      variant="text"
                      fullWidth={false}
                      onClick={() => onCancel()}
                      sx={{
                        marginRight: "20px"
                      }}
                      data-cy={testingId.creditingModal.cancelButton}
                    >
                      CANCEL
                    </Button>
                    <Button
                      type="submit"
                      fullWidth={false}
                      data-cy={testingId.creditingModal.confirmButton}
                      disabled={submitting || !preparationData?.task_guid}
                    >
                      PROCESS CREDIT
                    </Button>
                  </>
                )}
                {isProcessError && (
                  <>
                    <Box m={3} mx={0} display="flex" justifyContent="center" flexDirection="column">
                      <Typography variant="h5">Something went wrong!</Typography>
                      <br />
                      <Typography paragraph>
                        We apologize, we were not able to complete your request. Please try again later. If the problem
                        persists, please contact the user support.
                      </Typography>
                    </Box>
                    <br />
                    <Box m={1} mx={0} display="flex" justifyContent="flex-end">
                      <Button
                        fullWidth={false}
                        variant="text"
                        onClick={() => onCancel()}
                        data-cy={testingId.creditingModal.closeButton}
                      >
                        Close
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </form>
          )}
        </Form>
      </RequestLoaderWrapper>
    </ModalWrapper>
  )
}

export default memo(CreditingModal)
