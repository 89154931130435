import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { EmptyData } from "components"
import { ContractModelService } from "data/finance/contractModel/types"
import { FormApi } from "final-form"
import { DateTimeFormat } from "lib/datetime"
import { optionsToValueTitle } from "lib/schema"
import React, { memo, useMemo } from "react"
import { ContractModelUplift, ContractModelServiceUplift, RateUpliftForm } from "../../types"
import { RateUpliftTwoServiceCheckbox } from "./RateUpliftTwoServiceCheckbox"
import { RateUpliftTwoServiceCheckboxAll } from "./RateUpliftTwoServiceCheckboxAll"
import { useSchema } from "data/schema/useSchema"

interface OwnProps {
  contract: ContractModelUplift
  form: FormApi<RateUpliftForm, Partial<RateUpliftForm>>
  services: ContractModelServiceUplift[]
  isInvoicePaymentService?: boolean
}

export const RateUpliftTwoTableService: React.FC<OwnProps> = memo(
  ({ form, contract, services, isInvoicePaymentService = false }) => {
    RateUpliftTwoTableService.displayName = "RateUpliftTwoTableService"

    const { PaymentType } = useSchema(["PaymentType"])
    const paymentAliasMap = optionsToValueTitle(PaymentType)

    const orderedServices: ContractModelServiceUplift[] = useMemo(() => {
      const validServices = services.filter((service) => !service.disabled)
      const invalidServices = services.filter((service) => service.disabled)

      return [...validServices, ...invalidServices]
    }, [services])

    return (
      <Box
        sx={{
          margin: 1,
          marginBottom: 1
        }}
      >
        {isInvoicePaymentService && (
          <Typography
            sx={{
              paddingTop: 4,
              fontWeight: "bold"
            }}
          >
            Payment Services
          </Typography>
        )}
        {orderedServices.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <RateUpliftTwoServiceCheckboxAll {...{ contractGuid: contract.guid, form, isInvoicePaymentService }} />
                <TableCell>Service</TableCell>
                <TableCell>Price type</TableCell>
                <TableCell>Starts on</TableCell>
                <TableCell>Ends before</TableCell>
                {isInvoicePaymentService && <TableCell>Override</TableCell>}
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderedServices.map((service: ContractModelService) => (
                <TableRow key={service.guid}>
                  <RateUpliftTwoServiceCheckbox {...{ service, contractModelGuid: contract.guid }} />
                  <TableCell>{service.service_type.title}</TableCell>
                  <TableCell>{paymentAliasMap[service.payment_type_alias]}</TableCell>
                  <TableCell>{service.start.toFormat(DateTimeFormat.DATE)}</TableCell>
                  <TableCell>{service.end ? service.end.toFormat(DateTimeFormat.DATE) : "/ /"}</TableCell>
                  {isInvoicePaymentService && <TableCell>{service.payment_contract_title || "-"}</TableCell>}
                  <TableCell>{service.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyData message="This model does not contain any Service" />
        )}
      </Box>
    )
  }
)
