import React from "react"
import Menu from "@mui/material/Menu"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"
import Box from "@mui/material/Box"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

interface Props {
  children: React.ReactNode
  title: string
}

const LinkMenu: React.FC<Props> = ({ title, children }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: theme.spacing(2),
          py: theme.spacing(1)
        })}
        onClick={handleClick}
      >
        <ListItemText primary={title} />
        <ListItemIcon>
          <KeyboardArrowDownIcon style={{ color: "#FFF" }} />
        </ListItemIcon>
      </Box>
      <Menu
        sx={(theme) => ({ left: theme.spacing(2) })}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        slotProps={{
          paper: {
            style: {
              width: "25ch"
            }
          }
        }}
      >
        {React.Children.toArray(children).map((child) =>
          React.cloneElement(child as React.ReactElement, { onClick: handleClose })
        )}
      </Menu>
    </>
  )
}

export default React.memo(LinkMenu)
