import type { AppContext, History } from "pubnub"
import { useInfiniteQuery, useQuery } from "react-query"
import { useChat } from "./ChatProvider"
import { Contact, CustomChannelMetadata, StandardMessage } from "./types"
import { ACTION_TYPES, ACTION_VALUES } from "./constants"
import { getAllTimeTokens, getCurrentPubnubTimestamp, setTimeTokenByChannel } from "./utils"
import { commsApi } from "api"
import { AxiosProgressEvent } from "axios"

const generateFilterForQuery = (channelsQueryString: string, idsToFilter: string[]) =>
  idsToFilter?.length > 0
    ? `${idsToFilter
        .map((id) => {
          const [type, , carer_guid] = id.split(".")
          return `id LIKE '${type}.*.${carer_guid}'`
        })
        .join(" || ")}`
    : `id LIKE '${channelsQueryString}'`

export const useGetChannels = (channelsQueryString: string, carerIds: string[]) => {
  const pubnub = useChat()
  return useQuery<{}, Error, AppContext.ChannelMetadataObject<CustomChannelMetadata>[]>({
    keepPreviousData: true,
    queryKey: ["channels", channelsQueryString, carerIds],
    queryFn: async () => {
      const { data } = await pubnub.objects.getAllChannelMetadata({
        sort: { updated: "desc" },
        include: {
          customFields: true,
          totalCount: true
        },
        filter: generateFilterForQuery(channelsQueryString, carerIds)
      })
      return data
    }
  })
}

export const useGetUnreadCount = (
  channels: AppContext.ChannelMetadataObject<CustomChannelMetadata>[],
  currentChannelId?: string
) => {
  const pubnub = useChat()
  return useQuery<{}, Error, History.MessageCountResponse>({
    keepPreviousData: true,
    queryKey: ["channels-unread-count", channels, currentChannelId],
    queryFn: async () => {
      if (!channels.length) return { channels: {} }

      if (currentChannelId) {
        setTimeTokenByChannel(pubnub.userId, currentChannelId, getCurrentPubnubTimestamp())
      }

      const timeTokens = getAllTimeTokens(pubnub.userId)

      return await pubnub.messageCounts({
        channels: channels.map(({ id }) => id.replace("chat.", "carer.")),
        channelTimetokens: channels.map(({ id }) => String(timeTokens[id] ?? getCurrentPubnubTimestamp()))
      })
    }
  })
}

export const useGetMessages = (channelId: string) => {
  const pubnub = useChat()
  return useInfiniteQuery<{}, Error, History.FetchMessagesWithActionsResponse>({
    queryKey: [`channel-${channelId}-messages`],
    queryFn: async ({ pageParam }) => {
      const data = await pubnub.fetchMessages({
        channels: [channelId],
        includeMessageActions: true,
        includeMeta: true,
        start: pageParam ?? getCurrentPubnubTimestamp(),
        stringifiedTimeToken: true
      })

      ;(data as History.FetchMessagesWithActionsResponse)?.channels[channelId]?.forEach(async (value) => {
        const isNotRead = !value.actions?.delivered?.read && (value.message as StandardMessage).sender.isUser

        if (isNotRead) {
          try {
            const action = await pubnub.addMessageAction({
              channel: channelId,
              messageTimetoken: String(value.timetoken),
              action: {
                type: ACTION_TYPES.DELIVERED,
                value: ACTION_VALUES.READ
              }
            })

            value.actions = {
              delivered: {
                read: [action.data]
              }
            }
          } catch (error) {
            console.error(error)
          }
        }
      })
      return data
    }
  })
}

export const useCommsCarersQuery = ({
  regionIds,
  enabled = true,
  retries
}: {
  regionIds: string[]
  enabled?: boolean
  retries?: number
}) =>
  useQuery<{}, Error, { name: string; chat_channel_id: string }[]>({
    enabled,
    queryKey: ["comms-carers", regionIds],
    retry: retries,
    queryFn: () => commsApi.getCarers(regionIds),
    keepPreviousData: true
  })

export const useChannelContactsListQuery = () =>
  useQuery<{}, Error, Contact[]>({
    queryKey: "chat-contacts",
    queryFn: async () => commsApi.getContacts()
  })

export const useGetImage = (id: string, onDownloadProgress: (progressEvent: AxiosProgressEvent) => void) =>
  useQuery<{}, Error, { data: Blob }>({
    queryKey: ["message-image", id],
    queryFn: () => commsApi.getImage(id, onDownloadProgress)
  })
