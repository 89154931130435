import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"
import ErrorIcon from "@mui/icons-material/Error"
import { Divider } from "components"
import { SubmissionErrors, ValidationErrors } from "final-form"
import { ContractModelRuleGroupRuleFormData } from "pages/Finance/ContractModels/helpers/RuleGroupRule"
import React, { memo, useEffect, useState } from "react"
import { FormSpy } from "react-final-form"
import { deepObjectEqual } from "lib/utils"
import RuleGroupFormTabPanel from "./RuleGroupFormTabPanel"
import testingId from "constants/testingId"
import {
  ContractRuleConditionOptionsByType,
  ContractRuleConditionTypeAlias
} from "data/finance/contractRuleCondition/types"

interface Props {
  ruleGroupRules: ContractModelRuleGroupRuleFormData[]
  conditions: ContractRuleConditionOptionsByType
}

const RuleGroupFormRuleTable: React.FC<Props> = ({ conditions, ruleGroupRules }) => {
  const [formRules, setFormRules] = useState<ContractModelRuleGroupRuleFormData[]>(ruleGroupRules)
  const [value, setValue] = useState<number>(0)

  useEffect(() => {
    if (!deepObjectEqual(ruleGroupRules, formRules)) {
      setFormRules(ruleGroupRules)
    }
  }, [ruleGroupRules]) // eslint-disable-line

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const createNewRule = (type: ContractRuleConditionTypeAlias) =>
    ({
      condition_guids: [],
      rule_condition_map: [],
      rule_type_alias: type,
      charge_type_alias: "ZERO"
    } as ContractModelRuleGroupRuleFormData)

  // TODO: redo to use FieldArray same as in ClientContractStandingCharges
  const addNewRule = (type: ContractRuleConditionTypeAlias) => () => {
    setFormRules([...formRules, createNewRule(type)])
  }

  const hasRuleError = (
    type: ContractRuleConditionTypeAlias,
    submitErrors: SubmissionErrors,
    validationErrors: ValidationErrors
  ) => {
    const hasError = (errorObject: ValidationErrors | SubmissionErrors) => {
      if (!errorObject || Object.keys(errorObject).length === 0) return false

      if (!errorObject.rules) {
        return false
      }

      return Object.keys(errorObject.rules).map((fieldName) => errorObject.rules[fieldName]?.rule_type_alias === type)
    }

    // For generic submission errors based on rule type just report true
    if (submitErrors && submitErrors[type].length > 0) {
      return true
    }

    // No generic errors, look into rules mentioned in error objects
    const hasValidationError = hasError(validationErrors)
    const hasSubmissionError = hasError(submitErrors)

    return [...(hasSubmissionError || []), ...(hasValidationError || [])].includes(true)
  }

  return (
    <>
      <Box my={4} />
      <Typography variant="h5" component="h2">
        Rules
      </Typography>
      <Divider color="divider" />
      <FormSpy subscription={{ submitErrors: true, errors: true }}>
        {({ submitErrors, errors }) => (
          <>
            <AppBar
              position="static"
              color="transparent"
              sx={(theme) => ({
                boxShadow: "none",
                borderBottom: "1px solid",
                borderColor: theme.palette.secondary.main
              })}
            >
              <Tabs value={value} onChange={handleChange} aria-label="Rules">
                <Tab
                  label={
                    <>
                      Confirmed visit
                      {hasRuleError(ContractRuleConditionTypeAlias.CONF, submitErrors, errors) && (
                        <ErrorIcon color="error" />
                      )}
                    </>
                  }
                  id="rules-tab-0"
                  data-cy={testingId.invoiceModel.rules.confirmedVisit}
                />
                <Tab
                  label={
                    <>
                      Cancellations
                      {hasRuleError(ContractRuleConditionTypeAlias.CANCEL, submitErrors, errors) && (
                        <ErrorIcon color="error" />
                      )}
                    </>
                  }
                  id="rules-tab-1"
                  data-cy={testingId.invoiceModel.rules.cancellationTab}
                />
              </Tabs>
            </AppBar>

            <RuleGroupFormTabPanel
              value={value}
              index={0}
              rules={formRules}
              conditions={conditions[ContractRuleConditionTypeAlias.CONF]}
              addRule={addNewRule(ContractRuleConditionTypeAlias.CONF)}
              ruleType={ContractRuleConditionTypeAlias.CONF}
              errors={submitErrors ? submitErrors[ContractRuleConditionTypeAlias.CONF] : undefined}
            />
            <RuleGroupFormTabPanel
              value={value}
              index={1}
              rules={formRules}
              conditions={conditions[ContractRuleConditionTypeAlias.CANCEL]}
              ruleType={ContractRuleConditionTypeAlias.CANCEL}
              addRule={addNewRule(ContractRuleConditionTypeAlias.CANCEL)}
              errors={submitErrors ? submitErrors[ContractRuleConditionTypeAlias.CANCEL] : undefined}
            />
          </>
        )}
      </FormSpy>
    </>
  )
}

export default memo(RuleGroupFormRuleTable)
