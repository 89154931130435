import { ApiUserCarer } from "models"
import NotFound from "pages/NotFound"
import React, { memo } from "react"
import { CarerAccrualDetail } from "./CarerAccrual/CarerAccrualDetail"
import { CarerAccrualList } from "./CarerAccrual/CarerAccrualList"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

interface OwnProps {
  carer: ApiUserCarer
}

export const CarerAccrual: React.FC<OwnProps> = memo(({ carer }) => {
  CarerAccrual.displayName = "CarerAccrual"

  return (
    <SentryRoutes>
      <Route path="/" element={<CarerAccrualList carer={carer} />} />
      <Route path="/:id" element={<CarerAccrualDetail carer={carer} />} />
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  )
})
