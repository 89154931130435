import { Box, styled, Typography } from "@mui/material"
import { EmptyData } from "components"
import { InvoiceFileItem } from "data/finance/invoiceFile/types"
import { usePayrollEmployeeTransactionDetailQuery } from "data/payroll/queries"
import React, { memo, useMemo } from "react"
import { FileManagementTablePayrollTable } from "./FileManagementTablePayrollTable"
import { FileManagementTablePayrollError } from "./FileManagementTablePayrollError"
import { sortITrentUploads } from "../helpers/sortITrentUploads"

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  paddingLeft: theme.spacing(2)
}))

interface OwnProps {
  file: InvoiceFileItem
}

export const FileManagementTablePayroll: React.FC<OwnProps> = memo(({ file }) => {
  FileManagementTablePayroll.displayName = "FileManagementTablePayroll"

  const { data: historyItem } = usePayrollEmployeeTransactionDetailQuery({
    transactionGuid: file.guid,
    employeeGuid: file.user_guid
  })

  const recentUpload = useMemo(() => sortITrentUploads(historyItem?.uploads)[0], [historyItem?.uploads])

  return (
    <Box>
      <StyledTypography variant="h6">Payroll history</StyledTypography>
      {historyItem && recentUpload?.status === "Failed" ? (
        <FileManagementTablePayrollError recentUpload={recentUpload} file={file} historyItem={historyItem} />
      ) : null}
      {historyItem ? (
        <FileManagementTablePayrollTable historyItem={historyItem} file={file} />
      ) : (
        <EmptyData message="No history files found." />
      )}
    </Box>
  )
})
