import { Box, Grid } from "@mui/material"
import { ClearFilterButton } from "components/ClearFilterButton"
import { FormSpyCustom, Select, TextField } from "components/Form"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { ShiftListFilterProps } from "types/Shifts/ShiftList/types"
import { useDebouncedCallback } from "use-debounce"
import ShiftListFilterCarer from "./ShiftListFilterCarer"
import { getOptionsFromSchema } from "lib/schema"
import { useCoreSchemasStore } from "stores"

interface Props {
  setFilters: Dispatch<SetStateAction<{}>>
  filters: ShiftListFilterProps
}

const ShiftListFilter: React.FC<Props> = ({ setFilters, filters }) => {
  const { ApiUserPatientFilter } = useCoreSchemasStore((state) => state.schema.models)

  const regionOptions = getOptionsFromSchema(ApiUserPatientFilter, "region")

  const onSubmit = useDebouncedCallback((values: ShiftListFilterProps) => {
    setFilters(values)
  }, 300)

  return (
    <Box m={3} mx={0}>
      <Form onSubmit={onSubmit} initialValues={filters}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={3}>
                <Field name="shift_name" label="Shift Name" component={TextField} />
              </Grid>
              <ShiftListFilterCarer />
              <Grid item md={3}>
                <Field name="region" label="Region" component={Select} options={regionOptions} />
              </Grid>
              <Grid item md={3}>
                <Box textAlign="right">
                  <ClearFilterButton onClick={() => setFilters({})} disabled={Object.keys(filters).length === 0} />
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </Box>
  )
}

export default memo(ShiftListFilter)
