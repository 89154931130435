import { InvoiceTransaction } from "data/finance/invoiceProcessing/types"

const common = ["", "Visit date", "Start (R)", "Carer", "Service type"] // is for status column

const pendingReadyLabels = [...common, "Status", "Chargeable hours", "Chargeable", "£ Value"]
const invoicedLabels = [...common, "Chargeable hours", "Processed", "£ Value", "Credit"]
const creditedLabels = [...common, "Chargeable hours", "Processed", "Reason", "£ Value"]

export const getItemsTableHeaderLabels = ({ transaction }: { transaction: InvoiceTransaction }): string[] => {
  switch (transaction.status_type_alias) {
    case "PENDING":
    case "READY":
    case "NOCHARGE":
      return pendingReadyLabels
    case "COMPLETE":
      return invoicedLabels
    case "CREDITED":
      return creditedLabels
  }
}
