import React, { memo } from "react"
import { ModalWrapper } from "components"
import { Button } from "components/Form"
import { Grid, Typography, Box } from "@mui/material"
import { AccrualStats } from "data/finance/development/mutations"

type OwnProps = {
  accrualStats: AccrualStats
  onCancel: () => void
}

export const CarerAccrualDetailStatsModal: React.FC<OwnProps> = memo(({ onCancel, accrualStats }) => {
  CarerAccrualDetailStatsModal.displayName = "CarerAccrualDetailStatsModal"

  return (
    <ModalWrapper destroyModal={onCancel} width={600}>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Typography variant="h6" align="center">
          Accrual Stats
        </Typography>
      </Box>
      {Object.entries(accrualStats.data).map(([key, value]) => (
        <Grid
          key={key}
          sx={{
            textTransform: "capitalize",
            fontSize: "medium"
          }}
        >
          {key.replace("_", " ")} - {value}
        </Grid>
      ))}
      <Box m={3} mx={0} display="flex" justifyContent="center" flexDirection="column">
        <Button fullWidth={false} variant="text" onClick={() => onCancel()}>
          Close
        </Button>
      </Box>
    </ModalWrapper>
  )
})
