import coreApi, { coreApiInstance } from "./core"
import { downloadApiInstance } from "./download"
import { financeApiInstance } from "./finance"
import { reportApiInstance } from "./report"
import { carerProfileApiInstance } from "./carerProfile"
import { companyApiInstance } from "./company"
import { dashboardApiInstance } from "./dashboard"
import communicationsApi from "./communications"
import { newFinanceApiInstance } from "./newFinance"
import geoCodingApi, { geoCodingApiInstance } from "./geoCoding"
import { payrollApiInstance } from "./payroll"
import { fileApiInstance } from "./file"
import { mobileApiInstance } from "./mobileApi"
import { healthApiInstance } from "./health"
import { identityApiInstance } from "./identity"

export const CoreApi = coreApiInstance
export const FinanceApi = financeApiInstance
export const ReportApi = reportApiInstance
export const DownloadApi = downloadApiInstance
export const CarerProfileApi = carerProfileApiInstance
export const CompanyApi = companyApiInstance
export const DashboardApi = dashboardApiInstance
export const commsApi = communicationsApi
export const NewFinanceApi = newFinanceApiInstance
export const PayrollApi = payrollApiInstance
export const FileApi = fileApiInstance
export const MobileApi = mobileApiInstance
export const GeoCodingApi = geoCodingApiInstance
export const HealthApi = healthApiInstance
export const IdentityApi = identityApiInstance

const API = {
  ...coreApi,
  ...commsApi,
  ...geoCodingApi,
  ...payrollApiInstance,
  ...geoCodingApi
}

export default API
