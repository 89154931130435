import { Box, Grid } from "@mui/material"
import { ModalWrapper } from "components"
import { Button, Select, TextField } from "components/Form"
import React, { memo, useCallback } from "react"
import { Field, Form } from "react-final-form"
import { DateTime } from "luxon"
import { useInvoiceTransactionChargeUpdateMutation } from "data/finance/invoiceProcessing/mutations"
import testingId from "constants/testingId"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { DateField } from "components/Form/DateField"
import { DEFAULT_MAX_NUMBER, validateCommonNumber } from "lib/validators"
import { useQueryClient } from "react-query"
import { queryKeys } from "data/finance/invoiceProcessing/queries"
import { useUIStore } from "stores"
import { InvoiceTransactionCharge } from "data/finance/invoiceProcessing/types"

interface Props {
  destroyModal: () => void
  transactionGuid: string
  periodGuid: string
  charge: InvoiceTransactionCharge
}

interface FormValues {
  credit_reason: string
  date: DateTime
  total: string
  tax: string
}

interface SubmitHandlerProps {
  transactionGuid: string
  charge: InvoiceTransactionCharge
  periodGuid: string
  destroyModal: () => void
}

const useSubmitHandler = ({ transactionGuid, periodGuid, charge, destroyModal }: SubmitHandlerProps) => {
  const { mutateAsync: updateInvoiceTransactionCharge } = useInvoiceTransactionChargeUpdateMutation()

  const queryCache = useQueryClient()

  const showErrorMessage = useUIStore((state) => state.showErrorMessage)

  return useCallback(
    async (values: FormValues) => {
      const payload = {
        charge_date: charge.date,
        charge_type_alias: charge.charge_type_alias,
        description: charge.description,
        quantity: 1,
        total: Number(values.total)
      }

      try {
        await updateInvoiceTransactionCharge({
          charge: payload,
          transactionGuid,
          periodGuid,
          chargeGuid: charge.guid
        })
        queryCache.invalidateQueries(
          queryKeys.getInvoiceProcessingTransactionChargeListKey({ transactionGuid, periodGuid })
        )
        destroyModal()
      } catch (apiError: any) {
        showErrorMessage("Could not process contribution.", { apiError })
      }
    },
    [transactionGuid, periodGuid, charge, updateInvoiceTransactionCharge, destroyModal, queryCache, showErrorMessage]
  )
}

const ContributionModal: React.FC<Props> = ({ destroyModal, transactionGuid, periodGuid, charge }) => {
  const onSubmit = useSubmitHandler({ transactionGuid, charge, periodGuid, destroyModal })

  const initialValues = {
    total: charge.total,
    credit_reason: charge.credit_reason || charge.description,
    date: charge.date,
    tax: 0
  }

  return (
    <ModalWrapper
      title="Expense"
      destroyModal={destroyModal}
      data-cy={testingId.invoiceProcessingChargeModal.form}
      width={800}
    >
      <RequestLoaderWrapper>
        {charge && (
          <Form onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit, pristine }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item md={5}>
                      <Field name="credit_reason" label="Reason" component={TextField} disabled />
                    </Grid>
                    <Grid item md={3}>
                      <DateField name="date" label="Date" disabled />
                    </Grid>
                    <Grid item md={2}>
                      <Field
                        component={Select}
                        label="Taxable"
                        name="tax"
                        disabled
                        options={[
                          { title: "No", value: 0 },
                          { title: "Yes", value: 1 }
                        ]}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <Field
                        name="total"
                        label="Cost"
                        component={TextField}
                        validate={validateCommonNumber({ min: -DEFAULT_MAX_NUMBER })}
                      />
                    </Grid>
                  </Grid>
                  <Box m={3} mx={0} display="flex" justifyContent="flex-end">
                    <Button fullWidth={false} variant="text" onClick={destroyModal}>
                      Cancel
                    </Button>
                    <Button fullWidth={false} color="primary" type="submit" disabled={pristine}>
                      Save
                    </Button>
                  </Box>
                </form>
              )
            }}
          </Form>
        )}
      </RequestLoaderWrapper>
    </ModalWrapper>
  )
}

export default memo(ContributionModal)
