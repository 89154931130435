import React, { useCallback, useState } from "react"
import { FieldRenderProps } from "react-final-form"
import TextField from "@mui/material/TextField"
import testingId from "constants/testingId"
import { isNil } from "ramda"
import WarningMessage from "components/WarningMessage"
import { InputAdornment } from "@mui/material"

const TextFieldWrapper: React.FC<FieldRenderProps<string, any>> = (props: FieldRenderProps<string, any>) => {
  const [warningMessage, setWarningMessage] = useState(null)
  const { input, meta, instantValidation, warningValidators, inputProps, helperText, endAdornment, ...rest } = props
  const { onChange: handleChange } = input
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && (instantValidation || meta.touched)

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!isNil(warningValidators)) {
        // @ts-ignore
        setWarningMessage(warningValidators(event.target.value))
      }
      handleChange(event)
    },
    [warningValidators, handleChange]
  )
  return (
    <>
      <TextField
        {...input}
        fullWidth
        size="medium"
        margin="normal"
        variant="outlined"
        autoComplete="off"
        helperText={showError ? meta.error || meta.submitError : helperText}
        error={showError}
        data-cy={testingId.textField}
        onChange={onChange}
        inputProps={inputProps}
        {...rest}
        InputProps={
          endAdornment
            ? {
                endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>
              }
            : {}
        }
      />
      {!isNil(warningMessage) && <WarningMessage>{warningMessage}</WarningMessage>}
    </>
  )
}

export default TextFieldWrapper
