import PermissionDenied from "app/PermissionDenied"
import Auth from "lib/Auth"
import React from "react"
import Alerts from "./Finance/Alerts/AlertList"
import ContractModels from "./Finance/ContractModels"
import FileManagement from "./Finance/FileManagement"
import InvoiceProcessing from "./Finance/InvoiceProcessing"
import PaymentProcessing from "./Finance/PaymentProcessing"
import ProcessingStatus from "./Finance/ProcessingStatus"
import NotFound from "./NotFound"
import { InterfaceFileScreen } from "./Finance/InterfaceFile/InterfaceFileScreen"
import { Permission } from "constants/permission"
import { Import } from "./Finance/Import"
import { AdditionalSearch } from "./Finance/AdditionalSearch/AdditionalSearch"
import { Route } from "react-router-dom"
import AppointmentSearchList from "./Finance/AppointmentSearch/AppointmentSearchList"
import SentryRoutes from "components/Routes"

const Finance: React.FC = () => {
  if (
    !Auth.hasPermission(["Finance.Model:Edit", "Finance.File:Read", "Finance.Processing:Read", "Finance.Alert:Read"])
  ) {
    return <PermissionDenied />
  }

  return (
    <SentryRoutes>
      {Auth.hasPermission([Permission.FINANCE_SEARCH_READ, Permission.FINANCE_APPOINTMENT_READ]) && (
        <Route path="/appointment-search" element={<AppointmentSearchList />} />
      )}
      {Auth.hasPermission([Permission.FINANCE_PROCESSING_CHARGES_APPROVE_READ, Permission.HR_APPROVER_READ]) && (
        <Route path="/additionals-management" element={<AdditionalSearch />} />
      )}
      {Auth.hasPermission([Permission.FINANCE_MODEL_EDIT]) && (
        <Route path="/contract-models/*" element={<ContractModels />} />
      )}
      {Auth.hasPermission(["Finance.File:Read"]) && <Route path="/file-management/*" element={<FileManagement />} />}
      {Auth.hasPermission(["Finance.File:Read"]) && (
        <Route path="/interface-files/*" element={<InterfaceFileScreen />} />
      )}
      {Auth.hasPermission(["Finance.Processing:Read"]) && (
        <>
          <Route path="/invoice-processing/*" element={<InvoiceProcessing />} />
          <Route path="/payment-processing/*" element={<PaymentProcessing />} />
          <Route path="/processing-status/*" element={<ProcessingStatus />} />
        </>
      )}
      {Auth.hasPermission(["Finance.Alert:Read"]) && <Route path="/alerts/*" element={<Alerts />} />}
      {Auth.hasPermission(["Finance:Edit"]) && <Route path="/import" element={<Import />} />}
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  )
}

export default Finance
