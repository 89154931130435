import List from "@mui/material/List"
import FaceIcon from "@mui/icons-material/Face"
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks"
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext"
import PaymentIcon from "@mui/icons-material/Payment"

import { DetailSidebar, NavItem } from "components"
import { WithPermission } from "components/Auth"
import Avatar from "components/Avatar"
import { ApiUserCarer } from "models"
import React, { memo } from "react"
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation"
import WorkOffOutlinedIcon from "@mui/icons-material/WorkOffOutlined"
import UpdateIcon from "@mui/icons-material/Update"
import EventNoteIcon from "@mui/icons-material/EventNote"
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet"
import DateRangeIcon from "@mui/icons-material/DateRange"
import { path } from "ramda"
import { PermIdentity } from "@mui/icons-material"
import Auth from "lib/Auth"
import { PermissionGroup } from "constants/permission"
import useBranchSettings from "lib/hooks/branchInfo/useBranchSettings"

interface OwnProps {
  hasCarerProfileAvailabilityFlag: boolean
  hasSchedulerFlag: boolean
  carer: ApiUserCarer
}

export const CarerDetailNavBar: React.FC<OwnProps> = memo(
  ({ hasCarerProfileAvailabilityFlag, hasSchedulerFlag, carer }) => {
    CarerDetailNavBar.displayName = "CarerDetailNavBar"

    const careplannerUrl = `${path(["branch", "care_planner_url"], carer)}/roster/${carer.care_planner_internal_id}`

    const carerAbsencesUrl = `/carers/${carer.guid}/carer-absences`

    const { settings: branchSettings } = useBranchSettings()
    const { FeatureFinanceTeamCarerEmployment } = branchSettings
    const hasCarerEmploymentFlag = FeatureFinanceTeamCarerEmployment?.toLowerCase() === "true"
    const isFinanceAdmin = Auth.hasPermissionGroup(PermissionGroup.FINANCE_ADMIN)
    const showEmploymentPage = hasCarerEmploymentFlag && isFinanceAdmin

    return (
      <DetailSidebar>
        <Avatar
          sx={(theme) => ({
            margin: theme.spacing(2, 0, 4, 0),
            marginLeft: "auto",
            marginRight: "auto",
            height: "auto",
            maxHeight: "50%",
            maxWidth: "50%",
            width: "auto",
            transition: "all 0.1s linear"
          })}
          user={carer}
        />
        <List component="nav">
          <NavItem
            id="carer-details-nav-profile-link"
            to={`/carers/${carer.guid}/profile/personal-details`}
            icon={<FaceIcon color="primary" />}
            label="Profile"
          />
          <WithPermission permissions={["Finance.UserContract:Edit"]}>
            <NavItem
              id="carer-details-nav-payments-link"
              to={`/carers/${carer.guid}/contracts`}
              icon={<AccountBalanceWalletIcon color="primary" />}
              label="Payments"
            />
          </WithPermission>
          <NavItem
            id="carer-details-nav-absences-link"
            onClick={() => {
              window.dispatchEvent(new PopStateEvent("absencePop", { state: carerAbsencesUrl }))
            }}
            to={carerAbsencesUrl}
            label="Absences"
            icon={<WorkOffOutlinedIcon color="primary" />}
          />
          {hasCarerProfileAvailabilityFlag && (
            <WithPermission permissions={["User.Carer.Availability:Read"]}>
              <NavItem
                id="carer-details-nav-availability-link"
                to={`/carers/${carer.guid}/availability`}
                label="Availability"
                icon={<UpdateIcon color="primary" />}
              />
            </WithPermission>
          )}
          <NavItem
            id="carer-details-nav-accruals-link"
            to={`/carers/${carer.guid}/accruals`}
            label="Accrual"
            icon={<EventNoteIcon color="primary" />}
          />
          <NavItem
            id="carer-details-nav-pay-advice-link"
            to={`/carers/${carer.guid}/payslips`}
            label="Care Pay Breakdown"
            icon={<PaymentIcon {...{ color: "primary" }} />}
          />
          <NavItem
            id="carer-details-nav-visits-link"
            to={`/carers/${carer.guid}/visits`}
            icon={<InsertInvitationIcon color="primary" />}
            label="Visits"
          />
          <NavItem
            id="carer-details-nav-documents-link"
            to={`/carers/${carer.guid}/documents`}
            icon={<LibraryBooksIcon color="primary" />}
            label="Documents"
          />
          {hasSchedulerFlag && (
            <WithPermission permissions={["Scheduling.Appointment:Read"]}>
              <NavItem
                id="carer-details-nav-calendar-view-link"
                to={`/carers/${carer.guid}/scheduler-carer-weekly`}
                icon={<DateRangeIcon color="primary" />}
                label="Calendar view"
              />
            </WithPermission>
          )}
          <NavItem
            id="carer-details-nav-care-planner-profile-link"
            to={careplannerUrl}
            icon={<QueuePlayNextIcon color="primary" />}
            label="CarePlanner profile"
            external
          />
          {showEmploymentPage && (
            <NavItem
              id="carer-details-nav-employment-identity-link"
              to={`/carers/${carer.guid}/employment-identity`}
              icon={<PermIdentity style={{ color: "#C61E9D" }} />} // temporary until DCP uses shared theme
              label="Employment ID"
            />
          )}
        </List>
      </DetailSidebar>
    )
  }
)
