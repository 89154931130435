import { useClientContractQuery } from "data/finance/client/queries"
import { InvoiceTransaction } from "data/finance/invoiceProcessing/types"
import { useShiftCategoryContractQuery } from "data/finance/shiftCategory/queries"
import { useUnitContractDetailQuery } from "data/finance/unit/queries"
import { BasicPeriod } from "lib/types"
import { useMemo } from "react"

type OwnProps = {
  transaction: InvoiceTransaction
}

export const useGetChargeContractDates = ({ transaction }: OwnProps): BasicPeriod | undefined => {
  const { data: userContract } = useClientContractQuery({
    clientGuid: transaction.owner_guid,
    contractGuid: transaction.user_contract_guid,
    enabled: transaction.owner_type === "User"
  })
  const { data: shiftContract } = useShiftCategoryContractQuery({
    shiftCategoryGuid: transaction.owner_guid,
    userContractGuid: transaction.user_contract_guid,
    enabled: transaction.owner_type === "Shift"
  })
  const { data: unitContract } = useUnitContractDetailQuery({
    unitGuid: transaction.owner_guid,
    userContractGuid: transaction.user_contract_guid,
    enabled: transaction.owner_type === "Unit"
  })

  return useMemo(() => {
    if (transaction.owner_type === "User") return userContract
    if (transaction.owner_type === "Shift") return shiftContract
    if (transaction.owner_type === "Unit") return unitContract
    // returns undefined for Unassigned
  }, [transaction, userContract, shiftContract, unitContract])
}
