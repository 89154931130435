import { DateTime } from "luxon"

type AppointmentAssigneeTypeAlias = "CARER1" | "CARER2" | "NONE" | "SHADOW"
export enum AppointmentTypeAlias {
  SHIFT = "SHIFT",
  VISIT = "VISIT"
}

export interface AppointmentAssigneeRequest {
  confirmed: boolean
  contract_guid: string
  variation_guid: string
  service_guid: string
}

interface AppointmentAssigneeContract {
  guid: string
  title: string
}

export interface AppointmentAssignee {
  guid: string
  appointment_guid: string
  appointment_reference?: string
  service_guid: string
  service_title: string
  available_contracts: AppointmentAssigneeContract[]
  carer_guid: string
  carer_name: string
  check_in_at?: DateTime
  check_out_at?: DateTime
  confirmed: boolean
  contract_guid?: string
  contract_title?: string
  type_alias: AppointmentAssigneeTypeAlias
  variation_guid: string
  variation_title: string
}

export interface Appointment {
  guid: string
  assignees?: AppointmentAssignee[]
  cancellation_reason_guid?: string
  cancellation_reason_title?: string
  end: DateTime
  owner_guid: string
  owner_name: string
  owner_type: string
  service_guid: string
  service_title: string
  start: DateTime
  
  type_alias: AppointmentTypeAlias
}

export interface AppointmentPatchRequest {
  confirmed?: boolean
  contract_guid?: string
  user_appointment_guids: string[]
  variation_guid?: string
  service_guid?: string
}

export interface AppointmentPatchResponse {
  successful_user_appointment_guids: string[]
  failed_user_appointment_guids: string[]
}
