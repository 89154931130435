import { Box, Grid } from "@mui/material"
import { Button, TextField } from "components/Form"
import { useCarerBankDetailsCreateMutation, useCarerBankDetailsUpdateMutation } from "data/finance/carer/mutations"
import { useCarerBankDetailsQuery } from "data/finance/carer/queries"
import { ApiCarerBankDetails, ApiCarerBankDetailsRequest } from "data/finance/carer/types"
import Auth from "lib/Auth"
import validators from "lib/validators"
import { ApiUserCarer } from "models"
import React, { memo } from "react"
import { Field, Form, FormRenderProps } from "react-final-form"

interface OwnProps {
  carer: ApiUserCarer
}

export const CarerBankInfo: React.FC<OwnProps> = memo(({ carer }) => {
  CarerBankInfo.displayName = "CarerBankInfo"

  const canEdit = Auth.hasPermission(["Finance.UserContract.Bank:Edit"])

  const { data: bankDetails } = useCarerBankDetailsQuery({ carerGuid: carer.guid })

  const { mutateAsync: createBankDetails } = useCarerBankDetailsCreateMutation(carer.guid)
  const { mutateAsync: updateBankDetails } = useCarerBankDetailsUpdateMutation(carer.guid)

  const onSubmit = (vals: ApiCarerBankDetailsRequest) => {
    if (!canEdit) return
    if (vals.guid) {
      updateBankDetails(vals as ApiCarerBankDetails)
    } else {
      createBankDetails(vals)
    }
  }

  return (
    <Box>
      <Form {...{ onSubmit, initialValues: bankDetails || {} }}>
        {({ handleSubmit }: FormRenderProps<ApiCarerBankDetailsRequest>) => {
          return (
            <form {...{ onSubmit: handleSubmit }}>
              <Grid container item spacing={1}>
                <Grid item xs={6}>
                  <Field<string>
                    name="name"
                    label="Name (required)*"
                    component={TextField}
                    validate={validators.required}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field<string> name="branch" label="Branch" component={TextField} disabled={!canEdit} />
                </Grid>
                <Grid item xs={6}>
                  <Field<string>
                    name="account_number"
                    label="Account Number (required)*"
                    component={TextField}
                    validate={validators.required}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field<string>
                    name="code"
                    label="Code (required)*"
                    component={TextField}
                    validate={validators.required}
                    disabled={!canEdit}
                  />
                </Grid>
                {canEdit && (
                  <Grid item md={12}>
                    <Box textAlign="right">
                      <Button fullWidth={false} type="submit">
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </form>
          )
        }}
      </Form>
    </Box>
  )
})
