import { FinanceApi } from "api"
import { useMutation, UseMutationResult } from "react-query"
import { HolidayTier, HolidayTierRequest } from "./types"
import { ApiError } from "stores/useUIStore/types"
import { useInvalidateSchema } from "data/schema/useInvalidateSchema"
import { useNavigate } from "react-router-dom"
import { useApiMessage } from "stores"

export const useUpdateHolidayTierMutation = (): UseMutationResult<
  HolidayTier,
  ApiError,
  { guid: string; values: HolidayTierRequest }
> => {
  const invalidateSchema = useInvalidateSchema()
  const navigate = useNavigate()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async ({ values, guid }: { guid: string; values: HolidayTierRequest }): Promise<HolidayTier> => {
      return FinanceApi.put(`/finance/holiday-tiers/${guid}`, values)
    },
    {
      onSuccess: () => {
        invalidateSchema(["HolidayTier"])
        showSuccessMessage("Holiday tier successfully updated.")
        navigate(`/admin/finance/holiday-tiers`)
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not update Holiday tier.", { apiError })
      }
    }
  )
}

export const useCreateHolidayTierMutation = (): UseMutationResult<
  HolidayTier,
  ApiError,
  { values: HolidayTierRequest }
> => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const invalidateSchema = useInvalidateSchema()
  const navigate = useNavigate()

  return useMutation(
    async ({ values }: { values: HolidayTierRequest }): Promise<HolidayTier> => {
      return FinanceApi.post(`/finance/holiday-tiers`, values)
    },
    {
      onSuccess: () => {
        invalidateSchema(["HolidayTier"])
        showSuccessMessage("Holiday tier successfully created.")
        navigate(`/admin/finance/holiday-tiers`)
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not create Holiday tier.", { apiError })
      }
    }
  )
}

// clean after cypress
export const useDeleteHolidayTierMutation = () => {
  return useMutation(async (guid: string): Promise<void> => {
    return FinanceApi.delete(`/finance/holiday-tiers/${guid}`)
  })
}
