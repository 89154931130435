import { FinanceApi } from "api"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { queryKeys } from "../additional/queries"
import { AdditionalApproveRequest } from "./type"
import { useLocation } from "react-router-dom"
import { useApiMessage } from "stores"
import { ApiError } from "stores/useUIStore/types"

export const useApprovePaymentAdditionalMutation = (): UseMutationResult<void, ApiError, AdditionalApproveRequest> => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()
  const queryCache = useQueryClient()
  const { search } = useLocation()

  return useMutation({
    mutationFn: async (values: AdditionalApproveRequest) => {
      return FinanceApi.post("/finance/payment-additionals/bulk-approve", values)
    },
    onSuccess: (_, original) => {
      queryCache.invalidateQueries(queryKeys.getPaymentSearch({ search }))

      const isSingle = original.transaction_charge_guids.length === 1
      showSuccessMessage(
        `Additional${isSingle ? "" : "s"} requests successfully ${original.approved ? "approved" : "rejected"}.`
      )
    },
    onError: (apiError: ApiError, original) => {
      const isSingle = original.transaction_charge_guids.length === 1
      showErrorMessage(`Could not ${original.approved ? "approve" : "reject"} additional${isSingle ? "" : "s"}.`, {
        apiError
      })
    }
  })
}
