import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { grey } from "@mui/material/colors"
import testingId from "constants/testingId"
import { InterfaceFileContract } from "data/finance/invoiceFile/types"
import datetime from "lib/datetime"
import React, { memo } from "react"

interface OwnProps {
  contracts: InterfaceFileContract[]
}

const headers = ["Contract model", "Process date", "Billing cycle", "Users count"]

export const InterfaceFileTabItemContracts: React.FC<OwnProps> = memo(({ contracts }) => {
  return (
    <Table data-cy={testingId.interfaceFileViewer.tableViewer}>
      <TableHead>
        <TableRow
          sx={{
            backgroundColor: grey[200],
            borderTop: `1px solid ${grey[400]}`
          }}
        >
          {headers.map((header) => (
            <TableCell
              data-cy={testingId.interfaceFileViewer.tableHeaderCell}
              key={header}
              sx={{
                padding: "5px 16px"
              }}
            >
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {contracts.map((contract, key) => (
          <TableRow data-cy={testingId.interfaceFileViewer.tableRow} key={key}>
            <TableCell data-cy={testingId.interfaceFileViewer.tableCell}>{contract.contract_title}</TableCell>
            <TableCell data-cy={testingId.interfaceFileViewer.tableCell}>
              {datetime.formatDate(contract.average_completed_at)}
            </TableCell>
            <TableCell data-cy={testingId.interfaceFileViewer.tableCell}>
              {datetime.formatDate(contract.period_start)} - {datetime.formatDate(contract.period_end)}
            </TableCell>
            <TableCell data-cy={testingId.interfaceFileViewer.tableCell}>{contract.count}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

InterfaceFileTabItemContracts.displayName = "InterfaceFileTabItemContracts"
