import type { AppContext } from "pubnub"
import { create } from "zustand"
import { CustomChannelMetadata } from "./types"

export const DEFAULT_STORE = {
  currentChannel: undefined,
  channelNames: {
    chats: "",
    carers: ""
  },
  unreadMessagesCounterByChannelId: {}
}
interface UnreadMessagesCounterByChannelId {
  [channelId: string]: number
}

interface ChannelNames {
  chats: string
  carers: string
}

interface ChatStatus {
  currentChannel?: AppContext.ChannelMetadataObject<CustomChannelMetadata>
  channelNames: ChannelNames
  unreadMessagesCounterByChannelId: UnreadMessagesCounterByChannelId
  setCurrentChannel: (channel?: AppContext.ChannelMetadataObject<CustomChannelMetadata>) => void
  setChannelNames: (chats: string, carers: string) => void
  setAllUnreadMessagesCounterByChannelId: (unreadMessagesCounterByChannelId: UnreadMessagesCounterByChannelId) => void
  resetUnreadMessagesCounterForChanelId: (channelId: string) => void
  incrementUnreadMessagesCounterForChanelId: (channelId: string) => void
}

export const useChatStore = create<ChatStatus>((set) => ({
  ...DEFAULT_STORE,
  setCurrentChannel: (currentChannel) =>
    set(() => ({
      currentChannel
    })),
  setChannelNames: (chats, carers) =>
    set(() => ({
      channelNames: {
        chats,
        carers
      }
    })),
  setAllUnreadMessagesCounterByChannelId: (unreadMessagesCounterByChannelId) =>
    set(() => ({
      unreadMessagesCounterByChannelId
    })),
  resetUnreadMessagesCounterForChanelId: (channelId) =>
    set((state) => ({
      unreadMessagesCounterByChannelId: {
        ...state.unreadMessagesCounterByChannelId,
        [channelId]: 0
      }
    })),
  incrementUnreadMessagesCounterForChanelId: (channelId) =>
    set((state) => ({
      unreadMessagesCounterByChannelId: {
        ...state.unreadMessagesCounterByChannelId,
        [channelId]: (state.unreadMessagesCounterByChannelId[channelId] || 0) + 1
      }
    }))
}))
