import React, { memo, useMemo } from "react"
import { Button, FormSectionHeader, TextField } from "components/Form"
import Grid from "@mui/material/Grid"
import { Field, FormSpy } from "react-final-form"
import Typography from "@mui/material/Typography"
import BillingInformation from "pages/Finance/ContractModels/components/modelForm/BillingInformation"
import { ClientContractModelSettings } from "pages/Clients/components/ClientContractInvoiceSettings"
import DatesMessageHelp from "components/DatesMessageHelp"
import { ClientContractContributions } from "pages/Clients/components/ClientContractContributions"
import { DateTime } from "luxon"
import { filterNils } from "lib/datetime"
import { NoEndDateCheckbox } from "components"
import { INVOICE_CONTRACT_PRIVATE_ALIASES } from "constants/modelTypes"
import testingId from "constants/testingId"
import { ClientContractContributionPrivate } from "./ClientContractContributionPrivate"
import { ClientContractStandingCharges } from "./ClientContractStandingCharges"
import { CheckboxIsDefault } from "components/Form/CheckboxIsDefault"
import { DateSpan } from "components/DateSpan"
import { WithPermission } from "components/Auth"
import { Permission } from "constants/permission"
import { DateField } from "components/Form/DateField"
import validators from "lib/validators"
import { ContractModel } from "data/finance/contractModel/types"
import { ApiClientContract, ApiClientContractContribution } from "data/finance/client/types"

interface OwnProps {
  contractModel?: ContractModel
  contractReference?: string
  contract?: ApiClientContract
  isFirstContract: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const getContribStartsMin = (
  contributions: ApiClientContractContribution[] | null,
  end: DateTime | null
): DateTime | undefined => {
  const mappedStarts: DateTime[] = (contributions || []).map((cont) => cont.start).filter(filterNils)

  return DateTime.min(...(end ? [...mappedStarts, end.minus({ days: 1 })] : mappedStarts))
}

const getContribEndsMax = (
  contributions: ApiClientContractContribution[] | null,
  start: DateTime
): DateTime | undefined => {
  const filteredContributions: DateTime[] = (contributions || []).map((contrib) => contrib.end).filter(filterNils)

  return DateTime.max(...(start ? [...filteredContributions, start.plus({ days: 1 })] : filteredContributions))
}

export const ClientContractDetailStepTwo: React.FC<OwnProps> = memo(
  ({ contractModel, contractReference, contract, isFirstContract, setShowModal }) => {
    ClientContractDetailStepTwo.displayName = "ClientContractDetailStepTwo"

    const isPrivate = INVOICE_CONTRACT_PRIVATE_ALIASES.includes(contractModel?.type_alias || "")
    const isContributable = useMemo(() => !!contract?.is_contributable, [contract])

    return (
      <>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="body2" color="textSecondary">
              Model Name
            </Typography>
            <Typography variant="h6" data-cy={testingId.client.contract.detail.modelName}>
              {contractModel?.title} <DateSpan period={contractModel} />
            </Typography>
          </Grid>
          <Grid item md={6}>
            {contractReference && (
              <>
                <Typography variant="body2" color="textSecondary">
                  Contract ID
                </Typography>
                <Typography variant="h6">{contractReference}</Typography>
              </>
            )}
          </Grid>
        </Grid>

        <FormSectionHeader title="Contract duration" />
        <DatesMessageHelp messageType="E" />
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <Grid container spacing={8}>
              <Grid item md={6}>
                <DateField
                  name="start"
                  label="Starts on"
                  required
                  maxDate={getContribStartsMin(values.contributions, values.end)}
                />
                <CheckboxIsDefault {...{ isFirstContract, contract, labelType: "Client" }} />
              </Grid>
              <Grid item md={6}>
                <DateField
                  name="end"
                  label="Ends before"
                  minDate={getContribEndsMax(values.contributions, values.start)}
                  disabled={values.no_end_date}
                />
                <NoEndDateCheckbox />
              </Grid>
            </Grid>
          )}
        </FormSpy>

        <FormSectionHeader title="Social services" />
        <Grid container spacing={8}>
          <Grid item md={6}>
            <Field
              name="social_service_ref"
              label="Social service ref *"
              component={TextField}
              validate={validators.required}
            />
          </Grid>
          <Grid item md={6}>
            <Field name="alternative_social_service_ref" label="Alternative social service ref" component={TextField} />
          </Grid>
        </Grid>

        {contract && !contract.is_one_to_one ? (
          <>
            <FormSectionHeader title="Purchase Order" />
            <Button onClick={() => setShowModal(true)} fullWidth={false}>
              Upload a purchase order
            </Button>
          </>
        ) : null}

        <WithPermission
          permissions={[Permission.FINANCE_USER_CONTRACT_RATE_EDIT, Permission.FINANCE_USER_CONTRACT_RATE_READ]}
        >
          <ClientContractStandingCharges />
        </WithPermission>
        {isPrivate && (
          <>
            <FormSpy>
              {({ form }) => (
                <BillingInformation
                  isEditing={!!contractReference}
                  isClientContractModel={true}
                  typeAlias={contractModel?.type_alias}
                  form={form}
                />
              )}
            </FormSpy>

            <ClientContractModelSettings />

            {!!contract?.contributions?.length && (
              <ClientContractContributionPrivate {...{ contributions: contract.contributions }} />
            )}
          </>
        )}
        {!!contract && isContributable && (
          <>
            <FormSectionHeader
              title="Client contributions"
              data-cy={testingId.client.contract.detail.contributionSection}
            />
            <ClientContractContributions {...{ contract }} />
          </>
        )}
      </>
    )
  }
)
