import React, { useMemo, useState } from "react"
import { Header, UserList, EmptyData, ContentContainer } from "components"
import Box from "@mui/material/Box"
import { ApiUserClientFilter, UserType } from "models"
import { useInvoiceModelSummariesQuery } from "data/finance/contractModel/queries"
import { objectToQueryString, QSObject, queryStringToObject } from "lib/queryString"
import { omit } from "ramda"
import { useClientListQuery } from "data/core/client/queries"
import testingId from "constants/testingId"
import { ClientListFilter, CLIENT_STATUS_ACTIVE } from "./components/ClientListFilter"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { filterClients } from "./helpers/filterClients"
import { getOptionsFromSchema } from "lib/schema"
import { useLocation } from "react-router-dom"
import { useCoreSchemasStore } from "stores"

const ClientList: React.FC = () => {
  const location = useLocation()
  const { ApiUserPatientResponse } = useCoreSchemasStore((state) => state.schema.models)
  const statusOptions = getOptionsFromSchema(ApiUserPatientResponse, "status")

  // FE filter & pagination is here because of "active contract model", "funder" and "reference" filter, CORE BE can not filter it because it depends on FINANCE BE
  const [filter, setFilter] = useState<ApiUserClientFilter>(() =>
    queryStringToObject<ApiUserClientFilter>(location?.search || `status=${CLIENT_STATUS_ACTIVE}`)
  )

  const apiSearch = omit(["contract_model", "funder", "reference"], filter) as QSObject
  const { data: clientList, isRefetching } = useClientListQuery({
    search: `?${objectToQueryString(apiSearch)}`
  })

  const { data: invoiceSummaries } = useInvoiceModelSummariesQuery()

  const [isFiltering, setIsFiltering] = useState(false)

  // filtered clients
  const filteredClients = useMemo(() => {
    setIsFiltering(true) // add loader before filtering

    return filterClients({ summaries: invoiceSummaries, filter, clients: clientList?.users, setIsFiltering })
  }, [invoiceSummaries, filter, clientList])

  const isLoading = isRefetching || isFiltering

  return (
    <ContentContainer data-cy={testingId.client.list.screen}>
      <Header title="Clients" />
      <Box m={3} mx={0}>
        <ClientListFilter {...{ filter, setFilter, invoiceSummaries }} />
      </Box>
      <RequestLoaderWrapper isFiltering={isLoading}>
        {filteredClients?.length || isLoading ? (
          <UserList
            hasLogin={false}
            baseUrl="/clients"
            items={filteredClients ?? []}
            statusOptions={statusOptions}
            listUserType={UserType.Patient}
          />
        ) : (
          <EmptyData message="We’re sorry, but we couldn’t find a match for your search. Please check the spelling or adjust the filters and try again." />
        )}
      </RequestLoaderWrapper>
    </ContentContainer>
  )
}

export default ClientList
