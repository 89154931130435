import { TableCell, TableRow, Box } from "@mui/material"
import React, { memo, ReactNode, useState } from "react"
import { ContractModel } from "data/finance/contractModel/types"
import { DateTimeFormat } from "lib/datetime"
import { ContractModelUpliftContractCheckboxProps } from "../types"
import { ContractModelTableItemUpliftStatus } from "./ContractModelTableItemUpliftStatus"
import { ContractModelType } from "constants/modelTypes"
import { useNavigate } from "react-router-dom"

interface Props {
  item: ContractModel
  typeAliasMap: {
    [key: string]: string
  }
  CustomTableItem?: React.FC<ContractModelUpliftContractCheckboxProps>
  clickable: boolean
  modelType: ContractModelType
}

export const ContractModelTableItem: React.FC<Props> = memo(
  ({ item, CustomTableItem, typeAliasMap, clickable, modelType }) => {
    const navigate = useNavigate()
    const onClick = () => {
      navigate(item.guid)
    }

    const [modal, setModal] = useState<ReactNode>(null)

    return (
      <>
        <TableRow
          {...{
            hover: clickable,
            onClick: clickable ? onClick : undefined
          }}
          sx={clickable ? { cursor: "pointer" } : undefined}
        >
          {CustomTableItem ? <CustomTableItem {...{ contractModel: item }} /> : null}
          <TableCell>{item.title}</TableCell>
          <TableCell>{typeAliasMap[item.type_alias]}</TableCell>
          <TableCell>{item.start.toFormat(DateTimeFormat.DATE)}</TableCell>
          <TableCell>{item.end ? item.end.toFormat(DateTimeFormat.DATE) : "/ /"}</TableCell>
          <TableCell>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              {item.status}
              <ContractModelTableItemUpliftStatus {...{ contractModel: item, setModal, modelType }} />
            </Box>
          </TableCell>
        </TableRow>
        {modal}
      </>
    )
  }
)

ContractModelTableItem.displayName = "ContractModelTableItem"
