import { FinanceApi } from "api"
import { enabledByMandatoryUrlParams, setDayBorders } from "data/helpers"
import { removeFromSearchString } from "lib/helpers/removeFromSearchString"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { ApiAdditionalSearch, AdditionalType, ApiPaymentAdditionalSearch, ApiApproval, ApprovalStatus } from "./type"

enum QueryKeyParts {
  INVOICE_ADDITIONAL = "invoice-additional",
  PAYMENT_ADDITIONAL = "payment-additional",
  ADDITIONAL = "additional",
  APPROVAL = "approval",
  LIST = "list",
  TYPES = "types"
}

export const queryKeys = {
  getInvoiceSearch: (props: { search?: string }): QueryKey => [QueryKeyParts.INVOICE_ADDITIONAL, props],
  getPaymentSearch: (props: { search?: string }): QueryKey => [QueryKeyParts.PAYMENT_ADDITIONAL, props],
  getAdditionalTypes: (isInvoice: boolean): QueryKey => [QueryKeyParts.ADDITIONAL, QueryKeyParts.TYPES, { isInvoice }],
  getPaymentAdditionalApprovalListKey: (props: { additionalGuid: string; status: ApprovalStatus }): QueryKey => [
    QueryKeyParts.PAYMENT_ADDITIONAL,
    QueryKeyParts.APPROVAL,
    QueryKeyParts.LIST,
    props
  ]
}

export const useInvoiceAdditionalQuery = ({
  search,
  enabled = true
}: {
  search?: string
  enabled?: boolean
}): UseQueryResult<ApiAdditionalSearch[], Error> => {
  const searchQuery = removeFromSearchString({ search, remove: ["modelType"] })
  const newSearch = setDayBorders({ search: searchQuery, from: "start", to: "end" })

  return useQuery({
    queryKey: queryKeys.getInvoiceSearch({ search }),
    queryFn: async () => FinanceApi.get(`/finance/invoice-additionals${newSearch}`),
    ...enabledByMandatoryUrlParams([newSearch, enabled])
  })
}

export const usePaymentAdditionalQuery = ({
  search,
  enabled = true
}: {
  search?: string
  enabled?: boolean
}): UseQueryResult<ApiPaymentAdditionalSearch[], Error> => {
  const searchQuery = removeFromSearchString({ search, remove: ["modelType"] })
  const newSearch = setDayBorders({ search: searchQuery, from: "start", to: "end" })

  return useQuery({
    queryKey: queryKeys.getPaymentSearch({ search }),
    queryFn: async () => FinanceApi.get(`/finance/payment-additionals${newSearch}`),
    ...enabledByMandatoryUrlParams([newSearch, enabled])
  })
}

const getAdditionalUrl = (isInvoice: boolean) => (isInvoice ? "invoice-additionals" : "payment-additionals")

export const useAdditionalTypeListQuery = ({
  isInvoice
}: {
  isInvoice: boolean
}): UseQueryResult<AdditionalType[], Error> =>
  useQuery({
    queryKey: queryKeys.getAdditionalTypes(isInvoice),
    queryFn: async () => FinanceApi.get(`/finance/${getAdditionalUrl(isInvoice)}/types`)
  })

export const usePaymentAdditionalApprovalQuery = ({
  additionalGuid,
  status,
  enabled
}: {
  additionalGuid: string
  status: ApprovalStatus
  enabled?: boolean
}): UseQueryResult<ApiApproval[], Error> => {
  return useQuery({
    queryKey: queryKeys.getPaymentAdditionalApprovalListKey({ additionalGuid, status }),
    queryFn: async () => FinanceApi.get(`/finance/payment-additionals/${additionalGuid}/approvals`),
    ...enabledByMandatoryUrlParams([enabled])
  })
}
