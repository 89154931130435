import { useBranchInfoQuery } from "data/core/branch/queries"
import { BranchPermissionDictionary } from "./types"

/**
 * useBranchPermissions
 *
 * @returns the current branch reference
 */
const useBranchPermissions = () => {
  const { data: { features = null } = {}, isLoading, isFetching } = useBranchInfoQuery()

  const hasBranchPermission = (permission: keyof BranchPermissionDictionary) => {
    if (!features) {
      return false
    }
    return !!(permission in features && features[permission])
  }

  return { isLoading, isFetching, hasBranchPermission }
}

export default useBranchPermissions
