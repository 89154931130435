import React from "react"
import { Field } from "react-final-form"
import { TextFieldCountDown } from "components/Form"
import validators, { composeValidators } from "lib/validators"
import Typography from "@mui/material/Typography"

export const REASON_TEXT_MIN_LENGTH = 5
export const REASON_TEXT_MAX_LENGTH = 50

interface OwnProps {
  testingId: string
  minLength?: number
  maxLength?: number
}

const CheckInCheckOutReasonField = ({
  testingId,
  minLength = REASON_TEXT_MIN_LENGTH,
  maxLength = REASON_TEXT_MAX_LENGTH
}: OwnProps) => {
  return (
    <>
      <Field<string>
        component={TextFieldCountDown}
        name="reason"
        label="Update reason *"
        inputProps={{
          minLength,
          maxLength,
          placeholder: "Reason for updating the value"
        }}
        helperText={`Minimum of ${minLength} characters`}
        validate={composeValidators([
          validators.minLength(minLength),
          validators.maxLength(maxLength),
          validators.required
        ])}
        data-cy={testingId}
      />
      <Typography variant="body2">
        Edits to visit times are logged to ensure data integrity and may be reviewed as needed.
      </Typography>
    </>
  )
}

export default CheckInCheckOutReasonField
