import { Box, Grid } from "@mui/material"
import { AutocompleteMui, FormSpyCustom, Select, TextField } from "components/Form"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import React, { Dispatch, memo, SetStateAction, useMemo } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"
import { useIsInvoiceModelSearch } from "lib/hooks/useIsInvoiceModelSearch"
import { ClearFilterButton } from "components/ClearFilterButton"
import { formatFilterValues, FilterValues } from "lib/helpers/formatFilterValues"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"
import { AdditionalSearchFilterValues } from "data/finance/additional/type"
import { ContractModelType } from "constants/modelTypes"
import { Pagination } from "lib/types"
import { useSchema } from "data/schema/useSchema"
import { removeUnassigned } from "data/schema/helpers"
import { DateField } from "components/Form/DateField"

interface Props {
  filter: AdditionalSearchFilterValues
  setFilter: Dispatch<SetStateAction<AdditionalSearchFilterValues>>
  defaultFilterValues: { start: string; end: string; modelType: ContractModelType }
  pagination: Pagination
  resetPagination: () => void
}

// can be sorted
export const AdditionalSearchFilter: React.FC<Props> = memo(
  ({ filter, setFilter, defaultFilterValues, pagination, resetPagination }) => {
    AdditionalSearchFilter.displayName = "AdditionalSearchFilter"
    const isInvoiceModel = useIsInvoiceModelSearch()

    const debouncedOnSubmit = useDebouncedCallback((values: FilterValues<AdditionalSearchFilterValues>) => {
      const formattedValues = formatFilterValues<AdditionalSearchFilterValues>({
        values,
        startName: "start",
        endName: "end"
      })
      setFilter(formattedValues)
      resetPagination()
    }, 500)

    const clearFilter = () => {
      setFilter((prev) => ({ ...defaultFilterValues, modelType: prev.modelType }))
      resetPagination()
    }

    useFilterLocationChange({ filter, pagination })

    const { Client, Carer, InvoiceContract, PaymentContract, TransactionCharge, Region } = useSchema([
      "Client",
      "Carer",
      "InvoiceContract",
      "PaymentContract",
      "TransactionCharge",
      "Region"
    ])

    const InvoiceContractOptions = useMemo(() => removeUnassigned(InvoiceContract), [InvoiceContract])
    const PaymentContractOptions = useMemo(() => removeUnassigned(PaymentContract), [PaymentContract])

    return (
      <Form
        {...{
          onSubmit: debouncedOnSubmit,
          initialValues: filter,
          validate: validateFilterPeriodDates({ start: "start", end: "end" })
        }}
      >
        {({ handleSubmit }) => {
          return (
            <form {...{ onSubmit: handleSubmit }}>
              <FormSpyCustom {...{ handleSubmit }} />
              <Grid container alignItems="center" spacing={1}>
                <Grid item md={2}>
                  <DateField name="start" label="Start" required />
                </Grid>
                <Grid item md={2}>
                  <DateField name="end" label="End" required />
                </Grid>

                <Grid item md={3}>
                  {isInvoiceModel ? (
                    <Field name="clients" label="Clients" component={AutocompleteMui} options={Client} multiselect />
                  ) : (
                    <Field name="carers" label="Carers" component={AutocompleteMui} options={Carer} multiselect />
                  )}
                </Grid>

                <Grid item md={3}>
                  <Field
                    name="contracts"
                    label={isInvoiceModel ? "Contract model" : "Pay model"}
                    component={AutocompleteMui}
                    options={isInvoiceModel ? InvoiceContractOptions : PaymentContractOptions}
                    multiselect
                  />
                </Grid>

                <Grid item md={2}>
                  <Field
                    name="reference_contracts"
                    label={isInvoiceModel ? "Pay model" : "Contract model"}
                    component={AutocompleteMui}
                    options={isInvoiceModel ? PaymentContractOptions : InvoiceContractOptions}
                    multiselect
                  />
                </Grid>

                <Grid item md={3}>
                  <Field
                    name="charge_type_alias"
                    label="Additional type"
                    component={AutocompleteMui}
                    options={TransactionCharge}
                    multiselect
                  />
                </Grid>

                <Grid item md={2}>
                  <Field
                    name="approved"
                    label="Approval status"
                    component={Select}
                    options={[
                      { title: "None", value: "" },
                      { title: "Awaiting Approval", value: "Pending" },
                      { title: "Approved", value: "Approved" },
                      { title: "Rejected", value: "Rejected" }
                    ]}
                  />
                </Grid>

                <Grid item md={2}>
                  <Field
                    name="transaction_reference"
                    label={isInvoiceModel ? "Invoice number" : "Payroll number"}
                    component={TextField}
                  />
                </Grid>

                <Grid item md={3}>
                  <Field name="regions" label="Regions" component={AutocompleteMui} options={Region} multiselect />
                </Grid>

                <Grid item md={2}>
                  <Field
                    name="show_appointment_additionals"
                    label="Show Appointment Additionals"
                    component={Select}
                    options={[
                      { title: "Any", value: "" },
                      { title: "Yes", value: "true" },
                      { title: "No", value: "false" }
                    ]}
                  />
                </Grid>
              </Grid>
              <Box textAlign="right">
                <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
              </Box>
            </form>
          )
        }}
      </Form>
    )
  }
)
