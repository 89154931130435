import React, { Dispatch, memo, SetStateAction } from "react"
import { Form } from "react-final-form"
import { FormSpyCustom } from "components/Form"
import { DateTimeFormat } from "lib/datetime"
import { DateTime } from "luxon"
import { DateField } from "./Form/DateField"

interface DayFilterSelectProps {
  setDay: Dispatch<SetStateAction<DateTime>>
  day: DateTime
}

const DayFilterSelect: React.FC<DayFilterSelectProps> = ({ setDay, day }) => {
  const onSubmit = (values: { start: DateTime | string | null }) => {
    const { start } = values

    if (!start) {
      return
    }

    const newValue = DateTime.isDateTime(start) ? start : DateTime.fromISO(start)

    if (newValue.toFormat(DateTimeFormat.DATE_SECONDARY) !== day.toFormat(DateTimeFormat.DATE_SECONDARY)) {
      setDay(newValue)
    }
  }

  return (
    <Form onSubmit={onSubmit} initialValues={{ start: day }}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpyCustom {...{ handleSubmit }} />
          <DateField name="start" label="Day" required />
        </form>
      )}
    </Form>
  )
}

export default memo(DayFilterSelect)
