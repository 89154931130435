import React, { useMemo } from "react"
import CheckInCheckOutDetail from "./CheckInCheckOutDetail"
import { Typography } from "@mui/material"
import { CheckInCheckOutWithVariantProps } from "./types"
import { ApiUserClient } from "models"

/**
 * PropTypes
 */
interface CheckInCheckOutBodyProps extends CheckInCheckOutWithVariantProps {
  patient: undefined | ApiUserClient
  mapId: string
}

/**
 * CheckInCheckOutBody
 *
 * Renders warning messages if visitor has not check-in/out or is not using geolocation, otherwise
 * should render the check in and checkout details
 */
const CheckInCheckOutBody = ({ visit, patient, variant = "in", mapId }: CheckInCheckOutBodyProps) => {
  /**
   * Checks if the visitor is using geo location to check-in/out
   */
  const isVisitorUsingGeolocation = useMemo(() => {
    if (variant === "in") {
      return visit.check_in?.location.is_using_geolocation
    }
    return visit.check_out?.location.is_using_geolocation
  }, [visit, variant])

  /**
   * Checks if the visitor is already check-in/out
   */
  const isVisitorCheckInOrOut = useMemo(() => {
    return (variant === "in" && !visit.isCheckedIn()) || (variant === "out" && !visit.isCheckedOut())
  }, [variant, visit])

  if (isVisitorCheckInOrOut) {
    return <Typography variant="body2">{`Visit has not been checked ${variant} yet.`}</Typography>
  } else if (!isVisitorUsingGeolocation) {
    return <Typography variant="body2">{`Visit has not been checked ${variant} by the Carer.`}</Typography>
  }
  return <CheckInCheckOutDetail visit={visit} patient={patient} mapId={mapId} />
}

export default CheckInCheckOutBody
