import { CoreApi } from "api"
import { BranchInfoResponse } from "lib/hooks/branchInfo/types"
import { ApiBusinessSyncStatus } from "./types"
import { QueryKey, useQuery, UseQueryResult } from "react-query"

export enum QueryKeyParts {
  BRANCH = "branch",
  STATUS = "status"
}

export const queryKeys = {
  getBranchInfoKey: (): QueryKey => [QueryKeyParts.BRANCH],
  getBranchStatusKey: (): QueryKey => [QueryKeyParts.BRANCH, QueryKeyParts.STATUS]
}

export const useBranchInfoQuery = (): UseQueryResult<BranchInfoResponse, Error> => {
  return useQuery({
    queryKey: queryKeys.getBranchInfoKey(),
    queryFn: async () => CoreApi.get("/branch"),
    staleTime: 60 * 60 * 1000 //1h before expire request cache, same as token expiry
  })
}

export const useBranchStatusQuery = (): UseQueryResult<ApiBusinessSyncStatus, Error> => {
  return useQuery({
    queryKey: queryKeys.getBranchStatusKey(),
    queryFn: async () => CoreApi.get("/branch/status")
  })
}
