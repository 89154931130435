import React, { memo } from "react"
import { FormSpy } from "react-final-form"
import { CancelButton, Button } from "components/Form"
import { StickyBox } from "components"
import Grid from "@mui/material/Grid"
import testingId from "constants/testingId"
import BottomErrorMessage from "components/BottomErrorMessage"

interface OwnProps {
  isInvoiceModelType: boolean
}

export const ContractModelFormFooter: React.FC<OwnProps> = memo(({ isInvoiceModelType }) => (
  <FormSpy subscription={{ error: true, submitError: true, submitting: true, pristine: true }}>
    {({ error, submitError, submitting, pristine }) => {
      return (
        <StickyBox>
          <Grid container>
            <Grid item md={6}>
              {error || submitError ? <BottomErrorMessage message={error || submitError} /> : null}
            </Grid>
          </Grid>

          <CancelButton
            disabled={submitting}
            pristine={pristine}
            navigateUrl={`/finance/contract-models/${isInvoiceModelType ? "invoice" : "payment"}-models`}
            data-cy={testingId.contractModelSettings.cancelButton}
          />
          <Button
            type="submit"
            fullWidth={false}
            disabled={submitting}
            sx={(theme) => ({
              margin: theme.spacing(0, 0, 0, 3),
              whiteSpace: "nowrap"
            })}
            data-cy={testingId.contractModelSettings.saveButton}
          >
            Save changes
          </Button>
        </StickyBox>
      )
    }}
  </FormSpy>
))

ContractModelFormFooter.displayName = "ContractModelFormFooter"
