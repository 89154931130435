import { TableCell } from "@mui/material"
import { Checkbox } from "components/Form"
import { FormApi } from "final-form"
import { equals } from "ramda"
import React, { memo, useMemo } from "react"
import { Field, useFormState } from "react-final-form"
import { getUpliftModelServiceItems } from "../../helpers/upliftFormGetters"
import { RateUpliftForm, RateUpliftFormService } from "../../types"

interface OwnProps {
  contractGuid: string
  form: FormApi<RateUpliftForm, Partial<RateUpliftForm>>
  isInvoicePaymentService: boolean
}

export const RateUpliftTwoServiceCheckboxAll: React.FC<OwnProps> = memo(
  ({ contractGuid, form, isInvoicePaymentService }) => {
    RateUpliftTwoServiceCheckboxAll.displayName = "RateUpliftTwoServiceCheckboxAll"

    const { values } = useFormState<RateUpliftForm>()

    const serviceItems = useMemo(
      () => getUpliftModelServiceItems({ values, contractGuid }) || [],
      [values, contractGuid]
    )

    const allServicesAsForm = useMemo(() => {
      return serviceItems
        .filter((service) => !service.disabled)
        .reduce((result: Record<string, RateUpliftFormService>, service) => {
          return { ...result, [service.guid]: { picked: true } as RateUpliftFormService }
        }, {})
    }, [serviceItems])

    const areAllSelected = useMemo(() => {
      return equals(values.models[contractGuid].services, allServicesAsForm)
    }, [allServicesAsForm, values, contractGuid])

    const isDisabled = useMemo(() => {
      return serviceItems.every((service) => service.disabled)
    }, [serviceItems])

    if (isInvoicePaymentService) return <TableCell />

    return (
      <TableCell>
        <Field<boolean>
          {...{
            type: "checkbox",
            name: `models[${contractGuid}].all_services_picked`,
            checked: areAllSelected, // once clicked all_services_picked is always true, but it's not blocking anything
            disabled: isDisabled,
            component: Checkbox,
            onClick: () => {
              if (areAllSelected) {
                // @ts-ignore
                form.change(`models[${contractGuid}].services`, {})
              } else {
                // @ts-ignore
                form.change(`models[${contractGuid}].services`, allServicesAsForm)
              }
            }
          }}
        />
        Select All
      </TableCell>
    )
  }
)
