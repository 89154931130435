import React from "react"
import { Box } from "@mui/material"
import Warning from "@mui/icons-material/Warning"
import { default as testingIds } from "constants/testingId"

interface ErrorProps {
  message: string
  "data-cy"?: string
}

const BottomErrorMessage = ({ message, "data-cy": testingId }: ErrorProps) => {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
      data-cy={testingId ?? testingIds.bottomErrorMessage}
    >
      <Warning sx={{ color: "red" }} />
      <Box component="span" sx={{ marginLeft: "8px", color: "red" }}>
        {message}
      </Box>
    </Box>
  )
}
export default BottomErrorMessage
