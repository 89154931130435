import React, { memo } from "react"
import { FormSectionHeader, Select, TextField } from "components/Form"
import Grid from "@mui/material/Grid"
import validators, { composeValidators } from "lib/validators"
import { Field, FormSpy } from "react-final-form"
import Auth from "lib/Auth"
import { useSchema } from "data/schema/useSchema"

const isRateNone = (values: Record<string, any>): boolean => values.compensation?.standing_rate_mode === "NONE"

export const CarerContractDetailStepTwoCompensation: React.FC = memo(() => {
  const { UserContractCompensationTravel } = useSchema(["UserContractCompensationTravel"])

  return (
    <>
      <FormSectionHeader title="Compensation" />
      <FormSpy subscription={{ values: true }}>
        {({ values, form }) => (
          <Grid container spacing={3}>
            <Grid item md={3}>
              <Field
                name="compensation.commute_type_alias"
                label="Commute Method"
                component={Select}
                options={UserContractCompensationTravel}
              />
            </Grid>
            <Grid item md={3}>
              <Field
                name="compensation.travel_type_alias"
                label="Travel Method"
                component={Select}
                options={UserContractCompensationTravel}
              />
            </Grid>
            {Auth.hasPermission(["Finance.Model:Edit"]) && (
              <>
                <Grid item md={3}>
                  <Field
                    name="compensation.standing_rate_mode"
                    label="Standing rate mode *"
                    component={Select}
                    options={[
                      { title: "None", value: "NONE" },
                      { title: "Visits", value: "VISITS" },
                      { title: "Shifts", value: "SHIFTS" },
                      { title: "Both", value: "BOTH" }
                    ]}
                  />
                </Grid>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={({ values: newValues }) => {
                    if (isRateNone(newValues)) {
                      form.change("compensation.standing_rate", undefined)
                    }
                  }}
                />
                <Grid item md={3}>
                  <Field
                    name="compensation.standing_rate"
                    label="Standing rate"
                    component={TextField}
                    validate={composeValidators([validators.canNotBeZero, validators.availableValueMustBeANumber()])}
                    disabled={isRateNone(values)}
                    required={!isRateNone(values)}
                  />
                </Grid>
              </>
            )}
          </Grid>
        )}
      </FormSpy>
    </>
  )
})

CarerContractDetailStepTwoCompensation.displayName = "CarerContractDetailStepTwoCompensation"
