import { DownloadApi } from "api"
import { enabledByMandatoryUrlParams, enabledByMandatoryUrlSearch, setDayBorders } from "data/helpers"
import { removeFromSearchString } from "lib/helpers/removeFromSearchString"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { ApiDownload, ApiDownloadLink } from "./types"

export enum QueryKeyParts {
  DOWNLOAD = "download",
  LIST = "list",
  JOB = "job",
  LINK = "link"
}

export const queryKeys = {
  getDownloadListKey: ({ search }: { search: string }): QueryKey => [
    QueryKeyParts.DOWNLOAD,
    QueryKeyParts.LIST,
    { search }
  ],
  getDownloadJobLinkKey: ({ jobGuid }: { jobGuid?: string }): QueryKey => [
    QueryKeyParts.DOWNLOAD,
    QueryKeyParts.JOB,
    QueryKeyParts.LINK,
    { jobGuid }
  ]
}

export const useDownloadListQuery = ({
  query,
  withSearch,
  enableRefetchInterval = false
}: {
  query?: string
  withSearch?: boolean
  enableRefetchInterval?: boolean
}): UseQueryResult<ApiDownload[], Error> => {
  const trimmedSearch = removeFromSearchString({ search: query, remove: ["job_guid"] })
  const newSearch = setDayBorders({ search: trimmedSearch, from: "start", to: "end" })

  return useQuery({
    queryKey: queryKeys.getDownloadListKey({ search: newSearch }),
    queryFn: async () => DownloadApi.get(`/downloads${newSearch}`),
    refetchInterval: enableRefetchInterval ? 20000 : false,
    ...enabledByMandatoryUrlSearch({ withSearch, param: newSearch })
  })
}

export const useDownloadJobLinkQuery = ({ jobGuid }: { jobGuid?: string }): UseQueryResult<ApiDownloadLink, Error> =>
  useQuery({
    queryKey: queryKeys.getDownloadJobLinkKey({ jobGuid }),
    queryFn: async () => DownloadApi.get(`/downloads/${jobGuid}/link`),
    ...enabledByMandatoryUrlParams([jobGuid])
  })
