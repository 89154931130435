import { CoreApi } from "api"
import { DateTime } from "luxon"
import { RefusalOperator } from "models"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { ApiError } from "stores/useUIStore/types"
import { useApiMessage } from "stores"
import { queryKeys as clientQueryKeys } from "data/core/client/queries"

export const useUnlockMutation = (clientGuid: string): UseMutationResult<void, ApiError, string> => {
  const queryClient = useQueryClient()

  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async (carerVisitGuid: string) => {
      return CoreApi.patch(`/visit/${carerVisitGuid}/unlock`)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Successfully unlocked CM2000 visit.")
        queryClient.invalidateQueries(clientQueryKeys.getClientDetailKey(clientGuid))
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Something went wrong when unlocking CM2000 visit.", { apiError })
      }
    }
  )
}

type RefusalDetails = {
  door_step_refusal: {
    confirmed_at: DateTime
    confirmed_by: RefusalOperator
  }
}

export const useConfirmDoorstep = (refetch: () => void): UseMutationResult<RefusalDetails, ApiError, string> => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(async (visitGuid: string) => CoreApi.post(`/visit/${visitGuid}/confirm-door-step-refusal`), {
    onSuccess: () => {
      showSuccessMessage("Cancelled on arrival successfully confirmed")
      refetch()
    },
    onError: (apiError: ApiError) => {
      showErrorMessage("Something went wrong when confirming door step refusal.", { apiError })
    }
  })
}
