export enum ContractModelType {
  PAYMENT = "PM",
  INVOICE = "IM"
}

export const PAYMENT_CONTRACT_ALIAS_OPTIONS = [
  { title: "Waged", value: "P/WAGED" },
  { title: "Salaried", value: "P/SALARY" }
]

export const INVOICE_CONTRACT_ALIAS_OPTIONS = [
  { title: "Block", value: "BLOCK" },
  { title: "CCG", value: "CCG" },
  { title: "Clinical", value: "CLINICAL" },
  { title: "Direct Payment", value: "DP" },
  { title: "Extra Care", value: "EC" },
  { title: "ICB", value: "ICB" },
  { title: "Individual Budget", value: "IB" },
  { title: "Internal", value: "IN" },
  { title: "Local Authority", value: "LA" },
  { title: "Managed Direct Payment", value: "MDP" },
  { title: "PCT", value: "PCT" },
  { title: "Private", value: "PRV" },
  { title: "Other", value: "OTHER" }
]

export const INVOICE_CONTRACT_PUBLIC_ALIASES = ["BLOCK", "CCG", "EC", "ICB", "LA", "OTHER", "PCT", "CLINICAL"]
export const INVOICE_CONTRACT_PRIVATE_ALIASES = ["DP", "IB", "IN", "MDP", "PRV"]

export const INVOICE_CONTRACT_PRIVATE_ALIAS_OPTIONS = INVOICE_CONTRACT_ALIAS_OPTIONS.filter((option) =>
  INVOICE_CONTRACT_PRIVATE_ALIASES.includes(option.value)
)
export const INVOICE_CONTRACT_PUBLIC_ALIAS_OPTIONS = INVOICE_CONTRACT_ALIAS_OPTIONS.filter((option) =>
  INVOICE_CONTRACT_PUBLIC_ALIASES.includes(option.value)
)
