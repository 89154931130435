import React from "react"
import Grid from "@mui/material/Grid"
import TransactionDetailItemHeader from "./TransactionDetailItemHeader"
import { Typography } from "@mui/material"
import TransactionDetailItemSection from "./TransactionDetailItemSection"
import TransactionDetailItemSectionRow from "./TransactionDetailItemSectionRow"
import { BackButton, ContentContainer, Divider, Header } from "components"
import dateTimeUtils from "lib/datetime"
import { DateTime } from "luxon"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import { useInvoiceProcessingTransactionVisitCalculationQuery } from "data/finance/invoiceProcessing/queries"
import { usePaymentProcessingTransactionVisitCalculationQuery } from "data/finance/paymentProcessing/queries"
import { parseTransaction } from "./helpers/parseTransactionItem"
import { usePeriodQuery } from "data/finance/contractModel/queries"
import { useParams } from "react-router-dom"

const visitOverviewHeaders = [
  "Date",
  "Visit Status",
  "Date Proc",
  "Service",
  "Rost time",
  "Rost dur",
  "Actual time",
  "Act dur"
]
const chargeRulesHeaders = ["Variations", "Pay Rule", "Cap rule", "Figure"]
const paymentRulesCancelledHeaders = ["Reason", "Notice", "Pay Rule", "Cap rule", "Figure"]
const invoiceServicesHeaders = ["Charge type", "Band", "Rounding rule", "Round min", "Charge time", "Charge dur"]
const paymentModelHeaders = [
  "Contract model",
  "Service type",
  "Charge type",
  "Band",
  "Rounding rule",
  "Round min",
  "Pay time",
  "Pay dur"
]
const chargeBreakdownHeaders = ["Day rate", "Rate period", "Rate", "Dur", "Value"]
const additionalChargesHeaders = ["Description", "Rate", "Quantity", "Value"]

interface OwnProps {
  modelType: ContractModelType
}

const TransactionDetailItem: React.FC<OwnProps> = ({ modelType }) => {
  const { periodGuid, contractGuid, calendarGuid, transactionGuid, transactionItemGuid } = useParams()
  const isInvoiceModel = modelType === ContractModelType.INVOICE

  const { data: period } = usePeriodQuery({
    modelType,
    contractGuid,
    calendarGuid,
    periodGuid
  })

  const { data: invoiceVisitCalculations } = useInvoiceProcessingTransactionVisitCalculationQuery({
    periodGuid,
    transactionGuid,
    transactionVisitGuid: transactionItemGuid,
    enabled: isInvoiceModel
  })
  const { data: paymentVisitCalculations } = usePaymentProcessingTransactionVisitCalculationQuery({
    periodGuid,
    transactionGuid,
    transactionVisitGuid: transactionItemGuid,
    enabled: !isInvoiceModel
  })

  const transactionItem = parseTransaction({
    isInvoiceModel,
    item: isInvoiceModel ? invoiceVisitCalculations : paymentVisitCalculations
  })

  if (!transactionItem) return null

  const {
    visitOverview,
    chargeRules,
    invoiceServices,
    chargeBreakdown,
    extraCharges,
    paymentModels,
    paymentRulesCancelledVisit
  } = transactionItem

  return (
    <ContentContainer data-cy={testingId.invoiceProcessingTransactionDetailItem.screen}>
      <BackButton fallbackUrl=".." />
      <Header
        title={
          <>
            {isInvoiceModel
              ? `${transactionItem.contract_model}: ${transactionItem.status} / Visit`
              : `${transactionItem.paymentModels.paymentModel} (${dateTimeUtils.formatDate(
                  period ? period.start : DateTime.local()
                )} - ${dateTimeUtils.formatDate(period ? period.end : DateTime.local())}): ${
                  transactionItem.owner_name
                } / Visit`}
          </>
        }
        data-cy={testingId.invoiceProcessingTransactionDetailItem.header}
      />
      <TransactionDetailItemHeader visit={transactionItem} modelType={modelType} />
      <Grid container spacing={1}>
        <Grid item md={9}>
          <Typography variant="h6" data-cy={testingId.invoiceProcessingTransactionDetailItem.totalChargeableHours}>
            Total chargeable hours: {invoiceServices.duration_charge}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography variant="h6" data-cy={testingId.invoiceProcessingTransactionDetailItem.totalValue}>
            Total value: {transactionItem.totalValue}
          </Typography>
        </Grid>
      </Grid>
      <Divider color="divider" />

      <TransactionDetailItemSection
        title="Visit Overview"
        headers={visitOverviewHeaders}
        data={Object.values(visitOverview)}
        section="visit-overview"
        data-cy={testingId.invoiceProcessingTransactionDetailItem.sectionVisitOverview}
      />

      <TransactionDetailItemSection
        title={`${isInvoiceModel ? "Charge" : "Payment"} rules`}
        headers={transactionItem.isCancelled ? paymentRulesCancelledHeaders : chargeRulesHeaders}
        data={transactionItem.isCancelled ? Object.values(paymentRulesCancelledVisit) : Object.values(chargeRules)}
        section="charge-rules"
        data-cy={testingId.invoiceProcessingTransactionDetailItem.sectionChargeRules}
      />

      <TransactionDetailItemSection
        title={isInvoiceModel ? "Invoice Services" : "Payment model"}
        headers={isInvoiceModel ? invoiceServicesHeaders : paymentModelHeaders}
        data={Object.values(isInvoiceModel ? invoiceServices : paymentModels)}
        section={isInvoiceModel ? "invoice-services" : "payment-model"}
        data-cy={
          isInvoiceModel
            ? testingId.invoiceProcessingTransactionDetailItem.sectionInvoiceServices
            : testingId.invoiceProcessingTransactionDetailItem.sectionPaymentModel
        }
      />

      <TransactionDetailItemSectionRow
        title={`${isInvoiceModel ? "Charge" : "Payment"} breakdown`}
        headers={chargeBreakdownHeaders}
        data={chargeBreakdown.map((cb) => Object.values(cb))}
        section="charge-breakdown"
        data-cy={testingId.invoiceProcessingTransactionDetailItem.sectionChargeBreakdown}
      />

      <TransactionDetailItemSectionRow
        title="Additional charges (excluded from Total Value)"
        headers={additionalChargesHeaders}
        data={extraCharges.map((ev) => Object.values(ev))}
        section="additional-charges"
        data-cy={testingId.invoiceProcessingTransactionDetailItem.sectionAdditionalCharges}
      />
    </ContentContainer>
  )
}

export default React.memo(TransactionDetailItem)
