import { getOptionsFromSchema } from "lib/schema"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { pipe, prop, sortBy, toLower } from "ramda"
import React, { memo, useMemo } from "react"
import { OperatorPermissionTableRow } from "./OperatorPermissionTableRow"
import { SchemaFieldOption } from "lib/types"
import { useCoreSchemasStore } from "stores"

export const OperatorProfilePermissionTable: React.FC = memo(() => {
  OperatorProfilePermissionTable.displayName = "OperatorProfilePermissionTable"

  const { ApiResource } = useCoreSchemasStore((state) => state.schema.models)

  const memoizedRows = useMemo(() => {
    const resourceOptions = getOptionsFromSchema(ApiResource, "guid")
    const permissions = sortBy(pipe(prop("title"), toLower))(resourceOptions)

    return permissions.map((permission: SchemaFieldOption) => (
      <OperatorPermissionTableRow key={permission.value} permission={permission} />
    ))
  }, [ApiResource])

  return (
    <Table
      sx={(theme) => ({
        margin: theme.spacing(3, 0, 0, 3)
      })}
    >
      <TableHead
        sx={{
          "&>td, &>th": {
            padding: "8px"
          }
        }}
      >
        <TableRow>
          <TableCell>*</TableCell>
          <TableCell>Permission</TableCell>
          <TableCell>Resource</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{memoizedRows}</TableBody>
    </Table>
  )
})
