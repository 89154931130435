import { InvoiceTransactionCharge } from "data/finance/invoiceProcessing/types"
import { InvoiceTransactionChargeFilter } from "../types"

export const filterInvoiceTransactionCharges = ({
  charges,
  filter
}: {
  charges: InvoiceTransactionCharge[]
  filter: InvoiceTransactionChargeFilter
}): InvoiceTransactionCharge[] => {
  if (!filter) return charges

  const { reference_owner_name, charge_type_alias } = filter
  let result = charges
  if (reference_owner_name) {
    result = result.filter((item) =>
      item.reference_owner_name
        ? item.reference_owner_name.toLowerCase().indexOf(reference_owner_name.toLowerCase()) !== -1
        : false
    )
  }

  if (charge_type_alias?.length) {
    result = result.filter((item) => charge_type_alias.includes(item.charge_type_alias))
  }

  return result
}
