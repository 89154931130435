import React from "react"
import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { ContentContainer, EmptyData, Header, Link, Divider } from "components"
import { Button } from "components/Form"
import { optionsToValueTitle } from "lib/schema"
import { useContractRuleConditionListQuery } from "data/finance/contractRuleCondition/queries"
import { ContractRuleCondition } from "data/finance/contractRuleCondition/types"
import { contractRuleCancellationOptions, contractRuleConfirmedOptions } from "./data"
import { useSchema } from "data/schema/useSchema"
import { useLocation, useNavigate } from "react-router-dom"

const List: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { Rule } = useSchema(["Rule"])
  const ruleTypeAliasOptions = optionsToValueTitle(Rule)

  const { data: contractRuleConditions } = useContractRuleConditionListQuery()

  const allTypes = optionsToValueTitle([...contractRuleCancellationOptions, ...contractRuleConfirmedOptions])

  return (
    <ContentContainer>
      <Header title="Contract rule conditions" />
      <Box m={3} mx={0}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item md={9} />
          <Grid item md={3}>
            <Link to="new">
              <Button>Create a condition</Button>
            </Link>
          </Grid>
        </Grid>
        <Divider color="divider" />
        {contractRuleConditions?.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Reason</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align="right">Category</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contractRuleConditions.map((c: ContractRuleCondition) => (
                <TableRow
                  key={c.guid}
                  hover
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`${location.pathname}/${c.guid}`)
                  }}
                >
                  <TableCell>{c.title}</TableCell>
                  <TableCell>{c.type ? allTypes[c.type] : "-"}</TableCell>
                  <TableCell align="right">{ruleTypeAliasOptions[c.rule_type_alias]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyData message="No contract care rule conditions found, start by adding one." />
        )}
      </Box>
    </ContentContainer>
  )
}

export default React.memo(List)
