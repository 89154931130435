import React, { CSSProperties } from "react"
import AsyncSelect from "react-select/async"
import FormControl from "@mui/material/FormControl"
import { useTheme } from "@mui/material/styles"
import useSingleSelect from "./useSingleSelect"
import useMultiSelect from "./useMultiSelect"
import testingId from "constants/testingId"
import { ControlProps, ValueContainerProps } from "react-select"
import { Box, TextField } from "@mui/material"

export interface OptionType {
  label: string
  value: string
}

export default function IntegrationReactSelect(props: any) {
  const { input, label, placeholder, defaultOptions, loadOptions, isMulti, ...rest } = props

  const theme = useTheme()

  const selectStyles = {
    menu: (base: CSSProperties) => ({ ...base, zIndex: 2 }),
    menuPortal: (base: CSSProperties) => ({
      ...base,
      zIndex: 9999
    }),
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      },
      display: "inline-block"
    }),
    indicatorsContainer: () => ({
      alignItems: "center",
      display: "fex",
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0
    })
  }

  function ValueContainer(props: ValueContainerProps<OptionType, false>) {
    return <Box>{props.children}</Box>
  }

  function Control(props: ControlProps<OptionType, false>) {
    const {
      children,
      innerProps,
      innerRef,
      selectProps: { TextFieldProps }
    } = props

    return (
      <TextField
        fullWidth
        InputProps={{
          inputComponent: Box,
          inputProps: {
            ref: innerRef,
            children,
            sx: { height: "auto" },
            ...innerProps
          }
        }}
        variant="outlined"
        {...TextFieldProps}
      />
    )
  }

  const components = {
    Control,
    ValueContainer
  }

  const multiSelect = useMultiSelect(props, components)
  const singleSelect = useSingleSelect(props, components)

  const { configProps } = isMulti ? multiSelect : singleSelect

  return (
    <FormControl margin="normal" fullWidth data-cy={testingId.autocompleteField}>
      <AsyncSelect
        {...input}
        {...rest}
        styles={selectStyles}
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        inputId={`autocomplete-${input.name}`}
        TextFieldProps={{
          label: label,
          InputLabelProps: {
            htmlFor: `autocomplete-${input.name}`,
            shrink: true
          }
        }}
        placeholder={placeholder}
        isClearable
        {...configProps}
      />
    </FormControl>
  )
}
