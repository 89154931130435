import React, { memo } from "react"
import { TableCell, TableHead, TableRow } from "@mui/material"
import { Delete } from "@mui/icons-material"

export const BandEditorHeader = memo(() => (
  <TableHead>
    <TableRow>
      <TableCell
        sx={{
          width: "20px"
        }}
      >
        <Delete />
      </TableCell>
      <TableCell
        sx={{
          width: "25%",
          minWidth: "125px"
        }}
      >
        Minimum duration (minutes)
      </TableCell>
      <TableCell
        sx={{
          width: "25%",
          minWidth: "150px"
        }}
      >
        Maximum duration (minutes)
      </TableCell>
      <TableCell
        align="right"
        sx={{
          width: "150px"
        }}
      >
        Rounding rule
      </TableCell>
      <TableCell
        align="right"
        sx={{
          width: "20px"
        }}
      >
        Rounding
      </TableCell>
      <TableCell
        sx={{
          width: "20px"
        }}
      />
    </TableRow>
  </TableHead>
))

BandEditorHeader.displayName = "BandEditorHeader"
