import { useShiftCategoryContractDeleteMutation } from "data/finance/shiftCategory/mutations"
import { useCallback } from "react"
import { useQueryClient } from "react-query"
import { queryKeys } from "data/finance/shiftCategory/queries"
import { GENERAL_ERROR_MESSAGE } from "api/instance"
import { useApiMessage } from "stores"

type DeleteHandler = ({
  shiftCategoryGuid,
  selectedContracts
}: {
  shiftCategoryGuid: string
  selectedContracts: string[]
}) => Promise<void>

export const useDeleteShiftCategoryContractHandler = (): DeleteHandler => {
  const queryCache = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const { mutateAsync: deleteContract } = useShiftCategoryContractDeleteMutation()

  return useCallback(
    async ({ shiftCategoryGuid, selectedContracts }) => {
      try {
        await Promise.all(
          selectedContracts.map((contractGuid) => {
            return deleteContract({ shiftCategoryGuid, contractGuid })
          })
        )
        await queryCache.invalidateQueries(queryKeys.getShiftCategoryContractsKey(shiftCategoryGuid))
        showSuccessMessage("Contract successfully deleted.")
      } catch (apiError: any) {
        await queryCache.invalidateQueries(queryKeys.getShiftCategoryContractsKey(shiftCategoryGuid))
        showErrorMessage(GENERAL_ERROR_MESSAGE, { apiError })
      }
    },
    [queryCache, deleteContract, showSuccessMessage, showErrorMessage]
  )
}
