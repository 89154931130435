import { FormSectionHeader } from "components/Form"
import { ContractModelType } from "constants/modelTypes"
import { FormApi } from "final-form"
import { filter, keys, pipe } from "ramda"
import React, { memo, useMemo } from "react"
import { useFormState } from "react-final-form"
import { sortUpliftItemsByIndex } from "../../helpers/sortUpliftItemsByIndex"
import { getPickedUpliftModels, isModelWithRates } from "../../helpers/upliftFormGetters"
import { RateUpliftForm, RateUpliftFormModel } from "../../types"
import { RateUpliftSelect } from "./RateUpliftSelect"
import { RateUpliftThreeModel } from "./RateUpliftThreeModel"
import { IndexedContractModel } from "data/finance/contractModel/types"

interface OwnProps {
  contracts: IndexedContractModel[]
  form: FormApi<RateUpliftForm>
  modelType: ContractModelType
}

export const RateUpliftThree: React.FC<OwnProps> = memo(({ contracts, form, modelType }) => {
  RateUpliftThree.displayName = "RateUpliftThree"

  const { values } = useFormState<RateUpliftForm>()

  // all models that are picked and have a service (validation should be in step two)
  const contractModelGuids = pipe(
    filter(
      (model: RateUpliftFormModel) =>
        model.picked && Object.values(model.services || {}).some((service) => service.picked)
    ),
    keys
  )(values.models) as string[]

  const sortedContractModels = sortUpliftItemsByIndex({ items: contracts, guids: contractModelGuids })

  const modelsWithRatesCount = useMemo(() => {
    const pickedModels = getPickedUpliftModels(values)
    return pickedModels.filter(isModelWithRates).length
  }, [values])

  return (
    <>
      <FormSectionHeader title="Rates" />
      {!!modelsWithRatesCount && (
        <RateUpliftSelect {...{ isServiceLevel: false, upliftFieldsOwnerName: "", upliftType: values.uplift_type }} />
      )}
      {sortedContractModels.map((contract) => {
        return (
          <RateUpliftThreeModel
            {...{
              contract,
              form,
              modelType,
              defaultOpen: modelsWithRatesCount < 5
            }}
            key={contract.guid}
          />
        )
      })}
    </>
  )
})
