import { StatusEnum } from "data/commonTypes"
import { DateTime } from "luxon"
import { BillingFrequency } from "../contractModel/types"

// frequency_type_alias, rate and start are ? for form validator
export interface ContractRate {
  guid: string
  end?: DateTime
  frequency_type_alias?: BillingFrequency
  hours: number
  rate?: number
  start?: DateTime
  status: StatusEnum
  delete?: boolean // for form
}

export interface BaseApiContract {
  guid: string
  contract_guid: string
  contract_model: string
  contract_type_alias: string
  end?: DateTime
  is_default: boolean
  reference: string
  start: DateTime
  status: StatusEnum
}

export interface ApiClientContractContribution {
  guid: string
  end?: DateTime
  rate: number
  start: DateTime
  status?: StatusEnum
  user_contract_guid: string
  user_contract_title: string
}

export interface ApiClientContractPayloadContribution {
  guid: string
  end?: DateTime
  rate: number
  start: DateTime
  user_contract_guid: string
  delete?: boolean // for form
}

type InvoiceDelivery = "EMAIL" | "POST"
type OutputTypeAlias = "PRV"
type PaymentMethod = "BANK" | "CHEQUE" | "CARD" | "D/DEBIT" | "DP/CARD" | "PAYPOINT" | "PENDING" | "POSTAL" | "VOUCHER"
type FunderSalutation =
  | "Mr"
  | "Mrs"
  | "Miss"
  | "Ms"
  | "Mx"
  | "Dr"
  | "Lady"
  | "Master"
  | "Lord"
  | "Sir"
  | "Dame"
  | "Reverend"
  | "Sister"

export enum FunderTypes {
  INDIVIDUAL = "Individual",
  ORGANISATION = "Organisation"
}

export interface ApiUserContractFinance {
  address_line_1: string
  address_line_2?: string
  city: string
  county?: string
  country?: string
  email?: string
  finance_id: string
  for_attention_of?: string
  funder_first_name?: string
  funder_last_name?: string
  funder_salutation?: FunderSalutation
  funder_type: FunderTypes
  funding_name?: string
  output_delivery_type_alias: InvoiceDelivery
  output_type_alias: OutputTypeAlias
  payment_type_alias: PaymentMethod
  postcode: string
  term_days: number
}

export interface ApiClientContract {
  guid: string
  alternative_social_service_ref?: string
  contract_guid: string
  contract_model: string
  contract_type_alias: string
  contribution_sources?: { guid: string; title: string }[]
  contributions?: ApiClientContractContribution[]
  end?: DateTime
  finance: ApiUserContractFinance
  is_contributable: boolean
  is_default: boolean
  is_one_to_one: boolean
  rates: ContractRate[]
  owner_guid: string
  owner_name: string
  reference: string
  social_service_ref: string
  start: DateTime
  status: StatusEnum
}

export interface ApiClientContractPayload {
  guid?: string
  contract_guid: string
  social_service_ref: string
  alternative_social_service_ref: string
  contract_model: string
  contract_model_type: string
  contract_type_alias: string
  is_default?: boolean
  finance: ApiUserContractFinance
  clientId: string
  start: DateTime
  end?: DateTime
  no_end_date?: boolean
  contributions: ApiClientContractPayloadContribution[] | null
  rates?: ContractRate[]
}
