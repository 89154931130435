const testingId = {
  // reusable components
  addressEditButton: "addressEditButton",
  addressAutocompleteInput: "addressAutocompleteInput",
  alertSnackBar: "alertSnackBar",
  button: "button",
  checkboxField: "checkboxField",
  datePickerField: "datePickerField",
  datePickerModal: "datePickerModal",
  autocompleteField: "autocompleteField",
  emptyDataBox: "emptyDataBox",
  header: "header",
  textField: "textField",
  userListItem: "userListItem",
  userListItemName: "userListItemName",
  select: "select",
  selectOption: "selectOption",
  settingsForm: "settingsForm",
  submitButton: "submitButton",
  cancelButton: "cancelButton",
  deleteButton: "deleteButton",
  wizardForm: "wizardForm",
  wizardCreateNextSectionButton: "wizardNextSectionButton",
  wizardCreateSaveChangesButton: "wizardSaveChangesButton",
  dayFilterLeftArrow: "dayFilterLeftArrow",
  dayFilterRightArrow: "dayFilterRightArrow",
  warningMessage: "warningMessage",
  preferenceMultiselect: "preferenceMultiselect",
  userList: "userList",
  bottomErrorMessage: "bottomErrorMessage",

  pagination: "pagination",
  selectRowsPerPage: "rowsPerPage",
  nextPage: "nextPage",
  previousPage: "previousPage",

  clearFilterButton: "clearFilterButton",
  onboardingStatusChip: "carerListOnboardingStatusChip",
  // reusable ids
  checkbox: "checkbox",
  filterTransactionPeriodMultiselect: "filterTransactionPeriodMultiselect",
  selectAllForProcessing: "selectAllForProcessing",
  selectAllForCrediting: "selectAllForCrediting",
  tableRow: "tableRow",
  tableHeaderRow: "tableHeaderRow",
  tableCell: "tableCell",
  tableHeaderCell: "tableHeaderCell",
  tableTitle: "tableTitle",
  statsItem: "statsItem",
  preferenceMultiselectCloseButton: "preferenceMultiselectCloseButton",
  pageTitle: "pageTitle",
  loader: "loader",
  // summary list in modal
  summaryList: {
    headerCell: "summaryListHeaderCell",
    tableRow: "summaryListTableRow",
    tableCell: "summaryListTableCell",
    totalValue: "summaryListTotalValue",
    totalUsers: "summaryListTotalUsers"
  },

  // screens
  addUserTransactionModal: {
    saveButton: "addUserTransactionModalSaveButton"
  },
  creditingModal: {
    modal: "creditingModal",
    headerCell: "headerCell",
    totalValue: "totalValue",
    listRow: "creditingModalListRow",
    listContractModel: "creditingModalListCellInvoiceModel",
    listCellTotalVisits: "creditingModalListCellTotalVisits",
    listCellUsers: "creditingModalListCellUsers",
    listCellVisits: "creditingModalListCellVisits",
    listCellTotalExtra: "creditingModalListCellTotalExtra",
    listCellTotal: "creditingModalListCellTotal",
    confirmButton: "creditingModalConfirmButton",
    cancelButton: "creditingModalCancelButton",
    closeButton: "creditingModalCloseButton"
  },
  transactionGroupInvoicingModal: {
    modal: "invoiceSettingModal",
    cancelButton: "modalCancelButton",
    processInvoicesButton: "processInvoiceButton"
  },
  client: {
    create: {
      cm2000Header: "clientCreateCm2000Header",
      enableCm2000Checkbox: "clientCreateEnableCm2000Checkbox"
    },
    contract: {
      screen: "clientContractListScreen",
      detail: {
        screen: "clientContractDetailScreen",
        modelName: "clientContractDetailModelName",
        contributionSection: "clientContractDetailContributionHeader",
        contributionPrivateHeader: "clientContractDetailContributionPrivateHeader",
        contributionPrivateTableRow: "clientContractDetailContributionPrivateTableRow"
      },
      list: {
        screen: "clientContractsListScreen",
        table: {
          headCell: "clientContractsListTableHeadCell",
          rowCell: "clientContractsListTableRowCell",
          bodyCell: "clientContractsListTableBodyCell",
          bodyRow: "clientContractsListTableBodyRow"
        }
      }
    },
    list: {
      screen: "clientListScreen",
      showFilters: "clientShowFilters",
      activeModelFields: "clientActiveModelFields",
      carePreferences: "clientCarePreferences",
      carePackages: "clientCarePackages",
      carerCulture: "clientCarerCulture"
    }
  },
  appointmentSearch: {
    bulkCreditButton: "bulkCreditButton",
    bulkModal: "bulkModal",
    creditAppointmentsButton: "creditAppointmentsButton"
  },
  invoiceProcessingTransactionDetail: {
    screen: "invoiceProcessingTransactionDetailScreen",
    header: "invoiceProcessingTransactionDetailHeader",
    tabVisits: "invoiceProcessingTransactionDetailTabVisits",
    tabExtras: "invoiceProcessingTransactionDetailTabExtras",
    statsItem: "invoiceProcessingTransactionDetailStatsItem",
    listItemCell: "invoiceProcessingTransactionDetailListItemCell",
    listItemCheckbox: "invoiceProcessingTransactionDetailListItemCheckbox",
    listNumOfRecords: "invoiceProcessingTransactionDetailListNumOfRecords",
    creditButton: "invoiceProcessingTransactionDetailCreditButton",
    toggleAllForCrediting: "invoiceProcessingTransactionDetailToggleAllForCrediting",
    extrasList: "invoiceProcessingTransactionDetailExtrasList",
    extrasListItem: "invoiceProcessingTransactionDetailExtrasListItem",
    extrasListItemEditIcon: "invoiceProcessingTransactionDetailExtrasListItemEditIcon",
    extrasListItemCell: "invoiceProcessingTransactionDetailExtrasListItemCell",
    extrasListNumOfRecords: "invoiceProcessingTransactionDetailExtrasListNumOfRecords",
    extrasListDeleteButton: "invoiceProcessingTransactionDetailExtrasListItemDeleteButton",
    extrasDeleteModal: "invoiceProcessingTransactionDetailExtrasListDeleteModal",
    extrasDeleteModalCount: "invoiceProcessingTransactionDetailExtrasListDeleteModalCount",
    extrasDeleteModalTableRow: "invoiceProcessingTransactionDetailExtrasListDeleteModaltableRow",
    extrasDeleteModalTableCell: "invoiceProcessingTransactionDetailExtrasListDeleteModaltableCell"
  },
  invoiceProcessingTransactionDetailItem: {
    screen: "invoiceProcessingTransactionDetailItemScreen",
    header: "invoiceProcessingTransactionDetailItemHeader",
    headerClient: "invoiceProcessingTransactionDetailItemHeaderClient",
    headerContractNumber: "invoiceProcessingTransactionDetailItemHeaderContractNumber",
    headerContractModel: "invoiceProcessingTransactionDetailItemHeaderContractModel",
    headerCarerName: "invoiceProcessingTransactionDetailItemHeaderCarerName",
    headerPayrollNumber: "invoiceProcessingTransactionDetailItemHeaderPayrollNumber",
    headerPayModel: "invoiceProcessingTransactionDetailItemHeaderPayModel",
    headerInvoiceStatus: "invoiceProcessingTransactionDetailItemHeaderInvoiceStatus",
    headerInvoiceNumber: "invoiceProcessingTransactionDetailItemHeaderInvoiceNumber",
    headerInvoiceDate: "invoiceProcessingTransactionDetailItemHeaderInvoiceDate",
    totalChargeableHours: "invoiceProcessingTransactionDetailItemTotalChargeableHours",
    totalValue: "invoiceProcessingTransactionDetailItemTotalValue",
    sectionVisitOverview: "invoiceProcessingTransactionDetailItemSectionVisitOverview",
    sectionChargeRules: "invoiceProcessingTransactionDetailItemSectionChargeRules",
    sectionInvoiceServices: "invoiceProcessingTransactionDetailItemSectionInvoiceServices",
    sectionPaymentModel: "invoiceProcessingTransactionDetailItemSectionPaymentModel",
    sectionChargeBreakdown: "invoiceProcessingTransactionDetailItemSectionChargeBreakdown",
    sectionAdditionalCharges: "invoiceProcessingTransactionDetailItemSectionAdditionalCharges",
    sectionRow: "invoiceProcessingTransactionDetailItemSectionRow",
    sectionCell: "invoiceProcessingTransactionDetailItemSectionCell"
  },
  invoiceProcessingTransactionGroupList: {
    cell: "invoiceProcessingTransactionGroupListCell"
  },
  invoiceProcessingTransactionList: {
    screen: "invoiceProcessingTransactionListScreen",
    header: "invoiceProcessingTransactionListHeader",
    invoiceModelStatsItem: "invoiceProcessingTransactionListInvoiceModelStatsItem",
    itemCell: "invoiceProcessingTransactionListItemCell",
    itemCheckbox: "invoiceProcessingTransactionListItemCheckbox",
    invoiceSelectedButton: "invoiceProcessingTransactionListInvoiceSelectedButton",
    creditSelectedButton: "invoiceProcessingTransactionListCreditSelectedButton",
    selectionError: "invoiceProcessingTransactionListSelectionError",
    toggleAllForInvoicing: "invoiceProcessingTransactionListToggleAllForInvoicing",
    toggleAllForCrediting: "invoiceProcessingTransactionListToggleAllForCrediting",
    addClientButton: "invoiceProcessingTransactionListAddClientButton"
  },
  invoiceProcessingChargeModal: {
    form: "invoiceProcessingChargeModalForm"
  },
  invoiceModel: {
    rules: {
      addRuleButton: "addRuleButton",
      rulesListTitle: "rulesListTitle",
      tableHeader: "rulesHeader",
      tableRow: "rulesListTableRow",
      tableCell: "rulesListTableCell",
      errorBox: "rulesErrorBox",
      conditionSelect: "conditionSelect",
      chargeTypeSelect: "chargeTypeSelect",
      capTypeSelect: "capTypeSelect",
      figureInput: "figureRuleInput",
      deleteCheckbox: "deleteRuleCheckbox",
      periodTypeSelect: "periodTypeSelect",
      periodStartInput: "periodStartInput",
      intervalSelect: "intervalSelect",
      periodEndInput: "periodEnd",
      confirmedVisit: "confirmedVisitTab",
      cancellationTab: "cancellationsTab"
    }
  },
  contractModel: {
    list: {
      table: "contractModelListTable",
      expiredTable: "contractModelListExpiredTable",
      createButton: "contractModelCreateButton"
    },
    calendar: {
      list: {
        screen: "contractModelCalendarListScreen",
        addButton: "contractModelCalendarListAddButton",
        deleteCheckbox: "contractModelCalendarListDeleteCheckbox",
        tableRow: "contractModelCalendarListTableRow",
        tableCell: "contractModelCalendarListTableCell",
        deleteButton: "contractModelCalendarListDeleteButton"
      },
      detail: {
        headerName: "contractModelCalendarDetailName",
        headerBillingPeriod: "contractModelCalendarDetailHeaderBillingPeriod",
        headerStartDate: "contractModelCalendarDetailHeaderStartDate",
        headerEndDate: "contractModelCalendarDetailHeaderEndDate",
        periodAddButton: "contractModelCalendarDetailPeriodAddButton"
      }
    },
    rates: {
      errorBox: "errorBox",
      headerCell: "contractModelHeaderCell",
      addButton: "contractModelRateAddButton"
    },
    services: {
      title: "servicesTitle",
      deleteCheckbox: "deleteCheckbox",
      bandRow: "serviceBandRow",
      minimumDurationInput: "bandsMinimumDurationInput",
      maximumDurationInput: "bandsMaximumDurationInput",
      roundingRuleSelect: "bandsRoundingRuleSelect",
      roundingInput: "bandsRoundingInput",
      addRowButton: "bandsNewRowButton",
      addRowsButton: "bandsNewRowsButton",
      ratesLink: "ratesLink",
      copyModal: "serviceCopyModal"
    }
  },
  paymentProcessingTransactionList: {
    addCarerButton: "paymentProcessingTransactionListAddCarerButton",
    totalCostTitle: "paymentProcessingTransactionListScreenCostTitle",
    table: "paymentProcessingTransactionListTable",
    itemCheckbox: "paymentProcessingTransactionListItemCheckbox"
  },
  paymentProcessingGroupList: {
    totalCostTitle: "paymentProcessingGroupListScreenTitle",
    table: "paymentProcessingGroupListTable",
    listItemCheckbox: "paymentProcessingGroupListItemCheckbox"
  },
  paymentProcessingTransactionDetail: {
    screen: "paymentProcessingTransactionDetailItemScreen",
    tabVisits: "paymentProcessingTransactionDetailTabVisits",
    tabExtras: "paymentProcessingTransactionDetailTabExtras",
    statsItem: "paymentProcessingTransactionDetailStatsItem",
    listItemCheckbox: "paymentProcessingTransactionDetailListItemCheckbox",
    listNumOfRecords: "paymentProcessingTransactionDetailListNumOfRecords",
    creditButton: "paymentProcessingTransactionDetailListCreditButton",
    deleteButton: "paymentProcessingTransactionDetailListDeleteButton",
    addExtraButton: "paymentProcessingTransactionDetailListAddExtraButton",
    editButton: "paymentProcessingTransactionDetailListEditButton",
    selectAllChargesButton: "paymentProcessingSelectAllChargesButton"
  },
  paymentProcessingShiftTransactionDetail: {
    screen: "paymentProcessingTransactionShiftDetailScreen",
    totalChargeableHours: "paymentProcessingShiftTransactionDetailTotalChargeableHours",
    totalValue: "paymentProcessingShiftTransactionDetailTotalValue",
    sectionChargeRules: "paymentProcessingShiftTransactionDetailSectionChargeRules",
    sectionPaymentModel: "paymentProcessingShiftTransactionDetailSectionPaymentModel",
    sectionChargeBreakdown: "paymentProcessingShiftTransactionDetailSectionChargeBreakdown",
    sectionAdditionalCharges: "paymentProcessingShiftTransactionDetailSectionAdditionalCharges",
    sectionVisitOverview: "paymentProcessingShiftTransactionDetailSectionVisitOverview",
    sectionVisitsOverview: "paymentProcessingShiftTransactionDetailSectionVisitsOverview",
    headerClient: "paymentProcessingShiftTransactionDetailHeaderClient",
    headerContractNumber: "paymentProcessingShiftTransactionDetailHeaderContractNumber",
    headerContractModel: "paymentProcessingShiftTransactionDetailHeaderContractModel",
    headerShiftTitle: "paymentProcessingShiftTransactionDetailHeaderShiftTitle"
  },

  extrasModal: {
    screen: "extrasModalScreen",
    chargeTypeAlias: "extrasModalChargeTypeAlias",
    saveExtraButton: "extrasModalSaveExtraButton",
    extrasReason: "extrasModalExtrasReason",
    deleteButton: "extrasModalDeleteButton"
  },
  contractModelSettings: {
    screen: "contractModelSettingsScreen",
    title: "contractModelSettingsTitle",
    billingSection: "billingSection",
    invoiceSettings: "invoiceSettings",
    cancelButton: "contractModelSettingCancelButton",
    saveButton: "contractModelSettingSaveButton",
    enableCm2000Checkbox: "carerCreateEnableCm2000Checkbox"
  },
  contract: {
    title: "contractDetailTitle",
    settingsTab: "contractSettingsTab",
    bankDetailsTab: "contractBankDetailsTab",
    filesTab: "contractFilesTab"
  },
  clientContract: {
    ratesTableBody: "clientContractRatesTableBody",
    contributionsTableBody: "clientContractContributionsTableBody",
    addContributionButton: "clientContractAddContributionButton",
    addRateButton: "clientContractAddRateButton"
  },
  shifts: {
    screenTitle: "shiftsScreenTitle",
    category: {
      screen: "shiftCategoryScreen",
      list: "shiftCategoryList",
      row: "shiftCategoryRow",
      cell: "shiftCategoryCell",
      form: "shiftCategoryForm"
    },
    list: {
      screen: "shiftListScreen",
      row: "shiftListTableRow",
      cell: "shiftListTableCell",
      shiftNameInput: "shiftFilterNameInput",
      regionSelect: "shiftFilterRegionSelect",
      clearFilterButton: "shiftFilterClearButton"
    },
    detail: {
      screen: "shiftDetailScreen",
      shiftName: "shiftDetailShiftName",
      carerName: "shiftDetailCarerName",
      schedule: "shiftDetailSchedule",
      checkIn: "shiftDetailCheckIn",
      checkOut: "shiftDetailCheckOut",
      visitTable: "shiftDetailVisitTable",
      visitTableRow: "shiftDetailVisitTableRow",
      visitTableCell: "shiftDetailVisitTableCell"
    },
    contract: {
      modelName: "shiftContractModelName",
      reference: "shiftContractReference"
    }
  },
  alerts: {
    alertsTitle: "alertsTitle",
    invoicingTab: "invoicingTab",
    paymentsTab: "paymentsTab",
    tableHeaderRow: "alertsTableHeaderRow",
    tableHeaderCell: "alertsTableHeaderCell",
    tableBodyRow: "alertsTableBodyRow",
    tableBodyCell: "alertsTableBodyCell",
    recordsLength: "recordsLength"
  },
  fileManagement: {
    fileManagementTitle: "fileManagementTitle",
    invoicingTab: "invoicingTab",
    paymentsTab: "paymentsTab",
    fileManagementTableBodyCell: "fileManagementTableBodyCell",
    selectAllButton: "selectAllButton",
    downloadButton: "downloadButton",
    interfaceButton: "interfaceButton",
    downloadModalName: "downloadModal",
    modal: {
      headerSection: "headerSection",
      cancelButton: "modalCancelButton",
      downloadButton: "modalDownloadButton"
    }
  },
  interfaceFileViewer: {
    invoicingTab: "invoicingTab",
    paymentsTab: "paymentsTab",
    tableViewer: "tableViewer",
    tableHeaderCell: "interfaceFileViewerHeaderCell",
    tableRow: "interfaceFileViewerTableRow",
    tableCell: "interfaceFileViewerTableCell"
  },
  carer: {
    create: {
      cm2000Header: "carerCreateCm2000Header",
      enableCm2000Checkbox: "carerCreateEnableCm2000Checkbox",
      localAuthoritiesRequirements: "carerCreateLocalAuthoritiesRequirements",
      walesField: {
        section: "section"
      },
      scotlandFields: {
        section: "section"
      }
    },
    list: {
      screen: "carerListScreen",
      genderPreferences: "carerListGenderPreferences",
      workPreferences: "carerListWorkPreferences",
      package: "carerListPackage",
      experience: "carerListExperience",
      showFilters: "carerListShowFilters",
      postcode: "carerPostcode"
    },
    payAdvice: {
      payAdviceCarerInformationTitle: "carerInformationTitle",
      payAdviceFormTitle: "payAdviceFormTitle"
    }
  },
  holidayEstimation: {
    screen: "holidayEstimationScreen",
    totalMain: "holidayEstimationTotalMain",
    overviewTable: "holidaysOverviewTable",
    totals: "holidayEstimationTotals",
    table: "holidayEstimationTable"
  },
  bandEditor: {
    bandTitle: "bandEditorBandTitle"
  },
  syncStatus: {
    screen: "syncStatusScreen",
    title: "syncStatusTitle",
    refreshRateTitle: "syncStatusRefreshRateTitle",
    statusDot: "syncStatusDot",
    tooltip: "syncStatusTooltip"
  },
  report: {
    reportLooker: {
      screen: "reportLookerScreen",
      viewButton: "reportLookerViewButton",
      iframe: "reportLookerIframe"
    }
  },
  visit: {
    detail: {
      screen: "visitDetailsScreen",
      checkoutTime: "visitDetailsCheckoutTime",
      checkinTime: "visitDetailsCheckinTime",
      nfcCheckoutTime: "visitDetailsNfcCheckoutTime",
      nfcCheckinTime: "visitDetailsNfcCheckinTime",
      clientName: "visitDetailsClientName",
      carerName: "visitDetailsCarerName",
      schedule: "visitDetailsSchedule",
      status: "visitDetailsStatus",
      nfc: "visitDetailNfc",
      concern: "visitDetailsConcern",
      cancelCheckOut: "visitDetailsCancelCheckout",
      signOff: "visitDetailsSignOff",
      signOffState: "visitDetailsSignOffState",
      checkoutModal: "visitDetailsCheckoutModal",
      checkoutEdit: "visitDetailsCheckoutEdit",
      fieldTitle: "visitDetailsFieldTitle",
      checkoutModalReason: "visitDetailsCheckoutModalReason",
      checkinModalReason: "visitDetailsCheckinModalReason",
      tasks: {
        table: "visitDetailsTasksTable",
        tableRow: "visitDetailsTasksTableRow",
        tableCell: "visitDetailsTasksTableCell"
      },
      report: {
        title: "visitDetailsReportTitle",
        fieldTitle: "visitDetailsReportFieldTitle"
      },
      operator: {
        title: "visitDetailsOperatorTitle",
        comments: "visitDetailsOperatorComments"
      },
      variation: {
        section: "visitDetailVariationSection",
        model: "visitDetailVariationModel",
        variation: "visitDetailVariationVariation",
        service: "visitDetailService",
        reference: "visitDetailVariationReference",
        status: "visitDetailVariationStatus",
        editButton: "visitDetailVariationEditButton",
        modal: "visitDetailVariationModal",
        modalSaveButton: "visitDetailVariationModalSaveButton"
      }
    }
  },
  processingStatus: {
    statusBar: "processingStatusItemStatusBar"
  },
  messages: {
    NewMessagePanel: "NewMessagePanel",
    newMessageButton: "newMessageButton",
    NoChannelsFlowImage: "noChannelsFlowImage",
    channelsHeader: "channelsHeader",
    operatorNames: "operatorNames",
    branchOperators: "branchOperators",
    operatorCount: "operatorCount",
    messageInputPanel: "messageInputPanel",
    carerSearchField: "carerSearchField"
  },
  transactionItemStatus: "transactionItemStatus"
}

export default testingId
