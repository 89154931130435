import { FinanceApi } from "api"
import { ExtrasFormValues } from "pages/Finance/components/ExtrasModal"
import { useMutation, UseMutationResult } from "react-query"
import { useUIStore } from "stores"
import { InvoiceTransactionCharge } from "./types"

interface CreateInvoiceTransactionChargeProps {
  periodGuid: string
  transactionGuid: string
  charge: ExtrasFormValues
}

interface UpdateInvoiceTransactionChargeProps extends CreateInvoiceTransactionChargeProps {
  chargeGuid: string
}

interface DeleteInvoiceTransactionChargeProps {
  transactionGuid: string
  periodGuid: string
  chargeGuid: string
}

interface UpdateInvoiceTransactionItemChargeableProps {
  transactionGuid: string
  periodGuid: string
  itemGuid: string
  chargeable: boolean
}

export const useInvoiceTransactionChargeUpdateMutation = (): UseMutationResult<
  InvoiceTransactionCharge,
  Error,
  UpdateInvoiceTransactionChargeProps
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)

  return useMutation(
    async ({ charge, periodGuid, transactionGuid, chargeGuid }: UpdateInvoiceTransactionChargeProps) => {
      return FinanceApi.put(
        `/finance/invoice-processing/${periodGuid}/transactions/${transactionGuid}/charges/${chargeGuid}`,
        charge
      )
    },
    {
      onSuccess: () => {
        showSuccessMessage("Invoice transaction charge successfully updated.")
      }
    }
  )
}

export const useInvoiceTransactionChargeCreateMutation = (): UseMutationResult<
  InvoiceTransactionCharge,
  Error,
  CreateInvoiceTransactionChargeProps
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  return useMutation(
    async ({ charge, transactionGuid, periodGuid }: CreateInvoiceTransactionChargeProps) => {
      return FinanceApi.post(
        `/finance/invoice-processing/${periodGuid}/transactions/${transactionGuid}/charges`,
        charge
      )
    },
    {
      onSuccess: () => {
        showSuccessMessage("Contract extra charge successfully added.")
      }
    }
  )
}

export const useInvoiceTransactionChargeDeleteMutation = (): UseMutationResult<
  void,
  Error,
  DeleteInvoiceTransactionChargeProps
> => {
  return useMutation(async ({ transactionGuid, periodGuid, chargeGuid }) => {
    return FinanceApi.delete(
      `/finance/invoice-processing/${periodGuid}/transactions/${transactionGuid}/charges/${chargeGuid}`
    )
  })
}

export const useInvoiceTransactionItemNoChargeMutation = ({
  onOk
}: {
  onOk: () => void
}): UseMutationResult<void, Error, UpdateInvoiceTransactionItemChargeableProps> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  return useMutation(
    async ({ transactionGuid, periodGuid, itemGuid, chargeable }: UpdateInvoiceTransactionItemChargeableProps) => {
      return FinanceApi.put(
        `/finance/invoice-processing/${periodGuid}/transactions/${transactionGuid}/items/${itemGuid}/no-charges`,
        { no_charge: chargeable }
      )
    },
    {
      onSuccess: () => {
        onOk()
        showSuccessMessage("Appointment successfully updated.")
      }
    }
  )
}
