import {
  ContractModelEnhancedRate,
  ContractModelEnhancedRateModifierType,
  ContractModelRate,
  RateEditorPayload
} from "data/finance/contractModel/types"
import { FORM_ERROR } from "final-form"
import { FormValidation } from "lib/types"
import validators from "lib/validators"
import { set, merge } from "lodash"
import { DateTime } from "luxon"
import { head, last, uniq } from "ramda"

type Indexed<T> = T & { index: number }

const addIndexRemoveDeleted = <T extends { delete?: boolean }>(arr: T[] = []) =>
  arr.map((a, index) => ({ ...a, index })).filter((item) => !item.delete)

const parseRateTimeToMillis = (time: string) => DateTime.fromSQL(`2000-01-01 ${time}`).toMillis()

const sortByStart = <T extends { start: string }>(rates: T[]) => {
  return rates.sort((a: T, b: T) => {
    const timeA = parseRateTimeToMillis(a.start)
    const timeB = parseRateTimeToMillis(b.start)
    return timeA - timeB
  })
}

const validateRequiredFields = (basicRates: Indexed<ContractModelRate>[]) => {
  const errors: FormValidation = { basicRates: [] }

  basicRates.forEach((br) => {
    const validateRequired = (name: keyof ContractModelRate) => {
      const error = validators.required(br[name])
      if (error) set(errors, ["basicRates", br.index, name], error)
    }

    validateRequired("day_type_alias")
    validateRequired("start")
    validateRequired("end")

    const rateValidation = validators.validateCommonNumber({ min: 0 })(br.rate)
    if (rateValidation) set(errors, ["basicRates", br.index, "rate"], rateValidation)
  })

  return errors
}

const validateRequiredEnhancedFields = (enhancedRates: Indexed<ContractModelEnhancedRate>[]) => {
  const errors: FormValidation = { enhancedRates: [] }

  enhancedRates.forEach((er) => {
    const validateRequired = (name: keyof ContractModelEnhancedRate) => {
      const error = validators.required(er[name])
      if (error) set(errors, ["enhancedRates", er.index, name], error)
    }

    validateRequired("day_type_alias")
    validateRequired("standard_rate_guid")
    validateRequired("modifier_type")

    const modifierMin = er.modifier_type === ContractModelEnhancedRateModifierType.ADD ? 0 : 0.001

    const modifierValidation = validators.validateCommonNumber({ min: modifierMin })(er.modifier)
    if (modifierValidation) set(errors, ["enhancedRates", er.index, "modifier"], modifierValidation)
  })

  return errors
}

const validateWeekPeriods = (rates: Indexed<ContractModelRate>[]) => {
  const sortedRates = sortByStart(rates)
  const errors: FormValidation = { basicRates: [] }

  // make sure first starts at 00:00
  if (head(sortedRates)?.start !== "00:00")
    set(errors, ["basicRates", sortedRates[0].index, "start"], "First rate must start at 12:00 AM")

  // make sure last ends at 00:00
  if (last(sortedRates)?.end !== "00:00")
    set(errors, ["basicRates", sortedRates[sortedRates.length - 1].index, "end"], "Last rate must finish at 12:00 AM")

  // find gaps
  sortedRates.forEach((weekRate, index) => {
    const nextRate = sortedRates[index + 1]
    if (!nextRate) return
    if (parseRateTimeToMillis(weekRate.end) < parseRateTimeToMillis(nextRate.start)) {
      set(errors, ["basicRates", weekRate.index, "end"], "Rates can not have a gap")
      set(errors, ["basicRates", nextRate.index, "start"], "Rates can not have a gap")
    }
  })
  return errors
}

const validateWeekendEnhancedPeriods = ({
  weekendEnhancedRates,
  weekdayRates
}: {
  weekendEnhancedRates: Indexed<ContractModelEnhancedRate>[]
  weekdayRates: Indexed<ContractModelRate>[]
}) => {
  const weekdayRateGuids = weekdayRates.map((er) => er.guid)

  const hasAllWeekendRates = weekdayRateGuids.every((wrg) =>
    weekendEnhancedRates.some((er) => er.standard_rate_guid === wrg)
  )

  if (hasAllWeekendRates) return {}
  return {
    [FORM_ERROR]: [
      `When Weekend is enhanced it must have "Weekday" as it's standard rate for every existing Weekday rate`
    ]
  }
}

const validateBasicRatePeriods = (indexedBasicRates: Indexed<ContractModelRate>[]) => {
  const errors: FormValidation = { basicRates: [] }

  const uniqRateDayTypes = uniq(indexedBasicRates.map((p) => p.day_type_alias))

  uniqRateDayTypes.forEach((rt) => {
    const rates = indexedBasicRates.filter((or) => or.day_type_alias === rt)
    const sortedRates = sortByStart(rates)

    sortedRates.forEach((rate, index) => {
      // starts before end
      if (rate.end !== "00:00" && parseRateTimeToMillis(rate.start) > parseRateTimeToMillis(rate.end)) {
        set(errors, ["basicRates", rate.index, "start"], "Rate must start before end")
        set(errors, ["basicRates", rate.index, "end"], "Rate must start before end")
        return
      }

      const nextRate = sortedRates[index + 1]
      if (!nextRate) return

      // overlap because of same start
      if (parseRateTimeToMillis(rate.start) === parseRateTimeToMillis(nextRate.start)) {
        set(errors, ["basicRates", rate.index, "start"], "Rates can not overlap")
        set(errors, ["basicRates", nextRate.index, "start"], "Rates can not overlap")
        return
      }

      // overlap
      if (rate.end === "00:00" || parseRateTimeToMillis(rate.end) > parseRateTimeToMillis(nextRate.start)) {
        set(errors, ["basicRates", rate.index, "end"], "Rates can not overlap")
        set(errors, ["basicRates", nextRate.index, "start"], "Rates can not overlap")
      }
    })
  })

  return errors
}

const validateEnhancedRatesPeriods = (indexedEnhancedRates: Indexed<ContractModelEnhancedRate>[]) => {
  const errors: FormValidation = { enhancedRates: [] }

  indexedEnhancedRates.forEach((er) => {
    if (!er.day_type_alias || !er.standard_rate_guid) {
      return
    }

    const sameRates = indexedEnhancedRates
      .filter((rate) => rate.index !== er.index)
      .filter(
        (rate) =>
          rate.day_type_alias &&
          rate.standard_rate_guid &&
          rate.day_type_alias === er.day_type_alias &&
          rate.standard_rate_guid === er.standard_rate_guid
      )

    if (sameRates.length) {
      set(errors, ["enhancedRates", er.index, "day_type_alias"], "Rates can not have same Day Type and Standard Rate")
      set(errors, ["enhancedRates", er.index, "standard_rate_guid"], " ")

      sameRates.forEach((rate) => {
        set(
          errors,
          ["enhancedRates", rate.index, "day_type_alias"],
          "Rates can not have same Day Type and Standard Rate"
        )
        set(errors, ["enhancedRates", rate.index, "standard_rate_guid"], " ")
      })
    }
  })

  return errors
}

export const validateRates = (values: RateEditorPayload): FormValidation => {
  const errors: FormValidation = { basicRates: [], enhancedRates: [] }

  const indexedBasicRates = addIndexRemoveDeleted(values.basicRates)
  const indexedEnhancedRates = addIndexRemoveDeleted(values.enhancedRates)

  // all standard rates have all required fields
  merge(errors, validateRequiredFields(indexedBasicRates))
  merge(errors, validateRequiredEnhancedFields(indexedEnhancedRates))

  // rates are not overlapping and have valid times
  const otherBasicRateErrors = validateBasicRatePeriods(indexedBasicRates)
  const enhancedRateErrors = validateEnhancedRatesPeriods(indexedEnhancedRates)
  merge(errors, otherBasicRateErrors, enhancedRateErrors)

  // ALL WEEK MUST BE COVERED
  // if has week then it covers entire period
  const weekRates = indexedBasicRates.filter((item) => item.day_type_alias === "WEEK")
  const hasWeek = !!weekRates.length
  if (hasWeek) {
    const weekErrors = validateWeekPeriods(weekRates)
    merge(errors, weekErrors)
  }

  // does not have week, must have w/day and w/end
  if (!hasWeek) {
    const weekdayRates = indexedBasicRates.filter((item) => item.day_type_alias === "W/DAY")
    const weekendRates = indexedBasicRates.filter((item) => item.day_type_alias === "W/END")
    const weekendEnhancedRates = indexedEnhancedRates.filter((item) => item.day_type_alias === "W/END")

    const hasWeekday = !!weekdayRates.length
    const hasWeekend = !!weekendRates.length || !!weekendEnhancedRates.length

    if (!hasWeekday || !hasWeekend) {
      errors[FORM_ERROR] = ['Rates must either contain "Week" or both "Weekday" & "Weekend" type.']
    }

    if (hasWeekday) {
      const weekdayErrors = validateWeekPeriods(weekdayRates)
      merge(errors, weekdayErrors)
    }

    if (hasWeekend) {
      const weekendErrors = weekendRates.length
        ? validateWeekPeriods(weekendRates)
        : validateWeekendEnhancedPeriods({ weekendEnhancedRates, weekdayRates })

      merge(errors, weekendErrors)
    }
  }

  return errors
}
