import { useBranchInfoQuery } from "data/core/branch/queries"

/**
 * useBranchName
 *
 * @returns the current branch name
 */
const useBranchName = () => {
  const { data: { title: branchName = null } = {} } = useBranchInfoQuery()
  return branchName
}

export default useBranchName
