import Grid from "@mui/material/Grid"
import React, { useMemo } from "react"
import Typography from "@mui/material/Typography"
import { optionsToValueTitle } from "lib/schema"
import { getOldestPeriod, getNewestPeriod } from "./dateUtils"
import { DateTimeFormat } from "lib/datetime"
import { DateTime } from "luxon"
import { isNil } from "ramda"
import testingId from "constants/testingId"
import { Button } from "components/Form"
import { FormStage } from "constants/form"
import { useForm, useFormState } from "react-final-form"
import { FrequencyOptions } from "constants/selectOptions"
import { NewCalendarWithPeriodsRequest } from "../../types"

interface OwnProps {
  setFormStage: React.Dispatch<React.SetStateAction<FormStage>>
  isInvoiceModel: boolean
}

const getFormattedDate = (dateToFormat?: DateTime) =>
  !isNil(dateToFormat) && dateToFormat.isValid ? dateToFormat.toFormat(DateTimeFormat.DATE) : ""

const MainCalendarData: React.FC<OwnProps> = ({ setFormStage, isInvoiceModel }) => {
  const form = useForm<NewCalendarWithPeriodsRequest>()
  const { values } = useFormState<NewCalendarWithPeriodsRequest>()

  const frequency = optionsToValueTitle(FrequencyOptions)[values.frequency_type_alias]

  const startDate = useMemo(() => {
    const oldestPeriod = getOldestPeriod(values.periods)
    return oldestPeriod?.start || values.start
  }, [values.start, values.periods])

  const endDate = useMemo(() => {
    const latestPeriod = getNewestPeriod(values.periods)
    return latestPeriod?.end?.plus({ days: 1 }) || values.end
  }, [values.periods, values.end])

  const handleEditClick = () => {
    form.change("isReplace", true)
    form.change("periods", undefined)
    form.change("start", values.end)
    form.change("end", undefined)
    setFormStage(FormStage.CREATE)
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={3}>
          <Typography variant="body2" color="textSecondary">
            Name
          </Typography>
          <Typography variant="h6" data-cy={testingId.contractModel.calendar.detail.headerName}>
            {values.title}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography variant="body2" color="textSecondary">
            {isInvoiceModel ? "Billing" : "Payment"} Frequency
          </Typography>
          <Typography variant="h6" data-cy={testingId.contractModel.calendar.detail.headerBillingPeriod}>
            {frequency}
          </Typography>
        </Grid>
        {!!values.guid && (
          <Grid item md={6} style={{ textAlign: "right" }}>
            <Button type="button" variant="outlined" onClick={handleEditClick} fullWidth={false}>
              Edit Calendar
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={3}>
          <Typography variant="body2" color="textSecondary">
            Starts on
          </Typography>
          <Typography variant="h6" data-cy={testingId.contractModel.calendar.detail.headerStartDate}>
            {getFormattedDate(startDate)}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography variant="body2" color="textSecondary">
            Ends before
          </Typography>
          <Typography variant="h6" data-cy={testingId.contractModel.calendar.detail.headerEndDate}>
            {getFormattedDate(endDate)}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default React.memo(MainCalendarData)
