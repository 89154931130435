export interface WebAppManifest {
  [key: string]: {
    file: string
    css: Array<string>
  }
}

export const ENTRY_POINT = "src/index.tsx"

export const appendScripts = (scriptId: string, host: string, manifest: WebAppManifest, onLoad: () => void): void => {
  if (!manifest[ENTRY_POINT]) {
    throw new Error("main.js not found in manifest")
  }

  const scriptSrc = `${host}/${manifest[ENTRY_POINT].file}`
  console.log("preparing script", scriptSrc)
  const script = Object.assign(document.createElement("script"), {
    id: scriptId,
    crossOrigin: "",
    type: "module",
    src: scriptSrc,
    onload: onLoad
  })

  document.head.appendChild(script)
}

export const appendStyles = (styleId: string, host: string, manifest: WebAppManifest, onLoad: () => void): void => {
  if (!manifest[ENTRY_POINT]) {
    throw new Error("main.js not found in manifest")
  }

  const cssFiles = manifest[ENTRY_POINT].css

  cssFiles.forEach((cssFile, index) => {
    const linkSrc = `${host}/${cssFile}`
    console.log("preparing style", linkSrc)
    const linkCss = Object.assign(document.createElement("link"), {
      rel: "stylesheet",
      type: "text/css",
      crossOrigin: "",
      href: `${host}/${cssFile}`,
      onload: index === cssFiles.length - 1 ? onLoad : null
    })

    linkCss.setAttribute("data-microfrontend-css", styleId)
    document.head.appendChild(linkCss)
  })
}
