import API from "api"
import { UserType } from "models"
import { useMutation } from "react-query"
import { useApiMessage } from "stores"
import { ApiError } from "stores/useUIStore/types"
import { DeleteFileRequest, UploadFileRequest } from "./types"

export const useUploadUserDocumentMutation = (userType: UserType) => {
  const { showErrorMessage } = useApiMessage()

  return useMutation((data: UploadFileRequest) => API.uploadDocument(data, userType), {
    onError: (apiError: ApiError) => {
      showErrorMessage("Could not upload file", { apiError })
    }
  })
}

export const useReplaceUserDocumentMutation = (userType: UserType) => {
  const { showErrorMessage } = useApiMessage()

  return useMutation((data: UploadFileRequest) => API.replaceDocument(data, userType), {
    onError: (apiError: ApiError) => {
      showErrorMessage("Could not replace file", { apiError })
    }
  })
}

export const useDeleteUserDocumentMutation = (userType: UserType) => {
  const { showErrorMessage } = useApiMessage()

  return useMutation((payload: DeleteFileRequest) => API.deleteDocument(payload, userType), {
    onError: (apiError: ApiError) => {
      showErrorMessage("Could not delete file", { apiError })
    }
  })
}
