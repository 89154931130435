import React, { memo, useCallback } from "react"
import { Box } from "@mui/material"
import { StickyBox } from "components"
import { Button } from "components/Form"
import BottomErrorMessage from "components/BottomErrorMessage"
import { RateUpliftFormPage } from "../../types"

interface OwnProps {
  page: RateUpliftFormPage
  setPage: React.Dispatch<React.SetStateAction<RateUpliftFormPage>>
  error?: string
  isSubmitting: boolean
}

export const RateUpliftButtons: React.FC<OwnProps> = memo(({ page, setPage, error, isSubmitting }) => {
  RateUpliftButtons.displayName = "RateUpliftButtons"

  const handlePreviousStepClick = useCallback(() => {
    setPage((p) => p - 1)
  }, [setPage])

  const isModelPage = page === RateUpliftFormPage.PAY_GROUP

  const isSummaryPage = page === RateUpliftFormPage.SUMMARY

  return (
    <StickyBox justifyContent="space-between">
      {!isModelPage && (
        <Button fullWidth={false} flex-shrink={0} onClick={handlePreviousStepClick}>
          Previous step
        </Button>
      )}

      <Box>{error ? <BottomErrorMessage message={error} /> : null}</Box>

      <Button type="submit" fullWidth={false} disabled={!!error || isSubmitting}>
        {isSummaryPage ? "Submit" : "Proceed"}
      </Button>
    </StickyBox>
  )
})
