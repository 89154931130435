import React, { memo, ReactNode, useMemo, useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import { Form } from "react-final-form"
import { EmptyData, ListAlertIcon } from "components"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { Button } from "components/Form"
import { formatDate, DateTimeFormat } from "lib/datetime"
import CreditingModal from "../../components/CreditingModal"
import Auth from "lib/Auth"
import { SelectableIdsDictionary } from "lib/hooks"
import testingId from "constants/testingId"
import { objectToQueryString } from "lib/queryString"
import { ContractModelType } from "constants/modelTypes"
import { green } from "@mui/material/colors"
import { getItemsTableHeaderLabels } from "../helpers/getItemsTableHeaderLabels"
import { useProcessingItemRowClick } from "../handlers/useProcessingItemRowClick"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { isEmpty } from "ramda"
import { InvoiceTransactionItemClickableRow, mapInvoiceTransactionItems } from "../helpers/mapInvoiceTransactionItems"
import { navigateToFinanceAlerts } from "lib/navigate"
import { TaskPreparationCreditMode } from "data/finance/task/types"
import { TransactionItemStatus } from "components/TransactionItemStatus"
import { useSchema } from "data/schema/useSchema"
import { optionsToValueTitle } from "lib/schema"
import { useNavigate } from "react-router-dom"
import { SelectableTableRow } from "components/SelectableTableRow"
import { InvoiceTransaction, InvoiceTransactionItem } from "data/finance/invoiceProcessing/types"

type ItemsTableProps = {
  itemsLength: number
  filteredItems: InvoiceTransactionItem[]
  transaction: InvoiceTransaction
  periodGuid?: string
  onVisitsCredited: Function
  selectableVisitsDictionary: SelectableIdsDictionary
  contractGuid: string
}

export const TransactionDetailItemsTable: React.FC<ItemsTableProps> = memo(
  ({
    itemsLength,
    filteredItems,
    transaction,
    periodGuid,
    onVisitsCredited,
    selectableVisitsDictionary,
    contractGuid
  }) => {
    const navigate = useNavigate()
    const headerLabels = getItemsTableHeaderLabels({ transaction })

    const [modal, setModal] = useState<ReactNode>(null)
    const { toggleId, toggleAll, selectableIds, getSelectedAsStringArr } = selectableVisitsDictionary
    const selectedIds = getSelectedAsStringArr()

    const destroyModal = () => setModal(null)

    const handleRowClick = useProcessingItemRowClick({ filteredItems })

    const alertIconClick = (clickableRow: InvoiceTransactionItemClickableRow) => () => {
      navigateToFinanceAlerts(
        navigate,
        objectToQueryString({
          from: formatDate(clickableRow.visitDate, DateTimeFormat.DATE_SECONDARY),
          to: formatDate(clickableRow.visitDate.plus({ days: 1 }), DateTimeFormat.DATE_SECONDARY),
          user_name: clickableRow.clientName.replaceAll(".", ""),
          tab: "invoice",
          models: contractGuid
        })
      )
    }

    const isInvoicedRow = transaction.status_type_alias === "COMPLETE"
    const canCreditItems = isInvoicedRow && Auth.hasPermission(["Finance.Processing.Credit:Edit"])
    const selectAllForCreditDisabled = isEmpty(selectableIds)

    const { ContractServiceType } = useSchema(["ContractServiceType"])
    const serviceTypesByGuid = optionsToValueTitle(ContractServiceType)

    const data = useMemo(
      () =>
        mapInvoiceTransactionItems({
          items: filteredItems,
          transaction,
          serviceTypesByGuid,
          selectableIds,
          toggleId,
          canCreditItems,
          sx: { color: green[700] }
        }),
      [filteredItems, transaction, serviceTypesByGuid, selectableIds, toggleId, canCreditItems]
    )
    return (
      <RequestLoaderWrapper>
        <Form onSubmit={() => ({})}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {headerLabels.map((label, index) => (
                        <TableCell key={label} sx={!index ? { padding: 0 } : undefined}>
                          {label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isEmpty(data) ? (
                      Object.entries(data).map(([guid, clickableRow], rowIdx) => (
                        <SelectableTableRow
                          key={rowIdx}
                          hover={clickableRow.isClickable}
                          clickable={clickableRow.isClickable}
                          isSelected={getSelectedAsStringArr().includes(guid)}
                          onClick={() => clickableRow.isClickable && handleRowClick({ guid, rowIdx })}
                        >
                          <TransactionItemStatus item={filteredItems[rowIdx]} />
                          {clickableRow.row.map((value, cellIdx) => (
                            <TableCell
                              key={cellIdx}
                              data-cy={testingId.invoiceProcessingTransactionDetail.listItemCell}
                            >
                              {value}
                            </TableCell>
                          ))}
                          {clickableRow.isAlerting ? (
                            <ListAlertIcon clickable={true} callback={alertIconClick(clickableRow)} />
                          ) : null}
                        </SelectableTableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={headerLabels.length}>
                          <EmptyData message="No items found for filtered selection." />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {canCreditItems && (
                <Box p={1} px={0} display="flex" justifyContent="flex-end">
                  <Button
                    fullWidth={false}
                    variant="text"
                    onClick={toggleAll}
                    data-cy={testingId.invoiceProcessingTransactionDetail.toggleAllForCrediting}
                    disabled={selectAllForCreditDisabled}
                  >
                    (Un)Select all for crediting
                  </Button>
                </Box>
              )}
              <Box
                p={2}
                px={0}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={(theme) => ({
                  borderColor: theme.palette.text.primary,
                  borderTop: "1px solid"
                })}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  data-cy={testingId.invoiceProcessingTransactionDetail.listNumOfRecords}
                >
                  Showing {filteredItems.length} out of {itemsLength} records
                </Typography>
                <div>
                  {canCreditItems && (
                    <Button
                      variant="outlined"
                      fullWidth={false}
                      sx={(theme) => ({
                        marginLeft: theme.spacing(2)
                      })}
                      disabled={selectedIds.length === 0}
                      color="primary"
                      onClick={() => {
                        setModal(
                          <CreditingModal
                            guids={selectedIds}
                            onCancel={destroyModal}
                            periodGuid={periodGuid}
                            transactionGuid={transaction.guid}
                            credit_mode={TaskPreparationCreditMode.ITEMS}
                            modelType={ContractModelType.INVOICE}
                            onOk={() => {
                              destroyModal()
                              onVisitsCredited()
                            }}
                          />
                        )
                      }}
                      data-cy={testingId.invoiceProcessingTransactionDetail.creditButton}
                    >
                      Credit selected
                    </Button>
                  )}
                </div>
              </Box>
            </form>
          )}
        </Form>
        {modal !== null && modal}
      </RequestLoaderWrapper>
    )
  }
)

TransactionDetailItemsTable.displayName = "TransactionDetailItemsTable"
