import { ContentContainer, Link, StickyBox, DataLoading } from "components"
import { Button, CancelButton } from "components/Form"
import React, { memo, useMemo, useState } from "react"
import { Form } from "react-final-form"
import { ContractModelTitle, ServiceForm } from "./components"
import { ContractModelPageProps } from "./types"
import { validateServiceWithBands } from "./validators/validateServiceWithBands"
import { DateTime } from "luxon"
import { ContractModelType } from "constants/modelTypes"
import {
  useContractServiceBandListQuery,
  useContractServiceDetailQuery,
  useContractServiceListQuery,
  usePaymentModelListQuery
} from "data/finance/contractModel/queries"
import { isNil, omit } from "ramda"
import { useContractModelServiceCreateMutation } from "data/finance/contractModel/mutations"
import { ContractModelServiceRequestWithBands } from "data/finance/contractModel/types"
import { useContractModelServiceUpdateHandler } from "data/finance/contractModel/handlers/useContractModelServiceUpdateHandler"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { FormStage } from "constants/form"
import arrayMutators from "final-form-arrays"
import { useParams } from "react-router-dom"

interface OwnProps extends ContractModelPageProps {
  isInvoicePaymentService: boolean
}

const Service: React.FC<OwnProps> = ({ contractModel, modelType, isInvoicePaymentService }) => {
  const { serviceId } = useParams()
  const [formStage, setFormStage] = useState<FormStage>(serviceId ? FormStage.EDIT : FormStage.CREATE)
  const [stageOneValues, setStageOneValues] = useState<ContractModelServiceRequestWithBands>(
    {} as ContractModelServiceRequestWithBands
  )

  const { data: bands } = useContractServiceBandListQuery({
    modelType,
    contractGuid: contractModel.guid,
    serviceGuid: serviceId
  })
  const { data: service } = useContractServiceDetailQuery({
    modelType,
    contractGuid: contractModel.guid,
    serviceGuid: serviceId,
    isInvoicePaymentService
  })

  const { data: services } = useContractServiceListQuery({
    modelType,
    contractGuid: contractModel.guid,
    isInvoicePaymentService
  })

  const { data: paymentModels } = usePaymentModelListQuery({
    enabled: isInvoicePaymentService
  })

  const { mutate: createService } = useContractModelServiceCreateMutation({
    modelType,
    isInvoicePaymentService,
    contractModelGuid: contractModel.guid
  })

  const updateService = useContractModelServiceUpdateHandler({
    modelType,
    isInvoicePaymentService,
    contractModelGuid: contractModel.guid
  })

  const onSubmit = (values: ContractModelServiceRequestWithBands) => {
    if (formStage === FormStage.CREATE) {
      setStageOneValues(values)
      setFormStage(FormStage.EDIT)
      return
    }

    const { no_end_date, description, ...rest } = values
    const payload = {
      ...omit(["add_bands", "add_offset", "add_interval"], rest),
      ...(description ? { description } : {}),
      end: no_end_date ? undefined : values.end
    }

    if (serviceId && service) {
      updateService({ payload, serviceGuid: serviceId })
    } else {
      createService({ payload, serviceGuid: serviceId })
    }
  }

  const initialValues = useMemo(
    () =>
      service
        ? {
            guid: service.guid,
            start: service.start,
            end: service.end,
            no_end_date: service && isNil(service.end),
            description: service.description,
            type_guid: service.service_type?.guid,
            payment_contract_guid: service.payment_contract_guid,
            payment_type_alias: service.payment_type_alias,
            bands,
            ...(isInvoicePaymentService ? { mileage_rate: service.mileage_rate, travel_rate: service.travel_rate } : {})
          }
        : {
            ...stageOneValues,
            start: DateTime.local().startOf("day")
          },
    [service, bands, isInvoicePaymentService, stageOneValues]
  )

  if (serviceId && !service) return <DataLoading />

  const isInvoiceService = modelType === ContractModelType.INVOICE && !isInvoicePaymentService

  return (
    <ContentContainer>
      <ContractModelTitle
        modelType={modelType}
        title={
          <>
            <Link to={"../settings"}>{contractModel?.title}</Link> /{" "}
            <Link to={`../${isInvoiceService ? "invoice-services" : "payment-services"}`}>
              {isInvoiceService ? "Invoice" : "Payment"} services
            </Link>{" "}
            / {service ? service.service_type?.title : "Add service"}
          </>
        }
      />
      <RequestLoaderWrapper my={3}>
        <Form
          {...{
            onSubmit,
            initialValues,
            validate: validateServiceWithBands({
              services,
              contractModel,
              formStage,
              paymentModels,
              isInvoicePaymentService
            }),
            mutators: { ...arrayMutators }
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <ServiceForm {...{ bands, isInvoicePaymentService, service, formStage, formRenderProps: props }} />
              {formStage === FormStage.EDIT && (
                <StickyBox>
                  <CancelButton
                    disabled={props.submitting}
                    pristine={props.pristine}
                    navigateUrl={`/finance/contract-models/${
                      modelType === ContractModelType.INVOICE ? "invoice" : "payment"
                    }-models/${contractModel.guid}/${isInvoiceService ? "invoice" : "payment"}-services`}
                  />
                  <Button
                    type="submit"
                    fullWidth={false}
                    disabled={(service && props.pristine) || props.submitting}
                    sx={(theme) => ({
                      margin: theme.spacing(0, 0, 0, 3)
                    })}
                  >
                    Save
                  </Button>
                </StickyBox>
              )}
            </form>
          )}
        </Form>
      </RequestLoaderWrapper>
    </ContentContainer>
  )
}

export default memo(Service)
