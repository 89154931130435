import { AppointmentTypeAlias } from "data/finance/appointment/types"
import { AuthLink, Link } from "components"
import React from "react"
import { Permission } from "constants/permission"

export const appointmentTypeLink = ({
  appointment_type,
  appointment_guid
}: {
  appointment_type: string
  appointment_guid: string
}): React.JSX.Element => {
  // can be VISIT || Visit
  if (appointment_type.toUpperCase() === AppointmentTypeAlias.VISIT) {
    return <Link to={`/visits/detail/${appointment_guid}`}>Visit</Link>
  }
  return (
    <AuthLink to={`/shifts/detail/${appointment_guid}`} permissions={[Permission.SHIFT_READ]}>
      Shift
    </AuthLink>
  )
}
