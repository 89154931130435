import { objectToQueryString, queryStringToObject } from "lib/queryString"
import { Pagination } from "lib/types"
import { deepObjectEqual } from "lib/utils"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const useFilterLocationChange = ({
  filter = {},
  pagination
}: {
  pagination?: Pagination
  filter?: { [key: string]: any }
}): void => {
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    const current = queryStringToObject(location.search)
    const next = { ...filter, ...(pagination || {}) }

    const nextSearch = objectToQueryString(next)
    const nextSearchString = nextSearch ? `?${nextSearch}` : ""

    const currentUrl = `${location.pathname}${location.search}`
    const nextUrl = `${location.pathname}${nextSearchString}`

    if (nextUrl !== currentUrl && !deepObjectEqual(current, next)) {
      navigate(nextUrl, { replace: true })
    }
  }, [location, navigate, filter, pagination])
}
