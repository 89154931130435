import { TableCell, Tooltip } from "@mui/material"
import { Checkbox } from "components/Form"
import React, { memo } from "react"
import { Field } from "react-final-form"
import { ContractModelUpliftContractCheckboxProps } from "../../types"

const CheckboxField: React.FC<ContractModelUpliftContractCheckboxProps> = memo(({ contractModel }) => {
  CheckboxField.displayName = "CheckboxField"

  return (
    <Field<boolean>
      {...{
        type: "checkbox",
        name: `models[${contractModel.guid}].picked`,
        disabled: contractModel.disabled,
        component: Checkbox

        // should be fixed by prop CLICKABLE on ContractModelTable
        // onClick: (e: React.MouseEvent) => {
        //   e.stopPropagation()
        //   // TODO: for disabled onClick does not trigger and click propagates
        //   if (contractModel.disabled) {
        //     e.preventDefault()
        //   }
        // }
      }}
    />
  )
})

export const RateUpliftOneModelCheckbox: React.FC<ContractModelUpliftContractCheckboxProps> = memo(
  ({ contractModel }) => {
    RateUpliftOneModelCheckbox.displayName = "RateUpliftOneModelCheckbox"

    return (
      <TableCell>
        {contractModel.disabled ? (
          <Tooltip title={contractModel.errorMessage || ""}>
            <span>
              <CheckboxField {...{ contractModel }} />
            </span>
          </Tooltip>
        ) : (
          <CheckboxField {...{ contractModel }} />
        )}
      </TableCell>
    )
  }
)
