import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Box, CircularProgress, List, Typography } from "@mui/material"
import { AppContext, Subscription, History } from "pubnub"
import { useQueryClient } from "react-query"
import { CustomChannelMetadata, StandardMessage } from "./types"
import { useGetMessages } from "./queries"
import ChatRoomMessage from "./ChatRoomMessage"
import { useChat } from "./ChatProvider"
import useInViewPort from "./useInViewPort"
import { useChatStore } from "./useChatStore"
import ChatRoomMessagesEmpty from "./ChatRoomMessagesEmpty"
import { getCurrentPubnubTimestamp } from "./utils"
import { ACTION_TYPES, ACTION_VALUES } from "./constants"
import { DateTime } from "luxon"

interface Props {
  currentChannel: AppContext.ChannelMetadataObject<CustomChannelMetadata>
}

const ChatRoomMessagesList = ({ currentChannel }: Props) => {
  const chat = useChat()
  const queryCache = useQueryClient()
  const { data, isFetching, fetchNextPage } = useGetMessages(currentChannel.id)
  const listStartRef = useRef<null | HTMLDivElement>(null)
  const isVisible = useInViewPort(listStartRef)
  const channelNames = useChatStore(({ channelNames }) => channelNames)
  const [newMessages, setNewMessages] = useState<History.FetchedMessageWithActions[]>([])

  useEffect(() => {
    setNewMessages([])
  }, [currentChannel])

  useEffect(() => {
    const listener = {
      message: ({ channel, message, timetoken, publisher }: Subscription.Message) => {
        if (currentChannel?.id === channel) {
          queryCache.invalidateQueries("channels-unread-count")
          queryCache.invalidateQueries(["channels", channelNames.chats])

          let actions = undefined

          if ((message as StandardMessage).sender.isUser) {
            actions = {
              delivered: { read: [{ actionTimetoken: String(getCurrentPubnubTimestamp()), uuid: chat.userId }] }
            }

            chat.addMessageAction({
              channel,
              messageTimetoken: timetoken,
              action: {
                type: ACTION_TYPES.DELIVERED,
                value: ACTION_VALUES.READ
              }
            })
          }

          setNewMessages((messages) => [
            ...messages,
            {
              channel,
              message,
              timetoken,
              uuid: publisher,
              actions
            }
          ])
        }
      },
      messageAction: (action: Subscription.MessageAction) => {
        if (currentChannel?.id === action.channel && action.data.uuid !== chat.userId) {
          queryCache.invalidateQueries("channels-unread-count")
          setNewMessages((messages) =>
            messages.map((message) =>
              message.timetoken === action.data.messageTimetoken
                ? {
                    ...message,
                    actions: {
                      delivered: {
                        ...message.actions?.delivered,
                        [action.data.value]: [{ actionTimetoken: action.data.actionTimetoken, uuid: chat.userId }]
                      }
                    }
                  }
                : message
            )
          )
        }
      }
    }

    chat.addListener(listener)

    return () => {
      chat.removeListener(listener)
    }
  }, [chat, queryCache, currentChannel, channelNames])

  const onScroll = useCallback(() => {
    const [lastPage] = data?.pages.slice(-1) ?? []
    const [lastMessage] = lastPage?.channels[currentChannel.id] ?? []

    if (isVisible && !data?.pageParams.includes(lastMessage?.timetoken)) {
      fetchNextPage({
        pageParam: lastMessage?.timetoken
      })
    }
  }, [currentChannel.id, data?.pageParams, data?.pages, fetchNextPage, isVisible])

  const messages = useMemo(() => {
    const normalizedMessages =
      data?.pages
        .map(({ channels }) => [...(channels[currentChannel.id] ?? [])].reverse())
        .flat()
        .reduce(
          (map, item) => {
            const date = DateTime.fromMillis(Number(item.timetoken) / 10000)
            const day = date.toISODate() ?? ""

            if (!map[day]) {
              map[day] = []
            }

            map[day].unshift(item)
            return map
          },
          {} as { [key: string]: History.FetchedMessageWithActions[] }
        ) ?? {}

    newMessages.forEach((message) => {
      const date = DateTime.fromMillis(Number(message.timetoken) / 10000)
      const day = date.toISODate() ?? ""
      if (!normalizedMessages[day]) {
        normalizedMessages[day] = []
      }
      normalizedMessages[day].push(message)
    })

    return normalizedMessages
  }, [data?.pages, currentChannel, newMessages])

  return (
    <List
      sx={{ flex: 1, overflow: "auto", p: 5, display: "flex", flexDirection: "column-reverse" }}
      onScroll={onScroll}
    >
      {Object.keys(messages).length === 0 && !isFetching && <ChatRoomMessagesEmpty />}
      {Object.keys(messages).map((key) => {
        const date = DateTime.fromISO(key)
        return (
          <Box key={key} component="li">
            <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
              <Typography
                variant="body2"
                sx={{ px: 2, py: 0.5, borderRadius: 1, border: 1, borderColor: "divider", bgcolor: "grey.100" }}
              >
                {date.hasSame(DateTime.now(), "day") ? "Today" : date.toFormat("dd/MM/yyyy")}
              </Typography>
            </Box>
            {messages[key].map(({ timetoken, message, actions }) => (
              <ChatRoomMessage
                key={timetoken}
                message={message as StandardMessage}
                timetoken={timetoken}
                actions={actions}
              />
            ))}
          </Box>
        )
      })}
      {isFetching && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flex: data?.pages ? 0 : 1 }}>
          <CircularProgress />
        </Box>
      )}
      <Box ref={listStartRef} component="li" />
    </List>
  )
}

export default ChatRoomMessagesList
