import { ApiClientContract } from "data/finance/client/types"
import { ClientContractsFilter } from "../ClientContractList"

export const filterClientContracts = (
  items: ApiClientContract[],
  filter?: ClientContractsFilter
): ApiClientContract[] => {
  if (!filter) return items

  const { status } = filter
  let result = items

  if (status?.length) {
    result = result.filter((i) => status.includes(i.status))
  }

  return result
}
