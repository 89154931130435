import { FinanceApi } from "api"
import { enabledByMandatoryUrlParams, setDayBorders } from "data/helpers"
import { removeFromSearchString } from "lib/helpers/removeFromSearchString"
import { useIsInvoiceModelSearch } from "lib/hooks/useIsInvoiceModelSearch"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { Alert } from "./types"
import { useLocation } from "react-router-dom"

export enum QueryKeyParts {
  ALERT = "alert",
  LIST = "list"
}

export const queryKeys = {
  getAlertListKey: ({ search, isInvoice }: { search?: string; isInvoice: boolean }): QueryKey => [
    QueryKeyParts.ALERT,
    QueryKeyParts.LIST,
    { search, isInvoice }
  ]
}

export const useAlertListQuery = (): UseQueryResult<Alert[], Error> => {
  const isInvoice = useIsInvoiceModelSearch()
  const { search } = useLocation()

  const searchString = removeFromSearchString({ search, remove: ["modelType"] })
  const newSearch = setDayBorders({ search: searchString, from: "from", to: "to" })

  return useQuery({
    queryKey: queryKeys.getAlertListKey({ search: newSearch, isInvoice }),
    queryFn: async () => FinanceApi.get(`finance/${isInvoice ? "invoice" : "payment"}-alerts${newSearch}`),
    ...enabledByMandatoryUrlParams([newSearch])
  })
}
