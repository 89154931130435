import { CarerProfileApi, CoreApi } from "api"
import { SelectOption } from "components/Form/Select"
import { enabledById } from "data/helpers"
import { ApiCarerProfileData, ApiUserCarer } from "models"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { CarerListResponse } from "./types"
import { parseCarerExitInterviewPreferences, parseCarerLeavingReasons, parseCarerProfileData } from "./parsers"
import { CarerProfileData, ExitInterviewPreference, LeavingReason } from "data/carer-profile/types"

export enum QueryKeyParts {
  CARER = "carer",
  CARER_PROFILE = "carer-profile",
  LIST = "list",
  LEAVING_REASONS = "leaving-reasons",
  EXIT_INTERVIEW_PREFERENCE = "exit-interview-preference"
}

export const queryKeys = {
  getCarerListKey: (searchString?: string): QueryKey => [QueryKeyParts.CARER, QueryKeyParts.LIST, { searchString }],
  getCarerKey: (id?: string): QueryKey => [QueryKeyParts.CARER, { id }],
  getCarerProfileKey: (guid?: string): QueryKey => [QueryKeyParts.CARER_PROFILE, { guid }],
  getCarerLeavingReasonsKey: (): QueryKey => [QueryKeyParts.CARER, QueryKeyParts.LEAVING_REASONS],
  getCarerExitInterviewPreferenceKey: (): QueryKey => [QueryKeyParts.CARER, QueryKeyParts.EXIT_INTERVIEW_PREFERENCE]
}

export const useCarerLeavingReasonQuery = (): UseQueryResult<SelectOption[]> => {
  return useQuery<LeavingReason[], Error, SelectOption[]>({
    queryKey: queryKeys.getCarerLeavingReasonsKey(),
    queryFn: async () => CarerProfileApi.get("/reference-data/leaving-reasons"),
    select: parseCarerLeavingReasons
  })
}

export const useCarerExitInterviewPreferenceQuery = (): UseQueryResult<SelectOption[]> => {
  return useQuery<ExitInterviewPreference[], Error, SelectOption[]>({
    queryKey: queryKeys.getCarerExitInterviewPreferenceKey(),
    queryFn: async () => CarerProfileApi.get("/carer-profile/reference-data/exit-interview-preferences"),
    select: parseCarerExitInterviewPreferences
  })
}

export const useCarerProfileDataQuery = ({
  guid,
  enabled
}: {
  guid?: string
  enabled: boolean
}): UseQueryResult<ApiCarerProfileData> => {
  return useQuery<CarerProfileData, Error, ApiCarerProfileData>({
    queryKey: queryKeys.getCarerProfileKey(guid),
    queryFn: async () => CarerProfileApi.get(`/carer-profile/carers/${guid}`),
    select: parseCarerProfileData,
    enabled: !!guid && enabled
  })
}

// to get only carers for certain visits, use post carer/list endpoint
export const useCarerListQuery = ({
  searchString = ""
}: {
  searchString?: string
} = {}): UseQueryResult<CarerListResponse, Error> => {
  return useQuery({
    queryKey: queryKeys.getCarerListKey(searchString),
    queryFn: async () => CoreApi.get(`/carer${searchString}`)
  })
}

export const useCarerQuery = ({ id }: { id?: string }): UseQueryResult<ApiUserCarer, Error> => {
  return useQuery({
    queryKey: queryKeys.getCarerKey(id),
    queryFn: async () => CoreApi.get(`/carer/${id}`),
    ...enabledById(id)
  })
}
