import React, { memo, useState } from "react"
import { ApiUserClient } from "models"
import { Box } from "@mui/material"
import { ContentContainer, TabPanel } from "components"
import { ClientHeader } from "./components/ClientHeader"
import { FormStage } from "constants/form"
import { useClientContractQuery } from "data/finance/client/queries"
import { ContractBackButton } from "pages/Finance/components/ContractBackButton"
import ClientContractDetailSettings from "./components/ClientContractDetailSettings"
import { ContractFileList } from "pages/Finance/components/ContractFileList"
import { UserContractTab } from "pages/Finance/components/types"
import { ContractTabBar } from "pages/Finance/components/ContractTabBar"
import { ContractDetailHeader } from "pages/Finance/components/ContractDetailHeader"
import { useUserContractTabState } from "helpers/contractHelpers"
import { useParams } from "react-router-dom"

interface OwnProps {
  client: ApiUserClient
}

export const ClientContractDetail: React.FC<OwnProps> = memo(({ client }) => {
  ClientContractDetail.displayName = "ClientContractDetail"
  const { contractId } = useParams()

  const isEdit = !!contractId
  const [formStage, setFormStage] = useState<FormStage>(isEdit ? FormStage.EDIT : FormStage.CREATE)

  const [tab, setTab] = useUserContractTabState()

  const { data: contract } = useClientContractQuery({ clientGuid: client.guid, contractGuid: contractId })

  return (
    <ContentContainer>
      <ClientHeader client={client} />
      {!isEdit && (
        <>
          <ContractBackButton {...{ isEdit, formStage, setFormStage }} />
          <ClientContractDetailSettings {...{ client, contract, formStage, setFormStage, isEdit }} />
        </>
      )}

      {isEdit && (
        <>
          <ContractDetailHeader {...{ userProfile: client.profile, contract }} />
          <ContractTabBar {...{ tab, setTab }} />
          <Box my={2}>
            <TabPanel value={tab} index={UserContractTab.SETTINGS}>
              <ClientContractDetailSettings {...{ client, contract, formStage, setFormStage, isEdit }} />
            </TabPanel>
            <TabPanel value={tab} index={UserContractTab.FILES}>
              {contract && (
                <ContractFileList {...{ userGuid: client.guid, contractGuid: contract.guid, userType: "Client" }} />
              )}
            </TabPanel>
          </Box>
        </>
      )}
    </ContentContainer>
  )
})
