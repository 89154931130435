import React, { useState } from "react"
import { Cancel, CheckCircle, RemoveRedEye } from "@mui/icons-material"
import { FormGroup, IconButton, TextField, Typography } from "@mui/material"
import { useFormContext } from "react-hook-form"

const rules: { [key: string]: string } = {
  minLength: "At least 8 characters",
  uppercase: "An uppercase character",
  lowercase: "A lowercase character",
  number: "A number",
  specialChar: "A special character (£&%!)"
}

export interface PasswordFields {
  new_password: string
  new_password_confirm: string
}

const PasswordCreateFields = () => {
  const {
    register,
    getValues,
    formState: { errors, isDirty }
  } = useFormContext<PasswordFields>()
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false
  })

  return (
    <>
      <FormGroup sx={{ position: "relative" }}>
        <TextField
          {...register("new_password", {
            required: true,
            minLength: 8,
            validate: {
              uppercase: (value) => /[A-Z]/.test(value),
              lowercase: (value) => /[a-z]/.test(value),
              number: (value) => /\d/.test(value),
              specialChar: (value) => /[&£%!]/.test(value)
            }
          })}
          type={showPassword.password ? "text" : "password"}
          variant="outlined"
          label="New password"
          fullWidth
          error={!!errors.new_password}
        />
        <IconButton
          sx={{
            position: "absolute",
            top: "calc(50% - 20px)",
            right: 4,
            "&:hover": {
              background: "none"
            }
          }}
          onClick={() => setShowPassword((prev) => ({ ...prev, password: !prev.password }))}
        >
          <RemoveRedEye />
        </IconButton>
      </FormGroup>
      <Typography
        mb={1}
        color="textSecondary"
        sx={{ fontWeight: 600, fontSize: 12, lineHeight: 1.66, letterSpacing: 0.4, textAlign: "left", mb: 1, mt: 2 }}
      >
        You password must contain:
      </Typography>
      {Object.keys(rules).map((rule) => {
        const types = errors.new_password?.types ?? {}
        return (
          <Typography
            key={rule}
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: 12,
              letterSpacing: 0.4,
              textAlign: "left",
              mb: 0.5,
              py: 0.125
            }}
          >
            {(types[rule] || types?.required || !isDirty) && <Cancel color="error" sx={{ mr: 1, fontSize: 12 }} />}
            {!types[rule] && !types?.required && isDirty && (
              <CheckCircle color="success" sx={{ mr: 1, fontSize: 12 }} />
            )}
            {rules[rule]}
          </Typography>
        )
      })}
      <FormGroup sx={{ position: "relative", mt: 4 }}>
        <TextField
          {...register("new_password_confirm", {
            required: "The field is required",
            validate: (value) => (getValues("new_password") === value ? true : "Passwords don’t match")
          })}
          helperText={errors.new_password_confirm?.message}
          type={showPassword.confirm ? "text" : "password"}
          variant="outlined"
          label="Confirm new password"
          fullWidth
          error={!!errors.new_password_confirm}
        />
        <IconButton
          sx={{
            position: "absolute",
            top: errors.new_password_confirm ? "calc(50% - 32px)" : "calc(50% - 20px)",
            right: 4,
            "&:hover": {
              background: "none"
            }
          }}
          onClick={() => setShowPassword((prev) => ({ ...prev, confirm: !prev.confirm }))}
        >
          <RemoveRedEye />
        </IconButton>
      </FormGroup>
    </>
  )
}

export default PasswordCreateFields
