import { AppBar, Box, Tab, Tabs } from "@mui/material"
import { ContentContainer, Divider, Header } from "components"
import { queryStringToObject } from "lib/queryString"
import { DateTime } from "luxon"
import React, { memo, useState } from "react"
import { AdditionalSearchFilter } from "./AdditionalSearchFilter"
import { AdditionalSearchFilterValues } from "data/finance/additional/type"
import { ContractModelType } from "constants/modelTypes"
import { AdditionalSearchForm } from "./AdditionalSearchForm"
import { usePagination } from "components/handlers/usePagination"
import { useLocation } from "react-router-dom"

const defaultFilterValues = {
  start: DateTime.local().minus({ weeks: 1 }).startOf("week").toISODate(),
  end: DateTime.local().minus({ weeks: 1 }).endOf("week").toISODate(),
  modelType: ContractModelType.INVOICE
} as AdditionalSearchFilterValues

export const AdditionalSearch: React.FC = memo(() => {
  AdditionalSearch.displayName = "AdditionalSearch"
  const location = useLocation()

  const [filter, setFilter] = useState<AdditionalSearchFilterValues>(() => ({
    ...defaultFilterValues,
    ...(queryStringToObject<AdditionalSearchFilterValues>(location.search) as AdditionalSearchFilterValues)
  }))
  const { pagination, setPagination, resetPagination } = usePagination()

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: ContractModelType) => {
    setFilter((values) => ({
      start: values.start,
      end: values.end,
      modelType: newValue
    }))
    resetPagination()
  }

  return (
    <ContentContainer>
      <Header {...{ title: "Additionals Management" }} />
      <Box my={3}>
        <AppBar {...{ position: "static", color: "transparent" }}>
          <Tabs value={filter.modelType} onChange={handleTabChange}>
            <Tab label="Invoicing" value={ContractModelType.INVOICE} />
            <Tab label="Payments" value={ContractModelType.PAYMENT} />
          </Tabs>
        </AppBar>
      </Box>
      <AdditionalSearchFilter {...{ filter, setFilter, defaultFilterValues, pagination, resetPagination }} />
      <Divider color="divider" />
      <AdditionalSearchForm {...{ filter, setPagination }} />
    </ContentContainer>
  )
})
