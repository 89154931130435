import React, { memo } from "react"
import { ContractModelType } from "constants/modelTypes"
import { usePaymentModelListQuery } from "data/finance/contractModel/queries"
import ContractModelList from "../components/ContractModelList"

const PaymentModelList: React.FC = () => {
  const { data: contracts } = usePaymentModelListQuery()

  return <ContractModelList {...{ contracts, modelType: ContractModelType.PAYMENT }} />
}

export default memo(PaymentModelList)
