import { DateTime } from "luxon"

type VisitReportFieldValue = string | number | DateTime | null

interface VisitReportField {
  value: {
    string_value: string | null
    integer_value: number | null
    double_value: number | null
    date_time_value: DateTime | null
  }
  supporting_text: string | null
}

export interface VisitReportTemplateFieldOption {
  blocks_quick_check_out: boolean
  description: string | null
  narrative_text_value: string | null
  support_text: {
    required: boolean
  }
  title: string
  value: string
}

export interface VisitReportTemplateField {
  contributes_to_narrative: boolean
  default_value: VisitReportFieldValue
  description: string | null
  guid: string
  name: string | null
  quick_check_out: boolean
  title: string
  options: VisitReportTemplateFieldOption[] | null
  validators: Array<{ type: string }> | null
}

export interface VisitReportTemplateSection {
  title: string
  description: string | null
  guid: string
  fields: Array<VisitReportTemplateField>
}

interface VisitReportTemplate {
  guid: string
  sections: Array<VisitReportTemplateSection>
}

export interface ApiVisitReport {
  fields: { [key: string]: VisitReportField } | null
  template: VisitReportTemplate
  guid: string
  reopen_comment: string | null
  risk_score: number | null
  status: number
  updated_at: DateTime
}

export interface VisitReportValues {
  [key: string]: VisitReportFieldValue
}

export interface VisitReportFullValues {
  [key: string]: {
    value: VisitReportFieldValue
    supporting_text: string | null
  }
}

export interface VisitReportTypes extends ApiVisitReport {
  getQuickCheckOutFields: () => Array<VisitReportTemplateField>
  getValues: (withSupportText?: boolean) => VisitReportValues | VisitReportFullValues
  hasValues: () => boolean
}

const VisitReportModel = (visitReport: ApiVisitReport): VisitReportTypes => {
  const values = visitReport.fields !== null ? visitReport.fields : {}

  const getQuickCheckOutFields = () => {
    const fields: Array<VisitReportTemplateField> = []
    visitReport.template.sections.forEach((section: VisitReportTemplateSection) => {
      section.fields.forEach((field: VisitReportTemplateField) => {
        if (field.quick_check_out === true) {
          fields.push(field)
        }
      })
    })
    return fields
  }

  const hasValues = () => visitReport.fields !== null

  const getValues = (withSupportText?: boolean) => {
    withSupportText = withSupportText || false

    const result = Object.keys(values).reduce(
      (valueObj: Record<string, VisitReportFieldValue | Record<string, VisitReportFieldValue>>, key: string) => {
        const field = values[key]
        let value: VisitReportFieldValue = null

        if (field.value.string_value !== null) value = field.value.string_value
        if (field.value.integer_value !== null) value = field.value.integer_value
        if (field.value.double_value !== null) value = field.value.double_value
        if (field.value.date_time_value !== null) value = field.value.date_time_value

        if (withSupportText) {
          valueObj[key] = {
            value,
            supporting_text: field.supporting_text
          }
        } else {
          valueObj[key] = value
        }

        return valueObj
      },
      {}
    )

    if (withSupportText) return result as VisitReportFullValues
    return result as VisitReportValues
  }

  return {
    getQuickCheckOutFields,
    getValues,
    hasValues,
    ...visitReport
  }
}
export default VisitReportModel
