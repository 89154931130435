import { create } from "zustand"
import { GoogleMapsState, IGoogleMapsStore } from "./types"

const INITIAL_STATE: GoogleMapsState = {
  isInitialized: false,
  isLoaded: false
}

const useGoogleMapsStore = create<IGoogleMapsStore>((set) => ({
  ...INITIAL_STATE,
  setLoadGoogleMaps: () => {
    set((state) => ({
      ...state,
      isLoaded: true
    }))
  }
}))

export default useGoogleMapsStore
