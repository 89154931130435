import React, { PropsWithChildren, useState, useMemo, ReactNode, useCallback } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { ApiUserCarer, ApiUserClient } from "models"
import { ContentContainer, VisitAlerts, VisitReport, VisitTasks } from "components"
import {
  CheckInOut,
  VisitDetailCheckInOut,
  VisitDetailCommentForm,
  VisitDetailInfo,
  VisitDetailSignOff,
  CancellationReport
} from "./components"
import { FormSectionHeader } from "components/Form"
import VisitModel from "models/Visit"
import CmMobileBanner from "pages/Visits/components/CmMobileBanner"
import testingId from "constants/testingId"
import { useVisitMobilityAssessmentReportQuery, useVisitQuery } from "data/core/visit/queries"
import { useCarerQuery } from "data/core/carer/queries"
import { useClientDetailQuery } from "data/core/client/queries"
import VisitDetailNfc from "./components/VisitDetailNfc/"
import { Grid } from "@mui/material"
import { VisitDetailVariation } from "./components/VisitDetailVariation"
import { WithPermission } from "components/Auth"
import { ClientHeader } from "../Clients/components/ClientHeader"
import { FallRiskScore } from "./components/FallRiskScore"
import VisitReportEmpty from "./components/VisitReportEmpty"
import { useParams } from "react-router-dom"
import useBranchSettings from "lib/hooks/branchInfo/useBranchSettings"

interface OwnProps {
  user?: ApiUserCarer | ApiUserClient
  isCarer?: boolean
}

const VisitDetail: React.FC<OwnProps> = ({ user, isCarer }) => {
  const { id = "" } = useParams()
  const { settings: branchSettings } = useBranchSettings()

  const { data: apiVisit, refetch } = useVisitQuery({ id })
  const visit = useMemo(() => {
    if (apiVisit) {
      return VisitModel(apiVisit)
    }
    return null
  }, [apiVisit])

  const { data: client } = useClientDetailQuery({
    id: visit?.patient_guid
  })
  const { data: carer } = useCarerQuery({ id: visit?.carer_guid })
  const { data: mobilityAssessment } = useVisitMobilityAssessmentReportQuery({ visitId: apiVisit?.guid ?? "" })

  const [modal, setModal] = useState<ReactNode>()

  const hasCheckInCheckOutVisitAuditFlag = branchSettings["FeatureCheckInCheckOutVisitAudit"]?.toLowerCase() === "true"
  const hasRiskScoreWidgetFlag = branchSettings["FeatureMobileTeamClientFallRiskScore"]?.toLowerCase() === "true"

  const destroyModal = useCallback(() => {
    setModal(undefined)
  }, [])

  const isNfcOrGeoLocationCheckInOut =
    client && (client.has_nfc || visit?.isGeoLocationCheckIn() || visit?.isGeoLocationCheckOut())

  const shouldShowReports = visit?.visitReport.hasValues() || !!mobilityAssessment

  if (!visit) {
    return null
  }

  return (
    <Wrapper {...{ user, isCarer }}>
      <Box m={3} mx={0} data-cy={testingId.visit.detail.screen}>
        <VisitDetailInfo {...{ visit, carer, client }} />
        <Grid container spacing={3}>
          {carer && (
            <VisitDetailCheckInOut visit={visit} setModal={setModal} destroyModal={destroyModal} apiVisit={apiVisit} />
          )}
          {isNfcOrGeoLocationCheckInOut && <VisitDetailNfc hasClientNfc={client?.has_nfc} {...{ visit }} />}
        </Grid>
        {hasRiskScoreWidgetFlag && client && <FallRiskScore client={client} />}
        {hasCheckInCheckOutVisitAuditFlag && (
          <Box m={5} mx={0}>
            <FormSectionHeader title="Check-in / Check-out" />
            <CheckInOut visit={visit} />
          </Box>
        )}
        {apiVisit?.is_cm2000 && <CmMobileBanner />}
        {visit.alerts !== null && <VisitAlerts alerts={visit.alerts} display="full" />}
        <Box m={5} mx={0}>
          <FormSectionHeader title="Visit report" />
          {shouldShowReports ? (
            <VisitReport report={visit.visitReport} mobilityAssessment={mobilityAssessment} />
          ) : (
            <VisitReportEmpty isCancelled={!!visit.door_step_refusal} />
          )}
        </Box>
        {visit.door_step_refusal && (
          <CancellationReport
            userID={visit.guid}
            isRequired={visit.door_step_refusal.confirmation_is_required}
            confirmedAt={visit.door_step_refusal.confirmed_at}
            confirmedBy={visit.door_step_refusal.confirmed_by}
            type={visit.door_step_refusal.type}
            reason={visit.door_step_refusal.reason}
            refetch={refetch}
          />
        )}
        <Box m={5} mx={0}>
          <FormSectionHeader title="Tasks" />
          <VisitTasks tasks={visit.tasks} />
        </Box>
        <WithPermission permissions={["Finance.Appointment:Read"]}>
          <VisitDetailVariation {...{ appointmentGuid: visit.visit_guid, userAppointmentGuid: visit.carer_guid }} />
        </WithPermission>
        <VisitDetailSignOff setModal={setModal} visit={visit} destroyModal={destroyModal} />
        <Box m={5} mx={0}>
          <FormSectionHeader title="Operator comments" data-cy={testingId.visit.detail.operator.title} />
          <VisitDetailCommentForm visit={visit} />
        </Box>
      </Box>
      {modal !== undefined && modal}
    </Wrapper>
  )
}
export default VisitDetail

const Wrapper: React.FC<PropsWithChildren<{ user?: ApiUserCarer | ApiUserClient; isCarer?: boolean }>> = ({
  children,
  isCarer,
  user
}) => {
  // carer visit detail (carer has ContentContainer and own version of "ClientHeader" in CarerDetail component)
  if (isCarer) {
    return (
      <>
        <Box m={3} mx={0}>
          <Typography variant="h5">Visit detail</Typography>
        </Box>
        {children}
      </>
    )
  }

  // client visit detail
  if (user) {
    return (
      <ContentContainer>
        <ClientHeader client={user} />
        <Box m={3} mx={0}>
          <Typography variant="h5">Visit detail</Typography>
        </Box>
        {children}
      </ContentContainer>
    )
  }

  // visit detail
  return (
    <ContentContainer>
      <Typography variant="h4">Visit detail</Typography>
      {children}
    </ContentContainer>
  )
}
