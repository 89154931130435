import React from "react"
import { Header, ContentContainer } from "components"
import ClientProfile from "./components/ClientProfile"
import { Navigate, Route } from "react-router-dom"
import useBranchSettings from "lib/hooks/branchInfo/useBranchSettings"
import SentryRoutes from "components/Routes"

const CarerCreate: React.FC = () => {
  const { settings: branchSettings } = useBranchSettings()
  const isClientPrefv2Enabled = branchSettings["FeatureCCHTeamClientPrefv2"]?.toLowerCase() === "true"

  return (
    <>
      <ContentContainer>
        <Header title={"Create a client"} />
        <SentryRoutes>
          <Route path="/" element={<Navigate replace to="/clients/new/personal-details" />} />
          <Route
            path=":section"
            element={<ClientProfile mode="create" isClientPrefv2Enabled={isClientPrefv2Enabled} />}
          />
        </SentryRoutes>
      </ContentContainer>
    </>
  )
}

export default CarerCreate
