import React, { memo } from "react"
import { Typography } from "@mui/material"
import type { BranchesListResponse } from "data/core/operator/queries"
import { MainNavBranchSelect } from "./MainNavBranchSelect"
import { getBranchTitle } from "./helpers/getBranchTitle"
import useBranchData from "lib/hooks/branchInfo/useBranchData"

interface Props {
  branches?: BranchesListResponse[]
}

export const MainNavBranch = memo(({ branches }: Props) => {
  MainNavBranch.displayName = "MainNavBranch"

  const { data: currentSelectedBranch } = useBranchData()

  if (branches && branches.length > 1) {
    return <MainNavBranchSelect branches={branches} />
  }

  return (
    <Typography
      sx={(theme) => ({
        display: "inline",
        color: theme.palette.primary.contrastText,
        marginLeft: theme.spacing(4),
        fontSize: "85%",
        width: "150px",
        wordWrap: "break-word"
      })}
    >
      {getBranchTitle(currentSelectedBranch)}
    </Typography>
  )
})
