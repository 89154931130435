import { Box } from "@mui/material"
import { Button } from "components/Form"
import { AppointmentSearchFilterValues } from "data/finance/invoiceSearch/types"
import { isEmpty, omit } from "ramda"
import React, { Dispatch, memo, ReactNode, SetStateAction, useCallback, useState } from "react"
import { AppointmentSearchScheduledEditModal } from "./AppointmentSearchScheduledEditModal"
import { Appointment } from "data/finance/appointment/types"
import { RefetchQuery } from "data/types"

interface OwnProps {
  hasAppointmentEditPermission: boolean
  setFilter: Dispatch<SetStateAction<AppointmentSearchFilterValues>>
  resetAll: () => void
  selectedIds: string[]
  refetch: RefetchQuery<Appointment[]>
}

export const AppointmentSearchScheduledBulkEditButton: React.FC<OwnProps> = memo(
  ({ hasAppointmentEditPermission, setFilter, resetAll, selectedIds, refetch }) => {
    AppointmentSearchScheduledBulkEditButton.displayName = "AppointmentSearchScheduledBulkEditButton"

    const [modal, setModal] = useState<ReactNode>(null)

    const handleBulkEdit = useCallback(() => {
      const destroyModal = () => setModal(null)
      setModal(
        <AppointmentSearchScheduledEditModal
          {...{
            destroyModal,
            selectedIds,
            onOk: () => {
              refetch()
              destroyModal()
              resetAll()
              setFilter((prev) => omit(["errors"], prev) as AppointmentSearchFilterValues)
            },
            onError: (errors: string[]) => {
              refetch()
              destroyModal()
              resetAll()
              setFilter((prev) => ({ ...prev, errors }))
            }
          }}
        />
      )
    }, [selectedIds, refetch, resetAll, setFilter])

    if (!hasAppointmentEditPermission) {
      return null
    }

    return (
      <Box m={1}>
        <Button fullWidth={false} disabled={isEmpty(selectedIds)} onClick={handleBulkEdit}>
          Bulk Edit
        </Button>
        {modal}
      </Box>
    )
  }
)
