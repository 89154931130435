import { ApiVisitReport } from "./VisitReport"
import { DateTime } from "luxon"
import { StatusEnum } from "data/commonTypes"
import { ApiClientContract } from "data/finance/client/types"

export enum UserType {
  Relative = 1,
  Carer = 2,
  Operator = 3,
  Patient = 4
}

export enum VisitStatusCoalesced {
  VisitPlanned,
  VisitUpcoming,
  VisitCheckedIn,
  VisitCheckedOut,
  ReportCheckedOutPartially,
  ReportCheckedOut,
  ReportReopened,
  ReportSignedOff,
  ReportSignedOffConcerned,
  Cancelled,
  Deleted,
  VisitPaper,
  DoorstepRefusal
}

interface ApiUserProfile {
  address: string
  address_details: string
  address_post_code: string
  date_of_birth: DateTime
  ethnicity: number
  first_name: string
  gender: string
  languages: number[]
  last_name: string
  marital_status: number
  middle_name?: string
  name: string
  phone_landline: string | null
  phone_mobile: string | null
  phone_cera: string | null
  profile_picture_url: string | null
  religion: number
  salutation: string
  sexual_orientation: number
  short_name: string | null
  email: string
}

interface ApiUserResourceGroupPermission {
  guid: string
  name: string
  scope: number
  is_wildcard: boolean
}

export interface ApiUserResourceGroup {
  guid: string
  title: string
  permissions: ApiUserResourceGroupPermission[]
}

export interface ApiUserResource {
  resource_guid: string
  scope: "Edit" | "Read" | ""
  is_wildcard: boolean
}

export interface ApiUserHr {
  leaving_date?: DateTime
  leaving_location_after: string | null
  leaving_notes: string | null
  leaving_reason: string | null
  start_date: DateTime
  exit_interview_preference_title?: string
  resignation_date?: DateTime
}

interface BaseApiUser {
  status: number
  type: UserType
  regions?: number[]
  guid: string
  hr: ApiUserHr
  branch?: ApiUserBranch
  has_login: boolean
  reference: string
  login?: ApiCarerLogin
  invitation?: ApiUserInvitation
  has_nfc?: boolean
}

interface ApiUserBranch {
  guid: string
  title: string
  abbr: string
  phone: string
  care_planner_url: string
}

interface ApiUserPreferences {
  cat_friendly: number
  dog_friendly: number
  smoking: number
  a_car_owner: number
}

export interface ApiUserPreferencesCarer extends ApiUserPreferences {
  able_to_drive: number
  happy_to_service_female_clients: number
  happy_to_service_male_clients: number
  happy_to_service_couples: number
  alzheimers: number
  copd: number
  other_mental_health: number
  palliative_care: number
  peg_feeding: number
  stroke: number
  dementia: number
  multiple_sclerosis: number
  oxygen_machine: number
  parkinsons_disease: number
  spinal_injury: number
  permanent_live_in_package: number
  domicilliary_package: number
  short_term_live_in_package: number
  drug_and_alcohol_package: number
  mental_health_package: number
  learning_disability_package: number
  nurse_led_package: number
}

export interface ApiUserFinanceCarer {
  payroll_reference: string
  roster_by_email: boolean
  roster_by_mail: boolean
  is_agency?: boolean
  ni_number?: string
  is_subcontractor?: boolean
  is_bank?: boolean
  is_sponsorship?: boolean
}

interface ApiUserPreferencesClient extends ApiUserPreferences {
  a_driver: number
  happy_with_female_carers: number
  happy_with_male_carers: number
  caucasian_british: number
  caucasian_other: number
  asian_indian_pakistan_bangladesh: number
  asian_other: number
  african: number
  black_caribbean: number
  black_african: number
  black_other: number
  arab: number
  mixed: number
  other: number
}

interface ApiUserFinanceClient {
  invoice_group_guid: string
  social_services_number: string
  visit_charge_amount: string
  invoices_by_email: boolean
  invoices_by_mail: boolean
  nhs_number: number
  ni_number?: string
  agent_number: number
}

export interface ApiUserCarePackagesClient {
  domiciliary?: boolean
  short_term_live_in?: boolean
  permanent_live_in?: boolean
  drug_and_alcohol?: boolean
  learning_disability?: boolean
  mental_health?: boolean
  nurse_led?: boolean
}

interface ApiUserProfileClient extends ApiUserProfile {
  emergency_rating?: number
  address_latitude: number
  address_longitude: number
}

interface ApiUserProfileCarer extends ApiUserProfile {
  bio: string | null
  address_latitude: number
  address_longitude: number
}

export interface ApiCarerLogin {
  last_login_at?: DateTime
  password_updated_at?: DateTime
  invitation_accepted_at?: DateTime
  invitation_created_at?: DateTime
}

export interface ApiUserInvitation {
  label: string
  label_date_time?: DateTime
}

export enum MaleFemalePreferenceEnum {
  MALE_OR_FEMALE = "MALE_OR_FEMALE",
  PREFER_FEMALE = "PREFER_FEMALE",
  PREFER_MALE = "PREFER_MALE",
  ONLY_FEMALE = "ONLY_FEMALE",
  ONLY_MALE = "ONLY_MALE"
}

export interface ApiUserCarerWorkPreferences {
  has_asthma_or_breathing_difficulties: boolean | null
  is_allergic_to_cats: boolean | null
  is_allergic_to_dogs: boolean | null
  is_comfortable_with_pets: boolean | null
  preference_to_male_and_female_clients: null | MaleFemalePreferenceEnum
  preference_to_male_and_female_extra_info: string | null
}

export interface ApiUserCarer extends BaseApiUser {
  profile: ApiUserProfileCarer
  preferences: ApiUserPreferencesCarer
  finance: ApiUserFinanceCarer
  care_planner_internal_id: number
  cm2000: ApiUserCarerCm2000
  onboarding_status: number
  reset_onboarding_status: boolean
  has_profile_image?: boolean
  carerProfileData?: ApiCarerProfileData
}

export interface ApiCarerProfileData {
  id?: string
  sourcing_type?: string
  contact_about_future_roles?: boolean
  exit_interview_preference_id?: number
  resignation_date?: DateTime
  leaving_date?: DateTime
  status?: ApiCarerProfileStatus
}

export interface ApiCarerProfileStatus {
  id?: number
  title?: string
  type?: string
  external_id?: number
  alias?: string
}

export interface ApiUserClient extends BaseApiUser {
  profile: ApiUserProfileClient
  preferences: ApiUserPreferencesClient
  finance: ApiUserFinanceClient
  care_packages: ApiUserCarePackagesClient
  care_plan_file_guid: string
  care_planner_internal_id: number
  contracts: ApiClientContract[]
  clientContractFilter?: StatusEnum[]
  contract?: ApiClientContract
  cm2000: ApiUserClientCm2000
  has_profile_image?: boolean
}

export interface ApiUserRelative extends BaseApiUser {
  profile: ApiUserProfile
  has_profile_image?: boolean
}

interface BaseApiUserOperator extends BaseApiUser {
  profile: ApiUserProfile
  has_profile_image?: boolean
}

export interface ApiUserOperator extends BaseApiUserOperator {
  resource_group?: ApiUserResourceGroup[]
  resources: ApiUserResource[]
}

export interface ApiUserOperatorForm extends BaseApiUserOperator {
  resource_group?: string[]
  resources: { [key: string]: ApiUserResource }
}

export interface ApiUserOperatorFormRequest extends BaseApiUserOperator {
  resource_group?: string[]
  resources: ApiUserResource[]
}

export interface ApiAssignedOperator {
  guid: string
  first_name: string
  last_name: string
  email: string
  status: number
  invitation: ApiUserInvitation
}

export type ApiUnassignedOperator = ApiAssignedOperator

export type ApiUser = ApiUserCarer | ApiUserClient | ApiUserRelative | ApiUserOperator

export interface ApiUserClientFilter {
  search?: string
  preferences?: ApiUserPreferencesClient
  care_packages?: ApiUserCarePackagesClient
  region?: number[]
  contract_model?: string[]
  funder?: string[]
  status?: number
  reference?: string
}

export interface ApiUserFile {
  category_guid: string
  file_mime_type: string
  file_name: string
  file_guid: string
  title: string
  uploaded_at: string
  uploaded_by_guid: string
  uploaded_by_name: string
  expires_at?: DateTime
  warnings: string[]
}

export interface CheckInOutLocation {
  accuracy: number
  is_using_geolocation: boolean
  is_within_range: null | boolean
  latitude: number
  longitude: number
  outside_range_reason: null | string
}

interface CheckInOut {
  location: CheckInOutLocation
  time: DateTime
  nfc_time?: DateTime
}

export type RefusalType = "CancelledOnArrivalByServiceUser" | "Other" | "EnteredPropertyServiceUserNotAtHome"

export type RefusalOperator = {
  guid: string
  first_name: string
  last_name: string
  full_name: string
}

interface VisitDoorStepRefusal {
  confirmation_is_required: boolean
  confirmed_at: DateTime | null
  confirmed_by: RefusalOperator | null
  created_at: DateTime
  reason: string
  type: RefusalType
}

export interface ApiVisit {
  guid: string
  start: DateTime
  end: DateTime
  carer_guid: string
  patient_guid: string
  status: number
  missed: boolean
  check_in?: CheckInOut
  check_out?: CheckInOut
  status_coalesced: VisitStatusCoalesced
  risk_score: number
  visit_report: ApiVisitReport
  visit_guid: string
  alerts: Array<VisitAlert> | null
  tasks: Array<VisitTask> | null
  comment: string | null
  is_cm2000: boolean
  door_step_refusal: VisitDoorStepRefusal | null
}

export interface VisitAlert {
  id: number
  name: string
}

export interface VisitTask {
  guid: string
  description: string
  status: number
  comment: string
  category: string
}

export enum SourcingType {
  AGENCY = "agency",
  NONE = ""
}

export interface ApiUserCarerCm2000 {
  enabled: boolean
  pin?: string
}

export interface ApiUserClientCm2000 {
  enabled: boolean
  pin?: string
}

export interface ApiAddressSearchDataContract {
  id: string
  suggestion: string
  is_group: boolean
}

export interface ApiAddressSearchContract {
  address_line_1: string
  address_line_2?: string
  city: string
  country: string
  county: string
  postcode: string
  organisation: string
  latitude: number
  longitude: number
}
