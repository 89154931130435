import React, { memo, useMemo } from "react"
import { Field } from "react-final-form"
import { Select } from "components/Form"
import {
  INVOICE_CONTRACT_PRIVATE_ALIAS_OPTIONS,
  INVOICE_CONTRACT_PUBLIC_ALIASES,
  INVOICE_CONTRACT_PUBLIC_ALIAS_OPTIONS,
  INVOICE_CONTRACT_ALIAS_OPTIONS,
  PAYMENT_CONTRACT_ALIAS_OPTIONS
} from "constants/modelTypes"
import validators from "lib/validators"
import { InvoiceModel, PaymentModel } from "data/finance/contractModel/types"

interface OwnProps {
  contractModel?: InvoiceModel | PaymentModel
  isInvoiceModelType: boolean
}

export const ContractModelFormTypeField = memo(({ isInvoiceModelType, contractModel }: OwnProps) => {
  const options = useMemo(() => {
    // invoice model edit
    if (isInvoiceModelType && contractModel) {
      return INVOICE_CONTRACT_PUBLIC_ALIASES.includes(contractModel.type_alias)
        ? INVOICE_CONTRACT_PUBLIC_ALIAS_OPTIONS
        : INVOICE_CONTRACT_PRIVATE_ALIAS_OPTIONS
    }

    // invoice model new
    if (isInvoiceModelType) {
      return INVOICE_CONTRACT_ALIAS_OPTIONS
    }

    // payment model edit & new
    return PAYMENT_CONTRACT_ALIAS_OPTIONS
  }, [isInvoiceModelType, contractModel])

  return (
    <Field
      name="type_alias"
      label={isInvoiceModelType ? "Contract Type *" : "Pay Type *"}
      component={Select}
      options={options}
      validate={validators.required}
    />
  )
})

ContractModelFormTypeField.displayName = "ContractModelFormTypeField"
