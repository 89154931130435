import React, { Dispatch, memo, SetStateAction, useMemo, useState } from "react"
import { Field, Form } from "react-final-form"
import { Link } from "components"
import {
  AutocompleteMui,
  Button,
  FormSpyCustom,
  PreferenceMultiselect,
  TextField,
  Select,
  Preference,
  Checkbox
} from "components/Form"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { ApiUserClientFilter } from "models"
import { getOptionsFromSchema } from "lib/schema"
import { useDebouncedCallback } from "use-debounce"
import { countFilters, getDisplayStyle, deepObjectEqual } from "lib/utils"
import testingId from "constants/testingId"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { mapSummariesIntoModelOptions } from "lib/helpers/mapSummariesIntoModelOptions"
import { mapSummariesIntoFunderOptions } from "lib/helpers/mapSummariesIntoFunderOptions"
import { get } from "lodash"
import { ClearFilterButton } from "components/ClearFilterButton"
import CompanyInfo, { CompanyBranchFeatures } from "lib/CompanyInfo"
import { useAuthStore, useCoreSchemasStore } from "stores"
import useBranchSettings from "lib/hooks/branchInfo/useBranchSettings"
import { ContractModelSummaryRequest } from "data/finance/contractModel/types"

const carePreferences = [
  { name: "preferences.a_driver", label: "A driver" },
  { name: "preferences.a_car_owner", label: "A car owner" },
  { name: "preferences.cat_friendly", label: "Cat friendly" },
  { name: "preferences.dog_friendly", label: "Dog friendly" },
  { name: "preferences.smoking", label: "Smoking" },
  { name: "preferences.happy_with_male_carers", label: "Happy with male carers" },
  { name: "preferences.happy_with_female_carers", label: "Happy with female carers" }
]

const carePackages = [
  { name: "care_packages.domiciliary", label: "Domiciliary" },
  { name: "care_packages.short_term_live_in", label: "Short-term live-in" },
  { name: "care_packages.permanent_live_in", label: "Permanent live-in" }
]

const carerCultureLeft = [
  { name: "preferences.caucasian_british", label: "Caucasian - British" },
  { name: "preferences.asian_indian_pakistan_bangladesh", label: "Asian - Indian, Pakistan, Bangladesh" },
  { name: "preferences.african", label: "African" },
  { name: "preferences.black_african", label: "Black African" },
  { name: "preferences.arab", label: "Arab" },
  { name: "preferences.other", label: "other" }
]

const carerCultureRight = [
  { name: "preferences.caucasian_other", label: "Caucasian - Other" },
  { name: "preferences.asian_other", label: "Asian - Other" },
  { name: "preferences.black_caribbean", label: "Black Caribbean" },
  { name: "preferences.black_other", label: "Black - Other" },
  { name: "preferences.mixed", label: "Mixed" }
]

const additionalFilterValues = [
  { name: "gender" },
  ...carePreferences,
  ...carePackages,
  ...carerCultureLeft,
  ...carerCultureRight
]

export const CLIENT_STATUS_ACTIVE = 19

interface OwnProps {
  filter: ApiUserClientFilter
  setFilter: Dispatch<SetStateAction<ApiUserClientFilter>>
  invoiceSummaries?: ContractModelSummaryRequest[]
}

export const ClientListFilter: React.FC<OwnProps> = memo(({ filter, setFilter, invoiceSummaries }) => {
  // open other filters if they are active
  const [showMoreFilters, setShowMoreFilters] = useState((): boolean => {
    return !!additionalFilterValues.find(({ name }) => get(filter, name))
  })

  const user = useAuthStore((state) => state.user)
  const { data: isClientProfileV2Enabled } = CompanyInfo.useBranchFeatures(
    user?.branch_guid,
    CompanyBranchFeatures.CLIENT_PROFILE_V2
  )

  /**
   * isClientProfileV2Enabled is used for switching the client profile endpoints.
   * For a small amount of time, we will have the ability to enable both
   * endpoints as well as both UIs.
   * The isCreateClientProfileUIV2Enabled is a temporary flag to enable
   * the new client creation UI which will be replacing the old.
   * This allows us to test the existing UI against the new endpoint whilst
   * the new UI is being developed.
   */

  const { settings: branchSettings } = useBranchSettings()
  const isCreateClientProfileUIV2Enabled =
    branchSettings["FeatureCCHTeamCreateClientProfileUIV2"]?.toLowerCase() === "true"

  const isClientProfileV2 = isCreateClientProfileUIV2Enabled && isClientProfileV2Enabled

  const { ApiUserPatientFilter } = useCoreSchemasStore((state) => state.schema.models)

  const genderOptions = getOptionsFromSchema(ApiUserPatientFilter, "gender")
  const regionOptions = getOptionsFromSchema(ApiUserPatientFilter, "region")
  const statusOptions = getOptionsFromSchema(ApiUserPatientFilter, "status")

  // summaries options
  const contractModelOptions = useMemo(() => mapSummariesIntoModelOptions(invoiceSummaries), [invoiceSummaries])

  const funderOptions = useMemo(() => mapSummariesIntoFunderOptions(invoiceSummaries), [invoiceSummaries])

  const debouncedOnSubmit = useDebouncedCallback((values: ApiUserClientFilter) => {
    setFilter(values)
  }, 500)

  useFilterLocationChange({ filter })

  return (
    <Box m={3} mx={0}>
      <Form {...{ onSubmit: debouncedOnSubmit, initialValues: filter, initialValuesEqual: deepObjectEqual }}>
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={9}>
                <Field name="search" label="Search" component={TextField} />
              </Grid>
              <Grid item md={1} />
              <Grid item md={2}>
                <Link to={isClientProfileV2 ? "/clients/create" : "/clients/new"}>
                  <Button id="clients-create-client-btn">Create a client</Button>
                </Link>
              </Grid>
              <Grid item md={3}>
                <Field
                  {...{
                    name: "contract_model",
                    label: "Active invoice models",
                    component: AutocompleteMui,
                    options: contractModelOptions,
                    multiselect: true
                  }}
                />
              </Grid>
              <Grid item md={3}>
                <Field
                  {...{
                    name: "funder",
                    label: "Funder",
                    component: AutocompleteMui,
                    options: funderOptions,
                    multiselect: true
                  }}
                />
              </Grid>
              <Grid item md={2}>
                <Field name="status" label="Status" component={Select} options={statusOptions} />
              </Grid>
              <Grid item md={2}>
                <Field name="reference" label="Reference" component={TextField} />
              </Grid>
              <Grid item md={2}>
                <Field name="region" label="Region" component={Select} options={regionOptions} />
              </Grid>

              <Grid item md={3}>
                <Box sx={getDisplayStyle(showMoreFilters)}>
                  <PreferenceMultiselect
                    title="Care preferences"
                    data-cy={testingId.client.list.carePreferences}
                    filteredItems={countFilters<ApiUserClientFilter>(carePreferences, values)}
                  >
                    {carePreferences.map(({ name, label }) => (
                      <Field key={name} name={name} label={label} component={Preference} />
                    ))}
                  </PreferenceMultiselect>
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box sx={getDisplayStyle(showMoreFilters)}>
                  <PreferenceMultiselect
                    title="Care packages"
                    data-cy={testingId.client.list.carePackages}
                    filteredItems={countFilters<ApiUserClientFilter>(carePackages, values)}
                  >
                    {carePackages.map(({ name, label }) => (
                      <div key={name}>
                        <Field name={name} label={label} component={Checkbox} type="checkbox" />
                      </div>
                    ))}
                  </PreferenceMultiselect>
                </Box>
              </Grid>

              <Grid item md={3}>
                <Box sx={getDisplayStyle(showMoreFilters)}>
                  <PreferenceMultiselect
                    title="Carer culture"
                    data-cy={testingId.client.list.carerCulture}
                    filteredItems={countFilters<ApiUserClientFilter>(
                      [...carerCultureLeft, ...carerCultureRight],
                      values
                    )}
                  >
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        {carerCultureLeft.map(({ name, label }) => (
                          <Field key={name} name={name} label={label} component={Preference} />
                        ))}
                      </Grid>
                      <Grid item md={6}>
                        {carerCultureRight.map(({ name, label }) => (
                          <Field key={name} name={name} label={label} component={Preference} />
                        ))}
                      </Grid>
                    </Grid>
                  </PreferenceMultiselect>
                </Box>
              </Grid>

              <Grid item md={3}>
                <Box sx={getDisplayStyle(showMoreFilters)}>
                  <Field name="gender" label="Gender" component={Select} options={genderOptions} />
                </Box>
              </Grid>

              <Grid item md={3}></Grid>
              <Grid item md={9}>
                <Box justifyContent="flex-end" display="flex" alignItems="center" color="grey.600">
                  <Button
                    variant="text"
                    fullWidth={false}
                    data-cy={testingId.client.list.showFilters}
                    onClick={() => {
                      setShowMoreFilters(!showMoreFilters)
                    }}
                  >
                    Show {showMoreFilters ? "less filters" : "more filters"}
                  </Button>
                  <ClearFilterButton
                    onClick={() => {
                      setFilter({})
                    }}
                    disabled={!Object.keys(filter).length}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </Box>
  )
})

ClientListFilter.displayName = "ClientListFilter"
