import { FormApi } from "final-form"
import { useEffect, useMemo } from "react"
import { useFormState } from "react-final-form"
import { RateUpliftForm } from "../types"

interface OwnProps {
  contractGuid: string
  form: FormApi<RateUpliftForm>
}

export const useRateUpliftModelTypeUpdateHandler = ({ contractGuid, form }: OwnProps): void => {
  const { values } = useFormState<RateUpliftForm>()

  const { upliftValue, upliftType } = useMemo(() => {
    return {
      upliftValue: values?.uplift_value,
      upliftType: values?.uplift_type
    }
  }, [values])

  const formModelUpliftTypePath = `models[${contractGuid}].uplift_type`
  const formModelUpliftValuePath = `models[${contractGuid}].uplift_value`

  // update rate if service uplift value or type changes
  useEffect(() => {
    if (upliftType && upliftValue) {
      // @ts-ignore
      form.change(formModelUpliftTypePath, upliftType)
      // @ts-ignore
      form.change(formModelUpliftValuePath, upliftValue)
    }
    // we do not want form as a dependency
  }, [upliftValue, upliftType, formModelUpliftTypePath, formModelUpliftValuePath]) // eslint-disable-line
}
