import { useMutation } from "react-query"
import API from "api"
import { useApiMessage } from "stores"
import { ApiError } from "stores/useUIStore/types"

export const useInviteUserMutation = () => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation({
    mutationFn: (userGuid: string) => API.inviteUser(userGuid),
    onSuccess: () => {
      showSuccessMessage("User was successfully invited")
    },
    onError: (apiError: ApiError) => {
      showErrorMessage("Failed to invite user", { apiError })
    }
  })
}
