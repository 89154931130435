import Grid from "@mui/material/Grid"
import { AutocompleteMui, FormSpyCustom } from "components/Form"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { StatusSelectField } from "components/Form/StatusSelectField"
import { useSchema } from "data/schema/useSchema"
import { StateSchema } from "data/schema/types"
import { ClearFilterButton } from "components/ClearFilterButton"
import { StatusEnum } from "data/commonTypes"

export interface ServiceFilter {
  service_type?: string[]
  status?: StatusEnum[]
  payment_contract_guid?: string[]
}

interface OwnProps {
  filter: ServiceFilter
  setFilter: Dispatch<SetStateAction<ServiceFilter>>
  isInvoicePaymentService?: boolean
  paymentModelOptions?: StateSchema
}

export const ServiceListFilter: React.FC<OwnProps> = memo(
  ({ isInvoicePaymentService, filter, setFilter, paymentModelOptions }) => {
    const { ContractServiceType } = useSchema(["ContractServiceType"])

    const onSubmit = (values: ServiceFilter) => {
      setFilter(values)
    }

    useFilterLocationChange({ filter })

    const onClearClick = () => {
      setFilter({})
    }

    return (
      <Form onSubmit={onSubmit} initialValues={filter}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={3}>
                <StatusSelectField multiselect />
              </Grid>
              <Grid item md={3}>
                <Field
                  name="service_type"
                  label="Care Type"
                  component={AutocompleteMui}
                  options={ContractServiceType}
                  multiselect
                />
              </Grid>
              {isInvoicePaymentService && (
                <Grid item md={3}>
                  <Field
                    name="payment_contract_guid"
                    label="Pay Group"
                    component={AutocompleteMui}
                    options={paymentModelOptions}
                    multiselect
                  />
                </Grid>
              )}
              <Grid item md={3}>
                <ClearFilterButton onClick={onClearClick} disabled={Object.keys(filter).length === 0} />
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    )
  }
)

ServiceListFilter.displayName = "ServiceListFilter"
