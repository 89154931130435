import { Grid } from "@mui/material"
import { ClearFilterButton } from "components/ClearFilterButton"
import { AutocompleteMui, FormSectionHeader, FormSpyCustom } from "components/Form"
import { DateField } from "components/Form/DateField"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import testingId from "constants/testingId"
import { removeUnassigned } from "data/schema/helpers"
import { useSchema } from "data/schema/useSchema"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"
import { FilterValues, formatFilterValues } from "lib/helpers/formatFilterValues"
import { queryStringToObject } from "lib/queryString"
import { Pagination } from "lib/types"
import { DateTime } from "luxon"
import React, { memo, useState } from "react"
import { Field, Form } from "react-final-form"
import { useLocation } from "react-router-dom"
import { useDebouncedCallback } from "use-debounce"

const defaultFilterValues = {
  period_end_from: DateTime.local().minus({ week: 1 }).startOf("week").toISODate() as string,
  period_end_to: DateTime.local().minus({ week: 1 }).endOf("week").toISODate() as string
}

interface OwnProps {
  pagination: Pagination
  resetPagination: () => void
}
interface PayslipFilterValues {
  period_end_from: string
  period_end_to: string
  model?: string
}

export const CarerPayslipListFilter: React.FC<OwnProps> = memo(({ pagination, resetPagination }) => {
  const location = useLocation()

  const { PaymentContract } = useSchema(["PaymentContract"])
  const paymentModelOptions = removeUnassigned(PaymentContract)

  const [filter, setFilter] = useState<PayslipFilterValues>({
    ...defaultFilterValues,
    ...queryStringToObject(location?.search)
  })

  useFilterLocationChange({ filter, pagination })

  const debouncedOnSubmit = useDebouncedCallback((values: FilterValues<PayslipFilterValues>) => {
    const formattedValues = formatFilterValues<PayslipFilterValues>({
      values,
      startName: "period_end_from",
      endName: "period_end_to"
    })

    setFilter(formattedValues)
    resetPagination()
  }, 500)

  const clearFilter = () => {
    setFilter(defaultFilterValues)
    resetPagination()
  }

  return (
    <Form
      {...{
        onSubmit: debouncedOnSubmit,
        initialValues: filter,
        validate: validateFilterPeriodDates({ start: "period_end_from", end: "period_end_to", allowSameDay: false })
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpyCustom handleSubmit={handleSubmit} />
          <FormSectionHeader data-cy={testingId.carer.payAdvice.payAdviceFormTitle} title="Care Pay Breakdown" />
          <Grid container spacing={2} alignItems="center">
            <Grid item md={3}>
              <DateField name="period_end_from" label="Starts after" required />
            </Grid>
            <Grid item md={3}>
              <DateField name="period_end_to" label="Ends before" required />
            </Grid>
            <Grid item md={3}>
              {paymentModelOptions ? (
                <Field name="model" label="Pay Model" component={AutocompleteMui} options={paymentModelOptions} />
              ) : null}
            </Grid>
            <Grid item md={3}>
              <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  )
})

CarerPayslipListFilter.displayName = "CarerPayslipListFilter"
