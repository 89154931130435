import { Box, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { ContractModelType } from "constants/modelTypes"
import { useContractServiceBandWithRatesListQuery } from "data/finance/contractModel/queries"
import { ContractModelServiceBandWithRates } from "data/finance/contractModel/types"
import { FormApi } from "final-form"
import { deepObjectEqual } from "lib/utils"
import React, { memo, useEffect, useMemo } from "react"
import { useFormState } from "react-final-form"
import { useRateUpliftServiceTypeUpdateHandler } from "../../handlers/useRateUpliftServiceTypeUpdateHandler"
import { getServiceFromUplift, isServicesWithRates } from "../../helpers/upliftFormGetters"
import { ContractModelUplift, RateUpliftForm, ContractModelServiceUplift } from "../../types"
import { RateUpliftSelect } from "./RateUpliftSelect"
import { RateUpliftThreeBand } from "./RateUpliftThreeBand"

interface OwnProps {
  contract: ContractModelUplift
  service: ContractModelServiceUplift
  form: FormApi<RateUpliftForm>
  open: boolean
  modelType: ContractModelType
}

interface OwnBandsProps {
  contract: ContractModelUplift
  service: ContractModelServiceUplift
  bands: ContractModelServiceBandWithRates[]
  form: FormApi<RateUpliftForm>
  open: boolean
}

const RateUpliftThreeServiceBands: React.FC<OwnBandsProps> = memo(({ service, contract, bands, form, open }) => {
  RateUpliftThreeServiceBands.displayName = "RateUpliftThreeServiceBands"

  useRateUpliftServiceTypeUpdateHandler({ form, contractGuid: contract.guid, serviceGuid: service.guid })

  const { values } = useFormState<RateUpliftForm>()

  const formService = useMemo(
    () => getServiceFromUplift({ values, contractGuid: contract.guid, serviceGuid: service.guid }),
    [contract.guid, service.guid, values]
  )

  const serviceHasRate = isServicesWithRates(formService)

  return (
    <Box
      sx={{
        display: open ? "block" : "none",
        marginTop: "20px"
      }}
    >
      <Box
        sx={{
          padding: "15px 10px",
          backgroundColor: grey[200],
          borderTop: `1px solid ${grey[400]}`,
          borderBottom: `1px solid ${grey[400]}`
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold"
          }}
        >
          {service.isPayment ? "Payment Service" : "Service"}: {service.service_type.title}
        </Typography>
        {!!service.description && (
          <Typography
            sx={{
              fontSize: "0.8rem"
            }}
          >
            Description: {service.description}
          </Typography>
        )}
      </Box>
      {serviceHasRate ? (
        <>
          <RateUpliftSelect
            {...{
              isServiceLevel: true,
              upliftType: formService?.uplift_type,
              upliftFieldsOwnerName: `models[${contract.guid}].services[${service.guid}]`,
              formService
            }}
          />
          <Box>
            {bands.map((band) => {
              return <RateUpliftThreeBand {...{ band, contract, serviceGuid: service.guid, form }} key={band.guid} />
            })}
          </Box>
        </>
      ) : (
        <Typography
          sx={{
            margin: "10px 0"
          }}
        >
          This service does not have any rates.
        </Typography>
      )}
    </Box>
  )
})

export const RateUpliftThreeService: React.FC<OwnProps> = memo(({ contract, service, form, open, modelType }) => {
  RateUpliftThreeService.displayName = "RateUpliftThreeService"

  const { values } = useFormState<RateUpliftForm>()

  const { data: bands } = useContractServiceBandWithRatesListQuery({
    contractGuid: contract.guid,
    serviceGuid: service.guid,
    modelType
  })

  useEffect(() => {
    if (bands && !deepObjectEqual(bands, (values.models[contract.guid].services || {})[service.guid]?.bandItems)) {
      // @ts-ignore
      form.change(`models[${contract.guid}].services[${service.guid}].bandItems`, bands)
    }
  }, [form, bands, contract, service.guid, values.models])

  if (!service || !bands) return null

  return <RateUpliftThreeServiceBands {...{ contract, service, bands, form, open }} />
})
