import React from "react"
import { useConfigurationsQuery } from "data/config/queries"
import AppLoading from "./AppLoading"
import Authenticated from "./Authenticated"

const AuthenticatedWrapper = () => {
  const { isLoading } = useConfigurationsQuery()

  if (isLoading) {
    return <AppLoading />
  }

  return <Authenticated />
}

export default AuthenticatedWrapper
