import Grid from "@mui/material/Grid"
import { Button, CancelButton } from "components/Form"
import React, { memo } from "react"
import { FormSpy } from "react-final-form"
import { StickyBox } from "components"
import { FORM_ERROR } from "final-form"
import BottomErrorMessage from "components/BottomErrorMessage"

export const SalaryBandFormButtons: React.FC = memo(() => {
  SalaryBandFormButtons.displayName = "SalaryBandFormButtons"

  return (
    <FormSpy subscription={{ errors: true, submitting: true, pristine: true, invalid: true }}>
      {({ errors, submitting, pristine, invalid }) => {
        const formError = errors ? errors[FORM_ERROR] : null
        return (
          <StickyBox>
            <>
              {!!formError && (
                <Grid container>
                  <Grid item md={8}>
                    <BottomErrorMessage message={formError} />
                  </Grid>
                </Grid>
              )}
              <CancelButton disabled={submitting} pristine={pristine} />
              <Button
                type="submit"
                fullWidth={false}
                disabled={pristine || submitting || invalid}
                sx={(theme) => ({
                  margin: theme.spacing(0, 0, 0, 3)
                })}
              >
                Save
              </Button>
            </>
          </StickyBox>
        )
      }}
    </FormSpy>
  )
})
