import React, { memo } from "react"
import PaymentModelList from "./PaymentModelList"
import ContractModelDetail from "../ContractModelDetail"
import PaymentModelDetail from "./PaymentModelDetail"
import { ContractModelType } from "constants/modelTypes"
import { RateUplift } from "../RateUplift"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

const PaymentModels: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/" element={<PaymentModelList />} />
      <Route path="/new" element={<ContractModelDetail modelType={ContractModelType.PAYMENT} />} />
      <Route path="/:id/*" element={<PaymentModelDetail />} />
      <Route path="/rate-uplift" element={<RateUplift modelType={ContractModelType.PAYMENT} />} />
    </SentryRoutes>
  )
}

export default memo(PaymentModels)
