import React, { useState, useEffect } from "react"
import { Grid, Box, Typography } from "@mui/material"
import { Form, Field } from "react-final-form"
import { ApiUserClient } from "models"
import { UploadInput, FormSectionHeader, Button } from "components/Form"
import { downloadFile } from "lib/utils"
import { useClientDownloadCarePlanQuery } from "data/core/client/queries"
import { useClientUploadCarePlanMutation } from "data/core/client/mutations"
import { GUID_ZERO } from "constants/guid"
import { ContentContainer } from "components"
import { ClientHeader } from "./components/ClientHeader"
import { useUIStore } from "stores"

type Props = {
  client: ApiUserClient
}

const ClientCareplan = ({ client }: Props) => {
  const showErrorMessage = useUIStore((state) => state.showErrorMessage)
  const [file, setFile] = useState(null)
  const [isFileDownload, setIsFileDownload] = useState<boolean>(false)

  const { data: downloadCareplan } = useClientDownloadCarePlanQuery({
    enabled: client.care_plan_file_guid !== GUID_ZERO,
    guid: client.guid
  })
  const { mutateAsync: uploadCareplan } = useClientUploadCarePlanMutation({ guid: client.guid })

  const onSubmit = async () => {
    const payload = Object.assign({}, { File: file, user: client.guid })

    await uploadCareplan(payload)
    setFile(null)
  }

  useEffect(() => {
    if (isFileDownload && downloadCareplan) {
      try {
        const response: any = downloadCareplan

        const { size, type } = response.data
        if (size && type) {
          downloadFile(`Care Plan - ${client.profile.name}.pdf`, "application/pdf", response.data, "save")
        } else {
          throw new Error()
        }
      } catch (apiError: any) {
        showErrorMessage(
          "Something went wrong while downloading the file. Please try again later or contact user support.",
          { apiError }
        )
      }
    }

    setIsFileDownload(false)
    // we do not want client as a dependency
  }, [isFileDownload, downloadCareplan]) // eslint-disable-line

  return (
    <ContentContainer>
      <ClientHeader client={client} />
      <Box m={3} mx={0}>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, submitting, form }) => (
            <form
              onSubmit={(event: any) => {
                const result = handleSubmit(event) as any
                if (result && result.then)
                  result.then(() => {
                    form.reset()
                  })
              }}
              encType="multipart/form-data"
            >
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <Grid container spacing={3}>
                    <Grid item md={12}>
                      <FormSectionHeader title="Download client's careplan"></FormSectionHeader>
                    </Grid>
                    <Grid item md={12}>
                      {client.care_plan_file_guid === GUID_ZERO ? (
                        <Typography variant={"h6"}>
                          There is no careplan currently associated with this client.
                        </Typography>
                      ) : (
                        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" p={5}>
                          <Button fullWidth={false} onClick={() => setIsFileDownload(true)}>
                            Download Careplan PDF
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Grid container spacing={3}>
                    <Grid item md={12}>
                      <FormSectionHeader title="Upload a careplan PDF"></FormSectionHeader>
                    </Grid>
                    <Grid item md={12}>
                      <Field
                        name="File"
                        render={(props) => (
                          <UploadInput {...props} accept=".pdf,application/pdf,application/x-pdf" setFile={setFile} />
                        )}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Button
                        type="submit"
                        fullWidth={false}
                        sx={(theme) => ({
                          margin: theme.spacing(0, 2, 0, 0)
                        })}
                        disabled={!file || submitting}
                      >
                        Upload
                      </Button>
                      <Button
                        variant="text"
                        fullWidth={false}
                        onClick={() => {
                          form.reset()
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </Box>
    </ContentContainer>
  )
}
export default ClientCareplan
