import { SortableTableHeader } from "components/SortableTableHeader"
import React, { Dispatch, memo, SetStateAction, useMemo } from "react"
import { InvoiceTransactionGroupFilter } from "../types"

interface OwnProps {
  setFilter: Dispatch<SetStateAction<InvoiceTransactionGroupFilter>>
  hasCompletedTransactionGroups: boolean
  hasAlertingTransactionGroup: boolean
  hasRewindPermission: boolean
}

export const TransactionGroupTableHeader: React.FC<OwnProps> = memo(
  ({ setFilter, hasCompletedTransactionGroups, hasAlertingTransactionGroup, hasRewindPermission }) => {
    TransactionGroupTableHeader.displayName = "TransactionGroupTableHeader"

    const items = useMemo(
      () => [
        { label: "Model name", resource: "calendar.contract.title" },
        { label: "Model type", resource: "calendar.contract.type.title" },
        { label: "Account", resource: "calendar.contract.finance.finance_id" },
        { label: "Cycle", resource: "calendar.frequency_type.title" },
        { label: "Start date", resource: "start" },
        { label: "End date", resource: "end" },
        { label: "Deadline", resource: "invoice_deadline" },
        { label: "Completed at", resource: "completed_at" },
        { label: "% Conf" },
        { label: "% Inv" },
        { label: "Total" },
        { label: "Select" },
        ...(hasRewindPermission && hasCompletedTransactionGroups ? [{ label: "", key: "rewind" }] : []),
        ...(hasAlertingTransactionGroup ? [{ label: "", key: "alert" }] : [])
      ],
      [hasRewindPermission, hasCompletedTransactionGroups, hasAlertingTransactionGroup]
    )

    return <SortableTableHeader {...{ setFilter, items }} />
  }
)
