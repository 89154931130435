import { CarerProfileApi, CoreApi } from "api"
import { useMutation, useQueryClient } from "react-query"
import { queryKeys as carerQueryKeys } from "data/core/carer/queries"
import { ApiError } from "stores/useUIStore/types"
import { ApiUserCarer } from "models"
import { MutationCallbacks } from "data/types"
import { useApiMessage } from "stores"
import { CarerProfileData } from "data/carer-profile/types"

export const useCarerPayrollPutMutation = ({ carerGuid }: { carerGuid: string }) => {
  const queryCache = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation({
    mutationFn: async (payload: { payroll_reference?: string }) => {
      return CoreApi.put(`/carer/${carerGuid}/payroll-reference`, payload)
    },
    onSuccess: () => {
      queryCache.invalidateQueries(carerQueryKeys.getCarerKey(carerGuid))
      showSuccessMessage("Carer Payroll reference successfully updated")
    },
    onError: (apiError: ApiError) => {
      showErrorMessage("Failed to update Payroll ID", { apiError })
    }
  })
}

interface CarerRequestData {
  corePayload: ApiUserCarer
  carerProfilePayload?: CarerProfileData
}

export const useCarerPostMutation = ({
  onError,
  onSuccess
}: MutationCallbacks<ApiUserCarer, CarerRequestData> = {}) => {
  return useMutation<ApiUserCarer, ApiError, CarerRequestData>({
    mutationFn: ({ corePayload }) => {
      return CoreApi.post("/carer", corePayload)
    },
    onSuccess,
    onError
  })
}

export const useCarerPutMutation = ({ onError, onSuccess }: MutationCallbacks<ApiUserCarer, CarerRequestData> = {}) => {
  return useMutation<ApiUserCarer, ApiError, CarerRequestData>({
    mutationFn: ({ corePayload }) => {
      return CoreApi.put(`/carer/${corePayload.guid}`, corePayload)
    },
    onSuccess,
    onError
  })
}

export const useCarerProfilePutMutation = ({
  onSuccess,
  onError
}: MutationCallbacks<CarerProfileData, { carerGuid: string; payload: CarerProfileData }> = {}) => {
  return useMutation<CarerProfileData, ApiError, { carerGuid: string; payload: CarerProfileData }>({
    mutationFn: async ({ carerGuid, payload }) => CarerProfileApi.put(`/carer-profile/carers/${carerGuid}`, payload),
    onSuccess,
    onError
  })
}
