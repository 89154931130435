import { useMutation } from "react-query"
import type { AuthenticationRequest } from "api/core"
import coreApi from "api"

const useMutationLogin = () => {
  return useMutation({
    mutationKey: ["login"],
    mutationFn: (data: AuthenticationRequest) => coreApi.login(data)
  })
}

export default useMutationLogin
