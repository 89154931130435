import React, { Dispatch, FormEvent, SetStateAction, useState } from "react"
import { Box, Grid, Stack, Tooltip } from "@mui/material"
import { Form, Field } from "react-final-form"
import { Button, FormSectionHeader, Select, TextField, UploadInput } from "./Form"
import { ApiUserFile } from "../models"
import { FormApi } from "final-form"
import validators from "lib/validators"
import { SchemaFieldOption } from "lib/types"
import { useUIStore } from "stores"
import { UploadFileRequest } from "data/user-documents/types"
import { Info } from "@mui/icons-material"

const ACCEPTED_DOC_TYPES =
  "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/rtf,text/html,image/jpeg,image/tiff,image/png,text/plain"

interface OwnProps {
  onSubmit: (values: UploadFileRequest) => Promise<void>
  formValues: ApiUserFile
  setFormValues: Dispatch<SetStateAction<ApiUserFile>>
  categoryOptions: SchemaFieldOption[]
}

interface ResetFormProps {
  form: FormApi<any, Partial<any>>
}

interface FileType {
  lastModified: number
  lastModifiedDate: number
  name: string
  size: number
  type: string
  webkitRelativePath: string
}

// work around bug in .reset() when it does not reset field state after submit
const resetFormState = ({ form }: ResetFormProps) => {
  form.reset()
  form.resetFieldState("file_guid")
  form.resetFieldState("title")
  form.resetFieldState("category_guid")
  form.resetFieldState("File")
}

const FileUploadForm: React.FC<OwnProps> = ({ onSubmit, categoryOptions, formValues, setFormValues }) => {
  const showErrorMessage = useUIStore((state) => state.showErrorMessage)
  const [file, setFile] = useState<FileType | null>(null)

  return (
    <Form
      onSubmit={(values) => onSubmit(Object.assign({}, values, { File: file }))}
      validate={(values: any) => (values.File ? undefined : { File: "Required" })}
      initialValues={formValues}
      render={({ handleSubmit, form, values, invalid, submitting }) => (
        <form
          onSubmit={async (event: FormEvent) => {
            try {
              await handleSubmit(event)
              setFile(null)
              resetFormState({ form })
            } catch (apiError: any) {
              showErrorMessage(
                "Something went wrong while uploading the file. Please try again later or contact user support.",
                { apiError }
              )
            }
          }}
          encType="multipart/form-data"
        >
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item md={12}>
              <FormSectionHeader
                title={
                  values.file_guid !== undefined ? `Replacing document ${values.file_name}` : "Upload a new document"
                }
              />
            </Grid>
            <Grid item md={4}>
              <Field name="file_guid" type="hidden" component="input" />
              <Field name="title" label="Document name" component={TextField} disabled={!!values.file_guid} />
            </Grid>
            <Grid item md={4}>
              <Field
                name="category_guid"
                label="Document category *"
                component={Select}
                options={categoryOptions}
                validate={validators.required}
              />
            </Grid>
            <Grid item md={4}>
              <Stack direction="row" sx={{ alignItems: "center" }}>
                <Field
                  name="File"
                  render={(props) => <UploadInput {...props} setFile={setFile} accept={ACCEPTED_DOC_TYPES} />}
                />
                <Tooltip
                  title={
                    <span>
                      Allowed types: <br /> .pdf .doc .docx .xlsx .rtf .html .txt .jpg .tiff .png
                    </span>
                  }
                  arrow
                >
                  <Info sx={{ ml: 1, mt: 2 }} />
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item md={8} />
            <Grid item md={4}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  sx={(theme) => ({ margin: theme.spacing(0, 2, 0, 0) })}
                  type="submit"
                  fullWidth={false}
                  disabled={invalid || submitting}
                >
                  Upload
                </Button>
                <Button
                  variant="text"
                  fullWidth={false}
                  onClick={() => {
                    setFormValues({} as ApiUserFile)
                    setFile(null)
                    resetFormState({ form })
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}
export default FileUploadForm
