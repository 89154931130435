import {
  ContractModelSalary,
  ContractModelSalaryRequestWithBands,
  ContractModel
} from "data/finance/contractModel/types"
import { FORM_ERROR } from "final-form"
import { validatePeriodDates, validatePeriodInParent, validatePeriodWithSiblings } from "lib/dateTimeValidators"
import { ValidationErrorsDefined } from "lib/types"
import { assocPath, mergeDeepLeft } from "ramda"

const hasValidTimes = ({ start, end }: { start?: string; end?: string }) => {
  if (!start || !end) {
    return true
  }

  const [startHour, startMinute] = start.split(":").map((i) => Number(i))
  const [endHour, endMinute] = end.split(":").map((i) => Number(i))

  if (startHour < endHour || (startHour === endHour && startMinute < endMinute)) {
    return true
  }
  return false
}

export const validateSalaryWithBands =
  ({
    salaries,
    contractModel,
    isCreate
  }: {
    salaries?: ContractModelSalary[]
    contractModel: ContractModel
    isCreate: boolean
  }) =>
  (values: ContractModelSalaryRequestWithBands): ValidationErrorsDefined => {
    let errors: ValidationErrorsDefined = {}

    // start is before end
    errors = mergeDeepLeft(validatePeriodDates(values), errors)

    // compare to parent contract model
    errors = mergeDeepLeft(
      errors,
      validatePeriodInParent({
        values,
        parent: { start: contractModel.start, end: contractModel.end },
        itemName: "Salary",
        parentName: "Contract model"
      })
    )

    // compare to other salary bands
    errors = mergeDeepLeft(errors, validatePeriodWithSiblings({ values, items: salaries, label: "Salaries" }))

    if (isCreate) return errors

    const hasBands = values.bands?.filter((item) => !item.delete).length > 0
    if (!hasBands) {
      errors[FORM_ERROR] = "Salary band must have at least one banding"
    }

    values.bands?.forEach((item, key) => {
      if (item.delete) return

      if (!item.start) {
        errors = assocPath(["bands", key, "start"], "This field is required", errors)
      }
      if (!item.end) {
        errors = assocPath(["bands", key, "end"], "This field is required", errors)
      }
      if (!hasValidTimes({ start: item.start, end: item.end })) {
        errors = assocPath(["bands", key, "end"], "End must be after Start", errors)
      }
      if (!item.day_type_alias) {
        errors = assocPath(["bands", key, "day_type_alias"], "This field is required", errors)
      }
      if (item.day_type_alias === "HOL" && !item.holiday_tier_guid) {
        errors = assocPath(["bands", key, "holiday_tier_guid"], "Holiday tier must be selected", errors)
      }
    })

    return errors
  }
