import React, { Dispatch, memo, SetStateAction, useEffect, useState } from "react"
import { ClientContractDetailStepOne } from "./ClientContractDetailStepOne"
import { ClientContractDetailStepTwo } from "./ClientContractDetailStepTwo"
import { Form, FormRenderProps } from "react-final-form"
import { ApiUserClient } from "models"
import arrayMutators from "final-form-arrays"
import testingId from "constants/testingId"
import { Box } from "@mui/material"
import { validateClientContract } from "lib/helpers/validateClientContract"
import { FormStage } from "constants/form"
import { useClientContractListQuery } from "data/finance/client/queries"
import { useInvoiceModelQuery } from "data/finance/contractModel/queries"
import { useClientContractSubmit } from "../handlers/useClientContractSubmit"
import { buildUserContractInitialValues } from "helpers/contractHelpers"
import { ContainerSteps } from "components/Steps"
import { ContractDetailHeader } from "pages/Finance/components/ContractDetailHeader"
import { ContractDetailFooter } from "pages/Finance/components/ContractDetailFooter"
import { ClientPurchaseOrderModal } from "./ClientPurchaseOrderForm"
import { ApiClientContract, ApiClientContractPayload } from "data/finance/client/types"

interface OwnProps {
  client: ApiUserClient
  contract?: ApiClientContract
  formStage: FormStage
  setFormStage: Dispatch<SetStateAction<FormStage>>
  isEdit: boolean
}

const ClientContractDetailSettings: React.FC<OwnProps> = ({ client, contract, formStage, setFormStage, isEdit }) => {
  const [selectedContractModelGuid, setSelectedContractModelGuid] = useState<string | undefined>(
    contract?.contract_guid
  )
  useEffect(() => {
    setSelectedContractModelGuid(contract?.contract_guid)
  }, [contract])

  const { data: selectedContractModel } = useInvoiceModelQuery(selectedContractModelGuid)
  const { data: contracts } = useClientContractListQuery(client.guid)

  const onSubmit = useClientContractSubmit({
    formStage,
    setFormStage,
    client,
    setSelectedContractModelGuid
  })

  const isFirstContract = !contracts?.length

  const initialValues = buildUserContractInitialValues({ contract, isFirstContract })

  const [showModal, setShowModal] = useState(false)

  return (
    <Box data-cy={testingId.client.contract.detail.screen}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        validate={validateClientContract({
          clientContracts: contracts,
          invoiceModel: selectedContractModel,
          formStage
        })}
      >
        {(formProps: FormRenderProps<ApiClientContractPayload>) => (
          <form onSubmit={formProps.handleSubmit}>
            <ContainerSteps>
              {!isEdit && (
                <ContractDetailHeader
                  {...{ userProfile: client.profile, contract, selectedContractModel, formStage }}
                />
              )}
              {formStage === FormStage.CREATE && <ClientContractDetailStepOne />}
              {formStage === FormStage.EDIT && (
                <ClientContractDetailStepTwo
                  {...{
                    contractModel: selectedContractModel,
                    contractReference: contract?.reference,
                    contract,
                    isFirstContract,
                    setShowModal
                  }}
                />
              )}
              <ContractDetailFooter {...{ formStage, formProps }} />
            </ContainerSteps>
          </form>
        )}
      </Form>
      {showModal && !!contract && <ClientPurchaseOrderModal {...{ setShowModal, contract }} />}
    </Box>
  )
}

export default memo(ClientContractDetailSettings)
