import { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

/**
 * useMicroFrontend
 *
 * Custom hook to manage the states for MicroFrontend component
 */
const useMicroFrontend = () => {
  const [isLoadingScript, setIsLoadingScript] = useState(false)
  const [errorLoadManifest, setErrorLoadManifest] = useState(false)
  const [errorRenderMfe, setErrorRenderMfe] = useState(false)
  const location = useLocation()

  const resetState = useCallback(() => {
    setErrorRenderMfe(false)
    setErrorLoadManifest(false)
    setIsLoadingScript(false)
  }, [])

  useEffect(() => {
    resetState()
  }, [location.pathname, resetState])

  return {
    isLoadingScript,
    errorLoadManifest,
    errorRenderMfe,
    setIsLoadingScript,
    setErrorLoadManifest,
    setErrorRenderMfe
  }
}

export default useMicroFrontend
