import { DateTime } from "luxon"

export enum TaskPreparationMode {
  CREDIT = "Credit",
  COMPLETION = "Completion",
  INTERFACE = "Interface"
}

export enum TaskStatus {
  DRAFT = "Draft",
  QUEUED = "Queued",
  PROCESSING = "Processing",
  COMPLETE = "Complete",
  FAILED = "Failed"
}

export enum TaskPreparationCreditMode {
  CHARGES = "Charges",
  ITEMS = "Items"
}

export type TaskCreditTypeAlias = "CHG+RPAY" | "CHG/ONLY"
export type TaskChargeReasonTypeAlias = "BAD/CON" | "BAD/DUR" | "BAD/POST" | "BAD/RATE" | "BAD/PO"

export type TaskPreparationContract = {
  contract_guid: string
  contract_model_title: string
  extra_total: number
  total: number
  users: number
  visit_total: number
  visits: number
  processable: boolean
}

export type TaskPreparation = {
  task_guid: string
  contracts: TaskPreparationContract[]
  processable: boolean
}

export interface TaskPreparationPostPayload {
  credit_mode?: TaskPreparationCreditMode
  guids: string[]
  mode: TaskPreparationMode
  period_guid?: string
  transaction_guid?: string
}

export interface TaskPreparationPutPayload {
  task_guid: string
  tax_point_date?: DateTime
  is_draft: boolean
  mode: TaskPreparationMode
  credit_type_alias?: TaskCreditTypeAlias
  charge_reason_type_alias?: TaskChargeReasonTypeAlias
  note?: string
}

export type Task = {
  guid: string
  attempts: number
  estimated_completion_at?: DateTime
  estimated_run_time?: string
  inserted_at: DateTime
  inserted_by: string
  inserter: string
  item_count: number
  processed_at?: DateTime
  started_at?: DateTime
  status: TaskStatus
  transaction_count: number
  type: TaskPreparationMode
}
