import { Box, Grid } from "@mui/material"
import React, { memo, useState } from "react"
import { Form } from "react-final-form"
import { Link } from "components"
import { Button, FormSpyCustom } from "components/Form"
import { queryStringToObject } from "lib/queryString"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { StatusEnum } from "data/commonTypes"
import { StatusSelectField } from "components/Form/StatusSelectField"
import { useLocation } from "react-router-dom"

export interface ClientContractsFilter {
  status?: StatusEnum[]
}

export const ClientContractListFilter: React.FC = memo(() => {
  const location = useLocation()

  const [filter, setFilter] = useState<ClientContractsFilter>(queryStringToObject(location.search))

  const onSubmit = (values: ClientContractsFilter) => {
    setFilter(values)
  }

  useFilterLocationChange({ filter })

  return (
    <Box m={3} mx={0}>
      <Form {...{ onSubmit, initialValues: filter }}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={3}>
                <StatusSelectField multiselect />
              </Grid>
              <Grid item md={6} />
              <Grid item md={3}>
                <Link to="new">
                  <Button>NEW CONTRACT</Button>
                </Link>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </Box>
  )
})

ClientContractListFilter.displayName = "ClientContractListFilter"
