import React, { memo } from "react"
import { Box, LinearProgress } from "@mui/material"
import { useIsRequestPending } from "./handlers/useIsRequestPending"

const RequestLoader: React.FC = () => {
  const displayLoader = useIsRequestPending()

  if (!displayLoader) return null

  return (
    <Box
      sx={{
        width: "100%",
        top: 0,
        position: "fixed",
        zIndex: 99999999
      }}
    >
      <LinearProgress />
    </Box>
  )
}
export default memo(RequestLoader)
