import { FinanceApi } from "api"
import { enabledById } from "data/helpers"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { ContractServiceType } from "./types"

export enum QueryKeyParts {
  CONTRACT_SERVICE_TYPE = "contract-service-type",
  DETAIL = "detail",
  LIST = "list"
}

export const queryKeys = {
  getContractServiceTypeKey: ({ guid }: { guid?: string }): QueryKey => [
    QueryKeyParts.CONTRACT_SERVICE_TYPE,
    QueryKeyParts.DETAIL,
    { guid }
  ],
  getContractServiceTypeListKey: (): QueryKey => [QueryKeyParts.CONTRACT_SERVICE_TYPE, QueryKeyParts.LIST]
}

export const useContractServiceTypeQuery = ({
  guid
}: {
  guid?: string
}): UseQueryResult<ContractServiceType, Error> => {
  return useQuery({
    queryKey: queryKeys.getContractServiceTypeKey({ guid }),
    queryFn: async () => FinanceApi.get(`/finance/contract-service-types/${guid}`),
    ...enabledById(guid)
  })
}

export const useContractServiceTypeListQuery = (): UseQueryResult<ContractServiceType[], Error> => {
  return useQuery({
    queryKey: queryKeys.getContractServiceTypeListKey(),
    queryFn: async () => FinanceApi.get("/finance/contract-service-types")
  })
}
