import React, { memo } from "react"
import { ModalWrapper } from "components/index"
import { Field, Form } from "react-final-form"
import Box from "@mui/material/Box"
import { Button, FormSectionHeader, Select } from "components/Form"
import { DateTime } from "luxon"
import testingId from "constants/testingId"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { AdditionalType } from "data/finance/additional/type"
import { ExtrasModalForm } from "./ExtrasModalForm"
import { validateExtrasModalForm } from "./helpers/validateExtrasModalForm"
import { BasicPeriod, FormValidation } from "lib/types"
import { deepObjectEqual } from "lib/utils"
import { InvoiceTransactionCharge } from "data/finance/invoiceProcessing/types"
import { PaymentTransactionCharge } from "data/finance/paymentProcessing/types"
import { CalendarPeriod } from "data/finance/contractModel/types"

export type ExtrasFormValues = {
  charge_type_alias: string
  description: string
  charge_date: DateTime
  quantity: number
  total: number
  rate?: number
}

type OwnProps = {
  charge?: PaymentTransactionCharge | InvoiceTransactionCharge
  extraType?: AdditionalType
  period: CalendarPeriod
  contract?: BasicPeriod
  destroyModal: () => void
  onSubmit: (values: ExtrasFormValues) => void
  additionalChargeTypes?: AdditionalType[]
  additionalValidation?: (values: ExtrasFormValues) => FormValidation
}

export const ExtrasModal: React.FC<OwnProps> = memo(
  ({ period, charge, destroyModal, contract, extraType, onSubmit, additionalChargeTypes, additionalValidation }) => {
    const chargeTypeOptions = additionalChargeTypes?.map((chargeType) => ({
      title: chargeType.title,
      value: chargeType.alias
    }))

    const modalTitle = extraType ? extraType.title : "Extra payments"

    const initialValues = charge
      ? {
          charge_date: charge.date,
          charge_type_alias: charge.charge_type_alias,
          description: charge.description,
          ...(charge.field_type === "TimeAndRate"
            ? { quantity: charge.quantity, rate: charge.rate }
            : { total: charge.total })
        }
      : { charge_date: contract?.end ? DateTime.min(contract.end.minus({ days: 1 }), period.end) : period.end }

    return (
      <ModalWrapper title={modalTitle} destroyModal={destroyModal} width={800} data-cy={testingId.extrasModal.screen}>
        <RequestLoaderWrapper>
          <Form<ExtrasFormValues>
            onSubmit={onSubmit}
            initialValues={initialValues}
            initialValuesEqual={deepObjectEqual}
            validate={validateExtrasModalForm({ period, contract, additionalValidation })}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {extraType ? (
                  <ExtrasModalForm {...{ extraType, contract, period }} />
                ) : (
                  <>
                    <FormSectionHeader title="Select extra type" />

                    <Field
                      name="charge_type_alias"
                      label="Extra Type *"
                      component={Select}
                      options={chargeTypeOptions}
                      required
                      data-cy={testingId.extrasModal.chargeTypeAlias}
                    />
                  </>
                )}
                <Box m={3} mx={0} display="flex" justifyContent="flex-end">
                  <Button fullWidth={false} variant="text" onClick={destroyModal}>
                    Cancel
                  </Button>
                  <Button
                    fullWidth={false}
                    color="primary"
                    type="submit"
                    sx={(theme) => ({
                      marginLeft: theme.spacing(3)
                    })}
                    data-cy={testingId.extrasModal.saveExtraButton}
                  >
                    {extraType ? "Save" : "Next"}
                  </Button>
                </Box>
              </form>
            )}
          </Form>
        </RequestLoaderWrapper>
      </ModalWrapper>
    )
  }
)

ExtrasModal.displayName = "ExtrasModal"
