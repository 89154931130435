import React, { useState } from "react"
import { useModalsStore } from "./useModalsStore"
import { useGetImage } from "./queries"
import { Box, CircularProgress, IconButton, styled, Typography } from "@mui/material"
import { CloudDownload } from "@mui/icons-material"

const Img = styled("img")({
  objectFit: "cover",
  width: "100%",
  height: "100%"
})

const handleDownload = async (file: Blob) => {
  const fileHandle = await window.showSaveFilePicker()
  const writable = await fileHandle.createWritable()
  await writable.write(file)
  await writable.close()
}

const ChatRoomImage = ({ isOperator, id }: { isOperator: boolean; id: string }) => {
  const toggleImageModal = useModalsStore((state) => state.toggleImageModal)
  const [progress, setProgress] = useState<number>(0)
  const { data, isLoading } = useGetImage(id, (file) => setProgress((file.progress ?? 0) * 100))

  return (
    <Box
      sx={{
        alignItems: "center",
        border: "1px solid",
        borderRadius: 3,
        borderColor: "divider",
        cursor: "zoom-in",
        display: "flex",
        justifyContent: "center",
        width: 262,
        height: 352,
        overflow: "hidden",
        position: "relative",
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
        my: 0.25,
        ...(isOperator
          ? {
              bgcolor: "primary.tint",
              borderBottomRightRadius: 0
            }
          : { bgcolor: "grey.100", borderBottomLeftRadius: 0 })
      }}
      onClick={() => data && toggleImageModal(new Blob([data?.data]))}
    >
      {data && (
        <>
          <IconButton
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              handleDownload(new Blob([data.data]))
            }}
            sx={{ position: "absolute", top: 9, right: 9, bgcolor: "white" }}
          >
            <CloudDownload />
          </IconButton>
          <Img src={URL.createObjectURL(new Blob([data.data]))} alt={id} />
        </>
      )}
      {isLoading && (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          <CircularProgress variant="determinate" value={progress} size={32} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Typography variant="body2" sx={{ fontSize: 10 }}>{`${Math.round(progress)}%`}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ChatRoomImage
