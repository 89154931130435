import { Box, Grid } from "@mui/material"
import { ContentContainer, Header } from "components"
import { Button, Select, UploadInput } from "components/Form"
import { useImportFileUploadMutation } from "data/finance/import/mutations"
import { FormApi } from "final-form"
import { csvToArray } from "helpers/csvToArray"
import validators from "lib/validators"
import React, { memo, useState } from "react"
import { Field, Form } from "react-final-form"
import { useUIStore } from "stores"

type ImportType = "al-52-week-daily-data" | "al-52-week-data" | "al-carryover"

const importTypeOptions = [
  { title: "AL 52 Week Daily Data", value: "al-52-week-daily-data" },
  { title: "AL 52 Week Data", value: "al-52-week-data" },
  { title: "AL Carry Over", value: "al-carryover" }
]

interface ImportFormValues {
  import_type: ImportType
  File: string
}

export const ImportForm: React.FC = memo(() => {
  ImportForm.displayName = "ImportForm"
  const showErrorMessage = useUIStore((state) => state.showErrorMessage)

  const { mutateAsync: uploadFile } = useImportFileUploadMutation()

  const [file, setFile] = useState<Blob | null>(null)

  const resetFormState = (form: FormApi<ImportFormValues, Partial<ImportFormValues>>) => {
    form.reset()
    form.resetFieldState("import_type")
    form.resetFieldState("File")
    setFile(null)
  }

  const onSubmit = async (vals: ImportFormValues) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      const text = e.target?.result

      if (text) {
        const data = csvToArray(text as string)
        uploadFile({ import_type: vals.import_type, fileAsString: data.str })
        return
      }

      showErrorMessage("Something went wrong while uploading the file. Please try again later or contact user support.")
    }

    if (file) {
      reader.readAsText(file)
    }
  }

  return (
    <ContentContainer>
      <Header title="Import" />

      <Form<ImportFormValues> onSubmit={onSubmit}>
        {({ handleSubmit, form, invalid, submitting }) => (
          <form
            onSubmit={(values) => {
              handleSubmit(values)
              resetFormState(form)
            }}
            encType="multipart/form-data"
          >
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item md={4}>
                <Field
                  type="string"
                  name="import_type"
                  component={Select}
                  label="Import Type"
                  validate={validators.required}
                  options={importTypeOptions}
                />
              </Grid>
              <Grid item md={4}>
                <Field
                  name="File"
                  render={(props) => <UploadInput {...props} setFile={setFile} accept=".csv" />}
                  validate={validators.required}
                />
              </Grid>
              <Grid item md={4}>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <Button
                    type="submit"
                    fullWidth={false}
                    sx={(theme) => ({
                      margin: theme.spacing(0, 0, 0, 0)
                    })}
                    disabled={invalid || submitting}
                  >
                    Upload
                  </Button>
                  <Button
                    variant="text"
                    fullWidth={false}
                    onClick={() => {
                      resetFormState(form)
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </ContentContainer>
  )
})
