import React, { useEffect, useMemo } from "react"
import { useShallow } from "zustand/react/shallow"
import { useQueryClient } from "react-query"
import type { Subscription } from "pubnub"
import { Box, CircularProgress, List, Typography } from "@mui/material"
import { useGetChannels, useGetUnreadCount } from "./queries"
import { useChatStore } from "./useChatStore"
import ChatChannelsListItem from "./ChatChannelsListItem"
import { useChat } from "./ChatProvider"
import { ACTION_TYPES, ACTION_VALUES } from "./constants"
import { StandardMessage } from "./types"

interface Props {
  regions: string[]
  carers: string[]
}

const ChatChannelsList = ({ regions, carers }: Props) => {
  const chat = useChat()
  const queryCache = useQueryClient()
  const { channelNames, currentChannel, setCurrentChannel } = useChatStore(
    useShallow(({ channelNames, currentChannel, setCurrentChannel }) => ({
      channelNames,
      currentChannel,
      setCurrentChannel
    }))
  )
  const { data: channels = [], isLoading } = useGetChannels(channelNames.chats, carers)
  const { data: unreadCount } = useGetUnreadCount(channels, currentChannel?.id)

  useEffect(() => {
    if (channels.length > 0 && !currentChannel?.id) {
      setCurrentChannel(channels[0])
    }
  }, [channels, currentChannel, setCurrentChannel])

  useEffect(() => {
    const onMessage = {
      message: ({ channel, message, timetoken }: Subscription.Message) => {
        if (currentChannel?.id !== channel && (message as StandardMessage).sender.isUser) {
          chat.addMessageAction({
            channel: channel,
            messageTimetoken: timetoken,
            action: {
              type: ACTION_TYPES.DELIVERED,
              value: ACTION_VALUES.NOT_READ
            }
          })
          queryCache.invalidateQueries(["channels", channelNames.chats, carers])
          queryCache.invalidateQueries("channels-unread-count")
        }
      }
    }
    chat.subscribe({
      channels: [channelNames.chats]
    })
    chat.addListener(onMessage)

    return () => {
      chat.unsubscribeAll()
      chat.removeListener(onMessage)
    }
  }, [chat, channelNames, queryCache, currentChannel, carers])

  const filteredChannels = useMemo(
    () => (carers.length > 0 ? channels : channels?.filter((channel) => regions.includes(channel.id))),
    [carers, channels, regions]
  )

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        <CircularProgress />
      </Box>
    )
  }

  if (!filteredChannels.length) {
    return (
      <Typography
        color="textPrimary"
        align="center"
        sx={{
          mt: 2,
          display: "flex",
          fontStyle: "italic"
        }}
        variant="caption"
      >
        There are no channels matching your search criteria
      </Typography>
    )
  }

  return (
    <List sx={{ overflowY: "auto", mx: -2, borderTop: "1px solid", borderColor: "divider", py: 0 }}>
      {filteredChannels?.map((channel) => {
        return (
          <ChatChannelsListItem
            channel={channel}
            key={channel.id}
            numberOfUnreadMessages={unreadCount ? unreadCount.channels[channel.id.replace("chat.", "carer.")] : 0}
          />
        )
      })}
    </List>
  )
}

export default ChatChannelsList
