import { useBranchInfoQuery } from "data/core/branch/queries"

/**
 * useBranchData
 *
 * @returns all the branch data
 */
const useBranchData = () => {
  const { data, isLoading } = useBranchInfoQuery()
  return { isLoading, data }
}

export default useBranchData
