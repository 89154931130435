import React, { useMemo, useState } from "react"
import { Box, Button, IconButton, Typography } from "@mui/material"
import { SchemaFieldOption } from "lib/types"
import { useCoreSchemasStore } from "stores"
import { useCommsCarersQuery } from "./queries"
import { useModalsStore } from "./useModalsStore"
import AddIcon from "@mui/icons-material/Add"
import testingId from "constants/testingId"
import ChatPanelAutocomplete from "./ChatPanelAutocomplete"
import { Close } from "@mui/icons-material"
import ChatChannelsList from "./ChatChannelsList"

const ChannelPanel = () => {
  const setIsNewChannelCreateModalOpen = useModalsStore((state) => state.setIsNewChannelCreateModalOpen)
  const [filteredRegions, setFilteredRegions] = useState<{ id: string; label: string }[]>([])
  const [filteredChannelsIds, setFilteredChannelsIds] = useState<{ id: string; label: string }[]>([])
  const schema = useCoreSchemasStore(
    ({
      schema: {
        models: { ApiUserCarer }
      }
    }) => ApiUserCarer
  )

  const { data: carers = [] } = useCommsCarersQuery({ regionIds: filteredRegions.map(({ id }) => id) })
  const regions = useMemo(
    () =>
      (schema?.regions.options as SchemaFieldOption[]).map(({ value, title }) => ({
        id: String(value),
        label: title
      })),
    [schema]
  )

  const carerSearchList = useMemo(
    () =>
      carers.map((carer) => ({
        id: carer.chat_channel_id,
        label: carer.name
      })),
    [carers]
  )

  const regionFilter = useMemo(() => carerSearchList.map(({ id }) => id), [carerSearchList])
  const carersFilter = useMemo(() => filteredChannelsIds.map(({ id }) => id), [filteredChannelsIds])

  return (
    <Box
      sx={{
        p: 2,
        pb: 0,
        width: 332,
        bgcolor: "grey.50",
        borderRight: "2px solid",
        borderColor: "divider",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        zIndex: 2
      }}
    >
      <Typography variant="h4" sx={{ pb: 2, mb: 3 }}>
        Messages
      </Typography>
      <Typography variant="h6" sx={{ px: 2, py: 1, mb: 3, mx: -2, borderBottom: "1px solid", borderColor: "divider" }}>
        Search & filters
      </Typography>
      <ChatPanelAutocomplete
        label="Regions"
        options={regions}
        onChange={setFilteredRegions}
        sx={{ mb: 3 }}
        value={filteredRegions}
        placeholder="Filter by region"
      />
      <ChatPanelAutocomplete
        label="Carers"
        options={carerSearchList}
        onChange={setFilteredChannelsIds}
        sx={{ mb: 1 }}
        value={filteredChannelsIds}
        placeholder="Filter by carer"
      />
      <Button
        sx={{ alignSelf: "flex-end", mb: 3 }}
        startIcon={<Close />}
        disabled={filteredChannelsIds.length === 0 && filteredRegions.length === 0}
        onClick={() => {
          setFilteredRegions([])
          setFilteredChannelsIds([])
        }}
      >
        Clear
      </Button>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          py: 1,
          mx: -2,
          px: 2,
          borderTop: "1px solid",
          borderColor: "divider"
        }}
        data-cy={testingId.messages.NewMessagePanel}
      >
        <Typography variant="h6" flex="1">
          Chats
        </Typography>
        <IconButton data-cy={testingId.messages.newMessageButton} onClick={() => setIsNewChannelCreateModalOpen(true)}>
          <AddIcon titleAccess="New Message" />
        </IconButton>
      </Box>
      <ChatChannelsList regions={regionFilter} carers={carersFilter} />
    </Box>
  )
}

export default ChannelPanel
