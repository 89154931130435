export const FrequencyOptions = [
  { title: "Daily", value: "DAILY" },
  { title: "Weekly", value: "1/WEEK" },
  { title: "2 Weekly", value: "2/WEEK" },
  { title: "4 Weekly", value: "4/WEEK" },
  { title: "Monthly", value: "1/MONTH" },
  { title: "Quarterly", value: "3/MONTH" },
  { title: "Biannually", value: "6/MONTH" },
  { title: "Annually", value: "12/MONTH" }
]

export const StatusOptions = [
  { title: "Expired", value: "Expired" },
  { title: "Current", value: "Current" },
  { title: "Future", value: "Future" }
]
