import { Divider } from "components"
import { useSelectableIdsDictionary } from "lib/hooks"
import { queryStringToObject } from "lib/queryString"
import React, { memo, useMemo, useState } from "react"
import { filterInvoiceTransactionVisits } from "../helpers/filterInvoiceTransactionVisits"
import { defaultItemListFilter, TransactionDetailItemsFilter } from "./TransactionDetailItemsFilter"
import { TransactionDetailItemsTable } from "./TransactionDetailItemsTable"
import { useLocation } from "react-router-dom"
import { InvoiceTransaction, InvoiceTransactionItem } from "data/finance/invoiceProcessing/types"
import { InvoiceTransactionItemsFilter } from "../types"

interface OwnProps {
  transaction: InvoiceTransaction
  periodGuid: string
  contractModelGuid: string
  onVisitsCredited: () => void
  visits?: InvoiceTransactionItem[]
}

export const TransactionDetailItemTab: React.FC<OwnProps> = memo(
  ({ transaction, periodGuid, contractModelGuid, onVisitsCredited, visits = [] }) => {
    const location = useLocation()

    const [itemFilter, setItemFilter] = useState<InvoiceTransactionItemsFilter>({
      ...queryStringToObject(location?.search),
      ...defaultItemListFilter
    })
    const filteredVisits = useMemo(
      () => filterInvoiceTransactionVisits({ visits, filter: itemFilter }),
      [visits, itemFilter]
    )

    const visitsIds = useMemo(() => {
      return filteredVisits.filter((item) => item.is_creditable).map((item) => item.guid)
    }, [filteredVisits])

    const selectableVisitsDictionary = useSelectableIdsDictionary(visitsIds)

    return (
      <>
        <TransactionDetailItemsFilter
          {...{
            resetAll: selectableVisitsDictionary.resetAll,
            showStatusFilter: ["READY", "PENDING"].includes(transaction.status_type_alias),
            itemFilter,
            setItemFilter
          }}
        />
        <Divider color="divider" />
        <TransactionDetailItemsTable
          {...{
            selectableVisitsDictionary,
            itemsLength: visits.length,
            periodGuid,
            filteredItems: filteredVisits,
            transaction,
            onVisitsCredited,
            contractGuid: contractModelGuid
          }}
        />
      </>
    )
  }
)

TransactionDetailItemTab.displayName = "TransactionDetailItemTab"
