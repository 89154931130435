import React, { memo } from "react"
import { Box, Grid, Typography } from "@mui/material"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import { TransactionVisitDetail } from "../types"

interface Props {
  visit: TransactionVisitDetail
  modelType: ContractModelType
}

const TransactionDetailItemHeader: React.FC<Props> = ({ visit, modelType }) => {
  const isInvoice = modelType === ContractModelType.INVOICE

  return (
    <Box
      sx={(theme) => ({
        margin: theme.spacing(4)
      })}
    >
      <Grid container spacing={1}>
        <Grid item md={4}>
          <Typography variant="body2" color="textSecondary">
            Client
          </Typography>
          <Typography variant="h6" data-cy={testingId.invoiceProcessingTransactionDetailItem.headerClient}>
            {isInvoice ? visit.owner_name : visit.reference_owner_name}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography variant="body2" color="textSecondary">
            Contract number
          </Typography>
          <Typography variant="h6" data-cy={testingId.invoiceProcessingTransactionDetailItem.headerContractNumber}>
            {visit.contract_guid}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography variant="body2" color="textSecondary">
            Contract model
          </Typography>
          <Typography variant="h6" data-cy={testingId.invoiceProcessingTransactionDetailItem.headerContractModel}>
            {visit.contract_model}
          </Typography>
        </Grid>
      </Grid>
      {isInvoice && (
        <>
          <Grid container spacing={1}>
            <Grid item md={4}>
              <Typography variant="body2" color="textSecondary">
                Carer Name
              </Typography>
              <Typography variant="h6" data-cy={testingId.invoiceProcessingTransactionDetailItem.headerCarerName}>
                {visit.reference_owner_name}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="body2" color="textSecondary">
                Payroll number
              </Typography>
              <Typography variant="h6" data-cy={testingId.invoiceProcessingTransactionDetailItem.headerPayrollNumber}>
                {visit.payroll_number}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="body2" color="textSecondary">
                Pay model
              </Typography>
              <Typography variant="h6" data-cy={testingId.invoiceProcessingTransactionDetailItem.headerPayModel}>
                {visit.pay_model}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={4}>
              <Typography variant="body2" color="textSecondary">
                Invoice Status
              </Typography>
              <Typography variant="h6" data-cy={testingId.invoiceProcessingTransactionDetailItem.headerInvoiceStatus}>
                {visit.status}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="body2" color="textSecondary">
                Invoice number
              </Typography>
              <Typography variant="h6" data-cy={testingId.invoiceProcessingTransactionDetailItem.headerInvoiceNumber}>
                {visit.invoice_number}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="body2" color="textSecondary">
                Invoice Date
              </Typography>
              <Typography variant="h6" data-cy={testingId.invoiceProcessingTransactionDetailItem.headerInvoiceDate}>
                {visit.invoice_date}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  )
}

export default memo(TransactionDetailItemHeader)
