import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import React from "react"
import { Field, Form } from "react-final-form"
import { Button, TextField } from "components/Form"
import { ApiUserCarer } from "models"
import Auth from "lib/Auth"
import Typography from "@mui/material/Typography"
import { useCarerPayrollPutMutation } from "data/core/carer/mutations"

interface Props {
  carer: ApiUserCarer
}

const CarerPayrollReferenceForm: React.FC<Props> = ({ carer }) => {
  const isPayrollReferenceEditable = Auth.hasPermission(["User.Carer.PayrollReference:Edit"])

  const { mutate: editPayroll } = useCarerPayrollPutMutation({ carerGuid: carer.guid })

  const onSubmit = ({ payroll_reference }: { payroll_reference?: string }) => {
    editPayroll({ payroll_reference })
  }

  if (!carer) return null

  if (Auth.hasPermission(["User.Carer.PayrollReference:Edit"])) {
    return (
      <Box m={3} mx={0}>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item md={3}>
                  <Field
                    name="payroll_reference"
                    label="HR Payroll ID"
                    component={TextField}
                    initialValue={carer.finance.payroll_reference}
                  />
                </Grid>
                <Grid item md={3}>
                  <Button type="submit" fullWidth={false} disabled={!isPayrollReferenceEditable}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </Box>
    )
  } else if (Auth.hasPermission(["User.Carer.PayrollReference:Read"])) {
    return (
      <Typography variant="h6">
        HR Payroll ID: <strong>{carer.finance.payroll_reference ?? "Not assigned"}</strong>
      </Typography>
    )
  }

  return null
}

export default React.memo(CarerPayrollReferenceForm)
