import React, { memo, useState } from "react"
import { ApiShiftCategoryResponse } from "types/Shifts/ShiftCategories/types"
import { ProfileMode } from "lib/types"
import { useShiftCategoryContractQuery } from "data/finance/shiftCategory/queries"
import { Box } from "@mui/material"
import { FormStage } from "constants/form"
import { UserContractTab } from "pages/Finance/components/types"
import { TabPanel } from "components"
import { ContractBackButton } from "pages/Finance/components/ContractBackButton"
import { ContractDetailHeader } from "pages/Finance/components/ContractDetailHeader"
import { ContractTabBar } from "pages/Finance/components/ContractTabBar"
import { ShiftCategoryContractDetailSettings } from "./components/ShiftCategoryContractDetailSettings"
import { ContractFileList } from "pages/Finance/components/ContractFileList"
import { useUserContractTabState } from "helpers/contractHelpers"
import { useParams } from "react-router-dom"

interface OwnProps {
  mode: ProfileMode
  shiftCategory: ApiShiftCategoryResponse
}

export const ShiftCategoryContractDetail: React.FC<OwnProps> = memo(({ shiftCategory, mode }) => {
  ShiftCategoryContractDetail.displayName = "ShiftCategoryContractDetail"

  const { contractId } = useParams()
  const isEdit = !!contractId
  const [formStage, setFormStage] = useState<FormStage>(isEdit ? FormStage.EDIT : FormStage.CREATE)

  const [tab, setTab] = useUserContractTabState()

  const { data: contract } = useShiftCategoryContractQuery({
    shiftCategoryGuid: shiftCategory.guid,
    userContractGuid: contractId
  })

  return (
    <>
      <ContractBackButton {...{ isEdit, formStage, setFormStage }} />
      {!isEdit && (
        <ShiftCategoryContractDetailSettings
          {...{ shiftCategory, mode, shiftCategoryContract: contract, formStage, setFormStage, isEdit }}
        />
      )}

      {isEdit && (
        <>
          <ContractDetailHeader
            {...{ userProfile: { name: shiftCategory.title }, contract, navigateTo: "../detail" }}
          />
          <ContractTabBar {...{ tab, setTab }} />
          <Box my={2}>
            <TabPanel value={tab} index={UserContractTab.SETTINGS}>
              <ShiftCategoryContractDetailSettings
                {...{
                  shiftCategory,
                  mode,
                  shiftCategoryContract: contract,
                  formStage,
                  setFormStage,
                  isEdit
                }}
              />
            </TabPanel>
            <TabPanel value={tab} index={UserContractTab.FILES}>
              {contract && (
                <ContractFileList
                  {...{ userGuid: shiftCategory.guid, contractGuid: contract.guid, userType: "Client" }}
                />
              )}
            </TabPanel>
          </Box>
        </>
      )}
    </>
  )
})
