import { ApiContractModelRate, ContractModelEnhancedRate } from "data/finance/contractModel/types"

export const reduceEnhancedRates = (rates: ApiContractModelRate[] = []): ContractModelEnhancedRate[] =>
  rates.reduce((result: ContractModelEnhancedRate[], rate: ApiContractModelRate) => {
    if (!rate.enhancements) return result

    const enhancedRates = rate.enhancements.map((enhancedRate) => ({
      ...enhancedRate,
      standard_rate_guid: rate.guid,
      day_type_alias: enhancedRate.holiday_tier_guid ?? enhancedRate.day_type_alias // Reverse logic form patch mutation, we assign holiday_tier_guid to day_type_alias for consistency
    }))

    return [...result, ...enhancedRates]
  }, [])
