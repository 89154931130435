import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import React, { useMemo } from "react"
import { last } from "lodash"
import { DateTime } from "luxon"
import Typography from "@mui/material/Typography"
import FallIcon from "./FallIcon"
import { RiskScoreBar } from "./RiskScoreBar"
import { Trend } from "./Trend"
import { RiskScoreChart } from "./RiskScoreChart"
import { Strip } from "./Strip"
import { ApiUserClient } from "models"
import { useClientFallRiskScoreQuery, useVisitMobilityAssessmentReportEnabledQuery } from "data/core/client/queries"
import { getNumericScore, parseFallRiskScoreRecords } from "./utils"
import { TREND } from "./Enums"

interface Props {
  client: ApiUserClient
}

const FallRiskScore: React.FC<Props> = ({ client }) => {
  const { data: fallRiskScoreRecords = [] } = useClientFallRiskScoreQuery({ serviceUserId: client.guid })
  const { data: fallRiskScoreEnabledForClient = [], isLoading } = useVisitMobilityAssessmentReportEnabledQuery({
    serviceUserId: client.guid
  })
  const parsedRecords = parseFallRiskScoreRecords(fallRiskScoreRecords)
  const lastFallRiskScore = last(fallRiskScoreRecords)

  const trend = useMemo(() => {
    /**
     * Get the two last entries of the fall risk score records to build the trend
     * info, based on last and before last registry
     *
     * Negative index counts back from the end of the array
     */
    const [recordBeforeLast, lastRecord] = fallRiskScoreRecords.slice(-2)
    const scoreBeforeLast = getNumericScore(recordBeforeLast?.value)
    const scoreLast = getNumericScore(lastRecord?.value)
    if (!scoreBeforeLast || !scoreLast) {
      return TREND.UNKNOWN
    }

    if (scoreBeforeLast === scoreLast) {
      return TREND.STABLE
    }

    if (scoreBeforeLast < scoreLast) {
      return TREND.UP
    }

    return TREND.DOWN
  }, [fallRiskScoreRecords])

  if (fallRiskScoreEnabledForClient.length === 0 || fallRiskScoreRecords.length === 0 || isLoading) {
    return null
  }

  return (
    <Accordion
      square
      sx={(theme) => ({
        marginTop: 4,
        borderRadius: "8px",
        border: `1px solid ${theme.palette.divider}`,
        boxShadow: "none",
        "&:before": {
          display: "none"
        }
      })}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="riskScore-content"
        id="riskScore-header"
        sx={{
          paddingLeft: 0,
          ".MuiAccordionSummary-content": {
            margin: 0
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            width: "95%"
          }}
        >
          <Strip value={lastFallRiskScore?.value ?? null} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flex: "1 0 0"
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: 2,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "self-start"
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <FallIcon
                  fontSize="large"
                  sx={{
                    marginRight: 2
                  }}
                />
                <strong>Fall risk</strong>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", padding: 2, alignItems: "center" }}>
              <Typography variant="body1">
                Last updated:&nbsp;
                <strong>
                  {DateTime.fromISO(lastFallRiskScore?.date ?? "").toLocaleString({
                    day: "numeric",
                    month: "long",
                    year: "numeric"
                  })}
                </strong>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                padding: "12px 16px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start"
              }}
            >
              <RiskScoreBar value={lastFallRiskScore?.value ?? null} />
            </Box>
            <Box
              sx={{
                display: "flex",
                padding: 2,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "8px"
              }}
            >
              <Trend trend={trend} />
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingLeft: 0 }}>
        <Box sx={{ display: "flex" }}>
          <Strip value={lastFallRiskScore?.value ?? null} />
          <Box sx={{ display: "flex", width: "100%", paddingLeft: 2 }}>
            <RiskScoreChart records={parsedRecords} />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default FallRiskScore
