import { Box } from "@mui/material"
import { WithPermission } from "components/Auth"
import { Button } from "components/Form"
import React, { memo } from "react"

interface OwnProps {
  handleCreditClick: () => void
  handlePayrollClick: () => void
  selectableIdsForCrediting: string[]
  selectableIdsForPayroll: string[]
  selectedIds: string[]
}

export const TransactionGroupActionButtons: React.FC<OwnProps> = memo(
  ({ handleCreditClick, handlePayrollClick, selectableIdsForCrediting, selectableIdsForPayroll, selectedIds }) => {
    const isCreditingEnabled = selectedIds.some((id) => selectableIdsForCrediting.includes(id))
    const isPayrollEnabled = selectedIds.some((id) => selectableIdsForPayroll.includes(id))
    const bothEnabled = isCreditingEnabled && isPayrollEnabled

    return (
      <Box
        p={2}
        px={0}
        display="flex"
        justifyContent="flex-end"
        sx={(theme) => ({
          borderColor: theme.palette.text.primary,
          borderTop: "1px solid"
        })}
      >
        <WithPermission permissions={["Finance.Processing.Credit:Edit"]}>
          <Button
            fullWidth={false}
            sx={(theme) => ({
              marginLeft: theme.spacing(3)
            })}
            disabled={!isCreditingEnabled || bothEnabled}
            onClick={handleCreditClick}
          >
            Credit selected
          </Button>
        </WithPermission>
        <WithPermission permissions={["Finance.Processing:Edit"]}>
          <Button
            fullWidth={false}
            color="primary"
            disabled={!isPayrollEnabled || bothEnabled}
            sx={(theme) => ({
              marginLeft: theme.spacing(3)
            })}
            onClick={handlePayrollClick}
          >
            Run payroll
          </Button>
        </WithPermission>
      </Box>
    )
  }
)

TransactionGroupActionButtons.displayName = "TransactionGroupActionButtons"
