import React, { memo } from "react"
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material"
import { Button, Checkbox, FormSectionHeader, Select, TextField } from "components/Form"
import { Field, FormSpy, FormSpyRenderProps } from "react-final-form"
import DeleteIcon from "@mui/icons-material/Delete"
import Box from "@mui/material/Box"
import { FieldArray } from "react-final-form-arrays"
import { grey } from "@mui/material/colors"
import { CarerContractFormValues } from "../types"
import { StatusEnum } from "data/commonTypes"
import { DateField } from "components/Form/DateField"

const contractMileageHeaderData = ["Start date", "Ends before", "Pay Home Travel", "Rate", "Status"]

export const CarerContractDetailStepTwoMileage: React.FC = memo(() => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }: FormSpyRenderProps<CarerContractFormValues, {}>) => (
        <FieldArray name="mileages">
          {({ fields }) => (
            <>
              <FormSectionHeader title="Mileages" />
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ textAlign: "left" }}>
                      <DeleteIcon />
                    </TableCell>
                    {contractMileageHeaderData.map((label, index) => (
                      <TableCell key={index}>{label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((name, index) => {
                    const field = fields.value[index]
                    const canBeDeleted = field.status === StatusEnum.FUTURE || !field.guid
                    const isToBeDeleted = field.delete
                    return (
                      <TableRow
                        key={index}
                        style={{
                          opacity: isToBeDeleted ? 0.4 : 1,
                          backgroundColor: isToBeDeleted ? grey[100] : "transparent"
                        }}
                      >
                        <TableCell>
                          {canBeDeleted ? (
                            <Field<boolean> type="checkbox" label="" component={Checkbox} name={`${name}.delete`} />
                          ) : null}
                        </TableCell>
                        <TableCell>
                          <DateField
                            name={`${name}.start`}
                            label="Start date *"
                            minDate={values.start ?? undefined}
                            maxDate={values.current_role || !values.end ? undefined : values.end.minus({ days: 1 })}
                            disabled={isToBeDeleted}
                          />
                        </TableCell>
                        <TableCell>
                          <DateField
                            name={`${name}.end`}
                            label="Ends before"
                            minDate={values.start ? values.start.plus({ days: 1 }) : undefined}
                            maxDate={values.current_role || !values.end ? undefined : values.end}
                            disabled={isToBeDeleted}
                          />
                        </TableCell>
                        <TableCell>
                          <Field<boolean>
                            component={Select}
                            name={`${name}.pay_to_and_from_home`}
                            label={"Pay Home Travel *"}
                            disabled={isToBeDeleted}
                            options={[
                              { title: "No", value: false },
                              { title: "Yes", value: true }
                            ]}
                            noValidate={true}
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            name={`${name}.rate`}
                            label="Mileage Rate *"
                            component={TextField}
                            disabled={isToBeDeleted}
                          />
                        </TableCell>
                        <TableCell>{field.status}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                <Button fullWidth={false} onClick={() => fields.push({})}>
                  Add new row
                </Button>
              </Box>
            </>
          )}
        </FieldArray>
      )}
    </FormSpy>
  )
})

CarerContractDetailStepTwoMileage.displayName = "CarerContractDetailStepTwoMileage"
