import { TokenResponse, SendBulkMessageRequest, Contact } from "pages/Chat/types"
import { companyApiInstance } from "./company"
import { AxiosProgressEvent } from "axios"

const getCommsUrl = (url: string, apiVersion = "v1") => {
  return `/dcp-comms/${apiVersion}/${url}`
}

const communicationsApi = {
  generateCommsToken: (): Promise<TokenResponse> => {
    return companyApiInstance.post<void, TokenResponse>(getCommsUrl(`messages/token`))
  },
  sendBulkMessage: (payload: SendBulkMessageRequest): Promise<void> => {
    return companyApiInstance.post<void, void>(getCommsUrl("messages"), payload)
  },
  getContacts: (): Promise<Contact[]> => {
    return companyApiInstance.get<void, Contact[]>(getCommsUrl("contacts?userType=carer"))
  },
  getCarers: (regionIds: string[]): Promise<Contact[]> => {
    const queryParams = new URLSearchParams()
    if (regionIds.length > 0) {
      regionIds.forEach((region, index) => {
        queryParams.append(`regionIds[${index}]`, region)
      })
    }
    return companyApiInstance.get<void, Contact[]>(getCommsUrl(`contacts/carers?${queryParams}`))
  },
  getImage: (id: string, onDownloadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined) =>
    companyApiInstance.get(getCommsUrl(`content/images/${id}`), {
      responseType: "blob",
      onDownloadProgress
    })
}

export default communicationsApi
