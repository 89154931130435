import React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { FormSectionHeader } from "components/Form"
import testingId from "constants/testingId"

interface Props {
  title: string
  headers: string[]
  data: (string | number | undefined)[]
  section: string
  "data-cy"?: string
}

const TransactionDetailItemSection: React.FC<Props> = ({ title, headers, data, section, "data-cy": testId }) => (
  <>
    <FormSectionHeader title={title} />
    <Table
      sx={{
        marginBottom: "50px"
      }}
      data-cy={testId}
    >
      <TableHead>
        <TableRow data-cy={testingId.tableHeaderRow}>
          {headers.map((header, index) => (
            <TableCell
              align="left"
              key={`${section}-header-${index}`}
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                borderBottom: "1px solid black"
              }}
              data-cy={testingId.tableHeaderCell}
            >
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow data-cy={testingId.tableRow}>
          {data.map((value, index) => (
            <TableCell align="left" key={`${section}-data-${index}`} data-cy={testingId.tableCell}>
              {value}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  </>
)

export default React.memo(TransactionDetailItemSection)
