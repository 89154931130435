import React, { memo, useCallback } from "react"
import { Button, Typography } from "@mui/material"
import { ChevronRight as ChevronRightIcon, ChevronLeft as ChevronLeftIcon } from "@mui/icons-material"
import { useUIStore } from "stores"

interface OwnProps {
  isSidebarOpen: boolean
}

export const MainNavToggleButton: React.FC<OwnProps> = memo(({ isSidebarOpen }) => {
  MainNavToggleButton.displayName = "MainNavToggleButton"
  const toggleSidebar = useUIStore((state) => state.toggleSidebar)

  const toggleDrawer = useCallback(() => {
    toggleSidebar()
  }, [toggleSidebar])

  return (
    <Button
      size="small"
      onClick={toggleDrawer}
      variant="text"
      sx={(theme) => ({
        marginTop: theme.spacing(2),
        marginLeft: isSidebarOpen ? theme.spacing(0.6) : "unset",
        justifyContent: "flex-start",
        minWidth: isSidebarOpen ? "inherit" : "100%"
      })}
      disableElevation
      disableFocusRipple
      disableRipple
      id="mainnav-show-menu-btn"
    >
      <Typography
        sx={(theme) => ({
          writingMode: isSidebarOpen ? "unset" : "vertical-lr",
          textDecoration: "underline",
          color: theme.palette.primary.contrastText,
          fontSize: theme.typography.caption.fontSize,
          width: "100%",
          display: "inherit",
          alignItems: "inherit",
          justifyContent: "inherit"
        })}
        component="span"
      >
        {isSidebarOpen ? (
          <>
            <ChevronLeftIcon viewBox="0 0 17 24" />
            Hide Menus
          </>
        ) : (
          <>
            <ChevronRightIcon />
            Show Menus
          </>
        )}
      </Typography>
    </Button>
  )
})
