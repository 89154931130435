import React from "react"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import testingId from "constants/testingId"
import { Checkbox } from "components/Form"
import { Field } from "react-final-form"
import { SxProps, Theme } from "@mui/material"

export interface Row {
  guid: string
  data: React.ReactNode[]
  canBeDeleted: boolean
  navigate: Function
  isDefault?: boolean
  isInvoiceModel: boolean
}

export interface TableBodyProps {
  addDeleteCheckbox?: boolean
  sx?: SxProps<Theme>
  rows?: Row[]
  onRowChecked: Function
}

export const Body: React.FC<TableBodyProps> = (props) => {
  const { sx, rows, onRowChecked } = props

  const onChecked = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, rowGuid: string) => {
    event.stopPropagation()

    if (onRowChecked) onRowChecked(rowGuid)
  }

  return (
    <>
      <TableBody>
        {rows?.map((row, rowIdx: number) => {
          return (
            <TableRow
              key={row.guid}
              data-cy={testingId.client.contract.list.table.bodyRow}
              hover
              sx={sx}
              onClick={() => row.navigate && row.navigate()}
            >
              <TableCell align="center" key={`delete-row-${row.guid}-${rowIdx}`}>
                {row.canBeDeleted && (
                  <Field<boolean>
                    type="checkbox"
                    label=""
                    component={Checkbox}
                    name={`calendars[p${row.guid}][delete]`}
                    data-cy={testingId.checkbox}
                    onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => onChecked(event, row.guid)}
                  />
                )}
              </TableCell>

              {row.data.map((component: React.ReactNode, dataIdx: number) => {
                return (
                  <TableCell key={`${row.guid}-${dataIdx}`} data-cy={testingId.client.contract.list.table.bodyCell}>
                    {component}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </>
  )
}
