import React, { memo } from "react"
import { Box, CircularProgress, CircularProgressProps } from "@mui/material"

const Spinner: React.FC<CircularProgressProps> = (props) => (
  <Box sx={{ position: "relative" }}>
    <CircularProgress
      variant="determinate"
      sx={(theme) => ({ color: theme.palette.grey[theme.palette.mode === "light" ? 200 : 700] })}
      size={20}
      thickness={4}
      {...props}
      value={100}
    />
    <CircularProgress
      variant="indeterminate"
      disableShrink
      sx={{
        color: "#1a90ff",
        animationDuration: "550ms",
        position: "absolute",
        left: 0,
        strokeLinecap: "round"
      }}
      size={20}
      thickness={4}
      {...props}
    />
  </Box>
)
export default memo(Spinner)
