import React from "react"
import { TableCell, TableHead, TableRow } from "@mui/material"
import { Delete } from "@mui/icons-material"

export interface TableHeadProps {
  data: string[]
  className?: string
  addEmptyCell?: boolean
  addDeleteIcon?: boolean
}

export const Head: React.FC<TableHeadProps> = (props) => {
  const { data, className, addDeleteIcon, addEmptyCell } = props

  return (
    <TableHead className={className}>
      <TableRow>
        {addDeleteIcon && (
          <TableCell align="center">
            <Delete
              sx={{
                marginRight: "24px",
                marginTop: "5px"
              }}
            />
          </TableCell>
        )}
        {data.map((text: string) => (
          <TableCell key={text}>{text}</TableCell>
        ))}
        {addEmptyCell ? <TableCell /> : null}
      </TableRow>
    </TableHead>
  )
}
