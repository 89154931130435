import React from "react"
import { Box, Grid, Typography } from "@mui/material"
import { FormSectionHeader } from "components/Form"
import { ApiUserCarerWorkPreferences, MaleFemalePreferenceEnum } from "models"
import { grey } from "@mui/material/colors"

interface Props {
  preferences?: ApiUserCarerWorkPreferences
}

const WorkPreferences = ({ preferences }: Props) => {
  const noWorkPreferences = !preferences || Object.values(preferences).every((val) => val === null)

  return (
    <Box>
      <FormSectionHeader title="Work preferences" />
      {noWorkPreferences && (
        <Box
          sx={{
            backgroundColor: grey[100],
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
            padding: "80px 80px"
          }}
        >
          <Typography variant="h6">No work preferences added yet</Typography>
          <Typography color="textSecondary" variant="body2">
            This section will be populated once the Carer updates their preferences via the mobile app.
          </Typography>
        </Box>
      )}
      {!noWorkPreferences && (
        <>
          <Typography color="textSecondary" variant="body1" sx={{ marginBottom: 4 }}>
            The Carer has completed the preferences through the Mobile App. They have been advised that is it not always
            possible to guarantee a perfect match for each visit and that they will find themselves in circumstances
            where the visit does not align with their preferences.
          </Typography>
          <Typography variant="h6" mb={2}>
            Pets and allergies
          </Typography>
          <Grid container spacing={8} mb={4}>
            <Grid item md={6}>
              <Typography color="textSecondary" mb={1} variant="body2">
                Are you allergic to dogs?
              </Typography>
              <Typography>{preferences.is_allergic_to_dogs ? "Yes" : "No"}</Typography>
              <Typography color="textSecondary" mb={1} variant="body2">
                Are you comfortable working in homes with pets?
              </Typography>
              <Typography>{preferences.is_comfortable_with_pets ? "Yes" : "No"}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography color="textSecondary" mb={1} variant="body2">
                Are you allergic to cats?
              </Typography>
              <Typography>{preferences.is_allergic_to_cats ? "Yes" : "No"}</Typography>
            </Grid>
          </Grid>
          <Typography variant="h6" mb={2}>
            Smoking and asthma
          </Typography>
          <Grid container spacing={8} mb={4}>
            <Grid item md={6}>
              <Typography color="textSecondary" mb={1} variant="body2">
                Do you have asthma or any breathing difficulties triggered by the environment?
              </Typography>
              <Typography>{preferences.has_asthma_or_breathing_difficulties ? "Yes" : "No"}</Typography>
            </Grid>
          </Grid>
          <Typography variant="h6" mb={2}>
            Providing care
          </Typography>
          <Grid container spacing={8} mb={4}>
            <Grid item md={6}>
              <Typography color="textSecondary" sx={{ marginBottom: 1 }} variant="body2">
                Are you comfortable providing care to both male and female clients?
              </Typography>
              {preferences.preference_to_male_and_female_clients === MaleFemalePreferenceEnum.MALE_OR_FEMALE && (
                <Typography>Yes, I am comfortable providing care to clients of any gender</Typography>
              )}
              {preferences.preference_to_male_and_female_clients === MaleFemalePreferenceEnum.ONLY_FEMALE && (
                <Typography>Only females</Typography>
              )}
              {preferences.preference_to_male_and_female_clients === MaleFemalePreferenceEnum.ONLY_MALE && (
                <Typography>Only males</Typography>
              )}
              {preferences.preference_to_male_and_female_clients === MaleFemalePreferenceEnum.PREFER_MALE && (
                <Typography>Preferably males</Typography>
              )}
              {preferences.preference_to_male_and_female_clients === MaleFemalePreferenceEnum.PREFER_FEMALE && (
                <Typography>Preferably females</Typography>
              )}
            </Grid>
            {!!preferences.preference_to_male_and_female_extra_info && (
              <Grid item md={6}>
                <Typography color="textSecondary" sx={{ marginBottom: 1 }} variant="body2">
                  Please give more details about your answer. This information will only be shared with your branch
                  manager.
                </Typography>
                <Typography
                  sx={{
                    wordBreak: "break-word"
                  }}
                >
                  {preferences.preference_to_male_and_female_extra_info}
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Box>
  )
}

export default WorkPreferences
