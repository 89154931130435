import { useEffect, useState } from "react"
import Config from "./config"
import { BranchInfoResponse } from "./hooks/branchInfo/types"

enum EnvironmentTrait {
  DEVELOPMENT = "dev",
  PRODUCTION = "prod"
}

declare global {
  interface Window {
    _sva?: {
      setVisitorTraits: (traits: { branch_id: string; user_type: string; env: EnvironmentTrait }) => void
    }
  }
}

const useSurvicate = (branchData: BranchInfoResponse | undefined, userType: string | undefined): void => {
  const SURVICATE_ID = Config.getEnvVariable("APP_SURVICATE_ID")

  const [initialized, setInitialized] = useState(false)

  // initiate survicate
  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    const survicateScript = document.getElementById("#cera-survicate")

    if (!head || !SURVICATE_ID || survicateScript) return

    script.setAttribute("id", "cera-survicate")
    script.setAttribute("src", `https://survey.survicate.com/workspaces/${SURVICATE_ID}/web_surveys.js`)
    script.setAttribute("async", "true")

    head.prepend(script)

    return () => {
      head.removeChild(script)
    }
  }, [SURVICATE_ID])

  // set survicate options
  useEffect(() => {
    if (initialized || !branchData || !userType || !window._sva || !SURVICATE_ID) {
      return
    }

    window._sva.setVisitorTraits({
      branch_id: branchData.guid,
      user_type: userType,
      env:
        Config.getEnvVariable("NODE_ENV") === "production" ? EnvironmentTrait.PRODUCTION : EnvironmentTrait.DEVELOPMENT
    })
    setInitialized(() => true)
  }, [branchData, initialized, userType, SURVICATE_ID])
}

export default useSurvicate
