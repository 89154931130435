import { Box, Grid } from "@mui/material"
import { ContentContainer, Link, StickyBox } from "components"
import BottomErrorMessage from "components/BottomErrorMessage"
import { Button, CancelButton, TextField } from "components/Form"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import {
  usePaymentModelRateCreateMutation,
  usePaymentModelRateUpdateMutation
} from "data/finance/contractModel/mutations"
import { usePaymentModelRateListQuery, usePaymentModelRateQuery } from "data/finance/contractModel/queries"
import { PaymentModelRateRequest } from "data/finance/contractModel/types"
import validators from "lib/validators"
import React, { memo, useCallback } from "react"
import { Field, Form } from "react-final-form"
import { ContractModelTitle } from "../components"
import { ContractModelPageProps } from "../types"
import { validatePaymentBaseRate } from "../validators/validatePaymentBaseRate"
import { DateField } from "components/Form/DateField"
import { useParams } from "react-router-dom"

export const PaymentModelBaseRateDetail: React.FC<ContractModelPageProps> = memo(({ contractModel, modelType }) => {
  const { rateId } = useParams()
  const { data: rate } = usePaymentModelRateQuery({ contractGuid: contractModel.guid, contractRateGuid: rateId })
  const { data: rates } = usePaymentModelRateListQuery({ contractGuid: contractModel.guid })
  const { mutateAsync: createRate } = usePaymentModelRateCreateMutation()
  const { mutateAsync: updateRate } = usePaymentModelRateUpdateMutation()

  const onSubmit = useCallback(
    (values: PaymentModelRateRequest) => {
      if (rateId) {
        updateRate({ modelGuid: contractModel.guid, rateGuid: rateId, payload: values })
      } else {
        createRate({ modelGuid: contractModel.guid, payload: values })
      }
    },
    [contractModel.guid, createRate, rateId, updateRate]
  )

  return (
    <ContentContainer>
      <ContractModelTitle
        {...{
          modelType,
          title: (
            <>
              <Link to="../settings">{contractModel.title}</Link> / <Link to="../base-rates">Base Rates</Link> /{" "}
              {rateId ? "Detail" : "New"}
            </>
          )
        }}
      />
      <Box m={3} mx={0}>
        <RequestLoaderWrapper my={3}>
          <Form
            {...{ onSubmit, initialValues: rate || {}, validate: validatePaymentBaseRate({ contractModel, rates }) }}
          >
            {({ handleSubmit, pristine, submitting, error }) => (
              <form {...{ onSubmit: handleSubmit }}>
                <Grid container spacing={3}>
                  <Grid item md={4}>
                    <DateField name="start" label="Starts on" required minDate={contractModel.start} />
                  </Grid>
                  <Grid item md={4}>
                    <DateField
                      name="end"
                      label="Ends before"
                      maxDate={contractModel.end ? contractModel.end : undefined}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={4}>
                    <Field
                      name="rate"
                      label="Rate *"
                      component={TextField}
                      validate={validators.validateCommonNumber()}
                    />
                  </Grid>
                </Grid>

                <StickyBox>
                  {error ? (
                    <Grid container>
                      <Grid item md={8}>
                        <BottomErrorMessage message={error} />
                      </Grid>
                    </Grid>
                  ) : null}
                  <CancelButton disabled={submitting} pristine={pristine} navigateUrl="../base-rates" />
                  <Button
                    type="submit"
                    fullWidth={false}
                    disabled={pristine || submitting}
                    sx={(theme) => ({
                      margin: theme.spacing(0, 0, 0, 3)
                    })}
                  >
                    SAVE
                  </Button>
                </StickyBox>
              </form>
            )}
          </Form>
        </RequestLoaderWrapper>
      </Box>
    </ContentContainer>
  )
})

PaymentModelBaseRateDetail.displayName = "PaymentModelBaseRateDetail"
