import React, { memo } from "react"
import { Checkbox } from "components/Form"
import { Field } from "react-final-form"

interface Props {
  enableAgencyIndicator: boolean
}

export const CarerProfileEmploymentType: React.FC<Props> = memo(({ enableAgencyIndicator }) => {
  return (
    <>
      {enableAgencyIndicator && (
        <Field type="checkbox" label="Agency worker" component={Checkbox} name="carerProfileData.sourcing_type" />
      )}

      <Field type="checkbox" label="Subcontractor" component={Checkbox} name="finance.is_subcontractor" />

      <Field type="checkbox" label="Bank" component={Checkbox} name="finance.is_bank" />

      <Field type="checkbox" label="Sponsorship" component={Checkbox} name="finance.is_sponsorship" />
    </>
  )
})

CarerProfileEmploymentType.displayName = "CarerProfileEmploymentType"
