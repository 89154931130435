import { ContractModelSummaryRequest } from "data/finance/contractModel/types"
import { uniq } from "ramda"

export const mapSummariesIntoFunderOptions = (
  summaries?: ContractModelSummaryRequest[]
): {
  value: string
  title: string
}[] => {
  const allUniqFunders = uniq(
    (summaries || []).reduce((result: string[], summary) => {
      return [...result, ...(summary?.funders || [])]
    }, [])
  )
  return allUniqFunders.map((funder) => ({ value: funder, title: funder }))
}
