import { CoreApi } from "api"
import { DateTime } from "luxon"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { queryKeys } from "./queries"
import { ApiUserRelative } from "models"
import { RelativeCreateUpdateRequest } from "./types"
import { ApiError } from "stores/useUIStore/types"
import { useNavigate } from "react-router-dom"
import { useApiMessage, useUIStore } from "stores"
import { UploadCareplanRequest, UploadFileResponse } from "data/user-documents/types"

interface CarerFileExpiryMutationParams {
  user_guid: string
  file_guid: string
  expires_at: DateTime
}

export const useCarerFileExpirePutMutation = () => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  return useMutation(
    async ({ user_guid, file_guid, expires_at }: CarerFileExpiryMutationParams) => {
      return CoreApi.put(`/patient/${user_guid}/file/${file_guid}/expires-at`, { expires_at })
    },
    {
      onSuccess: () => {
        showSuccessMessage("Expiration date successfully updated.")
      }
    }
  )
}

export const useClientUploadCarePlanMutation = ({ guid }: { guid: string }) => {
  const queryCache = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async (payload: UploadCareplanRequest): Promise<UploadFileResponse> => {
      const formData = new FormData()
      formData.append("file", payload.File)

      return CoreApi.post(`/patient/${payload.user}/care-plan`, formData)
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.getClientDownloadCarePlanKey(guid))
        showSuccessMessage("Careplan successfully uploaded")
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not upload careplan", { apiError })
      }
    }
  )
}

export const useClientCreateRelativeMutation = ({
  clientGuid
}: {
  clientGuid: string
}): UseMutationResult<ApiUserRelative, ApiError, RelativeCreateUpdateRequest> => {
  const navigate = useNavigate()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async (payload: RelativeCreateUpdateRequest): Promise<ApiUserRelative> => {
      return CoreApi.post(`/patient/${clientGuid}/relative`, payload)
    },
    {
      onSuccess: (data) => {
        const { guid } = data
        showSuccessMessage("New Relative successfully created")

        navigate(`/clients/${clientGuid}/relative/${guid}/profile/personal-details`)
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not create new relative", { apiError })
      }
    }
  )
}

export const useClientUpdateRelativeMutation = ({
  clientGuid
}: {
  clientGuid: string
}): UseMutationResult<ApiUserRelative, ApiError, RelativeCreateUpdateRequest> => {
  const queryCache = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async (payload: RelativeCreateUpdateRequest): Promise<ApiUserRelative> => {
      const { guid } = payload
      return CoreApi.put(`/patient/${clientGuid}/relative/${guid}`, payload)
    },
    {
      onSuccess: (data) => {
        const { guid: relativeGuid } = data
        queryCache.invalidateQueries(queryKeys.getClientRelativeKey({ clientGuid, relativeGuid }))
        showSuccessMessage("User details successfully saved.")
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not update user details", { apiError })
      }
    }
  )
}
