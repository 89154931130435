import React, { memo } from "react"
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material"
import testingId from "constants/testingId"
import { Button, Checkbox, Select, TextField } from "components/Form"
import { isUndefined } from "lodash"
import { SelectableIdsDictionary } from "lib/hooks"
import { Field, FormSpy } from "react-final-form"
import DeleteIcon from "@mui/icons-material/Delete"
import Box from "@mui/material/Box"
import { FieldArray } from "react-final-form-arrays"
import { grey } from "@mui/material/colors"
import { DateTime } from "luxon"
import { UnitContract } from "data/finance/unit/types"
import { DateField } from "components/Form/DateField"
import validators from "lib/validators"
import { ApiClientContractContribution } from "data/finance/client/types"

const headerLabels = ["Start date", "Ends before", "Weekly contribution", "Private contract to bill", "Status"]

interface OwnProps {
  selectableContributions: SelectableIdsDictionary
  contract: UnitContract
}

interface WrappedFields {
  fields: {
    value: any[]
    remove: (idx: number) => void
  }
}

interface NewContributionProps extends WrappedFields {
  start: DateTime
}

const UnitContractContributions: React.FC<OwnProps> = ({ selectableContributions, contract }) => {
  const { toggleId, getSelectedAsStringArr, selectableIds } = selectableContributions
  const deleteSelected =
    ({ fields }: WrappedFields) =>
    () => {
      const idsToDelete = getSelectedAsStringArr()
      idsToDelete.forEach((id: string) => {
        const index = fields.value.findIndex((contribution: ApiClientContractContribution) => id === contribution.guid)
        fields.remove(index)
      })
    }
  const isSelectedForDeletion = (guid: string) => getSelectedAsStringArr().includes(guid)
  const contractModelOptions =
    contract?.contribution_sources?.map((source: { title: string; guid: string }) => ({
      title: source.title,
      value: source.guid
    })) || []
  const createNewContribution = ({ fields, start }: NewContributionProps) => {
    const previousEndDate = fields.value && fields.value[fields.value.length - 1]?.end

    return {
      start: previousEndDate ?? start ?? DateTime.local()
    } as ApiClientContractContribution
  }

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <FieldArray name="contributions">
          {({ fields }) => (
            <>
              <Table>
                <TableHead>
                  <TableRow data-cy={testingId.tableHeaderRow}>
                    <TableCell style={{ textAlign: "left" }}>
                      <DeleteIcon onClick={deleteSelected({ fields })} />
                    </TableCell>
                    {headerLabels.map((label, index) => (
                      <TableCell key={index} data-cy={testingId.tableHeaderCell}>
                        {label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((name, index) => (
                    <TableRow
                      key={index}
                      style={{
                        opacity: isSelectedForDeletion(fields.value[index].guid) ? 0.3 : 1,
                        backgroundColor: isSelectedForDeletion(fields.value[index].guid) ? grey[100] : "transparent"
                      }}
                      data-cy={testingId.tableRow}
                    >
                      <TableCell>
                        {!isUndefined(selectableIds[fields.value[index].guid]) ? (
                          <Field<boolean>
                            type="checkbox"
                            label=""
                            component={Checkbox}
                            name={`${name}[delete]`}
                            onClick={(e: React.MouseEvent) => {
                              e.stopPropagation()
                              toggleId(fields.value[index].guid)
                            }}
                          />
                        ) : null}
                      </TableCell>
                      <TableCell>
                        <DateField
                          name={`${name}.start`}
                          label="Start Date"
                          required
                          disabled={isSelectedForDeletion(fields.value[index].guid)}
                          minDate={values.start ?? undefined}
                          maxDate={values.no_end_date || !values.end ? undefined : values.end.minus({ days: 1 })}
                        />
                      </TableCell>
                      <TableCell>
                        <DateField
                          name={`${name}.end`}
                          label="Ends before"
                          disabled={isSelectedForDeletion(fields.value[index].guid)}
                          minDate={values.start ? values.start.plus({ days: 1 }) : undefined}
                          maxDate={values.no_end_date || !values.end ? undefined : values.end}
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${name}.rate`}
                          label="Contribution (£) *"
                          component={TextField}
                          disabled={isSelectedForDeletion(fields.value[index].guid)}
                          validate={validators.validateCommonNumber()}
                        />
                      </TableCell>
                      <TableCell>
                        <Field
                          name={`${name}.user_contract_guid`}
                          label="Private Contract to Bill *"
                          component={Select}
                          options={contractModelOptions}
                          disabled={isSelectedForDeletion(fields.value[index].guid)}
                          validate={validators.required}
                        />
                      </TableCell>
                      <TableCell>
                        <Field {...{ name: `${name}.status`, disabled: true }}>
                          {(props) => <span>{props.input.value}</span>}
                        </Field>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                <Button
                  fullWidth={false}
                  onClick={() => fields.push(createNewContribution({ fields, start: values.start }))}
                  data-cy={testingId.clientContract.addContributionButton}
                >
                  Add new row
                </Button>
              </Box>
            </>
          )}
        </FieldArray>
      )}
    </FormSpy>
  )
}

export default memo(UnitContractContributions)
