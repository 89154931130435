import validators, { composeValidators } from "./validators"
import {
  Schema,
  SchemaField,
  SchemaValidator,
  SchemaDefinition,
  SchemaFieldOption,
  SchemaOnlyValidator,
  SchemaFieldOnlyValidator
} from "./types"
import { get } from "lodash"
import { path, split } from "ramda"

const primitiveTypes = ["string", "guid", "integer", "real", "date", "time", "datetime", "boolean"]

/**
 * Produces a schemas for generating form inputs / sections / pages
 * @param SchemaDefinition[] definitions - An array of definitions to be processed
 * @returns {[key: Schema name]: Schema} - An object containing all Schema definitions with key as their name
 */
export const createSchemas = (definitions: SchemaDefinition[]): { [key: string]: Schema } =>
  definitions.reduce((result: { [key: string]: Schema }, item: SchemaDefinition) => {
    result[item.name] = item.fields.reduce((fieldsObj: Schema, field: SchemaField) => {
      const { type, rel0 } = field.type

      if (type === "object" || (type === "array" && primitiveTypes.indexOf(rel0 as string) === -1)) {
        const relation = definitions.filter((_item: SchemaDefinition) => {
          return _item.name === rel0
        })
        if (rel0) {
          fieldsObj[field.name] = { ...createSchemas(relation)[rel0] }
        }
      } else {
        fieldsObj[field.name] = field
      }
      return fieldsObj
    }, {})
    return result
  }, {})

export const optionsToValueTitle = (options?: SchemaFieldOption[]): Record<string, string> =>
  (options || []).reduce((result: { [key: string]: string }, item: SchemaFieldOption) => {
    result[item.value] = item.title
    return result
  }, {})

/**
 * Returns an object of composed validators by their name
 * @deprecated
 * @param field - Field to compose validators for
 */
export const composeFieldValidators = (field: SchemaFieldOnlyValidator) => {
  if (!field) {
    console.warn("Invalid field supplied to composeValidators")
    return null
  }

  if (!Array.isArray(field.validators)) return null

  if (field.validators.length === 1) {
    const fieldValidator = field.validators[0]
    const { type, arg } = fieldValidator

    const validator = get(validators, type)
    return arg ? validator(arg) : validator
  } else {
    return composeValidators(
      field.validators.reduce((res: { [key: string]: Function }, item: SchemaValidator) => {
        const { type, arg } = item
        const validator = get(validators, item.type)
        const callback = arg ? validator(arg) : validator

        res[type] = callback
        return res
      }, {})
    )
  }
}

export const getFormValidators = (
  fields: string[],
  schema: SchemaOnlyValidator
): {
  [key: string]: Function
} =>
  fields.reduce((ret: { [key: string]: Function }, key: string) => {
    const composedValidators = composeFieldValidators(get(schema, key) as SchemaFieldOnlyValidator)
    if (composedValidators !== null) {
      ret[key] = composedValidators
    }
    return ret
  }, {})

export const getOptionsFromSchema = (schema: Schema, pathString: string): SchemaFieldOption[] => {
  const splitPath = split(".")(pathString)
  const options = path([...splitPath, "options"], schema) || []

  return options as SchemaFieldOption[]
}
