import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { InvoiceModelRate, ContractModel } from "data/finance/contractModel/types"
import React, { memo, useCallback, useMemo } from "react"
import dateTimeUtils from "lib/datetime"
import { Delete } from "@mui/icons-material"
import { EmptyData } from "components"
import { useSelectableIdsDictionary } from "lib/hooks"
import { Field, Form } from "react-final-form"
import { Button, Checkbox } from "components/Form"
import { isEmpty } from "ramda"
import { useStandingChargeDeleteHandler } from "../../handlers/useStandingChargeDeleteHandler"
import { RefetchQuery } from "data/types"
import { optionsToValueTitle } from "lib/schema"
import { FrequencyOptions } from "constants/selectOptions"
import { useNavigate } from "react-router-dom"
import { PATH } from "constants/path"
import { StatusEnum } from "data/commonTypes"

interface OwnProps {
  rates: InvoiceModelRate[]
  contractModel: ContractModel
  refetch: RefetchQuery<InvoiceModelRate[]>
  canEdit: boolean
}

const isDeletable = (status: StatusEnum): boolean => status === StatusEnum.FUTURE

export const InvoiceModelStandingChargeListTable: React.FC<OwnProps> = memo(
  ({ rates, contractModel, refetch, canEdit }) => {
    const navigate = useNavigate()
    const frequencies = useMemo(() => optionsToValueTitle(FrequencyOptions), [])

    const futureRateIds: string[] = useMemo(
      () => rates.filter((rate) => isDeletable(rate.status)).map((rate) => rate.guid),
      [rates]
    )

    const onRowClick = useCallback(
      (contractGuid: string, rateGuid: string) => () => {
        navigate(`${PATH.FINANCE.INVOICE_MODELS}/${contractGuid}/standing-charges/${rateGuid}`)
      },
      [navigate]
    )

    const { selectableIds, toggleId, getSelectedAsStringArr } = useSelectableIdsDictionary(futureRateIds)
    const selectedIds = getSelectedAsStringArr()

    const onDelete = useStandingChargeDeleteHandler({ selectedIds, contractModelGuid: contractModel.guid, refetch })

    return (
      <Form onSubmit={() => ({})}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Table>
              <TableHead>
                <TableRow>
                  {canEdit && (
                    <TableCell>
                      <Delete />
                    </TableCell>
                  )}
                  <TableCell>Start date</TableCell>
                  <TableCell>Ends before</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>Frequency</TableCell>
                  <TableCell>Hours</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rates.length ? (
                  rates.map((rate) => (
                    <TableRow
                      key={rate.guid}
                      onClick={onRowClick(rate.contract_guid, rate.guid)}
                      hover={canEdit}
                      sx={{ cursor: "pointer" }}
                    >
                      {canEdit && (
                        <TableCell>
                          {isDeletable(rate.status) ? (
                            <Field<boolean>
                              {...{
                                type: "checkbox",
                                name: `delete_${rate.guid}`,
                                component: Checkbox,
                                checked: selectableIds[rate.guid],
                                onClick: (e: React.MouseEvent) => {
                                  e.stopPropagation()
                                  toggleId(rate.guid)
                                }
                              }}
                            />
                          ) : null}
                        </TableCell>
                      )}
                      <TableCell>{dateTimeUtils.formatDate(rate.start)}</TableCell>
                      <TableCell>{rate.end ? dateTimeUtils.formatDate(rate.end) : "//"}</TableCell>
                      <TableCell>{rate.rate}</TableCell>
                      <TableCell>{frequencies[rate.frequency_type_alias]}</TableCell>
                      <TableCell>{rate.hours}</TableCell>
                      <TableCell>{rate.status}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <EmptyData message="We did not find any standing charges for selected dates and filter values." />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {canEdit && rates.length ? (
              <Box textAlign="right">
                <Button variant="text" fullWidth={false} disabled={isEmpty(selectedIds)} onClick={onDelete}>
                  Delete charge
                </Button>
              </Box>
            ) : null}
          </form>
        )}
      </Form>
    )
  }
)

InvoiceModelStandingChargeListTable.displayName = "InvoiceModelStandingChargeListTable"
