import { Box, Grid, Typography } from "@mui/material"
import { ModalWrapper } from "components"
import { Button, TextField } from "components/Form"
import {
  useCarerAccrualAdjustmentCreateMutation,
  useCarerAccrualAdjustmentDeleteMutation,
  useCarerAccrualAdjustmentUpdateMutation
} from "data/finance/carer/mutations"
import { ApiContractAccrualItem, ApiContractAccrualItemRequest } from "data/finance/carer/types"
import React, { memo } from "react"
import { Field, Form } from "react-final-form"
import { timeSpanToValuePeriod, constructTimeSpanUsingPeriod } from "lib/timespan"
import validators, { composeUnrequiredValidators } from "lib/validators"
import { DateField } from "components/Form/DateField"

interface OwnProps {
  accrualItem?: ApiContractAccrualItem
  destroyModal: () => void
  carerGuid: string
  accrualGuid: string
}

export const CarerAccrualDetailListModal: React.FC<OwnProps> = memo(
  ({ destroyModal, accrualItem, carerGuid, accrualGuid }) => {
    const onOk = destroyModal
    const { mutateAsync: createAccrualItem } = useCarerAccrualAdjustmentCreateMutation({ carerGuid, accrualGuid, onOk })
    const { mutateAsync: editAccrualItem } = useCarerAccrualAdjustmentUpdateMutation({
      carerGuid,
      accrualGuid,
      onOk
    })
    const { mutateAsync: deleteAccrualItem } = useCarerAccrualAdjustmentDeleteMutation({ carerGuid, accrualGuid, onOk })

    const onSubmit = async (values: ApiContractAccrualItemRequest) => {
      const mutatedValues = {
        ...values,
        expiry: values.expiry
          ? constructTimeSpanUsingPeriod(String(values.expiry), "DAYS")
              ?.shiftTo("days", "hours", "minutes", "seconds", "milliseconds")
              .toFormat("d:h:m:s.S")
          : undefined
      }

      if (accrualItem) {
        await editAccrualItem({ ...mutatedValues, accrualItemGuid: accrualItem.guid })
        return
      }
      await createAccrualItem(mutatedValues)
    }

    const onDelete = async () => {
      if (accrualItem && window.confirm("Are you sure you want to delete this Accrual Adjustment?")) {
        await deleteAccrualItem(accrualItem.guid)
      }
    }

    const initialValues = accrualItem
      ? {
          amount: accrualItem.amount,
          date: accrualItem.date,
          expiry: accrualItem.expiry ? timeSpanToValuePeriod(accrualItem.expiry, "DAYS")[0] : undefined,
          description: accrualItem.description
        }
      : {}

    return (
      <ModalWrapper {...{ destroyModal, width: 700 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
          <Typography variant="h5" align="center">
            {accrualItem ? "Edit Adjustment" : "Add Adjustment"}
          </Typography>

          {accrualItem ? (
            <Button fullWidth={false} color="primary" onClick={onDelete}>
              Delete
            </Button>
          ) : null}
        </Box>

        <Form {...{ initialValues, onSubmit }}>
          {({ handleSubmit, pristine, submitting }) => {
            return (
              <form {...{ onSubmit: handleSubmit }}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <DateField name="date" label="Date" required />
                  </Grid>
                  <Grid item md={6}>
                    <Field
                      name="expiry"
                      label="Expires in days"
                      component={TextField}
                      validate={composeUnrequiredValidators([
                        validators.mustBeNumber,
                        validators.precision(0),
                        validators.minValue(1),
                        validators.maxValue(365)
                      ])}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Field
                      name="amount"
                      label="Amount *"
                      component={TextField}
                      validate={validators.validateCommonNumber({ min: -365, max: 365, precisionValue: 2 })}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Field
                      name="description"
                      label="Description *"
                      component={TextField}
                      validate={validators.required}
                    />
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="space-between" m={3} mx={0}>
                  <Button {...{ variant: "text", fullWidth: false, onClick: destroyModal }}>Cancel</Button>
                  <Button {...{ type: "submit", disabled: pristine || submitting, fullWidth: false }}>Save</Button>
                </Box>
              </form>
            )
          }}
        </Form>
      </ModalWrapper>
    )
  }
)

CarerAccrualDetailListModal.displayName = "CarerAccrualDetailListModal"
