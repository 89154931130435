import { BackButton, Header } from "components"
import dateTimeUtils from "lib/datetime"
import React, { memo, ReactNode } from "react"
import { ContractModel, CalendarPeriod } from "data/finance/contractModel/types"
import testingId from "constants/testingId"
import { PATH } from "constants/path"
import { InvoiceTransaction } from "data/finance/invoiceProcessing/types"

const statusToTitle = (transaction: InvoiceTransaction): ReactNode => {
  switch (transaction?.status_type_alias) {
    case "PENDING":
      return "Pending"
    case "READY":
      return "Ready"
    case "CREDITED":
      return `CR-${transaction.reference}`
    case "COMPLETE":
      return `INV-${transaction.reference}`
    default:
      return "N/A"
  }
}

interface OwnProps {
  periodGuid: string
  calendarGuid: string
  contractGuid: string
  contractModel: ContractModel
  period: CalendarPeriod
  transaction: InvoiceTransaction
}

export const TransactionDetailHeader: React.FC<OwnProps> = memo(
  ({ calendarGuid, periodGuid, contractGuid: contractModelGuid, contractModel, period, transaction }) => {
    return (
      <>
        <BackButton
          fallbackUrl={`${PATH.FINANCE.INVOICE_PROCESSING}/${calendarGuid}/${periodGuid}/${contractModelGuid}`}
        />
        <Header
          title={`${contractModel.title} (${dateTimeUtils.formatDate(period.start)} - ${dateTimeUtils.formatDate(
            period.end
          )}): ${transaction.owner_name} (${statusToTitle(transaction)})`}
          data-cy={testingId.invoiceProcessingTransactionDetail.header}
        />
      </>
    )
  }
)

TransactionDetailHeader.displayName = "TransactionDetailHeader"
