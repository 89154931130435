import { DateTime } from "luxon"
import { ValidationErrors } from "final-form"

interface FormValidationProps {
  time: DateTime
}

export const validateVisitCheckout =
  (checkinTime: DateTime) =>
  (values: FormValidationProps): ValidationErrors | Promise<ValidationErrors> | undefined => {
    const { time } = values

    if (time && time <= checkinTime) {
      return { time: "Check-out time must be after the check-in time." }
    }
  }

export const validateVisitCheckin =
  (checkoutTime: DateTime | undefined) =>
  (values: FormValidationProps): ValidationErrors | Promise<ValidationErrors> | undefined => {
    const { time } = values

    if (!checkoutTime) return
    if (time && time >= checkoutTime) {
      return { time: "Check-in time must be before the check-out time." }
    }
  }
