import { UserContractType, UserContractUrlType } from "./types"

export const getUserContractType = (type: UserContractType): UserContractUrlType => {
  const choices: Record<UserContractType, UserContractUrlType> = {
    Client: "clients",
    Carer: "carers",
    Unit: "units",
    ShiftCategory: "shift-categories"
  }
  return choices[type]
}

export const buildUserContractFileListUrl = ({
  userGuid,
  contractGuid,
  userType
}: {
  userGuid?: string
  contractGuid?: string
  userType: UserContractType
}): string => `/finance/${getUserContractType(userType)}/${userGuid}/contracts/${contractGuid}/files`
