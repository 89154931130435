import React, { memo } from "react"
import { EmptyData, ContentContainer, Paginator } from "components"
import AddCalendarPeriod from "./AddCalendarPeriod"
import DatesMessageHelp from "components/DatesMessageHelp"
import { Table } from "@mui/material"
import { FieldArray } from "react-final-form-arrays"
import { CalendarPeriodsTableBody } from "./CalendarPeriodsTableBody"
import { ContractModelType } from "constants/modelTypes"
import { ModelTable } from "./Table"
import AddPaymentCalendarPeriod from "./AddPaymentCalendarPeriod"
import { usePagination } from "components/handlers/usePagination"
import { getPaginatedItems } from "helpers/getPaginatedItems"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"

interface OwnProps {
  modelType: ContractModelType
  headers: string[]
}

export const CalendarPeriods: React.FC<OwnProps> = memo(({ modelType, headers }) => {
  CalendarPeriods.displayName = "CalendarPeriods"

  const isInvoiceModel: boolean = modelType === ContractModelType.INVOICE

  const { pagination, setPagination } = usePagination({ initialPageSize: 10 })
  useFilterLocationChange({ pagination })

  return (
    <ContentContainer
      sx={(theme) => ({
        padding: theme.spacing(5, 0),
        display: "flex",
        flexDirection: "column"
      })}
    >
      <FieldArray name="periods">
        {({ fields }) => {
          const items = fields.value
          const paginatedItems = getPaginatedItems({ pagination, items, addIndex: true })
          return (
            <>
              {isInvoiceModel ? (
                <AddCalendarPeriod {...{ fields, setPagination }} />
              ) : (
                <AddPaymentCalendarPeriod {...{ fields, setPagination }} />
              )}
              <DatesMessageHelp messageType="I" />
              {items?.length ? (
                <>
                  <Table>
                    <ModelTable.Head data={headers} addDeleteIcon />
                    <CalendarPeriodsTableBody
                      items={paginatedItems}
                      modelType={modelType}
                      pagination={pagination}
                      allItemsLength={items.length}
                    />
                  </Table>
                  {items.length > +pagination.page_size && (
                    <Paginator
                      {...{
                        setPagination,
                        itemsLength: paginatedItems.length,
                        count: items.length,
                        initialPageSize: 10,
                        rowsPerPageOptions: [10, 25]
                      }}
                    />
                  )}
                </>
              ) : (
                <EmptyData message="No calendar period found, start by adding one." />
              )}
            </>
          )
        }}
      </FieldArray>
    </ContentContainer>
  )
})
