import { ApiUser, ApiVisit, UserType } from "models"
import Config from "../lib/config"
import createAxiosInstance from "./instance"
import { Schema } from "lib/types"
import { ErrorRequest, ErrorResponse } from "stores/useUIStore/types"
import {
  VisitCheckInRequest,
  VisitCheckOutRequest,
  VisitReopenRequest,
  VisitSignOffRequest,
  VisitUpdateCommentRequest
} from "data/visits/types"
import {
  DeleteFileRequest,
  DownloadFileRequest,
  ListFilesRequest,
  ListFilesResponse,
  UploadFileRequest
} from "data/user-documents/types"

const CORE_API_BASE_URL = Config.getEnvVariable("APP_API_URL") || "https://localhost:5005/v1"

export const coreApiInstance = createAxiosInstance(CORE_API_BASE_URL)

const userTypeBaseUrl: {
  [x in UserType]: string
} = {
  [UserType.Relative]: "/patient",
  [UserType.Carer]: "/carer",
  [UserType.Operator]: "/operator",
  [UserType.Patient]: "/patient"
}

export interface AuthenticationRequest {
  email: string
  password: string
}

export interface AuthenticationResponse {
  access_token: string
  refresh_token: string
  user_type: string
  user_id: string
  branch_id: string
}

export interface RequestPasswordResetRequest {
  email: string
}

export interface PasswordResetRequest {
  password: string
  password_confirmation: string
  reset_token: string
  guid: string
}

export interface RefreshTokenRequest {
  refresh_token: string
  branchId?: string | null
}

export interface RefreshTokenResponse {
  access_token: string
  branch_id: string
}

export type CompleteInvitationRequest = Omit<PasswordResetRequest, "reset_token"> & { invitation_token: string }

const getUserBaseUrl = (userType: UserType, data?: any): string => {
  let url = userTypeBaseUrl[userType]

  if (userType === UserType.Relative) {
    url += `/${data.client}/relative`
  }
  return url
}

const coreApi = {
  getError: (data: ErrorRequest): Promise<ErrorResponse> => {
    return coreApiInstance.post<ErrorRequest, ErrorResponse>(`/error/${data.code}`, data)
  },
  schema: (): Promise<Schema> => {
    return coreApiInstance.get("/dcp-core/schema", {
      baseURL: CORE_API_BASE_URL.replace("/v1", "")
    })
  },
  login: (data: AuthenticationRequest): Promise<AuthenticationResponse> => {
    return coreApiInstance.post<AuthenticationRequest, AuthenticationResponse>("/auth/login", data)
  },
  refresh: ({ branchId, ...data }: RefreshTokenRequest): Promise<RefreshTokenResponse> => {
    const refreshQuery = branchId ? `?branch_id=${branchId}` : ""
    return coreApiInstance.post<RefreshTokenRequest, RefreshTokenResponse>(`/auth/refresh${refreshQuery}`, data)
  },
  passwordResetRequest: (data: RequestPasswordResetRequest): Promise<void> => {
    return coreApiInstance.post<RequestPasswordResetRequest, void>("/auth/request-password-reset", data)
  },
  passwordReset: (data: PasswordResetRequest): Promise<void> => {
    return coreApiInstance.post<PasswordResetRequest, void>(`/auth/reset-password`, data)
  },
  completeInvitation: (data: CompleteInvitationRequest): Promise<void> => {
    return coreApiInstance.post<CompleteInvitationRequest, void>(`/auth/invitation`, data)
  },
  inviteUser: (guid: string): Promise<void> => {
    return coreApiInstance.post<string, void>(`/user/${guid}/invite`)
  },
  createUser: (userType: UserType, data: ApiUser): Promise<ApiUser> => {
    return coreApiInstance.post<{ id?: string }, ApiUser>(`${getUserBaseUrl(userType)}`, data)
  },
  updateUser: (userType: UserType, data: ApiUser): Promise<ApiUser> => {
    return coreApiInstance.put<ApiUser, ApiUser>(`${getUserBaseUrl(userType)}/${data.guid}`, data)
  },
  updateComment: (data: VisitUpdateCommentRequest): Promise<ApiVisit> => {
    const { visit, ...payload } = data
    return coreApiInstance.post<VisitUpdateCommentRequest, ApiVisit>(`/visit/${visit}/comment`, payload)
  },
  checkInVisit: (data: VisitCheckInRequest): Promise<ApiVisit> => {
    const { visit, ...payload } = data
    return coreApiInstance.post<VisitCheckInRequest, ApiVisit>(`/visit/${visit}/status/check-in`, payload)
  },
  checkOutVisit: (data: VisitCheckOutRequest): Promise<ApiVisit> => {
    const { visit, ...payload } = data
    return coreApiInstance.post<VisitCheckInRequest, ApiVisit>(`/visit/${visit}/status/check-out`, payload)
  },
  signOffVisit: (data: VisitSignOffRequest): Promise<ApiVisit> => {
    return coreApiInstance.post<VisitSignOffRequest, ApiVisit>(
      `/visit/${data.visit}/report/status/${data.report}/sign-off`
    )
  },
  signOffVisitWithConcern: (data: VisitSignOffRequest): Promise<ApiVisit> => {
    return coreApiInstance.post<VisitSignOffRequest, ApiVisit>(
      `/visit/${data.visit}/report/status/${data.report}/sign-off/concern`
    )
  },
  reopenVisit: (data: VisitReopenRequest): Promise<ApiVisit> => {
    const { visit, report, ...payload } = data
    return coreApiInstance.post<VisitSignOffRequest, ApiVisit>(
      `/visit/${visit}/report/status/${report}/reopen`,
      payload
    )
  },
  listDocuments: (userType: UserType, { user, query }: ListFilesRequest): Promise<ListFilesResponse> => {
    const queryString = query ? `?${query}` : ""
    return coreApiInstance.get<ListFilesRequest, ListFilesResponse>(
      `${getUserBaseUrl(userType)}/${user}/file${queryString}`
    )
  },
  uploadDocument: (data: UploadFileRequest, userType: UserType) => {
    const formData = new FormData()
    formData.append("file", data.File)
    formData.set("file_name", data.title)
    formData.set("category_guid", data.category_guid)

    return coreApiInstance.post<any, any>(`${getUserBaseUrl(userType)}/${data.user}/file`, formData)
  },
  replaceDocument: (data: UploadFileRequest, userType: UserType) => {
    const formData = new FormData()
    formData.append("file", data.File)

    return coreApiInstance.put<any, any>(`${getUserBaseUrl(userType)}/${data.user}/file/${data.file_guid}`, formData)
  },
  downloadDocument: (data: DownloadFileRequest, userType: UserType) => {
    return coreApiInstance.get<DownloadFileRequest, any>(
      `${getUserBaseUrl(userType)}/${data.user}/file/${data.file_guid}`,
      {
        responseType: "blob"
      }
    )
  },
  deleteDocument: (data: DeleteFileRequest, userType: UserType) => {
    return coreApiInstance.delete<DeleteFileRequest, any>(
      `${getUserBaseUrl(userType)}/${data.user}/file/${data.file_guid}`
    )
  },
  getProfilePicture: (user: { type: UserType; guid: string }, signal?: AbortSignal) => {
    const { type, guid } = user
    return coreApiInstance.get(`${getUserBaseUrl(type)}/${guid}/profile-image`, {
      signal,
      responseType: "blob"
    })
  }
}

export default coreApi
