import { Box } from "@mui/material"
import { InfoBox, Link } from "components"
import React, { memo } from "react"
import { Typography } from "@ceracare/dcp-ui"

import { useGetCarerBranchList } from "data/finance/carer/queries"
import { useCarerBranchComparator } from "../helpers/useCarerBranchComparator"

interface OwnProps {
  carerGuid: string
}

export const CarerAccrualBranchWarning = memo(({ carerGuid }: OwnProps) => {
  const { data: branches } = useGetCarerBranchList({ carerGuid })

  const branchComparison = useCarerBranchComparator({ branches, carerGuid })

  return (
    <Box marginBottom={3}>
      <InfoBox
        condition={branchComparison.uniqIDCount > 1}
        variant="warning"
        title="Multiple active unique employment identities"
        text={
          <Box>
            <Typography gutterBottom variant="body2">
              This Carer has multiple active unique IDs linked to the same NI number or Payroll ID. There should only be
              one active employment identity per Carer. Please contact the Systems Finance Team to resolve this issue.{" "}
              <Link external to="https://ceranet.ceracare.co.uk/home/" target="_blank" sx={{ color: "inherit" }}>
                Raise a ticket on CeraNet using Intercom
              </Link>
              .
            </Typography>
          </Box>
        }
      />

      <InfoBox
        condition={branchComparison.hasNoPrimary}
        variant="warning"
        title="No Primary Branch"
        text={
          <Box>
            <Typography gutterBottom variant="body2">
              A Carer must have a Primary Branch assigned at all times to administer all annual leave absences. Please
              contact the Systems Finance Team to set the primary branch.{" "}
              <Link external to="https://ceranet.ceracare.co.uk/home/" target="_blank" sx={{ color: "inherit" }}>
                Raise a ticket on CeraNet using Intercom
              </Link>
              .
            </Typography>
          </Box>
        }
      />
      <InfoBox
        condition={branchComparison.hasMultiplePrimary}
        variant="warning"
        title="More then one Primary Branch"
        text={
          <Box>
            <Typography gutterBottom variant="body2">
              A Carer must only have one Primary Branch assigned at all times to administer all annual leave absences.
              Please contact the Systems Finance Team to set the correct Primary Branch.{" "}
              <Link external to="https://ceranet.ceracare.co.uk/home/" target="_blank" sx={{ color: "inherit" }}>
                Raise a ticket on CeraNet using Intercom
              </Link>
              .
            </Typography>
          </Box>
        }
      />
    </Box>
  )
})

CarerAccrualBranchWarning.displayName = "CarerAccrualBranchWarning"
