import React, { memo } from "react"
import Grid from "@mui/material/Grid"
import validators, { composeValidators, validateCommonNumber } from "lib/validators"
import { TextField } from "components/Form"
import { DateTime } from "luxon"
import testingId from "constants/testingId"
import { BasicPeriod } from "lib/types"
import { AdditionalType } from "data/finance/additional/type"
import { DateField } from "components/Form/DateField"
import { Field } from "react-final-form"
import { CalendarPeriod } from "data/finance/contractModel/types"

type FormProps = {
  contract?: BasicPeriod
  period: CalendarPeriod
}

const dateField = ({ contract, period }: FormProps) => (
  <DateField
    name="charge_date"
    label="Charge date"
    minDate={contract?.start ? DateTime.max(period.start, contract.start) : period.start}
    maxDate={contract?.end ? DateTime.min(period.end, contract.end.minus({ days: 1 })) : period.end}
  />
)

const descriptionField = () => (
  <Field
    name="description"
    label="Reason"
    component={TextField}
    required
    data-cy={testingId.extrasModal.extrasReason}
    validate={composeValidators([validators.required, validators.maxLength()])}
  />
)

type OwnProps = {
  extraType: AdditionalType
}

const MILEAGE_FIELDS = ["MILE/TOP", "X/MILES"]

const TimeRateExtraForm: React.FC<FormProps & OwnProps> = ({ contract, period, extraType }) => {
  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        {dateField({ period, contract })}
      </Grid>
      <Grid item md={3}>
        <Field
          name="quantity"
          label={MILEAGE_FIELDS.includes(extraType.alias) ? "Mileage *" : "Time (hours) *"}
          component={TextField}
          validate={validators.validateCommonNumber()}
        />
      </Grid>
      <Grid item md={3}>
        <Field name="rate" label="£ Rate *" component={TextField} validate={validators.validateCommonNumber()} />
      </Grid>
      <Grid item md={12}>
        {descriptionField()}
      </Grid>
    </Grid>
  )
}

const CashExtraForm: React.FC<FormProps> = ({ contract, period }) => (
  <Grid container spacing={3}>
    <Grid item md={6}>
      {descriptionField()}
    </Grid>
    <Grid item md={3}>
      {dateField({ contract, period })}
    </Grid>
    <Grid item md={3}>
      <Field name="total" label="£ Cash *" component={TextField} validate={validateCommonNumber()} />
    </Grid>
  </Grid>
)

export const ExtrasModalForm: React.FC<OwnProps & FormProps> = memo(({ extraType, ...props }) => {
  if (extraType.type === "TimeAndRate") {
    return <TimeRateExtraForm {...props} extraType={extraType} />
  }

  return <CashExtraForm {...props} />
})

ExtrasModalForm.displayName = "ExtrasModalForm"
