import React from "react"
import { Grid } from "@mui/material"
import { Field } from "react-final-form"
import { Checkbox, FormSectionHeader, TextField } from "components/Form"
import validators, { composeValidators } from "lib/validators"

const ClientProfileSectionContactDetails = () => {
  return (
    <>
      <FormSectionHeader title="Contact details" />
      <Grid container spacing={8}>
        <Grid item md={6}>
          <Field
            name="profile.phone_mobile"
            label="Personal mobile number"
            component={TextField}
            validate={validators.phone}
          />
          <Field
            name="profile.phone_landline"
            label="Landline phone number"
            component={TextField}
            validate={validators.phone}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            name="profile.email"
            label="E-mail *"
            component={TextField}
            validate={composeValidators([validators.required, validators.email])}
          />
          <div>
            <Field
              name="communication_preferences.rosters_by_email"
              label="Rosters by e-mail"
              type="checkbox"
              component={Checkbox}
            />
          </div>
          <div>
            <Field
              name="communication_preferences.rosters_by_mail"
              label="Rosters by mail"
              type="checkbox"
              component={Checkbox}
            />
          </div>
          <div>
            <Field
              name="communication_preferences.invoices_by_email"
              label="Invoices by e-mail"
              type="checkbox"
              component={Checkbox}
            />
          </div>
          <div>
            <Field
              name="communication_preferences.invoices_by_mail"
              label="Invoices by mail"
              type="checkbox"
              component={Checkbox}
            />
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ClientProfileSectionContactDetails
