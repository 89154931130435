import { Box, Grid } from "@mui/material"
import React, { memo } from "react"
import { Field, Form } from "react-final-form"
import { ContentContainer, Header, Link, StickyBox } from "components"
import { Button, Checkbox, FormSectionHeader, TextField } from "components/Form"
import { WithPermission } from "components/Auth"
import validators, { composeValidators } from "lib/validators"
import { useContractServiceTypeQuery } from "data/finance/contractServiceType/queries"
import {
  useContractServiceTypeCreateMutation,
  useContractServiceTypeUpdateMutation
} from "data/finance/contractServiceType/mutations"
import { ContractServiceType } from "data/finance/contractServiceType/types"
import { useParams } from "react-router-dom"

const ContractServiceTypeForm: React.FC = () => {
  const { id } = useParams()
  const { data: contractServiceType } = useContractServiceTypeQuery({ guid: id })

  const { mutate: createContractServiceType } = useContractServiceTypeCreateMutation()

  const { mutate: updateContractServiceType } = useContractServiceTypeUpdateMutation()

  const onSubmit = (values: ContractServiceType) => {
    if (contractServiceType) {
      updateContractServiceType({ values })
    } else {
      createContractServiceType({ values })
    }
  }

  if (id && !contractServiceType) return null

  return (
    <ContentContainer>
      <Header
        title={
          <>
            <Link to="/admin/finance/contract-service-types">Contract care types</Link> -{" "}
            {contractServiceType?.title ?? "New contract care type"}
          </>
        }
      />
      <Box m={3} mx={0}>
        <Form onSubmit={onSubmit} initialValues={contractServiceType}>
          {({ handleSubmit, submitting, pristine, form }) => (
            <form onSubmit={handleSubmit}>
              <FormSectionHeader title="Contract care type" />
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Field
                    name="title"
                    label="Title *"
                    component={TextField}
                    validate={composeValidators([validators.required, validators.maxLength(64)])}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12}>
                  <Field name="is_training" label="Set as Training Service" component={Checkbox} type="checkbox" />
                </Grid>

                <Grid item md={12}>
                  <Field name="is_shadowing" label="Set as Shadowing Service" component={Checkbox} type="checkbox" />
                </Grid>

                <Grid item md={12}>
                  <Field
                    name="exclude_from_nmw"
                    label="Exclude from National Minimum Wage calculations"
                    component={Checkbox}
                    type="checkbox"
                  />
                </Grid>
              </Grid>
              <WithPermission permissions={["Finance.Settings.ServiceType:Edit"]}>
                <StickyBox>
                  <Button
                    variant="text"
                    fullWidth={false}
                    disabled={submitting || pristine}
                    onClick={() => {
                      form.reset()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    fullWidth={false}
                    sx={(theme) => ({ margin: theme.spacing(0, 0, 0, 3) })}
                    disabled={submitting}
                  >
                    Save changes
                  </Button>
                </StickyBox>
              </WithPermission>
            </form>
          )}
        </Form>
      </Box>
    </ContentContainer>
  )
}

export default memo(ContractServiceTypeForm)
