import { Box } from "@mui/material"
import { ContentContainer, Divider, EmptyData, Paginator } from "components"
import DatesMessageHelp from "components/DatesMessageHelp"
import { usePagination } from "components/handlers/usePagination"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { Permission } from "constants/permission"
import { useInvoiceModelRateListQuery } from "data/finance/contractModel/queries"
import { InvoiceModelRate } from "data/finance/contractModel/types"
import { useIsBeingSearched } from "data/helpers"
import React, { memo } from "react"
import { ContractModelPageProps } from "../types"
import { InvoiceModelStandingChargeListFilter } from "./components/InvoiceModelStandingChargeListFilter"
import { InvoiceModelStandingChargeListTable } from "./components/InvoiceModelStandingChargeListTable"
import { InvoiceModelStandingChargeListTitle } from "./components/InvoiceModelStandingChargeListTitle"
import { WithPermission } from "components/Auth"
import Auth from "lib/Auth"
import { useLocation } from "react-router-dom"

// can be sorted
export const InvoiceModelStandingChargeListComponent: React.FC<ContractModelPageProps> = memo(
  ({ contractModel, modelType }) => {
    InvoiceModelStandingChargeListComponent.displayName = "InvoiceModelStandingChargeListComponent"

    const location = useLocation()

    const { data: rates, refetch } = useInvoiceModelRateListQuery({
      contractGuid: contractModel.guid,
      search: location.search
    })

    const { pagination, setPagination, resetPagination } = usePagination()

    const isSearched = useIsBeingSearched<InvoiceModelRate>(rates)

    const canEdit = Auth.hasPermission([Permission.FINANCE_MODEL_RATE_EDIT])

    return (
      <ContentContainer>
        <InvoiceModelStandingChargeListTitle {...{ contractModel, modelType, canEdit }} />
        <Box m={3} mx={0}>
          <InvoiceModelStandingChargeListFilter {...{ pagination, resetPagination }} />
          <Divider color="divider" />
          <DatesMessageHelp messageType="E" />
          <RequestLoaderWrapper my={3}>
            {rates && isSearched ? (
              <>
                <InvoiceModelStandingChargeListTable {...{ rates, contractModel, refetch, canEdit }} />
                <Paginator {...{ setPagination, itemsLength: rates?.length }} />
              </>
            ) : (
              <EmptyData message="This invoice model has no standing charges. Start by adding one." />
            )}
          </RequestLoaderWrapper>
        </Box>
      </ContentContainer>
    )
  }
)

export const InvoiceModelStandingChargeList: React.FC<ContractModelPageProps> = memo(({ contractModel, modelType }) => {
  InvoiceModelStandingChargeList.displayName = "InvoiceModelStandingChargeList"

  return (
    <WithPermission
      {...{ showDenied: true, permissions: [Permission.FINANCE_MODEL_RATE_EDIT, Permission.FINANCE_MODEL_RATE_READ] }}
    >
      <InvoiceModelStandingChargeListComponent {...{ contractModel, modelType }} />
    </WithPermission>
  )
})
