import { useBranchInfoQuery } from "data/core/branch/queries"

/**
 * useBranchSettings
 *
 * @returns the current branch settings
 */
const useBranchSettings = () => {
  const { data: { settings = {} } = {}, isFetching, isLoading } = useBranchInfoQuery()
  return { settings, isFetching, isLoading }
}

export default useBranchSettings
