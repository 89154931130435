import { DateTime } from "luxon"

export enum ApiDownloadStatus {
  PENDING = "Pending",
  COMPLETED = "Completed",
  FAILED = "Failed"
}

export enum ApiDownloadMergeTypeEnum {
  NONE = "NONE",
  PDF = "PDF",
  CSV = "CSV",
  ZIP = "ZIP"
}

export interface ApiDownloadFilter {
  start: string
  end?: string
  expired?: boolean
  status?: string
}
export interface ApiDownload {
  created_at: DateTime
  created_by: string
  error_code?: string
  error_message?: string
  expires_at: DateTime
  file_guid?: string
  file_name: string
  job_guid?: string
  status: ApiDownloadStatus
  updated_at: DateTime
  updated_by: string
  user_guid: string
}

export interface ApiDownloadLink {
  file_name: string
  link: string
}

export interface ApiDownloadInputRequest {
  file_guids: string[]
  file_name: string
  merge_type: ApiDownloadMergeTypeEnum
}

export interface ApiDownloadRequest {
  input: ApiDownloadInputRequest[]
  zip_name?: string
}
