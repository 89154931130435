import React, { useState } from "react"
import { Box, Grid } from "@mui/material"
import { Field, Form } from "react-final-form"
import { FormSpyCustom, TextField } from "components/Form"
import { queryStringToObject } from "lib/queryString"
import { useDebouncedCallback } from "use-debounce"
import { Pagination } from "lib/types"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { ClearFilterButton } from "components/ClearFilterButton"
import { useLocation } from "react-router-dom"

interface HrCarerSearchFilterProps {
  search?: string
}

const HrCarerSearchFilter: React.FC<{
  pagination: Pagination
  resetPagination: () => void
}> = ({ resetPagination, pagination }) => {
  const location = useLocation()
  const [filter, setFilter] = useState<HrCarerSearchFilterProps>({
    ...queryStringToObject<HrCarerSearchFilterProps>(location?.search)
  })

  const onFilterSubmit = useDebouncedCallback((values: HrCarerSearchFilterProps) => {
    setFilter(values)
    resetPagination()
  }, 500)

  const clearFilter = () => {
    setFilter({})
    resetPagination()
  }

  useFilterLocationChange({ filter, pagination })

  return (
    <Box m={3} mx={0}>
      <Form onSubmit={onFilterSubmit} initialValues={filter}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={3}>
                <Field name="search" label="Filter by carer" component={TextField} />
              </Grid>
              <Grid item>
                <Box textAlign="right">
                  <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </Box>
  )
}

export default HrCarerSearchFilter
