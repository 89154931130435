import { Box } from "@mui/material"
import { FormApi } from "final-form"
import { keys } from "ramda"
import React, { memo, useState } from "react"
import { useFormState } from "react-final-form"
import { useRateUpliftModelTypeUpdateHandler } from "../../handlers/useRateUpliftModelTypeUpdateHandler"
import { ContractModelUplift, RateUpliftForm } from "../../types"
import { RateUpliftSelect } from "./RateUpliftSelect"
import { RateUpliftThreeService } from "./RateUpliftThreeService"
import { RateUpliftTableModelWrapper } from "./RateUpliftTableModelWrapper"
import { ContractModelType } from "constants/modelTypes"
import { getPickedServicesFromUpliftModel, getUpliftModel, isModelWithRates } from "../../helpers/upliftFormGetters"
import { sortUpliftItemsByIndex } from "../../helpers/sortUpliftItemsByIndex"

interface OwnProps {
  contract: ContractModelUplift
  form: FormApi<RateUpliftForm>
  modelType: ContractModelType
  defaultOpen: boolean
}

interface UpliftModelContentProps {
  open: boolean
  contract: ContractModelUplift
  form: FormApi<RateUpliftForm>
  modelType: ContractModelType
}

const UpliftModelContent: React.FC<UpliftModelContentProps> = memo(({ open, contract, form, modelType }) => {
  UpliftModelContent.displayName = "UpliftModelContent"

  useRateUpliftModelTypeUpdateHandler({ contractGuid: contract.guid, form })

  const { values } = useFormState<RateUpliftForm>()

  const upliftModel = getUpliftModel({ values, contractGuid: contract.guid })
  const hasRates = isModelWithRates(upliftModel)

  const validServicesKeys = keys(getPickedServicesFromUpliftModel({ values, contractGuid: contract.guid })) as string[]

  const sortedServices = sortUpliftItemsByIndex({
    items: values.models[contract.guid].serviceItems,
    guids: validServicesKeys
  })

  return (
    <>
      {hasRates && open && (
        <Box>
          <RateUpliftSelect
            {...{
              isServiceLevel: false,
              upliftType: upliftModel?.uplift_type,
              upliftFieldsOwnerName: `models[${contract.guid}]`
            }}
          />
        </Box>
      )}
      {sortedServices.map((service) => {
        return <RateUpliftThreeService {...{ service, contract, form, open, modelType }} key={service.guid} />
      })}
    </>
  )
})

export const RateUpliftThreeModel: React.FC<OwnProps> = memo(({ contract, form, modelType, defaultOpen }) => {
  RateUpliftThreeModel.displayName = "RateUpliftThreeModel"

  const [open, setOpen] = useState(defaultOpen)

  return (
    <RateUpliftTableModelWrapper {...{ title: contract.title, open, setOpen }}>
      <UpliftModelContent {...{ contract, form, open, modelType }} />
    </RateUpliftTableModelWrapper>
  )
})
