import type { BranchesListResponse } from "data/core/operator/queries"
import { BranchInfoResponse } from "lib/hooks/branchInfo/types"

export const getBranchTitle = (branch?: BranchInfoResponse | BranchesListResponse): string => {
  if (!branch) {
    return ""
  }

  const { status, title } = branch
  if (!status) {
    return title
  }
  return `${title} (${status})`
}
