import { Typography } from "@mui/material"
import { Link } from "components"
import { Checkbox } from "components/Form"
import MoneyWrapper from "components/MoneyWrapper"
import testingId from "constants/testingId"
import Auth from "lib/Auth"
import { SelectableIdsDictionaryModel } from "lib/hooks"
import { TransactionFailed, TransactionInProgress, TransactionQueued } from "pages/Finance/components"
import { isNil } from "ramda"
import React from "react"
import { Field } from "react-final-form"
import dateTimeUtils from "lib/datetime"
import { Permission } from "constants/permission"
import { ReplaceFileButton } from "components/ReplaceFileButton"
import { ListAlertIconBasic } from "components/ListAlertIcon"
import { RewindButton } from "components/RewindButton"
import { InvoiceTransaction } from "data/finance/invoiceProcessing/types"

const getTransactionAction = (
  isCredited: boolean,
  isInvoiced: boolean,
  transaction: InvoiceTransaction,
  selectableIds: SelectableIdsDictionaryModel,
  toggleId: Function
) => {
  if (isCredited) return <span>Credited</span>
  if (transaction.status_type_alias === "NOCHARGE") return <span>Non Chargeable</span>
  if (transaction.is_failed) return <TransactionFailed />
  if (transaction.is_queued) return <TransactionQueued />
  if (transaction.is_processing) return <TransactionInProgress />

  const hasProcessingEditPermission = Auth.hasPermission([Permission.FINANCE_PROCESSING_EDIT])
  const hasProcessingCreditEditPermission = Auth.hasPermission([Permission.FINANCE_PROCESSING_CREDIT_EDIT])

  if (
    !isNil(selectableIds[transaction.guid]) &&
    ((hasProcessingEditPermission && transaction.is_processable) ||
      (hasProcessingCreditEditPermission && transaction.is_creditable))
  ) {
    const checkboxNamePrefix = isInvoiced ? "credit" : "invoice"
    return (
      <>
        <Field<boolean>
          type="checkbox"
          name={`${checkboxNamePrefix}[${transaction.guid}]`}
          checked={selectableIds[transaction.guid]}
          component={Checkbox}
          onClick={(e: MouseEvent) => {
            e.stopPropagation()
            toggleId(transaction.guid)
          }}
          data-cy={testingId.invoiceProcessingTransactionList.itemCheckbox}
        />
        {transaction.is_creditable ? "(Cr)" : "(Inv)"}
      </>
    )
  }

  return null
}

export const mapInvoiceTransaction = ({
  transaction,
  index,
  statusTypeOptions,
  selectableIds,
  toggleId,
  hasCreditableTransactions,
  hasFiles,
  hasAlerts,
  hasDeveloperPermission,
  hasRewindPermission,
  refetch
}: {
  transaction: InvoiceTransaction
  index: number
  statusTypeOptions: Record<string, string>
  selectableIds: SelectableIdsDictionaryModel
  toggleId: Function
  hasCreditableTransactions: boolean
  hasFiles: boolean
  hasAlerts: boolean
  hasDeveloperPermission: boolean
  hasRewindPermission: boolean
  refetch: () => void
}): React.ReactNode[] => {
  const isInvoiced = transaction.status_type_alias === "COMPLETE"
  const isCredited = !!transaction.credit_guid

  const pricePrefix = isCredited && !isInvoiced ? "CR " : ""
  const totalPriceColor = isInvoiced ? "primary" : "textPrimary"

  const defaultItems = [
    transaction.owner_type === "Unit" ? (
      <Link to={`/units/${transaction.owner_guid}`} key={`link-${transaction.owner_guid}`}>
        {transaction.owner_name}
      </Link>
    ) : (
      <Link to={`/clients/${transaction.owner_guid}/profile/personal-details`} key={`link-${transaction.owner_guid}`}>
        {transaction.owner_name}
      </Link>
    ),
    <Link
      to={`/clients/${transaction.owner_guid}/contracts/${transaction.user_contract_guid}`}
      key={`link=${transaction.user_contract_guid}`}
    >
      {transaction.user_contract_reference}
    </Link>,
    !isCredited || isInvoiced ? `${transaction.confirmed_percentage}%` : "-",
    transaction.appointment_count,
    statusTypeOptions[transaction.status_type_alias],
    transaction.reference ? transaction.reference : "-",
    transaction.completed_at ? dateTimeUtils.formatDate(transaction.completed_at) : "-",
    <MoneyWrapper key={transaction.guid} prefix={pricePrefix} amount={transaction.subtotal_item} />,
    <MoneyWrapper key={transaction.guid} prefix={pricePrefix} amount={transaction.subtotal_extra} />,
    <Typography
      key={`typo-${index}`}
      style={{ fontWeight: isInvoiced ? "bold" : "normal" }}
      color={totalPriceColor}
      variant="body2"
    >
      <MoneyWrapper key={transaction.guid} prefix={pricePrefix} amount={transaction.total} />
    </Typography>,
    getTransactionAction(isCredited, isInvoiced, transaction, selectableIds, toggleId)
  ]

  const replaceItem = transaction.reference ? (
    <ReplaceFileButton {...{ transaction_guids: [transaction.guid], isInvoice: true }} />
  ) : null

  const rewindItem = transaction.is_creditable ? (
    <RewindButton rewind_type="Transaction" item_guids={[transaction.guid]} refetch={refetch} />
  ) : null

  const alertItem = transaction.is_alerting ? <ListAlertIconBasic /> : null

  return [
    ...defaultItems,
    ...(hasDeveloperPermission && hasFiles ? [replaceItem] : []),
    ...(hasRewindPermission && hasCreditableTransactions ? [rewindItem] : []),
    ...(hasAlerts ? [alertItem] : [])
  ]
}
