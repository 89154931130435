import { Link } from "components"
import { useInvoiceFileInterfaceDownloadQuery } from "data/finance/invoiceFile/queries"
import { InterfaceFile } from "data/finance/invoiceFile/types"
import { usePaymentFileInterfaceDownloadQuery } from "data/finance/paymentFile/queries"
import { downloadFileSimple } from "lib/utils"
import React, { memo, MouseEvent, useEffect, useState } from "react"

interface OwnProps {
  file: InterfaceFile
  isInvoice: boolean
}

export const InterfaceFileTabItemLink: React.FC<OwnProps> = memo(({ file, isInvoice }) => {
  // TODO: create custom hook to handle download
  const [clickedDownload, setClickedDownload] = useState<boolean>(false)

  const { data: interfacePaymentFile } = usePaymentFileInterfaceDownloadQuery({
    enabled: clickedDownload && !isInvoice,
    interfaceGuid: file.guid
  })
  const { data: interfaceInvoiceFile } = useInvoiceFileInterfaceDownloadQuery({
    enabled: clickedDownload && isInvoice,
    interfaceGuid: file.guid
  })

  const interfaceFile = isInvoice ? interfaceInvoiceFile : interfacePaymentFile

  const onDownloadClick = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setClickedDownload(true)
  }

  useEffect(() => {
    if (clickedDownload && interfaceFile) {
      downloadFileSimple({ href: interfaceFile.link, fileName: "interface" })
      setClickedDownload(false)
    }
  }, [clickedDownload, interfaceFile])

  return <Link {...{ to: "/#", onClick: onDownloadClick }}>Download</Link>
})

InterfaceFileTabItemLink.displayName = "InterfaceFileTabItemLink"
