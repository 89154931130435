import { Appointment, AppointmentAssignee, AppointmentTypeAlias } from "data/finance/appointment/types"
import { compareDateTimes, DateTimeFormat, formatDate } from "lib/datetime"
import { AuthLink } from "components"
import React from "react"
import { GUID_ZERO } from "constants/guid"
import { appointmentOwnerLink } from "./appointmentOwnerLink"
import { appointmentTypeLink } from "./appointmentTypeLink"
import { getContractModelLink } from "pages/Finance/components/helpers/getContractModelLink"
import { Permission } from "constants/permission"

export interface ScheduledAppointment {
  typeAlias: AppointmentTypeAlias
  assigneeGuid: string
  index: number
  is_alerting?: number
  row: (string | React.JSX.Element)[]
}

const carerName = (assignee: AppointmentAssignee): string | React.JSX.Element => {
  if (assignee.carer_guid === GUID_ZERO) return assignee.carer_name
  return (
    <AuthLink to={`/carers/${assignee.carer_guid}/profile/personal-details`} permissions={[Permission.USER_CARER_READ]}>
      {assignee.carer_name}
    </AuthLink>
  )
}

export const mapScheduledAppointments = (apps: Appointment[]): ScheduledAppointment[] => {
  return apps.reduce((result: ScheduledAppointment[], app, index) => {
    // creates row for every assignee in every appointment
    const items: ScheduledAppointment[] = app.assignees?.length
      ? app.assignees.map((assignee) => {
          const startDiff = compareDateTimes({ main: app.start, compareTo: assignee.check_in_at })

          return {
            typeAlias: app.type_alias,
            assigneeGuid: assignee.guid,
            index,
            is_alerting: startDiff && startDiff >= 15 ? startDiff : undefined,
            row: [
              appointmentTypeLink({ appointment_type: app.type_alias, appointment_guid: assignee.guid }),
              formatDate(app.start, DateTimeFormat.DATE_TIME),
              formatDate(app.end, DateTimeFormat.DATE_TIME),
              appointmentOwnerLink({
                owner_type: app.owner_type,
                owner_guid: app.owner_guid,
                owner_name: app.owner_name
              }),
              carerName(assignee),
              getContractModelLink({
                contract_guid: assignee.contract_guid,
                contract_title: assignee.contract_title
              }),
              app.service_title,
              assignee.variation_title,
              assignee.check_in_at ? formatDate(assignee.check_in_at, DateTimeFormat.DATE_TIME) : "",
              assignee.check_out_at ? formatDate(assignee.check_out_at, DateTimeFormat.DATE_TIME) : "",
              assignee.confirmed ? "Confirmed" : "Unconfirmed"
            ]
          }
        })
      : [
          {
            typeAlias: app.type_alias,
            assigneeGuid: "",
            index,
            row: [
              app.type_alias === AppointmentTypeAlias.VISIT ? "Visit" : "Shift",
              formatDate(app.start, DateTimeFormat.DATE_TIME),
              formatDate(app.end, DateTimeFormat.DATE_TIME),
              app.owner_name,
              "",
              "",
              app.service_title,
              "",
              "",
              ""
            ]
          }
        ]

    return [...result, ...items]
  }, [])
}
