import { Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { UnitContract } from "data/finance/unit/types"
import React, { memo } from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import { Button, Checkbox } from "components/Form"
import { Field, Form } from "react-final-form"
import { Paginator, StickyBox } from "components"
import { useUnitContractDeleteMutation } from "data/finance/unit/mutations"
import { QueryRefetch, SetPagination } from "lib/types"
import { useDebouncedCallback } from "use-debounce"
import DatesMessageHelp from "components/DatesMessageHelp"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { useMapContractsAsTableRows } from "helpers/contractHelpers"

const headers: string[] = ["Contract ID", "Model", "Type", "Starts on", "Ends before", "Status"]

interface OwnProps {
  contracts: UnitContract[]
  unitGuid: string
  refetch: QueryRefetch<UnitContract[]>
  setPagination: SetPagination
}

export const UnitContractListTable: React.FC<OwnProps> = memo(({ contracts, unitGuid, refetch, setPagination }) => {
  UnitContractListTable.displayName = "UnitContractListTable"

  const { mutateAsync: deleteContract } = useUnitContractDeleteMutation({ unitGuid })

  const debouncedRefetch = useDebouncedCallback(() => {
    refetch()
  }, 500)

  const onSubmit = (values: Record<string, boolean>) => {
    Object.keys(values).forEach(async (contractGuid) => {
      if (values[contractGuid]) {
        await deleteContract({ contractGuid })
        debouncedRefetch()
      }
    })
  }

  const rows = useMapContractsAsTableRows(contracts)

  return (
    <RequestLoaderWrapper {...{ my: 3 }}>
      <Typography variant="h6">Units</Typography>
      <Divider
        sx={{
          bgcolor: "divider",
          marginTop: 1,
          marginBottom: 1
        }}
      />
      <DatesMessageHelp messageType="E" />
      <Form {...{ onSubmit, destroyOnUnregister: true }}>
        {({ handleSubmit, values }) => {
          return (
            <form {...{ onSubmit: handleSubmit }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <DeleteIcon sx={{ marginRight: 1, marginTop: 1 }} />
                    </TableCell>
                    {headers.map((title) => (
                      <TableCell key={title}>{title}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((contract) => (
                    <TableRow sx={{ cursor: "pointer" }} key={contract.guid} hover onClick={() => contract.navigate()}>
                      <TableCell>
                        {contract.canBeDeleted ? (
                          <Field<boolean>
                            {...{
                              type: "checkbox",
                              component: Checkbox,
                              name: contract.guid,
                              onClick: (e: MouseEvent) => {
                                e.stopPropagation()
                              }
                            }}
                          />
                        ) : null}
                      </TableCell>
                      {contract.data.map((item, index) => (
                        <TableCell key={`${contract.guid}-${index}`}>{item}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Paginator {...{ setPagination, itemsLength: contracts.length }} />
              <StickyBox>
                <Button
                  {...{
                    type: "submit",
                    fullWidth: false,
                    disabled: !Object.values(values).includes(true)
                  }}
                >
                  Delete
                </Button>
              </StickyBox>
            </form>
          )
        }}
      </Form>
    </RequestLoaderWrapper>
  )
})
