// login
export const PATH_LOGIN = "/login"
export const PATH_LOGIN_SSO = "/login-sso"
export const PATH_PASSWORD_RESET = "/password-reset"
export const PATH_PASSWORD_RESET_REQUEST_SUCCESS = "/password-reset-request-success"
export const PATH_PASSWORD_RESET_SUCCESS = "/password-reset-success"
export const PATH_PASSWORD_RESET_TOKEN = "/password-reset/:guid/:token"
export const PATH_INVITATION = "/invitation/:guid/:token"
export const PATH_INVITATION_SUCCESS = "/invitation-success"

// micro-frontend
export const PATH_MFE_DASHBOARD = "/dashboard"
export const PATH_MFE_VISITS = "/visits"
export const PATH_MFE_SCHEDULER_CARER_DAILY = "/scheduler-carer-daily"
export const PATH_MFE_SCHEDULER_RUNS = "/scheduler-runs/*"

export const PATH = {
  FINANCE: {
    ALERTS: "/finance/alerts",
    ADDITIONALS_MANAGEMENT: "/finance/additionals-management",
    APPOINTMENT_SEARCH: "/finance/appointment-search",
    FILE_MANAGEMENT: "/finance/file-management",
    IMPORT: "/finance/import",
    INTERFACE_FILES: "/finance/interface-files",
    INVOICE_MODELS: "/finance/contract-models/invoice-models",
    PAYMENT_MODELS: "/finance/contract-models/payment-models",
    INVOICE_PROCESSING: "/finance/invoice-processing",
    PAYMENT_PROCESSING: "/finance/payment-processing",
    PROCESSING_STATUS: "/finance/processing-status"
  }
}
