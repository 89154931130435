import React from "react"
import Button from "./Button"
import testingIds from "constants/testingId"
import { useNavigate } from "react-router-dom"

interface Props {
  disabled?: boolean
  pristine: boolean
  navigateUrl?: string | number
  "data-cy"?: string
}

const CancelButton: React.FC<Props> = ({ disabled = false, pristine, navigateUrl, "data-cy": testingId }) => {
  const navigate = useNavigate()

  const sashayAway = () => {
    const url = navigateUrl ?? -1

    if (typeof url === "string") {
      navigate(url)
    } else if (typeof url === "number") {
      navigate(url)
    }
  }

  const onCancel = () => {
    if (!disabled && pristine) {
      sashayAway()
    }

    if (
      !disabled &&
      !pristine &&
      window.confirm("This form contains unsaved changes, are you sure you want to leave the page?")
    ) {
      sashayAway()
    }
  }

  return (
    <Button
      variant="text"
      fullWidth={false}
      disabled={disabled}
      onClick={onCancel}
      data-cy={testingId || testingIds.cancelButton}
    >
      Cancel
    </Button>
  )
}

export default React.memo(CancelButton)
