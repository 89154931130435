import React, { Dispatch, memo, SetStateAction } from "react"
import { Button } from "components/Form"
import { FormStage } from "constants/form"
import { KeyboardArrowLeft } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"

interface OwnProps {
  isEdit: boolean
  formStage: FormStage
  setFormStage: Dispatch<SetStateAction<FormStage>>
}

export const ContractBackButton: React.FC<OwnProps> = memo(({ isEdit, formStage, setFormStage }) => {
  ContractBackButton.displayName = "ContractBackButton"
  const navigate = useNavigate()

  return (
    <Button
      variant="text"
      fullWidth={false}
      onClick={() => {
        formStage === FormStage.CREATE || isEdit ? navigate(-1) : setFormStage(FormStage.CREATE)
      }}
    >
      <KeyboardArrowLeft /> Back
    </Button>
  )
})
