import React from "react"
import { Box, Typography } from "@mui/material"
import { amber } from "@mui/material/colors"

const inclusiveMainText = "'Ends after' means the date you choose will be the last day included"
const inclusiveSecondaryText = "e.g. if the date selected is the 21st July it will end on the 21st July at midnight"

const exclusiveMainText = "'Ends before' means the date you choose is NOT included"
const exclusiveSecondaryText =
  "e.g. if the date selected is the 21st July it will end on the 20th July at end of the day"

const DatesMessageHelp = ({ messageType }: { messageType: string }) => (
  <Box
    sx={{
      backgroundColor: amber[50],
      display: "flex",
      flexDirection: "column",
      m: 3,
      mx: 0,
      p: 2,
      marginTop: 0,
      marginBottom: 0
    }}
  >
    <Typography>{messageType === "I" ? inclusiveMainText : exclusiveMainText}</Typography>
    <p>{messageType === "I" ? inclusiveSecondaryText : exclusiveSecondaryText}</p>
  </Box>
)

export default React.memo(DatesMessageHelp)
