import { FinanceApi } from "api"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { queryKeys } from "./queries"
import {
  Unit,
  UnitContract,
  UnitRequest,
  UnitShiftCategory,
  UnitShiftCategoryRequest,
  UnitUser,
  UnitUserRequest
} from "./types"
import { useNavigate } from "react-router-dom"
import { useUIStore } from "stores"

export const useUnitCreateMutation = (): UseMutationResult<Unit, Error, { unit: UnitRequest }> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()
  const navigate = useNavigate()

  return useMutation(
    async ({ unit }: { unit: UnitRequest }): Promise<Unit> => {
      return FinanceApi.post("/finance/units", unit)
    },
    {
      onSuccess: (data) => {
        const { guid } = data
        queryCache.setQueryData(queryKeys.getUnitDetailKey(guid), data)
        showSuccessMessage("Unit successfully created.")
        navigate(`/units/${guid}/edit`)
      }
    }
  )
}

export const useUnitUpdateMutation = (): UseMutationResult<Unit, Error, { unit: Unit }> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ unit }: { unit: Unit }): Promise<Unit> => {
      return FinanceApi.put(`/finance/units/${unit.guid}`, unit)
    },
    {
      onSuccess: (data) => {
        const { guid } = data
        queryCache.setQueryData(queryKeys.getUnitDetailKey(guid), data)
        showSuccessMessage("Unit successfully updated.")
      }
    }
  )
}

// clean after cypress
export const useUnitDeleteMutation = () => {
  return useMutation(async (guid: string): Promise<void> => {
    return FinanceApi.delete(`/finance/units/${guid}`)
  })
}

export const useUnitContractDeleteMutation = ({
  unitGuid
}: {
  unitGuid: string
}): UseMutationResult<void, Error, { contractGuid: string }> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)

  return useMutation(
    async ({ contractGuid }: { contractGuid: string }): Promise<void> => {
      return FinanceApi.delete(`/finance/units/${unitGuid}/contracts/${contractGuid}`)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Contract successfully deleted.")
      }
    }
  )
}

// TODO: add unit contract request type
export const useUnitContractPostMutation = ({
  unitGuid
}: {
  unitGuid: string
}): UseMutationResult<UnitContract, Error, { payload: any }> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  return useMutation(
    async ({ payload }: { payload: any }): Promise<UnitContract> => {
      return FinanceApi.post(`/finance/units/${unitGuid}/contracts`, payload)
    },
    {
      onSuccess: (data) => {
        showSuccessMessage("Contract successfully created.")
        navigate(`/units/${unitGuid}/contracts/${data.guid}`)
      }
    }
  )
}

export const useUnitContractPutMutation = ({
  unitGuid
}: {
  unitGuid: string
}): UseMutationResult<UnitContract, Error, { contractGuid: string; payload: any }> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ contractGuid, payload }: { contractGuid: string; payload: any }): Promise<UnitContract> => {
      return FinanceApi.put(`/finance/units/${unitGuid}/contracts/${contractGuid}`, payload)
    },
    {
      onSuccess: (data) => {
        queryCache.setQueryData(queryKeys.getUnitContractDetailKey({ unitGuid, contractGuid: data.guid }), data)
        showSuccessMessage("Contract successfully edited.")
      }
    }
  )
}

export const useUnitUserDeleteMutation = ({
  unitGuid
}: {
  unitGuid: string
}): UseMutationResult<void, Error, { userGuid: string }> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)

  return useMutation(
    async ({ userGuid }: { userGuid: string }): Promise<void> => {
      return FinanceApi.delete(`/finance/units/${unitGuid}/users/${userGuid}`)
    },
    {
      onSuccess: () => {
        showSuccessMessage("User successfully deleted.")
      }
    }
  )
}

export const useUnitUserCreateMutation = ({
  unitGuid
}: {
  unitGuid: string
}): UseMutationResult<UnitUser, Error, { user: UnitUserRequest }> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  return useMutation(
    async ({ user }: { user: UnitUserRequest }): Promise<UnitUser> => {
      return FinanceApi.post(`/finance/units/${unitGuid}/users`, user)
    },
    {
      onSuccess: (data) => {
        showSuccessMessage("User successfully created.")
        navigate(`/units/${unitGuid}/users/${data.guid}`)
      }
    }
  )
}

export const useUnitUserEditMutation = ({
  unitGuid
}: {
  unitGuid: string
}): UseMutationResult<UnitUser, Error, { user: UnitUser }> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ user }: { user: UnitUser }): Promise<UnitUser> => {
      return FinanceApi.put(`/finance/units/${unitGuid}/users/${user.guid}`, user)
    },
    {
      onSuccess: (data) => {
        queryCache.setQueryData(queryKeys.getUnitUserDetailKey({ unitGuid, userGuid: data.guid }), data)
        showSuccessMessage("User successfully edited.")
      }
    }
  )
}

export const useUnitShiftCategoryDeleteMutation = ({
  unitGuid
}: {
  unitGuid: string
}): UseMutationResult<void, Error, { guid: string }> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)

  return useMutation(
    async ({ guid }: { guid: string }): Promise<void> => {
      return FinanceApi.delete(`/finance/units/${unitGuid}/shift-categories/${guid}`)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Shift category successfully deleted.")
      }
    }
  )
}

export const useUnitShiftCategoryCreateMutation = ({
  unitGuid
}: {
  unitGuid: string
}): UseMutationResult<UnitShiftCategory, Error, { shiftCategory: UnitShiftCategoryRequest }> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  return useMutation(
    async ({ shiftCategory }: { shiftCategory: UnitShiftCategoryRequest }): Promise<UnitShiftCategory> => {
      return FinanceApi.post(`/finance/units/${unitGuid}/shift-categories`, shiftCategory)
    },
    {
      onSuccess: (data) => {
        showSuccessMessage("Shift category successfully created.")
        navigate(`/units/${unitGuid}/shift-categories/${data.guid}`)
      }
    }
  )
}

export const useShiftCategoryUserEditMutation = ({
  unitGuid
}: {
  unitGuid: string
}): UseMutationResult<UnitShiftCategory, Error, { shiftCategory: UnitShiftCategory }> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ shiftCategory }: { shiftCategory: UnitShiftCategory }): Promise<UnitShiftCategory> => {
      return FinanceApi.put(`/finance/units/${unitGuid}/shift-categories/${shiftCategory.guid}`, shiftCategory)
    },
    {
      onSuccess: (data) => {
        queryCache.setQueryData(queryKeys.getUnitShiftCategoryDetailKey({ unitGuid, guid: data.guid }), data)
        showSuccessMessage("Shift category successfully edited.")
      }
    }
  )
}
