import { SelectOption } from "components/Form/Select"
import { useHolidayTierListQuery } from "data/finance/holidayTier/queries"
import { HolidayTier } from "data/finance/holidayTier/types"
import { useSchema } from "data/schema/useSchema"
import { useMemo } from "react"

export const useGetRateDayTypeOptions = (): {
  dayTypeOptions: SelectOption[]
  isLoading: boolean
  holidayTiers?: HolidayTier[]
} => {
  const { Day = [] } = useSchema(["Day"])

  const { data: holidayTiers, isLoading } = useHolidayTierListQuery()

  const dayTypeOptions = useMemo(() => {
    const order = ["WEEK", "W/DAY", "W/END", "MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]

    return [
      ...Day.filter((option: SelectOption) => option.value !== "HOL").sort(
        (a, b) => order.indexOf(a.value) - order.indexOf(b.value)
      ),
      ...(holidayTiers || []).map((ht) => ({ title: ht.title, value: ht.guid } as SelectOption))
    ]
  }, [Day, holidayTiers])

  return { dayTypeOptions, holidayTiers, isLoading }
}
