import {
  useInvoiceTransactionChargeCreateMutation,
  useInvoiceTransactionChargeUpdateMutation
} from "data/finance/invoiceProcessing/mutations"
import { useCallback } from "react"
import { AdditionalType } from "data/finance/additional/type"
import { findAdditionalByType } from "../helpers/findAdditionalByType"
import { ExtrasFormValues } from "pages/Finance/components/ExtrasModal"
import { useQueryClient } from "react-query"
import { queryKeys } from "data/finance/invoiceProcessing/queries"
import { useUIStore } from "stores"
import { InvoiceTransaction, InvoiceTransactionCharge } from "data/finance/invoiceProcessing/types"
import { CalendarPeriod } from "data/finance/contractModel/types"

interface SubmitHandlerProps {
  extraType?: AdditionalType
  setExtraType: (type?: AdditionalType) => void
  charge?: InvoiceTransactionCharge | null
  transaction: InvoiceTransaction
  period: CalendarPeriod
  destroyModal: () => void
  refetchTransaction: () => void
  additionalChargeTypes?: AdditionalType[]
}

const useInvoiceExtrasSubmitHandler = ({
  extraType,
  setExtraType,
  charge,
  transaction,
  period,
  destroyModal,
  refetchTransaction,
  additionalChargeTypes
}: SubmitHandlerProps): ((values: ExtrasFormValues) => Promise<void>) => {
  const { mutateAsync: createCharge } = useInvoiceTransactionChargeCreateMutation()
  const { mutateAsync: updateCharge } = useInvoiceTransactionChargeUpdateMutation()

  const queryCache = useQueryClient()
  const showErrorMessage = useUIStore((state) => state.showErrorMessage)

  return useCallback(
    async (values: ExtrasFormValues) => {
      if (!extraType && values.charge_type_alias) {
        const foundItem = findAdditionalByType({ additionalChargeTypes, charge_type_alias: values.charge_type_alias })

        setExtraType(foundItem)
        return
      }

      try {
        if (charge) {
          await updateCharge({
            charge: values,
            transactionGuid: transaction.guid,
            periodGuid: period.guid,
            chargeGuid: charge.guid
          })
        } else {
          await createCharge({ charge: values, transactionGuid: transaction.guid, periodGuid: period.guid })
        }

        destroyModal()

        queryCache.invalidateQueries(
          queryKeys.getInvoiceProcessingTransactionChargeListKey({
            transactionGuid: transaction.guid,
            periodGuid: period.guid
          })
        )
        refetchTransaction()
      } catch (apiError: any) {
        showErrorMessage(`Could not ${charge ? "edit" : "create"} charge.`, { apiError })
      }
    },
    [
      extraType,
      setExtraType,
      charge,
      transaction,
      period,
      destroyModal,
      createCharge,
      updateCharge,
      refetchTransaction,
      additionalChargeTypes,
      queryCache,
      showErrorMessage
    ]
  )
}

export default useInvoiceExtrasSubmitHandler
