import React, { memo } from "react"
import { Field, FormSpy, FormSpyRenderProps } from "react-final-form"
import { Checkbox, TextField } from "components/Form"
import Select, { SelectOption } from "components/Form/Select"
import { ApiUserCarer } from "models"
import { Grid } from "@mui/material"
import validators from "lib/validators"
import { DateField } from "components/Form/DateField"
import TrainingField from "../components/TrainingField"
import { CarerProfileEmploymentType } from "./CarerProfileEmploymentType"
import { SchemaFieldOption } from "lib/types"
import { CarerOnboardingStatus } from "data/mobile/types"

interface Props {
  mode: "create" | "update"
  isEx: boolean
  enableAgencyIndicator: boolean
  enableCarerExit: boolean
  enableGivenNotice: boolean
  carerOnboardingStatus?: CarerOnboardingStatus
  leavingReasons?: SelectOption[]
  exitInterviewPreferences?: SelectOption[]
  statusOptions: SchemaFieldOption[]
}

export const CarerProfileSectionStatus: React.FC<Props> = memo(
  ({
    mode,
    isEx,
    enableAgencyIndicator,
    enableCarerExit,
    enableGivenNotice,
    carerOnboardingStatus,
    leavingReasons,
    exitInterviewPreferences,
    statusOptions
  }) => {
    return (
      <FormSpy subscription={{ values: true }}>
        {({ values }: FormSpyRenderProps<ApiUserCarer>) => {
          return (
            <Grid container spacing={8}>
              <Grid item md={6}>
                <Field
                  name="status"
                  label="Status *"
                  component={Select}
                  options={statusOptions}
                  validate={validators.required}
                />
                {mode === "update" && !!carerOnboardingStatus && (
                  <TrainingField carerOnboardingStatus={carerOnboardingStatus} />
                )}

                <CarerProfileEmploymentType enableAgencyIndicator={enableAgencyIndicator} />

                {carerOnboardingStatus?.step === 6 && (
                  <Field
                    name="reset_onboarding_status"
                    label="Reset training when I save"
                    type="checkbox"
                    component={Checkbox}
                  />
                )}
              </Grid>
              <Grid item md={6}>
                <DateField name="hr.start_date" label="Start date" required />
                {enableCarerExit && isEx && (
                  <DateField
                    name="carerProfileData.resignation_date"
                    label="Resignation date"
                    required
                    validate={validators.mustBeLaterDateTime(values.hr.start_date, "Start date")}
                  />
                )}
              </Grid>
              {isEx && (
                <Grid item md={6}>
                  {enableGivenNotice && (
                    <>
                      <DateField name="hr.leaving_date" label="Leaving date" required />
                      <Field
                        name="hr.leaving_reason"
                        label="Leaving Reason"
                        component={Select}
                        options={leavingReasons}
                        validate={validators.required}
                      />
                      <Field
                        name="hr.leaving_notes"
                        label="Leaving notes *"
                        component={TextField}
                        validate={validators.required}
                      />
                    </>
                  )}
                  {enableCarerExit && (
                    <Field
                      name="carerProfileData.contact_about_future_roles"
                      label="Contact for future roles *"
                      component={Select}
                      options={[
                        { title: "Yes", value: true },
                        { title: "No", value: false }
                      ]}
                      validate={validators.required}
                    />
                  )}
                </Grid>
              )}

              {isEx && (
                <Grid item md={6}>
                  {enableGivenNotice && (
                    <Field
                      name="hr.leaving_location_after"
                      label="Location after leaving *"
                      component={TextField}
                      validate={validators.required}
                    />
                  )}
                  {enableCarerExit && (
                    <Field
                      name="carerProfileData.exit_interview_preference_id"
                      label="Preference for Exit Interview *"
                      component={Select}
                      options={exitInterviewPreferences}
                      validate={validators.required}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          )
        }}
      </FormSpy>
    )
  }
)

CarerProfileSectionStatus.displayName = "CarerProfileSectionStatus"
