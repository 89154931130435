import { ContentContainer, Header, Link } from "components"
import { useOperatorDetailQuery } from "data/core/operator/queries"
import React from "react"
import OperatorProfile from "./OperatorProfile"
import { Navigate, Route, useParams } from "react-router-dom"
import SentryRoutes from "components/Routes"

const OperatorDetail: React.FC = () => {
  const { id } = useParams()
  const { data: operator } = useOperatorDetailQuery({ id })

  if (!operator) return null

  return (
    <ContentContainer>
      <Header
        title={
          operator ? (
            <>
              <Link to="/admin/operators">Operators</Link> - {operator.profile.name}
            </>
          ) : (
            "Not Found"
          )
        }
      />
      <SentryRoutes>
        <Route
          path="/"
          element={<Navigate replace to={`/admin/operators/${operator.guid}/profile/personal-details`} />}
        />
        <Route path="profile/:section" element={<OperatorProfile mode="update" operator={operator} />} />
      </SentryRoutes>
    </ContentContainer>
  )
}

export default OperatorDetail
