export const recalcTypes = [
  "ProcessTimeOff",
  "ProcessTraining",
  "ProcessInvoiceOutputs",
  "ProcessPaymentOutputs",
  "ProcessInvoiceTasks",
  "ProcessPaymentTasks",
  "ProcessContributions",
  "ProcessShifts",
  "ProcessVisits",
  "ProcessStandingCharges",
  "ProcessMinimumWages",
  "ProcessAccruals",
  "ProcessAbsence"
]
