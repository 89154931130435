import { ContentContainer, DataLoading } from "components"
import React from "react"
import { MicroFrontend, VisitDetail } from ".."
import CarerDocuments from "./CarerDocuments"
import CarerProfile from "./CarerProfile"
import CarerContractsList from "./CarerContractsList"
import { CarerContractDetail } from "./CarerContractDetail"
import { CarerPayslipList } from "./CarerPayslip/CarerPayslipList"
import Config from "lib/config"
import { CarerHeader } from "./components/CarerHeader"
import { CarerDetailNavBar } from "./components/CarerDetailNavBar"
import { CarerAccrual } from "./CarerAccrual"
import { useCarerQuery } from "data/core/carer/queries"
import Auth from "lib/Auth"
import { PermissionGroup } from "constants/permission"
import { Navigate, Route, useLocation, useParams } from "react-router-dom"
import { useAuthStore } from "stores"
import useBranchSettings from "lib/hooks/branchInfo/useBranchSettings"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { BranchFeatures } from "lib/hooks/branchInfo/types"
import SentryRoutes from "components/Routes"

// The Carer Detail Header is deprecated
const SCHEDULER_PAGES = ["scheduler-carer-weekly"]
const PAGES_WITHOUT_DCP_HEADER = [...SCHEDULER_PAGES, "carer-absences", "employment-identity"]

const CarerDetail: React.FC = () => {
  const { id } = useParams()
  const location = useLocation()
  const isScheduler = location && SCHEDULER_PAGES.some((page) => location.pathname.includes(page))
  const shouldShowCarerHeader = PAGES_WITHOUT_DCP_HEADER.every((pageUrl) => !location?.pathname.includes(pageUrl))
  const isReducedPaddingView = !!isScheduler
  const user = useAuthStore((state) => state.user)
  const envVarSchedulerUrl = Config.getEnvVariable("APP_SCHEDULER_URL")
  const envVarVisitsUrl = Config.getEnvVariable("APP_VISITS_URL")
  const envVarCarerAvailabilityUrl = Config.getEnvVariable("APP_CARER_AVAILABILITY_URL")
  const envVarFinanceMfeUrl = Config.getEnvVariable("APP_WEB_FINANCE_URL")
  const { hasBranchPermission, isLoading: isLoadingBranchData, isFetching } = useBranchPermissions()
  const hasCarerProfileAvailabilityFlag = hasBranchPermission(BranchFeatures.CARER_PROFILE_AVAILABILITY)
  const hasSchedulerFlag = hasBranchPermission(BranchFeatures.SCHEDULER)

  const { settings: branchSettings } = useBranchSettings()
  const { FeatureFinanceTeamCarerEmployment } = branchSettings
  const hasCarerEmploymentFlag = FeatureFinanceTeamCarerEmployment?.toLowerCase() === "true"
  const isFinanceAdmin = Auth.hasPermissionGroup(PermissionGroup.FINANCE_ADMIN)
  const showEmploymentPage = hasCarerEmploymentFlag && isFinanceAdmin

  const { data: carer, isLoading } = useCarerQuery({ id })

  if (isLoading || isLoadingBranchData || isFetching) {
    return <DataLoading />
  }

  if (!carer) {
    return "Carer not found"
  }

  return (
    <>
      <CarerDetailNavBar {...{ carer, hasCarerProfileAvailabilityFlag, hasSchedulerFlag }} />

      <ContentContainer
        sx={
          isReducedPaddingView
            ? {
                padding: 0
              }
            : undefined
        }
      >
        {shouldShowCarerHeader && <CarerHeader {...{ isReducedPaddingView, carer }} />}
        <SentryRoutes>
          <Route path="/" element={<Navigate replace to={`/carers/${carer.guid}/profile/personal-details`} />} />

          <Route path="/profile/:section" element={<CarerProfile mode="update" carer={carer} />} />

          <Route path="/contracts" element={<CarerContractsList carer={carer} />} />
          <Route path="/contracts/new" element={<CarerContractDetail carer={carer} />} />
          <Route path="/contracts/:contractId" element={<CarerContractDetail carer={carer} />} />

          <Route
            path="/carer-absences"
            element={<MicroFrontend carer={carer} name="Finance" host={envVarFinanceMfeUrl} loggedUser={user} />}
          />

          <Route
            path="/carer-absences/add"
            element={<MicroFrontend carer={carer} name="Finance" host={envVarFinanceMfeUrl} loggedUser={user} />}
          />

          <Route
            path="/carer-absences/:absenceId/edit"
            element={<MicroFrontend carer={carer} name="Finance" host={envVarFinanceMfeUrl} loggedUser={user} />}
          />

          {showEmploymentPage && (
            <Route
              path="/employment-identity"
              element={<MicroFrontend carer={carer} name="Finance" host={envVarFinanceMfeUrl} loggedUser={user} />}
            />
          )}

          {hasCarerProfileAvailabilityFlag && (
            <Route
              path="/availability"
              element={
                <MicroFrontend
                  client={carer}
                  loggedUser={user}
                  name="CarerAvailability"
                  host={envVarCarerAvailabilityUrl}
                />
              }
            />
          )}

          <Route path="/accruals/*" element={<CarerAccrual carer={carer} />} />

          <Route path="/payslips" element={<CarerPayslipList />} />

          <Route
            path="/visits"
            element={<MicroFrontend name="Visits" carer={carer} host={envVarVisitsUrl} loggedUser={user} />}
          />

          <Route path="/visits/detail/:id" element={<VisitDetail user={carer} isCarer={true} />} />

          <Route path="/documents" element={<CarerDocuments />} />

          {hasSchedulerFlag && (
            <Route
              path="/scheduler-carer-weekly"
              element={<MicroFrontend carer={carer} loggedUser={user} name="Scheduler" host={envVarSchedulerUrl} />}
            />
          )}
        </SentryRoutes>
      </ContentContainer>
    </>
  )
}

export default CarerDetail
