import React, { useState } from "react"
import { Button } from "./Form"
import { Box, List, ListItemButton, ListItemText, Modal, Paper, SxProps, Theme, Typography } from "@mui/material"
import { geocodeAddress } from "../lib/geocoding"
import { FormApi } from "final-form"
import { useUIStore } from "stores"

interface Props {
  address: string
  form?: FormApi<any>
  handleCustomFormChange?: Function
  buttonProps?: {
    sx?: SxProps<Theme>
  }
}

export type GeocodeAddress = {
  address_components: { long_name: string; short_name: string; types: string[] }[]
  formatted_address: string
  geometry: { location: { lat: number; lng: number }; [key: string]: any }
  partial_match?: boolean
  place_id: string
  types: string[]
}

const AddressSearch = ({ address, form, handleCustomFormChange, buttonProps }: Props) => {
  const showWarningMessage = useUIStore((state) => state.showWarningMessage)
  const [isModalOpen, setModalOpen] = useState(false)
  const [results, setResults] = useState<GeocodeAddress[]>()

  const handleClick = async () => {
    const response = await geocodeAddress(address)
    if (response.status === "OK") {
      setResults(response.results)
      setModalOpen(true)
    } else {
      showWarningMessage("We were unable to find a valid address for this postcode")
    }
  }

  return (
    <>
      <Button fullWidth={false} onClick={handleClick} {...buttonProps}>
        Search address
      </Button>
      <AddressModal
        isOpen={isModalOpen}
        destroyModal={() => setModalOpen(false)}
        form={form}
        results={results}
        handleCustomFormChange={handleCustomFormChange}
      />
    </>
  )
}
export default AddressSearch

interface ModalProps {
  destroyModal: Function
  handleCustomFormChange?: Function
  results?: GeocodeAddress[]
  form?: FormApi<any>
  isOpen: boolean
}

const AddressModal = ({ isOpen, destroyModal, handleCustomFormChange, results, form }: ModalProps) => {
  const handleModalClose = () => destroyModal()

  if (!isOpen) return null

  return (
    <Modal
      aria-labelledby="modal-wrapper"
      aria-describedby="modal-wrapper-action"
      open={isOpen}
      onClose={handleModalClose}
    >
      <Paper
        sx={(theme) => ({
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          width: 400,
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(3, 4, 2),
          outline: 0
        })}
      >
        <Box sx={{ p: 1, lineHeight: 1.5 }}>
          <Typography variant="h5">Select an address</Typography>
          <List component="nav">
            {results?.map((item: GeocodeAddress, key: number) => (
              <ListItemButton
                key={`${item.place_id}-${key}`}
                onClick={() => {
                  if (handleCustomFormChange) {
                    handleCustomFormChange(item)
                    return
                  }

                  if (!form) return
                  form.change("profile.address", item.formatted_address)
                  item.address_components.forEach((address) => {
                    if (address.types.indexOf("postal_code") !== -1) {
                      form.change("profile.address_post_code", address.long_name)
                    }
                  })

                  if (item.geometry) {
                    const { lat, lng } = item.geometry.location
                    form.change("profile.address_latitude", lat)
                    form.change("profile.address_longitude", lng)
                  }
                  destroyModal()
                }}
              >
                <ListItemText primary={item.formatted_address} />
              </ListItemButton>
            ))}
          </List>
          <Button variant="text" onClick={() => destroyModal()}>
            Cancel
          </Button>
        </Box>
      </Paper>
    </Modal>
  )
}
