import React, { FC, useMemo } from "react"
import { Box, Tooltip } from "@mui/material"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import RemoveIcon from "@mui/icons-material/Remove"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import Typography from "@mui/material/Typography"
import { TREND } from "../Enums"

interface Props {
  trend: TREND.UP | TREND.DOWN | TREND.STABLE | TREND.UNKNOWN
}

const Trend: FC<Props> = ({ trend }) => {
  const trendChunks = useMemo((): { Icon: JSX.Element; label: string; tooltip: string } => {
    if (trend === TREND.UP) {
      return {
        Icon: (
          <ArrowUpwardIcon
            sx={(theme) => ({
              color: theme.palette.action.active
            })}
          />
        ),
        label: "Increasing Risk",
        tooltip: "Falls risk score has risen since the previous days score"
      }
    }

    if (trend === TREND.DOWN) {
      return {
        Icon: (
          <ArrowDownwardIcon
            sx={(theme) => ({
              color: theme.palette.action.active
            })}
          />
        ),
        label: "Decreasing Risk",
        tooltip: "Falls risk score has dropped since the previous days score"
      }
    }

    return {
      Icon: (
        <RemoveIcon
          sx={(theme) => ({
            color: theme.palette.action.active
          })}
        />
      ),
      label: "No Change",
      tooltip: "Falls risk score has remained the same as yesterday’s score"
    }
  }, [trend])

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        gap: theme.spacing(1),
        alignItems: "center"
      })}
    >
      {trendChunks.Icon}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "2px"
        }}
      >
        <Typography variant="body1">{trendChunks.label}</Typography>
        <Tooltip
          arrow
          title={<Typography variant="caption">{trendChunks.tooltip}</Typography>}
          placement="top"
          sx={{
            width: 180
          }}
        >
          <ErrorOutlineIcon
            sx={(theme) => ({
              fontSize: "0.85rem",
              color: theme.palette.action.active
            })}
          />
        </Tooltip>
      </Box>
    </Box>
  )
}

export default Trend
