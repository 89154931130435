import { AppointmentType } from "constants/appointmentType"
import { ContractModelType } from "constants/modelTypes"
import { RelationType } from "constants/relationType"
import { InvoiceTransactionCharge, InvoiceTransactionChargeType } from "data/finance/invoiceProcessing/types"
import { PaymentTransactionCharge } from "data/finance/paymentProcessing/types"
import Auth from "lib/Auth"
import { isNil } from "ramda"
import { useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const useProcessingExtraRowClick = ({
  filteredCharges,
  modelType
}: {
  filteredCharges: (PaymentTransactionCharge | InvoiceTransactionCharge)[]
  modelType: ContractModelType
}): (({ rowIdx, setModal }: { rowIdx: number; setModal?: (() => void) | undefined }) => void | undefined) => {
  const location = useLocation()
  const navigate = useNavigate()
  const canEditContribution = Auth.hasPermission(["User.Operator:Edit", "Finance.Settings:Edit"])

  return useCallback(
    ({ rowIdx, setModal }: { rowIdx: number; setModal?: () => void }) => {
      const {
        appointment_type,
        transaction_item_guid: transactionItemGuid,
        relation_guid: visitGuid,
        relation_type,
        is_calculated,
        charge_type_alias,
        guid,
        processed_at
      } = filteredCharges[rowIdx]

      if (modelType === ContractModelType.INVOICE) {
        if (
          charge_type_alias === InvoiceTransactionChargeType.CONTRIB &&
          canEditContribution &&
          !processed_at &&
          setModal
        ) {
          setModal()
        }
      }

      if (modelType === ContractModelType.PAYMENT) {
        if (charge_type_alias === "HOLIDAY" && is_calculated) {
          return navigate(`${location.pathname}/${guid}/holiday-details`)
        }
      }

      if (isNil(location) || isNil(transactionItemGuid)) {
        return
      }

      if (appointment_type === AppointmentType.VISIT) {
        return navigate(`${location.pathname}/${transactionItemGuid}`)
      }
      if (appointment_type === AppointmentType.SHIFT && relation_type === RelationType.VISIT) {
        return navigate(`${location.pathname}/shift/${transactionItemGuid}/${visitGuid}`)
      }
      if (appointment_type === AppointmentType.SHIFT && relation_type === RelationType.SHIFT) {
        return navigate(`${location.pathname}/shift/${transactionItemGuid}`)
      }
    },
    [filteredCharges, location, canEditContribution, modelType, navigate]
  )
}
