import { FinanceApi } from "api"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { ContractRuleCondition, ContractRuleConditionRequest } from "./types"
import { queryKeys } from "./queries"
import { ApiError } from "stores/useUIStore/types"
import { useNavigate } from "react-router-dom"
import { useApiMessage } from "stores"

export const useContractRuleConditionUpdateMutation = ({
  guid
}: {
  guid?: string
}): UseMutationResult<ContractRuleCondition, ApiError, ContractRuleConditionRequest> => {
  const queryCache = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async (values: ContractRuleConditionRequest): Promise<ContractRuleCondition> => {
      return FinanceApi.put(`/finance/contract-rule-conditions/${guid}`, values)
    },
    {
      onSuccess: (data) => {
        showSuccessMessage("Rule condition successfully updated.")
        queryCache.setQueryData(queryKeys.getContractRuleConditionItem(guid), data)
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not update Rule condition.", { apiError })
      }
    }
  )
}

export const useContractRuleConditionCreateMutation = (): UseMutationResult<
  ContractRuleCondition,
  ApiError,
  ContractRuleConditionRequest
> => {
  const queryCache = useQueryClient()
  const navigate = useNavigate()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async (values: ContractRuleConditionRequest): Promise<ContractRuleCondition> => {
      return FinanceApi.post(`/finance/contract-rule-conditions`, values)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Rule condition successfully created.")
        navigate("/admin/finance/contract-rule-conditions")
        queryCache.invalidateQueries(queryKeys.getContractRuleConditionList())
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not create Rule condition.", { apiError })
      }
    }
  )
}

// clean after cypress
export const useContractRuleConditionDeleteMutation = () => {
  return useMutation(async (guid: string): Promise<void> => {
    return FinanceApi.delete(`/finance/contract-rule-conditions/${guid}`)
  })
}
