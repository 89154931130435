import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import { FormSectionHeader } from "components/Form"
import { useAppointmentAssigneeDetailQuery } from "data/finance/appointment/queries"
import React, { Dispatch, memo, ReactNode, SetStateAction, useMemo, useState } from "react"
import { Edit, Warning } from "@mui/icons-material"
import { VisitDetailVariationModal } from "./VisitDetailVariationModal"
import Auth from "lib/Auth"
import { AppointmentAssignee } from "data/finance/appointment/types"
import testingId from "constants/testingId"
import { GUID_ZERO } from "constants/guid"
import { isNil } from "ramda"
import { WithPermission } from "components/Auth"
import { Permission } from "constants/permission"
import { RecalculateButton } from "components/RecalculateButton"

interface OwnProps {
  appointmentGuid: string
  userAppointmentGuid?: string
}

const EditButton = ({
  id,
  assignee,
  destroyModal,
  setModal,
  hasValidModel
}: {
  id: string
  assignee: AppointmentAssignee
  destroyModal: () => void
  setModal: Dispatch<SetStateAction<ReactNode>>
  hasValidModel?: boolean
}) =>
  Auth.hasPermission(["Finance.Appointment:Edit"]) ? (
    <IconButton
      id={id}
      data-cy={testingId.visit.detail.variation.editButton}
      size="small"
      onClick={() => {
        setModal(<VisitDetailVariationModal {...{ assignee, destroyModal, hasValidModel }} />)
      }}
    >
      <Edit />
    </IconButton>
  ) : null

export const VisitDetailVariation: React.FC<OwnProps> = memo(({ appointmentGuid, userAppointmentGuid }) => {
  const { data: assignee } = useAppointmentAssigneeDetailQuery({
    appointmentGuid,
    userAppointmentGuid: userAppointmentGuid || GUID_ZERO
  })

  const [modal, setModal] = useState<ReactNode>()

  const destroyModal = () => {
    setModal(undefined)
  }

  const hasValidModel = useMemo(() => {
    if (!assignee?.contract_guid) return undefined
    return assignee.available_contracts.map((item) => item.guid).includes(assignee.contract_guid)
  }, [assignee])

  if (!assignee) return null

  return (
    <Box my={5} data-cy={testingId.visit.detail.variation.section}>
      <FormSectionHeader
        title="Confirmation, Variation & Service"
        action={
          <EditButton
            id="edit-confirmation-variation-service-btn"
            assignee={assignee}
            destroyModal={destroyModal}
            setModal={setModal}
            hasValidModel
          />
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={2.2}>
          <Typography variant="body2" color="textSecondary" component="h6">
            Reference
          </Typography>
          <Typography variant="body1" data-cy={testingId.visit.detail.variation.reference}>
            {assignee.appointment_reference || "No Reference"}
          </Typography>
        </Grid>
        <Grid item xs={2.2}>
          <Typography variant="body2" color="textSecondary" component="h6">
            Invoice Model
          </Typography>
          <Typography variant="body1" data-cy={testingId.visit.detail.variation.model}>
            {assignee.contract_title || "Unassigned"}
            {!isNil(hasValidModel) && !hasValidModel ? (
              <Tooltip title="The contract is not valid for this user">
                <Warning
                  sx={(theme) => ({
                    color: theme.palette.warning.light,
                    verticalAlign: "text-bottom",
                    marginLeft: "5px"
                  })}
                />
              </Tooltip>
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={2.2}>
          <Typography variant="body2" color="textSecondary" component="h6">
            Variation
          </Typography>
          <Typography variant="body1" data-cy={testingId.visit.detail.variation.variation}>
            {assignee.variation_title}
          </Typography>
        </Grid>
        <Grid item xs={2.2}>
          <Typography variant="body2" color="textSecondary" component="h6">
            Service
          </Typography>
          <Typography variant="body1" data-cy={testingId.visit.detail.variation.service}>
            {assignee.service_title}
          </Typography>
        </Grid>
        <Grid item xs={2.2}>
          <Typography variant="body2" color="textSecondary" component="h6">
            Status
          </Typography>
          <Typography variant="body1" data-cy={testingId.visit.detail.variation.status}>
            {assignee.confirmed ? "Confirmed" : "Unconfirmed"}
          </Typography>
        </Grid>
        <WithPermission permissions={[Permission.FINANCE_RECALCULATE_EDIT]}>
          <Grid item xs={1}>
            <RecalculateButton
              type={assignee.appointment_reference?.includes("V") ? "VISIT" : "SHIFT"}
              relation_guids={[assignee.guid]}
            />
          </Grid>
        </WithPermission>
      </Grid>
      {modal ? modal : null}
    </Box>
  )
})

VisitDetailVariation.displayName = "VisitDetailVariation"
