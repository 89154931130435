import React, { ReactNode } from "react"
import { Typography } from "@mui/material"
import testingId from "constants/testingId"

interface Props {
  children: ReactNode
}

const WarningMessage = ({ children }: Props) => {
  return (
    <Typography
      variant="caption"
      component="span"
      sx={(theme) => ({
        color: theme.palette.warning.dark,
        marginLeft: "14px",
        marginBottom: "7px",
        display: "inline-flex"
      })}
      data-cy={testingId.warningMessage}
    >
      {children}
    </Typography>
  )
}

export default WarningMessage
