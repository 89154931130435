import React, { FC, useEffect, useRef } from "react"
import { Box } from "@mui/material"
import { alpha } from "@mui/material/styles"
import useGoogleMaps from "./useGoogleMaps"
import { Coordinates } from "./types"
import pinIcon from "../../assets/images/maps/pin_red.svg"
import useGoogleMapsStore from "stores/useGoogleMapsStore"

const INITIAL_MAP_ZOOM = 17

export interface MapPreviewMarker {
  buffer?: {
    strokeColor: string
    strokeWeight: number
    strokeOpacity: number
    fillColor: string
    fillOpacity: number
    radius: number
  }
  coordinates: { lat: number; lng: number }
  icon?: {
    url?: string
    scaledSize?: number[]
    origin?: number[]
    anchor?: number[]
  }
}

interface MapPreviewProps {
  mapId: string
  markers?: MapPreviewMarker[]
  center?: google.maps.LatLngLiteral
  hasZoomControl?: boolean
  hasMapTypeControl?: boolean
  hasDraggableMap?: boolean
  onChangeMapCenter?: (coordinates: Coordinates) => void
  noMarkersMessageComponent?: React.ReactNode
}

const MapPreview: FC<MapPreviewProps> = ({
  markers,
  center,
  hasZoomControl = false,
  hasMapTypeControl = false,
  hasDraggableMap = false,
  onChangeMapCenter,
  noMarkersMessageComponent,
  mapId
}) => {
  const isMapsLoaded = useGoogleMapsStore((state) => state.isLoaded)
  const mapWrapperRef = useRef<HTMLDivElement>(null)
  const { createMap } = useGoogleMaps()

  useEffect(() => {
    if (!mapWrapperRef.current || !isMapsLoaded) {
      return
    }

    createMap({
      mapDomElement: mapWrapperRef.current,
      initialZoom: INITIAL_MAP_ZOOM,
      isDraggable: hasDraggableMap,
      hasZoomControl,
      hasMapTypeControl,
      mapCenter: center,
      markers: markers,
      onChangeMapCenter,
      mapId
    })
  }, [
    createMap,
    center,
    markers,
    hasDraggableMap,
    hasMapTypeControl,
    hasZoomControl,
    isMapsLoaded,
    onChangeMapCenter,
    mapId
  ])

  return (
    <Box sx={{ position: "relative" }}>
      <div>
        {hasDraggableMap && (
          <img
            src={pinIcon}
            alt="Pin"
            height={64}
            width={64}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, calc(-50% - 32px))",
              zIndex: 99
            }}
          />
        )}
        <Box
          ref={mapWrapperRef}
          sx={(theme) => ({
            height: 300,
            backgroundColor: alpha(theme.palette.common.black, 0.12),
            borderRadius: theme.spacing(1),
            margin: theme.spacing(2, 0, 1, 0),
            ...(noMarkersMessageComponent
              ? {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f5f5f5",
                  border: `1px solid ${theme.palette.divider}`
                }
              : {}),
            ...(hasDraggableMap
              ? {
                  margin: 0,
                  borderRadius: 0,
                  [theme.breakpoints.up("md")]: { height: 400 },
                  [theme.breakpoints.up("lg")]: { height: 500 },
                  [theme.breakpoints.up("xl")]: { height: 600 }
                }
              : {})
          })}
        />
        {noMarkersMessageComponent}
      </div>
    </Box>
  )
}

export default MapPreview
