export enum MessageType {
  Error = "error",
  Warning = "warning",
  Success = "success"
}

export interface UIMessage {
  readonly message: string
  readonly action?: string
  readonly type: MessageType
  readonly hideDuration?: number
}

export interface UIState {
  readonly sidebar: {
    isDetailSidebarPresent: boolean
    isOpen: boolean
  }
  readonly message?: UIMessage
}

export interface ErrorRequest {
  code: number
  title: string
  description?: string
  instance?: string
}

export type ErrorResponse = Omit<ErrorRequest, "code">

export interface ApiError {
  status: number
  statusText?: string
  response?: {
    data?: {
      detail?: string
      errors?: Record<string, string | string[]> // standard
      exception?: { message?: string } // unusual APIs
      extensions?: { error?: { message: string } } // unusual APIs
      message?: string
      status: number
      title?: string
      traceId?: string
      type?: string
    }
  }
  data?: { exception?: { message?: string } } // request error
}

export interface UIStore extends UIState {
  toggleSidebar: () => void
  setDetailSidebar: (value: boolean) => void
  showWarningMessage: (message: string, action?: string) => void
  showSuccessMessage: (message: string, action?: string) => void
  showErrorMessage: (message: string, rest?: { action?: string; apiError?: ApiError }) => void
}
