import { Box, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { Unit } from "data/finance/unit/types"
import React, { memo } from "react"
import { useNavigate } from "react-router-dom"

const headers: string[] = ["Unit Details", "Reference", "Email", "Phone number", "Address"]

interface OwnProps {
  units: Unit[]
}

export const UnitListTable: React.FC<OwnProps> = memo(({ units }) => {
  const navigate = useNavigate()

  const onRowClick = (unitGuid: string) => (): void => {
    navigate(`/units/${unitGuid}/edit`)
  }

  return (
    <Box {...{ m: 3, mx: 0 }}>
      <Typography variant="h6">Units</Typography>
      <Divider
        sx={{
          bgcolor: "divider",
          marginTop: 1,
          marginBottom: 1
        }}
      />
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((title) => (
              <TableCell key={title}>{title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {units.map((unit) => (
            <TableRow sx={{ cursor: "pointer" }} key={unit.guid} hover onClick={onRowClick(unit.guid)}>
              <TableCell>
                <Typography variant="h6" color="primary">
                  {unit.name}
                </Typography>
                <Typography component="span" variant="body2" color="textSecondary">
                  Postcode
                </Typography>
                &nbsp;
                <Typography color="textPrimary" variant="body2" component="span">
                  {unit.postcode}
                </Typography>
              </TableCell>
              <TableCell>{unit.reference}</TableCell>
              <TableCell>{unit.email}</TableCell>
              <TableCell>{unit.phone ? unit.phone : "-"}</TableCell>
              <TableCell>
                {unit.address} {unit.additional_address ? `| ${unit.additional_address}` : ""}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
})

UnitListTable.displayName = "UnitListTable"
