import React, { memo } from "react"
import { TablePagination } from "@mui/material"
import { SetPagination } from "lib/types"
import { queryStringToObject } from "lib/queryString"
import { isNil } from "ramda"
import testingId from "constants/testingId"
import { useLocation } from "react-router-dom"

interface PaginationProps {
  setPagination: SetPagination
  count?: number
  initialPageSize?: 10 | 25 | 50 | 100 | 250 | 500
  itemsLength?: number
  rowsPerPageOptions?: number[]
}

const PaginatorComponent: React.FC<PaginationProps> = ({
  setPagination,
  count,
  initialPageSize = 25,
  itemsLength,
  rowsPerPageOptions = [10, 25, 50, 100]
}) => {
  const location = useLocation()
  const locationObject: Record<string, string> = queryStringToObject(location.search)

  const onPageChange = (_: unknown, newPage: number) => {
    setPagination((values) => ({ ...values, page: `${+newPage + 1}` }))
  }

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({ page: "1", page_size: event.target.value })
  }

  return (
    // @ts-ignore to fix unknown data-cy prop
    <TablePagination
      {...{
        rowsPerPageOptions,
        component: "div",
        rowsPerPage: +locationObject.page_size || initialPageSize,
        page: +locationObject.page - 1,
        slotProps: {
          actions: {
            nextButton: {
              "data-cy": testingId.nextPage,
              title: "next page",
              disabled: !isNil(itemsLength) && +locationObject.page_size > itemsLength
            },
            previousButton: { "data-cy": testingId.previousPage, title: "previous page" }
          }
        },
        onPageChange,
        onRowsPerPageChange,
        SelectProps: {
          "data-cy": testingId.selectRowsPerPage
        },

        ...(isNil(count)
          ? {
              labelDisplayedRows: ({ page }) => `Page ${page + 1}`,
              count: -1
            }
          : {
              count
            })
      }}
    />
  )
}

const Paginator = (props: PaginationProps) => (
  <div data-cy={testingId.pagination}>
    <PaginatorComponent {...props} />
  </div>
)

export default memo(Paginator)
