import { ContentContainer, Link, StickyBox } from "components"
import { Button, CancelButton } from "components/Form"
import {
  mapRulesPayloadToFormData,
  mapRulesFormDataToPayload
} from "pages/Finance/ContractModels/helpers/RuleGroupRule"
import React, { memo } from "react"
import { Form } from "react-final-form"
import { ContractModelTitle, RuleGroupForm } from "./components"
import { ContractModelPageProps } from "./types"
import { validateRuleGroupDates, validateRuleGroupRules } from "./validators/validateRuleGroup"
import { DateTime } from "luxon"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { useContractRuleConditionsAsOptionsByType } from "data/finance/contractRuleCondition/handlers"
import { Grid } from "@mui/material"
import BottomErrorMessage from "components/BottomErrorMessage"
import { isEmpty } from "lodash"
import {
  useInvoiceModelRuleGroupItemQuery,
  useInvoiceModelRuleGroupItemRulesQuery,
  useInvoiceModelRuleGroupListQuery
} from "data/finance/contractModel/queries"
import { InvoiceModelRuleGroupForm, InvoiceModelRuleGroupRequest } from "data/finance/contractModel/types"
import { omit } from "ramda"
import { useInvoiceModelRuleGroupCreateMutation } from "data/finance/contractModel/mutations"
import { useInvoiceModelRuleGroupWithRulesUpdateHandler } from "data/finance/contractModel/handlers/useInvoiceModelRuleGroupWithRulesUpdateHandler"
import { useParams } from "react-router-dom"

type Props = ContractModelPageProps & {
  isPaymentRule?: boolean
}

const RuleGroup: React.FC<Props> = ({ contractModel, modelType, isPaymentRule = false }) => {
  const { ruleGroupId } = useParams()
  // queries
  const { data: ruleGroups = [] } = useInvoiceModelRuleGroupListQuery({
    isPaymentRule,
    contractGuid: contractModel.guid
  })

  const { data: ruleGroup } = useInvoiceModelRuleGroupItemQuery({
    isPaymentRule,
    contractGuid: contractModel.guid,
    ruleGroupGuid: ruleGroupId
  })

  const { data: ruleGroupRules } = useInvoiceModelRuleGroupItemRulesQuery({
    isPaymentRule,
    contractGuid: contractModel.guid,
    ruleGroupGuid: ruleGroupId
  })

  const conditions = useContractRuleConditionsAsOptionsByType()

  // mutations
  const { mutateAsync: createRuleGroup } = useInvoiceModelRuleGroupCreateMutation({
    contractGuid: contractModel.guid,
    isPaymentRule
  })

  const updateRuleGroupWithRules = useInvoiceModelRuleGroupWithRulesUpdateHandler({
    contractGuid: contractModel.guid,
    isPaymentRule,
    ruleGroupGuid: ruleGroupId as string
  })

  const onSubmit = (values: InvoiceModelRuleGroupForm) => {
    // rules are validated here because they do not follow final-form structure, having this validator in <Form /> throws an error
    const errs = validateRuleGroupRules(conditions)(values)

    if (!isEmpty(errs)) return errs

    const resultValues: InvoiceModelRuleGroupRequest = {
      ...omit(["rules", "no_end_date", "rate", "end"])(values),
      ...(values.price_type_alias === "NONE" ? {} : { rate: values.rate }),
      ...(values.no_end_date ? {} : { end: values.end })
    }

    if (ruleGroup && ruleGroup.guid) {
      updateRuleGroupWithRules({ ruleGroup: resultValues, rules: mapRulesFormDataToPayload(values.rules || []) })
    } else {
      createRuleGroup(resultValues)
    }
  }

  const formRules = mapRulesPayloadToFormData(ruleGroupRules || [])

  const initialValues = {
    ...(ruleGroup || {
      price_type_alias: "NONE",
      start: DateTime.local(),
      rate: 0
    }),
    no_end_date: ruleGroup && !ruleGroup.end,
    rules: formRules
  } as InvoiceModelRuleGroupForm

  if ((ruleGroupId && !ruleGroupRules?.length) || !conditions) return null

  return (
    <ContentContainer>
      <ContractModelTitle
        modelType={modelType}
        title={
          <>
            <Link to={`../`}>{contractModel.title}</Link> / {isPaymentRule ? "Payment" : "Invoice"} rules /{" "}
            {ruleGroup?.guid ? ruleGroup.title : "New"}
          </>
        }
      />
      <RequestLoaderWrapper my={3}>
        <Form
          {...{
            onSubmit,
            initialValues,
            validate: validateRuleGroupDates({ contractModel, items: ruleGroups })
          }}
        >
          {({ handleSubmit, submitting, pristine, error, submitError }) => (
            <form onSubmit={handleSubmit}>
              <RuleGroupForm ruleGroup={ruleGroup} conditions={conditions} ruleGroupRules={formRules} />
              <StickyBox>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    {error || submitError ? <BottomErrorMessage message={error || submitError} /> : null}
                  </Grid>
                </Grid>
                <CancelButton disabled={submitting} pristine={pristine} />
                <Button
                  type="submit"
                  fullWidth={false}
                  disabled={pristine || submitting}
                  sx={(theme) => ({
                    margin: theme.spacing(0, 0, 0, 3)
                  })}
                >
                  Save
                </Button>
              </StickyBox>
            </form>
          )}
        </Form>
      </RequestLoaderWrapper>
    </ContentContainer>
  )
}

export default memo(RuleGroup)
