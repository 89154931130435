import { CoreApi } from "api"
import { ApiInvoiceGroup } from "./types"
import { useMutation, UseMutationResult } from "react-query"
import { ApiError } from "stores/useUIStore/types"
import { useUIStore } from "stores"

export const useInvoiceGroupUpdateMutation = (): UseMutationResult<ApiInvoiceGroup, ApiError, ApiInvoiceGroup> => {
  const showErrorMessage = useUIStore((state) => state.showErrorMessage)

  return useMutation(
    async (payload: ApiInvoiceGroup): Promise<ApiInvoiceGroup> => {
      return CoreApi.put("/invoice-groups", payload)
    },
    {
      onError: (apiError: ApiError) => {
        showErrorMessage("Updating CM2000 settings unsuccessful.", { apiError })
      }
    }
  )
}
