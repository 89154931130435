import React, { memo, useMemo } from "react"
import { Field, Form, FormSpy } from "react-final-form"
import { FormSectionHeader, TextField } from "components/Form"
import BillingInformation from "./BillingInformation"
import Grid from "@mui/material/Grid"
import { DateTime } from "luxon"
import testingId from "constants/testingId"
import { ContractModelType, INVOICE_CONTRACT_PUBLIC_ALIASES } from "constants/modelTypes"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { validatePeriodDates } from "lib/dateTimeValidators"
import validators, { composeValidators } from "lib/validators"
import { ContractModelInvoiceSettings } from "../ContractModelInvoiceSettings"
import { ContractModelFormDuration } from "./ContractModelFormDuration"
import { ContractModelFormFooter } from "./ContractModelFormFooter"
import { ContractModelFormInvoiceFields } from "./ContractModelFormInvoiceFields"
import { ContractModelFormPaymentFields } from "./ContractModelFormPaymentFields"
import { ContractModelFormTypeField } from "./ContractModelFormTypeField"
import useBranchReference from "lib/hooks/branchInfo/useBranchReference"
import { ContractModelRequest, InvoiceModel, PaymentModel } from "data/finance/contractModel/types"

interface Props {
  contractModel: InvoiceModel | PaymentModel | undefined
  onSubmit: (values: ContractModelRequest & { no_end_date: boolean }) => Promise<void>
  modelType: ContractModelType
}

const ContractModelForm: React.FC<Props> = ({ contractModel, onSubmit, modelType }) => {
  const isInvoiceModelType = modelType === ContractModelType.INVOICE

  const branchReference = useBranchReference()

  const initialValues = useMemo(() => {
    if (contractModel) {
      const initial = {
        ...contractModel,
        no_end_date: contractModel.end === undefined || contractModel.end === null,
        ...(contractModel.run ? { run_guid: contractModel.run.guid } : {})
      }
      delete initial.run
      return initial
    }
    return isInvoiceModelType
      ? { start: DateTime.local(), info: { income_category_alias: "R/DC" } }
      : {
          start: DateTime.local(),
          info: {
            contact_name: "Payroll Team",
            contact_email: "dcp-finance-team@ceracare.co.uk",
            cost_centre: branchReference
          },
          shifts_enabled: true
        }
  }, [contractModel, isInvoiceModelType, branchReference])

  return (
    <RequestLoaderWrapper my={3}>
      <Form onSubmit={onSubmit} initialValues={initialValues} validate={validatePeriodDates}>
        {({ handleSubmit, values, form }) => {
          const isPublicInvoiceModel = INVOICE_CONTRACT_PUBLIC_ALIASES.includes(values.type_alias)
          return (
            <form onSubmit={handleSubmit} data-cy={testingId.settingsForm}>
              <FormSpy
                {...{
                  subscription: { values: true },
                  onChange: ({ values: _values }) => {
                    if (isInvoiceModelType && _values.dont_process_invoice) {
                      form.change("shifts_enabled", false)
                    }
                  }
                }}
              />
              <FormSectionHeader title="Model details" />
              <Grid container spacing={8}>
                <Grid item md={6}>
                  <Field
                    name="title"
                    label={isInvoiceModelType ? "Template name *" : "Pay group *"}
                    component={TextField}
                    validate={composeValidators([validators.required, validators.maxLength(64)])}
                  />
                  <Field
                    name="description"
                    label="Description"
                    component={TextField}
                    validate={validators.maxLength(1000)}
                    multiline
                    minRows={5}
                  />
                </Grid>
                <Grid item md={6}>
                  <ContractModelFormTypeField isInvoiceModelType={isInvoiceModelType} contractModel={contractModel} />
                  <Field
                    name="comment"
                    label="Comment"
                    component={TextField}
                    validate={validators.maxLength(1000)}
                    multiline
                    minRows={5}
                  />
                </Grid>
              </Grid>
              {isInvoiceModelType ? (
                <ContractModelFormInvoiceFields isPublicInvoiceModel={isPublicInvoiceModel} />
              ) : (
                <ContractModelFormPaymentFields />
              )}

              <ContractModelFormDuration />

              {isInvoiceModelType && isPublicInvoiceModel && (
                <>
                  <BillingInformation isEditing={!!contractModel} form={form} />
                  <ContractModelInvoiceSettings />
                </>
              )}

              <ContractModelFormFooter isInvoiceModelType={isInvoiceModelType} />
            </form>
          )
        }}
      </Form>
    </RequestLoaderWrapper>
  )
}

export default memo(ContractModelForm)
