import React from "react"
import { Grid } from "@mui/material"
import { Field } from "react-final-form"
import { FormSectionHeader, TextField } from "components/Form"

const ClientProfileSectionFinance = () => {
  return (
    <>
      <FormSectionHeader title="Financial details" />
      <Grid container spacing={8}>
        <Grid item md={6}>
          <Field name="finance.ni_number" label="NI number" component={TextField} />
          <Field name="finance.social_services_number" label="Social services ref" component={TextField} />
        </Grid>
        <Grid item md={6}>
          <Field name="finance.nhs_number" label="NHS number" component={TextField} />
          <Field name="finance.agent_number" label="Agent ref" component={TextField} />
        </Grid>
      </Grid>
    </>
  )
}

export default ClientProfileSectionFinance
