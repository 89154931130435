import React, { createContext, PropsWithChildren, useMemo, useState } from "react"
import { SchemaName, SchemasToLoad, StateSchemas } from "./types"
import { isEmpty, omit } from "ramda"
import { useSchemaQuery } from "./queries"
import { useDebounce } from "use-debounce"

interface State {
  schemas: StateSchemas
  setSchemas: React.Dispatch<React.SetStateAction<StateSchemas>>
  schemasToLoad: SchemasToLoad
  setSchemasToLoad: React.Dispatch<React.SetStateAction<SchemasToLoad>>
  invalidateSchema: (schemasToInvalidate: Array<keyof StateSchemas>) => void
  invalidateAllSchemas: () => void
}

export const SchemaProviderContext = createContext<State>({ schemas: {} } as State)

export const SchemaProvider = ({ children }: PropsWithChildren) => {
  const [schemas, setSchemas] = useState<StateSchemas>({} as StateSchemas)
  const [schemasToLoad, setSchemasToLoad] = useState<SchemasToLoad>([] as SchemasToLoad)

  const [schemasToFetch] = useDebounce(schemasToLoad, 50)

  const onSuccess = (response: StateSchemas) => {
    if (!isEmpty(response)) {
      setSchemas((existingSchemas) => ({ ...existingSchemas, ...response }))
    }

    setSchemasToLoad([] as SchemasToLoad)
  }

  const invalidateSchema = (schemasToInvalidate: Array<keyof StateSchemas>) => {
    setSchemas((currentSchemas) => omit(schemasToInvalidate, currentSchemas))
  }

  const invalidateAllSchemas = () => {
    setSchemas({})
  }

  useSchemaQuery(schemasToFetch.filter((i) => i) as SchemaName[], onSuccess)

  const store = useMemo(
    () => ({
      schemas,
      setSchemas,
      schemasToLoad,
      setSchemasToLoad,
      invalidateSchema,
      invalidateAllSchemas
    }),
    [schemas, setSchemas, schemasToLoad, setSchemasToLoad]
  )

  return <SchemaProviderContext.Provider value={store}>{children}</SchemaProviderContext.Provider>
}
