import React, { memo } from "react"
import { FormSectionHeader } from "components/Form"
import { Box, Divider } from "@mui/material"
import { HolidayEstimationCalculations } from "./HolidayEstimationCalculations"
import { HolidayEstimationTotals } from "./HolidayEstimationTotals"
import { HolidayEstimationOverviewTable } from "./HolidayEstimationOverviewTable"
import { HolidayEstimationTotal } from "./HolidayEstimationTotal"
import { DateTime } from "luxon"
import { HolidayCalculationItemDetails } from "data/finance/paymentProcessing/types"
import Alert from "components/Alert"

interface Props {
  date: DateTime
  items: HolidayCalculationItemDetails[]
  processed_at?: DateTime
  quantity: number
  rate: number
  total: number
}

export const HolidayEstimation = memo(({ total, date, items, quantity, rate, processed_at }: Props) => {
  HolidayEstimation.displayName = "HolidayEstimation"

  return (
    <Box m={5} mx={0}>
      <Alert
        severity="info"
        title="Please note"
        text="Any holiday rate shown below are of an estimated value, actual rate may differ slightly and is subject to change."
      />
      <HolidayEstimationTotal total={total} />
      <Divider sx={{ marginTop: "5px", bgcolor: "divider" }} />
      <FormSectionHeader title="Holiday Overview" />

      <HolidayEstimationOverviewTable
        date={date}
        quantity={quantity}
        rate={rate}
        total={total}
        processed_at={processed_at}
      />

      <HolidayEstimationTotals items={items} />

      <Divider sx={{ marginTop: "5px", bgcolor: "divider" }} />
      <FormSectionHeader title="Holiday Calculation" />
      {items.length ? <HolidayEstimationCalculations calculations={items} /> : null}
    </Box>
  )
})
