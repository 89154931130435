import { Box } from "@mui/material"
import { useRecalculateMutation } from "data/finance/development/mutations"
import { groupBy } from "ramda"
import React, { memo, useMemo } from "react"
import { ScheduledAppointment } from "./helpers/mapScheduledAppointments"
import { AppointmentTypeAlias } from "data/finance/appointment/types"
import { DevButton } from "components/DevButton"

interface OwnProps {
  items: ScheduledAppointment[]
  resetAll: () => void
  hasRecalcPermission: boolean
  disabled: boolean
}

export const AppointmentSearchScheduledBulkRecalcButton: React.FC<OwnProps> = memo(
  ({ items, resetAll, hasRecalcPermission, disabled }) => {
    AppointmentSearchScheduledBulkRecalcButton.displayName = "AppointmentSearchScheduledBulkRecalcButton"

    const selectableRecalcs = useMemo(() => {
      const groupFunction = (item: ScheduledAppointment) => item.typeAlias

      const groupedAppointments = groupBy(groupFunction)(items)
      return Object.keys(groupedAppointments).map((source: string) => {
        const appointment = groupedAppointments[source as AppointmentTypeAlias]
        const relation_guids = (appointment ?? []).map((item) => item.assigneeGuid)
        return { type: source, relation_guids: relation_guids }
      })
    }, [items])

    const { mutateAsync: bulkRecalc } = useRecalculateMutation()
    const handleBulkRecalc = () => {
      bulkRecalc(selectableRecalcs)
      resetAll()
    }

    if (!hasRecalcPermission) {
      return null
    }

    return (
      <Box margin={1}>
        <DevButton fullWidth={false} disabled={disabled} onClick={handleBulkRecalc}>
          Bulk Recalc
        </DevButton>
      </Box>
    )
  }
)
