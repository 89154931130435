import React, { memo } from "react"
import { TableCell, Tooltip } from "@mui/material"
import { Warning, Error } from "@mui/icons-material"

interface ListAlertIconProps {
  callback?: Function
  clickable?: boolean
  icon?: "error" | "warning"
  tooltip?: string
}

export const ListAlertIconBasic = () => {
  return <Error color="error" />
}

const ListAlertIcon = ({ callback, clickable = false, icon = "error", tooltip }: ListAlertIconProps) => {
  const IconComponent = () => {
    if (icon === "warning")
      return (
        <Warning
          sx={(theme) => ({
            color: theme.palette.warning.light,
            position: "absolute",
            right: "0px",
            top: "50%",
            transform: "translateY(-50%)"
          })}
        />
      )
    return (
      <Error
        sx={{
          position: "absolute",
          right: "0px",
          top: "50%",
          transform: "translateY(-50%)"
        }}
        color="error"
      />
    )
  }

  return (
    <TableCell
      sx={{
        position: "relative",
        width: "0px",
        padding: 0,
        cursor: clickable ? "pointer" : "default"
      }}
      onClick={(e) => {
        e.stopPropagation()
        callback && callback()
      }}
    >
      {tooltip ? (
        <Tooltip title={tooltip}>
          <div>
            <IconComponent />
          </div>
        </Tooltip>
      ) : (
        <IconComponent />
      )}
    </TableCell>
  )
}

export default memo(ListAlertIcon)
