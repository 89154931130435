import React from "react"
import InvoiceModels from "./InvoiceModels"
import PaymentModels from "./PaymentModels"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

const Contracts: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/invoice-models/*" element={<InvoiceModels />} />
      <Route path="/payment-models/*" element={<PaymentModels />} />
    </SentryRoutes>
  )
}

export default React.memo(Contracts)
