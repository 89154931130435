import Grid from "@mui/material/Grid"
import { FormSpyCustom, Button } from "components/Form"
import { Link } from "components"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Form } from "react-final-form"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { ClearFilterButton } from "components/ClearFilterButton"
import { StatusSelectField } from "components/Form/StatusSelectField"
import { StatusEnum } from "data/commonTypes"

export interface AccrualPolicyFilter {
  status?: StatusEnum
}

interface OwnProps {
  filter: AccrualPolicyFilter
  setFilter: Dispatch<SetStateAction<AccrualPolicyFilter>>
}

export const AccrualPolicyListFilter: React.FC<OwnProps> = memo(({ filter, setFilter }) => {
  const onSubmit = (values: AccrualPolicyFilter) => {
    setFilter(values)
  }

  const onClearClick = () => {
    setFilter({})
  }

  useFilterLocationChange({ filter })

  return (
    <Form onSubmit={onSubmit} initialValues={filter}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpyCustom {...{ handleSubmit }} />
          <Grid container alignItems="center" spacing={1}>
            <Grid item md={3}>
              <StatusSelectField />
            </Grid>
            <Grid item md={2}>
              <ClearFilterButton onClick={onClearClick} disabled={Object.keys(filter).length === 0} />
            </Grid>
            <Grid item md={4} />
            <Grid item md={3}>
              <Link to="new">
                <Button>Add Accrual Policy</Button>
              </Link>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  )
})

AccrualPolicyListFilter.displayName = "AccrualPolicyListFilter"
