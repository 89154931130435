import { Box, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { ContractModelServiceBandWithRates } from "data/finance/contractModel/types"
import React, { memo } from "react"
import { reduceEnhancedRates } from "../../handlers/reduceEnhancedRates"
import { useGetRateDayTypeOptions } from "../../helpers/useGetRateDayTypeOptions"
import { RateUpliftFormServiceBand } from "../../types"
import { RateUpliftFourServiceBandRates } from "./RateUpliftFourServiceBandRates"

interface OwnProps {
  formBand: RateUpliftFormServiceBand
  band: ContractModelServiceBandWithRates
}

export const RateUpliftFourServiceBand: React.FC<OwnProps> = memo(({ formBand, band }) => {
  RateUpliftFourServiceBand.displayName = "RateUpliftFourServiceBand"

  const { dayTypeOptions, isLoading } = useGetRateDayTypeOptions()

  if (!band.rates || isLoading) return null

  // take rates from form and enhanced rates from DB
  const enhancedRates = reduceEnhancedRates(band.rates)

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: grey[100],
        boxShadow: `0 0 10px 0 ${grey[300]}`
      }}
    >
      <Typography
        color="primary"
        sx={{
          paddingBottom: 2,
          paddingLeft: 2
        }}
      >
        Band: ({band.minimum_duration_minutes} - {band.maximum_duration_minutes || "---"})
      </Typography>
      {formBand.rates ? (
        <RateUpliftFourServiceBandRates {...{ formRates: formBand.rates, enhancedRates, dayTypeOptions }} />
      ) : (
        "No rates"
      )}
    </Box>
  )
})
