import { DateTime } from "luxon"
import { getDate, getNewestPeriod } from "../components/calendars/dateUtils"
import { AddCalendarRequest, CalendarPeriod } from "data/finance/contractModel/types"

export const getContractCalendarPeriodDates = (values: AddCalendarRequest): CalendarPeriod[] => {
  const {
    start,
    frequency_type_alias = "1/WEEK",
    periodCount = 0,
    processDays,
    invoiceDeadlineDays,
    invoiceDeadlineOffset,
    processDateOffset,
    periods = []
  } = values

  const newPeriods: CalendarPeriod[] = []

  let startDate = start || DateTime.local()

  const isInvoiceOffset = invoiceDeadlineOffset !== "" && invoiceDeadlineOffset !== "n/a"
  const isProcessOffset = processDateOffset !== "" && processDateOffset !== "asPerEnd"
  const procDaysCount = isProcessOffset ? processDays : 0

  for (let i = 0; i < periodCount; i++) {
    if (periods.length > 0 && i === 0) {
      const last = getNewestPeriod(periods)
      startDate = last.end.plus({ days: 1 })
    } else if (newPeriods.length > 0) {
      startDate = newPeriods[i - 1].end.plus({ days: 1 })
    }

    const getValueDate = getDate(frequency_type_alias, startDate, procDaysCount, invoiceDeadlineDays)
    const period = {
      guid: `new-item-${periods.length + i}`,
      start: getValueDate("start"),
      end: getValueDate("end"),
      process_date: getValueDate("processDate"),
      invoice_deadline: isInvoiceOffset ? getValueDate("invoiceDate") : undefined
    } as CalendarPeriod

    newPeriods.push(period)
  }

  return newPeriods
}
