import React, { memo } from "react"
import Grid from "@mui/material/Grid"
import { FormSpy } from "react-final-form"
import { NoEndDateCheckbox } from "components"
import { DateField } from "components/Form/DateField"

const ShiftCategoryDetailsDates = () => (
  <FormSpy subscription={{ values: true }}>
    {({ values }) => (
      <>
        <Grid item md={6}>
          <DateField
            name="start"
            label="Starts on"
            required
            maxDate={!values.no_end_date && values.end ? values.end.minus({ days: 1 }) : undefined}
          />
        </Grid>
        <Grid item md={6}>
          <DateField
            name="end"
            label="Ends before"
            minDate={values.start?.plus({ days: 1 })}
            disabled={!!values.no_end_date}
          />
          <NoEndDateCheckbox />
        </Grid>
      </>
    )}
  </FormSpy>
)

export default memo(ShiftCategoryDetailsDates)
