import TableBody from "@mui/material/TableBody"
import React, { memo } from "react"
import { optionsToValueTitle } from "lib/schema"
import { ContractModel } from "data/finance/contractModel/types"
import { ContractModelType } from "constants/modelTypes"
import { ContractModelTableItem } from "./ContractModelTableItem"
import { ContractModelUpliftContractCheckboxProps } from "../types"
import { useSchema } from "data/schema/useSchema"

interface Props {
  items: ContractModel[]
  modelType: ContractModelType
  CustomTableItem?: React.FC<ContractModelUpliftContractCheckboxProps>
  clickable: boolean
}

export const ContractModelTableBody: React.FC<Props> = memo(({ items, modelType, CustomTableItem, clickable }) => {
  ContractModelTableBody.displayName = "ContractModelTableBody"
  const isInvoice = modelType === ContractModelType.INVOICE

  const baseUrl = isInvoice ? "invoice-models" : "payment-models"

  const { InvoiceContractType, PaymentContractType } = useSchema(["InvoiceContractType", "PaymentContractType"])

  const typeAliasMap = optionsToValueTitle(isInvoice ? InvoiceContractType : PaymentContractType)

  return (
    <TableBody>
      {items.map((item) => (
        <ContractModelTableItem
          {...{ item, typeAliasMap, baseUrl, CustomTableItem, clickable, modelType }}
          key={item.guid}
        />
      ))}
    </TableBody>
  )
})
