import React from "react"
import { Box, Typography, Grid } from "@mui/material"
import testingId from "constants/testingId"

interface Props {
  visit: {
    contractModelTitle?: string
    clientName?: string
    contractNumber?: string
    shiftTitle?: string
  }
}

const TransactionShiftVisitDetailHeader: React.FC<Props> = ({ visit }) => {
  const { contractModelTitle, clientName, contractNumber, shiftTitle } = visit
  return (
    <Box
      sx={(theme) => ({
        margin: theme.spacing(4)
      })}
    >
      <Grid container spacing={10}>
        <Grid item md={3}>
          <Typography variant="body2" color="textSecondary">
            Client
          </Typography>
          <Typography variant="h6" data-cy={testingId.paymentProcessingShiftTransactionDetail.headerClient}>
            {clientName}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography variant="body2" color="textSecondary">
            Contract number
          </Typography>
          <Typography variant="h6" data-cy={testingId.paymentProcessingShiftTransactionDetail.headerContractNumber}>
            {contractNumber}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography variant="body2" color="textSecondary">
            Contract model
          </Typography>
          <Typography variant="h6" data-cy={testingId.paymentProcessingShiftTransactionDetail.headerContractModel}>
            {contractModelTitle}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography variant="body2" color="textSecondary">
            Shift
          </Typography>
          <Typography variant="h6" data-cy={testingId.paymentProcessingShiftTransactionDetail.headerShiftTitle}>
            {shiftTitle}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(TransactionShiftVisitDetailHeader)
