import { CheckInCheckOutWithVariantProps } from "./types"

export const isWithinRange = ({ visit, variant = "in" }: CheckInCheckOutWithVariantProps) =>
  visit[`check_${variant}`]?.location.is_within_range

//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export const calculateDistance = (
  startCoordinates: { lat1: number; lon1: number },
  endCoordinates: { lat2: number; lon2: number },
  unit: "M" | "K" | "N" = "K"
): number => {
  if (startCoordinates.lat1 === endCoordinates.lat2 && startCoordinates.lon1 === endCoordinates.lon2) {
    return 0
  }

  const radlat1 = (Math.PI * startCoordinates.lat1) / 180
  const radlat2 = (Math.PI * endCoordinates.lat2) / 180
  const theta = startCoordinates.lon1 - endCoordinates.lon2
  const radtheta = (Math.PI * theta) / 180
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)

  if (dist > 1) {
    dist = 1
  }
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515

  if (unit === "K") {
    dist = dist * 1.609344
  }
  if (unit === "N") {
    dist = dist * 0.8684
  }

  return dist
}
