import { FormStage } from "constants/form"
import { INVOICE_CONTRACT_PRIVATE_ALIASES } from "constants/modelTypes"
import {
  ApiClientContractPayloadContribution,
  ApiClientContractPayload,
  ContractRate,
  FunderTypes
} from "data/finance/client/types"
import { useUnitContractPostMutation, useUnitContractPutMutation } from "data/finance/unit/mutations"
import { Unit } from "data/finance/unit/types"
import { SelectableIdsDictionary } from "lib/hooks"
import { cloneDeep } from "lodash"
import { assocPath } from "ramda"
import { Dispatch, SetStateAction, useCallback } from "react"

export const useUnitContractSubmit = ({
  selectableContributions,
  unit,
  formStage,
  setSelectedContractModelGuid,
  setFormStage
}: {
  selectableContributions: SelectableIdsDictionary
  unit: Unit
  formStage: FormStage
  setSelectedContractModelGuid: Dispatch<SetStateAction<string | undefined>>
  setFormStage: Dispatch<SetStateAction<FormStage>>
  selectedContractModelGuid?: string
}): ((values: ApiClientContractPayload) => void) => {
  const { getSelectedAsStringArr } = selectableContributions

  const { mutateAsync: createContract } = useUnitContractPostMutation({ unitGuid: unit.guid })
  const { mutateAsync: editContract } = useUnitContractPutMutation({
    unitGuid: unit.guid
  })

  return useCallback(
    (values: ApiClientContractPayload) => {
      if (formStage === FormStage.CREATE) {
        setSelectedContractModelGuid(values.contract_guid)
        setFormStage(FormStage.EDIT)
        return
      }

      const mutatedValues =
        values.finance?.output_delivery_type_alias === "POST"
          ? assocPath(["finance", "email"], null, values)
          : cloneDeep(values)

      const contractFunderType: FunderTypes | undefined = mutatedValues.finance?.funder_type

      if (mutatedValues.finance && contractFunderType === FunderTypes.INDIVIDUAL) {
        delete mutatedValues.finance.for_attention_of
        delete mutatedValues.finance.funding_name
      }

      if (mutatedValues.finance && contractFunderType === FunderTypes.ORGANISATION) {
        delete mutatedValues.finance.funder_salutation
        delete mutatedValues.finance.funder_first_name
        delete mutatedValues.finance.funder_last_name
      }

      const isPrivate = INVOICE_CONTRACT_PRIVATE_ALIASES.includes(mutatedValues.contract_type_alias)

      if (isPrivate) {
        mutatedValues.contributions = null
      }

      const clonedContributions = cloneDeep(mutatedValues.contributions) || []
      let contributions = null
      if (clonedContributions.length > 0) {
        const contributionsToDelete = getSelectedAsStringArr()
        contributions = clonedContributions
          .filter((contribution) => !contributionsToDelete.includes(contribution.guid))
          .map((contribution: any): ApiClientContractPayloadContribution => {
            delete contribution.status
            delete contribution.user_contract_title
            return contribution as ApiClientContractPayloadContribution
          })
      }

      const clonedRates = cloneDeep(mutatedValues.rates) || []
      let rates: ContractRate[] | undefined = undefined
      if (clonedRates.length > 0) {
        rates = clonedRates
          .filter((rate) => !rate.delete)
          .map((rate) => {
            //TODO: rates in BE need hours
            return { ...rate, hours: rate.hours ?? 0 }
          })
      }

      const { no_end_date, ...rest } = mutatedValues
      const payload = {
        ...rest,
        clientId: unit.guid,
        end: no_end_date ? undefined : values.end,
        contributions,
        rates
      }

      if (values.guid) {
        editContract({ payload, contractGuid: values.guid })
      } else {
        createContract({ payload })
      }
    },
    [
      formStage,
      createContract,
      editContract,
      getSelectedAsStringArr,
      setFormStage,
      setSelectedContractModelGuid,
      unit.guid
    ]
  )
}
