import { useContractRuleConditionListQuery } from "./queries"
import { ContractRuleConditionOptionsByType, ContractRuleConditionTypeAlias } from "./types"

export const useContractRuleConditionsAsOptionsByType = (): ContractRuleConditionOptionsByType => {
  const { data: contractRuleConditions } = useContractRuleConditionListQuery()

  return (contractRuleConditions || []).reduce(
    (result, item) => {
      result[item.rule_type_alias].push({
        title: item.title,
        value: item.guid
      })
      return result
    },
    {
      [ContractRuleConditionTypeAlias.CONF]: [],
      [ContractRuleConditionTypeAlias.CANCEL]: []
    } as ContractRuleConditionOptionsByType
  )
}
