import { Tooltip, Typography } from "@mui/material"
import { green, orange, red } from "@mui/material/colors"
import { Timer } from "@mui/icons-material"
import { ApiContractAccrualItem } from "data/finance/carer/types"
import { timeSpanToValuePeriod } from "lib/timespan"
import React, { memo } from "react"

const getColor = (amount: number) => {
  if (amount < 0) return red[500]
  if (amount === 0) return orange[400]
  return green[400]
}

interface OwnProps {
  accrualItem: ApiContractAccrualItem
}

export const CarerAccrualDetailListItemCharge: React.FC<OwnProps> = memo(({ accrualItem }) => {
  CarerAccrualDetailListItemCharge.displayName = "CarerAccrualDetailListItemCharge"

  const amountPrefix = accrualItem.amount > 0 ? "+" : ""
  const amount = accrualItem.amount.toFixed(2)

  return (
    <Typography
      component="span"
      sx={{
        color: getColor(accrualItem.amount),
        display: "flex",
        alignItems: "center"
      }}
    >
      {amountPrefix}
      {amount}
      {accrualItem.expiry ? (
        <Tooltip
          title={<Typography>use within {timeSpanToValuePeriod(accrualItem.expiry, "DAYS")[0]} days</Typography>}
        >
          <Typography
            component="span"
            sx={{
              marginLeft: 5,
              display: "flex"
            }}
          >
            <Timer />
          </Typography>
        </Tooltip>
      ) : null}
    </Typography>
  )
})
