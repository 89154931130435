import { AppBar, Box, Tab, Tabs } from "@mui/material"
import { ContentContainer, Divider, Header } from "components"
import { queryStringToObject } from "lib/queryString"
import React, { ChangeEvent, memo, useState } from "react"
import { DateTime } from "luxon"
import { ContractModelType } from "constants/modelTypes"
import testingId from "constants/testingId"
import { AlertFilter, AlertsFilterValues } from "./components/AlertFilter"
import { AlertTable } from "./components/AlertTable"
import { useIsInvoiceModelSearch } from "lib/hooks/useIsInvoiceModelSearch"
import { usePagination } from "components/handlers/usePagination"
import { useLocation } from "react-router-dom"

const defaultFilterValues = {
  from: DateTime.local().minus({ week: 1 }).startOf("week").toISODate() as string,
  to: DateTime.local().minus({ week: 1 }).endOf("week").toISODate() as string,
  modelType: ContractModelType.INVOICE
}

const AlertList: React.FC = () => {
  const location = useLocation()
  const isInvoiceModel: boolean = useIsInvoiceModelSearch()

  const { pagination, setPagination, resetPagination } = usePagination()

  const [filter, setFilter] = useState<AlertsFilterValues>(() => ({
    ...defaultFilterValues,
    ...queryStringToObject<AlertsFilterValues>(location?.search)
  }))

  const handleTabChange = (_: ChangeEvent<{}>, newValue: ContractModelType) => {
    setFilter((values) => ({
      from: values.from,
      to: values.to,
      modelType: newValue
    }))
    resetPagination()
  }

  return (
    <ContentContainer>
      <Header data-cy={testingId.alerts.alertsTitle} title="Alerts" />
      <Box my={3}>
        <AppBar position="static" color="transparent">
          <Tabs value={filter.modelType} aria-label="Alerts" onChange={handleTabChange}>
            <Tab data-cy={testingId.alerts.invoicingTab} label="Invoicing" value={ContractModelType.INVOICE} />
            <Tab data-cy={testingId.alerts.paymentsTab} label="Payments" value={ContractModelType.PAYMENT} />
          </Tabs>
        </AppBar>
      </Box>
      <AlertFilter {...{ filter, setFilter, pagination, resetPagination, defaultFilterValues, isInvoiceModel }} />
      <Divider color="divider" />
      <AlertTable {...{ pagination, setPagination }} />
    </ContentContainer>
  )
}

export default memo(AlertList)
