import React, { memo, useCallback } from "react"
import { ContractModelType } from "constants/modelTypes"
import { InvoiceFileItem } from "data/finance/invoiceFile/types"
import { useInvoiceFileInterfacePostMutation } from "data/finance/invoiceFile/mutations"
import { usePaymentFileInterfacePostMutation } from "data/finance/paymentFile/mutations"
import { FileManagementModal } from "./FileManagementModal"
import { useUIStore } from "stores"
import { InterfaceFileMode } from "../types"

interface DownloadFormValues {
  download_mode: InterfaceFileMode
}

interface OwnProps {
  files: InvoiceFileItem[]
  modelType: ContractModelType
  onCancel: () => void
  onOk: () => void
  selectedIds: string[]
}

const downloadOptions = (isInvoiceModel: boolean): Array<{ title: string; value: InterfaceFileMode }> =>
  isInvoiceModel
    ? [
        { title: "NetSuite", value: "EX/NET-S" },
        { title: "Business Central", value: "EX/BIZ-C" }
      ]
    : [
        { title: "CIPHR", value: "EX/CIPHR" },
        { title: "iTrent", value: "EX/TRENT" }
      ]

export const FileManagementModalInterface: React.FC<OwnProps> = memo(
  ({ files, modelType, onCancel, onOk, selectedIds }) => {
    FileManagementModalInterface.displayName = "FileManagementModalInterface"
    const showErrorMessage = useUIStore((state) => state.showErrorMessage)
    const isInvoiceModel = modelType === ContractModelType.INVOICE

    const filesToInterface = files.filter((file) => selectedIds.includes(file.guid))

    const { mutateAsync: postInvoiceDownload } = useInvoiceFileInterfacePostMutation()
    const { mutateAsync: postPaymentDownload } = usePaymentFileInterfacePostMutation()

    const onSubmit = useCallback(
      async ({ download_mode }: DownloadFormValues) => {
        try {
          if (isInvoiceModel) {
            await postInvoiceDownload({ download_mode, transaction_guids: selectedIds })
          } else {
            await postPaymentDownload({ download_mode, transaction_guids: selectedIds })
          }
        } catch (apiError: any) {
          showErrorMessage(
            "Something went wrong while interfacing the file(s). Please try again later or contact user support.",
            { apiError }
          )
        }

        onOk()
      },
      [isInvoiceModel, selectedIds, onOk, postInvoiceDownload, postPaymentDownload, showErrorMessage]
    )

    return (
      <FileManagementModal
        {...{
          isInvoiceModel,
          onCancel,
          onSubmit,
          files: filesToInterface,
          downloadOptions: downloadOptions(isInvoiceModel),
          submitButtonLabel: "CREATE INTERFACE FILE",
          modalTitle: "Interface Download"
        }}
      />
    )
  }
)
