import { PATH } from "constants/path"
import { NavigateFunction } from "react-router-dom"

const addSearch = (search?: string): string => (search ? `?${search}` : "")

export const navigateToFinanceAlerts = (navigate: NavigateFunction, search?: string): void =>
  navigate(`${PATH.FINANCE.ALERTS}${addSearch(search)}`)

export const navigateToFinancePaymentModelBaseRate = (
  navigate: NavigateFunction,
  contractGuid: string,
  rateGuid: string
): void => navigate(`${PATH.FINANCE.PAYMENT_MODELS}/${contractGuid}/base-rates/${rateGuid}`)
