import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"
import { FormApi } from "final-form"
import { ApiUserClient } from "models"
import { Field, useField, useForm } from "react-final-form"
import { GeoAddressField, FormSectionHeader, Button, Select, TextField } from "components/Form"
import { Coordinates, CorrectPinLocationModal, MapPreview, useGoogleMaps } from "components/GoogleMaps"
import { Edit } from "@mui/icons-material/"
import NoMarkersMessage from "components/GoogleMaps/NoMarkersMessage"
import { getOptionsFromSchema } from "lib/schema"
import validators from "lib/validators"
import { useCoreSchemasStore } from "stores"
import useGoogleMapsStore from "stores/useGoogleMapsStore"

const ClientProfileSectionAddress = () => {
  const form = useForm<ApiUserClient>()

  const { ApiUserPatientCreate } = useCoreSchemasStore((state) => state.schema.models)
  const regionOptions = getOptionsFromSchema(ApiUserPatientCreate, "regions")

  const addressDefault = useField<string>("profile.address").input.value
  const addressLatDefault = useField<number>("profile.address_latitude").input.value
  const addressLongDefault = useField<number>("profile.address_longitude").input.value

  const isMapLoaded = useGoogleMapsStore((state) => state.isLoaded)
  const [isModalOpened, setIsModalOpened] = useState(false)
  const toggleModal = () => setIsModalOpened((isOpen) => !isOpen)

  const { loadGoogleMaps } = useGoogleMaps()

  useEffect(() => {
    if (!isMapLoaded) {
      loadGoogleMaps()
    }
  }, [loadGoogleMaps, isMapLoaded])

  const addressFetch = form.getFieldState("profile.address" as keyof ApiUserClient)?.value
  const address = typeof addressFetch === "string" ? addressFetch : addressDefault
  const addressLatFetch = form.getFieldState("profile.address_latitude" as keyof ApiUserClient)?.value
  const addressLat = typeof addressLatFetch === "number" ? addressLatFetch : addressLatDefault
  const addressLongFetch = form.getFieldState("profile.address_longitude" as keyof ApiUserClient)?.value
  const addressLong = typeof addressLongFetch === "number" ? addressLongFetch : addressLongDefault

  const handleMapChange = (form: FormApi<ApiUserClient>) => (coordinates: Coordinates) => {
    form.batch(() => {
      form.change("profile.address_latitude" as keyof ApiUserClient, coordinates.lat)
      form.change("profile.address_longitude" as keyof ApiUserClient, coordinates.lng)
    })
    toggleModal()
  }

  const coordinatesAvailable = addressLat && addressLong
  const markers = coordinatesAvailable ? [{ coordinates: { lat: addressLat, lng: addressLong } }] : []
  const shouldShowNoMarkersMessage = !markers || markers.length === 0

  return (
    <>
      <FormSectionHeader title="Address" />
      <Box
        sx={{
          display: "grid",
          columnGap: 8,
          gridTemplateColumns: "1fr 1fr"
        }}
      >
        <Box sx={{ gridColumn: "1/3", display: "flex", flexDirection: "column" }}>
          <MapPreview
            hasMapTypeControl
            center={markers[0]?.coordinates}
            markers={markers}
            noMarkersMessageComponent={shouldShowNoMarkersMessage ? <NoMarkersMessage /> : undefined}
            mapId="client-profile-map"
          />
          <Button
            id="client-profile-edit-pin-location-btn"
            startIcon={<Edit />}
            variant="text"
            fullWidth={false}
            disabled={markers.length === 0}
            onClick={toggleModal}
            sx={{ marginLeft: "auto" }}
          >
            Edit pin location
          </Button>
          {isModalOpened && (
            <CorrectPinLocationModal
              fullAddress={address}
              onCancel={toggleModal}
              onSave={handleMapChange(form)}
              center={markers[0]?.coordinates}
              mapId="client-profile-map-edit"
            />
          )}
        </Box>

        <Box sx={{ gridColumn: "1/3" }}>
          <GeoAddressField<ApiUserClient>
            shouldRenderAddressDetails
            required
            error={!address ? "This field is required" : ""}
          />
        </Box>
        <Box sx={{ paddingTop: 0 }}>
          <Field
            name="location.location_entry_code"
            label="Entry code"
            component={TextField}
            validate={validators.maxLength(64)}
          />
          <Field
            name="regions"
            label="Regions *"
            component={Select}
            multiselect
            options={regionOptions}
            validate={validators.required}
          />
        </Box>
        <Box sx={{ paddingTop: 0 }}>
          <Field name="location.location_entry_message" label="Entry message" component={TextField} />
        </Box>
      </Box>
    </>
  )
}

export default ClientProfileSectionAddress
