import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import { EmptyData } from "components"
import { Button, Checkbox, TextField } from "components/Form"
import React, { memo, useCallback, useMemo } from "react"
import { Field } from "react-final-form"
import { grey } from "@mui/material/colors"
import testingId from "constants/testingId"
import {
  ContractModelEnhancedRate,
  ContractModelEnhancedRateModifierType,
  ContractModelRate,
  ContractModelService,
  RateEditorPayload
} from "data/finance/contractModel/types"
import { RateEditorTableHead } from "./RateEditorTableHead"
import { FieldArray } from "react-final-form-arrays"
import { HolidayTier } from "data/finance/holidayTier/types"
import Select, { SelectOption } from "components/Form/Select"
import { uuidv4Regex } from "lib/utils"
import { findIndex, uniq } from "ramda"
import { FormApi } from "final-form"
import { getByGuid } from "helpers/getByGuid"
import { getUpliftEnhancedRatePrice } from "../helpers/upliftFormGetters"
import { useSchema } from "data/schema/useSchema"

interface OwnProps {
  service: ContractModelService
  values: RateEditorPayload
  holidayTiers?: HolidayTier[]
  dayTypeOptions: SelectOption[]
  form: FormApi<RateEditorPayload, Partial<RateEditorPayload>>
}

export const RateEditorEnhanced: React.FC<OwnProps> = memo(
  ({ service, values, holidayTiers = [], dayTypeOptions, form }) => {
    const { Day } = useSchema(["Day"])

    const standardRateOptions = useMemo(() => {
      return values.basicRates
        .filter((basicRate) => !basicRate.delete)
        .map((basicRate) => {
          const isCustomHolidayTier = !!basicRate.day_type_alias?.match(uuidv4Regex)

          const name = (
            isCustomHolidayTier
              ? holidayTiers.find((ht) => ht.guid === basicRate.day_type_alias)
              : (Day || []).find((dr) => String(dr.value) === basicRate.day_type_alias)
          )?.title

          return {
            title: `${name} (${basicRate.start} - ${basicRate.end})`,
            value: basicRate.guid
          }
        })
    }, [values.basicRates, holidayTiers, Day])

    // types not used in standard rates
    const dayTypeEnhancedOptions = useMemo(() => {
      const usedAsBasic = uniq(
        values.basicRates.filter((basicRate) => !basicRate.delete).map((basicRate) => basicRate.day_type_alias)
      )
      return dayTypeOptions.filter((option) => {
        // can not have types used as basic
        const isUsedAsBasic = usedAsBasic.includes(option.value as string)
        // can not have type "week" as enhanced
        const isWeek = option.value === "WEEK"
        // can not have type "w/day" as enhanced if does not have "week" as standard
        const canUseWeekday = values.basicRates.some((basicRate) => basicRate.day_type_alias === "WEEK")
        const weekdayCondition = canUseWeekday ? true : option.value !== "W/DAY"

        return !isUsedAsBasic && !isWeek && weekdayCondition
      })
    }, [values.basicRates, dayTypeOptions])

    const handleDeleteClick = useCallback(
      ({
          fields,
          index,
          rate
        }: {
          fields: { update: (index: number, value: ContractModelEnhancedRate) => void }
          index: number
          rate: ContractModelEnhancedRate
        }) =>
        (v: React.ChangeEvent<HTMLInputElement>) => {
          const val = v.target.checked
          fields.update(index, { ...rate, delete: val })
          // uncheck parent standard rate
          if (!val) {
            const standardRateIndex = findIndex((sr: ContractModelRate) => sr.guid === rate.standard_rate_guid)(
              values.basicRates
            )
            // @ts-ignore // works fine but TS throws an error
            form.change(`basicRates[${standardRateIndex}].delete`, false)
          }
        },
      [form, values.basicRates]
    )

    return (
      <FieldArray name="enhancedRates">
        {({ fields }) => {
          return (
            <>
              <Table size="small">
                <RateEditorTableHead {...{ service, isEnhanced: true }} />
                <TableBody>
                  {fields.value?.length ? (
                    fields.map((name, index) => {
                      const rate = fields.value[index] as ContractModelEnhancedRate
                      const rateModifierLabel =
                        rate.modifier_type === ContractModelEnhancedRateModifierType.ADD ? "Addition" : "Multiplier"
                      const isToBeDeleted = rate?.delete

                      const standardRate = getByGuid({ items: values.basicRates, guid: rate.standard_rate_guid })

                      return (
                        <TableRow
                          key={index}
                          style={{
                            opacity: isToBeDeleted ? 0.3 : 1,
                            backgroundColor: isToBeDeleted ? grey[100] : "transparent"
                          }}
                        >
                          <TableCell>
                            <Field<boolean>
                              type="checkbox"
                              label=""
                              component={Checkbox}
                              name={`${name}.delete`}
                              onChange={handleDeleteClick({ fields, rate, index })}
                            />
                          </TableCell>
                          <TableCell>
                            <Field
                              name={`${name}.day_type_alias`}
                              label="Day Type *"
                              component={Select}
                              options={dayTypeEnhancedOptions}
                            />
                          </TableCell>
                          <TableCell>
                            <Field
                              name={`${name}.standard_rate_guid`}
                              label="Standard rate *"
                              component={Select}
                              options={standardRateOptions}
                            />
                          </TableCell>
                          <TableCell>
                            <Field
                              type="string"
                              label="Modifier"
                              component={Select}
                              name={`${name}.modifier_type`}
                              options={[
                                { title: "Add", value: ContractModelEnhancedRateModifierType.ADD },
                                { title: "Multiply", value: ContractModelEnhancedRateModifierType.MULTIPLY }
                              ]}
                            />
                          </TableCell>
                          <TableCell>
                            <Field name={`${name}.modifier`} label={rateModifierLabel} component={TextField} />
                          </TableCell>
                          <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>
                            {getUpliftEnhancedRatePrice({ standardRate, enhancedRate: rate })}
                          </TableCell>
                        </TableRow>
                      )
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <EmptyData message="No rates found, start by adding one." />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                <Button
                  fullWidth={false}
                  data-cy={testingId.contractModel.rates.addButton}
                  onClick={() => {
                    fields.push({})
                  }}
                >
                  Add new row
                </Button>
              </Box>
            </>
          )
        }}
      </FieldArray>
    )
  }
)

RateEditorEnhanced.displayName = "RateEditorEnhanced"
