import React from "react"
import { Checkbox } from "components/Form"
import { Field } from "react-final-form"
import testingId from "constants/testingId"
import { SxProps, Theme, TableBody, TableRow, TableCell } from "@mui/material"

export interface Row {
  guid?: string
  data: React.ReactNode[]
  canBeDeleted: boolean
  navigate?: Function
}

export interface TableBodyProps {
  listName: string
  addDeleteCheckbox?: boolean
  sx?: SxProps<Theme>
  rows?: Row[]
  onRowChecked?: Function
}

export const Body: React.FC<TableBodyProps> = (props) => {
  const { listName, sx, rows, onRowChecked } = props

  const onChecked = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, rowGuid?: string) => {
    event.stopPropagation()
    if (onRowChecked) onRowChecked(rowGuid)
  }

  return (
    <>
      <TableBody>
        {rows?.map((row) => {
          return (
            <TableRow
              key={row.guid}
              hover
              sx={sx}
              onClick={() => row.navigate && row.navigate()}
              data-cy={testingId.contractModel.calendar.list.tableRow}
            >
              <TableCell align="center">
                {row.canBeDeleted && (
                  <Field<boolean>
                    type="checkbox"
                    label=""
                    component={Checkbox}
                    name={`${listName}[p${row.guid}][delete]`}
                    data-cy={testingId.contractModel.calendar.list.deleteCheckbox}
                    onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => onChecked(event, row.guid)}
                  />
                )}
              </TableCell>

              {row.data.map((component: React.ReactNode, dataIdx: number) => {
                return (
                  <TableCell key={`${row.guid}-${dataIdx}`} data-cy={testingId.contractModel.calendar.list.tableCell}>
                    {component}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </>
  )
}
