import { FormStage } from "constants/form"
import {
  ContractModelService,
  ContractModelServiceRequestWithBands,
  ServiceBandRoundingTypeAlias,
  ContractModel
} from "data/finance/contractModel/types"
import { FORM_ERROR } from "final-form"
import { validatePeriodDates, validatePeriodInParent, validatePeriodWithSiblings } from "lib/dateTimeValidators"
import { ValidationErrorsDefined } from "lib/types"
import { assocPath, isNil, mergeDeepLeft } from "ramda"

/**
 * Validates a list of bands
 * @param ContractModelServiceRequestWithBands values - Actual values of the form
 * {
 *   ... ContractModelService
 *   bands: [
 *      {
 *       guid: "082f1449-e790-4719-8db2-33dabcd5b311",
 *       minimum_duration_minutes: 0,
 *       maximum_duration_minutes: 10,
 *       rounding_type_alias: NEXT,
 *       rounding_minutes": 3
 *     },
 *     {
 *       guid: "0da0662d-19b7-406c-be0a-77c62e2038d6",
 *       minimum_duration_minutes: 10,
 *       maximum_duration_minutes: 30,
 *       rounding_type_alias: NEXT,
 *       rounding_minutes": 3
 *     },
 *     {
 *       minimum_duration_minutes: 30,
 *       maximum_duration_minutes: null,
 *       rounding_type_alias: "NEXT",
 *       rounding_minutes: 10
 *     }
 *   }
 * }
 *
 * @returns errors associated to form fields if invalid or a blank object
 */
export const validateServiceWithBands =
  ({
    services,
    contractModel,
    formStage,
    paymentModels,
    isInvoicePaymentService
  }: {
    services?: ContractModelService[]
    contractModel: ContractModel
    formStage: FormStage
    paymentModels?: ContractModel[]
    isInvoicePaymentService?: boolean
  }) =>
  (values: ContractModelServiceRequestWithBands): ValidationErrorsDefined => {
    let errors: ValidationErrorsDefined = {}

    // do not validate stage one
    if (formStage === FormStage.CREATE) return errors

    // start is before end
    errors = mergeDeepLeft(validatePeriodDates(values), errors)

    // compare to parent contract model
    errors = mergeDeepLeft(
      errors,
      validatePeriodInParent({
        values,
        parent: { start: contractModel.start, end: contractModel.end },
        itemName: "Service",
        parentName: "Contract model"
      })
    )

    const paymentGroupModel = paymentModels?.find((pm) => pm.guid === values.payment_contract_guid)

    if (isInvoicePaymentService && paymentGroupModel && values.payment_contract_guid) {
      errors = mergeDeepLeft(
        errors,
        validatePeriodInParent({
          values,
          parent: { start: paymentGroupModel?.start, end: paymentGroupModel?.end },
          itemName: "Service",
          parentName: "Payment Group"
        })
      )
    }

    // compare to other services
    errors = mergeDeepLeft(
      errors,
      validatePeriodWithSiblings<ContractModelService>({
        items: services,
        values,
        label: "service",
        comparePrimaryKey: ["type_guid", (item) => item?.service_type?.guid],
        compareSecondaryKey: ["payment_contract_guid", (item) => item?.payment_contract_guid]
      })
    )

    // do not validate bands for new service
    if (!values.bands) return errors

    const sortedBands = values.bands
      .filter((item) => item.delete !== true)
      .sort((a, b) => a.minimum_duration_minutes - b.minimum_duration_minutes)

    if (sortedBands.length === 0) {
      errors[FORM_ERROR] = "Service must have at least one band."
      return errors
    }

    sortedBands.forEach((item, key) => {
      const nextItem = sortedBands[key + 1]

      const min =
        item.minimum_duration_minutes !== undefined ? parseInt(item.minimum_duration_minutes.toString(), 10) : null
      const max = item.maximum_duration_minutes ? parseInt(item.maximum_duration_minutes.toString(), 10) : null

      if (min === max) {
        errors = assocPath(["bands", key, "maximum_duration_minutes"], "Cannot be the same as minimum duration", errors)
      }

      // First item in the list
      if (key === 0 && min && min > 0) {
        errors = assocPath(["bands", key, "minimum_duration_minutes"], "First band has to start at 0 minutes", errors)
      }

      if (
        nextItem &&
        nextItem.minimum_duration_minutes &&
        max !== parseInt(nextItem.minimum_duration_minutes.toString(), 10)
      ) {
        errors = assocPath(["bands", key, "maximum_duration_minutes"], "Maximum and minimum duration gap", errors)
        errors = assocPath(
          ["bands", key + 1, "minimum_duration_minutes"],
          "Minimum duration must follow previous maximum duration",
          errors
        )
      }

      if (
        (item.rounding_type_alias === ServiceBandRoundingTypeAlias.NEAR ||
          item.rounding_type_alias === ServiceBandRoundingTypeAlias.DOWN ||
          item.rounding_type_alias === ServiceBandRoundingTypeAlias.UP ||
          item.rounding_type_alias === ServiceBandRoundingTypeAlias.TO) &&
        (item.rounding_minutes === undefined || item.rounding_minutes === null)
      ) {
        errors = assocPath(["bands", key, "rounding_minutes"], "Required", errors)
      }

      // We are on last band
      if (!nextItem) {
        if (!isNil(max)) {
          errors = assocPath(
            ["bands", key, "maximum_duration_minutes"],
            "A band without maximum duration must exist",
            errors
          )
        }
      }
    })

    return errors
  }
