import { useCallback, useEffect, useState } from "react"
import Config from "./config"
import { addHeapAdapters, captureMouseDownHeap, removeHeapHandlers } from "./helpers/heap"

interface HeapCallback {
  name: string
  fn: () => void
}
declare global {
  interface Window {
    heapReadyCb: HeapCallback[]
    heap: IHeap
  }
}

type UserProps = {
  userId?: string
  userType?: string
  username?: string
  branchId?: string
  branchName?: string
}

const HEAP_ADAPTERS = [
  {
    name: "captureMouseDown",
    fn: captureMouseDownHeap
  }
]

const useHeap = ({ userId, userType, username, branchId, branchName }: UserProps): void => {
  const HEAP_APP_ID = Config.getEnvVariable("APP_HEAP_APP_ID")
  const [isInitialized, setIsInitialized] = useState<boolean | null>(null)
  const [heapUser, setHeapUser] = useState(false)

  useEffect(() => {
    if (!HEAP_APP_ID) {
      return
    }

    if (!heap.loaded) {
      heap.load(HEAP_APP_ID)
    }

    setIsInitialized(true)
    addHeapAdapters(HEAP_ADAPTERS)

    return () => {
      removeHeapHandlers()
    }
  }, [HEAP_APP_ID])

  const trackUser = useCallback(
    ({ userId = "", userType = "", username = "", branchId = "", branchName = "" }: UserProps): void => {
      heap.identify(userId)

      heap.addUserProperties({
        user_type: userType,
        user_name: username,
        branch_id: branchId,
        branch_name: branchName
      })
    },
    []
  )

  useEffect(() => {
    if (!userId || !username || !branchId || !branchName || !isInitialized || !userType || heapUser) {
      return
    }

    trackUser({ userId, userType, username, branchId, branchName })
    setHeapUser(true)
  }, [userId, username, branchId, branchName, isInitialized, userType, heapUser, trackUser])
}
export default useHeap
