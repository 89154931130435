import Grid from "@mui/material/Grid"
import { Select } from "components/Form"
import React, { memo } from "react"
import { Tooltip } from "@mui/material"
import { useSchema } from "data/schema/useSchema"
import { Field } from "react-final-form"
import { StateSchema } from "data/schema/types"

const RunSelect = ({
  isInvoice,
  options = [],
  disabled
}: {
  options?: StateSchema
  disabled?: boolean
  isInvoice: boolean
}) => (
  <Field
    name="runs"
    label={isInvoice ? "Invoice Run" : "Payment Run"}
    component={Select}
    multiselect
    options={options}
    disabled={disabled}
  />
)

export const TransactionGroupFilterRunSelect: React.FC<{ isInvoice: boolean }> = memo(({ isInvoice }) => {
  const { InvoiceRun, PaymentRun } = useSchema(["InvoiceRun", "PaymentRun"])

  const runOptions = isInvoice ? InvoiceRun : PaymentRun

  return (
    <Grid item md={4}>
      {runOptions?.length ? (
        <RunSelect options={runOptions} isInvoice={isInvoice} />
      ) : (
        <Tooltip title="No Run has been set up yet">
          <div>
            <RunSelect disabled isInvoice={isInvoice} />
          </div>
        </Tooltip>
      )}
    </Grid>
  )
})

TransactionGroupFilterRunSelect.displayName = "TransactionGroupFilterRunSelect"
