import React, { PropsWithChildren } from "react"
import { StepsTitle, StepsTitleProps } from "./StepsTitle"
import { StepsFooter, StepsFooterProps } from "./StepsFooter"

interface ContainerStepsProps {
  StepsTitle: React.FC<StepsTitleProps>
  StepsFooter: React.FC<StepsFooterProps>
}

const ContainerSteps: React.FC<PropsWithChildren> & ContainerStepsProps = ({ children }) => {
  return <div>{children}</div>
}

ContainerSteps.StepsTitle = StepsTitle
ContainerSteps.StepsFooter = StepsFooter

export { ContainerSteps }
