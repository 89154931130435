import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { ContractModelType } from "constants/modelTypes"
import { addOrRemoveItemInList } from "helpers/helpers"
import { useContractModelCalendarListQuery } from "data/finance/contractModel/queries"
import { useContractCalendarDeleteHandler } from "./handlers/useContractCalendarDeleteHandler"
import { filterCalendars } from "./helpers/filterCalendars"
import { queryStringToObject } from "lib/queryString"
import { ContentContainer, Link } from "components"
import testingId from "constants/testingId"
import { Box } from "@mui/material"
import { CalendarListFilter } from "./components/calendars/CalendarListFilter"
import CalendarListTable from "./components/calendars/CalendarListTable"
import { useLocation } from "react-router-dom"
import { ContractModelTitle } from "./components"
import { Button } from "components/Form"
import { CalendarFilter } from "./types"
import { ContractModel } from "data/finance/contractModel/types"

interface Props {
  contractModel: ContractModel
  modelType: ContractModelType
}

const CalendarList: React.FC<Props> = ({ contractModel, modelType }) => {
  const location = useLocation()
  const isInvoiceModel = modelType === ContractModelType.INVOICE

  const [selectedCalendars, setSelectedCalendars] = useState<string[]>([])
  const { data: calendars = [], refetch } = useContractModelCalendarListQuery({
    modelType,
    contractGuid: contractModel.guid
  })

  const [filter, setFilter] = useState<CalendarFilter>(queryStringToObject(location?.search))

  useEffect(() => {
    setSelectedCalendars([])
  }, [calendars, setSelectedCalendars])

  const onSelectRow = useCallback(
    (rowId: string) => {
      setSelectedCalendars((currentCalendars) => addOrRemoveItemInList(currentCalendars, rowId))
    },
    [setSelectedCalendars]
  )

  const onDelete = useContractCalendarDeleteHandler({
    modelType,
    contractGuid: contractModel.guid,
    selectedCalendars,
    refetch
  })

  const enableDelete = selectedCalendars.length > 0
  const filteredCalendars = useMemo(() => filterCalendars(calendars, filter), [calendars, filter])

  return (
    <ContentContainer data-cy={testingId.contractModel.calendar.list.screen}>
      <ContractModelTitle
        modelType={modelType}
        title={
          <>
            <Link to={`../settings`}>{contractModel.title}</Link> / {isInvoiceModel ? "Billing" : "Payment"} calendars
          </>
        }
        action={
          <Link to="new">
            <Button data-cy={testingId.contractModel.calendar.list.addButton}>
              {isInvoiceModel ? "ADD BILLING CALENDAR" : "ADD PAY CALENDAR"}
            </Button>
          </Link>
        }
      />
      <Box m={3} mx={0}>
        <CalendarListFilter {...{ filter, setFilter }} />
        <CalendarListTable {...{ calendars: filteredCalendars, enableDelete, onSelectRow, onDelete, isInvoiceModel }} />
      </Box>
    </ContentContainer>
  )
}

export default memo(CalendarList)
