import { ContentContainer, Header } from "components"
import { Unit } from "data/finance/unit/types"
import React, { memo } from "react"
import { UnitProfile } from "./components/UnitProfile"
import { Typography } from "@mui/material"

export const UnitEdit: React.FC<{ unit: Unit }> = memo(({ unit }) => {
  UnitEdit.displayName = "UnitEdit"

  const Title = () => (
    <>
      Unit - {unit.name} -{" "}
      <Typography
        component="span"
        variant="h4"
        sx={{
          color: "primary.main"
        }}
      >
        {unit.reference}
      </Typography>
    </>
  )

  return (
    <ContentContainer>
      <Header {...{ title: <Title /> }} />
      <UnitProfile {...{ mode: "update", unit }} />
    </ContentContainer>
  )
})
