import createAxiosInstance from "./instance";
import Config from "../lib/config";
import {
  ApiAddressSearchDataContract,
  ApiAddressSearchContract,
} from "../models";

const GEO_CODING_API_BASE_URL = Config.getEnvVariable("APP_GEOCODING_API_URL");

export const geoCodingApiInstance = createAxiosInstance(
  GEO_CODING_API_BASE_URL
);

const geoCodingApi = {
  /**
   * Get suggested addresses for input search terms.
   *
   * @param search Searched address chunk.
   * @param country OPTIONAL - Country to search for the address.
   */
  addressSuggestions: (
    search: string,
    country = "UK"
  ): Promise<ApiAddressSearchDataContract[]> => {
    return geoCodingApiInstance.post(
      `/v2/geocoding/address/search?country=${country}`,
      search,
      {
        headers: { "Content-Type": "text/plain" },
      }
    );
  },

  /**
   * Get suggested addresses for input search terms.
   *
   * @param searchId The address search id to retrieve.
   * @param country OPTIONAL - Country to search for the address.
   */
  addressFromSuggestionId: (
    searchId: string,
    country = "UK"
  ): Promise<ApiAddressSearchContract> => {
    return geoCodingApiInstance.post(
      `/v1/geocoding/address/search/${searchId}?country=${country}`,
      searchId,
      {
        headers: { "Content-Type": "text/plain" },
      }
    );
  },
};

export default geoCodingApi;
