import { useCarerProfilePutMutation, useCarerPutMutation } from "../mutations"
import { ApiError } from "stores/useUIStore/types"
import { queryKeys as carerQueryKeys } from "data/core/carer/queries"
import { queryKeys as mobileQueryKeys } from "data/mobile/queries"
import { useQueryClient } from "react-query"
import { useApiMessage } from "stores"

export const useCarerEditHandler = () => {
  const queryCache = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const { mutate: editCarerProfile } = useCarerProfilePutMutation({
    onSuccess: (response, { carerGuid }) => {
      showSuccessMessage("Carer successfully edited.")
      queryCache.setQueryData(carerQueryKeys.getCarerProfileKey(carerGuid), response)
      queryCache.invalidateQueries(mobileQueryKeys.getCarersOnboardingStatusKey({ carerGuid }))
    },
    onError: (apiError: ApiError) => {
      showErrorMessage(`Something went wrong when"editing carer in Carer Profile DB.`, { apiError })
    }
  })

  const { mutate: editCarer } = useCarerPutMutation({
    onSuccess: (response, { carerProfilePayload }) => {
      queryCache.setQueryData(carerQueryKeys.getCarerKey(response.guid), response)
      if (carerProfilePayload?.id) {
        editCarerProfile({ carerGuid: carerProfilePayload.id, payload: carerProfilePayload })
      } else {
        showSuccessMessage("Carer successfully edited.")
      }
    },
    onError: (apiError: ApiError) => {
      showErrorMessage("Something went wrong when editing carer in Core.", { apiError })
    }
  })

  return editCarer
}
