import React, { memo, useMemo } from "react"
import { FormSectionHeader } from "components/Form"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { formatDate } from "lib/datetime"
import { Link } from "components"
import testingId from "constants/testingId"
import { sortByDate } from "lib/sortByDate"
import { sort } from "ramda"
import { StatusEnum } from "data/commonTypes"
import Auth from "lib/Auth"
import { Permission } from "constants/permission"
import { RecalculateButton } from "components/RecalculateButton"
import { ApiClientContractContribution } from "data/finance/client/types"

const headers = ["Start date", "Ends before", "Weekly contribution", "Contract name", "Status"]

type OwnProps = {
  contributions: ApiClientContractContribution[]
}

const sortByStartFunction = sortByDate(false)((contribution: ApiClientContractContribution) => contribution.start)

export const ClientContractContributionPrivate: React.FC<OwnProps> = memo(({ contributions }) => {
  ClientContractContributionPrivate.displayName = "ClientContractContributionPrivate"

  const hasRecalcPermission = Auth.hasPermission([Permission.FINANCE_RECALCULATE_EDIT])

  const sortedContributions = useMemo(() => {
    const future = contributions.filter((c) => c.status === StatusEnum.FUTURE)
    const current = contributions.filter((c) => c.status === StatusEnum.CURRENT)
    const expired = contributions.filter((c) => c.status === StatusEnum.EXPIRED)
    return [
      ...sort(sortByStartFunction, future),
      ...sort(sortByStartFunction, current),
      ...sort(sortByStartFunction, expired)
    ]
  }, [contributions])

  return (
    <>
      <FormSectionHeader
        title="Client contributions"
        data-cy={testingId.client.contract.detail.contributionPrivateHeader}
      />
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header: string) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
            {hasRecalcPermission && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedContributions.map((contribution) => (
            <TableRow key={contribution.guid} data-cy={testingId.client.contract.detail.contributionPrivateTableRow}>
              <TableCell>{formatDate(contribution.start)}</TableCell>
              <TableCell>{contribution.end ? formatDate(contribution.end) : "--/--/--"}</TableCell>
              <TableCell>{contribution.rate}</TableCell>
              <TableCell>
                <Link {...{ to: `../contracts/${contribution.user_contract_guid}` }}>
                  {contribution.user_contract_title}
                </Link>
              </TableCell>
              <TableCell>{contribution.status}</TableCell>
              {hasRecalcPermission && (
                <TableCell>
                  <RecalculateButton {...{ type: "Contribution", relation_guids: [contribution.guid] }} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
})
