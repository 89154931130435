import { Button } from "components/Form"
import React, { memo, useState } from "react"
import { Link } from "components"
import { Box, styled, Typography } from "@mui/material"
import { ContractModelTitle } from "."
import { ContractModelType } from "constants/modelTypes"
import { ContractModel, ContractModelSalary } from "data/finance/contractModel/types"
import ConfirmationDialog from "components/ConfirmationDialog"
import { useContractModelSalaryDeleteMutation } from "data/finance/contractModel/mutations"

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between"
})

interface OwnProps {
  modelType: ContractModelType
  contractModel: ContractModel
  salary?: ContractModelSalary
}

export const SalaryBandFormTitle: React.FC<OwnProps> = memo(({ modelType, contractModel, salary }) => {
  SalaryBandFormTitle.displayName = "SalaryBandFormTitle"

  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false)

  const { mutateAsync: deleteSalaryBand } = useContractModelSalaryDeleteMutation()

  return (
    <StyledBox>
      <ContractModelTitle
        modelType={modelType}
        title={
          <>
            <Link to="../settings">{contractModel.title}</Link> / {salary ? "Salary band" : "Add salary band"}
          </>
        }
      />
      {salary ? (
        <>
          <Button type="button" fullWidth={false} onClick={() => setShowConfirmationDialog(true)}>
            Delete salary band
          </Button>
          <ConfirmationDialog
            title="Delete Salary band"
            open={showConfirmationDialog}
            onCancel={() => setShowConfirmationDialog(false)}
            onConfirm={() => {
              deleteSalaryBand({ contractGuid: contractModel.guid, salaryGuid: salary.guid })
            }}
          >
            <Typography variant="body1">Are you sure you want to delete this Salary band?</Typography>
          </ConfirmationDialog>
        </>
      ) : null}
    </StyledBox>
  )
})
