import { ApiCarerBranch, ParsedCarerBranch } from "data/finance/carer/types"
import { map, pipe, prepend, remove, uniq } from "ramda"
import { useMemo } from "react"

interface OwnProps {
  branches?: ApiCarerBranch[]
  carerGuid?: string
}

export interface BranchComparator {
  uniqIDCount: number
  hasNoPrimary: boolean
  hasMultiplePrimary: boolean
}

// copied query parser from Finance MFE
export const parseCarerBranchList =
  (carerGuid?: string) =>
  (response: ApiCarerBranch[]): ParsedCarerBranch[] => {
    const thisBranchUser = response.find((branch) => branch.guid === carerGuid)?.user.guid

    if (!thisBranchUser) {
      return response
    }

    const orderedUniqMatcher = pipe(
      map((branch: ApiCarerBranch) => branch.user.guid),
      uniq,
      (arr: string[]) => remove(arr.indexOf(thisBranchUser), 1)(arr),
      prepend(thisBranchUser)
    )(response)

    const matcher: Record<string, string> = orderedUniqMatcher.reduce((res, uniqCarerGuid, index) => {
      return { ...res, [uniqCarerGuid]: `${String.fromCharCode(97 + index).toUpperCase()}` }
    }, {})

    return response
      .map((branch) => ({
        ...branch,
        user: {
          ...branch.user,
          idAlias: matcher[branch.user.guid],
          idAliasMatches: branch.user.guid === thisBranchUser
        }
      }))
      .sort((a, b) => Number(b.user.idAliasMatches) - Number(a.user.idAliasMatches))
  }

export const useCarerBranchComparator = ({ branches = [], carerGuid }: OwnProps): BranchComparator =>
  useMemo(() => {
    const parsedBranches = parseCarerBranchList(carerGuid)(branches)

    const thisBranch = parsedBranches?.find((branch) => branch.guid === carerGuid)

    if (!parsedBranches || !thisBranch) {
      return { uniqIDCount: 0, hasNoPrimary: false, hasMultiplePrimary: false }
    }

    const uniqIDCount = uniq(parsedBranches.map((branch) => branch.user.guid)).length

    const hasNoPrimary = parsedBranches
      .filter((branch) => branch.user.idAliasMatches)
      .every((branch) => !branch.is_primary)

    const hasMultiplePrimary =
      parsedBranches.filter((branch) => branch.user.idAliasMatches && branch.is_primary).length > 1

    return { uniqIDCount, hasNoPrimary, hasMultiplePrimary }
  }, [branches, carerGuid])
