import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import React, { memo } from "react"
import { Field, FormSpy } from "react-final-form"
import { Button, Checkbox, FormSectionHeader, Select, TextField } from "components/Form"
import { grey } from "@mui/material/colors"
import { FieldArray } from "react-final-form-arrays"
import { SalaryBandEditorHeader } from "./SalaryBandEditorHeader"
import { useSchema } from "data/schema/useSchema"
import { SelectDummy } from "components/Form/SelectDummy"

export const SalaryBandEditor: React.FC = memo(() => {
  const { Day, HolidayTier } = useSchema(["Day", "HolidayTier"])

  return (
    <>
      <FormSectionHeader title="Bandings" />
      <FormSpy>
        {() => (
          <FieldArray name="bands">
            {(bandArrayProps) => {
              const fields = bandArrayProps.fields
              return (
                <>
                  <Table size="small">
                    <SalaryBandEditorHeader />
                    <TableBody>
                      {fields.map((name, index) => {
                        const field = fields.value[index]

                        return (
                          <TableRow
                            key={name}
                            style={{
                              opacity: field.delete === true ? 0.3 : 1,
                              backgroundColor: field.delete === true ? grey[100] : "transparent"
                            }}
                          >
                            <TableCell>
                              <Field type="checkbox" label="" component={Checkbox} name={`${name}.delete`} />
                              <Field type="hidden" component="input" name={`${name}.guid`} />
                            </TableCell>
                            <TableCell>
                              {Day ? (
                                <Field
                                  name={`${name}.day_type_alias`}
                                  label="Day Type"
                                  component={Select}
                                  options={Day}
                                />
                              ) : (
                                <SelectDummy value="loading..." />
                              )}
                            </TableCell>
                            <TableCell>
                              {HolidayTier ? (
                                <Field
                                  name={`${name}.holiday_tier_guid`}
                                  label="Tier"
                                  component={Select}
                                  options={HolidayTier}
                                  disabled={field?.day_type_alias !== "HOL"}
                                />
                              ) : (
                                <SelectDummy value="loading..." />
                              )}
                            </TableCell>
                            <TableCell>
                              <Field name={`${name}.start`} component={TextField} type="time" />
                            </TableCell>
                            <TableCell>
                              <Field name={`${name}.end`} component={TextField} type="time" />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                  <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                    <Button fullWidth={false} onClick={() => fields.push({})}>
                      Add new row
                    </Button>
                  </Box>
                </>
              )
            }}
          </FieldArray>
        )}
      </FormSpy>
    </>
  )
})

SalaryBandEditor.displayName = "SalaryBandEditor"
