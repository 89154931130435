import { create } from "zustand"

export const DEFAULT_STORE = {
  isNewChannelCreateModalOpen: false,
  imageModal: {
    open: false,
    src: undefined
  }
}

interface Modals {
  isNewChannelCreateModalOpen: boolean
  imageModal: {
    open: boolean
    src?: Blob
  }
  setIsNewChannelCreateModalOpen: (isOpen: boolean) => void
  toggleImageModal: (src?: Blob) => void
}

export const useModalsStore = create<Modals>((set) => ({
  ...DEFAULT_STORE,
  setIsNewChannelCreateModalOpen: (isNewChannelCreateModalOpen: boolean) =>
    set(() => ({
      isNewChannelCreateModalOpen
    })),
  toggleImageModal: (src?: Blob) =>
    set((state) => ({
      imageModal: { open: !state.imageModal.open, src }
    }))
}))
