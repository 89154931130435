import datetime from "lib/datetime"
import { Visit } from "models/Visit"

export const getNfcCheckInStatusText = (visit: Visit, hasClientNfc = false): string => {
  if (!hasClientNfc && visit.isGeoLocationCheckIn()) {
    return "Not Applicable"
  }

  if (visit.check_in?.nfc_time) {
    return datetime.formatTime(visit.check_in.nfc_time)
  }
  return "Not check-in"
}

export const getNfcCheckOutStatusText = (visit: Visit, hasClientNfc = false): string => {
  if (!hasClientNfc && visit.isGeoLocationCheckOut()) {
    return "Not Applicable"
  }

  if (visit.check_out?.nfc_time) {
    return datetime.formatTime(visit.check_out.nfc_time)
  }
  return "Not check-out"
}
