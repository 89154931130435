import { CoreApi, CompanyApi } from "api"
import {
  ListAssignedOperatorsResponse,
  ListOperatorsResponse,
  ListUnassignedOperatorsResponse,
  OperatorsRequest
} from "data/carer-profile/types"
import { enabledByMandatoryUrlParams } from "data/helpers"
import { ApiUserOperator } from "models"
import { QueryKey, useQuery, UseQueryResult } from "react-query"

export enum QueryKeyParts {
  OPERATOR = "operator",
  LIST = "list",
  DETAIL = "detail",
  BRANCHES = "branches",
  UNASSIGNED_OPERATORS = "unassigned-operators",
  ASSIGNED_OPERATORS = "assigned-operators"
}

export interface BranchesListResponse {
  guid: string
  title: string
  status: string
}

export const queryKeys = {
  getOperatorListKey: (): QueryKey => [QueryKeyParts.OPERATOR, QueryKeyParts.LIST],
  getOperatorDetailKey: (id?: string): QueryKey => [QueryKeyParts.OPERATOR, QueryKeyParts.DETAIL, { id }],
  getUnassignedOperators: (): QueryKey => [QueryKeyParts.UNASSIGNED_OPERATORS],
  getAssignedOperators: (): QueryKey => [QueryKeyParts.ASSIGNED_OPERATORS],
  getOperatorDetailBranchesKey: (id?: string): QueryKey => [
    QueryKeyParts.OPERATOR,
    QueryKeyParts.DETAIL,
    QueryKeyParts.BRANCHES,
    { id }
  ]
}

export const useOperatorListQuery = (): UseQueryResult<ListOperatorsResponse, Error> =>
  useQuery({
    queryKey: queryKeys.getOperatorListKey(),
    queryFn: async () => {
      return CoreApi.get("/operator")
    }
  })

export const useOperatorDetailQuery = ({ id }: { id?: string }): UseQueryResult<ApiUserOperator, Error> =>
  useQuery({
    queryKey: queryKeys.getOperatorDetailKey(id),
    queryFn: async () => {
      return CoreApi.get(`/operator/${id}`)
    },
    staleTime: 4 * 60 * 60 * 1000, // 4 hours
    ...enabledByMandatoryUrlParams([id])
  })

export const useBranchesByOperatorListQuery = (): UseQueryResult<BranchesListResponse[], Error> =>
  useQuery({
    queryFn: async () => {
      return CompanyApi.get(`v1/branches/operator-assigned`)
    }
  })

export const useUnassignedOperatorsListQuery = ({
  branchGuid,
  search,
  page,
  pageSize
}: OperatorsRequest): UseQueryResult<ListAssignedOperatorsResponse, Error> => {
  const queryParams = new URLSearchParams({
    page: `${page}`,
    page_size: `${pageSize}`
  })

  if (search) {
    queryParams.append("search", search)
  }

  return useQuery({
    queryKey: [...queryKeys.getUnassignedOperators(), branchGuid, search, page, pageSize],
    queryFn: async () => {
      return await CoreApi.get(`/branch/${branchGuid}/unassigned-operators?${queryParams}`)
    }
  })
}

export const useAssignedOperatorsListQuery = ({
  branchGuid,
  search,
  page,
  pageSize
}: OperatorsRequest): UseQueryResult<ListUnassignedOperatorsResponse, Error> => {
  const queryParams = new URLSearchParams({
    page: `${page}`,
    page_size: `${pageSize}`
  })

  if (search) {
    queryParams.append("search", search)
  }

  return useQuery({
    queryKey: [...queryKeys.getAssignedOperators(), branchGuid, search, page, pageSize],
    queryFn: async () => {
      return await CoreApi.get(`/branch/${branchGuid}/assigned-operators?${queryParams}`)
    }
  })
}
