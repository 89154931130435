import { TableCell } from "@mui/material"
import { Checkbox } from "components/Form"
import { Permission } from "constants/permission"
import {
  AdditionalSearchFilterValues,
  AdditionalSearchFormValues,
  ApiPaymentAdditionalSearch
} from "data/finance/additional/type"
import Auth from "lib/Auth"
import { keys, pipe, filter as Rfilter } from "ramda"
import React, { memo, useCallback, useEffect, useMemo } from "react"
import { Field, FormRenderProps } from "react-final-form"
import { getCheckboxDisabledText } from "./helpers/getCheckboxDisabledText"

interface OwnProps {
  formProps: FormRenderProps<AdditionalSearchFormValues, Partial<AdditionalSearchFormValues>>
  additionals: ApiPaymentAdditionalSearch[]
  filter: AdditionalSearchFilterValues
}

export const AdditionalSearchTableSelectAll: React.FC<OwnProps> = memo(({ formProps, additionals, filter }) => {
  AdditionalSearchTableSelectAll.displayName = "AdditionalSearchTableSelectAll"

  const allSelectableItemGuids = useMemo(() => {
    const hasApproveEditPermission = Auth.hasPermission([Permission.FINANCE_PROCESSING_CHARGES_APPROVE_EDIT])
    const hasHrApproveEditPermission = Auth.hasPermission([Permission.HR_APPROVER_EDIT])

    return additionals
      .filter((item) => {
        const isHrSelectable = hasHrApproveEditPermission && item.charge_type_alias === "SUSP/PAY"
        const isOtherSelectable = hasApproveEditPermission && item.charge_type_alias !== "SUSP/PAY"

        const isDisabled = getCheckboxDisabledText({ additional: item, pickedStatus: formProps.values.pickedStatus })

        return (isHrSelectable || isOtherSelectable) && !isDisabled
      })
      .map((item) => item.guid)
  }, [additionals, formProps.values.pickedStatus])

  // change selectAll based on selected items
  useEffect(() => {
    const selectedItemGuids = pipe(
      Rfilter((item: boolean) => item),
      keys
    )(formProps.values.items ?? {})

    const areAllSelected = allSelectableItemGuids.every((guid) => selectedItemGuids.includes(guid))

    if (areAllSelected && !formProps.values.selectAll) {
      formProps.form.change("selectAll", true)
    }
    if (!areAllSelected && formProps.values.selectAll) {
      formProps.form.change("selectAll", false)
    }
  }, [formProps.form, formProps.values, allSelectableItemGuids])

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      const allSelectableAsFormItems = allSelectableItemGuids.reduce(
        (res, itemGuid) => ({ ...res, [itemGuid]: true }),
        {}
      )

      e.stopPropagation()
      if (formProps.values.selectAll) {
        formProps.form.change("items", {})
      } else {
        formProps.form.change("items", allSelectableAsFormItems)
      }
    },
    [allSelectableItemGuids, formProps.form, formProps.values.selectAll]
  )

  return (
    <TableCell>
      {!!filter.approved && (
        <Field<boolean>
          {...{
            type: "checkbox",
            name: `selectAll`,
            component: Checkbox,
            disabled: !filter.approved,
            onClick
          }}
        />
      )}
    </TableCell>
  )
})
