import React from "react"
import { Grid } from "@mui/material"
import { Field } from "react-final-form"
import { FormSectionHeader, Checkbox } from "components/Form"
import testingId from "constants/testingId"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { BranchFeatures } from "lib/hooks/branchInfo/types"

const ClientProfileSectionCm = () => {
  const { hasBranchPermission } = useBranchPermissions()
  const hasBranchCm2000enabled = hasBranchPermission(BranchFeatures.CM2000)

  if (!hasBranchCm2000enabled) {
    return null
  }

  return (
    <>
      <FormSectionHeader title="CM Mobile" data-cy={testingId.client.create.cm2000Header} />
      <Grid container spacing={8}>
        <Grid item md={6}>
          <Field
            type="checkbox"
            label="Enable CM Mobile"
            component={Checkbox}
            name="cm2000.enabled"
            noValidate
            data-cy={testingId.client.create.enableCm2000Checkbox}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ClientProfileSectionCm
