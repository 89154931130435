import React from "react"
import LoopIcon from "@mui/icons-material/Loop"
import Tooltip from "@mui/material/Tooltip"

const TransactionInProgress: React.FC = () => {
  return (
    <Tooltip title="This transaction is currently being processed">
      <LoopIcon />
    </Tooltip>
  )
}

export default TransactionInProgress
