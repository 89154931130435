import React, { FC, useMemo } from "react"
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, XAxis, YAxis } from "recharts"
import Box from "@mui/material/Box"
import { CustomLegend } from "./CustomLegend"
import { CustomAxisXTick } from "./CustomAxisXTick"
import { CustomAxisYTick } from "./CustomAxisYTick"
import { RiskScoreValue } from "models/mobilityAssessment"
import { grey } from "@mui/material/colors"
import { useTheme } from "@mui/material"


interface Props {
  records: {
    date: string
    valueLabel: RiskScoreValue | null | undefined
    value: number | null | undefined
  }[]
}

const RiskScoreChart: FC<Props> = ({ records }) => {
  const theme = useTheme();

  const RISK_SCORE_COLORS: Record<RiskScoreValue, string> = useMemo(() =>({
    Low: theme.palette.success.light,
    Medium: theme.palette.warning.main,
    High: theme.palette.error.light
  }), [theme.palette.success.light,theme.palette.warning.main, theme.palette.error.light ]);
  
  return (
  <Box
    sx={{
      width: "100%",
      height: 450,
      backgroundColor: grey[100],
      borderRadius: "8px",
      padding: 2
    }}
  >
    <ResponsiveContainer>
      <BarChart data={records}>
        <Legend verticalAlign="top" align="left" content={CustomLegend} />
        <CartesianGrid strokeDasharray="0" vertical={false} opacity={0.8} />
        <XAxis dataKey="date" height={60} interval={0} tickLine={false} tick={CustomAxisXTick} />
        <YAxis orientation="right" tick={<CustomAxisYTick />} ticks={[1, 2, 3]} tickLine={false} axisLine={false} />
        <Bar dataKey="value">
          {records.map((riskRecord) => (
            <Cell
              key={`cell-${riskRecord.date}`}
              fill={riskRecord.valueLabel ? RISK_SCORE_COLORS[riskRecord.valueLabel] : undefined}
              opacity={0.9}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </Box>
)}

export default RiskScoreChart
