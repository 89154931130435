import { FinanceApi } from "api"
import { RunType } from "constants/runType"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { ApiError } from "stores/useUIStore/types"
import { getRunUrl, queryKeys } from "./queries"
import { useInvalidateSchema } from "data/schema/useInvalidateSchema"
import { useApiMessage, useUIStore } from "stores"
import { Run } from "./types"

interface RunMutationProps {
  title: string
}

export const useRunCreateMutation = ({
  runType
}: {
  runType: RunType
}): UseMutationResult<Run, Error, RunMutationProps> => {
  const queryCache = useQueryClient()
  const invalidateSchema = useInvalidateSchema()
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)

  return useMutation(
    async ({ title }: RunMutationProps): Promise<Run> => {
      return FinanceApi.post(`finance/${getRunUrl(runType)}`, { title })
    },
    {
      onSuccess: () => {
        invalidateSchema(["InvoiceRun", "PaymentRun"])
        queryCache.invalidateQueries(queryKeys.getRunList(runType))
        showSuccessMessage("Run successfully created.")
      }
    }
  )
}

export const useRunPutMutation = ({
  runType,
  run
}: {
  runType: RunType
  run: Run
}): UseMutationResult<Run, Error, RunMutationProps> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()
  const invalidateSchema = useInvalidateSchema()

  return useMutation(
    async ({ title }: RunMutationProps): Promise<Run> => {
      return FinanceApi.put(`finance/${getRunUrl(runType)}/${run.guid}`, { title })
    },
    {
      onSuccess: () => {
        invalidateSchema(["InvoiceRun", "PaymentRun"])
        queryCache.invalidateQueries(queryKeys.getRunList(runType))
        showSuccessMessage("Run successfully updated.")
      }
    }
  )
}

export const useRunDeleteMutation = ({ runType, run }: { runType: RunType; run: Run }) => {
  const queryCache = useQueryClient()
  const invalidateSchema = useInvalidateSchema()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async (): Promise<Run> => {
      return FinanceApi.delete(`finance/${getRunUrl(runType)}/${run.guid}`)
    },
    {
      onSuccess: () => {
        invalidateSchema(["InvoiceRun", "PaymentRun"])
        queryCache.invalidateQueries(queryKeys.getRunList(runType))
        showSuccessMessage("Run successfully deleted.")
      },
      onError: (apiError: ApiError) => {
        if (apiError.status === 424) {
          showErrorMessage(
            `You can not delete Run associated with ${runType === RunType.INVOICE ? "invoice" : "payment"} model.`,
            { apiError }
          )
          return
        }
        showErrorMessage("Something went wrong, please try again.", { apiError })
      }
    }
  )
}
