import { TableBody, TableCell, TableRow } from "@mui/material"
import { EmptyData } from "components"
import testingId from "constants/testingId"
import { SelectableIdsDictionaryModel } from "lib/hooks"
import React, { memo } from "react"
import { mapPaymentTransactionGroup } from "../helpers/mapPaymentTransactionGroup"
import { useLocation, useNavigate } from "react-router-dom"
import { SelectableTableRow } from "components/SelectableTableRow"
import { PaymentTransactionGroup } from "data/finance/paymentProcessing/types"

interface OwnProps {
  transactionGroups: PaymentTransactionGroup[]
  toggleId: (id: string) => void
  selectableIds: SelectableIdsDictionaryModel
  hasCompletedTransactionGroup: boolean
  hasAlertingTransactionGroup: boolean
  hasRewindPermission: boolean
  refetch: () => void
  selectedIds: string[]
}

export const TransactionGroupTableBody: React.FC<OwnProps> = memo(
  ({
    transactionGroups,
    toggleId,
    selectableIds,
    hasCompletedTransactionGroup,
    hasAlertingTransactionGroup,
    hasRewindPermission,
    refetch,
    selectedIds
  }) => {
    const navigate = useNavigate()
    const location = useLocation()

    return (
      <TableBody>
        {transactionGroups.length > 0 ? (
          transactionGroups.map((tg) => (
            <SelectableTableRow
              clickable
              isReady={tg.unconfirmed_visits === false && !tg.completed_at}
              isSelected={selectedIds.includes(tg.guid)}
              hover
              key={tg.guid}
              onClick={() => navigate(`${location.pathname}/${tg.calendar_guid}/${tg.guid}/${tg.contract_guid}`)}
            >
              {mapPaymentTransactionGroup({
                tg,
                toggleId,
                selectableIds,
                sx: {
                  display: "flex",
                  alignItems: "center",
                  "&>label": { marginRight: 0 }
                },
                hasCompletedTransactionGroup,
                hasAlertingTransactionGroup,
                hasRewindPermission,
                refetch
              }).map((value, cellIndex) => (
                <TableCell key={cellIndex} data-cy={testingId.tableCell}>
                  {value}
                </TableCell>
              ))}
            </SelectableTableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={10}>
              <EmptyData message="We did not find any matching contract models for processing in selected dates and filter values." />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    )
  }
)

TransactionGroupTableBody.displayName = "TransactionGroupTableBody"
