import React, { FC } from "react"
import { Box, LinearProgress, Typography, linearProgressClasses } from "@mui/material"
import { RiskScoreValue, RiskScoreValues } from "models/mobilityAssessment"

interface RiskScoreBarProps {
  value: RiskScoreValue | null
}

const BAR_FILL_PERCENTAGE = {
  Low: 33,
  Medium: 66,
  High: 100
} as const

const RiskScoreBar: FC<RiskScoreBarProps> = ({ value }) => {
  const isLow = value === RiskScoreValues.LOW
  const isMedium = value === RiskScoreValues.MEDIUM
  const isHigh = value === RiskScoreValues.HIGH

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        alignSelf: "stretch"
      }}
    >
      <LinearProgress
        sx={(theme) => ({
          minWidth: 300,
          height: theme.spacing(2),
          borderRadius: theme.spacing(1),
          borderStyle: "solid",
          borderWidth: 1,
          ...(isLow ? { borderColor: "success.light" } : {}),
          ...(isMedium ? { borderColor: "warning.main" } : {}),
          ...(isHigh ? { borderColor: "error.light" } : {}),
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: theme.spacing(1),
            maxWidth: 600,
            ...(isLow ? { backgroundColor: "success.light" } : {}),
            ...(isMedium ? { backgroundColor: "warning.main" } : {}),
            ...(isHigh ? { backgroundColor: "error.light" } : {})
          },
          ...(isLow ? { backgroundColor: "success.tint" } : {}),
          ...(isMedium ? { backgroundColor: "warning.tint" } : {}),
          ...(isHigh ? { backgroundColor: "error.light" } : {})
        })}
        variant="determinate"
        value={value ? BAR_FILL_PERCENTAGE[value] : 0}
        color="primary"
      />
      <Typography variant="h6">
        <strong>{value}</strong>
      </Typography>
    </Box>
  )
}

export default RiskScoreBar
