import { AppointmentType } from "constants/appointmentType"
import { InvoiceTransactionItem } from "data/finance/invoiceProcessing/types"
import { PaymentTransactionItem } from "data/finance/paymentProcessing/types"
import { isNil } from "ramda"
import { useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const useProcessingItemRowClick = ({
  filteredItems
}: {
  filteredItems: (PaymentTransactionItem | InvoiceTransactionItem)[]
}): (({ guid, rowIdx }: { guid: string; rowIdx: number }) => void) => {
  const location = useLocation()
  const navigate = useNavigate()

  return useCallback(
    ({ guid, rowIdx }: { guid: string; rowIdx: number }) => {
      const { appointment_type: type } = filteredItems[rowIdx]

      if (isNil(location)) {
        return
      }
      if (type === AppointmentType.SHIFT) {
        navigate(`${location.pathname}/shift/${guid}`)
      } else {
        navigate(`${location.pathname}/${guid}`)
      }
    },
    [filteredItems, location, navigate]
  )
}
