import { ContractModelAccrualPolicy } from "data/finance/contractModel/types"
import { AccrualPolicyFilter } from "../components/AccrualPolicyListFilter"

export const filterContractAccrualPolicies = (items: ContractModelAccrualPolicy[], filter?: AccrualPolicyFilter): ContractModelAccrualPolicy[] => {
  if (filter?.status) {
    return items.filter((i) => i.status === filter.status)
  }

  return items
}
