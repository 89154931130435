import React, { memo } from "react"
import { FormSectionHeader, Select, TextField } from "components/Form"
import { Grid } from "@mui/material"
import { Field } from "react-final-form"
import { getOptionsFromSchema } from "lib/schema"
import validators from "lib/validators"
import { useCoreSchemasStore } from "stores"

export const CarerProfileSectionFinancialDetails = memo(() => {
  const { ApiUserCarerCreate } = useCoreSchemasStore((state) => state.schema.models)
  const jobOptions = getOptionsFromSchema(ApiUserCarerCreate, "finance.job")

  return (
    <>
      <FormSectionHeader title="Financial details" />
      <Grid container spacing={8}>
        <Grid item md={6}>
          <Field
            name="finance.job"
            label="Job title *"
            component={Select}
            options={jobOptions}
            validate={validators.required}
          />
          <Field name="finance.ni_number" label="NI number" component={TextField} />
        </Grid>
        <Grid item md={6}>
          <Field name="finance.dbs_number" label="DBS number" component={TextField} />
          <Field
            name="finance.travel_method"
            label="Travel method"
            component={Select}
            options={[
              { title: "Driving", value: "Driving" },
              { title: "Motorcycle", value: "Motorcycle" },
              { title: "Walking", value: "Walking" },
              { title: "Bicycling", value: "Bicycling" },
              { title: "Public transport", value: "PublicTransport" }
            ]}
          />
        </Grid>
      </Grid>
    </>
  )
})

CarerProfileSectionFinancialDetails.displayName = "CarerProfileSectionFinancialDetails"
