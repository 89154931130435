import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { FinanceApi } from "api"
import { Appointment, AppointmentAssignee } from "./types"
import { GUID_ZERO } from "constants/guid"
import { removeFromSearchString } from "lib/helpers/removeFromSearchString"
import { enabledByMandatoryUrlParams, setDayBorders } from "data/helpers"
import { objectToQueryString, queryStringToObject } from "lib/queryString"
import { useLocation } from "react-router-dom"

interface AppointmentAssigneeDetailRequestProps {
  appointmentGuid: string
  userAppointmentGuid?: string
}

export enum QueryKeyParts {
  APPOINTMENT = "appointment",
  ASSIGNEE = "assignee",
  LIST = "list",
  DETAIL = "detail"
}

export const queryKeys = {
  getAppointmentListKey: (search?: string): QueryKey => [QueryKeyParts.APPOINTMENT, QueryKeyParts.LIST, { search }],
  getAppointmentAssigneeDetailKey: ({
    appointmentGuid,
    userAppointmentGuid
  }: AppointmentAssigneeDetailRequestProps): QueryKey => [
    QueryKeyParts.APPOINTMENT,
    QueryKeyParts.ASSIGNEE,
    QueryKeyParts.DETAIL,
    {
      appointmentGuid,
      userAppointmentGuid
    }
  ]
}

export const useAppointmentListQuery = (): UseQueryResult<Appointment[], Error> => {
  const { search } = useLocation()

  // convert status to confirmed/cancelled
  const searchObj = queryStringToObject(search)
  const status = () => {
    switch (searchObj.status) {
      case "cancelled":
        return { cancelled: true }
      case "confirmed":
        return { confirmed: true }
      case "unconfirmed":
        return { confirmed: false }
      default:
        return {}
    }
  }
  const searchWithStatus = objectToQueryString({ ...searchObj, ...status() })

  const trimmedSearch = removeFromSearchString({ search: searchWithStatus, remove: ["modelType", "status", "errors"] })
  const newSearch = setDayBorders({ search: trimmedSearch, from: "start", to: "end" })

  return useQuery<Appointment[], Error>({
    queryKey: queryKeys.getAppointmentListKey(newSearch),
    queryFn: async () => FinanceApi.get(`/finance/appointments${newSearch ?? ""}`),
    ...enabledByMandatoryUrlParams([newSearch])
  })
}

export const useAppointmentAssigneeDetailQuery = ({
  appointmentGuid,
  userAppointmentGuid = GUID_ZERO
}: AppointmentAssigneeDetailRequestProps): UseQueryResult<AppointmentAssignee, Error> => {
  return useQuery<AppointmentAssignee, Error>({
    queryKey: queryKeys.getAppointmentAssigneeDetailKey({
      appointmentGuid,
      userAppointmentGuid
    }),
    queryFn: async () => FinanceApi.get(`/finance/appointments/${appointmentGuid}/assignees/${userAppointmentGuid}`)
  })
}
