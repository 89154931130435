import React, { memo } from "react"
import { Field } from "react-final-form"
import { FormSectionHeader } from "components/Form"
import Select from "components/Form/Select"
import { Grid } from "@mui/material"
import validators, { composeValidators } from "lib/validators"
import { DateField } from "components/Form/DateField"
import { getOptionsFromSchema } from "lib/schema"
import { useCoreSchemasStore } from "stores"

export const CarerProfileSectionOtherPersonalDetails = memo(() => {
  const { ApiUserCarerCreate } = useCoreSchemasStore((state) => state.schema.models)
  const martialStatusOptions = getOptionsFromSchema(ApiUserCarerCreate, "profile.marital_status")
  const languageOptions = getOptionsFromSchema(ApiUserCarerCreate, "profile.languages")
  const religionOptions = [
    { title: "Unspecified", value: 0 },
    ...getOptionsFromSchema(ApiUserCarerCreate, "profile.religion")
  ]
  const ethnicityOptions = [
    { title: "Unspecified", value: 0 },
    ...getOptionsFromSchema(ApiUserCarerCreate, "profile.ethnicity")
  ]

  return (
    <>
      <FormSectionHeader title="Other personal details" />
      <Grid container spacing={8}>
        <Grid item md={6}>
          <Field
            name="preferences.able_to_drive"
            label="Able to drive *"
            component={Select}
            options={[
              { value: 0, title: "Any" },
              { value: 1, title: "Yes" },
              { value: -1, title: "No" }
            ]}
            validate={validators.required}
          />
          <DateField
            name="profile.date_of_birth"
            label="Date of birth *"
            warningValidators={validators.age}
            validate={composeValidators([validators.required, validators.futureDate])}
          />
          <Field
            name="profile.marital_status"
            label="Marital status"
            component={Select}
            options={martialStatusOptions}
          />
          <Field name="profile.languages" label="Languages" component={Select} options={languageOptions} multiselect />
          <Field name="profile.religion" label="Religion" component={Select} options={religionOptions} />
        </Grid>
        <Grid item md={6}>
          <Field
            name="preferences.a_car_owner"
            label="A car owner *"
            component={Select}
            options={[
              { value: 0, title: "Any" },
              { value: 1, title: "Yes" },
              { value: -1, title: "No" }
            ]}
            validate={validators.required}
          />
          <Field
            name="profile.gender"
            label="Gender *"
            component={Select}
            options={[
              { title: "Unspecified", value: "Unspecified" },
              { title: "Male", value: "Male" },
              { title: "Female", value: "Female" },
              { title: "Other", value: "Other" }
            ]}
            validate={validators.required}
          />
          <Field
            name="profile.sexual_orientation"
            label="Sexual orientation"
            component={Select}
            options={[
              { title: "Unknown", value: "Unknown" },
              { title: "Heterosexual", value: "Heterosexual" },
              { title: "Homosexual", value: "Homosexual" },
              { title: "Bisexual", value: "Bisexual" }
            ]}
          />
          <Field name="profile.ethnicity" label="Ethnicity" component={Select} options={ethnicityOptions} />
        </Grid>
      </Grid>
    </>
  )
})

CarerProfileSectionOtherPersonalDetails.displayName = "CarerProfileSectionOtherPersonalDetails"
