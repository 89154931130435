import React, { memo } from "react"
import testingId from "constants/testingId"
import { Field } from "react-final-form"
import { Checkbox } from "components/Form"
import { SelectableIdsDictionaryModel } from "lib/hooks"

interface Props {
  selectableIds: SelectableIdsDictionaryModel
  id: string
  name: string
  toggleId: (id: string) => void
}

const RowSelectionCheckbox: React.FC<Props> = ({ selectableIds, id, name, toggleId }) => (
  <Field<boolean>
    {...{
      type: "checkbox",
      name,
      component: Checkbox,
      checked: selectableIds[id] || false,
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation()
        toggleId(id)
      },
      "data-cy": testingId.checkbox
    }}
  />
)

export default memo(RowSelectionCheckbox)
