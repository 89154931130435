import React, { memo, useMemo } from "react"

import { AuthLink, EmptyData, Link, Paginator } from "components"
import dateTimeUtils from "lib/datetime"
import { Box, Tooltip, TableCell, TableHead, TableBody, TableContainer, TableRow, Table } from "@mui/material"
import testingId from "constants/testingId"
import { useAlertListQuery } from "data/finance/alert/queries"
import { useIsInvoiceModelSearch } from "lib/hooks/useIsInvoiceModelSearch"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { AlertTableSourceLink } from "./AlertTableSourceLink"
import { Pagination, SetPagination } from "lib/types"
import { Alert, AlertSource } from "data/finance/alert/types"
import Auth from "lib/Auth"
import { RecalculateButton } from "components/RecalculateButton"
import { Permission } from "constants/permission"
import { useRecalculateMutation } from "data/finance/development/mutations"
import { groupBy } from "ramda"
import { DevButton } from "components/DevButton"

const headers = (isInvoiceModel: boolean, hasRecalcPermission: boolean): string[] => [
  "Event date",
  "Time",
  "Status",
  "Client",
  "Carer",
  isInvoiceModel ? "Contract Model" : "Pay Model",
  "Source",
  "Alert",
  ...(hasRecalcPermission ? ["Recalculate"] : [])
]

const buildClientUrl = (alert: Alert) =>
  alert.source === "Shift"
    ? `/shift-categories/${alert.client_guid}/detail`
    : `/clients/${alert.client_guid}/profile/personal-details`

const buildCarerUrl = (alert: Alert) => `/carers/${alert.carer_guid}/profile/personal-details`

const buildContractUrl = (alert: Alert, isInvoiceModel: boolean) =>
  `/finance/contract-models/${isInvoiceModel ? "invoice" : "payment"}-models/${alert.contract_guid}/settings`

interface OwnProps {
  pagination: Pagination
  setPagination: SetPagination
}

export const AlertTable: React.FC<OwnProps> = memo(({ pagination, setPagination }) => {
  const isInvoiceModel: boolean = useIsInvoiceModelSearch()
  const hasRecalcPermission = Auth.hasPermission([Permission.FINANCE_RECALCULATE_EDIT])

  const { data: alerts } = useAlertListQuery()

  const isRecalculable = (alert: Alert) => alert.source !== "Output"

  const selectableRecalcs = useMemo(() => {
    const groupFunction = (alert: Alert) => alert.source
    const filteredItems = alerts?.filter((alert) => isRecalculable(alert)) ?? ([] as Alert[])
    const groupedRecalcs = groupBy(groupFunction)(filteredItems)
    return Object.keys(groupedRecalcs).map((source: string) => {
      const recalcs = groupedRecalcs[source as AlertSource]
      const relation_guids = (recalcs ?? []).map((recalc) => recalc.relation_guid)
      return { type: source, relation_guids: relation_guids }
    })
  }, [alerts])

  const { mutateAsync } = useRecalculateMutation()
  const handleBulkRecalc = () => {
    mutateAsync(selectableRecalcs)
  }

  return (
    <RequestLoaderWrapper>
      {alerts?.length ? (
        <>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headers(isInvoiceModel, hasRecalcPermission).map((name) => (
                    <TableCell
                      data-cy={testingId.alerts.tableHeaderCell}
                      key={name}
                      sx={{
                        fontWeight: 600
                      }}
                    >
                      {name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {alerts.map((alert) => (
                  <TableRow data-cy={testingId.alerts.tableBodyRow} key={alert.guid} hover>
                    <AlertTableSourceLink {...{ alert }} />
                    <TableCell data-cy={testingId.alerts.tableBodyCell}>
                      {dateTimeUtils.formatTime(alert.date)}
                    </TableCell>
                    <TableCell data-cy={testingId.alerts.tableBodyCell}>
                      {alert.confirmed ? "Conf" : "Unconf"}
                    </TableCell>
                    <TableCell data-cy={testingId.alerts.tableBodyCell}>
                      <Link to={buildClientUrl(alert)}>{alert.client_name}</Link>
                    </TableCell>
                    <TableCell data-cy={testingId.alerts.tableBodyCell}>
                      <Link to={buildCarerUrl(alert)}>{alert.carer_name}</Link>
                    </TableCell>
                    <TableCell data-cy={testingId.alerts.tableBodyCell}>
                      {alert.contract_title ? (
                        <AuthLink
                          to={buildContractUrl(alert, isInvoiceModel)}
                          permissions={[Permission.FINANCE_MODEL_EDIT]}
                        >
                          {alert.contract_title}
                        </AuthLink>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell data-cy={testingId.alerts.tableBodyCell}>{alert.source}</TableCell>
                    <TableCell
                      data-cy={testingId.alerts.tableBodyCell}
                      sx={{
                        whiteSpace: "nowrap",
                        maxWidth: "300px",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      }}
                    >
                      <Tooltip
                        sx={{
                          fontSize: "16px",
                          color: "black",
                          background: "#b7b7b7"
                        }}
                        title={alert.description}
                      >
                        <span>{alert.description}</span>
                      </Tooltip>
                    </TableCell>
                    {hasRecalcPermission && (
                      <TableCell>
                        {isRecalculable(alert) && (
                          <RecalculateButton {...{ type: alert.source, relation_guids: [alert.relation_guid] }} />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <EmptyData message="No alerts found." />
      )}
      {!alerts?.length && +pagination.page === 1 ? null : <Paginator {...{ pagination, setPagination }} />}
      {hasRecalcPermission ? (
        <Box display="flex" justifyContent="right" alignItems="right" m={1}>
          <DevButton fullWidth={false} onClick={handleBulkRecalc}>
            Recalc All
          </DevButton>
        </Box>
      ) : null}
    </RequestLoaderWrapper>
  )
})

AlertTable.displayName = "AlertTable"
