import React from "react"
import TransactionGroupList from "./TransactionGroupList"
import TransactionList from "./TransactionList"
import TransactionDetail from "./TransactionDetail"
import TransactionDetailItem from "../Processing/TransactionDetailItem"
import TransactionHolidayDetails from "./TransactionHolidayDetails"
import { ContractModelType } from "constants/modelTypes"
import TransactionShiftTransactionDetail from "pages/Finance/Processing/TransactionShiftTransactionDetail"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

// TODO: contractGuid is contractModelGuid, rename please
const PaymentProcessing: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/" element={<TransactionGroupList />} />
      <Route path="/:calendarGuid/:periodGuid/:contractGuid" element={<TransactionList />} />
      <Route path="/:calendarGuid/:periodGuid/:contractGuid/:transactionGuid" element={<TransactionDetail />} />
      <Route
        path="/:calendarGuid/:periodGuid/:contractGuid/:transactionGuid/:transactionItemGuid"
        element={<TransactionDetailItem modelType={ContractModelType.PAYMENT} />}
      />
      <Route
        path="/:calendarGuid/:periodGuid/:contractGuid/:transactionGuid/shift/:transactionShiftGuid/:visitGuid"
        element={<TransactionShiftTransactionDetail modelType={ContractModelType.PAYMENT} />}
      />
      <Route
        path="/:calendarGuid/:periodGuid/:contractGuid/:transactionGuid/shift/:transactionShiftGuid"
        element={<TransactionShiftTransactionDetail modelType={ContractModelType.PAYMENT} />}
      />
      <Route
        path="/:calendarGuid/:periodGuid/:contractGuid/:transactionGuid/:transactionItemGuid/holiday-details"
        element={<TransactionHolidayDetails />}
      />
    </SentryRoutes>
  )
}

export default PaymentProcessing
