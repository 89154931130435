import { BackButton, Header } from "components"
import dateTimeUtils from "lib/datetime"
import React, { memo } from "react"
import { ContractModel, CalendarPeriod } from "data/finance/contractModel/types"
import testingId from "constants/testingId"
import { PATH } from "constants/path"
import { PaymentTransaction } from "data/finance/paymentProcessing/types"

interface OwnProps {
  periodGuid: string
  calendarGuid: string
  contractModelGuid: string
  contractModel: ContractModel
  period: CalendarPeriod
  transaction: PaymentTransaction
}

export const TransactionDetailHeader: React.FC<OwnProps> = memo(
  ({ calendarGuid, periodGuid, contractModel, contractModelGuid, period, transaction }) => {
    return (
      <>
        <BackButton
          fallbackUrl={`${PATH.FINANCE.PAYMENT_PROCESSING}/${calendarGuid}/${periodGuid}/${contractModelGuid}`}
        />
        <Header
          data-cy={testingId.tableTitle}
          title={`${contractModel.title} (${dateTimeUtils.formatDate(period.start)} - ${dateTimeUtils.formatDate(
            period.end
          )}): ${transaction.owner_name} (${transaction.reference ? transaction.reference : "Pending"})`}
        />
      </>
    )
  }
)

TransactionDetailHeader.displayName = "TransactionDetailHeader"
