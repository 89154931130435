import React, { Dispatch, memo, SetStateAction } from "react"
import { Form, Field } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"
import { Select, FormSpyCustom, AutocompleteMui, TextField } from "components/Form"
import Grid from "@mui/material/Grid"
import { Pagination } from "lib/types"
import { ContractModelType } from "constants/modelTypes"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { ClearFilterButton } from "components/ClearFilterButton"
import { FilterValues, formatFilterValues } from "lib/helpers/formatFilterValues"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"
import { Box } from "@mui/material"
import { useSchema } from "data/schema/useSchema"
import { removeUnassigned } from "data/schema/helpers"
import { DateField } from "components/Form/DateField"
import { InvoiceFilesFilterValues } from "../types"

interface Props {
  modelType: ContractModelType
  pagination: Pagination
  resetPagination: () => void
  filter: InvoiceFilesFilterValues
  setFilter: Dispatch<SetStateAction<InvoiceFilesFilterValues>>
  defaultFilterValues: {
    period_end_from: string
    period_end_to: string
  }
  showITrent: boolean
}

export const FileManagementFilter: React.FC<Props> = memo(
  ({ modelType, pagination, resetPagination, filter, setFilter, defaultFilterValues, showITrent }) => {
    FileManagementFilter.displayName = "FileManagementFilter"

    const { Region, InvoiceContractFinanceId, InvoiceRun, PaymentRun, InvoiceContract, PaymentContract } = useSchema([
      "Region",
      "InvoiceContractFinanceId",
      "InvoiceRun",
      "PaymentRun",
      "InvoiceContract",
      "PaymentContract"
    ])

    const isInvoiceModel = modelType === ContractModelType.INVOICE

    const contractOptions = isInvoiceModel ? removeUnassigned(InvoiceContract) : removeUnassigned(PaymentContract)

    const runOptions = isInvoiceModel ? InvoiceRun : PaymentRun

    const debouncedOnSubmit = useDebouncedCallback((values: FilterValues<InvoiceFilesFilterValues>) => {
      const formattedValues = formatFilterValues<InvoiceFilesFilterValues>({
        values,
        startName: "period_end_from",
        endName: "period_end_to"
      })

      setFilter(formattedValues)
      resetPagination()
    }, 500)

    const clearFilter = () => {
      setFilter((oldValues) => ({ ...defaultFilterValues, modelType: oldValues.modelType }))
      resetPagination()
    }

    useFilterLocationChange({ filter, pagination })

    return (
      <Form
        onSubmit={debouncedOnSubmit}
        initialValues={filter}
        validate={validateFilterPeriodDates({ start: "period_end_from", end: "period_end_to" })}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={isInvoiceModel ? 2 : 3}>
                <DateField name="period_end_from" label="Show cycle end dates from" required />
              </Grid>
              <Grid item md={isInvoiceModel ? 2 : 3}>
                <DateField name="period_end_to" label="To" required />
              </Grid>
              {isInvoiceModel && (
                <Grid item md={2}>
                  <Field
                    name="invoice_type"
                    label="Invoice type"
                    component={Select}
                    options={[
                      { title: "Any", value: "" },
                      { title: "Invoice", value: "COMPLETE" },
                      { title: "Credit", value: "CREDITED" }
                    ]}
                  />
                </Grid>
              )}
              <Grid item md={3}>
                <Field
                  name="interfaced"
                  label="Interfaced status"
                  component={Select}
                  options={[
                    { title: "Any", value: "" },
                    { title: "Non Interfaced", value: "false" },
                    { title: "Interfaced", value: "true" }
                  ]}
                />
              </Grid>
              {isInvoiceModel && (
                <Grid item md={3}>
                  <Field
                    name="finance_id"
                    label="Account"
                    component={AutocompleteMui}
                    options={InvoiceContractFinanceId}
                  />
                </Grid>
              )}
              <Grid item md={isInvoiceModel ? 2 : 3}>
                <Field
                  name="runs"
                  label={isInvoiceModel ? "Invoice Run" : "Payment Run"}
                  component={Select}
                  multiselect
                  options={runOptions}
                />
              </Grid>
              {showITrent ? (
                <Grid item md={2}>
                  <Field
                    name="payroll_status"
                    label="Payroll Status"
                    component={Select}
                    options={[
                      { title: "Any", value: "" },
                      { title: "Pending", value: "Pending" },
                      { title: "Succeeded", value: "Succeeded" },
                      { title: "Failed", value: "Failed" }
                    ]}
                  />
                </Grid>
              ) : null}
              <Grid item md={3}>
                <Field
                  name="models"
                  label={isInvoiceModel ? "Contract models" : "Pay models"}
                  component={AutocompleteMui}
                  options={contractOptions}
                  multiselect
                />
              </Grid>
              <Grid item md={3}>
                <Field
                  name="user_name"
                  label={`Search by ${isInvoiceModel ? "client" : "carer"} name`}
                  component={TextField}
                />
              </Grid>
              <Grid item md={isInvoiceModel || showITrent ? 2 : 3}>
                <Field
                  name="reference"
                  label={`Search by ${isInvoiceModel ? "invoice" : "payment"} / interface`}
                  component={TextField}
                />
              </Grid>
              <Grid item md={isInvoiceModel || showITrent ? 2 : 3}>
                <Field name="regions" label="Regions" component={AutocompleteMui} options={Region} multiselect />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={12}>
                <Box textAlign="right">
                  <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    )
  }
)
