import { EmptyData } from "components"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { useApprovePaymentAdditionalMutation } from "data/finance/additional/mutations"
import { useInvoiceAdditionalQuery, usePaymentAdditionalQuery } from "data/finance/additional/queries"
import {
  AdditionalApproveRequest,
  AdditionalSearchFilterValues,
  AdditionalSearchFormValues,
  ApiAdditionalSearch,
  ApiPaymentAdditionalSearch
} from "data/finance/additional/type"
import { FormApi } from "final-form"
import { useIsInvoiceModelSearch } from "lib/hooks/useIsInvoiceModelSearch"
import { SetPagination } from "lib/types"
import React, { memo, useCallback } from "react"
import { Form, FormSpy } from "react-final-form"
import { AdditionalSearchSummary } from "./AdditionalSearchSummary"
import { AdditionalSearchTable } from "./AdditionalSearchTable"
import { getSelectedAdditionalGuids } from "./helpers/getSelectedAdditionalGuids"
import { useLocation } from "react-router-dom"

interface OwnProps {
  filter: AdditionalSearchFilterValues
  setPagination: SetPagination
}

export const AdditionalSearchForm: React.FC<OwnProps> = memo(({ filter, setPagination }) => {
  const location = useLocation()

  const isInvoiceModel = useIsInvoiceModelSearch()

  // fetch data
  const { data: invoiceAdditionals } = useInvoiceAdditionalQuery({
    search: location.search,
    enabled: isInvoiceModel && !!location.search
  })
  const { data: paymentAdditionals } = usePaymentAdditionalQuery({
    search: location.search,
    enabled: !isInvoiceModel && !!location.search
  })
  const additionals: (ApiAdditionalSearch | ApiPaymentAdditionalSearch)[] | undefined = isInvoiceModel
    ? invoiceAdditionals
    : paymentAdditionals

  // post data
  const { mutateAsync: approveAdditionals, isSuccess: isApproveSuccess } = useApprovePaymentAdditionalMutation()

  const onSubmit = (values: AdditionalSearchFormValues) => {
    const payload: AdditionalApproveRequest = {
      approved: values.approved,
      comment: values.comment ?? "No comment provided",
      transaction_charge_guids: getSelectedAdditionalGuids(values.items)
    }

    approveAdditionals(payload)
  }

  const handleStatusChange = useCallback(
    (form: FormApi<AdditionalSearchFormValues, Partial<AdditionalSearchFormValues>>) =>
      ({ values: newValues }: { values: AdditionalSearchFormValues }) => {
        const pickedItemGuids = getSelectedAdditionalGuids(newValues.items)

        if (pickedItemGuids.length === 1 || newValues.selectAll) {
          const pickedItem = (additionals as ApiPaymentAdditionalSearch[] | undefined)?.find(
            (item) => item.guid === pickedItemGuids[0]
          )
          form.change("pickedStatus", pickedItem?.status)
        }
        if (pickedItemGuids.length === 0) {
          form.change("pickedStatus", undefined)
        }
      },
    [additionals]
  )

  return (
    <RequestLoaderWrapper>
      {additionals?.length ? (
        <>
          {!isInvoiceModel && (
            <AdditionalSearchSummary additionals={additionals as ApiPaymentAdditionalSearch[]} filter={filter} />
          )}
          <Form<AdditionalSearchFormValues> onSubmit={onSubmit}>
            {(formProps) => {
              return (
                <form onSubmit={formProps.handleSubmit}>
                  <FormSpy<AdditionalSearchFormValues>
                    subscription={{ values: true }}
                    onChange={handleStatusChange(formProps.form)}
                  />

                  <AdditionalSearchTable
                    formProps={formProps}
                    additionals={additionals}
                    isInvoiceModel={isInvoiceModel}
                    filter={filter}
                    isApproveSuccess={isApproveSuccess}
                    setPagination={setPagination}
                  />
                </form>
              )
            }}
          </Form>
        </>
      ) : (
        <EmptyData message="No additionals found." />
      )}
    </RequestLoaderWrapper>
  )
})

AdditionalSearchForm.displayName = "AdditionalSearchForm"
