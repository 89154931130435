import React, { memo } from "react"
import { FormSectionHeader, TextField } from "components/Form"
import { Grid } from "@mui/material"
import validators, { composeValidators } from "lib/validators"
import { Field } from "react-final-form"

export const CarerProfileSectionContactDetails = memo(() => {
  return (
    <>
      <FormSectionHeader title="Contact Details" />
      <Grid container spacing={8}>
        <Grid item md={6}>
          <Field
            name="profile.phone_mobile"
            label="Personal mobile number *"
            component={TextField}
            validate={composeValidators([validators.required, validators.phone])}
          />
          <Field
            name="profile.phone_cera"
            label="Cera mobile number *"
            component={TextField}
            validate={composeValidators([validators.required, validators.phone])}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            name="profile.email"
            label="E-mail *"
            component={TextField}
            validate={composeValidators([validators.required, validators.email])}
          />
          <Field
            name="profile.phone_landline"
            label="Landline phone number"
            component={TextField}
            validate={validators.phone}
          />
        </Grid>
      </Grid>
    </>
  )
})

CarerProfileSectionContactDetails.displayName = "CarerProfileSectionContactDetails"
