export interface CsvToArrayResult {
  fileData: Record<string, string>[]
  rows: string[]
  headers: string[]
  str: string
}

export const csvToArray = (fileString: string, delimiter = ","): CsvToArrayResult => {
  // remove \r from windows machines
  const str = fileString.replace("\r", "")

  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf("\n")).split(delimiter)

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf("\n") + 1).split("\n")

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const fileData = rows.map((row: string) => {
    const values = row.split(delimiter)
    return headers.reduce((object: Record<string, string>, header: string, index: number) => {
      object[header] = values[index]
      return object
    }, {})
  })

  return { fileData, rows, headers, str }
}
