import React, { useCallback, useMemo, useState } from "react"
import { ApiUser, UserType } from "../models"
import { Button } from "./Form"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import dateTimeUtils, { DateTimeFormat } from "lib/datetime"
import { useInviteUserMutation } from "data/users/mutations"
import useMutationPasswordResetRequest from "pages/Auth/useMutationPasswordResetRequest"
import { useUIStore } from "stores"
interface OwnProps {
  user: ApiUser
}

const UserAccount: React.FC<OwnProps> = ({ user }) => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const { mutateAsync: mutateInviteUser } = useInviteUserMutation()
  const { mutate } = useMutationPasswordResetRequest()
  const [inviteDone, setInviteDone] = useState(false)

  const userType = useMemo(() => UserType[user.type].toLocaleLowerCase(), [user])

  // if the user is a carer (type 2) then the inactive status code is 14, for other users it's 1
  const userInactive = useMemo(() => (user.type === 2 ? user.status === 14 : user.status === 1), [user])

  const onClickInviteUser = useCallback(() => {
    mutateInviteUser(user.guid).then(() => {
      setInviteDone(true)
    })
  }, [mutateInviteUser, user])

  const onClickResetPassword = useCallback(
    () =>
      mutate(
        { email: user.profile.email },
        { onSuccess: () => showSuccessMessage("We have emailed you your password reset link.") }
      ),
    [mutate, showSuccessMessage, user.profile.email]
  )

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt={2} mb={4}>
      <Box display="column" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="body1" mb={0.5}>
          <Typography component="span" color="textSecondary">
            DCP / Mobile app login enabled&nbsp;&mdash;&nbsp;
          </Typography>
          <Typography component="span">{user.has_login ? "Yes" : "No"}</Typography>
        </Typography>

        <Typography variant="body1" mb={0.5}>
          <Typography component="span" color="textSecondary">
            Last Login&nbsp;&mdash;&nbsp;
          </Typography>
          <Typography component="span">
            {user.login?.last_login_at
              ? dateTimeUtils.formatDate(user.login.last_login_at, DateTimeFormat.DATE_TIME)
              : "N/A"}
          </Typography>
        </Typography>

        <Typography variant="body1" mb={0.5}>
          <Typography component="span" color="textSecondary">
            Invited at&nbsp;&mdash;&nbsp;
          </Typography>
          <Typography component="span">
            {user.login?.invitation_created_at
              ? dateTimeUtils.formatDate(user.login.invitation_created_at, DateTimeFormat.DATE_TIME)
              : "N/A"}
          </Typography>
        </Typography>

        <Typography variant="body1" mb={0.5}>
          <Typography component="span" color="textSecondary">
            Invitation link clicked&nbsp;&mdash;&nbsp;
          </Typography>
          <Typography component="span">
            {user.login?.invitation_accepted_at
              ? dateTimeUtils.formatDate(user.login.invitation_accepted_at, DateTimeFormat.DATE_TIME)
              : "N/A"}
          </Typography>
        </Typography>

        <Typography variant="body1" mb={0.5}>
          <Typography component="span" color="textSecondary">
            Password changed&nbsp;&mdash;&nbsp;
          </Typography>
          <Typography component="span">
            {user.login?.password_updated_at
              ? dateTimeUtils.formatDate(user.login.password_updated_at, DateTimeFormat.DATE_TIME)
              : "N/A"}
          </Typography>
        </Typography>
      </Box>

      {user.has_login ? (
        <Button id={`${userType}-profile-send-reset-password-btn`} fullWidth={false} onClick={onClickResetPassword}>
          Send password reset
        </Button>
      ) : (
        <Button
          id={`${userType}-profile-invite-use-btn`}
          disabled={inviteDone || userInactive}
          fullWidth={false}
          onClick={onClickInviteUser}
        >
          Invite user
        </Button>
      )}
    </Box>
  )
}

export default UserAccount
