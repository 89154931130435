const LAST_SELECTED_BRANCH_KEY = "lastSelectedBranchId"

const UserPreferences = {
  saveLastSelectedBranch: (branchId: string) => {
    window.localStorage.setItem(LAST_SELECTED_BRANCH_KEY, branchId)
  },
  
  getLastSelectedBranch: () => window.localStorage.getItem(LAST_SELECTED_BRANCH_KEY)
}

export default UserPreferences