import React, { memo, useMemo, useState } from "react"
import { EmptyData } from "components"
import ShiftCategoriesContractsTable from "./components/ShiftCategoryContractsTable"
import testingId from "constants/testingId"
import Grid from "@mui/material/Grid"
import { Button } from "components/Form"
import { ApiShiftCategoryResponse } from "types/Shifts/ShiftCategories/types"
import { useSelectableIdsDictionary } from "lib/hooks"
import filterShiftCategoryContracts from "./helpers/filterShiftCategoryContracts"
import { Box, Divider } from "@mui/material"
import { useShiftCategoryContractsQuery } from "data/finance/shiftCategory/queries"
import {
  ListShiftCategoryContractsFilter,
  ShiftCategoryContractsListFilter
} from "./components/ShiftCategoryContractsListFilter"
import { queryStringToObject } from "lib/queryString"
import { useLocation, Link } from "react-router-dom"

interface OwnProps {
  shiftCategory: ApiShiftCategoryResponse
}

export const ShiftCategoryContractsList: React.FC<OwnProps> = memo(({ shiftCategory }) => {
  const location = useLocation()
  const [filter, setFilter] = useState<ListShiftCategoryContractsFilter>(queryStringToObject(location?.search))

  const { data: contracts } = useShiftCategoryContractsQuery(shiftCategory.guid)

  const contractIds = useMemo(() => {
    return contracts?.filter((contract) => contract.status === "Future").map((contract) => contract.guid) || []
  }, [contracts])

  const selectableContracts = useSelectableIdsDictionary(contractIds)

  const filteredContracts = useMemo(() => filterShiftCategoryContracts({ contracts, filter }), [contracts, filter])

  return (
    <Box m={3} mx={0} data-cy={testingId.shifts.category.screen}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item md={9}>
          <ShiftCategoryContractsListFilter {...{ filter, setFilter }} />
        </Grid>
        <Grid item md={3}>
          <Link to={`new`}>
            <Button>New contract</Button>
          </Link>
        </Grid>
      </Grid>
      <Divider
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
          height: "2px",
          bgcolor: "divider"
        }}
      />
      <Grid container>
        {contracts?.length ? (
          filteredContracts.length ? (
            <ShiftCategoriesContractsTable
              shiftCategoryGuid={shiftCategory.guid}
              items={filteredContracts}
              selectableContracts={selectableContracts}
            />
          ) : (
            <EmptyData message="No filtered shift category contracts found." />
          )
        ) : (
          <EmptyData message="No shift category contracts found, start by adding one." />
        )}
      </Grid>
    </Box>
  )
})

ShiftCategoryContractsList.displayName = "ShiftCategoryContractsList"
