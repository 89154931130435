import { AppBar, Box, Tab, Tabs } from "@mui/material"
import { ContentContainer, Divider, Header, TabPanel } from "components"
import { usePagination } from "components/handlers/usePagination"
import testingId from "constants/testingId"
import { queryStringToObject } from "lib/queryString"
import { DateTime } from "luxon"
import React, { memo, useEffect, useMemo, useState } from "react"
import { AppointmentSearchListTable } from "./AppointmentSearchListTable"
import AppointmentSearchListFilter from "./AppointmentSearchListFilter"
import { AppointmentTabType } from "constants/appointmentType"
import { AppointmentSearchFilterValues } from "data/finance/invoiceSearch/types"
import { AppointmentSearchScheduled } from "./AppointmentSearchScheduled"
import Auth from "lib/Auth"
import { Permission } from "constants/permission"
import { useLocation } from "react-router-dom"

const AppointmentSearchList: React.FC = () => {
  const location = useLocation()

  const hasAppointmentReadPermission = Auth.hasPermission([Permission.FINANCE_APPOINTMENT_READ])
  const hasSearchReadPermission = Auth.hasPermission([Permission.FINANCE_SEARCH_READ])

  const defaultFilterValues = useMemo(
    () => ({
      start: DateTime.local().minus({ weeks: 1 }).startOf("week").toISODate() as string,
      end: DateTime.local().minus({ weeks: 1 }).endOf("week").toISODate() as string,
      modelType: hasAppointmentReadPermission ? AppointmentTabType.SCHEDULE : AppointmentTabType.INVOICE
    }),
    [hasAppointmentReadPermission]
  )

  const [filter, setFilter] = useState<AppointmentSearchFilterValues>(() => ({
    ...defaultFilterValues,
    ...queryStringToObject<AppointmentSearchFilterValues>(location.search)
  }))
  const { pagination, setPagination, resetPagination } = usePagination()

  // reset tabs after clicking appointment search in menu
  useEffect(() => {
    if (!location.search) {
      setFilter(defaultFilterValues)
    }
  }, [location.search, defaultFilterValues])

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: AppointmentTabType) => {
    setFilter((values) => ({
      start: values.start,
      end: values.end,
      modelType: newValue
    }))
    resetPagination()
  }

  return (
    <ContentContainer>
      <Header {...{ "data-cy": testingId.pageTitle, title: "Appointment Search" }} />
      <Box my={3}>
        <AppBar {...{ position: "static", color: "transparent" }}>
          <Tabs value={filter.modelType} onChange={handleTabChange}>
            {hasAppointmentReadPermission ? <Tab label="Scheduled" value={AppointmentTabType.SCHEDULE} /> : null}
            {hasSearchReadPermission ? <Tab label="Invoicing" value={AppointmentTabType.INVOICE} /> : null}
            {hasSearchReadPermission ? <Tab label="Payments" value={AppointmentTabType.PAYMENT} /> : null}
          </Tabs>
        </AppBar>
      </Box>
      <AppointmentSearchListFilter {...{ pagination, resetPagination, filter, setFilter, defaultFilterValues }} />
      <Divider color="divider" />
      {hasAppointmentReadPermission ? (
        <TabPanel {...{ value: filter.modelType, index: AppointmentTabType.SCHEDULE }}>
          <AppointmentSearchScheduled {...{ setPagination, setFilter, filter }} />
        </TabPanel>
      ) : null}
      {hasSearchReadPermission ? (
        <TabPanel {...{ value: filter.modelType !== AppointmentTabType.SCHEDULE ? 1 : 0, index: 1 }}>
          <AppointmentSearchListTable {...{ location, setPagination }} />
        </TabPanel>
      ) : null}
    </ContentContainer>
  )
}

export default memo(AppointmentSearchList)
