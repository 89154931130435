import React, { memo } from "react"
import ProcessingStatusList from "./ProcessingStatusList"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

const ProcessingStatus: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/" element={<ProcessingStatusList />} />
    </SentryRoutes>
  )
}

export default memo(ProcessingStatus)
