import Grid from "@mui/material/Grid"
import React, { memo } from "react"
import { FormSpy } from "react-final-form"
import { NoEndDateCheckbox } from "components"
import { DateField } from "components/Form/DateField"

export const SalaryBandFormDates: React.FC = memo(() => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <Grid container spacing={8}>
          <Grid item md={6}>
            <DateField name="start" label="Starts on" required />
          </Grid>
          <Grid item md={6}>
            <DateField name="end" label="Ends before" disabled={!!values.no_end_date} />
            <NoEndDateCheckbox />
          </Grid>
        </Grid>
      )}
    </FormSpy>
  )
})

SalaryBandFormDates.displayName = "SalaryBandFormDates"
