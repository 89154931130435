import React, { memo, useState } from "react"
import { Form } from "react-final-form"
import { Visit } from "models/Visit"
import { ModalWrapper } from "components"
import { Button } from "components/Form"
import { DateTime } from "luxon"
import { validateVisitCheckin } from "lib/helpers/visitValidations"
import ConfirmationStep from "./ConfirmationStep"
import CheckInEdit from "./CheckInEdit"
import { useQueryClient } from "react-query"
import { useApiMessage } from "stores"
import { ApiVisit } from "models"
import { queryKeys } from "data/core/visit/queries"
import { useCheckInVisitMutation } from "data/visits"
import { ApiError } from "stores/useUIStore/types"

interface Props {
  destroyModal: () => void
  visit: Visit
  canCheckIn: boolean
}

const CheckInModal = ({ visit, destroyModal, canCheckIn }: Props) => {
  const queryClient = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const onSuccess = (data: ApiVisit) => {
    showSuccessMessage("Check in time successfully saved.")
    queryClient.invalidateQueries(queryKeys.getVisitDetailKey(data.guid))
    destroyModal()
  }

  const onError = (apiError: ApiError) => {
    showErrorMessage("Something went wrong during check-in. Please check this visit is valid for check-in.", {
      apiError
    })
  }

  const { mutate: doCheckIn } = useCheckInVisitMutation({ onSuccess, onError })

  const [step, setStep] = useState<"edit" | "confirm">("edit")

  const onSubmit = ({ time, reason }: { time: DateTime; reason: string }) => {
    const isDuration1HourMoreThanScheduled =
      visit.check_in &&
      visit.check_out &&
      visit.check_out.time.diff(time, "hours").hours - visit.end.diff(visit.start, "hours").hours > 1

    if (isDuration1HourMoreThanScheduled && step === "edit") {
      setStep("confirm")
      return
    }

    doCheckIn({ visit: visit.guid, time, reason })
  }

  if (!canCheckIn)
    return (
      <ModalWrapper title="Unable to check-in" destroyModal={destroyModal}>
        <p>
          The check-in functionality is unavailable for this visit. This might be a result of the following reasons:
        </p>
        <ul>
          <li>The visit is scheduled to occur at least 3 hours from now</li>
        </ul>
        <p>If you are convinced this is an error, please contact our support.</p>
        <Button
          id="visit-details-check-in-modal-unable-to-check-in-cancel-btn"
          variant="text"
          onClick={() => destroyModal()}
        >
          Close
        </Button>
      </ModalWrapper>
    )

  return (
    <ModalWrapper title="Visit check in" destroyModal={destroyModal}>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          time: visit.isCheckedIn() ? visit.getDate("checkin") : visit.getDate("start")
        }}
        validate={validateVisitCheckin(visit.check_out?.time)}
      >
        {({ handleSubmit, submitting, invalid, values }) => (
          <form onSubmit={handleSubmit}>
            {step === "edit" && (
              <CheckInEdit submitting={submitting} invalid={invalid} destroyModal={() => destroyModal()} />
            )}
            {step === "confirm" && (
              <ConfirmationStep
                submitting={submitting}
                checkInTime={values.time}
                visit={visit}
                setEdit={() => setStep("edit")}
              />
            )}
          </form>
        )}
      </Form>
    </ModalWrapper>
  )
}

export default memo(CheckInModal)
