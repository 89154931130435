import { FinanceApi } from "api"
import { enabledByMandatoryUrlParams, setDayBorders } from "data/helpers"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import {
  ApiCarerPayslip,
  ApiCarerPayslipDetail,
  ApiCarerContract,
  ApiCarerBankDetails,
  ApiContractAccrual,
  ApiContractAccrualSummary,
  ApiContractAccrualItem,
  ApiCarerBranch
} from "./types"
import { useLocation } from "react-router-dom"

export enum QueryKeyParts {
  CARER = "carer",
  CONTRACT = "contract",
  PAYSLIP = "payslip",
  LIST = "list",
  DETAIL = "detail",
  BANK_DETAIL = "bank_detail",
  ACCRUAL = "accrual",
  ITEMS = "items",
  BRANCH = "branch"
}

export const queryKeys = {
  getCarerContractKey: ({ carerGuid, contractGuid }: { carerGuid?: string; contractGuid?: string }): QueryKey => [
    QueryKeyParts.CARER,
    QueryKeyParts.CONTRACT,
    QueryKeyParts.DETAIL,
    { carerGuid, contractGuid }
  ],
  getCarerContractListKey: ({ carerGuid }: { carerGuid?: string }): QueryKey => [
    QueryKeyParts.CARER,
    QueryKeyParts.CONTRACT,
    QueryKeyParts.LIST,
    { carerGuid }
  ],
  getCarerPayslipListKey: ({ carerGuid, search }: { carerGuid?: string; search?: string }): QueryKey => [
    QueryKeyParts.CARER,
    QueryKeyParts.PAYSLIP,
    QueryKeyParts.LIST,
    { carerGuid, search }
  ],
  getCarerPayslipKey: ({ carerGuid, payslipGuid }: { carerGuid: string; payslipGuid?: string }): QueryKey => [
    QueryKeyParts.CARER,
    QueryKeyParts.PAYSLIP,
    QueryKeyParts.LIST,
    { carerGuid, payslipGuid }
  ],
  getCarerBankDetailsKey: ({ carerGuid }: { carerGuid: string }): QueryKey => [
    QueryKeyParts.CARER,
    QueryKeyParts.BANK_DETAIL,
    { carerGuid }
  ],
  getCarerAccrualListKey: ({
    carerGuid,
    locationSearch
  }: {
    carerGuid?: string
    locationSearch?: string
  }): QueryKey => [QueryKeyParts.CARER, QueryKeyParts.ACCRUAL, QueryKeyParts.LIST, { carerGuid, locationSearch }],
  getCarerAccrualSummaryKey: ({ carerGuid, accrualGuid }: { carerGuid?: string; accrualGuid?: string }): QueryKey => [
    QueryKeyParts.CARER,
    QueryKeyParts.ACCRUAL,
    QueryKeyParts.DETAIL,
    { carerGuid, accrualGuid }
  ],
  getCarerAccrualDetailItemsKey: ({
    carerGuid,
    accrualGuid
  }: {
    carerGuid?: string
    accrualGuid?: string
  }): QueryKey => [
    QueryKeyParts.CARER,
    QueryKeyParts.ACCRUAL,
    QueryKeyParts.DETAIL,
    QueryKeyParts.ITEMS,
    { carerGuid, accrualGuid }
  ],
  getCarerBranchList: (props: { carerGuid?: string }) => [
    QueryKeyParts.CARER,
    QueryKeyParts.BRANCH,
    QueryKeyParts.LIST,
    props
  ]
}

export const useCarerContractListQuery = ({
  carerGuid
}: {
  carerGuid?: string
}): UseQueryResult<ApiCarerContract[], Error> => {
  return useQuery({
    queryKey: queryKeys.getCarerContractListKey({ carerGuid }),
    queryFn: async () => FinanceApi.get(`/finance/carers/${carerGuid}/contracts`),
    ...enabledByMandatoryUrlParams([carerGuid])
  })
}

export const useCarerContractQuery = ({
  carerGuid,
  contractGuid
}: {
  carerGuid?: string
  contractGuid?: string
}): UseQueryResult<ApiCarerContract, Error> => {
  return useQuery({
    queryKey: queryKeys.getCarerContractKey({ carerGuid, contractGuid }),
    queryFn: async () => FinanceApi.get(`/finance/carers/${carerGuid}/contracts/${contractGuid}`),
    ...enabledByMandatoryUrlParams([carerGuid, contractGuid])
  })
}

export const useCarerPayslipListQuery = ({
  carerGuid
}: {
  carerGuid: string
}): UseQueryResult<ApiCarerPayslip[], Error> => {
  const location = useLocation()

  const newSearch = setDayBorders({ search: location.search, from: "period_end_from", to: "period_end_to" })

  return useQuery({
    queryKey: queryKeys.getCarerPayslipListKey({ carerGuid, search: newSearch }),
    queryFn: async () => FinanceApi.get(`/finance/carers/${carerGuid}/payslips${newSearch}`),
    ...enabledByMandatoryUrlParams([carerGuid, newSearch])
  })
}

export const useCarerPayslipQuery = ({
  carerGuid,
  payslipGuid
}: {
  carerGuid: string
  payslipGuid?: string
}): UseQueryResult<ApiCarerPayslipDetail, Error> => {
  return useQuery({
    queryKey: queryKeys.getCarerPayslipKey({ carerGuid, payslipGuid }),
    queryFn: async () => FinanceApi.get(`/finance/carers/${carerGuid}/payslips/${payslipGuid}`),
    ...enabledByMandatoryUrlParams([carerGuid, payslipGuid])
  })
}

export const useCarerBankDetailsQuery = ({
  carerGuid
}: {
  carerGuid: string
}): UseQueryResult<ApiCarerBankDetails, Error> => {
  return useQuery({
    queryKey: queryKeys.getCarerBankDetailsKey({ carerGuid }),
    queryFn: async () => FinanceApi.get(`/finance/carers/${carerGuid}/bank-details`)
  })
}

export const useCarerAccrualListQuery = ({
  carerGuid,
  search
}: {
  carerGuid?: string
  search?: string
}): UseQueryResult<ApiContractAccrual[], Error> => {
  const location = useLocation()
  const locationSearch = search ?? location.search

  return useQuery({
    queryKey: queryKeys.getCarerAccrualListKey({ carerGuid, locationSearch }),
    queryFn: async () => FinanceApi.get(`/finance/carers/${carerGuid}/accruals${locationSearch}`),
    ...enabledByMandatoryUrlParams([carerGuid])
  })
}

export const useCarerAccrualDetailQuery = ({
  carerGuid,
  accrualGuid
}: {
  carerGuid: string
  accrualGuid?: string
}): UseQueryResult<ApiContractAccrualSummary, Error> =>
  useQuery({
    queryKey: queryKeys.getCarerAccrualSummaryKey({ carerGuid, accrualGuid }),
    queryFn: async () => FinanceApi.get(`/finance/carers/${carerGuid}/accruals/${accrualGuid}`),
    ...enabledByMandatoryUrlParams([carerGuid, accrualGuid])
  })

export const useCarerAccrualDetailItemsQuery = ({
  carerGuid,
  accrualGuid
}: {
  carerGuid: string
  accrualGuid?: string
}): UseQueryResult<ApiContractAccrualItem[], Error> =>
  useQuery({
    queryKey: queryKeys.getCarerAccrualDetailItemsKey({ carerGuid, accrualGuid }),
    queryFn: async () => FinanceApi.get(`/finance/carers/${carerGuid}/accruals/${accrualGuid}/items`),
    ...enabledByMandatoryUrlParams([carerGuid, accrualGuid])
  })

export const useGetCarerBranchList = ({ carerGuid }: { carerGuid?: string }) => {
  return useQuery<ApiCarerBranch[], Error>({
    queryKey: queryKeys.getCarerBranchList({ carerGuid }),
    queryFn: async () => FinanceApi.get(`/finance/carers/${carerGuid}/branches`),
    enabled: !!carerGuid
  })
}
