import { Link } from "components"
import { Checkbox } from "components/Form"
import { ListAlertIconBasic } from "components/ListAlertIcon"
import { RewindButton } from "components/RewindButton"
import { Permission } from "constants/permission"
import testingId from "constants/testingId"
import { PaymentTransaction } from "data/finance/paymentProcessing/types"
import Auth from "lib/Auth"
import { SelectableIdsDictionaryModel } from "lib/hooks"
import { timeSpanToStringDuration } from "lib/timespan"
import { moneyFormat } from "lib/utils"
import { TransactionFailed, TransactionInProgress, TransactionQueued } from "pages/Finance/components"
import { isNil } from "ramda"
import React, { ReactNode } from "react"
import { Field } from "react-final-form"

const getTransactionAction = (
  transaction: PaymentTransaction,
  selectableIds: SelectableIdsDictionaryModel,
  toggleId: (argId: string) => void
) => {
  if (transaction.is_failed) return <TransactionFailed />
  if (transaction.is_queued) return <TransactionQueued />
  if (transaction.is_processing) return <TransactionInProgress />
  if (transaction.credit_guid) return <span>Credited</span>

  const hasProcessingCreditEditPermission = Auth.hasPermission([Permission.FINANCE_PROCESSING_CREDIT_EDIT])

  if (!isNil(selectableIds[transaction.guid]) && hasProcessingCreditEditPermission && transaction.is_creditable) {
    return (
      <Field<boolean>
        type="checkbox"
        name={`trans[${transaction.guid}]`}
        component={Checkbox}
        checked={selectableIds[transaction.guid]}
        data-cy={testingId.paymentProcessingTransactionList.itemCheckbox}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          toggleId(transaction.guid)
        }}
      />
    )
  }

  return null
}

export const mapPaymentTransaction = ({
  transaction,
  selectableIds,
  toggleId,
  hasCreditableTransactions,
  hasAlerts,
  hasRewindPermission,
  refetch
}: {
  transaction: PaymentTransaction
  selectableIds: SelectableIdsDictionaryModel
  toggleId: (argId: string) => void
  hasCreditableTransactions: boolean
  hasAlerts: boolean
  hasRewindPermission: boolean
  hasDeveloperPermission: boolean
  refetch: () => void
}): ReactNode[] => {
  const defaultItems = [
    <Link to={`/carers/${transaction.owner_guid}/profile/personal-details`} key={`link-${transaction.owner_guid}`}>
      {transaction.owner_name}
    </Link>,
    <Link
      to={`/carers/${transaction.owner_guid}/contracts/${transaction.user_contract_guid}`}
      key={`link=${transaction.user_contract_guid}`}
    >
      {transaction.user_contract_reference}
    </Link>,
    transaction.reference ?? "N/A",
    timeSpanToStringDuration(transaction.unconfirmed_duration),
    moneyFormat.format(transaction.subtotal_item),
    moneyFormat.format(transaction.subtotal_extra),
    moneyFormat.format(transaction.total),
    getTransactionAction(transaction, selectableIds, toggleId)
  ]

  const rewindItem = transaction.is_creditable ? (
    <RewindButton rewind_type="Transaction" item_guids={[transaction.guid]} refetch={refetch} />
  ) : null

  const alertItem = transaction.is_alerting ? <ListAlertIconBasic /> : null

  return [
    ...defaultItems,
    ...(hasRewindPermission && hasCreditableTransactions ? [rewindItem] : []),
    ...(hasAlerts ? [alertItem] : [])
  ]
}
