import { DateTime } from "luxon"
import { ContractModelRuleGroupRuleFormData } from "pages/Finance/ContractModels/helpers/RuleGroupRule"
import { ContractRuleConditionTypeAlias } from "../contractRuleCondition/types"
import { ContractServiceType } from "../contractServiceType/types"
import { ContractModelUpliftItem } from "pages/Finance/ContractModels/types"
import { ContractModelType } from "constants/modelTypes"
import { StatusEnum } from "data/commonTypes"

type InvoiceModelRequestInfoIncomeCategoryAlias = "R/DC" | "R/LIVEIN" | "R/EC" | "R/OTHER"
type IncomeModelRequestTypeAlias = "BLOCK" | "LA" | "PRV" | "DP" | "IB" | "IN" | "CCG" | "EC" | "PCT" | "CLINICAL"
type PaymentModelRequestTypeAlias = "P/WAGED" | "P/SALARY"
type ContractModelFinanceAddressType = "FULL" | "NONE" | "POSTCODE"
type ContractModelFinanceNameType = "CN" | "FULLNAME" | "INIT+CN" | "INIT"
type ContractModelFinanceOutputType = "LA/D" | "LA" | "DTL/WEEK" | "SUB/WEEK" | "SUM/WEEK"

interface ContractModelFinance {
  address_line_1: string
  address_line_2?: string
  address_type_alias: ContractModelFinanceAddressType
  city: string
  country?: string
  county?: string
  email?: string
  for_attention_of?: string
  funding_name: string
  name_type_alias: ContractModelFinanceNameType
  output_type_alias: ContractModelFinanceOutputType
  postcode: string
  term_days: number
}

export interface ContractModel {
  guid: string
  archived: boolean
  cm2000_enabled: boolean // invoice
  comment?: string
  description?: string
  dont_process_invoice: boolean // invoice
  dont_process_payment: boolean // invoice
  end?: DateTime
  finance?: ContractModelFinance // invoice
  ignore_agency: boolean // invoice
  info: {
    contact_email?: string
    contact_name?: string
    cost_centre: string
    income_category_alias: InvoiceModelRequestInfoIncomeCategoryAlias // invoice
    // invoice
    crm_details?: {
      salesforce?: {
        contract_name: string
        funder: string
        id: string
      }
    }
  }
  lca_enabled: boolean // invoice
  require_nfc: boolean
  run?: {
    guid: string
    title: string
  }

  shifts_enabled: boolean
  start: DateTime
  status: StatusEnum
  title: string
  type_alias: IncomeModelRequestTypeAlias | PaymentModelRequestTypeAlias
  uplifts: ContractModelUpliftItem[]
}
export type IndexedContractModel = ContractModel & { index: number }

export type InvoiceModel = ContractModel

export type PaymentModel = ContractModel

export interface ContractModelRequest extends ContractModel {
  modelType: ContractModelType
}

export type PaymentTypeAlias = "FIX" | "PRO"

export interface ContractModelServicePostRequest {
  guid?: string
  description?: string
  end?: DateTime
  start: DateTime
  type_guid: string
  // payment-service
  mileage_rate?: number
  travel_rate?: number
  payment_contract_guid?: string
  payment_type_alias?: PaymentTypeAlias
}

export interface ContractModelServiceFormProps extends ContractModelServicePostRequest {
  no_end_date?: boolean
}

export interface ContractModelServiceOverriddenBy {
  guid: string
  title?: string
}

export interface ContractModelService {
  guid: string
  description?: string
  end?: DateTime | null
  payment_type_alias: PaymentTypeAlias
  service_type: ContractServiceType
  start: DateTime
  status: StatusEnum
  is_overidden: boolean
  overrides: ContractModelServiceOverriddenBy[]
  // invoice - payment services:
  payment_contract_guid?: string
  payment_contract_title?: string
  mileage_rate?: number
  travel_rate?: number
}

export enum ServiceBandRoundingTypeAlias {
  NEAR = "NEAR",
  UP = "UP",
  DOWN = "DOWN",
  TO = "TO",
  NONE = "NONE"
}

export interface ContractModelServiceBand {
  guid: string
  service_guid: string
  minimum_duration_minutes: number
  maximum_duration_minutes: number | null
  rounding_minutes?: number | null
  rounding_type_alias?: ServiceBandRoundingTypeAlias
  no_maximum_duration?: boolean
  delete?: boolean
}

export interface ContractModelSalaryBand {
  guid: string
  end: string
  start: string
  holiday_tier_guid: string
  day_type_alias: string
  delete?: boolean
}

export interface ContractModelSalary {
  guid: string
  end?: DateTime
  start: DateTime
  status: StatusEnum
}

export interface ContractModelSalaryRequestWithBands extends ContractModelSalary {
  bands: ContractModelSalaryBand[]
  no_maximum_duration?: boolean
  no_end_date?: boolean
}

export interface ContractModelServiceRequestWithBands extends ContractModelServicePostRequest {
  bands?: ContractModelServiceBand[]
  no_end_date?: boolean
  add_bands?: number
  add_offset?: number
  add_interval?: number
}

export interface InvoiceModelRateRequest {
  end?: DateTime
  frequency_type_alias: BillingFrequency
  rate: number
  hours: number
  start: DateTime
  guid?: string
}

export interface InvoiceModelRate extends InvoiceModelRateRequest {
  guid: string
  contract_guid: string
  status: StatusEnum
}

export interface PaymentModelRateRequest {
  end?: DateTime
  rate: number
  start: DateTime
  guid?: string
}

export interface PaymentModelRate extends PaymentModelRateRequest {
  guid: string
  contract_guid: string
  status: StatusEnum
}

// Rates

export interface ContractModelRate {
  guid: string
  day_type_alias: string
  holiday_tier_guid?: string
  start: string
  end: string
  rate: string
  // for form
  delete?: boolean
}

export enum ContractModelEnhancedRateModifierType {
  MULTIPLY = "Multiply",
  ADD = "Add"
}

export interface ContractModelEnhancedRate {
  day_type_alias: string
  guid: string
  holiday_tier_guid?: string
  modifier_type: ContractModelEnhancedRateModifierType
  modifier: number
  // for form
  standard_rate_guid?: string
  delete?: boolean
}

export interface ApiContractModelRate extends ContractModelRate {
  enhancements?: ContractModelEnhancedRate[]
}

export interface ContractModelServiceBandWithRates {
  guid: string
  maximum_duration_minutes?: number
  minimum_duration_minutes: number
  rates: ApiContractModelRate[]
  rounding_minutes?: number
  rounding_type_alias?: ServiceBandRoundingTypeAlias
}

export interface RateEditorPayload {
  basicRates: ContractModelRate[]
  enhancedRates: ContractModelEnhancedRate[]
}

// end Rates
type AccrualPolicyTypeAlias = "ACR12.07" | "FIXED"

export interface ContractModelAccrualPolicy {
  guid: string
  accrual_cap: number
  contract_guid: string
  rollover_cap: number
  rollover_expiry?: string
  end?: DateTime
  start: DateTime
  status: StatusEnum
  type_alias: AccrualPolicyTypeAlias
}

// Invoice model - Rules
export type InvoiceModelRuleGroupPriceTypeAlias = "NONE" | "CONFIRM"
export type InvoiceModelRuleCapTypeAlias = "TIME|MIN" | "TIMEONLY" | "TIME+%" | "TIME+MIN" | "TIME-%" | "TIME-MIN"
export type InvoiceModelRuleChargeTypeAlias = "ZERO" | "ACTUAL" | "ROSTER" | "C/ACTUAL" | "C/ROSTER"
export type InvoiceModelRulePeriodTypeAlias = "NONE" | "BETWEEN" | "INSIDE" | "OUTSIDE"

export interface InvoiceModelRuleGroupRequest {
  end?: DateTime
  price_type_alias: InvoiceModelRuleGroupPriceTypeAlias
  rate?: number
  start: DateTime
  title: string
}

export interface InvoiceModelRuleGroup extends InvoiceModelRuleGroupRequest {
  guid: string
  status: StatusEnum
}

export interface InvoiceModelRuleGroupRuleRequest {
  cap_type_alias?: InvoiceModelRuleCapTypeAlias
  charge_type_alias: InvoiceModelRuleChargeTypeAlias
  condition_guid: string
  figure?: number
  guid?: string
  period_end?: string // date-span
  period_start?: string // date-span
  period_type_alias?: InvoiceModelRulePeriodTypeAlias
}

export interface InvoiceModelRuleGroupRule extends InvoiceModelRuleGroupRuleRequest {
  guid: string
  rule_type_alias: ContractRuleConditionTypeAlias
}

export interface InvoiceModelRuleGroupForm extends InvoiceModelRuleGroupRequest {
  guid?: string
  rules?: ContractModelRuleGroupRuleFormData[] // mapped rules
  no_end_date?: boolean
}

export interface ContractModelSummaryRequest {
  owner_guid: string
  contracts: {
    [key: string]: string
  }
  funders: string[]
}

// calendar
export interface CalendarPeriod {
  guid: string
  start: DateTime
  end: DateTime
  invoice_deadline?: DateTime
  process_date: DateTime
  // for form
  delete?: boolean
  index?: number
}

export type BillingFrequency = "1/WEEK" | "2/WEEK" | "4/WEEK" | "1/MONTH" | "3/MONTH" | "6/MONTH" | "12/MONTH" | "DAILY"

export interface CalendarRequest {
  // create calendar
  frequency_type_alias: BillingFrequency
  title: string
  start: DateTime
  end?: DateTime
}

export interface AddPeriodPayload {
  periodCount: number
  processDays?: number
  // payment:
  processDateOffset?: string
  invoiceDeadlineOffset?: string
  invoiceDeadlineDays?: number
  lastEndDateFromCalendarPeriod?: DateTime
}

export interface AddCalendarRequest extends CalendarRequest, AddPeriodPayload {
  modelType: ContractModelType
  periods?: CalendarPeriod[]
}

export interface AddPaymentCalendarRequest extends CalendarRequest, AddPeriodPayload {
  modelType: ContractModelType
  periods?: CalendarPeriod[]
}

export interface CalendarResponse {
  guid: string
  status: StatusEnum
  frequency_type_alias: BillingFrequency
  title: string
  start: DateTime
  end: DateTime
}
