import React from "react"
import Tooltip from "@mui/material/Tooltip"
import ClearIcon from "@mui/icons-material/Clear"
import { IconButton } from "@mui/material"

interface Props {
  disabled?: boolean
  onClick: () => void
}

const UnassignOperatorButton: React.FC<Props> = ({ onClick, disabled }) => {
  const handleClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    ev.stopPropagation()
    onClick()
  }

  return (
    <Tooltip title="Unassign operator from branch">
      <IconButton aria-label="unassign operator" size="small" disabled={disabled} onClick={handleClick}>
        <ClearIcon />
      </IconButton>
    </Tooltip>
  )
}

export default UnassignOperatorButton
