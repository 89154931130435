import React, { ReactNode } from "react"
import testingId from "constants/testingId"
import { SxProps, Theme, Typography } from "@mui/material"

interface HeaderProps {
  title: ReactNode | string
  "data-cy"?: string
  sx?: SxProps<Theme>
}

const Header: React.FC<HeaderProps> = ({ title, "data-cy": testingIdProp, sx }) => (
  <Typography variant="h4" sx={sx} data-cy={testingIdProp ?? testingId.header}>
    {title}
  </Typography>
)
export default Header
