import { DateTime } from "luxon"
import { filter, map, pipe } from "ramda"
import { ContractModel } from "data/finance/contractModel/types"
import { ContractModelUplift } from "../types"

export const filterAndMapUpliftContracts = ({
  startDate,
  contracts = [],
  isInvoiceModel
}: {
  startDate?: DateTime
  contracts?: ContractModel[]
  isInvoiceModel: boolean
}): ContractModelUplift[] => {
  return pipe(
    filter<ContractModel>((contract) => !contract.end),
    map<ContractModel, ContractModelUplift>((contract) => {
      const hasInvalidStart = startDate && contract.start.toMillis() >= startDate.toMillis()
      const hasPendingStatus = contract.uplifts?.some((uplift) => uplift.status === "Pending")
      const isInvalid = hasInvalidStart || hasPendingStatus

      const errorMessageGroupType = isInvoiceModel ? "Invoice" : "Pay"
      const errorMessage = hasPendingStatus
        ? `This ${errorMessageGroupType} Group is disabled because it is already being uplifted`
        : `This ${errorMessageGroupType} Group is disabled because it starts after start of the uplift.`

      return { ...contract, disabled: isInvalid, errorMessage: isInvalid ? errorMessage : "" }
    })
  )(contracts)
}
