import React, { FC } from "react"
import { RiskScoreValues } from "models/mobilityAssessment"
import { Box } from "@mui/material"

interface StripProps {
  value: RiskScoreValues.LOW | RiskScoreValues.MEDIUM | RiskScoreValues.HIGH | null
}

const Strip: FC<StripProps> = ({ value }) => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      flexShrink: 0,
      alignSelf: "stretch",
      width: "8px",
      borderRadius: `8px 0 0 8px`,
      ...(value === RiskScoreValues.LOW ? { backgroundColor: "success.main" } : {}),
      ...(value === RiskScoreValues.MEDIUM ? { backgroundColor: "warning.main" } : {}),
      ...(value === RiskScoreValues.HIGH ? { backgroundColor: "error.main" } : {})
    }}
  />
)

export default Strip
