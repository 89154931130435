import { FinanceApi } from "api"
import { RunType } from "constants/runType"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { Run } from "./types"

export const getRunUrl = (runType: RunType): "invoice-runs" | "payment-runs" =>
  runType === RunType.INVOICE ? "invoice-runs" : "payment-runs"

export enum QueryKeyParts {
  LIST = "list",
  RUN = "run"
}

export const queryKeys = {
  getRunList: (runType: RunType): QueryKey => [QueryKeyParts.RUN, QueryKeyParts.LIST, { runType }]
}

export const useRunListQuery = ({ runType }: { runType: RunType }): UseQueryResult<Run[], Error> =>
  useQuery({
    queryKey: queryKeys.getRunList(runType),
    queryFn: async () => FinanceApi.get(`/finance/${getRunUrl(runType)}`)
  })
