import React, { FC } from "react"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Typography from "@mui/material/Typography"
import { CheckInCheckOutWithVariantProps } from "./types"
import WarningIcon from "@mui/icons-material/Warning"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { isWithinRange } from "./index"
import { ApiUserClient } from "models"
import CheckInCheckOutBody from "./CheckInCheckOutBody"

const CheckInCheckOutAccordion: FC<
  CheckInCheckOutWithVariantProps & {
    patient: undefined | ApiUserClient
    mapId: string
  }
> = ({ visit, patient, variant = "in", mapId }) => {
  const getStatusIcon = () => {
    if ((variant === "in" && !visit.isCheckedIn()) || (variant === "out" && !visit.isCheckedOut())) {
      return undefined
    }

    return isWithinRange({ visit, variant }) ? (
      <CheckCircleIcon
        sx={(theme) => ({
          color: theme.palette.success.light,
          verticalAlign: "text-bottom",
          marginLeft: theme.spacing(1)
        })}
      />
    ) : (
      <WarningIcon
        sx={(theme) => ({
          color: theme.palette.warning.light,
          verticalAlign: "text-bottom",
          marginLeft: "5px"
        })}
      />
    )
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`check-${variant}`}
        id={`check-${variant}`}
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, .125)"
        }}
      >
        <Typography variant="h6">
          {variant === "in" ? "Check-in" : "Check-out"}
          {getStatusIcon()}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          padding: theme.spacing(2)
        })}
      >
        <CheckInCheckOutBody visit={visit} patient={patient} mapId={mapId} />
      </AccordionDetails>
    </Accordion>
  )
}
export default CheckInCheckOutAccordion
