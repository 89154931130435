import React, { memo, ReactNode } from "react"
import { Header, Link } from "components"
import { ContractModelType } from "constants/modelTypes"
import { PATH } from "constants/path"
import { Box } from "@mui/material"

interface Props {
  modelType: ContractModelType
  title?: ReactNode | string
  action?: ReactNode
  "data-cy"?: string
}

export const ContractModelTitle = memo(({ modelType, title, "data-cy": testId, action }: Props) => {
  const isInvoice = modelType === ContractModelType.INVOICE

  const modelTypeName = isInvoice ? "Invoice models" : "Payment models"
  const modelTypeUrl = isInvoice ? PATH.FINANCE.INVOICE_MODELS : PATH.FINANCE.PAYMENT_MODELS

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Header
        title={
          title ? (
            <>
              <Link
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline"
                  }
                }}
                to={modelTypeUrl}
              >
                {modelTypeName}
              </Link>{" "}
              / {title}
            </>
          ) : (
            modelTypeName
          )
        }
        data-cy={testId}
      />
      {action}
    </Box>
  )
})

ContractModelTitle.displayName = "ContractModelTitle"
