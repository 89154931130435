import { ContentContainer, Header } from "components"
import { useUnitUserDetailQuery } from "data/finance/unit/queries"
import { Unit } from "data/finance/unit/types"
import React, { memo } from "react"
import { UnitUserProfile } from "./components/UnitUserProfile"
import { useParams } from "react-router-dom"

export const UnitUserEdit: React.FC<{ unit: Unit }> = memo(({ unit }) => {
  const { userGuid } = useParams()
  const { data: user } = useUnitUserDetailQuery({ unitGuid: unit.guid, userGuid })

  return (
    <ContentContainer>
      <Header title={`${unit.name} : Users / ${user?.user_name}`} />
      <UnitUserProfile {...{ mode: "update", unitGuid: unit.guid, user }} />
    </ContentContainer>
  )
})

UnitUserEdit.displayName = "UnitUserEdit"
