import React from "react"
import { Grid } from "@mui/material"
import { Field } from "react-final-form"
import { FormSectionHeader, Preference } from "components/Form"
import validators from "lib/validators"

interface PreferenceField {
  name: string
  label: string
}

const PreferenceGroup = ({ fields }: { fields: PreferenceField[] }) => {
  return (
    <Grid item md={6}>
      {fields.map(({ name, label }) => {
        return (
          <div key={name}>
            <Field
              name={name}
              label={label}
              component={Preference}
              options={[
                { value: 0, title: "Any" },
                { value: 1, title: "Yes" },
                { value: -1, title: "No" }
              ]}
              validate={validators.required}
            />
          </div>
        )
      })}
    </Grid>
  )
}

const ClientProfileSectionPreference = ({
  title,
  fields
}: {
  title: string
  fields: { left: PreferenceField[]; right: PreferenceField[] }
}) => {
  return (
    <>
      <FormSectionHeader title={title} />
      <Grid container spacing={8}>
        <PreferenceGroup fields={fields.left} />
        <PreferenceGroup fields={fields.right} />
      </Grid>
    </>
  )
}

export default ClientProfileSectionPreference
