import { Grid, Typography } from "@mui/material"
import { Link } from "components"
import testingId from "constants/testingId"
import { DateTimeFormat } from "lib/datetime"
import React, { memo } from "react"
import { ApiShiftDetailResponse } from "types/Shifts/ShiftList/types"

interface OwnProps {
  shift: ApiShiftDetailResponse
}

const ShiftDetailInfo: React.FC<OwnProps> = ({ shift }) => {
  return (
    <Grid container spacing={3}>
      <Grid item md={3}>
        <Typography variant="body2" color="textSecondary">
          Shift name
        </Typography>
        <Typography variant="h6" data-cy={testingId.shifts.detail.shiftName}>
          {shift.title}
        </Typography>
      </Grid>
      <Grid item md={3}>
        <Typography variant="body2" color="textSecondary">
          Carer name
        </Typography>
        <Typography variant="h6" data-cy={testingId.shifts.detail.carerName}>
          {shift.carer_guid ? (
            <Link external to={`/carers/${shift.carer_guid}/profile/personal-details`}>
              {shift.carer_name}
            </Link>
          ) : (
            shift.carer_name
          )}
        </Typography>
      </Grid>
      <Grid item md={3}>
        <Typography variant="body2" color="textSecondary">
          Scheduled date &amp; time
        </Typography>
        <Typography variant="h6" data-cy={testingId.shifts.detail.schedule}>
          {shift.start.toFormat(DateTimeFormat.DATE)}&mdash;{shift.end.toFormat(DateTimeFormat.DATE)}
          <br />
          {shift.start.toFormat(DateTimeFormat.TIME)}&mdash;{shift.end.toFormat(DateTimeFormat.TIME)}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default memo(ShiftDetailInfo)
