import { useReplaceFileMutation } from "data/finance/development/mutations"
import React, { memo, MouseEvent, useCallback, useEffect, useState } from "react"
import { DevButton } from "./DevButton"

interface OwnProps {
  transaction_guids: string[]
  isInvoice: boolean
  label?: string
  isDisabled?: boolean
}

export const ReplaceFileButton: React.FC<OwnProps> = memo(({ transaction_guids, isInvoice, label, isDisabled }) => {
  ReplaceFileButton.displayName = "ReplaceFileButton"

  const { mutateAsync: replaceFile } = useReplaceFileMutation()

  const [disabled, setDisabled] = useState(false)

  const onClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      replaceFile({ transaction_guids, isInvoice })
      setDisabled(true)
    },
    [replaceFile, transaction_guids, isInvoice]
  )

  useEffect(() => {
    if (disabled) {
      setTimeout(() => setDisabled(false), 300000)
    }
  }, [disabled])

  const fileType = isInvoice ? "inv" : "gpa"
  const buttonLabel = label ?? fileType
  return (
    <DevButton onClick={onClick} size="medium" disabled={isDisabled ?? disabled}>
      {`Replace ${buttonLabel}`}
    </DevButton>
  )
})
