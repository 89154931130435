import React, { memo, useState } from "react"
import { Header, ContentContainer } from "components"
import { Box, Tab, Tabs } from "@mui/material"
import { RunType } from "constants/runType"
import { Button } from "components/Form"
import RunListTable from "./components/RunListTable"
import { RunCreateModal } from "./components/RunCreateModal"
import { useRunListQuery } from "data/finance/run/queries"

const RunList: React.FC = () => {
  const [runType, setRunType] = useState<RunType>(RunType.INVOICE)
  const isInvoiceRunType: boolean = runType === RunType.INVOICE

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: RunType) => {
    setRunType(newValue)
  }

  const [modal, setModal] = useState<boolean>(false)
  const destroyModal = () => {
    setModal(false)
  }
  const openModal = () => {
    setModal(true)
  }

  const { data: invoiceRuns } = useRunListQuery({ runType: RunType.INVOICE })
  const { data: paymentRuns } = useRunListQuery({ runType: RunType.PAYMENT })
  const currentRuns = isInvoiceRunType ? invoiceRuns : paymentRuns

  return (
    <>
      <ContentContainer>
        <Header {...{ title: "Runs" }} />
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: theme.palette.primary.main,
            marginTop: "20px"
          })}
        >
          <Tabs {...{ value: runType, onChange: handleTabChange }}>
            <Tab {...{ label: "INVOICING", value: RunType.INVOICE }} />
            <Tab {...{ label: "PAYMENTS", value: RunType.PAYMENT }} />
          </Tabs>
          <Box {...{ display: "flex", justifyContent: "flex-end", flexGrow: 0 }}>
            <Button {...{ onClick: openModal }}>
              {isInvoiceRunType ? "Create Invoicing Run" : "Create Payments Run"}
            </Button>
          </Box>
        </Box>
        <RunListTable {...{ runType, runs: currentRuns }} />
      </ContentContainer>
      {modal && (
        <RunCreateModal
          {...{
            runType,
            destroyModal,
            runs: currentRuns
          }}
        />
      )}
    </>
  )
}

export default memo(RunList)
