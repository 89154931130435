import React, { useState, memo } from "react"
import { ContentContainer, Header, TabPanel } from "components"
import testingId from "constants/testingId"
import { Unit } from "data/finance/unit/types"
import { useUnitContractDetailQuery } from "data/finance/unit/queries"
import { FormStage } from "constants/form"
import { ContractBackButton } from "pages/Finance/components/ContractBackButton"
import { UserContractTab } from "pages/Finance/components/types"
import { UnitContractDetailSettings } from "./components/UnitContractDetailSettings"
import { ContractDetailHeader } from "pages/Finance/components/ContractDetailHeader"
import { ContractTabBar } from "pages/Finance/components/ContractTabBar"
import { Box } from "@mui/material"
import { ContractFileList } from "pages/Finance/components/ContractFileList"
import { useUserContractTabState } from "helpers/contractHelpers"
import { useParams } from "react-router-dom"

interface OwnProps {
  unit: Unit
}

export const UnitContractDetail: React.FC<OwnProps> = memo(({ unit }) => {
  UnitContractDetail.displayName = "UnitContractDetail"

  const { contractId } = useParams()
  const isEdit = !!contractId
  const [formStage, setFormStage] = useState<FormStage>(isEdit ? FormStage.EDIT : FormStage.CREATE)

  const [tab, setTab] = useUserContractTabState()

  const { data: contract } = useUnitContractDetailQuery({ unitGuid: unit.guid, userContractGuid: contractId })

  return (
    <ContentContainer data-cy={testingId.client.contract.detail.screen}>
      <Header title={`Unit - ${unit.name} - Contracts`} />
      <ContractBackButton {...{ isEdit, formStage, setFormStage }} />
      {!isEdit && <UnitContractDetailSettings {...{ unit, contract, formStage, setFormStage, isEdit }} />}

      {isEdit && (
        <>
          <ContractDetailHeader {...{ userProfile: unit, contract, navigateTo: "../edit" }} />
          <ContractTabBar {...{ tab, setTab }} />
          <Box my={2}>
            <TabPanel value={tab} index={UserContractTab.SETTINGS}>
              <UnitContractDetailSettings {...{ unit, contract, formStage, setFormStage, isEdit }} />
            </TabPanel>
            <TabPanel value={tab} index={UserContractTab.FILES}>
              {contract && (
                <ContractFileList {...{ userGuid: unit.guid, contractGuid: contract.guid, userType: "Unit" }} />
              )}
            </TabPanel>
          </Box>
        </>
      )}
    </ContentContainer>
  )
})
