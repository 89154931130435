import { AppBar, Box, Tab, Tabs } from "@mui/material"
import testingId from "constants/testingId"
import { TransactionDetailType } from "pages/Finance/types"
import React, { Dispatch, memo, SetStateAction } from "react"
import { TransactionDetailMoney } from "./TransactionDetailMoney"
import { PaymentTransactionCharge, PaymentTransactionItem } from "data/finance/paymentProcessing/types"

interface OwnProps {
  setTab: Dispatch<SetStateAction<TransactionDetailType>>
  tab: TransactionDetailType
  filteredVisits: PaymentTransactionItem[]
  filteredCharges: PaymentTransactionCharge[]
}

export const TransactionDetailTabBar: React.FC<OwnProps> = memo(({ tab, setTab, filteredVisits, filteredCharges }) => {
  const handleChange = (_: React.ChangeEvent<{}>, newValue: TransactionDetailType) => {
    setTab(newValue)
  }

  return (
    <Box
      display="flex"
      m={2}
      mx={0}
      justifyContent="space-between"
      alignItems="center"
      sx={(theme) => ({
        borderBottom: "1px solid",
        borderColor: theme.palette.primary.main
      })}
    >
      <AppBar
        position="relative"
        color="transparent"
        sx={{
          boxShadow: "none",
          width: "auto"
        }}
      >
        <Tabs value={tab} onChange={handleChange}>
          <Tab
            label="Visits"
            value={TransactionDetailType.VISIT}
            data-cy={testingId.paymentProcessingTransactionDetail.tabVisits}
          />
          <Tab
            label="Extras"
            value={TransactionDetailType.EXTRA}
            data-cy={testingId.paymentProcessingTransactionDetail.tabExtras}
          />
        </Tabs>
      </AppBar>
      <TransactionDetailMoney {...{ isVisits: tab === TransactionDetailType.VISIT, filteredCharges, filteredVisits }} />
    </Box>
  )
})

TransactionDetailTabBar.displayName = "TransactionDetailTabBar"
