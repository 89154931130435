type Props = {
  [key: string]: number | string
}

type HeapTrackProperties = {
  [key: string]: string | number | boolean | null
}

/**
 * @deprecated
 * The track method lets you record any actions your users perform
 */
const track = (eventName: string, properties?: Props): void => {
  console.warn(
    "This method is being deprecated, please update your code to support heap tracking instead of segment...",
    eventName,
    properties
  )
}

export interface AnalyticsClient {
  track: (eventName: string, properties?: Props) => void
  trackCustomEvent?: (heapEventId: string, properties?: HeapTrackProperties) => void
  trackHeapCustomEvent?: (heapEventId: string, properties?: HeapTrackProperties) => void
}

/**
 * The specific functions below to handle heap tracking will be merged in the future
 * with the current function definitions.
 * At the moment segment is still being used so to avoid issues
 * heap functions will be called explicity for the time being
 */
const runHeapFn = (callback: (heap: IHeap) => void) => {
  if (!window.heap) {
    console.warn("No valid heap script found, event tracking is disabled")
    return
  }

  callback(window.heap)
}

const trackCustomEvent = (heapEventId: string, properties?: HeapTrackProperties) =>
  runHeapFn((heap: IHeap) => heap.track(heapEventId, properties))

/**
 * @deprecated
 */
const trackHeapCustomEvent = (heapEventId: string, properties?: HeapTrackProperties) => {
  console.warn("trackHeapCustomEvent is deprecated, please update the fn calls to trackCustomEvent")
  runHeapFn((heap: IHeap) => heap.track(heapEventId, properties))
}

export const createAnalyticsMFEclient = (mfe: string): AnalyticsClient => ({
  /**
   * @deprecated
   */
  track: (eventName: string, properties?: Props) => track(`${mfe}: ${eventName}`, properties),
  trackCustomEvent,
  trackHeapCustomEvent
})

export const dcpAnalyticsClient = {
  trackCustomEvent,
  trackHeapCustomEvent
}
