import React from "react"
import { Box, Button, Typography } from "@mui/material"
import { DateField } from "components/Form/DateField"
import CheckInCheckOutReasonField from "./CheckInCheckOutReasonField"
import testingId from "constants/testingId"

interface Props {
  destroyModal: () => void
  invalid: boolean
  submitting: boolean
}

const CheckInEdit = ({ destroyModal, invalid, submitting }: Props) => {
  return (
    <Box>
      <Typography variant="body1" paragraph>
        Please set a check in time for this visit.
      </Typography>
      <DateField name="time" label="Check in time" required withTime />
      <CheckInCheckOutReasonField testingId={testingId.visit.detail.checkinModalReason} />
      <Box display="flex" justifyContent="space-between" marginBottom={3} marginTop={12}>
        <Button
          id="visit-details-check-in-modal-cancel-btn"
          variant="text"
          fullWidth={false}
          onClick={() => destroyModal()}
        >
          Cancel
        </Button>
        <Button
          id="visit-details-check-in-modal-submit-btn"
          type="submit"
          fullWidth={false}
          disabled={invalid || submitting}
          variant="contained"
        >
          Set check-in time
        </Button>
      </Box>
    </Box>
  )
}

export default CheckInEdit
