import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { DateTimeFormat } from "lib/datetime"
import React, { memo } from "react"
import { Field, Form } from "react-final-form"
import { Button, Checkbox } from "components/Form"
import { useContractFileDownloadHandler } from "data/finance/contractFile/handlers"
import { ApiUserContractFile, UserContractType } from "data/finance/contractFile/types"
import { Paginator } from "components"
import { SetPagination } from "lib/types"
import { SelectableTableRow } from "components/SelectableTableRow"

const headers = ["File Name", "Type", "Start", "End", "Select"]

interface OwnProps {
  files: ApiUserContractFile[]
  userGuid: string
  userType: UserContractType
  contractGuid: string
  setPagination: SetPagination
}

const downloadDisabled = (values: Record<string, boolean>) => Object.values(values).every((v) => !v)

export const ContractFileListTable = memo(({ files, userGuid, contractGuid, userType, setPagination }: OwnProps) => {
  const onSubmit = useContractFileDownloadHandler({
    userGuid,
    contractGuid,
    files,
    userType
  })

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((item) => (
                  <TableCell key={item}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((file) => {
                const isSelected = !!values[file.guid]
                return (
                  <SelectableTableRow key={file.guid} isSelected={isSelected}>
                    <TableCell>{file.file_name}</TableCell>
                    <TableCell>{file.file_type_alias}</TableCell>
                    <TableCell>{file.start.toFormat(DateTimeFormat.DATE)}</TableCell>
                    <TableCell>{file.end ? file.end.toFormat(DateTimeFormat.DATE) : "--/--/--"}</TableCell>
                    <TableCell>
                      <Field type="checkbox" name={file.guid} component={Checkbox} />
                    </TableCell>
                  </SelectableTableRow>
                )
              })}
            </TableBody>
          </Table>
          <Paginator itemsLength={files.length} setPagination={setPagination} />

          <Box py={3} display="flex" justifyContent="flex-end" alignItems="center">
            <Button type="submit" fullWidth={false} color="primary" disabled={downloadDisabled(values)}>
              Download
            </Button>
          </Box>
        </form>
      )}
    </Form>
  )
})

ContractFileListTable.displayName = "ContractFileListTable"
