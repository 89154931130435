import { SchemaOnlyValidator } from "lib/types"

export const getClientProfileValidators = (): SchemaOnlyValidator => {
  return {
    hr: {
      start_date: { validators: [{ type: "required" }] }
    },
    profile: {
      address: { validators: [{ type: "required" }] },
      email: { validators: [{ type: "required" }, { type: "email" }] },
      first_name: { validators: [{ type: "required" }] },
      gender: { validators: [{ type: "required" }] },
      last_name: { validators: [{ type: "required" }] },
      phone_cera: { validators: [{ type: "phone" }] },
      phone_landline: { validators: [{ type: "phone" }] },
      phone_mobile: { validators: [{ type: "phone" }] },
      salutation: { validators: [{ type: "required" }] },
      short_name: { validators: [{ type: "required" }] },
      date_of_birth: { validators: [{ type: "required" }] }
    },
    regions: { validators: [{ type: "required" }] },
    status: { validators: [{ type: "required" }] }
  }
}
