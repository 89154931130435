import React from "react"
import { Box, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied"

const ServerFailure: React.FC = () => {
  return (
    <Box
      sx={{
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        mx: "auto"
      }}
    >
      <Box sx={{ bgcolor: grey[100], p: 10, display: "flex", alignItems: "center", flexDirection: "column" }}>
        <Typography paragraph color="primary">
          <SentimentVeryDissatisfiedIcon sx={{ fontSize: 72 }} />
        </Typography>
        <Typography paragraph variant="h2" sx={{ margin: 2 }} color="textSecondary">
          Application failed to load
        </Typography>
        <Typography paragraph variant="h5" sx={{ margin: 2 }}>
          This is an error on our side, not yours.
        </Typography>
        <Typography paragraph variant="body1" sx={{ margin: 2, textAlign: "center" }}>
          Sorry about that, our engineers are working on a solution, please give it a minute and try to reload your
          browser.
          <br />
          If the problem persists, do not hesitate to contact our user support.
        </Typography>
      </Box>
    </Box>
  )
}
export default ServerFailure
