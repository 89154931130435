import { ContractModelType } from "constants/modelTypes"
import { FORM_ERROR, ValidationErrors } from "final-form"
import { filter } from "ramda"
import { RateUpliftForm, RateUpliftFormPage, RateUpliftFormModel } from "../types"
import { getServicesFromUpliftModel, getPickedUpliftModels } from "../helpers/upliftFormGetters"

const isAtLeastOnePicked = (models: { [key: string]: { picked: boolean } } = {}) => {
  return Object.values(models).some((item) => item.picked)
}

const everyModelHasValidService = (values: RateUpliftForm) => {
  const selectedModels: { [key: string]: RateUpliftFormModel } = filter((model: RateUpliftFormModel) => {
    const modelPicked = model.picked
    const modelHasValidService = !!model.serviceItems?.some((service) => !service.disabled)
    return modelPicked && modelHasValidService
  })(values.models)

  return Object.values(selectedModels).every(
    (model) => model.services && Object.values(model.services).some((service) => service.picked)
  )
}

const everyServiceIsValid = (values: RateUpliftForm) => {
  return getPickedUpliftModels(values).every((model) => {
    if (!model.services) return true
    return getServicesFromUpliftModel(model).every((service) => {
      if (!service.bands) return true
      // if has bands then bands do not have rates or rates have new_value
      return Object.values(service.bands).every(
        (band) =>
          !band.rates || Object.values(band.rates).every((rate) => rate.new_rate && !isNaN(Number(rate.new_rate)))
      )
    })
  })
}

const ratesExist = (values: RateUpliftForm): boolean => {
  if (!values.models) return false
  return getPickedUpliftModels(values).some((model) => {
    if (!model.services) return false
    return getServicesFromUpliftModel(model).some((service) => {
      if (!service.bands) return false
      // some band has a rate
      return Object.values(service.bands).some((band) => band.rates)
    })
  })
}

export const validatePayUplift =
  (page: RateUpliftFormPage) =>
  (values: RateUpliftForm): ValidationErrors => {
    const groupType = values.modelType === ContractModelType.INVOICE ? "Invoice" : "Pay"

    let errors: ValidationErrors = {}
    if (page === RateUpliftFormPage.PAY_GROUP && !isAtLeastOnePicked(values.models)) {
      errors = { [FORM_ERROR]: `Please select at least one ${groupType} Group to uplift.` }
    }

    if (page === RateUpliftFormPage.CARE_TYPE && !everyModelHasValidService(values)) {
      errors = {
        [FORM_ERROR]: `Please select least one Service from each ${groupType} Group (if available) to uplift.`
      }
    }

    if (page === RateUpliftFormPage.RATES && !everyServiceIsValid(values)) {
      errors = { [FORM_ERROR]: "All services with rates must have Uplift Type and set new values." }
    }

    if (page === RateUpliftFormPage.RATES && !ratesExist(values)) {
      errors = { [FORM_ERROR]: "There are no rates to uplift." }
    }

    return errors
  }
