import { FinanceApi } from "api"
import { DateTime } from "luxon"
import { UseMutationResult, useMutation } from "react-query"
import { ApiError } from "stores/useUIStore/types"
import { useNavigate } from "react-router-dom"
import { useApiMessage } from "stores"
import { ApiClientContract, ApiClientContractPayload } from "./types"

interface PurchaseOrderProps {
  payload: ApiUserContractPurchaseOrderRequest
  clientGuid: string
  clientContractGuid: string
}

export interface ApiUserContractPurchaseOrderRequest {
  file: File
  file_name: string
  reference: string
}

export interface ApiUserContractPurchaseOrder {
  guid: string
  status: string
  start: DateTime
  end: DateTime | undefined
  user_contract_guid: string
  reference: string
  user_contract_file_guid: string | undefined
}

export const useUserContractPurchaseOrderMutation = ({
  destroyModal
}: {
  destroyModal: () => void
}): UseMutationResult<ApiUserContractPurchaseOrder, ApiError, PurchaseOrderProps> => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async ({ clientGuid, clientContractGuid, payload }: PurchaseOrderProps): Promise<ApiUserContractPurchaseOrder> => {
      const formData = new FormData()
      formData.append("file", payload.file)
      formData.append("reference", payload.reference)
      formData.append("file_name", payload.file.name)

      return FinanceApi.post(`/finance/clients/${clientGuid}/contracts/${clientContractGuid}/purchase-order`, formData)
    },
    {
      onSuccess: async () => {
        showSuccessMessage("Purchase Order successfully uploaded.")
        destroyModal()
      },
      onError: async (apiError: ApiError) => {
        const errorMessage =
          apiError?.status === 409
            ? "Purchase Order for this contract already exists."
            : "Something went wrong while uploading purchase order. Please try again later or contact user support."

        showErrorMessage(errorMessage, { apiError })
      }
    }
  )
}

export const useClientContractCreateMutation = ({
  clientGuid
}: {
  clientGuid: string
}): UseMutationResult<ApiClientContract, ApiError, ApiClientContractPayload> => {
  const navigate = useNavigate()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async (payload: ApiClientContractPayload): Promise<ApiClientContract> => {
      return FinanceApi.post(`/finance/clients/${clientGuid}/contracts`, payload)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Client Contract successfully created.")
        navigate(`/clients/${clientGuid}/contracts`)
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not create Client Contract.", { apiError })
      }
    }
  )
}

export const useClientContractEditMutation = ({
  clientGuid
}: {
  clientGuid: string
}): UseMutationResult<ApiClientContract, ApiError, ApiClientContractPayload> => {
  const navigate = useNavigate()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async (payload: ApiClientContractPayload): Promise<ApiClientContract> => {
      return FinanceApi.put(`/finance/clients/${clientGuid}/contracts/${payload.guid}`, payload)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Client Contract successfully updated.")
        navigate(`/clients/${clientGuid}/contracts`)
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not edit Client Contract.", { apiError })
      }
    }
  )
}

export const useClientContractDeleteMutation = ({
  clientGuid
}: {
  clientGuid?: string
}): UseMutationResult<void, Error, { contractGuid: string }> => {
  return useMutation(async ({ contractGuid }): Promise<void> => {
    return FinanceApi.delete(`/finance/clients/${clientGuid}/contracts/${contractGuid}`)
  })
}
