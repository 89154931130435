import { FinanceApi } from "api"
import { enabledByMandatoryUrlParams, setDayBorders } from "data/helpers"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import {
  PaymentTransaction,
  PaymentTransactionCharge,
  PaymentTransactionGroup,
  PaymentTransactionItem,
  TransactionChargeHolidayCalculation
} from "./types"
import { useLocation } from "react-router-dom"
import { PaymentTransactionItemVisitDetailCalculations } from "pages/Finance/types"
import { ApiTransactionShiftCalculation } from "../invoiceProcessing/types"

interface UseShiftCalculationsQueryParams {
  periodGuid?: string
  transactionGuid?: string
  transactionShiftGuid?: string
  visitGuid?: string
  enabled?: boolean
}

export enum QueryKeyParts {
  PAYMENT_PROCESSING = "payment_processing",
  TRANSACTION_GROUP = "transaction_group",
  TRANSACTION = "transaction",
  SHIFT = "shift",
  CALCULATION = "calculation",
  ITEM = "item",
  LIST = "list",
  VISIT = "visit",
  CHARGE = "charge",
  HOLIDAY = "holiday"
}

export const queryKeys = {
  getPaymentProcessingTransactionGroupListKey: (search: string): QueryKey => [
    QueryKeyParts.PAYMENT_PROCESSING,
    QueryKeyParts.TRANSACTION_GROUP,
    QueryKeyParts.LIST,
    { search }
  ],
  getPaymentProcessingTransactionGroupKey: ({ periodGuid }: { periodGuid?: string }): QueryKey => [
    QueryKeyParts.PAYMENT_PROCESSING,
    QueryKeyParts.TRANSACTION_GROUP,
    QueryKeyParts.ITEM,
    { periodGuid }
  ],
  getPaymentProcessingTransactionVisitListKey: ({
    periodGuid,
    transactionGuid
  }: {
    periodGuid?: string
    transactionGuid?: string
  }): QueryKey => [
    QueryKeyParts.PAYMENT_PROCESSING,
    QueryKeyParts.TRANSACTION,
    QueryKeyParts.VISIT,
    QueryKeyParts.LIST,
    { periodGuid, transactionGuid }
  ],
  getPaymentProcessingTransactionChargeListKey: ({
    periodGuid,
    transactionGuid
  }: {
    periodGuid?: string
    transactionGuid?: string
  }): QueryKey => [
    QueryKeyParts.PAYMENT_PROCESSING,
    QueryKeyParts.TRANSACTION,
    QueryKeyParts.CHARGE,
    QueryKeyParts.LIST,
    { periodGuid, transactionGuid }
  ],
  getShiftCalculationKey: ({
    periodGuid,
    transactionGuid,
    transactionShiftGuid,
    visitGuid
  }: {
    periodGuid?: string
    transactionGuid?: string
    transactionShiftGuid?: string
    visitGuid?: string
  }): QueryKey => [
    QueryKeyParts.PAYMENT_PROCESSING,
    QueryKeyParts.TRANSACTION,
    QueryKeyParts.SHIFT,
    QueryKeyParts.CALCULATION,
    { periodGuid, transactionGuid, transactionShiftGuid, visitGuid }
  ],
  getPaymentProcessingTransactionListKey: ({
    periodGuid,
    search
  }: {
    periodGuid?: string
    search?: string
  }): QueryKey => [
    QueryKeyParts.PAYMENT_PROCESSING,
    QueryKeyParts.TRANSACTION,
    QueryKeyParts.LIST,
    { periodGuid, search }
  ],
  getPaymentProcessingTransactionKey: ({
    periodGuid,
    transactionGuid
  }: {
    periodGuid?: string
    transactionGuid?: string
  }): QueryKey => [QueryKeyParts.PAYMENT_PROCESSING, QueryKeyParts.TRANSACTION, { periodGuid, transactionGuid }],
  getPaymentProcessingTransactionVisitCalculations: ({
    periodGuid,
    transactionGuid,
    transactionVisitGuid
  }: {
    periodGuid?: string
    transactionGuid?: string
    transactionVisitGuid?: string
  }): QueryKey => [
    QueryKeyParts.PAYMENT_PROCESSING,
    QueryKeyParts.TRANSACTION,
    QueryKeyParts.VISIT,
    QueryKeyParts.CALCULATION,
    { periodGuid, transactionGuid, transactionVisitGuid }
  ],
  getPaymentProcessingTransactionChargeHolidayCalculations: ({
    periodGuid,
    transactionGuid,
    transactionChargeGuid
  }: {
    periodGuid?: string
    transactionGuid?: string
    transactionChargeGuid?: string
  }): QueryKey => [
    QueryKeyParts.PAYMENT_PROCESSING,
    QueryKeyParts.TRANSACTION,
    QueryKeyParts.CHARGE,
    QueryKeyParts.HOLIDAY,
    QueryKeyParts.CALCULATION,
    { periodGuid, transactionGuid, transactionChargeGuid }
  ]
}

export const usePaymentProcessingTransactionGroupListQuery = (): UseQueryResult<PaymentTransactionGroup[], Error> => {
  const search = useLocation().search

  const newSearch = setDayBorders({ search, from: "cycle_end_from", to: "cycle_end_to" })

  return useQuery({
    queryKey: queryKeys.getPaymentProcessingTransactionGroupListKey(newSearch),
    queryFn: async () => FinanceApi.get(`finance/payment-processing${newSearch}`),
    ...enabledByMandatoryUrlParams([newSearch])
  })
}

export const usePaymentProcessingTransactionGroupQuery = ({
  periodGuid,
  enabled = true
}: {
  periodGuid?: string
  enabled?: boolean
}): UseQueryResult<PaymentTransactionGroup, Error> =>
  useQuery({
    queryKey: queryKeys.getPaymentProcessingTransactionGroupKey({ periodGuid }),
    queryFn: async () => FinanceApi.get(`finance/payment-processing/${periodGuid}`),
    ...enabledByMandatoryUrlParams([periodGuid, enabled])
  })

export const usePaymentProcessingTransactionListQuery = ({
  periodGuid,
  search = ""
}: {
  periodGuid: string
  search?: string
}): UseQueryResult<PaymentTransaction[], Error> => {
  return useQuery({
    queryKey: queryKeys.getPaymentProcessingTransactionListKey({ periodGuid, search }),
    queryFn: async () => FinanceApi.get(`finance/payment-processing/${periodGuid}/transactions${search}`),
    ...enabledByMandatoryUrlParams([periodGuid])
  })
}

export const usePaymentProcessingTransactionQuery = ({
  periodGuid,
  transactionGuid
}: {
  periodGuid?: string
  transactionGuid?: string
}): UseQueryResult<PaymentTransaction, Error> =>
  useQuery({
    queryKey: queryKeys.getPaymentProcessingTransactionKey({ periodGuid, transactionGuid }),
    queryFn: async () => FinanceApi.get(`finance/payment-processing/${periodGuid}/transactions/${transactionGuid}`),
    ...enabledByMandatoryUrlParams([periodGuid, transactionGuid])
  })

export const usePaymentProcessingTransactionVisitListQuery = ({
  periodGuid,
  transactionGuid
}: {
  periodGuid?: string
  transactionGuid?: string
}): UseQueryResult<PaymentTransactionItem[], Error> => {
  return useQuery({
    queryKey: queryKeys.getPaymentProcessingTransactionVisitListKey({
      periodGuid,
      transactionGuid
    }),
    queryFn: async () =>
      FinanceApi.get(`finance/payment-processing/${periodGuid}/transactions/${transactionGuid}/items`),
    ...enabledByMandatoryUrlParams([periodGuid, transactionGuid])
  })
}

export const usePaymentProcessingTransactionChargeListQuery = ({
  periodGuid,
  transactionGuid
}: {
  periodGuid?: string
  transactionGuid?: string
}): UseQueryResult<PaymentTransactionCharge[], Error> => {
  return useQuery({
    queryKey: queryKeys.getPaymentProcessingTransactionChargeListKey({
      periodGuid,
      transactionGuid
    }),
    queryFn: async () =>
      FinanceApi.get(`finance/payment-processing/${periodGuid}/transactions/${transactionGuid}/charges`),
    ...enabledByMandatoryUrlParams([periodGuid, transactionGuid])
  })
}

export const usePaymentProcessingShiftCalculationQuery = ({
  periodGuid,
  transactionGuid,
  transactionShiftGuid,
  visitGuid,
  enabled = true
}: UseShiftCalculationsQueryParams): UseQueryResult<ApiTransactionShiftCalculation, Error> => {
  return useQuery({
    queryKey: queryKeys.getShiftCalculationKey({ periodGuid, transactionGuid, transactionShiftGuid, visitGuid }),
    queryFn: async () => {
      const visitQuery = visitGuid ? `?visit=${visitGuid}` : ""
      return FinanceApi.get(
        `/finance/payment-processing/${periodGuid}/transactions/${transactionGuid}/shifts/${transactionShiftGuid}/calculations${visitQuery}`
      )
    },
    ...enabledByMandatoryUrlParams([periodGuid, transactionGuid, transactionShiftGuid, enabled])
  })
}

export const usePaymentProcessingTransactionVisitCalculationQuery = ({
  periodGuid,
  transactionGuid,
  transactionVisitGuid,
  enabled = true
}: {
  periodGuid?: string
  transactionGuid?: string
  transactionVisitGuid?: string
  enabled?: boolean
}): UseQueryResult<PaymentTransactionItemVisitDetailCalculations, Error> => {
  return useQuery({
    queryKey: queryKeys.getPaymentProcessingTransactionVisitCalculations({
      periodGuid,
      transactionGuid,
      transactionVisitGuid
    }),
    queryFn: async () =>
      FinanceApi.get(
        `finance/payment-processing/${periodGuid}/transactions/${transactionGuid}/visits/${transactionVisitGuid}/calculations`
      ),
    ...enabledByMandatoryUrlParams([periodGuid, transactionGuid, transactionVisitGuid, enabled])
  })
}

export const usePaymentTransactionChargeHolidayCalculationQuery = ({
  periodGuid,
  transactionGuid,
  transactionChargeGuid
}: {
  periodGuid?: string
  transactionGuid?: string
  transactionChargeGuid?: string
}): UseQueryResult<TransactionChargeHolidayCalculation, Error> => {
  return useQuery({
    queryKey: queryKeys.getPaymentProcessingTransactionChargeHolidayCalculations({
      periodGuid,
      transactionGuid,
      transactionChargeGuid
    }),
    queryFn: async () =>
      FinanceApi.get(
        `finance/payment-processing/${periodGuid}/transactions/${transactionGuid}/charges/${transactionChargeGuid}/holiday-calculations`
      ),
    ...enabledByMandatoryUrlParams([periodGuid, transactionGuid, transactionChargeGuid])
  })
}
