import { DateTime } from "luxon"
import { clearFilterValues } from "./clearFilterValues"

// NOTE: This is here because MaterialUI-DatePickers always return values as a Date instance
// in order to satisfy TS we must list all possible names
// ? because not all filters require start/end

export type FilterValues<R> = R & {
  start?: DateTime
  end?: DateTime

  from?: DateTime
  to?: DateTime

  start_from?: DateTime
  end_to?: DateTime

  start_date?: DateTime
  end_date?: DateTime

  upload_at_start_date?: DateTime
  upload_at_end_date?: DateTime

  period_start_from?: DateTime
  period_start_to?: DateTime

  period_end_from?: DateTime
  period_end_to?: DateTime

  cycle_end_from?: DateTime
  cycle_end_to?: DateTime
}

type OwnProps<T> = {
  values: FilterValues<T>
  clearValues?: boolean
  startName:
    | "start"
    | "from"
    | "start_from"
    | "start_date"
    | "upload_at_start_date"
    | "period_start_from"
    | "period_end_from"
    | "cycle_end_from"
  endName:
    | "end"
    | "to"
    | "end_to"
    | "end_date"
    | "upload_at_end_date"
    | "period_start_to"
    | "period_end_to"
    | "cycle_end_to"
}

export const formatFilterValues = <T>({ values, clearValues = true, startName, endName }: OwnProps<T>): T => {
  const { [startName]: start, [endName]: end, ...rest } = values

  const startDate = DateTime.isDateTime(start) ? start.toISODate() : start
  const endDate = DateTime.isDateTime(end) ? end.toISODate() : end

  const result = { [startName]: startDate, [endName]: endDate, ...rest } as unknown as T
  return clearValues ? clearFilterValues<T>(result) : result
}
