import { useMemo } from "react"
import { useIsFetching, useIsMutating } from "react-query"

export const useIsRequestPending = (): boolean => {
  const isReactQueryRequestPending = useIsFetching()
  const isReactQueryMutationPending = useIsMutating()

  return useMemo(() => {
    return !!isReactQueryRequestPending || !!isReactQueryMutationPending
  }, [isReactQueryRequestPending, isReactQueryMutationPending])
}
