import React, { memo, useCallback, useMemo, useState } from "react"
import {
  Box,
  Grid,
  TextField,
  Select,
  Button,
  Autocomplete,
  ListItem,
  Checkbox,
  ListItemText,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material"
import { ClearFilterButton } from "components/ClearFilterButton"
import { usePaymentModelSummariesQuery } from "data/finance/contractModel/queries"
import { mapSummariesIntoModelOptions } from "lib/helpers/mapSummariesIntoModelOptions"
import { getOptionsFromSchema } from "lib/schema"
import { useNavigate, useSearchParams, createSearchParams, useLocation } from "react-router-dom"
import { useCoreSchemasStore } from "stores"
import Auth from "lib/Auth"
import { PermissionGroup } from "constants/permission"
import { useDebouncedCallback } from "use-debounce"
import testingId from "constants/testingId"

const CARER_STATUS_ACTIVE = "13"

interface CarerFilters {
  search: string
  region: string
  status: string
  contractModel: { id: string; label: string }[]
  reference: string
}

export const CarerListFilter = memo(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { ApiUserCarerFilter } = useCoreSchemasStore((state) => state.schema.models)
  const { data: paymentSummaries } = usePaymentModelSummariesQuery()
  const [{ search, status, region, reference, contractModel }, setFilters] = useState<CarerFilters>({
    search: searchParams.get("search") ?? "",
    status:
      searchParams.get("status") ??
      (!Auth.hasPermissionGroup(PermissionGroup.FINANCE_ADMIN) ? CARER_STATUS_ACTIVE : ""),
    region: searchParams.get("region") ?? "",
    reference: searchParams.get("reference") ?? "",
    contractModel: JSON.parse(decodeURIComponent(searchParams.get("contract_model") ?? "[]"))
  })

  const contractModelOptions = useMemo(
    () => mapSummariesIntoModelOptions(paymentSummaries).map(({ value, title }) => ({ label: title, id: value })),
    [paymentSummaries]
  )

  const regionMenuItem = useMemo(
    () =>
      getOptionsFromSchema(ApiUserCarerFilter, "region").map(({ title, value = "" }) => {
        return (
          <MenuItem key={`${title}-${value}`} value={value}>
            {title}
          </MenuItem>
        )
      }),

    [ApiUserCarerFilter]
  )

  const statusMenuItem = useMemo(
    () =>
      getOptionsFromSchema(ApiUserCarerFilter, "status").map(({ title, value = "" }) => {
        return (
          <MenuItem key={`${title}-${value}`} value={value}>
            {title}
          </MenuItem>
        )
      }),

    [ApiUserCarerFilter]
  )

  const isClearFilterDisabled = useMemo(
    () => !search && !region && !status && contractModel.length === 0 && !reference,
    [search, region, status, contractModel, reference]
  )

  const setQueryString = useCallback(
    ({ value, type }: { value?: string; type: string }) => {
      const searchParams = createSearchParams(location.search)
      if (value) {
        searchParams.set(type, value)
      } else {
        searchParams.delete(type)
      }
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString()
        },
        { replace: true }
      )
    },
    [location.pathname, location.search, navigate]
  )

  const setQueryStringDebounced = useDebouncedCallback(setQueryString, 500)

  return (
    <Box component="form" sx={{ flex: 1, my: 3 }}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item md={9}>
          <TextField
            data-cy={testingId.textField}
            name="search"
            variant="outlined"
            label="Search"
            fullWidth
            type="text"
            value={search}
            onChange={(event) => {
              setQueryStringDebounced({ value: event.target.value, type: "search" })
              setFilters((prev) => ({ ...prev, search: event.target.value }))
            }}
          />
        </Grid>
        <Grid item md={1} />
        <Grid item md={2}>
          <Button onClick={() => navigate("/carers/new")} variant="contained" fullWidth size="large">
            Create a carer
          </Button>
        </Grid>
        <Grid item md={3}>
          <Autocomplete
            data-cy={testingId.autocompleteField}
            sx={{ mt: 2, mb: 1 }}
            multiple
            options={contractModelOptions}
            renderInput={(params) => (
              <TextField {...params} name="contract_model" label="Active payment models" variant="outlined" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, state) => (
              <ListItem {...props} key={option.id}>
                <Checkbox checked={state.selected} />
                <ListItemText primary={option.label} data-cy={testingId.selectOption} data-value={option.label} />
              </ListItem>
            )}
            onChange={(_, value) => {
              setQueryString({
                value: value?.length > 0 ? encodeURIComponent(JSON.stringify(value)) : "",
                type: "contract_model"
              })
              setFilters((prev) => ({ ...prev, contractModel: value }))
            }}
            value={contractModel}
          />
        </Grid>
        <Grid item md={3}>
          <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
            <InputLabel id="regions-select-label">Region</InputLabel>
            <Select
              data-cy={testingId.select}
              name="region"
              onChange={(event) => {
                setQueryString({
                  value: event.target.value,
                  type: "region"
                })
                setFilters((prev) => ({ ...prev, region: event.target.value }))
              }}
              labelId="regions-select-label"
              fullWidth
              label="Region"
              value={region}
            >
              {regionMenuItem}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              data-cy={testingId.select}
              name="status"
              labelId="status-select-label"
              onChange={(event) => {
                setQueryString({
                  value: event.target.value,
                  type: "status"
                })
                setFilters((prev) => ({ ...prev, status: event.target.value }))
              }}
              label="Status"
              value={status}
            >
              {statusMenuItem}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <TextField
            data-cy={testingId.textField}
            name="reference"
            label="Reference, Payroll, NI number"
            fullWidth
            sx={{ mt: 2, mb: 1 }}
            onChange={(event) => {
              setQueryStringDebounced({ value: event.target.value, type: "reference" })
              setFilters((prev) => ({ ...prev, reference: event.target.value }))
            }}
            value={reference}
          />
        </Grid>
        <Grid item md={12}>
          <Box justifyContent="flex-end" display="flex" alignItems="center" color="grey.600">
            <ClearFilterButton
              id="carers-clear-filters-btn"
              onClick={() => {
                navigate(
                  {
                    pathname: location.pathname
                  },
                  { replace: true }
                )
                setFilters(() => ({ search: "", reference: "", region: "", status: "", contractModel: [] }))
              }}
              disabled={isClearFilterDisabled}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
})

CarerListFilter.displayName = "CarerListFilter"
