import { AuthLink } from "components"
import React from "react"
import { GUID_ZERO } from "constants/guid"
import { Permission } from "constants/permission"

export const getContractModelLink = ({
  contract_guid = GUID_ZERO,
  contract_title = "",
  isInvoiceModel = true
}: {
  contract_guid?: string
  contract_title?: string
  isInvoiceModel?: boolean
}): string | React.JSX.Element => {
  if (contract_guid === GUID_ZERO) return contract_title

  const modelType = isInvoiceModel ? "invoice-models" : "payment-models"

  return (
    <AuthLink
      to={`/finance/contract-models/${modelType}/${contract_guid}/settings`}
      permissions={[Permission.FINANCE_MODEL_EDIT]}
    >
      {contract_title}
    </AuthLink>
  )
}
