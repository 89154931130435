import { Box, Grid } from "@mui/material"
import { Link } from "components"
import { ClearFilterButton } from "components/ClearFilterButton"
import { Button, FormSpyCustom, TextField } from "components/Form"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { Pagination } from "lib/types"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"

export interface UnitFilter {
  name?: string
  reference?: string
}

interface OwnProps {
  pagination: Pagination
  resetPagination: () => void
  filter: UnitFilter
  setFilter: Dispatch<SetStateAction<UnitFilter>>
}

export const UnitListFilter: React.FC<OwnProps> = memo(({ filter, setFilter, pagination, resetPagination }) => {
  const debouncedOnSubmit = useDebouncedCallback((values: UnitFilter) => {
    setFilter(values)
    resetPagination()
  }, 500)

  const clearFilter = () => {
    setFilter({})
    resetPagination()
  }

  useFilterLocationChange({ filter, pagination })

  return (
    <Box {...{ pt: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Form {...{ initialValues: filter, onSubmit: debouncedOnSubmit }}>
        {({ handleSubmit }) => (
          <form {...{ onSubmit: handleSubmit }}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={3}>
                <Field<string> {...{ name: "name", label: "Search by name", component: TextField }} />
              </Grid>
              <Grid item md={3}>
                <Field<string> {...{ name: "reference", label: "Reference", component: TextField }} />
              </Grid>
              <Grid item md={3}>
                <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
      <Box flexShrink={0}>
        <Link to="/units/new">
          <Button>Create a unit</Button>
        </Link>
      </Box>
    </Box>
  )
})

UnitListFilter.displayName = "UnitListFilter"
