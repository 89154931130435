import { ContractModelType } from "constants/modelTypes"
import { queryStringToObject } from "lib/queryString"
import { useMemo } from "react"
import { useLocation } from "react-router-dom"

export const useModelTypeSearch = ({
  trueByDefault = false,
  model
}: {
  trueByDefault?: boolean
  model: string
}): boolean => {
  const location = useLocation()

  return useMemo(() => {
    if (trueByDefault && !location.search) return true

    const qsObj = queryStringToObject(location.search)
    return !!qsObj.modelType && qsObj.modelType === model
  }, [location.search, trueByDefault, model])
}

export const useIsInvoiceModelSearch = ({ trueByDefault = true }: { trueByDefault?: boolean } = {}): boolean => {
  return useModelTypeSearch({ trueByDefault, model: ContractModelType.INVOICE })
}
