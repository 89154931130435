import { SelectOption } from "components/Form/Select"
import { useMemo } from "react"
import { useDebouncedCallback } from "use-debounce"

interface MappedData {
  [key: string]: { name: string; guid: string; [key: string]: any }
}

interface MappedOption {
  guid: string
  profile: { name: string }
}

interface Options {
  value: string
  label: string
}

export const mapReducedDataForAutocomplete = (data?: MappedData) =>
  data &&
  Object.keys(data).map((key: string) => {
    return { value: key, label: data[key].name }
  })

const filterOptions = (inputValue: string, options?: Options[]) => {
  return options && inputValue
    ? options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
    : options
}

export const useLoadAutocompleteOptions = (options?: MappedData) => {
  const mappedOptions = mapReducedDataForAutocomplete(options)

  const searchOptions = useDebouncedCallback((name: string, callback) => {
    callback(filterOptions(name, mappedOptions))
  }, 300)

  return searchOptions
}

export const useMappedSearchableOptions = (options?: MappedOption[]): SelectOption[] =>
  useMemo(() => (options || []).map((option) => ({ value: option.guid, title: option.profile.name })), [options])
