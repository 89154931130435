import React, { useCallback } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { FormSectionHeader } from "components/Form"
import testingId from "constants/testingId"
import { isNil } from "ramda"

interface OwnProps {
  title: string
  headers: string[]
  data: (string | undefined)[][]
  section: string
  "data-cy"?: string
  onRowClickAction?: (idx: number) => void
}

const TransactionDetailItemSectionRow: React.FC<OwnProps> = ({
  title,
  headers,
  data,
  section,
  "data-cy": testId,
  onRowClickAction
}) => {
  const isClickable = !isNil(onRowClickAction)
  const rowClickHandler = useCallback(
    (itemIndex: number) => {
      if (onRowClickAction) {
        onRowClickAction(itemIndex)
      }
    },
    [onRowClickAction]
  )

  return (
    <>
      <FormSectionHeader title={title} />
      <Table
        sx={{
          marginBottom: "50px"
        }}
        data-cy={testId}
      >
        <TableHead>
          <TableRow data-cy={testingId.tableHeaderRow}>
            {headers.map((header, index) => (
              <TableCell
                align="left"
                key={`${section}-header-${index}`}
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  borderBottom: "1px solid black"
                }}
                data-cy={testingId.tableHeaderCell}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, itemIndex) => (
            <TableRow
              hover={isClickable}
              key={`${section}-row-${itemIndex}`}
              data-cy={testingId.tableRow}
              onClick={() => rowClickHandler(itemIndex)}
              sx={isClickable ? { cursor: "pointer" } : undefined}
            >
              {item.map((value: string | undefined, index: number) => (
                <TableCell align="left" key={`${section}-rowdata-${index}`} data-cy={testingId.tableCell}>
                  {value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default React.memo(TransactionDetailItemSectionRow)
