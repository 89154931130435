import React, { memo, useMemo } from "react"
import { Box, TableCell, Tooltip } from "@mui/material"
import { blue, green, grey, red, yellow } from "@mui/material/colors"
import testingId from "constants/testingId"
import { TransactionItem } from "pages/Finance/types"

interface Props {
  item: TransactionItem & { confirmed?: boolean } // for visits
}

export const TransactionItemStatus = memo(({ item }: Props) => {
  TransactionItemStatus.displayName = "TransactionItemStatus"

  const messageColor = useMemo(() => {
    if (item.processed_prefix === "P" && !item.credit_guid) return ["Paid", green[700]]
    if (item.processed_prefix === "CR") return ["Credited", red[700]]
    if (item.processed_prefix === "P" && item.credit_guid)
      return ["Paid but later credited", "linear-gradient(to bottom, rgb(56, 142, 60) 50%, rgb(211, 47, 47) 50%)"]
    if (item.confirmed && item.is_calculated && !item.processed_at) return ["Pending", yellow[700]]
    if (!item.confirmed) return ["Unconfirmed", grey[500]]
    return ["Repaid", blue[700]]
  }, [item])

  return (
    <TableCell
      sx={{
        width: 6,
        height: 0,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 0,
        paddingRight: 0
      }}
      data-cy={testingId.tableCell}
    >
      <Tooltip title={messageColor[0]}>
        <Box
          sx={{
            borderRadius: 3,
            height: "100%",
            width: 6,
            backgroundColor: messageColor[1],
            backgroundImage: messageColor[1],
            verticalAlign: "middle"
          }}
          data-cy={testingId.transactionItemStatus}
        ></Box>
      </Tooltip>
    </TableCell>
  )
})
