import React, { useState, useEffect, Children, ReactNode } from "react"
import { Box } from "@mui/material"
import { Button } from "./Form"
import { Form } from "react-final-form"
import { ProfileMode, SchemaOnlyValidator } from "../lib/types"
import { getFormValidators } from "../lib/schema"
import StickyBox from "./StickyBox"
import * as _ from "lodash"
import testingId from "constants/testingId"
import { deepObjectEqual } from "lib/utils"
import { path } from "ramda"
import { useNavigate } from "react-router-dom"
import { useUIStore } from "stores"

interface Props {
  onSubmit: Function
  initialValues: object
  page: number
  mode: ProfileMode
  createUrl: string
  sections: string[]
  schema: SchemaOnlyValidator
  customValidation?: Function
}

interface PageProps {
  validate?: string[]
}

// This does seem useless but it's we need to pass validate props to the wizard child
export const WizardPage: React.FC<React.PropsWithChildren<PageProps>> = (props) => (
  <>{props.children ? props.children : null}</>
)

const Wizard: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const navigate = useNavigate()
  const showWarningMessage = useUIStore((state) => state.showWarningMessage)
  const { page, children, initialValues, mode, sections, createUrl, schema, customValidation } = props
  const [currentPage, setPage] = useState(page || 0)
  const wizardChildren = Children.toArray(children)
  const activePage: any = wizardChildren[currentPage]

  const refs = wizardChildren.map(() => {
    return React.createRef<HTMLDivElement>()
  })

  useEffect(() => {
    setPage(props.page)

    if (mode === "update" && currentPage !== props.page) {
      if (props.page === 0) {
        window.scrollTo(0, 0)
      } else {
        const refOffset = (path(["current", "offsetTop"], refs[props.page]) as number) || 0
        window.scrollTo(0, refOffset - 50)
      }
    }
  }, [currentPage, mode, props.page, refs])

  const validate = (values: any) => {
    let validators: { [key: string]: Function } = {}

    if (mode === "create") {
      validators = getFormValidators(activePage.props.validate || [], schema)
    } else {
      validators = wizardChildren.reduce(
        (res: { [key: string]: Function }, child: ReactNode) => ({
          ...res,
          ...getFormValidators(path(["props", "validate"], child) ?? [], schema)
        }),
        validators
      )
    }

    const errors = Object.keys(validators).reduce((res: any, key: string) => {
      const val: any = _.get(values, key)
      const invalid = validators[key](val)
      if (invalid) res[key] = invalid
      return res
    }, {})

    return customValidation ? customValidation({ values, errors }) : errors
  }

  const scrollToError = (errors: any) => {
    Object.keys(errors).forEach((key: string) => {
      const el: HTMLInputElement | null = document.querySelector(`input[name='${key}']`)
      if (el && el.type !== "hidden") {
        el.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
        return false
      }
    })
  }

  const goToPreviousPage = (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    navigate(`${createUrl}/${sections[page - 1]}`)
  }

  const onSubmit = (values: any) => {
    if (mode === "update" || page === sections.length - 1) {
      return props.onSubmit(values)
    } else {
      navigate(`${createUrl}/${sections[page + 1]}`)
    }
  }

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValuesEqual={deepObjectEqual} initialValues={initialValues}>
      {({ handleSubmit, invalid, submitting, pristine, errors, form }) => {
        return (
          <form
            onSubmit={(event: any) => {
              const result = handleSubmit(event)
              if (result === undefined && invalid) {
                showWarningMessage("Please fix the errors in the form.")
                scrollToError(errors)
              } else {
                form.getRegisteredFields().forEach((field) => form.resetFieldState(field))
              }
              return result
            }}
            data-cy={testingId.wizardForm}
          >
            <Box
              sx={(theme) => ({
                marginTop: theme.spacing(5)
              })}
            >
              {mode === "create" &&
                Children.map(children, (child, idx) => (
                  <Box
                    position={idx === currentPage ? "static" : "absolute"}
                    left={idx === currentPage ? "auto" : "-9999999px"}
                  >
                    {child}

                    <Box display="flex" justifyContent="space-between" margin={5} mx={"auto"}>
                      {page !== 0 && (
                        <Button id="wizard-previous-btn" type="submit" fullWidth={false} onClick={goToPreviousPage}>
                          Previous section
                        </Button>
                      )}
                      {page === 0 && <span>&nbsp;</span>}
                      {page !== sections.length - 1 && (
                        <Button
                          id="wizard-next-btn"
                          type="submit"
                          fullWidth={false}
                          data-cy={testingId.wizardCreateNextSectionButton}
                        >
                          Next section
                        </Button>
                      )}
                      {page === sections.length - 1 && (
                        <Button
                          id="wizard-save-btn"
                          type="submit"
                          fullWidth={false}
                          disabled={submitting}
                          data-cy={testingId.wizardCreateSaveChangesButton}
                        >
                          Save changes
                        </Button>
                      )}
                    </Box>
                  </Box>
                ))}
              {mode === "update" && (
                <>
                  {Children.map(children, (child, idx) => (
                    <div key={`element-${idx}`} ref={refs[idx]}>
                      {child}
                    </div>
                  ))}
                  <StickyBox>
                    <Button
                      variant="text"
                      fullWidth={false}
                      disabled={submitting || pristine}
                      onClick={() => {
                        form.reset()
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={(theme) => ({
                        margin: theme.spacing(0, 0, 0, 3)
                      })}
                      type="submit"
                      fullWidth={false}
                      disabled={submitting || pristine}
                    >
                      Save changes
                    </Button>
                  </StickyBox>
                </>
              )}
            </Box>
          </form>
        )
      }}
    </Form>
  )
}

export default Wizard
