import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import React, { memo } from "react"

interface TableProps {
  label: string
  headers: string[]
  rows: string[][]
}

export const RateUpliftFourServiceBandRatesTable: React.FC<TableProps> = memo(({ label, rows, headers }) => {
  RateUpliftFourServiceBandRatesTable.displayName = "RateUpliftFourServiceBandRatesTable"

  return (
    <Box
      sx={{
        padding: "5px 10px",
        backgroundColor: "#fff"
      }}
    >
      <Typography
        sx={{
          textTransform: "uppercase",
          color: grey[500],
          fontSize: "1em",
          fontWeight: "bold",
          padding: "10px 15px"
        }}
      >
        {label}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((headerLabel) => (
              <TableCell
                sx={{
                  width: "20%",
                  padding: "10px 15px",
                  borderTop: `1px solid ${grey[400]}`,
                  borderBottom: `1px solid ${grey[400]}`
                }}
                key={headerLabel}
              >
                {headerLabel}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((items, key) => (
            <TableRow key={key}>
              {items.map((item, itemKey) => (
                <TableCell
                  key={`item-${itemKey}`}
                  sx={{
                    padding: "10px 15px",
                    border: "none",
                    backgroundColor: grey[100],
                    borderBottom: `1px solid ${grey[400]}`
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
})
