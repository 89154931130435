import numberUtils from "lib/number"
import { omit } from "ramda"
import { getRateTimeFromPeriod } from "../helpers/getRateTimeFromPeriod"
import { ApiContractModelRate, ContractModelRate } from "data/finance/contractModel/types"

export const reduceStandardRates = (rates: ApiContractModelRate[] = []): ContractModelRate[] =>
  rates.map((rate: ApiContractModelRate) => {
    const standardRate = omit(["enhancements"], rate)

    return {
      ...standardRate,
      start: getRateTimeFromPeriod(rate.start),
      end: getRateTimeFromPeriod(rate.end),
      rate: numberUtils.displayWithDecimals(rate.rate, 2),
      day_type_alias: rate.holiday_tier_guid ?? rate.day_type_alias // Reverse logic form patch mutation, we assign holiday_tier_guid to day_type_alias for consistency
    }
  })
