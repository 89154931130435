import React from "react"
import { Typography } from "@ceracare/dcp-ui"

interface Props {
  isCancelled: boolean
}
const VisitReportEmpty = ({ isCancelled }: Props) => (
  <Typography variant="body2">{`${isCancelled ? "No visit report available, because the visit was cancelled on arrival." : "Visit report will appear here once the visit has started."}`}</Typography>
)

export default VisitReportEmpty
