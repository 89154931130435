import { FinanceApi } from "api"
import { enabledByMandatoryUrlParams, setDayBorders } from "data/helpers"
import { removeFromSearchString } from "lib/helpers/removeFromSearchString"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { AppointmentSearch } from "../invoiceSearch/types"

enum QueryKeyParts {
  PAYMENT_SEARCH = "payment-search"
}

const QueryKeys = {
  getPaymentSearch: ({ search }: { search?: string }): QueryKey => [QueryKeyParts.PAYMENT_SEARCH, { search }]
}

export const usePaymentSearchQuery = ({
  search,
  enabled = true
}: {
  search?: string
  enabled?: boolean
}): UseQueryResult<AppointmentSearch[], Error> => {
  const searchQuery = removeFromSearchString({ search, remove: ["modelType"] })
  const newSearch = setDayBorders({ search: searchQuery, from: "start", to: "end" })

  return useQuery({
    queryKey: QueryKeys.getPaymentSearch({ search: newSearch }),
    queryFn: async () => FinanceApi.get(`finance/payment-search${newSearch}`),
    ...enabledByMandatoryUrlParams([newSearch, enabled])
  })
}
