import React, { memo, useMemo, useState } from "react"
import Typography from "@mui/material/Typography"
import { ContentContainer, EmptyData } from "components"
import { ShiftCategoryListFilter, ListShiftCategoriesFilter } from "./components/ShiftCategoryListFilter"
import filterShiftCategories from "./helpers/filterShiftCategories"
import ShiftCategoryTable from "./components/ShiftCategoryTable"
import testingId from "constants/testingId"
import Grid from "@mui/material/Grid"
import { Button } from "components/Form"
import { useShiftCategoryListQuery } from "data/core/shiftCategory/queries"
import { queryStringToObject } from "lib/queryString"
import { useLocation, Link } from "react-router-dom"

const ShiftCategoryList: React.FC = () => {
  const location = useLocation()
  const [filter, setFilter] = useState<ListShiftCategoriesFilter>(queryStringToObject(location?.search))

  const { data: shiftCategories } = useShiftCategoryListQuery()

  const filteredShiftCategories = useMemo(
    () => filterShiftCategories({ shiftCategories, filter }),
    [filter, shiftCategories]
  )

  return (
    <ContentContainer data-cy={testingId.shifts.category.screen}>
      <Typography variant="h4">Shift Categories</Typography>
      <Grid container alignItems="center" spacing={1}>
        <Grid item md={8}>
          <ShiftCategoryListFilter {...{ filter, setFilter }} />
        </Grid>
        <Grid item md={4}>
          <Link to={`new`}>
            <Button>Create shift category</Button>
          </Link>
        </Grid>
      </Grid>
      {shiftCategories?.length ? (
        filteredShiftCategories?.length ? (
          <ShiftCategoryTable items={filteredShiftCategories} />
        ) : (
          <EmptyData message="No filtered shift categories found." />
        )
      ) : (
        <EmptyData message="No shift categories found, start by adding one." />
      )}
    </ContentContainer>
  )
}

export default memo(ShiftCategoryList)
