import { CalendarResponse } from "data/finance/contractModel/types"
import { CalendarFilter } from "../types"

export const filterCalendars = (items: CalendarResponse[], filter?: CalendarFilter): CalendarResponse[] => {
  const orderedCalendars = [...items].sort((a, b) => b.start.toMillis() - a.start.toMillis())

  if (filter?.status?.length) {
    return orderedCalendars.filter((i) => filter?.status?.includes(i.status))
  }

  return orderedCalendars
}
