import { ContractModelAccrualPolicy } from "data/finance/contractModel/types"
import { ValidationErrors } from "final-form"
import { validatePeriodDates, validatePeriodWithSiblings } from "lib/dateTimeValidators"
import { isEmpty } from "ramda"

export const validatePaymentAccrualPolicy =
  (allPolicies?: ContractModelAccrualPolicy[]) =>
  (values: ContractModelAccrualPolicy): ValidationErrors | undefined => {
    const errorsInDates = validatePeriodDates(values)
    if (!isEmpty(errorsInDates)) return errorsInDates

    return validatePeriodWithSiblings({
      values,
      items: allPolicies,
      label: "Accrual policy"
    })
  }
