import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { CoreApi } from "api"
import { ApiReportSickLeave, ApiReportSickLeaveDownloadCsv } from "types/Reports/types"
import { enabledByMandatoryUrlSearch, enabledByMandatoryUrlParams } from "data/helpers"

export enum QueryKeyParts {
  REPORT = "report",
  CARER = "carer",
  SICK_LEAVE = "sick_leave",
  FILE = "file"
}

export const queryKeys = {
  getReportSickLeave: (query?: string): QueryKey => [
    QueryKeyParts.REPORT,
    QueryKeyParts.CARER,
    QueryKeyParts.SICK_LEAVE,
    { query }
  ],
  getReportSickLeaveDownloadCsv: (query?: string): QueryKey => [
    QueryKeyParts.REPORT,
    QueryKeyParts.CARER,
    QueryKeyParts.SICK_LEAVE,
    QueryKeyParts.FILE,
    { query }
  ]
}

export const useReportSickLeaveListQuery = ({
  query = "",
  withSearch
}: {
  query?: string
  withSearch?: boolean
}): UseQueryResult<ApiReportSickLeave, Error> => {
  return useQuery({
    queryKey: queryKeys.getReportSickLeave(query),
    queryFn: async () => CoreApi.get(`/report/carer/sick-leave${query}`),
    ...enabledByMandatoryUrlSearch({ withSearch, param: query })
  })
}

export const useReportSickLeaveDownloadCsvFile = ({
  query = "",
  isClicked
}: {
  query?: string
  isClicked?: boolean
}): UseQueryResult<ApiReportSickLeaveDownloadCsv, Error> => {
  return useQuery({
    queryKey: queryKeys.getReportSickLeaveDownloadCsv(query),
    queryFn: async () =>
      CoreApi.get(`/report/carer/sick-leave/file${query}`, {
        responseType: "blob"
      }),
    ...enabledByMandatoryUrlParams([isClicked, query])
  })
}
