import { Typography } from "@mui/material"
import { ContentContainer } from "components"
import { DateTimeFormat } from "lib/datetime"
import { DateTime } from "luxon"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import ShiftListDayFilter from "./ShiftListDayFilter"
import ShiftListTable from "./ShiftListTable"
import { ApiShiftResponse, ShiftListFilterProps } from "types/Shifts/ShiftList/types"
import ShiftListFilter from "./ShiftListFilter"
import { objectToQueryString, queryStringToObject } from "lib/queryString"
import testingId from "constants/testingId"
import { sortByDate } from "lib/sortByDate"
import { isNil, path, sort } from "ramda"
import { useShiftListQuery } from "data/core/shift/queries"
import { useLocation, useNavigate, useParams } from "react-router-dom"

const ShiftList: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { date } = useParams()
  const [day, setDay] = useState(date ? DateTime.fromISO(date) : DateTime.local())
  const searchString = path(["search"], location)
  const currentUrl = `${location.pathname}${searchString}`

  // set default filters after page reload
  const [filters, setFilters] = useState<ShiftListFilterProps>(
    queryStringToObject<ShiftListFilterProps>(location?.search)
  )
  const { shift_name, region, carer_guid } = filters

  // update url when filter changes
  useEffect(() => {
    const qs = objectToQueryString({ region, carer_guid, shift_name }, undefined, true)
    const newUrl = `/shifts/${day.toFormat(DateTimeFormat.DATE_SECONDARY)}${qs ? `?${qs}` : ""}`

    if (newUrl !== currentUrl) {
      navigate(newUrl)
    }
  }, [day, region, carer_guid, shift_name, currentUrl, navigate])

  const start = day.startOf("day")
  const end = day.plus({ days: 1 }).startOf("day")

  const { data: shifts, refetch } = useShiftListQuery({ start, end, region, carer_guid })

  const getFilteredShiftsByName = useCallback(() => {
    if (!shift_name) return shifts
    return !isNil(shifts)
      ? shifts.filter((shift) => shift.title.toLowerCase().includes(shift_name.toLowerCase()))
      : null
  }, [shifts, shift_name])

  const filteredShifts = getFilteredShiftsByName()

  const sortedShifts = useMemo(() => {
    const sortFunction = sortByDate(true)((shift: ApiShiftResponse) => shift.start)

    return !isNil(filteredShifts) ? sort(sortFunction, filteredShifts) : null
  }, [filteredShifts])

  useEffect(() => {
    refetch()
  }, [date, region, carer_guid, refetch])

  return (
    <ContentContainer data-cy={testingId.shifts.list.screen}>
      <Typography variant="h4">Shifts</Typography>
      <ShiftListFilter setFilters={setFilters} filters={filters} />
      <ShiftListDayFilter day={day} setDay={setDay} />
      <ShiftListTable shifts={sortedShifts} />
    </ContentContainer>
  )
}

export default memo(ShiftList)
