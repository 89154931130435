import { Box, Typography } from "@mui/material"
import { WithPermission } from "components/Auth"
import { Button } from "components/Form"
import testingId from "constants/testingId"
import React, { memo } from "react"
import ErrorIcon from "@mui/icons-material/Error"

interface Props {
  selectedIds: string[]
  selectableIdsForCrediting: string[]
  selectableIdsForInvoicing: string[]
  submitForInvoicing: () => void
  submitForCrediting: () => void
}

const TransactionListActionButtons: React.FC<Props> = ({
  selectedIds,
  selectableIdsForCrediting,
  selectableIdsForInvoicing,
  submitForInvoicing,
  submitForCrediting
}) => {
  const isCreditingEnabled = selectedIds.some((id) => selectableIdsForCrediting.includes(id))
  const isInvoicingEnabled = selectedIds.some((id) => selectableIdsForInvoicing.includes(id))
  const bothEnabled = isCreditingEnabled && isInvoicingEnabled

  return (
    <Box
      p={2}
      px={0}
      display="flex"
      justifyContent="flex-end"
      sx={(theme) => ({
        borderColor: theme.palette.text.primary,
        borderTop: "1px solid"
      })}
    >
      {bothEnabled && (
        <Box display="flex" data-cy={testingId.invoiceProcessingTransactionList.selectionError}>
          <ErrorIcon color="error" />
          <Typography color="error">Please select items either for invoicing or crediting, not both</Typography>
        </Box>
      )}
      <WithPermission permissions={["Finance.Processing.Credit:Edit"]}>
        <Button
          variant="outlined"
          fullWidth={false}
          color="primary"
          disabled={!isCreditingEnabled || bothEnabled}
          onClick={submitForCrediting}
          data-cy={testingId.invoiceProcessingTransactionList.creditSelectedButton}
        >
          Credit selected
        </Button>
      </WithPermission>
      <WithPermission permissions={["Finance.Processing:Edit"]}>
        <Button
          fullWidth={false}
          color="primary"
          disabled={!isInvoicingEnabled || bothEnabled}
          sx={(theme) => ({
            marginLeft: theme.spacing(3)
          })}
          onClick={submitForInvoicing}
          data-cy={testingId.invoiceProcessingTransactionList.invoiceSelectedButton}
        >
          Invoice selected
        </Button>
      </WithPermission>
    </Box>
  )
}

export default memo(TransactionListActionButtons)
