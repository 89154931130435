import React, { memo, useEffect, useState } from "react"
import { SxProps, Theme, Avatar as MaterialUIAvatar } from "@mui/material"
import { ApiUser } from "../models"
import DefaultAvatar from "./DefaultAvatar"
import { useGetProfilePicture } from "data/carer-profile/queries"

interface OwnProps {
  user: ApiUser
  sx?: SxProps<Theme>
}

const Avatar: React.FC<OwnProps> = (props) => {
  const { user, sx } = props
  const [imageBinary, setImageBinary] = useState("")
  const { type, guid, has_profile_image } = user

  const { data: response, isSuccess } = useGetProfilePicture({ type, guid, enabled: !!has_profile_image })

  useEffect(() => {
    if (isSuccess && response) {
      const reader = new FileReader()
      reader.readAsDataURL(response.data)
      reader.onloadend = () => {
        setImageBinary(reader.result as string)
      }
    }
  }, [isSuccess, response])

  if (imageBinary === "") return <DefaultAvatar sx={sx} alt={user.profile.name} />

  return <MaterialUIAvatar sx={sx} src={`${imageBinary}`} alt={user.profile.name} />
}

export default memo(Avatar)
