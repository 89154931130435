import React, { PropsWithChildren } from "react"
import Table from "@mui/material/Table"
import { Head, TableHeadProps } from "./Head"
import { Body, TableBodyProps } from "./Body"

interface ModelTableProps {
  Head: React.FC<TableHeadProps>
  Body: React.FC<TableBodyProps>
}

const ModelTable: React.FC<PropsWithChildren> & ModelTableProps = ({ children }) => {
  return <Table stickyHeader>{children}</Table>
}

ModelTable.Head = Head
ModelTable.Body = Body

export { ModelTable }
