import React, { memo } from "react"
import { FormSpy } from "react-final-form"
import { FormSectionHeader } from "components/Form"
import { NoEndDateCheckbox } from "components"
import Grid from "@mui/material/Grid"
import DatesMessageHelp from "components/DatesMessageHelp"
import { DateField } from "components/Form/DateField"

export const ContractModelFormDuration: React.FC = memo(() => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        return (
          <>
            <FormSectionHeader title="Model duration" />
            <DatesMessageHelp messageType="E" />
            <Grid container spacing={8}>
              <Grid item md={6}>
                <DateField
                  name="start"
                  label="Start"
                  required
                  maxDate={!values.no_end_date && values.end ? values.end.minus({ days: 1 }) : undefined}
                />
              </Grid>
              <Grid item md={6}>
                <DateField
                  name="end"
                  label="Ends before"
                  disabled={values.no_end_date}
                  minDate={values.start ? values.start.plus({ days: 1 }) : undefined}
                />
                <NoEndDateCheckbox />
              </Grid>
            </Grid>
          </>
        )
      }}
    </FormSpy>
  )
})

ContractModelFormDuration.displayName = "ContractModelFormDuration"
