import React, { ReactNode } from "react"
import Box from "@mui/material/Box"
import { alpha } from "@mui/material/styles"
import { grey } from "@mui/material/colors"

interface Props {
  justifyContent?: string
  children: ReactNode
}

const StickyBox = ({ justifyContent = "flex-end", children }: Props) => {
  return (
    <Box position="sticky" bottom={0} zIndex="991">
      <Box
        sx={(theme) => ({
          padding: theme.spacing(3, 0, 3, 0),
          margin: theme.spacing(3, 0, 0, 0),
          backgroundColor: alpha(theme.palette.background.default, 0.9),
          width: "100%",
          display: "flex",
          justifyContent,
          borderTop: `${grey[300]} 1px solid`
        })}
      >
        {children}
      </Box>
    </Box>
  )
}
export default StickyBox
