import React from "react"
import { NotFound } from "./index"
import ShiftList from "./Shifts/ShiftList/ShiftList"
import Auth from "lib/Auth"
import PermissionDenied from "app/PermissionDenied"
import { DateTimeFormat } from "lib/datetime"
import { DateTime } from "luxon"
import ShiftDetail from "./Shifts/ShiftDetail/ShiftDetail"
import { Navigate, Route } from "react-router-dom"
import AppLoading from "app/AppLoading"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { BranchFeatures } from "lib/hooks/branchInfo/types"
import { Permission } from "constants/permission"
import SentryRoutes from "components/Routes"

const Shifts: React.FC = () => {
  const { hasBranchPermission, isFetching } = useBranchPermissions()
  const hasShiftsFlag = hasBranchPermission(BranchFeatures.SHIFTS)

  if (isFetching) {
    return <AppLoading />
  }

  if (!Auth.hasPermission([Permission.SHIFT_READ]) || !hasShiftsFlag) {
    return <PermissionDenied />
  }

  return (
    <SentryRoutes>
      <Route path="/:date" element={<ShiftList />} />
      <Route path="/detail/:id/*" element={<ShiftDetail />} />
      <Route
        path="/"
        element={<Navigate to={`/shifts/${DateTime.local().toFormat(DateTimeFormat.DATE_SECONDARY)}`} replace />}
      />
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  )
}
export default Shifts
