import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React, { memo } from "react"
import testingId from "constants/testingId"

const headers = ["Starts on", "Ends before", "Status"]

export const SalaryBandListHeader: React.FC = memo(() => {
  SalaryBandListHeader.displayName = "SalaryBandListHeader"

  return (
    <TableHead>
      <TableRow data-cy={testingId.tableHeaderRow}>
        {headers.map((header) => (
          <TableCell data-cy={testingId.tableHeaderCell} align="left" key={header}>
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
})
