import { styled, Tooltip } from "@mui/material"
import { Info } from "@mui/icons-material"
import React, { memo } from "react"

const StyledIcon = styled(Info)(({ theme }) => ({
  color: theme.palette.text.secondary
}))

export const StyledTooltipText = styled("span")({
  fontSize: 12,
  display: "block",
  padding: 5,
  lineHeight: "20px"
})

interface OwnProps {
  title: string
  ml?: number
}

export const TooltipInfo: React.FC<OwnProps> = memo(({ title, ml = 0 }) => {
  TooltipInfo.displayName = "TooltipInfo"

  return (
    <Tooltip title={<StyledTooltipText>{title}</StyledTooltipText>}>
      <StyledIcon style={{ marginLeft: ml }} />
    </Tooltip>
  )
})
