import React from "react"
import { Header, Link } from "components"
import { ContractModel } from "data/finance/contractModel/types"
import { ContractModelType } from "constants/modelTypes"

interface Props {
  contractModel: ContractModel
  modelType: ContractModelType
  calendarTitle?: string
}

const CalendarHeader = ({ contractModel, modelType, calendarTitle = "Add" }: Props) => {
  const isPayment = modelType === ContractModelType.PAYMENT

  const modelListTitle = isPayment ? "Payment models" : "Invoice models"
  const calendarListTitle = isPayment ? "Payment calendars" : "Billing calendars"

  const modelListUrl = `/finance/contract-models/${isPayment ? "payment" : "invoice"}-models`
  const modelDetailUrl = `${modelListUrl}/${contractModel.guid}/settings`
  const calendarListUrl = `${modelListUrl}/${contractModel.guid}/calendars`

  return (
    <Header
      title={
        <>
          <Link to={modelListUrl}>{modelListTitle}</Link> / <Link to={modelDetailUrl}>{contractModel.title}</Link> /{" "}
          <Link to={calendarListUrl}>{calendarListTitle}</Link> / {calendarTitle}
        </>
      }
    />
  )
}

export default React.memo(CalendarHeader)
