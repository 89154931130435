import React, { memo } from "react"
import { FormSectionHeader } from "components/Form"
import Grid from "@mui/material/Grid"
import { FormSpy } from "react-final-form"
import Typography from "@mui/material/Typography"
import { ContractModel } from "data/finance/contractModel/types"
import DatesMessageHelp from "components/DatesMessageHelp"
import { ApiShiftCategoryResponse, ShiftCategoryContractResponse } from "types/Shifts/ShiftCategories/types"
import testingId from "constants/testingId"
import { NoEndDateCheckbox } from "components"
import { CheckboxIsDefault } from "components/Form/CheckboxIsDefault"
import { DateSpan } from "components/DateSpan"
import { DateField } from "components/Form/DateField"

interface Props {
  selectedContractModel?: ContractModel
  shiftCategoryContract?: ShiftCategoryContractResponse
  isEdit: boolean
  shiftCategory: ApiShiftCategoryResponse
  isFirstContract: boolean
}

export const ShiftCategoryContractStepTwo: React.FC<Props> = memo(
  ({ selectedContractModel, shiftCategoryContract, isEdit, shiftCategory, isFirstContract }) => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Typography variant="body2" color="textSecondary">
              Model Name
            </Typography>
            <Typography variant="h6" data-cy={testingId.shifts.contract.modelName}>
              {isEdit ? shiftCategoryContract?.contract_model : selectedContractModel?.title}{" "}
              <DateSpan period={selectedContractModel} />
            </Typography>
          </Grid>
          <Grid item md={6}>
            {shiftCategoryContract?.reference && (
              <>
                <Typography variant="body2" color="textSecondary">
                  Contract ID
                </Typography>
                <Typography variant="h6" data-cy={testingId.shifts.contract.reference}>
                  {shiftCategoryContract.reference}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>

        <FormSectionHeader title="Contract duration" />
        <DatesMessageHelp messageType="E" />
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <Grid container spacing={8}>
              <Grid item md={6}>
                <DateField
                  name="start"
                  label="Starts on"
                  required
                  minDate={shiftCategory.start}
                  maxDate={!values.no_end_date && values.end ? values.end.minus({ days: 1 }) : undefined}
                />
                <CheckboxIsDefault
                  {...{ isFirstContract, contract: shiftCategoryContract, labelType: "Shift Category" }}
                />
              </Grid>
              <Grid item md={6}>
                <DateField
                  name="end"
                  label="Ends before"
                  disabled={values.no_end_date}
                  minDate={values.start?.plus({ days: 1 })}
                  maxDate={shiftCategory.end ? shiftCategory.end : undefined}
                />
                <NoEndDateCheckbox />
              </Grid>
            </Grid>
          )}
        </FormSpy>
      </>
    )
  }
)

ShiftCategoryContractStepTwo.displayName = "ShiftCategoryContractStepTwo"
