export interface IHeapAdapter {
  name: string
  fn: (dependencyContainer: IDependencyContainer) => void
}

interface IDependencyContainer {
  queueCustomEvent: (event: Event) => void
}

// Add more heap events to type depending on the needs, be careful to map the keys to eventListeners
const HEAP_LISTENERS = new Map<"mousedown", (event: MouseEvent) => void>()

const shouldPushToHeapQueue = (target: HTMLElement | null) => {
  if (!target) {
    return false
  }

  const mouseDownInDateTimePickers = target.closest(
    ".cera-js-date-picker, .cera-js-time-picker, .cera-js-date-time-picker"
  )
  const isSelectMouseDown = target.closest("[role='combobox']")
  const isSelectItemMouseDown = target.closest(".MuiMenuItem-root")

  return !!isSelectMouseDown || !!isSelectItemMouseDown || !!mouseDownInDateTimePickers
}

export const captureMouseDownHeap = (dependencyContainer: IDependencyContainer) => {
  const mouseDownListener = (event: MouseEvent) => {
    const shouldTrack = shouldPushToHeapQueue(event.target as HTMLElement | null)
    if (shouldTrack) {
      dependencyContainer.queueCustomEvent(event)
    }
  }
  document.addEventListener("mousedown", mouseDownListener)
  HEAP_LISTENERS.set("mousedown", mouseDownListener)
}

export const removeHeapHandlers = () => {
  HEAP_LISTENERS.forEach((listener, eventType) => {
    document.removeEventListener(eventType, listener)
  })
  HEAP_LISTENERS.clear()
}

export const addHeapAdapters = (adapters: IHeapAdapter[]) => {
  if (!heap) {
    console.warn("Cannot add heap adapters, make sure the script has lodaded before trying to add custom adapters")
    return
  }

  adapters.forEach((adapter) => heap.addAdapter(adapter))
}
