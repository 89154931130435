import React, { memo, useState, useEffect, Dispatch, SetStateAction } from "react"
import { ContainerSteps } from "components/Steps"
import { ShiftCategoryContractStepOne } from "./ShiftCategoryContractStepOne"
import { ShiftCategoryContractStepTwo } from "./ShiftCategoryContractStepTwo"
import { Form } from "react-final-form"
import arrayMutators from "final-form-arrays"
import { ApiShiftCategoryResponse, ShiftCategoryContractResponse } from "types/Shifts/ShiftCategories/types"
import { validateShiftCategoryContract } from "lib/helpers/shiftValidations"
import { useShiftCategoryContractsQuery } from "data/finance/shiftCategory/queries"
import { FormStage } from "constants/form"
import { buildUserContractInitialValues } from "helpers/contractHelpers"
import { useSubmitShiftCategoryContractHandler } from "../helpers/useSubmitShiftCategoryContractHandler"
import { ContractDetailHeader } from "pages/Finance/components/ContractDetailHeader"
import { useInvoiceModelQuery } from "data/finance/contractModel/queries"
import { ContractDetailFooter } from "pages/Finance/components/ContractDetailFooter"

interface OwnProps {
  shiftCategory: ApiShiftCategoryResponse
  shiftCategoryContract?: ShiftCategoryContractResponse
  formStage: FormStage
  setFormStage: Dispatch<SetStateAction<FormStage>>
  isEdit: boolean
}

export const ShiftCategoryContractDetailSettings: React.FC<OwnProps> = memo(
  ({ shiftCategory, shiftCategoryContract, formStage, setFormStage, isEdit }) => {
    const [selectedContractModelGuid, setSelectedContractModelGuid] = useState<string | undefined>()
    useEffect(() => {
      setSelectedContractModelGuid(shiftCategoryContract?.contract_guid)
    }, [shiftCategoryContract])

    const { data: selectedContractModel } = useInvoiceModelQuery(selectedContractModelGuid)

    const { data: contracts } = useShiftCategoryContractsQuery(shiftCategory?.guid)

    const isFirstContract = !contracts?.length

    const initialValues = buildUserContractInitialValues({ contract: shiftCategoryContract, isFirstContract })

    const onSubmit = useSubmitShiftCategoryContractHandler({
      isEdit,
      shiftCategoryGuid: shiftCategory.guid,
      setSelectedContractModelGuid,
      formStage,
      setFormStage
    })

    const validate = validateShiftCategoryContract({
      contracts,
      shiftCategory,
      invoiceModel: selectedContractModel,
      formStage
    })

    return (
      <Form onSubmit={onSubmit} initialValues={initialValues} mutators={{ ...arrayMutators }} validate={validate}>
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <ContainerSteps>
              {!isEdit && (
                <ContractDetailHeader
                  {...{
                    userProfile: { name: shiftCategory.title },
                    contract: shiftCategoryContract,
                    selectedContractModel,
                    formStage,
                    navigateTo: "../detail"
                  }}
                />
              )}
              {formStage === FormStage.CREATE && <ShiftCategoryContractStepOne />}
              {formStage === FormStage.EDIT && (
                <ShiftCategoryContractStepTwo
                  {...{
                    selectedContractModel,
                    shiftCategory,
                    shiftCategoryContract,
                    isEdit,
                    isFirstContract
                  }}
                />
              )}
              <ContractDetailFooter {...{ formStage, formProps }} />
            </ContainerSteps>
          </form>
        )}
      </Form>
    )
  }
)

ShiftCategoryContractDetailSettings.displayName = "ShiftCategoryContractDetailSettings"
