import { SelectOption } from "components/Form/Select"
import { CarerProfileData, ExitInterviewPreference, LeavingReason } from "data/carer-profile/types"
import { ApiCarerProfileData } from "models"

export const parseCarerLeavingReasons = (leavingReasons: LeavingReason[]): SelectOption[] =>
  leavingReasons.map(({ title, guid }) => ({ title, value: title, description: guid }))

export const parseCarerExitInterviewPreferences = (
  exitInterviewPreferences: ExitInterviewPreference[]
): SelectOption[] => exitInterviewPreferences.map(({ id, title }) => ({ title, value: id }))

export const parseCarerProfileData = (carerProfileData?: CarerProfileData): ApiCarerProfileData => {
  if (!carerProfileData) return {}

  const { carer_exit, id, sourcing_type, status } = carerProfileData

  return {
    id,
    sourcing_type,
    contact_about_future_roles: carer_exit?.contact_about_future_roles,
    exit_interview_preference_id: carer_exit?.exit_interview_preference_id,
    resignation_date: carer_exit?.resignation_date,
    leaving_date: carer_exit?.leaving_date,
    status: {
      id: status?.id,
      title: status?.title,
      type: status?.type
    }
  }
}
