import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import DeleteIcon from "@mui/icons-material/Delete"
import React, { memo } from "react"
import testingId from "constants/testingId"
import { ContractModelService } from "data/finance/contractModel/types"

interface OwnProps {
  service: ContractModelService
  isEnhanced?: boolean
}

export const RateEditorTableHead: React.FC<OwnProps> = memo(({ service, isEnhanced = false }) => {
  RateEditorTableHead.displayName = "RateEditorTableHead"

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            width: "20px"
          }}
        >
          <DeleteIcon />
        </TableCell>
        {isEnhanced && (
          <TableCell
            sx={{
              width: "40%"
            }}
          >
            Day Type
          </TableCell>
        )}
        <TableCell
          data-cy={testingId.contractModel.rates.headerCell}
          sx={{
            width: "40%"
          }}
        >
          Standard rate
        </TableCell>

        {isEnhanced ? (
          <>
            <TableCell
              sx={{
                width: "150px",
                minWidth: "150px"
              }}
            >
              Modifier type
            </TableCell>
            <TableCell
              sx={{
                width: "150px",
                minWidth: "150px"
              }}
            >
              Modifier
            </TableCell>
          </>
        ) : (
          <>
            <TableCell data-cy={testingId.contractModel.rates.headerCell}>Start</TableCell>
            <TableCell data-cy={testingId.contractModel.rates.headerCell}>Finish</TableCell>
          </>
        )}
        <TableCell
          data-cy={testingId.contractModel.rates.headerCell}
          align="right"
          sx={{
            width: "150px",
            minWidth: "150px"
          }}
        >
          {service.payment_type_alias === "FIX" ? "Fixed Price" : "Pro rata hourly rate"}
        </TableCell>
      </TableRow>
    </TableHead>
  )
})
