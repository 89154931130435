import { Box } from "@mui/material"
import { Button } from "components/Form"
import React, { memo } from "react"

interface OwnProps {
  hasAppointmentEditPermission: boolean
  toggleAll: () => void
  disabled: boolean
}

export const AppointmentSearchScheduledSelectAllButton: React.FC<OwnProps> = memo(
  ({ hasAppointmentEditPermission, toggleAll, disabled }) => {
    AppointmentSearchScheduledSelectAllButton.displayName = "AppointmentSearchScheduledSelectAllButton"

    if (!hasAppointmentEditPermission) {
      return null
    }

    return (
      <Box py={1} display="flex" justifyContent="flex-end">
        <Button fullWidth={false} variant="text" onClick={toggleAll} disabled={disabled}>
          (Un)Select all for editing/recalculating
        </Button>
      </Box>
    )
  }
)
