import React, { memo } from "react"
import { FileManagementTabs } from "./FileManagementTabs"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

const FileManagement: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/" element={<FileManagementTabs />} />
    </SentryRoutes>
  )
}

export default memo(FileManagement)
