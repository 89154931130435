import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { mobileApiInstance } from "../../api/mobileApi"
import { ApiUserClient, UserType } from "models"
import API from "api"
import { useApiMessage } from "stores"
import { ApiError } from "stores/useUIStore/types"
import { queryKeys } from "data/core/client/queries"

export const useCarerOnboardingStatusMutation = ({
  guid,
  onboardingType = "basic_onboarding"
}: {
  guid: string
  onboardingType?: string
}): UseMutationResult<void, Error, void> => {
  return useMutation(
    async (): Promise<void> => mobileApiInstance.patch(`/onboarding/${guid}?onboardingType=${onboardingType}`)
  )
}

export const useCreateClientMutation = () => {
  const { showErrorMessage } = useApiMessage()

  return useMutation({
    mutationFn: (client: ApiUserClient) => API.createUser(UserType.Patient, client),
    onError: (apiError: ApiError) => {
      showErrorMessage("Failed to create client", { apiError })
    }
  })
}

export const useUpdateClientMutation = (clientGuid?: string) => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (client: ApiUserClient) => API.updateUser(UserType.Patient, client),
    onSuccess: () => {
      showSuccessMessage("Client details successfully updated")
      queryClient.invalidateQueries(queryKeys.getClientDetailKey(clientGuid))
    },
    onError: (apiError: ApiError) => {
      showErrorMessage("Failed to update client", { apiError })
    }
  })
}
