import { FinanceApi } from "api"
import { enabledById } from "data/helpers"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { ApiHoliday } from "./types"

export enum QueryKeyParts {
  HOLIDAY = "holiday",
  DETAIL = "detail",
  LIST = "list"
}

export const queryKeys = {
  getHolidayKey: ({ guid }: { guid?: string }): QueryKey => [QueryKeyParts.HOLIDAY, QueryKeyParts.DETAIL, { guid }],
  getHolidayListKey: (): QueryKey => [QueryKeyParts.HOLIDAY, QueryKeyParts.LIST]
}

export const useHolidayQuery = ({ guid }: { guid?: string }): UseQueryResult<ApiHoliday, Error> => {
  return useQuery({
    queryKey: queryKeys.getHolidayKey({ guid }),
    queryFn: async () => FinanceApi.get(`/finance/holidays/${guid}`),
    ...enabledById(guid)
  })
}

export const useHolidayListQuery = (): UseQueryResult<ApiHoliday[], Error> => {
  return useQuery({
    queryKey: queryKeys.getHolidayListKey(),
    queryFn: async () => FinanceApi.get("/finance/holidays")
  })
}
