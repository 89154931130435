import React, { memo, useState } from "react"
import { ContentContainer, EmptyData, Header } from "components"
import { Form, FormRenderProps } from "react-final-form"
import { DateTime } from "luxon"
import { useContractModelListQuery } from "data/finance/contractModel/handlers/useContractModelListQuery"
import { RateUpliftOne } from "./components/uplift/RateUpliftOne"
import { RateUpliftForm, RateUpliftFormInitialValues, RateUpliftFormPage } from "./types"
import { RateUpliftOneStartDate } from "./components/uplift/RateUpliftStartDate"
import { validatePayUplift } from "./validators/validateRateUplift"
import { RateUpliftTwo } from "./components/uplift/RateUpliftTwo"
import { RateUpliftThree } from "./components/uplift/RateUpliftThree"
import { RateUpliftStepper } from "./components/uplift/RateUpliftStepper"
import { ContractModelType } from "constants/modelTypes"
import { RateUpliftFour } from "./components/uplift/RateUpliftFour"
import { useRateUpliftSubmitHandler } from "./handlers/useRateUpliftSubmitHandler"
import { RateUpliftButtons } from "./components/uplift/RateUpliftButtons"
import { deepObjectEqual } from "lib/utils"
import { filterAndMapUpliftContracts } from "./helpers/filterAndMapUpliftContracts"
import { ContractModel, IndexedContractModel } from "data/finance/contractModel/types"

export const RateUplift: React.FC<{ modelType: ContractModelType }> = memo(({ modelType }) => {
  RateUplift.displayName = "RateUplift"

  const [page, setPage] = useState<RateUpliftFormPage>(0)

  const isInvoiceModel = modelType === ContractModelType.INVOICE

  const { data: allContracts } = useContractModelListQuery({ isInvoiceModel })

  const [indexedPickedContracts, setIndexedPickedContracts] = useState<IndexedContractModel[]>([])

  const onSortCallback = (sortedItems: ContractModel[]) => {
    const indexedItems = sortedItems.map((contract, index) => ({ ...contract, index }))
    setIndexedPickedContracts(indexedItems)
  }

  const { handleUpliftSubmit, isSubmitting } = useRateUpliftSubmitHandler({ page, setPage, modelType })

  const initialValues: RateUpliftFormInitialValues = {
    start_date: DateTime.local().startOf("day"),
    modelType,
    models: {}
  }

  const noContractsMessage = `No ${modelType === ContractModelType.INVOICE ? "Invoice" : "Pay"} models found.`

  return (
    <ContentContainer>
      <Header title="Rates Uplift" />
      {allContracts ? (
        <Form
          {...{
            onSubmit: handleUpliftSubmit,
            initialValues,
            initialValuesEqual: deepObjectEqual,
            validate: validatePayUplift(page)
          }}
        >
          {({ values, handleSubmit, form, error }: FormRenderProps<RateUpliftForm>) => {
            const isModelPage = page === RateUpliftFormPage.PAY_GROUP
            const isServicePage = page === RateUpliftFormPage.CARE_TYPE
            const isRatePage = page === RateUpliftFormPage.RATES
            const isSummaryPage = page === RateUpliftFormPage.SUMMARY

            return (
              <form {...{ onSubmit: handleSubmit }}>
                <RateUpliftStepper {...{ page, modelType }} />
                <RateUpliftOneStartDate {...{ form, values, allContracts, disabled: isRatePage || isSummaryPage }} />

                {isModelPage && (
                  <RateUpliftOne
                    {...{
                      contracts: filterAndMapUpliftContracts({
                        startDate: values.start_date,
                        contracts: allContracts,
                        isInvoiceModel
                      }),
                      modelType,
                      onSortCallback
                    }}
                  />
                )}
                {isServicePage && <RateUpliftTwo {...{ values, form, contracts: indexedPickedContracts, modelType }} />}
                {isRatePage && <RateUpliftThree {...{ values, contracts: indexedPickedContracts, form, modelType }} />}
                {isSummaryPage && <RateUpliftFour {...{ contracts: indexedPickedContracts }} />}

                <RateUpliftButtons {...{ page, setPage, error, isSubmitting }} />
              </form>
            )
          }}
        </Form>
      ) : (
        <EmptyData message={noContractsMessage} />
      )}
    </ContentContainer>
  )
})
