import React, { memo, useMemo, useState } from "react"
import { ContractModelPageProps } from "./types"
import { ContentContainer, Link, Divider, EmptyData } from "components"
import { ContractModelTitle, RuleGroupListTable } from "./components"
import Box from "@mui/material/Box"
import DatesMessageHelp from "components/DatesMessageHelp"
import testingId from "constants/testingId"
import { InvoiceModelRuleGroupFilter, RuleGroupListFilter } from "./components/RuleGroupListFilter"
import { queryStringToObject } from "lib/queryString"
import { filterContractRuleGroups } from "./helpers/filterContractRuleGroups"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { useInvoiceModelRuleGroupListQuery } from "data/finance/contractModel/queries"
import { useLocation } from "react-router-dom"
import { Button } from "components/Form"

type Props = ContractModelPageProps & {
  isPaymentRule?: boolean
}

const Rules: React.FC<Props> = ({ contractModel, modelType, isPaymentRule = false }) => {
  const location = useLocation()
  const { data: ruleGroups } = useInvoiceModelRuleGroupListQuery({
    contractGuid: contractModel.guid,
    isPaymentRule
  })

  const [filter, setFilter] = useState<InvoiceModelRuleGroupFilter>(queryStringToObject(location?.search))

  const filteredRuleGroups = useMemo(() => filterContractRuleGroups(filter, ruleGroups), [ruleGroups, filter])

  return (
    <ContentContainer>
      <ContractModelTitle
        data-cy={testingId.invoiceModel.rules.rulesListTitle}
        modelType={modelType}
        title={
          <>
            <Link to={`../settings`}>{contractModel.title}</Link> / {isPaymentRule ? "Payment" : "Invoice"} rules
          </>
        }
        action={
          <Link to="new">
            <Button data-cy={testingId.invoiceModel.rules.addRuleButton}>Add rule group</Button>
          </Link>
        }
      />
      <Box my={3}>
        <RuleGroupListFilter {...{ filter, setFilter }} />
        <Divider color="divider" />
        <DatesMessageHelp messageType="E" />
        <RequestLoaderWrapper>
          {filteredRuleGroups?.length ? (
            <RuleGroupListTable {...{ ruleGroups: filteredRuleGroups, isPaymentRule, contractModel }} />
          ) : (
            <EmptyData message="No rule groups found, start by adding one." />
          )}
        </RequestLoaderWrapper>
      </Box>
    </ContentContainer>
  )
}

export default memo(Rules)
