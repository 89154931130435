import { ContractModelSummaryRequest } from "data/finance/contractModel/types"
import { ApiUserClient, ApiUserClientFilter } from "models"
import { includes, uniq } from "ramda"
import { Dispatch, SetStateAction } from "react"

const filterClientsByReference = ({ filter, clients }: { filter: ApiUserClientFilter; clients: ApiUserClient[] }) => {
  const filterReference = filter.reference
  if (!filterReference) return clients

  return clients.filter((client) => {
    const reference = client.reference
    if (!!reference && reference.includes(filterReference)) {
      return true
    }

    const niNumber = client.finance.ni_number
    if (!!niNumber && String(niNumber).includes(filterReference)) {
      return true
    }

    const nhsNumber = client.finance.nhs_number
    if (!!nhsNumber && String(nhsNumber).includes(filterReference)) {
      return true
    }

    const socialServiceNumber = client.finance.social_services_number
    if (!!socialServiceNumber && socialServiceNumber.includes(filterReference)) {
      return true
    }

    return false
  })
}

const filterClientsByModelOrFunder = ({
  filter,
  summaries,
  clients = []
}: {
  filter: ApiUserClientFilter
  summaries?: ContractModelSummaryRequest[]
  clients?: ApiUserClient[]
}) => {
  const isFilteredByModelOrFunder = !!filter.contract_model?.length || !!filter.funder?.length

  if (!isFilteredByModelOrFunder) return clients

  const filteredSummaries = (summaries || []).filter((summary) => {
    const hasContractModel = filter.contract_model?.length
      ? Object.keys(summary.contracts).find((contractGuid) => includes(contractGuid, filter.contract_model || []))
      : true
    const hasFunder = filter.funder?.length
      ? summary.funders.find((funder) => includes(funder, filter.funder || []))
      : true
    return !!hasContractModel && !!hasFunder
  })

  const uniqClientGuids = uniq(filteredSummaries.map((summary) => summary.owner_guid))

  return clients.filter((client) => includes(client.guid, uniqClientGuids))
}

export const filterClients = ({
  filter,
  summaries,
  clients,
  setIsFiltering
}: {
  filter: ApiUserClientFilter
  summaries?: ContractModelSummaryRequest[]
  clients?: ApiUserClient[]
  setIsFiltering: Dispatch<SetStateAction<boolean>>
}): ApiUserClient[] | undefined => {
  if (!clients) return undefined

  const clientsFilteredByModelOrFunder = filterClientsByModelOrFunder({ filter, clients, summaries })

  const clientsFilteredByReference = filterClientsByReference({ filter, clients: clientsFilteredByModelOrFunder })

  setIsFiltering(false) // remove loader after filtering

  return clientsFilteredByReference
}
