import { CoreApi, HealthApi } from "api"
import { enabledById } from "data/helpers"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { ApiAddressSearchContract, ApiUserClient, ApiUserRelative } from "models"
import { ListRelativesResponse } from "./types"
import geoCodingApi from "api/geoCoding"
import { RiskScoreRecord } from "models/mobilityAssessment"
import { ClientListResponse } from "data/carer-profile/types"

export enum QueryKeyParts {
  PATIENT = "patient",
  LIST = "list",
  DETAIL = "detail",
  DOWNLOAD_CARE_PLAN = "download_care_plan",
  RELATIVE = "relative",
  ADDRESS_SEARCH_DATA_SUGGESTIONS = "address_search_data_suggestions",
  ADDRESS_SEARCH_SUGGESTIONS = "address_search_suggestions",
  FALL_RISK_SCORE = "fall_risk_score",
  MOBILITY_ASSESSMENT_REPORT_ENABLED = "mobility_assessment_report_enabled"
}

export const queryKeys = {
  getClientsKey: (search?: string): QueryKey => [QueryKeyParts.PATIENT, QueryKeyParts.LIST, { search }],
  getClientDetailKey: (id?: string): QueryKey => [QueryKeyParts.PATIENT, QueryKeyParts.DETAIL, { id }],
  getClientDownloadCarePlanKey: (guid: string): QueryKey => [
    QueryKeyParts.PATIENT,
    QueryKeyParts.DOWNLOAD_CARE_PLAN,
    { guid }
  ],
  getClientRelativeKey: ({ clientGuid, relativeGuid }: { clientGuid: string; relativeGuid?: string }): QueryKey => [
    QueryKeyParts.PATIENT,
    QueryKeyParts.RELATIVE,
    { clientGuid, relativeGuid }
  ],
  getClientRelativeListKey: ({ guid }: { guid: string }): QueryKey => [
    QueryKeyParts.PATIENT,
    QueryKeyParts.RELATIVE,
    QueryKeyParts.LIST,
    { guid }
  ],
  getClientAddressDataSuggestionsKey: (search: string): QueryKey => [
    QueryKeyParts.ADDRESS_SEARCH_DATA_SUGGESTIONS,
    { search }
  ],
  getClientAddressSuggestionsKey: (searchId: string): QueryKey => [
    QueryKeyParts.ADDRESS_SEARCH_SUGGESTIONS,
    { searchId }
  ],
  getClientFallRiskScoreKey: (clientId?: string): QueryKey => [QueryKeyParts.FALL_RISK_SCORE, { clientId }],
  getVisitMobilityAssessmentReportEnabledKey: (serviceUserId: string): QueryKey => [
    QueryKeyParts.MOBILITY_ASSESSMENT_REPORT_ENABLED,
    { serviceUserId }
  ]
}

// to get only patients for certain visits, use post patient/list endpoint
export const useClientListQuery = ({ search = "" }: { search?: string } = {}): UseQueryResult<
  ClientListResponse,
  Error
> => {
  return useQuery({
    queryKey: queryKeys.getClientsKey(search),
    queryFn: async () => CoreApi.get(`/patient${search}`)
  })
}

export const useClientDetailQuery = ({ id }: { id: string | undefined }): UseQueryResult<ApiUserClient, Error> => {
  return useQuery({
    queryKey: queryKeys.getClientDetailKey(id),
    queryFn: async () => CoreApi.get(`/patient/${id}`),
    ...enabledById(id)
  })
}

export const useClientDownloadCarePlanQuery = ({
  enabled,
  guid
}: {
  enabled: boolean
  guid: string
}): UseQueryResult<ApiUserClient, Error> => {
  return useQuery({
    queryKey: queryKeys.getClientDownloadCarePlanKey(guid),
    queryFn: async () =>
      CoreApi.get(`/patient/${guid}/care-plan`, {
        responseType: "blob"
      }),
    enabled
  })
}

export const useClientRelativeQuery = ({
  enabled,
  clientGuid,
  relativeGuid
}: {
  enabled: boolean
  clientGuid: string
  relativeGuid?: string
}): UseQueryResult<ApiUserRelative, Error> => {
  return useQuery({
    queryKey: queryKeys.getClientRelativeKey({ clientGuid, relativeGuid }),
    queryFn: async () => CoreApi.get(`/patient/${clientGuid}/relative/${relativeGuid}`),
    enabled
  })
}

export const useClientRelativeListQuery = ({
  guid
}: {
  guid: string
}): UseQueryResult<ListRelativesResponse, Error> => {
  return useQuery({
    queryKey: queryKeys.getClientRelativeListKey({ guid }),
    queryFn: async () => CoreApi.get(`/patient/${guid}/relative`)
  })
}

export const useClientAddressSuggestionsQuery = ({
  search
}: {
  search: string
}): UseQueryResult<{ id: string; label: string; isGroup: boolean }[], Error> => {
  return useQuery({
    enabled: !!search,
    queryKey: queryKeys.getClientAddressDataSuggestionsKey(search),
    queryFn: async () => geoCodingApi.addressSuggestions(search),
    select: (data) => {
      return data.map(({ id, suggestion, is_group }) => ({ id, label: suggestion, isGroup: is_group }))
    }
  })
}

export const useClientAddressFromSearchId = ({
  searchId
}: {
  searchId: string
}): UseQueryResult<ApiAddressSearchContract, Error> => {
  return useQuery({
    enabled: !!searchId,
    queryKey: queryKeys.getClientAddressSuggestionsKey(searchId),
    queryFn: async () => geoCodingApi.addressFromSuggestionId(searchId)
  })
}

export const useClientFallRiskScoreQuery = ({
  serviceUserId,
  numberOfRecords = 30
}: {
  serviceUserId: string
  numberOfRecords?: number
}): UseQueryResult<RiskScoreRecord[]> => {
  return useQuery({
    queryKey: queryKeys.getClientFallRiskScoreKey(serviceUserId),
    enabled: !!serviceUserId,
    queryFn: async () => HealthApi.get(`/risks/${serviceUserId}/fall?numberofrecords=${numberOfRecords}`)
  })
}

/**
 * Gets from API if the fall risk score mobility assessment should be displayed to a service user
 */
export const useVisitMobilityAssessmentReportEnabledQuery = ({
  serviceUserId
}: {
  serviceUserId: string
}): UseQueryResult<Array<string>> => {
  return useQuery({
    queryKey: queryKeys.getVisitMobilityAssessmentReportEnabledKey(serviceUserId),
    queryFn: async () => HealthApi.get(`/reports/mobility-assessment/enabled?serviceUserIds=${serviceUserId}`),
    enabled: !!serviceUserId
  })
}
