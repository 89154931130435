import React, { useRef } from "react"
import { Box, Dialog, DialogContent, DialogActions, Typography } from "@mui/material"
import { Form } from "react-final-form"
import { Button } from "components/Form"
import MapPreview from "./MapPreview"
import { Coordinates } from "./types"

interface CorrectPinLocationModalProps {
  center: Coordinates
  mapId: string
  fullAddress?: string
  onCancel: () => void
  onSave?: (coordinates: Coordinates) => void
}

const CorrectPinLocationModal = ({ fullAddress, center, mapId, onCancel, onSave }: CorrectPinLocationModalProps) => {
  const coordinatesRef = useRef<Coordinates>()

  const onMapCenterChange = (coordinates: Coordinates) => {
    coordinatesRef.current = coordinates
  }

  const onSubmit = () => {
    if (onSave && coordinatesRef.current) {
      onSave(coordinatesRef.current)
    }
  }

  return (
    <Dialog open fullWidth maxWidth="md">
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <MapPreview
              hasDraggableMap
              hasMapTypeControl
              hasZoomControl
              onChangeMapCenter={onMapCenterChange}
              center={center}
              mapId={mapId}
            />
            <DialogContent
              sx={({ spacing }) => ({
                display: "flex",
                flexDirection: "column",
                gap: spacing(2),
                marginTop: spacing(1),
                borderTopStyle: "none"
              })}
              dividers
            >
              <Typography variant="h5">
                <strong>Edit pin location</strong>
              </Typography>
              <Box>
                <Typography variant="caption">Client address</Typography>
                <Typography variant="h6">{fullAddress}</Typography>
              </Box>
              <Typography variant="body1">
                Simply click and drag the map until the pin points to the correct location. Please be aware that this
                action will only adjust the pin&apos;s position on the map and will not affect the address associated
                with it.
              </Typography>
            </DialogContent>
            <DialogActions sx={(theme) => ({ padding: theme.spacing(2, 3) })}>
              <Button variant="outlined" fullWidth={false} onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" fullWidth={false} disabled={submitting}>
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  )
}

export default CorrectPinLocationModal
