import { Box, styled } from "@mui/material"
import { InfoBox, ModalWrapper } from "components"
import { Button, FormSpyCustom, Select } from "components/Form"
import { InvoiceFileItem } from "data/finance/invoiceFile/types"
import { ApiSubmission, ApiSubmissionUpload } from "data/payroll/types"
import { DateTimeFormat } from "lib/datetime"
import React, { memo, useMemo, useState } from "react"
import { Field, Form } from "react-final-form"
import { groupITrentUploadErrors } from "../helpers/groupITrentUploadErrors"
import { sortITrentUploads } from "../helpers/sortITrentUploads"
import { FileManagementTablePayrollErrorModalAccordion } from "./FileManagementTablePayrollErrorModalAccordion"

const ButtonBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: "flex",
  justifyContent: "flex-end"
}))

const SelectBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: "flex",
  alignItems: "center"
}))

const Label = styled("span")(({ theme }) => ({
  flexShrink: 0,
  marginRight: theme.spacing(1)
}))

interface OwnProps {
  destroyModal: () => void
  initialUploadGuid: string
  file: InvoiceFileItem
  historyItem: ApiSubmission
}

export const FileManagementTablePayrollModal: React.FC<OwnProps> = memo(
  ({ destroyModal, initialUploadGuid, historyItem, file }) => {
    const [selectedUploadGuid, setSelectedUploadGuid] = useState<string>(initialUploadGuid)

    const selectedUpload: ApiSubmissionUpload = useMemo(() => {
      return historyItem.uploads.find((up) => up.guid === selectedUploadGuid) as ApiSubmissionUpload
    }, [historyItem, selectedUploadGuid])

    const latestUpload = useMemo(() => sortITrentUploads(historyItem?.uploads)[0], [historyItem])

    const showInfo = latestUpload.guid === selectedUpload.guid && latestUpload.status === "Failed"

    const options = useMemo(() => {
      const failedItems = historyItem.uploads.filter((upload) => upload.status === "Failed")

      return failedItems.map((upload) => {
        const latest = upload.guid === latestUpload.guid ? " - latest" : ""
        return {
          title: `Submission no. ${upload.submission_number} (${upload.submitted_at.toFormat(
            DateTimeFormat.DATE_TIME
          )})${latest}`,
          value: upload.guid
        }
      })
    }, [historyItem, latestUpload])

    const onSubmit = (values: { submission_guid: string }) => {
      setSelectedUploadGuid(values.submission_guid)
    }

    const groupedIssues = useMemo(() => groupITrentUploadErrors(selectedUpload.issues), [selectedUpload.issues])

    return (
      <ModalWrapper destroyModal={destroyModal} width={700} title={`${file.user_name} Payroll Error details`}>
        <SelectBox>
          <Label>Show errors for:</Label>
          <Form onSubmit={onSubmit} initialValues={{ submission_guid: initialUploadGuid }}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FormSpyCustom {...{ handleSubmit }} />

                  <Field name="submission_guid" label="Submission" component={Select} options={options} />
                </form>
              )
            }}
          </Form>
        </SelectBox>
        {groupedIssues.map((issueGroup, index) => (
          <FileManagementTablePayrollErrorModalAccordion issues={issueGroup} key={index} />
        ))}
        <InfoBox
          variant="info"
          condition={showInfo}
          title="What happens next"
          text="Please address the following errors and return to the File Management page afterwards. You can then select this submission in the table and click on the “Resubmit to iTrent” button to be processed."
        />
        <ButtonBox>
          <Button fullWidth={false} variant="text" onClick={destroyModal}>
            Cancel
          </Button>
        </ButtonBox>
      </ModalWrapper>
    )
  }
)

FileManagementTablePayrollModal.displayName = "FileManagementTablePayrollModal"
