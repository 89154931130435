import React, { PropsWithChildren } from "react"
import Link from "./Link"
import { ListItemButton, ListItemText, ListItemIcon, Tooltip } from "@mui/material"
import { useMatch } from "react-router-dom"

interface Props {
  to: string
  target?: string
  icon?: React.ReactNode
  label: string
  fullMatch?: boolean
  external?: boolean
  onClick?: () => void
  id?: string
}

const NavItem: React.FC<PropsWithChildren<Props>> = (props) => {
  const { to, icon, label, external, fullMatch, onClick, target, id } = props

  const match = useMatch(fullMatch ? to : `${to}/*`)

  return (
    <Link
      to={to}
      color="inherit"
      external={external}
      onClick={onClick}
      target={target}
      id={id}
      sx={{ textDecoration: "none", "&:hover": { textDecoration: "underline" } }}
    >
      <ListItemButton selected={match !== null}>
        {icon && (
          <Tooltip title={label} placement="right" arrow>
            <ListItemIcon sx={{ minWidth: 0, pr: 1.5, mr: 1 }}>{icon}</ListItemIcon>
          </Tooltip>
        )}
        <ListItemText primary={label} />
        {props.children}
      </ListItemButton>
    </Link>
  )
}

export default NavItem
