import { set, isEmpty } from "lodash"
import { FORM_ERROR } from "final-form"
import datetime, { DateTimeFormat } from "lib/datetime"
import { sortPeriodsByDate } from "./dateUtils"
import { ContractModel, CalendarPeriod, CalendarResponse } from "data/finance/contractModel/types"
import { DateTime } from "luxon"
import { FormValidation } from "lib/types"
import { isNil } from "ramda"
import { ContractModelType } from "constants/modelTypes"
import { NewCalendarWithPeriodsRequest } from "../../types"

export const isValidDate = (date: DateTime): boolean => date.isValid

/**
 * Validates periods
 * @param CalendarPeriod[] values - Actual values of the form
 * {
 *   periods: [
 *     {
 *       guid: "082f1449-e790-4719-8db2-33dabcd5b311",
 *       start: "01/08/2020",
 *       end: "07/08/2020",
 *       process_date: "07/08/2020"
 *     },
 *     {
 *       guid: "082f1449-e790-4719-8db2-33dabcd5b311",
 *       start: "08/08/2020",
 *       end: "15/08/2020",
 *       process_date: "17/08/2020"
 *     },
 *     {
 *       guid: "new-item-0",
 *       start: "15/08/2020",
 *       end: "22/08/2020",
 *       process_date: "22/08/2020"
 *     }
 *   ]
 * }
 * @returns errors associated to form fields if invalid
 */
export const validatePeriods = (periods?: CalendarPeriod[]): FormValidation => {
  const errors: FormValidation = { periods: [] }

  const indexedPeriods = (periods || []).map((period, index) => ({ ...period, index }))

  // sorted from oldest to newest, remove periods to be deleted
  const sortedPeriods = sortPeriodsByDate({ periods: indexedPeriods, removeDeleted: true })
  if (sortedPeriods.length === 0) {
    errors[FORM_ERROR] = "Not Saved - No periods entered"
    return errors
  }

  sortedPeriods.forEach((item, index) => {
    const periodIndex = !isNil(item.index) ? item.index : -1
    const nextPeriod = sortedPeriods[index + 1]

    const invalidStartDate = !isValidDate(item.start)
    const invalidEndDate = !isValidDate(item.end)

    if (invalidStartDate) set(errors, ["periods", periodIndex, "start"], "Invalid date")
    if (invalidStartDate) set(errors, ["periods", periodIndex, "end"], "Invalid date")

    if (invalidStartDate || invalidEndDate) {
      errors[FORM_ERROR] = "Not Saved - You are trying to save invalid dates."
    }

    const startDate = item.start
    const endDate = item.end
    const isValidPeriod = startDate <= endDate
    if (!isValidPeriod) {
      set(errors, ["periods", periodIndex, "end"], "Must be greater than start date")
      errors[FORM_ERROR] = "Not Saved - Invalid periods"
      return false
    } else if (nextPeriod) {
      const nextPeriodIndex = !isNil(nextPeriod?.index) ? nextPeriod.index : -1

      const gapFound = endDate.plus({ days: 1 }) < nextPeriod.start

      if (gapFound) {
        set(errors, ["periods", periodIndex, "end"], "End / Start gap detected")
        set(errors, ["periods", nextPeriodIndex, "start"], "Start must follow previous End date")
        errors[FORM_ERROR] = "Not Saved - There are gaps between periods"
      }

      const overlapFound = endDate.plus({ days: 1 }) > nextPeriod.start
      if (overlapFound) {
        set(errors, ["periods", periodIndex, "end"], "End / Start overload detected")
        set(errors, ["periods", nextPeriodIndex, "start"], "Start must follow previous End date")
        errors[FORM_ERROR] = "Not Saved - There are periods overlapping"
      }
    }
  })

  if (errors.periods.length === 0) {
    delete errors.periods
  }

  return errors
}

/**
 * Validates calendar overlappings.
 * @param CalendarPeriod values - Actual values of the form.
 * @param calendar: CalendarResponse - calendar to validate.
 * @param existingCalendars: CalendarResponse[] - Existing calendars to check overlapping.
 * @returns whether calendar overlaps with another calendar.
 */
export const validateCalendarPeriod = (
  values: NewCalendarWithPeriodsRequest,
  startDate: DateTime,
  existingCalendars: CalendarResponse[],
  endDate?: DateTime
): FormValidation => {
  let errors: FormValidation = {}
  if (isEmpty(existingCalendars)) return errors
  const overlappedCalendar = existingCalendars
    .filter((calendar) => calendar.guid !== values.guid)
    .find((calendar) => {
      const calendarStartDate = calendar.start
      const calendarEndDate = calendar.end

      return (
        calendarStartDate.startOf("day") === startDate.startOf("day") ||
        (calendarStartDate < startDate && (!calendarEndDate || (calendarEndDate && calendarEndDate > startDate))) ||
        (startDate < calendarStartDate && (!endDate || (endDate && endDate > calendarStartDate)))
      )
    })
  if (overlappedCalendar) {
    errors = {
      [FORM_ERROR]: `Calendar overlaps with another existing calendar (${overlappedCalendar.start.toFormat(
        DateTimeFormat.DATE
      )} - ${overlappedCalendar.end?.toFormat(DateTimeFormat.DATE)}). Please check start and end dates.`
    }
  }

  return errors
}

const validateCalendarInContract = (
  contractStartDate: DateTime,
  contractEndDate?: DateTime | null,
  periods?: CalendarPeriod[]
): FormValidation => {
  const parsedContractStartDate: DateTime = datetime.getDateOnly(contractStartDate)
  const parsedContractEndDate: DateTime | null = contractEndDate ? datetime.getDateOnly(contractEndDate) : null

  // sorted periods by start from oldest to newest
  const sortedPeriods = sortPeriodsByDate({ periods, removeDeleted: true })
  if (sortedPeriods.length > 0) {
    const calendarStartDate = datetime.getDateOnly(sortedPeriods[0].start)
    const calendarEndDate = datetime.getDateOnly(sortedPeriods[sortedPeriods.length - 1].end).plus({ days: 1 })

    // calendar starts before contract model
    if (calendarStartDate < parsedContractStartDate) {
      return {
        [FORM_ERROR]: `Calendar can not start before Contract start date (${contractStartDate.toFormat(
          DateTimeFormat.DATE
        )}).`
      }
    }

    // contract no end
    if (
      contractEndDate &&
      parsedContractEndDate &&
      (calendarStartDate >= parsedContractEndDate || calendarEndDate > parsedContractEndDate)
    ) {
      return {
        [FORM_ERROR]: `Calendar must be within Contract Start and End dates (${contractStartDate.toFormat(
          DateTimeFormat.DATE
        )} - ${contractEndDate.toFormat(DateTimeFormat.DATE)}).`
      }
    }
  }
  return {}
}

export const validateCalendarRequest = (
  contract: ContractModel,
  values: NewCalendarWithPeriodsRequest,
  calendars: CalendarResponse[]
): FormValidation => {
  //Checking periods data
  const periodsErrors = validatePeriods(values.periods)
  if (!isEmpty(periodsErrors)) return periodsErrors

  //Calendar must be within Contract period. Have to check again bcs end can change based on periods.
  const calendarErrors = validateCalendarInContract(contract.start, contract.end, values.periods)
  if (!isEmpty(calendarErrors)) return calendarErrors

  const sortedPeriods = sortPeriodsByDate({ periods: values.periods, removeDeleted: true })
  let calendarStartDate: DateTime = values.start
  let calendarEndDate: DateTime | undefined = values.end
  if (sortedPeriods.length > 0) {
    calendarStartDate = sortedPeriods[0].start
    calendarEndDate = sortedPeriods[sortedPeriods.length - 1].end
  }

  //Is this calendar overlapping existing calendars?
  const overlapErrors = validateCalendarPeriod(values, calendarStartDate, calendars, calendarEndDate)
  if (!isEmpty(overlapErrors)) return overlapErrors

  return {}
}

export const validateCalendarStartDate = (
  values: NewCalendarWithPeriodsRequest,
  contractModel: ContractModel,
  existingCalendars: CalendarResponse[],
  modelType: ContractModelType
): FormValidation => {
  const isInvoice = modelType === ContractModelType.INVOICE

  let errors: FormValidation = {}

  if (values.start < contractModel.start) {
    errors = {
      start: `Calendar can not start before contract model's start date (${contractModel.start.toFormat(
        DateTimeFormat.DATE
      )}).`
    }
  }
  if (contractModel.end && values.start >= contractModel.end) {
    errors = {
      start: `Calendar must start before contract model's end date (${contractModel.end.toFormat(
        DateTimeFormat.DATE
      )}).`
    }
  }

  if (isEmpty(existingCalendars)) return errors

  if (values.isReplace) {
    const originalCalendar = existingCalendars.find((calendar) => calendar.guid === values.guid)

    if (originalCalendar && originalCalendar.start >= values.start) {
      return {
        start: `Must start after start of the original calendar (${originalCalendar.start.toFormat(
          DateTimeFormat.DATE
        )})`
      }
    }

    if (originalCalendar && originalCalendar.end <= values.start) {
      return {
        start: `Date selected is outside of this ${
          isInvoice ? "billing" : "payment"
        } calendar (${originalCalendar.start.toFormat(DateTimeFormat.DATE)} - ${originalCalendar.end.toFormat(
          DateTimeFormat.DATE
        )}). To use this date, please create a new calendar on the ${isInvoice ? "Billing" : "Payment"} Calendars page.`
      }
    }
  }

  const overlappingCalendar = existingCalendars
    .filter((calendar) => {
      return values.guid !== calendar.guid
    })
    .find((calendar) => {
      return calendar.start < values.start && (!calendar.end || (calendar.end && calendar.end > values.start))
    })

  if (overlappingCalendar) {
    errors = {
      start: `Calendar overlaps with another calendar ${
        overlappingCalendar.title
      } (${overlappingCalendar.start.toFormat(DateTimeFormat.DATE)} - ${overlappingCalendar.end.toFormat(
        DateTimeFormat.DATE
      )}).`
    }
  }

  return errors
}
