import { Box } from "@mui/material"
import { WithPermission } from "components/Auth"
import { Button } from "components/Form"
import testingId from "constants/testingId"
import React, { memo, useCallback } from "react"

interface OwnProps {
  toggleAll: () => void
  selectableIdsForCrediting: string[]
}

export const TransactionListSelectButton: React.FC<OwnProps> = memo(({ toggleAll, selectableIdsForCrediting }) => {
  const handleSelectCreditClick = useCallback(() => {
    toggleAll()
  }, [toggleAll])

  const creditAllButtonDisabled = !selectableIdsForCrediting.length

  return (
    <WithPermission permissions={["Finance.Processing.Credit:Edit"]}>
      <Box p={1} px={0} display="flex" justifyContent="flex-end">
        <Button
          fullWidth={false}
          variant="text"
          color="secondary"
          onClick={handleSelectCreditClick}
          data-cy={testingId.selectAllForCrediting}
          disabled={creditAllButtonDisabled}
        >
          (Un)Select all for crediting
        </Button>
      </Box>
    </WithPermission>
  )
})

TransactionListSelectButton.displayName = "TransactionListSelectButton"
