import React from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { Field, FormSpy, FormSpyRenderProps } from "react-final-form"
import { Button, TextField, Select } from "components/Form"
import { SchemaFieldOption, SetPagination } from "lib/types"
import validators, { composeValidators, NUMBER_OF_WEEKS_IN_A_YEAR } from "lib/validators"
import testingId from "constants/testingId"
import { getContractCalendarPeriodDates } from "../../helpers/getContractCalendarPeriodDates"
import { FormApi, ValidationErrors } from "final-form"
import { AddCalendarRequest, CalendarPeriod } from "data/finance/contractModel/types"

const PROCESS_AS_PER_DAY = "asPerEnd"
const INVOICE_DEADLINE_NOT_AVAILABLE = "n/a"

const processDateOffset: SchemaFieldOption[] = [
  { title: "As per end", value: PROCESS_AS_PER_DAY },
  { title: "Offset by", value: "processDateOffset" }
]

const invoiceDeadlineOffset: SchemaFieldOption[] = [
  { title: "N/A", value: INVOICE_DEADLINE_NOT_AVAILABLE },
  { title: "Offset by", value: "invoiceDeadlineOffset" }
]

interface OwnProps<T> {
  fields: { insert: (index: number, value: T) => void }
  setPagination: SetPagination
}

const AddCalendarPeriod: React.FC<OwnProps<CalendarPeriod>> = ({ fields, setPagination }) => {
  const handleClick = ({
    values,
    form
  }: {
    values: AddCalendarRequest
    form: FormApi<AddCalendarRequest, Partial<AddCalendarRequest>>
  }) => {
    const newPeriods = getContractCalendarPeriodDates(values)

    // add new periods to the top
    newPeriods.forEach((newPeriod) => {
      fields.insert(0, newPeriod)
    })

    form.change("periodCount", 0)
    form.change("processDays", 1)
    form.change("invoiceDeadlineDays", 1)
    form.change("invoiceDeadlineOffset", "invoiceDeadlineOffset")
    form.change("processDateOffset", "processDateOffset")

    setPagination((prev) => ({ ...prev, page: "1" }))
  }

  const isDisabled = ({ values, errors }: { values: AddCalendarRequest; errors: ValidationErrors }) =>
    !!(errors?.periodCount || +values.periodCount === 0 || errors?.processDays || errors?.invoiceDeadlineDays)

  return (
    <Box m={3} mx={0}>
      <FormSpy subscription={{ values: true, errors: true }}>
        {({ values, form, errors }: FormSpyRenderProps<AddCalendarRequest>) => (
          <Grid container spacing={1}>
            <Grid item md={1}>
              <Field<string>
                label="Periods"
                component={TextField}
                name="periodCount"
                InputLabelProps={{
                  shrink: true
                }}
                validate={composeValidators([
                  validators.mustBeNumber,
                  validators.minValue(0),
                  validators.precision(0),
                  validators.maxValue(NUMBER_OF_WEEKS_IN_A_YEAR)
                ])}
              />
            </Grid>
            <Grid item md={3}>
              <Field<string>
                name="processDateOffset"
                component={Select}
                options={processDateOffset}
                label="Process Date Offset"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item md={1}>
              <Field<string>
                label="Days"
                component={TextField}
                name="processDays"
                InputLabelProps={{
                  shrink: true
                }}
                disabled={values.processDateOffset === undefined || values.processDateOffset === PROCESS_AS_PER_DAY}
                validate={composeValidators([validators.mustBeNumber, validators.minValue(0), validators.precision(0)])}
              />
            </Grid>
            <Grid item md={3}>
              <Field<string>
                name="invoiceDeadlineOffset"
                component={Select}
                options={invoiceDeadlineOffset}
                label="Invoice Deadline Offset"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item md={1}>
              <Field<string>
                label="Days"
                component={TextField}
                name="invoiceDeadlineDays"
                InputLabelProps={{
                  shrink: true
                }}
                disabled={
                  values.invoiceDeadlineOffset === undefined ||
                  values.invoiceDeadlineOffset === INVOICE_DEADLINE_NOT_AVAILABLE
                }
                validate={composeValidators([validators.mustBeNumber, validators.minValue(0), validators.precision(0)])}
              />
            </Grid>
            <Grid item md={1}>
              <Button
                style={{ marginTop: "22px" }}
                fullWidth={true}
                onClick={() => handleClick({ values, form })}
                data-cy={testingId.contractModel.calendar.detail.periodAddButton}
                disabled={isDisabled({ values, errors })}
              >
                ADD
              </Button>
            </Grid>
          </Grid>
        )}
      </FormSpy>
    </Box>
  )
}

export default React.memo(AddCalendarPeriod)
