import { FinanceApi } from "api"
import { enabledById, enabledByMandatoryUrlParams } from "data/helpers"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { ApiClientContract } from "./types"

export enum QueryKeyParts {
  CLIENT = "client",
  CONTRACT = "contract",
  LIST = "list",
  DETAIL = "detail"
}

export const queryKeys = {
  getClientContractKey: ({ clientGuid, contractGuid }: { clientGuid?: string; contractGuid?: string }): QueryKey => [
    QueryKeyParts.CLIENT,
    QueryKeyParts.CONTRACT,
    QueryKeyParts.DETAIL,
    { clientGuid, contractGuid }
  ],
  getClientContractsKey: (clientGuid: string): QueryKey => [
    QueryKeyParts.CLIENT,
    QueryKeyParts.CONTRACT,
    QueryKeyParts.LIST,
    { clientGuid }
  ]
}

export const useClientContractListQuery = (clientGuid: string): UseQueryResult<ApiClientContract[], Error> => {
  return useQuery({
    queryKey: queryKeys.getClientContractsKey(clientGuid),
    queryFn: async () => FinanceApi.get(`/finance/clients/${clientGuid}/contracts`),
    ...enabledById(clientGuid)
  })
}

export const useClientContractQuery = ({
  clientGuid,
  contractGuid,
  enabled = true
}: {
  clientGuid?: string
  contractGuid?: string
  enabled?: boolean
}): UseQueryResult<ApiClientContract, Error> => {
  return useQuery({
    queryKey: queryKeys.getClientContractKey({ clientGuid, contractGuid }),
    queryFn: async () => FinanceApi.get(`/finance/clients/${clientGuid}/contracts/${contractGuid}`),
    ...enabledByMandatoryUrlParams([clientGuid, contractGuid, enabled])
  })
}
