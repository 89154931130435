import { CoreApi } from "api"
import { enabledById } from "data/helpers"
import { objectToQueryString } from "lib/queryString"
import { DateTime } from "luxon"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { ApiShiftDetailResponse, ApiShiftResponse } from "types/Shifts/ShiftList/types"

export enum QueryKeyParts {
  SHIFT = "shift",
  LIST = "list",
  DETAIL = "detail"
}

export const queryKeys = {
  getShiftListKey: (start: DateTime, end: DateTime, qs?: string): QueryKey => [
    QueryKeyParts.SHIFT,
    QueryKeyParts.LIST,
    { start, end, qs }
  ],
  getShiftDetailKey: (id: string): QueryKey => [QueryKeyParts.SHIFT, QueryKeyParts.DETAIL, { id }]
}

export const useShiftListQuery = ({
  start,
  end,
  region,
  carer_guid
}: {
  start: DateTime
  end: DateTime
  region?: number
  carer_guid?: string
}): UseQueryResult<ApiShiftResponse[], Error> => {
  const qs = objectToQueryString({ region, carer_guid }, undefined, true)
  const qsBuild = qs ? `&${qs}` : ""

  return useQuery({
    queryKey: queryKeys.getShiftListKey(start, end, qsBuild),
    queryFn: async () => CoreApi.get(`/shifts?begin=${start}&end=${end}${qsBuild}`)
  })
}

export const useShiftDetailQuery = (id: string): UseQueryResult<ApiShiftDetailResponse, Error> => {
  return useQuery({
    queryKey: queryKeys.getShiftDetailKey(id),
    queryFn: async () => CoreApi.get(`/shifts/${id}`),
    ...enabledById(id)
  })
}
