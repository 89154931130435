import { useBranchInfoQuery } from "data/core/branch/queries"

/**
 * useBranchCareplannerUrl
 *
 * @returns the current branch care planner url
 */
const useBranchCareplannerUrl = () => {
  const { data: { care_planner_url = null } = {} } = useBranchInfoQuery()
  return care_planner_url
}

export default useBranchCareplannerUrl
