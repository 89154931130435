import { Box, Grid } from "@mui/material"
import { WithPermission } from "components/Auth"
import { Button } from "components/Form"
import testingId from "constants/testingId"
import React, { memo } from "react"

interface Props {
  onClick: () => void
}

const TransactionListAddClientButton: React.FC<Props> = ({ onClick }) => {
  return (
    <WithPermission permissions={["Finance.Processing.User:Edit"]}>
      <Grid container spacing={1}>
        <Grid item md={12}>
          <Box textAlign="right">
            <Button
              fullWidth={false}
              color="primary"
              onClick={onClick}
              data-cy={testingId.invoiceProcessingTransactionList.addClientButton}
            >
              Add Client
            </Button>
          </Box>
        </Grid>
      </Grid>
    </WithPermission>
  )
}

export default memo(TransactionListAddClientButton)
