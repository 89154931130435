import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { grey } from "@mui/material/colors"

const NotFound: React.FC = () => (
  <Box
    display="flex"
    justifyContent="space-between"
    p={10}
    m={10}
    mx={"auto"}
    alignItems="center"
    flexDirection="column"
    bgcolor={grey[100]}
  >
    <Typography variant="h2">Page not found</Typography>
    <Typography variant="body1">Looks like you have stumbled upon a page that does not exist.</Typography>
    <Typography variant="body2">
      If you think this is an error and this page should be here, please contact the user support.
    </Typography>
  </Box>
)
export default NotFound
