import { TableCell, TableHead, TableRow } from "@mui/material"
import React, { memo } from "react"

const baseHeaders = [
  "Type",
  "Start Date (R)",
  "End Date (R)",
  "Client Name",
  "Carer Name",
  "Invoice Model",
  "Service",
  "Variation",
  "Check In",
  "Check Out",
  "Status"
]

const getHeaders = (canEdit: boolean, hasRecalcPermission: boolean) => {
  const editHeaders = canEdit ? [...baseHeaders, "Select"] : baseHeaders
  return hasRecalcPermission ? [...editHeaders, "Recalculate"] : editHeaders
}
interface OwnProps {
  hasAppointmentEditPermission: boolean
  hasRecalcPermission: boolean
}

export const AppointmentSearchScheduledHeader: React.FC<OwnProps> = memo(
  ({ hasAppointmentEditPermission, hasRecalcPermission }) => {
    return (
      <TableHead>
        <TableRow>
          {getHeaders(hasAppointmentEditPermission, hasRecalcPermission).map((item) => (
            <TableCell key={item}>{item}</TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
)

AppointmentSearchScheduledHeader.displayName = "AppointmentSearchScheduledHeader"
