import { Pagination } from "lib/types"

export const getPaginatedItems = <T>({
  pagination,
  items = [],
  addIndex
}: {
  pagination: Pagination
  items: T[]
  addIndex?: boolean
}): T[] => {
  const firstItemIndex = (+pagination.page - 1) * +pagination.page_size
  const lastItemIndex = +pagination.page * +pagination.page_size

  const mappedItems = addIndex ? items.map((item, index) => ({ ...item, index })) : items

  return mappedItems.slice(firstItemIndex, lastItemIndex)
}
