import { Box, Typography } from "@mui/material"
import { ContentContainer, EmptyData, Divider, Link } from "components"
import DatesMessageHelp from "components/DatesMessageHelp"
import React, { memo, useMemo, useState } from "react"
import { ContractModelFilter, ContractModelTable, ContractModelTitle } from "."
import { ContractModelType } from "constants/modelTypes"
import { queryStringToObject } from "lib/queryString"
import { filterContractModels } from "../helpers/filterContractModels"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { WithPermission } from "components/Auth"
import testingId from "constants/testingId"
import { useLocation } from "react-router-dom"
import { Button } from "components/Form"
import { ContractModelFilterValues } from "../types"
import { ContractModel } from "data/finance/contractModel/types"

interface OwnProps {
  contracts?: ContractModel[]
  modelType: ContractModelType
}

interface TableProps {
  contracts?: ContractModel[]
  modelType: ContractModelType
  filter: ContractModelFilterValues
}

const Models: React.FC<TableProps> = ({ contracts, modelType, filter }) => {
  const filteredContracts = useMemo(
    () => filterContractModels({ filter, contracts, expired: false }),
    [filter, contracts]
  )

  return filteredContracts?.length ? (
    <ContractModelTable {...{ modelType, items: filteredContracts }} />
  ) : (
    <EmptyData message="No filtered contract models found." />
  )
}

const ExpiredModels: React.FC<TableProps> = ({ contracts, modelType, filter }) => {
  const expiredFilteredContracts = useMemo(
    () => filterContractModels({ filter, contracts, expired: true }),
    [filter, contracts]
  )

  return (
    <WithPermission permissions={["Finance.Model:Edit"]}>
      <Typography
        variant="h5"
        sx={(theme) => ({
          marginTop: theme.spacing(4)
        })}
        component="h2"
      >
        Expired Models
      </Typography>
      <Divider color="divider" />
      {expiredFilteredContracts?.length ? (
        <ContractModelTable
          {...{ modelType, items: expiredFilteredContracts, dataCy: testingId.contractModel.list.expiredTable }}
        />
      ) : (
        <EmptyData message="No expired contracts to show." />
      )}
    </WithPermission>
  )
}

const ContractModelList: React.FC<OwnProps> = ({ contracts, modelType }) => {
  const location = useLocation()
  const [filter, setFilter] = useState<ContractModelFilterValues>(queryStringToObject(location.search))

  return (
    <ContentContainer>
      <ContractModelTitle
        {...{
          modelType,
          action: (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Link to="rate-uplift" sx={(theme) => ({ marginRight: theme.spacing(1) })}>
                <Button variant="outlined">Rates Uplift</Button>
              </Link>

              <Link to="new">
                <Button data-cy={testingId.contractModel.list.createButton}>Create a model</Button>
              </Link>
            </Box>
          )
        }}
      />
      <Box m={3} mx={0}>
        <ContractModelFilter {...{ setFilter, filter, modelType }} />
        <Divider color="divider" />
        <DatesMessageHelp messageType="E" />
        <RequestLoaderWrapper>
          {contracts?.length ? (
            <>
              <Models {...{ contracts, modelType, filter }} />
              <ExpiredModels {...{ contracts, modelType, filter }} />
            </>
          ) : (
            <EmptyData message="No contract models found, start by adding one." />
          )}
        </RequestLoaderWrapper>
      </Box>
    </ContentContainer>
  )
}

export default memo(ContractModelList)
