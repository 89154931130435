import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { Link } from "components"
import testingId from "constants/testingId"
import { useCarerPayslipQuery } from "data/finance/carer/queries"
import { ApiCarerPayslip } from "data/finance/carer/types"
import { DateTimeFormat } from "lib/datetime"
import { downloadFileSimple } from "lib/utils"
import React, { memo, MouseEvent, useEffect, useState } from "react"

interface OwnProps {
  payslips: ApiCarerPayslip[]
  carerGuid: string
}

export const CarerPayslipListTable: React.FC<OwnProps> = memo(({ carerGuid, payslips }) => {
  const [clickedGuid, setClickedGuid] = useState<string | undefined>(undefined)

  const { data: payslipDetail } = useCarerPayslipQuery({ carerGuid, payslipGuid: clickedGuid })

  const onClick = (payslipGuid: string) => (e: MouseEvent) => {
    e.preventDefault()
    setClickedGuid(payslipGuid)
  }

  useEffect(() => {
    if (clickedGuid && payslipDetail?.link) {
      downloadFileSimple({ href: payslipDetail.link, fileName: "payslip" })
      setClickedGuid(undefined)
    }
  }, [clickedGuid, payslipDetail])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell data-cy={testingId.tableHeaderCell}>Start</TableCell>
          <TableCell data-cy={testingId.tableHeaderCell}>End</TableCell>
          <TableCell data-cy={testingId.tableHeaderCell}>Generated On</TableCell>
          <TableCell data-cy={testingId.tableHeaderCell}>Download</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {payslips.map((payslip) => (
          <TableRow data-cy={testingId.tableRow} key={payslip.guid}>
            <TableCell data-cy={testingId.tableCell}>
              {payslip.period_start.toFormat(DateTimeFormat.DATE_TIME)}
            </TableCell>
            <TableCell data-cy={testingId.tableCell}>{payslip.period_end.toFormat(DateTimeFormat.DATE_TIME)}</TableCell>
            <TableCell data-cy={testingId.tableCell}>
              {payslip.generated_at.toFormat(DateTimeFormat.DATE_TIME)}
            </TableCell>
            <TableCell data-cy={testingId.tableCell}>
              <Link {...{ to: "/#", onClick: onClick(payslip.guid) }}>Download</Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

CarerPayslipListTable.displayName = "CarerPayslipListTable"
