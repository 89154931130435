import validators, { composeValidators, ValidatorReturnValue } from "lib/validators"
import { Field } from "react-final-form"
import React from "react"
import { DatePicker } from "."
import { DateTime } from "luxon"

interface OwnProps {
  required?: boolean
  name: string
  label: string
  disabled?: boolean
  minDate?: DateTime
  maxDate?: DateTime
  helperText?: string
  defaultValue?: DateTime
  withTime?: boolean
  warningValidators?: (dob: DateTime | null) => ValidatorReturnValue
  validate?: (value: DateTime | null) => ValidatorReturnValue
}

export const DateField = ({ required, label, validate: customValidate = () => undefined, ...rest }: OwnProps) => {
  const defaultValidators = [validators.validDate, customValidate]

  const validate = composeValidators(required ? [validators.required, ...defaultValidators] : defaultValidators)

  const labelWithAsterisk = required ? `${label} *` : label

  return <Field label={labelWithAsterisk} component={DatePicker} validate={validate} {...rest} />
}
