import { Box, Grid, Typography } from "@mui/material"
import { StickyBox } from "components"
import { Button, CancelButton, DatePicker, Select } from "components/Form"
import { useClientListQuery } from "data/core/client/queries"
import { useUnitUserCreateMutation, useUnitUserEditMutation } from "data/finance/unit/mutations"
import { UnitUser, UnitUserRequest } from "data/finance/unit/types"
import { ProfileMode } from "lib/types"
import validators, { composeValidators } from "lib/validators"
import { DateTime } from "luxon"
import React, { memo, useMemo } from "react"
import { Field, Form } from "react-final-form"

interface OwnProps {
  mode: ProfileMode
  user?: UnitUser
  unitGuid: string
}

const REQUIRED_FIELD = "This field is required"

const useSubmitHandler = (mode: ProfileMode, unitGuid: string) => {
  const { mutateAsync: createUnitUser } = useUnitUserCreateMutation({ unitGuid })
  const { mutateAsync: updateUnitUser } = useUnitUserEditMutation({ unitGuid })

  if (mode === "create") {
    return (user: UnitUserRequest) => {
      if (!user.start || !user.user_guid) {
        return {
          ...(!user.start ? { start: REQUIRED_FIELD } : {}),
          ...(!user.user_guid ? { user_guid: REQUIRED_FIELD } : {})
        }
      }
      createUnitUser({ user })
    }
  } else {
    return (user: UnitUser) => {
      if (!user.start) {
        return { start: REQUIRED_FIELD }
      }
      updateUnitUser({ user })
    }
  }
}

const Item = ({ children }: { children: React.JSX.Element }): React.JSX.Element => (
  <Grid {...{ item: true, md: 6 }}>{children}</Grid>
)

export const UnitUserProfile: React.FC<OwnProps> = memo(({ mode, user, unitGuid }) => {
  const { data: clients } = useClientListQuery({})

  const handleSubmit = useSubmitHandler(mode, unitGuid)

  const clientsAsOptions = useMemo(
    () => clients?.users.map((client) => ({ title: client.profile.name, value: client.guid })) || [],
    [clients]
  )

  return (
    <Box>
      <Form {...{ onSubmit: handleSubmit, initialValues: user }}>
        {({ handleSubmit, pristine, submitting, values }) => (
          <form {...{ onSubmit: handleSubmit }}>
            <Box my={2}>
              <Grid
                container
                spacing={1}
                sx={{
                  mt: 2,
                  mb: 2
                }}
              >
                {user ? (
                  <>
                    <Item>
                      <>
                        <Typography variant="body2" color="textSecondary">
                          User Name
                        </Typography>
                        <Typography variant="h6">{user.user_name}</Typography>
                      </>
                    </Item>
                    <Item>
                      <>
                        <Typography variant="body2" color="textSecondary">
                          Status
                        </Typography>
                        <Typography variant="h6">{user.status}</Typography>
                      </>
                    </Item>
                  </>
                ) : (
                  <Item>
                    <Field<string>
                      {...{
                        name: "user_guid",
                        label: "User",
                        component: Select,
                        options: clientsAsOptions,
                        InputLabelProps: { required: true }
                      }}
                    />
                  </Item>
                )}
              </Grid>
              <Grid container spacing={1}>
                <Item>
                  <Field<DateTime>
                    {...{
                      name: "start",
                      label: "Start",
                      component: DatePicker,
                      withTime: false,
                      InputLabelProps: { required: true },
                      maxDate: values.end ? values.end.minus({ days: 1 }) : undefined,
                      validate: composeValidators([validators.required, validators.validDate])
                    }}
                  />
                </Item>
                <Item>
                  <Field<DateTime>
                    {...{
                      name: "end",
                      label: "End",
                      component: DatePicker,
                      withTime: false,
                      minDate: values.start ? values.start.plus({ days: 1 }) : undefined,
                      validate: validators.validDate
                    }}
                  />
                </Item>
              </Grid>
            </Box>
            <StickyBox>
              <CancelButton {...{ disabled: submitting, pristine, navigateUrl: `/units/${unitGuid}/users` }} />
              <Button {...{ type: "submit", fullWidth: false, disabled: submitting || pristine }}>Save changes</Button>
            </StickyBox>
          </form>
        )}
      </Form>
    </Box>
  )
})

UnitUserProfile.displayName = "UnitUserProfile"
