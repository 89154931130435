import React from "react"
import { Button, styled, SxProps, Typography } from "@mui/material"
import { unstable_styleFunctionSx, StyleFunction } from "@mui/system"
import { useNavigate, useParams } from "react-router-dom"
import Container from "./Container"
import { useForm, FormProvider } from "react-hook-form"
import useMutationInvitation from "./useMutationInvitation"
import PasswordCreateFields from "./PasswordCreateFields"
import type { PasswordFields } from "./PasswordCreateFields"

const Form = styled("form")<{ sx?: SxProps }>({
  textAlign: "right",
  ...(unstable_styleFunctionSx as StyleFunction<{ sx?: SxProps }>)
})

const Invitation = () => {
  const { guid = "", token = "" } = useParams<{ guid: string; token: string }>()
  const navigate = useNavigate()

  const methods = useForm<PasswordFields>({
    mode: "onChange",
    criteriaMode: "all"
  })

  const { mutate, isLoading } = useMutationInvitation()

  const onSubmit = ({ new_password, new_password_confirm }: PasswordFields) =>
    mutate(
      {
        password: new_password,
        password_confirmation: new_password_confirm,
        invitation_token: token,
        guid
      },
      {
        onSuccess: () => navigate("/invitation-success")
      }
    )

  return (
    <Container title="Set your password" hasLoginLink={false}>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)} sx={{ flex: 1 }}>
          <Typography color="textSecondary" textAlign="left" mb={4}>
            You&apos;ve been invited to create your Cera DCP account. To complete the setup, please set your password
            using the form below.
          </Typography>
          <PasswordCreateFields />
          <Button
            sx={{ display: "inline-block", mt: 4.5, fontWeight: 500 }}
            variant="contained"
            size="large"
            type="submit"
            disabled={!methods.formState.isValid || methods.formState.isSubmitting || isLoading}
            fullWidth
          >
            Create account
          </Button>
        </Form>
      </FormProvider>
    </Container>
  )
}

export default Invitation
