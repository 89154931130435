import { Box, Grid } from "@mui/material"
import { ClearFilterButton } from "components/ClearFilterButton"
import { AutocompleteMui, FormSpyCustom, Select } from "components/Form"
import { DateField } from "components/Form/DateField"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { ContractModelType } from "constants/modelTypes"
import { useOperatorListQuery } from "data/core/operator/queries"
import { TaskPreparationMode } from "data/finance/task/types"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"
import { FilterValues, formatFilterValues } from "lib/helpers/formatFilterValues"
import { Pagination } from "lib/types"
import React, { Dispatch, memo, SetStateAction, useMemo } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"

export type TaskFilter = {
  from: string
  to?: string
  modelType: ContractModelType
  type?: TaskPreparationMode
  is_processed?: boolean
  is_started?: boolean
  user_guid?: string
}

interface OwnProps {
  filter: TaskFilter
  setFilter: Dispatch<SetStateAction<TaskFilter>>
  pagination: Pagination
  resetPagination: () => void
  defaultFilterValues: { from: string; modelType: ContractModelType }
}

const typeOptions = [
  { title: "Any", value: "" },
  { title: "Completion", value: "Completion" },
  { title: "Credit", value: "Credit" },
  { title: "Interface", value: "Interface" }
]

const booleanOptions = [
  { title: "Any", value: "" },
  { title: "Yes", value: "true" },
  { title: "No", value: "false" }
]

const ProcessingStatusListFilter: React.FC<OwnProps> = ({
  filter,
  setFilter,
  pagination,
  resetPagination,
  defaultFilterValues
}) => {
  const { data: operators } = useOperatorListQuery()

  const operatorOptions = useMemo(() => {
    return operators?.users.map((operator) => ({ value: operator.guid, title: operator.profile.name })) || []
  }, [operators])

  useFilterLocationChange({ filter, pagination })

  const debouncedOnSubmit = useDebouncedCallback((values: FilterValues<TaskFilter>) => {
    const formattedValues = formatFilterValues<TaskFilter>({ values, startName: "from", endName: "to" })

    setFilter(formattedValues)
    resetPagination()
  }, 500)

  const clearFilter = () => {
    setFilter((oldValues) => ({ ...defaultFilterValues, modelType: oldValues.modelType }))
    resetPagination()
  }

  return (
    <Form
      {...{
        onSubmit: debouncedOnSubmit,
        initialValues: filter,
        validate: validateFilterPeriodDates({ start: "from", end: "to" })
      }}
    >
      {({ handleSubmit }) => {
        return (
          <form {...{ onSubmit: handleSubmit }}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={3}>
                <DateField name="from" label="From" required />
              </Grid>
              <Grid item md={3}>
                <DateField name="to" label="To" />
              </Grid>
              <Grid item md={3}>
                <Field name="type" label="Type" component={Select} options={typeOptions} />
              </Grid>
              <Grid item md={3}>
                <Field
                  {...{ label: "Inserted by", name: "user_guid", component: AutocompleteMui, options: operatorOptions }}
                />
              </Grid>
              <Grid item md={3}>
                <Field<boolean>
                  {...{ name: "is_processed", component: Select, options: booleanOptions, label: "Processed" }}
                />
              </Grid>
              <Grid item md={3}>
                <Field<boolean>
                  {...{ name: "is_started", component: Select, options: booleanOptions, label: "Started" }}
                />
              </Grid>
              <Grid item md={3} />
              <Grid item md={3} container justifyContent="flex-end">
                <Box textAlign="right">
                  <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
                </Box>
              </Grid>
            </Grid>
          </form>
        )
      }}
    </Form>
  )
}

export default memo(ProcessingStatusListFilter)
