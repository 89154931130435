import React from "react"
import Box from "@mui/material/Box"
import { grey } from "@mui/material/colors"
import TapAndPlayIcon from "@mui/icons-material/TapAndPlay"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import Link from "components/Link"
import Typography from "@mui/material/Typography"
import useBranchRedirectCclUrl from "lib/hooks/branchInfo/useBranchRedirectCclUrl"

const CmMobileBanner: React.FC = () => {
  const cclUrl = useBranchRedirectCclUrl()
  return (
    <Link
      to={cclUrl ?? ""}
      external
      color={"inherit"}
      sx={{
        "&:hover": {
          textDecoration: "none",
          cursor: "pointer"
        }
      }}
    >
      <Box
        m={3}
        mx={0}
        p={2}
        sx={{
          backgroundColor: grey[300],
          display: "flex"
        }}
      >
        <Box
          sx={{
            width: 50,
            height: 50,
            padding: 10,
            backgroundColor: "white",
            borderRadius: "50%"
          }}
        >
          <TapAndPlayIcon fontSize="large" />
        </Box>
        <Box
          mx={2}
          sx={{
            height: 50,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column"
          }}
        >
          <Typography variant="body2" color="textSecondary">
            CM Mobile Visit
          </Typography>
          <Typography variant="body2">Click to amend check-in/out times in Call Confirm Live</Typography>
        </Box>
        <Box
          sx={{
            height: 50,
            display: "flex",
            alignItems: "center",
            marginLeft: "auto"
          }}
        >
          <ArrowForwardIosIcon />
        </Box>
      </Box>
    </Link>
  )
}

export default CmMobileBanner
