import { Button, DatePicker } from "./Form"
import ModalWrapper from "./ModalWrapper"
import React, { memo } from "react"
import { Field, Form } from "react-final-form"
import { Box, Grid } from "@mui/material"
import validators, { composeValidators } from "lib/validators"
import { ApiUserFile } from "models"
import { DateTime } from "luxon"

interface Props {
  updateExpiry: ({ expires_at, file }: { expires_at: DateTime; file: ApiUserFile }) => void
  destroyModal: () => void
  file: ApiUserFile
}

const FileListExpiryModal: React.FC<Props> = ({ updateExpiry, destroyModal, file }) => {
  const handleFormSubmit = ({ expires_at }: { expires_at: DateTime }) => {
    updateExpiry({ file, expires_at })
    destroyModal()
  }
  const initialValues = { expires_at: file.expires_at }

  return (
    <ModalWrapper {...{ title: "Update expiration date", destroyModal }}>
      <p>
        You are about to update expiration date for file{" "}
        <strong>
          {file.title} ({file.file_name})
        </strong>
      </p>
      <Grid container>
        <Form {...{ onSubmit: handleFormSubmit, initialValues }}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Field<DateTime>
                      {...{
                        name: "expires_at",
                        component: DatePicker,
                        title: "Expire date",
                        validate: composeValidators([validators.required, validators.validDate])
                      }}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Box display="flex" justifyContent="space-between">
                      <Button variant="text" fullWidth={false} onClick={destroyModal}>
                        Cancel
                      </Button>
                      <Button fullWidth={false} type="submit">
                        Update
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        </Form>
      </Grid>
    </ModalWrapper>
  )
}

export default memo(FileListExpiryModal)
