import { TableBody, TableCell, TableRow } from "@mui/material"
import { EmptyData } from "components"
import testingId from "constants/testingId"
import { SelectableIdsDictionaryModel } from "lib/hooks"
import React, { memo, MouseEvent, useMemo } from "react"
import { optionsToValueTitle } from "lib/schema"
import { Pagination } from "lib/types"
import { mapInvoiceTransaction } from "../helpers/mapInvoiceTransaction"
import { useSchema } from "data/schema/useSchema"
import { Location, useNavigate } from "react-router-dom"
import { SelectableTableRow } from "components/SelectableTableRow"
import { InvoiceTransaction } from "data/finance/invoiceProcessing/types"

interface Props {
  transactions: InvoiceTransaction[]
  toggleId: (id: string) => void
  selectableIds: SelectableIdsDictionaryModel
  selectedIds: string[]
  location?: Location
  pagination: Pagination
  hasCreditableTransactions: boolean
  hasFiles: boolean
  hasAlerts: boolean
  hasDeveloperPermission: boolean
  hasRewindPermission: boolean
  refetch: () => void
}

const TransactionListTableBody: React.FC<Props> = ({
  transactions,
  toggleId,
  selectableIds,
  selectedIds,
  location,
  pagination,
  hasCreditableTransactions,
  hasFiles,
  hasAlerts,
  hasDeveloperPermission,
  hasRewindPermission,
  refetch
}) => {
  const navigate = useNavigate()
  const { TransactionStatus } = useSchema(["TransactionStatus"])
  const statusTypeOptions = optionsToValueTitle(TransactionStatus)

  const items: InvoiceTransaction[] = useMemo(() => {
    const sliceStart = (+pagination.page - 1) * +pagination.page_size
    const sliceEnd = +pagination.page * +pagination.page_size

    return transactions.slice(sliceStart, sliceEnd)
  }, [transactions, pagination])

  return (
    <TableBody>
      {items.length > 0 ? (
        items.map((transaction, index) => (
          <SelectableTableRow
            hover
            clickable
            isSelected={selectedIds.includes(transaction.guid)}
            isReady={transaction.status_type_alias === "READY"}
            onClick={(e: MouseEvent) => {
              e.stopPropagation()
              return location ? navigate(`${location.pathname}/${transaction.guid}`) : null
            }}
            key={index}
          >
            {mapInvoiceTransaction({
              transaction,
              index,
              statusTypeOptions,
              selectableIds,
              toggleId,
              hasCreditableTransactions,
              hasFiles,
              hasAlerts,
              hasDeveloperPermission,
              hasRewindPermission,
              refetch
            }).map((value, cellIndex) => (
              <TableCell key={cellIndex} data-cy={testingId.invoiceProcessingTransactionList.itemCell}>
                {value}
              </TableCell>
            ))}
          </SelectableTableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={11}>
            <EmptyData message="No client contracts found in this billing period" />
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )
}

export default memo(TransactionListTableBody)
