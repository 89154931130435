import { SelectOption } from "components/Form/Select"
import { GUID_ZERO } from "constants/guid"
import { ContractModelType } from "constants/modelTypes"
import { addDatesToSelectOptions } from "data/schema/helpers"
import { StateSchema } from "data/schema/types"
import { DateTime } from "luxon"

export const getContractModelUrl = (contractModelType: ContractModelType): "invoice-models" | "payment-models" => {
  return contractModelType === ContractModelType.INVOICE ? "invoice-models" : "payment-models"
}

export const getContractModelServiceRequestUrl = (isPayment?: boolean): "payment-services" | "services" =>
  isPayment ? "payment-services" : "services"

export const getContractModelServiceUrl = (isPayment?: boolean): "payment-services" | "invoice-services" =>
  isPayment ? "payment-services" : "invoice-services"

export const getContractModelRuleGroupUrl = (isPayment: boolean): "payment-rule-groups" | "invoice-rule-groups" =>
  isPayment ? "payment-rule-groups" : "invoice-rule-groups"

export const filterModelsForSelect = (
  showExpired: boolean,
  list: StateSchema = []
): SelectOption<React.JSX.Element>[] => {
  return addDatesToSelectOptions(
    list
      .filter((item) => item.value !== GUID_ZERO)
      .filter((item) => (showExpired ? true : !item.end || item.end.toMillis() > DateTime.local().toMillis()))
      .sort((a, b) => a.title.localeCompare(b.title))
  )
}
