import React, { memo } from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { ApiCarerContract } from "data/finance/carer/types"
import { DateSpan } from "components/DateSpan"
import { PaymentModel } from "data/finance/contractModel/types"

interface OwnProps {
  paymentModel?: PaymentModel
  contract?: ApiCarerContract
}

export const CarerContractDetailStepTwoHeader: React.FC<OwnProps> = memo(({ paymentModel, contract }) => {
  const contractId = contract?.guid
  const paymentContractId = contract?.reference
  const showTitle = contractId || paymentContractId

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <Typography variant="body2" color="textSecondary">
          Payment model
        </Typography>
        <Typography variant="h6">
          {paymentModel?.title} <DateSpan period={paymentModel} />
        </Typography>
      </Grid>
      {showTitle && (
        <Grid item md={6}>
          <Typography variant="body2" color="textSecondary">
            {paymentContractId ? "Payment Contract ID" : "Contract ID"}
          </Typography>
          <Typography variant="h6">{paymentContractId ? paymentContractId : contractId}</Typography>
        </Grid>
      )}
    </Grid>
  )
})

CarerContractDetailStepTwoHeader.displayName = "CarerContractDetailStepTwoHeader"
