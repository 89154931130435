import React from "react"
import { UserDocuments } from "components"
import { getOptionsFromSchema } from "lib/schema"
import { useParams } from "react-router-dom"
import { useCoreSchemasStore } from "stores"
import { UserType } from "models"

const CarerDocuments: React.FC = () => {
  const { id = "" } = useParams()
  const { BusinessCarerFile } = useCoreSchemasStore((state) => state.schema.models)

  const categoryOptions = getOptionsFromSchema(BusinessCarerFile, "category_guid")

  return <UserDocuments userType={UserType.Carer} user={id} categoryOptions={categoryOptions} />
}

export default CarerDocuments
