import React, { memo, useState } from "react"
import Grid from "@mui/material/Grid"
import { AutocompleteMui, Checkbox } from "components/Form"
import { useSchema } from "data/schema/useSchema"
import { Field } from "react-final-form"
import validators from "lib/validators"
import { filterModelsForSelect } from "data/finance/contractModel/helpers"

export const ShiftCategoryContractStepOne: React.FC = memo(() => {
  const { InvoiceContract } = useSchema(["InvoiceContract"])

  const [checked, setChecked] = useState(false)

  const invoiceModelOptions = filterModelsForSelect(checked, InvoiceContract)

  const showExpired = () => {
    setChecked(!checked)
  }

  return (
    <>
      <Grid container>
        <Grid item md={6}>
          <Field
            name="showExpired"
            label="Show Expired Invoice Models"
            component={Checkbox}
            type="checkbox"
            checked={checked}
            onChange={showExpired}
          />
          <Field
            name="contract_guid"
            label="Contract Model *"
            component={AutocompleteMui}
            options={invoiceModelOptions}
            validate={validators.required}
          />
        </Grid>
      </Grid>
    </>
  )
})

ShiftCategoryContractStepOne.displayName = "ShiftCategoryContractStepOne"
