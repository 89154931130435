import { useRef, useEffect, useState, useCallback } from "react"
import { mapValues, flow, pickBy } from "lodash/fp"
import { reduce, pick } from "lodash"
import { equals } from "ramda"
import Auth from "./Auth"
import { AUTH_ENV_DEV, AUTH_ENV_QA } from "./constants"

export function usePrevious<T>(value: T | undefined): T | undefined {
  const ref = useRef<T | undefined>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

/**
 * Returns a constant value, which has always the same reference.
 * You cannot change the value passed to the hook.
 *
 * Example usage: `const constantStringArray = useConstant(["A", "B"])`
 */
export const useConstant = <T>(value: T): T => {
  const ref = useRef(value)

  return ref.current
}

export interface SelectableIdsDictionaryModel {
  [key: string]: boolean
}

export interface SelectableIdsDictionary {
  toggleId: (id: string) => void
  toggleAll: () => void
  toggleSubset: (idArr: string[]) => void
  resetAll: () => void
  getSelectedAsStringArr: () => string[]
  selectableIds: SelectableIdsDictionaryModel
}

const initialDictionaryState = (idList: string[]): SelectableIdsDictionaryModel => {
  return reduce(
    idList,
    (obj: SelectableIdsDictionaryModel, id) => {
      obj[id] = false
      return obj
    },
    {}
  )
}

export function useSelectableIdsDictionary(ids: string[] = []): SelectableIdsDictionary {
  const [initialIds, setInitialIds] = useState<string[]>(() => ids)
  const [selectableIds, setSelectableIds] = useState<SelectableIdsDictionaryModel>(initialDictionaryState(ids))

  useEffect(() => {
    // initialIds and if condition exist because of setState & useEffect re-rendering loop
    if (!equals(ids, initialIds)) {
      setInitialIds(ids)
      setSelectableIds(initialDictionaryState(ids))
    }
  }, [setSelectableIds, setInitialIds, initialIds, ids])

  const toggleId = useCallback(
    (id: keyof typeof selectableIds | string) => {
      setSelectableIds((prevState) => {
        return { ...prevState, [id]: !prevState[id] }
      })
    },
    [setSelectableIds]
  )

  const toggleAll = useCallback(() => {
    const notAllAreSelected = Object.values(selectableIds).some((val: boolean) => !val)
    setSelectableIds((selectableIds) => mapValues(() => notAllAreSelected, selectableIds))
  }, [selectableIds, setSelectableIds])

  const resetAll = useCallback(() => {
    setSelectableIds((selectableIds) => mapValues(() => false, selectableIds))
  }, [setSelectableIds])

  const toggleSubset = useCallback(
    (idArray: string[]) => {
      const subset = pick(selectableIds, idArray)
      const notAllAreSelected = Object.values(subset).some((val: boolean) => !val)
      const modifiedSubset = mapValues(() => notAllAreSelected, subset)
      setSelectableIds((selectableIds) => {
        const defaultValues = mapValues(() => false, selectableIds)
        return { ...defaultValues, ...modifiedSubset }
      })
    },
    [selectableIds, setSelectableIds]
  )

  const getSelectedAsStringArr = useCallback(() => {
    return flow(
      pickBy((val) => val),
      Object.keys
    )(selectableIds)
  }, [selectableIds])

  return {
    toggleId,
    toggleSubset,
    toggleAll,
    resetAll,
    getSelectedAsStringArr,
    selectableIds
  }
}

export const useIsQA = ({ includeDev = true }: { includeDev?: boolean } = {}): boolean => {
  const userInfo = Auth.getUserInfo()

  if (!userInfo?.env) {
    return false
  }

  if (includeDev) {
    return [AUTH_ENV_DEV, AUTH_ENV_QA].includes(userInfo?.env)
  }

  return AUTH_ENV_QA === userInfo?.env
}
