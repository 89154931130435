import React, { ReactNode, useState } from "react"
import { Box, Chip, Fade, FormControl, Paper, Popper, PopperProps } from "@mui/material"
import Button from "./Button"
import testingId from "constants/testingId"

interface Props {
  title: string
  filteredItems: number
  "data-cy"?: string
  children?: ReactNode
}

const PreferenceMultiselect = ({ "data-cy": dataCyId, title, filteredItems, children }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<PopperProps["anchorEl"]>(null)
  const [isOpen, setIsOpen] = useState(false)

  // TODO(maoc): We'll have to find a different way, the select options are now appended to HTML DOM rather than as a child of select element
  // const handleClickAway = (event: any) => {
  //   let parent = event.target.parentElement
  //   while (parent) {
  //     if (parent.classList.contains("MuiPaper-root")) return
  //     parent = parent.parentElement
  //   }

  //   setIsOpen(false)
  // }

  return (
    <>
      <Popper sx={{ zIndex: 10 }} open={isOpen} anchorEl={anchorEl} placement="bottom" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box minWidth={"130%"} maxWidth={"200%"}>
              <Paper
                elevation={2}
                sx={{
                  width: "100%"
                }}
              >
                <Box p={3}>{children}</Box>
                <Box p={3} display="flex" justifyContent="flex-end">
                  <Button
                    fullWidth={false}
                    onClick={() => setIsOpen(false)}
                    data-cy={testingId.preferenceMultiselectCloseButton}
                  >
                    Close
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Fade>
        )}
      </Popper>
      <FormControl margin={"normal"} fullWidth>
        <Box
          sx={(theme) => ({
            border: "solid",
            borderWidth: isOpen ? 2 : 1,
            borderColor: isOpen ? theme.palette.primary.main : "rgba(0, 0, 0, 0.23)", // same as material-ui select
            color: isOpen ? theme.palette.primary.main : theme.palette.text.secondary,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: theme.spacing(1.8, 2),
            margin: theme.spacing(0, 0, 0, 0),
            borderRadius: "4px",
            cursor: "pointer"
          })}
          onClick={(event) => {
            setAnchorEl(event.currentTarget)
            setIsOpen(!isOpen)
          }}
          data-cy={dataCyId ? dataCyId : testingId.preferenceMultiselect}
        >
          <span>{title}</span>
          <Chip
            sx={{ margin: 0 }}
            size="small"
            label={filteredItems}
            color={filteredItems > 0 ? "secondary" : "default"}
          />
        </Box>
      </FormControl>
    </>
  )
}
export default PreferenceMultiselect
