import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { EmptyData, Link } from "components"
import dateTimeUtils from "lib/datetime"
import DeleteIcon from "@mui/icons-material/Delete"
import { optionsToValueTitle } from "lib/schema"
import React, { memo, useCallback, useMemo, useState } from "react"
import { Form } from "react-final-form"
import { Button } from "components/Form"
import {
  CreditingModal,
  PaymentExtrasModal,
  DeleteChargesModal,
  TransactionInProgress,
  TransactionQueued,
  TransactionFailed
} from "../../components"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import Auth from "lib/Auth"
import MoneyWrapper from "components/MoneyWrapper"
import { SelectableIdsDictionary } from "lib/hooks"
import { isUndefined } from "lodash"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import { isEmpty, isNil } from "ramda"
import { useProcessingExtraRowClick } from "pages/Finance/InvoiceProcessing/handlers/useProcessingExtraRowClick"
import { AppointmentType } from "constants/appointmentType"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import RowSelectionCheckbox from "components/RowSelectionCheckbox"
import { TaskPreparationCreditMode } from "data/finance/task/types"
import { ApiCarerContract } from "data/finance/carer/types"
import { RelationType } from "constants/relationType"
import { TransactionItemStatus } from "components/TransactionItemStatus"
import { useSchema } from "data/schema/useSchema"
import { PaymentTransaction, PaymentTransactionCharge } from "data/finance/paymentProcessing/types"
import { CalendarPeriod } from "data/finance/contractModel/types"

type ChargesTableProps = {
  charges: PaymentTransactionCharge[]
  filteredCharges: PaymentTransactionCharge[]
  transaction: PaymentTransaction
  period: CalendarPeriod
  onExtrasCredited: Function
  selectableChargesDictionary: SelectableIdsDictionary
  deletableChargesDictionary: SelectableIdsDictionary
  contract?: ApiCarerContract
  canEditExtra: boolean
}

export const TransactionDetailChargesTable: React.FC<ChargesTableProps> = memo(
  ({
    charges,
    period,
    filteredCharges,
    transaction,
    onExtrasCredited,
    selectableChargesDictionary,
    deletableChargesDictionary,
    contract,
    canEditExtra
  }) => {
    const [modal, setModal] = useState<React.ReactNode>(null)

    const { TransactionCharge } = useSchema(["TransactionCharge"])
    const chargeTypes = optionsToValueTitle(TransactionCharge)

    const hasTransactionReference = !!transaction.reference
    const canCreditTransaction = hasTransactionReference && Auth.hasPermission(["Finance.Processing.Credit:Edit"])

    // credit
    const { selectableIds, getSelectedAsStringArr, toggleId, toggleAll, resetAll } = selectableChargesDictionary
    const selectedIds = getSelectedAsStringArr()

    // delete
    const {
      selectableIds: selectableDeleteIds,
      getSelectedAsStringArr: getSelectedToDeleteAsStringArr,
      toggleId: toggleDeleteId,
      resetAll: resetDeleteAll
    } = deletableChargesDictionary
    const selectedDeleteIds = getSelectedToDeleteAsStringArr()

    const getCreditActions = (charge: PaymentTransactionCharge) => {
      if (charge.credit_guid) {
        if (charge.is_historic && !charge.is_projected && charge.processed_prefix === "CR") return ""
        return "Credited"
      }

      if (charge.is_failed) return <TransactionFailed />
      if (charge.is_queued) return <TransactionQueued />
      if (charge.is_processing) return <TransactionInProgress />

      if (!isNil(selectableIds[charge.guid]) && canCreditTransaction && charge.is_creditable) {
        return (
          <RowSelectionCheckbox {...{ name: `credit[${charge.guid}]`, id: charge.guid, selectableIds, toggleId }} />
        )
      }

      return null
    }

    const destroyModal = () => setModal(null)

    const getTableHeadLabels = () => {
      const labels = [
        "Date",
        "Start",
        "Period",
        "Client",
        "Contract model",
        "Extra type",
        "Description",
        "Tax",
        "Processed",
        "£ Cost"
      ]
      return hasTransactionReference
        ? ["", ...labels, "Credit"]
        : ["", ...(canEditExtra ? [<DeleteIcon key={`delete-icon`} />] : []), ...labels, ""]
      // "" is for status column
    }

    const rows = filteredCharges.map((charge) => {
      const deleteCheckbox =
        !charge.readonly && !isUndefined(selectableDeleteIds[charge.guid]) && canEditExtra ? (
          <RowSelectionCheckbox
            {...{
              name: `itemDelete[${charge.guid}]`,
              id: charge.guid,
              selectableIds: selectableDeleteIds,
              toggleId: toggleDeleteId
            }}
          />
        ) : (
          ""
        )

      const editIcon =
        !charge.readonly && canEditExtra ? (
          <IconButton
            {...{
              "data-cy": testingId.paymentProcessingTransactionDetail.editButton,
              onClick: () =>
                setModal(
                  <PaymentExtrasModal
                    {...{
                      transaction,
                      period,
                      charge,
                      destroyModal,
                      contract
                    }}
                  />
                )
            }}
          >
            <EditIcon color="primary" />
          </IconButton>
        ) : (
          ""
        )

      const visitDate =
        charge.relation_guid &&
        charge.relation_type &&
        (charge.relation_type === RelationType.VISIT || charge.relation_type === RelationType.SHIFT) ? (
          <Link
            to={`/${charge.relation_type === RelationType.VISIT ? "visits" : "shifts"}/detail/${charge.relation_guid}`}
          >
            {dateTimeUtils.formatDate(charge.date)}
          </Link>
        ) : (
          dateTimeUtils.formatDate(charge.date)
        )

      const visitStart = charge.reference_owner_guid ? dateTimeUtils.formatTime(charge.date) : "-"

      const visitClient =
        charge.reference_owner_type !== "Unassigned" ? (
          <Link
            to={
              charge.reference_owner_type === "User"
                ? `/clients/${charge.reference_owner_guid}/profile/personal-details`
                : `/units/${charge.reference_owner_guid}/edit`
            }
          >
            {charge.reference_owner_name}
          </Link>
        ) : (
          charge.reference_owner_name || "-"
        )

      const item = [
        visitDate,
        visitStart,
        charge.is_projected ? "Current" : charge.is_historic ? "Previous" : "Current",
        visitClient,
        charge.reference_contract_name ? charge.reference_contract_name : "-",
        chargeTypes[charge.charge_type_alias],
        charge.description,
        charge.taxable ? "Y" : "-",
        charge.processed_at
          ? `${charge.processed_prefix ?? "P"} - ${dateTimeUtils.formatDate(charge.processed_at)}`
          : "-",
        <MoneyWrapper key={charge.guid} amount={charge.total} />
      ]

      return hasTransactionReference ? [...item, getCreditActions(charge)] : [deleteCheckbox, ...item, editIcon]
    })

    const handleRowClick = useProcessingExtraRowClick({ filteredCharges, modelType: ContractModelType.PAYMENT })

    const isRowClickable = useCallback(
      (index: number): boolean => {
        const { charge_type_alias, guid, transaction_item_guid, is_calculated, appointment_type } =
          filteredCharges[index]
        const isHoliday = charge_type_alias === "HOLIDAY"

        return isHoliday
          ? is_calculated && !isNil(guid)
          : !isNil(transaction_item_guid) &&
              (appointment_type === AppointmentType.SHIFT || appointment_type === AppointmentType.VISIT)
      },
      [filteredCharges]
    )

    const selectedChargesToDelete = useMemo(() => {
      return charges.filter((item) => selectedDeleteIds.includes(item.guid))
    }, [charges, selectedDeleteIds])

    const resetDictionaries = () => {
      resetAll()
      resetDeleteAll()
    }

    const deleteCharges = () => {
      setModal(
        <DeleteChargesModal
          {...{
            charges: selectedChargesToDelete,
            transaction,
            period,
            destroyModal,
            onOk: resetDictionaries
          }}
        />
      )
    }

    const creditCharges = () => {
      setModal(
        <CreditingModal
          {...{
            guids: selectedIds,
            onCancel: destroyModal,
            periodGuid: period.guid,
            transactionGuid: transaction.guid,
            credit_mode: TaskPreparationCreditMode.CHARGES,
            modelType: ContractModelType.PAYMENT,
            onOk: () => {
              resetDictionaries()
              destroyModal()
              onExtrasCredited()
            }
          }}
        />
      )
    }

    const selectAllCreditButtonDisabled = isEmpty(selectableIds)
    const creditButtonDisabled = !selectedIds.length
    const deleteButtonDisabled = !selectedDeleteIds.length

    return (
      <RequestLoaderWrapper>
        <Form onSubmit={() => undefined}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow data-cy={testingId.tableHeaderRow}>
                      {getTableHeadLabels().map((label, index) => (
                        <TableCell
                          key={index}
                          data-cy={testingId.tableHeaderCell}
                          sx={!index ? { padding: 0 } : undefined}
                        >
                          {label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.length > 0 ? (
                      rows.map((row, rowIdx) => (
                        <TableRow
                          key={rowIdx}
                          sx={isRowClickable(rowIdx) ? { cursor: "pointer" } : undefined}
                          {...{
                            hover: isRowClickable(rowIdx),
                            onClick: () => handleRowClick({ rowIdx }),
                            "data-cy": testingId.tableRow
                          }}
                        >
                          <TransactionItemStatus item={filteredCharges[rowIdx]} />
                          {row.map((value, cellIdx) => (
                            <TableCell key={cellIdx} data-cy={testingId.tableCell}>
                              {value}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell {...{ colSpan: getTableHeadLabels().length }}>
                          <EmptyData message="No visits found for filtered selection." />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {canCreditTransaction && (
                <Box {...{ p: 1, px: 0, display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    {...{
                      "data-cy": testingId.paymentProcessingTransactionDetail.selectAllChargesButton,
                      fullWidth: false,
                      variant: "text",
                      onClick: toggleAll,
                      disabled: selectAllCreditButtonDisabled
                    }}
                  >
                    (Un)Select all for crediting
                  </Button>
                </Box>
              )}
              <Box
                sx={(theme) => ({
                  borderColor: theme.palette.text.primary,
                  borderTop: "1px solid"
                })}
                {...{
                  p: 2,
                  px: 0,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Typography
                  {...{ variant: "h6", color: "primary" }}
                  data-cy={testingId.paymentProcessingTransactionDetail.listNumOfRecords}
                >
                  Showing {filteredCharges.length} out of {charges.length} records
                </Typography>
                <div>
                  {canCreditTransaction ? (
                    <Button
                      sx={(theme) => ({
                        marginLeft: theme.spacing(2)
                      })}
                      {...{
                        fullWidth: false,
                        color: "primary",
                        disabled: creditButtonDisabled,
                        onClick: creditCharges,
                        "data-cy": testingId.paymentProcessingTransactionDetail.creditButton
                      }}
                    >
                      Credit selected
                    </Button>
                  ) : null}
                  {canEditExtra ? (
                    <Button
                      sx={(theme) => ({
                        marginLeft: theme.spacing(2)
                      })}
                      {...{
                        fullWidth: false,
                        color: "primary",
                        disabled: deleteButtonDisabled,
                        onClick: deleteCharges,
                        "data-cy": testingId.paymentProcessingTransactionDetail.deleteButton
                      }}
                    >
                      Delete selected extras
                    </Button>
                  ) : null}
                </div>
              </Box>
            </form>
          )}
        </Form>
        {modal !== null && modal}
      </RequestLoaderWrapper>
    )
  }
)

TransactionDetailChargesTable.displayName = "TransactionDetailChargesTable"
