import { useCallback, useRef } from "react"
import Config from "lib/config"
import defaultIcon from "../../assets/images/maps/pin_red.svg"
import { MapPreviewMarker } from "./MapPreview"
import useGoogleMapsStore from "stores/useGoogleMapsStore"
import { useShallow } from "zustand/react/shallow"

type MapParams = {
  mapDomElement: HTMLDivElement
  initialZoom: number
  isDraggable: boolean
  hasZoomControl: boolean
  hasMapTypeControl: boolean
  mapId: string
  mapCenter?: google.maps.LatLngLiteral
  markers?: MapPreviewMarker[]
  onChangeMapCenter?: (center: google.maps.LatLngLiteral) => void
}

type UseGoogleMapsReturn = {
  createMap: (params: MapParams) => void
  addMarker: (params: MapPreviewMarker) => void
  map: google.maps.Map | undefined
  loadGoogleMaps: () => void
}

const getMarkerIcon = (icon: MapPreviewMarker["icon"]) => {
  const markerContent = document.createElement("img")
  markerContent.src = !icon ? defaultIcon : (icon.url ?? "")

  // if carerIcon do not translate (icon does not have offset)
  const isCarerIcon = icon?.url?.includes("pin_carer.svg")

  if (!icon || isCarerIcon) {
    return markerContent
  }

  markerContent.style.width = icon?.scaledSize ? `${icon.scaledSize[0]}px` : "auto"
  markerContent.style.height = icon?.scaledSize ? `${icon.scaledSize[1]}px` : "auto"
  markerContent.style.position = "absolute"

  const anchorX = icon?.anchor ? `${-icon.anchor[0]}px` : "0px"
  const anchorY = icon?.anchor ? `${-icon.anchor[1]}px` : "0px"
  markerContent.style.transform = `translate(${anchorX}, ${anchorY})`

  return markerContent
}

const useGoogleMaps = (): UseGoogleMapsReturn => {
  const apiKey = Config.getEnvVariable("APP_GOOGLE_API_KEY")
  const mapRef = useRef<google.maps.Map | undefined>(undefined)
  const { isMapLoaded, setLoadGoogleMaps } = useGoogleMapsStore(
    useShallow((state) => ({
      setLoadGoogleMaps: state.setLoadGoogleMaps,
      isMapLoaded: state.isLoaded
    }))
  )
  const loadGoogleMaps = useCallback(() => {
    if (document.getElementById("google-maps-api-script")) {
      return
    }
    window.initMap = () => setLoadGoogleMaps()
    const script = document.createElement("script")
    script.setAttribute("id", "google-maps-api-script")
    script.setAttribute("type", "text/javascript")
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=marker&callback=initMap`
    script.async = true
    document.body.appendChild(script)
  }, [apiKey, setLoadGoogleMaps])

  const addMarker = useCallback(
    ({ coordinates, icon, buffer }: MapPreviewMarker): google.maps.marker.AdvancedMarkerElement => {
      if (!mapRef.current) {
        throw new Error("Map is not initialized! Can't add marker")
      }

      const marker = new google.maps.marker.AdvancedMarkerElement({
        map: mapRef.current,
        position: coordinates,
        content: getMarkerIcon(icon)
      })

      if (buffer) {
        new google.maps.Circle({
          ...buffer,
          center: new google.maps.LatLng(coordinates.lat, coordinates.lng),
          map: mapRef.current
        })
      }
      return marker
    },
    []
  )

  const createMap = useCallback(
    ({
      mapDomElement,
      initialZoom,
      isDraggable,
      hasZoomControl,
      hasMapTypeControl,
      mapCenter,
      markers = [],
      mapId,
      onChangeMapCenter
    }: MapParams) => {
      if (!isMapLoaded) {
        throw new Error("Google Maps is not loaded! Can't create map")
      }
      mapRef.current = new google.maps.Map(mapDomElement, {
        zoom: initialZoom,
        gestureHandling: isDraggable ? "auto" : "none",
        clickableIcons: false,
        zoomControl: hasZoomControl,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        keyboardShortcuts: false,
        mapTypeControl: hasMapTypeControl,
        center: mapCenter,
        mapId
      })

      markers.forEach(addMarker)

      if (onChangeMapCenter) {
        mapRef.current.addListener("dragend", () => {
          const centerPosition = mapRef.current?.getCenter()?.toJSON()
          if (centerPosition) {
            onChangeMapCenter(centerPosition)
          }
        })
      }
    },
    [addMarker, isMapLoaded]
  )

  return { loadGoogleMaps, addMarker, createMap, map: mapRef.current }
}

export default useGoogleMaps
