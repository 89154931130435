import React, { memo, useState } from "react"
import { AppBar, Tabs, Tab, Box } from "@mui/material"
import { ContentContainer, Divider, Header } from "components"
import { FileManagementTab } from "./components/FileManagementTab"
import { queryStringToObject } from "lib/queryString"
import { ContractModelType } from "constants/modelTypes"
import testingId from "constants/testingId"
import { DateTime } from "luxon"
import { FileManagementFilter } from "./components/FileManagementFilter"
import { usePagination } from "components/handlers/usePagination"
import { useLocation } from "react-router-dom"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { BranchFeatures } from "lib/hooks/branchInfo/types"
import { InvoiceFilesFilterValues } from "./types"

const defaultFilterValues = {
  period_end_from: DateTime.local().minus({ weeks: 1 }).startOf("week").toISODate() as string,
  period_end_to: DateTime.local().minus({ weeks: 1 }).endOf("week").toISODate() as string
}

export const FileManagementTabs: React.FC = memo(() => {
  const location = useLocation()

  const qsObj = queryStringToObject<InvoiceFilesFilterValues>(location.search)
  const [tabValue, setTabValue] = useState<ContractModelType>(qsObj.modelType || ContractModelType.INVOICE)

  const [filter, setFilter] = useState<InvoiceFilesFilterValues>({
    ...defaultFilterValues,
    ...queryStringToObject<InvoiceFilesFilterValues>(location.search),
    modelType: tabValue
  } as InvoiceFilesFilterValues)

  const { pagination, setPagination, resetPagination } = usePagination({ initialPageSize: 500 })

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: ContractModelType) => {
    setTabValue(newValue)
    setFilter((values) => ({
      period_end_from: values.period_end_from,
      period_end_to: values.period_end_to,
      modelType: newValue
    }))
    resetPagination()
  }

  const { hasBranchPermission } = useBranchPermissions()

  const hasITrentFlag = hasBranchPermission(BranchFeatures.I_TRENT)
  const isInvoiceModel = tabValue === ContractModelType.INVOICE
  const showITrent = hasITrentFlag && !isInvoiceModel

  return (
    <ContentContainer>
      <Header data-cy={testingId.fileManagement.fileManagementTitle} title="File management" />
      <Box my={3}>
        <AppBar position="static" color="transparent">
          <Tabs value={tabValue} aria-label="Files" onChange={handleTabChange}>
            <Tab data-cy={testingId.fileManagement.invoicingTab} label="Invoicing" value={ContractModelType.INVOICE} />
            <Tab data-cy={testingId.fileManagement.paymentsTab} label="Payments" value={ContractModelType.PAYMENT} />
          </Tabs>
        </AppBar>
      </Box>
      <FileManagementFilter
        {...{
          modelType: tabValue,
          pagination,
          resetPagination,
          filter,
          setFilter,
          defaultFilterValues,
          showITrent
        }}
      />
      <Divider color="divider" />
      <FileManagementTab
        {...{ modelType: tabValue, pagination, setPagination, setFilter, showITrent }}
      ></FileManagementTab>
    </ContentContainer>
  )
})

FileManagementTabs.displayName = "FileManagementTabs"
