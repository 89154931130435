import React, { memo, useState, useEffect } from "react"
import { Typography } from "@mui/material"
import { ContentContainer, Paginator, EmptyData } from "components"
import { DateTimeFormat } from "lib/datetime"
import SickReportFilters from "./SickReportFilters"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { downloadFile } from "lib/utils"
import { useReportSickLeaveListQuery, useReportSickLeaveDownloadCsvFile } from "data/core/report/queries"
import { usePagination } from "components/handlers/usePagination"
import { useLocation } from "react-router-dom"
import { useUIStore } from "stores"

const ReportsList: React.FC = () => {
  const location = useLocation()
  const [isClicked, setIsClicked] = useState<boolean>(false)
  const showErrorMessage = useUIStore((state) => state.showErrorMessage)

  const { pagination, setPagination, resetPagination } = usePagination({ initialPageSize: 10 })

  const { data } = useReportSickLeaveListQuery({ query: location?.search, withSearch: true })

  const { data: fileData } = useReportSickLeaveDownloadCsvFile({ query: location?.search, isClicked })

  useEffect(() => {
    if (isClicked && fileData) {
      const { size, type } = fileData.data

      try {
        if (size && type) {
          downloadFile("SickReport.csv", "text/csv", fileData.data, "save")
        } else {
          throw new Error()
        }
      } catch (apiError: any) {
        showErrorMessage(
          "Something went wrong while downloading the file. Please try again later or contact user support.",
          { apiError }
        )
      }
    }

    setIsClicked(false)
  }, [fileData, isClicked, showErrorMessage])

  const downloadCsvFile = () => setIsClicked(true)

  return (
    <ContentContainer>
      <Typography variant="h4">Sick note reports</Typography>
      <SickReportFilters {...{ location, pagination, resetPagination, downloadCsvFile }} />
      {data && data.sick_leaves.length > 0 ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Carer</TableCell>
                <TableCell align="left">Branch</TableCell>
                <TableCell align="left">Start</TableCell>
                <TableCell align="left">End</TableCell>
                <TableCell align="left">Files</TableCell>
                <TableCell align="left">Comments</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.sick_leaves.map((leave) => (
                <TableRow key={leave.time_off_guid} hover>
                  <TableCell>{leave.carer.name}</TableCell>
                  <TableCell>{leave.branch.title}</TableCell>
                  <TableCell>{leave.start_date.toFormat(DateTimeFormat.DATE_TIME)}</TableCell>
                  <TableCell>{leave.end_date.toFormat(DateTimeFormat.DATE_TIME)}</TableCell>
                  <TableCell>
                    {leave.files.length > 0
                      ? `${leave.files.length}
                          ${leave.files.length === 1 ? "file" : "files"}  attached`
                      : "No files attached"}
                  </TableCell>
                  <TableCell>{leave.comments}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Paginator {...{ setPagination, count: data.count, initialPageSize: 10 }} />
        </>
      ) : (
        <EmptyData message="We did not find any matching sick reports" />
      )}
    </ContentContainer>
  )
}

export default memo(ReportsList)
