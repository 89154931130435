import { styled, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { StatusIcon } from "components/StatusIcon"
import { InvoiceFileItem } from "data/finance/invoiceFile/types"
import { ApiSubmission, ApiSubmissionUpload } from "data/payroll/types"
import { DateTimeFormat, formatDate } from "lib/datetime"
import React, { memo, ReactNode, useCallback, useState } from "react"
import { useDownloadFile } from "../helpers/downloadFiles"
import { FileManagementTablePayrollModal } from "./FileManagementTablePayrollErrorModal"
import { Link } from "components"

const StyedTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1)
}))

interface OwnProps {
  historyItem: ApiSubmission
  file: InvoiceFileItem
}

export const FileManagementTablePayrollTable: React.FC<OwnProps> = memo(({ historyItem, file }) => {
  FileManagementTablePayrollTable.displayName = "FileManagementTablePayrollTable"

  const downloadFile = useDownloadFile()

  const [modal, setModal] = useState<ReactNode>(null)

  const handleClick = useCallback(
    (upload: ApiSubmissionUpload) => () => {
      const destroyModal = () => {
        setModal(null)
      }

      setModal(
        <FileManagementTablePayrollModal
          destroyModal={destroyModal}
          file={file}
          initialUploadGuid={upload.guid}
          historyItem={historyItem}
        />
      )
    },
    [file, historyItem]
  )

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Interface</TableCell>
            <TableCell>Submission number</TableCell>
            <TableCell>Submitted to iTrent</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {historyItem.uploads.map((up) => {
            return (
              <TableRow key={up.guid}>
                <StyedTableCell>
                  {up.file_guid ? (
                    <Link
                      to="/#"
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault()
                        !!up.file_guid && downloadFile(up.file_guid)
                      }}
                    >
                      download file
                    </Link>
                  ) : (
                    "no file"
                  )}
                </StyedTableCell>
                <StyedTableCell>{up.submission_number}</StyedTableCell>
                <StyedTableCell>{formatDate(up.submitted_at, DateTimeFormat.DATE_TIME)}</StyedTableCell>
                <StyedTableCell>
                  <StatusIcon
                    status={up.status}
                    pendingName="Pending"
                    failedName="Failed"
                    successName="Complete"
                    errorTooltip="Click to view errors of this submission"
                    onErrorClick={handleClick(up)}
                  />
                </StyedTableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {modal}
    </>
  )
})
