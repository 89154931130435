import { FinanceApi } from "api"
import { AxiosResponse } from "axios"
import { setDayBorders } from "data/helpers"
import { queryStringToObject } from "lib/queryString"
import { useMutation, UseMutationResult } from "react-query"
import { TaskChargeReasonTypeAlias, TaskCreditTypeAlias, TaskPreparation } from "../task/types"
import { useLocation } from "react-router-dom"
import { useUIStore } from "stores"

export const useInvoiceBulkPostMutation = (): UseMutationResult<TaskPreparation, Error> => {
  const location = useLocation()

  return useMutation(async (): Promise<TaskPreparation> => {
    const newSearch = setDayBorders({ search: location.search, from: "start", to: "end" })
    const payload = queryStringToObject(newSearch)

    return FinanceApi.post("/finance/invoice-bulk-credit", payload)
  })
}

export const useInvoiceBulkPutMutation = (): UseMutationResult<
  AxiosResponse<any>,
  unknown,
  {
    taskGuid: string
    charge_reason_type_alias: TaskChargeReasonTypeAlias
    credit_type_alias: TaskCreditTypeAlias
    note: string
  },
  unknown
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)

  return useMutation(
    async ({
      taskGuid,
      charge_reason_type_alias,
      credit_type_alias,
      note
    }: {
      taskGuid: string
      charge_reason_type_alias: TaskChargeReasonTypeAlias
      credit_type_alias: TaskCreditTypeAlias
      note: string
    }) => {
      return FinanceApi.put(`/finance/invoice-bulk-credit/${taskGuid}`, {
        charge_reason_type_alias,
        credit_type_alias,
        note
      })
    },
    {
      onSuccess: () => {
        showSuccessMessage("Invoices successfully credited.")
      }
    }
  )
}
