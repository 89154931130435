import { Box, Typography } from "@mui/material"
import { ContentContainer } from "components"
import { WithPermission } from "components/Auth"
import { FormSectionHeader } from "components/Form"
import testingId from "constants/testingId"
import { useShiftDetailQuery } from "data/core/shift/queries"
import { VisitDetailVariation } from "pages/Visits/components/VisitDetailVariation"
import React, { memo } from "react"
import ShiftDetailCheckInOut from "./ShiftDetailCheckInOut"
import ShiftDetailInfo from "./ShiftDetailInfo"
import ShiftDetailVisitTable from "./ShiftDetailVisitTable"
import { useParams } from "react-router-dom"

const ShiftDetail: React.FC = () => {
  const { id = "" } = useParams()
  const { data: shift } = useShiftDetailQuery(id)

  if (!shift) return null

  return (
    <ContentContainer data-cy={testingId.shifts.detail.screen}>
      <Typography variant="h4">Shift Detail - {shift.title}</Typography>

      <Box my={3}>
        <ShiftDetailInfo shift={shift} />
        <ShiftDetailCheckInOut shift={shift} />
      </Box>

      <Box my={5}>
        <FormSectionHeader title="Visit list" />
        <ShiftDetailVisitTable visits={shift.visits} />
      </Box>

      <WithPermission permissions={["Finance.Appointment:Read"]}>
        <VisitDetailVariation {...{ appointmentGuid: shift.shift_guid, userAppointmentGuid: shift.carer_guid }} />
      </WithPermission>
    </ContentContainer>
  )
}

export default memo(ShiftDetail)
