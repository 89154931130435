import { styled, Tooltip } from "@mui/material"
import { Check, Close, HourglassEmpty } from "@mui/icons-material"
import React, { memo } from "react"

const PendingIcon = styled(HourglassEmpty)(({ theme }) => ({
  color: theme.palette.warning.main
}))

const SuccessIcon = styled(Check)(({ theme }) => ({
  color: theme.palette.success.main
}))

const FailedIcon = styled(Close)(({ theme }) => ({
  color: theme.palette.error.main
}))

interface OwnProps {
  status: string
  pendingName: string
  failedName: string
  successName: string
  errorTooltip?: string
  onErrorClick?: () => void
}

export const StatusIcon: React.FC<OwnProps> = memo(
  ({ status, pendingName, failedName, successName, errorTooltip, onErrorClick }) => {
    StatusIcon.displayName = "StatusIcon"

    if (status === successName) return <SuccessIcon />
    if (status === pendingName) return <PendingIcon />

    if (status === failedName) {
      const clickProps = { ...(onErrorClick ? { style: { cursor: "pointer" }, onClick: onErrorClick } : {}) }

      if (errorTooltip) {
        return (
          <Tooltip title={errorTooltip} arrow>
            <FailedIcon {...clickProps} />
          </Tooltip>
        )
      }
      return <FailedIcon {...clickProps} />
    }

    return <></>
  }
)
