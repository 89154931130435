import Auth from "lib/Auth"
import { useMutation } from "react-query"
import { identityApiInstance } from "api/identity"
import Config from "lib/config"

interface GoogleAuthResponse {
  refreshToken: string
  identityToken: string
}

interface GoogleAuthRequest {
  authorizationCode: string
  redirectUri: string
  clientId: string
}

interface DcpAuthResponse {
  accessToken: string
  idToken: string
  legacyAccessToken: string
}

const useMutationLoginSSO = () => {
  return useMutation({
    mutationKey: ["login-sso"],
    mutationFn: async (code: string) => {
      const { refreshToken, identityToken } = await identityApiInstance.post<GoogleAuthRequest, GoogleAuthResponse>(
        "/v1/auth/google",
        {
          authorizationCode: code,
          redirectUri: Config.getEnvVariable("APP_GOOGLE_IDENTITY_REDIRECT_URI"),
          clientId: Config.getEnvVariable("APP_GOOGLE_CLIENT_ID")
        }
      )
      Auth.setIdentityToken(identityToken)
      Auth.setRefreshToken(refreshToken)

      return identityApiInstance.get<void, DcpAuthResponse>("/v1/auth/dcp", {
        headers: { Authorization: `Bearer ${identityToken}` }
      })
    }
  })
}
export default useMutationLoginSSO
