import React, { memo, PropsWithChildren, useEffect, useMemo } from "react"
import { ModalWrapper } from "components"
import Typography from "@mui/material/Typography"
import { FormSectionHeader, Button } from "components/Form"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import { Form } from "react-final-form"
import { DateTime } from "luxon"
import { useInvoiceTaskPostMutation, useInvoiceTaskPutMutation } from "data/finance/invoiceTask/mutations"
import WarningMessage from "components/WarningMessage"
import { SummaryList } from "components/SummaryList"
import testingId from "constants/testingId"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { TaskPreparationMode } from "data/finance/task/types"
import { DateField } from "components/Form/DateField"
import { useUIStore } from "stores"

type Props = {
  guids: string[]
  destroyModal: () => void
  periodGuid?: string
}

const TransactionGroupInvoicingModal: React.FC<Props> = ({ guids, destroyModal, periodGuid }) => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)

  const { mutateAsync: postInvoiceTask, data: preparationData, isLoading } = useInvoiceTaskPostMutation() // prepare
  const { mutateAsync: putInvoiceTask, isSuccess, isError } = useInvoiceTaskPutMutation() // complete

  useEffect(() => {
    postInvoiceTask({ guids, mode: TaskPreparationMode.COMPLETION, period_guid: periodGuid })
  }, [guids, postInvoiceTask, periodGuid])

  const isProcessable = useMemo(() => !!preparationData?.processable, [preparationData])
  const hasInvoices = useMemo(() => !!preparationData?.contracts?.length, [preparationData])

  useEffect(() => {
    if (isSuccess) {
      showSuccessMessage("Invoice generated!")
      destroyModal()
    }
  }, [isSuccess, showSuccessMessage, destroyModal])

  const onSubmit = (values: { tax_point_date: DateTime }) => {
    if (preparationData?.task_guid) {
      putInvoiceTask({
        ...values,
        task_guid: preparationData.task_guid,
        mode: TaskPreparationMode.COMPLETION,
        is_draft: false
      })
    }
  }

  const ModalButtons: React.FC<PropsWithChildren<{ m?: number }>> = ({ m = 1, children }) => (
    <Box m={m} mx={0} display="flex" justifyContent="flex-end">
      <Button
        data-cy={testingId.transactionGroupInvoicingModal.cancelButton}
        fullWidth={false}
        variant="text"
        onClick={destroyModal}
      >
        Cancel
      </Button>
      {children}
    </Box>
  )

  return (
    <ModalWrapper
      data-cy={testingId.transactionGroupInvoicingModal.modal}
      title={isSuccess ? "Invoicing done" : "Invoice settings"}
      destroyModal={destroyModal}
      width={700}
    >
      <RequestLoaderWrapper>
        {!isLoading ? (
          <>
            <FormSectionHeader title="Selection summary" />
            {hasInvoices ? (
              <SummaryList
                {...{
                  items: preparationData?.contracts || [],
                  headers: ["Invoice Model", "Clients", "Visits", "Total value"],
                  totalLabel: "Total Clients:"
                }}
              />
            ) : (
              "No invoices to process."
            )}
          </>
        ) : null}

        {hasInvoices && !isError ? (
          <Form onSubmit={onSubmit} initialValues={{ tax_point_date: DateTime.local() }}>
            {({ handleSubmit }) =>
              isProcessable ? (
                <>
                  <FormSectionHeader title="Invoice options" />
                  <form onSubmit={handleSubmit}>
                    <Box m={1} mx={0}>
                      <DateField name="tax_point_date" label="Date" />
                    </Box>
                    <Divider sx={{ background: "none" }} color="divider" />
                    <ModalButtons m={3}>
                      <Button
                        data-cy={testingId.transactionGroupInvoicingModal.processInvoicesButton}
                        fullWidth={false}
                        color="primary"
                        type="submit"
                        sx={(theme) => ({
                          marginLeft: theme.spacing(3)
                        })}
                      >
                        Process invoices
                      </Button>
                    </ModalButtons>
                  </form>
                </>
              ) : (
                <>
                  <Box m={1} mx={0}>
                    <WarningMessage>Contracts can not be invoiced because all contracts are empty.</WarningMessage>
                  </Box>
                  <ModalButtons m={3} />
                </>
              )
            }
          </Form>
        ) : null}
        {hasInvoices && isError ? (
          <>
            <Box m={3} mx={0} display="flex" justifyContent="center" flexDirection="column">
              <Typography variant="h5">Something went wrong!</Typography>
              <br />
              <Typography paragraph>
                We apologize, we were not able to complete invoice processing for selected transactions, please try
                again later. If the problem persists, please contact the user support.
              </Typography>
            </Box>
            <Divider color="divider" />
            <ModalButtons />
          </>
        ) : null}
        {!hasInvoices ? <ModalButtons /> : null}
      </RequestLoaderWrapper>
    </ModalWrapper>
  )
}

export default memo(TransactionGroupInvoicingModal)
