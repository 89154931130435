import { showFormattedDate } from "lib/datetime"
import { ValidationErrorsDefined } from "lib/types"
import {
  ApiShiftCategoryContractRequest,
  ApiShiftCategoryResponse,
  ShiftCategoryContractResponse
} from "types/Shifts/ShiftCategories/types"
import { validateContract } from "./contractValidations"
import { ContractModelType } from "constants/modelTypes"
import { FormStage } from "constants/form"
import { isEmpty } from "ramda"
import { getOtherUserContracts } from "helpers/contractHelpers"
import { ContractModel } from "data/finance/contractModel/types"

interface ShiftCategoryContractValues extends ApiShiftCategoryContractRequest {
  no_end_date?: boolean
}

export const validateShiftCategoryContract =
  ({
    contracts = [],
    shiftCategory,
    invoiceModel,
    formStage
  }: {
    contracts?: ShiftCategoryContractResponse[]
    shiftCategory: ApiShiftCategoryResponse
    invoiceModel?: ContractModel
    formStage: FormStage
  }) =>
  (values: ShiftCategoryContractValues): ValidationErrorsDefined => {
    let errors: ValidationErrorsDefined = {}

    // do not validate step one
    if (formStage === FormStage.CREATE) return errors

    // validate towards shift category
    if (values.start && values.start < shiftCategory.start) {
      errors.start = `Contract can not start sooner then shift category (${showFormattedDate(shiftCategory.start)})`
    }
    if (shiftCategory.end) {
      if (values.no_end_date) {
        errors.end = `Contract must end before shift category (${showFormattedDate(shiftCategory.end)})`
      }
      if (!values.no_end_date && values.end && values.end > shiftCategory.end) {
        errors.end = `Contract can not end later then shift category (${showFormattedDate(shiftCategory.start)})`
      }
    }

    if (!isEmpty(errors)) return errors

    // validate towards invoice model and other contracts
    if (invoiceModel && values.start) {
      const otherContracts = getOtherUserContracts(invoiceModel.guid, contracts)

      errors = validateContract({
        modelType: ContractModelType.INVOICE,
        contractModel: invoiceModel,
        values,
        existingContracts: otherContracts
      })
    }

    return errors
  }
