import { FileApi } from "api"
import { enabledByMandatoryUrlParams } from "data/helpers"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { ApiFilePresigned } from "./types"

export const queryKeys = {
  getFileKey: (props: { fileGuid?: string }): QueryKey => ["file", props]
}

export const useFileQuery = ({
  fileGuid,
  onSuccess,
  onSettled
}: {
  fileGuid?: string
  onSuccess?: (response: ApiFilePresigned) => void
  onSettled?: () => void
}): UseQueryResult<ApiFilePresigned> => {
  return useQuery<ApiFilePresigned>({
    queryKey: queryKeys.getFileKey({ fileGuid }),
    queryFn: async () => FileApi.get(`/v1/files/${fileGuid}`),
    ...enabledByMandatoryUrlParams([fileGuid]),
    onSuccess,
    onSettled
  })
}
