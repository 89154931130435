import React, { memo } from "react"
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material"
import { Button, Checkbox, FormSectionHeader, TextField } from "components/Form"
import { Field, FormSpy, FormSpyRenderProps } from "react-final-form"
import DeleteIcon from "@mui/icons-material/Delete"
import Box from "@mui/material/Box"
import { FieldArray } from "react-final-form-arrays"
import { grey } from "@mui/material/colors"
import { CarerContractFormValues } from "../types"
import Auth from "lib/Auth"
import { Permission } from "constants/permission"
import { DateField } from "components/Form/DateField"
import { StatusEnum } from "data/commonTypes"

const clientRatesHeaderData = ["Start date", "Ends before", "Rate", "Status"]

export const CarerContractDetailStepTwoRate: React.FC = memo(() => {
  const canEdit = Auth.hasPermission([Permission.FINANCE_USER_CONTRACT_RATE_EDIT])

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }: FormSpyRenderProps<CarerContractFormValues, {}>) => (
        <FieldArray name="rates">
          {({ fields }) => {
            if (!fields.length && !canEdit) return null
            return (
              <>
                <FormSectionHeader title="Base Rates" />
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {canEdit && (
                        <TableCell style={{ textAlign: "left" }}>
                          <DeleteIcon />
                        </TableCell>
                      )}
                      {clientRatesHeaderData.map((label, index) => (
                        <TableCell key={index}>{label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.map((name, index) => {
                      const field = fields.value[index]
                      const canBeDeleted = field.status === StatusEnum.FUTURE || !field.guid
                      const isToBeDeleted = field.delete
                      const isDisabled = isToBeDeleted || !canEdit

                      return (
                        <TableRow
                          key={index}
                          style={{
                            opacity: isToBeDeleted ? 0.4 : 1,
                            backgroundColor: isToBeDeleted ? grey[100] : "transparent"
                          }}
                        >
                          {canEdit && (
                            <TableCell>
                              {canBeDeleted ? (
                                <Field<boolean> type="checkbox" label="" component={Checkbox} name={`${name}.delete`} />
                              ) : null}
                            </TableCell>
                          )}
                          <TableCell>
                            <DateField
                              name={`${name}.start`}
                              label="Start date *"
                              minDate={values.start ?? undefined}
                              maxDate={values.current_role || !values.end ? undefined : values.end.minus({ days: 1 })}
                              disabled={isDisabled}
                            />
                          </TableCell>
                          <TableCell>
                            <DateField
                              name={`${name}.end`}
                              label="Ends before"
                              minDate={values.start ? values.start.plus({ days: 1 }) : undefined}
                              maxDate={values.current_role || !values.end ? undefined : values.end}
                              disabled={isDisabled}
                            />
                          </TableCell>
                          <TableCell>
                            <Field name={`${name}.rate`} label="Rate *" component={TextField} disabled={isDisabled} />
                          </TableCell>
                          <TableCell>{field.status}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                {canEdit && (
                  <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                    <Button fullWidth={false} onClick={() => fields.push({})}>
                      Add new row
                    </Button>
                  </Box>
                )}
              </>
            )
          }}
        </FieldArray>
      )}
    </FormSpy>
  )
})

CarerContractDetailStepTwoRate.displayName = "CarerContractDetailStepTwoRate"
