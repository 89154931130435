import React from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { Field, FormSpy, FormSpyRenderProps } from "react-final-form"
import { Button, TextField } from "components/Form"
import validators, { composeValidators, NUMBER_OF_WEEKS_IN_A_YEAR } from "lib/validators"
import testingId from "constants/testingId"
import { FormApi, ValidationErrors } from "final-form"
import { getPaymentCalendarPeriodDates } from "../../helpers/getPaymentContractCalendarPeriodDates"
import { SetPagination } from "lib/types"
import { AddPaymentCalendarRequest, CalendarPeriod } from "data/finance/contractModel/types"

interface OwnProps<T> {
  fields: { insert: (index: number, value: T) => void }
  setPagination: SetPagination
}

const AddPaymentCalendarPeriod: React.FC<OwnProps<CalendarPeriod>> = ({ fields, setPagination }) => {
  const handleClick = ({
    values,
    form
  }: {
    values: AddPaymentCalendarRequest
    form: FormApi<AddPaymentCalendarRequest, Partial<AddPaymentCalendarRequest>>
  }) => {
    const newPeriods = getPaymentCalendarPeriodDates(values)

    // add new periods to the top
    newPeriods.forEach((newPeriod) => {
      fields.insert(0, newPeriod)
    })

    form.change("periodCount", 0)
    form.change("processDays", undefined)

    setPagination((prev) => ({ ...prev, page: "1" }))
  }

  const isDisabled = ({ values, errors }: { values: AddPaymentCalendarRequest; errors: ValidationErrors }) =>
    !!(errors?.periodCount || +values.periodCount === 0 || errors?.processDays)

  return (
    <Box m={3} mx={0}>
      <FormSpy subscription={{ values: true, errors: true }}>
        {({ values, form, errors }: FormSpyRenderProps<AddPaymentCalendarRequest>) => (
          <Grid container spacing={1}>
            <Grid item md={1}>
              <Field<string>
                label="Periods"
                component={TextField}
                name="periodCount"
                InputLabelProps={{
                  shrink: true
                }}
                validate={composeValidators([
                  validators.mustBeNumber,
                  validators.minValue(0),
                  validators.precision(0),
                  validators.maxValue(NUMBER_OF_WEEKS_IN_A_YEAR)
                ])}
              />
            </Grid>
            <Grid item md={3}>
              <Field<string>
                label="Payment date offset days"
                component={TextField}
                name="processDays"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item md={1}>
              <Button
                style={{ marginTop: "22px" }}
                fullWidth={true}
                onClick={() => handleClick({ values, form })}
                data-cy={testingId.contractModel.calendar.detail.periodAddButton}
                disabled={isDisabled({ values, errors })}
              >
                ADD
              </Button>
            </Grid>
          </Grid>
        )}
      </FormSpy>
    </Box>
  )
}

export default React.memo(AddPaymentCalendarPeriod)
