import { PaymentTransactionItem } from "data/finance/paymentProcessing/types"
import { PaymentTransactionItemsFilter } from "../components/TransactionDetailItemsFilter"

export const filterPaymentTransactionVisits = ({
  visits,
  filter
}: {
  visits: PaymentTransactionItem[]
  filter: PaymentTransactionItemsFilter
}): PaymentTransactionItem[] => {
  if (!filter) return visits

  const { reference_owner_name, service_type_guid, visit_dates, visit_status } = filter
  let result = visits

  if (reference_owner_name) {
    result = result.filter(
      (item) =>
        item.reference_owner_name &&
        item.reference_owner_name.toLowerCase().indexOf(reference_owner_name.toLowerCase()) !== -1
    )
  }

  if (service_type_guid?.length) {
    result = result.filter((item) => item.service_type_guid && service_type_guid.includes(item.service_type_guid))
  }

  if (typeof visit_dates === "boolean") {
    result = result.filter((item) => item.is_historic === visit_dates)
  }

  if (typeof visit_status === "boolean") {
    result = result.filter((item) => item.confirmed === visit_status)
  }

  return result
}
