import { ContractModelType } from "constants/modelTypes"
import { useContractModelServiceBandPatchMutation, useContractModelServicePutMutation } from "../mutations"
import { ContractModelServiceBand, ContractModelServiceRequestWithBands, ServiceBandRoundingTypeAlias } from "../types"
import { useApiMessage } from "stores"

export const useContractModelServiceUpdateHandler = ({
  modelType,
  isInvoicePaymentService,
  contractModelGuid
}: {
  modelType: ContractModelType
  isInvoicePaymentService?: boolean
  contractModelGuid: string
}): (({
  serviceGuid,
  payload
}: {
  serviceGuid: string
  payload: ContractModelServiceRequestWithBands
}) => Promise<void>) => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const { mutateAsync: updateService } = useContractModelServicePutMutation({
    modelType,
    isInvoicePaymentService,
    contractModelGuid
  })

  const { mutateAsync: updateBands } = useContractModelServiceBandPatchMutation({
    modelType,
    contractModelGuid
  })

  return async ({ serviceGuid, payload }: { serviceGuid: string; payload: ContractModelServiceRequestWithBands }) => {
    const { bands, ...service } = payload

    const filteredBands = (bands || [])
      .filter((item) => !item.delete)
      .map((item) => {
        const typeNone = !item.rounding_type_alias || item.rounding_type_alias === ServiceBandRoundingTypeAlias.NONE

        return {
          ...item,
          ...(typeNone ? { rounding_type_alias: ServiceBandRoundingTypeAlias.NONE, rounding_minutes: null } : {})
        }
      }) as ContractModelServiceBand[]

    try {
      await updateService({ serviceGuid, service })
      await updateBands({ serviceGuid, bands: filteredBands })

      showSuccessMessage("Service and it's bands have been updated successfully.")
    } catch (apiError: any) {
      showErrorMessage("Something went wrong, please try again.", { apiError })
    }
  }
}
