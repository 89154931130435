import React, { useCallback, useMemo, useRef, useState } from "react"
import { AppContext } from "pubnub"
import { IconButton, styled, SxProps, TextField } from "@mui/material"
import { unstable_styleFunctionSx, StyleFunction } from "@mui/system"
import { Send } from "@mui/icons-material"
import testingId from "constants/testingId"
import Auth from "lib/Auth"
import { MESSAGE_INPUT_PLACEHOLDER } from "./constants"
import { CustomChannelMetadata, MessageUserMetadata } from "./types"
import { useSendMessage } from "./mutations"

const Form = styled("form")<{ sx?: SxProps }>({
  ...(unstable_styleFunctionSx as StyleFunction<{ sx?: SxProps }>)
})

interface Props {
  currentChannel: AppContext.ChannelMetadataObject<CustomChannelMetadata>
}

const ChatRoomInput = ({ currentChannel }: Props) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const formRef = useRef<HTMLFormElement>(null)
  const [message, setMessage] = useState<string>("")
  const { mutate: sendMessage, isLoading } = useSendMessage(currentChannel.id)

  const user = Auth.getUserInfo()

  const userMetadata: MessageUserMetadata = useMemo(
    () => ({
      user_id: user?.id ?? "",
      profile_url: "",
      name: user?.username ?? ""
    }),
    [user]
  )

  const onSubmit = useCallback(() => {
    if (!message) return
    sendMessage(
      { text: message, userMetadata },
      {
        onSuccess: () => {
          setMessage("")
          requestAnimationFrame(() => inputRef.current?.focus())
        }
      }
    )
  }, [message, userMetadata, sendMessage])

  return (
    <Form
      sx={{
        display: "flex",
        boxShadow: 6,
        py: 2,
        px: 11,
        gap: 2,
        zIndex: 1
      }}
      ref={formRef}
      data-cy={testingId.messages.messageInputPanel}
      onSubmit={(event) => {
        event.preventDefault()
        onSubmit()
      }}
    >
      <TextField
        sx={{
          marginLeft: 2,
          border: "none",
          flex: 1,
          resize: "none",
          overflow: "hidden",
          "&:active": {
            outline: "none"
          },
          "&:focus": {
            outline: "none"
          },
          "&:disabled": {
            color: "common.disabled"
          }
        }}
        multiline
        autoFocus
        onKeyDown={(event) => {
          if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault()
            onSubmit()
          }
        }}
        onChange={(event) => setMessage(event.target.value)}
        placeholder={MESSAGE_INPUT_PLACEHOLDER}
        inputRef={inputRef}
        value={message}
        rows={1}
        disabled={isLoading}
      />
      <IconButton
        sx={{
          color: "primary.main",
          ...(isLoading && {
            cursor: "not-allowed",
            opacity: "0.3"
          })
        }}
        disabled={isLoading || !message}
        type="submit"
      >
        <Send />
      </IconButton>
    </Form>
  )
}

export default ChatRoomInput
