import { GENERAL_ERROR_MESSAGE } from "api/instance"
import { useInvoiceTransactionChargeDeleteMutation } from "data/finance/invoiceProcessing/mutations"
import { queryKeys } from "data/finance/invoiceProcessing/queries"
import { InvoiceTransactionCharge } from "data/finance/invoiceProcessing/types"
import { useCallback } from "react"
import { useQueryClient } from "react-query"
import { useApiMessage } from "stores"

interface DeleteInvoiceTransactionChargesProps {
  transactionGuid: string
  periodGuid: string
  charges: InvoiceTransactionCharge[]
  onOk: () => void
}

export const useDeleteInvoiceTransactionCharges = ({
  periodGuid,
  transactionGuid,
  charges,
  onOk
}: DeleteInvoiceTransactionChargesProps): (() => Promise<void>) => {
  const queryCache = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const { mutateAsync: deleteCharge } = useInvoiceTransactionChargeDeleteMutation()

  return useCallback(async () => {
    if (!charges) return

    try {
      await Promise.all(
        charges.map((charge) => {
          return deleteCharge({ transactionGuid, periodGuid, chargeGuid: charge.guid })
        })
      )

      showSuccessMessage("Extras successfully deleted.")
      onOk()
    } catch (apiError: any) {
      showErrorMessage(GENERAL_ERROR_MESSAGE, { apiError })
    } finally {
      queryCache.invalidateQueries(
        queryKeys.getInvoiceProcessingTransactionChargeListKey({
          transactionGuid,
          periodGuid
        })
      )
    }
  }, [periodGuid, transactionGuid, charges, deleteCharge, showSuccessMessage, onOk, queryCache, showErrorMessage])
}
