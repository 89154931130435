import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { FormSpyCustom, Select, AutocompleteMui } from "components/Form"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"
import { DateTime } from "luxon"
import { Pagination } from "lib/types"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { ClearFilterButton } from "components/ClearFilterButton"
import { FilterValues, formatFilterValues } from "lib/helpers/formatFilterValues"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"
import { TransactionGroupFilterRunSelect } from "pages/Finance/components/TransactionGroupFilterRunSelect"
import { DateField } from "components/Form/DateField"
import { FrequencySelectField } from "components/Form/FrequencySelectField"
import { useSchema } from "data/schema/useSchema"
import { removeUnassigned } from "data/schema/helpers"
import { PaymentTransactionGroupFilter } from "../types"

export const defaultFilterValues = {
  cycle_end_from: DateTime.local().minus({ weeks: 1 }).startOf("week").toISODate() as string,
  cycle_end_to: DateTime.local().minus({ weeks: 1 }).endOf("week").toISODate() as string
}

interface OwnProps {
  pagination: Pagination
  resetPagination: () => void
  filter: PaymentTransactionGroupFilter
  setFilter: Dispatch<SetStateAction<PaymentTransactionGroupFilter>>
}

export const TransactionGroupFilter: React.FC<OwnProps> = memo(({ pagination, resetPagination, filter, setFilter }) => {
  const { PaymentContract } = useSchema(["PaymentContract"])
  const contractOptions = removeUnassigned(PaymentContract)

  const debouncedOnSubmit = useDebouncedCallback((values: FilterValues<PaymentTransactionGroupFilter>) => {
    const formattedValues = formatFilterValues<PaymentTransactionGroupFilter>({
      values,
      startName: "cycle_end_from",
      endName: "cycle_end_to"
    })

    setFilter(formattedValues)
    resetPagination()
  }, 500)

  const clearFilter = () => {
    setFilter(defaultFilterValues)
    resetPagination()
  }

  useFilterLocationChange({ filter, pagination })

  return (
    <Form
      onSubmit={debouncedOnSubmit}
      initialValues={filter}
      validate={validateFilterPeriodDates({ start: "cycle_end_from", end: "cycle_end_to" })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpyCustom {...{ handleSubmit }} />
          <Grid container alignItems="center" spacing={1}>
            <Grid item md={3}>
              <DateField name="cycle_end_from" label="Cycle end dates from" required />
            </Grid>
            <Grid item md={3}>
              <DateField name="cycle_end_to" label="To" required />
            </Grid>
            <Grid item md={3}>
              <FrequencySelectField label="Cycle Type" multiselect />
            </Grid>
            <Grid item md={3}>
              <Field
                name="status"
                label="Payment Status"
                component={Select}
                options={[
                  { title: "Any", value: "" },
                  { title: "Pending", value: "Pending" },
                  { title: "Processed", value: "Processed" }
                ]}
              />
            </Grid>
            <TransactionGroupFilterRunSelect isInvoice={false} />
            <Grid item md={4}>
              <Field
                name="models"
                label="Pay Model"
                component={AutocompleteMui}
                options={contractOptions}
                multiselect
              />
            </Grid>
            <Grid item md={4}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  )
})

TransactionGroupFilter.displayName = "TransactionGroupFilter"
