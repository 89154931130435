import { TableBody, TableCell, TableRow } from "@mui/material"
import { EmptyData } from "components"
import React, { memo } from "react"
import testingId from "constants/testingId"
import { SelectableIdsDictionaryModel } from "lib/hooks"
import { mapInvoiceTransactionPeriod } from "../helpers/mapInvoiceTransactionPeriod"
import { useLocation, useNavigate } from "react-router-dom"
import { SelectableTableRow } from "components/SelectableTableRow"
import { InvoiceTransactionGroup } from "data/finance/invoiceProcessing/types"

const isRowReady = (period: InvoiceTransactionGroup): boolean =>
  !period.is_alerting && period.confirmed_percentage === 100

interface OwnProps {
  transactionGroups: InvoiceTransactionGroup[]
  toggleId: (id: string) => void
  selectableIds: SelectableIdsDictionaryModel
  hasCompletedTransactionGroups: boolean
  hasAlertingTransactionGroup: boolean
  hasRewindPermission: boolean
  refetch: () => void
  selectedRows: string[]
}

const TransactionGroupTableBody: React.FC<OwnProps> = ({
  transactionGroups,
  toggleId,
  selectableIds,
  hasCompletedTransactionGroups,
  hasAlertingTransactionGroup,
  hasRewindPermission,
  refetch,
  selectedRows
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <TableBody>
      {transactionGroups.length > 0 ? (
        transactionGroups.map((period, index) => (
          <SelectableTableRow
            clickable
            isSelected={selectedRows.includes(period.guid)}
            isReady={isRowReady(period)}
            hover
            key={index}
            onClick={() =>
              navigate(`${location.pathname}/${period.calendar_guid}/${period.guid}/${period.contract_guid}`)
            }
          >
            {mapInvoiceTransactionPeriod({
              period,
              selectableIds,
              toggleId,
              sx: {
                display: "flex",
                alignItems: "center",
                "&>label": { marginRight: 0 }
              },
              hasCompletedTransactionGroups,
              hasAlertingTransactionGroup,
              hasRewindPermission,
              refetch
            }).map((value, cellIndex) => (
              <TableCell key={cellIndex} data-cy={testingId.invoiceProcessingTransactionGroupList.cell}>
                {value}
              </TableCell>
            ))}
          </SelectableTableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={12}>
            <EmptyData message="We did not find any matching contract models for invoicing for selected dates and filter values." />
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )
}

export default memo(TransactionGroupTableBody)
