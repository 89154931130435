import { Grid } from "@mui/material"
import { DatePicker } from "components/Form"
import { FormApi } from "final-form"
import { getByGuid } from "helpers/getByGuid"
import { usePrevious } from "lib/hooks"
import validators, { composeValidators } from "lib/validators"
import { DateTime } from "luxon"
import React, { memo, useEffect } from "react"
import { Field } from "react-final-form"
import { ContractModel } from "data/finance/contractModel/types"
import { RateUpliftForm, RateUpliftFormModel, RateUpliftFormService } from "../../types"

interface OwnProps {
  form: FormApi<RateUpliftForm, Partial<RateUpliftForm>>
  values: RateUpliftForm
  disabled: boolean
  allContracts: ContractModel[]
}

// end does not concern us because models & services with end can not be picked
const remainsPicked = (wasPicked: boolean, upliftStart: DateTime, start: DateTime) => {
  if (!wasPicked) return false
  if (start.toMillis() >= upliftStart.toMillis()) return false
  return true
}

export const RateUpliftOneStartDate: React.FC<OwnProps> = memo(({ form, values, disabled, allContracts }) => {
  RateUpliftOneStartDate.displayName = "RateUpliftOneStartDate"

  const currentStart = usePrevious(values.start_date)

  //check picked models and services
  useEffect(() => {
    if (values.start_date?.isValid && currentStart && values.start_date.toMillis() !== currentStart.toMillis()) {
      const valuesModels = values.models || {}

      const reducedFormModels = Object.keys(valuesModels).reduce(
        (res: Record<string, RateUpliftFormModel>, modelGuid: string) => {
          const formModel = values.models[modelGuid]
          const model = getByGuid({ items: allContracts, guid: modelGuid })

          if (!model) return res

          const formModelServices = formModel.services || {}
          const reducedFormServices = Object.keys(formModelServices).reduce(
            (result: Record<string, RateUpliftFormService>, serviceGuid: string) => {
              const formService = formModelServices[serviceGuid]
              const service = getByGuid({ items: formModel.serviceItems, guid: serviceGuid })

              if (!service) return result
              return {
                ...result,
                [serviceGuid]: {
                  ...formService,
                  picked: remainsPicked(formService.picked, values.start_date, service.start)
                }
              }
            },
            formModelServices
          )

          return {
            ...res,
            [modelGuid]: {
              ...formModel,
              services: reducedFormServices,
              picked: remainsPicked(formModel.picked, values.start_date, model.start)
            }
          }
        },
        valuesModels
      )

      form.change("models", reducedFormModels)
    }
  }, [form, currentStart, values, allContracts])

  return (
    <Grid container spacing={0}>
      <Grid item md={3}>
        <Field<DateTime>
          {...{
            withTime: false,
            name: "start_date",
            component: DatePicker,
            label: "Start Date (required) *",
            validate: composeValidators([validators.required, validators.validDate]),
            disabled
          }}
        />
      </Grid>
    </Grid>
  )
})
