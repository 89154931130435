import { DateTimeFormat } from "lib/datetime"
import { BasicPeriod, FormValidation } from "lib/types"
import { ExtrasFormValues } from "../ExtrasModal"
import { CalendarPeriod } from "data/finance/contractModel/types"

export const validateExtrasModalForm =
  ({
    period,
    contract,
    additionalValidation
  }: {
    period: CalendarPeriod
    contract?: BasicPeriod
    additionalValidation?: (values: ExtrasFormValues) => FormValidation
  }) =>
  (values: ExtrasFormValues): FormValidation => {
    const chargeDate = values.charge_date

    if (!chargeDate) return undefined

    const periodStart = period.start
    const periodEnd = period.end

    if (chargeDate < periodStart || chargeDate > periodEnd) {
      return {
        charge_date: `Charge date must be within the transaction period (${periodStart.toFormat(
          DateTimeFormat.DATE
        )} - ${periodEnd.toFormat(DateTimeFormat.DATE)})`
      }
    }

    if (!contract) return

    const contractStart = contract.start
    const contractEnd = contract.end

    if (chargeDate < contractStart || (contractEnd && chargeDate >= contractEnd)) {
      return {
        charge_date: `Charge date must be within the contract period (${contractStart.toFormat(
          DateTimeFormat.DATE
        )} - ${contractEnd ? contractEnd.minus({ days: 1 }).toFormat(DateTimeFormat.DATE) : "unset"})`
      }
    }

    if (additionalValidation) {
      return additionalValidation(values)
    }

    return undefined
  }
