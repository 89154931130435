import React, { useMemo, useState } from "react"
import { useShallow } from "zustand/react/shallow"
import { Controller, useForm } from "react-hook-form"
import { useQueryClient } from "react-query"
import { isEmpty } from "ramda"
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material"
import { Send } from "@mui/icons-material"
import { Button, Autocomplete } from "components/Form"
import { mapReducedDataForAutocomplete, useLoadAutocompleteOptions } from "lib/hooks/useLoadAutocompleteOptions"
import { ModalWrapper } from "components"
import { useApiMessage, useCoreSchemasStore } from "stores"
import {
  CANCEL_LABEL,
  SEND_LABEL,
  SEND_NEW_MESSAGE,
  MESSAGE_LABEL,
  TYPE_MESSAGE_HERE_LABEL,
  TYPE_THE_CARER_NAME_LABEL,
  CARERS_LABEL,
  ALL_CARERS_LABEL,
  REGIONS_LABEL,
  SELECT_REGIONS,
  SELECT_CARERS,
  SEND_TO_LABEL
} from "./constants"
import { useSendBulkMessage } from "./mutations"
import { useChannelContactsListQuery } from "./queries"
import { useModalsStore } from "./useModalsStore"
import { FormValues, SendBulkMessageRequest } from "./types"
import { SchemaFieldOption } from "lib/types"
import { useChatStore } from "./useChatStore"

const SendToValues = {
  CARERS: "carers",
  REGIONS: "regions",
  ALL_CARERS: "allCarers"
}

type RegionsByValue = {
  [key: number | string]: SchemaFieldOption
}

const ChatModal = () => {
  const { setIsNewChannelCreateModalOpen, isNewChannelCreateModalOpen } = useModalsStore(useShallow((state) => state))
  const queryCache = useQueryClient()
  const { showSuccessMessage } = useApiMessage()

  const channelNames = useChatStore(({ channelNames }) => channelNames)
  const { data: contacts } = useChannelContactsListQuery()
  const { mutate: sendBulkMessage } = useSendBulkMessage()
  const schema = useCoreSchemasStore(
    useShallow(
      ({
        schema: {
          models: { ApiUserCarer }
        }
      }) => ApiUserCarer
    )
  )

  const [disableCreateChannel, setDisableCreateChannel] = useState<boolean>(false)
  const [sendTo, setSendTo] = useState(SendToValues.CARERS)
  const sendToAllSelected = useMemo(() => sendTo === SendToValues.ALL_CARERS, [sendTo])
  const sendToCarersSelected = useMemo(() => sendTo === SendToValues.CARERS, [sendTo])
  const sendToRegionsSelected = useMemo(() => sendTo === SendToValues.REGIONS, [sendTo])
  const [selectedRegions, setSelectedRegions] = React.useState<number[]>([])

  const regions = useMemo(() => (schema?.regions.options as Array<SchemaFieldOption>) || [], [schema])
  const regionsByValue: RegionsByValue = useMemo(
    () =>
      regions.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.value]: cur
        }),
        {}
      ) || [],
    [regions]
  )

  const reducedContacts = useMemo(
    () =>
      contacts?.reduce(
        (res, item) => ({
          ...res,
          [item.id]: {
            guid: item.id,
            name: item.name,
            type: item.user_type
          }
        }),
        {}
      ),
    [contacts]
  )

  const contactList = useLoadAutocompleteOptions(reducedContacts)
  const mappedOptions = mapReducedDataForAutocomplete(reducedContacts)

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    control,
    getValues
  } = useForm<FormValues>()

  const contactsValue = getValues("contacts")

  const isSendDisabled =
    ((isSubmitting || !isDirty || !contactsValue) && !sendToAllSelected && isEmpty(selectedRegions)) ||
    disableCreateChannel

  const onSubmit = handleSubmit((props) => {
    setDisableCreateChannel(true)

    const payload: SendBulkMessageRequest = { message: props.message }
    if (sendToCarersSelected) {
      payload.carer_ids = props.contacts.map((contact) => contact.value)
    } else if (sendToRegionsSelected) {
      payload.region_ids = selectedRegions
    } else if (sendToAllSelected) {
      payload.send_to_all = true
    }

    sendBulkMessage(payload, {
      onSuccess: () => {
        setIsNewChannelCreateModalOpen(false)
        showSuccessMessage("New message sent successfully")
        queryCache.invalidateQueries(["channels", channelNames.chats])
      }
    })
  })

  const handleSendToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendTo((event.target as HTMLInputElement).value)
  }

  const handleRegionsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as number[]
    setSelectedRegions(value)
  }

  if (!isNewChannelCreateModalOpen) {
    return null
  }

  return (
    <ModalWrapper title={SEND_NEW_MESSAGE} destroyModal={() => setIsNewChannelCreateModalOpen(false)} width={670}>
      <form onSubmit={onSubmit}>
        <FormControl component="fieldset">
          <FormLabel component="label">{SEND_TO_LABEL}</FormLabel>
          <RadioGroup row name="sendTo" value={sendTo} onChange={handleSendToChange}>
            <FormControlLabel value={SendToValues.CARERS} control={<Radio />} label={CARERS_LABEL} />
            <FormControlLabel value={SendToValues.REGIONS} control={<Radio />} label={REGIONS_LABEL} />
            <FormControlLabel value={SendToValues.ALL_CARERS} control={<Radio />} label={ALL_CARERS_LABEL} />
          </RadioGroup>
        </FormControl>
        {!sendToAllSelected && sendToCarersSelected && (
          <Controller
            name="contacts"
            control={control}
            render={({ field }) => {
              return (
                <Autocomplete
                  isMulti
                  id="contacts"
                  loadOptions={contactList}
                  placeholder={TYPE_THE_CARER_NAME_LABEL}
                  input={field}
                  label={SELECT_CARERS}
                  defaultOptions={mappedOptions}
                />
              )
            }}
          />
        )}
        {!sendToAllSelected && sendToRegionsSelected && (
          <FormControl margin="normal" sx={{ display: "block" }}>
            <TextField
              id="outlined-select-currency-native"
              select
              label={SELECT_REGIONS}
              value={selectedRegions}
              onChange={handleRegionsChange}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (selected as number[]).map((opt) => regionsByValue[opt].title).join(", ")
              }}
              variant="outlined"
              fullWidth
            >
              {regions.map((item) => (
                <MenuItem dense={true} key={item.value} value={item.value}>
                  <Checkbox checked={selectedRegions.findIndex((region) => item.value === region) >= 0} />
                  <ListItemText primary={item.title} />
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        )}

        <Controller
          name="message"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                label={MESSAGE_LABEL}
                multiline
                fullWidth
                placeholder={TYPE_MESSAGE_HERE_LABEL}
                value={value}
                onChange={onChange}
                margin="normal"
                variant="outlined"
                autoComplete="off"
                inputProps={{ style: { height: "100px" } }}
              />
            )
          }}
        />
        <Box m={3} mx={0} display="flex" justifyContent="flex-end" color="secondary">
          <Button fullWidth={false} variant="outlined" onClick={() => setIsNewChannelCreateModalOpen(false)}>
            {CANCEL_LABEL}
          </Button>
          <Button
            sx={{ ml: 4 }}
            fullWidth={false}
            variant="contained"
            type="submit"
            disabled={isSendDisabled}
            startIcon={<Send />}
          >
            {SEND_LABEL}
          </Button>
        </Box>
      </form>
    </ModalWrapper>
  )
}
export default ChatModal
