import React from "react"
import { Grid } from "@mui/material"
import { ClearFilterButton } from "components/ClearFilterButton"
import { Field, Form } from "react-final-form"
import { FormSpyCustom, TextField } from "components/Form"
import { useDebouncedCallback } from "use-debounce"

export type SearchFilter = { search?: string }

interface Props {
  onClear: () => void
  onSubmit: (filter: SearchFilter) => void
  initialValues: SearchFilter
}

const SearchOperators: React.FC<Props> = ({ onClear, onSubmit, initialValues }) => {
  const debouncedOnSubmit = useDebouncedCallback((filter: SearchFilter) => {
    onSubmit(filter)
  }, 200)

  return (
    <Grid item container justifyContent="flex-start" alignItems="center" sm={9} md={9} lg={9} xl={9} spacing={1}>
      <Grid item sm={6}>
        <Form onSubmit={debouncedOnSubmit} initialValues={initialValues}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormSpyCustom handleSubmit={handleSubmit} />
              <Field<string> component={TextField} name="search" label="Search (name / email)" />
            </form>
          )}
        </Form>
      </Grid>
      <Grid item>
        <ClearFilterButton onClick={onClear} disabled={Object.keys(initialValues).length === 0} />
      </Grid>
    </Grid>
  )
}

export default SearchOperators
