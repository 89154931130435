import React, { memo } from "react"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import { ApiUserCarer, ApiUserClient } from "models"
import { DateTimeFormat } from "lib/datetime"
import { Visit } from "models/Visit"
import { Link } from "components"
import testingId from "constants/testingId"

interface OwnProps {
  carer?: ApiUserCarer
  visit: Visit
  client?: ApiUserClient
}

const VisitDetailInfo: React.FC<OwnProps> = ({ carer, visit, client }) => (
  <Grid container spacing={3} mb={2}>
    <Grid item xs={3}>
      <Typography variant="body2" color="textSecondary" component="h6">
        Client name
      </Typography>
      {client ? (
        <Link
          id="visit-details-client-name-link"
          external
          to={`/clients/${client.guid}/profile/personal-details`}
          data-cy={testingId.visit.detail.clientName}
        >
          {client.profile.name}
        </Link>
      ) : (
        <Typography variant="body1">Unknown</Typography>
      )}
    </Grid>
    <Grid item xs={3}>
      <Typography variant="body2" color="textSecondary" component="h6">
        Carer name
      </Typography>
      {carer ? (
        <Link
          id="visit-details-carer-name-link"
          external
          to={`/carers/${carer.guid}/profile/personal-details`}
          data-cy={testingId.visit.detail.carerName}
        >
          {carer.profile.name}
        </Link>
      ) : (
        <Typography variant="body1">Unassigned</Typography>
      )}
    </Grid>
    <Grid item xs={3}>
      <Typography variant="body2" color="textSecondary" component="h6">
        Scheduled date &amp; time
      </Typography>
      <Typography variant="body1" data-cy={testingId.visit.detail.schedule}>
        {visit.formatDate("start")}&mdash;{visit.formatDate("end")}
        <br />
        {visit.formatDate("start", DateTimeFormat.TIME)}&mdash;{visit.formatDate("end", DateTimeFormat.TIME)}
      </Typography>
    </Grid>
    <Grid item xs={1.5}>
      <Typography variant="body2" color="textSecondary" component="h6">
        NFC Tag
      </Typography>
      <Typography variant="body1" data-cy={testingId.visit.detail.nfc}>
        {client?.has_nfc ? "Enabled" : "Disabled"}
      </Typography>
    </Grid>
    <Grid
      item
      xs={1.5}
      sx={{
        textAlign: "right"
      }}
    >
      <Typography variant="body2" color="textSecondary" component="h6">
        Visit status
      </Typography>
      <Typography variant="body1" data-cy={testingId.visit.detail.status}>
        {visit.statusToString()}
      </Typography>
    </Grid>
  </Grid>
)

export default memo(VisitDetailInfo)
