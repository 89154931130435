import { Typography } from "@mui/material"
import testingId from "constants/testingId"
import { PaymentTransaction } from "data/finance/paymentProcessing/types"
import { moneyFormat } from "lib/utils"
import React, { memo } from "react"

const getTotal = (items: PaymentTransaction[]) =>
  items.reduce((total: number, item) => {
    total += item.total
    return total
  }, 0)

interface Props {
  transactions?: PaymentTransaction[]
}

const TransactionListCost: React.FC<Props> = ({ transactions }) => {
  const transactionsMoney: number = transactions ? getTotal(transactions) : 0

  return (
    <Typography variant="h5" component="h2" data-cy={testingId.paymentProcessingTransactionList.totalCostTitle}>
      Cost of displayed: {moneyFormat.format(transactionsMoney)}
    </Typography>
  )
}

export default memo(TransactionListCost)
