import { Box, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { filter, keys, pipe } from "ramda"
import React, { memo, useMemo, useState } from "react"
import { sortUpliftItemsByIndex } from "../../helpers/sortUpliftItemsByIndex"
import { isModelWithRates } from "../../helpers/upliftFormGetters"
import { RateUpliftFormModel, RateUpliftFormService } from "../../types"
import { RateUpliftFourService } from "./RateUpliftFourService"
import { RateUpliftTableModelWrapper } from "./RateUpliftTableModelWrapper"

interface OwnProps {
  formModel: RateUpliftFormModel
  modelTitle?: string
  defaultOpen: boolean
}

interface ContentOwnProps {
  formModel: RateUpliftFormModel
}

export const RateUpliftFourModelContent: React.FC<ContentOwnProps> = memo(({ formModel }) => {
  RateUpliftFourModelContent.displayName = "RateUpliftFourModelContent"

  const pickServicesByGuid = useMemo(
    () =>
      pipe(
        filter<RateUpliftFormService>((service) => service.picked),
        keys
      )(formModel.services || {}),
    [formModel.services]
  ) as string[]

  const sortedServices = sortUpliftItemsByIndex({
    items: formModel.serviceItems,
    guids: pickServicesByGuid
  })

  if (!formModel.services) {
    return (
      <Box
        sx={{
          borderTop: `1px solid ${grey[400]}`
        }}
      >
        <Typography
          component="span"
          sx={{
            display: "block",
            padding: "10px 0",
            border: "none"
          }}
        >
          This Model does not have any Service
        </Typography>
      </Box>
    )
  }

  if (!isModelWithRates(formModel)) {
    return (
      <Box
        sx={{
          borderTop: `1px solid ${grey[400]}`
        }}
      >
        <Typography
          sx={{
            display: "block",
            padding: "10px 0",
            border: "none"
          }}
          component="span"
        >
          This Model does not Service with Rates
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        borderTop: `1px solid ${grey[400]}`
      }}
    >
      {sortedServices.map((service) => {
        const formService = (formModel.services || {})[service.guid]

        if (!formService) return null
        return <RateUpliftFourService key={service.guid} {...{ formService, service }} />
      })}
    </Box>
  )
})

export const RateUpliftFourModel: React.FC<OwnProps> = memo(({ formModel, modelTitle = "", defaultOpen }) => {
  RateUpliftFourModel.displayName = "RateUpliftFourModel"

  const [open, setOpen] = useState(defaultOpen)

  return (
    <RateUpliftTableModelWrapper {...{ title: modelTitle, open, setOpen }}>
      {open && <RateUpliftFourModelContent {...{ formModel }} />}
    </RateUpliftTableModelWrapper>
  )
})
