import { FinanceApi } from "api"
import { enabledByMandatoryUrlParams, setDayBorders } from "data/helpers"
import { removeFromSearchString } from "lib/helpers/removeFromSearchString"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { InterfaceFile, InterfaceFileDetailDownload, InvoiceFileItem } from "./types"
import { useLocation } from "react-router-dom"

export enum QueryKeyParts {
  INVOICE_FILE = "invoice_file",
  LIST = "list",
  DOWNLOAD = "download",
  INTERFACE = "interface"
}

export const queryKeys = {
  getInvoiceFileListKey: ({ search }: { search?: string }): QueryKey => [
    QueryKeyParts.INVOICE_FILE,
    QueryKeyParts.LIST,
    { search }
  ],
  getInvoiceFileInterfaceList: ({ search }: { search?: string }): QueryKey => [
    QueryKeyParts.INVOICE_FILE,
    QueryKeyParts.INTERFACE,
    QueryKeyParts.LIST,
    { search }
  ],
  getInvoiceFileInterfaceDownloadKey: ({ interfaceGuid }: { interfaceGuid?: string }): QueryKey => [
    QueryKeyParts.INVOICE_FILE,
    QueryKeyParts.INTERFACE,
    QueryKeyParts.DOWNLOAD,
    { interfaceGuid }
  ]
}

export const useInvoiceFileListQuery = ({
  enabled = true
}: {
  enabled?: boolean
}): UseQueryResult<InvoiceFileItem[], Error> => {
  const location = useLocation()

  const searchString = removeFromSearchString({ search: location.search, remove: ["modelType"] })
  const newSearch = setDayBorders({ search: searchString, from: "period_end_from", to: "period_end_to" })

  return useQuery({
    queryKey: queryKeys.getInvoiceFileListKey({ search: newSearch }),
    queryFn: async () => FinanceApi.get(`finance/invoice-files${newSearch}`),
    ...enabledByMandatoryUrlParams([enabled, newSearch])
  })
}

export const useInvoiceFileInterfaceListQuery = ({
  search,
  enabled = true
}: {
  search?: string
  enabled?: boolean
}): UseQueryResult<InterfaceFile[], Error> => {
  const searchString = removeFromSearchString({ search, remove: ["modelType"] })
  const newSearch = setDayBorders({ search: searchString, from: "period_start_from", to: "period_start_to" })

  return useQuery({
    queryKey: queryKeys.getInvoiceFileInterfaceList({ search: newSearch }),
    queryFn: async () => FinanceApi.get(`finance/invoice-files/interfaces${newSearch}`),
    ...enabledByMandatoryUrlParams([enabled, newSearch])
  })
}

export const useInvoiceFileInterfaceDownloadQuery = ({
  enabled = true,
  interfaceGuid
}: {
  enabled?: boolean
  interfaceGuid: string
}): UseQueryResult<InterfaceFileDetailDownload, Error> => {
  return useQuery({
    queryKey: queryKeys.getInvoiceFileInterfaceDownloadKey({ interfaceGuid }),
    queryFn: async () => FinanceApi.get(`finance/invoice-files/interfaces/${interfaceGuid}/download`),
    ...enabledByMandatoryUrlParams([enabled, interfaceGuid])
  })
}
