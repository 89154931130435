import React, { memo, useMemo } from "react"
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material"
import testingId from "constants/testingId"
import { Button, Checkbox, Select, TextField } from "components/Form"
import { Field, FormSpy } from "react-final-form"
import DeleteIcon from "@mui/icons-material/Delete"
import Box from "@mui/material/Box"
import { FieldArray } from "react-final-form-arrays"
import { grey } from "@mui/material/colors"
import { DateTime } from "luxon"
import { SelectOption } from "components/Form/Select"
import Auth from "lib/Auth"
import { Permission } from "constants/permission"
import { RecalculateButton } from "components/RecalculateButton"
import { DateField } from "components/Form/DateField"
import { StatusEnum } from "data/commonTypes"
import { ApiClientContract, ApiClientContractContribution } from "data/finance/client/types"

const clientContributionsHeaderData = [
  "Start date",
  "Ends before",
  "Weekly contribution",
  "Private contract to bill",
  "Status"
]

interface Props {
  contract: ApiClientContract
}

interface WrappedFields {
  fields: {
    value: ApiClientContractContribution[]
    remove: (idx: number) => void
  }
}

interface NewContributionProps extends WrappedFields {
  start: DateTime
}

export const ClientContractContributions: React.FC<Props> = memo(({ contract }) => {
  const hasRecalcPermission = Auth.hasPermission([Permission.FINANCE_RECALCULATE_EDIT])

  const contractModelOptions: SelectOption[] = useMemo(
    () =>
      contract?.contribution_sources?.map((source: { title: string; guid: string }) => ({
        title: source.title,
        value: source.guid
      })) || [],
    [contract]
  )

  const createNewContribution = ({ fields, start }: NewContributionProps) => {
    const previousEndDate = fields.value && fields.value[fields.value.length - 1]?.end

    return {
      start: previousEndDate ?? start ?? DateTime.local()
    } as ApiClientContractContribution
  }

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <FieldArray name="contributions">
          {({ fields }) => (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ textAlign: "left" }}>
                      <DeleteIcon />
                    </TableCell>
                    {clientContributionsHeaderData.map((label, index) => (
                      <TableCell key={index}>{label}</TableCell>
                    ))}
                    {hasRecalcPermission && <TableCell />}
                  </TableRow>
                </TableHead>
                <TableBody data-cy={testingId.clientContract.contributionsTableBody}>
                  {fields.map((name, index) => {
                    const field = fields.value[index]
                    const canBeDeleted = field.status === StatusEnum.FUTURE || !field.guid
                    const isToBeDeleted = field.delete

                    return (
                      <TableRow
                        key={index}
                        style={{
                          opacity: isToBeDeleted ? 0.3 : 1,
                          backgroundColor: isToBeDeleted ? grey[100] : "transparent"
                        }}
                      >
                        <TableCell>
                          {canBeDeleted ? (
                            <Field<boolean> type="checkbox" label="" component={Checkbox} name={`${name}[delete]`} />
                          ) : null}
                        </TableCell>
                        <TableCell>
                          <DateField
                            name={`${name}.start`}
                            label="Start date *"
                            minDate={values.start ?? undefined}
                            maxDate={values.no_end_date || !values.end ? undefined : values.end.minus({ days: 1 })}
                            disabled={isToBeDeleted}
                          />
                        </TableCell>
                        <TableCell>
                          <DateField
                            name={`${name}.end`}
                            label="Ends before"
                            minDate={values.start ? values.start.plus({ days: 1 }) : undefined}
                            maxDate={values.no_end_date || !values.end ? undefined : values.end}
                            disabled={isToBeDeleted}
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            name={`${name}.rate`}
                            label="Contribution (£) *"
                            component={TextField}
                            disabled={isToBeDeleted}
                          />
                        </TableCell>
                        <TableCell>
                          <Field
                            name={`${name}.user_contract_guid`}
                            label="Private contract to bill *"
                            component={Select}
                            options={contractModelOptions}
                            disabled={isToBeDeleted}
                          />
                        </TableCell>
                        <TableCell>
                          <Field {...{ name: `${name}.status`, disabled: true }}>
                            {(props) => <span>{props.input.value}</span>}
                          </Field>
                        </TableCell>
                        {hasRecalcPermission && (
                          <TableCell>
                            <RecalculateButton {...{ type: "Contribution", relation_guids: [field.guid] }} />
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                <Button
                  fullWidth={false}
                  onClick={() => fields.push(createNewContribution({ fields, start: values.start }))}
                  data-cy={testingId.clientContract.addContributionButton}
                >
                  Add new row
                </Button>
              </Box>
            </>
          )}
        </FieldArray>
      )}
    </FormSpy>
  )
})

ClientContractContributions.displayName = "ClientContractContributions"
