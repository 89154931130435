import React from "react"
import { Avatar, Box, Grid, Typography } from "@mui/material"
import { Error, CheckCircle } from "@mui/icons-material"
import { Tag } from "@ceracare/dcp-ui"
import { Button, FormSectionHeader } from "components/Form"
import { RefusalOperator, RefusalType } from "models"
import { DateTime } from "luxon"
import { useConfirmDoorstep } from "data/core/mutations"

interface Props {
  confirmedAt: DateTime | null
  confirmedBy: RefusalOperator | null
  reason: string
  type: RefusalType
  isRequired: boolean
  userID: string
  refetch: () => void
}

const cancellationType = {
  CancelledOnArrivalByServiceUser: "Visit cancelled on arrival by service user/representative.",
  Other: "Other",
  EnteredPropertyServiceUserNotAtHome: "Entered property, service user not at home."
} as const

const Status = ({ confirmed }: { confirmed: boolean }) =>
  confirmed ? (
    <Tag label="Confirmed" color="success" icon={<CheckCircle sx={{ fontSize: 16 }} />} />
  ) : (
    <Tag label="Unconfirmed" color="error" icon={<Error sx={{ fontSize: 16 }} />} />
  )

const CancellationReport = ({ userID, reason, type, confirmedAt, confirmedBy, isRequired, refetch }: Props) => {
  const { mutateAsync, isLoading, data } = useConfirmDoorstep(refetch)
  const confirmation = {
    user: data?.door_step_refusal.confirmed_by ?? confirmedBy,
    date: data?.door_step_refusal.confirmed_at ?? confirmedAt
  }

  return (
    <Box my={5}>
      <FormSectionHeader
        title="Cancellation on arrival report"
        action={isRequired ? <Status confirmed={!!confirmation.date} /> : null}
      />
      <Grid container mt={2}>
        <Grid xs={6}>
          <Typography variant="body2" color="textSecondary" mb={1}>
            Reason for cancellation
          </Typography>
          <Typography variant="body1">{cancellationType[type]}</Typography>
        </Grid>
        <Grid xs={6}>
          <Typography variant="body2" color="textSecondary" mb={1}>
            Additional information
          </Typography>
          <Typography variant="body1">{reason}</Typography>
        </Grid>
        {isRequired && (
          <Grid xs={12} mt={3}>
            {confirmation.date === null && (
              <Button
                disabled={isLoading}
                onClick={() => {
                  mutateAsync(userID)
                }}
                sx={{ width: "auto", float: "right" }}
              >
                Confirm cancelled visit
              </Button>
            )}
            {confirmation.user && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "success.tint",
                  width: "fit-content",
                  padding: 1,
                  borderRadius: "4px"
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: "success.main",
                    fontSize: 20,
                    lineHeight: 1,
                    letterSpacing: 0.14,
                    textTransform: "uppercase",
                    mr: 1
                  }}
                >
                  {confirmation.user.first_name.charAt(0)}
                  {confirmation.user.last_name.charAt(0)}
                </Avatar>
                <Typography variant="body1">
                  Cancelled visit was confirmed by&nbsp;
                  <Typography component="span" variant="body1" sx={{ fontWeight: 500 }}>
                    {confirmation.user.full_name}
                  </Typography>
                  &nbsp;on&nbsp;
                  <Typography component="span" variant="body1" sx={{ fontWeight: 500 }}>
                    {confirmation.date?.toFormat("dd/MM/yyyy HH:mm")}
                  </Typography>
                </Typography>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default CancellationReport
