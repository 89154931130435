import { FinanceApi } from "api"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import {
  AppointmentAssignee,
  AppointmentAssigneeRequest,
  AppointmentPatchRequest,
  AppointmentPatchResponse
} from "./types"
import { queryKeys } from "./queries"
import { GUID_ZERO } from "constants/guid"
import { ApiError } from "stores/useUIStore/types"
import { useApiMessage } from "stores"

export const useAppointmentAssigneePutMutation = ({
  appointmentGuid,
  userAppointmentGuid = GUID_ZERO
}: {
  appointmentGuid: string
  userAppointmentGuid?: string
}): UseMutationResult<AppointmentAssignee, ApiError, AppointmentAssigneeRequest> => {
  const queryCache = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async (values: AppointmentAssigneeRequest): Promise<AppointmentAssignee> => {
      return FinanceApi.put(`/finance/appointments/${appointmentGuid}/assignees/${userAppointmentGuid}`, values)
    },
    {
      onSuccess: (data) => {
        queryCache.setQueryData(
          queryKeys.getAppointmentAssigneeDetailKey({ appointmentGuid, userAppointmentGuid }),
          data
        )
        showSuccessMessage("Appointment successfully edited.")
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not edit appointment.", { apiError })
      }
    }
  )
}

export const useAppointmentPatchMutation = ({
  onOk,
  onError
}: {
  onOk: () => void
  onError: (errors: string[]) => void
}): UseMutationResult<AppointmentPatchResponse, ApiError, AppointmentPatchRequest> => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useMutation(
    async (values: AppointmentPatchRequest): Promise<AppointmentPatchResponse> => {
      return FinanceApi.patch("/finance/appointments", values)
    },
    {
      onSuccess: (data) => {
        if (data?.failed_user_appointment_guids?.length) {
          showErrorMessage("Some Appointments could not be edited. Incompatible Contract Model.")
          onError(data.failed_user_appointment_guids)
        } else {
          showSuccessMessage("Appointments successfully edited.")
          onOk()
        }
      },
      onError: (apiError: ApiError) => {
        showErrorMessage("Could not edit appointments.", { apiError })
      }
    }
  )
}
