import { ApiVisit, VisitStatusCoalesced } from "./index"
import VisitReportModel, { VisitReportTypes, ApiVisitReport } from "./VisitReport"
import { DateTimeFormat } from "lib/datetime"
import { DateTime } from "luxon"

type VisitTimestamp = "start" | "end" | "checkin" | "checkout"

type StatusOptions = {
  [status in VisitStatusCoalesced]: string
}

const CHECKIN_HOURS_THRESHOLD = 3

export interface Visit extends ApiVisit {
  canCheckIn: () => boolean
  canCheckOut: () => boolean
  canSignOff: () => boolean
  canSignOffWithConcern: () => boolean
  canReopen: () => boolean
  hasSignOffAvailable: () => boolean
  statusToString: () => string
  isCheckedIn: () => boolean
  isCheckedOut: () => boolean
  isMissed: () => boolean
  formatDate: (date: VisitTimestamp, formatString?: string) => string
  getDate: (date: VisitTimestamp) => DateTime
  visitReport: VisitReportTypes
  isGeoLocationCheckIn: () => boolean
  isGeoLocationCheckOut: () => boolean
}

export const statusOptions: StatusOptions = {
  [VisitStatusCoalesced.VisitPlanned]: "Planned",
  [VisitStatusCoalesced.VisitUpcoming]: "Upcoming",
  [VisitStatusCoalesced.VisitCheckedIn]: "Checked in",
  [VisitStatusCoalesced.VisitCheckedOut]: "Checked out",
  [VisitStatusCoalesced.ReportCheckedOutPartially]: "Partial report",
  [VisitStatusCoalesced.ReportCheckedOut]: "Report complete",
  [VisitStatusCoalesced.ReportReopened]: "Reopened",
  [VisitStatusCoalesced.ReportSignedOff]: "Signed off",
  [VisitStatusCoalesced.ReportSignedOffConcerned]: "Signed off with concern",
  [VisitStatusCoalesced.Cancelled]: "Cancelled",
  [VisitStatusCoalesced.Deleted]: "Deleted",
  [VisitStatusCoalesced.VisitPaper]: "Paper visit",
  [VisitStatusCoalesced.DoorstepRefusal]: "Cancelled on arrival"
}

const VisitModel = (visit: ApiVisit): Visit => {
  const { visit_report } = visit

  const startDate = visit.start
  const endDate = visit.end
  const checkInDate = visit.check_in ? visit.check_in.time : DateTime.local()
  const checkOutDate = visit.check_out ? visit.check_out.time : DateTime.local()

  const canCheckIn = () =>
    !visit.is_cm2000 && DateTime.local().plus({ hours: CHECKIN_HOURS_THRESHOLD }).valueOf() >= visit.start.valueOf()

  const canCheckOut = () =>
    visit.status_coalesced !== VisitStatusCoalesced.VisitPaper &&
    visit.status_coalesced >= VisitStatusCoalesced.VisitCheckedIn &&
    !visit.is_cm2000
  const canSignOff = () =>
    visit.status_coalesced !== VisitStatusCoalesced.VisitPaper &&
    visit.status_coalesced === VisitStatusCoalesced.ReportCheckedOut
  const canSignOffWithConcern = () =>
    visit.status_coalesced !== VisitStatusCoalesced.VisitPaper &&
    visit.status_coalesced === VisitStatusCoalesced.ReportCheckedOut
  const canReopen = () =>
    visit.status_coalesced !== VisitStatusCoalesced.VisitPaper &&
    visit.status_coalesced === VisitStatusCoalesced.ReportCheckedOut
  const hasSignOffAvailable = () => canSignOff() || canSignOffWithConcern() || canReopen()

  const isCheckedIn = () => visit.check_in !== null
  const isCheckedOut = () => visit.check_out !== null

  const isGeoLocationCheckIn = () => !!visit?.check_in?.location?.is_using_geolocation
  const isGeoLocationCheckOut = () => !!visit?.check_out?.location?.is_using_geolocation

  const isMissed = () => visit.missed === true

  const statusToString = (): string => {
    if (isMissed()) {
      return "Missed"
    }
    return statusOptions[visit.status_coalesced]
  }

  const getDate = (date: VisitTimestamp) => {
    if (date === "start") return startDate
    if (date === "end") return endDate
    if (date === "checkin") return checkInDate
    if (date === "checkout") return checkOutDate
    throw new Error("Invalid visit timestamp")
  }

  const formatDate = (date: VisitTimestamp, formatString?: string) => {
    if (date === "start") return startDate.toFormat(formatString || DateTimeFormat.DATE)
    if (date === "end") return endDate.toFormat(formatString || DateTimeFormat.DATE)
    if (date === "checkin")
      return isCheckedIn() ? checkInDate.toFormat(formatString || DateTimeFormat.DATE_TIME) : "Unknown"
    if (date === "checkout")
      return isCheckedOut() ? checkOutDate.toFormat(formatString || DateTimeFormat.DATE_TIME) : "Unknown"
    throw new Error("Invalid visit timestamp")
  }

  const visitReport = VisitReportModel(visit_report || ({} as ApiVisitReport))

  return {
    canCheckIn,
    canCheckOut,
    canSignOff,
    canSignOffWithConcern,
    canReopen,
    hasSignOffAvailable,
    statusToString,
    formatDate,
    getDate,
    isCheckedIn,
    isCheckedOut,
    isGeoLocationCheckOut,
    isGeoLocationCheckIn,
    isMissed,
    visitReport: visitReport,
    ...visit
  }
}

export default VisitModel
