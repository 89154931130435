import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { ApiContractAccrualItem } from "data/finance/carer/types"
import { DateTimeFormat, formatDate } from "lib/datetime"
import React, { memo, useMemo } from "react"
import { CarerAccrualDetailListItemCharge } from "./CarerAccrualDetailListItemCharge"
import { CarerAccrualDetailListItemTotal } from "./CarerAccrualDetailListItemTotal"
import { DataLoading, EmptyData, Paginator } from "components"
import { Pagination, SetPagination } from "lib/types"
import { CarerAccrualItemFilterValues } from "./CarerAccrualDetailListFilter"
import { useCarerAccrualDetailItemsQuery } from "data/finance/carer/queries"
import { ApiUserCarer } from "models"
import { getPaginatedItems } from "helpers/getPaginatedItems"
import { isEmpty, omit } from "ramda"

interface OwnProps {
  hasModelEditPermission: boolean
  pagination: Pagination
  setPagination: SetPagination
  openModal: (accrualItem?: ApiContractAccrualItem | undefined) => () => void
  filter: CarerAccrualItemFilterValues
  carer: ApiUserCarer
  accrualGuid: string
}

export const CarerAccrualDetailListTable: React.FC<OwnProps> = memo(
  ({ hasModelEditPermission, setPagination, pagination, openModal, filter, carer, accrualGuid }) => {
    CarerAccrualDetailListTable.displayName = "CarerAccrualDetailListTable"

    const { data: allAccrualItems, isLoading } = useCarerAccrualDetailItemsQuery({ carerGuid: carer.guid, accrualGuid })

    const filteredItems = useMemo(() => {
      if (!allAccrualItems) return undefined
      return allAccrualItems
        .filter((item) => !filter.type || item.type === filter.type)
        .filter((item) => !filter.date || formatDate(filter.date) === formatDate(item.date))
        .filter((item) => !filter.hide_zero || item.amount !== 0)
    }, [allAccrualItems, filter])

    const paginatedItems = getPaginatedItems({ pagination, items: filteredItems || [] })

    if (isLoading || !filteredItems) return <DataLoading />

    if (isEmpty(paginatedItems)) {
      if (isEmpty(omit(["page", "page_size"], filter))) {
        return <EmptyData message="This Accrual has no Adjustments." />
      }
      return <EmptyData message="This Accrual has no Adjustments with current filter." />
    }

    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              {["Date", "Type", "Hours", "Cumulative total", "Description"].map((item) => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedItems.map((accrualItem, index) => {
              const isEditable = hasModelEditPermission && accrualItem.type === "Manual"

              return (
                <TableRow
                  sx={
                    isEditable
                      ? {
                          cursor: "pointer"
                        }
                      : undefined
                  }
                  key={accrualItem.guid}
                  onClick={isEditable ? openModal(accrualItem) : undefined}
                  hover={isEditable}
                >
                  <TableCell>{accrualItem.date.toFormat(DateTimeFormat.DATE)}</TableCell>
                  <TableCell>{accrualItem.type}</TableCell>
                  <TableCell>
                    <CarerAccrualDetailListItemCharge {...{ accrualItem }} />
                  </TableCell>
                  <TableCell>
                    <CarerAccrualDetailListItemTotal {...{ filteredItems, index, pagination }} />
                  </TableCell>
                  <TableCell>{accrualItem.description}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Paginator {...{ setPagination, itemsLength: paginatedItems.length, initialPageSize: 100 }} />
      </>
    )
  }
)
