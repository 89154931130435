import dateTimeUtils, { DateTimeFormat, formatTime } from "lib/datetime"
import { moneyFormat } from "lib/utils"
import { timeSpanToHhMm, timeSpanToStringDuration } from "lib/timespan"
import numberUtils from "lib/number"
import { sort } from "ramda"
import { sortByDate } from "lib/sortByDate"
import { ChargeBreakdown, ExtraCharge } from "pages/Finance/types"
import { ApiTransactionShiftCalculation, ApiTransactionShiftVisit } from "data/finance/invoiceProcessing/types"

export interface ParsedShiftTransactionCalculation {
  contract_name: string
  reference_owner_name?: string
  duration_charge: string
  chargeRules: (string | undefined)[]
  paymentModels: (string | number | undefined)[]
  chargeBreakdowns: (string | undefined)[][]
  extraCharges: (string | undefined)[][]
  visitsOverview: string[][]
  visitDetailHeader: {
    contractModelTitle?: string
    clientName?: string
    contractNumber?: string
    shiftTitle?: string
  }
  totalCharges: string
}

const shiftPaymentTransactionParser = (data: ApiTransactionShiftCalculation): ParsedShiftTransactionCalculation => {
  const {
    reference_contract_name,
    reference_owner_name,
    labour_charges,
    extra_charges,
    visits,
    rule_condition_title,
    rule_charge_type_title,
    rule_cap_type_title,
    rule_cap_figure,
    contract_name,
    service_type_title,
    payment_type_title,
    band_maximum_minutes,
    band_minimum_minutes,
    rounding_type_title,
    rounding_minutes,
    start_charged,
    end_charged,
    duration_actual,
    processed_at
  } = data
  const chargeRules = [
    rule_condition_title,
    rule_charge_type_title,
    rule_cap_type_title,
    rule_cap_figure ? rule_cap_figure.toString() : "-"
  ]

  const paymentModels = [
    contract_name,
    service_type_title,
    payment_type_title,
    `${band_minimum_minutes} - ${band_maximum_minutes || "N/A"}`,
    rounding_type_title,
    rounding_minutes,
    `${timeSpanToHhMm(formatTime(start_charged, DateTimeFormat.TIME_WITH_SECONDS))} - ${timeSpanToHhMm(
      formatTime(end_charged, DateTimeFormat.TIME_WITH_SECONDS)
    )}`,
    timeSpanToStringDuration(duration_actual)
  ]

  const chargeBreakdowns = labour_charges
    .map((labour_charge) => {
      const { description, start, end, rate, duration, total } = labour_charge

      const rate_period = `${timeSpanToHhMm(start, false)} - ${timeSpanToHhMm(end)}`
      const chargeBreakdown: ChargeBreakdown = {
        day_rate: description || "",
        rate_period,
        rate: moneyFormat.format(rate),
        duration: timeSpanToStringDuration(duration),
        total: moneyFormat.format(total)
      }

      return chargeBreakdown
    })
    .map((lc) => Object.values(lc))

  const extraCharges = extra_charges
    .map((ex) => {
      const extraCharge: ExtraCharge = {
        description: ex.description || "",
        rate: moneyFormat.format(ex.rate),
        quantity: numberUtils.displayWithDecimals(ex.quantity.toString(), 2),
        total: moneyFormat.format(ex.total)
      }

      return extraCharge
    })
    .map((ec) => Object.values(ec))

  const visitsOverview = visits
    ? sort(
        sortByDate(true)((visit: ApiTransactionShiftVisit) => visit.start_rostered),
        visits
      )
        .map((visit) => ({
          date: dateTimeUtils.formatDate(visit.visit_date),
          status: visit.confirmed ? "Confirmed" : "Unconf",
          calculationTime: processed_at ? dateTimeUtils.formatTime(processed_at) : "--/--/--",
          service: visit.service_type_title,
          rostTime: `${dateTimeUtils.formatTime(visit.start_rostered)} - ${dateTimeUtils.formatTime(
            visit.end_rostered
          )}`,
          rostDuration: timeSpanToStringDuration(visit.duration_rostered),
          actualTime:
            visit.start_actual && visit.end_actual
              ? `${timeSpanToHhMm(formatTime(visit.start_actual, DateTimeFormat.TIME_WITH_SECONDS))} - ${timeSpanToHhMm(
                  formatTime(visit.end_actual, DateTimeFormat.TIME_WITH_SECONDS)
                )}`
              : "",
          actualDur: timeSpanToStringDuration(visit.duration_actual)
        }))
        .map((lc) => Object.values(lc))
    : []

  const totalCharges = labour_charges.map((labour) => labour.total).reduce((a, b) => a + b, 0)

  const visitDetailHeader = {
    contractModelTitle: reference_contract_name,
    clientName: visits?.[0]?.reference_owner_name,
    contractNumber: visits?.[0]?.contract_reference,
    shiftTitle: `${reference_owner_name} - ${dateTimeUtils.formatDate(visits?.[0]?.visit_date || "")}`
  }

  return {
    contract_name,
    reference_owner_name,
    duration_charge: timeSpanToStringDuration(data.duration_charge),
    chargeRules,
    paymentModels,
    chargeBreakdowns,
    extraCharges,
    visitsOverview,
    visitDetailHeader,
    totalCharges: moneyFormat.format(totalCharges)
  }
}

export default shiftPaymentTransactionParser
