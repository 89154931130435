import { FinanceApi } from "api"
import { enabledByMandatoryUrlParams, setDayBorders } from "data/helpers"
import { removeFromSearchString } from "lib/helpers/removeFromSearchString"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { InterfaceFile, InterfaceFileDetailDownload, InvoiceFileItem } from "../invoiceFile/types"
import { useLocation } from "react-router-dom"

export enum QueryKeyParts {
  PAYMENT_FILE = "payment_file",
  LIST = "list",
  DOWNLOAD = "download",
  INTERFACE = "interface"
}

export const queryKeys = {
  getPaymentFileListKey: ({ search }: { search?: string }): QueryKey => [
    QueryKeyParts.PAYMENT_FILE,
    QueryKeyParts.LIST,
    { search }
  ],
  getPaymentFileListInterfaceListKey: ({ searchString }: { searchString?: string }): QueryKey => [
    QueryKeyParts.PAYMENT_FILE,
    QueryKeyParts.INTERFACE,
    QueryKeyParts.LIST,
    { searchString }
  ],
  getPaymentFileInterfaceDownloadKey: ({ interfaceGuid }: { interfaceGuid?: string }): QueryKey => [
    QueryKeyParts.PAYMENT_FILE,
    QueryKeyParts.INTERFACE,
    QueryKeyParts.DOWNLOAD,
    { interfaceGuid }
  ]
}

export const usePaymentFileListQuery = ({
  enabled = true
}: {
  enabled?: boolean
}): UseQueryResult<InvoiceFileItem[], Error> => {
  // TODO: should have PaymentFileItem type
  const location = useLocation()

  const searchString = removeFromSearchString({ search: location.search, remove: ["modelType"] })
  const newSearch = setDayBorders({ search: searchString, from: "period_end_from", to: "period_end_to" })

  return useQuery({
    queryKey: queryKeys.getPaymentFileListKey({ search: newSearch }),
    queryFn: async () => FinanceApi.get(`finance/payment-files${newSearch}`),
    ...enabledByMandatoryUrlParams([enabled, newSearch])
  })
}

export const usePaymentFileInterfaceListQuery = ({
  search,
  enabled = true
}: {
  search?: string
  enabled?: boolean
}): UseQueryResult<InterfaceFile[], Error> => {
  const searchString = removeFromSearchString({ search, remove: ["modelType"] })
  const newSearch = setDayBorders({ search: searchString, from: "period_start_from", to: "period_start_to" })

  return useQuery({
    queryKey: queryKeys.getPaymentFileListInterfaceListKey({ searchString: newSearch }),
    queryFn: async () => FinanceApi.get(`finance/payment-files/interfaces${newSearch}`),
    ...enabledByMandatoryUrlParams([enabled, newSearch])
  })
}

export const usePaymentFileInterfaceDownloadQuery = ({
  enabled = true,
  interfaceGuid
}: {
  enabled?: boolean
  interfaceGuid: string
}): UseQueryResult<InterfaceFileDetailDownload, Error> => {
  return useQuery({
    queryKey: queryKeys.getPaymentFileInterfaceDownloadKey({ interfaceGuid }),
    queryFn: async () => FinanceApi.get(`finance/payment-files/interfaces/${interfaceGuid}/download`),
    ...enabledByMandatoryUrlParams([enabled, interfaceGuid])
  })
}
