import React, { Dispatch, memo, SetStateAction, useMemo } from "react"
import { SortableTableHeader } from "components/SortableTableHeader"
import { PaymentTransactionFilter } from "../types"

interface OwnProps {
  hasRewindPermission: boolean
  hasCreditableTransactions: boolean
  hasAlerts: boolean
  setFilter: Dispatch<SetStateAction<PaymentTransactionFilter>>
}

export const TransactionListTableHeader: React.FC<OwnProps> = memo(
  ({ setFilter, hasRewindPermission, hasCreditableTransactions, hasAlerts }) => {
    TransactionListTableHeader.displayName = "TransactionListTableHeader"

    const items = useMemo(
      () => [
        {
          label: "Carer name",
          resource: [
            "user_contract.owner.user_branch.user.last_name",
            "user_contract.owner.user_branch.user.first_name"
          ]
        },
        { label: "Payroll", resource: "user_contract.reference" },
        {
          label: "Payment Num",
          resource: "reference"
        },
        { label: "Unconf Hours" },
        { label: "Visit Cost" },
        { label: "Extra Cost" },
        { label: "Total Cost" },
        { label: "Select" },
        ...(hasRewindPermission && hasCreditableTransactions ? [{ label: "", key: "rewind" }] : []),
        ...(hasAlerts ? [{ label: "", key: "alert" }] : [])
      ],
      [hasRewindPermission, hasCreditableTransactions, hasAlerts]
    )

    return <SortableTableHeader {...{ setFilter, items }} />
  }
)
