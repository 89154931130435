import React from "react"
import defaultProfilePicture from "../assets/images/default-profile-picture.png"
import { Avatar, SxProps, Theme } from "@mui/material"

interface OwnProps {
  alt: string
  sx?: SxProps<Theme>
}

const DefaultAvatar: React.FC<OwnProps> = (props) => {
  return <Avatar src={defaultProfilePicture} {...props} />
}

export default DefaultAvatar
