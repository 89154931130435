import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import DeleteIcon from "@mui/icons-material/Delete"
import React, { memo } from "react"

export const SalaryBandEditorHeader: React.FC = memo(() => {
  SalaryBandEditorHeader.displayName = "SalaryBandEditorHeader"

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ width: "20px" }}>
          <DeleteIcon />
        </TableCell>
        <TableCell>Day</TableCell>
        <TableCell sx={{ width: "25%" }}>Tier</TableCell>
        <TableCell sx={{ width: "25%", minWidth: "150px" }}>Start</TableCell>
        <TableCell sx={{ width: "25%" }}>Finish</TableCell>
      </TableRow>
    </TableHead>
  )
})
