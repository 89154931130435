export function addOrRemoveItemInList(list: string[], item: string): string[] {
  const index = list.findIndex((listItem) => listItem === item)

  if (index !== -1) {
    list = list.filter((guid) => guid !== item)
  } else {
    list = [...list, item]
  }

  return list
}
