import React from "react"
import { Field } from "react-final-form"
import { TextField } from "components/Form"
import { CarerOnboardingStatus } from "data/mobile/types"

interface Props {
  carerOnboardingStatus: CarerOnboardingStatus
}

const TrainingField = ({ carerOnboardingStatus }: Props) => {
  return (
    <Field
      {...{
        name: "onboarding_status",
        label: "Training",
        component: TextField,
        format: (): string => {
          switch (carerOnboardingStatus.step) {
            case 6:
              return "Completed"
            case 0:
            case -1:
              return "Not started"
            default:
              return "In progress"
          }
        },
        disabled: true
      }}
    />
  )
}

export default TrainingField
