import React, { FC, useEffect, useMemo } from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { Visit } from "models/Visit"
import { CheckInCheckOutAccordion } from "./CheckInCheckOut"
import { useClientDetailQuery } from "../../../data/core/client/queries"
import useGoogleMaps from "../../../components/GoogleMaps/useGoogleMaps"
import useGoogleMapsStore from "stores/useGoogleMapsStore"

interface OwnProps {
  visit: Visit
}

declare global {
  interface Window {
    initMap: () => void
    google: {
      maps: google.maps.Map
    }
  }
}

const CheckInOut: FC<OwnProps> = ({ visit }) => {
  const { data: client } = useClientDetailQuery({
    id: visit?.patient_guid
  })
  const isMapLoaded = useGoogleMapsStore((state) => state.isLoaded)
  const { loadGoogleMaps } = useGoogleMaps()

  useEffect(() => {
    if (!isMapLoaded) {
      loadGoogleMaps()
    }
  }, [loadGoogleMaps, isMapLoaded])

  const serviceUserFullAddress = useMemo(() => {
    if (!client?.profile) {
      return ""
    }

    const { address, address_post_code } = client.profile
    const isPostCodeInAddressAlready = address.includes(address_post_code ?? "")

    return isPostCodeInAddressAlready ? address : `${address} ${address_post_code}`
  }, [client])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary" component="h6">
          Service User Location
        </Typography>
        <Typography variant="body1">{serviceUserFullAddress}</Typography>
      </Grid>
      <Grid item xs={6}>
        <CheckInCheckOutAccordion visit={visit} patient={client} mapId="check-in-map" />
      </Grid>
      <Grid item xs={6}>
        <CheckInCheckOutAccordion visit={visit} patient={client} variant="out" mapId="check-out-map" />
      </Grid>
    </Grid>
  )
}

export default CheckInOut
