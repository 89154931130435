import React from "react"
import Button from "./Form/Button"
import { Box, ButtonProps } from "@mui/material"
import { ChevronLeft } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"

interface Props extends ButtonProps {
  fallbackUrl: string
}

const BackButton = ({ fallbackUrl, ...buttonProps }: Props) => {
  const navigate = useNavigate()
  return (
    <Box m={2} mx={0}>
      <Button
        size="small"
        variant="text"
        color="primary"
        sx={(theme) => ({
          marginLeft: theme.spacing(-1)
        })}
        fullWidth={false}
        {...buttonProps}
        onClick={() => {
          window.history.state !== null ? navigate(-1) : navigate(fallbackUrl)
        }}
      >
        <ChevronLeft />
        Back
      </Button>
    </Box>
  )
}

export default React.memo(BackButton)
