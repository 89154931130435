import React, { useCallback } from "react"
import { Button, Checkbox, FormSectionHeader, Select, TextField } from "components/Form"
import { ApiUserOperator, ApiUserOperatorForm, ApiUserOperatorFormRequest, ApiUserResource } from "models"
import Grid from "@mui/material/Grid"
import { getOptionsFromSchema } from "lib/schema"
import { StickyBox, UserAccount } from "components"
import { ProfileMode } from "lib/types"
import { WithPermission } from "components/Auth"
import validators, { composeValidators } from "lib/validators"
import { useOperatorCreateMutation, useOperatorUpdateMutation } from "data/core/operator/mutations"
import { OperatorProfilePermissionTable } from "./components/OperatorProfilePermissionTable"
import { Field, Form } from "react-final-form"
import { deepObjectEqual } from "lib/utils"
import { useCoreSchemasStore } from "stores"

interface OwnProps {
  mode: ProfileMode
  operator?: ApiUserOperator
}

const useSubmitHandler = (mode: ProfileMode) => {
  const { mutate: createOperator } = useOperatorCreateMutation()
  const { mutate: updateOperator } = useOperatorUpdateMutation()

  return useCallback(
    async (operator: ApiUserOperatorForm) => {
      const operatorResources = operator.resources

      const resources = Object.keys(operatorResources)
        .map((resourceGuid) => {
          return { ...operatorResources[resourceGuid], resource_guid: resourceGuid }
        })
        .filter((resource) => resource.scope)

      const normalizedOperator: ApiUserOperatorFormRequest = {
        ...operator,
        profile: {
          ...operator.profile,
          short_name: operator.profile.first_name
        },
        resources
      }

      if (mode === "create") {
        createOperator({ operator: normalizedOperator })
      } else {
        updateOperator({ operator: normalizedOperator })
      }
    },
    [createOperator, updateOperator, mode]
  )
}

const OperatorProfile: React.FC<OwnProps> = ({ mode, operator }) => {
  const { ApiUserOperatorUpdate } = useCoreSchemasStore((state) => state.schema.models)

  const resourceGroupOptions = getOptionsFromSchema(ApiUserOperatorUpdate, "resource_group")
  const salutationOptions = getOptionsFromSchema(ApiUserOperatorUpdate, "profile.salutation")

  const onSubmit = useSubmitHandler(mode)

  const initialValues = operator
    ? ({
        ...operator,
        resource_group: operator.resource_group?.map((g) => g.guid),
        resources: operator.resources?.reduce((result: Record<string, ApiUserResource>, resource: ApiUserResource) => {
          return { ...result, [resource.resource_guid]: resource }
        }, {})
      } as ApiUserOperatorForm)
    : { status: 0, resources: {} }

  return (
    <>
      {operator && mode === "update" && <UserAccount user={operator} />}

      <Form onSubmit={onSubmit} initialValues={initialValues} initialValuesEqual={deepObjectEqual}>
        {({ handleSubmit, form, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={8}>
                <Grid item md={6}>
                  <Field
                    name="status"
                    label="Status *"
                    component={Select}
                    options={[
                      { title: "Active", value: 0 },
                      { title: "Inactive", value: 1 }
                    ]}
                    validate={validators.required}
                  />
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>

              <FormSectionHeader title="Personal details" />
              <Grid container spacing={8}>
                <Grid item md={6}>
                  <Field
                    name="profile.salutation"
                    label="Salutation *"
                    component={Select}
                    options={salutationOptions}
                    validate={validators.required}
                  />
                </Grid>
                <Grid item md={6}>
                  <Field
                    name="profile.first_name"
                    label="First Name *"
                    component={TextField}
                    validate={validators.required}
                  />
                  <Field
                    name="profile.last_name"
                    label="Last Name *"
                    component={TextField}
                    validate={validators.required}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={8}>
                <Grid item md={6}>
                  <Field
                    name="profile.phone_mobile"
                    label="Personal mobile number"
                    component={TextField}
                    validate={validators.phone}
                  />
                </Grid>
                <Grid item md={6}>
                  <Field
                    name="profile.email"
                    label="E-mail *"
                    component={TextField}
                    validate={composeValidators([validators.required, validators.email])}
                    disabled={mode === "update"}
                  />
                  {mode === "create" && (
                    <Field name="send_welcome_email" label="Send welcome e-mail" component={Checkbox} type="checkbox" />
                  )}
                </Grid>
              </Grid>

              <WithPermission permissions={["User.Permission.Group:Edit"]}>
                <FormSectionHeader title="Roles" />
                <Grid container spacing={8}>
                  <Grid item md={6}>
                    <Field
                      name="resource_group"
                      label="Role *"
                      component={Select}
                      options={resourceGroupOptions}
                      multiselect
                      validate={validators.required}
                    />
                  </Grid>
                </Grid>
              </WithPermission>

              <WithPermission permissions={["User.Permission.Resource:Edit"]}>
                <FormSectionHeader title="Permissions" />
                <Grid container spacing={2}>
                  <OperatorProfilePermissionTable />
                </Grid>
              </WithPermission>

              <StickyBox>
                {mode === "update" && (
                  <Button
                    variant="text"
                    fullWidth={false}
                    disabled={submitting || pristine}
                    onClick={() => {
                      form.reset()
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  type="submit"
                  color="secondary"
                  fullWidth={false}
                  disabled={submitting || pristine}
                  style={{ marginLeft: "10px" }}
                >
                  Save changes
                </Button>
              </StickyBox>
            </form>
          )
        }}
      </Form>
    </>
  )
}

export default OperatorProfile
