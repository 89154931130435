import { Link } from "components"
import { SelectableIdsDictionaryModel } from "lib/hooks"
import { DateTime } from "luxon"
import dateTimeUtils from "lib/datetime"
import { Box, SxProps, Theme, Typography } from "@mui/material"
import React from "react"
import { TransactionFailed, TransactionInProgress, TransactionQueued } from "pages/Finance/components"
import DoneIcon from "@mui/icons-material/Done"
import { isNil } from "ramda"
import Auth from "lib/Auth"
import { Field } from "react-final-form"
import { Checkbox } from "components/Form"
import { RewindButton } from "components/RewindButton"
import { Permission } from "constants/permission"
import { ListAlertIconBasic } from "components/ListAlertIcon"
import { optionsToValueTitle } from "lib/schema"
import { FrequencyOptions } from "constants/selectOptions"
import { PATH } from "constants/path"
import { InvoiceTransactionGroup } from "data/finance/invoiceProcessing/types"

const formatDeadline = (deadline?: DateTime, isOverDeadline?: boolean): React.ReactNode => {
  const deadlineColor = isOverDeadline ? "error" : "textPrimary"
  const color = deadline ? deadlineColor : "textSecondary"
  const content = deadline ? dateTimeUtils.formatDate(deadline) : "N/A"

  return (
    <Typography variant="body2" color={color}>
      {content}
    </Typography>
  )
}

const getPeriodAction = (
  period: InvoiceTransactionGroup,
  selectableIds: SelectableIdsDictionaryModel,
  toggleId: (id: string) => void,
  sx?: SxProps<Theme>
) => {
  if (period.is_failed) return <TransactionFailed />
  if (period.is_queued) return <TransactionQueued />
  if (period.is_processing) return <TransactionInProgress />

  if (!period.is_creditable && !period.is_processable && period.completed_at) {
    return <DoneIcon />
  }

  const hasProcessingEditPermission = Auth.hasPermission([Permission.FINANCE_PROCESSING_EDIT])
  const hasProcessingCreditEditPermission = Auth.hasPermission([Permission.FINANCE_PROCESSING_CREDIT_EDIT])

  if (
    !isNil(selectableIds[period.guid]) &&
    ((hasProcessingEditPermission && period.is_processable) ||
      (hasProcessingCreditEditPermission && period.is_creditable))
  ) {
    return (
      <Box sx={sx}>
        <Field
          {...{
            type: "checkbox",
            name: `${period.is_creditable ? "credit" : "invoice"}[${period.guid}]`,
            checked: selectableIds[period.guid],
            component: Checkbox,
            onClick: (e: React.MouseEvent) => {
              e.stopPropagation()
              toggleId(period.guid)
            }
          }}
        />
        {period.is_creditable ? "(Cr)" : "(Inv)"}
      </Box>
    )
  }
}

export const mapInvoiceTransactionPeriod = ({
  period,
  selectableIds,
  toggleId,
  sx,
  hasCompletedTransactionGroups,
  hasAlertingTransactionGroup,
  hasRewindPermission,
  refetch
}: {
  period: InvoiceTransactionGroup
  selectableIds: SelectableIdsDictionaryModel
  toggleId: (id: string) => void
  sx?: SxProps<Theme>
  hasCompletedTransactionGroups: boolean
  hasAlertingTransactionGroup: boolean
  hasRewindPermission: boolean
  refetch: () => void
}): React.ReactNode[] => {
  const hasModelEditPermission = Auth.hasPermission([Permission.FINANCE_MODEL_EDIT])

  const calendarFrequencies = optionsToValueTitle(FrequencyOptions)

  const defaultItems = [
    hasModelEditPermission ? (
      <Link key={`link-${period.contract_guid}`} to={`${PATH.FINANCE.INVOICE_MODELS}/${period.contract_guid}/settings`}>
        {period.contract_name}
      </Link>
    ) : (
      period.contract_name
    ),
    period.contract_type,
    period.account,
    calendarFrequencies[period.cycle_type ?? ""],
    dateTimeUtils.formatDate(period.start),
    dateTimeUtils.formatDate(period.end),
    period.deadline ? formatDeadline(period.deadline, period.deadline_overdue) : "",
    period.completed_at ? dateTimeUtils.formatDate(period.completed_at) : "",
    `${period.confirmed_percentage}%`,
    `${period.invoiced_percentage}%`,
    `£${period.total.toFixed(2)}`,
    getPeriodAction(period, selectableIds, toggleId, sx)
  ]

  const alertItem = period.is_alerting ? <ListAlertIconBasic /> : null

  const rewindItem = period.completed_at ? (
    <RewindButton rewind_type="Period" item_guids={[period.guid]} refetch={refetch} />
  ) : null

  return [
    ...defaultItems,
    ...(hasRewindPermission && hasCompletedTransactionGroups ? [rewindItem] : []),
    ...(hasAlertingTransactionGroup ? [alertItem] : [])
  ]
}
