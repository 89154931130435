import { Box } from "@mui/material"
import { Link } from "components"
import { Button } from "components/Form"
import { ContractModelType } from "constants/modelTypes"
import React, { memo } from "react"
import { ContractModel } from "data/finance/contractModel/types"
import { ContractModelTitle } from "../../components"

interface OwnProps {
  modelType: ContractModelType
  contractModel: ContractModel
  canEdit: boolean
}

export const InvoiceModelStandingChargeListTitle: React.FC<OwnProps> = memo(({ contractModel, modelType, canEdit }) => {
  InvoiceModelStandingChargeListTitle.displayName = "InvoiceModelStandingChargeListTitle"

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <ContractModelTitle
        {...{
          modelType,
          title: (
            <>
              <Link to={`../settings`}>{contractModel.title}</Link> / Standing Charges
            </>
          )
        }}
      />
      {canEdit && (
        <Link to="new">
          <Button fullWidth={false}>Add Standing Charge</Button>
        </Link>
      )}
    </Box>
  )
})
