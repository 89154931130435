import React, { ReactNode } from "react"
import { Container, SxProps, Theme } from "@mui/material"

interface Props {
  children?: ReactNode
  "data-cy"?: string
  sx?: SxProps<Theme>
}

const ContentContainer = ({ sx, "data-cy": testingId, children }: Props) => {
  if (!children) return null

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={sx ?? ((theme) => ({ padding: theme.spacing(5, 5) }))}
      data-cy={testingId}
    >
      {children}
    </Container>
  )
}
export default ContentContainer
