import React, { useCallback } from "react"
import { Form } from "react-final-form"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { Visit } from "models/Visit"
import { ModalWrapper } from "components"
import { Button } from "components/Form"
import { useSignOffVisitMutation } from "data/visits"

interface OwnProps {
  destroyModal: Function
  visit: Visit
}

const SignOffModal: React.FC<OwnProps> = (props) => {
  const { visit, destroyModal } = props
  const { mutateAsync } = useSignOffVisitMutation()

  const onSubmit = useCallback(async () => {
    await mutateAsync({
      visit: visit.guid,
      report: visit.visit_report.guid
    })

    destroyModal()
  }, [destroyModal, visit, mutateAsync])

  return (
    <ModalWrapper title="Sign off" destroyModal={props.destroyModal}>
      <Typography variant="body1" paragraph>
        You are about to sign off this visit report <strong>without concern</strong>.
      </Typography>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="space-between" m={3} mx={0}>
              <Button variant="text" fullWidth={false} onClick={() => destroyModal()}>
                Cancel
              </Button>
              <Button type="submit" fullWidth={false} disabled={submitting}>
                Sign off
              </Button>
            </Box>
          </form>
        )}
      </Form>
    </ModalWrapper>
  )
}
export default SignOffModal
