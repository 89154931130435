import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { ModalWrapper } from "components"
import { Button } from "components/Form"
import { ContractModelType } from "constants/modelTypes"
import { useContractServiceListQuery } from "data/finance/contractModel/queries"
import { formatDate } from "lib/datetime"
import React, { memo, useCallback } from "react"
import { ContractModel } from "data/finance/contractModel/types"
import { ContractModelUpliftItem } from "../types"

interface Props {
  uplifts: ContractModelUpliftItem[]
  destroyModal: () => void
  contractModel: ContractModel
  modelType: ContractModelType
}

export const ContractModelTableItemUpliftStatusModal: React.FC<Props> = memo(
  ({ uplifts, destroyModal, contractModel, modelType }) => {
    ContractModelTableItemUpliftStatusModal.displayName = "ContractModelTableItemUpliftStatusModal"

    const hasError = uplifts.some((uplift) => !!uplift.errors)

    const { data: services } = useContractServiceListQuery({
      contractGuid: contractModel.guid,
      modelType,
      enabled: hasError
    })

    const getErrorMessage = useCallback(
      (errors?: string) => {
        if (!errors) return ""

        const replacedErrorMessage = errors.replaceAll(contractModel.guid, contractModel.title)

        if (!services?.length) return replacedErrorMessage

        return services.reduce((res, service) => {
          return res.replaceAll(
            service.guid,
            `${service.service_type.title} (${formatDate(service.start)} - ${
              service.end ? formatDate(service.end) : "--/--/--"
            })`
          )
        }, replacedErrorMessage)
      },
      [contractModel, services]
    )

    return (
      <ModalWrapper {...{ title: "Uplift history", width: 700, destroyModal }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Effective from</TableCell>
              <TableCell>Status</TableCell>
              <TableCell width={"60%"}>Errors</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uplifts.map((uplift) => (
              <TableRow key={uplift.guid}>
                <TableCell>{formatDate(uplift.effective_from)}</TableCell>
                <TableCell>{uplift.status}</TableCell>
                <TableCell>{getErrorMessage(uplift.errors)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Box m={3} mx={0} display="flex" justifyContent="flex-end">
          <Button fullWidth={false} variant="text" onClick={destroyModal}>
            Cancel
          </Button>
        </Box>
      </ModalWrapper>
    )
  }
)
