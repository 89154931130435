import React from "react"
import { DateTime } from "luxon"

const CustomAxisXTick = (props: any) => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle">
        {DateTime.fromISO(payload.value).get("day")}
      </text>
    </g>
  )
}

export default CustomAxisXTick
