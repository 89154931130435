import { Divider } from "components"
import { useSelectableIdsDictionary } from "lib/hooks"
import { queryStringToObject } from "lib/queryString"
import React, { memo, useMemo, useState } from "react"
import { useGetChargeContractDates } from "../handlers/useGetChargeContractDates"
import { filterInvoiceTransactionCharges } from "../helpers/filterInvoiceTransactionCharges"
import { defaultChargeListFilter, TransactionDetailChargesFilter } from "./TransactionDetailChargesFilter"
import { TransactionDetailChargesTable } from "./TransactionDetailChargesTable"
import { useLocation } from "react-router-dom"
import { InvoiceTransaction, InvoiceTransactionCharge } from "data/finance/invoiceProcessing/types"
import { InvoiceTransactionChargeFilter } from "../types"
import { CalendarPeriod } from "data/finance/contractModel/types"

interface OwnProps {
  transaction: InvoiceTransaction
  refetchTransaction: () => void
  period: CalendarPeriod
  periodGuid: string
  onExtrasCredited: () => void
  charges?: InvoiceTransactionCharge[]
}

export const TransactionDetailChargeTab: React.FC<OwnProps> = memo(
  ({ transaction, refetchTransaction, period, periodGuid, onExtrasCredited, charges = [] }) => {
    const location = useLocation()

    const contract = useGetChargeContractDates({ transaction })

    const [chargeFilter, setChargeFilter] = useState<InvoiceTransactionChargeFilter>({
      ...queryStringToObject(location?.search),
      ...defaultChargeListFilter
    })
    const filteredCharges = useMemo(
      () => filterInvoiceTransactionCharges({ charges, filter: chargeFilter }),
      [charges, chargeFilter]
    )

    const creditableChargeIds = useMemo(
      () => filteredCharges.filter((charge) => charge.is_creditable).map((charge) => charge.guid),
      [filteredCharges]
    )

    const deletableChargeIds = useMemo(
      () => filteredCharges.filter((charge) => !charge.readonly).map((charge) => charge.guid),
      [filteredCharges]
    )

    const creditableChargesDictionary = useSelectableIdsDictionary(creditableChargeIds)
    const deletableChargesDictionary = useSelectableIdsDictionary(deletableChargeIds)

    return (
      <>
        <TransactionDetailChargesFilter
          {...{
            transaction,
            refetchTransaction,
            period,
            resetAllCreditable: creditableChargesDictionary.resetAll,
            resetAllDeletable: deletableChargesDictionary.resetAll,
            contract,
            chargeFilter,
            setChargeFilter
          }}
        />
        <Divider color="divider" />
        <TransactionDetailChargesTable
          {...{
            refetchTransaction,
            contract,
            period,
            periodGuid,
            charges,
            filteredCharges,
            transaction,
            onExtrasCredited,
            creditableChargesDictionary,
            deletableChargesDictionary
          }}
        />
      </>
    )
  }
)

TransactionDetailChargeTab.displayName = "TransactionDetailChargeTab"
