import React, { memo, PropsWithChildren } from "react"
import RequestTableLoader from "./RequestTableLoader"
import { Box, BoxProps } from "@mui/material"

const RequestLoaderWrapper: React.FC<PropsWithChildren<BoxProps & { isFiltering?: boolean }>> = ({
  children,
  position = "relative",
  isFiltering = false, // handle FE filtering
  ...rest
}) => {
  return (
    <Box {...rest} position={position}>
      <RequestTableLoader isFiltering={isFiltering} />
      {children}
    </Box>
  )
}
export default memo(RequestLoaderWrapper)
