import { TableCell, TableRow } from "@mui/material"
import { grey } from "@mui/material/colors"
import testingId from "constants/testingId"
import React, { memo, useMemo, useState } from "react"
import { InterfaceFileTabItemContracts } from "./InterfaceFileTabItemContracts"
import { InterfaceFileTabItemLink } from "./InterfaceFileTabItemLink"
import { InterfaceFile } from "data/finance/invoiceFile/types"

interface OwnProps {
  file: InterfaceFile
  isInvoice: boolean
}

const borderColor = grey[500]

export const InterfaceFileTabItem: React.FC<OwnProps> = memo(({ file, isInvoice }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false)

  const onRowClick = () => {
    setIsOpened(!isOpened)
  }

  const userCount = useMemo(
    () => file.contracts.map((contract) => contract.count).reduce((previous, current) => previous + current),
    [file]
  )

  return (
    <>
      <TableRow
        sx={{
          cursor: "pointer",
          ...(isOpened
            ? {
                borderLeft: `1px solid ${borderColor}`,
                borderRight: `1px solid ${borderColor}`,
                borderBottom: `1px solid ${borderColor}`,
                backgroundColor: grey[200]
              }
            : {})
        }}
        data-cy={testingId.tableRow}
        onClick={onRowClick}
        hover
      >
        <TableCell data-cy={testingId.tableCell}>{file.reference}</TableCell>
        <TableCell data-cy={testingId.tableCell}>£ {file.total}</TableCell>
        <TableCell data-cy={testingId.tableCell}>{file.contracts.length}</TableCell>
        <TableCell data-cy={testingId.tableCell}>{userCount}</TableCell>
        <TableCell data-cy={testingId.tableCell}>
          <InterfaceFileTabItemLink {...{ file, isInvoice }} />
        </TableCell>
      </TableRow>
      {isOpened ? (
        <TableRow>
          <TableCell
            sx={{
              width: "100%",
              padding: 0,
              borderLeft: `1px solid ${borderColor}`,
              borderRight: `1px solid ${borderColor}`,
              borderBottom: `1px solid ${borderColor}`
            }}
            colSpan={5}
          >
            <InterfaceFileTabItemContracts {...{ contracts: file.contracts }} />
          </TableCell>
        </TableRow>
      ) : null}
    </>
  )
})

InterfaceFileTabItem.displayName = "InterfaceFileTabItem"
