export enum StandingChargeAppointmentModeEnum {
  NONE = "NONE",
  VISITS = "VISITS",
  SHIFTS = "SHIFTS",
  BOTH = "BOTH"
}

export enum StatusEnum {
  EXPIRED = "Expired",
  CURRENT = "Current",
  FUTURE = "Future"
}
