import { TIME_TOKENS_KEY } from "./constants"
import { TimeTokens } from "./types"

export const getNameFirstLetters = (name: string) => {
  const names = name.trim().split(" ")
  let initials = names[0].substring(0, 1)

  if (names.length > 1) {
    initials += names[names.length - 1].trim().substring(0, 1)
  }
  return initials.toUpperCase()
}

export const getCurrentPubnubTimestamp = (): number => Math.floor(Date.now() * 10000)

const getUserTimeTokenKey = (userUuid: string) => `${userUuid}-${TIME_TOKENS_KEY}`

export const getAllTimeTokens = (userUuid: string): TimeTokens => {
  let timeTokens: TimeTokens = {}
  const userKey = getUserTimeTokenKey(userUuid)
  const unparsedTimeTokens: string | null = localStorage.getItem(userKey)
  if (unparsedTimeTokens) {
    timeTokens = JSON.parse(unparsedTimeTokens)
  }

  return timeTokens
}

export const setAllTimeTokens = (userUuid: string, timeTokens: TimeTokens): void => {
  const userKey = getUserTimeTokenKey(userUuid)
  localStorage.setItem(userKey, JSON.stringify(timeTokens))
}

export const setTimeTokenByChannel = (userUuid: string, channel: string, value: number): void => {
  const currentTimeTokens: TimeTokens = getAllTimeTokens(userUuid)
  const newTimeTokens: TimeTokens = {
    ...currentTimeTokens,
    [channel]: value
  }
  setAllTimeTokens(userUuid, newTimeTokens)
}
