import React from "react"
import { Permission } from "constants/permission"
import { Typography } from "@ceracare/dcp-ui"
import Auth from "lib/Auth"
import Link, { LinkProps } from "./Link"

interface Props extends LinkProps {
  permissions?: Permission[]
}

const AuthLink = ({ permissions, ...props }: Props) => {
  if (permissions?.length && !Auth.hasPermission(permissions)) {
    return <Typography variant="body2">{props.children}</Typography>
  }

  return <Link {...props} />
}
export default AuthLink
