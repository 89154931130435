import Grid from "@mui/material/Grid"
import { Select, TextField } from "components/Form"
import { ContractModelRuleGroupRuleFormData } from "pages/Finance/ContractModels/helpers/RuleGroupRule"
import React, { memo } from "react"
import { Field, FormSpy } from "react-final-form"
import validators, { composeValidators } from "lib/validators"
import DatesMessageHelp from "components/DatesMessageHelp"
import RuleGroupFormRuleTable from "./RuleGroupFormRuleTable"
import { NoEndDateCheckbox } from "components"
import { ContractRuleConditionOptionsByType } from "data/finance/contractRuleCondition/types"
import { InvoiceModelRuleGroup } from "data/finance/contractModel/types"
import { useSchema } from "data/schema/useSchema"
import { SelectDummy } from "components/Form/SelectDummy"
import { DateField } from "components/Form/DateField"

interface Props {
  ruleGroupRules: ContractModelRuleGroupRuleFormData[]
  conditions: ContractRuleConditionOptionsByType
  ruleGroup?: InvoiceModelRuleGroup
}

const RuleGroupForm: React.FC<Props> = ({ conditions, ruleGroupRules, ruleGroup }) => {
  const { RuleGroupPrice } = useSchema(["RuleGroupPrice"])

  return (
    <>
      <DatesMessageHelp messageType="E" />
      <Grid container spacing={8}>
        <Grid item md={6}>
          <Field
            name="title"
            label="Group Name *"
            component={TextField}
            validate={composeValidators([validators.required, validators.maxLength(64)])}
          />
          <Grid container>
            <Grid item md={8}>
              {RuleGroupPrice ? (
                <Field
                  name="price_type_alias"
                  label="Fixed Price per Visit *"
                  component={Select}
                  options={RuleGroupPrice}
                  validate={validators.required}
                />
              ) : (
                <SelectDummy value="loading..." />
              )}
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={3}>
              <FormSpy subscription={{ values: true }}>
                {({ values }) =>
                  values.price_type_alias === "NONE" ? null : (
                    <Field
                      component={TextField}
                      name="rate"
                      label="Rate *"
                      validate={validators.validateCommonNumber({ min: 0 })}
                    />
                  )
                }
              </FormSpy>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <>
                <DateField
                  name="start"
                  label="Starts on"
                  required
                  maxDate={values.end ? values.end.minus({ days: 1 }) : undefined}
                />

                <DateField
                  name="end"
                  label="Ends before"
                  minDate={values.start ? values.start.plus({ days: 1 }) : undefined}
                  disabled={!!values.no_end_date}
                />
                <NoEndDateCheckbox />
              </>
            )}
          </FormSpy>
        </Grid>
      </Grid>
      {ruleGroup && ruleGroup.guid ? (
        <RuleGroupFormRuleTable conditions={conditions} ruleGroupRules={ruleGroupRules} />
      ) : null}
    </>
  )
}

export default memo(RuleGroupForm)
