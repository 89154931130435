import { Box, Grid } from "@mui/material"
import { Button, Select, TextField } from "components/Form"
import { SelectOption } from "components/Form/Select"
import { ContractModelType } from "constants/modelTypes"
import validators, { DEFAULT_MAX_NUMBER, composeValidators } from "lib/validators"
import { negate } from "ramda"
import React, { memo, useMemo, useState } from "react"
import { Field, useFormState } from "react-final-form"
import { RateUpliftForm, RateUpliftFormService, RateUpliftTypeEnum } from "../../types"

const upliftTypeOptions: SelectOption[] = [
  { value: RateUpliftTypeEnum.FIXED_AMOUNT, title: "Fixed amount" },
  { value: RateUpliftTypeEnum.PERCENTAGE, title: "Percentage" }
]

const upliftTypeServiceOptions: SelectOption[] = [
  ...upliftTypeOptions,
  { value: RateUpliftTypeEnum.MANUAL, title: "Manual" }
]

const getMinRate = (formService: RateUpliftFormService): number => {
  const rates = (formService.bandItems || []).reduce((res: number[], bandItem) => {
    const values = (bandItem.rates || []).map((rate) => +rate.rate)
    return [...res, ...values]
  }, [])

  return negate(Math.min(...rates))
}

interface OwnProps {
  isServiceLevel: boolean
  upliftType?: RateUpliftTypeEnum
  upliftFieldsOwnerName: string
  formService?: RateUpliftFormService
}

// upliftFieldsOwnerName === "" ==> selects for all models
// !isServiceLevel && upliftFieldsOwnerName ==> selects for model
// isServiceLevel ==> selects for service

export const RateUpliftSelect: React.FC<OwnProps> = memo(
  ({ isServiceLevel, upliftType, upliftFieldsOwnerName, formService }) => {
    RateUpliftSelect.displayName = "RateUpliftSelect"
    const {
      values: { modelType }
    } = useFormState<RateUpliftForm>()

    const isGlobal = !upliftFieldsOwnerName

    const upliftTypeName = isGlobal ? "uplift_type" : `${upliftFieldsOwnerName}.uplift_type`
    const upliftTypeSelectOptions = isServiceLevel ? upliftTypeServiceOptions : upliftTypeOptions
    const upliftTypeFieldValidators = isServiceLevel ? { validate: validators.required } : {}

    const showUpliftInput = !!upliftType && upliftType !== RateUpliftTypeEnum.MANUAL
    const upliftValueName = isGlobal ? "uplift_value" : `${upliftFieldsOwnerName}.uplift_value`

    const upliftValueFieldValidators = useMemo(() => {
      const precision = upliftType === RateUpliftTypeEnum.PERCENTAGE ? 0 : 3

      const minFixedValue = isServiceLevel && formService ? getMinRate(formService) : 0
      const minValue = upliftType === RateUpliftTypeEnum.PERCENTAGE ? -100 : minFixedValue

      if (isServiceLevel) {
        return composeValidators([
          validators.required,
          validators.mustBeNumber,
          validators.minValue(minValue),
          validators.maxValue(DEFAULT_MAX_NUMBER),
          validators.precision(precision)
        ])
      }

      return (value?: number | string): string | undefined => {
        if (value) {
          return composeValidators([
            validators.mustBeNumber,
            validators.minValue(minValue),
            validators.maxValue(DEFAULT_MAX_NUMBER),
            validators.precision(precision)
          ])(value)
        }
      }
    }, [isServiceLevel, upliftType, formService])

    const upliftValueLabelSuffix = upliftType === RateUpliftTypeEnum.FIXED_AMOUNT ? "absolute" : "percentage"

    const [showSelect, setShowSelect] = useState(isServiceLevel)

    const labelType = modelType === ContractModelType.INVOICE ? "Invoice" : "Pay"
    const showButtonLabel = isGlobal
      ? `Apply the same rate update to all ${labelType} Models`
      : "Apply the same rate update to all Services"

    const handleOnClick = () => setShowSelect((prev) => !prev)

    return (
      <Grid
        container
        spacing={1}
        sx={(isGlobal || !isServiceLevel) && !showSelect ? { justifyContent: "flex-end" } : { alignItems: "center" }}
      >
        {showSelect ? (
          <>
            <Grid item md={3}>
              <Field<string>
                {...{
                  name: upliftTypeName,
                  label: "Uplift Type",
                  component: Select,
                  options: upliftTypeSelectOptions,
                  ...upliftTypeFieldValidators
                }}
              />
            </Grid>
            {showUpliftInput && (
              <Grid item md={3}>
                <Field
                  {...{
                    name: upliftValueName,
                    label: `Input Number (${upliftValueLabelSuffix})`,
                    component: TextField,
                    validate: upliftValueFieldValidators
                  }}
                />
              </Grid>
            )}
            {!isServiceLevel && (
              <Box>
                <Button type="button" variant="text" onClick={handleOnClick}>
                  Hide
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Box>
            <Button type="button" variant="text" onClick={handleOnClick}>
              {showButtonLabel}
            </Button>
          </Box>
        )}
      </Grid>
    )
  }
)
