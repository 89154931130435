import React, { Dispatch, memo, SetStateAction } from "react"
import Grid from "@mui/material/Grid"
import { DayFilter, DayFilterSelect } from "components"
import { DateTime } from "luxon"

interface ShiftListDayFilterProps {
  day: DateTime
  setDay: Dispatch<SetStateAction<DateTime>>
}

const ShiftListDayFilter = ({ day, setDay }: ShiftListDayFilterProps) => (
  <Grid
    container
    sx={(theme) => ({
      borderBottom: "1px solid",
      borderColor: theme.palette.primary.main,
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(2)
    })}
    alignItems="flex-end"
  >
    <Grid item md={4}>
      <DayFilter setDay={setDay} day={day} />
    </Grid>
    <Grid item md={4}></Grid>
    <Grid item md={4}>
      <DayFilterSelect setDay={setDay} day={day} />
    </Grid>
  </Grid>
)

export default memo(ShiftListDayFilter)
