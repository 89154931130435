import { Box, TableCell, Typography } from "@mui/material"
import datetime, { DateTimeFormat } from "lib/datetime"
import React, { memo, useCallback, useMemo } from "react"
import { additionalOwnerLink } from "./helpers/additionalOwnerLink"
import {
  AdditionalSearchFormValues,
  ApiAdditionalSearch,
  ApiPaymentAdditionalSearch
} from "data/finance/additional/type"
import MoneyWrapper from "components/MoneyWrapper"
import { AdditionalSearchTableItemStatus } from "./AdditionalSearchTableItemStatus"
import { getContractModelLink } from "../components/helpers/getContractModelLink"
import { AdditionalSearchTableItemCheckbox } from "./AdditionalSearchTableItemCheckbox"
import { useNavigate } from "react-router-dom"
import { SelectableTableRow } from "components/SelectableTableRow"

interface OwnProps {
  additional: ApiAdditionalSearch | ApiPaymentAdditionalSearch
  isInvoiceModel: boolean
  mappedChargeTypes: Record<string, string>
  canApprove: boolean
  values: AdditionalSearchFormValues
}

export const AdditionalSearchTableItem: React.FC<OwnProps> = memo(
  ({ additional, isInvoiceModel, mappedChargeTypes, canApprove, values }) => {
    AdditionalSearchTableItem.displayName = "AdditionalSearchTableItem"
    const navigate = useNavigate()
    const pickedStatus = values.pickedStatus
    const isPicked = (values.items || {})[additional.guid]

    const transactionUrl = useMemo(
      () =>
        `/finance/${isInvoiceModel ? "invoice-processing" : "payment-processing"}/${additional.calendar_guid}/${
          additional.calendar_period_guid
        }/${additional.contract_guid}/${additional.transaction_guid}?tab=${
          additional.extra_type === "ShiftExtra" ? "VI" : "EX"
        }`,
      [additional, isInvoiceModel]
    )

    const onClick = useCallback(() => {
      navigate(transactionUrl)
    }, [transactionUrl, navigate])

    return (
      <SelectableTableRow key={additional.guid} hover clickable isSelected={isPicked} onClick={onClick}>
        {!isInvoiceModel && canApprove && (
          <TableCell>
            <AdditionalSearchTableItemCheckbox
              {...{ additional: additional as ApiPaymentAdditionalSearch, pickedStatus }}
            />
          </TableCell>
        )}
        <TableCell
          sx={(theme) => ({
            color: additional.status === "Rejected" ? theme.palette.text.disabled : theme.palette.text.primary
          })}
        >
          {additional.event_date ? datetime.formatDate(additional.event_date, DateTimeFormat.DATE_TIME) : null}
        </TableCell>

        <TableCell>
          {additionalOwnerLink({
            owner_type: additional.owner_type,
            owner_guid: additional.owner_guid,
            owner_name: additional.owner_name,
            isInvoiceModel
          })}
        </TableCell>
        <TableCell>
          {getContractModelLink({
            contract_guid: additional.contract_guid,
            contract_title: additional.contract_title,
            isInvoiceModel
          })}
        </TableCell>
        <TableCell sx={{ width: "25%" }}>
          <Box
            sx={(theme) => ({
              display: "flex",
              flexFlow: "column",
              flexGrow: 0,
              color: additional.status === "Rejected" ? theme.palette.text.disabled : theme.palette.text.primary
            })}
          >
            <span>{mappedChargeTypes[additional.charge_type_alias]}</span>
            <Typography
              variant="body2"
              component="span"
              sx={(theme) => ({
                color: additional.status === "Rejected" ? theme.palette.text.disabled : theme.palette.text.secondary
              })}
            >
              {additional.description}
            </Typography>
          </Box>
        </TableCell>
        <TableCell
          sx={(theme) => ({
            color: additional.status === "Rejected" ? theme.palette.text.disabled : theme.palette.text.primary
          })}
        >
          {additional.extra_type}
        </TableCell>
        <TableCell
          sx={(theme) => ({
            color: additional.status === "Rejected" ? theme.palette.text.disabled : theme.palette.text.primary
          })}
        >
          {additional.transaction_reference}
        </TableCell>
        <TableCell
          sx={(theme) => ({
            color: additional.status === "Rejected" ? theme.palette.text.disabled : theme.palette.text.primary,
            whiteSpace: "nowrap"
          })}
        >
          <MoneyWrapper {...{ amount: additional.total }} />
        </TableCell>
        {!isInvoiceModel && (
          <TableCell>
            <AdditionalSearchTableItemStatus {...{ additional: additional as ApiPaymentAdditionalSearch }} />
          </TableCell>
        )}
      </SelectableTableRow>
    )
  }
)
