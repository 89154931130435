import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { EmptyData } from "components"
import { Button } from "components/Form"
import { optionsToValueTitle } from "lib/schema"
import React, { memo, ReactNode, useCallback, useMemo, useState } from "react"
import { Form } from "react-final-form"
import testingId from "constants/testingId"
import ContributionModal from "./ContributionModal"
import Auth from "lib/Auth"
import { SelectableIdsDictionary } from "lib/hooks"
import { ContractModelType } from "constants/modelTypes"
import { useProcessingExtraRowClick } from "../handlers/useProcessingExtraRowClick"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { getChargeTableHeaderLabels } from "../helpers/getChargeTableHeaderLabels"
import { mapInvoiceTransactionCharges } from "../helpers/mapInvoiceTransactionCharges"
import { TransactionDetailChargeActionButtons } from "./TransactionDetailChargeActionButtons"
import { isEmpty } from "ramda"
import { Permission } from "constants/permission"
import { BasicPeriod } from "lib/types"
import { TransactionItemStatus } from "components/TransactionItemStatus"
import { useSchema } from "data/schema/useSchema"
import { InvoiceTransaction, InvoiceTransactionCharge } from "data/finance/invoiceProcessing/types"
import { CalendarPeriod } from "data/finance/contractModel/types"

interface ChargesTableProps {
  charges: InvoiceTransactionCharge[]
  filteredCharges: InvoiceTransactionCharge[]
  transaction: InvoiceTransaction
  periodGuid: string
  onExtrasCredited: Function
  creditableChargesDictionary: SelectableIdsDictionary
  deletableChargesDictionary: SelectableIdsDictionary
  period: CalendarPeriod
  contract?: BasicPeriod
  refetchTransaction: () => void
}

export const TransactionDetailChargesTable: React.FC<ChargesTableProps> = memo(
  ({
    contract,
    charges,
    filteredCharges,
    transaction,
    periodGuid,
    onExtrasCredited,
    creditableChargesDictionary,
    deletableChargesDictionary,
    period,
    refetchTransaction
  }) => {
    const [modal, setModal] = useState<ReactNode>(null)
    const destroyModal = useCallback(() => setModal(null), [setModal])

    const { TransactionCharge } = useSchema(["TransactionCharge"])
    const chargeTypes = optionsToValueTitle(TransactionCharge)

    const hasTransactionReference = !!transaction.reference
    const canCreditExtras = hasTransactionReference && Auth.hasPermission(["Finance.Processing.Credit:Edit"])
    const canEditExtras = !hasTransactionReference && Auth.hasPermission([Permission.FINANCE_PROCESSING_CHARGES_EDIT])
    const canEditContributions = Auth.hasPermission(["User.Operator:Edit", "Finance.Settings:Edit"])

    // credit
    const {
      selectableIds: selectableCreditIds,
      toggleId: toggleCreditId,
      toggleAll: toggleCreditAll
    } = creditableChargesDictionary

    // delete
    const { selectableIds: selectableDeleteIds, toggleId: toggleDeleteId } = deletableChargesDictionary

    const data = useMemo(() => {
      return mapInvoiceTransactionCharges({
        charges: filteredCharges,
        transaction,
        chargeTypes,
        selectableIds: selectableCreditIds,
        canCreditExtras,
        canEditExtras,
        toggleId: toggleCreditId,
        selectableDeleteIds,
        toggleDeleteId,
        setModal,
        period,
        destroyModal,
        contract,
        refetchTransaction,
        canEditContributions
      })
    }, [
      filteredCharges,
      transaction,
      canEditExtras,
      chargeTypes,
      selectableCreditIds,
      canCreditExtras,
      toggleCreditId,
      selectableDeleteIds,
      toggleDeleteId,
      setModal,
      period,
      destroyModal,
      contract,
      refetchTransaction,
      canEditContributions
    ])

    const handleRowClick = useProcessingExtraRowClick({
      filteredCharges,
      modelType: ContractModelType.INVOICE
    })

    const selectAllForCreditButtonDisabled = isEmpty(selectableCreditIds)

    return (
      <RequestLoaderWrapper>
        <Form onSubmit={() => undefined}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TableContainer>
                <Table stickyHeader data-cy={testingId.invoiceProcessingTransactionDetail.extrasList}>
                  <TableHead>
                    <TableRow>
                      {getChargeTableHeaderLabels(transaction, canEditExtras).map((label, index) => (
                        <TableCell key={`headerLabel-${index}`} sx={!index ? { padding: 0 } : undefined}>
                          {label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isEmpty(data) ? (
                      Object.values(data).map((chargeRow, rowIdx) => {
                        const clickedCharge = filteredCharges[rowIdx]
                        const clickable = chargeRow.isClickable

                        return (
                          <TableRow
                            key={rowIdx}
                            hover={clickable}
                            sx={clickable ? { cursor: "pointer" } : undefined}
                            onClick={() =>
                              handleRowClick({
                                rowIdx,
                                setModal: () => {
                                  setModal(
                                    <ContributionModal
                                      destroyModal={destroyModal}
                                      periodGuid={periodGuid}
                                      transactionGuid={transaction.guid}
                                      charge={clickedCharge}
                                    />
                                  )
                                }
                              })
                            }
                            data-cy={testingId.invoiceProcessingTransactionDetail.extrasListItem}
                          >
                            <TransactionItemStatus item={filteredCharges[rowIdx]} />
                            {chargeRow.row.map((value, cellIdx) => {
                              return (
                                <TableCell
                                  key={cellIdx}
                                  data-cy={testingId.invoiceProcessingTransactionDetail.extrasListItemCell}
                                >
                                  {value}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        )
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={getChargeTableHeaderLabels(transaction, canEditExtras).length}>
                          <EmptyData message="No visits found for filtered selection." />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {canCreditExtras ? (
                <Box {...{ p: 1, px: 0, display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    fullWidth={false}
                    variant={"text"}
                    color={"secondary"}
                    onClick={toggleCreditAll}
                    disabled={selectAllForCreditButtonDisabled}
                    data-cy={testingId.invoiceProcessingTransactionDetail.toggleAllForCrediting}
                  >
                    (Un)Select all for crediting
                  </Button>
                </Box>
              ) : null}
              <TransactionDetailChargeActionButtons
                {...{
                  charges,
                  filteredCharges,
                  transaction,
                  periodGuid,
                  onExtrasCredited,
                  creditableChargesDictionary,
                  deletableChargesDictionary,
                  period,
                  refetchTransaction,
                  setModal,
                  destroyModal,
                  canCreditExtras,
                  canEditExtras
                }}
              />
            </form>
          )}
        </Form>
        {modal !== null && modal}
      </RequestLoaderWrapper>
    )
  }
)

TransactionDetailChargesTable.displayName = "TransactionDetailChargesTable"
