import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import React, { memo } from "react"
import { Link } from "components"
import { Field, FormSpy } from "react-final-form"
import { Button, Checkbox, FormSectionHeader, Select, TextField } from "components/Form"
import { grey } from "@mui/material/colors"
import validators, { composeValidators } from "lib/validators"
import { FormApi } from "final-form"
import testingId from "constants/testingId"
import {
  ContractModelService,
  ContractModelServiceRequestWithBands,
  ServiceBandRoundingTypeAlias
} from "data/finance/contractModel/types"
import { BandGenerator } from "./BandGenerator"
import { FieldArray } from "react-final-form-arrays"
import { BandEditorHeader } from "./BandEditorHeader"
import { useSchema } from "data/schema/useSchema"
import { SelectDummy } from "components/Form/SelectDummy"

const validateRoundingMinutes =
  (index: number) => (value: string, other: Partial<ContractModelServiceRequestWithBands>) => {
    if ((other.bands || [])[index]?.rounding_type_alias === "NONE") return false

    return composeValidators([
      validators.required,
      validators.mustBeNumber,
      validators.minValue(1),
      validators.precision(0)
    ])(value)
  }

interface OwnProps {
  service: ContractModelService
}

export const BandEditor: React.FC<OwnProps> = memo(({ service }) => {
  const { Rounding } = useSchema(["Rounding"])

  const isFix = service.payment_type_alias === "FIX"
  const title = isFix ? "Fixed price bands" : "Pro-rata bands"

  const onRoundingTypeClick =
    ({
      form,
      index
    }: {
      form: FormApi<ContractModelServiceRequestWithBands, Partial<ContractModelServiceRequestWithBands>>
      index: number
    }) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const aliasValue = e.target.value
      if (aliasValue === ServiceBandRoundingTypeAlias.NONE) {
        // @ts-ignore
        form.change(`bands[${index}][rounding_minutes]`, undefined)
      }
    }

  const newBand = { rounding_type_alias: ServiceBandRoundingTypeAlias.NONE, rounding_minutes: undefined }

  return (
    <>
      <FormSectionHeader title={title} data-cy={testingId.bandEditor.bandTitle} />
      <FormSpy<ContractModelServiceRequestWithBands>>
        {({ form }) => (
          <FieldArray name="bands">
            {(bandArrayProps) => {
              const fields = bandArrayProps.fields

              return (
                <>
                  <BandGenerator {...{ bandArrayProps }} />
                  <Table size="small">
                    <BandEditorHeader />
                    <TableBody>
                      {fields.map((name, index) => {
                        const field = fields.value[index]

                        return (
                          <TableRow
                            data-cy={testingId.contractModel.services.bandRow}
                            key={index}
                            style={{
                              opacity: field.delete === true ? 0.3 : 1,
                              backgroundColor: field.delete === true ? grey[100] : "transparent"
                            }}
                          >
                            <TableCell>
                              <Field
                                type="checkbox"
                                label=""
                                component={Checkbox}
                                name={`${name}.delete`}
                                data-cy={testingId.contractModel.services.deleteCheckbox}
                              />
                              <Field type="hidden" component="input" name={`${name}.guid`} />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`${name}.minimum_duration_minutes`}
                                label="Minimum duration (minutes)"
                                data-cy={testingId.contractModel.services.minimumDurationInput}
                                component={TextField}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`${name}.maximum_duration_minutes`}
                                label="Maximum duration (minutes)"
                                data-cy={testingId.contractModel.services.maximumDurationInput}
                                component={TextField}
                              />
                            </TableCell>
                            <TableCell align="right">
                              {Rounding ? (
                                <Field
                                  name={`${name}.rounding_type_alias`}
                                  data-cy={testingId.contractModel.services.roundingRuleSelect}
                                  component={Select}
                                  options={Rounding}
                                  onClick={onRoundingTypeClick({ form, index })}
                                />
                              ) : (
                                <SelectDummy value="loading..." />
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <Field
                                name={`${name}.rounding_minutes`}
                                data-cy={testingId.contractModel.services.roundingInput}
                                component={TextField}
                                disabled={field.rounding_type_alias === "NONE"}
                                validate={validateRoundingMinutes(index)}
                              />
                            </TableCell>
                            <TableCell align="right">
                              {field.guid ? (
                                <Link data-cy={testingId.contractModel.services.ratesLink} to={`rates/${field.guid}`}>
                                  RATES
                                </Link>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                  <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                    <Button
                      fullWidth={false}
                      data-cy={testingId.contractModel.services.addRowButton}
                      onClick={() => fields.push(newBand)}
                    >
                      Add new row
                    </Button>
                  </Box>
                </>
              )
            }}
          </FieldArray>
        )}
      </FormSpy>
    </>
  )
})

BandEditor.displayName = "BandEditor"
