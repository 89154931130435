import { TableRow } from "@mui/material"
import testingId from "constants/testingId"
import React, { CSSProperties, memo, MouseEvent, PropsWithChildren } from "react"

interface Props {
  "data-cy"?: string
  hover?: boolean
  isSelected?: boolean
  isReady?: boolean
  clickable?: boolean
  onClick?: (e: MouseEvent) => void
  sx?: CSSProperties
}

export const SelectableTableRow = memo(
  ({
    children,
    "data-cy": dataCy = testingId.tableRow,
    hover,
    isSelected,
    isReady,
    clickable,
    onClick,
    sx: sxProps = {}
  }: PropsWithChildren<Props>) => {
    return (
      <TableRow
        data-cy={dataCy}
        onClick={onClick}
        hover={hover}
        selected={isSelected}
        sx={{
          cursor: clickable ? "pointer" : "default",
          backgroundColor: isReady ? "success.tint" : "background.default",
          position: "relative",
          ...sxProps
        }}
      >
        {children}
      </TableRow>
    )
  }
)

SelectableTableRow.displayName = "SelectableTableRow"
