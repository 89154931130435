import { List } from "@mui/material"
import { DetailSidebar, NavItem } from "components"
import React, { memo } from "react"
import { UnitContractList } from "./UnitContractList"
import { UnitEdit } from "./UnitEdit"
import FolderIcon from "@mui/icons-material/Folder"
import { UnitContractDetail } from "./UnitContractDetail"
import { useUnitDetailQuery } from "data/finance/unit/queries"
import { UnitUserList } from "./UnitUserList"
import { UnitUserCreate } from "./UnitUserCreate"
import { UnitUserEdit } from "./UnitUserEdit"
import { UnitShiftCategoryList } from "./UnitShiftCategoryList"
import { UnitShiftCategoryEdit } from "./UnitShiftCategoryEdit"
import { UnitShiftCategoryCreate } from "./UnitShiftCategoryCreate"
import { Navigate, Route, useParams } from "react-router-dom"
import SentryRoutes from "components/Routes"

export const UnitDetail: React.FC = memo(() => {
  const { unitGuid } = useParams()
  const { data: unit } = useUnitDetailQuery({ unitGuid })

  if (!unit) return null

  return (
    <>
      <DetailSidebar>
        <List
          component="nav"
          sx={(theme) => ({
            marginTop: theme.spacing(20)
          })}
        >
          <NavItem
            {...{ to: `/units/${unitGuid}/edit`, label: "Unit Details", icon: <FolderIcon color="primary" /> }}
          />
          <NavItem
            {...{ to: `/units/${unitGuid}/contracts`, label: "Contracts", icon: <FolderIcon color="primary" /> }}
          />
          <NavItem {...{ to: `/units/${unitGuid}/users`, label: "Users", icon: <FolderIcon color="primary" /> }} />
          <NavItem
            {...{
              to: `/units/${unitGuid}/shift-categories`,
              label: "Shift Categories",
              icon: <FolderIcon color="primary" />
            }}
          />
        </List>
      </DetailSidebar>
      <SentryRoutes>
        <Route path="/" element={<Navigate replace to={`/units/${unitGuid}/edit`} />} />
        <Route path="/edit" element={<UnitEdit {...{ unit }} />} />
        <Route path="/contracts" element={<UnitContractList {...{ unit }} />} />
        <Route path="/contracts/new" element={<UnitContractDetail {...{ unit }} />} />
        <Route path="/contracts/:contractId" element={<UnitContractDetail {...{ unit }} />} />
        <Route path="/users" element={<UnitUserList {...{ unit }} />} />
        <Route path="/users/new" element={<UnitUserCreate {...{ unit }} />} />
        <Route path="/users/:userGuid" element={<UnitUserEdit {...{ unit }} />} />
        <Route path="/shift-categories" element={<UnitShiftCategoryList {...{ unit }} />} />
        <Route path="/shift-categories/:unitShiftCategoryGuid" element={<UnitShiftCategoryEdit {...{ unit }} />} />
        <Route path="/shift-categories/new" element={<UnitShiftCategoryCreate {...{ unit }} />} />
      </SentryRoutes>
    </>
  )
})

UnitDetail.displayName = "UnitDetail"
