import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { CompanyApi } from "api"
import { ApiBranchFeature } from "./types"
import { enabledByMandatoryUrlParams } from "data/helpers"

export enum QueryKeyParts {
  BRANCH_FEATURES = "branch-features",
  COMPANY = "company",
  COMPANY_FEATURES = "company-features"
}

export const queryKeys = {
  getCompany: (branchGuid?: string): QueryKey => [QueryKeyParts.COMPANY, { branchGuid }],
  getBranchFeaturesKey: (branchGuid?: string): QueryKey => [QueryKeyParts.BRANCH_FEATURES, { branchGuid }],
  getCompanyFeaturesKey: (branchGuid?: string, companyGuid?: string): QueryKey => [
    QueryKeyParts.COMPANY_FEATURES,
    { branchGuid, companyGuid }
  ]
}

export const useBranchFeaturesQuery = ({
  branchGuid,
  enabled = true,
  retries
}: {
  branchGuid: string | undefined
  enabled?: boolean
  retries?: number
}): UseQueryResult<ApiBranchFeature[], Error> => {
  return useQuery({
    ...enabledByMandatoryUrlParams([branchGuid, enabled]),
    staleTime: 5 * (60 * 1000), // 5 mins
    cacheTime: 10 * (60 * 1000), // 10 mins
    queryKey: queryKeys.getBranchFeaturesKey(branchGuid),
    retry: retries,
    queryFn: async () => CompanyApi.get(`/v1/branches/${branchGuid}/features`)
  })
}
