import { useEffect, useState } from "react"
import Config from "./config"
import type { BranchesListResponse } from "data/core/operator/queries"
import { useLocation } from "react-router-dom"

declare global {
  interface Window {
    Intercom?: (
      method: string,
      config?: {
        [key: string]: string | number | { company_id: string; name: string }[] | { user_id: string; email: string }
      }
    ) => void
  }
}

interface Props {
  userId?: string
  isLoading: boolean
  branches?: BranchesListResponse[]
}

const useIntercom = ({ userId = "", isLoading, branches }: Props): void => {
  const INTERCOM_ID = Config.getEnvVariable("APP_INTERCOM_ID")
  const [initialized, setInitialized] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    const intercomScript = document.getElementById("#cera-intercom")

    if (!head || !INTERCOM_ID || intercomScript) return

    script.setAttribute("id", "cera-intercom")
    script.setAttribute("src", `https://widget.intercom.io/widget/${INTERCOM_ID}`)
    script.setAttribute("async", "true")
    script.onload = () => setInitialized(true)
    head.prepend(script)

    return () => {
      head.removeChild(script)
    }
  }, [INTERCOM_ID])

  useEffect(() => {
    if (initialized && INTERCOM_ID && window.Intercom && !isLoading && branches) {
      window.Intercom("boot", {
        app_id: INTERCOM_ID,
        api_base: "https://api-iam.eu.intercom.io",
        user_data: {
          user_id: userId,
          email: userId
        },
        companies: branches.map(({ guid, title }) => ({
          company_id: guid,
          name: title
        }))
      })
    }
  }, [initialized, INTERCOM_ID, userId, isLoading, branches])

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("update", { last_request_at: new Date().getTime() / 1000 })
    }
  }, [location])
}

export default useIntercom
