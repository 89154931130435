import React, { memo } from "react"
import { Form } from "react-final-form"
import { EmptyData, StickyBox } from "components"
import { Button } from "components/Form"
import { Divider } from "@mui/material"
import { ModelTable } from "pages/Finance/ContractModels/components/calendars/Table/index"
import DatesMessageHelp from "components/DatesMessageHelp"
import testingId from "constants/testingId"
import { mapCalendarsToRows } from "../../helpers/mapCalendarsToRows"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { useNavigate } from "react-router-dom"
import { CalendarResponse } from "data/finance/contractModel/types"

interface Props {
  calendars: CalendarResponse[]
  enableDelete: boolean
  onSelectRow: (rowId: string) => void
  onDelete: () => Promise<void>
  isInvoiceModel: boolean
}

const headers = (isInvoiceModel: boolean) => [
  "Name",
  `${isInvoiceModel ? "Billing" : "Payment"} Frequency`,
  "Starts on",
  "Ends before",
  "Status"
]

const CalendarListTable: React.FC<Props> = ({ calendars, enableDelete, onSelectRow, onDelete, isInvoiceModel }) => {
  const navigate = useNavigate()
  const rows = mapCalendarsToRows(navigate, calendars)

  const emptyMessage = `No ${isInvoiceModel ? "billing" : "payment"} calendar found, start by adding one.`

  return (
    <RequestLoaderWrapper>
      <Form {...{ onSubmit: () => ({}) }}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Divider
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                height: "2px",
                backgroundColor: "divider"
              }}
            />
            <DatesMessageHelp messageType="E" />
            {calendars && calendars.length ? (
              <ModelTable>
                <ModelTable.Head data={headers(isInvoiceModel)} addDeleteIcon />
                <ModelTable.Body
                  listName="calendars"
                  rows={rows}
                  onRowChecked={onSelectRow}
                  sx={{
                    cursor: "pointer"
                  }}
                />
              </ModelTable>
            ) : (
              <EmptyData message={emptyMessage} />
            )}
            <StickyBox>
              <Button
                type="submit"
                fullWidth={false}
                disabled={!enableDelete}
                onClick={() => onDelete()}
                data-cy={testingId.contractModel.calendar.list.deleteButton}
              >
                DELETE
              </Button>
            </StickyBox>
          </form>
        )}
      </Form>
    </RequestLoaderWrapper>
  )
}

export default memo(CalendarListTable)
