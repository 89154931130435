import { useLocation } from "react-router-dom"
import { removeFromSearchString } from "lib/helpers/removeFromSearchString"
import { objectToQueryString, queryStringToObject } from "lib/queryString"
import { DateTime } from "luxon"
import { filter, isEmpty, isNil, omit, prop } from "ramda"

interface EnabledQuery {
  enabled: boolean
}

export const enabledById = (id?: string): EnabledQuery => ({
  enabled: !isNil(id)
})

export const enabledByMandatoryUrlParams = (
  paramArr: Array<string | number | boolean | undefined | null>
): EnabledQuery => ({
  enabled:
    !paramArr.includes(undefined) && !paramArr.includes(null) && !paramArr.includes(false) && !paramArr.includes("")
})

export const enabledByMandatoryUrlSearch = ({
  param,
  withSearch = false
}: {
  param?: string
  withSearch?: boolean
}): EnabledQuery => ({ enabled: !withSearch || (!isNil(param) && param !== "") })

export const setDayBorders = ({ search, from, to }: { search?: string; from: string; to: string }): string => {
  const searchObject = filter((val) => val !== "undefined")(queryStringToObject(search))

  const start = prop(from, searchObject)
  const end = prop(to, searchObject)
  const rest = omit([from, to], searchObject)

  const newSearch = objectToQueryString({
    ...(start ? { [from]: DateTime.fromISO(start).startOf("day").toISO() } : {}),
    ...(end ? { [to]: DateTime.fromISO(end).endOf("day").toISO() } : {}),
    ...rest
  })

  return newSearch.length ? `?${newSearch}` : ""
}

export const isNotZero = (val: string | number | undefined): boolean => {
  if (!val) return false
  return !!+val
}

export const useIsBeingSearched = <T>(data: T[] | undefined): boolean => {
  const location = useLocation()
  const isSearched = !!removeFromSearchString({ search: location.search, remove: ["page", "page_size"] })

  return !isNil(data) && (!isEmpty(data) || isSearched)
}

export const filterDeletableItems = <T extends { delete?: boolean }>(items?: T[]): Omit<T, "delete">[] =>
  items?.filter((item) => !item.delete).map((item) => omit(["delete"], item)) || []
