import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { BackButton, ContentContainer, Divider, Paginator } from "components"
import React, { memo, ReactNode, useCallback, useMemo, useState } from "react"
import { ContractModelType } from "constants/modelTypes"
import TransactionListFilter, { defaultPaymentTransactionFilter } from "./components/TransactionListFilter"
import TransactionListHeader from "./components/TransactionListHeader"
import TransactionListTable from "./components/TransactionListTable"
import TransactionListAddButton from "./components/TransactionListAddButton"
import TransactionListCost from "./components/TransactionListCost"
import { usePaymentProcessingTransactionListQuery } from "data/finance/paymentProcessing/queries"
import { usePagination } from "components/handlers/usePagination"
import { Form } from "react-final-form"
import { useSelectableIdsDictionary } from "lib/hooks"
import { TransactionListActionButtons } from "./components/TransactionListActionButtons"
import { CreditingModal } from "../components"
import { TransactionListSelectButton } from "./components/TransactionListSelectButton"
import { queryStringToObject } from "lib/queryString"
import { usePeriodQuery } from "data/finance/contractModel/queries"
import { Location, useLocation, useParams } from "react-router-dom"
import { PaymentTransactionFilter } from "./types"

interface Props {
  calendarGuid: string
  periodGuid: string
  contractGuid: string
  location?: Location
}

const Component: React.FC<Props> = ({ location, calendarGuid, periodGuid, contractGuid }) => {
  const { data: period } = usePeriodQuery({
    modelType: ContractModelType.PAYMENT,
    contractGuid,
    calendarGuid,
    periodGuid
  })

  const { data: transactions, refetch } = usePaymentProcessingTransactionListQuery({
    periodGuid,
    search: location?.search
  })

  const { pagination, setPagination, resetPagination } = usePagination()
  const [filter, setFilter] = useState<PaymentTransactionFilter>({
    ...defaultPaymentTransactionFilter,
    ...queryStringToObject(location?.search)
  })
  const [modal, setModal] = useState<ReactNode>(null)

  const selectableIdsForCrediting = useMemo(() => {
    return (transactions || []).reduce((res: string[], trans) => (trans.is_creditable ? [...res, trans.guid] : res), [])
  }, [transactions])

  const { toggleId, toggleAll, getSelectedAsStringArr, selectableIds, resetAll } =
    useSelectableIdsDictionary(selectableIdsForCrediting)
  const selectedIds = useMemo(() => getSelectedAsStringArr(), [getSelectedAsStringArr])

  const onOk = useCallback(() => {
    resetAll()
    refetch()
    setModal(null)
  }, [refetch, resetAll])

  const onCancel = useCallback(() => {
    setModal(null)
  }, [])

  const handleCreditClick = useCallback(() => {
    setModal(
      <CreditingModal
        {...{
          guids: selectedIds,
          onCancel,
          isTransaction: true,
          modelType: ContractModelType.PAYMENT,
          onOk,
          periodGuid
        }}
      />
    )
  }, [onCancel, onOk, selectedIds, periodGuid])

  if (!period) return null

  return (
    <ContentContainer>
      <BackButton fallbackUrl="../payment-processing" />
      <TransactionListHeader {...{ contractGuid, period }} />
      <Box m={3} mx={0}>
        <TransactionListFilter {...{ location, resetPagination, pagination, filter, setFilter }} />

        <Grid container spacing={1}>
          <Grid item md={10}>
            <TransactionListCost {...{ transactions }} />
          </Grid>
          <Grid item md={2}>
            <TransactionListAddButton {...{ periodGuid, refetch }} />
          </Grid>
        </Grid>
        <Divider color="divider" />
        <Form onSubmit={() => ({})}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TransactionListTable {...{ transactions, toggleId, selectableIds, setFilter, refetch, selectedIds }} />
            </form>
          )}
        </Form>
        <Paginator {...{ setPagination, itemsLength: transactions?.length }} />
        <TransactionListSelectButton {...{ toggleAll, selectableIdsForCrediting }} />
        <TransactionListActionButtons {...{ selectableIdsForCrediting, selectedIds, handleCreditClick }} />
      </Box>
      {modal ? modal : null}
    </ContentContainer>
  )
}

const TransactionList: React.FC = () => {
  const location = useLocation()
  const { calendarGuid, contractGuid, periodGuid } = useParams()
  if (!calendarGuid || !contractGuid || !periodGuid) return null
  return <Component {...{ location, calendarGuid, contractGuid, periodGuid }} />
}

export default memo(TransactionList)
