import { AppointmentType } from "constants/appointmentType"
import { DateTime } from "luxon"
import { AdditionalTypeType, ApprovalStatus } from "../additional/type"
import { OwnerType, TransactionItem } from "pages/Finance/types"

export type InvoiceTransactionGroup = {
  guid: string
  account: string
  calendar_guid: string
  completed_at?: DateTime
  confirmed_percentage: number
  contract_guid: string
  contract_name?: string
  contract_type: string
  cycle_type?: string
  deadline?: DateTime
  deadline_overdue?: boolean
  end: DateTime
  invoiced_percentage: number
  is_alerting: boolean
  is_creditable: boolean
  is_failed: boolean
  is_processable: boolean
  is_processing: boolean
  is_queued: boolean
  start: DateTime
  task_guid?: string
  total: number
}

export enum InvoiceTransactionChargeType {
  E_TAX = "E/TAX",
  E_NO_TAX = "E/NO-TAX",
  MILE_TOP = "MILE/TOP",
  CONTRIB = "CONTRIB"
}

export type RelationType = "Visit" | "Shift" | "Contribution" | "Training" | "TimeOff"

export interface InvoiceTransactionCharge extends TransactionItem {
  appointment_type?: AppointmentType
  approval: ApprovalStatus
  charge_type_alias: InvoiceTransactionChargeType
  date: DateTime
  description: string
  field_type: AdditionalTypeType
  quantity?: number
  rate?: number
  readonly: boolean
  relation_guid?: string
  relation_type?: RelationType
  taxable: boolean
  transaction_item_guid?: string
}

export interface InvoiceTransactionItem extends TransactionItem {
  appointment_guid: string
  appointment_type: AppointmentType
  confirmed: boolean
  is_nocharge?: boolean
  payable_duration?: string // timespan
  service_type_guid?: string
  visit_date: DateTime
}

type StatusTypeAlias = "PENDING" | "READY" | "COMPLETE" | "CREDITED" | "NOCHARGE"

export type InvoiceTransaction = {
  guid: string
  completed_at?: DateTime
  confirmed_percentage: number
  credit_guid?: string
  is_alerting: boolean
  is_creditable: boolean
  is_failed: boolean
  is_processable: boolean
  is_processing: boolean
  is_queued: boolean
  owner_guid: string
  owner_name?: string
  owner_type: OwnerType
  reference?: string
  status_type_alias: StatusTypeAlias
  subtotal_extra: number
  subtotal_item: number
  task_guid?: string
  tax_point_date?: DateTime
  total: number
  user_contract_guid: string
  user_contract_reference: string
  appointment_count: number
}

// calculation
export interface CalculationExtraCharge {
  guid: string
  description?: string
  rate: number
  quantity: number
  total: number
}

export interface CalculationLabourCharge {
  guid: string
  description?: string
  duration: string // timespan
  end: string // timespan
  rate: number
  start: string // timespan
  total: number
}

export interface ApiTransactionShiftVisit {
  confirmed: boolean
  contract_model_title: string
  contract_reference: string
  duration_actual?: string // timespan
  duration_rostered: string // timespan
  end_actual?: DateTime
  end_rostered: DateTime
  reference_owner_guid: string
  reference_owner_name: string
  service_type_title: string
  start_actual?: DateTime
  start_rostered: DateTime
  visit_date: DateTime
  visit_guid: string
}

export interface ApiTransactionShiftCalculation {
  guid: string
  band_maximum_minutes?: number
  band_minimum_minutes: number
  cancellation_notice?: string
  completed_at?: DateTime // invoice-processing
  confirmed: boolean
  contract_name: string
  contract_reference: string
  duration_actual?: string // timespan
  duration_charge: string // timespan
  duration_rostered: string // timespan
  end_actual?: DateTime
  end_charged: DateTime
  end_rostered: DateTime
  extra_charges: CalculationExtraCharge[]
  invoice_reference?: string // invoice-processing
  is_cancelled: boolean
  labour_charges: CalculationLabourCharge[]
  owner_guid: string
  owner_name?: string
  owner_type: OwnerType
  payment_type_title: string
  processed_at: DateTime
  reference_contract_name: string
  reference_contract_reference: string
  reference_owner_guid: string
  reference_owner_name?: string
  reference_owner_type: OwnerType
  rounding_minutes?: number
  rounding_type_title?: string
  rule_cap_figure?: number
  rule_cap_type_title?: string
  rule_charge_type_title: string
  rule_condition_title: string
  service_type_title: string
  start_actual?: DateTime
  start_charged: DateTime
  start_rostered: DateTime
  visit_date: DateTime
  visits?: ApiTransactionShiftVisit[]
}
