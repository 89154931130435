interface NumberUtils {
  displayWithDecimals: (number: string | number, decimals: number) => string
}

export const displayWithDecimals = (number: string | number, decimals: number): string =>
  Number(number).toFixed(decimals)

export default {
  displayWithDecimals
} as NumberUtils
