import React, { useCallback, memo, useState, MouseEvent } from "react"
import datetime from "lib/datetime"
import { moneyFormat } from "lib/utils"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import { DateTime } from "luxon"
import { Checkbox } from "@mui/material"
import { isNil } from "ramda"
import { SelectableIdsDictionaryModel } from "lib/hooks"
import { TransactionInProgress, TransactionQueued, TransactionFailed } from "pages/Finance/components"
import { ContractModelType } from "constants/modelTypes"
import testingId from "constants/testingId"
import { InvoiceFileItem } from "data/finance/invoiceFile/types"
import { ExpandMore, ExpandLess } from "@mui/icons-material"
import { FileManagementTablePayroll } from "./FileManagementTablePayroll"
import { useDownloadFinanceFile } from "pages/Finance/FileManagement/helpers/downloadFiles"
import { StatusIcon } from "components/StatusIcon"
import { ReplaceFileButton } from "components/ReplaceFileButton"
import { Link } from "components"
import { SelectableTableRow } from "components/SelectableTableRow"

const formatPaymentPeriod = (startDate: DateTime, endDate: DateTime) => {
  return `${datetime.formatDate(startDate)} - ${datetime.formatDate(endDate)}`
}

interface OwnProps {
  file: InvoiceFileItem
  modelType: ContractModelType
  onSelectRow: (id: string) => void
  selectableRows: SelectableIdsDictionaryModel
  showITrent: boolean
  selectableIdsForITrent: SelectableIdsDictionaryModel
  toggleIdForITrent: (id: string) => void
  isDeveloper: boolean
  isSelected: boolean
}

export const FileManagementTableItem: React.FC<OwnProps> = memo(
  ({
    file,
    modelType,
    onSelectRow,
    selectableRows,
    showITrent,
    selectableIdsForITrent,
    toggleIdForITrent,
    isDeveloper,
    isSelected
  }) => {
    FileManagementTableItem.displayName = "FileManagementTableItem"

    const isPaymentModel = modelType === ContractModelType.PAYMENT

    const onSelectClickHandler = useCallback(
      (id: string) => {
        onSelectRow(id)
      },
      [onSelectRow]
    )

    const downloadInterfaceFile = useDownloadFinanceFile({ modelType, isInterface: true })

    const getAction = useCallback(
      (item: InvoiceFileItem) => {
        if (showITrent && item.payroll_status) {
          return (
            <StatusIcon
              status={item.payroll_status}
              pendingName="Pending"
              failedName="Failed"
              successName="Succeeded"
            />
          )
        }
        if (item.interface_reference && item.interface_guid)
          return (
            <Link
              to="/#"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                item.interface_guid && downloadInterfaceFile(item.interface_guid)
              }}
            >
              {item.interface_reference}
            </Link>
          )

        if (item.is_failed) return <TransactionFailed />
        if (item.is_queued) return <TransactionQueued />
        if (item.is_processing) return <TransactionInProgress />
        if (!isNil(selectableRows[item.guid]))
          return <Checkbox onChange={() => onSelectClickHandler(item.guid)} checked={selectableRows[item.guid]} />
        return ""
      },
      [downloadInterfaceFile, onSelectClickHandler, selectableRows, showITrent]
    )

    const [showPayrollHistory, setShowPayrollHistory] = useState(false)

    const downloadFile = useDownloadFinanceFile({ modelType })
    const handleReferenceClick = (e: MouseEvent) => {
      e.preventDefault()
      downloadFile(file.guid)
    }

    const handlePayrollClick = () => {
      setShowPayrollHistory((show) => !show)
    }

    return (
      <>
        <SelectableTableRow hover isSelected={isSelected}>
          {showITrent && (
            <TableCell>
              {file.payroll_status === "Failed" ? (
                <Checkbox
                  onChange={() => toggleIdForITrent(file.guid)}
                  checked={selectableIdsForITrent[file.guid] ?? false}
                />
              ) : null}
            </TableCell>
          )}
          <TableCell data-cy={testingId.fileManagement.fileManagementTableBodyCell}>
            {file.contract_model_name}
          </TableCell>
          <TableCell data-cy={testingId.fileManagement.fileManagementTableBodyCell}>
            {datetime.formatDate(file.process_date)}
          </TableCell>
          <TableCell data-cy={testingId.fileManagement.fileManagementTableBodyCell}>{file.user_name}</TableCell>
          <TableCell data-cy={testingId.fileManagement.fileManagementTableBodyCell}>
            {isPaymentModel ? file.user_contract_reference : file.type_alias}
          </TableCell>
          <TableCell data-cy={testingId.fileManagement.fileManagementTableBodyCell}>
            {file.file_guid ? (
              <Link to="/#" onClick={handleReferenceClick}>
                {file.output_reference}
              </Link>
            ) : (
              "N/A"
            )}
          </TableCell>
          {isPaymentModel ? (
            <TableCell data-cy={testingId.fileManagement.fileManagementTableBodyCell}>
              {formatPaymentPeriod(file.period_start, file.period_end)}
            </TableCell>
          ) : null}
          <TableCell data-cy={testingId.fileManagement.fileManagementTableBodyCell}>
            {datetime.formatDate(isPaymentModel ? file.completed_at : file.period_start)}
          </TableCell>
          <TableCell data-cy={testingId.fileManagement.fileManagementTableBodyCell}>
            {moneyFormat.format(file.rate)}
          </TableCell>
          <TableCell data-cy={testingId.fileManagement.fileManagementTableBodyCell}>{getAction(file)}</TableCell>
          {showITrent && (
            <TableCell onClick={handlePayrollClick} style={{ cursor: "pointer" }}>
              {showPayrollHistory ? <ExpandLess /> : <ExpandMore />}
            </TableCell>
          )}
          {isDeveloper && file.output_reference && !isPaymentModel && (
            <TableCell>
              <ReplaceFileButton {...{ transaction_guids: [file.guid], isInvoice: !isPaymentModel }} />
            </TableCell>
          )}
        </SelectableTableRow>
        {showPayrollHistory && (
          <TableRow>
            <TableCell colSpan={11}>
              <FileManagementTablePayroll {...{ file }} />
            </TableCell>
          </TableRow>
        )}
      </>
    )
  }
)
