import { ApiCarerContract } from "data/finance/carer/types"
import { Row } from "pages/Clients/components/Table/Body"
import dateTimeUtils from "lib/datetime"
import { getContractModelLink } from "pages/Finance/components/helpers/getContractModelLink"
import { NavigateFunction } from "react-router-dom"

export const mapCarerContractListData = (navigate: NavigateFunction, carerContracts?: ApiCarerContract[]): Row[] =>
  carerContracts
    ? carerContracts.map((contract: ApiCarerContract) => {
        const canBeDeleted = contract.status === "Future"
        const navTo = () => navigate(`${contract.guid}`)
        const isInvoiceModel = false

        const data = [
          getContractModelLink({
            isInvoiceModel,
            contract_guid: contract.contract_guid,
            contract_title: contract.contract_model
          }),
          dateTimeUtils.formatDate(contract.start),
          contract.end ? dateTimeUtils.formatDate(contract.end) : "--/--/--",
          contract.status
        ]
        return {
          data,
          guid: contract.guid,
          canBeDeleted,
          navigate: navTo,
          isInvoiceModel
        }
      })
    : []
