import React, { memo } from "react"
import { CarerContractDetailStepTwoGuaranteedHours } from "./CarerContractDetailStepTwoGuaranteedHours"
import { CarerContractDetailStepTwoRate } from "./CarerContractDetailStepTwoRate"
import { CarerContractDetailStepTwoHeader } from "./CarerContractDetailStepTwoHeader"
import { CarerContractDetailStepTwoCompensation } from "./CarerContractDetailStepTwoCompensation"
import { CarerContractDetailStepTwoDate } from "./CarerContractDetailStepTwoDate"
import { ApiCarerContract } from "data/finance/carer/types"
import { CarerContractDetailStepTwoMileage } from "./CarerContractDetailStepTwoMileage"
import { CarerContractDetailStepTwoTravelTimes } from "./CarerContractDetailStepTwoTravelTimes"
import { WithPermission } from "components/Auth"
import { Permission } from "constants/permission"
import { PaymentModel } from "data/finance/contractModel/types"

interface Props {
  paymentModel?: PaymentModel
  contract?: ApiCarerContract
}

export const CarerContractDetailStepTwo: React.FC<Props> = memo(({ paymentModel, contract }) => {
  CarerContractDetailStepTwo.displayName = "CarerContractDetailStepTwo"

  return (
    <>
      <CarerContractDetailStepTwoHeader {...{ contract, paymentModel }} />
      <CarerContractDetailStepTwoDate />
      <CarerContractDetailStepTwoCompensation />
      <CarerContractDetailStepTwoGuaranteedHours />
      <WithPermission
        permissions={[Permission.FINANCE_USER_CONTRACT_RATE_EDIT, Permission.FINANCE_USER_CONTRACT_RATE_READ]}
      >
        <CarerContractDetailStepTwoRate />
      </WithPermission>
      <CarerContractDetailStepTwoMileage />
      <CarerContractDetailStepTwoTravelTimes />
    </>
  )
})
