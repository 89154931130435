import { useBranchInfoQuery } from "data/core/branch/queries"

/**
 * useBranchRedirectCclUrl
 *
 * @returns the current branch redirect cc url
 */
const useBranchRedirectCclUrl = () => {
  const { data: { ccl_url = null } = {} } = useBranchInfoQuery()
  return ccl_url
}

export default useBranchRedirectCclUrl
