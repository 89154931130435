import React, { memo } from "react"
import { ModalWrapper } from "components"
import Typography from "@mui/material/Typography"
import Table from "@mui/material/Table"
import Box from "@mui/material/Box"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { Button } from "components/Form"
import { optionsToValueTitle } from "lib/schema"
import { moneyFormat } from "lib/utils"
import testingId from "constants/testingId"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { useSchema } from "data/schema/useSchema"
import { usePaymentTransactionChargesDeleteHandler } from "data/finance/paymentProcessing/handlers"
import { PaymentTransaction, PaymentTransactionCharge } from "data/finance/paymentProcessing/types"
import { CalendarPeriod } from "data/finance/contractModel/types"

type Props = {
  charges: PaymentTransactionCharge[]
  transaction: PaymentTransaction
  period: CalendarPeriod
  destroyModal: () => void
  onOk: () => void
}

const DeleteChargesModal: React.FC<Props> = ({ charges, transaction, period, destroyModal, onOk }) => {
  const { TransactionCharge } = useSchema(["TransactionCharge"])
  const chargeTypes = optionsToValueTitle(TransactionCharge)

  const deleteCharges = usePaymentTransactionChargesDeleteHandler({
    periodGuid: period.guid,
    transactionGuid: transaction.guid,
    charges,
    onSuccess: () => {
      onOk()
      destroyModal()
    }
  })

  return (
    <ModalWrapper title="Delete Extras" destroyModal={destroyModal} width={700}>
      <RequestLoaderWrapper>
        <Typography>
          You are about to delete <strong>{charges.length} extras</strong> listed below:
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              {["Extra type", "Description", "Cost"].map((label, index) => (
                <TableCell key={index}>{label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {charges.map((charge, index) => (
              <TableRow key={index}>
                {[chargeTypes[charge.charge_type_alias], charge.description, moneyFormat.format(charge.total || 0)].map(
                  (value, cellIdx) => (
                    <TableCell key={cellIdx}>{value}</TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box m={3} mx={0}>
          <Typography>
            <strong>This process is irreversible, are you sure you want to proceed?</strong>
          </Typography>
        </Box>
        <Box m={3} mx={0} display="flex" justifyContent="flex-end">
          <Button fullWidth={false} variant="text" onClick={destroyModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            fullWidth={false}
            onClick={deleteCharges}
            sx={(theme) => ({
              marginLeft: theme.spacing(3)
            })}
            data-cy={testingId.extrasModal.deleteButton}
          >
            Delete extras
          </Button>
        </Box>
      </RequestLoaderWrapper>
    </ModalWrapper>
  )
}

export default memo(DeleteChargesModal)
