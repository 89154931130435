import React, { ReactNode, useCallback, useState } from "react"
import { List, ListItemButton, ListItemText } from "@mui/material"
import { WithPermission } from "../Auth"
import { Link, NavItem, FrontendDiagnostics } from "components"
import { CERA_FRESHDESK_LINK, CERA_JARVIS_FORM_LINK, CERA_TECH_LINK } from "constants/link"
import { MainNavVersion } from "./MainNavVersion"
import { useInvalidateAllSchemas } from "data/schema/useInvalidateAllSchemas"
import { useAuthStore } from "stores"
import useBranchSettings from "lib/hooks/branchInfo/useBranchSettings"

export const MainNavListBottom = () => {
  const { settings: branchSettings } = useBranchSettings()
  const hasJarvisLinkInMainMenu = !!branchSettings?.FeatureDashboardJarvisFormsLeftMenu
  const logOut = useAuthStore((state) => state.logOut)
  const [modal, setModal] = useState<ReactNode>(null)
  const destroyModal = () => setModal(null)
  const openDiagnosticsModal = () => setModal(<FrontendDiagnostics onCancel={destroyModal} />)
  const invalidateAllSchemas = useInvalidateAllSchemas()

  const handleLogout = useCallback(() => {
    invalidateAllSchemas()
    logOut()
  }, [invalidateAllSchemas, logOut])

  return (
    <>
      <List>
        <WithPermission {...{ permissions: ["File.Download:Read"] }}>
          <NavItem {...{ to: "/download", label: "Downloads" }} id="mainnav-downloads" />
        </WithPermission>
        <NavItem to="sync-status" label="Sync Status" id="mainnav-sync-status" />
        <ListItemButton onClick={openDiagnosticsModal} id="mainnav-diagnostics">
          <ListItemText primary="Diagnostics" />
        </ListItemButton>
        {hasJarvisLinkInMainMenu && (
          <Link
            sx={{ textDecoration: "none", "&:hover": { textDecoration: "underline" } }}
            external
            to={CERA_JARVIS_FORM_LINK}
            color="inherit"
            id="mainnav-jarvis"
          >
            <ListItemButton>
              <ListItemText primary="Jarvis" />
            </ListItemButton>
          </Link>
        )}
        <Link
          sx={{ textDecoration: "none", "&:hover": { textDecoration: "underline" } }}
          external
          to={CERA_FRESHDESK_LINK}
          color="inherit"
          id="mainnav-tickets-system"
        >
          <ListItemButton>
            <ListItemText primary="Tickets System" />
          </ListItemButton>
        </Link>
        <Link
          sx={{ textDecoration: "none", "&:hover": { textDecoration: "underline" } }}
          external
          to={CERA_TECH_LINK}
          color="inherit"
          id="mainnav-how-to-guide"
        >
          <ListItemButton>
            <ListItemText primary="How to Guide" />
          </ListItemButton>
        </Link>
        <WithPermission
          permissions={["User.Operator:Edit", "Finance.Settings:Edit", "Company:Read", "Company.Branch:Read"]}
        >
          <NavItem to="/admin" label="Administration" id="mainnav-administration" />
        </WithPermission>
        <ListItemButton onClick={handleLogout} id="mainnav-log-out">
          <ListItemText primary="Log out" />
        </ListItemButton>
        <MainNavVersion />
      </List>

      {modal !== null && modal}
    </>
  )
}

MainNavListBottom.displayName = "MainNavListBottom"
