import React, { memo } from "react"
import { List } from "@mui/material"
import {
  CalendarToday,
  GroupRounded,
  Face,
  FavoriteBorder,
  LibraryBooks,
  QueuePlayNext,
  Security,
  Folder,
  LocalHospital,
  DateRange,
  Equalizer
} from "@mui/icons-material"
import { Avatar, DetailSidebar, NavItem } from "components"
import { WithPermission } from "components/Auth"
import { ApiUserClient } from "models"

import MedicationIcon from "components/icons/Medication"
import { path } from "ramda"

interface OwnProps {
  client: ApiUserClient
  hasDigitalCareplanV2: boolean
  hasEmarsFlag: boolean
  hasSchedulerFlag: boolean
  hasClientAbsenceFlag: boolean
  isClientPrefv2Enabled: boolean
  isOutcomesEnabled: boolean
  isClientProfileV2: boolean
}

export const ClientDetailSidebar: React.FC<OwnProps> = memo(
  ({
    client,
    hasDigitalCareplanV2,
    hasEmarsFlag,
    hasSchedulerFlag,
    hasClientAbsenceFlag,
    isClientPrefv2Enabled,
    isOutcomesEnabled,
    isClientProfileV2
  }) => {
    ClientDetailSidebar.displayName = "ClientDetailSidebar"

    const careplannerUrl = `${path(["branch", "care_planner_url"], client)}/roster/${client.care_planner_internal_id}`
    const outcomesUrl = `/clients/${client.guid}/outcomes`

    return (
      <DetailSidebar>
        {!isClientProfileV2 ? (
          <Avatar
            sx={(theme) => ({
              margin: theme.spacing(2, 0, 4, 0),
              marginLeft: "auto",
              marginRight: "auto",
              height: "auto",
              maxHeight: "50%",
              maxWidth: "50%",
              width: "auto",
              transition: "all 0.1s linear"
            })}
            user={client}
          />
        ) : null}

        <List component="nav">
          <NavItem
            to={
              isClientProfileV2 ? `/clients/${client.guid}/profile` : `/clients/${client.guid}/profile/personal-details`
            }
            icon={<Face color="primary" />}
            label="Profile"
            id="clientdetail-sidebar-profile"
          />

          {isClientPrefv2Enabled && (
            <NavItem
              to={`/clients/${client.guid}/care-preferences`}
              icon={<GroupRounded shapeRendering="circle" color="primary" />}
              label="Care Preferences"
              id="clientdetail-sidebar-client-care-preferences"
            />
          )}
          {isOutcomesEnabled && (
            <NavItem
              to={outcomesUrl}
              onClick={() => {
                window.dispatchEvent(new PopStateEvent("outcomesPop", { state: outcomesUrl }))
              }}
              icon={<Equalizer shapeRendering="circle" color="primary" />}
              label="Care Outcomes"
              id="clientdetail-sidebar-outcomes"
            />
          )}
          <WithPermission permissions={["Finance.UserContract:Edit"]}>
            <NavItem
              to={`/clients/${client.guid}/contracts`}
              icon={<Folder color="primary" />}
              label="Contracts"
              id="clientdetail-sidebar-contracts"
            />
          </WithPermission>

          <NavItem
            to={`/clients/${client.guid}/visits`}
            icon={<CalendarToday color="primary" />}
            label="Visits"
            id="clientdetail-sidebar-visits"
          />

          <NavItem
            to={`/clients/${client.guid}/careplan`}
            icon={<FavoriteBorder color="primary" />}
            label="Careplan"
            id="clientdetail-sidebar-careplan"
          />

          <NavItem
            to={`/clients/${client.guid}/documents`}
            icon={<LibraryBooks color="primary" />}
            label="Documents"
            id="clientdetail-sidebar-documents"
          />
          <NavItem
            to={`/clients/${client.guid}/relative`}
            icon={<Security color="primary" />}
            label="Care Circle"
            id="clientdetail-sidebar-care-circle"
          />
          {hasDigitalCareplanV2 && (
            <NavItem
              to={`/clients/${client.guid}/new-careplan/decision`}
              icon={<LocalHospital color="primary" />}
              label="Digital Careplan"
              id="clientdetail-sidebar-new-digital-careplans"
            />
          )}
          {hasEmarsFlag && (
            <NavItem
              to={`/clients/${client.guid}/emars`}
              icon={<MedicationIcon color="primary" />}
              label="eMARS"
              id="clientdetail-sidebar-emars"
            />
          )}
          {hasSchedulerFlag && (
            <WithPermission permissions={["Scheduling.Appointment:Read"]}>
              <NavItem
                to={`/clients/${client.guid}/scheduler-client-weekly`}
                icon={<DateRange color="primary" />}
                label="Calendar"
                id="clientdetail-sidebar-calendar-view"
              />
              <NavItem
                to={`/clients/${client.guid}/client-schedule`}
                icon={<DateRange color="primary" />}
                label="Schedule"
                id="clientdetail-sidebar-schedule-view"
              />
            </WithPermission>
          )}
          {hasClientAbsenceFlag && (
            <NavItem
              to={`/clients/${client.guid}/absences`}
              icon={<CalendarToday color="primary" />}
              label="Client Absences"
              id="clientdetail-sidebar-absence"
            />
          )}
        </List>
        <List component="nav">
          <NavItem
            to={careplannerUrl}
            icon={<QueuePlayNext color="primary" />}
            label="CarePlanner profile"
            external
            id="clientdetail-sidebar-careplanner-profile"
          />
        </List>
      </DetailSidebar>
    )
  }
)
