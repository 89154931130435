import { FinanceApi } from "api"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { ApiShiftCategoryContractRequest, ShiftCategoryContractResponse } from "types/Shifts/ShiftCategories/types"
import { queryKeys } from "./queries"
import { useNavigate } from "react-router-dom"
import { useUIStore } from "stores"

interface ShiftCategoryContractMutationParams {
  shiftCategoryGuid: string
  contract: ApiShiftCategoryContractRequest
}

interface ShiftCategoryContractDeleteMutationParams {
  shiftCategoryGuid: string
  contractGuid: string
}

export const useShiftCategoryContractCreateMutation = (): UseMutationResult<
  ShiftCategoryContractResponse,
  Error,
  ShiftCategoryContractMutationParams
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()
  const queryCache = useQueryClient()
  return useMutation(
    async ({
      shiftCategoryGuid,
      contract
    }: ShiftCategoryContractMutationParams): Promise<ShiftCategoryContractResponse> => {
      return FinanceApi.post(`/finance/shift-categories/${shiftCategoryGuid}/contracts`, contract)
    },
    {
      onSuccess: async (data) => {
        const { owner_guid, guid } = data
        queryCache.setQueryData(queryKeys.getShiftCategoryContractKey(owner_guid, guid), data)
        showSuccessMessage("Contract successfully created.")
        navigate(`/shift-categories/${owner_guid}/contracts/${guid}`)
      }
    }
  )
}

export const useShiftCategoryContractUpdateMutation = (): UseMutationResult<
  ShiftCategoryContractResponse,
  Error,
  ShiftCategoryContractMutationParams
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()
  return useMutation(
    async ({
      shiftCategoryGuid,
      contract
    }: ShiftCategoryContractMutationParams): Promise<ShiftCategoryContractResponse> => {
      return FinanceApi.put(`/finance/shift-categories/${shiftCategoryGuid}/contracts/${contract.guid}`, contract)
    },
    {
      onSuccess: async (data) => {
        const { owner_guid, guid } = data
        queryCache.setQueryData(queryKeys.getShiftCategoryContractKey(owner_guid, guid), data)
        showSuccessMessage("Contract successfully updated.")
      }
    }
  )
}

export const useShiftCategoryContractDeleteMutation = (): UseMutationResult<
  ApiShiftCategoryContractRequest,
  Error,
  ShiftCategoryContractDeleteMutationParams
> => {
  return useMutation(
    async ({
      shiftCategoryGuid,
      contractGuid
    }: ShiftCategoryContractDeleteMutationParams): Promise<ApiShiftCategoryContractRequest> => {
      return FinanceApi.delete(`/finance/shift-categories/${shiftCategoryGuid}/contracts/${contractGuid}`)
    }
  )
}
