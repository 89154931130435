import Grid from "@mui/material/Grid"
import { FormSpyCustom, Button } from "components/Form"
import { Link } from "components"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Form } from "react-final-form"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { StatusSelectField } from "components/Form/StatusSelectField"
import { StatusEnum } from "data/commonTypes"

export interface SalaryBandFilter {
  status?: StatusEnum
}

interface OwnProps {
  filter: SalaryBandFilter
  setFilter: Dispatch<SetStateAction<SalaryBandFilter>>
  canAddSalaryBand: boolean
}

export const SalaryBandListFilter: React.FC<OwnProps> = memo(({ filter, setFilter, canAddSalaryBand }) => {
  const onSubmit = (values: SalaryBandFilter) => {
    setFilter(values)
  }

  useFilterLocationChange({ filter })

  return (
    <Form onSubmit={onSubmit} initialValues={filter}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpyCustom {...{ handleSubmit }} />
          <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
            <Grid item md={3}>
              <StatusSelectField withAny />
            </Grid>
            {canAddSalaryBand ? (
              <Grid item md={3}>
                <Link to={`new`}>
                  <Button>Add Salary Band</Button>
                </Link>
              </Grid>
            ) : null}
          </Grid>
        </form>
      )}
    </Form>
  )
})

SalaryBandListFilter.displayName = "SalaryBandListFilter"
