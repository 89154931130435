import { Box, Divider, styled } from "@mui/material"
import React, { memo } from "react"
import { ApiApproval } from "data/finance/additional/type"
import { formatDate } from "lib/datetime"
import { reverse } from "ramda"

const StyledWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  fontSize: 12,
  lineHeight: "20px",
  padding: 5
})

const StyledItem = styled(Box)({
  display: "flex",
  flexDirection: "column"
})

const StyledSpan = styled("span")({
  whiteSpace: "nowrap"
})

const StyledDivider = styled(Divider)({
  background: "white",
  mt: 2,
  mb: 2
})

interface OwnProps {
  approvals: ApiApproval[]
}

export const AdditionalSearchTableItemStatusTooltipContent: React.FC<OwnProps> = memo(({ approvals }) => {
  AdditionalSearchTableItemStatusTooltipContent.displayName = "AdditionalSearchTableItemStatusTooltipContent"

  return (
    <StyledWrapper>
      {reverse(approvals).map((approval, index) => {
        const text = approval.approved ? "Approved" : "Rejected"
        return (
          <StyledItem key={approval.guid} style={{ fontWeight: !index ? "bold" : undefined }}>
            <StyledSpan>{`${text} by: ${approval.approver_name}`}</StyledSpan>
            <StyledSpan>{`${text} on: ${formatDate(approval.date)}`}</StyledSpan>
            <span>Feedback: {approval.comment}</span>
            {index !== approvals.length - 1 ? <StyledDivider /> : null}
          </StyledItem>
        )
      })}
    </StyledWrapper>
  )
})
