import { ContentContainer, DataLoading } from "components"
import React, { memo } from "react"
import { Form } from "react-final-form"
import { ContractModelPageProps } from "./types"
import {
  useContractSalaryBandListQuery,
  useContractSalaryDetailQuery,
  useContractSalaryListQuery
} from "data/finance/contractModel/queries"
import { isNil } from "ramda"
import { useContractModelSalaryCreateMutation } from "data/finance/contractModel/mutations"
import { ContractModelSalaryRequestWithBands } from "data/finance/contractModel/types"
import { useContractModelSalaryBandUpdateHandler } from "data/finance/contractModel/handlers/useContractModelSalaryBandUpdateHandler"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import arrayMutators from "final-form-arrays"
import { timeSpanToHhMm } from "lib/timespan"
import { validateSalaryWithBands } from "./validators/validateSalaryWithBands"
import { SalaryBandEditor } from "./components/SalaryBandEditor"
import { SalaryBandFormDates } from "./components/SalaryBandFormDates"
import DatesMessageHelp from "components/DatesMessageHelp"
import { SalaryBandFormButtons } from "./components/SalaryBandFormButtons"
import { SalaryBandFormTitle } from "./components/SalaryBandTitle"
import { useParams } from "react-router-dom"
import { useUIStore } from "stores"

const SalaryBandsDetail: React.FC<ContractModelPageProps> = ({ contractModel, modelType }) => {
  const { salaryGuid } = useParams()
  const { data: salaryBands } = useContractSalaryBandListQuery({
    contractGuid: contractModel.guid,
    contractSalaryGuid: salaryGuid
  })

  const { data: salary } = useContractSalaryDetailQuery({
    contractGuid: contractModel.guid,
    contractSalaryGuid: salaryGuid
  })

  const { data: salaries } = useContractSalaryListQuery({
    contractGuid: contractModel.guid
  })

  const { mutateAsync: createSalary } = useContractModelSalaryCreateMutation()

  const updateSalary = useContractModelSalaryBandUpdateHandler({
    contractModelGuid: contractModel.guid,
    salaryGuid: salaryGuid
  })

  const showErrorMessage = useUIStore((state) => state.showErrorMessage)

  const onSubmit = async (values: ContractModelSalaryRequestWithBands) => {
    const { no_end_date, ...rest } = values
    const payload = {
      ...rest,
      end: no_end_date ? undefined : values.end
    }

    if (!salary) {
      try {
        await createSalary({ payload, contractGuid: contractModel.guid })
      } catch (apiError: any) {
        showErrorMessage("Something went wrong, please try again.", { apiError })
      }
      return
    }

    try {
      await updateSalary({ payload })
    } catch (apiError: any) {
      showErrorMessage("Something went wrong, please try again.", { apiError })
    }
  }

  const initialValues = salary
    ? {
        guid: salary.guid,
        start: salary.start,
        end: salary.end,
        no_end_date: salary && isNil(salary.end),
        bands: salaryBands?.map((band) => {
          return {
            ...band,
            start: timeSpanToHhMm(band.start),
            end: timeSpanToHhMm(band.end)
          }
        })
      }
    : {}

  if (salaryGuid && !salary) return <DataLoading />

  return (
    <ContentContainer>
      <SalaryBandFormTitle {...{ modelType, contractModel, salary }} />
      <RequestLoaderWrapper my={3}>
        <Form
          {...{
            onSubmit,
            initialValues,
            mutators: { ...arrayMutators },
            validate: validateSalaryWithBands({ salaries, contractModel, isCreate: !salary })
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DatesMessageHelp messageType="E" />
              <SalaryBandFormDates />
              {!!salary && <SalaryBandEditor />}

              <SalaryBandFormButtons />
            </form>
          )}
        </Form>
      </RequestLoaderWrapper>
    </ContentContainer>
  )
}

export default memo(SalaryBandsDetail)
