import React, { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import { Box, TableCell, TableHead, TableRow } from "@mui/material"
import testingId from "constants/testingId"
import { pipe, remove } from "ramda"
import { ArrowRightAlt, ImportExport } from "@mui/icons-material"

import { grey } from "@mui/material/colors"
import { queryStringToObject } from "lib/queryString"
import { useLocation } from "react-router-dom"

type SortStateItemType = "ASC" | "DESC"
type SortStateItem = {
  name: string
  type: SortStateItemType
}

const buildItems = ({ resource, type }: { resource: string | string[]; type: SortStateItemType }): SortStateItem[] => {
  if (typeof resource === "string") return [{ name: resource, type }]
  return resource.map((resourceItem) => ({ name: resourceItem, type }))
}

interface OwnProps {
  setFilter: Dispatch<SetStateAction<any>>
  items: { label: string; resource?: string | string[]; key?: string }[]
}

const SortIcon: React.FC<{ sortObj?: SortStateItem }> = memo(({ sortObj }) => {
  SortIcon.displayName = "SortIcon"

  return sortObj ? (
    <ArrowRightAlt sx={{ transform: sortObj.type === "ASC" ? "rotate(-90deg)" : "rotate(90deg)" }} />
  ) : (
    <ImportExport
      sx={{
        color: grey[400]
      }}
    />
  )
})

export const SortableTableHeader: React.FC<OwnProps> = memo(({ setFilter, items }) => {
  SortableTableHeader.displayName = "SortableTableHeader"
  const location = useLocation()

  const [sortState, setSortState] = useState<SortStateItem[]>(() => {
    const { sort } = queryStringToObject(location.search)
    return (
      sort?.split(",").map((resource: string) => {
        const isAsc = resource[0] !== "-"
        const type = isAsc ? "ASC" : "DESC"
        const name = isAsc ? resource : resource.substring(1)
        return { type, name } as SortStateItem
      }) || []
    )
  })

  const [isInitial, setIsInitial] = useState(true)

  const onClick = useCallback(
    (resource?: string | string[]) => () => {
      if (!resource) return

      const isArray = Array.isArray(resource)
      const arrayLength = resource.length
      const resourceItem = Array.isArray(resource) ? resource[0] : resource

      const index = sortState.findIndex((item) => item.name === resourceItem)
      const existingItem = sortState[index]

      if (existingItem) {
        // if is ASC make it DESC and move to the end
        if (existingItem.type === "ASC") {
          const resASC = pipe(remove(index, isArray ? arrayLength : 1), (sState) => [
            ...sState,
            ...buildItems({ resource, type: "DESC" })
          ])(sortState)
          setSortState(resASC)
          return
        }
        // if is DSC remove it
        const resDCP = remove<SortStateItem>(index, isArray ? arrayLength : 1, sortState)
        setSortState(resDCP)
        return
      }
      // if does not exist create ASC at the end
      const res = [...sortState, ...buildItems({ resource, type: "ASC" })]
      setSortState(res)
    },
    [sortState]
  )

  const resString = useMemo(() => {
    const getPrefix = (item: SortStateItem) => (item.type === "ASC" ? "" : "-")
    return sortState.reduce((res, item, index) => {
      const itemString = `${getPrefix(item)}${item.name}`
      if (index === 0) return itemString
      return res + `,${itemString}`
    }, "")
  }, [sortState])

  useEffect(() => {
    if (isInitial) setIsInitial(false)
    if (!isInitial) {
      setFilter(({ sort, ...rest }: any) => ({ ...(rest || {}), ...(resString ? { sort: resString } : {}) }))
    }
  }, [setFilter, resString]) // eslint-disable-line

  return (
    <TableHead>
      <TableRow>
        {items.map(({ label, resource, key }) => {
          const isSortable = !!resource
          const sortObj = sortState.find(
            ({ name }) => !!resource && name === (Array.isArray(resource) ? resource[0] : resource)
          )

          return (
            <TableCell
              data-cy={testingId.tableHeaderCell}
              key={label || key}
              onClick={onClick(resource)}
              sx={{
                ...(isSortable
                  ? {
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: grey[300]
                      }
                    }
                  : {}),
                ...(sortObj ? { backgroundColor: grey[200] } : {})
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {label}
                {isSortable ? <SortIcon {...{ sortObj }} /> : null}
              </Box>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
})
