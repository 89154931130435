import { timeSpanToHhMm, timeSpanToStringDuration } from "lib/timespan"
import dateTimeUtils, { DateTimeFormat, formatDate, formatTime } from "lib/datetime"
import { moneyFormat } from "lib/utils"

import numberUtils from "lib/number"
import {
  ChargeBreakdown,
  ChargeRules,
  ExtraCharge,
  InvoiceServices,
  InvoiceTransactionItemVisitDetailCalculations,
  TransactionVisitDetailPaymentModel,
  PaymentRulesCancelledVisit,
  TransactionVisitDetail,
  VisitOverview,
  LabourCharge,
  ExtraChargeApi,
  PaymentTransactionItemVisitDetailCalculations
} from "pages/Finance/types"
import { DateTime } from "luxon"

const isOverMidnight = ({ start_actual, end_actual }: { start_actual?: DateTime; end_actual?: DateTime }) => {
  if (!start_actual || !end_actual) {
    return false
  }
  return end_actual.diff(start_actual, "days").days >= 1
}

const parseActualTime = ({ start_actual, end_actual }: { start_actual?: DateTime; end_actual?: DateTime }) => {
  if (!start_actual || !end_actual) {
    return "N/A - N/A"
  }
  if (isOverMidnight({ start_actual, end_actual })) {
    return `${formatDate(start_actual, DateTimeFormat.DATE_TIME_SECONDARY)} - ${formatTime(
      end_actual,
      DateTimeFormat.DATE_TIME_SECONDARY
    )}`
  }
  return `${timeSpanToHhMm(formatTime(start_actual, DateTimeFormat.TIME_WITH_SECONDS))} - ${timeSpanToHhMm(
    formatTime(end_actual, DateTimeFormat.TIME_WITH_SECONDS)
  )}`
}

const parseRostTime = ({
  start_rostered,
  end_rostered,
  isOverStay
}: {
  start_rostered: DateTime
  end_rostered: DateTime
  isOverStay: boolean
}) => {
  if (isOverStay) {
    return `${formatDate(start_rostered, DateTimeFormat.DATE_TIME_SECONDARY)} - ${formatTime(
      end_rostered,
      DateTimeFormat.DATE_TIME_SECONDARY
    )}`
  }
  return `${timeSpanToHhMm(formatTime(start_rostered, DateTimeFormat.TIME_WITH_SECONDS))} - ${timeSpanToHhMm(
    formatTime(end_rostered, DateTimeFormat.TIME_WITH_SECONDS)
  )}`
}

const parseChargeTime = ({
  start_charged,
  end_charged,
  isOverStay
}: {
  start_charged: DateTime
  end_charged: DateTime
  isOverStay: boolean
}) => {
  if (isOverStay) {
    return `${formatDate(start_charged, DateTimeFormat.DATE_TIME_SECONDARY)} - ${formatTime(
      end_charged,
      DateTimeFormat.DATE_TIME_SECONDARY
    )}`
  }
  return `${timeSpanToHhMm(formatTime(start_charged, DateTimeFormat.TIME_WITH_SECONDS))} - ${timeSpanToHhMm(
    formatTime(end_charged, DateTimeFormat.TIME_WITH_SECONDS)
  )}`
}

const mapLabourCharges = ({ labour_charges }: { labour_charges: LabourCharge[] }) => {
  return labour_charges.map((labour_charge) => {
    const { description, start, end, rate, duration, total } = labour_charge

    const rate_period = `${timeSpanToHhMm(start, false)} - ${timeSpanToHhMm(end)}`

    const chargeBreakdown: ChargeBreakdown = {
      day_rate: description,
      rate_period,
      rate: moneyFormat.format(rate),
      duration: timeSpanToStringDuration(duration),
      total: moneyFormat.format(total)
    }

    return chargeBreakdown
  })
}

const sumLabourCharges = ({ labour_charges }: { labour_charges: LabourCharge[] }) => {
  return labour_charges.map((labour) => labour.total).reduce((a, b) => a + b, 0)
}

const mapExtraCharges = ({ extra_charges }: { extra_charges: ExtraChargeApi[] }): ExtraCharge[] => {
  return extra_charges.map((ex) => {
    const extraCharge: ExtraCharge = {
      description: ex.description,
      rate: moneyFormat.format(ex.rate),
      quantity: numberUtils.displayWithDecimals(ex.quantity.toString(), 2),
      total: moneyFormat.format(ex.total)
    }

    return extraCharge
  })
}

export const parseTransaction = ({
  isInvoiceModel,
  item
}: {
  isInvoiceModel: boolean
  item: InvoiceTransactionItemVisitDetailCalculations | PaymentTransactionItemVisitDetailCalculations | undefined
}): TransactionVisitDetail | null => {
  if (!item) return null

  const invoice_status = isInvoiceModel
    ? (item as InvoiceTransactionItemVisitDetailCalculations).invoice_status
    : undefined

  const completed_at = isInvoiceModel ? (item as InvoiceTransactionItemVisitDetailCalculations).completed_at : undefined

  const {
    owner_name,
    reference_owner_name,
    contract_reference,
    reference_contract_reference,
    contract_name,
    reference_contract_name,
    is_cancelled,
    cancellation_notice,

    visit_date,
    confirmed,
    processed_at,
    service_type_title,
    start_rostered,
    end_rostered,
    duration_rostered,
    start_actual,
    end_actual,
    duration_actual,

    rule_condition_title,
    rule_charge_type_title,
    rule_cap_type_title,
    rule_cap_figure,

    payment_type_title,
    band_minimum_minutes,
    band_maximum_minutes,
    rounding_type_title,
    rounding_minutes,
    start_charged, // timespan
    end_charged, // timespan
    duration_charge,

    labour_charges,
    extra_charges
  } = item

  const isOverStay = isOverMidnight({ start_actual, end_actual })

  const visitOverview: VisitOverview = {
    date: dateTimeUtils.formatDate(visit_date),
    status: confirmed ? "Conf" : "Unconf",
    date_proc: processed_at ? dateTimeUtils.formatDate(processed_at) : "--/--/--",
    service: service_type_title,
    rost_time: parseRostTime({ start_rostered, end_rostered, isOverStay }),
    rost_dur: timeSpanToStringDuration(duration_rostered),
    actual_time: parseActualTime({ start_actual, end_actual }),
    duration_actual: timeSpanToStringDuration(duration_actual)
  }

  const chargeRules: ChargeRules = {
    variations: rule_condition_title,
    pay_rule: rule_charge_type_title,
    cap_rule: rule_cap_type_title ?? "-",
    figure: rule_cap_figure ? rule_cap_figure.toString() : "-"
  }

  const paymentRulesCancelledVisit: PaymentRulesCancelledVisit = {
    reason: rule_condition_title,
    cancellationNotice: cancellation_notice ?? "-",
    pay_rule: rule_charge_type_title,
    cap_rule: rule_cap_type_title ?? "-",
    figure: rule_cap_figure ? rule_cap_figure.toString() : "-"
  }

  const band = `${band_minimum_minutes} - ${band_maximum_minutes ?? "N/A"}`
  const charge_time = parseChargeTime({ start_charged, end_charged, isOverStay })

  const invoiceServices: InvoiceServices = {
    charge_type: payment_type_title,
    band,
    rounding_rule: rounding_type_title,
    rounding_minutes,
    charge_time,
    duration_charge: timeSpanToStringDuration(duration_charge)
  }

  const paymentModels: TransactionVisitDetailPaymentModel = {
    paymentModel: isInvoiceModel ? reference_contract_name : contract_name,
    service: service_type_title,
    chargeType: payment_type_title,
    band,
    roundingRule: rounding_type_title,
    roundingMinutes: rounding_minutes,
    chargeTime: charge_time,
    durationCharge: timeSpanToStringDuration(duration_charge)
  }

  const chargeBreakdowns = mapLabourCharges({ labour_charges })

  const totalCharges = sumLabourCharges({ labour_charges })

  const extraCharges = mapExtraCharges({ extra_charges })

  return {
    contract_guid: isInvoiceModel ? contract_reference : reference_contract_reference,
    contract_model: isInvoiceModel ? contract_name : reference_contract_name,
    owner_name,
    reference_owner_name,
    pay_model: payment_type_title,
    status: invoice_status,
    isCancelled: is_cancelled,
    invoice_number: isInvoiceModel ? contract_reference : "--",
    payroll_number: isInvoiceModel ? "--" : reference_contract_reference,
    invoice_date: completed_at ? dateTimeUtils.formatDate(completed_at) : "--/--/--",
    visitOverview: visitOverview,
    chargeRules: chargeRules,
    invoiceServices: invoiceServices,
    paymentModels: paymentModels,
    chargeBreakdown: chargeBreakdowns,
    paymentRulesCancelledVisit: paymentRulesCancelledVisit,
    extraCharges: extraCharges,
    totalValue: moneyFormat.format(totalCharges)
  }
}
