import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { EmptyData, ListAlertIcon } from "components"
import { Button } from "components/Form"
import { DateTimeFormat, formatDate } from "lib/datetime"
import { isEmpty } from "lodash"
import { CreditingModal } from "pages/Finance/components"
import React, { memo, ReactNode, useMemo, useState } from "react"
import { Form } from "react-final-form"
import Auth from "lib/Auth"
import { SelectableIdsDictionary } from "lib/hooks"
import { objectToQueryString } from "lib/queryString"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import { useProcessingItemRowClick } from "pages/Finance/InvoiceProcessing/handlers/useProcessingItemRowClick"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { navigateToFinanceAlerts } from "lib/navigate"
import { TaskPreparationCreditMode } from "data/finance/task/types"
import { getItemsTableHeaderLabels } from "../helpers/getItemsTableHeaderLabels"
import { useMapPaymentTransactionItems } from "../handlers/useMapPaymentTransactionItems"
import { TransactionItemStatus } from "components/TransactionItemStatus"
import { useNavigate } from "react-router-dom"
import { SelectableTableRow } from "components/SelectableTableRow"
import { PaymentTransaction, PaymentTransactionItem } from "data/finance/paymentProcessing/types"

type ItemsTableProps = {
  selectableVisitsDictionary: SelectableIdsDictionary
  itemsLength: number
  filteredItems: PaymentTransactionItem[]
  transaction: PaymentTransaction
  periodGuid: string
  onVisitsCredited: Function
  contractGuid: string
}

export const TransactionDetailItemsTable: React.FC<ItemsTableProps> = memo(
  ({
    selectableVisitsDictionary,
    filteredItems,
    itemsLength,
    periodGuid,
    onVisitsCredited,
    transaction,
    contractGuid
  }) => {
    const navigate = useNavigate()
    const [modal, setModal] = useState<ReactNode>(null)
    const destroyModal = () => setModal(null)

    const hasTransactionReference = !!transaction.reference
    const canCreditTransaction = hasTransactionReference && Auth.hasPermission(["Finance.Processing.Credit:Edit"])

    const {
      toggleId,
      toggleAll,
      getSelectedAsStringArr,
      selectableIds,
      resetAll: resetSelection
    } = selectableVisitsDictionary
    const selectedIds = getSelectedAsStringArr()
    const creditingDisabled = selectedIds.length === 0

    const tableHeadLabels = useMemo(() => getItemsTableHeaderLabels(hasTransactionReference), [hasTransactionReference])

    const mappedPaymentTransactionItems = useMapPaymentTransactionItems({
      items: filteredItems,
      hasTransactionReference,
      canCreditTransaction,
      toggleId,
      selectableIds
    })

    const handleRowClick = useProcessingItemRowClick({ filteredItems })

    const creditTransactionItems = (reset: () => void) => {
      setModal(
        <CreditingModal
          guids={selectedIds}
          onCancel={destroyModal}
          periodGuid={periodGuid}
          transactionGuid={transaction.guid}
          credit_mode={TaskPreparationCreditMode.ITEMS}
          modelType={ContractModelType.PAYMENT}
          onOk={() => {
            reset()
            destroyModal()
            onVisitsCredited()
          }}
        />
      )
    }

    const alertIconClick = (item: PaymentTransactionItem) => () => {
      navigateToFinanceAlerts(
        navigate,
        objectToQueryString({
          from: formatDate(item.visit_date, DateTimeFormat.DATE_SECONDARY),
          to: formatDate(item.visit_date.plus({ days: 1 }), DateTimeFormat.DATE_SECONDARY),
          user_name: (item.reference_owner_name || "").replaceAll(".", ""),
          tab: "payment",
          models: contractGuid
        })
      )
    }

    const creditButtonDisabled = isEmpty(selectableIds)

    return (
      <RequestLoaderWrapper>
        <Form onSubmit={() => ({})}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow data-cy={testingId.tableHeaderRow}>
                      {tableHeadLabels.map((label, index) => (
                        <TableCell
                          key={index}
                          data-cy={testingId.tableHeaderCell}
                          sx={!index ? { padding: 0 } : undefined}
                        >
                          {label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isEmpty(mappedPaymentTransactionItems) ? (
                      Object.entries(mappedPaymentTransactionItems).map(([guid, row], rowIdx) => {
                        const item = filteredItems[rowIdx]
                        const isClickable = item.is_calculated && item.confirmed

                        return (
                          <SelectableTableRow
                            key={rowIdx}
                            hover={isClickable}
                            clickable={isClickable}
                            isSelected={selectedIds.includes(guid)}
                            onClick={() => isClickable && handleRowClick({ guid, rowIdx })}
                          >
                            <TransactionItemStatus item={item} />
                            {row.map((value, cellIdx) => (
                              <TableCell key={cellIdx} data-cy={testingId.tableCell}>
                                {value}
                              </TableCell>
                            ))}
                            {item.is_alerting ? (
                              <ListAlertIcon clickable={true} callback={alertIconClick(item)} />
                            ) : null}
                          </SelectableTableRow>
                        )
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={tableHeadLabels.length}>
                          <EmptyData message="No items found for filtered selection." />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {canCreditTransaction && (
                  <Box p={1} px={0} display="flex" justifyContent="flex-end">
                    <Button
                      fullWidth={false}
                      variant="text"
                      onClick={toggleAll}
                      data-cy={testingId.selectAllForCrediting}
                      disabled={creditButtonDisabled}
                    >
                      (Un)Select all for crediting
                    </Button>
                  </Box>
                )}
                <Box
                  p={2}
                  px={0}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={(theme) => ({
                    borderColor: theme.palette.text.primary,
                    borderTop: "1px solid"
                  })}
                >
                  <Typography
                    variant="h6"
                    color="primary"
                    data-cy={testingId.paymentProcessingTransactionDetail.listNumOfRecords}
                  >
                    Showing {filteredItems.length} out of {itemsLength} records
                  </Typography>
                  <div>
                    {canCreditTransaction && (
                      <Button
                        fullWidth={false}
                        color="primary"
                        disabled={creditingDisabled}
                        sx={(theme) => ({
                          marginLeft: theme.spacing(2)
                        })}
                        onClick={() => creditTransactionItems(resetSelection)}
                        data-cy={testingId.paymentProcessingTransactionDetail.creditButton}
                      >
                        Submit for crediting
                      </Button>
                    )}
                  </div>
                </Box>
              </TableContainer>
            </form>
          )}
        </Form>
        {modal !== null && modal}
      </RequestLoaderWrapper>
    )
  }
)

TransactionDetailItemsTable.displayName = "TransactionDetailItemsTable"
