import { grey } from "@mui/material/colors"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import { Checkbox, Select, TextField } from "components/Form"
import { SelectOption } from "components/Form/Select"
import { SelectDummy } from "components/Form/SelectDummy"
import testingId from "constants/testingId"
import { useSchema } from "data/schema/useSchema"
import RuleGroupRuleModel, {
  ContractModelRuleGroupRuleFormData
} from "pages/Finance/ContractModels/helpers/RuleGroupRule"
import React, { memo } from "react"
import { Field } from "react-final-form"

interface Props {
  rule: ContractModelRuleGroupRuleFormData
  conditions: SelectOption[]
  ruleKey: number
}

const Rule: React.FC<Props> = ({ conditions, ruleKey, rule }) => {
  const model = RuleGroupRuleModel(rule)

  const { RulePeriod, RuleCharge, RuleCap } = useSchema(["RulePeriod", "RuleCharge", "RuleCap"])

  const ruleChargeOptions = (RuleCharge || []).filter((charge) => charge.value !== "MISSED")

  const fieldPrefix = `rules[${ruleKey}]`
  const tableRowStyle = {
    opacity: model.isMarkedForDeletion() ? 0.3 : 1,
    backgroundColor: model.isMarkedForDeletion() ? grey[100] : "transparent"
  }

  return (
    <>
      <TableRow style={tableRowStyle}>
        <TableCell align="left">
          <Field<boolean>
            type="checkbox"
            label=""
            initialValue={false}
            component={Checkbox}
            name={`${fieldPrefix}[delete]`}
            data-cy={testingId.invoiceModel.rules.deleteCheckbox}
          />

          <Field<string>
            type="hidden"
            component="input"
            name={`${fieldPrefix}[rule_type_alias]`}
            initialValue={model.rule_type_alias}
          />
        </TableCell>
        <TableCell>
          <strong>IF</strong>
        </TableCell>
        <TableCell>
          <Field<string[]>
            component={Select}
            options={conditions}
            label="Conditions"
            multiselect={true}
            data-cy={testingId.invoiceModel.rules.conditionSelect}
            name={`${fieldPrefix}[condition_guids]`}
            noValidate={true}
            disabled={model.isMarkedForDeletion()}
            initialValue={model.condition_guids}
          />
        </TableCell>
      </TableRow>

      {!model.isConfirmedVisitRule() && (
        <TableRow style={tableRowStyle}>
          <TableCell />
          <TableCell>
            <strong>AND</strong>
          </TableCell>
          <TableCell padding="none">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ borderBottom: 0 }}>
                    {RulePeriod ? (
                      <Field
                        name={`${fieldPrefix}[period_type_alias]`}
                        label="Period Type"
                        component={Select}
                        options={RulePeriod}
                        data-cy={testingId.invoiceModel.rules.periodTypeSelect}
                        initialValue={model.period_type_alias ?? "NONE"}
                        disabled={model.isMarkedForDeletion()}
                      />
                    ) : (
                      <SelectDummy value="loading..." />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "20%",
                      borderBottom: 0
                    }}
                  >
                    <Field
                      name={`${fieldPrefix}[period_start]`}
                      component={TextField}
                      data-cy={testingId.invoiceModel.rules.periodStartInput}
                      label="Period"
                      disabled={!model.isPeriodStartRequired() || model.isMarkedForDeletion()}
                    />
                  </TableCell>
                  <TableCell
                    size="small"
                    sx={{
                      width: "20%",
                      borderBottom: 0
                    }}
                  >
                    <Field
                      label="Interval"
                      component={Select}
                      options={[
                        { title: "N/A", value: "" },
                        { title: "Minutes", value: "MINUTES" },
                        { title: "Hours", value: "HOURS" },
                        { title: "Days", value: "DAYS" }
                      ]}
                      data-cy={testingId.invoiceModel.rules.intervalSelect}
                      name={`${fieldPrefix}[period]`}
                      disabled={!model.isPeriodStartRequired() || model.isMarkedForDeletion()}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "20%",
                      borderBottom: 0
                    }}
                  >
                    <Field
                      name={`${fieldPrefix}[period_end]`}
                      component={TextField}
                      data-cy={testingId.invoiceModel.rules.periodEndInput}
                      label="Period to"
                      disabled={!model.isPeriodEndRequired() || model.isMarkedForDeletion()}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      )}

      <TableRow style={tableRowStyle}>
        <TableCell
          sx={{
            borderBottom: "1px #000 solid"
          }}
        />
        <TableCell
          sx={{
            borderBottom: "1px #000 solid"
          }}
        >
          <strong>THEN</strong>
        </TableCell>
        <TableCell
          sx={{
            borderBottom: "1px #000 solid"
          }}
          padding="none"
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    borderBottom: 0,
                    width: "40%"
                  }}
                >
                  {RuleCharge ? (
                    <Field
                      name={`${fieldPrefix}[charge_type_alias]`}
                      label="Charge type"
                      component={Select}
                      options={ruleChargeOptions}
                      initialValue={model.charge_type_alias ?? ""}
                      data-cy={testingId.invoiceModel.rules.chargeTypeSelect}
                      disabled={model.isMarkedForDeletion()}
                    />
                  ) : (
                    <SelectDummy value="loading..." />
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: 0,
                    width: "40%"
                  }}
                >
                  {RuleCap ? (
                    <Field
                      name={`${fieldPrefix}[cap_type_alias]`}
                      label="Cap Type"
                      component={Select}
                      options={RuleCap}
                      initialValue={model.cap_type_alias ?? ""}
                      data-cy={testingId.invoiceModel.rules.capTypeSelect}
                      disabled={!model.isCapTypeAliasRequired() || model.isMarkedForDeletion()}
                    />
                  ) : (
                    <SelectDummy value="loading..." />
                  )}
                </TableCell>
                <TableCell sx={{ width: "20%", borderBottom: 0 }}>
                  <Field
                    name={`${fieldPrefix}[figure]`}
                    component={TextField}
                    data-cy={testingId.invoiceModel.rules.figureInput}
                    label="Figure"
                    disabled={!model.isFigureRequired() || model.isMarkedForDeletion()}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    </>
  )
}

export default memo(Rule)
