import { DateTime, Duration, Interval } from "luxon"
import { isNil } from "ramda"

interface DateTimeUtils {
  formatToUtcISO: (date: DateTime, seconds?: number) => string | null
  formatToISO: (date: DateTime, seconds?: number) => string | null
  formatDate: (date: DateTime | string, format?: DateTimeFormat) => string
  formatTime: (date: DateTime | string) => string
  getDateOnly: (date: DateTime) => DateTime
  checkDatesOverlapped: (startDateA: DateTime, startDateB: DateTime, endDateA: DateTime, endDateB: DateTime) => boolean
  checkDatesOverlapNotEqual: (
    startDateA: DateTime,
    startDateB: DateTime,
    endDateA: DateTime,
    endDateB: DateTime
  ) => boolean
}

export enum DateTimeFormat {
  DATE = "dd/MM/yyyy",
  DATE_SECONDARY = "yyyy-MM-dd",
  DATE_TIME = "dd/MM/yyyy HH:mm",
  TIME = "HH:mm",
  TIME_DAY_MONTH = "HH:mma dd MMM",
  TIME_WITH_SECONDS = "HH:mm:ss",
  DATE_TERTIARY = "dd MMM yyyy",
  DATE_FILE = "yyyyMMdd",
  DATE_TIME_SECONDARY = "dd/MM HH:mm"
}

export const formatToUtcISO = (date: DateTime, seconds?: number): string | null => {
  return date
    .toUTC()
    .set({ second: seconds || 0, millisecond: 0 })
    .toISO({ suppressSeconds: true })
}

export const formatToISO = (date: DateTime, seconds?: number): string | null => {
  return date.set({ second: seconds || 0, millisecond: 0 }).toISO({ suppressSeconds: true })
}

export const formatDate = (date: DateTime | string, dateFormat = DateTimeFormat.DATE): string => {
  if (typeof date === "object") {
    return date.toFormat(dateFormat)
  }

  return DateTime.fromISO(date).toFormat(dateFormat)
}

export const showFormattedDate = (date?: DateTime | null): string => (date ? formatDate(date) : "--/--/--")

export const showFormattedDatePeriod = ({ start, end }: { start?: DateTime; end?: DateTime | null } = {}): string =>
  start ? `(${formatDate(start)} - ${showFormattedDate(end)})` : ""

export const formatTime = (date: DateTime | string, timeFormat = DateTimeFormat.TIME): string => {
  if (typeof date === "object") {
    return date.toFormat(timeFormat)
  }

  return DateTime.fromISO(date).toFormat(timeFormat)
}

export const getDateOnly = (date: DateTime): DateTime => {
  return date.startOf("day")
}

export const checkDatesOverlapped = (
  startDateA: DateTime,
  startDateB: DateTime,
  endDateA: DateTime,
  endDateB: DateTime
): boolean => {
  return (
    (startDateA <= startDateB && endDateA >= startDateB) ||
    (startDateA >= startDateB && endDateA <= endDateB) ||
    (startDateA <= endDateB && endDateA >= endDateB)
  )
}

export const checkDatesOverlapNotEqual = (
  startDateA: DateTime,
  startDateB: DateTime,
  endDateA: DateTime,
  endDateB: DateTime
): boolean => {
  return (
    (startDateA < startDateB && endDateA > startDateB) ||
    (startDateA > startDateB && endDateA < endDateB) ||
    (startDateA < endDateB && endDateA > endDateB)
  )
}

export const getTimeDurationString = ({
  duration,
  fromNow = true
}: {
  duration: Duration
  fromNow?: boolean
}): string => {
  const { days, hours, minutes } = duration
  let daysSegment = ""
  let hoursSegment = ""
  let minutesSegment = ""
  if (days) {
    daysSegment = `${days} day${days > 1 ? "s" : ""}, `
  }
  if (hours) {
    hoursSegment = `${hours} hour${hours > 1 ? "s" : ""}, `
  }
  if (minutes) {
    const roundedMinutes = Math.round(minutes)
    const minuteSuffix = roundedMinutes > 1 ? "s" : ""
    minutesSegment = minutes >= 1 ? `${roundedMinutes} minute${minuteSuffix}` : "Less than a minute"
  }
  return `${daysSegment}${hoursSegment}${minutesSegment}${fromNow ? " ago" : ""}`
}

export const filterNils = (date: DateTime | null | undefined): date is DateTime => {
  return !isNil(date)
}

interface PeriodDatesProps {
  start?: DateTime
  end?: DateTime | null
  no_end_date?: boolean
  [key: string]: any
}

export const getBorderPeriodDateFromArray = (
  dateArray: PeriodDatesProps[] | null | undefined
): {
  start: DateTime
  end: DateTime | null
} => {
  const starts: DateTime[] = (dateArray || []).map((cont) => cont.start).filter(filterNils)

  const mapEnds = (dateArray || []).map((cont) => cont.end)
  const ends: DateTime[] = mapEnds.filter(filterNils)
  const hasNoEndDate = mapEnds.find((end) => end === null) === null

  return { start: DateTime.min(...starts), end: hasNoEndDate ? null : DateTime.max(...ends) }
}

export const compareDateTimes = ({
  main,
  compareTo,
  unit = "minutes"
}: {
  main?: DateTime
  compareTo?: DateTime
  unit?: "minutes" | "hours"
}): number | undefined => {
  if (!main || !compareTo) return undefined

  const diffInt = Interval.fromDateTimes(main, compareTo)

  return diffInt.isValid ? Math.round(diffInt.length(unit)) : undefined
}

export default {
  formatToUtcISO,
  formatToISO,
  formatDate,
  formatTime,
  getDateOnly,
  checkDatesOverlapped,
  checkDatesOverlapNotEqual,
  getTimeDurationString
} as DateTimeUtils
