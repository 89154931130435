import { Box, Grid } from "@mui/material"
import { AutocompleteMui, FormSpyCustom, Select, TextField } from "components/Form"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { Pagination } from "lib/types"
import React, { Dispatch, memo, SetStateAction, useMemo } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"
import { useIsInvoiceModelSearch, useModelTypeSearch } from "lib/hooks/useIsInvoiceModelSearch"
import { AppointmentSearchFilterValues } from "data/finance/invoiceSearch/types"
import { AppointmentTabType } from "constants/appointmentType"
import { omit } from "ramda"
import { deepObjectEqual } from "lib/utils"
import { ClearFilterButton } from "components/ClearFilterButton"
import { formatFilterValues, FilterValues } from "lib/helpers/formatFilterValues"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"
import { useSchema } from "data/schema/useSchema"
import { removeUnassigned } from "data/schema/helpers"
import { DateField } from "components/Form/DateField"

interface Props {
  pagination: Pagination
  resetPagination: () => void
  filter: AppointmentSearchFilterValues
  setFilter: Dispatch<SetStateAction<AppointmentSearchFilterValues>>
  defaultFilterValues: { start: string; end: string; modelType: AppointmentTabType }
}

// can be sorted
const AppointmentSearchListFilter: React.FC<Props> = ({
  pagination,
  resetPagination,
  filter,
  setFilter,
  defaultFilterValues
}) => {
  const isInvoiceModel = useIsInvoiceModelSearch()
  const isScheduleModel = useModelTypeSearch({ model: AppointmentTabType.SCHEDULE })
  const isInvoiceOrScheduleModel = isInvoiceModel || isScheduleModel

  const debouncedOnSubmit = useDebouncedCallback((values: FilterValues<AppointmentSearchFilterValues>) => {
    const formattedValues = formatFilterValues<AppointmentSearchFilterValues>({
      values,
      startName: "start",
      endName: "end"
    })

    setFilter((prev) => {
      // remove errors after filter change
      const removedErrors = omit(["errors"], formattedValues) as AppointmentSearchFilterValues
      const isEqual = deepObjectEqual(omit(["errors"], prev), removedErrors)

      if (isEqual) return prev
      return removedErrors
    })
    resetPagination()
  }, 500)

  const clearFilter = () => {
    setFilter((prev) => ({ ...defaultFilterValues, modelType: prev.modelType }))
    resetPagination()
  }

  useFilterLocationChange({ filter, pagination })

  const { Client, Carer, InvoiceContract, PaymentContract, ContractServiceType, Region, TransactionStatus } = useSchema(
    ["Client", "Carer", "InvoiceContract", "PaymentContract", "ContractServiceType", "Region", "TransactionStatus"]
  )

  const ContractModelOptions = useMemo(() => {
    if (isScheduleModel) {
      return InvoiceContract
    }
    if (isInvoiceModel) {
      return removeUnassigned(InvoiceContract)
    }
    return removeUnassigned(PaymentContract)
  }, [InvoiceContract, PaymentContract, isInvoiceModel, isScheduleModel])

  const ReferenceContractModelOptions = useMemo(() => {
    if (isInvoiceModel) {
      return removeUnassigned(PaymentContract)
    }
    return removeUnassigned(InvoiceContract)
  }, [InvoiceContract, PaymentContract, isInvoiceModel])

  return (
    <Form
      {...{
        onSubmit: debouncedOnSubmit,
        initialValues: filter,
        validate: validateFilterPeriodDates({ start: "start", end: "end" })
      }}
    >
      {({ handleSubmit }) => {
        return (
          <form {...{ onSubmit: handleSubmit }}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={2}>
                <DateField name="start" label="Start" required />
              </Grid>
              <Grid item md={2}>
                <DateField name="end" label="End" required />
              </Grid>
              <Grid item md={3}>
                <Field name="clients" label="Clients" component={AutocompleteMui} options={Client} multiselect />
              </Grid>
              <Grid item md={3}>
                <Field name="carers" label="Carers" component={AutocompleteMui} options={Carer} multiselect />
              </Grid>
              <Grid item md={2}>
                <Field<string> name="reference" label="Appointment Reference" component={TextField} />
              </Grid>
              <Grid item md={2}>
                {isScheduleModel ? (
                  <Field
                    name="services"
                    label="Service Types"
                    component={Select}
                    options={ContractServiceType}
                    multiselect
                  />
                ) : (
                  <Field<string>
                    name="transaction_reference"
                    label={isInvoiceModel ? "Invoice number" : "Payroll number"}
                    component={TextField}
                  />
                )}
              </Grid>
              <Grid item md={isInvoiceModel ? 2 : 3}>
                <Field
                  name="contracts"
                  label={isInvoiceOrScheduleModel ? "Contract model" : "Pay model"}
                  component={AutocompleteMui}
                  options={ContractModelOptions}
                  multiselect
                />
              </Grid>
              {!isScheduleModel ? (
                <Grid item md={isInvoiceModel ? 2 : 3}>
                  <Field
                    name="reference_contracts"
                    label={isInvoiceModel ? "Pay model" : "Contract model"}
                    component={AutocompleteMui}
                    options={ReferenceContractModelOptions}
                    multiselect
                  />
                </Grid>
              ) : null}
              {isScheduleModel ? (
                <Grid item md={2}>
                  <Field
                    name="status"
                    label="Status"
                    component={Select}
                    options={[
                      { title: "Any", value: "" },
                      { title: "Confirmed", value: "confirmed" },
                      { title: "Unconfirmed", value: "unconfirmed" },
                      { title: "Cancelled", value: "cancelled" }
                    ]}
                  />
                </Grid>
              ) : null}
              <Grid item md={2}>
                <Field name="regions" label="Regions" component={Select} options={Region} multiselect />
              </Grid>
              {isInvoiceModel ? (
                <Grid item md={2}>
                  <Field name="statuses" label="Statuses" component={Select} options={TransactionStatus} multiselect />
                </Grid>
              ) : null}
              {!isScheduleModel ? (
                <Grid item md={2}>
                  <Field
                    name="confirmed"
                    label="Confirmation"
                    component={Select}
                    options={[
                      { title: "Any", value: "" },
                      { title: "Confirmed", value: "true" },
                      { title: "Unconfirmed", value: "false" }
                    ]}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Box textAlign="right">
              <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
            </Box>
          </form>
        )
      }}
    </Form>
  )
}

export default memo(AppointmentSearchListFilter)
