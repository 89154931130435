import React from "react"
import { styled, SxProps, Button, Typography } from "@mui/material"
import { Link } from "components"
import { unstable_styleFunctionSx, StyleFunction } from "@mui/system"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import useMutationPasswordReset from "./useMutationPasswordReset"
import Container from "./Container"
import PasswordCreateFields from "./PasswordCreateFields"
import type { PasswordFields } from "./PasswordCreateFields"

const Form = styled("form")<{ sx?: SxProps }>({
  textAlign: "right",
  ...(unstable_styleFunctionSx as StyleFunction<{ sx?: SxProps }>)
})

const PasswordReset = () => {
  const navigate = useNavigate()
  const { token = "", guid = "" } = useParams<{ guid: string; token: string }>()
  const { mutate } = useMutationPasswordReset()

  const methods = useForm<PasswordFields>({
    mode: "onChange",
    criteriaMode: "all"
  })

  const onSubmit = ({ new_password, new_password_confirm }: PasswordFields) =>
    mutate(
      {
        password: new_password,
        password_confirmation: new_password_confirm,
        reset_token: token,
        guid
      },
      {
        onSuccess: () => navigate("/password-reset-success")
      }
    )

  return (
    <Container title="Password reset">
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)} sx={{ flex: 1 }}>
          <Typography color="textSecondary" textAlign="left" mb={4}>
            Enter and confirm your new password
          </Typography>
          <PasswordCreateFields />
          <Link to="/login" sx={{ display: "inline-block", mt: 0.5, mb: 4, fontWeight: 500 }}>
            I want to sign in
          </Link>
          <Button
            variant="contained"
            size="large"
            type="submit"
            disabled={!methods.formState.isValid || methods.formState.isSubmitting}
            fullWidth
          >
            Reset my password
          </Button>
        </Form>
      </FormProvider>
    </Container>
  )
}
export default PasswordReset
