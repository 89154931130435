import { ContentContainer, EmptyData, Header } from "components"
import { usePagination } from "components/handlers/usePagination"
import { useUnitShiftCategoryListQuery } from "data/finance/unit/queries"
import { Unit } from "data/finance/unit/types"
import { queryStringToObject } from "lib/queryString"
import React, { memo, useState } from "react"
import { UnitShiftCategoryListFilter, UnitShiftCategoryFilter } from "./components/UnitShiftCategoryListFilter"
import { UnitShiftCategoryListTable } from "./components/UnitShiftCategoryListTable"
import { useLocation } from "react-router-dom"

export const UnitShiftCategoryList: React.FC<{ unit: Unit }> = memo(({ unit }) => {
  const location = useLocation();
  const [filter, setFilter] = useState<UnitShiftCategoryFilter>(queryStringToObject(location?.search))
  const { data: shiftCategories, refetch } = useUnitShiftCategoryListQuery({
    search: location?.search,
    unitGuid: unit.guid
  })

  const { pagination, setPagination, resetPagination } = usePagination()

  return (
    <ContentContainer>
      <Header title={`Units - ${unit.name} - Shift Categories`} />
      <UnitShiftCategoryListFilter {...{ pagination, resetPagination, filter, setFilter, unitGuid: unit.guid }} />
      {shiftCategories?.length ? (
        <>
          <UnitShiftCategoryListTable {...{ shiftCategories, unitGuid: unit.guid, refetch, setPagination }} />
        </>
      ) : (
        <EmptyData {...{ message: "No shift category found, start by adding one." }} />
      )}
    </ContentContainer>
  )
})

UnitShiftCategoryList.displayName = "UnitShiftCategoryList"
