import Config from "./config";

export const geocodeAddress = async (address: string) => {
  const api_key = Config.getEnvVariable("APP_GOOGLE_API_KEY");
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&components=country:GB&key=${api_key}`;

  try {
    const response = await fetch(url);
    return await response.json();
  } catch (e: any) {
    console.log(e);
  }
};
