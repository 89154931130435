import { FinanceApi } from "api"
import { useQuery } from "react-query"
import { StateSchemas } from "./types"

const buildQuery = (list: Array<keyof StateSchemas>) => {
  return list.reduce((res, item, index) => res + (index ? "&" : "") + "schemaTypes[]=" + item, "?")
}

export const useSchemaQuery = (list: Array<keyof StateSchemas>, onSuccess?: (data: StateSchemas) => void) => {
  return useQuery<StateSchemas, Error>({
    queryKey: ["schema", list],
    enabled: !!list.length,
    queryFn: async () => {
      return FinanceApi.get(`/finance/schema${buildQuery(list)}`)
    },
    onSuccess
  })
}
