import React, { memo, useEffect, useState } from "react"
import { ApiCarerContract } from "data/finance/carer/types"
import { useAdditionalTypeListQuery } from "data/finance/additional/queries"
import { AdditionalType } from "data/finance/additional/type"
import { findAdditionalByType } from "pages/Finance/InvoiceProcessing/helpers/findAdditionalByType"
import { ExtrasFormValues, ExtrasModal } from "pages/Finance/components/ExtrasModal"
import { validatePaymentExtra } from "../helpers/validatePaymentExtra"
import {
  usePaymentTransactionChargeCreateMutation,
  usePaymentTransactionChargeUpdateMutation
} from "data/finance/paymentProcessing/mutations"
import { PaymentTransaction, PaymentTransactionCharge } from "data/finance/paymentProcessing/types"
import { CalendarPeriod } from "data/finance/contractModel/types"

type OwnProps = {
  charge?: PaymentTransactionCharge
  transaction: PaymentTransaction
  period: CalendarPeriod
  destroyModal: () => void
  contract?: ApiCarerContract
}

const PaymentExtrasModal: React.FC<OwnProps> = ({ transaction, period, charge, destroyModal, contract }) => {
  const { data: additionalChargeTypes } = useAdditionalTypeListQuery({ isInvoice: false })

  const [extraType, setExtraType] = useState<AdditionalType | undefined>(undefined)

  useEffect(() => {
    if (charge && additionalChargeTypes) {
      setExtraType(findAdditionalByType({ additionalChargeTypes, charge_type_alias: charge.charge_type_alias }))
    }
  }, [charge, additionalChargeTypes])

  const { mutate: createCharge } = usePaymentTransactionChargeCreateMutation({
    onSuccess: destroyModal,
    periodGuid: period.guid,
    transactionGuid: transaction.guid
  })
  const { mutate: updateCharge } = usePaymentTransactionChargeUpdateMutation({
    onSuccess: destroyModal,
    periodGuid: period.guid,
    transactionGuid: transaction.guid
  })

  const onSubmit = (values: ExtrasFormValues) => {
    if (!extraType && values.charge_type_alias) {
      const foundItem = findAdditionalByType({ additionalChargeTypes, charge_type_alias: values.charge_type_alias })

      setExtraType(foundItem)
      return
    }

    if (extraType) {
      if (charge) {
        updateCharge({ chargeGuid: charge.guid, charge: values })
      } else {
        createCharge({ charge: values })
      }
    }
  }

  return (
    <ExtrasModal
      {...{
        period,
        charge,
        contract,
        extraType,
        additionalChargeTypes,
        destroyModal,
        onSubmit,
        additionalValidation: validatePaymentExtra
      }}
    />
  )
}

export default memo(PaymentExtrasModal)
