import { Field } from "react-final-form"
import React, { memo } from "react"
import { Select } from "."
import validators from "lib/validators"
import { FrequencyOptions } from "constants/selectOptions"

interface OwnProps {
  name?: string
  label?: string
  required?: boolean
  multiselect?: boolean
  disabled?: boolean
  withoutDaily?: boolean
}

export const FrequencySelectField = memo(
  ({ name = "frequency_type_alias", label = "Frequency", required, withoutDaily, ...rest }: OwnProps) => {
    const labelWithAsterisk = required ? `${label} *` : label

    const validate = required ? validators.required : undefined

    const options = withoutDaily ? FrequencyOptions.filter((i) => i.value !== "DAILY") : FrequencyOptions

    return (
      <Field name={name} label={labelWithAsterisk} component={Select} options={options} validate={validate} {...rest} />
    )
  }
)

FrequencySelectField.displayName = "FrequencySelectField"
