import React from "react"
import ChatRoomHeader from "./ChatRoomHeader"
import { Box } from "@mui/material"
import ChatRoomInput from "./ChatRoomInput"
import ChatRoomMessagesList from "./ChatRoomMessagesList"
import { useChatStore } from "./useChatStore"

const ChatRoom = () => {
  const currentChannel = useChatStore(({ currentChannel }) => currentChannel)

  if (!currentChannel) {
    return null
  }

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column"
      }}
    >
      <ChatRoomHeader currentChannel={currentChannel} />
      <ChatRoomMessagesList currentChannel={currentChannel} />
      <ChatRoomInput currentChannel={currentChannel} />
    </Box>
  )
}

export default ChatRoom
