import React, { createContext, ReactNode, useContext, useEffect, useState } from "react"
import Pubnub from "pubnub"
import { commsApi } from "api"
import { useChatStore } from "./useChatStore"
import AppLoading from "app/AppLoading"

type ContextType = Pubnub | undefined
export const ChatContext = createContext<ContextType>(undefined)

interface Props {
  children: ReactNode
  isMainNav?: boolean
}

const ChatProvider = ({ children, isMainNav }: Props) => {
  const [pubnub, setPubnub] = useState<Pubnub>()

  const setChannelNames = useChatStore(({ setChannelNames }) => setChannelNames)

  useEffect(() => {
    const fetchConfig = async () => {
      const {
        publish_key,
        subscribe_key,
        uuid,
        token,
        channels: { chats, carers }
      } = await commsApi.generateCommsToken()

      setPubnub(
        new Pubnub({
          publishKey: publish_key,
          subscribeKey: subscribe_key,
          uuid,
          restore: true,
          keepAlive: true,
          heartbeatInterval: 0,
          authKey: token
        })
      )
      setChannelNames(chats, carers)
    }

    if (!pubnub) {
      fetchConfig()
    }

    return () => pubnub?.destroy()
  }, [setChannelNames, pubnub])

  if (!pubnub && isMainNav) {
    return null
  }
  if (!pubnub) {
    return <AppLoading />
  }
  return <ChatContext.Provider value={pubnub}>{children}</ChatContext.Provider>
}

export const useChat = () => {
  const chat = useContext(ChatContext)
  if (!chat) {
    throw new Error("ChatProvider provider is missing from the React tree")
  } else {
    return chat
  }
}

export default ChatProvider
