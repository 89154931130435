import { Box, Grid } from "@mui/material"
import { Link } from "components"
import { Button, FormSpyCustom, TextField } from "components/Form"
import { DateField } from "components/Form/DateField"
import { StatusSelectField } from "components/Form/StatusSelectField"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { StatusEnum } from "data/commonTypes"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"
import { FilterValues, formatFilterValues } from "lib/helpers/formatFilterValues"
import { Pagination } from "lib/types"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"

export interface UnitShiftCategoryFilter {
  shift_title?: string
  start?: string
  end?: string
  status?: StatusEnum
}

interface OwnProps {
  pagination: Pagination
  resetPagination: () => void
  filter: UnitShiftCategoryFilter
  setFilter: Dispatch<SetStateAction<UnitShiftCategoryFilter>>
  unitGuid: string
}

export const UnitShiftCategoryListFilter: React.FC<OwnProps> = memo(
  ({ filter, setFilter, pagination, resetPagination, unitGuid }) => {
    const debouncedOnSubmit = useDebouncedCallback((values: FilterValues<UnitShiftCategoryFilter>) => {
      const formattedValues = formatFilterValues<UnitShiftCategoryFilter>({
        values,
        startName: "start",
        endName: "end"
      })

      setFilter(formattedValues)
      resetPagination()
    }, 500)

    useFilterLocationChange({ filter, pagination })

    return (
      <Box {...{ pt: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Form
          {...{
            initialValues: filter,
            onSubmit: debouncedOnSubmit,
            validate: validateFilterPeriodDates({ start: "start", end: "end" })
          }}
        >
          {({ handleSubmit }) => (
            <form {...{ onSubmit: handleSubmit }}>
              <FormSpyCustom {...{ handleSubmit }} />
              <Grid container alignItems="center" spacing={1}>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <Field name="shift_title" label="Search by shift title" component={TextField} />
                  </Grid>
                  <Grid item md={6}>
                    <StatusSelectField withAny />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <DateField name="start" label="Start from" />
                  </Grid>
                  <Grid item md={6}>
                    <DateField name="end" label="End to" />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
        <Box flexShrink={0} ml={4}>
          <Link to={`/units/${unitGuid}/shift-categories/new`}>
            <Button>Create a shift category</Button>
          </Link>
        </Box>
      </Box>
    )
  }
)

UnitShiftCategoryListFilter.displayName = "UnitShiftCategoryListFilter"
