import React from "react"
import { Box, Typography } from "@mui/material"
import EmptyMessageStateIcon from "../../assets/images/chat/messages_empty_state.svg?react"

const ChatRoomMessagesEmpty = () => (
  <Box sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
    <EmptyMessageStateIcon />
    <Typography variant="h6" sx={{ mt: 2 }}>
      No messages yet
    </Typography>
    <Typography color="textSecondary">Send and receive messages from the carer, here</Typography>
  </Box>
)

export default ChatRoomMessagesEmpty
