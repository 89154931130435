import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import FolderIcon from "@mui/icons-material/Folder"
import { ContentContainer, DetailSidebar, NavItem } from "components"
import React, { memo } from "react"
import RatesForm from "../RatesForm"
import Service from "../Service"
import Services from "../Services"
import ContractModelDetail from "../ContractModelDetail"
import AccrualPolicies from "../AccrualPolicies"
import Tooltip from "@mui/material/Tooltip"
import CalendarList from "../CalendarList"
import { ContractModelType } from "constants/modelTypes"
import { usePaymentModelQuery } from "data/finance/contractModel/queries"
import { PaymentModelBaseRateList } from "./PaymentModelBaseRateList"
import { PaymentModelBaseRateDetail } from "./PaymentModelBaseRateDetail"
import { AccrualPolicy } from "../AccrualPolicy"
import SalaryBands from "../SalaryBands"
import SalaryBandsDetail from "../SalaryBand"
import { useIsQA } from "lib/hooks"
import CalendarDetail from "../CalendarDetail"
import { Navigate, Route, useParams } from "react-router-dom"
import { useUIStore } from "stores"
import SentryRoutes from "components/Routes"

const modelType: ContractModelType = ContractModelType.PAYMENT

const ModelDetail: React.FC = () => {
  const { id } = useParams()
  const isSidebarOpen = useUIStore((state) => state.sidebar.isOpen)
  const { data: contractModel } = usePaymentModelQuery(id)

  const isQA = useIsQA()

  if (!contractModel) return null

  return (
    <>
      <DetailSidebar>
        <Box m={10} mx={0}></Box>
        <List component="nav">
          <NavItem to="settings" label="Settings" icon={<FolderIcon color="primary" />} />
        </List>
        <Tooltip title="Payments" placement="right">
          <Typography
            variant="h6"
            sx={(theme) => ({
              margin: theme.spacing(2, 2, 0, 2)
            })}
          >
            {isSidebarOpen ? "Payments" : "Pm"}
          </Typography>
        </Tooltip>
        <List component="nav">
          <NavItem to="accrual-policies" label="Accrual Policies" icon={<FolderIcon color="primary" />} />
          {isQA && contractModel.type_alias === "P/SALARY" ? (
            <NavItem to="salary-bands" label="Salary Bands" icon={<FolderIcon color="primary" />} />
          ) : null}
          <NavItem to="payment-services" label="Services" icon={<FolderIcon color="primary" />} />
          <NavItem to="base-rates" label="Base Rates" icon={<FolderIcon color="primary" />} />
          <NavItem to="calendars" label="Payment calendars" icon={<FolderIcon color="primary" />} />
        </List>
      </DetailSidebar>
      <ContentContainer
        sx={(theme) => ({
          padding: theme.spacing(0, 0)
        })}
      >
        <SentryRoutes>
          <Route path="/" element={<Navigate to="settings" replace />} />

          <Route path="/settings" element={<ContractModelDetail {...{ modelType, contractModel }} />} />

          {isQA && contractModel.type_alias === "P/SALARY" ? (
            <>
              <Route path="/salary-bands" element={<SalaryBands {...{ modelType, contractModel }} />} />
              <Route path="/salary-bands/new" element={<SalaryBandsDetail {...{ modelType, contractModel }} />} />
              <Route
                path="/salary-bands/:salaryGuid"
                element={<SalaryBandsDetail {...{ modelType, contractModel }} />}
              />
            </>
          ) : null}

          <Route path="/accrual-policies" element={<AccrualPolicies {...{ contractModel }} />} />
          <Route path="/accrual-policies/new" element={<AccrualPolicy {...{ contractModel }} />} />
          <Route path="/accrual-policies/:policyId" element={<AccrualPolicy {...{ modelType, contractModel }} />} />

          <Route
            path="/payment-services"
            element={<Services {...{ modelType, contractModel, isInvoicePaymentService: false }} />}
          />
          <Route
            path="/payment-services/new"
            element={<Service {...{ modelType, contractModel, isInvoicePaymentService: false }} />}
          />
          <Route
            path="/payment-services/:serviceId"
            element={<Service {...{ modelType, contractModel, isInvoicePaymentService: false }} />}
          />
          <Route
            path="/payment-services/:serviceId/rates/:bandId"
            element={<RatesForm {...{ modelType, contractModel }} />}
          />

          <Route path="/base-rates" element={<PaymentModelBaseRateList {...{ modelType, contractModel }} />} />
          <Route path="/base-rates/new" element={<PaymentModelBaseRateDetail {...{ modelType, contractModel }} />} />
          <Route
            path="/base-rates/:rateId"
            element={<PaymentModelBaseRateDetail {...{ modelType, contractModel }} />}
          />

          <Route path="/calendars" element={<CalendarList {...{ modelType, contractModel }} />} />
          <Route path="/calendars/new" element={<CalendarDetail {...{ modelType, contractModel }} />} />
          <Route path="/calendars/:calendarId" element={<CalendarDetail {...{ modelType, contractModel }} />} />
        </SentryRoutes>
      </ContentContainer>
    </>
  )
}

export default memo(ModelDetail)
