import React, { memo } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import MoneyWrapper from "components/MoneyWrapper"
import testingId from "constants/testingId"
import { getInvoiceTransactionMoney } from "../helpers/getInvoiceTransactionMoney"
import {
  InvoiceTransaction,
  InvoiceTransactionCharge,
  InvoiceTransactionItem
} from "data/finance/invoiceProcessing/types"

const amountWrapper = (amount: number, prefix?: string) => <MoneyWrapper prefix={prefix} amount={amount} />

type Props = {
  transaction?: InvoiceTransaction
  visits?: InvoiceTransactionItem[]
  charges?: InvoiceTransactionCharge[]
}

export const TransactionDetailMoney: React.FC<Props> = memo(({ transaction, visits, charges }) => {
  const money = getInvoiceTransactionMoney({ transaction, visits, charges })

  const pendingFields = [
    ["Visits:", amountWrapper(money.visits)],
    ["Extras:", amountWrapper(money.extras)],
    ["Pending Value:", amountWrapper(money.total)]
  ]

  const invoiceFields = {
    PENDING: pendingFields,
    NOCHARGE: pendingFields,
    READY: [
      ["Visits:", amountWrapper(money.visits)],
      ["Extras:", amountWrapper(money.extras)],
      ["Pending Value:", amountWrapper(money.total)]
    ],
    CREDITED: [
      ["Visits:", amountWrapper(money.visits)],
      ["Extras:", amountWrapper(money.extras)],
      ["Credit Value:", money.total > 0 ? amountWrapper(money.total, "-") : amountWrapper(Math.abs(money.total))]
    ],
    COMPLETE: [
      ["Visits:", amountWrapper(money.visits)],
      ["Extras:", amountWrapper(money.extras)],
      ["Processed Value:", amountWrapper(money.total)],
      ["Current Value:", amountWrapper(money.current)]
    ]
  }

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      flexGrow={1}
      sx={(theme) => ({
        "& div": {
          marginLeft: theme.spacing(4)
        }
      })}
    >
      {transaction &&
        invoiceFields[transaction.status_type_alias].map(([label, value], index) => (
          <div key={index} data-cy={testingId.statsItem}>
            <Typography variant="h6" component="span">
              {label}
            </Typography>{" "}
            <Typography variant="h6" color="primary" component="span">
              {value}
            </Typography>
          </div>
        ))}
    </Box>
  )
})

TransactionDetailMoney.displayName = "TransactionDetailMoney"
