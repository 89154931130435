import React, { useCallback, useEffect, useState } from "react"
import { ApiUser, ApiUserCarer, UserType } from "../models"
import Avatar from "./Avatar"
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Tooltip } from "@mui/material"
import { SchemaFieldOption } from "../lib/types"
import testingId from "constants/testingId"
import dateTimeUtils, { DateTimeFormat } from "lib/datetime"
import OnboardingStatusChip, { SquareChipColorVariant } from "components/OnboardingStatusChip"
import { paginateArray } from "lib/utils"
import { optionsToValueTitle } from "lib/schema"
import { useNavigate } from "react-router-dom"

interface Props {
  items: ApiUser[]
  statusOptions: SchemaFieldOption[]
  baseUrl: string
  listUserType: UserType
  hasLogin?: boolean
  onChangePage?: (newPage: number) => void
  onChangePageSize?: (rowsPerPage: number) => void
}

const UserList = ({
  statusOptions,
  listUserType,
  hasLogin = true,
  items,
  baseUrl,
  onChangePageSize,
  onChangePage
}: Props) => {
  const navigate = useNavigate()
  const isCarer = listUserType === UserType.Carer

  const statusMap = optionsToValueTitle(statusOptions)

  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [itemsLength, setItemsLength] = useState<number>(items.length)

  /**
   * Change the page
   */
  const changePage = useCallback(
    (newPage = 0) => {
      setPage(newPage)
      if (onChangePage) {
        onChangePage(newPage)
      }
    },
    [onChangePage]
  )

  const handleChangePage = (_: unknown, newPage: number) => {
    changePage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageSize = +event.target.value
    setRowsPerPage(newPageSize)
    if (onChangePageSize) {
      onChangePageSize(newPageSize)
    }
    changePage(0)
  }

  useEffect(() => {
    changePage(0)
    setItemsLength(items.length)
  }, [items.length, setItemsLength, changePage])

  const instanceOfApiUserCarer = (object: ApiUser): object is ApiUserCarer => {
    return "onboarding_status" in object
  }

  const getInvitationStatus = (user: ApiUser) => {
    const label_date_time = user.invitation?.label_date_time
      ? dateTimeUtils.formatDate(user.invitation?.label_date_time, DateTimeFormat.DATE_TIME)
      : ""
    return (
      <Tooltip title={label_date_time}>
        <Typography variant="body2" color="textSecondary">
          {user.invitation?.label}
        </Typography>
      </Tooltip>
    )
  }

  const getOnboardingInformation = (user: ApiUser) => {
    const pointerCursor = true
    if (instanceOfApiUserCarer(user)) {
      switch (user.onboarding_status) {
        case 0:
        case -1:
          return (
            <OnboardingStatusChip {...{ label: "Not started", color: SquareChipColorVariant.GREY, pointerCursor }} />
          )
        case 6:
          return (
            <OnboardingStatusChip {...{ label: "Completed", color: SquareChipColorVariant.GREEN, pointerCursor }} />
          )
        default:
          return (
            <OnboardingStatusChip {...{ label: "In training", color: SquareChipColorVariant.RED, pointerCursor }} />
          )
      }
    }
  }

  const getSecondaryInformation = (user: ApiUser) => {
    if (user.type === UserType.Operator) {
      return (
        <>
          <Typography component="span" variant="body2" color="textPrimary">
            <Typography component="span" variant="body2" color="textSecondary">
              Role
            </Typography>
            &nbsp;Admin
          </Typography>
        </>
      )
    } else {
      return (
        <>
          <Typography component="span" variant="body2" color="textPrimary">
            <Typography component="span" variant="body2" color="textSecondary">
              Postcode
            </Typography>
            &nbsp;
            {user.profile.address_post_code ? (
              <Typography color="textPrimary" variant="body2" component="span" data-cy={testingId.carer.list.postcode}>
                {user.profile.address_post_code}
              </Typography>
            ) : (
              <Typography color="textSecondary" variant="body2" component="span">
                Unknown
              </Typography>
            )}
          </Typography>
          {user.branch && (
            <Typography component="span" variant="body2" color="textPrimary">
              <Typography component="span" variant="body2" color="textSecondary">
                &nbsp;|&nbsp;Branch
              </Typography>
              &nbsp;{user.branch.title}
            </Typography>
          )}
        </>
      )
    }
  }

  return (
    <>
      <Table data-cy={testingId.userList}>
        <TableHead>
          <TableRow data-cy={testingId.tableHeaderRow}>
            <TableCell
              sx={{
                width: "45px"
              }}
              data-cy={testingId.tableHeaderCell}
            />
            <TableCell data-cy={testingId.tableHeaderCell}>User details</TableCell>
            {isCarer && (
              <TableCell align="center" data-cy={testingId.tableHeaderCell}>
                Onboarding
              </TableCell>
            )}
            {hasLogin && (
              <TableCell align="center" data-cy={testingId.tableHeaderCell}>
                Login
              </TableCell>
            )}
            <TableCell align="right" data-cy={testingId.tableHeaderCell}>
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginateArray(items, page, rowsPerPage).map((user: ApiUser) => {
            return (
              <TableRow
                key={user.guid}
                hover
                sx={{
                  cursor: "pointer"
                }}
                onClick={() => {
                  navigate(`${baseUrl}/${user.guid}/profile/personal-details`)
                }}
                data-cy={testingId.tableRow}
              >
                <TableCell align="center" data-cy={testingId.tableCell}>
                  <Avatar user={user} />
                </TableCell>
                <TableCell data-cy={testingId.tableCell}>
                  <Typography variant="h6" color="primary" data-cy={testingId.userListItemName}>
                    {user.profile.name}
                  </Typography>
                  {getSecondaryInformation(user)}
                </TableCell>
                {isCarer && (
                  <TableCell align="center" data-cy={testingId.tableCell}>
                    {getOnboardingInformation(user)}
                  </TableCell>
                )}
                {hasLogin && (
                  <>
                    <TableCell align="center">
                      {user.has_login ? (
                        getInvitationStatus(user)
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          Not invited
                        </Typography>
                      )}
                    </TableCell>
                  </>
                )}
                <TableCell align="right" data-cy={testingId.tableCell}>
                  {statusMap[user.status]}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={itemsLength}
        rowsPerPage={rowsPerPage}
        page={page}
        slotProps={{
          actions: {
            previousButton: {
              "aria-label": "previous page"
            },
            nextButton: {
              "aria-label": "next page"
            }
          }
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}
export default UserList
