import * as Sentry from "@sentry/react"
import Config from "../lib/config"
import React from "react"
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"
import { isUndefined } from "lodash"

const DOMAINS_TO_IGNORE_REGEX = [
  /pndsn\.com/i,
  /maps.googleapis\.com/i,
  /heap-api\.com/i,
  /survicate(-cdn)?\.com/i,
  /intercom\.io/i
]

export const initializeSentryTracing = (): void => {
  const sentryDsn = Config.getEnvVariable("APP_SENTRY_DSN")
  const version = Config.getEnvVariable("APP_BUILD_VERSION")
  const env = Config.getEnvVariable("NODE_ENV")
  Sentry.init({
    dsn: sentryDsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
        shouldCreateSpanForRequest: (url) => {
          const isExcludedDomain = DOMAINS_TO_IGNORE_REGEX.find((regex) => regex.test(url))
          return isUndefined(isExcludedDomain)
        }
      }),
      Sentry.replayIntegration()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    environment: env,
    release: version,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.1,
    denyUrls: DOMAINS_TO_IGNORE_REGEX
  })
}
