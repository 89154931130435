import { SelectableIdsDictionaryModel } from "lib/hooks"
import { TransactionInProgress, TransactionQueued, TransactionFailed } from "pages/Finance/components"
import React, { ReactNode } from "react"
import { Field } from "react-final-form"
import { Link } from "components"
import { Checkbox } from "components/Form"
import testingId from "constants/testingId"
import MoneyWrapper from "components/MoneyWrapper"
import dateTimeUtils from "lib/datetime"
import { DateTime } from "luxon"
import { timeSpanToStringDuration } from "lib/timespan"
import { Check, Clear } from "@mui/icons-material"
import { isNil } from "ramda"
import { AppointmentType } from "constants/appointmentType"
import { SxProps, Theme, Tooltip } from "@mui/material"
import { InvoiceTransaction, InvoiceTransactionItem } from "data/finance/invoiceProcessing/types"

const getServiceTypeLabel = (serviceTypesByGuid?: Record<string, string>, serviceTypeGuid?: string) => {
  if (!serviceTypesByGuid) return "Loading"
  if (!serviceTypeGuid) return "Unknown"
  return serviceTypesByGuid[serviceTypeGuid]
}

const getCreditActions = ({
  visit,
  selectableIds,
  toggleId,
  canCreditItems
}: {
  visit: InvoiceTransactionItem
  selectableIds: SelectableIdsDictionaryModel
  toggleId: (id: string) => void
  canCreditItems: boolean
}) => {
  if (visit.credit_guid) {
    return (
      <Tooltip title={visit.credit_reason ?? ""}>
        <>Credited</>
      </Tooltip>
    )
  }

  if (visit.is_failed) {
    return <TransactionFailed />
  }

  if (visit.is_queued) {
    return <TransactionQueued />
  }

  if (visit.is_processing) {
    return <TransactionInProgress />
  }

  if (!isNil(selectableIds[visit.guid]) && visit.is_creditable && canCreditItems) {
    return (
      <Field<boolean>
        type="checkbox"
        component={Checkbox}
        name={`visit[${visit.guid}]`}
        checked={selectableIds[visit.guid]}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          toggleId(visit.guid)
        }}
        data-cy={testingId.invoiceProcessingTransactionDetail.listItemCheckbox}
      />
    )
  }

  return null
}

export type InvoiceTransactionItemClickableRow = {
  isClickable: boolean
  row: React.ReactNode[]
  isAlerting: boolean
  clientName: string
  visitDate: DateTime
}

export const mapInvoiceTransactionItems = ({
  items,
  transaction,
  serviceTypesByGuid,
  selectableIds,
  toggleId,
  canCreditItems,
  sx
}: {
  items: InvoiceTransactionItem[]
  transaction: InvoiceTransaction
  serviceTypesByGuid?: Record<string, string>
  selectableIds: SelectableIdsDictionaryModel
  toggleId: (id: string) => void
  canCreditItems: boolean
  sx?: SxProps<Theme>
}): Record<string, InvoiceTransactionItemClickableRow> => {
  const mapItemToRow = (item: InvoiceTransactionItem): ReactNode[] => {
    const total = <MoneyWrapper key={item.guid} amount={item.total} />
    const baseData = [
      <Link
        key={`link=${item.appointment_guid}`}
        to={`/${item.appointment_type === AppointmentType.VISIT ? "visits" : "shifts"}/detail/${item.appointment_guid}`}
      >
        {dateTimeUtils.formatDate(item.visit_date)}
      </Link>,
      dateTimeUtils.formatTime(item.visit_date),
      item.reference_owner_type !== "Unassigned" ? (
        <Link to={`/carers/${item.reference_owner_guid}/profile/personal-details`}>{item.reference_owner_name}</Link>
      ) : (
        item.reference_owner_name
      ),
      getServiceTypeLabel(serviceTypesByGuid, item.service_type_guid)
    ]

    const creditedData = [
      ...baseData,
      timeSpanToStringDuration(item.payable_duration),
      item.processed_at ? `${item.processed_prefix ?? "CR"} - ${dateTimeUtils.formatDate(item.processed_at)}` : "-",
      item.credit_reason,
      total
    ]
    switch (transaction.status_type_alias) {
      case "PENDING":
      case "READY":
      case "NOCHARGE":
        return [
          ...baseData,
          item.confirmed ? "Conf" : "Unconf",
          timeSpanToStringDuration(item.payable_duration),
          item.is_nocharge ? <Clear color="error" /> : <Check sx={sx} />,
          total ? total : "N/A"
        ]
      case "COMPLETE":
        return [
          ...baseData,
          timeSpanToStringDuration(item.payable_duration),
          item.processed_at
            ? `${item.processed_prefix ?? "INV"} - ${dateTimeUtils.formatDate(item.processed_at)}`
            : "-",
          total,
          getCreditActions({
            visit: item,
            selectableIds,
            toggleId,
            canCreditItems
          })
        ]
      case "CREDITED":
        return creditedData
    }
  }

  return items.reduce((result: Record<string, InvoiceTransactionItemClickableRow>, item) => {
    result[item.guid] = {
      row: mapItemToRow(item),
      isClickable: item.is_calculated,
      isAlerting: item.is_alerting,
      clientName: item.reference_owner_name || "",
      visitDate: item.visit_date
    }
    return result
  }, {})
}
