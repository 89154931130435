import React from "react"
import { Header, ContentContainer } from "components"
import CarerProfile from "./CarerProfile"
import { Navigate, Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

const CarerCreate: React.FC = () => {
  return (
    <>
      <ContentContainer>
        <Header title={"Create a new carer"} />
        <SentryRoutes>
          <Route path="/" element={<Navigate replace to="/carers/new/personal-details" />} />
          <Route path=":section" element={<CarerProfile mode="create" />} />
        </SentryRoutes>
      </ContentContainer>
    </>
  )
}

export default CarerCreate
