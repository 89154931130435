import React from "react"
import ContentContainer from "./ContentContainer"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

interface Props {
  withContainer?: boolean
}

const Loader: React.FC = () => (
  <Box display="flex" alignItems="center" flexDirection="column">
    <CircularProgress size={40} />
    <Typography
      style={{
        marginTop: "40px"
      }}
    >
      Loading, please wait...
    </Typography>
  </Box>
)

const DataLoading: React.FC<Props> = ({ withContainer = true }) => {
  if (withContainer) {
    return (
      <ContentContainer>
        <Loader />
      </ContentContainer>
    )
  }

  return <Loader />
}

export default React.memo(DataLoading)
