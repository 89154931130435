import React from "react"
import { Box } from "@mui/material"
import Auth from "lib/Auth"
import PermissionDenied from "app/PermissionDenied"
import ChatProvider from "./ChatProvider"
import ChatPanel from "./ChatPanel"
import ChatRoom from "./ChatRoom"
import ChatModalImage from "./ChatModalImage"
import ChatModal from "./ChatModal"

const Chat = () => {
  if (!Auth.hasPermission(["User.Carer:Read"])) {
    return <PermissionDenied />
  }
  return (
    <ChatProvider>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <ChatPanel />
        <ChatRoom />
        <ChatModal />
        <ChatModalImage />
      </Box>
    </ChatProvider>
  )
}
export default Chat
