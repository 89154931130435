import React, { Dispatch, memo, SetStateAction } from "react"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { Field, Form } from "react-final-form"
import { FormSpyCustom, Select, AutocompleteMui } from "components/Form"
import { useDebouncedCallback } from "use-debounce"
import { DateTime } from "luxon"
import { Pagination } from "lib/types"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { ClearFilterButton } from "components/ClearFilterButton"
import { FilterValues, formatFilterValues } from "lib/helpers/formatFilterValues"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"
import { TransactionGroupFilterRunSelect } from "pages/Finance/components/TransactionGroupFilterRunSelect"
import { DateField } from "components/Form/DateField"
import { useSchema } from "data/schema/useSchema"
import { removeUnassigned } from "data/schema/helpers"
import { FrequencySelectField } from "components/Form/FrequencySelectField"
import { InvoiceTransactionGroupFilter } from "../types"

const percentageOptions = [
  { title: "Show any", value: "ShowAny" },
  { title: "Show under 100%", value: "ShowUnder100Percent" },
  { title: "Show only 100%", value: "ShowOnly100Percent" }
]

export const defaultFilterValues = {
  cycle_end_from: DateTime.local().minus({ weeks: 1 }).startOf("week").toISODate() as string,
  cycle_end_to: DateTime.local().minus({ weeks: 1 }).endOf("week").toISODate() as string,
  percent_visits_confirmed: "ShowAny",
  percent_visits_invoiced: "ShowAny"
}

interface OwnProps {
  pagination: Pagination
  resetPagination: () => void
  setFilter: Dispatch<SetStateAction<InvoiceTransactionGroupFilter>>
  filter: InvoiceTransactionGroupFilter
}

export const TransactionGroupFilter: React.FC<OwnProps> = memo(({ pagination, resetPagination, filter, setFilter }) => {
  const { InvoiceContractFinanceId, InvoiceContract, InvoiceContractType } = useSchema([
    "InvoiceContractFinanceId",
    "InvoiceContract",
    "InvoiceContractType"
  ])

  const invoiceModelOptions = removeUnassigned(InvoiceContract)

  const debouncedOnSubmit = useDebouncedCallback((values: FilterValues<InvoiceTransactionGroupFilter>) => {
    const formattedValues = formatFilterValues<InvoiceTransactionGroupFilter>({
      values,
      startName: "cycle_end_from",
      endName: "cycle_end_to"
    })

    setFilter(formattedValues)
    resetPagination()
  }, 500)

  const clearFilter = () => {
    setFilter(defaultFilterValues)
    resetPagination()
  }

  useFilterLocationChange({ filter, pagination })

  return (
    <Form
      onSubmit={debouncedOnSubmit}
      initialValues={filter}
      validate={validateFilterPeriodDates({ start: "cycle_end_from", end: "cycle_end_to" })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpyCustom {...{ handleSubmit }} />
          <Grid container alignItems="center" spacing={1}>
            <Grid item md={2}>
              <DateField name="cycle_end_from" label="Cycle end dates from" required />
            </Grid>
            <Grid item md={2}>
              <DateField name="cycle_end_to" label="To" required />
            </Grid>
            <Grid item md={2}>
              <Field
                name="percent_visits_confirmed"
                label="% of visits confirmed"
                component={Select}
                options={percentageOptions}
              />
            </Grid>
            <Grid item md={2}>
              <Field
                name="percent_visits_invoiced"
                label="% of visits invoiced"
                component={Select}
                options={percentageOptions}
              />
            </Grid>
            <Grid item md={2}>
              <FrequencySelectField multiselect />
            </Grid>
            <Grid item md={2}>
              <Field name="finance_id" label="Account" component={AutocompleteMui} options={InvoiceContractFinanceId} />
            </Grid>
            <TransactionGroupFilterRunSelect isInvoice={true} />
            <Grid item md={4}>
              <Field
                name="models"
                label="Invoice Models"
                component={AutocompleteMui}
                options={invoiceModelOptions}
                multiselect
              />
            </Grid>
            <Grid item md={4}>
              <Field
                name="contract_type_alias"
                label="Model Type"
                component={Select}
                options={InvoiceContractType}
                multiselect
              />
            </Grid>
            <Grid item md={12}>
              <Box textAlign="right">
                <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  )
})

TransactionGroupFilter.displayName = "TransactionGroupFilter"
