import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { VisitConcern } from "components"
import testingId from "constants/testingId"
import { DateTimeFormat } from "lib/datetime"
import { formatDuration } from "lib/utils"
import { statusOptions } from "models/Visit"
import React, { memo } from "react"
import { useNavigate } from "react-router-dom"
import { ApiShiftDetailVisit } from "types/Shifts/ShiftList/types"

interface Props {
  visits: ApiShiftDetailVisit[]
}

const ShiftDetailVisitTable: React.FC<Props> = ({ visits }) => {
  const navigate = useNavigate()
  if (visits.length === 0) return <p>This shift does not have any visits.</p>

  return (
    <Table data-cy={testingId.shifts.detail.visitTable}>
      <TableHead>
        <TableRow>
          <TableCell>Visit time</TableCell>
          <TableCell>Duration</TableCell>
          <TableCell>Client name</TableCell>
          <TableCell>Carer name</TableCell>
          <TableCell>Concern level</TableCell>
          <TableCell align="right">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {visits.map((visit: ApiShiftDetailVisit) => (
          <TableRow
            key={visit.guid}
            hover
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/visits/detail/${visit.guid}`)
            }}
            data-cy={testingId.shifts.detail.visitTableRow}
          >
            <TableCell data-cy={testingId.shifts.detail.visitTableCell}>
              {visit.start.toFormat(DateTimeFormat.TIME)} &mdash; {visit.end.toFormat(DateTimeFormat.TIME)}
            </TableCell>
            <TableCell data-cy={testingId.shifts.detail.visitTableCell}>
              {formatDuration(visit.start, visit.end)}
            </TableCell>
            <TableCell data-cy={testingId.shifts.detail.visitTableCell}>{visit.client_name}</TableCell>
            <TableCell data-cy={testingId.shifts.detail.visitTableCell}>{visit.carer_name}</TableCell>
            <TableCell data-cy={testingId.shifts.detail.visitTableCell}>
              <VisitConcern concern={visit.concern_level} />
            </TableCell>
            <TableCell data-cy={testingId.shifts.detail.visitTableCell} align="right">
              {statusOptions[visit.status]}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
export default memo(ShiftDetailVisitTable)
