import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { ContentContainer, EmptyData, Header, Link, Divider } from "components"
import { Button } from "components/Form"
import { optionsToValueTitle } from "lib/schema"
import React, { memo, useCallback } from "react"
import { DateTimeFormat } from "lib/datetime"
import { DateTime } from "luxon"
import { useHolidayListQuery } from "data/finance/holiday/queries"
import { ApiHoliday } from "data/finance/holiday/types"
import { useHolidayDeleteMutation } from "data/finance/holiday/mutations"
import { useSchema } from "data/schema/useSchema"
import { useLocation, useNavigate } from "react-router-dom"

const HolidayType: React.FC<{ item: ApiHoliday }> = memo(({ item }) => {
  HolidayType.displayName = "HolidayType"

  const { mutateAsync: deleteHoliday } = useHolidayDeleteMutation()

  if (item.read_only) return <span>System holiday</span>
  if (item.date <= DateTime.local()) return <span>Past holiday</span>

  return (
    <Link
      to={"."}
      onClick={() => {
        if (window.confirm("Are you sure you want to delete this holiday?")) {
          deleteHoliday({ guid: item.guid })
        }
      }}
    >
      <Typography variant="body2" color="primary">
        DELETE HOLIDAY
      </Typography>
    </Link>
  )
})

const HolidayList: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { HolidayTier, NationAlias } = useSchema(["HolidayTier", "NationAlias"])

  const { data: holidays } = useHolidayListQuery()

  const nationAliasMap = optionsToValueTitle(NationAlias)

  const getTierTitle = useCallback(
    (guid?: string) => {
      return (
        HolidayTier?.find((tier) => tier.value === guid)?.title ?? (
          <Typography variant="body2" color="textSecondary">
            Unassigned
          </Typography>
        )
      )
    },
    [HolidayTier]
  )

  return (
    <ContentContainer>
      <Header title="Holidays" />
      <Box display="flex" justifyContent="flex-end">
        <Link to="new">
          <Button>Create a new holiday</Button>
        </Link>
      </Box>
      <Divider color="divider" />
      <Box m={3} mx={0}>
        {holidays && holidays.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell size="small">Date</TableCell>
                <TableCell size="small">Nation</TableCell>
                <TableCell size="small">Invoice Tier</TableCell>
                <TableCell size="small">Payment Tier</TableCell>
                <TableCell size="small" align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {holidays.map((item) => (
                <TableRow
                  hover
                  sx={{
                    cursor: "pointer"
                  }}
                  key={item.guid}
                  onClick={() => {
                    navigate(`${location.pathname}/${item.guid}`)
                  }}
                >
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.date.toFormat(DateTimeFormat.DATE)}</TableCell>
                  <TableCell>{nationAliasMap[item.nation_alias]}</TableCell>
                  <TableCell>{getTierTitle(item.invoice_tier_guid)}</TableCell>
                  <TableCell>{getTierTitle(item.payment_tier_guid)}</TableCell>
                  <TableCell align="right">
                    <HolidayType {...{ item }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyData message="No holidays found, start by adding one." />
        )}
      </Box>
    </ContentContainer>
  )
}

export default React.memo(HolidayList)
