import Box from "@mui/material/Box"
import { ContentContainer, Link, EmptyData, Divider } from "components"
import DatesMessageHelp from "components/DatesMessageHelp"
import React, { memo, useMemo, useState } from "react"
import { ServiceList, ContractModelTitle } from "./components"
import { ContractModelPageProps } from "./types"
import { ContractModelType } from "constants/modelTypes"
import testingId from "constants/testingId"
import { useContractServiceListQuery } from "data/finance/contractModel/queries"
import { queryStringToObject } from "lib/queryString"
import { ServiceFilter, ServiceListFilter } from "./components/ServiceListFilter"
import { filterContractServices } from "./helpers/filterContractServices"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { PATH } from "constants/path"
import { useSchema } from "data/schema/useSchema"
import { removeUnassigned } from "data/schema/helpers"
import { useParams } from "react-router-dom"
import { Button } from "components/Form"

interface OwnProps extends ContractModelPageProps {
  isInvoicePaymentService: boolean
}

const Services: React.FC<OwnProps> = ({ contractModel, modelType, isInvoicePaymentService }) => {
  const location = useParams()
  const { ContractServiceType, PaymentContract } = useSchema([
    "ContractServiceType",
    isInvoicePaymentService ? "PaymentContract" : ""
  ])

  const [filter, setFilter] = useState<ServiceFilter>(queryStringToObject(location?.search))

  const paymentModelOptions = removeUnassigned(PaymentContract)

  const { data: servicesAll } = useContractServiceListQuery({
    modelType,
    contractGuid: contractModel.guid,
    isInvoicePaymentService
  })
  const services = useMemo(() => filterContractServices(servicesAll || [], filter), [servicesAll, filter])

  if (!ContractServiceType || ContractServiceType?.length === 0) {
    return (
      <ContentContainer>
        <EmptyData
          message={
            <>
              There are no care types present in the system,{" "}
              <Link to="/admin/finance/contract-service-types/new">start by adding one</Link>.
            </>
          }
        />
      </ContentContainer>
    )
  }

  if (isInvoicePaymentService && !paymentModelOptions?.length) {
    return (
      <ContentContainer>
        <EmptyData
          message={
            <>
              There are no payment models currently present in the system.{" "}
              <Link to={`${PATH.FINANCE.PAYMENT_MODELS}/new`}>start by adding one</Link>.
            </>
          }
        />
      </ContentContainer>
    )
  }

  return (
    <ContentContainer>
      <ContractModelTitle
        data-cy={testingId.contractModel.services.title}
        modelType={modelType}
        title={
          <>
            <Link to="../settings">{contractModel.title}</Link> /{" "}
            {modelType === ContractModelType.PAYMENT || isInvoicePaymentService ? "Payment" : "Invoice"} services
          </>
        }
        action={
          <Link to="new">
            <Button>Add service</Button>
          </Link>
        }
      />
      <Box m={3} mx={0}>
        <ServiceListFilter {...{ filter, setFilter, isInvoicePaymentService, paymentModelOptions }} />
        <Divider color="divider" />
        <DatesMessageHelp messageType="E" />
        <RequestLoaderWrapper>
          <ServiceList
            {...{
              modelType,
              isInvoicePaymentService,
              services,
              contractModel
            }}
          />
        </RequestLoaderWrapper>
      </Box>
    </ContentContainer>
  )
}

export default memo(Services)
