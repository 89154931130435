import { CoreApi } from "api"
import { ApiHrCarerSearch } from "./types"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { enabledByMandatoryUrlSearch } from "data/helpers"

export enum QueryKeyParts {
  HR = "hr",
  CARER = "carer"
}

export const queryKeys = {
  getHrCarersSearchKey: (query?: string): QueryKey => [QueryKeyParts.HR, QueryKeyParts.CARER, { query }]
}

export const useHrCarerSearchQuery = ({
  query = "",
  withSearch
}: {
  query?: string
  withSearch?: boolean
}): UseQueryResult<ApiHrCarerSearch, Error> => {
  return useQuery({
    queryKey: queryKeys.getHrCarersSearchKey(query),
    queryFn: async () => CoreApi.get(`/hr/carers${query}`),
    ...enabledByMandatoryUrlSearch({ withSearch, param: query })
  })
}
