import React, { memo } from "react"
import { FormSectionHeader, GeoAddressField, Select } from "components/Form"
import { Grid } from "@mui/material"
import validators from "lib/validators"
import { Field, FormSpy, FormSpyRenderProps } from "react-final-form"
import { ApiUserCarer } from "models"
import { getOptionsFromSchema } from "lib/schema"
import { useCoreSchemasStore } from "stores"

export const CarerProfileSectionAddress = memo(() => {
  const { ApiUserCarerCreate } = useCoreSchemasStore((state) => state.schema.models)
  const regionOptions = getOptionsFromSchema(ApiUserCarerCreate, "regions")

  return (
    <FormSpy subscription={{ values: true, submitFailed: true, errors: true }}>
      {({ submitFailed, errors }: FormSpyRenderProps<ApiUserCarer>) => (
        <>
          <FormSectionHeader title="Address" />
          <Grid container spacing={8}>
            <Grid item md={12} style={{ paddingBottom: 0 }}>
              <GeoAddressField<ApiUserCarer>
                error={submitFailed ? errors?.profile?.address : undefined}
                shouldRenderAddressDetails
              />
            </Grid>
            <Grid item md={6}>
              <Field
                name="regions"
                label="Regions *"
                component={Select}
                multiselect
                options={regionOptions}
                validate={validators.required}
              />
            </Grid>
          </Grid>
        </>
      )}
    </FormSpy>
  )
})

CarerProfileSectionAddress.displayName = "CarerProfileSectionAddress"
