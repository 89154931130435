import React, { useState } from "react"
import { Alert as MUIAlert, AlertProps as MUIAlertProps, AlertTitle, AlertColor, Typography } from "@mui/material"

interface AlertProps extends MUIAlertProps {
  severity: AlertColor
  canBeDismissed?: boolean
  title: string
  text: string
}

const Alert = ({ title, severity, text, canBeDismissed = true, ...rest }: AlertProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const handleClose = () => {
    if (canBeDismissed) {
      setIsOpen(false)
    }
  }

  if (!isOpen) {
    return null
  }

  return (
    <MUIAlert severity={severity} onClose={canBeDismissed ? handleClose : undefined} {...rest}>
      <AlertTitle sx={{ fontWeight: "bold" }}>{title}</AlertTitle>
      <Typography sx={{ margin: 0 }}>{text}</Typography>
    </MUIAlert>
  )
}

export default React.memo(Alert)
