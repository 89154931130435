import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { PaymentModelRate, ContractModel } from "data/finance/contractModel/types"
import React, { memo, useCallback, useMemo } from "react"
import dateTimeUtils from "lib/datetime"
import DeleteIcon from "@mui/icons-material/Delete"
import { EmptyData } from "components"
import { useSelectableIdsDictionary } from "lib/hooks"
import { Field, Form } from "react-final-form"
import { Button, Checkbox } from "components/Form"
import { isEmpty } from "ramda"
import { useBaseRateDeleteHandler } from "../../handlers/useBaseRateDeleteHandler"
import { RefetchQuery } from "data/types"
import { navigateToFinancePaymentModelBaseRate } from "lib/navigate"
import { useNavigate } from "react-router-dom"
import { StatusEnum } from "data/commonTypes"

interface OwnProps {
  rates: PaymentModelRate[]
  contractModel: ContractModel
  refetch: RefetchQuery<PaymentModelRate[]>
}

const isDeletable = (status: StatusEnum): boolean => status === StatusEnum.FUTURE

export const PaymentModelBaseRateListTable: React.FC<OwnProps> = memo(({ rates, contractModel, refetch }) => {
  const navigate = useNavigate()
  const futureRateIds: string[] = useMemo(
    () => rates.filter((rate) => isDeletable(rate.status)).map((rate) => rate.guid),
    [rates]
  )

  const { selectableIds, toggleId, getSelectedAsStringArr } = useSelectableIdsDictionary(futureRateIds)
  const selectedIds = getSelectedAsStringArr()

  const onRowClick = useCallback(
    (contractGuid: string, rateGuid: string) => () => {
      navigateToFinancePaymentModelBaseRate(navigate, contractGuid, rateGuid)
    },
    [navigate]
  )

  const onDelete = useBaseRateDeleteHandler({ selectedIds, contractModelGuid: contractModel.guid, refetch })

  return (
    <Form onSubmit={() => ({})}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <DeleteIcon />
                </TableCell>
                <TableCell>Start date</TableCell>
                <TableCell>Ends before</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rates.length ? (
                rates.map((rate) => (
                  <TableRow
                    key={rate.guid}
                    onClick={onRowClick(rate.contract_guid, rate.guid)}
                    hover
                    sx={{
                      cursor: "pointer"
                    }}
                  >
                    <TableCell>
                      {isDeletable(rate.status) ? (
                        <Field<boolean>
                          {...{
                            type: "checkbox",
                            name: `delete_${rate.guid}`,
                            component: Checkbox,
                            checked: selectableIds[rate.guid],
                            onClick: (e: React.MouseEvent) => {
                              e.stopPropagation()
                              toggleId(rate.guid)
                            }
                          }}
                        />
                      ) : null}
                    </TableCell>
                    <TableCell>{dateTimeUtils.formatDate(rate.start)}</TableCell>
                    <TableCell>{rate.end ? dateTimeUtils.formatDate(rate.end) : "//"}</TableCell>
                    <TableCell>{rate.rate}</TableCell>
                    <TableCell>{rate.status}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <EmptyData message="We did not find any base rates for selected dates and filter values." />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {rates.length ? (
            <Box textAlign="right">
              <Button variant="text" fullWidth={false} disabled={isEmpty(selectedIds)} onClick={onDelete}>
                Delete rates
              </Button>
            </Box>
          ) : null}
        </form>
      )}
    </Form>
  )
})

PaymentModelBaseRateListTable.displayName = "PaymentModelBaseRateListTable"
