import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Checkbox,
  FormControl,
  FormControlProps,
  FormHelperText,
  ListItemText,
  ListItem,
  TextField
} from "@mui/material"
import testingId from "constants/testingId"
import React, { HTMLAttributes, memo, ReactNode, useMemo } from "react"
import { FieldInputProps, FieldRenderProps } from "react-final-form"
import { SelectOption } from "./Select"

type FormValue = Array<string | number> | string | number

type OwnProps = FieldRenderProps<FormValue> & {
  multiselect?: boolean
  displayEmpty?: boolean
  options?: SelectOption[]
  label?: string
  formControlProps?: FormControlProps
  InputLabelProps?: {
    required?: boolean
  }
  helperText?: ReactNode
}

const singleProps = ({ input, options }: { input: FieldInputProps<FormValue>; options: SelectOption[] }) => ({
  renderOption: (props: HTMLAttributes<HTMLLIElement>, option: SelectOption) => (
    <ListItem {...props} key={option.value}>
      <ListItemText primary={option.title} data-cy={testingId.selectOption} data-value={option.value} />
    </ListItem>
  ),
  value: options.find((option) => input.value === String(option.value)) || null // to keep component controlled
})

const multiProps = ({ input, options }: { input: FieldInputProps<FormValue>; options: SelectOption[] }) => ({
  multiple: true,
  limitTags: 3,
  disableCloseOnSelect: true,
  renderOption: (props: HTMLAttributes<HTMLLIElement>, option: SelectOption, state: AutocompleteRenderOptionState) => (
    <ListItem {...props} key={option.value}>
      <Checkbox checked={state.selected} />
      <ListItemText primary={option.title} data-cy={testingId.selectOption} data-value={option.value} />
    </ListItem>
  ),
  value: options.filter((option) => Array.isArray(input.value) && input.value.includes(String(option.value)))
})

export const AutocompleteMui: React.FC<OwnProps> = memo(
  ({ options = [], label, input, helperText, multiselect, meta, formControlProps, ...rest }) => {
    AutocompleteMui.displayName = "AutocompleteMui"

    const selectTypeProps = useMemo(() => {
      return multiselect ? multiProps({ input, options }) : singleProps({ input, options })
    }, [input, multiselect, options])

    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched

    return (
      <FormControl
        fullWidth
        margin="normal"
        {...formControlProps}
        error={showError}
        data-cy={testingId.autocompleteField}
      >
        <Autocomplete
          {...selectTypeProps}
          // cover single and multi select, must decide at runtime to cope with Autocomplete types
          onChange={(_, value) => {
            if (Array.isArray(value)) return input.onChange(value.map((v) => v.value))
            if (value) return input.onChange(value.value)
            return input.onChange(undefined)
          }}
          id={input.name}
          renderInput={(params) => (
            <TextField
              {...params}
              name={input.name}
              label={label}
              variant="outlined"
              placeholder={rest.placeholder || "search..."}
            />
          )}
          isOptionEqualToValue={
            (option: SelectOption, value?: SelectOption) => String(option.value) === String(value?.value) // option value can be number
          }
          getOptionLabel={(option: SelectOption) => option.plainTitle ?? (option.title as string)}
          options={options}
          {...rest}
        />
        {showError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
        {!showError && helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
)
