import React from "react"
import { Box, Typography } from "@mui/material"
import { Warning } from "@mui/icons-material/"

const PermissionDenied: React.FC = () => {
  return (
    <Box display="flex" justifyContent="center" height="100vh" mx={"auto"} alignItems="center" flexDirection="column">
      <Box bgcolor={"grey[100]"} p={10} display="flex" alignItems="center" flexDirection="column">
        <Typography paragraph color="primary">
          <Warning sx={{ fontSize: 72 }} />
        </Typography>
        <Typography paragraph variant="h2" sx={{ margin: 2 }} color="textSecondary">
          Access denied
        </Typography>
        <Typography paragraph variant="h5" sx={{ margin: 2, textAlign: "center" }}>
          You {"don't"} have permissions to access this page.
        </Typography>
      </Box>
    </Box>
  )
}
export default PermissionDenied
