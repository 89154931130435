import { FormValidation } from "lib/types"
import { ExtrasFormValues } from "pages/Finance/components/ExtrasModal"
import { isEmpty } from "ramda"

export const validatePaymentExtra = (values: ExtrasFormValues): FormValidation => {
  let err = {}
  if (values.charge_type_alias === "INCENT/P" && values.total > 50) {
    err = { total: "Amount must be 50 or lower" }
  }

  if (values.charge_type_alias === "GHR/LOSS" || values.charge_type_alias === "GHR/WORK") {
    if (values.quantity > 10) {
      err = { quantity: "Time (hours) must be 10 or lower" }
    }
    if (values.rate && values.rate > 15) {
      err = { ...err, rate: "Rate must be 15 or lower" }
    }
  }

  if (values.charge_type_alias === "MILE/TOP") {
    if (values.quantity > 100) {
      err = { quantity: "Mileage must be 100 or lower" }
    }
    if (values.rate && values.rate > 15) {
      err = { ...err, rate: "Rate must be 0.45 or lower" }
    }
  }

  if (values.charge_type_alias === "ONCALL/W" && values.total > 200) {
    err = { total: "Amount must be 200 or lower" }
  }

  if (values.charge_type_alias === "E/TAX" && values.total > 20) {
    err = { total: "Amount must be 20 or lower" }
  }

  if (values.charge_type_alias === "TAXI" || values.charge_type_alias === "TRAV/TOP") {
    if (values.quantity > 10) {
      err = { quantity: "Time (hours) must be 10 or lower" }
    }
    if (values.rate && values.rate > 11.44) {
      err = { ...err, rate: "Rate must be 11.44 or lower" }
    }
  }

  if (isEmpty(err)) {
    return undefined
  }
  return err
}
