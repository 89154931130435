import React, { memo } from "react"
import MainCalendarData from "./MainCalendarData"
import { CalendarPeriods } from "./CalendarPeriods"
import { ContractModelType } from "constants/modelTypes"
import { FormStage } from "constants/form"

interface Props {
  modelType: ContractModelType
  setFormStage: React.Dispatch<React.SetStateAction<FormStage>>
}

export const CreateCalendarStepTwo: React.FC<Props> = memo(({ modelType, setFormStage }) => {
  const isInvoiceModel: boolean = modelType === ContractModelType.INVOICE

  const headers = isInvoiceModel
    ? ["Starts on", "Ends after", "Process date", "Invoice Deadline"]
    : ["Starts on", "Ends after", "Pay date"]

  return (
    <>
      <MainCalendarData setFormStage={setFormStage} isInvoiceModel={isInvoiceModel} />
      <CalendarPeriods {...{ modelType, headers }} />
    </>
  )
})

CreateCalendarStepTwo.displayName = "CreateCalendarStepTwo"
