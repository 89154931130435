import { DateTime } from "luxon"

export type OwnerType = "Unassigned" | "Shift" | "User" | "Unit"

// Invoice & Payment Processing shared types
export enum TransactionDetailType {
  VISIT = "VI",
  EXTRA = "EX"
}

export type LabourCharge = {
  guid: string
  description?: string
  start: string // timespan
  end: string // timespan
  rate: number
  duration: string // timespan
  total: number
}

export type ExtraChargeApi = {
  guid: string
  description?: string
  rate: number
  quantity: number
  total: number
}

export type ExtraCharge = {
  description?: string
  rate: string
  quantity: string
  total: string
}

export interface PaymentTransactionItemVisitDetailCalculations {
  guid: string
  band_maximum_minutes?: number
  band_minimum_minutes: number
  cancellation_notice?: string
  confirmed: boolean
  contract_name: string
  contract_reference: string
  duration_actual?: string // timespan
  duration_charge: string // timespan
  duration_rostered: string // timespan
  end_actual?: DateTime
  end_charged: DateTime
  end_rostered: DateTime
  extra_charges: ExtraChargeApi[]
  is_cancelled: boolean
  labour_charges: LabourCharge[]
  owner_guid: string
  owner_name?: string
  owner_type: OwnerType
  payment_type_title: string
  processed_at: DateTime
  reference_contract_name: string
  reference_contract_reference: string
  reference_owner_guid: string
  reference_owner_name?: string
  reference_owner_type: OwnerType
  rounding_minutes?: number
  rounding_type_title?: string
  rule_cap_figure?: number
  rule_cap_type_title?: string
  rule_charge_type_title: string
  rule_condition_title: string
  service_type_title: string
  start_actual?: DateTime
  start_charged: DateTime
  start_rostered: DateTime
  visit_date: DateTime
}

export interface InvoiceTransactionItemVisitDetailCalculations extends PaymentTransactionItemVisitDetailCalculations {
  completed_at?: DateTime
  invoice_status?: string
}

export type ChargeBreakdown = {
  day_rate?: string
  rate_period: string
  rate: string
  duration: string
  total: string
}

export type TransactionVisitDetailPaymentModel = {
  paymentModel: string
  service: string
  chargeType: string
  band: string
  roundingRule?: string
  roundingMinutes?: number
  chargeTime: string
  durationCharge: string
}

export type ChargeRules = {
  variations: string
  pay_rule: string
  cap_rule: string
  figure: string
}

export type PaymentRulesCancelledVisit = {
  reason: string
  cancellationNotice: string
  pay_rule: string
  cap_rule: string
  figure: string
}

export type InvoiceServices = {
  charge_type: string
  band: string
  rounding_rule?: string
  rounding_minutes?: number
  charge_time: string
  duration_charge: string
}

export type VisitOverview = {
  date: string
  status: string
  date_proc: string
  service: string
  rost_time: string
  rost_dur: string
  actual_time: string
  duration_actual: string
}

export type TransactionVisitDetail = {
  contract_guid: string
  contract_model: string
  owner_name?: string
  reference_owner_name?: string
  pay_model: string
  status?: string
  isCancelled: boolean
  invoice_number: string
  payroll_number: string
  invoice_date: string
  visitOverview: VisitOverview
  chargeRules: ChargeRules
  invoiceServices: InvoiceServices
  paymentModels: TransactionVisitDetailPaymentModel
  chargeBreakdown: ChargeBreakdown[]
  paymentRulesCancelledVisit: PaymentRulesCancelledVisit
  extraCharges: ExtraCharge[]
  totalValue: string
}

export interface TransactionItem {
  guid: string
  alert_guid?: string
  contract_name: string
  credit_guid?: string
  credit_reason?: string
  is_alerting: boolean
  is_calculated: boolean
  is_creditable: boolean
  is_failed: boolean
  is_processable: boolean
  is_processing: boolean
  is_queued: boolean
  owner_guid: string
  owner_name?: string
  owner_type: OwnerType
  processed_at?: DateTime
  processed_prefix?: string
  reference_contract_name: string
  reference_owner_guid: string
  reference_owner_name?: string
  reference_owner_type: OwnerType
  task_guid?: string
  total?: number
}
