import { FinanceApi } from "api"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { InvoiceFileDownloadPostResponse, InvoiceFileItem } from "../invoiceFile/types"
import { queryKeys } from "./queries"
import { useLocation, useNavigate } from "react-router-dom"
import { useUIStore } from "stores"
import { BusinessDownloadMode, InterfaceFileMode } from "pages/Finance/FileManagement/types"

interface PaymentFileDownloadPostMutationProps {
  download_mode: BusinessDownloadMode
  transaction_guids: string[]
}

interface PaymentFileInterfacePostMutationProps {
  download_mode: InterfaceFileMode
  transaction_guids: string[]
}

interface PaymentFilePatchMutationProps {
  guids: string[]
}

export const usePaymentFileDownloadPostMutation = (): UseMutationResult<
  InvoiceFileDownloadPostResponse,
  Error,
  PaymentFileDownloadPostMutationProps
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  return useMutation(
    async ({ download_mode, transaction_guids }): Promise<InvoiceFileDownloadPostResponse> => {
      return FinanceApi.post("/finance/payment-files/download", { download_mode, transaction_guids })
    },
    {
      onSuccess: async (data) => {
        navigate(`/download?job_guid=${data.job_guid}`)
        showSuccessMessage("Download request sent.")
      }
    }
  )
}

export const usePaymentFileInterfacePostMutation = (): UseMutationResult<
  InvoiceFileDownloadPostResponse,
  Error,
  PaymentFileInterfacePostMutationProps
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  return useMutation(
    async ({ download_mode, transaction_guids }): Promise<InvoiceFileDownloadPostResponse> => {
      return FinanceApi.post(`/finance/payment-files/interfaces?type=${download_mode}`, transaction_guids)
    },
    {
      onSuccess: async (data) => {
        navigate(`/download?job_guid=${data.job_guid}`)
        showSuccessMessage("Download request sent.")
      }
    }
  )
}

export const usePaymentFilePatchMutation = (): UseMutationResult<
  InvoiceFileItem,
  Error,
  PaymentFilePatchMutationProps
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()
  const location = useLocation()

  return useMutation(async ({ guids }) => FinanceApi.patch("/finance/payment-files", guids), {
    onSuccess: async () => {
      showSuccessMessage("Files successfully resubmitted to I Trent.")
      queryCache.invalidateQueries(queryKeys.getPaymentFileListKey({ search: location.search }))
    }
  })
}
