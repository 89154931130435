import React, { useState, useEffect } from "react"
import { Snackbar, SnackbarContent, IconButton, Box } from "@mui/material"
import Link from "./Link"
import { Button } from "./Form"
import { Close, CheckCircle, Warning, Error } from "@mui/icons-material"
import testingId from "constants/testingId"
import { useUIStore } from "stores"

const icon = {
  success: <CheckCircle sx={{ marginRight: 1.5, color: "success.main" }} />,
  warning: <Warning sx={{ marginRight: 1.5, color: "warning.main" }} />,
  error: <Error sx={{ marginRight: 1.5, color: "error.main" }} />
}

const GlobalMessageBar = () => {
  const message = useUIStore((state) => state.message)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(true)
  }, [message])

  if (!message) return null

  return (
    <Snackbar
      open={isOpen}
      onClose={() => setIsOpen(false)}
      anchorOrigin={{
        horizontal: "center",
        vertical: "top"
      }}
      data-cy={testingId.alertSnackBar}
      autoHideDuration={message.hideDuration || 5000}
    >
      <SnackbarContent
        sx={{
          color: "text.primary",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 1.5,
          letterSpacing: 0.15,
          ...(message.type === "error" && {
            backgroundColor: "error.tint"
          }),
          ...(message.type === "warning" && {
            backgroundColor: "warning.tint"
          }),
          ...(message.type === "success" && {
            backgroundColor: "success.tint"
          })
        }}
        message={
          <Box sx={{ display: "flex" }}>
            {icon[message.type]}
            {message.message}
          </Box>
        }
        action={
          <>
            {message.action && (
              <Link external to={message.action} color="inherit">
                <Button variant="text" color="inherit">
                  More details
                </Button>
              </Link>
            )}
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setIsOpen(false)}>
              <Close fontSize="small" />
            </IconButton>
          </>
        }
      />
    </Snackbar>
  )
}
export default GlobalMessageBar
