import { AppBar, Box, Tab, Tabs } from "@mui/material"
import testingId from "constants/testingId"
import { UserContractTab } from "pages/Finance/components/types"
import React, { Dispatch, memo, SetStateAction } from "react"
import { useLocation, useNavigate } from "react-router-dom"

interface OwnProps {
  tab: UserContractTab
  setTab: Dispatch<SetStateAction<UserContractTab>>
  isCarer?: boolean
  canSeeBankDetails?: boolean
}

export const ContractTabBar: React.FC<OwnProps> = memo(
  ({ tab, setTab, isCarer = false, canSeeBankDetails = false }) => {
    ContractTabBar.displayName = "ContractTabBar"
    const location = useLocation()
    const navigate = useNavigate()

    const handleChange = (_: React.ChangeEvent<{}>, newValue: UserContractTab) => {
      setTab(newValue)
      navigate(`${location.pathname}?tabType=${newValue}`, { replace: true })
    }

    return (
      <Box
        display="flex"
        sx={(theme) => ({
          borderBottom: "1px solid",
          borderColor: theme.palette.primary.main
        })}
      >
        <AppBar
          sx={{
            boxShadow: "none",
            width: "auto"
          }}
          position="relative"
          color="transparent"
        >
          <Tabs value={tab} onChange={handleChange}>
            <Tab label="Settings" value={UserContractTab.SETTINGS} data-cy={testingId.contract.settingsTab} />
            {isCarer && canSeeBankDetails && (
              <Tab label="Bank Details" value={UserContractTab.BANK} data-cy={testingId.contract.bankDetailsTab} />
            )}
            <Tab label="Files" value={UserContractTab.FILES} data-cy={testingId.contract.filesTab} />
          </Tabs>
        </AppBar>
      </Box>
    )
  }
)
