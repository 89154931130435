import { Box, Grid, Typography } from "@mui/material"
import testingId from "constants/testingId"
import { DateTimeFormat } from "lib/datetime"
import React, { memo } from "react"
import { ApiShiftDetailResponse } from "types/Shifts/ShiftList/types"

interface OwnProps {
  shift: ApiShiftDetailResponse
}

const ShiftDetailCheckInOut: React.FC<OwnProps> = ({ shift }) => {
  return (
    <Box m={2} mx={0}>
      <Grid container spacing={3}>
        <Grid item md={3}>
          <Typography variant="body2" color="textSecondary">
            Check in time
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" data-cy={testingId.shifts.detail.checkIn}>
              {shift.checkin_at ? shift.checkin_at.toFormat(DateTimeFormat.DATE_TIME) : "Not checked-in"}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={3}>
          <Typography variant="body2" color="textSecondary">
            Check out time
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" data-cy={testingId.shifts.detail.checkOut}>
              {shift.has_checked_out ? shift.end.toFormat(DateTimeFormat.DATE_TIME) : "Not checked-out"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default memo(ShiftDetailCheckInOut)
