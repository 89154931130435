import { Container } from "@mui/material"
import React, { useEffect } from "react"
import { Route, useLocation, Navigate } from "react-router-dom"
import { ErrorBoundary, MainContent, MainNav } from "components"
import * as Pages from "pages"
import Config from "lib/config"
import Auth from "lib/Auth"
import * as PATH from "constants/path"
import AppLoading from "./AppLoading"
import ServerFailure from "./ServerFailure"
import * as Sentry from "@sentry/react"
import useSurvicate from "../lib/useSurvicate"
import useIntercom from "../lib/useIntercom"
import CompanyInfo, { CompanyBranchFeatures } from "lib/CompanyInfo"
import useHeap from "lib/useHeap"
import { useBranchesByOperatorListQuery, useOperatorDetailQuery } from "../data/core/operator/queries"
import { useCoreSchemasStore, useAuthStore } from "stores"
import { useShallow } from "zustand/react/shallow"
import useBranchData from "lib/hooks/branchInfo/useBranchData"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { BranchFeatures } from "lib/hooks/branchInfo/types"
import SentryRoutes from "components/Routes"

const MFE_PATHS = [
  PATH.PATH_MFE_DASHBOARD,
  PATH.PATH_MFE_VISITS,
  PATH.PATH_MFE_SCHEDULER_CARER_DAILY,
  PATH.PATH_MFE_SCHEDULER_RUNS
]

const Authenticated = () => {
  Config.getEnvVariable("NODE_ENV") !== "production" && console.log("%c Authenticated user", "font-weight: bold")
  const isSentryEnabled = Config.getEnvVariable("APP_SENTRY_ENABLED").toLowerCase() === "true"
  const { hasBranchPermission, isFetching } = useBranchPermissions()
  const hasFinanceFlag = hasBranchPermission(BranchFeatures.FINANCE)
  const hasChatFlagAndPermissions =
    hasBranchPermission(BranchFeatures.CHAT) && Auth.hasPermission(["Chat:Edit", "Chat:Read"])
  const hasDashboardFlag = hasBranchPermission(BranchFeatures.DASHBOARD)
  const { data: branches } = useBranchesByOperatorListQuery()
  const user = useAuthStore((state) => state.user)

  const userType = user?.user_type
  const { fetchCoreSchemas, hasSchemasLoaded, hasServerFailure } = useCoreSchemasStore(
    useShallow(({ fetchCoreSchemas, serverFailure, schema: { coreLoaded } }) => ({
      fetchCoreSchemas,
      hasSchemasLoaded: coreLoaded,
      hasServerFailure: serverFailure
    }))
  )
  const { data: operator } = useOperatorDetailQuery({
    id: user?.user_guid
  })
  const hasSchedulerFlag = hasBranchPermission(BranchFeatures.SCHEDULER)
  const schedulerHost = Config.getEnvVariable("APP_SCHEDULER_URL")
  const dashboardHost = Config.getEnvVariable("APP_DASHBOARD_URL")
  const visitsHost = Config.getEnvVariable("APP_VISITS_URL")

  const { data: branchData } = useBranchData()
  useSurvicate(branchData, userType)

  const { data: hasIntercomFlag } = CompanyInfo.useBranchFeatures(
    user?.branch_guid,
    CompanyBranchFeatures.INTERCOM_CHAT
  )

  useHeap({
    userId: user?.user_guid,
    userType: user?.user_type,
    username: user?.user_name,
    branchId: branchData?.guid,
    branchName: branchData?.title
  })

  useIntercom({
    userId: operator?.profile?.email,
    isLoading: !hasSchemasLoaded,
    branches
  })

  useEffect(() => {
    if (!isSentryEnabled || !branchData || !user) {
      return
    }
    Sentry.setTag("branch_name", branchData.title)
    Sentry.setTag("branch_guid", branchData.guid)
    Sentry.setTag("user_guid", user?.user_guid)
    Sentry.setTag("user_type", user?.user_type)
    Sentry.setTag("micro_frontend", "DCP-WEB")
  }, [isSentryEnabled, branchData, user])

  useEffect(() => {
    fetchCoreSchemas()
  }, [fetchCoreSchemas])

  const location = useLocation()
  const isMfe = MFE_PATHS.some((mfePath) => location.pathname === mfePath)

  if (!hasSchemasLoaded && !isMfe) return <AppLoading />

  if (userType !== "Operator") {
    return (
      <SentryRoutes>
        <Route path="*" element={<Pages.NonAdminRole />} />
      </SentryRoutes>
    )
  }

  if (hasServerFailure) return <ServerFailure />

  if (isFetching) {
    return <AppLoading />
  }

  return (
    <>
      <MainNav branches={branches} hasIntercomFlag={hasIntercomFlag} />
      <MainContent>
        <ErrorBoundary location={location}>
          <Container disableGutters maxWidth={false}>
            <SentryRoutes>
              {hasDashboardFlag ? (
                <>
                  <Route path={PATH.PATH_LOGIN} element={<Navigate to={PATH.PATH_MFE_DASHBOARD} replace />} />
                  <Route path={PATH.PATH_LOGIN_SSO} element={<Navigate to={PATH.PATH_MFE_DASHBOARD} replace />} />
                  <Route path="/" element={<Navigate to={PATH.PATH_MFE_DASHBOARD} replace />} />
                  <Route
                    path={PATH.PATH_MFE_DASHBOARD}
                    element={<Pages.MicroFrontend name="Dashboard" host={dashboardHost} loggedUser={user} />}
                  />
                </>
              ) : (
                <>
                  <Route path={PATH.PATH_LOGIN} element={<Navigate to={PATH.PATH_MFE_VISITS} replace />} />
                  <Route path={PATH.PATH_LOGIN_SSO} element={<Navigate to={PATH.PATH_MFE_VISITS} replace />} />
                  <Route path="/" element={<Navigate to={PATH.PATH_MFE_VISITS} replace />} />
                </>
              )}
              <Route
                path={PATH.PATH_MFE_VISITS}
                element={<Pages.MicroFrontend name="Visits" host={visitsHost} loggedUser={user} />}
              />
              <Route path="/visits/detail/:id" element={<Pages.VisitDetail />} />
              <Route path="/shifts/*" element={<Pages.Shifts />} />
              <Route path="/units/*" element={<Pages.Units />} />
              <Route path="/carers/*" element={<Pages.Carers />} />
              <Route path="/clients/*" element={<Pages.Clients />} />
              {hasChatFlagAndPermissions && <Route path="/messages/*" element={<Pages.Chat />} />}
              <Route path="/carers-search/*" element={<Pages.CarersSearch />} />
              <Route path="/shift-categories/*" element={<Pages.ShiftCategories />} />
              {hasFinanceFlag && <Route path="/finance/*" element={<Pages.Finance />} />}
              <Route path="/reports/*" element={<Pages.Reports />} />
              <Route path="/download" element={<Pages.MyDownload />} />
              {hasSchedulerFlag && (
                <>
                  <Route
                    path={PATH.PATH_MFE_SCHEDULER_CARER_DAILY}
                    element={<Pages.MicroFrontend name="Scheduler" host={schedulerHost} loggedUser={user} />}
                  />
                  <Route
                    path={PATH.PATH_MFE_SCHEDULER_RUNS}
                    element={<Pages.MicroFrontend name="Scheduler" host={schedulerHost} loggedUser={user} />}
                  />
                </>
              )}
              <Route path="/admin/*" element={<Pages.Admin />} />
              <Route path="/sync-status/*" element={<Pages.SyncStatus />} />
              <Route path="*" element={<Pages.NotFound />} />
            </SentryRoutes>
          </Container>
        </ErrorBoundary>
      </MainContent>
    </>
  )
}

export default Authenticated
