import { BillingFrequency } from "data/finance/contractModel/types"
import { sortByDate } from "lib/sortByDate"
import { DateTime } from "luxon"
import { prop, sort } from "ramda"

export const getNextDate = (date: DateTime, frequency: BillingFrequency): DateTime => {
  const parts = frequency.split("/")
  const count = Number.parseInt(parts[0])
  const weekly = parts[1] === "WEEK"

  return date.plus(weekly ? { weeks: count } : { months: count }).minus({ days: 1 })
}

export function sortPeriodsByDate<T extends { start: DateTime; delete?: boolean }>({
  periods = [],
  asc = true,
  removeDeleted = false
}: {
  periods?: T[]
  asc?: boolean
  removeDeleted?: boolean
}): T[] {
  const sortFunction = sortByDate(asc)((period: T) => prop("start")(period))
  const periodsToSort = removeDeleted ? periods.filter((item) => !item.delete) : periods
  return sort(sortFunction, periodsToSort)
}

export function getOldestPeriod<T extends { start: DateTime; delete?: boolean }>(periods?: T[]): T {
  const sortedPeriods = sortPeriodsByDate({ periods, removeDeleted: true })
  return sortedPeriods[0] || {}
}

export function getNewestPeriod<T extends { start: DateTime; delete?: boolean }>(periods?: T[]): T {
  const sortedPeriods = sortPeriodsByDate({ periods, asc: false, removeDeleted: true })
  return sortedPeriods[0] || {}
}

const processDate = (date: DateTime): DateTime => {
  if (date && typeof date === "object") {
    return date.startOf("day")
  }
  return date
}

export const getDate = (
  billingFrequency: BillingFrequency,
  startDate: DateTime,
  procDateOffset = 0,
  invoiceOffset?: number
): ((typeOfDate: string) => DateTime) => {
  const end: DateTime = getNextDate(startDate, billingFrequency)
  const proc: DateTime = end.plus({ days: procDateOffset })
  const invoice: DateTime = end.plus({ days: invoiceOffset || 0 })

  return (typeOfDate: string): DateTime => {
    switch (typeOfDate) {
      case "start":
        return processDate(startDate)
      case "end":
        return processDate(end)
      case "processDate":
        return proc
      case "invoiceDate":
        return processDate(invoice)
      default:
        return processDate(DateTime.local())
    }
  }
}
