import React from "react"
import { FieldRenderProps } from "react-final-form"
import FormControlLabel from "@mui/material/FormControlLabel"
import MaterialCheckbox from "@mui/material/Checkbox"
import testingId from "constants/testingId"
import { omit } from "ramda"

type Props = FieldRenderProps<boolean, any> & {
  label?: string
  InputLabelProps?: {
    required?: boolean
  }
}

const Checkbox: React.FC<Props> = (props) => {
  const omittedProps = omit(["meta", "InputLabelProps"], props)
  const {
    input: { checked, name, onChange },
    ...rest
  } = omittedProps

  return (
    <FormControlLabel
      control={<MaterialCheckbox name={name} onChange={onChange} checked={checked} {...rest} />}
      label={props.label}
      data-cy={testingId.checkboxField}
    />
  )
}

export default Checkbox
