import { useInvoiceModelRuleGroupRulePatchMutation, useInvoiceModelRuleGroupUpdateMutation } from "../mutations"
import { InvoiceModelRuleGroupRequest, InvoiceModelRuleGroupRuleRequest } from "../types"
import { useApiMessage } from "stores"

export const useInvoiceModelRuleGroupWithRulesUpdateHandler = ({
  contractGuid,
  isPaymentRule,
  ruleGroupGuid
}: {
  contractGuid: string
  isPaymentRule: boolean
  ruleGroupGuid: string
}): (({
  ruleGroup,
  rules
}: {
  ruleGroup: InvoiceModelRuleGroupRequest
  rules: InvoiceModelRuleGroupRuleRequest[]
}) => Promise<void>) => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const { mutateAsync: updateRuleGroup } = useInvoiceModelRuleGroupUpdateMutation({
    contractGuid,
    isPaymentRule,
    ruleGroupGuid
  })

  const { mutateAsync: patchRules } = useInvoiceModelRuleGroupRulePatchMutation({
    contractGuid,
    isPaymentRule,
    ruleGroupGuid
  })

  return async ({
    ruleGroup,
    rules
  }: {
    ruleGroup: InvoiceModelRuleGroupRequest
    rules: InvoiceModelRuleGroupRuleRequest[]
  }) => {
    try {
      await updateRuleGroup(ruleGroup)
      await patchRules(rules)

      showSuccessMessage("Rule group updated successfully.")
    } catch (apiError: any) {
      showErrorMessage("Something went wrong, please try again.", { apiError })
    }
  }
}
