import { styled } from "@mui/material"
import React, { memo } from "react"
import { ApiPaymentAdditionalSearch } from "data/finance/additional/type"
import { Check, Clear, Schedule } from "@mui/icons-material"

const StyledApprovedIcon = styled(Check)(({ theme }) => ({
  color: theme.palette.success.main
}))

const StyledPendingIcon = styled(Schedule)(({ theme }) => ({
  color: theme.palette.warning.main
}))

const StyledErrorIcon = styled(Clear)(({ theme }) => ({
  color: theme.palette.error.main
}))

export const AdditionalSearchTableItemStatusIcon: React.FC<{ additional: ApiPaymentAdditionalSearch }> = memo(
  ({ additional }) => {
    AdditionalSearchTableItemStatusIcon.displayName = "AdditionalSearchTableItemStatusIcon"

    if (additional.status === "Approved") {
      return <StyledApprovedIcon />
    }
    if (additional.status === "Pending") {
      return <StyledPendingIcon />
    }
    return <StyledErrorIcon />
  }
)
