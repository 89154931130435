import React from "react"
import { NotFound } from "./index"
import Auth from "lib/Auth"
import PermissionDenied from "app/PermissionDenied"
import HrCarerSearch from "./HrCarerSearch"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

const CarersSearch: React.FC = () => {
  if (!Auth.hasPermission(["Hr.Carer:Read"])) {
    return <PermissionDenied />
  }

  return (
    <SentryRoutes>
      <Route path="/" element={<HrCarerSearch />} />
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  )
}
export default CarersSearch
