import React, { memo, useCallback, useEffect, useState } from "react"
import Box from "@mui/material/Box"
import { ApiUserFile, UserType } from "../models"
import { downloadFile } from "../lib/utils"
import { FileUploadForm, FileList } from "./index"
import UserDocumentsFilter, { FormFilterProps } from "./UserDocumentsFilter"
import { usePagination } from "./handlers/usePagination"
import Paginator from "./Paginator"
import { useCarerFileExpirePutMutation } from "data/core/client/mutations"
import { DateTime } from "luxon"
import { FormSectionHeader } from "./Form"
import { SchemaFieldOption } from "lib/types"
import { useApiMessage, useUIStore } from "stores"
import { queryKeys, useGetUserDocuments } from "data/user-documents/queries"
import { useQueryClient } from "react-query"
import { useLocation } from "react-router-dom"
import { queryStringToObject } from "lib/queryString"
import {
  useDeleteUserDocumentMutation,
  useReplaceUserDocumentMutation,
  useUploadUserDocumentMutation
} from "data/user-documents/mutations"
import API from "api"
import { DownloadMode, UploadFileRequest } from "data/user-documents/types"
import { useFilterLocationChange } from "./handlers/useFilterLocationChange"

interface Props {
  userType: UserType
  user: string
  categoryOptions: SchemaFieldOption[]
  categoryFilter?: boolean
}

const UserDocuments: React.FC<Props> = ({ userType, user, categoryOptions, categoryFilter = false }) => {
  const location = useLocation()
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useApiMessage()
  const [filter, setFilter] = useState<FormFilterProps>({
    ...queryStringToObject<FormFilterProps>(location.search)
  })
  const { pagination, setPagination, resetPagination } = usePagination()

  const { data } = useGetUserDocuments({ userType, userId: user, queryString: location.search })
  const showErrorMessage = useUIStore((state) => state.showErrorMessage)
  const [formValues, setFormValues] = useState({} as ApiUserFile)

  const { mutate } = useUploadUserDocumentMutation(userType)
  const { mutate: mutateReplaceUserDocs } = useReplaceUserDocumentMutation(userType)
  const { mutate: mutateDeleteUserDocs } = useDeleteUserDocumentMutation(userType)

  const onSuccessReplaceAndUploadDoc = useCallback(
    (type: "replace" | "upload") => {
      queryClient.invalidateQueries(queryKeys.getDocumentsKey(userType, user, location.search))
      setFormValues({} as ApiUserFile)
      if (type === "replace") {
        showSuccessMessage("File successfully replaced")
      } else {
        showSuccessMessage("File successfully uploaded")
      }
    },
    [location.search, queryClient, showSuccessMessage, user, userType]
  )

  const uploadFile = useCallback(
    async (values: UploadFileRequest) => {
      const payload = Object.assign({}, values, { user })
      if (values.file_guid) {
        mutateReplaceUserDocs(payload, {
          onSuccess: () => onSuccessReplaceAndUploadDoc("replace")
        })
      } else {
        mutate(payload, {
          onSuccess: () => onSuccessReplaceAndUploadDoc("upload")
        })
      }
    },
    [mutate, mutateReplaceUserDocs, onSuccessReplaceAndUploadDoc, user]
  )

  const onFilterChange = useCallback(
    (filterValues: FormFilterProps) => {
      setFilter(filterValues)
      resetPagination()
    },
    [resetPagination]
  )

  const replaceFile = useCallback((file: ApiUserFile) => setFormValues(file), [])

  const deleteFile = useCallback(
    async (file: ApiUserFile) => {
      const payload = { user, file_guid: file.file_guid }
      mutateDeleteUserDocs(payload, {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeys.getDocumentsKey(userType, user, location.search))
          showSuccessMessage("File successfully deleted")
        }
      })
    },
    [location.search, mutateDeleteUserDocs, queryClient, showSuccessMessage, user, userType]
  )

  const handleDownloadFile = useCallback(
    async (file: ApiUserFile, mode: DownloadMode) => {
      const payload = { user, file_guid: file.file_guid }
      const response: any = await new Promise((resolve, reject) =>
        API.downloadDocument(payload, userType).then(resolve).catch(reject)
      )

      try {
        const { size, type } = response.data
        if (size && type) {
          downloadFile(file.file_name, file.file_mime_type, response.data, mode)
        } else {
          throw new Error()
        }
      } catch (apiError: any) {
        showErrorMessage(
          "Something went wrong while downloading the file. Please try again later or contact user support.",
          { apiError }
        )
      }
    },
    [showErrorMessage, user, userType]
  )

  const { mutate: updateExpireDate, isSuccess } = useCarerFileExpirePutMutation()

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries(queryKeys.getDocumentsKey(userType, user, location.search))
    }
  }, [isSuccess, queryClient, user, userType, location])

  const updateExpiry = useCallback(
    ({ file, expires_at }: { file: ApiUserFile; expires_at: DateTime }) => {
      updateExpireDate({
        user_guid: user,
        file_guid: file.file_guid,
        expires_at
      })
    },
    [updateExpireDate, user]
  )

  useFilterLocationChange({ filter, pagination })

  return (
    <>
      <Box m={4} mx={0}>
        <FileUploadForm
          categoryOptions={categoryOptions}
          onSubmit={uploadFile}
          formValues={formValues}
          setFormValues={setFormValues}
        />
        <FormSectionHeader title="" />
        <UserDocumentsFilter categoryOptions={categoryOptions} filter={filter} onFilterChange={onFilterChange} />
      </Box>
      <Box m={4} mx={0}>
        <FileList
          categoryOptions={categoryOptions}
          categoryFilter={categoryFilter}
          files={data?.files}
          downloadFile={handleDownloadFile}
          replaceFile={replaceFile}
          deleteFile={deleteFile}
          updateExpiry={updateExpiry}
        />
        <Paginator setPagination={setPagination} itemsLength={data?.files.length} />
      </Box>
    </>
  )
}

export default memo(UserDocuments)
