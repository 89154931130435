import { create } from "zustand"
import API from "api"
import { UICoreSchemasState, UICoreSchemasStore } from "./types"
import { createSchemas } from "lib/schema"
import { SchemaDefinition } from "lib/types"

const INITIAL_STATE: UICoreSchemasState = {
  schema: {
    models: {},
    coreLoaded: false
  },
  serverFailure: false
}

const useCoreSchemasStore = create<UICoreSchemasStore>((set) => ({
  ...INITIAL_STATE,
  fetchCoreSchemas: async () => {
    try {
      const coreSchema = await API.schema()
      set((state) => ({
        schema: {
          ...state.schema,
          models: createSchemas(coreSchema.models as unknown as SchemaDefinition[]),
          coreLoaded: true
        }
      }))
    } catch {
      set({
        serverFailure: true
      })
    }
  }
}))

export default useCoreSchemasStore
