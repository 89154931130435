import React from "react"
import { MicroFrontend, NotFound } from "./index"
import ClientList from "./Clients/ClientList"
import ClientDetail from "./Clients/ClientDetail"
import ClientCreate from "./Clients/ClientCreate"
import Auth from "lib/Auth"
import PermissionDenied from "app/PermissionDenied"
import { Route } from "react-router-dom"
import Config from "lib/config"
import CompanyInfo, { CompanyBranchFeatures } from "lib/CompanyInfo"
import { useAuthStore } from "stores"
import useBranchSettings from "lib/hooks/branchInfo/useBranchSettings"
import { Permission } from "constants/permission"
import SentryRoutes from "components/Routes"

const Clients = () => {
  const clientMfeHost = Config.getEnvVariable("APP_CLIENTS_MFE_URL")
  const user = useAuthStore((state) => state.user)
  const { data: isClientProfileV2Enabled } = CompanyInfo.useBranchFeatures(
    user?.branch_guid,
    CompanyBranchFeatures.CLIENT_PROFILE_V2
  )

  /**
   * isClientProfileV2Enabled is used for switching the client profile endpoints.
   * For a small amount of time, we will have the ability to enable both
   * endpoints as well as both UIs.
   * The isCreateClientProfileUIV2Enabled is a temporary flag to enable
   * the new client creation UI which will be replacing the old.
   * This allows us to test the existing UI against the new endpoint whilst
   * the new UI is being developed.
   */

  const { settings: branchSettings } = useBranchSettings()
  const isCreateClientProfileUIV2Enabled =
    branchSettings["FeatureCCHTeamCreateClientProfileUIV2"]?.toLowerCase() === "true"
  const isClientProfileV2 = isCreateClientProfileUIV2Enabled && isClientProfileV2Enabled

  if (!Auth.hasPermission([Permission.USER_CLIENT_READ])) {
    return <PermissionDenied />
  }

  return (
    <SentryRoutes>
      <Route path="/" element={<ClientList />} />

      {isClientProfileV2 ? (
        <Route path="create/*" element={<MicroFrontend name="Clients" loggedUser={user} host={clientMfeHost} />} />
      ) : (
        <Route path="new/*" element={<ClientCreate />} />
      )}

      <Route path=":id/*" element={<ClientDetail />} />
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  )
}
export default Clients
