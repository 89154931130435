import { Box } from "@mui/material"
import { Button, FormSectionHeader } from "components/Form"
import { ApiContractAccrualItem } from "data/finance/carer/types"
import { ApiUserCarer } from "models"
import React, { memo, useState } from "react"
import { CarerAccrualDetailListModal } from "./CarerAccrualDetailListModal"
import { CarerAccrualDetailListFilter, CarerAccrualItemFilterValues } from "./CarerAccrualDetailListFilter"
import { queryStringToObject } from "lib/queryString"
import { usePagination } from "components/handlers/usePagination"
import Auth from "lib/Auth"
import { Permission } from "constants/permission"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { CarerAccrualDetailListTable } from "./CarerAccrualDetailListTable"
import { useLocation } from "react-router-dom"

interface OwnProps {
  carer: ApiUserCarer
  accrualGuid: string
}

export const CarerAccrualDetailList: React.FC<OwnProps> = memo(({ carer, accrualGuid }) => {
  CarerAccrualDetailList.displayName = "CarerAccrualDetailList"

  const location = useLocation()
  const hasModelEditPermission = Auth.hasPermission([Permission.FINANCE_MODEL_EDIT])

  const [modal, setModal] = useState<React.ReactNode>(null)

  const destroyModal = () => {
    setModal(null)
  }

  const openModal = (accrualItem?: ApiContractAccrualItem) => () => {
    setModal(<CarerAccrualDetailListModal {...{ accrualItem, destroyModal, carerGuid: carer.guid, accrualGuid }} />)
  }

  const { pagination, setPagination, resetPagination } = usePagination({ initialPageSize: 100 })
  const [filter, setFilter] = useState<CarerAccrualItemFilterValues>({
    hide_zero: true,
    ...queryStringToObject(location.search)
  })

  return (
    <RequestLoaderWrapper>
      <Box my={3}>
        <FormSectionHeader {...{ title: "Breakdown" }} />

        <Box
          my={3}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <CarerAccrualDetailListFilter {...{ filter, setFilter, pagination, resetPagination }} />
          {hasModelEditPermission && (
            <Button onClick={openModal()} fullWidth={false} sx={{ flexShrink: 0 }}>
              Add Adjustment
            </Button>
          )}
        </Box>
        <CarerAccrualDetailListTable
          {...{ hasModelEditPermission, setPagination, pagination, openModal, filter, carer, accrualGuid }}
        />
        {modal ?? null}
      </Box>
    </RequestLoaderWrapper>
  )
})
