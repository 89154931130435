import { DateTime } from "luxon"
import { RiskScoreRecord, RiskScoreValue, RiskScoreValues } from "models/mobilityAssessment"

export const getTextScore = (value: number) => {
  switch (value) {
    case 3:
      return "High"
    case 2:
      return "Medium"
    case 1:
      return "Low"
  }
}

const DAYS_TO_SHOW_FALL_RISK_SCORE = 30

export const getNumericScore = (value?: RiskScoreValue | null): number | null => {
  if (value === RiskScoreValues.LOW) {
    return 1
  }
  if (value === RiskScoreValues.MEDIUM) {
    return 2
  }
  if (value === RiskScoreValues.HIGH) {
    return 3
  }
  return null
}

export const parseFallRiskScoreRecords = (
  fallRiskScoreRecords: RiskScoreRecord[]
): {
  date: string
  valueLabel: RiskScoreValue | null | undefined
  value: number | null | undefined
}[] => {
  const day1 = DateTime.now().minus({ days: DAYS_TO_SHOW_FALL_RISK_SCORE - 1 })
  return Array.from({ length: DAYS_TO_SHOW_FALL_RISK_SCORE }).map((_, index: number) => {
    const day = day1.plus({ day: index }).toFormat("yyyy-MM-dd")
    const record = fallRiskScoreRecords.find((record) => record.date === day)
    return {
      date: day,
      valueLabel: record?.value,
      value: getNumericScore(record?.value)
    }
  })
}
