import { Box, Grid } from "@mui/material"
import { ClearFilterButton } from "components/ClearFilterButton"
import { AutocompleteMui, FormSpyCustom, TextField } from "components/Form"
import { DateField } from "components/Form/DateField"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { ContractModelType } from "constants/modelTypes"
import { removeUnassigned } from "data/schema/helpers"
import { useSchema } from "data/schema/useSchema"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"
import { FilterValues, formatFilterValues } from "lib/helpers/formatFilterValues"
import { Pagination } from "lib/types"
import React, { Dispatch, memo, SetStateAction, useMemo } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"

export interface InterfaceFileFilterValues {
  period_start_from: string
  period_start_to: string
  modelType: ContractModelType
}

interface OwnProps {
  filter: InterfaceFileFilterValues
  setFilter: Dispatch<SetStateAction<InterfaceFileFilterValues>>
  pagination: Pagination
  resetPagination: () => void
  defaultFilterValues: {
    period_start_from: string
    period_start_to: string
    modelType: ContractModelType
  }
}

export const InterfaceFileFilter: React.FC<OwnProps> = memo(
  ({ filter, setFilter, resetPagination, pagination, defaultFilterValues }) => {
    const isInvoiceModel = filter.modelType === ContractModelType.INVOICE

    const { InvoiceContract, PaymentContract } = useSchema(["InvoiceContract", "PaymentContract"])
    const contractOptions = useMemo(
      () => (isInvoiceModel ? removeUnassigned(InvoiceContract) : removeUnassigned(PaymentContract)),
      [isInvoiceModel, InvoiceContract, PaymentContract]
    )

    const debouncedOnSubmit = useDebouncedCallback((values: FilterValues<InterfaceFileFilterValues>) => {
      const formattedValues = formatFilterValues<InterfaceFileFilterValues>({
        values,
        startName: "period_start_from",
        endName: "period_start_to"
      })

      setFilter(formattedValues)
      resetPagination()
    }, 500)

    const clearFilter = () => {
      setFilter((oldValues) => ({ ...defaultFilterValues, modelType: oldValues.modelType }))
      resetPagination()
    }

    useFilterLocationChange({ filter, pagination })

    return (
      <Box {...{ pt: 2 }}>
        <Form
          {...{
            onSubmit: debouncedOnSubmit,
            initialValues: filter,
            validate: validateFilterPeriodDates({ start: "period_start_from", end: "period_start_to" })
          }}
        >
          {({ handleSubmit }) => (
            <form {...{ onSubmit: handleSubmit }}>
              <FormSpyCustom {...{ handleSubmit }} />
              <Grid {...{ container: true, alignItems: "center", spacing: 1 }}>
                <Grid {...{ item: true, md: 3 }}>
                  <DateField name="period_start_from" label="Start From" required />
                </Grid>
                <Grid {...{ item: true, md: 3 }}>
                  <DateField name="period_start_to" label="To" required />
                </Grid>
                <Grid {...{ item: true, md: 3 }}>
                  <Field
                    name="models"
                    label={isInvoiceModel ? "Contract models" : "Pay models"}
                    component={AutocompleteMui}
                    options={contractOptions}
                    multiselect
                  />
                </Grid>
                <Grid {...{ item: true, md: 3 }}>
                  <Field name="reference" label="Reference" component={TextField} />
                </Grid>
              </Grid>
              <Grid {...{ container: true, alignItems: "center", justifyContent: "flex-end" }}>
                <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
              </Grid>
            </form>
          )}
        </Form>
      </Box>
    )
  }
)

InterfaceFileFilter.displayName = "InterfaceFileFilter"
