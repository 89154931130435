import * as constants from "lib/constants"
import { DateTime } from "luxon"
import { isNil } from "ramda"

const messages = {
  fieldInvalidRequired: "This field is required",
  fieldInvalidEmail: "Value must be a valid email address",
  fieldInvalidPhone: "Value must be a valid phone number",
  fieldCanNotBeZero: "Value can not be zero",
  fieldInvalidName:
    "Must ONLY contain their name and can only comprise of valid letters and/or these special characters ' ` - , . and must not be longer than 64 characters."
}

export type ValidatorValue = string | number | Array<string> | Array<number> | null | undefined | DateTime
export type ValidatorReturnValue = string | undefined

export const DEFAULT_MAX_NUMBER = 1000000
export const NUMBER_OF_WEEKS_IN_A_YEAR = 52

const required = (
  value: string | number | Array<string | number> | boolean | null | undefined | DateTime
): ValidatorReturnValue => {
  const valueIsNil = isNil(value)
  const valueIsEmptyArray = Array.isArray(value) && value.length === 0
  const valueIsEmptyString = typeof value === "string" && value.trim().length === 0

  if (valueIsNil || valueIsEmptyArray || valueIsEmptyString) {
    return messages.fieldInvalidRequired
  }

  return undefined
}

const phone = (value: string): ValidatorReturnValue => {
  if (!value) return undefined
  return constants.phoneValidationRegex.test(value) ? undefined : messages.fieldInvalidPhone
}

const email = (value: string): ValidatorReturnValue => {
  if (!value) return undefined
  return constants.emailValidationRegex.test(String(value).toLowerCase()) ? undefined : messages.fieldInvalidEmail
}

const name = (value: string): ValidatorReturnValue => {
  if (!value) return undefined
  return constants.nameValidationRegex.test(String(value).toLowerCase()) ? undefined : messages.fieldInvalidName
}

const minLength =
  (min = 1) =>
  (value: string): ValidatorReturnValue =>
    !value || value.length >= min ? undefined : `Value must have at least ${min} characters`

/**
 * Validates string length
 *
 * @param max OPTIONAL - default: 255
 */
const maxLength =
  (max = 255) =>
  (value: string): ValidatorReturnValue =>
    !value || value.length <= max ? undefined : `Value cannot exceed ${max} characters`

const validDate = (value?: string | object): ValidatorReturnValue =>
  value?.toString() === "Invalid DateTime" ? "Enter a valid date" : undefined

const mustBeLaterDateTime =
  (earlierDate?: DateTime, field?: string) =>
  (date: DateTime | null): ValidatorReturnValue => {
    if (!earlierDate || !date) {
      return undefined
    }
    const fieldName = field ? `than ${field}` : ""
    return date < earlierDate ? `Must be a later date & time ${fieldName}` : undefined
  }

const mustBeNumber = (value: number): ValidatorReturnValue =>
  Number.isFinite(Number(value)) ? undefined : "Must be a number"

const minValue =
  (min: number) =>
  (value: number): ValidatorReturnValue =>
    isNaN(value) || value >= min ? undefined : `Minimal value is ${min}`

const minValueOrNull =
  (min: number) =>
  (value: number): ValidatorReturnValue =>
    isNaN(value) || value === null || value >= min ? undefined : `Minimal value is ${min}`

const maxValue =
  (max: number) =>
  (value: number): ValidatorReturnValue =>
    isNaN(value) || value <= max ? undefined : `Maximal value is ${max}`

const precision =
  (decimalPlaces: number): ((value: string) => ValidatorReturnValue) =>
  (value: string) => {
    const regex = new RegExp(constants.precisionValidationRegex(decimalPlaces))
    return regex.test(value) ? undefined : `Maximum of decimal places is ${decimalPlaces}`
  }

const availableValueMustBeANumber =
  (min?: number, max?: number) =>
  (value?: number): ValidatorReturnValue => {
    if (!value) return undefined
    if (mustBeNumber(value)) return mustBeNumber(value)
    if (!isNil(min) && minValue(min)(value)) return minValue(min)(value)
    if (!isNil(max) && maxValue(max)(value)) return maxValue(max)(value)
    return undefined
  }

const canNotBeZero = (value?: string | number | null): ValidatorReturnValue =>
  !isNil(value) && +value === 0 ? messages.fieldCanNotBeZero : undefined

/**
 * Validates required number
 *
 * mustBenumber
 * *
 * @param min OPTIONAL - default: 0.001
 * @param precisionValue OPTIONAL - default: 3
 * @param max OPTIONAN - default: 1000000
 */
export const validateCommonNumber =
  ({ min = 0.001, precisionValue = 3, max = DEFAULT_MAX_NUMBER } = {}) =>
  (num?: number | string): string | undefined =>
    composeValidators([required, mustBeNumber, minValue(min), precision(precisionValue), maxValue(max)])(num)

const age = (dob: DateTime | null): ValidatorReturnValue => {
  if (isNil(dob)) {
    return undefined
  }
  const start = dob.plus({ days: 1 })
  const end = DateTime.local()
  const diff = end.diff(start, "years")
  diff.toObject()
  if (diff.years > 99) {
    return "Person is over the age of 99. Is this correct?"
  }
  if (diff.years < 18 && diff.years >= 0) return "Person is under the age of 18. It this correct?"
  return undefined
}

const futureDate = (date: DateTime | null): ValidatorReturnValue => {
  if (isNil(date)) {
    return undefined
  }
  const now = DateTime.local()
  const diff = now.diff(date, "milliseconds")
  diff.toObject()
  if (diff.milliseconds < 0) {
    return "Selected date is in the future"
  }
  return undefined
}

const mustBeFutureDateTime = (date: DateTime | null): ValidatorReturnValue => {
  if (isNil(date)) {
    return undefined
  }
  const now = DateTime.local()
  const diff = now.diff(date, "minutes")
  diff.toObject()
  if (diff.minutes > 1) {
    return "Selected date and time is in the past"
  }
  return undefined
}

export const composeValidators =
  (validators: any) =>
  (value: ValidatorValue): ValidatorReturnValue => {
    const result = Object.keys(validators).reduce((error: string, key: string) => error || validators[key](value), "")
    if (result) return result
  }

export const composeUnrequiredValidators =
  (validators: any) =>
  (value: ValidatorValue): ValidatorReturnValue => {
    if (!value) return undefined

    return composeValidators(validators)(value)
  }

const validators = {
  required,
  email,
  name,
  validDate,
  minLength,
  maxLength,
  mustBeNumber,
  minValue,
  maxValue,
  precision,
  phone,
  minValueOrNull,
  age,
  futureDate,
  canNotBeZero,
  mustBeLaterDateTime,
  mustBeFutureDateTime,
  availableValueMustBeANumber,
  validateCommonNumber
}

export default validators
