import React, { ReactNode } from "react"
import { Box, Typography } from "@mui/material"
import Logo from "../../assets/svg/logo.svg?react"
import background from "../../assets/svg/login_background.svg"
import { purple } from "@mui/material/colors"
import { DateTime } from "luxon"
import Config from "lib/config"
import { Link } from "components"

interface Props {
  children: ReactNode
  title: string
  hasLoginLink?: boolean
}

const Container = ({ children, title, hasLoginLink = true }: Props) => {
  const isSSOLoginEnabled = Config.getEnvVariable("APP_SSO_LOGIN_ENABLED").toLowerCase() === "true"

  return (
    <Box sx={{ height: "100vh", display: ["block", null, "flex", null, null, null] }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: 10,
          minHeight: "100%",
          p: [3, 7, 7, 7, 7],
          flex: 1,
          maxWidth: 1000,
          "@media only screen and (min-width: 700px)": {
            py: 7,
            px: 18
          },
          "@media only screen and (min-width: 900px)": {
            py: 7,
            px: 7
          }
        }}
      >
        <Logo />
        <Typography variant="h3" sx={{ mt: 7, mb: 3 }}>
          {title}
        </Typography>
        {children}
        {isSSOLoginEnabled && hasLoginLink && (
          <Typography color="textSecondary" mb={2}>
            Sign in using your&nbsp;
            <Link to="/login-sso" sx={{ fontWeight: 500 }}>
              Google account
            </Link>
          </Typography>
        )}
        <Typography variant="body2" color="textSecondary">
          © {DateTime.now().year} Cera Care
        </Typography>
      </Box>
      <Box
        sx={{
          bgcolor: purple[200],
          backgroundImage: `url(${background})`,
          flex: 2,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "100%"
        }}
      ></Box>
    </Box>
  )
}

export default Container
