import React, { memo } from "react"
import { Field, FormSpy } from "react-final-form"
import { FormSectionHeader, Select, Checkbox, TextField } from "components/Form"
import Grid from "@mui/material/Grid"
import testingId from "constants/testingId"
import { useSchema } from "data/schema/useSchema"
import validators, { composeUnrequiredValidators, composeValidators } from "lib/validators"
import { SelectDummy } from "components/Form/SelectDummy"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { BranchFeatures } from "lib/hooks/branchInfo/types"

interface Props {
  isPublicInvoiceModel: boolean
}

export const ContractModelFormInvoiceFields: React.FC<Props> = memo(({ isPublicInvoiceModel }) => {
  const { hasBranchPermission } = useBranchPermissions()
  const hasBranchCM2000enabled = hasBranchPermission(BranchFeatures.CM2000)

  const { ContractInfoIncomeCategory, InvoiceRun } = useSchema(["ContractInfoIncomeCategory", "InvoiceRun"])

  const runField = () => {
    return InvoiceRun ? (
      <Field
        name="run_guid"
        label="Invoice Run"
        component={Select}
        options={[{ title: "Unset", value: "" }, ...InvoiceRun]}
      />
    ) : (
      <SelectDummy value="loading..." />
    )
  }

  const nfcField = () => <Field name="require_nfc" label="Require NFC" component={Checkbox} type="checkbox" />

  const costCentreField = () => (
    <Field
      name="info.cost_centre"
      label="Cost centre *"
      component={TextField}
      validate={composeValidators([validators.required, validators.maxLength(16)])}
    />
  )

  const shiftsEnabled = (disabled: boolean) => (
    <Field name="shifts_enabled" label="Shifts Enabled" component={Checkbox} type="checkbox" disabled={disabled} />
  )

  const incomeCategorySelectField = () =>
    ContractInfoIncomeCategory ? (
      <Field
        name="info.income_category_alias"
        label="Income Category *"
        component={Select}
        options={ContractInfoIncomeCategory}
        validate={validators.required}
      />
    ) : (
      <SelectDummy value="loading..." />
    )

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        return (
          <>
            <Grid container spacing={8}>
              <Grid item md={6}>
                <Field
                  name="dont_process_invoice"
                  label="Ignore appointments for invoice"
                  component={Checkbox}
                  type="checkbox"
                />
                <Field
                  name="dont_process_payment"
                  label="Ignore appointments for payment"
                  component={Checkbox}
                  type="checkbox"
                />
                {shiftsEnabled(values.dont_process_invoice)}
              </Grid>
              <Grid item md={6}>
                {runField()}
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid item md={6}>
                <Field
                  name="info.contact_name"
                  label="Contact name"
                  component={TextField}
                  validate={validators.maxLength()}
                />
                <Field
                  name="info.contact_email"
                  label="Contact email"
                  component={TextField}
                  validate={composeUnrequiredValidators([validators.email, validators.maxLength()])}
                />
                {nfcField()}
                <Field
                  name="lca_enabled"
                  label="Allow late confirmed appointments"
                  component={Checkbox}
                  type="checkbox"
                />
                <Field name="ignore_agency" label="Ignore agency staff" component={Checkbox} type="checkbox" />
              </Grid>
              <Grid item md={6}>
                {costCentreField()}
                {incomeCategorySelectField()}
              </Grid>
            </Grid>
            {hasBranchCM2000enabled && (
              <>
                <FormSectionHeader title="CM Mobile" />
                <Grid container spacing={8}>
                  <Grid item md={6}>
                    <Field
                      name="cm2000_enabled"
                      label="Enable CM Mobile"
                      component={Checkbox}
                      type="checkbox"
                      data-cy={testingId.contractModelSettings.enableCm2000Checkbox}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {isPublicInvoiceModel ? (
              <>
                <FormSectionHeader title="Salesforce" />
                <Grid container spacing={8}>
                  <Grid item md={6}>
                    <Field
                      name="info.crm_details.salesforce.id"
                      label="Salesforce ID *"
                      component={TextField}
                      validate={validators.required}
                    />
                    <Field
                      name="info.crm_details.salesforce.funder"
                      label="Salesforce funder *"
                      component={TextField}
                      validate={validators.required}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Field
                      name="info.crm_details.salesforce.contract_name"
                      label="Salesforce contract name *"
                      component={TextField}
                      validate={validators.required}
                    />
                  </Grid>
                </Grid>
              </>
            ) : null}
          </>
        )
      }}
    </FormSpy>
  )
})

ContractModelFormInvoiceFields.displayName = "ContractModelFormInvoiceFields"
