import { ContentContainer, TabPanel } from "components"
import React, { memo, useCallback, useState } from "react"
import { TransactionDetailTabBar } from "./components/TransactionDetailTabBar"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import {
  useInvoiceProcessingTransactionChargeListQuery,
  useInvoiceProcessingTransactionQuery,
  useInvoiceProcessingTransactionVisitListQuery
} from "data/finance/invoiceProcessing/queries"
import { TransactionDetailChargeTab } from "./components/TransactionDetailChargeTab"
import { TransactionDetailItemTab } from "./components/TransactionDetailItemTab"
import { TransactionDetailHeader } from "./components/TransactionDetailHeader"
import { queryStringToObject } from "lib/queryString"
import { TransactionDetailType } from "../types"
import { useInvoiceModelQuery, usePeriodQuery } from "data/finance/contractModel/queries"
import { useLocation, useParams } from "react-router-dom"

interface OwnProps {
  periodGuid: string
  calendarGuid: string
  contractGuid: string
  transactionGuid: string
}

const TransactionDetailComponent: React.FC<OwnProps> = ({
  calendarGuid,
  periodGuid,
  contractGuid: contractModelGuid,
  transactionGuid
}) => {
  const location = useLocation()

  const [tab, setTab] = useState<TransactionDetailType>(() => {
    const { tab: tabType } = queryStringToObject(location.search)
    return tabType === TransactionDetailType.EXTRA ? TransactionDetailType.EXTRA : TransactionDetailType.VISIT
  })

  const { data: invoiceModel } = useInvoiceModelQuery(contractModelGuid)

  const { data: period } = usePeriodQuery({
    modelType: ContractModelType.INVOICE,
    contractGuid: contractModelGuid,
    calendarGuid,
    periodGuid
  })

  const { data: transaction, refetch: refetchTransaction } = useInvoiceProcessingTransactionQuery({
    periodGuid,
    transactionGuid
  })

  const { data: visits, refetch: refetchVisits } = useInvoiceProcessingTransactionVisitListQuery({
    periodGuid,
    transactionGuid
  })
  const { data: charges, refetch: refetchCharges } = useInvoiceProcessingTransactionChargeListQuery({
    periodGuid,
    transactionGuid
  })

  const onVisitsCredited = useCallback(() => {
    refetchVisits()
  }, [refetchVisits])

  const onExtrasCredited = useCallback((): void => {
    refetchCharges()
  }, [refetchCharges])

  if (!period || !invoiceModel || !transaction) return null

  return (
    <ContentContainer data-cy={testingId.invoiceProcessingTransactionDetail.screen}>
      <TransactionDetailHeader
        {...{
          calendarGuid,
          periodGuid,
          contractGuid: contractModelGuid,
          contractModel: invoiceModel,
          period,
          transaction
        }}
      />
      <TransactionDetailTabBar {...{ setTab, tab, transaction, visits, charges }} />
      <TabPanel value={tab} index={TransactionDetailType.VISIT}>
        <TransactionDetailItemTab
          {...{
            periodGuid,
            transaction,
            onVisitsCredited,
            contractModelGuid,
            visits
          }}
        />
      </TabPanel>
      <TabPanel value={tab} index={TransactionDetailType.EXTRA}>
        <TransactionDetailChargeTab
          {...{
            transaction,
            refetchTransaction,
            period,
            periodGuid,
            onExtrasCredited,
            charges
          }}
        />
      </TabPanel>
    </ContentContainer>
  )
}

const TransactionDetail: React.FC = () => {
  const { calendarGuid, periodGuid, contractGuid, transactionGuid } = useParams()

  if (!calendarGuid || !periodGuid || !contractGuid || !transactionGuid) return null
  return <TransactionDetailComponent {...{ calendarGuid, periodGuid, contractGuid, transactionGuid }} />
}

export default memo(TransactionDetail)
