import React from "react"
import List from "@mui/material/List"
import Box from "@mui/material/Box"
import FolderIcon from "@mui/icons-material/Folder"
import { ContentContainer, DetailSidebar, Header, NavItem } from "components"
import { WithPermission } from "components/Auth"
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext"
import ShiftCategoryDetail from "./ShiftCategoryDetail"
import { ShiftCategoryContractsList } from "./ShiftCategoryContractsList"
import { ShiftCategoryContractDetail } from "./ShiftCategoryContractDetail"
import testingId from "constants/testingId"
import { useShiftCategoryDetailQuery } from "data/core/shiftCategory/queries"
import { Route, useParams } from "react-router-dom"
import SentryRoutes from "components/Routes"

const ShiftCategoryMain: React.FC = () => {
  const { id = "" } = useParams()
  const { data: shiftCategory } = useShiftCategoryDetailQuery(id)

  if (!shiftCategory) return null
  const careplannerUrl = `${shiftCategory.care_planner_url}/roster/${shiftCategory.care_planner_internal_id}`

  return (
    <>
      <DetailSidebar>
        <Box m={10} mx={0}></Box>
        <List component="nav">
          <NavItem
            to={`/shift-categories/${shiftCategory.guid}/detail`}
            icon={<FolderIcon color="primary" />}
            label="Shift Category Details"
          />
          <WithPermission permissions={["Finance.UserContract:Edit"]}>
            <NavItem
              to={`/shift-categories/${shiftCategory.guid}/contracts`}
              icon={<FolderIcon color="primary" />}
              label="Contracts"
            />
          </WithPermission>
        </List>
        <List component="nav">
          <NavItem
            to={careplannerUrl}
            icon={<QueuePlayNextIcon color="primary" />}
            label="CarePlanner profile"
            external
          />
        </List>
      </DetailSidebar>
      <ContentContainer data-cy={testingId.shifts.category.screen}>
        <Header
          data-cy={testingId.shifts.screenTitle}
          title={shiftCategory ? <>{`Shift Category - ${shiftCategory.title}`}</> : "Not Found"}
        />
        <SentryRoutes>
          <Route path="/detail" element={<ShiftCategoryDetail shiftCategory={shiftCategory} mode="update" />} />
          <Route path="/contracts" element={<ShiftCategoryContractsList shiftCategory={shiftCategory} />} />
          <Route
            path="/contracts/new"
            element={<ShiftCategoryContractDetail shiftCategory={shiftCategory} mode="create" />}
          />
          <Route
            path="/contracts/:contractId"
            element={<ShiftCategoryContractDetail shiftCategory={shiftCategory} mode="update" />}
          />
        </SentryRoutes>
      </ContentContainer>
    </>
  )
}

export default ShiftCategoryMain
