import { Grid } from "@mui/material"
import { ClearFilterButton } from "components/ClearFilterButton"
import { FormSpyCustom } from "components/Form"
import { StatusSelectField } from "components/Form/StatusSelectField"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Form } from "react-final-form"
import { CalendarFilter } from "../../types"

interface OwnProps {
  filter: CalendarFilter
  setFilter: Dispatch<SetStateAction<CalendarFilter>>
}

export const CalendarListFilter: React.FC<OwnProps> = memo(({ filter, setFilter }) => {
  useFilterLocationChange({ filter })

  const onSubmit = (values: CalendarFilter) => {
    setFilter(values)
  }

  const clearFilter = () => {
    setFilter(() => ({}))
  }

  return (
    <Form {...{ onSubmit, initialValues: filter }}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpyCustom {...{ handleSubmit }} />
          <Grid container alignItems="center" spacing={1}>
            <Grid item md={3}>
              <StatusSelectField multiselect />
            </Grid>
            <Grid item md={6}>
              <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  )
})

CalendarListFilter.displayName = "CalendarListFilter"
