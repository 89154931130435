import { Box } from "@mui/material"
import { DatePicker } from "components/Form"
import { Permission } from "constants/permission"
import { AccrualStats, useCalculateAccrualMutation } from "data/finance/development/mutations"
import Auth from "lib/Auth"
import validators from "lib/validators"
import { DateTime } from "luxon"
import React, { memo, useCallback } from "react"
import { Field, Form } from "react-final-form"
import { deepObjectEqual } from "lib/utils"
import { CarerAccrualDetailStatsModal } from "pages/Carers/CarerAccrual/CarerAccrualDetailStatsModal"
import { CarerAccrualDetailStatsInfo } from "./CarerAccrualDetailStatsInfo"
import { ApiContractAccrualSummary } from "data/finance/carer/types"
import { DevButton } from "components/DevButton"

interface OwnProps {
  carerGuid: string
  accrualSummary: ApiContractAccrualSummary
}

interface FormValues {
  start: DateTime
}

export const CarerAccrualDetailStats: React.FC<OwnProps> = memo(({ carerGuid, accrualSummary }) => {
  CarerAccrualDetailStats.displayName = "CarerAccrualDetailStats"

  const hasRecalcPermission = Auth.hasPermission([Permission.FINANCE_RECALCULATE_EDIT])

  const [modal, setShow] = React.useState<React.ReactNode>(null)
  const destroyModal = () => setShow(false)
  const onOk = (data: AccrualStats) => {
    setShow(<CarerAccrualDetailStatsModal onCancel={destroyModal} accrualStats={data} />)
  }

  const { mutate: recalculate } = useCalculateAccrualMutation({ onOk })

  const onSubmit = useCallback(
    (values: FormValues) => {
      recalculate({
        carer_guid: carerGuid,
        fromDate: values.start
      })
    },
    [recalculate, carerGuid]
  )

  const initialValues = { start: accrualSummary.policy?.start }

  return (
    <>
      <Box display="flex">
        <CarerAccrualDetailStatsInfo {...{ accrualSummary }} />
        {hasRecalcPermission && (
          <Form
            {...{
              onSubmit,
              initialValues,
              initialValuesEqual: deepObjectEqual
            }}
          >
            {({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" justifyContent="flex-end">
                  <Box display="flex" m={0} mx={1}>
                    <Field<DateTime>
                      {...{
                        name: "start",
                        label: "Recalc start",
                        component: DatePicker,
                        withTime: false,
                        validate: validators.validDate
                      }}
                    />
                  </Box>
                  <Box display="flex" m={2} mx={0}>
                    <DevButton type="submit" disabled={!values.start}>
                      Recalc Stats
                    </DevButton>
                  </Box>
                </Box>
              </form>
            )}
          </Form>
        )}
      </Box>
      {modal}
    </>
  )
})
