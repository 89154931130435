import React, { memo } from "react"
import StatusDot, { DotColorVariant, DotSizeVariant } from "pages/SyncStatus/components/StatusDot"
import { isNil, takeLast } from "ramda"
import Tooltip from "@mui/material/Tooltip"
import Box from "@mui/material/Box"
import { getTimeDurationString, formatDate, DateTimeFormat } from "lib/datetime"
import { DateTime } from "luxon"
import testingId from "constants/testingId"
import { ApiSync } from "data/core/branch/types"

interface StatusDotContainerProps {
  syncs: ApiSync[] | null
}

const getColorVariant = (sync: ApiSync): DotColorVariant => {
  const noEndDate = isNil(sync.ended_at)
  const isFailed = sync.is_failed
  if (noEndDate) {
    return DotColorVariant.WHITE
  }
  if (isFailed) {
    return DotColorVariant.RED
  }
  return DotColorVariant.GREEN
}

const getSizeVariant = (syncIndex: number, totalSyncsLength: number): DotSizeVariant => {
  if (totalSyncsLength < 4) {
    return totalSyncsLength === syncIndex + 1 ? DotSizeVariant.LARGE : DotSizeVariant.MEDIUM
  }
  return syncIndex === 4 ? DotSizeVariant.LARGE : DotSizeVariant.MEDIUM
}

const getTooltipMessage = (sync: ApiSync): string => {
  if (isNil(sync.ended_at)) {
    return "In progress"
  }
  const now = DateTime.local()
  const duration = now.diff(sync.ended_at, ["days", "hours", "minutes"])
  if (sync.range_from && sync.range_to) {
    return `${sync.is_failed ? "Failed" : "Succeeded"} ${getTimeDurationString({
      duration
    })}. Retrieved visits & shifts within the range from: ${formatDate(
      sync.range_from,
      DateTimeFormat.DATE_TIME
    )} to: ${formatDate(sync.range_to, DateTimeFormat.DATE_TIME)}.`
  } else {
    return `${sync.is_failed ? "Failed" : "Succeeded"} ${getTimeDurationString({ duration })}. ID: ${sync.id}.`
  }
}

const StatusDotContainer: React.FC<StatusDotContainerProps> = ({ syncs }) => (
  <Box
    sx={{
      display: "inline-flex",
      alignItems: "center"
    }}
  >
    {!isNil(syncs)
      ? takeLast(5, syncs).map((sync: ApiSync, index: number) => (
          <Tooltip
            title={getTooltipMessage(sync)}
            key={sync.id}
            arrow
            data-cy={testingId.syncStatus.tooltip}
            slotProps={{
              tooltip: {
                sx: (theme) => ({
                  backgroundColor: theme.palette.warning.light,
                  color: theme.palette.text.primary
                })
              },
              arrow: {
                sx: (theme) => ({
                  color: theme.palette.warning.light
                })
              }
            }}
          >
            <Box>
              <StatusDot
                {...{
                  colorVariant: getColorVariant(sync),
                  sizeVariant: getSizeVariant(index, syncs.length),
                  pulsating: sync.is_failed
                }}
              />
            </Box>
          </Tooltip>
        ))
      : "N/A"}
  </Box>
)

export default memo(StatusDotContainer)
