import React from "react"
import { ApiUserClient, UserType } from "models"
import { Button, FormSectionHeader } from "components/Form"
import { UserList, Link, EmptyData, ContentContainer } from "components"
import Grid from "@mui/material/Grid"
import { useClientRelativeListQuery } from "data/core/client/queries"
import { ClientHeader } from "./components/ClientHeader"
import { getOptionsFromSchema } from "lib/schema"
import { useCoreSchemasStore } from "stores"

interface OwnProps {
  client: ApiUserClient
}

const ClientRelativeList: React.FC<OwnProps> = ({ client }) => {
  const { ApiUserRelative } = useCoreSchemasStore((state) => state.schema.models)
  const statusOptions = getOptionsFromSchema(ApiUserRelative, "status")

  const { data: relatives } = useClientRelativeListQuery({ guid: client.guid })

  return (
    <ContentContainer>
      <ClientHeader client={client} />
      <Grid container spacing={3}>
        <Grid item md={9}></Grid>
        <Grid item md={3}>
          <Link to={`/clients/${client.guid}/relative/new/personal-details`}>
            <Button>Add new contact</Button>
          </Link>
        </Grid>
      </Grid>
      {relatives?.users.length ? (
        <>
          <FormSectionHeader title="Relative list" />
          <UserList
            baseUrl={`/clients/${client.guid}/relative`}
            items={relatives?.users}
            statusOptions={statusOptions}
            listUserType={UserType.Relative}
          />
        </>
      ) : (
        <EmptyData message="No contacts found, start by adding one." />
      )}
    </ContentContainer>
  )
}
export default ClientRelativeList
