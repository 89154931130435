import { ContainerSteps } from "components/Steps"
import { FormStage } from "constants/form"
import React, { memo } from "react"
import { FormRenderProps } from "react-final-form"

interface OwnProps {
  formStage: FormStage
  formProps: FormRenderProps<any>
}

export const ContractDetailFooter: React.FC<OwnProps> = memo(({ formStage, formProps }) => {
  ContractDetailFooter.displayName = "ContractDetailFooter"
  const { error, submitError, pristine } = formProps

  return (
    <ContainerSteps.StepsFooter
      cancelNavTo="../contracts"
      buttonName={formStage === FormStage.CREATE ? "Next" : "Save"}
      errorMessage={error || submitError || ""}
      pristine={pristine}
    />
  )
})
