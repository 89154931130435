import React from "react"
import Config from "lib/config"
import Auth from "lib/Auth"
import { ModalWrapper } from "./"
import { Button } from "components/Form"
import TextField from "@mui/material/TextField"
import { API_CORE_BUILD_NUMBER_KEY, API_FINANCE_BUILD_NUMBER_KEY } from "api/instance"
import { Typography, Box } from "@mui/material"
import { Permission } from "constants/permission"
import { DevButton } from "./DevButton"
import { useLocation } from "react-router-dom"
import useBranchData from "lib/hooks/branchInfo/useBranchData"
import useBranchName from "lib/hooks/branchInfo/useBranchName"

interface Props {
  onCancel: () => void
}

const FrontendDiagnostics = ({ onCancel }: Props) => {
  const isDeveloper = Auth.hasPermission([Permission.DEVELOPER_EDIT])

  const coreApiBuildNumber = window.sessionStorage.getItem(API_CORE_BUILD_NUMBER_KEY)
  const financeApiBuildNumber = window.sessionStorage.getItem(API_FINANCE_BUILD_NUMBER_KEY)
  const userInfo = Auth.getUserInfo()
  const accessToken = Auth.getAccessToken()
  const branchName = useBranchName()
  const { data: branchInfo } = useBranchData()
  const location = useLocation()

  const data = {
    URL: window.location.href,
    Environment: userInfo?.env,
    "Core API": Config.getEnvVariable("APP_API_URL"),
    "Core API build number": coreApiBuildNumber ?? "",
    "Finance API": Config.getEnvVariable("ViteAppApiFinanceUrl") || Config.getEnvVariable("APP_API_URL"),
    "Finance API build number": financeApiBuildNumber ?? "",
    "Build number": Config.getEnvVariable("APP_BUILD_VERSION") || "local-build",
    Branch: branchName,
    "Branch Guid": userInfo?.branchGuid,
    "Logged in user": userInfo?.username,
    "User type": userInfo?.userType,
    Permissions: "\n" + (userInfo?.permissions ?? []).join("\n").replaceAll(",", ".")
  }

  if (isDeveloper) {
    console.log("userInfo", userInfo)
    console.log("branchInfo", branchInfo)
    console.log("import.meta.env", import.meta.env)
    console.log("window.environmentVariables:", (window as any)["environmentVariables"])
    console.log("window", window)
    console.log("react router location:", location)
  }

  return (
    <ModalWrapper destroyModal={onCancel} width={650}>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Typography variant="h6" align="center">
          Diagnostics
        </Typography>
        {isDeveloper && (
          <DevButton
            fullWidth={false}
            onClick={() =>
              accessToken ? window.navigator.clipboard.writeText(accessToken) : alert("Access token unavailable")
            }
          >
            Copy JWT Token
          </DevButton>
        )}
      </Box>
      <TextField
        sx={{ width: "100%" }}
        fullWidth={true}
        multiline={true}
        variant="outlined"
        defaultValue={Object.entries(data)
          .map(([key, value]) => `- ${key}: ${value}`)
          .join("\n")}
      />
      {isDeveloper &&
        "Open console to view userInfo, branchInfo, window.environmentVariables, import.meta.env (vite version of process.env), window object and react router location."}

      <Box m={3} mx={0} display="flex" justifyContent="center" flexDirection="column">
        <Button fullWidth={false} variant="text" onClick={() => onCancel()}>
          Close
        </Button>
      </Box>
    </ModalWrapper>
  )
}

export default React.memo(FrontendDiagnostics)
