import { FinanceApi } from "api"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { ProcessingTransactionRequest } from "./types"
import { getProcessingUrl, queryKeys } from "./queries"
import { ContractModelType } from "constants/modelTypes"
import { useUIStore } from "stores"
import { InvoiceTransaction } from "../invoiceProcessing/types"

export const useProcessingTransactionPostMutation = ({
  periodGuid,
  modelType,
  onOk
}: {
  periodGuid: string
  modelType: ContractModelType
  onOk: Function
}): UseMutationResult<InvoiceTransaction, Error, ProcessingTransactionRequest> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  const isInvoiceModel = modelType === ContractModelType.INVOICE
  const entityType = isInvoiceModel ? "Client" : "Carer"

  return useMutation(
    async (values: ProcessingTransactionRequest): Promise<InvoiceTransaction> => {
      return FinanceApi.post(`/finance/${getProcessingUrl(modelType)}/${periodGuid}/transactions`, values)
    },
    {
      onSuccess: () => {
        showSuccessMessage(`${entityType} successfully added.`)
        onOk()
        queryCache.invalidateQueries(queryKeys.getProcessingAvailableUsersKey({ modelType, periodGuid }))
      }
    }
  )
}
