import React, { memo } from "react"
import { alpha } from "@mui/material/styles"
import Spinner from "./Spinner"
import { useIsRequestPending } from "./handlers/useIsRequestPending"
import testingId from "constants/testingId"
import { Box } from "@mui/material"

const RequestTableLoader: React.FC<{ isFiltering: boolean }> = ({ isFiltering }) => {
  const displayLoader = useIsRequestPending()

  if (!displayLoader && !isFiltering) return null

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        top: 0,
        height: "100%",
        position: "absolute",
        zIndex: 999,
        backgroundColor: alpha(theme.palette.background.default, 0.6),
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      })}
      data-cy={testingId.loader}
    >
      <Spinner />
    </Box>
  )
}
export default memo(RequestTableLoader)
