import React from "react"
import MaterialButton, { ButtonProps } from "@mui/material/Button"
import testingId from "constants/testingId"

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { type } = props
  return (
    <MaterialButton
      size="large"
      variant="contained"
      fullWidth
      data-cy={type === "submit" ? testingId.submitButton : testingId.button}
      {...props}
    >
      {props.children}
    </MaterialButton>
  )
}

export default Button
