import React from "react"
import { Grid } from "@mui/material"
import { getOptionsFromSchema } from "lib/schema"
import { Field } from "react-final-form"
import { Select, TextField } from "components/Form"
import validators from "lib/validators"
import { DateField } from "components/Form/DateField"
import { useCoreSchemasStore } from "stores"

interface OwnProps {
  formStatusValue?: number
}

const inactiveStatuses = [6, 20, 21, 22]

const ClientProfileSectionStatus = ({ formStatusValue }: OwnProps) => {
  const { ApiUserPatientCreate } = useCoreSchemasStore((state) => state.schema.models)

  const isInactive = formStatusValue && inactiveStatuses.includes(formStatusValue)

  const statusOptions = getOptionsFromSchema(ApiUserPatientCreate, "status")
  const leavingReasonOptions = getOptionsFromSchema(ApiUserPatientCreate, "hr.leaving_reason")

  return (
    <Grid container spacing={8}>
      <Grid item md={6}>
        <Field
          name="status"
          label="Status *"
          component={Select}
          options={statusOptions}
          validate={validators.required}
        />
        {isInactive ? (
          <>
            <Field
              name="hr.leaving_reason"
              label="Leaving reason *"
              component={Select}
              options={leavingReasonOptions}
              validate={validators.required}
            />
            <Field
              name="hr.leaving_notes"
              label="Leaving notes *"
              component={TextField}
              validate={validators.required}
            />
          </>
        ) : null}
      </Grid>
      <Grid item md={6}>
        <DateField name="hr.start_date" label="Start date" required />
        {isInactive ? <DateField name="hr.leaving_date" label="Leaving date *" validate={validators.required} /> : null}
      </Grid>
    </Grid>
  )
}

export default ClientProfileSectionStatus
