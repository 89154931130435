import { FinanceApi } from "api"
import { ContractModelType } from "constants/modelTypes"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { queryKeys } from "./queries"
import { uuidv4Regex } from "lib/utils"
import {
  getContractModelRuleGroupUrl,
  getContractModelServiceRequestUrl,
  getContractModelServiceUrl,
  getContractModelUrl
} from "./helpers"
import {
  ApiContractModelRate,
  CalendarPeriod,
  CalendarRequest,
  CalendarResponse,
  ContractModelAccrualPolicy,
  ContractModelEnhancedRate,
  ContractModelRate,
  ContractModelRequest,
  ContractModelSalary,
  ContractModelSalaryBand,
  ContractModelService,
  ContractModelServiceBand,
  ContractModelServicePostRequest,
  InvoiceModel,
  InvoiceModelRate,
  InvoiceModelRateRequest,
  InvoiceModelRuleGroup,
  InvoiceModelRuleGroupRequest,
  InvoiceModelRuleGroupRule,
  InvoiceModelRuleGroupRuleRequest,
  PaymentModel,
  PaymentModelRate,
  PaymentModelRateRequest
} from "./types"
import { PATH } from "constants/path"
import { omit } from "ramda"
import { ContractModelUpliftRequest } from "pages/Finance/ContractModels/types"
import { ApiError } from "stores/useUIStore/types"
import { useInvalidateSchema } from "data/schema/useInvalidateSchema"
import { useNavigate } from "react-router-dom"
import { useUIStore } from "stores"
import { MutationCallbacks } from "data/types"

interface InvoiceModelUpdateMutationParams {
  payload: InvoiceModel
}

interface ContractModelCalendarDeleteMutationParams {
  modelType: ContractModelType
  contractGuid: string
  calendarGuid: string
}

interface ContractModelCalendarPeriodMutationParams {
  modelType: ContractModelType
  contractGuid: string
  calendarGuid: string
  periods: CalendarPeriod[]
}

interface ContractModelCalendarCreateMutationParams {
  modelType: ContractModelType
  contractGuid: string
  calendar: CalendarRequest
}

interface ContractModelCalendarEditMutationParams extends ContractModelCalendarCreateMutationParams {
  calendarGuid: string
  onSuccess?: () => void
}

interface ContractServiceBandRatePatchMutationParams {
  basicRates: ContractModelRate[]
  enhancedRates: ContractModelEnhancedRate[]
}

// common
export const useModelRateUpliftMutation = ({
  modelType,
  onSuccess,
  onError
}: MutationCallbacks<void, ContractModelUpliftRequest[]> & { modelType: ContractModelType }) => {
  return useMutation({
    mutationFn: async (payload: ContractModelUpliftRequest[]): Promise<void> => {
      return FinanceApi.post(`/finance/${getContractModelUrl(modelType)}/uplift`, payload)
    },
    onSuccess,
    onError
  })
}

// clean after cypress
export const useModelDeleteMutation = (modelType: ContractModelType) => {
  return useMutation(async (guid: string): Promise<void> => {
    return FinanceApi.delete(`/finance/${getContractModelUrl(modelType)}/${guid}`)
  })
}

// invoice-models
export const useInvoiceModelUpdateMutation = (): UseMutationResult<
  InvoiceModel,
  Error,
  InvoiceModelUpdateMutationParams
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const invalidateSchema = useInvalidateSchema()
  const queryCache = useQueryClient()

  return useMutation(
    async ({ payload }: InvoiceModelUpdateMutationParams): Promise<InvoiceModel> => {
      const { guid } = payload
      return FinanceApi.put(`/finance/invoice-models/${guid}`, payload)
    },
    {
      onSuccess: (data) => {
        invalidateSchema(["InvoiceContract"])

        const { guid } = data
        queryCache.setQueryData(queryKeys.getInvoiceModelKey(guid), data)
        showSuccessMessage("Contract template updated.")
      }
    }
  )
}

export const useInvoiceModelCreateMutation = (): UseMutationResult<
  InvoiceModel,
  Error,
  InvoiceModelUpdateMutationParams
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const invalidateSchema = useInvalidateSchema()

  return useMutation(
    async ({ payload }: InvoiceModelUpdateMutationParams): Promise<InvoiceModel> => {
      return FinanceApi.post("/finance/invoice-models", payload)
    },
    {
      onSuccess: () => {
        invalidateSchema(["InvoiceContract"])
        showSuccessMessage("Contract template successfully created.")
      }
    }
  )
}

export const useInvoiceModelRateCreateMutation = (): UseMutationResult<
  InvoiceModelRate,
  Error,
  { modelGuid: string; payload: InvoiceModelRateRequest }
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()
  const navigate = useNavigate()

  return useMutation(
    async ({ modelGuid, payload }): Promise<InvoiceModelRate> => {
      return FinanceApi.post(`/finance/invoice-models/${modelGuid}/rates`, payload)
    },
    {
      onSuccess: (data, request) => {
        queryCache.setQueryData(
          queryKeys.getInvoiceModelRateQuery({ contractGuid: request.modelGuid, contractRateGuid: data.guid }),
          data
        )
        showSuccessMessage("Standing Charge successfully created.")
        navigate(`${PATH.FINANCE.INVOICE_MODELS}/${request.modelGuid}/standing-charges/${data.guid}`)
      }
    }
  )
}

export const useInvoiceModelRateUpdateMutation = (): UseMutationResult<
  InvoiceModelRate,
  Error,
  { modelGuid: string; rateGuid: string; payload: InvoiceModelRateRequest }
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ modelGuid, rateGuid, payload }): Promise<InvoiceModelRate> => {
      return FinanceApi.put(`/finance/invoice-models/${modelGuid}/rates/${rateGuid}`, payload)
    },
    {
      onSuccess: (data, request) => {
        queryCache.setQueryData(
          queryKeys.getInvoiceModelRateQuery({ contractGuid: request.modelGuid, contractRateGuid: request.rateGuid }),
          data
        )
        showSuccessMessage("Standing Charge successfully updated.")
      }
    }
  )
}

export const useInvoiceModelRateDeleteMutation = (): UseMutationResult<
  InvoiceModelRate,
  Error,
  { modelGuid: string; rateGuid: string }
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ modelGuid, rateGuid }): Promise<InvoiceModelRate> => {
      return FinanceApi.delete(`/finance/invoice-models/${modelGuid}/rates/${rateGuid}`)
    },
    {
      onSuccess: (_, request) => {
        queryCache.invalidateQueries(
          queryKeys.getInvoiceModelRateQuery({ contractGuid: request.modelGuid, contractRateGuid: request.rateGuid })
        )
        showSuccessMessage("Standing Charge successfully deleted.")
      }
    }
  )
}

export const useInvoiceModelRuleGroupCreateMutation = ({
  contractGuid,
  isPaymentRule,
  copyGuid,
  destroyModal
}: {
  contractGuid: string
  isPaymentRule: boolean
  copyGuid?: string
  destroyModal?: () => void
}): UseMutationResult<InvoiceModelRuleGroup, Error, InvoiceModelRuleGroupRequest> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  const suffix = copyGuid ? `?copy_guid=${copyGuid}` : ""
  return useMutation(
    async (payload: InvoiceModelRuleGroupRequest): Promise<InvoiceModelRuleGroup> =>
      FinanceApi.post(
        `/finance/invoice-models/${contractGuid}/${getContractModelRuleGroupUrl(isPaymentRule)}${suffix}`,
        payload
      ),
    {
      onSuccess: (response) => {
        destroyModal && destroyModal()
        showSuccessMessage(`Rule Group ${copyGuid ? "copied" : "created"} successfully.`)
        navigate(
          `${PATH.FINANCE.INVOICE_MODELS}/${contractGuid}/${getContractModelRuleGroupUrl(isPaymentRule)}/${
            response.guid
          }`
        )
      }
    }
  )
}

export const useInvoiceModelRuleGroupUpdateMutation = ({
  contractGuid,
  isPaymentRule,
  ruleGroupGuid
}: {
  contractGuid: string
  isPaymentRule: boolean
  ruleGroupGuid: string
}): UseMutationResult<InvoiceModelRuleGroup, Error, InvoiceModelRuleGroupRequest> => {
  const queryCache = useQueryClient()

  return useMutation(
    async (payload: InvoiceModelRuleGroupRequest): Promise<InvoiceModelRuleGroup> =>
      FinanceApi.put(
        `/finance/invoice-models/${contractGuid}/${getContractModelRuleGroupUrl(isPaymentRule)}/${ruleGroupGuid}`,
        payload
      ),
    {
      onSuccess: (response) => {
        queryCache.setQueryData(
          queryKeys.getInvoiceModelRuleGroupItemKey({ contractGuid, isPaymentRule, ruleGroupGuid }),
          response
        )
      }
    }
  )
}

export const useInvoiceModelRuleGroupRulePatchMutation = ({
  contractGuid,
  isPaymentRule,
  ruleGroupGuid
}: {
  contractGuid: string
  isPaymentRule: boolean
  ruleGroupGuid: string
}): UseMutationResult<InvoiceModelRuleGroupRule[], Error, InvoiceModelRuleGroupRuleRequest[]> => {
  const queryCache = useQueryClient()

  return useMutation(
    async (payload: InvoiceModelRuleGroupRuleRequest[]): Promise<InvoiceModelRuleGroupRule[]> =>
      FinanceApi.patch(
        `/finance/invoice-models/${contractGuid}/${getContractModelRuleGroupUrl(isPaymentRule)}/${ruleGroupGuid}/rules`,
        { rules: payload }
      ),
    {
      onSuccess: (result) => {
        queryCache.setQueryData(
          queryKeys.getInvoiceModelRuleGroupItemRulesKey({ contractGuid, isPaymentRule, ruleGroupGuid }),
          result
        )
      }
    }
  )
}

// payment models
export const usePaymentModelCreateMutation = (): UseMutationResult<PaymentModel, Error, ContractModelRequest> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const invalidateSchema = useInvalidateSchema()

  return useMutation(
    async (payload: ContractModelRequest): Promise<PaymentModel> => {
      return FinanceApi.post("/finance/payment-models", payload)
    },
    {
      onSuccess: () => {
        invalidateSchema(["PaymentContract"])
        showSuccessMessage("Contract template successfully created.")
      }
    }
  )
}

export const usePaymentModelUpdateMutation = (): UseMutationResult<PaymentModel, Error, ContractModelRequest> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()
  const invalidateSchema = useInvalidateSchema()

  return useMutation(
    async (payload: ContractModelRequest): Promise<PaymentModel> => {
      const { guid } = payload
      return FinanceApi.put(`/finance/payment-models/${guid}`, payload)
    },
    {
      onSuccess: async (data) => {
        invalidateSchema(["PaymentContract"])
        const { guid } = data
        queryCache.setQueryData(queryKeys.getPaymentModelKey(guid), data)
        showSuccessMessage("Contract template updated.")
      }
    }
  )
}

export const usePaymentModelRateCreateMutation = (): UseMutationResult<
  PaymentModelRate,
  Error,
  { modelGuid: string; payload: PaymentModelRateRequest }
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()
  const navigate = useNavigate()

  return useMutation(
    async ({ modelGuid, payload }): Promise<PaymentModelRate> => {
      return FinanceApi.post(`/finance/payment-models/${modelGuid}/rates`, payload)
    },
    {
      onSuccess: (data, request) => {
        queryCache.setQueryData(
          queryKeys.getPaymentModelRateQuery({ contractGuid: request.modelGuid, contractRateGuid: data.guid }),
          data
        )
        showSuccessMessage("Standing Charge successfully created.")
        navigate(`${PATH.FINANCE.PAYMENT_MODELS}/${request.modelGuid}/base-rates/${data.guid}`)
      }
    }
  )
}

export const usePaymentModelRateUpdateMutation = (): UseMutationResult<
  PaymentModelRate,
  Error,
  { modelGuid: string; rateGuid: string; payload: PaymentModelRateRequest }
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ modelGuid, rateGuid, payload }): Promise<PaymentModelRate> => {
      return FinanceApi.put(`/finance/payment-models/${modelGuid}/rates/${rateGuid}`, payload)
    },
    {
      onSuccess: (data, request) => {
        queryCache.setQueryData(
          queryKeys.getPaymentModelRateQuery({ contractGuid: request.modelGuid, contractRateGuid: request.rateGuid }),
          data
        )
        showSuccessMessage("Standing Charge successfully updated.")
      }
    }
  )
}

export const usePaymentModelRateDeleteMutation = (): UseMutationResult<
  PaymentModelRate,
  Error,
  { modelGuid: string; rateGuid: string }
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ modelGuid, rateGuid }): Promise<PaymentModelRate> => {
      return FinanceApi.delete(`/finance/payment-models/${modelGuid}/rates/${rateGuid}`)
    },
    {
      onSuccess: (_, request) => {
        queryCache.invalidateQueries(
          queryKeys.getPaymentModelRateQuery({ contractGuid: request.modelGuid, contractRateGuid: request.rateGuid })
        )
        showSuccessMessage("Standing Charge successfully deleted.")
      }
    }
  )
}

// invoice-models && payment-models common
export const useContractModelCalendarCreateMutation = (): UseMutationResult<
  CalendarResponse,
  Error,
  ContractModelCalendarCreateMutationParams
> => {
  return useMutation(
    async ({
      modelType,
      contractGuid,
      calendar
    }: ContractModelCalendarCreateMutationParams): Promise<CalendarResponse> => {
      return FinanceApi.post(`/finance/${getContractModelUrl(modelType)}/${contractGuid}/calendars`, calendar)
    }
  )
}

export const useContractModelCalendarUpdateMutation = (): UseMutationResult<
  CalendarResponse,
  Error,
  ContractModelCalendarEditMutationParams
> => {
  return useMutation(
    async ({
      modelType,
      contractGuid,
      calendarGuid,
      calendar
    }: ContractModelCalendarEditMutationParams): Promise<CalendarResponse> => {
      return FinanceApi.put(
        `/finance/${getContractModelUrl(modelType)}/${contractGuid}/calendars/${calendarGuid}`,
        calendar
      )
    }
  )
}

export const useContractModelCalendarReplaceMutation = (): UseMutationResult<
  CalendarResponse,
  Error,
  ContractModelCalendarEditMutationParams
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  return useMutation(
    async ({
      modelType,
      contractGuid,
      calendarGuid,
      calendar
    }: ContractModelCalendarEditMutationParams): Promise<CalendarResponse> => {
      return FinanceApi.patch(
        `/finance/${getContractModelUrl(modelType)}/${contractGuid}/calendars/${calendarGuid}`,
        calendar
      )
    },
    {
      onSuccess: (response, { modelType, contractGuid, onSuccess }) => {
        navigate(
          `/finance/contract-models/${getContractModelUrl(modelType)}/${contractGuid}/calendars/${response.guid}`
        )
        showSuccessMessage("Calendar for contract successfully created.")
        onSuccess && onSuccess()
      }
    }
  )
}

export const useContractModelCalendarPeriodsPatchMutation = (): UseMutationResult<
  CalendarPeriod,
  Error,
  ContractModelCalendarPeriodMutationParams
> => {
  return useMutation(
    async ({
      modelType,
      contractGuid,
      calendarGuid,
      periods
    }: ContractModelCalendarPeriodMutationParams): Promise<CalendarPeriod> => {
      return FinanceApi.patch(
        `/finance/${getContractModelUrl(modelType)}/${contractGuid}/calendars/${calendarGuid}/periods`,
        { periods }
      )
    }
  )
}

export const useContractModelCalendarDeleteMutation = (): UseMutationResult<
  void,
  Error,
  ContractModelCalendarDeleteMutationParams
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)

  return useMutation(
    async ({ modelType, contractGuid, calendarGuid }: ContractModelCalendarDeleteMutationParams): Promise<void> => {
      return FinanceApi.delete(`/finance/${getContractModelUrl(modelType)}/${contractGuid}/calendars/${calendarGuid}`)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Calendar successfully deleted.")
      }
    }
  )
}

export const useContractModelServiceCreateMutation = ({
  modelType,
  isInvoicePaymentService,
  contractModelGuid
}: {
  modelType: ContractModelType
  isInvoicePaymentService?: boolean
  contractModelGuid: string
}): UseMutationResult<
  ContractModelService,
  ApiError,
  { payload: ContractModelServicePostRequest; serviceGuid?: string }
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  return useMutation(
    async ({ payload, serviceGuid }) => {
      const query = serviceGuid ? `?copy_guid=${serviceGuid}` : ""

      return FinanceApi.post(
        `/finance/${getContractModelUrl(modelType)}/${contractModelGuid}/${getContractModelServiceRequestUrl(
          isInvoicePaymentService
        )}${query}`,
        payload
      )
    },
    {
      onSuccess: (data) => {
        navigate(
          `/finance/contract-models/${getContractModelUrl(modelType)}/${contractModelGuid}/${getContractModelServiceUrl(
            isInvoicePaymentService || modelType === ContractModelType.PAYMENT
          )}/${data.guid}`
        )
        showSuccessMessage("Contract model service successfully created.")
      }
    }
  )
}

export const useContractModelServicePutMutation = ({
  modelType,
  isInvoicePaymentService,
  contractModelGuid
}: {
  modelType: ContractModelType
  isInvoicePaymentService?: boolean
  contractModelGuid: string
}): UseMutationResult<
  ContractModelService,
  Error,
  { serviceGuid: string; service: ContractModelServicePostRequest }
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ serviceGuid, service }): Promise<ContractModelService> => {
      const servicePayload = omit(["guid"], service) as ContractModelServicePostRequest
      return FinanceApi.put(
        `/finance/${getContractModelUrl(modelType)}/${contractModelGuid}/${getContractModelServiceRequestUrl(
          isInvoicePaymentService
        )}/${serviceGuid}`,
        servicePayload
      )
    },
    {
      onSuccess: (data, { serviceGuid }) => {
        queryCache.setQueryData(
          queryKeys.getContractServiceDetailKey({ modelType, contractGuid: contractModelGuid, serviceGuid }),
          data
        )
        showSuccessMessage("Contract model service successfully updated.")
      }
    }
  )
}

export const useContractModelServiceBandPatchMutation = ({
  modelType,
  contractModelGuid
}: {
  modelType: ContractModelType
  contractModelGuid: string
}): UseMutationResult<
  ContractModelServiceBand[],
  Error,
  { bands: ContractModelServiceBand[]; serviceGuid: string }
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ bands, serviceGuid }): Promise<ContractModelServiceBand[]> => {
      return FinanceApi.patch(
        `/finance/${getContractModelUrl(modelType)}/${contractModelGuid}/services/${serviceGuid}/bands`,
        { bands }
      )
    },
    {
      onSuccess: (data, { serviceGuid }) => {
        queryCache.setQueryData(
          queryKeys.getContractServiceBandListKey({ modelType, contractGuid: contractModelGuid, serviceGuid }),
          data
        )
        showSuccessMessage("Service bands successfully updated.")
      }
    }
  )
}

export const useContractServiceBandRatePatchMutation = ({
  modelType,
  contractGuid,
  serviceGuid,
  bandGuid
}: {
  modelType: ContractModelType
  contractGuid: string
  serviceGuid: string
  bandGuid: string
}): UseMutationResult<ApiContractModelRate[], Error, ContractServiceBandRatePatchMutationParams> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({
      basicRates,
      enhancedRates
    }: ContractServiceBandRatePatchMutationParams): Promise<ApiContractModelRate[]> => {
      const processedRates = basicRates
        .filter((rate) => !rate.delete)
        .map(({ ...rate }) => {
          /**
           * If you're questioning your sanity here, good news, you still got it
           *
           * Tom Day explains this bit by:
           * So in order for the times to contain no gaps, and also encompass a "whole day"
           * the values must span from TimeSpan 0.00:00:00 -> 1.00:00:00
           */
          if (rate.end === "00:00" || rate.end === "00:00:00.000") {
            rate.end = "1.00:00:00"
          }
          if (rate.start === "00:00") {
            rate.start = "0.00:00:00"
          }

          /**
           * Holidays are a special case as they have user assigned tiers in them
           * @todo uuidv4 standalone library supports isGuid validation rather than using regex
           */
          if (rate.day_type_alias.match(uuidv4Regex)) {
            rate.holiday_tier_guid = rate.day_type_alias
            rate.day_type_alias = "HOL"
          }

          const enhancements = enhancedRates
            .filter((er) => !er.delete && er.standard_rate_guid === rate.guid)
            .map(({ ...er }) => {
              //@ts-ignore
              delete er.standard_rate_guid

              if (er.day_type_alias.match(uuidv4Regex)) {
                er.holiday_tier_guid = er.day_type_alias
                er.day_type_alias = "HOL"
              }
              return er
            })

          if (rate.guid?.indexOf("new-item-") === 0) {
            //@ts-ignore
            delete rate.guid
          }

          return enhancements.length ? { ...rate, enhancements } : rate
        })

      return FinanceApi.patch(
        `/finance/${getContractModelUrl(modelType)}/${contractGuid}/services/${serviceGuid}/bands/${bandGuid}/rates`,
        { rates: processedRates }
      )
    },
    {
      onSuccess: async (data) => {
        queryCache.setQueryData(
          queryKeys.getContractServiceBandRateListKey({ modelType, contractGuid, serviceGuid, bandGuid }),
          data
        )
        showSuccessMessage("Rates successfully saved.")
      }
    }
  )
}

export const useContractModelAccrualPolicyCreateMutation = (): UseMutationResult<
  ContractModelAccrualPolicy,
  Error,
  { contractModelGuid: string; payload: ContractModelAccrualPolicy }
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()
  const navigate = useNavigate()

  return useMutation(
    async ({ payload, contractModelGuid }) => {
      return FinanceApi.post(`/finance/payment-models/${contractModelGuid}/accrual-policies`, payload)
    },
    {
      onSuccess: (data, { contractModelGuid }) => {
        queryCache.setQueryData(
          queryKeys.getContractAccrualPolicyDetailKey({ contractGuid: contractModelGuid, policyGuid: data.guid }),
          data
        )

        showSuccessMessage("Accrual policy successfully created.")
        navigate(`${PATH.FINANCE.PAYMENT_MODELS}/${contractModelGuid}/accrual-policies/${data.guid}`)
      }
    }
  )
}

export const useContractModelAccrualPolicyUpdateMutation = (): UseMutationResult<
  ContractModelAccrualPolicy,
  Error,
  { payload: ContractModelAccrualPolicy; contractModelGuid: string }
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ payload, contractModelGuid }) => {
      return FinanceApi.put(`/finance/payment-models/${contractModelGuid}/accrual-policies/${payload.guid}`, payload)
    },
    {
      onSuccess: (data, { contractModelGuid }) => {
        queryCache.setQueryData(
          queryKeys.getContractAccrualPolicyDetailKey({ contractGuid: contractModelGuid, policyGuid: data.guid }),
          data
        )
        showSuccessMessage("Accrual policy successfully updated.")
      }
    }
  )
}

export const useContractModelSalaryCreateMutation = (): UseMutationResult<
  ContractModelSalary,
  Error,
  { contractGuid: string; payload: ContractModelSalary }
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()
  const navigate = useNavigate()

  return useMutation(
    async ({ payload, contractGuid }) => {
      return FinanceApi.post(`/finance/payment-models/${contractGuid}/salaries`, payload)
    },
    {
      onSuccess: (data, { contractGuid }) => {
        queryCache.setQueryData(
          queryKeys.getContractSalaryDetailKey({ contractGuid, contractSalaryGuid: data.guid }),
          data
        )

        showSuccessMessage("Salary successfully successfully created.")
        navigate(`${PATH.FINANCE.PAYMENT_MODELS}/${contractGuid}/salary-bands/${data.guid}`, { replace: true })
      }
    }
  )
}

export const useContractModelSalaryUpdateMutation = (): UseMutationResult<
  ContractModelSalary,
  Error,
  { payload: ContractModelSalary; contractGuid: string }
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ payload, contractGuid }) => {
      return FinanceApi.put(`/finance/payment-models/${contractGuid}/salaries/${payload.guid}`, payload)
    },
    {
      onSuccess: (data, { contractGuid }) => {
        queryCache.setQueryData(
          queryKeys.getContractSalaryDetailKey({ contractGuid: contractGuid, contractSalaryGuid: data.guid }),
          data
        )
        showSuccessMessage("Salary successfully updated.")
      }
    }
  )
}

export const useContractModelSalaryDeleteMutation = (): UseMutationResult<
  ContractModelSalary,
  Error,
  { contractGuid: string; salaryGuid: string }
> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  return useMutation(
    async ({ contractGuid, salaryGuid }) => {
      return FinanceApi.delete(`/finance/payment-models/${contractGuid}/salaries/${salaryGuid}`)
    },
    {
      onSuccess: (_, { contractGuid }) => {
        navigate(`${PATH.FINANCE.PAYMENT_MODELS}/${contractGuid}/salary-bands`)
        showSuccessMessage("Salary successfully deleted.")
      }
    }
  )
}

export const useContractModelSalaryBandPatchMutation = ({
  contractGuid,
  salaryGuid
}: {
  contractGuid: string
  salaryGuid?: string
}): UseMutationResult<ContractModelSalaryBand[], Error, { bands: ContractModelSalaryBand[] }> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async ({ bands }): Promise<ContractModelSalaryBand[]> => {
      return FinanceApi.patch(`/finance/payment-models/${contractGuid}/salaries/${salaryGuid}/bands`, { bands })
    },
    {
      onSuccess: (data) => {
        queryCache.setQueryData(
          queryKeys.getContractSalaryBandListKey({ contractGuid, contractSalaryGuid: salaryGuid }),
          data
        )
        showSuccessMessage("Salary bands successfully updated.")
      }
    }
  )
}
