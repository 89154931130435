import { mapValues } from "lodash/fp"
import { DateTime } from "luxon"
import timezone from "lib/timezone"
import dateTimeUtils from "lib/datetime"
import { is, isEmpty } from "ramda"
import { ApiError } from "stores/useUIStore/types"

export const getErrorMessage = (apiError?: ApiError) => {
  const requestError = apiError?.data?.exception?.message

  if (requestError) {
    return requestError
  }

  if (!apiError?.response?.data) {
    return undefined
  }

  const { title, detail, extensions, exception, errors, message } = apiError.response.data

  let err = []

  if (extensions?.error?.message) {
    err.push(extensions.error.message)
  } else if (exception?.message) {
    err.push(exception.message)
  } else if (title) {
    err.push(title)
  } else if (message) {
    err.push(message)
  }

  if (detail) {
    err.push(detail)
  }

  if (errors && is(Object, errors) && !isEmpty(errors)) {
    err = []
    Object.values(errors).forEach((error) => {
      if (typeof error === "string") {
        err.push(error)
      }
      if (is(Array, error)) {
        error.forEach((errorMessage) => {
          err.push(errorMessage)
        })
      }
    })
  }

  if (err.length) {
    return err.join(" | ")
  }

  return undefined
}

const DATE_TIME_WITH_SECONDS_ISO_8601_REGEX =
  /^(?:[1-9]\d{3}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00)-02-29)T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?::|.\d{1,3})?(?:Z|[+-][01]\d:[0-5]\d)$/
const MAX_DATE_TIME_WITH_SECONDS_ISO_8601_STRING_LENGTH = 30

const matchesFullISO8601Format = (obj: any) => {
  return (
    typeof obj === "string" &&
    obj.length <= MAX_DATE_TIME_WITH_SECONDS_ISO_8601_STRING_LENGTH &&
    DATE_TIME_WITH_SECONDS_ISO_8601_REGEX.test(obj)
  )
}

export const convertDatesFromUtcToBranchTimezone = (obj: any): any => {
  if (matchesFullISO8601Format(obj)) {
    return DateTime.fromISO(obj, { zone: "utc" }).setZone(timezone.get())
  }

  if (Array.isArray(obj)) {
    return obj.map(convertDatesFromUtcToBranchTimezone)
  }

  if (!!obj && typeof obj === "object") {
    return mapValues(convertDatesFromUtcToBranchTimezone, obj)
  }

  return obj
}

export const convertDatesFromBranchTimezoneToUtc = (obj: any): any => {
  if (obj instanceof DateTime || DateTime.isDateTime(obj)) {
    return dateTimeUtils.formatToUtcISO(obj)
  }

  if (matchesFullISO8601Format(obj)) {
    return dateTimeUtils.formatToUtcISO(DateTime.fromISO(obj))
  }

  if (Array.isArray(obj)) {
    return obj.map(convertDatesFromBranchTimezoneToUtc)
  }

  if (!!obj && typeof obj === "object") {
    return mapValues(convertDatesFromBranchTimezoneToUtc, obj)
  }

  return obj
}
