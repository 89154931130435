import { DownloadMode } from "data/user-documents/types"
import * as _ from "lodash"
import { DateTime } from "luxon"

export const uuidv4Regex = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

export function countFilters<T>(fields: { name: string }[], values: T): number {
  return fields.reduce((res: number, { name }) => {
    const value = _.get(values, name)
    if (value) {
      return res + 1
    }
    return res
  }, 0)
}

export const downloadFileSimple = ({
  href,
  fileName,
  mode = "save"
}: {
  href: string
  fileName: string
  mode?: DownloadMode
}): void => {
  const link = document.createElement("a")
  link.setAttribute("href", href)

  if (mode === "save&open") {
    link.setAttribute("target", "_blank")
    link.setAttribute("download", fileName)
  } else if (mode === "open") {
    link.setAttribute("target", "_blank")
  } else {
    link.setAttribute("download", fileName)
  }

  document.body.appendChild(link)
  link.click()
  link?.parentNode?.removeChild(link)
}

export function downloadFile(fileName: string, mimeType: string, response: any, mode: DownloadMode): void {
  const url = (window as any).URL.createObjectURL(new Blob([response], { type: mimeType }))
  downloadFileSimple({ href: url, fileName, mode })
}

export const getDisplayStyle = (
  display: boolean
): {
  position: string
  left: string
} => ({
  position: display ? "static" : "absolute",
  left: display ? "auto" : "-9999999px"
})

export const formatDuration = (start: DateTime, end: DateTime): string => {
  const dWeeks = Math.floor(end.diff(start, "weeks").weeks)
  const dDays = Math.floor(end.diff(start, "days").days)
  const dHours = Math.floor(end.diff(start, "hours").hours)
  const dMinutes = Math.floor(end.diff(start, "minutes").minutes)

  const weeks = dWeeks
  const days = dDays - dWeeks * 7
  const hours = dHours - dDays * 24
  const minutes = dMinutes - dHours * 60

  const result = []
  if (weeks > 1) result.push(`${weeks} weeks`)
  else if (weeks > 0) result.push(`${weeks} week`)

  if (days > 1) result.push(`${days} days`)
  else if (days > 0) result.push(`${days} day`)

  if (hours > 1) result.push(`${hours} hours`)
  else if (hours > 0) result.push(`${hours} hour`)

  if (minutes > 1) result.push(`${minutes} minutes`)
  else if (minutes > 0) result.push(`${minutes} minute`)

  return result.join(", ")
}

export const deepObjectEqual = (a: Record<string, any> | undefined, b: Record<string, any> | undefined): boolean => {
  return JSON.stringify(a) === JSON.stringify(b)
}

export const moneyFormat = new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" })

/**
 * Check if there is duplicated values on the array
 */
export const hasDuplicates = <T>(array: Array<T>) => {
  return array.filter((element: T, index: number, array: Array<T>) => array.indexOf(element) !== index).length > 0
}

/**
 * Slice the array according defined page and pageSize
 */
export const paginateArray = <T>(array: Array<T>, page: number, pageSize: number) => {
  return array.slice(page * pageSize, page * pageSize + pageSize)
}
