import { QueryKey, useQuery, UseQueryResult } from "react-query"
import API from "api"
import { UserType } from "models"
import { objectToQueryString, queryStringToObject } from "lib/queryString"
import { ListFilesResponse } from "./types"

export enum QueryKeyParts {
  CARER_DOCUMENTS = "carer-documents",
  CLIENT_DOCUMENTS = "client-documents"
}

export const queryKeys = {
  getDocumentsKey: (userType: UserType, userId: string, queryString?: string): QueryKey => [
    userType,
    { userId, queryString }
  ]
}

export const useGetUserDocuments = ({
  userId,
  enabled = true,
  queryString,
  userType
}: {
  userId: string
  enabled?: boolean
  queryString?: string
  userType: UserType
}): UseQueryResult<ListFilesResponse, Error> => {
  const queryObject = queryStringToObject(queryString)
  const { page, page_size, ...rest } = queryObject

  const mutatedQuery = objectToQueryString({
    page: page || 1,
    page_size: page_size || 25,
    ...rest
  })

  return useQuery({
    queryKey: queryKeys.getDocumentsKey(userType, userId, queryString),
    enabled,
    queryFn: () => API.listDocuments(userType, { user: userId, query: mutatedQuery })
  })
}
