import React, { useCallback, useMemo, useState } from "react"
import { ContentContainer, EmptyData, Header, UserList } from "components"
import testingId from "constants/testingId"
import { Typography, Divider } from "@mui/material"
import { useCarerListQuery } from "data/core/carer/queries"
import { objectToQueryString, queryStringToObject } from "lib/queryString"
import { usePaymentModelSummariesQuery } from "data/finance/contractModel/queries"
import { omit } from "ramda"
import { CarerListFilter } from "./components/CarerListFilter"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { useCarersOnboardingStatusesQuery } from "data/mobile/queries"
import { paginateArray } from "lib/utils"
import { CarerListFilterSearchValues, filterCarerList } from "./helpers/filterCarerList"
import { getOptionsFromSchema } from "lib/schema"
import { useLocation, useSearchParams } from "react-router-dom"
import { useCoreSchemasStore } from "stores"
import { UserType } from "models"

const CarerList = () => {
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const { ApiUserCarer: ApiUserCarerSchema } = useCoreSchemasStore((state) => state.schema.models)
  const statusOptions = getOptionsFromSchema(ApiUserCarerSchema, "status")

  const filter: CarerListFilterSearchValues = useMemo(
    () => ({
      ...queryStringToObject(location?.search),
      contract_model: JSON.parse(decodeURIComponent(searchParams.get("contract_model") ?? "[]"))
    }),
    [location?.search, searchParams]
  )

  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)

  const apiSearch = omit(["contract_model", "reference"], filter)

  const { data: carerList, isRefetching } = useCarerListQuery({ searchString: `?${objectToQueryString(apiSearch)}` })

  const carersPerPage = useMemo(
    () => paginateArray(carerList?.users ?? [], page, pageSize),
    [carerList?.users, page, pageSize]
  )
  const { data: carersOnboardingStatuses, isLoading: isLoadingOnboardingStatus } = useCarersOnboardingStatusesQuery({
    carerIds: carersPerPage.map(({ guid }) => guid),
    enabled: (carersPerPage ?? []).length > 0
  })
  const { data: paymentSummaries, isLoading: isLoadingSummaries } = usePaymentModelSummariesQuery()

  /**
   * Check if requests are still on loading status
   */
  const areRequestsLoading = useMemo(() => {
    return isRefetching || isLoadingOnboardingStatus || isLoadingSummaries
  }, [isLoadingOnboardingStatus, isLoadingSummaries, isRefetching])

  const [isFiltering, setIsFiltering] = useState(false)

  // filtered carers
  const carerWithNewOnboardingStatus = useMemo(() => {
    return carerList?.users.map((carer) => ({
      ...carer,
      onboarding_status: carersOnboardingStatuses?.[carer.guid] ?? carer.onboarding_status
    }))
  }, [carerList?.users, carersOnboardingStatuses])

  const filteredCarers = useMemo(() => {
    setIsFiltering(true) // add loader before filtering

    // filter by Finance attributes needs to be on FE side, CORE BE can not do it
    return filterCarerList({
      summaries: paymentSummaries,
      filter,
      carers: carerWithNewOnboardingStatus,
      setIsFiltering
    })
  }, [paymentSummaries, filter, carerWithNewOnboardingStatus])

  /**
   * Callback when user changes between pages
   */
  const onChangePage = useCallback(
    (newPage: number) => {
      setPage(newPage)
    },
    [setPage]
  )

  /**
   * Callback when user changes page size
   */
  const onChangePageSize = useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize)
    },
    [setPageSize]
  )

  return (
    <ContentContainer data-cy={testingId.carer.list.screen}>
      <Header title="Carers" />
      <CarerListFilter />
      <Typography variant="h6">Carers</Typography>
      <Divider
        sx={{
          bgcolor: "divider",
          mt: 0.5,
          mb: 2
        }}
      />
      <RequestLoaderWrapper isFiltering={isFiltering || areRequestsLoading}>
        {filteredCarers?.length || areRequestsLoading ? (
          <UserList
            baseUrl="/carers"
            items={filteredCarers ?? []}
            statusOptions={statusOptions}
            listUserType={UserType.Carer}
            onChangePage={onChangePage}
            onChangePageSize={onChangePageSize}
          />
        ) : (
          <EmptyData message="We’re sorry, but we couldn’t find a match for your search. Please check the spelling or adjust the filters and try again." />
        )}
      </RequestLoaderWrapper>
    </ContentContainer>
  )
}

export default CarerList
