import { useContractModelSalaryUpdateMutation, useContractModelSalaryBandPatchMutation } from "../mutations"
import { ContractModelSalaryRequestWithBands } from "../types"
import { useApiMessage } from "stores"

export const useContractModelSalaryBandUpdateHandler = ({
  contractModelGuid,
  salaryGuid
}: {
  contractModelGuid: string
  salaryGuid?: string
}): (({ payload }: { payload: ContractModelSalaryRequestWithBands }) => Promise<void>) => {
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  const { mutateAsync: updateSalary } = useContractModelSalaryUpdateMutation()

  const { mutateAsync: updateSalaryBands } = useContractModelSalaryBandPatchMutation({
    contractGuid: contractModelGuid,
    salaryGuid
  })

  return async ({ payload }: { payload: ContractModelSalaryRequestWithBands }) => {
    const { bands, ...salary } = payload

    const filteredBands = (bands || []).filter((item) => !item.delete)

    try {
      await updateSalary({ contractGuid: contractModelGuid, payload: salary })
      await updateSalaryBands({ bands: filteredBands ?? [] })

      showSuccessMessage("Salary and it's bands have been updated successfully.")
    } catch (apiError: any) {
      showErrorMessage("Something went wrong, please try again.", { apiError })
    }
  }
}
