import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import React, { memo } from "react"
import { EmptyData } from "components"
import { DateTimeFormat } from "lib/datetime"
import testingId from "constants/testingId"
import { ContractModelAccrualPolicy } from "data/finance/contractModel/types"
import { AccrualPolicyListHeader } from "./AccrualPolicyListHeader"
import { splitTimespanStringToTimeParts } from "lib/timespan"
import { useNavigate } from "react-router-dom"

interface OwnProps {
  policies?: ContractModelAccrualPolicy[]
}

const AccrualPolicyList: React.FC<OwnProps> = ({ policies }) => {
  const navigate = useNavigate()

  if (!policies?.length) return <EmptyData message="No accrual policies found, start by adding one." />

  return (
    <>
      <Table>
        <AccrualPolicyListHeader />
        <TableBody>
          {policies.map((policy: ContractModelAccrualPolicy) => (
            <TableRow
              key={policy.guid}
              data-cy={testingId.tableRow}
              hover
              sx={{
                cursor: "pointer"
              }}
              onClick={() => {
                navigate(policy.guid)
              }}
            >
              <TableCell data-cy={testingId.tableCell}>{policy.type_alias}</TableCell>
              <TableCell data-cy={testingId.tableCell}>{policy.start.toFormat(DateTimeFormat.DATE)}</TableCell>
              <TableCell data-cy={testingId.tableCell}>{policy?.end?.toFormat(DateTimeFormat.DATE) ?? "-"}</TableCell>
              <TableCell data-cy={testingId.tableCell}>{policy.accrual_cap} hours</TableCell>
              <TableCell data-cy={testingId.tableCell}>{policy.rollover_cap} weeks</TableCell>
              <TableCell>
                {policy.rollover_expiry ? `${splitTimespanStringToTimeParts(policy.rollover_expiry)[0]} days` : "unset"}
              </TableCell>
              <TableCell data-cy={testingId.tableCell}>{policy.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default memo(AccrualPolicyList)
