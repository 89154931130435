import { Box } from "@mui/material"
import React, { memo } from "react"
import { Apartment } from "@mui/icons-material"
import { Tag } from "components/Tag"
import { ApiContractAccrual } from "data/finance/carer/types"

interface OwnProps {
  accrual: ApiContractAccrual
}

export const CarerAccrualListTableItemBranch: React.FC<OwnProps> = memo(({ accrual }) => {
  CarerAccrualListTableItemBranch.displayName = "CarerAccrualListTableItemBranch"

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center"
      }}
    >
      {accrual.branch.title}
      {accrual.branch.is_primary && (
        <Tag status="Info" tooltipText="Branch that administers all annual leave absences" sx={{ ml: "10px" }}>
          <Apartment
            sx={(theme) => ({
              fill: theme.palette.info.main
            })}
          />
        </Tag>
      )}
    </Box>
  )
})
