import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

const AppLoading: React.FC = () => {
  return (
    <Box
      style={{
        height: "100vh"
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h3" color="primary">
        Loading, please wait...
      </Typography>
    </Box>
  )
}
export default AppLoading
