import { InvoiceModelRuleGroup } from "data/finance/contractModel/types"
import { InvoiceModelRuleGroupFilter } from "../components/RuleGroupListFilter"

export const filterContractRuleGroups = (
  filter: InvoiceModelRuleGroupFilter,
  items: InvoiceModelRuleGroup[] = []
): InvoiceModelRuleGroup[] => {
  const { status } = filter
  if (status?.length) {
    return items.filter((item) => status.includes(item.status))
  }

  return items
}
