import { useState } from "react"

interface Pagination {
  rowsPerPage: number
  page: number
  onPageChange: (newPage: number) => void
  onRowsPerPageChange: (newRowsPerPage: number) => void
}

const usePagination = (): Pagination => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)

  const onPageChange = (newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  return { 
    rowsPerPage,
    page,
    onRowsPerPageChange,
    onPageChange
  }

}

export default usePagination