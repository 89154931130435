import { ContentContainer, Header } from "components"
import React, { memo } from "react"
import { UnitProfile } from "./components/UnitProfile"

export const UnitCreate: React.FC = memo(() => {
  return (
    <ContentContainer>
      <Header title="Unit - new" />
      <UnitProfile mode="create" />
    </ContentContainer>
  )
})

UnitCreate.displayName = "UnitCreate"
