import React from "react"
import { Form, Field } from "react-final-form"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { Visit } from "models/Visit"
import { ModalWrapper } from "components"
import { Button, TextField } from "components/Form"
import validators, { composeValidators } from "lib/validators"
import { useReopenVisitMutation } from "data/visits/mutations"

interface OwnProps {
  destroyModal: Function
  visit: Visit
}

const ReopenModal: React.FC<OwnProps> = ({ visit, destroyModal }) => {
  const { mutateAsync } = useReopenVisitMutation()

  const onSubmit = async (values: { comment: string }) => {
    await mutateAsync({
      visit: visit.guid,
      report: visit.visit_report.guid,
      comment: values.comment
    })
    destroyModal()
  }

  return (
    <ModalWrapper title="Re-open visit report" destroyModal={destroyModal}>
      <Typography variant="body1" paragraph>
        You are about to reopen this visit report. Please provide a descriptive comment why are you doing so and how to
        resolve the issues you have found in this report.
      </Typography>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="comment"
              component={TextField}
              multiline
              minRows={5}
              label="Reason & guidance *"
              validate={composeValidators([validators.required, validators.maxLength(512)])}
            />
            <Box display="flex" justifyContent="space-between" m={3} mx={0}>
              <Button variant="text" fullWidth={false} onClick={() => destroyModal()}>
                Cancel
              </Button>
              <Button type="submit" fullWidth={false} disabled={submitting}>
                Re-open
              </Button>
            </Box>
          </form>
        )}
      </Form>
    </ModalWrapper>
  )
}
export default ReopenModal
