import { Box, Typography } from "@mui/material"
import { ModalWrapper } from "components"
import { Button, TextField } from "components/Form"
import { RunType } from "constants/runType"
import { useRunDeleteMutation, useRunPutMutation } from "data/finance/run/mutations"
import { Run } from "data/finance/run/types"
import React from "react"
import { Field, Form } from "react-final-form"

interface ModalProps {
  runType: RunType
  destroyModal: () => void
  run: Run
  runs: Run[]
}

export const RunEditModal: React.FC<ModalProps> = ({ runType, destroyModal, run, runs }) => {
  const { mutateAsync: updateRun } = useRunPutMutation({ runType, run })
  const { mutateAsync: deleteRun } = useRunDeleteMutation({ runType, run })

  const handleSubmit = async (values: { title: string }) => {
    const titleExists = runs?.find((run) => run.title === values.title)
    if (titleExists) {
      return { title: "Run with this title already exists" }
    }
    await updateRun(values)
    destroyModal()
  }

  const handleDelete = async () => {
    await deleteRun()
    destroyModal()
  }

  return (
    <ModalWrapper {...{ destroyModal }}>
      <Typography
        sx={(theme) => ({
          marginBottom: theme.spacing(4)
        })}
        variant="h5"
      >
        <Typography
          sx={{
            color: "primary.main"
          }}
          variant="h5"
          component="span"
        >
          {runType === RunType.INVOICE ? "Invoice Runs" : "Payment Runs"}
        </Typography>
        <span> / {run.title}</span>
      </Typography>
      <Form {...{ onSubmit: handleSubmit, initialValues: { title: run.title } }}>
        {({ handleSubmit, pristine, submitting }) => (
          <form {...{ onSubmit: handleSubmit }}>
            <Field<string> {...{ name: "title", component: TextField }} />
            <Box display="flex" justifyContent="space-between" m={3} mx={0}>
              <Button {...{ variant: "text", fullWidth: false, onClick: destroyModal }}>Cancel</Button>
              <Button {...{ variant: "text", fullWidth: false, onClick: handleDelete }}>Delete</Button>
              <Button {...{ type: "submit", disabled: pristine || submitting, fullWidth: false }}>Save</Button>
            </Box>
          </form>
        )}
      </Form>
    </ModalWrapper>
  )
}
