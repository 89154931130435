import React, { memo } from "react"
import InvoiceModelList from "./InvoiceModelList"
import InvoiceModelDetail from "./InvoiceModelDetail"
import ContractModelDetail from "../ContractModelDetail"
import { ContractModelType } from "constants/modelTypes"
import { RateUplift } from "../RateUplift"
import { Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

const InvoiceModels: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/" element={<InvoiceModelList />} />
      <Route path="/new" element={<ContractModelDetail modelType={ContractModelType.INVOICE} />} />
      <Route path="/:id/*" element={<InvoiceModelDetail />} />
      <Route path="/rate-uplift" element={<RateUplift modelType={ContractModelType.INVOICE} />} />
    </SentryRoutes>
  )
}

export default memo(InvoiceModels)
