import { Box } from "@mui/material"
import { WithPermission } from "components/Auth"
import { Button } from "components/Form"
import testingId from "constants/testingId"
import React, { memo, useCallback } from "react"

interface OwnProps {
  selectableIdsForPayroll: string[]
  selectableIdsForCrediting: string[]
  toggleSubset: (idArr: string[]) => void
}

export const TransactionGroupSelectButtons: React.FC<OwnProps> = memo(
  ({ selectableIdsForPayroll, selectableIdsForCrediting, toggleSubset }) => {
    const handleSelectPayrollClick = useCallback(() => {
      toggleSubset(selectableIdsForPayroll)
    }, [selectableIdsForPayroll, toggleSubset])

    const handleSelectCreditClick = useCallback(() => {
      toggleSubset(selectableIdsForCrediting)
    }, [selectableIdsForCrediting, toggleSubset])

    const creditAllButtonDisabled = !selectableIdsForCrediting.length
    const payrollAllButtonDisabled = !selectableIdsForPayroll.length

    return (
      <Box p={1} px={0} display="flex" justifyContent="flex-end">
        <WithPermission permissions={["Finance.Processing.Credit:Edit"]}>
          <Button
            fullWidth={false}
            variant="text"
            onClick={handleSelectCreditClick}
            data-cy={testingId.selectAllForCrediting}
            disabled={creditAllButtonDisabled}
          >
            (Un)Select all for crediting
          </Button>
        </WithPermission>
        <WithPermission permissions={["Finance.Processing:Edit"]}>
          <Button
            fullWidth={false}
            variant="text"
            onClick={handleSelectPayrollClick}
            data-cy={testingId.selectAllForProcessing}
            disabled={payrollAllButtonDisabled}
          >
            (Un)Select all for payroll run
          </Button>
        </WithPermission>
      </Box>
    )
  }
)

TransactionGroupSelectButtons.displayName = "TransactionGroupSelectButtons"
