import React, { memo, useMemo } from "react"
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material"
import { moneyFormat } from "lib/utils"
import { sum } from "ramda"
import testingId from "constants/testingId"
import { Info } from "@mui/icons-material"

interface OwnProps {
  items: {
    total: number
    users: number
    processable?: boolean
    contract_model_title?: string
    visits?: number
    visit_total?: number
    extra_total?: number
  }[]
  headers: string[]
  totalLabel: string
  isDownload?: boolean
}

export const SummaryList: React.FC<OwnProps> = memo(({ items, headers, totalLabel, isDownload }) => {
  const isCredit = headers.length === 5

  const totalValue = useMemo(() => moneyFormat.format(sum((items || []).map((item) => item.total)) || 0), [items])
  const totalEmployees = useMemo(() => sum((items || []).map((item) => item.users)) || 0, [items])

  return (
    <Table sx={{ borderCollapse: "separate" }}>
      <TableHead>
        <TableRow>
          {headers.map((label, index) => (
            <TableCell key={`${label}-${index}`} data-cy={testingId.summaryList.headerCell}>
              {label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {items?.map((item, key) => {
          const processable = item.processable || isDownload || false

          const cellStyle = processable ? null : { color: "text.disabled" }

          const creditCells = (
            <>
              <TableCell sx={cellStyle}>{moneyFormat.format(item.visit_total ?? 0)}</TableCell>
              <TableCell sx={cellStyle}>{moneyFormat.format(item.extra_total ?? 0)}</TableCell>
            </>
          )
          const nonCreditCell = isDownload ? (
            <TableCell sx={cellStyle} />
          ) : (
            <TableCell sx={cellStyle}>{item.visits}</TableCell>
          )

          return (
            <TableRow key={`summary-item-${key}`} hover={processable} data-cy={testingId.summaryList.tableRow}>
              <TableCell sx={cellStyle}>{item.contract_model_title}</TableCell>
              <TableCell sx={cellStyle}>{item.users}</TableCell>
              {isCredit ? creditCells : nonCreditCell}
              <TableCell sx={cellStyle}>
                <Box
                  sx={
                    !processable
                      ? {
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }
                      : null
                  }
                >
                  {moneyFormat.format(item.total)}
                  {!processable ? (
                    <Tooltip title="Transaction is empty and can not be processed.">
                      <Info />
                    </Tooltip>
                  ) : null}
                </Box>
              </TableCell>
            </TableRow>
          )
        })}
        <TableRow
          sx={(theme) => ({
            "& td": {
              borderTop: `1px solid ${theme.palette.primary.main}`
            }
          })}
        >
          <TableCell
            sx={{
              fontWeight: "bold",
              textAlign: "right",
              paddingRight: "10px"
            }}
          >
            {totalLabel}
          </TableCell>
          <TableCell
            sx={{
              fontWeight: "bold",
              color: "primary.main"
            }}
            data-cy={testingId.summaryList.totalUsers}
          >
            {totalEmployees}
          </TableCell>
          {isCredit ? <TableCell /> : null}
          <TableCell
            sx={{
              fontWeight: "bold",
              textAlign: "right",
              paddingRight: "10px"
            }}
          >
            Total Value:
          </TableCell>
          <TableCell
            sx={{
              fontWeight: "bold",
              color: "primary.main"
            }}
            data-cy={testingId.summaryList.totalValue}
          >
            {totalValue}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
})

SummaryList.displayName = "SummaryList"
