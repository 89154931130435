import { Box, Grid } from "@mui/material"
import { ModalWrapper, NoEndDateCheckbox } from "components"
import { Button, Select, TextField } from "components/Form"
import React, { memo } from "react"
import { Field, Form } from "react-final-form"
import { ContractModel, InvoiceModelRuleGroup, InvoiceModelRuleGroupRequest } from "data/finance/contractModel/types"
import validators, { composeValidators } from "lib/validators"
import { useInvoiceModelRuleGroupCreateMutation } from "data/finance/contractModel/mutations"
import { validateCopiedRuleGroup } from "../validators/validateRuleGroup"
import BottomErrorMessage from "components/BottomErrorMessage"
import { useSchema } from "data/schema/useSchema"
import { SelectDummy } from "components/Form/SelectDummy"
import { DateField } from "components/Form/DateField"

interface OwnProps {
  destroyModal: () => void
  ruleGroup: InvoiceModelRuleGroup
  ruleGroups: InvoiceModelRuleGroup[]
  contractModel: ContractModel
  isPaymentRule: boolean
}

interface FormValues extends InvoiceModelRuleGroupRequest {
  no_end_date?: boolean
}

export const RuleGroupCopyModal: React.FC<OwnProps> = memo(
  ({ destroyModal, ruleGroup, ruleGroups, contractModel, isPaymentRule }) => {
    const { RuleGroupPrice } = useSchema(["RuleGroupPrice"])

    const { mutateAsync: createRuleGroup } = useInvoiceModelRuleGroupCreateMutation({
      contractGuid: contractModel.guid,
      isPaymentRule,
      copyGuid: ruleGroup.guid,
      destroyModal
    })

    const onSubmit = (values: FormValues) => {
      const { no_end_date, rate, ...rest } = values
      const payload = {
        ...rest,
        end: no_end_date ? undefined : values.end,
        rate: values.price_type_alias === "NONE" ? undefined : rate
      }
      createRuleGroup(payload)
    }

    const validate = (values: FormValues) => {
      return validateCopiedRuleGroup({ contractModel, items: ruleGroups, originalRuleGroup: ruleGroup })(values)
    }

    const initialValues = {
      title: ruleGroup.title,
      price_type_alias: ruleGroup.price_type_alias,
      rate: ruleGroup.rate,
      start: ruleGroup.end
    }

    return (
      <ModalWrapper {...{ title: "Copy Rule Group", width: 700, destroyModal }}>
        <Form {...{ onSubmit, initialValues, validate }}>
          {({ handleSubmit, values, error, submitError }) => {
            return (
              <form {...{ onSubmit: handleSubmit }}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Field
                      name="title"
                      label="Group Name *"
                      component={TextField}
                      validate={composeValidators([validators.required, validators.maxLength(64)])}
                    />
                  </Grid>
                  <Grid item md={9}>
                    {RuleGroupPrice ? (
                      <Field
                        name="price_type_alias"
                        label="Fixed Price per Visit *"
                        component={Select}
                        options={RuleGroupPrice}
                        validate={validators.required}
                      />
                    ) : (
                      <SelectDummy value="loading..." />
                    )}
                  </Grid>
                  <Grid item md={3}>
                    {values.price_type_alias === "NONE" ? null : (
                      <Field
                        component={TextField}
                        name="rate"
                        label="Rate *"
                        validate={validators.validateCommonNumber({ min: 0 })}
                      />
                    )}
                  </Grid>
                  <Grid item md={6}>
                    <DateField name="start" label="Starts on" required />
                  </Grid>
                  <Grid item md={6}>
                    <DateField name="end" label="Ends before" disabled={!!values.no_end_date} />
                    <NoEndDateCheckbox />
                  </Grid>

                  <Grid item md={6}>
                    {error || submitError ? <BottomErrorMessage message={error || submitError} /> : null}
                  </Grid>
                  <Grid item md={6}>
                    <Box mt={2} display="flex" justifyContent="flex-end" width="100%">
                      <Button fullWidth={false} variant="text" onClick={destroyModal}>
                        Cancel
                      </Button>
                      <Button fullWidth={false} color="primary" type="submit">
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        </Form>
      </ModalWrapper>
    )
  }
)

RuleGroupCopyModal.displayName = "RuleGroupCopyModal"
