import { FinanceApi } from "api"
import { enabledById, enabledByMandatoryUrlParams, setDayBorders } from "data/helpers"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { Unit, UnitContract, UnitShiftCategory, UnitUser } from "./types"

export enum QueryKeyParts {
  LIST = "list",
  CONTRACT = "contract",
  UNIT = "unit",
  DETAIL = "detail",
  USER = "user",
  SHIFT_CATEGORY = "shift_category"
}

export const queryKeys = {
  getUnitListKey: (search?: string): QueryKey => [QueryKeyParts.UNIT, QueryKeyParts.LIST, { search }],
  getUnitDetailKey: (guid?: string): QueryKey => [QueryKeyParts.UNIT, QueryKeyParts.DETAIL, { guid }],
  getUnitContractListKey: ({ unitGuid, search }: { unitGuid?: string; search?: string }): QueryKey => [
    QueryKeyParts.UNIT,
    QueryKeyParts.CONTRACT,
    QueryKeyParts.LIST,
    { unitGuid, search }
  ],
  getUnitContractDetailKey: ({ unitGuid, contractGuid }: { unitGuid: string; contractGuid?: string }): QueryKey => [
    QueryKeyParts.UNIT,
    QueryKeyParts.CONTRACT,
    QueryKeyParts.DETAIL,
    { unitGuid, contractGuid }
  ],
  getUnitUserListKey: ({ unitGuid, search }: { unitGuid?: string; search?: string }): QueryKey => [
    QueryKeyParts.UNIT,
    QueryKeyParts.USER,
    QueryKeyParts.LIST,
    { unitGuid, search }
  ],
  getUnitUserDetailKey: ({ unitGuid, userGuid }: { unitGuid: string; userGuid?: string }): QueryKey => [
    QueryKeyParts.UNIT,
    QueryKeyParts.USER,
    QueryKeyParts.DETAIL,
    { unitGuid, userGuid }
  ],
  getUnitShiftCategoryListKey: ({ unitGuid, search }: { unitGuid?: string; search?: string }): QueryKey => [
    QueryKeyParts.UNIT,
    QueryKeyParts.SHIFT_CATEGORY,
    QueryKeyParts.LIST,
    { unitGuid, search }
  ],
  getUnitShiftCategoryDetailKey: ({ unitGuid, guid }: { unitGuid: string; guid?: string }): QueryKey => [
    QueryKeyParts.UNIT,
    QueryKeyParts.SHIFT_CATEGORY,
    QueryKeyParts.DETAIL,
    { unitGuid, guid }
  ]
}

export const useUnitListQuery = ({ search }: { search?: string }): UseQueryResult<Unit[], Error> => {
  return useQuery({
    queryKey: queryKeys.getUnitListKey(search),
    queryFn: async () => FinanceApi.get(`/finance/units${search ? search : ""}`),
    ...enabledByMandatoryUrlParams([search])
  })
}

export const useUnitDetailQuery = ({ unitGuid }: { unitGuid?: string }): UseQueryResult<Unit, Error> => {
  return useQuery({
    queryKey: queryKeys.getUnitDetailKey(unitGuid),
    queryFn: async () => FinanceApi.get(`/finance/units/${unitGuid}`),
    ...enabledById(unitGuid)
  })
}

export const useUnitContractListQuery = ({
  unitGuid,
  search,
  searchRequired = false
}: {
  unitGuid?: string
  search?: string
  searchRequired?: boolean
}): UseQueryResult<UnitContract[], Error> => {
  return useQuery({
    queryKey: queryKeys.getUnitContractListKey({ unitGuid, search }),
    queryFn: async () => FinanceApi.get(`/finance/units/${unitGuid}/contracts${search ? search : ""}`),
    cacheTime: 0,
    ...enabledByMandatoryUrlParams([searchRequired ? search : true, unitGuid])
  })
}

export const useUnitContractDetailQuery = ({
  unitGuid,
  userContractGuid,
  enabled = true
}: {
  unitGuid: string
  userContractGuid?: string
  enabled?: boolean
}): UseQueryResult<UnitContract, Error> => {
  return useQuery({
    queryKey: queryKeys.getUnitContractDetailKey({ unitGuid, contractGuid: userContractGuid }),
    queryFn: async () => FinanceApi.get(`/finance/units/${unitGuid}/contracts/${userContractGuid}`),
    ...enabledByMandatoryUrlParams([userContractGuid, enabled])
  })
}

export const useUnitUserListQuery = ({
  search,
  unitGuid
}: {
  unitGuid: string
  search?: string
}): UseQueryResult<UnitUser[], Error> => {
  const newSearch = setDayBorders({ search, from: "start_from", to: "end_to" })

  return useQuery({
    queryKey: queryKeys.getUnitUserListKey({ search: newSearch, unitGuid }),
    queryFn: async () => FinanceApi.get(`/finance/units/${unitGuid}/users${newSearch}`),
    ...enabledByMandatoryUrlParams([newSearch, unitGuid])
  })
}

export const useUnitUserDetailQuery = ({
  unitGuid,
  userGuid,
  enabled = true
}: {
  unitGuid: string
  userGuid?: string
  enabled?: boolean
}): UseQueryResult<UnitUser, Error> => {
  return useQuery({
    queryKey: queryKeys.getUnitUserDetailKey({ unitGuid, userGuid }),
    queryFn: async () => FinanceApi.get(`/finance/units/${unitGuid}/users/${userGuid}`),
    ...enabledByMandatoryUrlParams([userGuid, enabled])
  })
}

export const useUnitShiftCategoryListQuery = ({
  search,
  unitGuid
}: {
  unitGuid: string
  search?: string
}): UseQueryResult<UnitShiftCategory[], Error> => {
  const newSearch = setDayBorders({ search, from: "start_from", to: "end_to" })

  return useQuery({
    queryKey: queryKeys.getUnitShiftCategoryListKey({ search: newSearch, unitGuid }),
    queryFn: async () => FinanceApi.get(`/finance/units/${unitGuid}/shift-categories${newSearch}`),
    ...enabledByMandatoryUrlParams([newSearch, unitGuid])
  })
}

export const useUnitShiftCategoryDetailQuery = ({
  unitGuid,
  guid,
  enabled = true
}: {
  unitGuid: string
  guid?: string
  enabled?: boolean
}): UseQueryResult<UnitShiftCategory, Error> => {
  return useQuery({
    queryKey: queryKeys.getUnitShiftCategoryDetailKey({ unitGuid, guid }),
    queryFn: async () => FinanceApi.get(`/finance/units/${unitGuid}/shift-categories/${guid}`),
    ...enabledByMandatoryUrlParams([guid, enabled])
  })
}
