import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { EmptyData, Paginator } from "components"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { AppointmentTabType } from "constants/appointmentType"
import testingId from "constants/testingId"
import { useInvoiceSearchQuery } from "data/finance/invoiceSearch/queries"
import { usePaymentSearchQuery } from "data/finance/paymentSearch/queries"
import Auth from "lib/Auth"
import { useIsInvoiceModelSearch, useModelTypeSearch } from "lib/hooks/useIsInvoiceModelSearch"
import { SetPagination } from "lib/types"
import React, { memo } from "react"
import { AppointmentSearchBulkCreditButton } from "./AppointmentSearchBulkCreditButton"
import { AppointmentSearchListTableItem } from "./AppointmentSearchListTableItem"
import { AppointmentSearchBulkRecalcButton } from "./AppointmentSearchBulkRecalcButton"
import { useLocation } from "react-router-dom"
import { Permission } from "constants/permission"

interface Props {
  setPagination: SetPagination
}

const headers = (isInvoiceModel: boolean, hasRecalcPermission: boolean): string[] => [
  "Start Date (R)",
  "End Date (R)",
  isInvoiceModel ? "Client Name" : "Carer Name",
  "Contract Model",
  "Appointment Type",
  isInvoiceModel ? "Invoice Number" : "Payment Number",
  "Cost",
  "",
  ...(hasRecalcPermission ? ["Recalculate"] : [])
]

export const AppointmentSearchListTable = memo(({ setPagination }: Props) => {
  const location = useLocation()
  const isInvoiceModel = useIsInvoiceModelSearch()
  const isPaymentModel = useModelTypeSearch({ model: AppointmentTabType.PAYMENT })
  const hasRecalcPermission = Auth.hasPermission([Permission.FINANCE_RECALCULATE_EDIT])

  const { data: invoiceAppointments, refetch: refetchInvoices } = useInvoiceSearchQuery({
    search: location.search,
    enabled: isInvoiceModel && !!location.search
  })
  const { data: paymentAppointments, refetch: refetchPayments } = usePaymentSearchQuery({
    search: location.search,
    enabled: isPaymentModel && !!location.search
  })
  const appointments = isInvoiceModel ? invoiceAppointments : paymentAppointments
  const refetch = isInvoiceModel ? refetchInvoices : refetchPayments

  return (
    <RequestLoaderWrapper>
      {appointments && appointments.length ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                {headers(isInvoiceModel, hasRecalcPermission).map((name) => (
                  <TableCell
                    data-cy={testingId.tableHeaderCell}
                    key={name}
                    sx={{
                      fontWeight: 600
                    }}
                  >
                    {name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {appointments.map((appointment) => (
                <AppointmentSearchListTableItem
                  {...{ appointment, isInvoiceModel, refetch, hasRecalcPermission }}
                  key={appointment.guid}
                />
              ))}
            </TableBody>
          </Table>
          <Paginator {...{ setPagination, itemsLength: appointments.length }} />
          <Box {...{ display: "flex", justifyContent: "flex-end", my: 2 }}>
            <AppointmentSearchBulkCreditButton {...{ location, isInvoiceModel, refetch }} />
            {hasRecalcPermission && <AppointmentSearchBulkRecalcButton {...{ appointments }} />}
          </Box>
        </>
      ) : (
        <EmptyData {...{ message: "No appointments found." }} />
      )}
    </RequestLoaderWrapper>
  )
})

AppointmentSearchListTable.displayName = "AppointmentSearchListTable"
