import { useInvoiceModelListQuery, usePaymentModelListQuery } from "data/finance/contractModel/queries"
import { UseQueryResult } from "react-query"
import { ContractModel } from "../types"

interface OwnProps {
  isInvoiceModel: boolean
}

export const useContractModelListQuery = ({ isInvoiceModel }: OwnProps): UseQueryResult<ContractModel[], Error> => {
  const invoiceModelResponse = useInvoiceModelListQuery({ enabled: isInvoiceModel })
  const paymentModelResponse = usePaymentModelListQuery({ enabled: !isInvoiceModel })

  return isInvoiceModel ? invoiceModelResponse : paymentModelResponse
}
