import TableBody from "@mui/material/TableBody"
import { Box, Grid, Table, TableCell, TableHead, TableRow } from "@mui/material"
import React from "react"
import { ContentContainer, EmptyData, Header, Link, Divider } from "components"
import { Button } from "components/Form"
import { ContractServiceType, ContractServiceSetupType } from "data/finance/contractServiceType/types"
import { WithPermission } from "components/Auth"
import { useContractServiceTypeListQuery } from "data/finance/contractServiceType/queries"
import { useLocation, useNavigate } from "react-router-dom"

const ContractServiceTypeList: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { data: contractServiceTypeList } = useContractServiceTypeListQuery()

  const removeSetAsValue = (list: ContractServiceSetupType[], key: ContractServiceSetupType) =>
    list.filter((st) => st !== key)

  const formatSetAsToString = (contractType: ContractServiceType) => {
    let setAs: ContractServiceSetupType[] = ["Training", "Shadowing", "Excluded From National Minimum Wage"]

    if (!contractType.is_training) setAs = removeSetAsValue(setAs, "Training")
    if (!contractType.is_shadowing) setAs = removeSetAsValue(setAs, "Shadowing")
    if (!contractType.exclude_from_nmw) setAs = removeSetAsValue(setAs, "Excluded From National Minimum Wage")
    return setAs.join(", ")
  }

  return (
    <ContentContainer>
      <Header title="Contract care types" />
      <Box m={3} mx={0}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item md={9} />
          <Grid item md={3}>
            <WithPermission permissions={["Finance.Settings.ServiceType:Edit"]}>
              <Link to="new">
                <Button>Create a care type</Button>
              </Link>
            </WithPermission>
          </Grid>
        </Grid>
        <Divider color="divider" />
        {contractServiceTypeList?.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Care type</TableCell>
                <TableCell align="left">Set as</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contractServiceTypeList.map((c: ContractServiceType) => (
                <TableRow
                  key={c.guid}
                  hover
                  sx={{
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    navigate(`${location.pathname}/${c.guid}`)
                  }}
                >
                  <TableCell>{c.title}</TableCell>
                  <TableCell>{formatSetAsToString(c)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyData message="No contract care types found, start by adding one." />
        )}
      </Box>
    </ContentContainer>
  )
}

export default React.memo(ContractServiceTypeList)
