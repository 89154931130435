import React from "react"
import { NotFound } from "./index"
import Auth from "lib/Auth"
import PermissionDenied from "app/PermissionDenied"
import { UnitList } from "./Units/UnitList"
import { UnitCreate } from "./Units/UnitCreate"
import { UnitDetail } from "./Units/UnitDetail"
import { Route } from "react-router-dom"
import AppLoading from "app/AppLoading"
import useBranchPermissions from "lib/hooks/branchInfo/useBranchPermissions"
import { BranchFeatures } from "lib/hooks/branchInfo/types"
import { Permission } from "constants/permission"
import SentryRoutes from "components/Routes"

const Units: React.FC = () => {
  const { hasBranchPermission, isFetching } = useBranchPermissions()

  const hasFinanceFlag = hasBranchPermission(BranchFeatures.FINANCE)
  const hasShiftsFlag = hasBranchPermission(BranchFeatures.SHIFTS)

  if (isFetching) {
    return <AppLoading />
  }

  if (!Auth.hasPermission([Permission.FINANCE_UNIT_READ]) || !hasFinanceFlag || !hasShiftsFlag) {
    return <PermissionDenied />
  }

  return (
    <SentryRoutes>
      <Route path="/" element={<UnitList />} />
      <Route path="/new" element={<UnitCreate />} />
      <Route path="/:unitGuid/*" element={<UnitDetail />} />
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  )
}
export default Units
