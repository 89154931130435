import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import { EmptyData } from "components"
import React, { Dispatch, memo, SetStateAction } from "react"
import testingId from "constants/testingId"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { SelectableIdsDictionaryModel, useIsQA } from "lib/hooks"
import Auth from "lib/Auth"
import { TransactionListTableHeader } from "./TransactionListTableHeader"
import { mapPaymentTransaction } from "../helpers/mapPaymentTransaction"
import { Permission } from "constants/permission"
import { useLocation, useNavigate } from "react-router-dom"
import { SelectableTableRow } from "components/SelectableTableRow"
import { PaymentTransactionFilter } from "../types"
import { PaymentTransaction } from "data/finance/paymentProcessing/types"

interface Props {
  transactions?: PaymentTransaction[]
  selectableIds: SelectableIdsDictionaryModel
  toggleId: (argId: string) => void
  setFilter: Dispatch<SetStateAction<PaymentTransactionFilter>>
  refetch: () => void
  selectedIds: string[]
}

const TransactionListTable: React.FC<Props> = ({
  transactions = [],
  selectableIds,
  toggleId,
  setFilter,
  refetch,
  selectedIds
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const isQA = useIsQA()
  const hasDeveloperPermission = Auth.hasPermission([Permission.DEVELOPER_EDIT])
  const hasRewindPermission = isQA && Auth.hasPermission([Permission.FINANCE_REWIND_EDIT]) && hasDeveloperPermission

  const hasCreditableTransactions = transactions.some((t) => t.is_creditable)
  const hasAlerts = transactions.some((t) => t.is_alerting)

  return (
    <RequestLoaderWrapper>
      <TableContainer>
        <Table stickyHeader data-cy={testingId.paymentProcessingTransactionList.table}>
          <TransactionListTableHeader
            {...{
              setFilter,
              hasRewindPermission,
              hasDeveloperPermission,
              hasCreditableTransactions,
              hasAlerts
            }}
          />
          <TableBody>
            {transactions.length > 0 ? (
              transactions.map((transaction) => (
                <SelectableTableRow
                  hover
                  clickable
                  isSelected={selectedIds.includes(transaction.guid)}
                  onClick={() => navigate(`${location.pathname}/${transaction.guid}`)}
                  key={transaction.guid}
                >
                  {mapPaymentTransaction({
                    transaction,
                    selectableIds,
                    toggleId,
                    hasCreditableTransactions,
                    hasAlerts,
                    hasRewindPermission,
                    hasDeveloperPermission,
                    refetch
                  }).map((value, cellIndex) => (
                    <TableCell key={cellIndex} data-cy={testingId.tableCell}>
                      {value}
                    </TableCell>
                  ))}
                </SelectableTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8}>
                  <EmptyData message="No payment contracts found in this billing period with the selected filter" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </RequestLoaderWrapper>
  )
}

export default memo(TransactionListTable)
