import { queryStringToObject } from "lib/queryString"
import { Pagination } from "lib/types"
import { Dispatch, SetStateAction, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"

interface Props {
  initialPageSize?: 10 | 25 | 50 | 100 | 250 | 500
}

export const usePagination = (
  { initialPageSize }: Props = {
    initialPageSize: 25
  }
): {
  pagination: Pagination
  setPagination: Dispatch<SetStateAction<Pagination>>
  resetPagination: () => void
} => {
  const location = useLocation()
  const searchObject = useMemo(() => queryStringToObject(location.search), [location])

  const [pagination, setPagination] = useState<Pagination>({
    page: searchObject.page || "1",
    page_size: searchObject.page_size || initialPageSize?.toString()
  })

  const resetPagination = () => {
    setPagination((oldValues) => ({ page: "1", page_size: oldValues.page_size }))
  }

  return {
    pagination,
    setPagination,
    resetPagination
  }
}
