import { CoreApi } from "api"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { ApiShiftCategoryResponse, ListShiftCategoriesResponse } from "types/Shifts/ShiftCategories/types"

export enum QueryKeyParts {
  SHIFT_CATEGORY = "shift_category",
  LIST = "list",
  DETAIL = "detail"
}

export const queryKeys = {
  getShiftCategoriesKey: (): QueryKey => [QueryKeyParts.SHIFT_CATEGORY, QueryKeyParts.LIST, QueryKeyParts.LIST],
  getShiftCategoryDetailKey: (id: string): QueryKey => [QueryKeyParts.SHIFT_CATEGORY, QueryKeyParts.DETAIL, { id }]
}

export const useShiftCategoryListQuery = (): UseQueryResult<ListShiftCategoriesResponse[], Error> => {
  return useQuery({
    queryKey: queryKeys.getShiftCategoriesKey(),
    queryFn: async () => CoreApi.get("/shift-categories")
  })
}

export const useShiftCategoryDetailQuery = (id: string): UseQueryResult<ApiShiftCategoryResponse, Error> => {
  return useQuery({
    queryKey: queryKeys.getShiftCategoryDetailKey(id),
    queryFn: async () => CoreApi.get(`/shift-categories/${id}`)
  })
}
