import React, { Dispatch, memo, ReactNode, SetStateAction } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import EditIcon from "@mui/icons-material/Edit"
import { VisitConcern } from "components"
import IconButton from "@mui/material/IconButton"
import HelpIcon from "@mui/icons-material/Help"
import { Visit } from "models/Visit"
import CheckInModal from "./CheckInModal"
import CheckOutModal from "./CheckOutModal"
import { ApiVisit } from "models"
import testingId from "constants/testingId"
import Auth from "lib/Auth"
import { UnlockButton } from "components/UnlockButton"

interface OwnProps {
  visit: Visit
  setModal: Dispatch<SetStateAction<ReactNode>>
  destroyModal: () => void
  apiVisit?: ApiVisit
}

const VisitDetailCheckInOut: React.FC<OwnProps> = ({ visit, setModal, destroyModal, apiVisit }) => {
  const hasPermission = Auth.hasPermission(["Visit.CM2000:Edit"])

  return (
    <>
      <Grid item xs={3}>
        <Typography variant="body2" color="textSecondary" component="h6">
          Check in time
        </Typography>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            ...(apiVisit?.is_cm2000 && {
              color: "grey.500"
            })
          }}
        >
          <Typography variant="body1" data-cy={testingId.visit.detail.checkinTime}>
            {visit.isCheckedIn() ? visit.formatDate("checkin") : "Not checked-in"}
          </Typography>
          <IconButton
            id="visit-details-edit-check-in-btn"
            size="small"
            sx={{
              ml: 1
            }}
            onClick={() => {
              setModal(() => <CheckInModal canCheckIn={visit.canCheckIn()} destroyModal={destroyModal} visit={visit} />)
            }}
          >
            {visit.canCheckIn() ? <EditIcon /> : <HelpIcon />}
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" color="textSecondary">
          Check out time
        </Typography>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            ...(apiVisit?.is_cm2000 && {
              color: "grey.500"
            })
          }}
        >
          <Typography variant="body1" data-cy={testingId.visit.detail.checkoutTime}>
            {visit.isCheckedOut() ? visit.formatDate("checkout") : "Not checked-out"}
          </Typography>
          {!apiVisit?.is_cm2000 && (
            <IconButton
              id="visit-details-edit-check-out-btn"
              size="small"
              sx={{
                ml: 1
              }}
              onClick={() => {
                setModal(() => (
                  <CheckOutModal canCheckOut={visit.canCheckOut()} visit={visit} destroyModal={destroyModal} />
                ))
              }}
            >
              {visit.canCheckOut() ? <EditIcon data-cy={testingId.visit.detail.checkoutEdit} /> : <HelpIcon />}
            </IconButton>
          )}
          {apiVisit?.is_cm2000 && hasPermission && (
            <UnlockButton
              carerVisitGuid={visit.guid}
              unlocked={!apiVisit?.is_cm2000}
              clientGuid={visit.patient_guid}
            ></UnlockButton>
          )}
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" color="textSecondary" component="h6">
          Concern
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <VisitConcern variant="h6" concern={visit.risk_score} />
        </Box>
      </Grid>
    </>
  )
}
export default memo(VisitDetailCheckInOut)
