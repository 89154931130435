import { Box, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { UnitShiftCategory } from "data/finance/unit/types"
import { DateTimeFormat } from "lib/datetime"
import React, { memo, useCallback } from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import { Button, Checkbox } from "components/Form"
import { Field, Form } from "react-final-form"
import { Paginator, StickyBox } from "components"
import { useUnitShiftCategoryDeleteMutation } from "data/finance/unit/mutations"
import { QueryRefetch, SetPagination } from "lib/types"
import { useDebouncedCallback } from "use-debounce"
import DatesMessageHelp from "components/DatesMessageHelp"
import { useNavigate } from "react-router-dom"

const headers: string[] = ["Title", "Starts on", "Ends before", "Status"]

interface OwnProps {
  shiftCategories: UnitShiftCategory[]
  unitGuid: string
  refetch: QueryRefetch<UnitShiftCategory[]>
  setPagination: SetPagination
}

export const UnitShiftCategoryListTable: React.FC<OwnProps> = memo(
  ({ shiftCategories, unitGuid, refetch, setPagination }) => {
    const navigate = useNavigate()

    const onRowClick = useCallback(
      (guid: string) => () => {
        navigate(`/units/${unitGuid}/shift-categories/${guid}`)
      },
      [unitGuid, navigate]
    )

    const { mutateAsync: deleteShiftCategory } = useUnitShiftCategoryDeleteMutation({ unitGuid })

    const debouncedRefetch = useDebouncedCallback(() => {
      refetch()
    }, 500)

    const onSubmit = (values: Record<string, boolean>) => {
      Object.keys(values).forEach(async (guid) => {
        if (values[guid]) {
          await deleteShiftCategory({ guid })
          debouncedRefetch()
        }
      })
    }

    return (
      <Box {...{ m: 3, mx: 0 }}>
        <Typography variant="h6">Shift Categories</Typography>
        <Divider
          sx={{
            backgroundColor: "divider",
            marginTop: 1,
            marginBottom: 1
          }}
        />
        <DatesMessageHelp messageType="E" />
        <Form {...{ onSubmit, destroyOnUnregister: true }}>
          {({ handleSubmit, values }) => {
            return (
              <form {...{ onSubmit: handleSubmit }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <DeleteIcon sx={{ marginRight: 1, marginTop: 1 }} />
                      </TableCell>
                      {headers.map((title) => (
                        <TableCell key={title}>{title}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {shiftCategories.map((shiftCategory) => (
                      <TableRow
                        sx={{ cursor: "pointer" }}
                        key={shiftCategory.guid}
                        hover
                        onClick={onRowClick(shiftCategory.guid)}
                      >
                        <TableCell>
                          {shiftCategory.status === "Future" ? (
                            <Field<boolean>
                              {...{
                                type: "checkbox",
                                component: Checkbox,
                                name: shiftCategory.guid,
                                onClick: (e: MouseEvent) => {
                                  e.stopPropagation()
                                }
                              }}
                            />
                          ) : null}
                        </TableCell>
                        <TableCell>{shiftCategory.shift_title}</TableCell>
                        <TableCell>{shiftCategory.start.toFormat(DateTimeFormat.DATE)}</TableCell>
                        <TableCell>
                          {shiftCategory.end ? shiftCategory.end.toFormat(DateTimeFormat.DATE) : "--/--/--"}
                        </TableCell>
                        <TableCell>{shiftCategory.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Paginator {...{ setPagination, itemsLength: shiftCategories.length }} />
                <StickyBox>
                  <Button
                    {...{
                      type: "submit",
                      fullWidth: false,
                      disabled: !Object.values(values).includes(true)
                    }}
                  >
                    Delete
                  </Button>
                </StickyBox>
              </form>
            )
          }}
        </Form>
      </Box>
    )
  }
)

UnitShiftCategoryListTable.displayName = "UnitShiftCategoryListTable"
