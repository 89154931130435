import { ContentContainer, Divider, TabPanel } from "components"
import React, { memo, useMemo, useState } from "react"
import { PaymentTransactionItemsFilter, TransactionDetailItemsFilter } from "./components/TransactionDetailItemsFilter"
import { useIsQA, useSelectableIdsDictionary } from "lib/hooks"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import {
  usePaymentProcessingTransactionChargeListQuery,
  usePaymentProcessingTransactionQuery,
  usePaymentProcessingTransactionVisitListQuery
} from "data/finance/paymentProcessing/queries"
import { useCarerContractQuery } from "data/finance/carer/queries"
import { TransactionDetailHeader } from "./components/TransactionDetailHeader"
import { TransactionDetailTabBar } from "./components/TransactionDetailTabBar"
import { queryStringToObject } from "lib/queryString"
import { filterPaymentTransactionVisits } from "./helpers/filterPaymentTransactionVisits"
import {
  PaymentTransactionChargesFilter,
  TransactionDetailChargesFilter
} from "./components/TransactionDetailChargesFilter"
import { filterPaymentTransactionCharges } from "./helpers/filterPaymentTransactionCharges"
import { TransactionDetailType } from "../types"
import { TransactionDetailItemsTable } from "./components/TransactionDetailItemsTable"
import { TransactionDetailChargesTable } from "./components/TransactionDetailChargesTable"
import { usePaymentModelQuery, usePeriodQuery } from "data/finance/contractModel/queries"
import Alert from "components/Alert"
import { Permission, PermissionGroup } from "constants/permission"
import Auth from "lib/Auth"
import { useLocation, useParams } from "react-router-dom"

interface OwnProps {
  periodGuid: string
  calendarGuid: string
  contractGuid: string
  transactionGuid: string
}

const TransactionDetailComponent: React.FC<OwnProps> = ({
  calendarGuid,
  periodGuid,
  contractGuid: contractModelGuid,
  transactionGuid
}) => {
  const location = useLocation()

  const { data: paymentModel } = usePaymentModelQuery(contractModelGuid)

  const { data: period } = usePeriodQuery({
    modelType: ContractModelType.PAYMENT,
    contractGuid: contractModelGuid,
    calendarGuid,
    periodGuid
  })

  const { data: transaction } = usePaymentProcessingTransactionQuery({ periodGuid, transactionGuid })

  const { data: contract } = useCarerContractQuery({
    carerGuid: transaction?.owner_guid,
    contractGuid: transaction?.user_contract_guid
  })

  const { data: visits = [], refetch: refetchVisits } = usePaymentProcessingTransactionVisitListQuery({
    periodGuid,
    transactionGuid
  })
  const { data: charges = [], refetch: refetchCharges } = usePaymentProcessingTransactionChargeListQuery({
    periodGuid,
    transactionGuid
  })

  const [tab, setTab] = useState<TransactionDetailType>(() => {
    const { tab: tabType } = queryStringToObject(location?.search)
    return tabType === TransactionDetailType.EXTRA ? TransactionDetailType.EXTRA : TransactionDetailType.VISIT
  })

  // visits
  const [itemFilter, setItemFilter] = useState<PaymentTransactionItemsFilter>(() => {
    return tab === TransactionDetailType.VISIT ? queryStringToObject(location?.search) : {}
  })
  const filteredVisits = useMemo(
    () => filterPaymentTransactionVisits({ visits, filter: itemFilter }),
    [visits, itemFilter]
  )
  const visitsIds = useMemo(() => {
    return filteredVisits.filter((item) => item.is_creditable).map((item) => item.guid)
  }, [filteredVisits])
  const selectableVisitsDictionary = useSelectableIdsDictionary(visitsIds)

  // charges
  const [chargeFilter, setChargeFilter] = useState<PaymentTransactionChargesFilter>(() => {
    return tab === TransactionDetailType.EXTRA ? queryStringToObject(location?.search) : {}
  })
  const filteredCharges = useMemo(
    () => filterPaymentTransactionCharges({ charges, filter: chargeFilter }),
    [charges, chargeFilter]
  )
  const chargesIds = useMemo(() => {
    return filteredCharges.filter((charge) => charge.is_creditable).map((charge) => charge.guid)
  }, [filteredCharges])
  const chargesDeletableIds = useMemo(() => {
    return filteredCharges.filter((charge) => !charge.readonly).map((charge) => charge.guid)
  }, [filteredCharges])

  const selectableChargesDictionary = useSelectableIdsDictionary(chargesIds)
  const deletableChargesDictionary = useSelectableIdsDictionary(chargesDeletableIds)

  const onVisitsCredited = () => {
    refetchVisits()
  }

  const onExtrasCredited = () => {
    refetchCharges()
  }

  const isQA = useIsQA()

  if (!period || !paymentModel || !transaction || !contract) return null

  // in dev & qa we do not need finance/branch admin as a condition
  // TODO: check & refactor duplicated conditions for edit/delete
  const canEditExtra =
    !transaction.reference &&
    Auth.hasPermission([Permission.FINANCE_PROCESSING_CHARGES_EDIT]) &&
    (isQA ||
      Auth.hasPermissionGroup(PermissionGroup.FINANCE_ADMIN) ||
      Auth.hasPermissionGroup(PermissionGroup.BRANCH_ADMIN))

  return (
    <ContentContainer data-cy={testingId.paymentProcessingTransactionDetail.screen}>
      <TransactionDetailHeader
        {...{ calendarGuid, periodGuid, contractModel: paymentModel, contractModelGuid, period, transaction }}
      />
      <Alert
        severity="info"
        title="Please note"
        text="Any holiday rate shown below are of an estimated value, actual rate may differ slightly and is subject to change."
        sx={{ mt: 2 }}
      />
      <TransactionDetailTabBar {...{ tab, setTab, filteredVisits, filteredCharges }} />
      <TabPanel value={tab} index={TransactionDetailType.VISIT}>
        <TransactionDetailItemsFilter
          {...{
            resetAll: selectableVisitsDictionary.resetAll,
            setItemFilter,
            itemFilter,
            tab
          }}
        />
        <Divider color="divider" />
        <TransactionDetailItemsTable
          {...{
            selectableVisitsDictionary,
            itemsLength: visits.length,
            filteredItems: filteredVisits,
            transaction,
            periodGuid,
            onVisitsCredited,
            contractGuid: contractModelGuid
          }}
        />
      </TabPanel>
      <TabPanel value={tab} index={TransactionDetailType.EXTRA}>
        <TransactionDetailChargesFilter
          {...{
            transaction,
            period,
            resetAll: selectableChargesDictionary.resetAll,
            resetDeletableAll: deletableChargesDictionary.resetAll,
            contract,
            chargeFilter,
            setChargeFilter,
            tab,
            canEditExtra
          }}
        />
        <Divider color="divider" />
        <TransactionDetailChargesTable
          {...{
            contract,
            charges,
            period,
            filteredCharges,
            transaction,
            onExtrasCredited,
            selectableChargesDictionary,
            deletableChargesDictionary,
            canEditExtra
          }}
        />
      </TabPanel>
    </ContentContainer>
  )
}

const TransactionDetail = () => {
  const { calendarGuid, periodGuid, contractGuid, transactionGuid } = useParams()
  if (!calendarGuid || !periodGuid || !contractGuid || !transactionGuid) return null
  return <TransactionDetailComponent {...{ calendarGuid, periodGuid, contractGuid, transactionGuid }} />
}

export default memo(TransactionDetail)
