import React from "react"
import { Header, ContentContainer } from "components"
import OperatorProfile from "./OperatorProfile"
import { Navigate, Route } from "react-router-dom"
import SentryRoutes from "components/Routes"

const OperatorCreate: React.FC = () => {
  return (
    <>
      <ContentContainer>
        <Header title={"Create a new operator"} />
        <SentryRoutes>
          <Route path="/" element={<Navigate to="/admin/operators/new/personal-details" replace />} />
          <Route path=":section" element={<OperatorProfile mode="create" />} />
        </SentryRoutes>
      </ContentContainer>
    </>
  )
}

export default OperatorCreate
