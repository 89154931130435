import Box from "@mui/material/Box"
import { ContentContainer, DataLoading, StickyBox } from "components"
import { Form, FormRenderProps } from "react-final-form"
import React, { memo } from "react"
import { RateEditorStandard } from "./components/RateEditorStandard"
import { FormSectionHeader, Button, CancelButton } from "components/Form"
import { ContractModelPageProps } from "./types"
import Typography from "@mui/material/Typography"
import { FORM_ERROR } from "final-form"
import { red } from "@mui/material/colors"
import { validateRates } from "./validators/validateRates"
import { ContractModelType } from "constants/modelTypes"
import {
  useContractServiceBandListQuery,
  useContractServiceBandRateListQuery,
  useContractServiceDetailQuery
} from "data/finance/contractModel/queries"
import { reduceStandardRates } from "./handlers/reduceStandardRates"
import { useContractServiceBandRatePatchMutation } from "data/finance/contractModel/mutations"
import testingId from "constants/testingId"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { RatesTitle } from "./components/RatesTitle"
import arrayMutators from "final-form-arrays"
import { RateEditorEnhanced } from "./components/RateEditorEnhanced"
import { reduceEnhancedRates } from "./handlers/reduceEnhancedRates"
import { useGetRateDayTypeOptions } from "./helpers/useGetRateDayTypeOptions"
import { useParams } from "react-router-dom"
import { ContractModel, RateEditorPayload } from "data/finance/contractModel/types"

interface ComponentProps {
  contractModel: ContractModel
  modelType: ContractModelType
  serviceGuid: string
  bandGuid: string
  isInvoicePaymentService?: boolean // invoice model only
}

const RatesFormComponent: React.FC<ComponentProps> = ({
  contractModel,
  serviceGuid,
  bandGuid,
  modelType,
  isInvoicePaymentService
}) => {
  const { data: service } = useContractServiceDetailQuery({
    modelType,
    contractGuid: contractModel.guid,
    serviceGuid
  })
  const { data: bands } = useContractServiceBandListQuery({
    modelType,
    contractGuid: contractModel.guid,
    serviceGuid
  })
  const band = bands?.find((item) => item.guid === bandGuid)

  const { data: rates } = useContractServiceBandRateListQuery({
    modelType,
    contractGuid: contractModel.guid,
    serviceGuid,
    bandGuid
  })

  const { mutateAsync: patchRates } = useContractServiceBandRatePatchMutation({
    modelType,
    contractGuid: contractModel.guid,
    serviceGuid,
    bandGuid
  })

  const { dayTypeOptions, holidayTiers, isLoading } = useGetRateDayTypeOptions()

  if (isLoading) return <DataLoading /> // prevent warnings in the console
  if (!service || !band) return null

  const onSubmit = (values: RateEditorPayload) => {
    patchRates(values)
  }

  const basicRates = reduceStandardRates(rates)
  const enhancedRates = reduceEnhancedRates(rates)

  const initialValues = {
    basicRates,
    enhancedRates
  }

  return (
    <ContentContainer>
      <RatesTitle {...{ modelType, contractModel, service, band, isInvoicePaymentService }} />
      <RequestLoaderWrapper my={3}>
        <FormSectionHeader title="Day rates" />
        <Form
          {...{
            onSubmit,
            initialValues,
            validate: validateRates,
            mutators: { ...arrayMutators }
          }}
        >
          {({
            handleSubmit,
            pristine,
            errors,
            submitting,
            values,
            form: _form
          }: FormRenderProps<RateEditorPayload>) => (
            <form onSubmit={handleSubmit}>
              {!pristine && errors !== undefined && errors[FORM_ERROR] && (
                <Box m={2} mx={0} p={2} bgcolor={red[50]} data-cy={testingId.contractModel.rates.errorBox}>
                  {errors[FORM_ERROR].map((item: string, key: number) => (
                    <Typography key={key}>{item}</Typography>
                  ))}
                </Box>
              )}
              <RateEditorStandard {...{ service, values, dayTypeOptions }} />
              <FormSectionHeader title="Enhanced rates" />
              <RateEditorEnhanced {...{ service, holidayTiers, values, dayTypeOptions, form: _form }} />
              <StickyBox>
                <CancelButton disabled={submitting} pristine={pristine} />
                <Button
                  type="submit"
                  fullWidth={false}
                  disabled={pristine || submitting}
                  sx={(theme) => ({
                    margin: theme.spacing(0, 0, 0, 3)
                  })}
                >
                  Save
                </Button>
              </StickyBox>
            </form>
          )}
        </Form>
      </RequestLoaderWrapper>
    </ContentContainer>
  )
}

interface Props extends ContractModelPageProps {
  isInvoicePaymentService?: boolean // invoice model only
}

const RatesForm: React.FC<Props> = ({ contractModel, modelType, isInvoicePaymentService }) => {
  const { serviceId, bandId } = useParams()
  if (!serviceId || !bandId) return null

  return (
    <RatesFormComponent
      {...{ contractModel, modelType, serviceGuid: serviceId, bandGuid: bandId, isInvoicePaymentService }}
    />
  )
}

export default memo(RatesForm)
