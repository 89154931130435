import { useMemo } from "react"
import { useUIStore } from "stores"
import { useShallow } from "zustand/react/shallow"

interface Hook {
  showSuccessMessage: (message: string) => void
  showErrorMessage: (message: string) => void
  showWarningMessage: (message: string) => void
}
const useToast = (): Hook => {
  const { showWarningMessage, showErrorMessage, showSuccessMessage } = useUIStore(
    useShallow((state) => ({
      showSuccessMessage: state.showSuccessMessage,
      showErrorMessage: state.showErrorMessage,
      showWarningMessage: state.showWarningMessage
    }))
  )

  const response = useMemo(
    () => ({
      showSuccessMessage: (message: string) => showSuccessMessage(message),
      showErrorMessage: (message: string) => showErrorMessage(message),
      showWarningMessage: (message: string) => showWarningMessage(message)
    }),
    [showSuccessMessage, showWarningMessage, showErrorMessage]
  )

  return response
}

export default useToast
