import { Box } from "@mui/material"
import { Button, TextField } from "components/Form"
import testingId from "constants/testingId"
import {
  ContractModelServiceBand,
  ContractModelServiceRequestWithBands,
  ServiceBandRoundingTypeAlias
} from "data/finance/contractModel/types"
import { FormApi } from "final-form"
import validators, { composeUnrequiredValidators } from "lib/validators"
import { isNil } from "ramda"
import React, { memo, useCallback } from "react"
import { Field, FormSpy, FormSpyRenderProps } from "react-final-form"
import { FieldArrayRenderProps } from "react-final-form-arrays"

interface OwnProps {
  bandArrayProps: FieldArrayRenderProps<any, HTMLElement>
}

interface AddBandProps {
  values: ContractModelServiceRequestWithBands
  form: FormApi<ContractModelServiceRequestWithBands, Partial<ContractModelServiceRequestWithBands>>
}

const generatorValidators = composeUnrequiredValidators([
  validators.canNotBeZero,
  validators.mustBeNumber,
  validators.precision(0)
])

const addFormDisabled = ({ form, values }: AddBandProps) => {
  return (
    !values.add_bands ||
    !!form.getFieldState("add_bands")?.error ||
    !values.add_offset ||
    !!form.getFieldState("add_offset")?.error ||
    !values.add_interval ||
    !!form.getFieldState("add_interval")?.error
  )
}

export const BandGenerator: React.FC<OwnProps> = memo(({ bandArrayProps }) => {
  const onAddBands = useCallback(
    ({ values, form }: AddBandProps) => {
      if (!values.add_bands || !values.add_interval || !values.add_offset) return

      const fields = bandArrayProps.fields

      const bands = fields.value
      const lastBandCurrent = bands[bands.length - 1]
      const lastNewBandEnd = +lastBandCurrent.minimum_duration_minutes + +values.add_offset

      // do not set min/max values if last row is empty
      const willBeEmpty = isNil(lastBandCurrent.minimum_duration_minutes)

      const newBandsCount = values.add_bands
      for (let i = 0; i < newBandsCount; i++) {
        const isLast = i === newBandsCount - 1

        const newBand = willBeEmpty
          ? ({
              rounding_type_alias: ServiceBandRoundingTypeAlias.NONE
            } as ContractModelServiceBand)
          : ({
              rounding_type_alias: ServiceBandRoundingTypeAlias.NONE,
              minimum_duration_minutes: lastNewBandEnd + i * +values.add_interval,
              maximum_duration_minutes: isLast ? null : lastNewBandEnd + (i + 1) * +values.add_interval
            } as ContractModelServiceBand)

        fields.push(newBand)
      }

      if (!willBeEmpty) {
        // @ts-ignore // works fine but TS throws an error
        form.change(`bands[${bands.length - 1}]maximum_duration_minutes`, lastNewBandEnd)
      }

      form.change("add_bands", undefined)
      form.change("add_offset", undefined)
      form.change("add_interval", undefined)
    },
    [bandArrayProps]
  )

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values, form }: FormSpyRenderProps<ContractModelServiceRequestWithBands>) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px"
          }}
        >
          <Box
            sx={{
              maxWidth: "100px",
              marginRight: "10px"
            }}
          >
            <Field name="add_bands" label="Bands" component={TextField} validate={generatorValidators} />
          </Box>
          <Box
            sx={{
              maxWidth: "100px",
              marginRight: "10px"
            }}
          >
            <Field name="add_offset" label="Offset" component={TextField} validate={generatorValidators} />
          </Box>
          <Box
            sx={{
              maxWidth: "100px",
              marginRight: "10px"
            }}
          >
            <Field name="add_interval" label="Interval" component={TextField} validate={generatorValidators} />
          </Box>
          <Box>
            <Button
              {...{
                disabled: addFormDisabled({ form, values }),
                "data-cy": testingId.contractModel.services.addRowsButton,
                onClick: () => onAddBands({ values, form })
              }}
            >
              ADD NEW ROWS
            </Button>
          </Box>
        </Box>
      )}
    </FormSpy>
  )
})

BandGenerator.displayName = "BandGenerator"
