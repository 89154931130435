import { ContractModelType, INVOICE_CONTRACT_PUBLIC_ALIASES } from "constants/modelTypes"
import { DateTime } from "luxon"
import { mergeValidationErrorsLeft, validateContract, validateOverlappingPeriods } from "./contractValidations"
import validators, { composeValidators } from "lib/validators"
import { filter, isEmpty, isNil } from "ramda"
import { ValidationErrorsDefined } from "lib/types"
import { FormStage } from "constants/form"
import { getOtherUserContracts } from "helpers/contractHelpers"
import { ContractModel } from "data/finance/contractModel/types"
import { ApiClientContractPayloadContribution, ApiClientContractPayload, ContractRate } from "data/finance/client/types"

const validateStandingChargesFields = (items?: ContractRate[]): ValidationErrorsDefined[] => {
  const errors: ValidationErrorsDefined[] = []

  items?.forEach((item, key) => {
    errors[key] = {}

    if (item.delete) return

    errors[key].start = composeValidators([validators.required, validators.validDate])(item.start)
    errors[key].end = validators.validDate(item.end)
    errors[key].frequency_type_alias = validators.required(item.frequency_type_alias)
    errors[key].rate = validators.validateCommonNumber({ min: 0 })(item.rate)

    errors[key] = filter((errorKey) => !isNil(errorKey), errors[key])
  })

  return errors
}

const validateContributionsFields = (
  items?: ApiClientContractPayloadContribution[] | null
): ValidationErrorsDefined[] => {
  const errors: ValidationErrorsDefined[] = []

  items?.forEach((item, key) => {
    errors[key] = {}

    if (item.delete) return

    errors[key].start = composeValidators([validators.required, validators.validDate])(item.start)
    errors[key].end = validators.validDate(item.end)
    errors[key].rate = validators.validateCommonNumber()(item.rate)
    errors[key].user_contract_guid = validators.required(item.user_contract_guid)

    errors[key] = filter((errorKey) => !isNil(errorKey), errors[key])
  })

  return errors
}

interface FormValidationProps extends ApiClientContractPayload {
  no_end_date?: boolean
}
export const validateClientContract =
  <Contract extends { guid: string; start: DateTime; end?: DateTime | null; contract_guid?: string }>({
    clientContracts = [],
    invoiceModel,
    formStage
  }: {
    clientContracts?: Contract[]
    invoiceModel?: ContractModel
    formStage: FormStage
  }) =>
  (values: FormValidationProps): ValidationErrorsDefined => {
    let errors: ValidationErrorsDefined = {}

    // do not validate step one
    if (formStage === FormStage.CREATE) return errors

    if (invoiceModel && values.start) {
      // do not compare to other contract based on a different model
      const otherContracts = getOtherUserContracts(invoiceModel.guid, clientContracts)

      const contractErrors = validateContract({
        modelType: ContractModelType.INVOICE,
        values,
        contractModel: invoiceModel,
        existingContracts: otherContracts
      })

      if (!isEmpty(contractErrors)) {
        errors = contractErrors
      }
    }

    // rates
    errors.rates = mergeValidationErrorsLeft(
      validateStandingChargesFields(values.rates),
      validateOverlappingPeriods<ContractRate, FormValidationProps>({
        items: values.rates,
        values,
        label: "Rate",
        compareKey: "frequency_type_alias"
      })
    )

    // contributions
    const isPublic = INVOICE_CONTRACT_PUBLIC_ALIASES.includes(values.contract_type_alias)

    if (isPublic) {
      errors.contributions = mergeValidationErrorsLeft(
        validateContributionsFields(values.contributions),
        validateOverlappingPeriods<ApiClientContractPayloadContribution, FormValidationProps>({
          items: values.contributions || [],
          values,
          label: "Contribution",
          compareKey: "user_contract_guid"
        })
      )
    }

    return errors
  }
