import React, { FC } from "react"
import { SvgIcon, SvgIconProps } from "@mui/material"

const FallIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" {...props}>
    <path
      fill="#000"
      fillOpacity=".56"
      fillRule="evenodd"
      d="M5.572 2.323a2.006 2.006 0 0 1 .582 2.768 2.006 2.006 0 0 1-2.768.582 2.006 2.006 0 0 1-.582-2.768 2.006 2.006 0 0 1 2.768-.582ZM12.69 12.46l3.139 3.242-4.077 4.158H22v2H2v-2h7.873l-.498-.488 3.515-3.558-5.91-5.766-.397 1.89 2.847 1.858-1.092 1.675L4.4 12.902 5.4 7.345c.082-.126.13-.244.177-.362.047-.119.094-.237.176-.363.383-.586 1.046-.87 1.767-.877l1.886.037c1.61.095 3.321-.7 4.36-2.29l1.675 1.092a7.322 7.322 0 0 1-5.1 3.24l2.186 2.143 2.822-.666 6.282 4.097-1.093 1.675-5.026-3.278-2.822.666Z"
      clipRule="evenodd"
    />
  </SvgIcon>
)

export default FallIcon
