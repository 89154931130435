import React, { Dispatch, memo, SetStateAction } from "react"
import { Form, Field } from "react-final-form"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { FormSpyCustom, Select } from "components/Form"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { ClearFilterButton } from "components/ClearFilterButton"
import { StatusSelectField } from "components/Form/StatusSelectField"
import { useSchema } from "data/schema/useSchema"

export interface ListShiftCategoriesFilter {
  status?: string
  region?: number
}

interface OwnProps {
  filter: ListShiftCategoriesFilter
  setFilter: Dispatch<SetStateAction<ListShiftCategoriesFilter>>
}

export const ShiftCategoryListFilter: React.FC<OwnProps> = memo(({ filter, setFilter }) => {
  const { Region } = useSchema(["Region"])

  const onSubmit = (values: ListShiftCategoriesFilter) => {
    setFilter(values)
  }

  useFilterLocationChange({ filter })

  return (
    <Box m={3} mx={0}>
      <Form onSubmit={onSubmit} initialValues={filter}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={3}>
                <StatusSelectField />
              </Grid>
              <Grid item md={3}>
                <Field name="region" label="Region" component={Select} options={Region} />
              </Grid>
              <Grid item md={3}>
                <ClearFilterButton onClick={() => setFilter({})} disabled={Object.keys(filter).length === 0} />
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </Box>
  )
})

ShiftCategoryListFilter.displayName = "ShiftCategoryListFilter"
