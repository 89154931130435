import { GENERAL_ERROR_MESSAGE } from "api/instance"
import { PATH } from "constants/path"
import { usePaymentModelCreateMutation, usePaymentModelUpdateMutation } from "data/finance/contractModel/mutations"
import { ContractModel, ContractModelRequest } from "data/finance/contractModel/types"
import { FORM_ERROR } from "final-form"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useUIStore } from "stores"

export const usePaymentModelSubmitHandler = (): ((
  contractModel: ContractModel | undefined,
  payload: ContractModelRequest
) => Promise<
  | {
      "FINAL_FORM/form-error": string
    }
  | undefined
>) => {
  const { mutateAsync: updatePaymentContract } = usePaymentModelUpdateMutation()
  const { mutateAsync: createPaymentContract } = usePaymentModelCreateMutation()
  const navigate = useNavigate()
  const showErrorMessage = useUIStore((state) => state.showErrorMessage)

  return useCallback(
    async (contractModel: ContractModel | undefined, payload: ContractModelRequest) => {
      if (contractModel?.guid) {
        try {
          await updatePaymentContract(payload)
        } catch (apiError: any) {
          showErrorMessage(GENERAL_ERROR_MESSAGE, { apiError })

          const errorMessage: string | undefined = apiError?.response?.data?.exception?.message
          if (errorMessage && errorMessage.includes("EXCEEDS_CHILD")) {
            return {
              [FORM_ERROR]:
                "Something (service/rule/calendar) using this invoice model starts sooner or ends later than this model's start/end date. Change this model's start/end date."
            }
          }
        }
      } else {
        try {
          const response = await createPaymentContract(payload)
          if (response?.guid) {
            navigate(`${PATH.FINANCE.PAYMENT_MODELS}/${response.guid}`)
          }
        } catch (apiError: any) {
          showErrorMessage(GENERAL_ERROR_MESSAGE, { apiError })
        }
      }
    },
    [updatePaymentContract, createPaymentContract, showErrorMessage, navigate]
  )
}
