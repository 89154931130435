import { FinanceApi } from "api"
import { useMutation, UseMutationResult } from "react-query"
import { useUIStore } from "stores"

interface ImportRequest {
  fileAsString: string
  import_type: string
}

export const useImportFileUploadMutation = (): UseMutationResult<void, Error, ImportRequest> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)

  return useMutation(
    async (payload: ImportRequest): Promise<void> => {
      return FinanceApi.post(`/finance/import/${payload.import_type}`, payload.fileAsString, {
        headers: {
          "Content-Type": "text/csv"
        }
      })
    },
    {
      onSuccess: () => {
        showSuccessMessage("Your file has been successfully uploaded.")
      }
    }
  )
}
