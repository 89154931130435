import React from "react"
import { Field, Form } from "react-final-form"
import { ContentContainer, Header, Link, StickyBox } from "components"
import { Button, FormSectionHeader, TextField } from "components/Form"
import { Box, Grid } from "@mui/material"
import { useUpdateHolidayTierMutation, useCreateHolidayTierMutation } from "data/finance/holidayTier/mutations"
import { useHolidayTierListQuery, useHolidayTierQuery } from "data/finance/holidayTier/queries"
import { HolidayTierRequest } from "data/finance/holidayTier/types"
import validators from "lib/validators"
import { useParams } from "react-router-dom"

const HolidayTierDetail: React.FC = () => {
  const { id } = useParams()
  const { data: holidayTiers } = useHolidayTierListQuery()
  const { data: holidayTier } = useHolidayTierQuery({ guid: id })

  const { mutateAsync: updateHolidayTier } = useUpdateHolidayTierMutation()
  const { mutateAsync: createHolidayTier } = useCreateHolidayTierMutation()

  const initialValues = holidayTier ? { title: holidayTier.title } : {}

  // TODO: add validation, can not have 2 tiers with the same title: https://ceracare.atlassian.net/browse/FAM-1781
  const onSubmit = (values: HolidayTierRequest) => {
    if (id) {
      updateHolidayTier({ guid: id, values })
    } else {
      createHolidayTier({ values })
    }
  }

  const validate = (values: HolidayTierRequest) => {
    const sameNameTier = holidayTiers?.find((tier) => id !== tier.guid && tier.title === values.title)

    return sameNameTier ? { title: "Holiday Tier title must be unique" } : undefined
  }

  return (
    <ContentContainer>
      <Header
        title={
          <>
            <Link to="/admin/finance/holiday-tiers">Holiday Tiers</Link> /{" "}
            {holidayTier ? holidayTier.title : "Add new holiday tier"}
          </>
        }
      />
      <Box m={3} mx={0}>
        <Form {...{ onSubmit, initialValues, validate }}>
          {({ handleSubmit, submitting, pristine, form }) => (
            <form onSubmit={handleSubmit}>
              <FormSectionHeader title="Holiday tier details" />
              <Grid container spacing={8}>
                <Grid item md={6}>
                  <Field name="title" label="Title" component={TextField} validate={validators.maxLength(64)} />
                </Grid>
              </Grid>
              <StickyBox>
                <Button
                  variant="text"
                  fullWidth={false}
                  disabled={submitting || pristine}
                  onClick={() => {
                    form.reset()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  sx={(theme) => ({
                    margin: theme.spacing(0, 0, 0, 3)
                  })}
                  type="submit"
                  fullWidth={false}
                  disabled={submitting || pristine}
                >
                  Save changes
                </Button>
              </StickyBox>
            </form>
          )}
        </Form>
      </Box>
    </ContentContainer>
  )
}

export default React.memo(HolidayTierDetail)
