import { FormSectionHeader } from "components/Form"
import { ContractModelType } from "constants/modelTypes"
import { FormApi } from "final-form"
import React, { memo } from "react"
import { sortUpliftItemsByIndex } from "../../helpers/sortUpliftItemsByIndex"
import { getPickedUpliftModelGuids } from "../../helpers/upliftFormGetters"
import { RateUpliftForm } from "../../types"
import { RateUpliftHeaderWithTooltip } from "./RateUpliftHeaderWithTooltip"
import { RateUpliftTwoModel } from "./RateUpliftTwoModel"
import { IndexedContractModel } from "data/finance/contractModel/types"

interface OwnProps {
  values: RateUpliftForm
  form: FormApi<RateUpliftForm, Partial<RateUpliftForm>>
  contracts: IndexedContractModel[]
  modelType: ContractModelType
}

export const RateUpliftTwo: React.FC<OwnProps> = memo(({ values, form, contracts, modelType }) => {
  RateUpliftTwo.displayName = "RateUpliftTwo"

  const contractModelGuids = getPickedUpliftModelGuids(values)
  const sortedContracts = sortUpliftItemsByIndex({ items: contracts, guids: contractModelGuids })

  return (
    <>
      <FormSectionHeader
        title={
          <RateUpliftHeaderWithTooltip
            {...{
              label: "Service",
              title: "Rules for selecting Service:",
              titleBody: ["Service must not have an end date", "Service must start before the uplift"]
            }}
          />
        }
      />
      {sortedContracts.map((contract) => {
        return <RateUpliftTwoModel {...{ values, form, contract, modelType }} key={contract.guid} />
      })}
    </>
  )
})
