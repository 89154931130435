import React, { memo } from "react"

import { Logo, Link } from "components"

export const MainNavLogo: React.FC = memo(() => {
  MainNavLogo.displayName = "MainNavLogo"

  return (
    <Link to="/" id="cera-logo">
      <Logo
        sx={(theme) => ({
          alignSelf: "center",
          display: "block",
          marginBottom: theme.spacing(1),
          marginTop: theme.spacing(1),
          marginLeft: theme.spacing(4)
        })}
        invert
      />
    </Link>
  )
})
