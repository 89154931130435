import { Link } from "components"
import React from "react"

export const additionalOwnerLink = ({
  owner_guid,
  owner_name,
  owner_type,
  isInvoiceModel = true
}: {
  owner_type: string
  owner_guid: string
  owner_name?: string
  isInvoiceModel?: boolean
}): JSX.Element | string => {
  switch (owner_type) {
    case "User":
      return (
        <Link to={`/${isInvoiceModel ? "clients" : "carers"}/${owner_guid}/profile/personal-details`}>
          {owner_name}
        </Link>
      )
    case "Shift":
      return <Link to={`/shift-categories/${owner_guid}/detail`}>{owner_name}</Link>
    case "Unit":
      return <Link to={`/units/${owner_guid}/edit`}>{owner_name}</Link>
    default:
      return owner_name ?? ""
  }
}
