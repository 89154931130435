import { Box, Grid } from "@mui/material"
import { FormSpyCustom, Select } from "components/Form"
import { DateTime } from "luxon"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { ClearFilterButton } from "components/ClearFilterButton"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { formatFilterValues } from "lib/helpers/formatFilterValues"
import { Pagination } from "lib/types"
import { useSchema } from "data/schema/useSchema"
import { DateField } from "components/Form/DateField"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"

export interface CarerAccrualListFilterValues {
  start?: DateTime
  end?: DateTime
  type_alias?: string
  page?: number
  page_size?: number
}

interface OwnProps {
  filter: CarerAccrualListFilterValues
  setFilter: Dispatch<SetStateAction<CarerAccrualListFilterValues>>
  pagination: Pagination
  resetPagination: () => void
}

export const CarerAccrualListFilter: React.FC<OwnProps> = memo(({ filter, setFilter, pagination, resetPagination }) => {
  const { Accrual = [] } = useSchema(["Accrual"])
  const accrualOptions = [{ title: "Any", value: "" }, ...Accrual]

  const onSubmit = (values: CarerAccrualListFilterValues) => {
    const formattedValues = formatFilterValues<CarerAccrualListFilterValues>({
      values,
      startName: "start",
      endName: "end"
    })
    setFilter(formattedValues)
    resetPagination()
  }

  const onClearClick = () => {
    setFilter({})
    resetPagination()
  }

  useFilterLocationChange({ filter, pagination })

  return (
    <Box mb={3}>
      <Form
        {...{ onSubmit, initialValues: filter, validate: validateFilterPeriodDates({ start: "start", end: "end" }) }}
      >
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormSpyCustom {...{ handleSubmit }} />
              <Grid container spacing={2} alignItems="center">
                <Grid item md={3}>
                  <Field name="type_alias" label="Accrual Type" component={Select} options={accrualOptions} />
                </Grid>
                <Grid item md={3}>
                  <DateField name="start" label="Start" />
                </Grid>
                <Grid item md={3}>
                  <DateField name="end" label="End" />
                </Grid>
                <Grid item md={3}>
                  <ClearFilterButton onClick={onClearClick} disabled={Object.keys(filter).length === 0} />
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Form>
    </Box>
  )
})

CarerAccrualListFilter.displayName = "CarerAccrualListFilter"
