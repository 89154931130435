import { Box, Tooltip, Typography } from "@mui/material"
import React, { memo } from "react"
import { Info } from "@mui/icons-material"

interface OwnProps {
  label: string
  title: string
  titleBody: string[]
}

export const RateUpliftHeaderWithTooltip: React.FC<OwnProps> = memo(({ label, title, titleBody }) => {
  RateUpliftHeaderWithTooltip.displayName = "RateUpliftHeaderWithTooltip"

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <Typography variant="h6">{label}</Typography>
      <Tooltip
        arrow={true}
        placement="right"
        title={
          <Box>
            <Typography
              sx={{
                fontWeight: "bold"
              }}
            >
              {title}
            </Typography>
            {titleBody.map((tb) => (
              <Typography key={tb}>&bull; {tb}</Typography>
            ))}
          </Box>
        }
      >
        <Info
          sx={{
            marginLeft: 2,
            color: "grey.400"
          }}
        />
      </Tooltip>
    </Box>
  )
})
