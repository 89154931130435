import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React, { MouseEvent, ReactNode, useState } from "react"
import { ContractModel, InvoiceModelRuleGroup } from "data/finance/contractModel/types"
import { DateTimeFormat } from "lib/datetime"
import testingId from "constants/testingId"
import { Button } from "components/Form"
import { RuleGroupCopyModal } from "./RuleGroupCopyModal"
import { useNavigate } from "react-router-dom"

interface Props {
  ruleGroups: InvoiceModelRuleGroup[]
  isPaymentRule?: boolean
  contractModel: ContractModel
}

const RuleGroups: React.FC<Props> = ({ ruleGroups, isPaymentRule = false, contractModel }) => {
  const navigate = useNavigate()
  const handleRowClick = (ruleGroup: InvoiceModelRuleGroup) => () => {
    navigate(ruleGroup.guid)
  }

  const [modal, setModal] = useState<ReactNode>(null)
  const destroyModal = () => setModal(null)
  const handleCopyClick = (ruleGroup: InvoiceModelRuleGroup) => (e: MouseEvent) => {
    e.stopPropagation()
    setModal(<RuleGroupCopyModal {...{ ruleGroup, ruleGroups, destroyModal, isPaymentRule, contractModel }} />)
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" data-cy={testingId.invoiceModel.rules.tableHeader}>
              Group name
            </TableCell>
            <TableCell align="left" data-cy={testingId.invoiceModel.rules.tableHeader}>
              Starts on
            </TableCell>
            <TableCell align="left" data-cy={testingId.invoiceModel.rules.tableHeader}>
              Ends before
            </TableCell>
            <TableCell align="left" data-cy={testingId.invoiceModel.rules.tableHeader}>
              Status
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {ruleGroups.map((ruleGroup: InvoiceModelRuleGroup) => (
            <TableRow
              key={ruleGroup.guid}
              hover
              sx={{
                cursor: "pointer"
              }}
              onClick={handleRowClick(ruleGroup)}
              data-cy={testingId.invoiceModel.rules.tableRow}
            >
              <TableCell data-cy={testingId.invoiceModel.rules.tableCell}>{ruleGroup.title}</TableCell>
              <TableCell data-cy={testingId.invoiceModel.rules.tableCell}>
                {ruleGroup.start.toFormat(DateTimeFormat.DATE)}
              </TableCell>
              <TableCell data-cy={testingId.invoiceModel.rules.tableCell}>
                {ruleGroup.end ? ruleGroup.end.toFormat(DateTimeFormat.DATE) : "/ /"}
              </TableCell>
              <TableCell data-cy={testingId.invoiceModel.rules.tableCell}>{ruleGroup.status}</TableCell>
              <TableCell data-cy={testingId.tableCell}>
                <Button
                  {...{ variant: "text", color: "primary", fullWidth: false, onClick: handleCopyClick(ruleGroup) }}
                >
                  Copy
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {modal ? modal : null}
    </>
  )
}

export default React.memo(RuleGroups)
