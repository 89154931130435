import { isNil, sort } from "ramda"

export const sortUpliftItemsByIndex = <T extends { index?: number; guid: string }>({
  items = [],
  guids
}: {
  items?: T[]
  guids: string[]
}): T[] => {
  const pickedItems = items.filter((item) => guids.includes(item.guid))

  return sort<T>((itemA, itemB) => (!isNil(itemA.index) && !isNil(itemB.index) ? itemA.index - itemB.index : -1))(
    pickedItems
  )
}
