import React, { useCallback, useState } from "react"
import { Typography, TextField, Button, styled, SxProps, Alert, IconButton, FormGroup } from "@mui/material"
import { unstable_styleFunctionSx, StyleFunction } from "@mui/system"
import { useForm } from "react-hook-form"
import { useInvalidateAllSchemas } from "data/schema/useInvalidateAllSchemas"
import { useNavigate } from "react-router-dom"
import { statusCodes } from "constants/statusCodes"
import type { AuthenticationRequest } from "api/core"
import { Link } from "components"
import validators from "lib/validators"
import Container from "./Container"
import useMutationLogin from "./useMutationLogin"
import { RemoveRedEye } from "@mui/icons-material"
import { useAuthStore } from "stores"
import useResetBranchData from "lib/hooks/branchInfo/useResetBranchData"
import { AUTH_TYPE_LEGACY } from "lib/Auth"

const Form = styled("form")<{ sx?: SxProps }>({
  display: "block",
  textAlign: "right",
  ...(unstable_styleFunctionSx as StyleFunction<{ sx?: SxProps }>)
})

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting }
  } = useForm<AuthenticationRequest>({ mode: "onChange" })
  const setLogin = useAuthStore((state) => state.logIn)
  const navigate = useNavigate()
  const { mutate, isError } = useMutationLogin()
  const [error, setError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { resetBranchQueryData } = useResetBranchData()

  const invalidateAllSchemas = useInvalidateAllSchemas()

  const onSubmit = (values: AuthenticationRequest) => {
    invalidateAllSchemas()
    resetBranchQueryData()
    mutate(values, {
      onSuccess: (response) => {
        setLogin({
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
          authType: AUTH_TYPE_LEGACY
        })
        navigate("/")
      },
      onError: (err) => {
        if ((err as { status: number }).status === statusCodes.PASSWORD_RESET_REQUIRED) {
          navigate("/password-reset")
        } else {
          setError(true)
        }
      }
    })
  }

  const onClickShowPassword = useCallback(() => setShowPassword(!showPassword), [setShowPassword, showPassword])

  return (
    <Container title="Welcome to DCP">
      <Form onSubmit={handleSubmit(onSubmit)} sx={{ flex: 1 }} onFocus={() => setError(false)}>
        <Typography color="textSecondary" textAlign="left" mb={4}>
          Welcome back! Sign in with your email and password to continue.
        </Typography>
        <TextField
          {...register("email", {
            required: "The field is required",
            validate: validators.email
          })}
          variant="outlined"
          label="Email address"
          fullWidth
          helperText={errors?.email?.message}
          error={!!errors.email || error}
          sx={{ mb: 4 }}
          type="email"
        />
        <FormGroup sx={{ position: "relative" }}>
          <TextField
            {...register("password", {
              required: "The field is required"
            })}
            helperText={errors?.password?.message}
            type={showPassword ? "text" : "password"}
            variant="outlined"
            label="Password"
            fullWidth
            error={!!errors.password || error}
          />
          <IconButton
            sx={{
              position: "absolute",
              top: "calc(50% - 20px)",
              right: 4,
              "&:hover": {
                background: "none"
              }
            }}
            onClick={onClickShowPassword}
          >
            <RemoveRedEye />
          </IconButton>
        </FormGroup>
        <Link to="/password-reset" sx={{ display: "inline-block", mt: 0.5, mb: 4, fontWeight: 500 }}>
          Forgotten password?
        </Link>
        <Button
          variant="contained"
          size="large"
          type="submit"
          fullWidth
          disabled={!isValid || isSubmitting}
          sx={{ mb: 4 }}
        >
          Sign in with email
        </Button>
        {isError && (
          <Alert sx={{ textAlign: "left" }} severity="error">
            We were unable to sign you in, this email address and password combination doesn’t match our records. Are
            you sure you have an account?
          </Alert>
        )}
      </Form>
    </Container>
  )
}

export default Login
