import { Box } from "@mui/material"
import { Button } from "components/Form"
import testingId from "constants/testingId"
import React, { memo, ReactNode, useCallback, useState } from "react"
import { AppointmentSearchCreditModal } from "./AppointmentSearchCreditModal"

interface OwnProps {
  isInvoiceModel: boolean
  refetch: () => void
}

export const AppointmentSearchBulkCreditButton: React.FC<OwnProps> = memo(({ isInvoiceModel, refetch }) => {
  const [modal, setModal] = useState<ReactNode>(null)

  const destroyModal = useCallback(() => {
    refetch()
    setModal(null)
  }, [setModal, refetch])

  const onClick = useCallback(() => {
    setModal(<AppointmentSearchCreditModal {...{ isInvoiceModel, destroyModal }} />)
  }, [setModal, isInvoiceModel, destroyModal])

  return (
    <Box margin={1}>
      <Button {...{ "data-cy": testingId.appointmentSearch.bulkCreditButton, fullWidth: false, onClick }}>
        Bulk Credit
      </Button>
      {modal ? modal : null}
    </Box>
  )
})

AppointmentSearchBulkCreditButton.displayName = "AppointmentSearchBulkCreditButton"
