import React from "react"
import { Box, FormGroup, Tooltip, Typography } from "@mui/material"
import { Checkbox, FormSectionHeader } from "./Form"
import { Field, FormSpy } from "react-final-form"
import { Info } from "@mui/icons-material"
import testingId from "constants/testingId"

interface Props {
  title: string
  fieldNames: string[]
}

const packages = [
  {
    label: "Reablement",
    tooltip: "A short-term program to help the Service User recover from a specific condition."
  },
  {
    label: "End of Life",
    tooltip: `The Service User is expected to have only short-term care needs due to their health condition being classified as "end of life."`
  },
  {
    label: "Domiciliary",
    tooltip:
      "Standard care services provided in the Service User's own home, including help with food, medications, household tasks, and mobility."
  },
  {
    label: "Extra Care",
    tooltip: "Domiciliary care provided within a specialised housing scheme consisting of multiple units."
  },
  {
    label: "Short-term live-in",
    tooltip:
      "A live-in carer assigned to the Service User's home for up to 6 months. If care needs change, this may be updated to Permanent live-in care."
  },
  {
    label: "Permanent live-in",
    tooltip: "A permanent live-in Carer residing in the Service User's home."
  },
  {
    label: "Nurse Led",
    tooltip:
      "Care that requires an appropriately registered branch and oversight from a Nurse. For more details, refer to the Nurse-led care team SLA and the list of registered branches."
  },
  {
    label: "LD Supported Living",
    tooltip: "Care for Service Users diagnosed with a learning disability or autism."
  },
  {
    label: "MH Support Services",
    tooltip: "Care for Service Users with a diagnosed mental health condition."
  },
  {
    label: "D&A Support Services",
    tooltip: "Care for Service Users diagnosed with addiction to drugs, alcohol, or other substances."
  }
] as const

const CarePackagesFormGroup = ({ title, fieldNames }: Props) => {
  return (
    <FormGroup>
      <FormSectionHeader title={title} />
      <FormSpy subscription={{ errors: true, submitFailed: true }}>
        {({ errors, submitFailed }) => {
          const showError = !!errors?.["care_packages.nurse_led"] && submitFailed
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Typography
                sx={{ flex: "1 1 100%", mt: 1, mb: 4 }}
                variant="body1"
                color={showError ? "error" : "textPrimary"}
              >
                At least one care package option is required.
              </Typography>
              {packages.map(({ label, tooltip }, index) => (
                <Box
                  key={label}
                  sx={{ display: "flex", flexDirection: "row", alignItems: "center", flex: "0 0 33.3%", mb: 2 }}
                >
                  <Field
                    name={fieldNames[index]}
                    type="checkbox"
                    label={label}
                    component={Checkbox}
                    data-cy={testingId.checkboxField}
                  />
                  <Tooltip title={tooltip} arrow placement="top">
                    <Info sx={{ opacity: 0.56 }} />
                  </Tooltip>
                </Box>
              ))}
            </Box>
          )
        }}
      </FormSpy>
    </FormGroup>
  )
}

export default CarePackagesFormGroup
