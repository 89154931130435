import { ContentContainer, Header } from "components"
import { Unit } from "data/finance/unit/types"
import React, { memo } from "react"
import { UnitShiftCategoryProfile } from "./components/UnitShiftCategoryProfile"

export const UnitShiftCategoryCreate: React.FC<{ unit: Unit }> = memo(({ unit }) => {
  return (
    <ContentContainer>
      <Header title={`${unit.name} : Shift Category / New`} />
      <UnitShiftCategoryProfile {...{ mode: "create", unitGuid: unit.guid }} />
    </ContentContainer>
  )
})

UnitShiftCategoryCreate.displayName = "UnitShiftCategoryCreate"
