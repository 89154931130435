import { Box, Grid, Typography } from "@mui/material"
import testingId from "constants/testingId"
import { InvoiceTransaction } from "data/finance/invoiceProcessing/types"
import { moneyFormat } from "lib/utils"
import React, { memo } from "react"

type InvoiceTransactionsMoney = {
  pending: number
  ready: number
  credited: number
  invoiced: number
  total: number
}

const getTransactionsMoney = (transactions?: InvoiceTransaction[]) => {
  const result: InvoiceTransactionsMoney = {
    pending: 0,
    ready: 0,
    credited: 0,
    invoiced: 0,
    total: 0
  }

  transactions?.forEach((item) => {
    switch (item.status_type_alias) {
      case "PENDING":
        result.pending += item.total
        break
      case "READY":
        result.ready += item.total
        break
      case "CREDITED":
        result.credited += item.total
        break
      case "COMPLETE":
        result.invoiced += item.total
        break
    }

    result.total += item.total
  })

  return result
}

interface Props {
  transactions?: InvoiceTransaction[]
}

const TransactionListMoney: React.FC<Props> = ({ transactions }) => {
  const money = getTransactionsMoney(transactions)

  return (
    <Box m={3} mx={0}>
      <Grid
        container
        spacing={1}
        alignItems="center"
        sx={(theme) => ({
          borderBottom: "1px solid",
          borderColor: theme.palette.primary.main
        })}
      >
        {[
          ["Pending:", moneyFormat.format(money.pending)],
          ["Ready:", moneyFormat.format(money.ready)],
          ["Invoiced:", moneyFormat.format(money.invoiced)],
          ["Credited:", moneyFormat.format(money.credited)],
          ["Current total value:", moneyFormat.format(money.total)]
        ].map(([label, value], index) => (
          <Grid
            item
            md={index === 4 ? 3 : 2}
            key={index}
            data-cy={testingId.invoiceProcessingTransactionList.invoiceModelStatsItem}
          >
            <Typography variant="h6" component="span">
              {label}
            </Typography>{" "}
            <Typography variant="h6" color="primary" component="span">
              {value}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default memo(TransactionListMoney)
