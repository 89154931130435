import { SelectOption } from "components/Form/Select"
import validators from "lib/validators"
import { filter, keys, pipe } from "ramda"
import { useCallback } from "react"
import { ContractModelServiceUplift, RateUpliftForm, RateUpliftFormModel, RateUpliftFormService } from "../types"
import { ContractModelEnhancedRate, ContractModelEnhancedRateModifierType } from "data/finance/contractModel/types"

export const getPickedUpliftModels = (values: RateUpliftForm): RateUpliftFormModel[] => {
  return Object.values(values.models || {}).filter((model) => model.picked)
}

export const getPickedUpliftModelGuids = (values: RateUpliftForm): string[] =>
  pipe(
    filter<RateUpliftFormModel>((model) => model.picked),
    keys
  )(values.models) as string[]

export const getUpliftModel = ({
  values,
  contractGuid
}: {
  values: RateUpliftForm
  contractGuid: string
}): RateUpliftFormModel | undefined => {
  return values.models[contractGuid]
}

export const getUpliftModelServiceItems = ({
  values,
  contractGuid
}: {
  values: RateUpliftForm
  contractGuid: string
}): ContractModelServiceUplift[] | undefined => {
  return values.models[contractGuid]?.serviceItems
}

export const getPickedServicesFromUpliftModel = ({
  values,
  contractGuid
}: {
  values: RateUpliftForm
  contractGuid: string
}): {
  [key: string]: RateUpliftFormService
} => {
  return filter((service: RateUpliftFormService) => service.picked)(values.models[contractGuid]?.services || {})
}

export const getServiceFromUplift = ({
  values,
  contractGuid,
  serviceGuid
}: {
  values: RateUpliftForm
  contractGuid: string
  serviceGuid: string
}): RateUpliftFormService | undefined => {
  const model = getUpliftModel({ values, contractGuid })
  return (model?.services || {})[serviceGuid]
}

export const getServicesFromUpliftModel = (model: RateUpliftFormModel): RateUpliftFormService[] => {
  return Object.values(model.services || {}).filter((service) => service.picked)
}

export const getUpliftBasicRate = <T extends { guid: string }>({
  rates,
  enhancedRate
}: {
  rates: T[]
  enhancedRate: ContractModelEnhancedRate
}): T => {
  return rates.find((rate) => rate.guid === enhancedRate.standard_rate_guid) || ({} as T)
}

export const getUpliftEnhancedRatePrice = ({
  enhancedRate,
  standardRate
}: {
  enhancedRate: ContractModelEnhancedRate
  standardRate?: { rate: string } // TODO: use ContractModelRate and RateUpliftFormServiceBandRate, fails because of day_type and day_type_alias
}): string => {
  if (!standardRate?.rate || !enhancedRate.modifier_type || validators.mustBeNumber(enhancedRate.modifier)) return ""

  const result =
    enhancedRate.modifier_type === ContractModelEnhancedRateModifierType.ADD
      ? +standardRate.rate + +enhancedRate.modifier
      : +standardRate.rate * +enhancedRate.modifier

  return `£ ${result.toFixed(2)}`
}

export const getUpliftNewEnhancedRate = ({
  formRateNewValue,
  enhancedRate
}: {
  formRateNewValue: number
  enhancedRate: ContractModelEnhancedRate
}): string => {
  if (!formRateNewValue) return ""

  const isAdd = enhancedRate.modifier_type === ContractModelEnhancedRateModifierType.ADD

  const res = isAdd ? +formRateNewValue + enhancedRate.modifier : +formRateNewValue * enhancedRate.modifier
  return res.toFixed(2)
}

export const getUpliftEnhancedRateMultiplier = (enhancedRate: ContractModelEnhancedRate): string => {
  const isAdd = enhancedRate.modifier_type === ContractModelEnhancedRateModifierType.ADD

  return `${isAdd ? "Add" : "Multiply by"}: ${enhancedRate.modifier}`
}

export const useGetUpliftDayTypeLabel = (dayTypeOptions: SelectOption[]): ((value: string) => string) =>
  useCallback(
    (value: string) => dayTypeOptions.find((option) => option.value === value)?.title as string,
    [dayTypeOptions]
  )

export const isServicesWithRates = (service?: RateUpliftFormService): boolean => {
  if (!service?.bandItems) return false

  return Object.values(service.bandItems).some((band) => {
    return Object.keys(band.rates || {}).length
  })
}

export const isModelWithRates = (model?: RateUpliftFormModel): boolean => {
  if (!model?.services) return false
  return Object.values(model.services)
    .filter((service) => service.picked)
    .some(isServicesWithRates)
}
