import { FinanceApi } from "api"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"
import { queryKeys } from "./queries"
import {
  ApiCarerBankDetails,
  ApiCarerBankDetailsRequest,
  ApiCarerContract,
  ApiCarerContractRequest,
  ApiContractAccrualItem,
  ApiContractAccrualItemRequest
} from "./types"
import { useNavigate } from "react-router-dom"
import { useUIStore } from "stores"

export const useCarerContractCreateMutation = ({
  carerGuid
}: {
  carerGuid: string
}): UseMutationResult<ApiCarerContract, Error, ApiCarerContractRequest> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  return useMutation(
    async (payload: ApiCarerContractRequest): Promise<ApiCarerContract> => {
      return FinanceApi.post(`/finance/carers/${carerGuid}/contracts`, payload)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Payment group successfully created.")
        navigate(`/carers/${carerGuid}/contracts`)
      }
    }
  )
}

export const useCarerContractEditMutation = ({
  carerGuid
}: {
  carerGuid: string
}): UseMutationResult<ApiCarerContract, Error, ApiCarerContract> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  return useMutation(
    async (payload: ApiCarerContract): Promise<ApiCarerContract> => {
      return FinanceApi.put(`/finance/carers/${carerGuid}/contracts/${payload.guid}`, payload)
    },
    {
      onSuccess: () => {
        showSuccessMessage("Payment group successfully updated.")
        navigate(`/carers/${carerGuid}/contracts`)
      }
    }
  )
}

export const useCarerContractDeleteMutation = ({
  carerGuid
}: {
  carerGuid?: string
}): UseMutationResult<void, Error, { contractGuid: string }> => {
  return useMutation(async ({ contractGuid }): Promise<void> => {
    return FinanceApi.delete(`/finance/carers/${carerGuid}/contracts/${contractGuid}`)
  })
}

export const useCarerBankDetailsCreateMutation = (
  carerGuid: string
): UseMutationResult<ApiCarerBankDetails, Error, ApiCarerBankDetailsRequest> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()
  return useMutation(
    async (payload: ApiCarerBankDetailsRequest): Promise<ApiCarerBankDetails> => {
      return FinanceApi.post(`/finance/carers/${carerGuid}/bank-details`, payload)
    },
    {
      onSuccess: async (data) => {
        queryCache.setQueryData(queryKeys.getCarerBankDetailsKey({ carerGuid }), data)
        showSuccessMessage("Bank details successfully created.")
      }
    }
  )
}

export const useCarerBankDetailsUpdateMutation = (
  carerGuid: string
): UseMutationResult<ApiCarerBankDetails, Error, ApiCarerBankDetails> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()
  return useMutation(
    async (payload: ApiCarerBankDetails): Promise<ApiCarerBankDetails> => {
      return FinanceApi.put(`/finance/carers/${carerGuid}/bank-details`, payload)
    },
    {
      onSuccess: async (data) => {
        queryCache.setQueryData(queryKeys.getCarerBankDetailsKey({ carerGuid }), data)
        showSuccessMessage("Bank details successfully updated.")
      }
    }
  )
}

export const useCarerAccrualAdjustmentCreateMutation = ({
  carerGuid,
  accrualGuid,
  onOk
}: {
  carerGuid: string
  accrualGuid: string
  onOk: () => void
}): UseMutationResult<ApiContractAccrualItem, Error, ApiContractAccrualItemRequest> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async (payload: ApiContractAccrualItemRequest): Promise<ApiContractAccrualItem> => {
      return FinanceApi.post(`/finance/carers/${carerGuid}/accruals/${accrualGuid}/items`, payload)
    },
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(queryKeys.getCarerAccrualDetailItemsKey({ carerGuid, accrualGuid }))
        await queryCache.invalidateQueries(queryKeys.getCarerAccrualSummaryKey({ carerGuid, accrualGuid }))

        showSuccessMessage("Accrual Adjustment successfully created.")
        onOk()
      }
    }
  )
}

type AccrualAdjustmentUpdateRequest = ApiContractAccrualItemRequest & { accrualItemGuid: string }

export const useCarerAccrualAdjustmentUpdateMutation = ({
  carerGuid,
  accrualGuid,
  onOk
}: {
  carerGuid: string
  accrualGuid: string
  onOk: () => void
}): UseMutationResult<ApiContractAccrualItem, Error, AccrualAdjustmentUpdateRequest> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async (payload: AccrualAdjustmentUpdateRequest): Promise<ApiContractAccrualItem> => {
      const { accrualItemGuid, ...rest } = payload
      return FinanceApi.put(`/finance/carers/${carerGuid}/accruals/${accrualGuid}/items/${accrualItemGuid}`, rest)
    },
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(queryKeys.getCarerAccrualDetailItemsKey({ carerGuid, accrualGuid }))
        await queryCache.invalidateQueries(queryKeys.getCarerAccrualSummaryKey({ carerGuid, accrualGuid }))

        showSuccessMessage("Accrual Adjustment successfully edited.")
        onOk()
      }
    }
  )
}

export const useCarerAccrualAdjustmentDeleteMutation = ({
  carerGuid,
  accrualGuid,
  onOk
}: {
  carerGuid: string
  accrualGuid: string
  onOk: () => void
}): UseMutationResult<void, Error, string> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const queryCache = useQueryClient()

  return useMutation(
    async (accrualItemGuid: string): Promise<void> => {
      return FinanceApi.delete(`/finance/carers/${carerGuid}/accruals/${accrualGuid}/items/${accrualItemGuid}`)
    },
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(queryKeys.getCarerAccrualDetailItemsKey({ carerGuid, accrualGuid }))
        await queryCache.invalidateQueries(queryKeys.getCarerAccrualSummaryKey({ carerGuid, accrualGuid }))

        showSuccessMessage("Accrual Adjustment successfully deleted.")
        onOk()
      }
    }
  )
}
