import React, { memo } from "react"
import { ContractModelType } from "constants/modelTypes"
import { useInvoiceModelListQuery } from "data/finance/contractModel/queries"
import ContractModelList from "../components/ContractModelList"

const InvoiceModelList: React.FC = () => {
  const { data: contracts } = useInvoiceModelListQuery()

  return <ContractModelList {...{ contracts, modelType: ContractModelType.INVOICE }} />
}

export default memo(InvoiceModelList)
