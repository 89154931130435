import React, { memo } from "react"
import TableCell from "@mui/material/TableCell"
import { Link } from "components"
import dateTimeUtils from "lib/datetime"
import testingId from "constants/testingId"
import { Alert } from "data/finance/alert/types"
import useBranchCareplannerUrl from "lib/hooks/branchInfo/useBranchCareplannerUrl"

enum AlertSource {
  VISIT = "Visit",
  SHIFT = "Shift",
  TIMEOFF = "TimeOff",
  TRAINING = "Training"
}

const buildAlertUrl = ({ alert, careplannerUrl }: { alert: Alert; careplannerUrl: string | null }) => {
  if (alert.source === AlertSource.VISIT) return `/visits/detail/${alert.relation_guid}`
  if (alert.source === AlertSource.SHIFT) return `/shifts/detail/${alert.relation_guid}`
  if (alert.source === AlertSource.TIMEOFF && careplannerUrl)
    return `${careplannerUrl}/respite/${alert.carer_third_party_id}`
  if (alert.source === AlertSource.TRAINING && careplannerUrl)
    return `${careplannerUrl}/training/${alert.carer_third_party_id}/view`
  return ""
}

const isExternal = (alert: Alert): boolean =>
  alert.source === AlertSource.TIMEOFF || alert.source === AlertSource.TRAINING

const hasLink = (alert: Alert) =>
  alert.source === AlertSource.VISIT || alert.source === AlertSource.SHIFT || isExternal(alert)

interface OwnProps {
  alert: Alert
}

export const AlertTableSourceLink: React.FC<OwnProps> = memo(({ alert }) => {
  const careplannerUrl = useBranchCareplannerUrl()

  return (
    <TableCell data-cy={testingId.alerts.tableBodyCell}>
      {hasLink(alert) ? (
        <Link external={isExternal(alert)} to={buildAlertUrl({ alert, careplannerUrl })}>
          {dateTimeUtils.formatDate(alert.date)}
        </Link>
      ) : (
        dateTimeUtils.formatDate(alert.date)
      )}
    </TableCell>
  )
})

AlertTableSourceLink.displayName = "AlertTableSourceLink"
