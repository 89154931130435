import React from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { Logo, UserPathBox } from "../components"

const NonAdminRole: React.FC = () => {
  return (
    <Grid
      sx={{
        height: "100vh",
        textAlign: "center"
      }}
      container
      spacing={0}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={10} md={3}>
        <Logo
          sx={{
            width: "299px",
            height: "auto",
            margin: "32px auto 96px auto",
            display: "block"
          }}
        />
        <Typography variant="h5">
          Unfortunately, DCP is only available to branch team members at this moment.
        </Typography>
        <UserPathBox login={false} logout={true} />
      </Grid>
    </Grid>
  )
}
export default NonAdminRole
