import React, { PropsWithChildren } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import Config from "lib/config"


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchOnWindowFocus: false
    }
  }
})
const devtoolsEnabled = Config.getEnvVariable("APP_REACT_QUERY_DEVTOOLS") === "True"

const ReactQueryProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <>
        {children}
        {devtoolsEnabled && <ReactQueryDevtools initialIsOpen={false} />}
      </>
    </QueryClientProvider>
  )
}

export default ReactQueryProvider
