import { FinanceApi } from "api"
import { ContractModelType } from "constants/modelTypes"
import { enabledById, enabledByMandatoryUrlParams } from "data/helpers"
import { compose, prop, sortBy, toLower } from "ramda"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { getContractModelServiceRequestUrl, getContractModelUrl, getContractModelRuleGroupUrl } from "./helpers"
import {
  ContractModelSalary,
  ContractModelSalaryBand,
  ContractModelService,
  ContractModelAccrualPolicy,
  ContractModelServiceBand,
  ContractModelServiceBandWithRates,
  InvoiceModelRate,
  InvoiceModelRuleGroup,
  InvoiceModelRuleGroupRule,
  PaymentModelRate,
  ContractModelSummaryRequest,
  ApiContractModelRate,
  CalendarPeriod,
  CalendarResponse,
  InvoiceModel,
  PaymentModel
} from "./types"
import { StatusEnum } from "data/commonTypes"

export enum QueryKeyParts {
  LIST = "list",
  INVOICE_MODEL = "invoice_model",
  PAYMENT_MODEL = "payment_model",
  SUMMARIES = "summaries",
  MODEL = "model",
  CONTRACT = "contract",
  CALENDAR = "calendar",
  PERIOD = "period",
  DETAIL = "detail",
  DETAILS = "details",
  SERVICE = "service",
  BAND = "band",
  RATE = "rate",
  RULE_GROUP = "rule_group",
  RULES = "rules",
  ACCRUAL_POLICIES = "accrual_policies",
  SALARY_BANDS = "salary_bands",
  SALARIES = "salaries"
}

export const queryKeys = {
  getInvoiceModelKey: (id?: string): QueryKey => [QueryKeyParts.INVOICE_MODEL, QueryKeyParts.DETAIL, { id }],
  getInvoiceModelListKey: (): QueryKey => [QueryKeyParts.INVOICE_MODEL, QueryKeyParts.LIST],
  getInvoiceModelRateQuery: ({
    contractGuid,
    contractRateGuid
  }: {
    contractGuid: string
    contractRateGuid?: string
  }): QueryKey => [
    QueryKeyParts.INVOICE_MODEL,
    QueryKeyParts.LIST,
    QueryKeyParts.RATE,
    QueryKeyParts.DETAIL,
    { contractGuid, contractRateGuid }
  ],
  getInvoiceModelRateListQuery: ({ contractGuid, search }: { contractGuid: string; search?: string }): QueryKey => [
    QueryKeyParts.INVOICE_MODEL,
    QueryKeyParts.LIST,
    QueryKeyParts.RATE,
    QueryKeyParts.LIST,
    { contractGuid, search }
  ],
  getPaymentModelRateQuery: ({
    contractGuid,
    contractRateGuid
  }: {
    contractGuid: string
    contractRateGuid?: string
  }): QueryKey => [
    QueryKeyParts.PAYMENT_MODEL,
    QueryKeyParts.LIST,
    QueryKeyParts.RATE,
    QueryKeyParts.DETAIL,
    { contractGuid, contractRateGuid }
  ],
  getPaymentModelRateListQuery: ({ contractGuid, search }: { contractGuid: string; search?: string }): QueryKey => [
    QueryKeyParts.PAYMENT_MODEL,
    QueryKeyParts.LIST,
    QueryKeyParts.RATE,
    QueryKeyParts.LIST,
    { contractGuid, search }
  ],
  getPaymentModelKey: (id?: string): QueryKey => [QueryKeyParts.PAYMENT_MODEL, QueryKeyParts.DETAIL, { id }],
  getPaymentModelListKey: (): QueryKey => [QueryKeyParts.PAYMENT_MODEL, QueryKeyParts.LIST],
  getInvoiceModelSummaries: (status?: StatusEnum): QueryKey => [
    QueryKeyParts.INVOICE_MODEL,
    QueryKeyParts.SUMMARIES,
    { status }
  ],
  getPaymentModelSummaries: (status?: StatusEnum): QueryKey => [
    QueryKeyParts.PAYMENT_MODEL,
    QueryKeyParts.SUMMARIES,
    { status }
  ],
  getInvoiceModelsKey: (): QueryKey => [QueryKeyParts.INVOICE_MODEL, QueryKeyParts.LIST],
  getPaymentModelsKey: (): QueryKey => [QueryKeyParts.PAYMENT_MODEL, QueryKeyParts.LIST],
  getContractModelCalendarListKey: ({
    modelType,
    contractGuid
  }: {
    modelType: ContractModelType
    contractGuid: string
  }): QueryKey => [
    QueryKeyParts.CONTRACT,
    QueryKeyParts.MODEL,
    QueryKeyParts.CALENDAR,
    QueryKeyParts.LIST,
    { modelType, contractGuid }
  ],
  getContractModelCalendarKey: ({
    modelType,
    contractGuid,
    calendarGuid
  }: {
    modelType: ContractModelType
    contractGuid: string
    calendarGuid?: string
  }): QueryKey => [
    QueryKeyParts.CONTRACT,
    QueryKeyParts.MODEL,
    QueryKeyParts.CALENDAR,
    { modelType, contractGuid, calendarGuid }
  ],
  getContractCalendarPeriodListKey: ({
    modelType,
    contractModelGuid,
    calendarGuid
  }: {
    modelType: ContractModelType
    contractModelGuid?: string
    calendarGuid?: string
  }): QueryKey => [QueryKeyParts.PERIOD, QueryKeyParts.LIST, { modelType, contractModelGuid, calendarGuid }],
  getPeriodKey: (props: {
    modelType: ContractModelType
    contractGuid?: string
    calendarGuid?: string
    periodGuid?: string
  }): QueryKey => [QueryKeyParts.PERIOD, props],
  getContractServiceListKey: ({
    modelType,
    contractGuid,
    isInvoicePaymentService
  }: {
    modelType: ContractModelType
    isInvoicePaymentService?: boolean
    contractGuid: string
  }): QueryKey => [
    QueryKeyParts.CONTRACT,
    QueryKeyParts.MODEL,
    QueryKeyParts.SERVICE,
    QueryKeyParts.LIST,
    { modelType, contractGuid, isInvoicePaymentService }
  ],
  getContractServiceDetailKey: ({
    modelType,
    contractGuid,
    serviceGuid
  }: {
    modelType: ContractModelType
    contractGuid: string
    serviceGuid?: string
  }): QueryKey => [
    QueryKeyParts.CONTRACT,
    QueryKeyParts.MODEL,
    QueryKeyParts.SERVICE,
    QueryKeyParts.DETAIL,
    { modelType, contractGuid, serviceGuid }
  ],
  getContractServiceBandListKey: ({
    modelType,
    contractGuid,
    serviceGuid
  }: {
    modelType: ContractModelType
    contractGuid: string
    serviceGuid?: string
  }): QueryKey => [
    QueryKeyParts.CONTRACT,
    QueryKeyParts.SERVICE,
    QueryKeyParts.BAND,
    QueryKeyParts.LIST,
    { modelType, contractGuid, serviceGuid }
  ],
  getContractServiceBandWithRatesListKey: ({
    modelType,
    contractGuid,
    serviceGuid
  }: {
    modelType: ContractModelType
    contractGuid: string
    serviceGuid?: string
  }): QueryKey => [
    QueryKeyParts.CONTRACT,
    QueryKeyParts.SERVICE,
    QueryKeyParts.BAND,
    QueryKeyParts.DETAILS,
    { modelType, contractGuid, serviceGuid }
  ],
  getContractServiceBandRateListKey: ({
    modelType,
    contractGuid,
    serviceGuid,
    bandGuid
  }: {
    modelType: ContractModelType
    contractGuid: string
    serviceGuid?: string
    bandGuid?: string
  }): QueryKey => [
    QueryKeyParts.CONTRACT,
    QueryKeyParts.SERVICE,
    QueryKeyParts.BAND,
    QueryKeyParts.RATE,
    QueryKeyParts.LIST,
    { modelType, contractGuid, serviceGuid, bandGuid }
  ],
  getInvoiceModelRuleGroupListKey: ({
    contractGuid,
    isPaymentRule
  }: {
    contractGuid: string
    isPaymentRule: boolean
  }): QueryKey => [
    QueryKeyParts.INVOICE_MODEL,
    QueryKeyParts.RULE_GROUP,
    QueryKeyParts.LIST,
    { contractGuid, isPaymentRule }
  ],
  getInvoiceModelRuleGroupItemKey: ({
    contractGuid,
    ruleGroupGuid,
    isPaymentRule
  }: {
    contractGuid: string
    ruleGroupGuid?: string
    isPaymentRule: boolean
  }): QueryKey => [
    QueryKeyParts.INVOICE_MODEL,
    QueryKeyParts.RULE_GROUP,
    QueryKeyParts.DETAIL,
    { contractGuid, ruleGroupGuid, isPaymentRule }
  ],
  getInvoiceModelRuleGroupItemRulesKey: ({
    contractGuid,
    ruleGroupGuid,
    isPaymentRule
  }: {
    contractGuid: string
    ruleGroupGuid?: string
    isPaymentRule: boolean
  }): QueryKey => [
    QueryKeyParts.INVOICE_MODEL,
    QueryKeyParts.RULE_GROUP,
    QueryKeyParts.DETAIL,
    QueryKeyParts.RULES,
    { contractGuid, ruleGroupGuid, isPaymentRule }
  ],
  getContractAccrualPoliciesListKey: ({ contractGuid }: { contractGuid: string }): QueryKey => [
    QueryKeyParts.CONTRACT,
    QueryKeyParts.ACCRUAL_POLICIES,
    QueryKeyParts.LIST,
    { contractGuid }
  ],
  getContractAccrualPolicyDetailKey: ({
    contractGuid,
    policyGuid
  }: {
    contractGuid: string
    policyGuid?: string
  }): QueryKey => [
    QueryKeyParts.CONTRACT,
    QueryKeyParts.ACCRUAL_POLICIES,
    QueryKeyParts.DETAIL,
    { contractGuid, policyGuid }
  ],
  getContractSalaryBandListKey: ({
    contractGuid,
    contractSalaryGuid
  }: {
    contractGuid: string
    contractSalaryGuid?: string
  }): QueryKey => [
    QueryKeyParts.CONTRACT,
    QueryKeyParts.SALARY_BANDS,
    QueryKeyParts.LIST,
    { contractGuid, contractSalaryGuid }
  ],
  getContractSalaryBandDetailKey: ({
    contractGuid,
    contractSalaryGuid,
    contractSalaryBandGuid
  }: {
    contractGuid: string
    contractSalaryGuid?: string
    contractSalaryBandGuid?: string
  }): QueryKey => [
    QueryKeyParts.CONTRACT,
    QueryKeyParts.SALARY_BANDS,
    QueryKeyParts.DETAIL,
    { contractGuid, contractSalaryGuid, contractSalaryBandGuid }
  ],
  getContractSalaryListKey: ({ contractGuid }: { contractGuid: string }): QueryKey => [
    QueryKeyParts.CONTRACT,
    QueryKeyParts.SALARIES,
    QueryKeyParts.LIST,
    { contractGuid }
  ],
  getContractSalaryDetailKey: ({
    contractGuid,
    contractSalaryGuid
  }: {
    contractGuid: string
    contractSalaryGuid?: string
  }): QueryKey => [
    QueryKeyParts.CONTRACT,
    QueryKeyParts.SALARIES,
    QueryKeyParts.DETAIL,
    { contractGuid, contractSalaryGuid }
  ]
}

// invoice-models
export const useInvoiceModelListQuery = ({ enabled = true }: { enabled?: boolean } = {}): UseQueryResult<
  InvoiceModel[],
  Error
> => {
  const { data, ...rest } = useQuery<InvoiceModel[], Error>({
    queryKey: queryKeys.getInvoiceModelListKey(),
    queryFn: async () => FinanceApi.get("/finance/invoice-models"),
    ...enabledByMandatoryUrlParams([enabled])
  })
  return {
    data: data ? sortBy<InvoiceModel>(compose(toLower, prop("title")))(data) : data,
    ...rest
  } as UseQueryResult<InvoiceModel[], Error>
}

export const useInvoiceModelQuery = (id: string | undefined): UseQueryResult<InvoiceModel, Error> => {
  return useQuery({
    queryKey: queryKeys.getInvoiceModelKey(id),
    queryFn: async () => FinanceApi.get(`/finance/invoice-models/${id}`),
    ...enabledById(id)
  })
}

export const useInvoiceModelSummariesQuery = (
  status?: StatusEnum
): UseQueryResult<ContractModelSummaryRequest[], Error> =>
  useQuery({
    queryKey: queryKeys.getInvoiceModelSummaries(status),
    queryFn: async () => {
      const statusQuery = status ? `?status=${status}` : ""
      return FinanceApi.get(`/finance/invoice-models/summaries${statusQuery}`)
    }
  })

export const useInvoiceModelRateListQuery = ({
  contractGuid,
  search = ""
}: {
  contractGuid: string
  search?: string
}): UseQueryResult<InvoiceModelRate[], Error> =>
  useQuery({
    queryKey: queryKeys.getInvoiceModelRateListQuery({ contractGuid, search }),
    queryFn: async () => FinanceApi.get(`/finance/invoice-models/${contractGuid}/rates${search}`)
  })

export const useInvoiceModelRateQuery = ({
  contractGuid,
  contractRateGuid
}: {
  contractGuid: string
  contractRateGuid?: string
}): UseQueryResult<InvoiceModelRate, Error> =>
  useQuery({
    queryKey: queryKeys.getInvoiceModelRateQuery({ contractGuid, contractRateGuid }),
    queryFn: async () => FinanceApi.get(`/finance/invoice-models/${contractGuid}/rates/${contractRateGuid}`),
    ...enabledById(contractRateGuid)
  })

// payment-models
export const usePaymentModelSummariesQuery = (
  status?: StatusEnum
): UseQueryResult<ContractModelSummaryRequest[], Error> =>
  useQuery({
    queryKey: queryKeys.getPaymentModelSummaries(),
    queryFn: async () => {
      const statusQuery = status ? `?status=${status}` : ""
      return FinanceApi.get(`/finance/payment-models/summaries${statusQuery}`)
    }
  })

export const usePaymentModelListQuery = ({ enabled = true }: { enabled?: boolean } = {}): UseQueryResult<
  PaymentModel[],
  Error
> => {
  const { data, ...rest } = useQuery<PaymentModel[], Error>({
    queryKey: queryKeys.getPaymentModelListKey(),
    queryFn: async () => FinanceApi.get("/finance/payment-models"),
    ...enabledByMandatoryUrlParams([enabled])
  })

  return { data: data ? sortBy<PaymentModel>(compose(toLower, prop("title")))(data) : data, ...rest } as UseQueryResult<
    PaymentModel[],
    Error
  >
}

export const usePaymentModelQuery = (id?: string): UseQueryResult<PaymentModel, Error> => {
  return useQuery({
    queryKey: queryKeys.getPaymentModelKey(id),
    queryFn: async () => FinanceApi.get(`/finance/payment-models/${id}`),
    ...enabledById(id)
  })
}

export const usePaymentModelRateListQuery = ({
  contractGuid,
  search = ""
}: {
  contractGuid: string
  search?: string
}): UseQueryResult<PaymentModelRate[], Error> =>
  useQuery({
    queryKey: queryKeys.getPaymentModelRateListQuery({ contractGuid, search }),
    queryFn: async () => FinanceApi.get(`/finance/payment-models/${contractGuid}/rates${search}`)
  })

export const usePaymentModelRateQuery = ({
  contractGuid,
  contractRateGuid
}: {
  contractGuid: string
  contractRateGuid?: string
}): UseQueryResult<PaymentModelRate, Error> =>
  useQuery({
    queryKey: queryKeys.getPaymentModelRateQuery({ contractGuid, contractRateGuid }),
    queryFn: async () => FinanceApi.get(`/finance/payment-models/${contractGuid}/rates/${contractRateGuid}`),
    ...enabledById(contractRateGuid)
  })

export const useContractServiceAccrualPoliciesListQuery = ({
  contractGuid
}: {
  contractGuid: string
}): UseQueryResult<ContractModelAccrualPolicy[], Error> =>
  useQuery({
    queryKey: queryKeys.getContractAccrualPoliciesListKey({ contractGuid }),
    queryFn: async () => FinanceApi.get(`/finance/payment-models/${contractGuid}/accrual-policies`)
  })

export const useContractAccrualPolicyQuery = ({
  contractGuid,
  policyGuid
}: {
  contractGuid: string
  policyGuid?: string
}): UseQueryResult<ContractModelAccrualPolicy, Error> =>
  useQuery({
    queryKey: queryKeys.getContractAccrualPolicyDetailKey({ contractGuid, policyGuid }),
    queryFn: async () => FinanceApi.get(`/finance/payment-models/${contractGuid}/accrual-policies/${policyGuid}`),
    ...enabledById(policyGuid)
  })

// invoice && payment common
export const useContractModelCalendarListQuery = ({
  modelType,
  contractGuid
}: {
  modelType: ContractModelType
  contractGuid: string
}): UseQueryResult<CalendarResponse[], Error> => {
  return useQuery({
    queryKey: queryKeys.getContractModelCalendarListKey({ modelType, contractGuid }),
    queryFn: async () => FinanceApi.get(`/finance/${getContractModelUrl(modelType)}/${contractGuid}/calendars`)
  })
}

export const useContractModelCalendarQuery = ({
  modelType,
  contractGuid,
  calendarGuid
}: {
  modelType: ContractModelType
  contractGuid: string
  calendarGuid?: string
}): UseQueryResult<CalendarResponse, Error> => {
  return useQuery({
    queryKey: queryKeys.getContractModelCalendarKey({ modelType, contractGuid, calendarGuid }),
    queryFn: async () =>
      FinanceApi.get(`/finance/${getContractModelUrl(modelType)}/${contractGuid}/calendars/${calendarGuid}`),
    ...enabledById(calendarGuid)
  })
}

export const useContractCalendarPeriodListQuery = ({
  modelType,
  contractModelGuid,
  calendarGuid
}: {
  modelType: ContractModelType
  contractModelGuid?: string
  calendarGuid?: string
}): UseQueryResult<CalendarPeriod[], Error> => {
  // TODO: fix types, should be calendar period
  return useQuery({
    queryKey: queryKeys.getContractCalendarPeriodListKey({ modelType, contractModelGuid, calendarGuid }),
    queryFn: async () =>
      FinanceApi.get(
        `/finance/${getContractModelUrl(modelType)}/${contractModelGuid}/calendars/${calendarGuid}/periods`
      ),
    ...enabledByMandatoryUrlParams([modelType, contractModelGuid, calendarGuid])
  })
}

export const usePeriodQuery = ({
  modelType,
  contractGuid,
  calendarGuid,
  periodGuid
}: {
  modelType: ContractModelType
  contractGuid?: string
  calendarGuid?: string
  periodGuid?: string
}): UseQueryResult<CalendarPeriod, Error> => {
  return useQuery({
    queryKey: queryKeys.getPeriodKey({ modelType, contractGuid, calendarGuid, periodGuid }),
    queryFn: async () =>
      FinanceApi.get(
        `/finance/${getContractModelUrl(modelType)}/${contractGuid}/calendars/${calendarGuid}/periods/${periodGuid}`
      ),
    ...enabledByMandatoryUrlParams([modelType, contractGuid, calendarGuid, periodGuid])
  })
}

export const useContractServiceListQuery = ({
  modelType,
  contractGuid,
  isInvoicePaymentService,
  enabled = true
}: {
  modelType: ContractModelType
  contractGuid: string
  isInvoicePaymentService?: boolean
  enabled?: boolean
}): UseQueryResult<ContractModelService[], Error> =>
  useQuery({
    queryKey: queryKeys.getContractServiceListKey({ modelType, contractGuid, isInvoicePaymentService }),
    queryFn: async () =>
      FinanceApi.get(
        `/finance/${getContractModelUrl(modelType)}/${contractGuid}/${getContractModelServiceRequestUrl(
          isInvoicePaymentService
        )}`
      ),
    ...enabledByMandatoryUrlParams([enabled])
  })

export const useContractServiceDetailQuery = ({
  modelType,
  contractGuid,
  isInvoicePaymentService,
  serviceGuid
}: {
  modelType: ContractModelType
  contractGuid: string
  isInvoicePaymentService?: boolean
  serviceGuid?: string
}): UseQueryResult<ContractModelService, Error> =>
  useQuery({
    queryKey: queryKeys.getContractServiceDetailKey({ modelType, contractGuid, serviceGuid }),
    queryFn: async () =>
      FinanceApi.get(
        `/finance/${getContractModelUrl(modelType)}/${contractGuid}/${getContractModelServiceRequestUrl(
          isInvoicePaymentService
        )}/${serviceGuid}`
      ),
    ...enabledById(serviceGuid)
  })

export const useContractServiceBandListQuery = ({
  modelType,
  contractGuid,
  serviceGuid
}: {
  modelType: ContractModelType
  contractGuid: string
  serviceGuid?: string
}): UseQueryResult<ContractModelServiceBand[], Error> =>
  useQuery({
    queryKey: queryKeys.getContractServiceBandListKey({ modelType, contractGuid, serviceGuid }),
    queryFn: async () =>
      FinanceApi.get(`/finance/${getContractModelUrl(modelType)}/${contractGuid}/services/${serviceGuid}/bands`),
    ...enabledByMandatoryUrlParams([serviceGuid])
  })

export const useContractServiceBandWithRatesListQuery = ({
  modelType,
  contractGuid,
  serviceGuid
}: {
  modelType: ContractModelType
  contractGuid: string
  serviceGuid?: string
}): UseQueryResult<ContractModelServiceBandWithRates[], Error> =>
  useQuery({
    queryKey: queryKeys.getContractServiceBandWithRatesListKey({ modelType, contractGuid, serviceGuid }),
    queryFn: async () =>
      FinanceApi.get(
        `/finance/${getContractModelUrl(modelType)}/${contractGuid}/services/${serviceGuid}/bands/details`
      ),
    ...enabledByMandatoryUrlParams([serviceGuid])
  })

export const useContractServiceBandRateListQuery = ({
  modelType,
  contractGuid,
  serviceGuid,
  bandGuid
}: {
  modelType: ContractModelType
  contractGuid: string
  serviceGuid?: string
  bandGuid?: string
}): UseQueryResult<ApiContractModelRate[], Error> =>
  useQuery({
    queryKey: queryKeys.getContractServiceBandRateListKey({ modelType, contractGuid, serviceGuid, bandGuid }),
    queryFn: async () =>
      FinanceApi.get(
        `/finance/${getContractModelUrl(modelType)}/${contractGuid}/services/${serviceGuid}/bands/${bandGuid}/rates`
      ),
    ...enabledByMandatoryUrlParams([serviceGuid, bandGuid])
  })

export const useInvoiceModelRuleGroupListQuery = ({
  contractGuid,
  isPaymentRule
}: {
  contractGuid: string
  isPaymentRule: boolean
}): UseQueryResult<InvoiceModelRuleGroup[], Error> => {
  return useQuery({
    queryKey: queryKeys.getInvoiceModelRuleGroupListKey({ contractGuid, isPaymentRule }),
    queryFn: async () =>
      FinanceApi.get(`/finance/invoice-models/${contractGuid}/${getContractModelRuleGroupUrl(isPaymentRule)}`)
  })
}

export const useInvoiceModelRuleGroupItemQuery = ({
  contractGuid,
  ruleGroupGuid,
  isPaymentRule
}: {
  contractGuid: string
  ruleGroupGuid?: string
  isPaymentRule: boolean
}): UseQueryResult<InvoiceModelRuleGroup, Error> => {
  return useQuery({
    queryKey: queryKeys.getInvoiceModelRuleGroupItemKey({ contractGuid, isPaymentRule, ruleGroupGuid }),
    queryFn: async () =>
      FinanceApi.get(
        `/finance/invoice-models/${contractGuid}/${getContractModelRuleGroupUrl(isPaymentRule)}/${ruleGroupGuid}`
      ),
    ...enabledByMandatoryUrlParams([ruleGroupGuid])
  })
}

export const useInvoiceModelRuleGroupItemRulesQuery = ({
  contractGuid,
  ruleGroupGuid,
  isPaymentRule
}: {
  contractGuid: string
  ruleGroupGuid?: string
  isPaymentRule: boolean
}): UseQueryResult<InvoiceModelRuleGroupRule[], Error> => {
  return useQuery({
    queryKey: queryKeys.getInvoiceModelRuleGroupItemRulesKey({ contractGuid, isPaymentRule, ruleGroupGuid }),
    queryFn: async () =>
      FinanceApi.get(
        `/finance/invoice-models/${contractGuid}/${getContractModelRuleGroupUrl(isPaymentRule)}/${ruleGroupGuid}/rules`
      ),
    ...enabledByMandatoryUrlParams([ruleGroupGuid])
  })
}

export const useContractSalaryBandListQuery = ({
  contractGuid,
  contractSalaryGuid
}: {
  contractGuid: string
  contractSalaryGuid?: string
}): UseQueryResult<ContractModelSalaryBand[], Error> =>
  useQuery({
    queryKey: queryKeys.getContractSalaryBandListKey({ contractGuid, contractSalaryGuid }),
    queryFn: async () => FinanceApi.get(`/finance/payment-models/${contractGuid}/salaries/${contractSalaryGuid}/bands`),
    ...enabledById(contractSalaryGuid)
  })

export const useContractSalaryListQuery = ({
  contractGuid
}: {
  contractGuid: string
}): UseQueryResult<ContractModelSalary[], Error> =>
  useQuery({
    queryKey: queryKeys.getContractSalaryListKey({ contractGuid }),
    queryFn: async () => FinanceApi.get(`/finance/payment-models/${contractGuid}/salaries`)
  })

export const useContractSalaryDetailQuery = ({
  contractGuid,
  contractSalaryGuid
}: {
  contractGuid: string
  contractSalaryGuid?: string
}): UseQueryResult<ContractModelSalary, Error> =>
  useQuery({
    queryKey: queryKeys.getContractSalaryDetailKey({ contractGuid, contractSalaryGuid }),
    queryFn: async () => FinanceApi.get(`/finance/payment-models/${contractGuid}/salaries/${contractSalaryGuid}`),
    ...enabledById(contractSalaryGuid)
  })
