import { IdentityApi } from "api"
import { DcpAuthResponse, RefreshIdentityResponse } from "api/instance"
import Auth, { AUTH_TYPE_GOOGLE } from "lib/Auth"
import { useEffect, useState } from "react"
import { useAuthStore } from "stores"
import CoreApi from "api/core"
import Config from "lib/config"
import { useShallow } from "zustand/react/shallow"

const refreshAccessToken = async (refreshToken: string, authType: string) => {
  if (authType === AUTH_TYPE_GOOGLE) {
    const { identityToken, refreshToken: newRefreshToken } = await IdentityApi.post<void, RefreshIdentityResponse>(
      "/v1/auth/google/refresh",
      {
        refreshToken,
        clientId: Config.getEnvVariable("APP_GOOGLE_CLIENT_ID")
      }
    )
    const { accessToken } = await IdentityApi.get<void, DcpAuthResponse>("/v1/auth/dcp", {
      headers: { Authorization: `Bearer ${identityToken}` }
    })
    return { accessToken, ...(newRefreshToken && { refreshToken: newRefreshToken }) }
  } else {
    const { access_token } = await CoreApi.refresh({ refresh_token: refreshToken })
    return { accessToken: access_token }
  }
}

export const useRefreshAuth = () => {
  const [isRefreshing, setIsRefreshing] = useState(true)
  const { logIn, user } = useAuthStore(useShallow(({ logIn, user }) => ({ logIn, user })))

  useEffect(() => {
    if (user) {
      setIsRefreshing(false)
      return
    }

    const accessToken = Auth.getAccessToken()
    const authType = Auth.getAuthType()

    if (accessToken) {
      logIn({ accessToken, authType })
      setIsRefreshing(false)
    } else {
      const refreshToken = Auth.getRefreshToken()
      if (refreshToken) {
        ;(async () => {
          const { accessToken, refreshToken: newRefreshToken } = await refreshAccessToken(refreshToken, authType)
          logIn({ accessToken, refreshToken: newRefreshToken, authType })
          setIsRefreshing(false)
        })()
      }
    }
  }, [user, logIn])

  return isRefreshing
}
