import { isNumber, padStart } from "lodash"
import { isNil } from "ramda"
// No typings currently available
import { Duration } from "luxon"

export type TimeSpanPeriod = "MINUTES" | "HOURS" | "DAYS"

export const constructTimeSpanUsingPeriod = (time: string, period: TimeSpanPeriod) => {
  const numTime = parseFloat(time)
  if (period === "MINUTES") {
    return Duration.fromObject({ minutes: numTime })
  }
  if (period === "HOURS") {
    return Duration.fromObject({ hours: numTime })
  }
  if (period === "DAYS") {
    return Duration.fromObject({ days: numTime })
  }
}

export const splitTimespanStringToTimeParts = (time: string): (string | number)[] => {
  // Begins with days
  if (time.match(/^\d+\./)) {
    const [days, ...remainder] = time.split(".")
    const [hours, minutes, seconds] = remainder[0].split(":")
    return [days, hours, minutes, seconds]
  }

  const [hours, minutes, seconds] = time.split(":")
  return [0, hours, minutes, seconds]
}

const parseToNumber = (input: string | number) => {
  return isNumber(input) ? input : parseFloat(input)
}

/**
 * Returns value in period of it's lowest part
 * example:
 * for 0.01:00:00.000 returns [1, "HOURS"]
 * for 0.01:30:00.000 returns [90, "MINUTES"]
 *
 * can force MINUTE or HOUR period
 *
 * if value has days and no forced period, returns just full days
    example:
    for 1.02:30:00.000 returns [1, "DAYS"]

 * can compare with second value. Then returns main value in period of lowest part of any of these values
 * example:
 *  main value: for 0.01:00:00.000
 *  compared value: for 0.01:30:00.000
    returns [60, "MINUTES"]
 *
  does not work with seconds

  see timespan.test.ts for more examples

 * @param time string - String representation of a TimeSpan
 * @param period string - forces in what period is value returned
 * @param comparedTime string - String representation of a TimeSpan to compare
 */
export const timeSpanToValuePeriod = (
  time: string,
  period?: TimeSpanPeriod | null,
  comparedTime?: string | null | undefined
): [number | null, TimeSpanPeriod | null] => {
  const [days, hours, minutes, seconds] = splitTimespanStringToTimeParts(time)
  const ts = Duration.fromObject({
    milliseconds: 0,
    seconds: isNumber(seconds) ? seconds : parseFloat(seconds),
    minutes: isNumber(minutes) ? minutes : parseFloat(minutes),
    hours: isNumber(hours) ? hours : parseFloat(hours),
    days: days === time ? 0 : parseToNumber(days)
  })

  const forcedMinutes: [number | null, TimeSpanPeriod | null] = [ts.days * 1440 + ts.hours * 60 + ts.minutes, "MINUTES"]
  const forcedHours: [number | null, TimeSpanPeriod | null] = [ts.days * 24 + ts.hours + ts.minutes / 60, "HOURS"]
  const forcedDays: [number | null, TimeSpanPeriod | null] = [ts.days + ts.hours / 24 + ts.minutes / (24 * 60), "DAYS"]

  if (comparedTime) {
    const [days2, hours2, minutes2, seconds2] = splitTimespanStringToTimeParts(comparedTime)
    const ts2 = Duration.fromObject({
      milliseconds: 0,
      seconds: isNumber(seconds2) ? seconds2 : parseFloat(seconds2),
      minutes: isNumber(minutes2) ? minutes2 : parseFloat(minutes2),
      hours: isNumber(hours2) ? hours2 : parseFloat(hours2),
      days: days2 === comparedTime ? 0 : parseToNumber(days2)
    })
    if (ts2.minutes > 0 || ts.minutes > 0) {
      return forcedMinutes
    }
    if (ts2.hours > 0 || ts.hours > 0) {
      return forcedHours
    }
  }

  // forced period
  if (period === "MINUTES") {
    return forcedMinutes
  }
  if (period === "HOURS") {
    return forcedHours
  }
  if (period === "DAYS") {
    return forcedDays
  }

  if (ts.minutes > 0) {
    return forcedMinutes
  }
  if (ts.hours > 0) {
    return forcedHours
  }
  if (ts.days > 0) {
    return [ts.days, "DAYS"]
  }

  return [null, null]
}

export const timeSpanToStringDuration = (time?: string | null): string => {
  if (isNil(time)) return "N/A"

  const [days, hours, minutes, seconds] = splitTimespanStringToTimeParts(time)
  const ts = Duration.fromObject({
    milliseconds: 0,
    seconds: isNumber(seconds) ? seconds : parseFloat(seconds),
    minutes: isNumber(minutes) ? minutes : parseFloat(minutes),
    hours: isNumber(hours) ? hours : parseFloat(hours),
    days: isNumber(days) ? days : parseFloat(days)
  })

  const shiftedDuration = ts.shiftTo("days", "hours", "minutes", "seconds", "milliseconds")

  if (shiftedDuration.as("seconds") === 0) {
    return "N/A"
  }

  const result: string[] = []

  if (shiftedDuration.days > 0) {
    result.push(`${shiftedDuration.days}d`)
  }

  result.push(`${shiftedDuration.hours}h`)
  result.push(`${shiftedDuration.minutes}m`)

  if (shiftedDuration.seconds > 0) {
    result.push(`${shiftedDuration.seconds}s`)
  }

  return result.join(" | ")
}

export const timeSpanSum = (times: string[]): string => {
  const minuteSum = times.reduce((minuteResult: number, time: string) => {
    const [days, hours, minutes, seconds] = splitTimespanStringToTimeParts(time)
    const ts = Duration.fromObject({
      milliseconds: 0,
      seconds: isNumber(seconds) ? seconds : parseFloat(seconds),
      minutes: isNumber(minutes) ? minutes : parseFloat(minutes),
      hours: isNumber(hours) ? hours : parseFloat(hours),
      days: isNumber(days) ? days : parseFloat(days)
    })
    return minuteResult + ts.minutes + ts.hours * 60 + ts.days * 1440
  }, 0)

  const res = {
    days: Math.floor(minuteSum / 1440),
    hours: Math.floor((minuteSum % 1440) / 60),
    minutes: minuteSum % 60
  }

  const result: string[] = []

  if (res.days > 0) {
    result.push(`${res.days}d`)
  }

  result.push(`${res.hours}h`)
  result.push(`${res.minutes}m`)

  return result.join(" | ")
}

export const timeSpanToHhMm = (time?: string | null, displayAsNA = true): string => {
  if (isNil(time)) return "N/A"

  const [days, hours, minutes, seconds] = splitTimespanStringToTimeParts(time)

  const ts = Duration.fromObject({
    milliseconds: 0,
    seconds: isNumber(seconds) ? seconds : parseFloat(seconds),
    minutes: isNumber(minutes) ? minutes : parseFloat(minutes),
    hours: isNumber(hours) ? hours : parseFloat(hours),
    days: isNumber(days) ? days : parseFloat(days)
  })

  if (ts.as("seconds") === 0 && displayAsNA) {
    return "N/A"
  }

  const result: string[] = []

  result.push(padStart(ts.hours.toString(), 2, "0"))
  result.push(padStart(ts.minutes.toString(), 2, "0"))

  return result.join(":")
}
