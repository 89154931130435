import { Box, Grid } from "@mui/material"
import { ClearFilterButton } from "components/ClearFilterButton"
import { DatePicker, FormSpyCustom, Select } from "components/Form"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { ApiDownloadFilter } from "data/download/types"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"
import { FilterValues, formatFilterValues } from "lib/helpers/formatFilterValues"
import { Pagination } from "lib/types"
import validators, { composeValidators } from "lib/validators"
import { DateTime } from "luxon"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"

const expiredOptions = [
  { title: "Any", value: undefined },
  { title: "Expired", value: true },
  { title: "Not ", value: false }
]

const statusOptions = [
  { title: "Any", value: "" },
  { title: "Pending", value: "Pending" },
  { title: "Completed", value: "Completed" },
  { title: "Failed", value: "Failed" }
]

interface OwnProps {
  filter: ApiDownloadFilter
  setFilter: Dispatch<SetStateAction<ApiDownloadFilter>>
  resetPagination: () => void
  pagination: Pagination
  defaultFilterValues: { start: string }
}

export const MyDownloadFilter: React.FC<OwnProps> = memo(
  ({ filter, setFilter, resetPagination, pagination, defaultFilterValues }) => {
    const debouncedOnSubmit = useDebouncedCallback((values: FilterValues<ApiDownloadFilter>) => {
      const formattedValues = formatFilterValues<ApiDownloadFilter>({ values, startName: "start", endName: "end" })

      setFilter(formattedValues)
      resetPagination()
    }, 500)

    const clearFilter = () => {
      setFilter(defaultFilterValues)
      resetPagination()
    }

    useFilterLocationChange({ filter, pagination })

    return (
      <Box {...{ pt: 2 }}>
        <Form
          {...{
            onSubmit: debouncedOnSubmit,
            initialValues: filter,
            validate: validateFilterPeriodDates({ start: "start", end: "end" })
          }}
        >
          {({ handleSubmit }) => {
            return (
              <form {...{ onSubmit: handleSubmit }}>
                <FormSpyCustom {...{ handleSubmit }} />
                <Grid {...{ container: true, alignItems: "center", spacing: 1 }}>
                  <Grid {...{ item: true, md: 3 }}>
                    <Field<DateTime>
                      {...{
                        name: "start",
                        component: DatePicker,
                        withTime: false,
                        label: "Started at",
                        validate: composeValidators([validators.required, validators.validDate]),
                        InputLabelProps: { required: true }
                      }}
                    />
                  </Grid>
                  <Grid {...{ item: true, md: 3 }}>
                    <Field<DateTime>
                      {...{
                        name: "end",
                        component: DatePicker,
                        withTime: false,
                        label: "End",
                        validate: validators.validDate
                      }}
                    />
                  </Grid>
                  <Grid {...{ item: true, md: 2 }}>
                    <Field<boolean>
                      {...{ name: "expired", label: "Expired", component: Select, options: expiredOptions }}
                    />
                  </Grid>
                  <Grid {...{ item: true, md: 2 }}>
                    <Field<string>
                      {...{ name: "status", label: "Status", component: Select, options: statusOptions }}
                    />
                  </Grid>
                  <Grid {...{ item: true, md: 2 }}>
                    <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
                  </Grid>
                </Grid>
              </form>
            )
          }}
        </Form>
      </Box>
    )
  }
)

MyDownloadFilter.displayName = "MyDownloadFilter"
