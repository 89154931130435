import React, { Dispatch, memo, SetStateAction } from "react"
import { Form } from "react-final-form"
import Box from "@mui/material/Box"
import { FormSpyCustom } from "components/Form"
import { Grid } from "@mui/material"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { StatusEnum } from "data/commonTypes"
import { StatusSelectField } from "components/Form/StatusSelectField"

export interface ListShiftCategoryContractsFilter {
  status?: StatusEnum
}

interface OwnProps {
  filter: ListShiftCategoryContractsFilter
  setFilter: Dispatch<SetStateAction<ListShiftCategoryContractsFilter>>
}

export const ShiftCategoryContractsListFilter: React.FC<OwnProps> = memo(({ filter, setFilter }) => {
  const onSubmit = (values: ListShiftCategoryContractsFilter) => {
    setFilter(values)
  }

  useFilterLocationChange({ filter })

  return (
    <Box m={3} mx={0}>
      <Form onSubmit={onSubmit} initialValues={filter}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={4}>
                <StatusSelectField multiselect />
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </Box>
  )
})

ShiftCategoryContractsListFilter.displayName = "ShiftCategoryContractsListFilter"
