import { InvoiceFileItem } from "data/finance/invoiceFile/types"
import { ApiSubmission, ApiSubmissionUpload } from "data/payroll/types"
import { DateTimeFormat } from "lib/datetime"
import React, { memo, ReactNode, useCallback, useMemo, useState } from "react"
import { groupITrentUploadErrors } from "../helpers/groupITrentUploadErrors"
import { FileManagementTablePayrollModal } from "./FileManagementTablePayrollErrorModal"
import { InfoBox } from "components"
import { ChevronRight } from "@mui/icons-material"
import { Button } from "@mui/material"

interface OwnProps {
  recentUpload: ApiSubmissionUpload
  file: InvoiceFileItem
  historyItem: ApiSubmission
}

export const FileManagementTablePayrollError: React.FC<OwnProps> = memo(({ recentUpload, file, historyItem }) => {
  const [modal, setModal] = useState<ReactNode>(null)

  const groupedIssues = useMemo(() => groupITrentUploadErrors(recentUpload.issues), [recentUpload.issues])

  const handleClick = useCallback(() => {
    const destroyModal = () => {
      setModal(null)
    }

    setModal(
      <FileManagementTablePayrollModal
        destroyModal={destroyModal}
        file={file}
        initialUploadGuid={recentUpload.guid}
        historyItem={historyItem}
      />
    )
  }, [file, recentUpload, historyItem])

  return (
    <>
      <InfoBox
        variant="error"
        condition
        title={`The latest submission no. ${recentUpload.submission_number} (${recentUpload.submitted_at.toFormat(
          DateTimeFormat.DATE_TIME
        )}) has ${groupedIssues.length} errors across ${recentUpload.issues.length} rows`}
        text="Please click on 'View error details' button to identify all rows that require resolving before being resubmitted to iTrent"
        action={
          <Button
            sx={{ padding: 0, fontWeight: "bold", color: "inherit" }}
            onClick={handleClick}
            variant="text"
            fullWidth={false}
          >
            View error details <ChevronRight />
          </Button>
        }
      />

      {!!modal && modal}
    </>
  )
})

FileManagementTablePayrollError.displayName = "FileManagementTablePayrollError"
