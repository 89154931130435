import Table from "@mui/material/Table"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React, { memo, useEffect, useMemo, useState } from "react"
import { ContractModelType } from "constants/modelTypes"
import { ascend, descend, reverse, sortWith } from "ramda"
import { ArrowDownward, ArrowUpward } from "@mui/icons-material"
import { ContractModelTableBody } from "./ContractModelTableBody"
import { ContractModelUpliftContractCheckboxProps, ContractModelUpliftHeaderProps } from "../types"
import { usePrevious } from "lib/hooks"
import { deepObjectEqual } from "lib/utils"
import testingId from "constants/testingId"
import { ContractModel } from "data/finance/contractModel/types"

interface Props {
  items: ContractModel[]
  modelType: ContractModelType
  customComponents?: {
    CustomTableHeader: React.FC<ContractModelUpliftHeaderProps>
    CustomTableItem: React.FC<ContractModelUpliftContractCheckboxProps>
  }
  clickable?: boolean
  onSortCallback?: (items: ContractModel[]) => void
  dataCy?: string
}

const ContractModelTable: React.FC<Props> = ({
  items,
  modelType,
  customComponents: { CustomTableHeader, CustomTableItem } = {},
  clickable = true,
  onSortCallback,
  dataCy = testingId.contractModel.list.table
}) => {
  const isInvoice = modelType === ContractModelType.INVOICE

  const [titleAscended, setTitleAscended] = useState(true)
  const [startAscended, setStartAscended] = useState(false)
  const [titleFirst, setTitleFirst] = useState(true)

  const sortedItems = useMemo(() => {
    const sortBy = [
      (titleAscended ? ascend : descend)((item: ContractModel) => item.title.toLowerCase()),
      (startAscended ? ascend : descend)((item: ContractModel) => item.start.toMillis())
    ]
    return sortWith<ContractModel>(titleFirst ? sortBy : reverse(sortBy))(items)
  }, [items, titleFirst, startAscended, titleAscended])

  const ref = usePrevious(sortedItems)
  useEffect(() => {
    if (onSortCallback && !deepObjectEqual(sortedItems, ref)) {
      onSortCallback(sortedItems)
    }
  }, [sortedItems, onSortCallback, ref])

  return (
    <Table data-cy={dataCy}>
      <TableHead>
        <TableRow>
          {CustomTableHeader ? <CustomTableHeader {...{ items }} /> : null}
          <TableCell
            align="left"
            sx={{
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)"
              },
              "& svg": {
                marginLeft: "5px",
                width: "0.7em",
                height: "0.7em",
                verticalAlign: "middle"
              }
            }}
            onClick={() => {
              setTitleFirst(true)
              setTitleAscended(!titleAscended)
            }}
          >
            {isInvoice ? "Template name" : "Pay group"}
            {titleAscended ? <ArrowUpward /> : <ArrowDownward />}
          </TableCell>
          <TableCell align="left">Type</TableCell>
          <TableCell
            align="left"
            sx={{
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)"
              },
              "& svg": {
                marginLeft: "5px",
                width: "0.7em",
                height: "0.7em",
                verticalAlign: "middle"
              }
            }}
            onClick={() => {
              setTitleFirst(false)
              setStartAscended(!startAscended)
            }}
          >
            Starts on
            {startAscended ? <ArrowUpward /> : <ArrowDownward />}
          </TableCell>
          <TableCell align="left">Ends before</TableCell>
          <TableCell align="left">Status</TableCell>
        </TableRow>
      </TableHead>
      <ContractModelTableBody {...{ items: sortedItems, modelType, CustomTableItem, clickable }} />
    </Table>
  )
}

export default memo(ContractModelTable)
