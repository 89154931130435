import React, { memo } from "react"
import Box from "@mui/material/Box"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"
import { Grid } from "@mui/material"
import { DatePicker, FormSpyCustom, Select, TextField } from "components/Form"
import { SchemaFieldOption } from "lib/types"
import { ClearFilterButton } from "./ClearFilterButton"
import { FilterValues, formatFilterValues } from "lib/helpers/formatFilterValues"
import { DateTime } from "luxon"
import validators from "lib/validators"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"

interface Props {
  filter: FormFilterProps
  categoryOptions: SchemaFieldOption[]
  onFilterChange: (filterValues: FormFilterProps) => void
}

export interface FormFilterProps {
  document_name?: string
  upload_at_start_date?: string
  upload_at_end_date?: string
  category_guid?: string
}

const UserDocumentsFilter: React.FC<Props> = ({ filter, categoryOptions, onFilterChange }) => {
  const debouncedOnSubmit = useDebouncedCallback((values: FilterValues<FormFilterProps>) => {
    const formattedValues = formatFilterValues<FormFilterProps>({
      values,
      startName: "upload_at_start_date",
      endName: "upload_at_end_date"
    })

    onFilterChange(formattedValues)
  }, 500)

  const clearFilter = () => {
    onFilterChange({})
  }

  return (
    <Box m={4} mx={0}>
      <Form
        onSubmit={debouncedOnSubmit}
        initialValues={filter}
        validate={validateFilterPeriodDates({ start: "upload_at_start_date", end: "upload_at_end_date" })}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={3}>
                <Field<string> name="document_name" label="Document name" component={TextField} />
              </Grid>
              <Grid item md={2}>
                <Field<DateTime>
                  name="upload_at_start_date"
                  component={DatePicker}
                  label="Start date"
                  validate={validators.validDate}
                />
              </Grid>
              <Grid item md={2}>
                <Field<DateTime>
                  name="upload_at_end_date"
                  component={DatePicker}
                  label="End date"
                  validate={validators.validDate}
                />
              </Grid>
              <Grid item md={3}>
                <Field
                  name="category_guid"
                  label="Category type"
                  component={Select}
                  options={[{ title: "Any", value: "" }, ...categoryOptions]}
                />
              </Grid>
              <Grid item md={2}>
                <Box textAlign="right">
                  <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </Box>
  )
}

export default memo(UserDocumentsFilter)
