import { useQuery, UseQueryResult } from "react-query"
import { IntercomDTO } from "./types"
import createAxiosInstance from "api/instance"
import Config from "lib/config"
import { enabledByMandatoryUrlParams } from "data/helpers"

const queryKeys = {
  getIntercomInbox: "GET_INTERCOM_INBOX"
}

export interface UseIntercomProps {
  enabled: boolean
  refetchOnWindowFocus?: boolean
  staleTime?: number
  retry?: number
  branchGuid?: string
}

const branchHealthApiInstance = createAxiosInstance(Config.getEnvVariable("APP_BRANCH_HEALTH_API_URL"))

export const useIntercomQuery = ({
  enabled,
  refetchOnWindowFocus = false,
  staleTime = 30000,
  retry = 0,
  branchGuid
}: UseIntercomProps): UseQueryResult<IntercomDTO, Error> => {
  return useQuery({
    queryKey: [queryKeys.getIntercomInbox, branchGuid],
    queryFn: () => branchHealthApiInstance.get(`/api/intercom/unattended-conversations?branchGuid=${branchGuid}`),
    ...enabledByMandatoryUrlParams([enabled, branchGuid]),
    refetchInterval: 60000 * 5,
    refetchOnWindowFocus,
    staleTime,
    retry
  })
}
