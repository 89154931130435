import React from "react"
import { Field } from "react-final-form"
import { FormSectionHeader, TextField } from "components/Form"
import Grid from "@mui/material/Grid"
import Auth from "lib/Auth"
import testingId from "constants/testingId"
import validators, { composeValidators } from "lib/validators"
import { BillingInformationAddressSearch } from "./BillingInformationAddressSearch"
import { FormApi } from "final-form"
import { BillingInformationClientSection } from "./BillingInformationClientSection"

type Props = {
  isEditing: boolean
  isClientContractModel?: boolean
  typeAlias?: string
  form: FormApi<any>
}

const BillingInformation: React.FC<Props> = ({ isEditing, isClientContractModel = false, typeAlias, form }) => {
  const isFinanceIdEditable = Auth.hasPermission(["Finance.Model:Edit"])

  return (
    <>
      <FormSectionHeader title="Billing Information" action={<BillingInformationAddressSearch {...{ form }} />} />
      <Grid container spacing={8} data-cy={testingId.contractModelSettings.billingSection}>
        <Grid item md={6}>
          {!isClientContractModel && (
            <>
              <Field
                name="finance.funding_name"
                label="Funding Name (FAO) *"
                component={TextField}
                validate={composeValidators([validators.required, validators.maxLength()])}
              />
              <Field
                name="finance.for_attention_of"
                label="For attention of"
                component={TextField}
                validate={validators.maxLength()}
              />
            </>
          )}
          {isClientContractModel && <BillingInformationClientSection typeAlias={typeAlias} />}
          <Field
            name="finance.address_line_1"
            label="Address Line 1 *"
            component={TextField}
            validate={composeValidators([validators.required, validators.maxLength()])}
          />
          <Field
            name="finance.address_line_2"
            label="Address Line 2"
            component={TextField}
            validate={validators.maxLength()}
          />
          <Field
            name="finance.city"
            label="City *"
            component={TextField}
            validate={composeValidators([validators.required, validators.maxLength()])}
          />
          <Field name="finance.county" label="County" component={TextField} validate={validators.maxLength()} />
          <Field
            name="finance.postcode"
            label="Post code *"
            component={TextField}
            validate={composeValidators([validators.required, validators.maxLength(16)])}
          />
          <Field
            name="finance.country"
            label="Country"
            component={TextField}
            validate={validators.maxLength()}
            defaultValue="United Kingdom"
          />
        </Grid>
        {isEditing && (
          <Grid item md={6}>
            <Field
              name="finance.finance_id"
              label="Finance Id *"
              component={TextField}
              disabled={!isFinanceIdEditable}
              validate={composeValidators([validators.required, validators.maxLength(32)])}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default React.memo(BillingInformation)
