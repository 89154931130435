import React, { PropsWithChildren, useState } from "react"
import { Modal, Box, Typography, Paper } from "@mui/material"

interface ModalProps {
  title?: string
  width?: number
  destroyModal: Function
  "data-cy"?: string
}

const ModalWrapper: React.FC<PropsWithChildren<ModalProps>> = (props) => {
  const [isOpen, setIsOpen] = useState(true)

  const handleModalClose = () => {
    setIsOpen(false)
    props.destroyModal()
  }

  return (
    <Modal
      aria-labelledby="modal-wrapper"
      aria-describedby="modal-wrapper-action"
      open={isOpen}
      onClose={handleModalClose}
      data-cy={props["data-cy"]}
      slotProps={{ backdrop: { title: "Modal Overlay" } }}
    >
      <Paper
        sx={(theme) => ({
          position: "absolute",
          width: props.width || 400,
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(3, 4, 2),
          outline: 0,
          maxHeight: "85vh",
          overflowY: "auto",
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%, -50%)`
        })}
      >
        <Box p={1} lineHeight={1.5}>
          {props.title && (
            <Typography variant="h5" mb={4}>
              {props.title}
            </Typography>
          )}
          {props.children}
        </Box>
      </Paper>
    </Modal>
  )
}

export default ModalWrapper
