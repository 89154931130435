import { Box } from "@mui/material"
import testingId from "constants/testingId"
import { Task } from "data/finance/task/types"
import { DateTime } from "luxon"
import React, { memo, useMemo } from "react"

export const StatusBar: React.FC<{ task: Task }> = memo(({ task }) => {
  const percentage = useMemo(() => {
    const start = task.started_at?.toMillis()
    const end = task.estimated_completion_at?.toMillis()

    if (!start || !end) return undefined

    const now = DateTime.local().toMillis()

    const timeLength = end - start
    const timePassed = now - start
    return (100 * timePassed) / timeLength
  }, [task])

  if (!percentage || percentage > 100 || percentage < 0 || task.processed_at) return <>{task.status}</>

  return (
    <Box
      sx={{
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center"
      }}
      data-cy={testingId.processingStatus.statusBar}
    >
      <Box
        sx={{
          minWidth: "50px",
          height: "10px",
          border: "1px solid black",
          borderRadius: 3,
          overflow: "hidden",
          flexGrow: 1,
          flexShrink: 1,
          marginRight: "5px"
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: `${percentage ?? 0}%`,
            backgroundColor: `rgba(${((255 * (100 - (percentage ?? 0))) / 100).toFixed()},${(255 * ((percentage ?? 0) / 100)).toFixed()},0)`
          }}
        />
      </Box>{" "}
      <span>{percentage.toFixed()}%</span>
    </Box>
  )
})

StatusBar.displayName = "StatusBar"
