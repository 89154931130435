import { ValidationErrors } from "final-form"
import { isEmpty } from "lodash"
import { DateTime } from "luxon"
import { ContractModelType } from "constants/modelTypes"
import { mergeDeepLeft, values as valuesR } from "ramda"
import { validatePeriodDates, validatePeriodInParent, validatePeriodWithSiblings } from "lib/dateTimeValidators"
import { ValidationErrorsDefined } from "lib/types"
import { BillingFrequency } from "data/finance/contractModel/types"

export const validateOverlappingPeriods = <
  T extends {
    start?: DateTime
    end?: DateTime
    user_contract_guid?: string
    frequency_type_alias?: BillingFrequency
    delete?: boolean
  },
  V extends { start: DateTime; end?: DateTime; no_end_date?: boolean }
>({
  items,
  values,
  label,
  compareKey
}: {
  items?: T[]
  values: V
  label: string
  compareKey?: "user_contract_guid" | "frequency_type_alias"
}): ValidationErrors[] => {
  const errors: Exclude<ValidationErrors, undefined>[] = []

  items?.forEach((item, key) => {
    errors[key] = {}

    if (item.delete || !item.start) return

    // compare item periods to mother period
    if (item.start && values.start > item.start) {
      errors[key].start = `${label} must start within contract period`
    }

    const endsAfter = item.end && values.end && !values.no_end_date && values.end < item.end
    const noItemEnd = !item.end && values.end && !values.no_end_date

    if (endsAfter || noItemEnd) {
      errors[key].end = `${label} must end within contract period`
    }

    // validate period
    if (item.start && item.end && item.start >= item.end) {
      errors[key].start = "Start date must be before end date"
      errors[key].end = "End date must be after start date"
    }

    // compare item periods
    items?.forEach((itemInn, keyInner) => {
      // comparable
      if (
        !item.start ||
        !itemInn.start ||
        itemInn.delete ||
        key === keyInner ||
        (compareKey && item[compareKey] !== itemInn[compareKey])
      )
        return

      // validate starts
      const startsInPeriod =
        (itemInn.end && item.start >= itemInn.start && item.start < itemInn.end) ||
        (!itemInn.end && item.start >= itemInn.start)

      if (startsInPeriod) {
        errors[key].start = `${label} periods can not overlap`
      }

      // validate ends
      const closedInClosed = item.end && itemInn.end && item.end > itemInn.start && item.end <= itemInn.end

      const closedInOpen = item.end && !itemInn.end && item.end > itemInn.start

      const openInClosed = !item.end && itemInn.end && item.start < itemInn.end

      const openInOpen = !item.end && !itemInn.end

      if (closedInClosed || closedInOpen || openInClosed || openInOpen) {
        errors[key].end = `${label} periods can not overlap`
      }
    })
  })

  return errors
}

// validate to model, other contracts and that has valid start-end
export const validateContract = <
  Contract extends { guid: string; start: DateTime; end?: DateTime | null; contract_guid?: string },
  Model extends { start: DateTime; end?: DateTime | null }
>({
  modelType,
  values,
  contractModel,
  existingContracts
}: {
  modelType: ContractModelType
  values: { start: DateTime; end?: DateTime | null; no_end_date?: boolean; guid?: string }
  contractModel: Model
  existingContracts: Contract[]
  contractId?: string
}): ValidationErrorsDefined => {
  let errorsOutside = {}

  // start to end
  const errorsInDates = validatePeriodDates(values)
  if (!isEmpty(errorsInDates)) return errorsInDates

  // contract period to contract model
  const parentErrors = validatePeriodInParent({
    parent: contractModel,
    values,
    itemName: "Contract",
    parentName: `${modelType === ContractModelType.INVOICE ? "Invoice" : "Payment"} model`
  })
  if (!isEmpty(parentErrors)) return parentErrors

  // period to siblings
  errorsOutside = mergeDeepLeft(
    validatePeriodWithSiblings<Contract>({
      items: existingContracts,
      values,
      label: "Contract"
    }),
    errorsOutside
  )

  return errorsOutside
}

export const mergeValidationErrorsLeft = (main: ValidationErrors[] = [], secondary: ValidationErrors[] = []) =>
  valuesR(mergeDeepLeft(main, secondary))
