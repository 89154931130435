import React, { memo, useEffect, useMemo } from "react"
import { ModalWrapper } from "components"
import { Typography, Box, Divider, Grid } from "@mui/material"
import { FormSectionHeader, TextField, Button, Select } from "components/Form"
import { Form, Field } from "react-final-form"
import Spinner from "components/Spinner"
import WarningMessage from "components/WarningMessage"
import validators from "lib/validators"
import { UseMutateAsyncFunction } from "react-query"
import { AxiosResponse } from "axios"
import testingId from "constants/testingId"
import { SummaryList } from "components/SummaryList"
import { TaskChargeReasonTypeAlias, TaskCreditTypeAlias, TaskPreparation } from "data/finance/task/types"
import { useSchema } from "data/schema/useSchema"

interface OwnProps {
  destroyModal: () => void
  postBulk: UseMutateAsyncFunction<TaskPreparation, unknown, unknown>
  preparationData?: TaskPreparation
  putBulk: UseMutateAsyncFunction<
    AxiosResponse<any>,
    unknown,
    {
      taskGuid: string
      charge_reason_type_alias: TaskChargeReasonTypeAlias
      credit_type_alias: TaskCreditTypeAlias
      note: string
    },
    unknown
  >
  isError: boolean
  isInvoiceModel: boolean
}

interface CreditFormValues {
  credit_type_alias: TaskCreditTypeAlias
  charge_reason_type_alias: TaskChargeReasonTypeAlias
  note: string
}

export const AppointmentSearchBulkModal: React.FC<OwnProps> = memo(
  ({ isInvoiceModel, destroyModal, postBulk, preparationData, putBulk, isError }) => {
    const { TransactionCreditReason, TransactionCredit } = useSchema(["TransactionCreditReason", "TransactionCredit"])

    useEffect(() => {
      postBulk({})
    }, [postBulk])

    const isProcessable = useMemo(() => {
      return preparationData?.contracts?.length ?? false
    }, [preparationData])

    const onSubmit = async (values: CreditFormValues) => {
      if (preparationData?.task_guid) {
        await putBulk({ ...values, taskGuid: preparationData.task_guid })
        destroyModal()
      }
    }

    const cancelButton = () => (
      <Button data-cy={testingId.cancelButton} fullWidth={false} variant="text" onClick={destroyModal}>
        Cancel
      </Button>
    )

    return (
      <ModalWrapper
        {...{
          "data-cy": testingId.appointmentSearch.bulkModal,
          title: `Bulk ${isInvoiceModel ? "Invoicing" : "Payments"} Credit`,
          destroyModal,
          width: 700
        }}
      >
        {!preparationData ? (
          <Spinner />
        ) : (
          <>
            <FormSectionHeader title="Selection summary" />
            {preparationData.contracts?.length ? (
              <SummaryList
                {...{
                  headers: [isInvoiceModel ? "Invoice Model" : "Payment model", "Clients", "Visits", "Total value"],
                  items: preparationData.contracts,
                  totalLabel: "Total clients:"
                }}
              />
            ) : null}
            <Form onSubmit={onSubmit}>
              {({ handleSubmit }) =>
                isProcessable ? (
                  <>
                    <FormSectionHeader title="Credit options" />
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <Field
                            name="charge_reason_type_alias"
                            label="Credit note reason *"
                            component={Select}
                            options={TransactionCreditReason}
                            validate={validators.required}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <Field
                            name="credit_type_alias"
                            label="Credit action *"
                            component={Select}
                            options={TransactionCredit}
                            validate={validators.required}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <Field<string>
                            name="note"
                            component={TextField}
                            multiline
                            minRows={5}
                            label="Notes *"
                            validate={validators.required}
                          />
                        </Grid>
                      </Grid>

                      <Divider color="divider" />
                      <Box m={3} mx={0} display="flex" justifyContent="flex-end">
                        {cancelButton()}
                        <Button
                          data-cy={testingId.appointmentSearch.creditAppointmentsButton}
                          fullWidth={false}
                          color="primary"
                          type="submit"
                          sx={(theme) => ({
                            marginLeft: theme.spacing(3)
                          })}
                        >
                          Credit appointments
                        </Button>
                      </Box>
                    </form>
                  </>
                ) : (
                  <>
                    <Box m={1} mx={0}>
                      <WarningMessage>{"No appointments to credit."}</WarningMessage>
                    </Box>
                    <Box m={3} mx={0} display="flex" justifyContent="flex-end">
                      {cancelButton()}
                    </Box>
                  </>
                )
              }
            </Form>
          </>
        )}
        {isError ? (
          <>
            <Box m={3} mx={0} display="flex" justifyContent="center" flexDirection="column">
              <Typography variant="h5">Something went wrong!</Typography>
              <br />
              <Typography paragraph>
                We apologize, we were not able to complete your request. Please try again later. If the problem
                persists, please contact the user support.
              </Typography>
            </Box>
            <Divider color="divider" />
            <Box m={1} mx={0} display="flex" justifyContent="flex-end">
              {cancelButton()}
            </Box>
          </>
        ) : null}
      </ModalWrapper>
    )
  }
)

AppointmentSearchBulkModal.displayName = "AppointmentSearchBulkModal"
