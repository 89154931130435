import { ApiContractAccrualItem } from "data/finance/carer/types"
import { Pagination } from "lib/types"
import { reverse } from "ramda"
import React, { memo, useMemo } from "react"

interface OwnProps {
  filteredItems: ApiContractAccrualItem[]
  index: number
  pagination: Pagination
}

export const CarerAccrualDetailListItemTotal: React.FC<OwnProps> = memo(({ filteredItems, index, pagination }) => {
  CarerAccrualDetailListItemTotal.displayName = "CarerAccrualDetailListItemTotal"

  const cumulativeTotal = useMemo(() => {
    const paginatedIndex = index + (+pagination.page - 1) * +pagination.page_size

    const total = reverse(filteredItems)
      .slice(0, filteredItems.length - paginatedIndex)
      .reduce((result, item) => result + item.amount, 0)

    return total.toFixed(2)
  }, [filteredItems, index, pagination])

  return <span>{cumulativeTotal}</span>
})
