import { Box } from "@mui/material"
import { ContentContainer, Divider, EmptyData, Paginator } from "components"
import DatesMessageHelp from "components/DatesMessageHelp"
import { usePagination } from "components/handlers/usePagination"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { usePaymentModelRateListQuery } from "data/finance/contractModel/queries"
import { PaymentModelRate } from "data/finance/contractModel/types"
import { useIsBeingSearched } from "data/helpers"
import React, { memo } from "react"
import { ContractModelPageProps } from "../types"
import { PaymentModelBaseRateListFilter } from "./components/PaymentModelBaseRateListFilter"
import { PaymentModelBaseRateListTable } from "./components/PaymentModelBaseRateListTable"
import { PaymentModelBaseRateListTitle } from "./components/PaymentModelBaseRateListTitle"
import { useLocation } from "react-router-dom"

export const PaymentModelBaseRateList: React.FC<ContractModelPageProps> = memo(({ contractModel, modelType }) => {
  const location = useLocation()

  const { data: rates, refetch } = usePaymentModelRateListQuery({
    contractGuid: contractModel.guid,
    search: location.search
  })

  const { pagination, setPagination, resetPagination } = usePagination()

  const isSearched = useIsBeingSearched<PaymentModelRate>(rates)

  return (
    <ContentContainer>
      <PaymentModelBaseRateListTitle {...{ contractModel, modelType }} />
      <Box m={3} mx={0}>
        <PaymentModelBaseRateListFilter {...{ pagination, resetPagination }} />
        <Divider color="divider" />
        <DatesMessageHelp messageType="E" />
        <RequestLoaderWrapper my={3}>
          {rates && isSearched ? (
            <>
              <PaymentModelBaseRateListTable {...{ rates, contractModel, refetch }} />
              <Paginator {...{ setPagination, itemsLength: rates?.length }} />
            </>
          ) : (
            <EmptyData message="This payment model has no base rates. Start by adding one." />
          )}
        </RequestLoaderWrapper>
      </Box>
    </ContentContainer>
  )
})

PaymentModelBaseRateList.displayName = "PaymentModelBaseRateList"
