import React, { Dispatch, memo, SetStateAction, useMemo } from "react"
import { EmptyData, Paginator } from "components"
import { FileManagementTable } from "./FileManagementTable"
import { useSelectableIdsDictionary } from "../../../../lib/hooks"
import { ContractModelType } from "constants/modelTypes"
import { useInvoiceFileListQuery } from "data/finance/invoiceFile/queries"
import { usePaymentFileListQuery } from "data/finance/paymentFile/queries"
import { InvoiceFileItem } from "data/finance/invoiceFile/types"
import { FileManagementActionButtons } from "./FileManagementActionButtons"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { useIsInvoiceModelSearch } from "lib/hooks/useIsInvoiceModelSearch"
import { isEmpty } from "ramda"
import { Pagination, SetPagination } from "lib/types"
import Auth from "lib/Auth"
import { Permission } from "constants/permission"
import { InvoiceFilesFilterValues } from "../types"

interface OwnProps {
  modelType: ContractModelType
  pagination: Pagination
  setPagination: SetPagination
  setFilter: Dispatch<SetStateAction<InvoiceFilesFilterValues>>
  showITrent: boolean
}

export const FileManagementTab: React.FC<OwnProps> = memo(
  ({ modelType, pagination, setPagination, setFilter, showITrent }) => {
    FileManagementTab.displayName = "FileManagementTab"

    const isInvoice = useIsInvoiceModelSearch()

    const {
      data: invoiceFiles,
      refetch: refetchInvoiceFileList,
      isLoading: isInvoiceLoading
    } = useInvoiceFileListQuery({
      enabled: isInvoice
    })
    const {
      data: paymentFiles,
      refetch: refetchPaymentFileList,
      isLoading: isPaymentLoading
    } = usePaymentFileListQuery({
      enabled: !isInvoice
    })

    const files = isInvoice ? invoiceFiles : paymentFiles
    const isLoading = isInvoice ? isInvoiceLoading : isPaymentLoading

    const isDeveloper = Auth.hasPermission([Permission.DEVELOPER_EDIT])

    const selectableIds: string[] | undefined = useMemo(
      () =>
        files && files.filter((item: InvoiceFileItem) => item.is_processable).map((item: InvoiceFileItem) => item.guid),
      [files]
    )

    // invoice
    const {
      selectableIds: selectableRows,
      toggleId: onSelectRow,
      toggleAll,
      getSelectedAsStringArr: getSelectedIdsForInterface
    } = useSelectableIdsDictionary(selectableIds)

    const interfaceDisabled = isEmpty(selectableRows)

    const emptyTableMessage = modelType === ContractModelType.INVOICE ? "No invoices found." : "no payments found."

    // i-trent resubmit
    const selectableIdsForITrentResubmit: string[] | undefined = useMemo(() => {
      if (!showITrent || !files) return

      return files
        .filter((item: InvoiceFileItem) => item.payroll_status === "Failed")
        .map((item: InvoiceFileItem) => item.guid)
    }, [files, showITrent])

    // payment
    const {
      selectableIds: selectableIdsForITrent,
      toggleId: toggleIdForITrent,
      getSelectedAsStringArr: getSelectedIdsForITrent,
      toggleAll: toggleAllForITrent
    } = useSelectableIdsDictionary(selectableIdsForITrentResubmit)

    const selectAllForITrentDisabled = isEmpty(selectableIdsForITrent)

    return (
      <RequestLoaderWrapper>
        {!isLoading && files?.length ? (
          <>
            <FileManagementTable
              {...{
                items: files,
                onSelectRow,
                modelType,
                toggleAll,
                selectableRows,
                interfaceDisabled,
                setFilter,
                showITrent,
                selectableIdsForITrent,
                toggleIdForITrent,
                toggleAllForITrent,
                selectAllForITrentDisabled,
                idsArrayForInterface: getSelectedIdsForInterface(),
                idsArrayForITrent: getSelectedIdsForITrent()
              }}
            />
          </>
        ) : (
          <EmptyData message={emptyTableMessage} />
        )}

        {files?.length || pagination.page !== "1" ? (
          <Paginator
            {...{
              setPagination,
              itemsLength: files?.length,
              rowsPerPageOptions: [10, 25, 50, 100, 250, 500],
              initialPageSize: 500
            }}
          />
        ) : null}
        {files?.length ? (
          <FileManagementActionButtons
            {...{
              modelType,
              files,
              refetchInvoiceFileList,
              refetchPaymentFileList,
              idsArrayForInterface: getSelectedIdsForInterface(),
              showITrent,
              idsArrayForITrent: getSelectedIdsForITrent(),
              isDeveloper
            }}
          />
        ) : null}
      </RequestLoaderWrapper>
    )
  }
)
