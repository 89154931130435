import React, { memo, useEffect, useState } from "react"
import { Typography } from "@mui/material"
import testingId from "constants/testingId"

const refreshIntervalInSeconds = 60

interface SyncRefreshStatusTimerProps {
  callback: () => void
}

const SyncRefreshStatusTimer: React.FC<SyncRefreshStatusTimerProps> = ({ callback }) => {
  const [timeLeft, setTimeLeft] = useState(refreshIntervalInSeconds)

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)
    if (timeLeft === 0) {
      callback()
      setTimeLeft(refreshIntervalInSeconds)
    }
    return () => clearTimeout(timer)
  }, [timeLeft, callback])

  return (
    <Typography variant={"body2"} data-cy={testingId.syncStatus.refreshRateTitle}>
      Refresh interval is {refreshIntervalInSeconds} seconds. Time left until next refresh: <b>{timeLeft} </b>
      seconds.
    </Typography>
  )
}

export default memo(SyncRefreshStatusTimer)
