import { Box, Grid } from "@mui/material"
import { ContentContainer, Link, StickyBox } from "components"
import BottomErrorMessage from "components/BottomErrorMessage"
import { Button, CancelButton, TextField } from "components/Form"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import {
  useInvoiceModelRateCreateMutation,
  useInvoiceModelRateUpdateMutation
} from "data/finance/contractModel/mutations"
import { useInvoiceModelRateListQuery, useInvoiceModelRateQuery } from "data/finance/contractModel/queries"
import { InvoiceModelRateRequest } from "data/finance/contractModel/types"
import { FORM_ERROR, ValidationErrors } from "final-form"
import validators from "lib/validators"
import React, { memo, useCallback } from "react"
import { Field, Form } from "react-final-form"
import { ContractModelTitle } from "../components"
import { ContractModelPageProps } from "../types"
import dateTimeUtils, { DateTimeFormat } from "lib/datetime"
import { isEmpty } from "ramda"
import { Permission } from "constants/permission"
import { WithPermission } from "components/Auth"
import Auth from "lib/Auth"
import { DateField } from "components/Form/DateField"
import { FrequencySelectField } from "components/Form/FrequencySelectField"
import { useParams } from "react-router-dom"

export const InvoiceModelStandingChargeDetailComponent: React.FC<ContractModelPageProps> = memo(
  ({ contractModel, modelType }) => {
    InvoiceModelStandingChargeDetailComponent.displayName = "InvoiceModelStandingChargeDetailComponent"
    const { rateId } = useParams()
    const canEdit = Auth.hasPermission([Permission.FINANCE_MODEL_RATE_EDIT])

    const { data: rate } = useInvoiceModelRateQuery({ contractGuid: contractModel.guid, contractRateGuid: rateId })
    const { data: rates } = useInvoiceModelRateListQuery({ contractGuid: contractModel.guid })
    const { mutateAsync: createRate } = useInvoiceModelRateCreateMutation()
    const { mutateAsync: updateRate } = useInvoiceModelRateUpdateMutation()

    const validate = useCallback(
      (values: InvoiceModelRateRequest): ValidationErrors | undefined => {
        let errors: ValidationErrors = {}

        // check with contract model
        if (values.start && values.start < contractModel.start) {
          errors.start = `Can not start before contract model starts (${dateTimeUtils.formatDate(contractModel.start)})`
        }
        if (contractModel.end && (!values.end || values.end > contractModel.end)) {
          errors.end = `Can not end after contract model ends (${dateTimeUtils.formatDate(contractModel.end)})`
        }

        // compare start & end
        if (values.start && values.end && values.start.toMillis() >= values.end.toMillis()) {
          errors = {
            start: "Must start before end",
            end: "Must start before end"
          }
        }
        if (!isEmpty(errors)) return errors

        // check with other rates
        const filteredRates = rates?.filter(
          (filteredRate) =>
            filteredRate.guid !== values.guid && filteredRate.frequency_type_alias === values.frequency_type_alias
        )

        const overlappedRate = filteredRates?.find((filteredRate) => {
          const rateStart = filteredRate.start.toMillis()
          const valueStart = values.start?.toMillis()

          const sameStart = rateStart === valueStart
          const overlapsLeft = rateStart < valueStart && (!filteredRate.end || filteredRate.end.toMillis() > valueStart)
          const overlapsRight = rateStart >= valueStart && (!values.end || values.end.toMillis() > rateStart)

          return overlapsLeft || sameStart || overlapsRight
        })

        if (overlappedRate) {
          return {
            [FORM_ERROR]: `Can not overlap with existing Standing Charge (${overlappedRate.start.toFormat(
              DateTimeFormat.DATE
            )} - ${
              overlappedRate.end ? overlappedRate.end.toFormat(DateTimeFormat.DATE) : "--/--/--"
            }) with the same Frequency Type`,
            start: " ",
            end: " "
          }
        }
        return undefined
      },
      [rates, contractModel]
    )

    const onSubmit = useCallback(
      (values: InvoiceModelRateRequest) => {
        if (rateId) {
          updateRate({ modelGuid: contractModel.guid, rateGuid: rateId, payload: values })
        } else {
          createRate({ modelGuid: contractModel.guid, payload: values })
        }
      },
      [contractModel.guid, createRate, rateId, updateRate]
    )

    return (
      <ContentContainer>
        <ContractModelTitle
          {...{
            modelType,
            title: (
              <>
                <Link to="../settings">{contractModel.title}</Link> /{" "}
                <Link to="../standing-charges">Standing Charges</Link> / {rateId ? "Detail" : "New"}
              </>
            )
          }}
        />
        <Box m={3} mx={0}>
          <RequestLoaderWrapper my={3}>
            <Form {...{ onSubmit, initialValues: rate || {}, validate }}>
              {({ handleSubmit, pristine, submitting, error }) => {
                return (
                  <form {...{ onSubmit: handleSubmit }}>
                    <Grid container spacing={3}>
                      <Grid item md={4}>
                        <DateField
                          name="start"
                          label="Starts on"
                          required
                          minDate={contractModel.start}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <DateField
                          name="end"
                          label="Ends before"
                          maxDate={contractModel.end ? contractModel.end : undefined}
                          disabled={!canEdit}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md={2}>
                        <Field
                          name="rate"
                          label="Rate *"
                          component={TextField}
                          validate={validators.validateCommonNumber({ min: 0 })}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <Field
                          name="hours"
                          label="Chargeable Hours *"
                          component={TextField}
                          validate={validators.validateCommonNumber({ precisionValue: 2, min: 0.01 })}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <FrequencySelectField label="Frequency Type" required />
                      </Grid>
                    </Grid>

                    <StickyBox>
                      {error ? (
                        <Grid container>
                          <Grid item md={8}>
                            <BottomErrorMessage message={error} />
                          </Grid>
                        </Grid>
                      ) : null}
                      <CancelButton disabled={submitting} pristine={pristine} navigateUrl="../standing-charges" />
                      <Button
                        type="submit"
                        fullWidth={false}
                        disabled={pristine || submitting || !canEdit}
                        sx={(theme) => ({
                          margin: theme.spacing(0, 0, 0, 3)
                        })}
                      >
                        SAVE
                      </Button>
                    </StickyBox>
                  </form>
                )
              }}
            </Form>
          </RequestLoaderWrapper>
        </Box>
      </ContentContainer>
    )
  }
)

export const InvoiceModelStandingChargeDetail: React.FC<ContractModelPageProps> = memo((props) => {
  return (
    <WithPermission
      {...{ showDenied: true, permissions: [Permission.FINANCE_MODEL_RATE_EDIT, Permission.FINANCE_MODEL_RATE_READ] }}
    >
      <InvoiceModelStandingChargeDetailComponent {...props} />
    </WithPermission>
  )
})

InvoiceModelStandingChargeDetailComponent.displayName = "InvoiceModelStandingChargeDetailComponent"
InvoiceModelStandingChargeDetail.displayName = "InvoiceModelStandingChargeDetail"
