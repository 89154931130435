import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Box, Typography } from "@mui/material"
import Container from "./Container"

const InvitationSuccess = () => {
  const navigate = useNavigate()
  return (
    <Container title="Account created" hasLoginLink={false}>
      <Box sx={{ flex: 1 }}>
        <Typography mb={4} color="textSecondary">
          Your password has been successfully set. You can now sign in to your account.
        </Typography>
        <Button variant="contained" size="large" fullWidth onClick={() => navigate("/login")}>
          Continue to sign in with email
        </Button>
      </Box>
    </Container>
  )
}

export default InvitationSuccess
