import React, { memo } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { moneyFormat } from "lib/utils"
import testingId from "constants/testingId"
import { ContractModelType } from "constants/modelTypes"
import { TaskPreparation, TaskPreparationCreditMode } from "data/finance/task/types"

interface Props {
  summary?: TaskPreparation
  credit_mode?: TaskPreparationCreditMode
  modelType: ContractModelType
}

const invoiceHeaders = ["£ Visits", "£ Extras", "£ Total value"]
const paymentCreditVisitsHeaders = ["Carers", "Visits", "Total value"]
const paymentCreditExtrasHeaders = ["Extras", "Total value"]

const CreditingSummary: React.FC<Props> = ({ summary, credit_mode, modelType }) => {
  const isInvoiceModel = modelType === ContractModelType.INVOICE
  const isItemsMode = credit_mode === TaskPreparationCreditMode.ITEMS
  const isChargeMode = credit_mode === TaskPreparationCreditMode.CHARGES

  const displayNumberOfCarers = !isInvoiceModel && !isChargeMode
  const displayNumberOfVisits = !isInvoiceModel && !isChargeMode
  const displayExtrasAmount = isInvoiceModel || (!isInvoiceModel && isChargeMode)
  const displayVisitsAmount = isInvoiceModel

  const getHeaders = () => {
    if (isInvoiceModel) return invoiceHeaders
    if (isItemsMode) return paymentCreditVisitsHeaders
    if (isChargeMode) return paymentCreditExtrasHeaders
    return []
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          {getHeaders().map((key, index) => (
            <TableCell data-cy={testingId.creditingModal.headerCell} key={index}>
              {key}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {summary?.contracts?.map((contract, index) => (
          <TableRow key={index} data-cy={testingId.creditingModal.listRow}>
            {displayVisitsAmount && (
              <TableCell data-cy={testingId.creditingModal.listCellTotalVisits}>
                {moneyFormat.format(contract.visit_total)}
              </TableCell>
            )}
            {displayNumberOfCarers && (
              <TableCell data-cy={testingId.creditingModal.listCellUsers}>{contract.users}</TableCell>
            )}
            {displayNumberOfVisits && (
              <TableCell data-cy={testingId.creditingModal.listCellVisits}>{contract.visits}</TableCell>
            )}
            {displayExtrasAmount && (
              <TableCell data-cy={testingId.creditingModal.listCellTotalExtra}>
                {moneyFormat.format(contract.extra_total)}
              </TableCell>
            )}
            <TableCell data-cy={testingId.creditingModal.listCellTotal}>{moneyFormat.format(contract.total)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default memo(CreditingSummary)
