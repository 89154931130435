import { ContractModelType } from "constants/modelTypes"
import {
  useContractModelCalendarCreateMutation,
  useContractModelCalendarUpdateMutation,
  useContractModelCalendarPeriodsPatchMutation
} from "data/finance/contractModel/mutations"
import { dissoc } from "ramda"
import { useCallback } from "react"
import { getNewestPeriod, getOldestPeriod, sortPeriodsByDate } from "../components/calendars/dateUtils"
import { getContractModelUrl } from "data/finance/contractModel/helpers"
import { useNavigate } from "react-router-dom"
import { useApiMessage } from "stores"
import { CalendarPeriod, CalendarRequest } from "data/finance/contractModel/types"
import { NewCalendarWithPeriodsRequest } from "../types"

const createPeriodsPayload = (periods?: CalendarPeriod[]): CalendarPeriod[] => {
  return sortPeriodsByDate({ periods, removeDeleted: true }).map((item) => {
    if (item.guid?.indexOf("new-item-") === 0) {
      return dissoc("guid", item)
    }
    return item
  }) as CalendarPeriod[]
}

interface HandlerProps {
  modelType: ContractModelType
  contractGuid: string
}

const useCreateOrUpdateCalendarWithPeriodsHandler = ({
  modelType,
  contractGuid
}: HandlerProps): ((values: NewCalendarWithPeriodsRequest) => Promise<void>) => {
  const { mutateAsync: createCalendar } = useContractModelCalendarCreateMutation()
  const { mutateAsync: editCalendar } = useContractModelCalendarUpdateMutation()
  const { mutateAsync: createPeriods } = useContractModelCalendarPeriodsPatchMutation()
  const navigate = useNavigate()
  const { showSuccessMessage, showErrorMessage } = useApiMessage()

  return useCallback(
    async (values: NewCalendarWithPeriodsRequest) => {
      const filteredPeriods = createPeriodsPayload(values.periods)
      const oldestPeriod = getOldestPeriod(filteredPeriods)
      const newestPeriod = getNewestPeriod(filteredPeriods)

      const calendar: CalendarRequest = {
        start: oldestPeriod.start,
        end: newestPeriod.end.plus({ days: 1 }),
        frequency_type_alias: values.frequency_type_alias,
        title: values.title
      }

      const navigateToList = () =>
        navigate(`/finance/contract-models/${getContractModelUrl(modelType)}/${contractGuid}/calendars`)

      if (values.guid) {
        const calendarGuid = values.guid
        try {
          await editCalendar({ modelType, contractGuid, calendarGuid, calendar }).then(() => {
            createPeriods({ modelType, contractGuid, calendarGuid, periods: filteredPeriods })
          })

          navigateToList()
          showSuccessMessage("Calendar for contract successfully updated.")
        } catch (apiError: any) {
          showErrorMessage("Something went wrong, please try again.", { apiError })
        }
      }

      if (!values.guid) {
        try {
          await createCalendar({ modelType, contractGuid, calendar }).then((newCalendar) => {
            createPeriods({ modelType, contractGuid, calendarGuid: newCalendar.guid, periods: filteredPeriods })
          })
          navigateToList()
          showSuccessMessage("Calendar for contract successfully created.")
        } catch (apiError: any) {
          showErrorMessage("Something went wrong, please try again.", { apiError })
        }
      }
    },
    [
      modelType,
      contractGuid,
      createCalendar,
      createPeriods,
      showSuccessMessage,
      editCalendar,
      navigate,
      showErrorMessage
    ]
  )
}

export default useCreateOrUpdateCalendarWithPeriodsHandler
