import React, { useCallback, useEffect, useState, useMemo } from "react"
import { useDebounce } from "use-debounce"
import { Button, TextField } from "components/Form"
import { ListItemText, FormHelperText, TextField as MuiTextField, Box, Autocomplete, ListItem } from "@mui/material"
import testingId from "constants/testingId"
import { useClientAddressFromSearchId, useClientAddressSuggestionsQuery } from "data/core/client/queries"
import { ApiAddressSearchContract } from "models"
import { AutoCompleteOptionList } from "components/AutoComplete"
import { Field, useForm } from "react-final-form"

interface Props {
  shouldRenderAddressDetails?: boolean
  onUpdate?: () => void
  error?: string
  required?: boolean
}

interface AddressFields {
  address: string
  address_details: string
  address_post_code: string
  address_latitude: number
  address_longitude: number
}

const GeoAddressField = <T extends { profile: AddressFields }>({
  shouldRenderAddressDetails = false,
  onUpdate,
  error,
  required
}: Props) => {
  const form = useForm<T>()

  const [isAddressBeingEdited, setIsAddressBeingEdited] = useState(false)
  const [isAddressDetailDisabled, setIsAddressDetailDisabled] = useState(true)
  const { address: currentAddress, address_post_code: currentPostCode } = form.getState().values.profile

  const currentValue = useMemo(() => {
    if (currentAddress || currentPostCode) {
      const isAlreadyExistPostCodeInAddress = currentAddress?.indexOf(currentPostCode ?? "") !== -1

      return isAlreadyExistPostCodeInAddress ? `${currentAddress}` : `${currentAddress} ${currentPostCode}`
    }

    return ""
  }, [currentAddress, currentPostCode])

  const [searchValue, setSearchValue] = useState("")
  const [debouncedSearchValue] = useDebounce(searchValue, 300)
  const [selectedOption, setSelectedOption] = useState<AutoCompleteOptionList | null>(null)
  const { data: suggestions = [], isLoading } = useClientAddressSuggestionsQuery({ search: debouncedSearchValue })
  const { data: addressDetail } = useClientAddressFromSearchId({ searchId: selectedOption?.id as string })
  const autocompleteOptions = useMemo<AutoCompleteOptionList[]>(
    () =>
      suggestions
        .filter(({ isGroup }) => !isGroup)
        .map((suggestion) => ({
          id: suggestion.id,
          label: suggestion.label
        })),
    [suggestions]
  )

  const getFullAddress = useCallback((address: ApiAddressSearchContract) => {
    const { organisation, address_line_1, address_line_2, city, county, postcode } = address
    return [organisation, address_line_1, address_line_2, city, county, postcode].filter(Boolean).join(", ")
  }, [])

  /**
   * Validate if is the same address
   */
  const isEqualAddress = useCallback(
    (addressOnForm: AddressFields, selectedAddress: ApiAddressSearchContract) => {
      const isEqualCoordinates =
        addressOnForm.address_latitude === selectedAddress.latitude &&
        addressOnForm.address_longitude === selectedAddress.longitude
      const isEqualAddressName = addressOnForm.address === getFullAddress(selectedAddress)

      return isEqualCoordinates && isEqualAddressName
    },
    [getFullAddress]
  )

  const updateFormAddressFields = useCallback(
    (selectedAddress: ApiAddressSearchContract) => {
      const { values } = form.getState()

      if (isEqualAddress(values["profile"], selectedAddress)) {
        return
      }

      if (onUpdate) {
        onUpdate()
      }

      form.batch(() => {
        form.change("profile.address" as keyof T, getFullAddress(selectedAddress) as unknown as T[keyof T])
        form.change("profile.address_post_code" as keyof T, selectedAddress.postcode as unknown as T[keyof T])
        form.change("profile.address_latitude" as keyof T, selectedAddress.latitude as unknown as T[keyof T])
        form.change("profile.address_longitude" as keyof T, selectedAddress.longitude as unknown as T[keyof T])
      })
    },
    [form, getFullAddress, onUpdate, isEqualAddress]
  )

  const resetFormAddressFields = useCallback(() => {
    form.batch(() => {
      const emptyValue = "" as unknown as T[keyof T]
      form.change("profile.address" as keyof T, emptyValue)
      form.change("profile.address_post_code" as keyof T, emptyValue)
      form.change("profile.address_latitude" as keyof T, emptyValue)
      form.change("profile.address_longitude" as keyof T, emptyValue)
    })
  }, [form])

  useEffect(() => {
    if (!addressDetail) {
      return
    }
    updateFormAddressFields(addressDetail)
  }, [addressDetail, updateFormAddressFields])

  useEffect(() => {
    if (form.getState().values?.profile.address) {
      setIsAddressDetailDisabled(false)
    }
  }, [form])

  const selectAddress = useCallback(
    (option: AutoCompleteOptionList) => {
      setSelectedOption(option)
      if (!option) {
        resetFormAddressFields()
        return
      }
      setIsAddressBeingEdited(false)
      setIsAddressDetailDisabled(false)
    },
    [resetFormAddressFields]
  )

  return (
    <>
      <Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              marginBottom: 1
            }}
          >
            <Autocomplete
              loading={isLoading}
              open={isAddressBeingEdited && !!searchValue}
              loadingText="Searching..."
              sx={({ spacing }) => ({ paddingTop: spacing(2) })}
              disabled={!isAddressBeingEdited}
              noOptionsText="No result found. Try searching using only the street name or postcode to refine your search."
              fullWidth
              autoHighlight
              options={autocompleteOptions}
              inputValue={isAddressBeingEdited ? searchValue : currentValue}
              // workaround for https://github.com/mui/material-ui/issues/20068
              filterOptions={(options: AutoCompleteOptionList[]) => options}
              value={selectedOption}
              onInputChange={(_, newInputValue) => {
                setSearchValue(newInputValue)
              }}
              isOptionEqualToValue={(option: AutoCompleteOptionList, value: AutoCompleteOptionList) =>
                option.id === value.id
              }
              getOptionLabel={(option: AutoCompleteOptionList) => option.label}
              renderOption={(props, option: AutoCompleteOptionList) => (
                <ListItem {...props} key={option.id} onClick={() => selectAddress(option)}>
                  <ListItemText primary={option.label} data-cy={testingId.selectOption} data-value={option.id} />
                </ListItem>
              )}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  name="client-address-autocomplete"
                  label="Address *"
                  variant="outlined"
                  placeholder="Type address"
                  error={!!error}
                  helperText={error}
                  data-cy={testingId.addressAutocompleteInput}
                  required={required}
                />
              )}
            />
            {isAddressBeingEdited && <FormHelperText>Type a part of address or postcode to begin</FormHelperText>}
          </Box>
          <Box display="none">
            <Field name="profile.address" component={TextField} type="hidden" />
            <Field name="profile.address_post_code" component={TextField} type="hidden" />
            <Field name="profile.address_latitude" component={TextField} type="hidden" />
            <Field name="profile.address_longitude" component={TextField} type="hidden" />
          </Box>
          {!isAddressBeingEdited && (
            <Button
              id="geo-location-edit-address-btn"
              variant="text"
              fullWidth={false}
              onClick={() => {
                setSearchValue(currentValue)
                setIsAddressBeingEdited(true)
              }}
              sx={{ marginLeft: 2, whiteSpace: "nowrap", paddingTop: 2 }}
              data-cy={testingId.addressEditButton}
            >
              Edit Address
            </Button>
          )}
        </Box>
      </Box>
      {shouldRenderAddressDetails && (
        <Field
          name="profile.address_details"
          label="Apartment, suite, unit, etc. (optional)"
          component={TextField}
          disabled={isAddressDetailDisabled}
        />
      )}
    </>
  )
}

export default GeoAddressField
