import { Box, Grid } from "@mui/material"
import { ClearFilterButton } from "components/ClearFilterButton"
import { FormSpyCustom, TextField } from "components/Form"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { StatusEnum } from "data/commonTypes"
import { FilterValues, formatFilterValues } from "lib/helpers/formatFilterValues"
import { Pagination } from "lib/types"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"
import { UserContractTab } from "./types"
import { DateField } from "components/Form/DateField"
import { StatusSelectField } from "components/Form/StatusSelectField"
import { validateFilterPeriodDates } from "lib/dateTimeValidators"

export const defaultFilterValues = {
  tabType: UserContractTab.FILES
}

export type UserContractFileFilter = {
  start?: string
  end?: string
  status?: StatusEnum
  filename?: string
  tabType?: UserContractTab
}

interface OwnProps {
  filter: UserContractFileFilter
  setFilter: Dispatch<SetStateAction<UserContractFileFilter>>
  pagination: Pagination
  resetPagination: () => void
}

export const ContractFileListFilter: React.FC<OwnProps> = memo(({ filter, setFilter, pagination, resetPagination }) => {
  const onSubmit = useDebouncedCallback((values: FilterValues<UserContractFileFilter>) => {
    const formattedValues = formatFilterValues<UserContractFileFilter>({
      values,
      startName: "start",
      endName: "end"
    })

    setFilter(formattedValues)
    resetPagination()
  }, 300)

  const clearFilter = () => {
    setFilter(defaultFilterValues)
    resetPagination()
  }

  useFilterLocationChange({ filter, pagination })

  return (
    <Box
      display="flex"
      my={2}
      sx={(theme) => ({
        pb: 3,
        borderBottom: "1px solid",
        borderColor: theme.palette.primary.main
      })}
    >
      <Form
        {...{
          onSubmit,
          initialValues: filter,
          validate: validateFilterPeriodDates({ start: "start", end: "end", allowSameDay: false })
        }}
      >
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormSpyCustom {...{ handleSubmit }} />
              <Grid container alignItems="center" spacing={1}>
                <Grid item md={3}>
                  <DateField name="start" label="Starts after" />
                </Grid>
                <Grid item md={3}>
                  <DateField name="end" label="Ends before" />
                </Grid>
                <Grid item md={3}>
                  <StatusSelectField withAny />
                </Grid>
                <Grid item md={3}>
                  <Field name="filename" label="Filename" component={TextField} />
                </Grid>
                <Grid item md={12}>
                  <Box textAlign="right">
                    <ClearFilterButton onClick={clearFilter} disabled={Object.keys(filter).length === 0} />
                  </Box>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Form>
    </Box>
  )
})

ContractFileListFilter.displayName = "ContractFileListFilter"
