import React, { ReactNode } from "react"
import { Box, Typography } from "@mui/material"
import { Error } from "@mui/icons-material"

interface ErrorPageProps {
  title?: string
  description?: string
  children?: ReactNode
}

const ErrorPage = ({
  title = "Something went wrong!",
  description = "Please try again later or contact user support.",
  children,
}: ErrorPageProps) => {
  return (
    <Box display="flex" justifyContent="center" height="100vh" mx={"auto"} alignItems="center" flexDirection="column">
      <Box p={10} display="flex" alignItems="center" flexDirection="column">
        <Typography paragraph color="primary">
          <Error style={{ fontSize: 72 }} />
        </Typography>
        <Typography
          paragraph
          variant="h2"
          sx={(theme) => ({
            margin: theme.spacing(2)
          })}
          color="textSecondary"
        >
          {title}
        </Typography>
        <Typography
          paragraph
          variant="h5"
          sx={(theme) => ({
            margin: theme.spacing(2)
          })}
          align="center"
        >
          {description}
        </Typography>
        {children}
      </Box>
    </Box>
  )
}
export default ErrorPage
