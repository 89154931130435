import { FinanceApi } from "api"
import { enabledByMandatoryUrlParams, setDayBorders } from "data/helpers"
import { removeFromSearchString } from "lib/helpers/removeFromSearchString"
import { QueryKey, useQuery, UseQueryResult } from "react-query"
import { Task } from "../task/types"

export enum QueryKeyParts {
  INVOICE_TASK = "invoice_task",
  LIST = "list"
}

export const queryKeys = {
  getInvoiceTaskList: ({ search }: { search?: string }): QueryKey => [
    QueryKeyParts.INVOICE_TASK,
    QueryKeyParts.LIST,
    { search }
  ]
}

export const useInvoiceTaskListQuery = ({
  search,
  enabled = true,
  enableRefetchInterval = false
}: {
  search?: string
  enabled?: boolean
  enableRefetchInterval?: boolean
}): UseQueryResult<Task[], Error> => {
  const searchString = removeFromSearchString({ search, remove: ["modelType"] })
  const newSearch = setDayBorders({ search: searchString, from: "from", to: "to" })

  return useQuery({
    queryKey: queryKeys.getInvoiceTaskList({ search: newSearch }),
    queryFn: async () => FinanceApi.get(`finance/invoice-tasks${newSearch}`),
    ...enabledByMandatoryUrlParams([enabled, newSearch]),
    refetchInterval: enableRefetchInterval ? 20000 : false
  })
}
