import { Box, Grid } from "@mui/material"
import { Link } from "components"
import { Button, FormSpyCustom, Select } from "components/Form"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { StatusEnum } from "data/commonTypes"
import { Pagination } from "lib/types"
import React, { Dispatch, memo, SetStateAction } from "react"
import { Field, Form } from "react-final-form"
import { useDebouncedCallback } from "use-debounce"

const contractStatusOptions = [
  { value: "", title: "Any" },
  { title: "Expired", value: StatusEnum.EXPIRED },
  { title: "Current", value: StatusEnum.CURRENT },
  { title: "Future", value: StatusEnum.FUTURE }
]

export interface UnitContractFilter {
  status?: StatusEnum
}

interface OwnProps {
  pagination: Pagination
  resetPagination: () => void
  filter: UnitContractFilter
  setFilter: Dispatch<SetStateAction<UnitContractFilter>>
  unitGuid: string
}

export const UnitContractListFilter: React.FC<OwnProps> = memo(
  ({ filter, setFilter, pagination, resetPagination, unitGuid }) => {
    const debouncedOnSubmit = useDebouncedCallback((values: UnitContractFilter) => {
      setFilter(values)
      resetPagination()
    }, 500)

    useFilterLocationChange({ filter, pagination })

    return (
      <Box {...{ pt: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Form {...{ initialValues: filter, onSubmit: debouncedOnSubmit }}>
          {({ handleSubmit }) => (
            <form {...{ onSubmit: handleSubmit }}>
              <FormSpyCustom {...{ handleSubmit }} />
              <Grid container alignItems="center" spacing={1}>
                <Grid item md={3}>
                  <Field<StatusEnum>
                    {...{ name: "status", label: "Status", component: Select, options: contractStatusOptions }}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
        <Box flexShrink={0}>
          <Link to={`/units/${unitGuid}/contracts/new`}>
            <Button>New contract</Button>
          </Link>
        </Box>
      </Box>
    )
  }
)

UnitContractListFilter.displayName = "UnitContractListFilter"
