import { TableCell, Tooltip } from "@mui/material"
import { Checkbox } from "components/Form"
import React, { memo } from "react"
import { Field } from "react-final-form"
import { ContractModelServiceUplift } from "../../types"

interface OwnProps {
  service: ContractModelServiceUplift
  contractModelGuid: string
}

const CheckboxField: React.FC<OwnProps> = memo(({ service, contractModelGuid }) => {
  CheckboxField.displayName = "CheckboxField"

  return (
    <Field<boolean>
      {...{
        type: "checkbox",
        name: `models[${contractModelGuid}].services[${service.guid}].picked`,
        disabled: service.disabled,
        component: Checkbox,
        onClick: (e: React.ChangeEvent<HTMLInputElement>) => {
          if (service.disabled) {
            e.preventDefault()
          }
        }
      }}
    />
  )
})

export const RateUpliftTwoServiceCheckbox: React.FC<OwnProps> = memo(({ service, contractModelGuid }) => {
  RateUpliftTwoServiceCheckbox.displayName = "RateUpliftTwoServiceCheckbox"

  const errorMessage = service.end
    ? "This Service is disabled because it has an end date."
    : "This Service is disabled because it starts after start of the uplift."

  return (
    <TableCell>
      {service.disabled ? (
        <Tooltip title={errorMessage}>
          <span>
            <CheckboxField {...{ service, contractModelGuid }} />
          </span>
        </Tooltip>
      ) : (
        <CheckboxField {...{ service, contractModelGuid }} />
      )}
    </TableCell>
  )
})
