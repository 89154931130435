import React from "react"
import { Link } from "components"
import Auth from "lib/Auth"
import { SelectableIdsDictionaryModel } from "lib/hooks"
import dateTimeUtils from "lib/datetime"
import { moneyFormat } from "lib/utils"
import { RewindButton } from "components/RewindButton"
import testingId from "constants/testingId"
import { Checkbox } from "components/Form"
import { Field } from "react-final-form"
import { Box, SxProps, Theme } from "@mui/material"
import { isNil } from "ramda"
import { TransactionFailed, TransactionInProgress, TransactionQueued } from "pages/Finance/components"
import DoneIcon from "@mui/icons-material/Done"
import { Permission } from "constants/permission"
import { ListAlertIconBasic } from "components/ListAlertIcon"
import { optionsToValueTitle } from "lib/schema"
import { FrequencyOptions } from "constants/selectOptions"
import { PATH } from "constants/path"
import { PaymentTransactionGroup } from "data/finance/paymentProcessing/types"

const getPeriodAction = (
  period: PaymentTransactionGroup,
  toggleId: Function,
  selectableIds: SelectableIdsDictionaryModel,
  sx?: SxProps<Theme>
) => {
  if (period.is_failed) return <TransactionFailed />
  if (period.is_queued) return <TransactionQueued />
  if (period.is_processing) return <TransactionInProgress />

  if (!period.is_creditable && !period.is_processable && period.paid_at) {
    return <DoneIcon />
  }

  if (!period.is_creditable && !period.is_processable) {
    return null
  }

  const hasProcessingEditPermission = Auth.hasPermission([Permission.FINANCE_PROCESSING_EDIT])
  const hasProcessingCreditEditPermission = Auth.hasPermission([Permission.FINANCE_PROCESSING_CREDIT_EDIT])

  if (
    !isNil(selectableIds[period.guid]) &&
    ((hasProcessingEditPermission && period.is_processable) ||
      (hasProcessingCreditEditPermission && period.is_creditable))
  ) {
    return (
      <Box sx={sx}>
        <Field<boolean>
          type="checkbox"
          name={`tGroup[${period.guid}]`}
          component={Checkbox}
          checked={selectableIds[period.guid]}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            toggleId(period.guid)
          }}
          data-cy={testingId.paymentProcessingGroupList.listItemCheckbox}
        />
        {period.is_creditable ? "(Cr)" : "(Pay)"}
      </Box>
    )
  }

  return null
}

export const mapPaymentTransactionGroup = ({
  tg,
  toggleId,
  selectableIds,
  sx,
  hasCompletedTransactionGroup,
  hasAlertingTransactionGroup,
  hasRewindPermission,
  refetch
}: {
  tg: PaymentTransactionGroup
  toggleId: Function
  selectableIds: SelectableIdsDictionaryModel
  sx?: SxProps<Theme>
  hasCompletedTransactionGroup: boolean
  hasAlertingTransactionGroup: boolean
  hasRewindPermission: boolean
  refetch: () => void
}): React.ReactNode[] => {
  const hasModelEditPermission = Auth.hasPermission([Permission.FINANCE_MODEL_EDIT])

  const nameItem = hasModelEditPermission ? (
    <Link to={`${PATH.FINANCE.PAYMENT_MODELS}/${tg.contract_guid}/settings`} key={`link-${tg.contract_guid}`}>
      {tg.contract_name}
    </Link>
  ) : (
    tg.contract_name
  )

  const calendarFrequencies = optionsToValueTitle(FrequencyOptions)

  const defaultItems = [
    nameItem,
    calendarFrequencies[tg.cycle_type ?? ""],
    dateTimeUtils.formatDate(tg.start),
    dateTimeUtils.formatDate(tg.end),
    tg.unconfirmed_visits ? "Y" : "-",
    tg.completed_at ? dateTimeUtils.formatDate(tg.completed_at) : "-",
    moneyFormat.format(tg.total),
    getPeriodAction(tg, toggleId, selectableIds, sx)
  ]

  const rewindItem = tg.completed_at ? (
    <RewindButton rewind_type="Period" item_guids={[tg.guid]} refetch={refetch} />
  ) : null

  const alertItem = tg.is_alerting ? <ListAlertIconBasic /> : null

  return [
    ...defaultItems,
    ...(hasRewindPermission && hasCompletedTransactionGroup ? [rewindItem] : []),
    ...(hasAlertingTransactionGroup ? [alertItem] : [])
  ]
}
