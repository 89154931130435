import { Publish } from "pubnub"
import { useMutation } from "react-query"
import { MessageUserMetadata, SendBulkMessageRequest } from "./types"
import { commsApi } from "api"
import { SYSTEM_CHAT_TEXT } from "./constants"
import { useChat } from "./ChatProvider"

export const useSendBulkMessage = () =>
  useMutation<void, Error, SendBulkMessageRequest>((channelRequests: SendBulkMessageRequest) =>
    commsApi.sendBulkMessage(channelRequests)
  )

export const useSendMessage = (channelId: string) => {
  const pubnub = useChat()
  return useMutation<Publish.PublishResponse, Error, { text: string; userMetadata: MessageUserMetadata }>(
    ({ text, userMetadata }) =>
      pubnub.publish({
        message: {
          type: SYSTEM_CHAT_TEXT,
          text: text,
          sender: { ...userMetadata },
          pn_fcm: {
            android: {
              data: {
                type: "system.chat.text",
                channel_id: channelId,
                // WE WANT DELETE THIS EVENTUALLY!!!!
                message_timetoken: String(Math.floor((new Date().getTime() + 1000) * 10000)),
                name: userMetadata.name,
                message_id: crypto.randomUUID()
              }
            }
          }
        },
        channel: channelId,
        sendByPost: true
      })
  )
}
