export enum ACTION_TYPES {
  DELIVERED = "delivered"
}

export enum ACTION_VALUES {
  READ = "read",
  NOT_READ = "notRead"
}

export const SYSTEM_CHAT_TEXT = "system.chat.text"
export const SYSTEM_CHAT_IMAGE = "system.chat.image"
export const MESSAGE_INPUT_PLACEHOLDER = "Type a message..."
export const TIME_TOKENS_KEY = "TIME_TOKENS_BY_CHANNEL"
export const CANCEL_LABEL = "Cancel"
export const SEND_LABEL = "Send"
export const SEND_NEW_MESSAGE = "Send new message"
export const MESSAGE_LABEL = "Message"
export const TYPE_MESSAGE_HERE_LABEL = "Type message here"
export const TYPE_THE_CARER_NAME_LABEL = "Type the carer name"
export const CARERS_LABEL = "Carer(s)"
export const ALL_CARERS_LABEL = "All carers"
export const REGIONS_LABEL = "Region(s)"
export const SELECT_REGIONS = "Select region(s)"
export const SEND_TO_LABEL = "Send to"
export const SELECT_CARERS = "Select carer(s)"
export const NO_MESSAGES = "No messages"
export const IMAGE_MESSAGE = "Sent an image"
