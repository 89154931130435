import { TableBody, TableCell, TableRow } from "@mui/material"
import { ListAlertIcon } from "components"
import { Checkbox } from "components/Form"
import { RecalculateButton } from "components/RecalculateButton"
import { AppointmentSearchFilterValues } from "data/finance/invoiceSearch/types"
import { SelectableIdsDictionaryModel } from "lib/hooks"
import { isNil } from "ramda"
import React, { memo } from "react"
import { Field } from "react-final-form"
import { ScheduledAppointment } from "./helpers/mapScheduledAppointments"

interface OwnProps {
  items: ScheduledAppointment[]
  selectableIds: SelectableIdsDictionaryModel
  selectedIds: string[]
  toggleId: (id: string) => void
  filter: AppointmentSearchFilterValues
  hasAppointmentEditPermission: boolean
  hasRecalcPermission: boolean
}

export const AppointmentSearchScheduledTable: React.FC<OwnProps> = memo(
  ({ items, selectableIds, selectedIds, toggleId, filter, hasAppointmentEditPermission, hasRecalcPermission }) => {
    return (
      <TableBody>
        {items.map((appointment, key) => {
          const isEven = appointment.index % 2 === 0
          const isError = (filter.errors || []).includes(appointment.assigneeGuid)
          const isSelected = selectedIds.includes(appointment.assigneeGuid)

          return (
            <TableRow
              key={`appointment-${key}`}
              selected={isSelected}
              sx={{
                backgroundColor: isEven ? "grey.200" : "background.default",
                ...(isError ? { border: "2px solid", borderColor: "error.main" } : {})
              }}
            >
              {appointment.row.map((item, itemKey) => (
                <TableCell key={itemKey}>{item}</TableCell>
              ))}
              {hasAppointmentEditPermission ? (
                <TableCell>
                  {!isNil(selectableIds[appointment.assigneeGuid]) ? (
                    <Field<boolean>
                      {...{
                        type: "checkbox",
                        name: `delete_${appointment.assigneeGuid}`,
                        component: Checkbox,
                        checked: selectableIds[appointment.assigneeGuid],
                        onClick: (e: React.MouseEvent) => {
                          e.stopPropagation()
                          toggleId(appointment.assigneeGuid)
                        }
                      }}
                    />
                  ) : null}
                </TableCell>
              ) : null}
              {hasRecalcPermission && (
                <TableCell>
                  <RecalculateButton {...{ type: appointment.typeAlias, relation_guids: [appointment.assigneeGuid] }} />
                </TableCell>
              )}
              {appointment.is_alerting ? (
                <ListAlertIcon
                  {...{
                    icon: "warning",
                    tooltip: `Punctuality was below standard (${appointment.is_alerting} minutes)`
                  }}
                />
              ) : null}
            </TableRow>
          )
        })}
      </TableBody>
    )
  }
)

AppointmentSearchScheduledTable.displayName = "AppointmentSearchScheduledTable"
