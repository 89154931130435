import React, { memo } from "react"
import { Field, Form } from "react-final-form"
import { Box, Grid } from "@mui/material"
import { ModalWrapper } from "components"
import { Button, FormSectionHeader, Select } from "components/Form"
import useDownloadSummaryList from "pages/Finance/FileManagement/helpers/useDownloadSummaryList"
import testingId from "constants/testingId"
import { InvoiceFileItem } from "data/finance/invoiceFile/types"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { SummaryList } from "components/SummaryList"
import { BusinessDownloadMode, InterfaceFileMode } from "../types"

export interface FileManagementDownloadType {
  download_mode: BusinessDownloadMode & InterfaceFileMode
}

interface OwnProps {
  files: InvoiceFileItem[]
  isInvoiceModel: boolean
  onCancel: () => void
  onSubmit: ({ download_mode }: FileManagementDownloadType) => void
  downloadOptions: { title: string; value: string }[]
  submitButtonLabel: string
  modalTitle: string
}

const getTotalLabel = (isInvoiceModel: boolean) => (isInvoiceModel ? "Total Clients:" : "Total Employees:")

const getHeaders = (isInvoiceModel: boolean) =>
  isInvoiceModel ? ["Contract Model", "Clients", "", "£ Total value"] : ["Pay Model", "Employees", "", "£ Total value"]

export const FileManagementModal: React.FC<OwnProps> = memo(
  ({ files, isInvoiceModel, onCancel, onSubmit, downloadOptions, submitButtonLabel, modalTitle }) => {
    FileManagementModal.displayName = "FileManagementModal"

    const summaryList = useDownloadSummaryList(files)

    return (
      <ModalWrapper
        data-cy={testingId.fileManagement.downloadModalName}
        title={modalTitle}
        destroyModal={onCancel}
        width={700}
      >
        <FormSectionHeader data-cy={testingId.fileManagement.modal.headerSection} title="Selection summary" />
        <RequestLoaderWrapper>
          <SummaryList
            {...{
              items: summaryList,
              headers: getHeaders(isInvoiceModel),
              totalLabel: getTotalLabel(isInvoiceModel),
              isDownload: true
            }}
          />
          <FormSectionHeader title="Download options" />
          <Form
            onSubmit={onSubmit}
            validate={(values: FileManagementDownloadType) => {
              if (!values.download_mode) {
                return { download_mode: "Please select download mode." }
              }
            }}
          >
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item md={8}>
                    <Field<string>
                      name="download_mode"
                      component={Select}
                      options={downloadOptions}
                      label="File format"
                    />
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                  <Button
                    data-cy={testingId.fileManagement.modal.cancelButton}
                    variant="text"
                    fullWidth={false}
                    onClick={() => onCancel()}
                    sx={{
                      marginRight: "20px"
                    }}
                  >
                    CANCEL
                  </Button>
                  <Button
                    data-cy={testingId.fileManagement.modal.downloadButton}
                    type="submit"
                    fullWidth={false}
                    disabled={submitting}
                  >
                    {submitButtonLabel}
                  </Button>
                </Box>
              </form>
            )}
          </Form>
        </RequestLoaderWrapper>
      </ModalWrapper>
    )
  }
)
