import { Box, Tooltip } from "@mui/material"
import { useRewindMutation } from "data/finance/development/mutations"
import React, { memo, MouseEvent, useCallback, useState } from "react"
import { Replay } from "@mui/icons-material"

interface OwnProps {
  rewind_type: string
  item_guids: string[]
  refetch: () => void
}

export const RewindButton: React.FC<OwnProps> = memo(({ rewind_type, item_guids, refetch }) => {
  RewindButton.displayName = "RewindButton"

  const { mutateAsync: rewind } = useRewindMutation({
    rewind_type,
    item_guids,
    onSettled: () => {
      setSpin(false)
    },
    refetch
  })

  const [spin, setSpin] = useState(false)

  const onClick = useCallback(
    async (e: MouseEvent) => {
      e.stopPropagation()

      if (window.confirm(`Are you sure you want to rewind this ${rewind_type}`)) {
        setSpin(true)
        await rewind({ rewind_type, item_guids })
      }
    },
    [rewind, rewind_type, item_guids]
  )

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex"
      }}
    >
      <Tooltip title={`Rewind ${rewind_type}`}>
        <Replay
          sx={{
            "@keyframes spin": {
              "0%": {
                transform: "rotate(360deg)"
              },
              "100%": {
                transform: "rotate(0deg)"
              }
            },
            color: "crimson",
            ...(spin ? { animation: "$spin 1s linear infinite" } : {})
          }}
          onClick={onClick}
        />
      </Tooltip>
    </Box>
  )
})
