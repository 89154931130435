import Box from "@mui/material/Box"
import { ContentContainer, Link, Divider } from "components"
import React, { memo, useMemo, useState } from "react"
import { ContractModelTitle } from "./components"
import { ContractModelPageProps } from "./types"
import { useContractSalaryListQuery } from "data/finance/contractModel/queries"
import { queryStringToObject } from "lib/queryString"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { filterContractSalaryBands } from "./helpers/filterContractSalaryBands"
import { SalaryBandFilter, SalaryBandListFilter } from "./components/SalaryBandListFilter"
import { SalaryBandList } from "./components/SalaryBandList"
import DatesMessageHelp from "components/DatesMessageHelp"
import { useLocation } from "react-router-dom"

const SalaryBands: React.FC<ContractModelPageProps> = ({ contractModel, modelType }) => {
  const location = useLocation()
  const [filter, setFilter] = useState<SalaryBandFilter>(queryStringToObject(location?.search))

  const { data: allSalaries } = useContractSalaryListQuery({
    contractGuid: contractModel.guid
  })

  const currentSalaries = useMemo(() => filterContractSalaryBands(allSalaries ?? [], filter), [allSalaries, filter])

  return (
    <ContentContainer>
      <ContractModelTitle
        modelType={modelType}
        title={
          <>
            <Link to={`../settings`}>{contractModel.title}</Link>/ Salary Bands
          </>
        }
      />
      <Box m={3} mx={0}>
        <SalaryBandListFilter {...{ filter, setFilter, canAddSalaryBand: contractModel.type_alias === "P/SALARY" }} />
        <Divider color="divider" />
        <DatesMessageHelp messageType="E" />
        <RequestLoaderWrapper>
          <SalaryBandList salaries={currentSalaries} />
        </RequestLoaderWrapper>
      </Box>
    </ContentContainer>
  )
}

export default memo(SalaryBands)
