import React, { memo } from "react"
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material"
import testingId from "constants/testingId"
import { Button, Checkbox, FormSectionHeader, TextField } from "components/Form"
import { Field, FormSpy } from "react-final-form"
import DeleteIcon from "@mui/icons-material/Delete"
import Box from "@mui/material/Box"
import { FieldArray } from "react-final-form-arrays"
import { grey } from "@mui/material/colors"
import { Permission } from "constants/permission"
import Auth from "lib/Auth"
import { RecalculateButton } from "components/RecalculateButton"
import { DateField } from "components/Form/DateField"
import { FrequencySelectField } from "components/Form/FrequencySelectField"
import validators from "lib/validators"
import { StatusEnum } from "data/commonTypes"

// moved field validators to form validator because react-final-form does not register noValidate (or validator) change after initial mount (for rows to be deleted)
export const ClientContractStandingCharges: React.FC = memo(() => {
  const hasRecalcPermission = Auth.hasPermission([Permission.FINANCE_RECALCULATE_EDIT])
  const canEdit = Auth.hasPermission([Permission.FINANCE_USER_CONTRACT_RATE_EDIT])

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <FieldArray name="rates">
          {({ fields }) => {
            if (!fields.length && !canEdit) return null
            return (
              <>
                <FormSectionHeader title="Standing Charges" />
                <Table>
                  <TableHead>
                    <TableRow>
                      {canEdit && (
                        <TableCell style={{ textAlign: "left" }}>
                          <DeleteIcon />
                        </TableCell>
                      )}
                      <TableCell>Start date</TableCell>
                      <TableCell>Ends before</TableCell>
                      <TableCell>Rate</TableCell>
                      <TableCell>Hours</TableCell>
                      <TableCell
                        sx={{
                          minWidth: "150px"
                        }}
                      >
                        Frequency
                      </TableCell>
                      <TableCell>Status</TableCell>
                      {hasRecalcPermission && <TableCell />}
                    </TableRow>
                  </TableHead>
                  <TableBody data-cy={testingId.clientContract.ratesTableBody}>
                    {fields.map((name, index) => {
                      const field = fields.value[index]
                      const canBeDeleted = field.status === StatusEnum.FUTURE || !field.guid
                      const isToBeDeleted = field.delete
                      const isDisabled = isToBeDeleted || !canEdit

                      return (
                        <TableRow
                          key={index}
                          style={{
                            opacity: isToBeDeleted ? 0.4 : 1,
                            backgroundColor: isToBeDeleted ? grey[100] : "transparent"
                          }}
                        >
                          {canEdit && (
                            <TableCell>
                              {canBeDeleted ? (
                                <Field<boolean>
                                  type="checkbox"
                                  label=""
                                  component={Checkbox}
                                  name={`${name}.delete`}
                                  noValidate={true}
                                />
                              ) : null}
                            </TableCell>
                          )}
                          <TableCell>
                            <DateField
                              name={`${name}.start`}
                              label="Start date *"
                              minDate={values.start ?? undefined}
                              maxDate={values.no_end_date || !values.end ? undefined : values.end.minus({ days: 1 })}
                              disabled={isDisabled}
                            />
                          </TableCell>
                          <TableCell>
                            <DateField
                              name={`${name}.end`}
                              label="Ends before"
                              minDate={values.start ? values.start.plus({ days: 1 }) : undefined}
                              maxDate={values.no_end_date || !values.end ? undefined : values.end}
                              disabled={isDisabled}
                            />
                          </TableCell>
                          <TableCell>
                            <Field
                              name={`${name}.rate`}
                              label="Rate *"
                              component={TextField}
                              validate={validators.validateCommonNumber({ min: 0 })}
                              disabled={isDisabled}
                            />
                          </TableCell>
                          <TableCell>
                            <Field
                              name={`${name}.hours`}
                              label="Chargeable Hours *"
                              component={TextField}
                              validate={validators.validateCommonNumber({ precisionValue: 2, min: 0 })}
                              disabled={isDisabled}
                            />
                          </TableCell>
                          <TableCell>
                            <FrequencySelectField
                              name={`${name}.frequency_type_alias`}
                              label="Frequency *"
                              disabled={isDisabled}
                            />
                          </TableCell>
                          <TableCell>{fields.value[index].status}</TableCell>
                          {hasRecalcPermission && (
                            <TableCell>
                              {!!field.guid && (
                                <RecalculateButton {...{ type: "StandingCharge", relation_guids: [field.guid] }} />
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                {canEdit && (
                  <Box display="flex" justifyContent="flex-end" m={3} mx={0}>
                    <Button
                      fullWidth={false}
                      onClick={() => fields.push({})}
                      data-cy={testingId.clientContract.addRateButton}
                    >
                      Add new row
                    </Button>
                  </Box>
                )}
              </>
            )
          }}
        </FieldArray>
      )}
    </FormSpy>
  )
})

ClientContractStandingCharges.displayName = "ClientContractStandingCharges"
