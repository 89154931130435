import React, { Dispatch, SetStateAction, memo, ReactNode } from "react"
import { Box } from "@mui/material"
import { SignOffModal, SignOffWithConcernModal, ReopenModal } from "../components"
import { Button, FormSectionHeader } from "components/Form"
import { Visit } from "models/Visit"
import testingId from "constants/testingId"

interface SignOffButtonProps {
  id: string
  onClick: () => void
  title: string
}

interface Props {
  setModal: Dispatch<SetStateAction<ReactNode>>
  destroyModal: () => void
  visit: Visit
}

const SignOffButton = ({ id, onClick, title }: SignOffButtonProps) => {
  return (
    <Button sx={(theme) => ({ marginLeft: theme.spacing(2) })} id={id} fullWidth={false} onClick={onClick}>
      {title}
    </Button>
  )
}

const VisitDetailSignOff = ({ setModal, visit, destroyModal }: Props) => {
  return (
    <Box m={5} mx={0} data-cy={testingId.visit.detail.signOff}>
      <FormSectionHeader title="Visit sign off" data-cy={testingId.visit.detail.signOffState} />
      {!visit.hasSignOffAvailable() ? (
        <p>This visit report cannot be signed off or reopened at this time</p>
      ) : (
        <Box m={3} mx={0} display="flex" justifyContent="flex-end">
          {visit.canReopen() && (
            <SignOffButton
              id="visit-details-reopen-btn"
              onClick={() => {
                setModal(() => <ReopenModal visit={visit} destroyModal={destroyModal} />)
              }}
              title="Reopen"
            />
          )}
          {visit.canSignOff() && (
            <SignOffButton
              id="visit-details-sign-off-btn"
              onClick={() => {
                setModal(() => <SignOffModal visit={visit} destroyModal={destroyModal} />)
              }}
              title="Sign off"
            />
          )}
          {visit.canSignOffWithConcern() && (
            <SignOffButton
              id="visit-details-sign-off-with-concern-btn"
              onClick={() => {
                setModal(() => <SignOffWithConcernModal visit={visit} destroyModal={destroyModal} />)
              }}
              title="Sign off with concern"
            />
          )}
        </Box>
      )}
    </Box>
  )
}
export default memo(VisitDetailSignOff)
