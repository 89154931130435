import React, { memo, PropsWithChildren } from "react"
import { Box, styled, Tooltip } from "@mui/material"
import { StyledTooltipText } from "./TooltipInfo"

interface OwnProps {
  status: "Expired" | "Current" | "Future" | "Info"
  tooltipText?: string
  sx?: Record<string, string | number>
}

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: "4px",
  padding: theme.spacing(1),
  display: "inline-flex",
  flexGrow: 0,
  margin: "auto"
}))

const bgColors = {
  Expired: "grey.200",
  Current: "#E6F0EE",
  Future: "#FFF3E0",
  Info: "#E5F2FC"
}

export const Tag = memo(({ status, children, tooltipText, sx = {} }: PropsWithChildren<OwnProps>) => {
  Tag.displayName = "Tag"

  const defaultStyles = {}

  return (
    <Box sx={{ ...defaultStyles, ...sx }}>
      <Tooltip title={!!tooltipText && <StyledTooltipText>{tooltipText}</StyledTooltipText>} arrow>
        <StyledBox sx={{ bgcolor: bgColors[status] }}>{children ?? status}</StyledBox>
      </Tooltip>
    </Box>
  )
})
