import React, { memo, useCallback, useMemo, useState } from "react"
import Grid from "@mui/material/Grid"
import { ContentContainer, StickyBox } from "components"
import { CancelButton, Button } from "components/Form"
import { Form, FormRenderProps } from "react-final-form"
import CalendarHeader from "./components/calendars/CalendarHeader"
import CreateCalendarStepOne from "./components/calendars/CreateCalendarStepOne"
import { CreateCalendarStepTwo } from "./components/calendars/CreateCalendarStepTwo"
import { validateCalendarRequest, validateCalendarStartDate } from "./components/calendars/validations"
import BottomErrorMessage from "components/BottomErrorMessage"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import { ContractModelType } from "constants/modelTypes"
import {
  queryKeys,
  useContractCalendarPeriodListQuery,
  useContractModelCalendarListQuery,
  useContractModelCalendarQuery
} from "data/finance/contractModel/queries"
import { useContractModelCalendarReplaceMutation } from "data/finance/contractModel/mutations"
import useCreateOrUpdateCalendarWithPeriodsHandler from "./handlers/useCreateOrUpdateCalendarWithPeriodsHandler"
import RequestLoaderWrapper from "components/RequestLoaderWrapper"
import { FormStage } from "constants/form"
import arrayMutators from "final-form-arrays"
import { isEmpty } from "ramda"
import { useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { AddPeriodPayload, CalendarRequest, ContractModel } from "data/finance/contractModel/types"
import { NewCalendarWithPeriodsRequest } from "./types"

interface OwnProps {
  contractModel: ContractModel
  modelType: ContractModelType
}

const CalendarDetail: React.FC<OwnProps> = ({ contractModel, modelType }) => {
  const { calendarId } = useParams()
  const [formStage, setFormStage] = useState<FormStage>(calendarId ? FormStage.EDIT : FormStage.CREATE)

  const { data: calendars = [] } = useContractModelCalendarListQuery({ modelType, contractGuid: contractModel.guid })

  const { data: calendar } = useContractModelCalendarQuery({
    modelType,
    contractGuid: contractModel.guid,
    calendarGuid: calendarId
  })
  const { data: periods } = useContractCalendarPeriodListQuery({
    modelType,
    contractModelGuid: contractModel.guid,
    calendarGuid: calendarId
  })

  const createOrUpdate = useCreateOrUpdateCalendarWithPeriodsHandler({
    modelType,
    contractGuid: contractModel.guid
  })

  const { mutate: replaceCalendar } = useContractModelCalendarReplaceMutation()

  const queryCache = useQueryClient()

  const onSubmit = useCallback(
    (values: NewCalendarWithPeriodsRequest) => {
      // new calendar
      if (formStage === FormStage.CREATE) {
        const dateErrors = validateCalendarStartDate(values, contractModel, calendars, modelType)

        if (!isEmpty(dateErrors)) {
          return dateErrors
        }
        if (!values.isReplace) {
          setFormStage(FormStage.EDIT)
          return
        }
      }

      if (values.isReplace && values.guid) {
        const calendarPayload: CalendarRequest = {
          start: values.start,
          title: values.title,
          frequency_type_alias: values.frequency_type_alias
        }

        replaceCalendar({
          modelType,
          contractGuid: contractModel.guid,
          calendarGuid: values.guid,
          calendar: calendarPayload,
          onSuccess: () => {
            queryCache.invalidateQueries(
              queryKeys.getContractModelCalendarListKey({ modelType, contractGuid: contractModel.guid })
            )
            setFormStage(FormStage.EDIT)
          }
        })
        return
      }

      const errors = validateCalendarRequest(contractModel, values, calendars)
      if (errors && Object.keys(errors).length > 0) {
        return errors
      }

      return createOrUpdate(values)
    },
    [contractModel, calendars, createOrUpdate, formStage, modelType, replaceCalendar, queryCache]
  )

  const onBackClick = useCallback(() => {
    setFormStage(FormStage.CREATE)
  }, [])

  const submitButtonText = useMemo(
    () => (formStage === FormStage.CREATE && !calendar ? "NEXT" : "SAVE"),
    [formStage, calendar]
  )

  const isInvoiceModel = modelType === ContractModelType.INVOICE

  const periodPayload: AddPeriodPayload = isInvoiceModel
    ? {
        periodCount: 0,
        invoiceDeadlineOffset: "invoiceDeadlineOffset",
        invoiceDeadlineDays: 1,
        processDateOffset: "processDateOffset",
        processDays: 1
      }
    : { periodCount: 0 }

  const initialValues = calendar ? { ...calendar, periods, ...periodPayload } : periodPayload

  if (!contractModel || (calendarId && !calendar)) return null

  return (
    <ContentContainer>
      <CalendarHeader contractModel={contractModel} modelType={modelType} calendarTitle={calendar?.title} />
      {!calendar && formStage === FormStage.EDIT ? (
        <Button variant="text" fullWidth={false} onClick={onBackClick}>
          <KeyboardArrowLeft /> Back
        </Button>
      ) : null}
      <RequestLoaderWrapper my={3}>
        <Form onSubmit={onSubmit} initialValues={initialValues} mutators={{ ...arrayMutators }}>
          {({
            error,
            submitError,
            handleSubmit,
            pristine,
            submitting
          }: FormRenderProps<NewCalendarWithPeriodsRequest>) => (
            <form onSubmit={handleSubmit}>
              {formStage === FormStage.CREATE && <CreateCalendarStepOne modelType={modelType} calendars={calendars} />}
              {formStage === FormStage.EDIT && (
                <CreateCalendarStepTwo modelType={modelType} setFormStage={setFormStage} />
              )}
              <StickyBox>
                <Grid container sx={{ margin: 0, width: "100%" }}>
                  <Grid item md={6}>
                    {error || submitError ? <BottomErrorMessage message={error || submitError} /> : null}
                  </Grid>
                </Grid>

                <CancelButton disabled={submitting} pristine={pristine} navigateUrl="../calendars" />
                <Button
                  type="submit"
                  fullWidth={false}
                  disabled={submitting || pristine}
                  sx={(theme) => ({
                    margin: theme.spacing(0, 0, 0, 3)
                  })}
                >
                  {submitButtonText}
                </Button>
              </StickyBox>
            </form>
          )}
        </Form>
      </RequestLoaderWrapper>
    </ContentContainer>
  )
}

export default memo(CalendarDetail)
