import { FinanceApi } from "api"
import { ContractModelType } from "constants/modelTypes"
import { useFileQuery } from "data/file/queries"
import { FileDownloadResponse } from "data/finance/invoiceFile/types"
import { enabledByMandatoryUrlParams } from "data/helpers"
import { downloadFileSimple } from "lib/utils"
import { useCallback, useState } from "react"
import { useQuery } from "react-query"

// Currently we are receiving fle name and S3 link.
const downloadFile = ({ link, fileName }: { link?: string; fileName?: string }) => {
  if (link && fileName) {
    downloadFileSimple({ href: link, fileName, mode: "save&open" })
  }
}

export const useDownloadFinanceFile = ({
  modelType,
  isInterface = false
}: {
  modelType: ContractModelType
  isInterface?: boolean
}): ((fileGuid: string) => void) => {
  const [fileToDownload, setFileToDownload] = useState("")

  useQuery<FileDownloadResponse>({
    queryKey: ["finance-file", fileToDownload],
    queryFn: async () =>
      FinanceApi.get(
        `/finance/${modelType === ContractModelType.PAYMENT ? "payment-files" : "invoice-files"}/${
          isInterface ? "interfaces/" : ""
        }${fileToDownload}/download`
      ),
    ...enabledByMandatoryUrlParams([fileToDownload]),
    onSuccess: (response) => {
      downloadFile({ link: response.link, fileName: response.file_name })
    },
    onSettled: () => {
      setFileToDownload("")
    }
  })

  return useCallback((fileGuid: string) => {
    setFileToDownload(fileGuid)
  }, [])
}

export const useDownloadFile = (): ((guid: string) => void) => {
  const [fileGuid, setFileGuid] = useState<string>()

  useFileQuery({
    fileGuid,
    onSuccess: (response) => {
      downloadFile({ link: response.presigned_link, fileName: response.file_name })
    },
    onSettled: () => {
      setFileGuid("")
    }
  })

  return useCallback((guid: string) => {
    setFileGuid(guid)
  }, [])
}
