import React, { memo } from "react";
import { ListItem, Typography } from "@mui/material";

import Config from "lib/config";

export const MainNavVersion = memo(() => {
  MainNavVersion.displayName = "MainNavVersion";

  const version = Config.getEnvVariable("APP_BUILD_VERSION");

  if (!version) {
    return null;
  }

  return (
    <ListItem>
      <Typography
        sx={(theme) => ({
          textDecoration: "underline",
          marginTop: theme.spacing(4),
        })}
        variant="caption"
      >
        v {version}
      </Typography>
    </ListItem>
  );
});
