import { DownloadApi } from "api"
import { useMutation, UseMutationResult } from "react-query"
import { ApiDownload, ApiDownloadRequest } from "./types"
import { useNavigate } from "react-router-dom"
import { useUIStore } from "stores"

export const useDownloadMutation = (): UseMutationResult<ApiDownload, Error, ApiDownloadRequest> => {
  const showSuccessMessage = useUIStore((state) => state.showSuccessMessage)
  const navigate = useNavigate()

  return useMutation(
    async (values: ApiDownloadRequest): Promise<ApiDownload> => DownloadApi.post("/downloads", values),
    {
      onSuccess: (data) => {
        navigate(`/download?job_guid=${data.job_guid}`)
        showSuccessMessage("Download request sent.")
      }
    }
  )
}
