import { FORM_ERROR, ValidationErrors } from "final-form"
import { DateTime } from "luxon"
import { DateTimeFormat } from "lib/datetime"
import { ValidationErrorsDefined } from "./types"

export const validateFilterPeriodDates =
  ({ start, end, allowSameDay = true }: { start: string; end: string; allowSameDay?: boolean }) =>
  (values: Record<string, any>): ValidationErrors => {
    const startTime = values[start]
    const endTime = values[end]

    if (DateTime.isDateTime(startTime) && DateTime.isDateTime(endTime)) {
      if (allowSameDay && startTime.toMillis() > endTime.toMillis()) {
        return {
          [start]: "Start date can not be after end date",
          [end]: "End date can not be before start date"
        }
      }

      if (!allowSameDay && startTime.toMillis() >= endTime.toMillis()) {
        return {
          [start]: "Start date must be before end date",
          [end]: "End date must be after start date"
        }
      }
    }

    return undefined
  }

interface PeriodDatesProps {
  start?: DateTime
  end?: DateTime | null
  no_end_date?: boolean
}

export const validatePeriodDates = (values: PeriodDatesProps): ValidationErrorsDefined => {
  if (!values.no_end_date && values.end && values.start && values.start >= values.end) {
    return {
      start: "Start date must be before end date",
      end: "End date must be after start date",
      [FORM_ERROR]: "End date must be after start date."
    }
  }
  return {}
}

export const validatePeriodInParent = ({
  itemName,
  parentName,
  values,
  parent
}: {
  values: { start: DateTime; end?: DateTime | null; no_end_date?: boolean }
  parent: { start: DateTime; end?: DateTime | null }
  itemName: string
  parentName: string
}): ValidationErrorsDefined => {
  let errors: ValidationErrorsDefined = {}

  if (!parent.start || !values.start) return errors

  const parsedItemStart = values.start.toMillis()
  const parsedItemEnd = values.no_end_date || !values.end ? null : values.end.toMillis()

  const parsedParentStart = parent.start.toMillis()
  const parsedParentEnd = parent.end ? parent.end.toMillis() : null

  const isOutOfRange =
    parsedItemStart < parsedParentStart || // starts before parent
    (parsedParentEnd && parsedItemEnd && parsedItemEnd > parsedParentEnd) || // both have end, ends after parent
    (!!parsedParentEnd && !parsedItemEnd) // no end, but parent has end

  if (isOutOfRange) {
    const parentPeriod = `${parent.start.toFormat(DateTimeFormat.DATE)} - ${
      parent.end ? parent.end.toFormat(DateTimeFormat.DATE) : "--/--/--"
    }`

    errors = {
      start: `Must be within ${parentName} period (${parentPeriod})`,
      end: `Must be within ${parentName} period (${parentPeriod})`,
      [FORM_ERROR]: `${itemName} must be within ${parentName} Start and End dates (${parentPeriod}).`
    }
  }

  return errors
}

export const validatePeriodWithSiblings = <I extends { guid: string; start: DateTime; end?: DateTime | null }>({
  items,
  values,
  label,
  comparePrimaryKey,
  compareSecondaryKey
}: {
  items?: I[]
  values: {
    guid?: string
    start?: DateTime
    end?: DateTime | null
    no_end_date?: boolean
    [key: string]: any
  }
  label: string
  comparePrimaryKey?: [string, (item: I) => string | undefined]
  compareSecondaryKey?: [string, (item: I) => string | undefined]
}): ValidationErrorsDefined => {
  let errors: ValidationErrorsDefined = {}

  // do not compare with itself
  const filteredItems = items?.filter((item) => item.guid !== values.guid)

  // do not compare if not valid
  if (!values.start) return errors
  if (!filteredItems?.length) return errors

  const valueStart = values.start.toMillis()

  const overlappedItem = filteredItems?.find((item) => {
    const itemStart = item.start.toMillis()

    const sameStart = itemStart === valueStart
    const overlapsLeft = itemStart < valueStart && (!item.end || item.end.toMillis() > valueStart)
    const overlapsRight =
      valueStart < itemStart && (values.no_end_date || !values.end || values.end.toMillis() > itemStart)

    let isComparable = true

    // do not compare if they have same prop (for example client contract's contract_guid)
    if (comparePrimaryKey) {
      const [primaryValueKey, primaryItemGetter] = comparePrimaryKey
      isComparable = isComparable && values[primaryValueKey] === primaryItemGetter(item)
    }

    if (compareSecondaryKey) {
      const [secondaryValueKey, secondaryItemGetter] = compareSecondaryKey
      isComparable = isComparable && values[secondaryValueKey] === secondaryItemGetter(item)
    }

    return isComparable && (overlapsLeft || sameStart || overlapsRight)
  })

  if (overlappedItem) {
    errors = {
      [FORM_ERROR]: `Can not overlap with existing ${label} (${overlappedItem.start.toFormat(DateTimeFormat.DATE)} - ${
        overlappedItem.end ? overlappedItem.end.toFormat(DateTimeFormat.DATE) : "--/--/--"
      })`,
      start: `Can not overlap with existing ${label}`,
      end: `Can not overlap with existing ${label}`
    }
  }

  return errors
}
