import { TableRow, alpha, TableCell } from "@mui/material"
import { Checkbox, Select } from "components/Form"
import { SchemaFieldOption } from "lib/types"
import React, { memo } from "react"
import { Field, useField, useForm } from "react-final-form"

interface OperatorPermissionTableRowProps {
  permission: SchemaFieldOption
}

export const OperatorPermissionTableRow: React.FC<OperatorPermissionTableRowProps> = memo(({ permission }) => {
  const { input: isWildCardInput } = useField(`resources.${permission.value}.is_wildcard`)
  const { input: scopeInput } = useField(`resources.${permission.value}.scope`)

  const isWildCard = isWildCardInput.value
  const scope = scopeInput.value

  const form = useForm()

  return (
    <TableRow
      sx={(theme) => ({
        "&>td, &>th": {
          padding: "8px"
        },
        ...(permission && scope === "Read"
          ? {
              backgroundColor: alpha(theme.palette.primary.main, 0.1)
            }
          : {}),
        ...(permission && scope === "Edit"
          ? {
              backgroundColor: theme.palette.primary.main,
              "& td": {
                color: theme.palette.background.default
              },
              "& svg": {
                fill: theme.palette.background.default
              }
            }
          : {})
      })}
    >
      <TableCell>
        <Field
          type="checkbox"
          component={Checkbox}
          name={`resources.${permission.value}.is_wildcard`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const isChecked = e.target.checked
            form.change(`resources.${permission.value}.is_wildcard`, isChecked)
            if (isChecked && !scope) {
              form.change(`resources.${permission.value}.scope`, "Read")
            }
          }}
        />
      </TableCell>
      <TableCell>
        <Field
          name={`resources.${permission.value}.scope`}
          component={Select}
          options={
            isWildCard
              ? [
                  { title: "Read", value: "Read" },
                  { title: "Edit", value: "Edit" }
                ]
              : [
                  { title: "Read", value: "Read" },
                  { title: "Edit", value: "Edit" },
                  { title: "None", value: "" }
                ]
          }
        />
      </TableCell>
      <TableCell>{permission.title}</TableCell>
    </TableRow>
  )
})

OperatorPermissionTableRow.displayName = "OperatorPermissionTableRow"
