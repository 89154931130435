import { Box, Divider, Grid } from "@mui/material"
import { EmptyData, Link, StickyBox } from "components"
import DatesMessageHelp from "components/DatesMessageHelp"
import { Button, FormSectionHeader, FormSpyCustom } from "components/Form"
import { StatusSelectField } from "components/Form/StatusSelectField"
import { useFilterLocationChange } from "components/handlers/useFilterLocationChange"
import { useCarerContractListDeleteHandler } from "data/finance/carer/handlers/useCarerContractListDeleteHandler"
import { useCarerContractListQuery } from "data/finance/carer/queries"
import { useSelectableIdsDictionary } from "lib/hooks"
import { queryStringToObject } from "lib/queryString"
import { ApiUserCarer } from "models"
import { ModelTable } from "pages/Clients/components/Table/index"
import React, { memo, useMemo, useState } from "react"
import { Form } from "react-final-form"
import CarerPayrollReferenceForm from "./CarerPayrollReferenceForm"
import { filterCarerContractList } from "./helpers/filterCarerContractList"
import { mapCarerContractListData } from "./helpers/mapCarerContractListData"
import { CarerContractsFilter } from "./types"
import { useLocation, useNavigate } from "react-router-dom"

interface Props {
  carer: ApiUserCarer
}

const headerNamesList = ["Payment model", "Starts on", "Ends before", "Status"]

const CarerContractsList: React.FC<Props> = ({ carer }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { data: carerContracts, refetch } = useCarerContractListQuery({ carerGuid: carer?.guid })

  const [filter, setFilter] = useState<CarerContractsFilter>(queryStringToObject(location?.search))
  useFilterLocationChange({ filter })

  const rows = useMemo(() => {
    const filteredContracts = filterCarerContractList({ contracts: carerContracts, filter })
    return mapCarerContractListData(navigate, filteredContracts)
  }, [navigate, filter, carerContracts])

  const deletableGuids = useMemo(() => {
    return rows.reduce((res: string[], row) => (row.canBeDeleted ? [...res, row.guid] : res), [])
  }, [rows])

  const { toggleId, getSelectedAsStringArr, resetAll } = useSelectableIdsDictionary(deletableGuids)
  const enableDelete = getSelectedAsStringArr().length > 0

  const onRowChecked = (rowId: string) => toggleId(rowId)

  const onDelete = useCarerContractListDeleteHandler({
    carerGuid: carer.guid,
    contractGuids: getSelectedAsStringArr(),
    onOk: () => {
      resetAll()
      refetch()
    }
  })

  const onSubmit = (values: CarerContractsFilter) => {
    setFilter(values)
  }
  if (!carer) return null

  return (
    <Box m={3} mx={0}>
      <CarerPayrollReferenceForm carer={carer} />
      <FormSectionHeader title="Payment Groups" />
      <Form {...{ onSubmit, initialValues: filter }}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpyCustom {...{ handleSubmit }} />
            <Grid container alignItems="center" spacing={1}>
              <Grid item md={3}>
                <StatusSelectField multiselect />
              </Grid>
              <Grid item md={6} />
              <Grid item md={3}>
                <Link to={`new`}>
                  <Button
                    sx={{
                      whiteSpace: "nowrap"
                    }}
                  >
                    ADD PAY GROUP
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <Divider
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                height: "2px",
                backgroundColor: "divider"
              }}
            />
            <DatesMessageHelp messageType="E" />
            {rows?.length > 0 ? (
              <>
                {
                  <ModelTable>
                    <ModelTable.Head data={headerNamesList} addDeleteIcon />
                    <ModelTable.Body
                      {...{
                        rows,
                        onRowChecked,
                        sx: {
                          cursor: "pointer"
                        }
                      }}
                    />
                  </ModelTable>
                }
              </>
            ) : (
              <EmptyData message="No contracts found, start by adding one." />
            )}
            <StickyBox>
              <Button type="submit" fullWidth={false} onClick={onDelete} disabled={!enableDelete}>
                Delete
              </Button>
            </StickyBox>
          </form>
        )}
      </Form>
    </Box>
  )
}

export default memo(CarerContractsList)
